import React from "react";
import { LinkButton, Flex, Heading, Spacer, Text } from "@badi/badi-components";

import Footer from "components/Layout/Footer";
import { translate } from "base/shared/Localization";
import { getLocalePath } from "base/shared/Utils";
import HeaderLang from "components/header-lang";
import LayoutWrapper from "components/Layout/Wrapper";
import Image from "components/Image/Image";

const NotFound = ({ location }) => (
  <LayoutWrapper footer={<Footer campaign="notfound_footer" />}>
    <HeaderLang relativePath={location.pathname} />
    <Spacer bottom={8} top={8}>
      <Flex alignItems="center" direction="column">
        <Spacer bottom={5}>
          <Image alt="" src="/assets/images/not-found.svg" />
        </Spacer>
        <Heading data-qa="not-found-page-title" level={3}>
          {translate("not_found.title")}
        </Heading>
        <Spacer bottom={3} top={1}>
          <Text>{translate("not_found.description")}</Text>
        </Spacer>
        <LinkButton data-qa="not-found-page-cta" href={getLocalePath()}>
          {translate("not_found.cta")}
        </LinkButton>
      </Flex>
    </Spacer>
  </LayoutWrapper>
);

export default NotFound;
