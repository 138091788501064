import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import PublishedListing from "./published-listing";

export const closeRoomPublishedModal = () => {
  modal.remove(MODAL_ID.ROOM_PUBLISHED);
};

export const openRoomPublishedModal = ({ roomId, room = null }) => {
  modal.add(
    <PublishedListing
      onClose={closeRoomPublishedModal}
      room={room}
      roomId={roomId}
    />,
    {
      onIconClose: closeRoomPublishedModal,
      id: MODAL_ID.ROOM_PUBLISHED,
      onClose: closeRoomPublishedModal,
      type: MODAL_TYPE.ONBOARDING,
    },
  );
};
