import React from "react";
import { Heading, Flex, Spacer } from "@badi/badi-components";

const SearchFilterSectionWrapper = ({ children, title, hasPadding }) => (
  <Flex direction="column">
    <Spacer bottom={1}>
      <Spacer bottom={hasPadding ? 2 : 0}>
        <Heading level={4}>{title}</Heading>
      </Spacer>
      {children}
    </Spacer>
  </Flex>
);

SearchFilterSectionWrapper.defaultProps = {
  hasPadding: true,
};

export default SearchFilterSectionWrapper;
