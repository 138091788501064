import React, { Component } from "react";

import { translate } from "base/shared/Localization";

import Button from "components/Button/Button";
import { Container, Row, Col } from "reactstrap";
import Formsy from "formsy-react";

import RadioButtonGroup from "components/Form/RadioButton/RadioButtonGroupWithFormsy";
import TextArea from "components/Form/TextArea/TextAreaWithFormsy";

import { getOptions, getReportTypeId, getReasonId } from "./utils";

import "./ReportExt.css";

class ReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getReasons(props, ""),
      selectedOptionId: null,
      reportOption: undefined,
      badBehaviour: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      options: this.getReasons(nextProps, this.state.badBehaviour),
    });
  }

  onChange = (values) => {
    const { reportOption, badBehaviour } = values;
    const { type } = this.props;
    const valid =
      !!this.state.selectedOptionId &&
      (reportOption !== "badBehaviour" || !!badBehaviour);
    const optionId =
      type === "reportType"
        ? getReportTypeId(reportOption)
        : getReasonId(type, reportOption);
    const selectedOptionId = optionId;

    this.setState({ reportOption, valid, selectedOptionId, badBehaviour });
  };

  onSubmit() {
    const { reportOption, selectedOptionId, badBehaviour } = this.state;
    this.props.onSubmit(
      selectedOptionId,
      reportOption === "badBehaviour" ? badBehaviour : "",
    );
    this.setState({ selectedOptionId: null });
  }

  getReasons = ({ type }, additionalValue) =>
    translate(getOptions(type), ["title", "description"]).map(
      (optionProperty) => ({
        ...optionProperty,
        children: optionProperty.children ? (
          <TextArea
            maxLength={300}
            name={optionProperty.value}
            placeholder={translate(optionProperty.placeholder)}
            required={true}
            validations={{
              maxLength: 300,
            }}
            value={additionalValue}
          />
        ) : null,
      }),
    );

  render() {
    const { options, reportOption, valid } = this.state;

    const { loading, type } = this.props;

    const isTypeSelector = type === "reportType";
    return (
      <div className="Report">
        <Formsy
          onChange={this.onChange}
          ref={(node) => {
            this.formsy = node;
          }}
        >
          <Container fluid={true}>
            <Row>
              <Col sm={12}>
                <h1>{translate(`report.${type}.title`)}</h1>
                {!isTypeSelector && (
                  <p className="Report--description">
                    {translate(`report.${type}.description`)}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="Report--content">
              <Col sm={12}>
                <RadioButtonGroup
                  items={options}
                  name="reportOption"
                  required={true}
                  layout="area"
                  value={reportOption}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={{ size: 4, offset: 8 }}>
                <Button
                  config={{ color: "pink", restrain: true, wide: true }}
                  disabled={!valid}
                  loading={loading}
                  type="button"
                  text={translate(`report.${type}.button`)}
                  onClick={this.onSubmit}
                />
              </Col>
            </Row>
          </Container>
        </Formsy>
      </div>
    );
  }
}

ReportForm.defaultProps = {
  type: "reportType",
};

export default ReportForm;
