import React from "react";
import { Row, Col } from "reactstrap";

import "./IntroExt";

const SecurityIntro = ({ text }) => (
  <Row>
    <Col md={11} lg={6}>
      <h4 className="SecurityIntro">{text}</h4>
    </Col>
  </Row>
);

export default SecurityIntro;
