import React from "react";
import { Text, Spacer, Flex } from "@badi/badi-components";
import { SubSectionWrapper } from "./styled-components";

const SearchFilterSubSectionWrapper = ({ children, title }) => (
  <SubSectionWrapper>
    <Flex direction="column">
      <Spacer bottom={2}>
        <Text body={2}>{title}</Text>
      </Spacer>
      {children}
    </Flex>
  </SubSectionWrapper>
);

export default SearchFilterSubSectionWrapper;
