import React from "react";
import { FilterLayerWrapper } from "./styled-components";

const FilterLayer = ({ listingIdMoreActionsOpen, closeAllActions }) => {
  return listingIdMoreActionsOpen ? (
    <FilterLayerWrapper onClick={closeAllActions} role="presentation" />
  ) : null;
};

export default FilterLayer;
