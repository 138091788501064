import React from "react";

import { getRelativePath } from "base/shared/Utils";
import { getGoldUpgradePath } from "Sections/plans/routes";

import Referrals from "./referrals-mapping";

const ReferralsRoute = ({ location, router }) => {
  const { query = {} } = location;
  const { sgid } = query;

  const handleNotAuthorized = () => {
    router.push(getRelativePath("/404"));
  };

  const redirectToBadiGold = () => {
    router.push(getGoldUpgradePath());
  };

  return (
    <Referrals
      onNotAuthorized={handleNotAuthorized}
      redirectToBadiGold={redirectToBadiGold}
      sgid={sgid}
    />
  );
};

export default ReferralsRoute;
