import React, { useEffect } from "react";
import Close from "components/Icons/Close";
import MenuElement from "./menu-element";
import {
  MenuRoot,
  MenuCloseWrapper,
  MenuList,
  MenuSeparator,
} from "./styled-components";

const MenuBody = ({ elements, onClose, visible = false }) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [visible]);

  useEffect(() => {
    return () => document.body.style.removeProperty("overflow");
  }, []);

  return (
    <MenuRoot active={visible}>
      <MenuCloseWrapper onClick={onClose} role="button" tabIndex={0}>
        <Close format="small" />
      </MenuCloseWrapper>
      <MenuList>
        {elements.map(({ id, ...props }) =>
          props.title ? (
            <MenuElement key={id} {...props} callback={onClose} />
          ) : (
            <MenuSeparator key={id} />
          ),
        )}
      </MenuList>
    </MenuRoot>
  );
};

export default MenuBody;
