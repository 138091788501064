import fetch from "base/shared/fetch-data";
import envVars from "base/shared/Env";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { CollectionRoomModel, BadiPlusCollectionRoomModel } from "./models";

const fetchHomeCities = (
  token,
  isLoggedUser,
  countryCode,
  additionalParams,
) => {
  const { BADI_API_URL, BADI_HOME, BADI_HOME_CONTEXT } = envVars();

  const service = isLoggedUser ? BADI_HOME_CONTEXT : BADI_HOME;
  const params = countryCode ? { country_code: countryCode } : null;

  return fetch(BADI_API_URL + service, { params, token, additionalParams });
};

const fetchRoomCollections = async ({ token }) => {
  const { BADI_API_URL, BADI_ROOMS_COLLECTIONS } = envVars();
  const serverUrl = getServerUrl(BADI_ROOMS_COLLECTIONS, BADI_API_URL);

  const {
    data: { collections },
  } = await fetch(serverUrl, { token });
  return collections.map(({ rooms, ...collection }) => {
    const parsedRooms = rooms.map((room) => {
      return new CollectionRoomModel(room).toJSON();
    });
    return { ...collection, rooms: parsedRooms };
  });
};

const fetchBadiPlusCollection = async ({ token }) => {
  const { BADI_API_URL, BADI_PLUS_COLLECTION } = envVars();
  const serverUrl = getServerUrl(BADI_PLUS_COLLECTION, BADI_API_URL);

  const { data } = await fetch(serverUrl, { token });
  const rooms = data.rooms.map((room) =>
    new BadiPlusCollectionRoomModel(room).toJSON(),
  );
  return { ...data, rooms };
};

const sendForm = (token, params) => {
  const { BADI_FORM_LEADS } = envVars();
  const serverUrl = getServerUrl(BADI_FORM_LEADS);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

export {
  fetchHomeCities,
  sendForm,
  fetchRoomCollections,
  fetchBadiPlusCollection,
};
