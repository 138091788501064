import { getPicture } from "base/shared/Utils";
import Review from "datamodel/reviews/models/review-model";
import {
  getUserLanguages,
  getUserOccupation,
  getVerificationLevel,
  getYearsOld,
  getTrustedItems,
  getListerScore,
  getSocialProfiles,
} from "base/shared/User";
import {
  getFullName,
  metricsBuilder,
  orderTagsByLabel,
  getCouple,
} from "../utils";
import { LOGIN_TYPE } from "../constants";
import UserCompany from "./user-company-model";

export default class User {
  constructor(data) {
    this.entity = data;
  }

  get aboutMe() {
    return this.entity.small_bio || "";
  }

  get age() {
    return getYearsOld(this.entity.birth_date);
  }

  get badges() {
    return this.entity.badges || [];
  }

  get birthDate() {
    return this.entity.birth_date;
  }

  get confirmations() {
    return this.entity.confirmations;
  }

  get company() {
    try {
      return new UserCompany(this.entity.company).toJSON();
    } catch (error) {
      return undefined;
    }
  }

  // only for /me
  get couple() {
    return getCouple(this.entity.is_couple);
  }

  get fullName() {
    return getFullName(this.entity.first_name, this.entity.last_name);
  }

  // only for /me
  get ghost() {
    return this.entity.ghost;
  }

  get id() {
    return this.entity.id;
  }

  get languages() {
    return getUserLanguages(this.entity.languages_attributes);
  }

  get lastName() {
    return this.entity.last_name;
  }

  // only for /me
  get lister() {
    return this.entity.is_lister;
  }

  get loginType() {
    return this.entity.login_type || LOGIN_TYPE.UNKNOWN;
  }

  get metrics() {
    return metricsBuilder(this.entity.metrics);
  }

  get name() {
    return this.entity.first_name || "";
  }

  get occupationDisplayText() {
    return this.occupation.occupation;
  }

  get workStudiesStatus() {
    return (
      (this.entity.occupation_detail &&
        this.entity.occupation_detail.main_occupation) ||
      0
    );
  }

  get occupation() {
    return getUserOccupation(this.entity.occupation_detail);
  }

  get studies() {
    return this.entity.occupation_detail
      ? this.entity.occupation_detail.pursued_level_of_study || 0
      : 0;
  }

  get work() {
    return this.entity.occupation_detail
      ? this.entity.occupation_detail.work_industry || 0
      : 0;
  }

  get picture() {
    return getPicture(this.entity.pictures, "width_100_url");
  }

  get pictures() {
    return this.entity.pictures;
  }

  get mediumPicture() {
    return getPicture(this.entity.pictures, "width_500_url");
  }

  get gender() {
    return this.entity.biological_sex;
  }

  get social() {
    return this.entity.social;
  }

  get verified() {
    return this.entity.verified_account;
  }

  get verificationLevel() {
    return getVerificationLevel(this.entity.badges);
  }

  get tags() {
    return orderTagsByLabel(this.entity.tags);
  }

  get trustItems() {
    return getTrustedItems(this.entity.trust);
  }

  get listerScore() {
    return getListerScore(this.entity.trust);
  }

  get publishedRooms() {
    return this.entity.number_of_ever_published_rooms || 0;
  }

  get socialProfiles() {
    return getSocialProfiles(this.entity.social_profiles);
  }

  get reviews() {
    const { reviews } = this.entity;
    return reviews ? reviews.map((review) => new Review(review).toJSON()) : [];
  }

  get reviewsTotal() {
    return this.entity.reviews_total || 0;
  }

  get seekerPreferencesRequired() {
    return !!this.entity.seeker_preferences_required;
  }

  get subscriber() {
    return !!this.entity.open_stripe_customer_portal;
  }

  get activeUserPlans() {
    return this.entity.active_user_plans || [];
  }

  toJSON() {
    return {
      aboutMe: this.aboutMe,
      age: this.age,
      badges: this.badges,
      birthDate: this.birthDate,
      company: this.company,
      confirmations: this.confirmations,
      couple: this.couple,
      fullName: this.fullName,
      ghost: this.ghost,
      id: this.id,
      languages: this.languages,
      lastName: this.lastName,
      lister: this.lister,
      loginType: this.loginType,
      metrics: this.metrics,
      name: this.name,
      occupationDisplayText: this.occupationDisplayText,
      workStudiesStatus: this.workStudiesStatus,
      occupation: this.occupation,
      studies: this.studies,
      work: this.work,
      picture: this.picture,
      pictures: this.pictures,
      mediumPicture: this.mediumPicture,
      gender: this.gender,
      social: this.social,
      verified: this.verified,
      verificationLevel: this.verificationLevel,
      tags: this.tags,
      trustItems: this.trustItems,
      listerScore: this.listerScore,
      publishedRooms: this.publishedRooms,
      socialProfiles: this.socialProfiles,
      reviews: this.reviews,
      reviewsTotal: this.reviewsTotal,
      seekerPreferencesRequired: this.seekerPreferencesRequired,
      subscriber: this.subscriber,
      activeUserPlans: this.activeUserPlans,
    };
  }
}
