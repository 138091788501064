import React, { Fragment } from "react";

import { BREAKPOINTS, MatchMedia } from "@badi/badi-components";

import AdvancedFilters from "./buttons/advanced";
import ReducedFilters from "./buttons/reduced";
import { FilterViewButton, MapViewButton } from "./buttons/view-button";

const desktopmq = `(min-width: ${BREAKPOINTS.lg}px)`;
const mobilemq = `(max-width: ${BREAKPOINTS.lg - 1}px)`;

const SearchFilters = ({ onSelectView, view, filters }) => (
  <Fragment>
    <MatchMedia query={mobilemq}>
      <Fragment>
        <FilterViewButton onSelectView={onSelectView} view={view} />
        <ReducedFilters filters={filters} />
        <MapViewButton onSelectView={onSelectView} view={view} />
      </Fragment>
    </MatchMedia>
    <MatchMedia query={desktopmq}>
      <AdvancedFilters filters={filters} />
    </MatchMedia>
  </Fragment>
);

export default SearchFilters;
