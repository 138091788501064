import { BOOKING_BENEFITS } from "./constants";

export const getBenefitsByUserRole = (userRole) =>
  userRole
    ? [
        { id: 1, description: `booking.benefits.${userRole}.one` },
        { id: 2, description: `booking.benefits.${userRole}.two` },
        { id: 3, description: `booking.benefits.${userRole}.three` },
      ]
    : BOOKING_BENEFITS;
