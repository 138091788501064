import React from "react";
import { Container, Col, Row } from "reactstrap";
import {
  BADI_COLORS,
  BUTTON_TYPES,
  Flex,
  Heading,
  Spacer,
  LinkButton,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset, getRelativePath } from "base/shared/Utils";
import { HeaderWrapper } from "./styled-components";

const Header = () => (
  <HeaderWrapper
    data-qa="landing-header"
    image={getPublicAsset("/images/landings/list-room-apartment-head-1.jpg")}
  >
    <Container>
      <Flex direction="column" fullHeight={true} justify="flexEnd">
        <Row>
          <Col lg={6} md={11}>
            <Spacer bottom={4}>
              <Heading
                color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                data-qa="landing-header-title"
                level="32-Extrabold"
              >
                {translate("rentRoom.landing.text")}
              </Heading>
            </Spacer>
            <LinkButton
              dataQa="landing-header-button"
              variant={BUTTON_TYPES.PRIMARY_INVERTED}
              href={getRelativePath("/list/new")}
            >
              {translate("rentRoom.button.text")}
            </LinkButton>
          </Col>
        </Row>
      </Flex>
    </Container>
  </HeaderWrapper>
);

export default Header;
