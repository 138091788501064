import React, { memo, useEffect, useState } from "react";
import { translate } from "base/shared/Localization";
import { Row, Col } from "reactstrap";
import { Spacer } from "@badi/badi-components";

import FormBlock from "components/form-block";
import Select from "components/Form/Select/SelectWithFormsy";
import CheckBox from "components/Form/CheckBox/CheckBoxWithFormsy";
import { countryCodesOptions } from "components/Verification/utils";
import { getVatCountries } from "app/datamodel/Payment/api";
import { CountryWrapper } from "./styled-components";

const Country = ({ country, spainOverseas, isValid, saved, token }) => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setLoading(true);
    getVatCountries({ token }).then((r) => {
      const parsedCountries = countryCodesOptions(r.data).map((c) => ({
        ...c,
        title: c.label,
      }));
      setCountries(parsedCountries);
      setLoading(false);
    });
  }, []);

  if (loading) return null;

  return (
    <FormBlock
      dataQa="profile-form-country-section"
      id="formblock-country"
      isRequired={true}
      saved={saved}
      title={translate("location.popup_country")}
      valid={isValid}
    >
      <Row>
        <Col>
          <Select
            className="centered-flex-row"
            disabled={loading}
            name="countryCode"
            options={countries}
            placeholder={translate("location.popup_country_placeholder")}
            placeholderValue="DEFAULT"
            required={true}
            validations={{
              isNotCountryPlaceholder: true,
            }}
            value={country}
          />
          <CountryWrapper visible={country === "ES"}>
            <CheckBox
              checked={spainOverseas}
              label={translate("location.popup.check_spanish_islands")}
              name="spainOverseas"
              value={spainOverseas}
            />
          </CountryWrapper>
        </Col>
      </Row>
      <Spacer bottom={3} />
    </FormBlock>
  );
};

export default memo(Country);
