import React, { useState, createRef, useEffect } from "react";
import { debounce } from "radash";
import GoogleMap from "components/google-map";
import { fitBounds } from "google-map-react";

import { scrollTo } from "base/shared/Utils";
import { areCoordinatesEqual } from "./utils";
import { Marker, ReducedMarker } from "./marker";
import { MapWrapper } from "./styled-components";
import SearchOnMoveButton from "./search-on-move-map";
import TransportButton from "./transport-button";
import LoadingMap from "./loading-map";

const Map = ({
  coordinates: coordinatesProp,
  zoom: zoomProp,
  bounds,
  overrideLocation = true,
  setLocation,
  selectRoomById,
  recalculateBounds,
  setBoundsRecalculation,
  fetching = false,
  shownRoomCardsMarker,
  markers,
  selectedRoomId,
  setMapUsageTrackingParam,
}) => {
  const [auto, setAuto] = useState(false);
  const [loaded, setLoaded] = useState(!!(coordinatesProp && zoomProp));
  const [coordinates, setCoordinates] = useState(coordinatesProp);
  const [redoSearch, setRedoSearch] = useState(null);
  const [transport, setTransport] = useState(false);
  const [zoom, setZoom] = useState(zoomProp);

  const wrapperRef = createRef();

  const calculateCenterAndZoomFromBounds = (initialBounds) => {
    const { height, width } = wrapperRef.current
      ? wrapperRef.current.getBoundingClientRect()
      : {};

    if (!height || !width) return;

    const { center, zoom: zoomBounds } = fitBounds(initialBounds, {
      height,
      width,
    });

    setLoaded(true);
    setCoordinates(center);
    setZoom(zoomBounds);
  };

  const onBoundsChange = ({ bounds: { ne, sw }, center, gmZoom }) => {
    const newLocation = { bounds: { ne, sw }, coordinates: center, gmZoom };

    if (!auto) {
      setRedoSearch(newLocation);
    } else if (
      overrideLocation ||
      !areCoordinatesEqual(coordinates, coordinatesProp) ||
      gmZoom !== zoomProp
    ) {
      setLocation(newLocation);
    }
  };

  const handleRoomClick = (id) => {
    selectRoomById(id);

    const element = document.getElementById(`list-room-card-${id}`);
    const wrapperElement = document.getElementById("search-rooms-list");

    if (element) {
      scrollTo(element.offsetTop, 200, wrapperElement);
    }
  };

  const handleAutoClick = () => {
    setMapUsageTrackingParam();

    setAuto(!auto);
    setRedoSearch(null);
  };

  const handleRedoSearchClick = () => {
    setMapUsageTrackingParam();

    const location = redoSearch;
    setRedoSearch(null);
    setLocation(location);
  };

  const handleTransportClick = () => {
    setTransport(!transport);
  };

  const handleTouchedMap = () => {
    if (!recalculateBounds) setBoundsRecalculation();
  };

  const handleDebounceBoundsChange = debounce({ delay: 600 }, onBoundsChange);

  useEffect(() => {
    setCoordinates(coordinatesProp);
    setZoom(zoomProp);
  }, [coordinatesProp, zoomProp]);

  useEffect(() => {
    calculateCenterAndZoomFromBounds(bounds);
  }, [bounds]);

  return (
    <>
      <MapWrapper ref={wrapperRef}>
        {loaded && (
          <>
            <GoogleMap
              center={coordinates}
              layerTypes={transport ? ["TransitLayer"] : []}
              onChange={recalculateBounds ? handleDebounceBoundsChange : null}
              onDrag={handleTouchedMap}
              onZoomAnimationStart={handleTouchedMap}
              resetBoundsOnResize={true}
              zoom={zoom}
            >
              {!!markers &&
                !!markers.length &&
                markers.map((marker) => (
                  <ReducedMarker key={marker.id} {...marker} />
                ))}
              {Array.isArray(shownRoomCardsMarker) &&
                shownRoomCardsMarker.map((marker) => {
                  return (
                    <Marker
                      id={marker.id}
                      key={marker.id}
                      onClick={handleRoomClick}
                      selected={marker.id === selectedRoomId}
                      {...marker.coordinates}
                    >
                      {marker.label.content}
                    </Marker>
                  );
                })}
            </GoogleMap>
            <SearchOnMoveButton
              auto={auto}
              onAutoClick={handleAutoClick}
              onRedoSearchClick={handleRedoSearchClick}
              redoSearch={!!redoSearch}
            />
            <TransportButton
              onClick={handleTransportClick}
              selected={transport}
            />
          </>
        )}
      </MapWrapper>
      {fetching && <LoadingMap />}
    </>
  );
};

export default Map;
