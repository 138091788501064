import ReferralAccount from "./referral-account-model";
import PendingReferrals from "./pending-referrals-model";

const ReferralsModel = {
  fetchingAccount: false,
  fetchedAccount: false,
  fetchingPending: false,
  fetchedPending: false,
  fetchingProducts: false,
  fetchedProducts: false,
  error: null,
  pendingReferrals: new PendingReferrals().toJSON(),
  referralAccount: new ReferralAccount().toJSON(),
  referralProducts: [],
};

const setInitialState = (initialState) => {
  const state = initialState;

  if (!state || !state.Referrals) {
    return {
      ...ReferralsModel,
      error: "There was an error in ReferralsModel.",
    };
  }
  return state;
};

export { ReferralsModel, setInitialState };
