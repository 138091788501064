import React from "react";

import { translate } from "base/shared/Localization";

import Spinner from "components/spinner";
import { getPublicAsset } from "base/shared/Utils";

const ModerationSpinner = () => (
  <Spinner
    fromSrc={getPublicAsset("icons/non_active_booking_proposals.svg")}
    text={translate("moderation.message.validating")}
    toSrc={getPublicAsset("icons/active_booking_proposals.svg")}
  />
);

export default ModerationSpinner;
