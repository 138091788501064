import React, { PureComponent } from "react";
import classNames from "classnames";
import { Row, Col } from "reactstrap";

import LandingSectionHeader from "./SectionHeader";

import "./SectionExt";

class LandingSection extends PureComponent {
  render() {
    const { children, className, title, subtitle } = this.props;

    return (
      <Row className={classNames("LandingSection", className)}>
        <Col md={11} lg={6}>
          <LandingSectionHeader
            className="LandingSection__Header"
            title={title}
            subtitle={subtitle}
          />
        </Col>
        <Col lg={12}>{children}</Col>
      </Row>
    );
  }
}

LandingSection.defaultProps = {
  children: null,
  className: null,
  subtitle: null,
  title: null,
};

export default LandingSection;
