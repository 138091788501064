export const handleMutuallyExclusiveParams = (params) => {
  let exists = false;
  return Object.entries(params).reduce((acc, [key, val]) => {
    if (!exists && !!val) {
      acc[key] = val;
      exists = true;
    }
    return acc;
  }, {});
};
