import React from "react";

import { translate } from "base/shared/Localization";

import {
  Button,
  BUTTON_TYPES,
  Text,
  Heading,
  Flex,
} from "@badi/badi-components";

import { InfoFeedback } from "./styled-components";

const CancelConfirmation = ({ onSubmit, info }) => {
  const {
    data: {
      primary_cta: {
        action: {
          attrs: { title, content, cta },
        },
      },
    },
  } = info;
  return (
    <Flex as={InfoFeedback} direction="column" gutter={2}>
      <Heading data-qa="info-feedback-title" level="20-Bold">
        {translate(title)}
      </Heading>
      <Text body="14-Regular">{translate(content)}</Text>
      <Flex justify="flexEnd">
        <Button
          dataQa="info-feedback-submit-button"
          onClick={onSubmit}
          variant={BUTTON_TYPES.PRIMARY}
        >
          {translate(cta)}
        </Button>
      </Flex>
    </Flex>
  );
};

export default CancelConfirmation;
