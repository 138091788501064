import { createSelector } from "reselect";
import { LISTING_STATUS } from "./constants";

const getMyListings = (state) => state.MyListings;

export const planlanInfoSelector = createSelector(
  getMyListings,
  (myListings) => myListings.plansInfo,
);

export const professionalPlanInfoSelector = createSelector(
  planlanInfoSelector,
  (plansInfo) => plansInfo.pro,
);

export const proPlanTierSelector = createSelector(
  professionalPlanInfoSelector,
  (pro) => pro.tier,
);

export const proTokensLeftSelector = createSelector(
  professionalPlanInfoSelector,
  (pro) => (pro ? pro.totalTokens - pro.activeTokens : 0),
);

export const isProSelector = createSelector(
  professionalPlanInfoSelector,
  (pro) => !!pro,
);

export const statusesCountersSelector = createSelector(
  getMyListings,
  (myListings) => myListings.statusesCounters,
);

export const activeListingsCounterSelector = createSelector(
  statusesCountersSelector,
  (statusesCounter) =>
    statusesCounter.find(({ id }) => id === LISTING_STATUS.ACTIVE)?.count || 0,
);

export const selectedListingsIds = createSelector(
  getMyListings,
  (myListings) => myListings.selectedListingsIds,
);

export const myListingsRoomsSelector = createSelector(
  getMyListings,
  (myListings) => myListings.rooms,
);

export const fetchedSummarySelector = createSelector(
  getMyListings,
  (myListings) => myListings.fetchedSummary,
);

export const focusStateSelector = createSelector(
  getMyListings,
  (myListings) => myListings.focusState,
);
