import React, { useMemo, useContext } from "react";
import {
  BUTTON_TYPES,
  Spacer,
  Text,
  Flex,
  Button,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { Container, Col, Row } from "reactstrap";
import { getButtonConfig } from "./utils";
import { FooterBar, FooterContent, ButtonsWrapper } from "./styled-components";

const Footer = ({
  hint,
  hintColor,
  onAccept,
  onReject,
  openBadiGold,
  payToMatchRequired = false,
  roomId,
}) => {
  const handleBadiGoldClick = () => {
    openBadiGold(roomId);
  };
  const { text, ...submitBtnConfig } = useMemo(
    () =>
      getButtonConfig({
        payToMatchRequired,
        onAccept,
        handleBadiGoldClick,
      }),
    [payToMatchRequired, onAccept, handleBadiGoldClick],
  );

  return (
    <FooterBar data-qa="enquiry-actions-footer">
      <Container>
        <Row>
          <Col xs={12}>
            <FooterContent>
              <Spacer bottom={1} md={{ bottom: 0, right: 2 }}>
                <Text
                  align="center"
                  body="14-Medium"
                  color={hintColor}
                  dataQa="enquiry-actions-hint"
                >
                  {translate(hint)}
                </Text>
              </Spacer>
              <ButtonsWrapper as={Flex} gutter={1}>
                <Button
                  basis="100%"
                  dataQa="enquiry-footer-button-reject"
                  fullWidth={true}
                  grow={1}
                  onClick={onReject}
                  variant={BUTTON_TYPES.SECONDARY}
                >
                  {translate("lister.contact.request.cta_decline")}
                </Button>
                <Button
                  basis="100%"
                  fullWidth={true}
                  grow={1}
                  {...submitBtnConfig}
                >
                  {translate(text)}
                </Button>
              </ButtonsWrapper>
            </FooterContent>
          </Col>
        </Row>
      </Container>
    </FooterBar>
  );
};

export default Footer;
