import { getLocalesCode } from "base/shared/Localization";
import {
  HEADER_PARAMS,
  PARAMS_NULL_VALUES,
  SEARCH_SORTING_MAPPER,
  ET_PARAMS_PREFIX,
} from "base/shared/tracking-headers";
import { getSelectedIds } from "Sections/Search/search-v2/filters/forms/utils";

import { HEADER_PARAMS_KEY_VALUE } from "./static";

export const getFullPath = ({ pathname, search }) => {
  return `${decodeURIComponent(pathname)}${search}`;
};

const getNewsletterParam = (queryParams) => {
  const filteredUtmParams = Object.entries(queryParams).filter(([key, val]) => {
    return (
      (key === "utm_source" && val === "email") ||
      (key === "utm_campaign" && val === "recommended_users")
    );
  });
  if (filteredUtmParams.length === 2) {
    const {
      keyVal,
      options: { RECOMMENDATIONS_NEWSLETTER },
    } = HEADER_PARAMS.ROOM_VIEW_SOURCE;
    return { ...queryParams, [keyVal]: RECOMMENDATIONS_NEWSLETTER };
  }
  return queryParams;
};

const getPathFragments = (fullPath) => {
  const splitter = "?";
  const [path, search = ""] = fullPath.split(splitter);
  const searchParams = new URLSearchParams(search);
  return { path, splitter, searchParams };
};

export const interpolateAllQueryParam = (fullPath, arrayParams) => {
  if (!arrayParams || !arrayParams.length) return fullPath;
  const { path, splitter, searchParams } = getPathFragments(fullPath);
  arrayParams.forEach(([key, val]) => {
    searchParams.set(key, val);
  });
  return `${path}${splitter}${searchParams}`;
};

export const interpolateQueryParam = (fullPath, key, value) => {
  if (!value) return fullPath;
  const { path, splitter, searchParams } = getPathFragments(fullPath);
  searchParams.set(key, value);
  return `${path}${splitter}${searchParams}`;
};

export const handleSortBy = (fullPath, key, value) => {
  const { path, splitter, searchParams } = getPathFragments(fullPath);
  if (!key || !searchParams) return fullPath;

  if (!value && searchParams.has(key)) {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  return `${path}${splitter}${searchParams}`;
};

export const sortByQueryParamHandler = (
  searchUrl,
  sortByFilters,
  sortFilter,
) => {
  if (!sortFilter || !sortByFilters) return searchUrl;
  const sortByValue = sortFilter.value;
  const trackingSortbyId = getSelectedIds(sortByFilters);
  if (!trackingSortbyId || !sortByValue) return searchUrl;
  const { keyVal } = HEADER_PARAMS.SEARCH_SORTING;
  const option = SEARCH_SORTING_MAPPER[trackingSortbyId];
  return handleSortBy(searchUrl, keyVal, option);
};

const fillNullValueParams = (paramsObject) => ({
  ...PARAMS_NULL_VALUES,
  ...paramsObject,
});

export const generateUrlWithNoEtParams = (fullPath) => {
  const { path, splitter, searchParams } = getPathFragments(fullPath);
  if (!searchParams) return fullPath;
  const entriesArray = Array.from(searchParams.keys());
  entriesArray.forEach((key) => {
    if (key.indexOf(ET_PARAMS_PREFIX) !== -1) {
      searchParams.delete(key);
    }
  });
  return `${path}${splitter}${searchParams}`;
};

export const deleteCorruptedParams = (entriesArray) => {
  if (!Array.isArray(entriesArray) || !entriesArray.length) return null;
  return entriesArray.filter(([key]) =>
    HEADER_PARAMS_KEY_VALUE.some((exisitingKey) => exisitingKey === key),
  );
};

export const parseEtParamsApi = (entriesArray) => {
  const filteredParams = deleteCorruptedParams(entriesArray);
  if (!Array.isArray(filteredParams) || !filteredParams.length) return null;
  const parsedParams = filteredParams.reduce((acc, [key, val]) => {
    if (key.indexOf(ET_PARAMS_PREFIX) !== -1) {
      const newKey = key.substring(3);
      acc[newKey] = val;
    }
    return acc;
  }, {});

  return fillNullValueParams(parsedParams);
};

export const getParamsEntriesFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Array.from(searchParams.entries());
};

export const getParamsEntriesfromQueryObject = (queryParams) => {
  const parsedQueryParams = getNewsletterParam(queryParams);
  return Object.entries(parsedQueryParams);
};

export const filterEtQueryParams = () => {
  const entriesArray = getParamsEntriesFromUrl();
  const params = entriesArray.filter(([key]) => {
    return key.indexOf(ET_PARAMS_PREFIX) !== -1;
  });
  return params;
};

export const checkIsPath = (stringToMatch) => (pathname) => {
  const matchingLocale = getLocalesCode().find((localeCode) => {
    return pathname.startsWith(`/${localeCode}`);
  });

  let formattedPath = pathname;
  if (matchingLocale) {
    formattedPath = pathname.replace(`/${matchingLocale}`, "");
  }

  return (formattedPath || "/") === stringToMatch;
};

export const checkIsHome = checkIsPath("/");
export const checkIsSearch = checkIsPath("/s");
const checkIsPreferences = checkIsPath("/preferences");

export const getSearchSourceScreenParamArray = (originParam, textParam) => {
  let option;
  const {
    keyVal: keyValSss,
    options: { HOME_PAGE, RESULTS_PAGE, SEEKER_PREFERENCES },
  } = HEADER_PARAMS.SEARCH_SOURCE_SCREEN;

  if (checkIsHome(originParam, textParam)) {
    option = HOME_PAGE;
  } else if (checkIsSearch(originParam, textParam)) {
    option = RESULTS_PAGE;
  } else if (checkIsPreferences(originParam)) {
    option = SEEKER_PREFERENCES;
  }

  return !option ? [] : [[keyValSss, option]];
};

export const routerEventsListenerInitiator =
  (router) => (searchSourceEtParam) => {
    const origin = router.location.pathname;
    const { text } = router.params;

    const etParams = getSearchSourceScreenParamArray(origin, text);
    const unlisten = router.listen((location) => {
      if (
        !searchSourceEtParam ||
        !checkIsSearch(location.pathname, router.params.text)
      )
        return;
      const { keyVal } = HEADER_PARAMS.SEARCH_SOURCES;
      etParams.push([keyVal, searchSourceEtParam]);
      const fullPath = getFullPath(location);
      const newNextPath = interpolateAllQueryParam(fullPath, etParams);
      const currentLocation = router.getCurrentLocation();
      const currentPath = getFullPath(currentLocation);

      if (newNextPath !== currentPath) {
        unlisten();
        router.replace(newNextPath);
      }
    });

    return unlisten;
  };
