import React from "react";
import { Heading } from "@badi/badi-components";

import { UserCardBlock, UserName } from "../styled-components";

const getDisplayName = (name, age) =>
  name && age ? `${name}, ${age}` : name || age;

const NameAge = ({ age, isCompany = false, name }) => {
  const text = isCompany ? name : getDisplayName(name, age);

  return (
    <UserCardBlock>
      <Heading as={UserName} data-qa="user-nameage-heading" level={1}>
        {text}
      </Heading>
    </UserCardBlock>
  );
};

export default NameAge;
