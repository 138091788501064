import { connect } from "react-redux";

import ConversationActions from "datamodel/Conversation/actions";
import {
  getConnection,
  getConnectionStatus,
  getUserRole,
} from "datamodel/Conversation/selectors";
import { getToken } from "datamodel/Auth/selectors";

import Chat from "./chat";

export default connect(
  (state) => ({
    connection: getConnection(state),
    token: getToken(state),
    userRole: getUserRole(state),
    connectionStatus: getConnectionStatus(state),
  }),
  null,
  ({ token, ...stateProps }, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { connectionId } = ownProps;

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      getConnectionDetails: () =>
        dispatch(ConversationActions.getConnectionDetails(connectionId, token)),
      resetInboxConnection: () =>
        dispatch(ConversationActions.resetConnectionDetails()),
      token,
    };
  },
)(Chat);
