import React from "react";
import { translate } from "base/shared/Localization";
import { Flex, Text, Icon, Spacer } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";
import { Root } from "./styled-components";

const PayThroughBadiInfo = () => (
  <Root>
    <Spacer bottom={3} left={2} right={2} top={2}>
      <Flex direction={"column"} gutter={1}>
        <Icon
          alt={translate("payout.setup.tooltip.title")}
          iconMaxHeight={39}
          iconMaxWidth={36}
          src={getPublicAsset("icons/trusted_badi_payment.svg")}
        />
        <Text body={2}>{translate("payout.setup.tooltip.title")}</Text>
        <Text body={6}>{translate("payout.setup.tooltip.subtitle")}</Text>
      </Flex>
    </Spacer>
  </Root>
);

export default PayThroughBadiInfo;
