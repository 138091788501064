import React from "react";
import classNames from "classnames";

import "./SectionImageExt";

const SectionImage = ({ children, className }) => (
  <div className={"LandingSection__ImageWrapper"}>
    <div className={classNames("LandingSection__Image", className)}>
      <div className="LandingSection__Content">{children}</div>
    </div>
  </div>
);

SectionImage.defaultProps = {
  children: null,
  className: null,
};

export default SectionImage;
