import { browserHistory } from "react-router";

const FEEDBACK_SET_RECOMMENDATION = {
  accept: {
    title: "find_a_badi.recommendations.accept.title",
    description: "find_a_badi.recommendations.accept.description",
  },
  reject: {
    title: "find_a_badi.recommendations.reject.title",
    description: "find_a_badi.recommendations.reject.description",
  },
};

const redirectOverview = () => {
  browserHistory.push("/overview");
};

const checkUnsetRecommendations = (recommendations) =>
  recommendations.some((recommendation) => !recommendation.hidden);

export {
  redirectOverview,
  FEEDBACK_SET_RECOMMENDATION,
  checkUnsetRecommendations,
};
