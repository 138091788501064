import React from "react";
import { BADI_COLORS, Button, Flex } from "@badi/badi-components";

import LoadingSpinner from "components/Icons/LoadingSpinner";
import Image from "components/Image";
import { Wrapper } from "./styled-components";

const AuthButton = ({
  dataQa,
  onClick,
  loaded = true,
  iconSrc,
  children,
  iconAlt = "",
}) => {
  return (
    <Button
      dataQa={dataQa}
      fullWidth={true}
      onClick={onClick}
      type="button"
      variant="secondary"
    >
      {loaded ? (
        <Flex alignItems="center" as={Wrapper} gutter={2}>
          <Image alt={iconAlt} height="20px" src={iconSrc} />
          <span>{children}</span>
        </Flex>
      ) : (
        <LoadingSpinner color={BADI_COLORS.NEUTRAL.NEUTRAL_80} />
      )}
    </Button>
  );
};

export default AuthButton;
