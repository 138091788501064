import { getRecommendationsPath } from "app/Sections/recommendations/routes";
import { getViewersPath } from "app/Sections/viewers/routes";

export const PREMIUM_FEATURES = {
  RECOMMENDED_SEEKERS: "recommended_seekers",
  HIGHLIGHT_DEMAND: "highlight_relevant_demand",
  FREE_BOOSTING_1X: "1x_free_boosting",
  ROOM_VIEWERS: "room_viewers",
};

export const PREMIUM_FEATURES_PATHS = new Map([
  [PREMIUM_FEATURES.RECOMMENDED_SEEKERS, getRecommendationsPath],
  [PREMIUM_FEATURES.ROOM_VIEWERS, getViewersPath],
]);

export const PLAN_TYPES = {
  INDIVIDUAL: "individual",
  SUBSCRIPTION: "subscription",
  PAYMENT: "payment",
};
