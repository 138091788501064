import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";

import TermsAndConditions from "./terms-and-conditions";
import ConfirmTermsAndConditions from "./confirm-terms-and-conditions";

export const closeConfirmationModal = () => {
  modal.remove(MODAL_ID.CONFIRM_TERMS_AND_CONDITIONS);
};

export const closeTermsAndConditionsModal = (callback) => {
  modal.remove(MODAL_ID.TERMS_AND_CONDITIONS);
  if (callback) callback();
};

export const openConfirmationModal = (callback) => {
  modal.add(
    <ConfirmTermsAndConditions
      onCancel={closeConfirmationModal}
      onConfirm={() => {
        closeConfirmationModal();
        closeTermsAndConditionsModal(() => callback(false));
      }}
    />,
    {
      id: MODAL_ID.CONFIRM_TERMS_AND_CONDITIONS,
      // type: MODAL_TYPE.ONBOARDING,
      closable: false,
    },
  );
};

export const openTermsAndConditionsModal = (callback) => {
  modal.add(
    <TermsAndConditions
      onAccept={() => {
        closeTermsAndConditionsModal();
        callback(true);
      }}
      onReject={() => openConfirmationModal(callback)}
    />,
    {
      closeOnOutsideClick: false,
      id: MODAL_ID.TERMS_AND_CONDITIONS,
      type: MODAL_TYPE.ONBOARDING,
    },
  );
};
