import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const Root = styled.div`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ImageWrapper = styled.div`
  img {
    height: ${unitizedPx(5)};
  }
`;

export const NumberWrapper = styled.div`
  position: relative;
  width: ${unitizedPx(6)};
  height: ${unitizedPx(6)};
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
`;
