import React from "react";
import { Text, Flex } from "@badi/badi-components";
import GeoSuggest from "components/geo-suggest";
import { translate } from "base/shared/Localization";
import { SearchLocationWrapper } from "./styled-components";
import LocationSelectorPopover from "./location-selector-popover";

const LocationSelectorAutosuggest = ({
  handleLocationChange,
  initialValue = "",
  showPopover = false,
}) => (
  <Flex dataQa="search-bar-availability-location_selector" direction="column">
    <Text body="12-Medium">{translate("search.home_city_label")}</Text>

    <Flex alignItems="center" fullHeight={true} justify="flexStart">
      <SearchLocationWrapper basis="100%">
        <GeoSuggest
          data-qa="search-bar-availability-location_geosuggest"
          includeTypeLabels={true}
          initialValue={initialValue}
          nearby={true}
          onSuggestSelect={handleLocationChange}
          placeholder={translate("search.home_city_placeholder")}
          prefillableSearch={true}
          recent={true}
          types={["geocode"]}
        />
      </SearchLocationWrapper>
    </Flex>
    {showPopover && <LocationSelectorPopover />}
  </Flex>
);

export default LocationSelectorAutosuggest;
