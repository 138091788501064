import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";

import "./shareExt.css";

const copyHandler = () => {
  UserFeedback.success(
    translate("Link copied"),
    translate("Now you're ready to share it!"),
  );
};

const CopyUrl = ({ target }) => (
  <div className="Button__share">
    <CopyToClipboard onCopy={copyHandler} text={target}>
      <div className="Button__share_copyurl" />
    </CopyToClipboard>
  </div>
);

CopyUrl.defaultProps = {
  children: null,
};

export default CopyUrl;
