import React, { Component } from "react";
import { SEARCH_VIEW } from "datamodel/SearchFilters/static";
import ListViewIcon from "components/Icons/SearchListView";
import MapViewIcon from "components/Icons/SearchMapView";
import { ToggleButton, IconsWrapper, Root } from "./styled-components";

class ToggleListMapButton extends Component {
  state = { map: false };

  static getDerivedStateFromProps(props) {
    const { view } = props;
    if (view === SEARCH_VIEW.form) return null;
    return { map: view === SEARCH_VIEW.map };
  }

  handleClick = () => {
    const { onViewChange } = this.props;
    const { list, map } = SEARCH_VIEW;

    onViewChange(this.state.map ? list : map);
  };

  render() {
    const { map } = this.state;

    return (
      <Root onClick={this.handleClick}>
        <ToggleButton toRight={map} />
        <IconsWrapper data-qa="view-switch-button">
          <ListViewIcon selected={!map} />
          <MapViewIcon selected={map} />
        </IconsWrapper>
      </Root>
    );
  }
}

ToggleListMapButton.defaultProps = {
  view: SEARCH_VIEW.list,
};

export default ToggleListMapButton;
