import { getRelativePath } from "base/shared/Utils";

export const getListerMetricsPath = ({ roomId } = {}) => {
  let path = "/analytics/rooms";

  if (roomId) {
    path += `/${roomId}`;
  }

  return getRelativePath(path);
};
