import React from "react";

import { modal, MODAL_TYPE, MODAL_ID } from "containers/ReduxModal/emitter";
import VisitFeedback from "./visit-feedback-route";

export function closeVisitFeedbackModal() {
  modal.remove(MODAL_ID.VISIT_FEEDBACK);
}

export function openVisitFeedbackModal({ visitId }) {
  modal.add(
    <VisitFeedback onClose={closeVisitFeedbackModal} visitId={visitId} />,
    {
      id: MODAL_ID.VISIT_FEEDBACK,
      type: MODAL_TYPE.LATERAL,
    },
  );
}
