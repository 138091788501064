import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const RoomPicture = styled.div`
  width: ${unitizedPx(11)};
  height: ${unitizedPx(8)};
  flex-shrink: 0;

  > img {
    border-radius: 5px;
    width: 100%;
    height: 64px;
    object-fit: cover;
  }
`;

export const ChoiceWrapper = styled.div`
  width: 100%;

  ${mediaquery.xl`
    width: ${unitizedPx(60)};
  `}

  ${mediaquery.lg`
    width: ${unitizedPx(50)};
  `}

  ${mediaquery.md`
    width: ${unitizedPx(40)};
  `}
`;

export const ContentWrapper = styled.div`
  height: auto;

  ${mediaquery.md`
    min-height: ${unitizedPx(46)};
  `}
`;

export const TextWrapper = styled.div`
  word-break: break-word;
`;
