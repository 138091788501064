import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";

import BookingLeave from "./booking-leave";

export const closeBookingLeaveModal = () => {
  modal.remove(MODAL_ID.BOOKING_LEAVE);
};

export const openBookingLeaveModal = ({ onLeave }) => {
  modal.add(
    <BookingLeave
      onCancel={closeBookingLeaveModal}
      onLeave={() => {
        closeBookingLeaveModal();
        onLeave();
      }}
    />,
    {
      closeOnOutsideClick: false,
      id: MODAL_ID.BOOKING_LEAVE,
      type: MODAL_TYPE.DEFAULT,
    },
  );
};
