import React from "react";
import { useDispatch } from "react-redux";
import { getGoldUpgradePath } from "Sections/plans/routes";
import { setPremiumOrigin } from "datamodel/premium/actions";
import Recommendations from "./recommendations-mapping";

const RecommendationsRoute = ({ router, params, authtoken }) => {
  const { roomId } = params;
  const dispatch = useDispatch();

  const redirectToPremium = ({ feature }) => {
    dispatch(setPremiumOrigin(window.location.pathname));
    router.push(getGoldUpgradePath({ roomId, feature }));
  };

  return (
    <Recommendations
      redirectToPremium={redirectToPremium}
      roomId={Number(roomId)}
      token={authtoken}
    />
  );
};

export default RecommendationsRoute;
