import React from "react";
import classNames from "classnames";

import ChoiceItem from "./ChoiceItem";
import "./ChoiceExt.css";

class Choice extends React.PureComponent {
  selectItem = (e) => {
    const { multi, onChange, value } = this.props;
    const inputValue = e.currentTarget.value;
    const newValue = isNaN(inputValue) ? inputValue : +inputValue;

    if (!multi) {
      onChange(newValue);
      return;
    }

    const newValueIndex = value.findIndex((i) => i === newValue);
    const val = [...value];

    if (newValueIndex < 0) val.push(newValue);
    else val.splice(newValueIndex, 1);

    onChange(val);
  };

  render() {
    const { className, items, multi, name, value, dataQa } = this.props;

    return items?.length ? (
      <div className={classNames("Form__Choice--wrapper", className)}>
        {items.map((item) => (
          <ChoiceItem
            checked={multi ? value.includes(item.value) : value === item.value}
            dataQa={dataQa}
            item={item}
            key={item.value}
            name={name}
            onChange={this.selectItem}
          />
        ))}
      </div>
    ) : null;
  }
}

Choice.defaultProps = {
  className: null,
  multi: true,
  value: -1,
};

export default Choice;
