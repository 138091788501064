import { connect } from "react-redux";

import { getUserMode } from "datamodel/User/utils";
import AuthActions from "datamodel/Auth/actions";
import { AUTH_TOKEN_INVALID } from "datamodel/Auth/constants";
import UserActions from "datamodel/User/actions";
import FeaturesActions from "datamodel/Features/actions";
import { getPremiumCapabilities } from "datamodel/premium/actions";
import { resetReviewsAction } from "datamodel/reviews/actions";
import {
  fetchingFeaturesSelector,
  getPushNotifications,
} from "datamodel/Features/selectors";
import { getPushNotificationsSupportedSelector } from "datamodel/Device/selectors";

import NotificationActions from "datamodel/Notifications/actions";
import PaymentActions from "datamodel/Payment/actions";
import PayoutsActions from "datamodel/Payouts/actions";
import RequirementsActions from "datamodel/Requirements/actions";
import {
  getTermsAndConditions,
  isGdprAnsweredSelector,
  isLegalLoadedSelector,
  getGhost,
} from "datamodel/User/selectors";

import { getCountryAction } from "app/datamodel/CountryVat/actions";
import {
  getCountryVatCountryCode,
  getCountryVatFetching,
} from "app/datamodel/CountryVat/selectors";

import App from "./App";

const mapStateToProps = (state) => ({
  phrases: state.Localization.phrases,
  locale: state.Localization.locale,
  AuthModel: state.Auth,
  User: state.User,
  userMode: getUserMode(state.User.mode),
  socket: state.Socket.socket,
  socketStatus: state.Socket.status,
  socketError: state.Socket.error,
  termsAndConditions: getTermsAndConditions(state),
  arePushNotificationsEnabled: getPushNotifications(state),
  arePushNotificationsSupported: getPushNotificationsSupportedSelector(state),
  isGdprAnswered: isGdprAnsweredSelector(state),
  isLegalLoaded: isLegalLoadedSelector(state),
  fetchingFeatures: fetchingFeaturesSelector(state),
  countryCodeVat: getCountryVatCountryCode(state),
  fetchingCountryVat: getCountryVatFetching(state),
  isGhost: getGhost(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetMe: () => dispatch(UserActions.resetMe()),
  resetNotifications: () => dispatch(NotificationActions.resetNotifications()),
  resetPayouts: () => dispatch(PayoutsActions.resetPayouts()),
  resetPayment: () => dispatch(PaymentActions.resetPayment()),
  resetRequirements: () => dispatch(RequirementsActions.resetRequirements()),
  getCountryVat: (token) => dispatch(getCountryAction(token)),
  getFeatures: (params) => dispatch(FeaturesActions.getFeatures(params)),
  getMe: (token) => dispatch(UserActions.getMe(token)),
  getLegal: (token) => dispatch(UserActions.getLegal(token)),
  getOnboarding: (token) => dispatch(UserActions.getOnboarding(token)),
  setInvalidToken: () => dispatch(AuthActions.setError(AUTH_TOKEN_INVALID)),
  logout: () => dispatch(AuthActions.logout()),
  dispatch: () => dispatch,
  setRequirements: (requirements) =>
    dispatch(RequirementsActions.setRequirements(requirements)),
  setGDPR: ({ visible }) => dispatch(RequirementsActions.setGDPR(visible)),
  setCountryVat: ({ visible }) =>
    dispatch(RequirementsActions.setCountryVat(visible)),
  getPremiumCapabilities: () => dispatch(getPremiumCapabilities()),
  resetReviews: () => dispatch(resetReviewsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
