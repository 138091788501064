import {
  AUTH_REFRESH_TOKEN_EVENT,
  AUTH_TOKEN_INVALID,
} from "datamodel/Auth/constants";

import { EE } from "./Emitter";

const AUTH_TOKEN_INVALID_SERVER = "invalid_token_intercept";

const ErrorHandler = (err) => {
  const error = err.error || err;
  const parsedError = { code: "unknown", message: "" };

  if (error === AUTH_TOKEN_INVALID) {
    return { code: AUTH_TOKEN_INVALID_SERVER };
  }

  if (error) {
    parsedError.code = error.code;

    switch (error.code) {
      case "coupon_non_available":
        parsedError.message = "Coupon with given code is not available";
        break;
      case "facebook_login_exists":
        parsedError.message = "Whoops, your facebook account already exists";
        break;
      case "google_login_exists":
        parsedError.message = "Whoops, your google account already exists";
        break;
      case "invalid_context":
        parsedError.message = "Whoops, you need to be logged-in to do that!";
        break;
      case "invalid_operation":
        parsedError.message =
          "Whoops, you don’t have access there. Please, contact support.";
        break;
      case AUTH_TOKEN_INVALID:
        EE.emit(AUTH_REFRESH_TOKEN_EVENT);
        break;
      case "invalid_client":
      case "invalid_grant":
      case "invalid_request":
      case "invalid_redirect_uri":
      case "unauthorized_client":
      case "access_denied":
      case "invalid_scope":
      case "server_error":
      case "temporarily_unavailable":
      case "unsupported_grant_type":
        parsedError.message =
          "Whoops, something went wrong with you authentication. Please try again, and if the error persists contact support.";
        break;
      case "resource_not_found":
        parsedError.message =
          "Whoops, couldn’t find that resource. Please, contact support.";
        break;
      case "forbidden_operation":
        parsedError.message =
          "Whoops, you are not allowed to do that! Please, contact support.";
        break;
      case "invalid_body":
        parsedError.message = "";
        break;
      case "invalid_params":
        parsedError.message =
          "Whoops, some mandatory fields are missing. Try again or contact support.";
        break;
      case "invalid_record":
        parsedError.message =
          "Whoops, there was an error with some of the fields submitted. Try later or contact support.";
        break;
      case "invalid_facebook_token":
        parsedError.message =
          "Whoops, there was an unknown error with facebook. Try later or contact support.";
        break;
      case "invalid_sms_request":
        parsedError.message =
          "Whoops, this phone number doesn’t seem to exist. Check you’ve entered your number accurately to continue.";
        break;
      case "concurrent_verification":
        parsedError.message =
          "You already requested an SMS verification. If you don’t receive a message within 5 minutes, try again.";
        break;
      case "unknown_sms_exception":
        parsedError.message =
          "Whoops, there was an unknown error with the sms verification service. Try later or contact support.";
        break;
      case "no_match_login_type":
        parsedError.message = "error.no_match_login_type";
        break;
      case "email_not_found":
        parsedError.message =
          "Whoops, couldn’t find any users with that e-mail";
        break;
      case "email_exists":
        parsedError.message = "Whoops, your email already exists";
        break;
      case "incorrect_password":
        parsedError.message = "Whoops, the password is incorrect.";
        break;
      case "unknown_exception":
        parsedError.message =
          "Whoops, there was an unknown error. Try later or contact support.";
        break;
      case "room_is_frozen":
        parsedError.message = "Whoops, this room can’t be updated.";
        break;
      case "request_already_answered":
        parsedError.message = "Whoops, this request is already answered.";
        break;
      case "request_non_archivable":
        parsedError.message = "Whoops, this request is no archivable.";
        break;
      case "user_with_no_payment_data":
        parsedError.message = "Whoops, the payment data is missing.";
        break;
      case "user_with_no_payout_data":
        parsedError.message = "Whoops, the payout data is missing.";
        break;
      case "resource_already_confirmed_by_another_user":
        parsedError.message =
          "It looks like these details have already been used to verify another account. Try again with different details to continue.";
        break;
      case "delete_account_booking_proposal_pending":
        parsedError.message = "error.delete_account_booking_proposal_pending";
        break;
      case "delete_account_booking_unfinished":
        parsedError.message = "error.delete_account_booking_unfinished";
        break;

      // Payouts error messages

      case "booking_proposal_in_progress":
        parsedError.message =
          "Whoops, It’s not possible to send a booking request while you have one waiting for answer.";
        break;
      case "invalid_postal_code":
        parsedError.message =
          "Whoops, the postal code and your account country do not match. Please double check them.";
        break;
      case "invalid_account_number":
        parsedError.message =
          "Whoops, there was an error with your bank account number. Please double check it, and if the error persists contact support.";
        break;
      case "invalid_account_country":
        parsedError.message =
          "Whoops, your bank account does not belong to the country you indicated. Please double check your account number.";
        break;

      // Payment error messages

      case "stripe_invalid_expiry_month":
        parsedError.message = "error.payment.stripe_invalid_expiry_month";
        break;
      case "stripe_invalid_expiry_year":
        parsedError.message = "error.payment.stripe_incorrect_expiry_year";
        break;
      case "stripe_incorrect_cvc":
      case "stripe_invalid_cvc":
        parsedError.message = "error.payment.stripe_incorrect_cvc";
        break;
      case "stripe_invalid_swipe_data":
        parsedError.message = "error.payment.stripe_invalid_swipe_data";
        break;
      case "stripe_invalid_number":
      case "stripe_incorrect_number":
        parsedError.message = "error.payment.stripe_incorrect_number";
        break;
      case "stripe_expired_card":
        parsedError.message = "error.payment.stripe_expired_card";
        break;
      case "stripe_card_declined":
        parsedError.message = "error.payment.stripe_card_declined";
        break;
      case "stripe_incorrect_zip":
        parsedError.message = "error.payment.stripe_incorrect_zip";
        break;
      case "stripe_missing":
        parsedError.message = "error.payment.stripe_missing";
        break;
      case "stripe_processing_error":
        parsedError.message = "error.payment.stripe_processing_error";
        break;
      case "stripe_do_not_honor":
        parsedError.message = "error.payment.stripe_do_not_honor";
        break;
      case "stripe_fraudulent":
        parsedError.message = "error.payment.stripe_fraudulent";
        break;
      case "stripe_insufficient_funds":
        parsedError.message = "error.payment.stripe_insufficient_funds";
        break;
      case "stripe_generic_decline":
        parsedError.message = "error.payment.stripe_generic_decline";
        break;
      case "stripe_incorrect_expiry_year":
        parsedError.message = "error.payment.stripe_incorrect_expiry_year";
        break;
      case "stripe_invalid_pin":
      case "stripe_incorrect_pin":
        parsedError.message = "error.payment.stripe_invalid_pin";
        break;
      case "stripe_transaction_not_allowed":
        parsedError.message = "error.payment.stripe_transaction_not_allowed";
        break;
      case "stripe_invalid_routing":
        parsedError.message = "error.payment.stripe_invalid_routing";
        break;
      case "stripe_routing_number_invalid":
        parsedError.message = "error.payment.stripe_routing_number_invalid";
        break;

      // Booking error messages

      case "booking_proposal_creation_forbidden":
        parsedError.message =
          "Whoops, it looks like this user is no longer available";
        break;
      case "booking_proposal_answer_forbidden":
        parsedError.message =
          "Whoops, it’s not possible to answer your own booking request";
        break;
      case "booking_proposal_already_answered":
        parsedError.message =
          "Whoops, you’ve already responded to this booking request";
        break;
      default:
        parsedError.message =
          "Something went wrong, try again or contact support.";
    }
  }
  return parsedError;
};

function BadiError(error) {
  const { code, message } = ErrorHandler(error);
  this.code = code;
  this.message = message;
}

BadiError.prototype = Object.create(Error.prototype);

export { ErrorHandler, BadiError };
