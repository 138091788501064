import styled from "styled-components";
import { mediaquery, unitizedPx, BADI_COLORS } from "@badi/badi-components";

export const MainHomeLayer = styled.div`
  max-width: ${unitizedPx(220)};
  margin: auto;
  max-height: 100%;
  padding: ${unitizedPx(2)};
  position: relative;
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  ${mediaquery.md`  
    padding: ${unitizedPx(3)} ${unitizedPx(10)};
  `}
`;

export const HomeTitle = styled.h2`
  font-size: ${unitizedPx(3)};
  margin: 0px;
  color: ${({ color }) => color || BADI_COLORS.NEUTRAL.NEUTRAL_80};
  ${mediaquery.md`
    font-size: ${unitizedPx(4)};
  `};
`;
