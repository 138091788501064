import React, { useState } from "react";
import { getCookie, setCookie } from "base/shared/CookiesHandler";
import { COOKIES } from "base/shared/StaticData";
import { isBrowser } from "base/shared/Utils";
import dayjs from "dayjs";

import { openHowToNotificationsModal } from "./how-to-notifications-modal";

import InfoBarUi from "../info-bar-ui";

const NOTIFICATION_GRANTED = "granted";

const hasNotificationsGranted = () =>
  isBrowser() &&
  typeof Notification !== "undefined" &&
  Notification.permission === NOTIFICATION_GRANTED;

const PushNotificationsBar = () => {
  const [cookie, setCookieValue] = useState(
    getCookie(COOKIES.NOTIFICATION_BAR),
  );

  const handleCloseNotification = () => {
    const expires = dayjs().add(1, "month").toDate();

    setCookie(COOKIES.NOTIFICATION_BAR, "true", expires);
    setCookieValue(true);
  };

  const handleClick = () => {
    openHowToNotificationsModal();
  };

  if (cookie || !isBrowser() || hasNotificationsGranted()) return null;

  return (
    <InfoBarUi
      link={"notifications.turnon_combar_link"}
      onClick={handleClick}
      onCloseNotification={handleCloseNotification}
      text={"notifications.turnon_combar"}
    />
  );
};

export default PushNotificationsBar;
