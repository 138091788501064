import React from "react";
import Image from "components/Image/Image";
import {
  Text,
  Heading,
  Flex,
  useMatchMedia,
  Spacer,
} from "@badi/badi-components";
import { Root, Description } from "../styled-components";
import { QUERIES } from "../../constants";

const FinalStep = ({ description, illustration, title }) => {
  const [isDesktop] = useMatchMedia(QUERIES);

  return (
    <Flex
      alignItems="center"
      as={Root}
      direction="column"
      fullHeight={true}
      justify="center"
    >
      <Image
        alt={title}
        data-qa="new-review-final-step-illustration"
        src={illustration}
      />
      <Spacer bottom={1}>
        <Heading
          data-qa="new-review-final-step-title"
          level={isDesktop ? "28-Extrabold" : "20-Bold"}
        >
          {title}
        </Heading>
      </Spacer>
      <Text
        align="center"
        as={Description}
        body="16-Regular"
        dataQa="new-review-final-step-description"
      >
        {description}
      </Text>
    </Flex>
  );
};

export default FinalStep;
