import { colors } from "./constants/colors";
import { UNIT } from "./constants";
import { BREAKPOINTS } from "./constants/breakpoints";
import mediaqueries from "./mediaqueries";

export const colorsList = (c = colors) => Object.values(c);

export const colorsNamesList = (c = colors) => Object.keys(c);

const parseHexComponents = (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`;

export const hexToRgb = (hex, alpha) => {
  if (!hex) return "";

  const rgb = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, parseHexComponents)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

  for (let i = 0; i < 3; i += 1) {
    const colorComponent = rgb[i];
    if (typeof colorComponent !== "number" || Number.isNaN(colorComponent))
      return "";
  }

  const parsedRGB = rgb.join(",");

  return alpha ? `rgba(${parsedRGB}, ${alpha})` : `rgb(${parsedRGB})`;
};

export const filterColorsByName = (names) =>
  names.reduce((_colors, name) => {
    const filteredColors = _colors;

    if (Object.prototype.hasOwnProperty.call(colors, name)) {
      filteredColors[name] = colors[name];
    }

    return filteredColors;
  }, {});

export const colorNamesWithDark = (c = colors) =>
  Object.keys(c)
    .filter((colorName) => colorName.endsWith("Dark"))
    .map((colorName) => colorName.replace("Dark", ""));

export const getDarkColorFromHex = (hex, c = colors) => {
  const colorName = Object.keys(c).find((key) => c[key] === hex);

  return colors[`${colorName}Dark`];
};

export const range = (num) => {
  if (typeof num !== "number" || num < 0) return [];

  return [...Array(num).keys()].map((i) => i + 1);
};

export const getBadge = (badgesConfig, badge) => {
  if (!badge) return {};
  return badgesConfig[badge];
};
export const unitizedPx = (size = 0) => `${UNIT * size}px`;

export const toKebabCase = (string) =>
  string
    ? string
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/\s+/g, "-")
        .toLowerCase()
    : null;

/**
 * Generate a valid structure for responsive configuration for a component
 * @param {object} props props received from the component
 * @param {array} baseProps list of props to be validated
 *
 * @returns a structured object with the props for each media query
 */
export const generateResponsiveProps = (props, baseProps) => {
  // from the breakpoints registered check which props exists
  const shapedPropsWithMq = Object.keys(BREAKPOINTS).reduce(
    (responsiveProps, mqName) => {
      const propsForMq = props[mqName];
      if (!propsForMq && typeof propsForMq !== "object") return responsiveProps;

      // for the props that exists, prepare them with the correct shape
      const shapedProps = baseProps.reduce(
        (propList, prop) => ({
          ...propList,
          [prop]: propsForMq[prop],
        }),
        {},
      );

      return {
        ...responsiveProps,
        [mqName]: shapedProps,
      };
    },
    {},
  );

  return shapedPropsWithMq;
};

/**
 * Call the styles factory with the correct props for each media query
 * @param {function} stylesGenerator function that generates the styles
 *
 * @returns {array} array of styles to be applied for the registered media queries
 */
export const generateResponsiveStyles = (stylesGenerator) => (props) =>
  Object.keys(mediaqueries).reduce((rules, mq) => {
    if (!props[mq]) return rules;

    const styles = mediaqueries[mq]`
    ${stylesGenerator(props[mq])}
  `;
    return [...rules, styles];
  }, []);
