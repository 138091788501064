import React, { useState } from "react";

import { getRelativePath } from "base/shared/Utils";
import { getMyListingsPath } from "../routes";
import EditRoom from "./edit-room-mapping";

const EditRoomRoute = ({ route, router, params = {}, location = {} }) => {
  const { publish = false } = location.query;
  const [nextPathname, setNextPath] = useState(getRelativePath("/overview"));

  const handleRoomPublished = () => {
    router.push(getMyListingsPath());
  };

  const registerLeavingHandler = (shouldLeave) => {
    // https://github.com/ReactTraining/react-router/blob/v3/docs/guides/ConfirmingNavigation.md
    router.setRouteLeaveHook(route, (nextPath) => {
      setNextPath(nextPath.pathname);

      if (shouldLeave()) return null;

      return false;
    });
  };

  const handleLeaveFlow = () => {
    router.push(nextPathname);
  };

  const handleSuccessfulModeration = () => router.push(getMyListingsPath());

  const handleNotOwnedRoom = () => {
    router.push(getRelativePath("/404"));
  };

  return (
    <EditRoom
      forcePublish={!!publish}
      onLeaveFlow={handleLeaveFlow}
      onNotOwnedRoom={handleNotOwnedRoom}
      onRoomPublished={handleRoomPublished}
      onSuccessfulModeration={handleSuccessfulModeration}
      registerOnLeaving={registerLeavingHandler}
      roomId={params.roomId}
    />
  );
};

export default EditRoomRoute;
