import React from "react";
import { Heading, Spacer, Flex } from "@badi/badi-components";
import { Col, Row } from "reactstrap";
import { translate } from "base/shared/Localization";

import Help from "components/Icons/Help";

const RecommendationsHeader = ({ children = null, onHelpClick = () => {} }) => {
  return (
    <Row>
      <Col lg={{ offset: 1, size: 10 }} xs={{ size: 12, offset: 0, order: 1 }}>
        <Spacer bottom={2} top={4}>
          {children}
          <Flex justify="spaceBetween">
            <Heading data-qa="recommendations-header-title" level={2}>
              {translate("recommendations.main.title")}
            </Heading>
            <Help height={24} onClick={onHelpClick} width={24} />
          </Flex>
        </Spacer>
      </Col>
    </Row>
  );
};

export default RecommendationsHeader;
