import React from "react";

import { Flex, Heading, Text, Spacer, Button } from "@badi/badi-components";
import Illustration from "Sections/booking/success-illustration";
import { translate } from "base/shared/Localization";

import { Align, FinishSetupWrapper } from "./styled-components";

const FinishSetup = ({ title, description, buttonLabel, handleFinishFlow }) => {
  return (
    <FinishSetupWrapper>
      <Flex direction="column" fullHeight={true} justify="center">
        <Spacer bottom={5}>
          <Illustration />
        </Spacer>
        <Spacer bottom={1}>
          <Heading>
            <Align align="center">{translate(title)}</Align>
          </Heading>
        </Spacer>
        <Align align="center">
          <Spacer bottom={4}>
            <Text>{translate(description)}</Text>
          </Spacer>
          <Flex justify="center">
            <Button onClick={handleFinishFlow} variant="primary">
              {translate(buttonLabel)}
            </Button>
          </Flex>
        </Align>
      </Flex>
    </FinishSetupWrapper>
  );
};

export default FinishSetup;
