import React from "react";

import { translate } from "base/shared/Localization";

import {
  Flex,
  Heading,
  Text,
  Spacer,
  LinkButton,
  BUTTON_TYPES,
} from "@badi/badi-components";
import { getUrl } from "./utils";
import { Root } from "./styled-components";
import Illustration from "../success-illustration";

const CreatedStep = ({ connectionId, userRole, otherUserName }) => {
  const url = getUrl({ connectionId });
  const description =
    userRole === "lister"
      ? "booking.flow.lister.sent_dialog_subtitle"
      : "booking.flow.seeker.sent_dialog_subtitle";

  return (
    <Flex
      as={Root}
      direction="column"
      fullHeight={true}
      gutter={1}
      justify="center"
    >
      <Spacer bottom={4}>
        <Illustration />
      </Spacer>
      <Heading align="center" data-qa="created-step-heading">
        {translate("booking.new.created.heading")}
      </Heading>
      <Spacer bottom={3} top={2}>
        <Text align="center">
          {translate(description, {
            otherUserName,
          })}
        </Text>
      </Spacer>
      <Spacer bottom={3} />
      <Flex justify="center">
        <LinkButton href={url} variant={BUTTON_TYPES.PRIMARY}>
          {translate("booking.new.created.cta")}
        </LinkButton>
      </Flex>
      <Spacer bottom={5} />
    </Flex>
  );
};

export default CreatedStep;
