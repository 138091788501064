import { createSelector } from "reselect";

const getHome = (state) => state.Home;

const getMainLocation = (state) => getHome(state).mainCity;

export const getMainCity = (state) => {
  const mainLocation = getMainLocation(state);
  return mainLocation ? mainLocation.city : "";
};

export const getMainCountry = (state) => {
  const mainLocation = getMainLocation(state);
  return mainLocation ? mainLocation.country : "";
};
