import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "PREMIUM_PLANS_REQUEST",
  "PREMIUM_PLANS_SUCCESS",
  "PREMIUM_PLANS_ERROR",
  "PREMIUM_CAPABILITIES_REQUEST",
  "PREMIUM_CAPABILITIES_SUCCESS",
  "PREMIUM_CAPABILITIES_ERROR",
  "PREMIUM_ROOM_CAPABILITIES_REQUEST",
  "PREMIUM_ROOM_CAPABILITIES_SUCCESS",
  "PREMIUM_ROOM_CAPABILITIES_ERROR",
  "PREMIUM_ORIGIN_SET",
]);
