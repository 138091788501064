import { objectToQueryParams } from "base/shared/Utils";
import { DEFAULT_MARKER_ICON, GOOGLE_MAPS_URL } from "./constants";

export const parseMarker = ({
  icon = DEFAULT_MARKER_ICON,
  latitude,
  longitude,
}) => {
  if (!latitude || !longitude) return "";
  return `icon:${icon}|${latitude},${longitude}`;
};

export const getUrlPath = (paramsObject) => {
  const params = objectToQueryParams(paramsObject);
  const unsignedUrl = new URL(`${GOOGLE_MAPS_URL}?${params}`);
  return `${unsignedUrl.pathname}${unsignedUrl.search}`;
};
