import React from "react";

import { modal, MODAL_TYPE, MODAL_ID } from "containers/ReduxModal/emitter";
import VisitOverview from "./visit-overview-mapping";

export function closeVisitOverviewModal() {
  modal.remove(MODAL_ID.VIEW_VISIT_PROPOSAL);
}

export function openVisitOverviewModal({ visitId, status }) {
  modal.add(
    <VisitOverview
      onClose={closeVisitOverviewModal}
      status={status}
      visitId={visitId}
    />,
    {
      id: MODAL_ID.VIEW_VISIT_PROPOSAL,
      type: MODAL_TYPE.LATERAL,
    },
  );
}
