const defaultAnimation = (ref) => [
  {
    opacity: 0,
    yPercent: 10,
  },
  {
    opacity: 1,
    yPercent: 0,
    ease: "none",
    duration: 0.4,
    scrollTrigger: {
      trigger: ref,
      start: "top 97%",
    },
  },
];

const scaleDownAnimation = () => [
  {
    opacity: 0,
    scale: 1.05,
  },
  {
    opacity: 1,
    ease: "none",
    scale: 1,
    duration: 0.8,
  },
];

const config = {
  default: defaultAnimation,
  scaleDown: scaleDownAnimation,
};

export const getAnimation = (type, ref) => config[type](ref);
