import React from "react";
import { Flex, Heading, Text, Spacer, LinkButton } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getConversationPath } from "Sections/chat/routes";

import { Align, Root } from "./styled-components";
import Illustration from "../success-illustration";

const STATUS_ERROR = 1;

const AcceptedStep = ({
  connectionId = null,
  message = "",
  status = null,
  seeker = "",
  url = "",
  description = "booking.accepted.created.description",
  title = "booking.accepted.created.heading",
  cta = "booking.accepted.created.primary_button",
}) => {
  const seekerName = seeker ? { seeker } : undefined;
  const getDescription = () =>
    status === STATUS_ERROR
      ? translate(message)
      : translate(description, seekerName);

  return (
    <Root>
      <Flex direction="column" fullHeight={true} justify="center">
        <Spacer bottom={5}>
          <Illustration />
        </Spacer>
        <Spacer bottom={1}>
          <Heading>
            <Align align="center" data-qa="accepted-step-heading">
              {translate(title)}
            </Align>
          </Heading>
        </Spacer>
        <Align align="center">
          <Spacer bottom={4} data-qa="accepted-step-description">
            <Text>{getDescription()}</Text>
          </Spacer>
          <Flex justify="center">
            <LinkButton
              data-qa="accepted-step-link-button"
              href={url || getConversationPath({ connectionId })}
              variant="primary"
            >
              {translate(cta)}
            </LinkButton>
          </Flex>
        </Align>
      </Flex>
    </Root>
  );
};

export default AcceptedStep;
