import { addValidationRule } from "formsy-react";
import moment from "moment";

export const isCompleteAddress = (values, value, stateComponentValues = []) =>
  stateComponentValues.every((stateComponentValue) => !!stateComponentValue);

export const isConfirmedAddress = (values, value) => {
  if (typeof value !== "object" || !value) {
    return false;
  }

  return value.isConfirmed;
};

export const isPreciseAddress = (values, value) => {
  if (typeof value !== "object" || !value) {
    return false;
  }
  return value.isPreciseAddress && !!value.meta && !!value.meta.streetNumber;
};

export const nonEmptyAddress = (values, value) => {
  if (typeof value !== "object" || !value) {
    return false;
  }

  return !!value.address;
};

export const areUploaded = (values, value) => {
  if (!Array.isArray(value)) {
    return false;
  }

  return value.every((picture) => picture.uploadStatus === "success");
};

export const isMoment = (values, value) => moment.isMoment(value);

export const isMomentOrNull = (values, value) =>
  value === null || moment.isMoment(value);

export const isBadisList = (values, value) =>
  typeof value === "undefined" || Array.isArray(value);

export const isPositiveInt = (values, value) => /^[0-9]\d*$/.test(value);

export const maxValue = (values, value, max) => value <= max;

export const minValue = (values, value, min) => value >= min;

export const isBankAccountNumberValid = (values, value) => {
  if (!value) return false;

  const bankAccountNumber = value.replace(/\W/g, "");

  return /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[a-zA-Z0-9]{7}([a-zA-Z0-9]?){0,16}/g.test(
    bankAccountNumber,
  );
};

export const areDigitsEqualToLimit = (values, value, limit) =>
  value !== undefined ? value.length === limit : false;

export const confirmNewPassword = (values, value) =>
  !!values && value === values.newPassword;

export const isValidMoment = (values, value) =>
  moment.isMoment(value) && value.isValid();

export const isSomeValueSelected = (values, value) => value.length > 0;

export const requiredOnWork = (values, value) =>
  (value && values.occupation !== 1) || values.occupation === 1;

export const requiredOnStudy = (values, value) =>
  (value && values.occupation !== 2) || values.occupation === 2;

export const isNotCountryPlaceholder = (values, value) => value !== "DEFAULT";

addValidationRule("requiredOnWork", requiredOnWork);
addValidationRule("requiredOnStudy", requiredOnStudy);
addValidationRule("isConfirmedAddress", isConfirmedAddress);
addValidationRule("isPreciseAddress", isPreciseAddress);
addValidationRule("nonEmptyAddress", nonEmptyAddress);
addValidationRule("areUploaded", areUploaded);
addValidationRule("isMoment", isMoment);
addValidationRule("isMomentOrNull", isMomentOrNull);
addValidationRule("isBadisList", isBadisList);
addValidationRule("isPositiveInt", isPositiveInt);
addValidationRule("maxValue", maxValue);
addValidationRule("minValue", minValue);
addValidationRule("isBankAccountNumberValid", isBankAccountNumberValid);
addValidationRule("confirmNewPassword", confirmNewPassword);
addValidationRule("isValidMoment", isValidMoment);
addValidationRule("isSomeValueSelected", isSomeValueSelected);
addValidationRule("areDigitsEqualToLimit", areDigitsEqualToLimit);
addValidationRule("isCompleteAddress", isCompleteAddress);
addValidationRule("isNotCountryPlaceholder", isNotCountryPlaceholder);
