import React, { useState } from "react";
import { getCookie, setCookie } from "base/shared/CookiesHandler";
import dayjs from "dayjs";
import { COOKIES } from "base/shared/StaticData";

import InfoBarUi from "../info-bar-ui";

const CompleteProfileBar = ({ onClick }) => {
  const [cookie, setCookieValue] = useState(
    getCookie(COOKIES.COMPLETE_PROFILE_BAR),
  );

  const handleCloseNotification = () => {
    const expires = dayjs().add(1, "week").toDate();

    setCookie(COOKIES.COMPLETE_PROFILE_BAR, "true", expires);
    setCookieValue(true);
  };

  if (cookie) return null;

  return (
    <InfoBarUi
      link="info_bar.complete_profile.link"
      onClick={onClick}
      onCloseNotification={handleCloseNotification}
      text="info_bar.complete_profile.text"
    />
  );
};

export default CompleteProfileBar;
