import React, { useState } from "react";

import { translate } from "base/shared/Localization";
import { FilterModal } from "@badi/badi-components";
import qs from "qs";

import { getImageUrl } from "components/Image/utils";
import { FILTERS, FILTERS_ID } from "../../static";

import AdvancedFilter from "./advanced-filter-mapping";
import AdvancedFilterForm from "./advanced-filter-form";
import { FilterButton } from "@badi/badi-components";
import { MarketplaceSegmentsFilterButton } from "../../marketplace-segments/";

import { getFormWidth } from "./utils";

import "./AdvancedFiltersExt";

const AdvancedFilters = ({ filters }) => {
  const [filter, setFilter] = useState(null);

  const handleSelectFilter = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const handleCloseFilter = () => {
    setFilter(null);
  };

  const handleAppliedFilter = () => {
    handleCloseFilter();
  };

  const modalWidth = getFormWidth(filter);

  return (
    <>
      <div className="AdvancedFilters">
        {filters.map(({ id, icon, title, ...rest }) => {
          const props = {
            icon: getImageUrl(true, "", icon),
            id,
            key: id,
            onFilterClick: handleSelectFilter,
            title: translate(title),
            trackResetFilter: () => {},
            ...rest,
          };
          // I could not figure out how to make <AdvancedFilter /> work as required, so for the marketplace segment filter
          // I implemented it using a different component.
          return id == FILTERS_ID.marketplace_segments ? (
            <MarketplaceSegmentsFilterButton
              component={FilterButton}
              {...props}
            />
          ) : (
            <AdvancedFilter {...props} />
          );
        })}
      </div>
      {!!filter && (
        <FilterModal
          filterId={filter}
          marginX={24}
          onClose={handleCloseFilter}
          width={modalWidth}
        >
          <AdvancedFilterForm
            filter={filter}
            onFilterApplied={handleAppliedFilter}
          />
        </FilterModal>
      )}
    </>
  );
};

export default AdvancedFilters;
