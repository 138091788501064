import createReducer from "base/shared/createReducer";
import {
  getCurrencyCodeByCountryCode,
  convertAffirmationToBool,
} from "base/shared/Utils";

import Types from "./types";
import { ModerationResultModel, RoomListModel } from "./models";
import { parseAPIRoom, unparseBenefits, parseFlatmatesToApi } from "./utils";

function resetRoomList() {
  return { ...RoomListModel };
}

function setInitialStep(state, action) {
  const initialStep = !isNaN(action.step) ? Number(action.step) : 1;
  return { ...state, initialStep, title: action.title };
}

function setRoomFlatmate(state, action) {
  const parsedFlatmate = parseFlatmatesToApi(action.flatmate);

  return {
    ...state,
    flatmate: parsedFlatmate,
    publish: action.publish,
  };
}

function setRoomId(state, action) {
  if (state.id !== action.id) return { ...state, id: action.id };
  return state;
}

function setRoomLocation(state, action) {
  const { location } = action;
  const currency = getCurrencyCodeByCountryCode(location.countryCode);

  const room =
    currency !== state.room.currency
      ? {
          ...state.room,
          currency,
          deposit: "",
          price: "",
        }
      : state.room;

  return { ...state, location: action.location, room, publish: action.publish };
}

function setRoomPhotos(state, action) {
  return { ...state, photos: action.photos, publish: action.publish };
}

function setRoomProperty(state, action) {
  const { bedIncluded, bedType } = action.property;
  return {
    ...state,
    property: {
      ...state.property,
      ...action.property,
      bedType: convertAffirmationToBool(bedIncluded) ? bedType : null,
    },
    publish: action.publish,
  };
}

function setRoomVisitPreferences(state, action) {
  const { publish, visitPreferences } = action;
  return {
    ...state,
    visitPreferences,
    publish,
  };
}

function setRoomRoom(state, action) {
  const room = {
    ...action.room,
    benefits: unparseBenefits(action.room.benefits),
  };
  return { ...state, room, publish: action.publish };
}

function setTotalSteps(state, action) {
  return { ...state, totalSteps: action.totalSteps };
}

function roomSuccess(state, action) {
  const { room } = action.result;
  const parsedRoom = parseAPIRoom(
    {
      ...room,
      place_type: state.property?.placeType || room?.place_type,
    },
    !room,
  );

  return { ...state, ...parsedRoom, fetching: false, fetched: true };
}

function roomError(state, action) {
  return { ...state, fetching: false, error: action.error, fetched: false };
}

function roomRequest(state) {
  return { ...state, error: null, fetching: true, fetched: false };
}

function countryTemplateRequest(state) {
  return { ...state, fetching: true };
}

function countryTemplateError(state, action) {
  const { error } = action;
  return { ...state, fetching: false, error };
}

function countryTemplateSuccess(state, { result, payload }) {
  const { countryCode } = payload;
  const { size_unit: sizeUnit } = result[countryCode] || result.DEFAULT;

  return {
    ...state,
    fetching: false,
    error: null,
    sizeUnit: {
      value: sizeUnit.value,
      label: sizeUnit.display_text,
    },
  };
}

function roomModerationResultRequest(state) {
  return { ...state, fetching: true };
}

function roomModerationResultError(state, action) {
  const { error } = action;
  return { ...state, fetching: false, error };
}

function roomModerationResultSuccess(state, action) {
  const { result: moderationResult } = action;
  return {
    ...state,
    moderationResult,
    fetching: false,
    error: null,
  };
}

export function setRoomModerationResult(state, action) {
  const { data } = action.payload.moderationResult;
  const { roomId } = action.payload;

  if (state.id !== roomId) return state;
  const moderationResult = new ModerationResultModel(data).toJSON();

  return {
    ...state,
    moderationResult,
  };
}

const actionHandlers = {
  [Types.LISTROOM_RESET]: resetRoomList,
  [Types.LISTROOM_INITIALSTEP]: setInitialStep,
  [Types.LISTROOM_FLATMATESTEP]: setRoomFlatmate,
  [Types.LISTROOM_ID]: setRoomId,
  [Types.LISTROOM_LOCATIONSTEP]: setRoomLocation,
  [Types.LISTROOM_PROPERTYSTEP]: setRoomProperty,
  [Types.LISTROOM_PHOTOSSTEP]: setRoomPhotos,
  [Types.LISTROOM_ROOMSTEP]: setRoomRoom,
  [Types.LISTROOM_VISITSTEP]: setRoomVisitPreferences,
  [Types.LISTROOM_TOTALSTEPS]: setTotalSteps,
  [Types.LISTROOM_REQUEST]: roomRequest,
  [Types.LISTROOM_ERROR]: roomError,
  [Types.LISTROOM_SUCCESS]: roomSuccess,
  [Types.DRAFTFETCH_REQUEST]: roomRequest,
  [Types.DRAFTFETCH_ERROR]: roomError,
  [Types.DRAFTFETCH_SUCCESS]: roomSuccess,
  [Types.FETCH_DRAFT_BY_ID_REQUEST]: roomRequest,
  [Types.FETCH_DRAFT_BY_ID_ERROR]: roomError,
  [Types.FETCH_DRAFT_BY_ID_SUCCESS]: roomSuccess,
  [Types.ROOM_TEMPLATE_REQUEST]: countryTemplateRequest,
  [Types.ROOM_TEMPLATE_ERROR]: countryTemplateError,
  [Types.ROOM_TEMPLATE_SUCCESS]: countryTemplateSuccess,
  [Types.ROOM_MODERATION_RESULT_REQUEST]: roomModerationResultRequest,
  [Types.ROOM_MODERATION_RESULT_ERROR]: roomModerationResultError,
  [Types.ROOM_MODERATION_RESULT_SUCCESS]: roomModerationResultSuccess,
  [Types.ROOM_MODERATION_RESULT_SET]: setRoomModerationResult,
};

export default createReducer(actionHandlers, { ...RoomListModel });
