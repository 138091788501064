class Price {
  constructor(entity) {
    this.entity = entity || {};
  }

  get purchaseValue() {
    return this.entity.purchase_value || "";
  }

  get preDiscountValue() {
    return this.entity.pre_discount_value || "";
  }

  get vat() {
    return this.entity.vat || "";
  }

  get promotion() {
    return this.entity.promotion || "";
  }

  toJSON() {
    return {
      purchaseValue: this.purchaseValue,
      preDiscountValue: this.preDiscountValue,
      vat: this.vat,
      promotion: this.promotion,
    };
  }
}

export default Price;
