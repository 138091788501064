import React, { Fragment } from "react";

import { PageRow } from "components/User/Sections/Sections";
import AvatarSlider from "components/User/Sections/sections/avatar-slider";
import { getReviewsSummaryHeaderText } from "components/reviews-summary-modal/utils";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import GenderAndOccupation from "components/User/Sections/sections/gender-and-occupation";
import { Icon, Spacer, Flex, Text, BADI_COLORS } from "@badi/badi-components";
import {
  UserCardBlock,
  LinkWrapper,
} from "components/User/Sections/styled-components";
import SocialProfile from "components/User/Sections/sections/social-profile";
import Metrics from "components/User/Sections/sections/metrics";
import NameAge from "components/User/Sections/sections/name-age";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import ReviewsSummary from "components/reviews-summary-modal/reviews-summary";

const closeReviewsSummaryModal = () => {
  modal.remove(MODAL_ID.REVIEWS_SUMMARY);
};

const openReviewsSummaryModal = (reviewsSummary) => {
  modal.add(<ReviewsSummary reviewsSummary={reviewsSummary} />, {
    id: MODAL_ID.REVIEWS_SUMMARY,
    onClose: closeReviewsSummaryModal,
    closable: true,
    closeOnOutsideClick: true,
    type: MODAL_TYPE.LATERAL,
  });
};

const RoomAdminProfile = ({ format, title, user, reviewsTotal, reviews }) => {
  const name = user.company ? user.company.name : user.name;
  const pictures = user.company ? user.company.pictures : user.pictures;
  const isCompany = !!user.company;

  return (
    <Fragment>
      <div></div>
      <PageRow>
        <AvatarSlider
          pictures={pictures}
          showVerificationFlag={false}
          user={user}
        />
        {!isCompany && (
          <GenderAndOccupation
            gender={user.gender}
            occupation={user.workStudiesStatus}
          />
        )}
        <NameAge age={user.age} isCompany={isCompany} name={name} />
        <Metrics format={format} metrics={user.metrics} />
        <UserCardBlock>
          {isCompany && (
            <Spacer top={1}>
              <Flex gutter={1}>
                <Icon
                  alt="user filled"
                  iconMaxHeight={16}
                  iconMaxWidth={16}
                  src={getPublicAsset("icons/icon-user-filled-blue.svg")}
                />
                <Text
                  body="14-Regular"
                  color={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
                  dataQa="user-company-description"
                >
                  {translate("user.section_profile.agency")}
                </Text>
              </Flex>
            </Spacer>
          )}
          <Spacer top={1}>
            <Flex gutter={1}>
              <Icon
                alt="star filled"
                iconMaxHeight={16}
                iconMaxWidth={16}
                src={getPublicAsset("icons/reviews-star-filled.svg")}
              />
              <LinkWrapper
                onClick={() =>
                  openReviewsSummaryModal({ total: reviewsTotal, reviews })
                }
              >
                <Text body="14-Regular" dataQa="user-reviews-description">
                  {`${reviewsTotal} ${getReviewsSummaryHeaderText(
                    reviewsTotal,
                  )}`}
                </Text>
              </LinkWrapper>
            </Flex>
          </Spacer>
          {!!user.socialProfiles?.length &&
            user.socialProfiles.map((profile) => (
              <SocialProfile
                icon={profile.icon}
                key={profile.media}
                media={profile.media}
                profileUserId={user.id}
                value={profile.value}
              />
            ))}
        </UserCardBlock>
      </PageRow>
    </Fragment>
  );
};

export default RoomAdminProfile;
