import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import { parse, stringify } from "qs";
import { omit } from "radash";
import {
  BADI_COLORS,
  BREAKPOINTS_PX,
  MatchMedia,
  Pagination,
  Text,
  Spacer,
} from "@badi/badi-components";
import styled from "styled-components";
import { ConfinedRow } from "./styled-components";
import InboxRow from "./inbox-row";
import EmptyInbox from "./empty";
import Header from "./components/header/header";
import { CHATS_PER_PAGE } from "../constants";
import { getInboxPath } from "Sections/inbox/routes";

import "./stylesExt.css";
import { getInboxRoomsSummary } from "app/datamodel/Inbox/api";

const ChatsBrowserHeader = styled(Header)`
  justify-content: flex-end;
`;

const getQueryStringParametersWithout = (key) => {
  const parameters = omit(
    parse(window.location.search, { ignoreQueryPrefix: true }),
    [key],
  );
  return stringify(parameters);
};

const ChatsBrowser = ({
  connections,
  token,
  router,
  setFilter,
  filters,
  chatsPagination: pagination,
  setPagination,
  roomTitleFromStore,
  isUserInListerMode,
}) => {
  if (!isUserInListerMode) {
    router.push(getInboxPath());
    return null;
  }
  const hasConnections = connections.length > 0;
  const {
    total,
    current_page: currentPage,
    total_pages: totalPages,
  } = pagination;

  const indexOfFirstChatInPage =
    currentPage * CHATS_PER_PAGE - CHATS_PER_PAGE + 1;
  const indexOfLastChatInPage = Math.min(currentPage * CHATS_PER_PAGE, total);
  const handleOpenRoomFilter = () => {
    const pathname = `/rooms${window.location.search}`;
    router.push(pathname);
  };

  const [roomTitle, setRoomTitle] = useState(null);

  useEffect(() => {
    if (!filters.room || roomTitleFromStore) {
      return;
    }
    getInboxRoomsSummary(token).then(({ data }) => {
      const title = data.rooms.find(({ id }) => id == filters.room)?.title;
      setRoomTitle(title);
    });
  }, [filters.room]);

  const handleCleanRoomFilter = () => {
    const queryStringParameters = getQueryStringParametersWithout("room");
    router.push(`/inbox/chats?${queryStringParameters}`);
  };

  return (
    <>
      <ChatsBrowserHeader
        category={filters.category}
        disabled={false}
        onCancelFilter={handleCleanRoomFilter}
        onChangeCategory={(category) => setFilter({ category, page: 1 })}
        onFilterClick={handleOpenRoomFilter}
        roomTitle={filters.room ? roomTitleFromStore || roomTitle : null}
        showFilterRoom={true}
      />
      <ConfinedRow>
        <Row>
          <Col lg={{ offset: 0, size: 12 }} md={{ offset: 0, size: 12 }}>
            {hasConnections &&
              connections.map((connection) => (
                <InboxRow
                  connection={connection}
                  key={connection.id}
                  token={token}
                />
              ))}

            {!hasConnections && <EmptyInbox />}
          </Col>
        </Row>
      </ConfinedRow>
      {hasConnections && (
        <Container className="Inbox--Pagination">
          <Row>
            <Col
              className="Inbox--Pagination-pages"
              md={{ offset: 3, order: 1, size: 6 }}
              xs={{ offset: 0, order: 2, size: 12 }}
            >
              <MatchMedia query={`(max-width: ${BREAKPOINTS_PX.sm})`}>
                {(match) => (
                  <Pagination
                    compact={match}
                    currentPage={currentPage}
                    onSelectPage={(page) => setPagination({ page })}
                    totalPages={totalPages}
                  />
                )}
              </MatchMedia>
            </Col>
            <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.lg})`}>
              <Col
                className="Inbox--Pagination-overview"
                md={{ order: 2, size: 2 }}
                xs={{ offset: 0, order: 1, size: 12 }}
              >
                <Text body={3} color={BADI_COLORS.NEUTRAL.NEUTRAL_50}>
                  {`${indexOfFirstChatInPage}-${indexOfLastChatInPage} of ${total}`}
                </Text>
              </Col>
            </MatchMedia>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ChatsBrowser;
