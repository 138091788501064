import Types from "../types";

export default {
  addModal(result) {
    return { type: Types.ADD_MODAL, result };
  },
  clearAll() {
    return { type: Types.CLEAR_ALL };
  },
  destroyModal(id) {
    return { type: Types.DESTROY_MODAL, result: id };
  },
  removeModal(id) {
    return { type: Types.REMOVE_MODAL, result: id };
  },
  updateModal(result) {
    return { type: Types.UPDATE_MODAL, result };
  },
  updateModalOptions(result) {
    return { type: Types.UPDATE_MODALOPTIONS, result };
  },
};
