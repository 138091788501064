import React from "react";
import { Text, Flex, Spacer } from "@badi/badi-components";

import {
  BackcoverWrapper,
  ContentWrapper,
  SeeAll,
  BackCoverOverlay,
} from "./styled-components";

const BackOver = ({ backgroundUrl, hint, cta, children }) => (
  <BackcoverWrapper backgroundUrl={backgroundUrl}>
    {children}
    <Flex
      alignItems="center"
      as={ContentWrapper}
      direction="column"
      fullHeight={true}
      justify="center"
    >
      <Text body="14-Medium" color={hint.color}>
        {hint.content}
      </Text>
      <Spacer top={2}>
        <SeeAll color={cta.color}>
          <Text body="12-Bold" color={cta.color}>
            {cta.content}
          </Text>
        </SeeAll>
      </Spacer>
    </Flex>
    <BackCoverOverlay />
  </BackcoverWrapper>
);

export default BackOver;
