import { connect } from "react-redux";

import Actions from "datamodel/User/actions";
import { getToken } from "datamodel/Auth/selectors";
import {
  getNameSelector,
  getSurnameSelector,
  getBirthdateSelector,
  getGenderSelector,
  getOccupationStatusSelector,
  getPicturesSelector,
} from "datamodel/User/selectors";

import MainProfile from "./main-profile";

export default connect(
  (state) => ({
    error: state.User.error,
    fetching: state.User.fetching,
    token: getToken(state),
    name: getNameSelector(state),
    lastName: getSurnameSelector(state),
    birthDate: getBirthdateSelector(state),
    gender: getGenderSelector(state),
    occupation: getOccupationStatusSelector(state),
    pictures: getPicturesSelector(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    return {
      ...stateProps,
      ...ownProps,
      ...dispatchProps,
      getMe: () => dispatch(Actions.getMe(stateProps.token)),
      saveProfile: (params) =>
        dispatch(Actions.saveProfile(stateProps.token, params)),
      updateProfile: (fieldName, value) =>
        dispatch(Actions.updateProfile(stateProps.token, fieldName, value)),
    };
  },
)(MainProfile);
