import React, { useState, useEffect } from "react";
import { translate } from "base/shared/Localization";
import { getReview, submitReview } from "datamodel/reviews/api";
import { routerEventListenerInitiator } from "base/shared/mobile-web-view";
import StepperLayout, { Step } from "components/Layout/stepper-layout";
import NewReviewStepFooter from "./components/new-review-step-footer";
import { STATUS, STEPS } from "./constants";
import { FinalStep, InProgressStep } from "./components/steps";
import { isLister } from "./utils";

const NewReview = ({
  reviewId,
  onLeaveFlow,
  onNotAuthorized,
  router,
  isIOSApp,
  isLoggedIn,
  sgid = null,
  token,
}) => {
  if (!reviewId || (!isLoggedIn && !sgid)) {
    onNotAuthorized();
  }

  const routerEventListener = routerEventListenerInitiator(router, isIOSApp);

  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [fields, setFields] = useState({});
  const [review, setReview] = useState({});

  const handleError = () => {
    setLoading(false);
    setStatus(STATUS.EXPIRED);
  };

  const handleFetched = (fetchedReview) => {
    setLoading(false);
    setReview(fetchedReview);

    if (fetchedReview.submittable) {
      setStatus(STATUS.IN_PROGRESS);
    } else {
      setStatus(STATUS.EXPIRED);
    }
  };

  const handleSubmitted = () => {
    setLoading(false);
    setStatus(STATUS.SUBMITTED);
  };

  const handleValidation = ({ valid: reviewValid, payload }) => {
    setValid(reviewValid);
    setFields({
      ...fields,
      ...payload,
    });
  };

  useEffect(() => {
    setLoading(true);
    getReview({ reviewId, sgid, token })
      .then((res) => handleFetched(res))
      .catch(() => handleError());

    const unlisten = routerEventListener();
    return () => {
      unlisten();
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    const params = {
      text: fields.text,
      stars: fields.stars,
    };

    submitReview({
      reviewId,
      sgid,
      token,
      params,
    })
      .then(() => handleSubmitted())
      .catch(() => handleError());
  };

  const getContent = () => {
    switch (status) {
      case STATUS.IN_PROGRESS: {
        const { id, title, descriptionLister, descriptionSeeker, button } =
          STEPS[status];
        return (
          <Step
            button={translate(button)}
            data-qa="in-progress-step"
            footer={NewReviewStepFooter}
            id={id}
            key={id}
            onClick={handleSubmit}
            stepIsValid={valid}
          >
            <InProgressStep
              description={
                isLister(review.reviewerType)
                  ? translate(descriptionLister)
                  : translate(descriptionSeeker)
              }
              onValidation={handleValidation}
              review={review}
              title={translate(title)}
            />
          </Step>
        );
      }
      case STATUS.SUBMITTED: {
        const { id, title, description, button, illustration } = STEPS[status];
        return (
          <Step
            button={translate(button)}
            data-qa="submitted-step"
            footer={NewReviewStepFooter}
            id={id}
            key={id}
            onClick={onLeaveFlow}
            stepIsValid={true}
          >
            <FinalStep
              description={translate(description)}
              illustration={illustration}
              title={translate(title)}
            />
          </Step>
        );
      }
      case STATUS.EXPIRED: {
        const { id, title, description, illustration } = STEPS[status];
        return (
          <Step data-qa="expired-step" id={id} key={id}>
            <FinalStep
              description={translate(description)}
              illustration={illustration}
              title={translate(title)}
            />
          </Step>
        );
      }
      default:
        return null;
    }
  };

  const content = getContent();
  const showCloseButton = status !== STATUS.SUBMITTED;

  return (
    <StepperLayout
      disabled={loading}
      onClose={showCloseButton ? onLeaveFlow : null}
      showProgress={false}
    >
      {content}
    </StepperLayout>
  );
};

export default NewReview;
