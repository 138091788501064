import { createSelector } from "reselect";

export const getDeviceFromState = (state) => state.Device;
export const getDeviceOsFromStore = (state) =>
  getDeviceFromState(state).os.name;
export const getIsSafari = (state) => state.Device.isSafari;
export const getIsChrome = (state) => state.Device.isChrome;
export const getIsFirefox = (state) => state.Device.isFirefox;
export const getIsDesktop = (state) => state.Device.isDesktop;
export const getIsIOS = (state) => state.Device.isIOS;
export const isAndroidSelector = (state) => state.Device.isAndroid;
export const isMobileSelector = createSelector(
  getDeviceFromState,
  (device) => device.isMobile,
);

export const getPushNotificationsSupportedSelector = createSelector(
  [getIsChrome, getIsFirefox, getIsIOS],
  (isChrome, isFirefox, isIOS) => (isChrome || isFirefox) && !isIOS,
);
