import React from "react";
import { BADI_COLORS, Icon, unitizedPx } from "@badi/badi-components";
import LayoutModal from "../Layout";
import { CloseButtonWrapper, Modal } from "./styled-components";

const InfoModal = ({
  children,
  closable,
  isClosed,
  outsideClickHandler,
  backgroundColor,
}) => {
  return (
    <LayoutModal
      backgroundColor={backgroundColor}
      isClosed={isClosed}
      outsideClickHandler={outsideClickHandler}
      padding={unitizedPx(0)}
      wrapper={Modal}
    >
      {closable && (
        <CloseButtonWrapper
          data-qa="info-modal-close"
          onClick={outsideClickHandler}
        >
          <Icon
            alt="info-modal-icon-close"
            color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
            data-qa="info-modal-icon-close"
            height={16}
            name="close"
            width={16}
          />
        </CloseButtonWrapper>
      )}
      {children}
    </LayoutModal>
  );
};

InfoModal.defaultProps = {
  children: null,
  closable: false,
  isClosed: false,
  outsideClickHandler: () => {},
};

export default InfoModal;
