export const VARIANTS = {
  LIGHT: "LIGHT",
  DEFAULT: "DEFAULT",
};

export const STATUS = {
  CHECK: "CHECK",
  REQUEST: "REQUEST",
  VERIFIED: "VERIFIED",
};
