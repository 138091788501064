import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchRecommendations, rejectRecommendation, sendInvite } from "./api";

export default {
  getRecommendations({ roomId }, token) {
    return {
      types: generateFetchTypes(Types.RECOMMENDATIONS_REQUEST),
      request: fetchRecommendations({ roomId }, token),
    };
  },
  acceptRecommendation({ roomId, userId, message = "" }, token) {
    return {
      types: generateFetchTypes(Types.RECOMMENDATION_SET_STATUS_REQUEST),
      request: sendInvite(
        {
          roomId,
          userId,
          message,
        },
        token,
      ),
      roomId,
    };
  },
  rejectRecommendation({ roomId, userId }, token) {
    return {
      types: generateFetchTypes(Types.RECOMMENDATION_SET_STATUS_REQUEST),
      request: rejectRecommendation(
        {
          roomId,
          userId,
        },
        token,
      ),
      roomId,
    };
  },
  removeRecommendation(id) {
    return {
      type: Types.RECOMMENDATION_REMOVE,
      id,
    };
  },
  resetRecommendations() {
    return {
      type: Types.RECOMMENDATIONS_RESET,
    };
  },
};
