import React, { Component } from "react";
import classNames from "classnames";

import { translate } from "base/shared/Localization";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";

import Button from "components/Button/Button";
import TagCategory from "./TagCategory";
import { getValues, parseCategoryTags, toggleCategoryTag } from "./utils";
import "./TagsExt.css";

class TagList extends Component {
  constructor(props) {
    super(props);
    this.state = { disabled: true, tags: [] };
    this.modalTagsId = MODAL_ID.Tags;
  }
  UNSAFE_componentWillMount() {
    this.setTagList(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTagList(nextProps);
  }

  onClickTag = (categoryId, tagId) => {
    this.setState({
      tags: toggleCategoryTag(this.state.tags, categoryId, tagId),
      disabled: false,
    });
  };

  onSaveClick = () => {
    const values = getValues(this.state.tags);
    this.props.onChange(values);
    modal.remove(this.modalTagsId);
  };

  setTagList = ({ tags, value, mode }) => {
    let fieldToTranslate = "name";
    let parsedTags;

    switch (mode) {
      case "add":
        fieldToTranslate = "title";
        parsedTags = parseCategoryTags(tags, value, true);
        break;
      case "display":
        parsedTags = parseCategoryTags(tags, value, true).filter(
          (p) => !!p.tags.length,
        );
        break;
      default:
        parsedTags = parseCategoryTags(tags, value, false);
    }

    this.setState({
      tags: translate(parsedTags, fieldToTranslate),
    });
  };

  toggleCategory = (id) => {
    this.setState({
      tags: this.state.tags.map((t) =>
        t.id === id
          ? { ...t, expanded: !t.expanded }
          : { ...t, expanded: false },
      ),
    });
  };

  editCategories = (id) => {
    const editTags = this.props.tags.map((t) => ({
      ...t,
      expanded: t.id === id,
    }));

    modal.add(
      <div className="Form__TagList_wrapper">
        <h2>{translate("profile.form.label.tags")}</h2>
        <p>{translate("profile.form.description.tags")}</p>
        <TagList {...this.props} mode="edit" tags={editTags} />
      </div>,
      {
        id: this.modalTagsId,
        onClose: () => {
          modal.remove(this.modalTagsId);
        },
        type: "lateral",
      },
    );
  };

  render() {
    const { disabled } = this.state;
    const { className, mode } = this.props;

    return (
      <div className={`Form__TagList_${mode}`}>
        <ul className={classNames("Form__TagList", className)}>
          {this.state.tags.map((tag) => (
            <TagCategory
              key={tag.title}
              mode={mode}
              onAddClick={this.editCategories}
              {...tag}
              onClickTag={this.onClickTag}
              className="Form__TagList_item"
              toggleCategory={this.toggleCategory}
            />
          ))}
        </ul>
        {mode === "edit" && (
          <div className="Form__TagList_actions">
            <Button
              className="width-100"
              disabled={disabled}
              type="button"
              onClick={this.onSaveClick}
              text={translate("profile.form.fields.tags.button")}
            />
          </div>
        )}
      </div>
    );
  }
}

TagList.defaultProps = {
  className: "",
  mode: "display",
  onChange: () => {},
};

export default TagList;
