import Price from "./price-model";
import { PLAN_TYPES } from "../constants";

class Plan {
  constructor(entity) {
    this.entity = entity || { display_data: {} };
  }

  get id() {
    return this.entity.id;
  }

  get productReference() {
    return this.entity.product_reference;
  }

  get productId() {
    return this.entity.product_id;
  }

  /** @deprecated Since Matching model version. */
  get heading() {
    return this.entity.display_data.heading;
  }

  /** @deprecated Since Matching model version. */
  get subheading() {
    return this.entity.display_data.subheading;
  }

  /** @deprecated Since Matching model version. */
  get price() {
    return this.entity.display_data.price;
  }

  /** @deprecated Since Matching model version. */
  get promotionPrice() {
    return this.entity.display_data.promotion_price || null;
  }

  /** @deprecated Since Matching model version. */
  get promotion() {
    return this.entity.display_data.promotion;
  }

  get groups() {
    return this.entity.display_data?.groups;
  }

  get title() {
    return this.entity.display_data?.title;
  }

  get subTitle() {
    return this.entity.display_data?.subtitle;
  }

  get additionalInfo() {
    return this.entity.display_data?.additional_info || {};
  }

  get priceDetails() {
    return new Price(this.entity.display_data?.price_data).toJSON();
  }

  get contactSales() {
    return this.entity.display_data?.contact_sales || false;
  }

  get type() {
    return this.entity.display_data?.type || PLAN_TYPES.INDIVIDUAL;
  }

  get category() {
    return this.entity.category;
  }

  get upsellCategory() {
    return this.entity.upsell_data?.plan_category || null;
  }

  toJSON() {
    return {
      category: this.category,
      id: this.id,
      productId: this.productId,
      productReference: this.productReference,
      heading: this.heading,
      subheading: this.subheading,
      price: this.price,
      promotionPrice: this.promotionPrice,
      promotion: this.promotion,
      groups: this.groups,
      title: this.title,
      subTitle: this.subTitle,
      additionalInfo: this.additionalInfo,
      priceDetails: this.priceDetails,
      contactSales: this.contactSales,
      type: this.type,
      upsellCategory: this.upsellCategory,
    };
  }
}

class Plans {
  constructor(entity) {
    this.entity = entity;
  }

  get plans() {
    const plans = this.entity?.plans || [];

    return plans.map((plan) => new Plan(plan));
  }

  toJSON() {
    return {
      plans: this.plans.map((plan) => plan.toJSON()),
    };
  }
}

export default Plans;
