export const requestVisitRequestReducer = (state) => state;

export const requestVisitErrorReducer = (state) => state;

/**
 * Reducer in conversation state branch, `state` points to InboxConnection.
 * @param {object} state
 * @param {object} action
 */
export const requestVisitSuccessReducer = (state, action) => {
  return {
    ...state,
    visits: action.result,
  };
};

export const proposeVisitRequestReducer = (state) => state;

export const proposeVisitErrorReducer = (state) => state;

export const proposeVisitSuccessReducer = (state, action) => {
  return {
    ...state,
    visits: action.result,
  };
};

export const updateVisitRequestReducer = (state) => state;

export const updateVisitErrorReducer = (state) => state;

export const updateVisitSuccessReducer = (state, action) => {
  return {
    ...state,
    visits: action.result,
  };
};
