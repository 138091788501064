import React from "react";
import {
  Text,
  Flex,
  Icon,
  TextButton,
  BADI_COLORS,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import BadiLogo from "components/Icons/BadiLogo";
import { TopBarWrapper } from "../styled-components";

const SeekerPreferencesTopBar = ({ onClose }) => (
  <TopBarWrapper as={Flex} justify="spaceBetween">
    <BadiLogo />
    <TextButton
      color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
      data-qa="seeker-preferences-close"
      hoverColor={BADI_COLORS.NEUTRAL.NEUTRAL_80}
      onClick={onClose}
      withIcon={<Icon name="close" />}
    >
      <Text body="12-Bold">
        {translate("seeker.preferences.close").toUpperCase()}
      </Text>
    </TextButton>
  </TopBarWrapper>
);

export default SeekerPreferencesTopBar;
