import React from "react";
import ViewEvolutionGraph from "./widgets/view-evolution-graph";
import ConnectionEvolutionGraph from "./widgets/connection-evolution-graph";
import RoomPropertiesSection from "./sections/room-properties-section";
import ResponseSection from "./sections/response-section";

const Metrics = ({
  viewEvolution = null,
  connectionEvolution = null,
  responseRate = null,
  responseTime = null,
  roomProperties = null,
}) => (
  <>
    {(responseRate || responseTime) && (
      <ResponseSection
        responseRate={responseRate}
        responseTime={responseTime}
      />
    )}
    {roomProperties && (
      <RoomPropertiesSection roomProperties={roomProperties} />
    )}
    {viewEvolution && <ViewEvolutionGraph evolution={viewEvolution} />}
    {connectionEvolution && (
      <ConnectionEvolutionGraph evolution={connectionEvolution} />
    )}
  </>
);

export default Metrics;
