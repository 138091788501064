import React from "react";
import { Label, Card, colors, Spacer, Text } from "@badi/badi-components";

const BadiHomesBadge = ({ color, backgroundColor, title, description }) => (
  <Card
    backgroundColor={description.backgroundColor}
    dataQa="badi-homes-badge"
    withShadow={false}
  >
    <Spacer bottom={2} left={2} right={2} top={2}>
      <Label
        color={color}
        backgroundColor={backgroundColor}
        variant="general-inverted"
      >
        {title}
      </Label>
      <Spacer top={1}>
        <p style={{ fontSize: "14px" }}>{description.title}</p>
        <ul
          style={{
            marginLeft: "15px",
            marginTop: "8px",
            padding: 0,
          }}
        >
          {description.lines.map((line, index) => (
            <li style={{ fontSize: "14px" }} key={index}>
              {line}
            </li>
          ))}
        </ul>
      </Spacer>
    </Spacer>
  </Card>
);

export default BadiHomesBadge;
