import React from "react";
import styled from "styled-components";
import { colors, unitizedPx } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Image from "components/Image/Image";

const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.small ? "10px" : "12px")};
  line-height: ${(props) => (props.small ? "16px" : "20px")};
  padding: 2px 4px 2px 2px;
  background-color: ${colors.blueBooking};
  border-radius: ${unitizedPx(0.5)};
  color: ${colors.white};
`;

const ListerScoreBadge = ({ score, small = false }) => (
  <StyledBadge small={small}>
    <Image
      alt={translate("badge.trusted")}
      lazy={false}
      src="/assets/icons/icon-trust-shield-white.svg"
    />
    {score}
  </StyledBadge>
);

export default ListerScoreBadge;
