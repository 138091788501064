import { getPublicAsset } from "base/shared/Utils";

export const SEEKER_FLOW = {
  title: "aboutbadi.flow.seeker.title",
  cta: "aboutbadi.flow.seeker.cta",
  photo: getPublicAsset("images/landings/about-badi/seeker-flow-photo.png"),
  getScreenshot: (locale = "en") =>
    getPublicAsset(
      `images/landings/about-badi/seeker-flow-screen_${locale.toUpperCase()}.jpg`,
    ),
  steps: [
    {
      title: "aboutbadi.flow.seeker.step1.title",
      description: "aboutbadi.flow.seeker.step1.description",
      icon: getPublicAsset("icons/place.svg"),
    },
    {
      title: "aboutbadi.flow.seeker.step2.title",
      description: "aboutbadi.flow.seeker.step2.description",
      icon: getPublicAsset("icons/privacy.svg"),
    },
    {
      title: "aboutbadi.flow.seeker.step3.title",
      description: "aboutbadi.flow.seeker.step3.description",
      icon: getPublicAsset("icons/chat.svg"),
    },
    {
      title: "aboutbadi.flow.seeker.step4.title",
      description: "aboutbadi.flow.seeker.step4.description",
      icon: getPublicAsset("icons/user-outline.svg"),
    },
  ],
};

export const LISTER_FLOW = {
  title: "aboutbadi.flow.lister.title",
  cta: "aboutbadi.flow.lister.cta",
  photo: getPublicAsset("images/landings/about-badi/lister-flow-photo.jpg"),
  getScreenshot: (locale = "en") =>
    getPublicAsset(
      `images/landings/about-badi/lister-flow-screen_${locale.toUpperCase()}.jpg`,
    ),
  steps: [
    {
      title: "aboutbadi.flow.lister.step1.title",
      description: "aboutbadi.flow.lister.step1.description",
      icon: getPublicAsset("icons/camera2.svg"),
    },
    {
      title: "aboutbadi.flow.lister.step2.title",
      description: "aboutbadi.flow.lister.step2.description",
      icon: getPublicAsset("icons/eye.svg"),
    },
    {
      title: "aboutbadi.flow.lister.step3.title",
      description: "aboutbadi.flow.lister.step3.description",
      icon: getPublicAsset("icons/chat.svg"),
    },
  ],
};
