import styled from "styled-components";

export const Root = styled.div`
  ${(props) =>
    props.clickable &&
    `
      &:hover {
        cursor: pointer;
      }
  `}
`;

export const Image = styled.img`
  display: block;
  max-width: 96px;
  max-height: 72px;
  border-radius: 4px;
`;

export const TextWrapper = styled.div`
  word-break: break-word;
`;
