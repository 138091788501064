import React from "react";

const LeaveConnection = ({ color, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill={color}>
      <path
        stroke={color}
        strokeWidth="0.3"
        d="M22.243 6.734h-3.416C18.657 5.198 17.399 4 15.876 4c-1.523 0-2.78 1.198-2.951 2.734H9.508C8.125 6.734 7 7.904 7 9.342v.134c0 1.098.658 2.039 1.585 2.422v12.317c0 1.438 1.125 2.608 2.508 2.608h9.566c1.382 0 2.507-1.17 2.507-2.608V11.898c.927-.383 1.585-1.324 1.585-2.422v-.134c0-1.438-1.125-2.608-2.508-2.608zm-6.367-1.497c.866 0 1.589.645 1.749 1.497h-3.498c.16-.852.883-1.497 1.749-1.497zm6.1 18.978c0 .756-.59 1.371-1.317 1.371h-9.567c-.726 0-1.318-.615-1.318-1.37V12.082h12.203v12.132zm1.586-14.74c0 .756-.592 1.372-1.319 1.372H9.508c-.727 0-1.319-.616-1.319-1.371v-.134c0-.756.592-1.371 1.319-1.371h12.735c.727 0 1.319.615 1.319 1.37v.135z"
      />
      <path d="M12.595 23.954a.607.607 0 0 0 .594-.619v-6.961a.607.607 0 0 0-.594-.618.607.607 0 0 0-.595.618v6.961c0 .342.266.619.595.619zM15.88 23.954a.607.607 0 0 0 .595-.619v-6.961a.607.607 0 0 0-.595-.618.607.607 0 0 0-.594.618v6.961c0 .342.266.619.594.619zM19.166 23.954a.607.607 0 0 0 .595-.619v-6.961a.607.607 0 0 0-.595-.618.607.607 0 0 0-.595.618v6.961c0 .342.267.619.595.619z" />
    </g>
  </svg>
);

LeaveConnection.defaultProps = {
  color: "#333",
  className: "",
};

export default LeaveConnection;
