import React from "react";
import {
  Anchor,
  BADI_COLORS,
  Button,
  Flex,
  Spacer,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { useForm, useFormValidation } from "components/hooks";
import Input from "components/form-text-input";
import { getResetPasswordPath } from "../../routes";
import { Root } from "./styled-components";
import { MODE } from "../authentication-form/constants";

const EMAIL_FIELDNAME = "email";
const PASSWORD_FIELDNAME = "password";
const EMAIL_VALIDATIONS = ["email"];
const PASSWORD_VALIDATIONS = ["password"];

const EmailForm = ({ mode = MODE.LOGIN, onSubmit }) => {
  const [values, setInputValue] = useForm({
    [EMAIL_FIELDNAME]: "",
    [PASSWORD_FIELDNAME]: "",
  });
  const [isFormValid, setFieldValidation] = useFormValidation({
    [EMAIL_FIELDNAME]: false,
    [PASSWORD_FIELDNAME]: false,
  });

  const handleChange = ({ name: inputName, value }) => {
    setInputValue(inputName, value);
  };

  const handleValidation = ({ name: inputName, isValid }) => {
    setFieldValidation(inputName, !!isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      email: values[EMAIL_FIELDNAME],
      password: values[PASSWORD_FIELDNAME],
    });
  };

  const isLoginMode = mode === MODE.LOGIN;

  return (
    <Root data-qa="email-form">
      <Flex as="form" direction="column">
        <Input
          dataQa="input-email"
          initialValue=""
          name={EMAIL_FIELDNAME}
          newType={true}
          onChange={handleChange}
          onInputValidation={handleValidation}
          placeholder={translate("Email")}
          required={true}
          validations={EMAIL_VALIDATIONS}
          warning={translate("Please select a valid email")}
          wide={true}
        />
        <Input
          dataQa="input-password"
          initialValue=""
          name={PASSWORD_FIELDNAME}
          newType={true}
          onChange={handleChange}
          onInputValidation={handleValidation}
          placeholder={translate("Password")}
          required={true}
          type="password"
          validations={PASSWORD_VALIDATIONS}
          warning={translate("At least 6 characters, 1 letter and 1 number")}
          wide={true}
        />
        {isLoginMode && (
          <Anchor
            alignself="flexEnd"
            bodySize={3}
            color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
            data-qa="forgot-password-link"
            href={getResetPasswordPath()}
          >
            {translate("I forgot")}
          </Anchor>
        )}
        <Spacer bottom={2} />
        <Button
          dataQa="email-form-button"
          disabled={!isFormValid}
          fullWidth={true}
          onClick={handleSubmit}
          type="submit"
          wide={true}
        >
          {isLoginMode ? translate("Login") : translate("Sign Up")}
        </Button>
      </Flex>
    </Root>
  );
};

export default EmailForm;
