import styled from "styled-components";
import { unitizedPx, BADI_COLORS } from "@badi/badi-components";

export const Divider = styled.div`
  border-bottom: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
`;

export const TextAlignCenter = styled.div`
  text-align: center;
`;

export const Card = styled.div`
  position: relative;
  overflow: hidden;
  height: ${(props) =>
    props.height ? unitizedPx(props.height) : unitizedPx(23)};
  border-radius: ${unitizedPx(1)};
  border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  padding: ${unitizedPx(2)};
  text-align: center;
`;

export const SharingOptionWrapper = styled.div`
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }
`;

export const LinkWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
  ${({ disabled }) => (disabled ? "pointer-events: none;" : "")}
`;
