import {
  objectToQueryParams,
  removeUndefinedAndNullFields,
} from "base/shared/Utils";
import { PREMIUM_FEATURES_PATHS } from "datamodel/premium/constants";
import { PLANS_CATEGORIES } from "datamodel/plans/constants";
import { getRecommendationsPath } from "Sections/recommendations/routes";
import { getOverviewPath } from "Sections/overview/routes";

export const getSuccessUrlParsed = ({
  category,
  successUrl,
  origin,
  roomId,
  initiator,
}) => {
  const queryParams = objectToQueryParams(
    removeUndefinedAndNullFields({
      origin,
      category,
      roomId,
      initiator,
    }),
  );

  return `${successUrl}?${queryParams}`;
};

const getPathFromFeature = ({ feature, roomId }) => {
  if (!feature) {
    return getOverviewPath();
  }
  const getPath = PREMIUM_FEATURES_PATHS.get(feature);
  return getPath ? getPath({ roomId }) : undefined;
};

export const getContent = ({ origin, category, roomId, initiator }) => {
  switch (category) {
    case PLANS_CATEGORIES.UPSELL_BADI_PLUS:
      return {
        title: "boost.and.plus_success.screen.title",
        description: "boost.and.plus_success.screen.text",
        cta: "boost.and.plus_success.screen.got.it.cta",
        pathUrl: initiator
          ? decodeURIComponent(initiator)
          : getPathFromFeature({ feature: origin, roomId }),
      };
    case PLANS_CATEGORIES.MATCHING:
      return {
        title: "badi.gold.success_title",
        description: "badi.gold.success_subtitle",
        cta: "badi.gold.success_cta",
        pathUrl: initiator
          ? decodeURIComponent(initiator)
          : getPathFromFeature({ feature: origin, roomId }),
      };
    case PLANS_CATEGORIES.STANDALONE_BOOSTING:
      return {
        title: "boost_success.screen.title",
        description: "boost_success.screen.text",
        cta: "boost_success.screen.got.it.cta",
      };
    default:
      return {
        description: "purchase.modal.success_promo_reminder_subtitle",
        cta: "purchase.modal.success_promo_reminder_cta",
        pathUrl: getRecommendationsPath({ roomId }),
        title: "purchase.modal.success_title",
      };
  }
};
