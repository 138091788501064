import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import "./MessageEnquiryModalExt.css";
import MessageEnquiryFeedbackWithFastTrackInfoSwipingSeekerExperience from "./swiping-seeker-experience/message-enquiry-feedback-with-fast-track-info";

export const closeEnquiryFeedbackModal = () => {
  modal.remove(MODAL_ID.MessageEnquiryFeedback);
};

const mainClassName = "enquiry_feedback_modal";

export const openEnquiryFeedbackModal = ({
  onFastTrackFindOutMoreClick,
  standardEnquiriesCount,
}) => {
  modal.add(
    <MessageEnquiryFeedbackWithFastTrackInfoSwipingSeekerExperience
      onContinue={closeEnquiryFeedbackModal}
      onFastTrackFindOutMoreClick={onFastTrackFindOutMoreClick}
      standardEnquiriesCount={standardEnquiriesCount}
    />,
    {
      id: MODAL_ID.MessageEnquiryFeedback,
      type: MODAL_TYPE.LATERAL,
      containerProps: { style: { padding: "0" } },
      onClose: () => closeEnquiryFeedbackModal(),
      mainClassName,
    },
  );
};
