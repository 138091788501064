import React from "react";

import { translate } from "base/shared/Localization";
import { UserCardBlock } from "../styled-components";

const PublishedRooms = ({ publishedRooms, visible }) => {
  const hasPublishedRooms = publishedRooms > 0;

  return (
    visible &&
    hasPublishedRooms && (
      <UserCardBlock>
        <h3>{translate("user.section.rooms")}</h3>
        <p data-qa="number-of-published-rooms">{publishedRooms}</p>
      </UserCardBlock>
    )
  );
};

PublishedRooms.defaultProps = {
  publishedRooms: 0,
};

export default PublishedRooms;
