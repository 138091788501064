import React from "react";
import { Spacer, Text } from "@badi/badi-components";
import Discard from "app/assets/icons/discard.svg";
import { translate } from "base/shared/Localization";

const DiscardButtonContent = ({ isDiscarded }) => {
  const text = isDiscarded
    ? "discard.listing.pressed.title"
    : "discard.listing.cta";

  return (
    <>
      {!isDiscarded && (
        <Spacer right={1} top={0.8}>
          <Discard />
        </Spacer>
      )}

      <Text body="14-Bold" dataQa="room-details-discard-button-texts">
        {translate(text)}
      </Text>
    </>
  );
};

export default DiscardButtonContent;
