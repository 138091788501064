import { getDisplayPrice } from "base/shared/Utils";
import RoomModel from "./room-model";

class RoomCompact extends RoomModel {
  get billsIncluded() {
    const { bills_included: billsIncluded } = this.entity.monthly_price || {};
    return billsIncluded;
  }

  get monthlyPrice() {
    const { currency, price } = this.entity.monthly_price || {};
    return getDisplayPrice(price, currency);
  }

  toJSON() {
    return {
      address: this.address,
      availableFrom: this.availableFrom,
      billsIncluded: this.billsIncluded,
      id: this.id,
      minStay: this.minStay,
      monthlyPrice: this.monthlyPrice,
      picture: this.coverPicture,
      title: this.title,
    };
  }
}

export default RoomCompact;
