import React from "react";
import { Flex, Spacer, Text, Card, colors } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import UserProfileBadge from "components/user-profile-badge";
import BookingPreview from "components/booking-preview";
import BadiGuaranteeCard from "components/badi-guarantee-card";
import BenefitsDescriptionCard from "components/benefits-description-card";
import { getOtherUserRole, isLister } from "datamodel/User/utils";

import ExpirationBadge from "../booking-expiration-badge";
import { MessageCaret, MessageRoot } from "./styled-components";
import BookingStepLayout from "../step-layout";

const UserMessageStep = ({ bookingRequestDetail, title, userRole, token }) => {
  const {
    expirationText,
    expirationHours,
    message,
    room,
    moveIn,
    moveOut,
    exactMonthsLength,
    lengthInMonths,
    seeker,
    lister,
    price,
  } = bookingRequestDetail;

  const user = isLister(userRole) ? seeker : lister;
  const sidebar = [
    <Flex direction="column">
      <Flex direction="row">
        <ExpirationBadge expiration={expirationText} />
      </Flex>
      {room && price && (
        <BookingPreview
          exactMonthsLength={exactMonthsLength}
          lengthInMonths={lengthInMonths}
          moveIn={moveIn}
          moveOut={moveOut}
          price={price}
          room={room}
          userRole={userRole}
        />
      )}
    </Flex>,
    <BadiGuaranteeCard
      alignText={false}
      textDirection="column"
      userRole={userRole}
    />,
  ];
  if (isLister(userRole)) sidebar.push(<BenefitsDescriptionCard />);

  return (
    <BookingStepLayout
      expirationHours={expirationHours}
      sidebar={sidebar}
      sidebarTitle={translate("booking.new.request_details_title")}
      title={translate(title)}
    >
      <Flex direction="column" gutter={2}>
        <Spacer top={2}>
          <UserProfileBadge
            {...user}
            token={token}
            userRole={getOtherUserRole(userRole)}
          />
        </Spacer>
        <MessageRoot>
          <MessageCaret />
          <Card backgroundColor={colors.lightGrey} withShadow={false}>
            <Spacer bottom={2} left={2} right={2} top={2}>
              <Text>{message}</Text>
            </Spacer>
          </Card>
        </MessageRoot>
      </Flex>
    </BookingStepLayout>
  );
};

UserMessageStep.defaultProps = {
  connectionId: 0,
  title: "",
};

export default UserMessageStep;
