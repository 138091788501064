export const getDistance = (ne, center) => {
  const radius =
    window.google.maps.geometry.spherical.computeDistanceBetween(center, ne) /
    1000;

  return radius < 0.35 ? 0.35 : radius;
};

export const getSelectedElementsByKey = (elements, ids, key) =>
  elements.map((elem) => ({
    ...elem,
    selected: ids.includes(elem[key]),
  }));

const convertValuesNumber = (object) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: Number(value) }),
    {},
  );

export const parseBounds = (bounds) => {
  if (!bounds) return null;
  return {
    ne: convertValuesNumber(bounds.ne),
    sw: convertValuesNumber(bounds.sw),
  };
};

export const parseCoordinates = (coordinates) => {
  if (!coordinates) return null;
  return convertValuesNumber(coordinates);
};
