import React from "react";

import { colors } from "@badi/badi-components";

const SearchListView = ({ selected }) => (
  <svg
    height="15"
    viewBox="0 0 18 15"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={selected ? colors.green : colors.midGrey}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M1 1.5h16M1 7.5h16M1 13.5h8" />
    </g>
  </svg>
);

SearchListView.defaultProps = {
  selected: false,
};

export default SearchListView;
