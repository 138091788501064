import { connect } from "react-redux";

import WhatsAppButton from "./WhatsApp";

export default connect(
  (state) => ({
    desktop: state.Device.isDesktop,
  }),
  null,
)(WhatsAppButton);
