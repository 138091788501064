import React from "react";
import {
  BADI_COLORS,
  Text,
  Card,
  Spacer,
  TextButton,
  Flex,
} from "@badi/badi-components";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";

const InviteLink = ({ url }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    UserFeedback.success(
      translate("Link copied"),
      translate("Now you're ready to share it!"),
    );
  };

  return (
    <Card backgroundColor={BADI_COLORS.NEUTRAL.NEUTRAL_00} withShadow={false}>
      <Spacer bottom={2} left={2} right={2} top={2}>
        <Flex direction="row" justify="spaceBetween">
          <Text dataQa="referrals-invite-link">{url}</Text>
          <TextButton
            data-qa="copy-invite-link-button"
            disabled={!url}
            onClick={copyToClipboard}
          >
            {translate("referrals.sharing.copy_link")}
          </TextButton>
        </Flex>
      </Spacer>
    </Card>
  );
};

export default InviteLink;
