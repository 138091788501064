import React, { useState, useEffect } from "react";
import { Flex, RadioButton } from "@badi/badi-components";

const RadioButtonGroup = ({
  onChangeCallback,
  items,
  name,
  initialValue = "",
  dataQa: groupDataQa = "",
}) => {
  const [selectedOption, setSelectedOption] = useState(initialValue);

  const handleOnChange = ({ currentTarget: { value } }) => {
    const parsedValue = parseInt(value, 10);
    setSelectedOption(parsedValue);
    onChangeCallback(parsedValue);
  };

  useEffect(() => {
    setSelectedOption(initialValue);
  }, [initialValue]);

  return (
    <Flex direction="column" gutter={2}>
      {items.map((item, i) => (
        <RadioButton
          checked={item.value === selectedOption}
          dataQa={groupDataQa}
          id={`${name}-${i}`}
          key={item.value}
          label={item.title}
          name={name}
          onChange={handleOnChange}
          value={item.value}
        />
      ))}
    </Flex>
  );
};

export default RadioButtonGroup;
