import { translate } from "base/shared/Localization";

export const parseFilter = (filter) => {
  if (!filter) return { value: "", cluster: 0 };

  const { value: filterValue, cluster, showCancelButton } = filter;

  if (filterValue && typeof filterValue === "object") {
    const { value, text } = filterValue;

    return { value: translate(text, value), cluster, showCancelButton };
  }

  return { value: translate(filterValue), cluster, showCancelButton };
};

export const areFiltersApplied = (filters = {}) =>
  Object.values(filters).some(({ value, cluster }) => !!value || !!cluster);

// this logic it will be not necessary when we refactor tracking
// adding a middleware
export const parseFilterToKebabCase = (action) => {
  const filter = action.substring(5);

  return filter
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
};
