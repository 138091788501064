import React, { memo } from "react";

import {
  Flex,
  Pagination,
  Spacer,
  BREAKPOINTS_PX,
  useMatchMedia,
} from "@badi/badi-components";
import RoomCard from "components/Room/room-card";

import { PaginationWrapper } from "Sections/favorites/styled-components";

import { generateRoomCardIdString } from "../utils";

const QUERIES = [
  `(min-width: ${BREAKPOINTS_PX.lg}) and (max-width: ${BREAKPOINTS_PX.xl})`,
];

const Rooms = ({
  rooms,
  showArrows = true,
  showBillsIncludedFlag = false,
  showPriceDiscount = false,
  matchProfileFlag,
  getFavoritesPerPage,
  pagination,
  cardType = "search",
}) => {
  const { currentPage, totalPages } = pagination;
  const [isLg] = useMatchMedia(QUERIES);

  return (
    <>
      <Flex
        direction="column"
        gutter={4}
        md={{ justify: "spaceBetween", wrap: "wrap" }}
      >
        {rooms.map((room) => {
          const { price = {}, previousPrice } = room;
          const { displayText, billsIncluded } = price;

          return (
            <RoomCard
              basis={isLg ? "100%" : "auto"}
              htmlId={generateRoomCardIdString(room.id)}
              key={room.id}
              openSameTab={true}
              {...room}
              billsIncluded={billsIncluded}
              matchProfile={matchProfileFlag && room.matchProfile}
              previousPrice={showPriceDiscount ? previousPrice : null}
              priceText={displayText}
              showArrows={showArrows}
              showBillsIncludedFlag={showBillsIncludedFlag}
              type={cardType}
            />
          );
        })}
      </Flex>
      {totalPages > 1 && (
        <PaginationWrapper>
          <Spacer bottom={4} top={4}>
            <Pagination
              currentPage={currentPage}
              onSelectPage={getFavoritesPerPage}
              totalPages={0}
            />
          </Spacer>
        </PaginationWrapper>
      )}
    </>
  );
};

export default memo(Rooms);
