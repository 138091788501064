import React from "react";

export default ({ color = "#1E3255", width = "200px", height = "50px" }) => (
  <svg
    height={height}
    version="1.1"
    viewBox="0 0 63.1 24.5"
    width={width}
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g>
      <path
        d="M55.8,24.1c-1.3,0-2.4,0-3.7,0c-0.1-0.6-0.2-1.3-0.4-2.3c-2.1,2.5-4.7,3.1-7.4,2.4c-1.6-0.4-3.3-1.6-4.4-2.9
    c-2.7-3.3-2.1-9,0.9-11.9c2.8-2.7,7.1-2.7,10.9,0.3c0-3.1,0-6.2,0-9.3c1.5,0,2.7,0,4,0C55.8,8.3,55.8,16.1,55.8,24.1z M42.5,15.9
    c0,2.9,2,5.1,4.7,5.1c2.7,0,4.7-2.3,4.7-5.1c0-2.9-2.1-5.1-4.7-5.1C44.4,10.8,42.5,13,42.5,15.9z"
        fill={color}
      />
      <path
        d="M4,22.1c-0.1,0.7-0.2,1.3-0.3,2c-1.3,0-2.4,0-3.7,0C0,16.2,0,8.3,0,0.4c1.3,0,2.5,0,3.9,0c0,3.1,0,6.1,0,9.8
    C6.1,7.5,8.6,7,11.2,7.7c1.6,0.4,3.2,1.4,4.3,2.7c2.9,3.3,2.5,9.1-0.5,12.1C12,25.2,7.7,25.1,4,22.1z M13.1,16c0-3-1.9-5.2-4.6-5.2
    c-2.7,0-4.7,2.2-4.7,5.2c0,2.9,1.9,5,4.5,5.1C11.2,21.1,13.1,19,13.1,16z"
        fill={color}
      />
      <path
        d="M32.4,9.9c0.1-0.8,0.2-1.4,0.3-2.1c1.1,0,2.2,0,3.6,0c0.1,0.7,0.2,1.4,0.2,2.1c0,3.7,0,7.4,0,11.2
    c0,3.5,0,3.5-3.7,2.9c-0.1-0.5-0.2-1.1-0.4-2.1c-2.1,2.5-4.8,3.1-7.6,2.2c-1.6-0.5-3.2-1.7-4.3-3c-2.5-3.3-1.9-9,1.1-11.7
    C24.5,6.7,28.8,6.8,32.4,9.9z M32.4,16.1c0.1-3.1-1.8-5.2-4.5-5.3c-2.7-0.1-4.7,2.2-4.8,5.1c0,3,2,5.2,4.7,5.2
    C30.5,21,32.4,19,32.4,16.1z"
        fill={color}
      />
      <path
        d="M58.8,7.7c1.3,0,2.4,0,3.7,0c0,5.4,0,10.8,0,16.3c-1.1,0-2.4,0-3.7,0C58.8,18.6,58.8,13.2,58.8,7.7z"
        fill={color}
      />
      <path
        d="M60.9,0c1.3,0.2,2.2,0.8,2.2,2.4c0,1.5-0.7,2.4-2.2,2.4c-1.5,0.1-2.5-1-2.5-2.4C58.4,1,59.4,0,60.9,0z"
        fill={color}
      />
    </g>
  </svg>
);
