import React from "react";
import { Flex, Text, Heading, StatusCard, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const BankAccountVerified = ({ bankAccountLastDigits }) => (
  <StatusCard isEditable={true} isVerified={true}>
    <Spacer bottom={3}>
      <Heading level="20-Bold">
        {translate("payout.bank_account.title")}
      </Heading>
    </Spacer>
    <Flex direction="column" gutter={1}>
      <Text body="14-Bold">{translate("payout.bank_account.number")}</Text>
      <Text>**** {bankAccountLastDigits}</Text>
    </Flex>
  </StatusCard>
);

export default BankAccountVerified;
