import { connect } from "react-redux";

import { acceptVisitProposal, cancelVisitProposal } from "datamodel/visits/api";
import { leaveInboxConversation } from "datamodel/Conversation/api";
import { getToken, getUserId } from "datamodel/Auth/selectors";
import VisitActions from "./visit-actions";

const mapStateToProps = (state) => ({
  token: getToken(state),
  userId: getUserId(state),
});

const mergeProps = (state, _, ownProps) => {
  const { connectionId } = ownProps;
  return {
    ...state,
    acceptVisitProposal: (visitId) =>
      acceptVisitProposal({ token: state.token }, { visitId }),
    cancelVisitProposal: (visitId) =>
      cancelVisitProposal({ token: state.token }, { visitId }),
    leaveInboxConversation: () =>
      leaveInboxConversation(connectionId, state.token),
    ...ownProps,
  };
};

export default connect(mapStateToProps, null, mergeProps)(VisitActions);
