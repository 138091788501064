import React, { useState } from "react";

import Button from "components/Button/TextButton";
import { Icon } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";

import MenuBody from "./menu-body";
import { TopBarMenuRoot } from "./styled-components";

const Menu = ({ dataQa = "menu", elements, position = "" }) => {
  const [visible, setVisible] = useState(false);

  const toggleMenu = () => {
    setVisible(!visible);
  };

  return (
    <TopBarMenuRoot data-qa={dataQa} position={position}>
      <Button onClick={toggleMenu}>
        <Icon
          alt="menu"
          iconMaxHeight={20}
          iconMaxWidth={15}
          src={getPublicAsset("icons/home/menu_mobile.svg")}
        />
      </Button>
      <MenuBody elements={elements} onClose={toggleMenu} visible={visible} />
    </TopBarMenuRoot>
  );
};

export default Menu;
