import { connect } from "react-redux";

import { getToken, getUserId } from "datamodel/Auth/selectors";
import VisitExpress from "./visit-express";

const mapStateToProps = (state) => ({
  token: getToken(state),
  userId: getUserId(state),
});

export default connect(mapStateToProps)(VisitExpress);
