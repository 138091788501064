import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { OverviewListerScore } from "./models";

const fetchOverviewSummary = (token) => {
  const { BADI_OVERVIEW_SUMMARY_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_OVERVIEW_SUMMARY_SERVICE);
  return fetch(serverUrl, { params: { platform: "web" }, token }).then(
    (res) => res.data,
  );
};

const fetchRoomsOverview = (token, params) => {
  const { BADI_OVERVIEW_ROOMS_OVERVIEW_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_OVERVIEW_ROOMS_OVERVIEW_SERVICE);
  return fetch(serverUrl, { params, token }).then((res) => res);
};

const fetchListingStatuses = (token) => {
  const { BADI_OVERVIEW_LISTINGS_STATUSES } = envVars();
  const serverUrl = getServerUrl(BADI_OVERVIEW_LISTINGS_STATUSES);
  return fetch(serverUrl, { params: { platform: "web" }, token }).then(
    (res) => res.data,
  );
};

const fetchListerScore = ({ token }) => {
  const { BADI_OVERVIEW_SCORE } = envVars();
  const serverUrl = getServerUrl(BADI_OVERVIEW_SCORE);

  return fetch(serverUrl, { token }).then((res) =>
    new OverviewListerScore(res.data).toJSON(),
  );
};

export {
  fetchOverviewSummary,
  fetchRoomsOverview,
  fetchListingStatuses,
  fetchListerScore,
};
