import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "INBOX_ERROR",
  "INBOX_REQUEST",
  "INBOX_SUCCESS",
  "INBOX_CONNETION_UPDATE",
  "INBOX_CATEGORY_SET",
  "INBOX_ROOM_SET",
  "INBOX_ROOM_CLEAN",
  "INBOX_CONNTECTIONS_PER_PAGE_SET",
  "INBOX_NOTIFICATIONS_COUNT",
  "INBOX_UNREAD_CONNECTIONS_REQUEST",
  "INBOX_UNREAD_CONNECTIONS_ERROR",
  "INBOX_UNREAD_CONNECTIONS_SUCCESS",
  "INBOX_CLEAR_CONNECTIONS",
  "INBOX_ROOMS_SUMMARY_REQUEST",
  "INBOX_ROOMS_SUMMARY_SUCCESS",
  "INBOX_ROOMS_SUMMARY_ERROR",
]);
