import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import {
  MetricEvolution,
  AnalyticsRoomModel,
  RoomMetrics,
  Hint,
} from "./models";

const getRooms = ({ token }, params) => {
  const { sgid } = params;
  const { BADI_ANALYTICS_ROOMS } = envVars();

  const parsedParams = sgid ? { sgid } : null;

  const serverUrl = getServerUrl(BADI_ANALYTICS_ROOMS);

  return fetch(serverUrl, { token, params: parsedParams }).then((res) => {
    return res.data.rooms.map((room) => new AnalyticsRoomModel(room).toJSON());
  });
};

const getRoomDetails = ({ token }, params) => {
  const { roomId, sgid } = params;
  const { BADI_ANALYTICS_ROOMS } = envVars();

  const parsedParams = sgid ? { sgid } : null;

  const serverUrl = getServerUrl(`${BADI_ANALYTICS_ROOMS}/${roomId}`);

  return fetch(serverUrl, { token, params: parsedParams }).then((room) =>
    new AnalyticsRoomModel(room).toJSON(),
  );
};

const getRoomMetrics = ({ token, roomId, sgid }) => {
  const { BADI_ANALYTICS_ROOMS_METRICS } = envVars();

  const parsedParams = sgid ? { sgid } : null;

  const serverUrl = getServerUrl(
    `${BADI_ANALYTICS_ROOMS_METRICS.replace(":id", roomId)}`,
  );
  return fetch(serverUrl, { token, params: parsedParams }).then((res) =>
    new RoomMetrics(res.data).toJSON(),
  );
};

const getListerHints = ({ token }, params) => {
  const { roomId, sgid } = params;
  const { BADI_HINTS_LISTER } = envVars();

  const parsedParams = sgid ? { room_id: roomId, sgid } : { room_id: roomId };

  const serverUrl = getServerUrl(BADI_HINTS_LISTER);

  return fetch(serverUrl, { token, params: parsedParams }).then((res) => {
    return res.data.hints.map((hint) => new Hint(hint).toJSON());
  });
};

export { getRooms, getRoomDetails, getRoomMetrics, getListerHints };
