import styled from "styled-components";
import { colors, unitizedPx } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const MessageTip = styled.div`
  position: absolute;
  top: ${unitizedPx(2)};
  left: ${unitizedPx(2)};
  padding: ${unitizedPx(1)} ${unitizedPx(2)};

  background-color: ${colors.white};
  border-radius: ${unitizedPx(0.5)};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
`;

export const Marker = styled.div`
  position: absolute;
  align-self: center;
  width: ${unitizedPx(5)};
  height: ${unitizedPx(5)};
  margin-top: -20px;
  background-image: url("${getCDNUrl()}/assets/icons/pin.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
`;
