export const BOOKING_BENEFITS = [
  { id: 1, description: "contact.flow.stepper_benefit_zero" },
  { id: 2, description: "contact.flow.stepper_benefit_one" },
  { id: 3, description: "contact.flow.stepper_benefit_two" },
  {
    id: 4,
    description: "contact.flow.stepper_benefit_three",
  },
];

export const MODAL_BOOKING_BENEFITS = [
  {
    number: 1,
    title: "roomdetail.bottomsheet.section_one_title",
    description: "roomdetail.bottomsheet.section_one_desc",
  },
  {
    number: 2,
    title: "roomdetail.bottomsheet.section_two_title",
    description: "roomdetail.bottomsheet.section_two_desc",
  },
  {
    number: 3,
    title: "roomdetail.bottomsheet.section_three_title",
    description: "roomdetail.bottomsheet.section_three_desc",
  },
];
