import React from "react";

export const Eye = ({ color }) => (
  <svg
    display="block"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.665 11.178l.19.303a1 1 0 01.063.12l.024.066.021.063.028.143.009.129-.007.116-.02.113-.031.108-.044.103-.047.086c-.78 1.262-1.65 2.357-2.592 3.27l-.357.335c-5.332 4.819-12.183 3.472-16.556-3.294l-.201-.32a1 1 0 01-.136-.381L2 12.002c0-.19.052-.371.15-.53 5.269-8.526 14.142-8.63 19.515-.294zm-17.298.558L4.188 12l.04.061c3.658 5.417 8.864 6.433 13.118 2.778l.215-.19a13.876 13.876 0 002.054-2.36l.196-.291-.012-.017c-4.4-6.56-10.988-6.64-15.432-.245zM12 8a1 1 0 01.117 1.993L12 10c-1.102 0-2 .898-2 2 0 1.102.898 2 2 2 1.102 0 2-.898 2-2a1 1 0 012 0c0 2.206-1.793 4-4 4-2.206 0-4-1.793-4-4s1.794-4 4-4z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export const Calendar = ({ color }) => (
  <svg
    display="block"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.004 2a1 1 0 01.993.883l.007.117v1H18a4 4 0 013.995 3.8L22 8v5.01a1 1 0 01-1.993.117L20 13.01V8a2 2 0 00-1.85-1.995L18 6h-.996v1a1 1 0 01-1.993.117L15.004 7V6h-6v1a1 1 0 01-1.993.117L7.004 7V6H6a2 2 0 00-1.995 1.85L4 8v10a2 2 0 001.85 1.995L6 20h12a2 2 0 001.995-1.85L20 18v-.933a1 1 0 011.993-.117l.007.117V18a4 4 0 01-3.8 3.995L18 22H6a4 4 0 01-3.995-3.8L2 18V8a4 4 0 013.8-3.995L6 4h1.004V3a1 1 0 011.993-.117L9.004 3v1h6V3a1 1 0 011-1zm-7 8a3 3 0 010 6h-1a3 3 0 010-6zm0 2h-1a1 1 0 000 2h1a1 1 0 000-2z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export const Home = ({ color }) => (
  <svg
    display="block"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.513 2.134l.112.075 10.35 8a1 1 0 01-1.118 1.653l-.104-.07-.753-.583v2.816a1 1 0 01-1.993.116L19 14.025V9.663l-6.987-5.4L5 9.669v8.384a2 2 0 001.85 1.995l.15.005h10a2 2 0 001.995-1.85l.005-.15a1 1 0 012 0 4 4 0 01-3.68 3.988l-.247.012H7a4 4 0 01-3.995-3.8l-.005-.2v-6.842l-.754.581a1 1 0 01-1.32-.087l-.082-.094a1 1 0 01.087-1.32l.094-.083 10.378-8a1 1 0 011.11-.074zm4.35 8.22l.087.078a1 1 0 01.077 1.327l-.077.087-5.657 5.657a1 1 0 01-1.327.078l-.087-.078-2.829-2.829a1 1 0 011.327-1.492l.087.078 2.121 2.122 4.95-4.95a1 1 0 011.328-.078z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);
