import React, { memo } from "react";
import { Col } from "reactstrap";
import { Spacer, Flex, Heading } from "@badi/badi-components";
import { Wrapper } from "./styled-components";

const COLUMN_PROPS = { size: 4, offset: 1 };

const BookingSidebar = ({ children = null, title = null }) => (
  <Col lg={COLUMN_PROPS} sm={12}>
    <Wrapper>
      <Spacer bottom={1} lg={{ top: 0 }} top={2}>
        {title && (
          <Heading data-qa="booking-sidebar-title" level={2}>
            {title}
          </Heading>
        )}
      </Spacer>
      <Flex direction="column" gutter={4}>
        {children}
      </Flex>
    </Wrapper>
  </Col>
);

export default memo(BookingSidebar);
