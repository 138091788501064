class CountryVatModel {
  constructor(data) {
    this.entity = data || {};
  }

  get countryCode() {
    return this.entity.country_code || "DEFAULT";
  }

  get country() {
    return this.entity.country_of_residence || "";
  }

  get spainOverseas() {
    return this.entity.spain_overseas || false;
  }

  toJSON() {
    return {
      countryCode: this.countryCode,
      country: this.country,
      spainOverseas: this.spainOverseas,
    };
  }
}

export default CountryVatModel;
