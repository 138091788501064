import React, { useEffect, useState } from "react";

import { translate } from "base/shared/Localization";
import { getRelativePath } from "base/shared/Utils";

import ListerScoreBadge from "components/lister-score-badge";
import InfoCard from "../info-card";

const ListerScoreCard = ({ listerScore }) => {
  if (!listerScore) {
    return null;
  }

  const badge = <ListerScoreBadge score={listerScore} small={false} />;

  return (
    <InfoCard
      badge={badge}
      badgeTitle={translate("lister_score_element_badge_label")}
      content={translate("lister_score_element_new_text")}
      href={getRelativePath("/lister-score")}
      icon="/assets/icons/overview-lister-score.svg"
      title={translate("lister_score_element_new_title_improve")}
    />
  );
};

ListerScoreCard.defaultProps = {
  listerScore: "",
};

export default ListerScoreCard;
