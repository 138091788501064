const getFeatureFlagValue = (feature, defaultValue = false) => {
  return feature ? !!feature.flag : defaultValue;
};

class FeatureFlag {
  constructor(data) {
    this.data = data || {};
  }

  get bookingMoveOutRequired() {
    return getFeatureFlagValue(this.data.mandatory_move_out);
  }

  get recommendationsMatchProfileBadge() {
    return getFeatureFlagValue(this.data.recommendations_match_profile_badge);
  }

  get filterChatInvites() {
    return getFeatureFlagValue(this.data.filter_chat_invites);
  }

  get cardExperiment() {
    const { type } = this.data.card_experiment || { type: "" };
    return type;
  }

  get matchProfileBadge() {
    return getFeatureFlagValue(this.data.match_profile_badge);
  }

  get bonusSchemeLondon() {
    const { allowed, flag, url } = this.data.bonus_scheme_london || {
      allowed: false,
      flag: false,
      url: "",
    };
    return { allowed, flag, url };
  }

  get showBillsIncludedRoomCard() {
    return getFeatureFlagValue(this.data.room_card_bills_included);
  }

  get pushNotifications() {
    return getFeatureFlagValue(this.data.web_push_notifications);
  }

  get skipMessageStep() {
    return getFeatureFlagValue(this.data.skip_message_step);
  }

  get immediateListingFlow() {
    return getFeatureFlagValue(this.data.immediate_listing_flow);
  }

  get visitsExpress() {
    return getFeatureFlagValue(this.data.express_visit);
  }

  get listingFlowWithBankAccount() {
    return getFeatureFlagValue(this.data.listing_flow_with_bank_account_step);
  }

  get skipEnquiryAcceptAndDecline() {
    return getFeatureFlagValue(this.data.skip_enquiry_accept_and_decline);
  }

  get addAccountDetailsProfile() {
    return getFeatureFlagValue(this.data.add_account_details_on_profile);
  }

  get addPaymentDetailsProfile() {
    return getFeatureFlagValue(this.data.add_payment_details_on_profile);
  }

  get showPriceDiscounts() {
    return getFeatureFlagValue(this.data.show_price_discounts);
  }

  get isFavoriteToggleActive() {
    return getFeatureFlagValue(this.data.release_favorites_toggle);
  }

  get legalServices() {
    return getFeatureFlagValue(this.data.golden_pot_legal_services);
  }

  get contractOfferedLabel() {
    return getFeatureFlagValue(this.data.room_card_contract_included);
  }

  get standaloneBoosting() {
    return getFeatureFlagValue(this.data.standalone_boosting_plan);
  }

  get professionalPhotos() {
    return getFeatureFlagValue(
      this.data.listing_flow_professional_photos_service,
    );
  }

  get sepaDebitDetails() {
    return getFeatureFlagValue(this.data.sepa_debit_first_iteration);
  }

  get matchingModel() {
    return getFeatureFlagValue(this.data.release_matching_model);
  }

  get completeProfileBanner() {
    return getFeatureFlagValue(this.data.complete_profile_push);
  }

  get swipingProfileCards() {
    return getFeatureFlagValue(this.data.swiping_profile_cards);
  }

  get swipingSeekerExperience() {
    return getFeatureFlagValue(this.data.swiping_seeker_experience);
  }

  toJSON() {
    return {
      bookingMoveOutRequired: this.bookingMoveOutRequired,
      filterChatInvites: this.filterChatInvites,
      recommendationsMatchProfileBadge: this.recommendationsMatchProfileBadge,
      matchProfileBadge: this.matchProfileBadge,
      cardExperiment: this.cardExperiment,
      bonusSchemeLondon: this.bonusSchemeLondon,
      pushNotifications: this.pushNotifications,
      showBillsIncludedRoomCard: this.showBillsIncludedRoomCard,
      skipMessageStep: this.skipMessageStep,
      immediateListingFlow: this.immediateListingFlow,
      skipEnquiryAcceptAndDecline: this.skipEnquiryAcceptAndDecline,
      listingFlowWithBankAccount: this.listingFlowWithBankAccount,
      addAccountDetailsProfile: this.addAccountDetailsProfile,
      addPaymentDetailsProfile: this.addPaymentDetailsProfile,
      showPriceDiscounts: this.showPriceDiscounts,
      isFavoriteToggleActive: this.isFavoriteToggleActive,
      visitsExpress: this.visitsExpress,
      legalServices: this.legalServices,
      contractOfferedLabel: this.contractOfferedLabel,
      standaloneBoosting: this.standaloneBoosting,
      professionalPhotos: this.professionalPhotos,
      sepaDebitDetails: this.sepaDebitDetails,
      matchingModel: this.matchingModel,
      completeProfileBanner: this.completeProfileBanner,
      swipingProfileCards: this.swipingProfileCards,
      swipingSeekerExperience: this.swipingSeekerExperience,
    };
  }
}

export default FeatureFlag;
