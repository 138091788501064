import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";

import { getAPIUrl as getServerUrl } from "base/shared/Utils";

import RoomModel from "datamodel/Room/models/room-compact-model";
import Plans from "./models/plans-model";
import Room from "./models/room-model";
import { Capabilities } from "./models";

export const getPlans = ({ token }, options = {}) => {
  const { BADI_PLANS } = envVars();
  const serverUrl = getServerUrl(BADI_PLANS);

  const {
    roomId = null,
    feature = null,
    category = null,
    categories = null,
  } = options;

  const params = { platform: "web" };

  if (roomId) {
    params.room_id = roomId;
  }

  if (feature) {
    params.unlock = feature;
  }

  if (category) {
    params.category = category;
  }

  if (categories) {
    params.categories = categories;
  }

  return fetch(serverUrl, {
    token,
    params,
  }).then((response) => new Plans(response.data).toJSON());
};

export const getCapabilities = ({ token }) => {
  const { BADI_PREMIUM_CAPABILITIES } = envVars();
  const serverUrl = getServerUrl(BADI_PREMIUM_CAPABILITIES);

  return fetch(serverUrl, {
    token,
  })
    .then((response) =>
      new Capabilities({
        capabilities: response.data?.capabilities,
      }).toJSON(),
    )
    .catch((error) => {
      return new Capabilities({ error }).toJSON();
    });
};

export const getRoomCapabilities = ({ token }, { roomId } = {}) => {
  const { BADI_PREMIUM_ROOM_CAPABILITIES } = envVars();
  const serverUrl = getServerUrl(
    BADI_PREMIUM_ROOM_CAPABILITIES.replace(":id", roomId),
  );

  return fetch(serverUrl, {
    token,
  })
    .then((response) =>
      new Room({
        id: roomId,
        capabilities: response.data?.capabilities || [],
      }).toJSON(),
    )
    .catch((error) => {
      return new Room({ id: roomId, error }).toJSON();
    });
};

export const getAvailableRoomsFromPlan = (
  { token },
  { planId, page = 1, per = 10 } = {},
) => {
  const { BADI_PREMIUM_AVAILABLE_ROOMS } = envVars();
  const serverUrl = getServerUrl(BADI_PREMIUM_AVAILABLE_ROOMS);

  return fetch(serverUrl.replace(":id", planId), {
    token,
    params: { page, per },
  }).then((response) => {
    const { data, pagination } = response;

    return {
      rooms: data.rooms.map((room) => new RoomModel(room).toJSON()),
      pagination,
    };
  });
};

export const upgradeListings = ({ token }, { idList, tier }) => {
  const { BADI_UPGRADE_LISTINGS } = envVars();
  const serverUrl = getServerUrl(BADI_UPGRADE_LISTINGS);

  return fetch(serverUrl, {
    method: "POST",
    token,
    params: { room_ids: idList, plan_tier: tier },
  });
};

export const postRedeemPromotion = (
  { token },
  { roomId, productReference },
) => {
  const { BADI_PROMOTION_REDEEM } = envVars();
  const serverUrl = getServerUrl(BADI_PROMOTION_REDEEM);

  return fetch(serverUrl, {
    method: "POST",
    token,
    params: { room_id: roomId, product_reference: productReference },
  });
};

const updateListingProPlan = ({ token, roomPlanId, renew = true }) => {
  const { BADI_RENEW_LISTING_PLAN, BADI_STOP_RENEW_LISTING_PLAN } = envVars();
  const serverUrl = getServerUrl(
    renew ? BADI_RENEW_LISTING_PLAN : BADI_STOP_RENEW_LISTING_PLAN,
  );

  return fetch(serverUrl, {
    method: "PUT",
    token,
    params: { room_plan_id: roomPlanId },
  });
};

export const renewListingProPlan = ({ token }, { roomPlanId }) => {
  return updateListingProPlan({ token, roomPlanId });
};

export const stopRenewListingProPlan = ({ token }, { roomPlanId }) => {
  return updateListingProPlan({ token, roomPlanId, renew: false });
};
