import styled, { css } from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const StepsWrapper = styled.div`
  background-color: ${BADI_COLORS.ADDITIONAL.ADDITIONAL_07};
  border-radius: ${unitizedPx(1)};

  padding: ${unitizedPx(1)} ${unitizedPx(3)};

  width: 100%;
`;

const activeStyles = css`
  &:after {
    content: "";
    width: 72px;
    position: absolute;
    bottom: -12px;
    height: 4px;
    border-radius: 2px;
    background-color: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
    left: calc(50% - 36px);
  }
`;

export const StepWrapper = styled.div`
  position: relative;

  ${({ active }) => active && activeStyles}
`;
