import styled, { css } from "styled-components";
import { colors } from "@badi/badi-components";

export const Root = styled.div`
  position: relative;
  width: 80px;
  height: 40px;
  border-radius: 20px;
  background-color: ${colors.lightGrey};
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const ToggleButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  ${({ toRight }) =>
    toRight
      ? css`
          transform: translateX(40px);
        `
      : null}
  transition: transform .2s ease-out;

  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: solid 3px ${colors.lightGrey};
  background-color: ${colors.white};
  box-sizing: border-box;

  margin: 0;
  padding: 0;
`;

export const IconsWrapper = styled.div`
  position: relative;
  height: 100%;
  left: 0;
  right: 0;

  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
