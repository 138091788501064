export const INPUT_BIRTHDATE = "birthdate";
export const INPUT_NAME = "name";
export const INPUT_SURNAME = "surname";
export const INPUT_POSTALCODE = "postalcode";
export const INPUT_ADDRESS = "address";
export const INPUT_CITY = "city";

export const VALIDATIONS_NAME = [
  ["minLength", 2],
  ["maxLength", 20],
];
export const VALIDATIONS_SURNAME = [
  ["minLength", 2],
  ["maxLength", 20],
];
export const VALIDATIONS_POSTALCODE = [
  ["minLength", 4],
  ["maxLength", 10],
];
export const VALIDATIONS_ADDRESS = [
  ["minLength", 2],
  ["maxLength", 50],
];
export const VALIDATIONS_CITY = [
  ["minLength", 2],
  ["maxLength", 20],
];
