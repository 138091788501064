import React, { Component } from "react";

import { translate } from "base/shared/Localization";

import Spinner from "components/spinner";
import { getPublicAsset } from "base/shared/Utils";

class SwitchSpinner extends Component {
  constructor(props) {
    super(props);

    this.icons = {
      lister: getPublicAsset("icons/ico_bed_color.svg"),
      seeker: getPublicAsset("icons/ico_search_color.svg"),
    };
  }

  getStartIconSrc(switchingTo) {
    if (switchingTo === "lister") return this.icons.seeker;

    return this.icons.lister;
  }

  render() {
    const { switchingTo } = this.props;

    if (!switchingTo) return null;

    return (
      <Spinner
        fromSrc={this.getStartIconSrc(switchingTo)}
        text={translate(`Switching to ${switchingTo} mode`)}
        toSrc={this.icons[switchingTo]}
      />
    );
  }
}

SwitchSpinner.defaultProps = {
  switchingTo: null,
};

export default SwitchSpinner;
