export const SHRINK_VALUES = ["inherit", "initial", "unset"];

export const ALIGN_CHOICES = [
  "stretch",
  "flexStart",
  "flexEnd",
  "center",
  "baseline",
];

export const JUSTIFY_CHOICES = [
  "flexStart",
  "flexEnd",
  "center",
  "spaceBetween",
  "spaceAround",
  "spaceEvenly",
];

export const WRAP_CHOICES = ["nowrap", "wrap", "wrapReverse"];

export const BASE_PROPS = [
  "direction",
  "gutter",
  "justify",
  "alignItems",
  "reverse",
  "wrap",
];
