import styled from "styled-components";
import { mediaquery, Flex, unitizedPx, Button } from "@badi/badi-components";
import BadiLogo from "components/Icons/BadiLogoDisplay";

const badiHomesGreen = "#283618";
const badiHomesGreenLight = "#d4dcbc";

export const ModalWrapper = styled.div`
  font-family: Spectral;
  text-rendering: geometricPrecision;
  color: ${badiHomesGreen};
  display: flex;
  flex-direction: column;
  align-tems: center;
  background-color: #ffffff;
  min-height: 100%;
  padding: ${unitizedPx(2)} ${unitizedPx(1.5)};
  ${mediaquery.lg`
    padding: ${unitizedPx(4)} ${unitizedPx(6)};
  `}
`;

export const SelectorWrapper = styled.div`
  position: relative;
  background-image: url(${({ picture }) => picture});
  background-position: center center;
  background-size: cover;
  border-radius: ${unitizedPx(1)};
  padding: ${unitizedPx(2.5)};
  height: ${unitizedPx(43)};
  width: 100%;
  ${mediaquery.lg`
    position: relative;
    height: 100%;
    min-height: ${unitizedPx(38)};
    padding: ${unitizedPx(8)};
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  background-image: url(${({ picture }) => picture});
  background-position: center center;
  background-size: 200%;
  height: ${unitizedPx(18)};
  width: 100%;
  background-repeat: no-repeat;
  ${mediaquery.md`
    background-size: cover;
    height: ${unitizedPx(36)};
  `}
  ${mediaquery.lg`
    background-size: cover;
    height: ${unitizedPx(36)};
  `}
  @media (min-width: 1600px) {
    height: ${unitizedPx(52)};
  }
`;

export const ImageWrapperBg = styled.div`
  position: relative;
  z-index: 0;
  background-image: url(${({ picture }) => picture});
  background-position: -36px center;
  background-size: cover;
  height: ${unitizedPx(18)};
  width: 100%;
  background-repeat: no-repeat;
  ${mediaquery.md`
    height: ${unitizedPx(25)};
  `}
  ${mediaquery.lg`
    position: absolute;
    background-position: 66% center;
    height: 100%;
  `}
  ${mediaquery.xxl`
    position: absolute;
    background-position: center center;
    height: 100%;
  `}
`;

export const FullWidthFlex = styled(Flex)`
  position: relative;
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  width: 100%;
  flex: 1;
  margin: auto;
`;

export const BadiHomesButton = styled(Button)`
  background-color: ${badiHomesGreen};
  color: #fff;
  border-radius: 0px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  word-spacing: 0;
  font-family: Spectral;
  text-rendering: geometricPrecision;
  font-weight: 400;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px 55px;
  gap: 10px;
  height: auto;
  z-index: 1;
  &:hover {
    background-color: ${badiHomesGreenLight};
    color: ${badiHomesGreen};
  }
`;

export const BadiLogoCustom = styled(BadiLogo)`
  width: 20px;
`;

export const BadiHomesMenu = styled.div`
  cursor: pointer;
  color: ${badiHomesGreen};
  font-size: 18px;
  line-height: 20px;
  text-rendering: geometricPrecision;
  &:hover {
    color: ${badiHomesGreenLight};
  }
`;

export const NewTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 1;
`;

export const LongText = styled.div`
  font-size: 14px;
  line-height: 18px;
  position: relative;
  z-index: 1;
  ${mediaquery.lg`
    font-size: 16px;
    line-height: 20px;
  `}
  ${mediaquery.xxl`
    font-size: 18px;
    line-height: 22px;
  `}
  @media (min-width: 1600px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const Newtag = styled.div`
  font-size: 10px;
  line-height: 14px;
  padding-left: 12px;

  color: #000;
  text-decoration-line: underline;
  text-transform: uppercase;
  ${mediaquery.lg`
  font-size: 12px;
  padding-left: 14px;
  line-height: 12px;
  `}
  ${mediaquery.xxl`
  font-size: 14px;
  padding-left: 16px;
  line-height: 18px;
  `}
  @media (min-width: 1600px) {
    font-size: 16px;
    padding-left: 18px;
    line-height: 20px;
  }
`;

export const BadiHomesTitle = styled.h1`
  font-size: 36px;
  line-height: 38px;
  font-weight: 400;
  ${mediaquery.lg`
    font-size: 39px;
    line-height: 39px;
  `}

  ${mediaquery.xxl`
    font-size: 55px;
    line-height: 56px;
  `}
`;

export const BadiHomesSubtitle = styled.h2`
  font-size: 28px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 5px;
  ${mediaquery.lg`
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 5px;
  `}
  ${mediaquery.xxl`
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 10px;
  `}
  @media (min-width: 1600px) {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 12px;
  }
`;

export const JustifiedSpacer = styled.div`
  margin-bottom: ${({ bottom }) => unitizedPx(bottom)};
  display: flex;
  justify-content: space-between;
`;
