import React, { useEffect, useState } from "react";

import MobileMenuItem from "./mobile-menu-item";
import { Ul } from "./styled-components";

const MobileMenu = ({
  listingObjectMobileActionsOpen,
  renderActionsInMenu,
  roomId,
  sections,
  setMobileActionsMenu,
}) => {
  const [unselectByForce, setUnselectByForce] = useState(null);

  useEffect(() => {
    setUnselectByForce(listingObjectMobileActionsOpen.listingId === roomId);
  }, [roomId, listingObjectMobileActionsOpen]);

  const categorySelected = (clickedSection) => {
    const { listingId, sectionId } = listingObjectMobileActionsOpen;
    const sectionToSet =
      listingId !== roomId ||
      (listingId === roomId && sectionId !== clickedSection)
        ? clickedSection
        : null;
    setMobileActionsMenu(sectionToSet);
  };

  const { sectionId } = listingObjectMobileActionsOpen;

  return (
    <Ul>
      {sections.map((category) => (
        <MobileMenuItem
          {...category}
          actionsInMenu={renderActionsInMenu(category.subCategories)}
          key={category.id}
          onClick={() => categorySelected(category.id)}
          renderHeader={category.renderHeader}
          selected={sectionId === category.id && unselectByForce}
        />
      ))}
    </Ul>
  );
};

export default MobileMenu;
