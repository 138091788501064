import React from "react";
import {
  Flex,
  Heading,
  Label,
  Spacer,
  Text,
  BADI_COLORS,
  useMatchMedia,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";
import { PremiumLabel, usePremium } from "modules/premium";
import SeekerBar from "../SeekerBar";
import {
  ClickableLayer,
  ContentWrapper,
  ImageWrapper,
  Root,
  Address,
  PremiumWrapper,
} from "./styled-components";
import { SCREEN_SIZE_QUERIES, IMAGE_STYLES } from "./constants";

const CardDetails = ({
  room: {
    address,
    availabilityStatus,
    coverPicture,
    pendingBookingProposals,
    priceDescription,
    seeker,
    title,
    planLabel = null,
    planRenewalStatus = null,
    externalAgencyId = null,
  },
  room,
  viewSeekerProfile = () => {},
  showPreview,
}) => {
  const { premiumEnabled } = usePremium();
  const [isTablet, isDesktop] = useMatchMedia(SCREEN_SIZE_QUERIES);

  return (
    <Flex as={Root}>
      {!isDesktop && (
        <ClickableLayer onClick={showPreview} role="presentation" />
      )}
      <ImageWrapper>
        <Image alt="room" src={coverPicture} styles={IMAGE_STYLES} tag="div" />
        {premiumEnabled && planLabel && (
          <PremiumWrapper>
            <PremiumLabel />
          </PremiumWrapper>
        )}
      </ImageWrapper>
      <ContentWrapper grow={1}>
        <Flex direction="column" gutter={1}>
          <Spacer bottom={1}>
            <Text
              body="12-Medium"
              color={
                pendingBookingProposals
                  ? BADI_COLORS.SEMANTIC.WARNING.DEFAULT
                  : BADI_COLORS.NEUTRAL.NEUTRAL_50
              }
              dataQa="listing-card-availability"
            >
              {availabilityStatus}
            </Text>
          </Spacer>
          <Text body="14-Bold" dataQa="listing-card-title">
            {title}
          </Text>
          <Address body="14-Regular" dataQa="listing-card-address">
            {address}
          </Address>
          {isTablet && (
            <Spacer bottom={1} top={1}>
              <Heading data-qa="listing-card-price" level="16-Bold">
                {priceDescription}
              </Heading>
            </Spacer>
          )}
          {externalAgencyId && (
            <Text body="12-Regular" dataQa="room-card-external-agency-id">
              {`${translate(
                "listing.card.externalAgencyId",
              )}: ${externalAgencyId}`}
            </Text>
          )}
          {planRenewalStatus === false && (
            <Label dataQa="listing-card-plan-paused" variant="temporary">
              {translate("pro_paused.one.listing.label")}
            </Label>
          )}
          {seeker && (
            <SeekerBar
              onClick={viewSeekerProfile}
              room={room}
              variation={"details"}
            />
          )}
        </Flex>
      </ContentWrapper>
    </Flex>
  );
};

export default CardDetails;
