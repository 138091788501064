import React from "react";

import { translate } from "base/shared/Localization";
import { composeLanguages } from "base/shared/User";

const UserLanguages = ({ className, languages, title }) => {
  if (!languages.length) return null;

  return (
    <div className={className}>
      <h3>{translate(title)}</h3>
      <div className="person__text">{composeLanguages(languages)}</div>
    </div>
  );
};

UserLanguages.defaultProps = {
  className: "person__box person__box_lang",
};

export default UserLanguages;
