import React, { useEffect, memo } from "react";

import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";

import Lister from "./components/lister-top-bar";
import Public from "./components/public-top-bar";
import Seeker from "./components/seeker-top-bar";

const PUBLIC_TOP_BAR = "public";
const TOPBAR = {
  [LISTER_USER_ROLE]: Lister,
  [SEEKER_USER_ROLE]: Seeker,
  [PUBLIC_TOP_BAR]: Public,
};

const getTopBar = ({ isLoggedIn, userMode }) => {
  if (!isLoggedIn) return TOPBAR[PUBLIC_TOP_BAR];

  return TOPBAR[userMode] || TOPBAR[PUBLIC_TOP_BAR];
};

const TopBar = ({
  isLoggedIn,
  getUnreadConnections,
  inboxFetched,
  isSocketReady,
  mode: userMode,
  socket,
  ...rest
}) => {
  useEffect(() => {
    if (!isLoggedIn || inboxFetched) return;

    getUnreadConnections();
  }, [isLoggedIn, inboxFetched]);

  const TopBarComponent = getTopBar({ isLoggedIn, userMode });

  return <TopBarComponent {...rest} />;
};

TopBar.defaultProps = {
  city: null,
  socket: null,
};

export default memo(TopBar);
