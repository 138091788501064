import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "../types";
import {
  getPaymentMethod,
  setPaymentMethod as setPaymentMethodToApi,
} from "../api";

export function getPayment() {
  return {
    types: generateFetchTypes(Types.PAYMENT_REQUEST),
    request: getPaymentMethod,
    requireAuth: true,
  };
}

export function setPaymentMethod(id, token) {
  return {
    types: generateFetchTypes(Types.PAYMENTMETHOD_REQUEST),
    request: setPaymentMethodToApi({ stripe_token: id }, token),
  };
}

export default {
  getPayment,
  setPaymentMethod,
  resetPayment() {
    return { type: Types.RESET_PAYMENT };
  },
};
