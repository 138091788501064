export const AMENITIES = [
  { value: 1, title: "room.amenities.tv", icon: "Tv" },
  { value: 2, title: "room.amenities.wifi", icon: "Wifi" },
  {
    value: 3,
    title: "room.amenities.airConditioning",
    icon: "AirConditioning",
  },
  { value: 4, title: "room.amenities.parking", icon: "Parking" },
  { value: 5, title: "room.amenities.smoker", icon: "Smoker" },
  { value: 6, title: "room.amenities.pet", icon: "Pet" },
  { value: 7, title: "room.amenities.elevator", icon: "Elevator" },
  { value: 8, title: "room.amenities.heating", icon: "Heating" },
  { value: 9, title: "room.amenities.washingMachine", icon: "WashingMachine" },
  { value: 10, title: "room.amenities.dryer", icon: "Dryer" },
  { value: 12, title: "room.amenities.doorman", icon: "Doorman" },
  { value: 13, title: "room.amenities.couples", icon: "Couples" },
  { value: 14, title: "room.amenities.furnishedRoom", icon: "FurnishedRoom" },
  { value: 15, title: "room.amenities.pool", icon: "Pool" },
  {
    value: 16,
    title: "room.amenities.privateBathroom",
    icon: "PrivateBathroom",
  },
  { value: 17, title: "room.amenities.balcony", icon: "Balcony" },
  { value: 18, title: "room.amenities.naturalLight", icon: "NaturalLight" },
  { value: 19, title: "room.amenities.dishwasher", icon: "Dishwasher" },
  {
    value: 20,
    title: "room.amenities.wheelchairFriendly",
    icon: "WheelchairFriendly",
  },
  { value: 21, title: "room.amenities.garden", icon: "Garden" },
  { value: 22, title: "room.amenities.terrace", icon: "Terrace" },
];

export const COOKIES = {
  APP: "t_app",
  BOOKING_ONBOARDING: "badi_booking_onboarding",
  BOOK_NOW_ONBOARDING: "badi_book_now_onboarding",
  DECLINE_CONVERSATION_MODAL: "badi_decline_modal",
  WHAT_ROOM_INVITE_IS: "badi_room_invite_modal",
  APP_LOCALE: "badi_lang",
  RECOMMENDATIONS_MODAL: "badi_recos_tips_shown",
  VIEWERS_MODAL: "badi_viewers_tips_shown",
  NOTIFICATION_BAR: "badi_notification_bar",
  COMPLETE_PROFILE_BAR: "badi_complete_profile_bar",
  LEGAL_SERVICES_BANNER_ENQUIRY: "badi_legal_services_banner_enquiry",
  LEGAL_SERVICES_BANNER_BOOKING: "badi_legal_services_banner_booking",
  LEGAL_SERVICES_BANNER_MY_LISTINGS: "badi_legal_services_banner_my_listings",
  PROMOTION_MODAL: "badi_show_promotion_modal",
  BADI_DEVICE_ID: "badi_device_id",
  ADVERTISED_MONEYBACK_ROOMS: "badi_advertised_moneyback_rooms",
  ADVERTISED_MONEYBACK_OVERVIEW: "badi_advertised_moneyback_overview",
  BOOKING_EDUCATIONAL_DISPLAYED: "booking_educational_displayed",
};

export const COUPLE = [
  {
    title: "user.section.just_me",
    value: 1,
  },
  {
    title: "user.section.with_partner",
    value: 2,
  },
];

export const GENDERS = [
  {
    title: "onboarding.profile.seeker_gender_female",
    value: 1,
    icon: "Female",
  },
  {
    title: "onboarding.profile.seeker_gender_male",
    value: 2,
    icon: "Male",
  },
  {
    title: "onboarding.profile.seeker_gender_non_binary",
    value: 3,
    icon: "NoBinary",
  },
];

export const GENDERS_ROOMMATES_CHECKBOXES = {
  FEMALE: {
    label: "listing.setup.step6.gender.female",
    dataQa: "gender-preference-female",
    dataQaLabel: "gender-preference-female",
    name: "female",
    value: 1,
  },
  MALE: {
    label: "listing.setup.step6.gender.male",
    dataQa: "gender-preference-male",
    dataQaLabel: "gender-preference-male",
    name: "male",
    value: 2,
  },
  NON_BINARY: {
    label: "listing.setup.step6.gender.non_binary",
    dataQa: "gender-preference-nonBinary",
    dataQaLabel: "gender-preference-nonBinary",
    name: "nonBinary",
    value: 3,
  },
};

export const LANGUAGES = [
  { title: "general.languages.arabic", value: 1 },
  { title: "general.languages.indonesian", value: 2 },
  { title: "general.languages.chinese", value: 3 },
  { title: "general.languages.czech", value: 4 },
  { title: "general.languages.danish", value: 5 },
  { title: "general.languages.german", value: 6 },
  { title: "general.languages.greek", value: 7 },
  { title: "general.languages.english", value: 8, recommended: true },
  { title: "general.languages.esperanto", value: 9 },
  { title: "general.languages.spanish", value: 10, recommended: true },
  { title: "general.languages.french", value: 11, recommended: true },
  { title: "general.languages.hindi", value: 12 },
  { title: "general.languages.italian", value: 13, recommended: true },
  { title: "general.languages.japanese", value: 14 },
  { title: "general.languages.corean", value: 15 },
  { title: "general.languages.hungarian", value: 16 },
  { title: "general.languages.dutch", value: 17 },
  { title: "general.languages.norwegian", value: 18 },
  { title: "general.languages.polish", value: 19 },
  { title: "general.languages.portuguese", value: 20 },
  { title: "general.languages.romanian", value: 21 },
  { title: "general.languages.russian", value: 22 },
  { title: "general.languages.finnish", value: 23 },
  { title: "general.languages.swahili", value: 24 },
  { title: "general.languages.swedish", value: 25 },
  { title: "general.languages.thai", value: 26 },
  { title: "general.languages.turkish", value: 27 },
  { title: "general.languages.vietnamese", value: 28 },
  { title: "general.languages.catalan", value: 29 },
  { title: "general.languages.euskera", value: 30 },
  { title: "general.languages.galician", value: 31 },
  { title: "general.languages.armenian", value: 32 },
  { title: "general.languages.azerbaijani", value: 33 },
  { title: "general.languages.bengali", value: 34 },
  { title: "general.languages.bulgarian", value: 35 },
  { title: "general.languages.farzi", value: 36 },
  { title: "general.languages.filipino", value: 37 },
  { title: "general.languages.georgian", value: 38 },
  { title: "general.languages.javanese", value: 39 },
  { title: "general.languages.lithuanian", value: 40 },
  { title: "general.languages.marathi", value: 41 },
  { title: "general.languages.punjabi", value: 42 },
  { title: "general.languages.tamil", value: 43 },
  { title: "general.languages.telugu", value: 44 },
  { title: "general.languages.ukrainian", value: 45 },
  { title: "general.languages.urdu", value: 46 },
  { title: "general.languages.wu", value: 47 },
];

export const MAIN_CITIES = [
  {
    title: "general.cities.barcelona",
    placeId: "ChIJ5TCOcRaYpBIRCmZHTz37sEQ",
  },
  {
    title: "general.cities.madrid",
    placeId: "ChIJgTwKgJcpQg0RaSKMYcHeNsQ",
  },
  {
    title: "general.cities.london",
    placeId: "ChIJdd4hrwug2EcRmSrV3Vo6llI",
  },
  {
    title: "general.cities.paris",
    placeId: "ChIJD7fiBh9u5kcRYJSMaMOCCwQ",
  },
  {
    title: "general.cities.rome",
    placeId: "ChIJu46S-ZZhLxMROG5lkwZ3D7k",
  },
  {
    title: "general.cities.valencia",
    placeId: "ChIJb7Dv8ExPYA0ROR1_HwFRo7Q",
  },
  {
    title: "general.cities.seville",
    placeId: "ChIJkWK-FBFsEg0RSFb-HGIY8DQ",
  },
  {
    title: "general.cities.granada",
    placeId: "ChIJfcIyLeb8cQ0Rcg1g0533WJI",
  },
  {
    title: "general.cities.malaga",
    placeId: "ChIJLSHbT8RZcg0RzzLKyZLcJWA",
  },
  {
    title: "general.cities.alicante",
    placeId: "ChIJS6udO9o1Yg0R44ELrHKofR0",
  },
  {
    title: "general.cities.salamanca",
    placeId: "ChIJRXzdx10mPw0Rqqm_C9Dkei8",
  },
  {
    title: "general.cities.palmademallorca",
    placeId: "ChIJV8caxlmSlxIRxljQwwZUDcQ",
  },
  {
    title: "general.cities.murcia",
    placeId: "ChIJf4yS1fiBYw0RmqvEOJsSJ9Y",
  },
  {
    title: "general.cities.zaragoza",
    placeId: "ChIJkY5hXt0UWQ0RqImEFfET30k",
  },
];

export const matchImages = [
  "1tazas.gif",
  "2cepillos.gif",
  "3llaves.gif",
  "4tostadas.gif",
  "5lamparas.gif",
];

export const OCCUPATIONS = [
  {
    title: "onboarding.profile.seeker_occupation_study",
    value: 1,
    icon: "Study",
  },
  {
    title: "onboarding.profile.seeker_occupation_work",
    value: 2,
    icon: "Work",
  },
  {
    title: "onboarding.profile.seeker_occupation_study_work",
    value: 3,
    icon: "Both",
  },
];

export const OCCUPATIONS_ROOMMATE = [
  {
    title: "room.detail.fields.occupation.study",
    displayTitle: "room.detail.fields.occupation.study",
    name: "student",
    value: 1,
  },
  {
    title: "room.detail.fields.occupation.work",
    displayTitle: "room.detail.fields.occupation.work",
    name: "worker",
    value: 2,
  },
  {
    title: "room.detail.fields.occupation.both",
    displayTitle: "room.detail.fields.occupation.both.display",
    name: "worker_and_student",
    value: 3,
  },
];

export const PLACE_TYPES = [
  { title: "room.list.step2.property.private", value: 1 },
  { title: "room.list.step2.property.shared", value: 3 },
  { title: "room.list.step2.property.entire", value: 2 },
];

export const PROFESSIONS = [
  { value: 1, title: "profile.form.fields.work.beauty" },
  { value: 2, title: "profile.form.fields.work.business" },
  { value: 3, title: "profile.form.fields.work.charity" },
  { value: 4, title: "profile.form.fields.work.creative" },
  { value: 5, title: "profile.form.fields.work.customer" },
  { value: 6, title: "profile.form.fields.work.education" },
  { value: 7, title: "profile.form.fields.work.entertainment" },
  { value: 8, title: "profile.form.fields.work.finance" },
  { value: 9, title: "profile.form.fields.work.health" },
  { value: 10, title: "profile.form.fields.work.hr" },
  { value: 11, title: "profile.form.fields.work.it" },
  { value: 12, title: "profile.form.fields.work.marketing" },
  { value: 13, title: "profile.form.fields.work.public" },
  { value: 14, title: "profile.form.fields.work.law" },
  { value: 15, title: "profile.form.fields.work.logistics" },
  { value: 16, title: "profile.form.fields.work.realestate" },
  { value: 17, title: "profile.form.fields.work.research" },
  { value: 18, title: "profile.form.fields.work.retail" },
  { value: 19, title: "profile.form.fields.work.sales" },
  { value: 20, title: "profile.form.fields.work.tourism" },
  { value: 21, title: "profile.form.fields.work.communication" },
  { value: 22, title: "profile.form.fields.work.otherwork" },
];

export const SETTINGS_MENU = [
  {
    title: "Notifications",
    url: "{locale}/settings/notifications",
    active: false,
    id: "notifications",
  },
  {
    title: "Change Password",
    url: "{locale}/settings/change-password",
    active: false,
    id: "change-password",
  },
  {
    title: "settings.privacy.title",
    url: "{locale}/settings/privacy",
    active: false,
    id: "privacy",
  },
  {
    title: "settings.support.title",
    url: "{locale}/settings/support",
    active: false,
    id: "support",
  },
  {
    title: "settings.sepa",
    url: "{locale}/settings/sepa",
    active: false,
    id: "sepa",
  },
];

export const STUDIES = [
  { value: 1, title: "profile.form.fields.studies.preuniversity" },
  { value: 2, title: "profile.form.fields.studies.university" },
  { value: 3, title: "profile.form.fields.studies.master" },
  { value: 4, title: "profile.form.fields.studies.phd" },
];

export const TAGS_ORDER = {
  personality: 1,
  lifestyle: 2,
  movies_genres: 5,
  music: 3,
  sports: 4,
};

export const DEFAULT_ROOM_IMAGE = "/assets/images/placeholderRoom.png";
export const DEFAULT_USER_IMAGE = "/assets/icons/profile_picture.svg";
