import React, { memo } from "react";
import { Container, Col, Row } from "reactstrap";

import { Heading, Text, Spacer, Card } from "@badi/badi-components";

import {
  Amenity,
  IconComponentWrapper,
  AmenitiesListWrapper,
} from "./styled-components";

import { ICONS, STYLES_CONFIG } from "./constants";

const DynamicSectionTypeIcon = ({
  title,
  elements,
  type = "default",
  dataQa,
}) => (
  <Container data-qa={dataQa}>
    {title && (
      <Row>
        <Col xs={12}>
          <Spacer bottom={4} top={4}>
            <Heading data-qa={`${dataQa}-title`} level="20-Bold">
              {title}
            </Heading>
          </Spacer>
        </Col>
      </Row>
    )}
    <Row>
      <AmenitiesListWrapper
        as={Card}
        backgroundColor={STYLES_CONFIG[type].backgroundColor}
        type={type}
        withShadow={false}
      >
        {elements.map(({ icon, content }) => {
          const IconComponent = ICONS[icon] || ICONS.default;
          return (
            <Amenity key={`${dataQa}-element-${icon}`}>
              <IconComponentWrapper>
                <IconComponent
                  circle={false}
                  color={STYLES_CONFIG[type].iconColor}
                  dataQa={`${dataQa}-icon-${icon}`}
                />
              </IconComponentWrapper>
              <Text
                body={STYLES_CONFIG[type].fontSize}
                color={STYLES_CONFIG[type].text}
                dataQa={`${dataQa}-icon-label-${icon}`}
              >
                {content}
              </Text>
            </Amenity>
          );
        })}
      </AmenitiesListWrapper>
    </Row>
  </Container>
);

export default memo(DynamicSectionTypeIcon);
