import React from "react";
import { Button, Flex, Text } from "@badi/badi-components";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { openVisitProposalModal } from "Sections/visits/visit-proposal";
import { VISIT_ACTIONS } from "datamodel/visits/constants";
import { getVisitActions } from "./utils";

const VisitActions = ({
  acceptVisitProposal,
  actions,
  cancelVisitProposal,
  onUpdate,
  roomId,
  userId,
  userRole,
  visitId,
  openVideoCall,
}) => {
  const handleCloseVisitModal = () => {
    modal.remove(MODAL_ID.VIEW_VISIT_PROPOSAL);
  };

  const handleCancelVisit = () => {
    cancelVisitProposal(visitId).then((visit) => onUpdate(visit));
  };

  const handleAcceptVisit = () => {
    acceptVisitProposal(visitId).then((visit) => {
      onUpdate(visit);
    });
  };

  const handleReschedule = () => {
    openVisitProposalModal({
      roomId,
      modalId: MODAL_ID.VIEW_VISIT_PROPOSAL,
      visitId,
    });
  };

  const handleClick = (event) => {
    const { value } = event.currentTarget;

    switch (value) {
      case VISIT_ACTIONS.RESCHEDULE:
        handleReschedule();
        break;
      case VISIT_ACTIONS.CANCEL:
        handleCancelVisit();
        break;
      case VISIT_ACTIONS.CONFIRM:
        handleAcceptVisit();
        break;
      case VISIT_ACTIONS.VIDEO_VISIT:
        handleCloseVisitModal();
        openVideoCall();
        break;
      default:
    }
  };
  const buttonActions = getVisitActions(actions);

  return (
    <Flex gutter={1}>
      {buttonActions.map(({ dataQa, variant, label, id }) => {
        return (
          <Button
            dataQa={dataQa}
            disabled={false}
            fullWidth={true}
            grow={1}
            key={id}
            onClick={handleClick}
            value={id}
            variant={variant}
          >
            <Text body="12-Bold" style={{ color: "inherit" }}>
              {label}
            </Text>
          </Button>
        );
      })}
    </Flex>
  );
};

export default VisitActions;
