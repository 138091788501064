import createReducer from "base/shared/createReducer";

import Types from "../types";
import { PaymentModel } from "../models";

function paymentRequest(state) {
  return { ...state, fetching: true };
}

function paymentError(state, action) {
  const error = { ...action.error };
  return { ...state, fetching: false, error };
}

function paymentSuccess(state, action) {
  const { email, phone, methods } = action.result;

  // get credit card info
  const creditCardMethod = methods.length > 0 ? methods[0] : {};
  const creditCardExists = Object.entries(creditCardMethod).length;

  return {
    completed: !!phone && !!creditCardExists,
    email,
    error: null,
    fetching: false,
    phone,
    lastDigits: creditCardMethod.last_four,
    expMonth: creditCardMethod.expiration_month,
    expYear: creditCardMethod.expiration_year,
    provider: creditCardMethod.provider,
  };
}

function methodSuccess(state, action) {
  const {
    last_four: lastDigits,
    expiration_month: expMonth,
    expiration_year: expYear,
    provider,
  } = action.result;
  return {
    ...state,
    error: null,
    fetching: false,
    lastDigits,
    expMonth,
    expYear,
    provider,
  };
}

function reset() {
  return PaymentModel;
}

const actionHandlers = {
  [Types.PAYMENT_REQUEST]: paymentRequest,
  [Types.PAYMENT_SUCCESS]: paymentSuccess,
  [Types.PAYMENT_ERROR]: paymentError,
  [Types.PAYMENTMETHOD_REQUEST]: paymentRequest,
  [Types.PAYMENTMETHOD_SUCCESS]: methodSuccess,
  [Types.PAYMENTMETHOD_ERROR]: paymentError,
  [Types.RESET_PAYMENT]: reset,
};

export default createReducer(actionHandlers, PaymentModel);
