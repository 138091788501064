import { SHRINK_VALUES } from "./constants";

/**
 * Function that check if the CSS shrink value is a valid one, positive number or any of the shrink values dictionary
 * @param {string} value
 *
 * @returns {boolean}
 */
export const isValidFlexShrink = (value) => {
  return (
    (!Number.isNaN(Number(value)) && value >= 0) ||
    SHRINK_VALUES.includes(value)
  );
};

export const hasValue = (value) => {
  return value === 0 || !!value;
};
