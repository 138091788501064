import React from "react";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";

import { getGoldUpgradePath } from "Sections/plans/routes";
import { setPremiumOrigin } from "datamodel/premium/actions";
import PremiumInfo from "./premium-info";

const PremiumInfoRoute = ({ router, ...props }) => {
  const dispatch = useDispatch();

  const handlePremiumClick = ({ roomId, feature }) => {
    dispatch(setPremiumOrigin(window.location.pathname));
    router.push(getGoldUpgradePath({ roomId, feature }));
  };

  return <PremiumInfo {...props} onRedirect={handlePremiumClick} />;
};

export default withRouter(PremiumInfoRoute);
