import React from "react";
import { Spacer, Text } from "@badi/badi-components";
import Favorite from "assets/icons/favorite.svg";
import NotFavorite from "assets/icons/not_favorite.svg";
import { translate } from "base/shared/Localization";

const FavoriteButtonContent = ({ isFavorited }) => {
  const Icon = isFavorited ? Favorite : NotFavorite;
  const text = isFavorited ? "room.favorite.saved" : "room.favorite.not.saved";

  return (
    <>
      <Icon />
      <Spacer left={1}>
        <Text body={2} dataQa="room-details-favorite-button-texts">
          {translate(text)}
        </Text>
      </Spacer>
    </>
  );
};

export default FavoriteButtonContent;
