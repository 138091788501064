import { translate } from "base/shared/Localization";
import { ROOM_STATUS } from "datamodel/Room/constants";

const SUBMIT_BUTTON_TEXT = {
  LIST: "list",
  UPDATE: "update",
  PUBLISH: "updateAndPublish",
};

export const getStepTitle = (step, title) =>
  `${translate("Step")} ${step}: ${title}`;

export const getButtonText = (status) => {
  if (!status) return SUBMIT_BUTTON_TEXT.LIST;

  switch (status) {
    case ROOM_STATUS.DRAFT:
      return SUBMIT_BUTTON_TEXT.LIST;
    case ROOM_STATUS.PENDING:
      return SUBMIT_BUTTON_TEXT.PUBLISH;
    default:
      return SUBMIT_BUTTON_TEXT.UPDATE;
  }
};

export const getStepsArray = ({
  status,
  disabled,
  isEntireProperty,
  onLeaveFlow,
  isListingFlowWithBankAccountEnabled,
  isDuplicate,
  StepsComponents,
}) => {
  const buttonText = getButtonText(status);

  const baseSteps = [
    {
      component: StepsComponents.Location,
      id: "list-location",
      key: "list-location",
      onLeaveFlow,
      title: "room.list.step1.title",
    },
    {
      component: StepsComponents.Property,
      id: "list-prop",
      key: "list-prop",
      title: "room.list.step2.title",
    },
    {
      component: StepsComponents.Room,
      id: "list-room",
      key: "list-room",
      title: "listing.setup.step3.availability.section.title",
    },
    {
      component: StepsComponents.Photos,
      id: "list-photos",
      key: "list-photos",
      isDuplicate,
      title: "room.list.step4.title",
      submitBtnText: translate(`room.list.button.${buttonText}`),
    },
  ];

  if (!isEntireProperty) {
    const FlatmateStep = {
      component: StepsComponents.Flatmate,
      fetchingCreateRoom: disabled,
      id: "list-flatmate",
      key: "list-flatmate",
      submitBtnText: translate(`room.list.button.${buttonText}`),
      title: "room.list.step5.title",
    };
    baseSteps.splice(baseSteps.length, 0, FlatmateStep);
  }

  if (isListingFlowWithBankAccountEnabled) {
    const PayoutStep = {
      component: StepsComponents.Payout,
      id: "list-payout",
      key: "list-payout",
      title: "listing.setup.step4.payout.section.title",
    };

    const position = baseSteps.findIndex(({ id }) => id === "list-room");
    baseSteps.splice(position + 1, 0, PayoutStep);
  }

  return baseSteps;
};
