import { isBrowser } from "base/shared/Utils";

export const getExampleStreet = (countryCode) => {
  switch (countryCode) {
    case "ES":
      return "room.list.step1.street.example.es";
    case "FR":
      return "room.list.step1.street.example.fr";
    case "GB":
      return "room.list.step1.street.example.gb";
    case "IT":
      return "room.list.step1.street.example.it";
    case "US":
      return "RoomCreate.LocationStep.AddressExample.nyc";
    default:
      return "room.list.step1.street.placeholder";
  }
};

export const isCitySet = ({ city, country }) => !!city && !!country;

export const getCityLocation = ({ latitude, longitude }) => {
  return !!latitude && !!longitude && isBrowser()
    ? new window.google.maps.LatLng(latitude, longitude)
    : null;
};

export const getMostAccuratedAddress = (locations) => {
  const address = {
    address: "",
    city: "",
    country: "",
  };
  const safeLocations = locations || [];

  const filteredLocations = safeLocations.filter(
    (r) =>
      !r.types.find((t) => t === "establishment" || t === "point_of_interest"),
  );

  if (filteredLocations.length) {
    const location = filteredLocations[0];
    const addrComponents = location.address_components;
    address.address = location.formatted_address;

    addrComponents.reduce((prev, next) => {
      const newAddress = prev;

      if (next.types.find((t) => t === "street_number")) {
        newAddress.streetNumber = next.long_name;
      } else if (next.types.find((t) => t === "route")) {
        newAddress.street = next.long_name;
      } else if (
        next.types.find(
          (t) =>
            t === "locality" ||
            t === "administrative_area_level_1" ||
            t === "postal_town",
        ) &&
        !newAddress.city
      ) {
        newAddress.city = next.long_name;
      } else if (next.types.find((t) => t === "country")) {
        newAddress.country = next.long_name;
        newAddress.countryCode = next.short_name;
      } else if (next.types.find((t) => t === "postal_code")) {
        newAddress.postalCode = next.long_name;
      }

      return newAddress;
    }, address);

    const city = addrComponents.find(
      (a) =>
        !!a.types.find(
          (t) =>
            t === "locality" ||
            t === "administrative_area_level_1" ||
            t === "postal_town",
        ),
    );

    address.city = city ? city.long_name : "";
  }

  return address;
};

export const getMapCenter = (center) => {
  if (!isBrowser()) return Promise.reject();

  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: center }, (results) => {
      const address = getMostAccuratedAddress(results);

      if (address) {
        resolve(address);
      } else {
        reject();
      }
    });
  });
};
