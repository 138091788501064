import React from "react";
import { BADI_COLORS, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { Button } from "./styled-components";

const ScheduleButton = ({ disabled, onClick, schedule, selected }) => {
  const color = selected
    ? BADI_COLORS.NEUTRAL.NEUTRAL_80
    : BADI_COLORS.NEUTRAL.NEUTRAL_50;

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick(schedule);
  };

  return (
    <Button
      data-qa={`visit-${schedule}-button`}
      disabled={disabled}
      onClick={handleClick}
      selected={selected}
    >
      <Text align="center" body="12-Bold" color={color} inline={true}>
        {translate(`visits.date.${schedule}`)}
      </Text>
    </Button>
  );
};

export default ScheduleButton;
