import React from "react";
import {
  Spacer,
  Card,
  Heading,
  Text,
  Flex,
  colors,
} from "@badi/badi-components";
import Image from "components/Image/Image";
import { HintIconWrapper } from "../styled-components";
import HintLink from "./hint-link";

const Hint = ({ clickable = true, roomId, id, content, link, title }) => {
  const Component = () => (
    <Spacer bottom={3} top={3}>
      <Card backgroundColor={colors.white}>
        <Spacer bottom={2} left={2} right={2} top={2}>
          <Flex
            alignItems="flexStart"
            direction="row"
            fullHeight={true}
            gutter={3}
          >
            <Flex direction="column">
              <HintIconWrapper>
                <Image
                  data-qa="hint-image"
                  lazy={false}
                  src="/assets/icons/overview-lister-metrics.svg"
                />
              </HintIconWrapper>
            </Flex>
            <Flex direction="column">
              <Heading data-qa="hint-title" level={4}>
                {title}
              </Heading>
              <Spacer top={0.5} />
              <Text body={3} dataQa="hint-content">
                {content}
              </Text>
            </Flex>
          </Flex>
        </Spacer>
      </Card>
    </Spacer>
  );

  return clickable ? (
    <HintLink to={link}>
      <Component />
    </HintLink>
  ) : (
    <Component />
  );
};

export default Hint;
