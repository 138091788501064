export const generateRoomCardIdString = (id) => {
  return `room-list-card-${id}`;
};

export const generateBounds = (map, maps, rooms) => {
  const bounds = new maps.LatLngBounds();
  rooms.forEach((room) => {
    const { lat, lng } = room.coordinates;
    bounds.extend(new maps.LatLng(lat, lng));
  });

  map.fitBounds(bounds);

  return bounds;
};

export const getCenter = (bounds) => {
  const generatedCenter = bounds.getCenter();
  return { lat: generatedCenter.lat(), lng: generatedCenter.lng() };
};
