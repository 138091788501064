import envVars from "base/shared/Env";

const {
  BADI_FIREBASE_API_KEY,
  BADI_FIREBASE_AUTH_DOMAIN,
  BADI_FIREBASE_DB_URL,
  BADI_FIREBASE_PROJECT_ID,
  BADI_FIREBASE_MESSAGING_SENDER_ID,
  BADI_FIREBASE_APP_ID,
} = envVars();

export const OS_CODE_NUMBER = 3;
export const APP_VERSION = envVars().VERSION;
export const BADI_PUBLIC_VAPID_KEY = envVars().BADI_PUBLIC_VAPID_KEY;

export const DEFAULT_SW_NAME = "firebase-messaging-sw.js";

export const DEVICE_TOKEN_ERROR = "there is not device token";

export const FIREBASE_CONFIG = {
  apiKey: BADI_FIREBASE_API_KEY,
  authDomain: BADI_FIREBASE_AUTH_DOMAIN,
  databaseURL: BADI_FIREBASE_DB_URL,
  projectId: BADI_FIREBASE_PROJECT_ID,
  messagingSenderId: BADI_FIREBASE_MESSAGING_SENDER_ID,
  appId: BADI_FIREBASE_APP_ID,
};
