import React from "react";

const Retry = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.457 8.527c1.235-1.842 3.427-2.99 5.837-2.822 3.55.248 6.233 3.263 5.99 6.734-.242 3.472-3.319 6.084-6.869 5.835-2.884-.201-5.193-2.225-5.83-4.836"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.916 14.782l1.808-2.515 2.373 1.706"
      />
    </g>
  </svg>
);

Retry.defaultProps = {
  color: "#FB4848",
};

export default Retry;
