import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { parseConnectionData, parseConnectionEnquiry } from "./utils";

export const archiveInboxConversation = (id, token) => {
  const { BADI_INBOX_ARCHIVE } = envVars();

  const serverUrl = getServerUrl(BADI_INBOX_ARCHIVE.replace("{id}", id));

  return fetch(serverUrl, { method: "PUT", token });
};

export const fetchInboxConnection = (id, token) => {
  const { BADI_INBOX_CONNECTIONS } = envVars();
  const serverUrl = getServerUrl(BADI_INBOX_CONNECTIONS.replace("{id}", id));

  return fetch(serverUrl, { token }).then((response) =>
    parseConnectionData(response.data),
  );
};

export const fetchInboxHistory = (id, params, token) => {
  const { BADI_INBOX_HISTORY } = envVars();
  const serverUrl = getServerUrl(BADI_INBOX_HISTORY.replace("{id}", id));

  return fetch(serverUrl, { params, token });
};

export const leaveInboxConversation = (id, token) => {
  const { BADI_INBOX_LEAVE } = envVars();

  const serverUrl = getServerUrl(BADI_INBOX_LEAVE.replace("{id}", id));

  return fetch(serverUrl, { method: "PUT", token });
};

export const sendNewChatTextMessage = (content, id, token) => {
  const { BADI_INBOX_MESSAGE_TEXT } = envVars();

  const params = { content };
  const serverUrl = getServerUrl(BADI_INBOX_MESSAGE_TEXT.replace("{id}", id));

  return fetch(serverUrl, { method: "POST", params, token });
};

export const sendNewChatPictureMessage = (url, id, token) => {
  const { BADI_INBOX_MESSAGE_PICTURE } = envVars();

  const params = { url };
  const serverUrl = getServerUrl(
    BADI_INBOX_MESSAGE_PICTURE.replace("{id}", id),
  );

  return fetch(serverUrl, { method: "POST", params, token });
};

export const markConnectionAsRead = (id, token) => {
  const { BADI_INBOX_CONNECTION_MARK_AS_READ } = envVars();

  const serverUrl = getServerUrl(
    BADI_INBOX_CONNECTION_MARK_AS_READ.replace("{id}", id),
  );

  return fetch(serverUrl, { method: "PUT", token });
};

export const sendMessageEnquiry = (
  token,
  {
    roomId,
    message,
    withVisit = false,
    saveMessage = false,
    isFastTrack = false,
  },
) => {
  const { BADI_ENQUIRY_MESSAGE } = envVars();
  const serverUrl = getServerUrl(BADI_ENQUIRY_MESSAGE);

  return fetch(serverUrl, {
    params: {
      room_id: roomId,
      message,
      visit_requested: withVisit,
      save_message: saveMessage,
      fast_track: isFastTrack,
    },
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const enableChat = ({ connectionId, token }) => {
  const { BADI_INBOX_ENABLE_CHAT } = envVars();
  const serverUrl = getServerUrl(
    BADI_INBOX_ENABLE_CHAT.replace("{id}", connectionId),
  );

  return fetch(serverUrl, {
    method: "PUT",
    token,
  });
};

export const getConnectionEnquiry = ({ connectionId, token }) => {
  const { BADI_CONNECTION_ENQUIRY } = envVars();
  const serverUrl = getServerUrl(
    BADI_CONNECTION_ENQUIRY.replace(":id", connectionId),
  );

  return fetch(serverUrl, {
    token,
  }).then((response) => parseConnectionEnquiry(response.data));
};

export const acceptEnquiry = ({ token, connectionId }) => {
  const { BADI_ENQUIRY_ACCEPT } = envVars();

  const serverUrl = getServerUrl(
    BADI_ENQUIRY_ACCEPT.replace(":id", connectionId),
  );

  return fetch(serverUrl, {
    method: "PUT",
    token,
  });
};

export const getSavedMessageEnquiry = (token) => {
  const { BADI_ENQUIRY_SAVED_MESSAGE } = envVars();
  const serverUrl = getServerUrl(BADI_ENQUIRY_SAVED_MESSAGE);

  return fetch(serverUrl, {
    method: "GET",
    token,
  });
};
