import styled from "styled-components";

export const Root = styled.div`
  min-height: 600px;
  height: 600px;
`;

export const Align = styled.div`
  text-align: ${(props) => props.align};
`;
