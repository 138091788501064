import styled from "styled-components";
import { colors, unitizedPx } from "@badi/badi-components";

export const BannerWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const Button = styled.button`
  border: 1px solid ${colors.white};
  border-radius: ${unitizedPx(0.5)};
  padding: 10px ${unitizedPx(3)};
  background: none;
  cursor: pointer;
`;
