import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";

import Gdpr from "./gdpr-mapping";

export const closeGdprModal = () => {
  modal.remove(MODAL_ID.GDPR);
};

const handleResponse = (callback) => {
  closeGdprModal();

  if (!callback) {
    return;
  }
  callback();
};

export const openGdprModal = ({ onSubmit }) => {
  modal.add(<Gdpr onResponse={() => handleResponse(onSubmit)} />, {
    id: MODAL_ID.GDPR,
    closeOnOutsideClick: false,
    closable: false,
    type: MODAL_TYPE.ONBOARDING,
  });
};
