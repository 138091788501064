import React, { useCallback, useMemo, useState, useEffect } from "react";

import envVars from "base/shared/Env";
import StepperLayout, { Step } from "components/Layout/stepper-layout";
import StripeApi from "base/shared/stripe-api";
import { translate } from "base/shared/Localization";
import { retryBookingRequest } from "datamodel/booking/api";
import { getRelativePath } from "base/shared/Utils";

import { getConversationPath } from "Sections/chat/routes";

import PaymentFailStep from "../payment-fail-step";
import PaymentCheckoutStep, {
  PaymentCheckoutFooter,
} from "../payment-checkout";
import AcceptedStep from "../accepted-step";
import ConfirmationFooter from "./confirmation-footer";
import { BOOKING_STEPS } from "../constants";

const RecoverBookingStepHandler = ({
  fromConnection = 0,
  requestId,
  bookingInfo,
  handleError,
  router,
  token,
  disabled = false,
}) => {
  const [validatedSteps, setValidatedSteps] = useState(new Set());
  const [fields, setFields] = useState({});
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const steps = useMemo(
    () =>
      bookingInfo?.useCheckout
        ? [
            {
              id: BOOKING_STEPS.PAYMENT_CHECKOUT,
              render: PaymentCheckoutStep,
              footer: PaymentCheckoutFooter,
              title: translate("recovery.payment_checkout_title"),
            },
          ]
        : [
            {
              id: BOOKING_STEPS.PAYMENT_FAIL,
              render: PaymentFailStep,
              footer: ConfirmationFooter,
              title: translate("recovery.payment_title"),
            },
          ],
    [],
  );

  const stripe = useMemo(
    () => new StripeApi({ token: envVars().BADI_PAYMENT_TOKEN }),
    [],
  );

  useEffect(() => {
    if (!bookingInfo?.useCheckout) {
      stripe.init();
    }
  }, []);

  const handleValidStep = ({ id, valid, payload }) => {
    if (valid) {
      validatedSteps.add(id);
    } else {
      validatedSteps.delete(id);
    }

    setFields({ ...fields, ...payload });
    setValidatedSteps(validatedSteps);
  };

  const handleFlowClose = useCallback(() => {
    router.push(getRelativePath("/inbox"));
  }, []);

  const handleMessageClick = useCallback(() => {
    router.push(getConversationPath({ connectionId: fromConnection }));
  }, []);

  const handleRetryBookingRequest = async (params) => {
    const { error } = await retryBookingRequest(params);
    setLoading(false);

    if (error) {
      handleError({
        message: translate(
          "Whoops, there was an unknown error. Try later or contact support.",
        ),
        error,
      });
      return;
    }

    setAccepted(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    const params = {
      token,
      requestId,
      stripeVerification: stripe.authorizeCardPayment,
    };

    return handleRetryBookingRequest(params);
  };

  return (
    <StepperLayout
      disabled={loading || disabled}
      onClose={handleFlowClose}
      onSubmit={handleSubmit}
    >
      {accepted ? (
        <AcceptedStep
          connectionId={fromConnection}
          id={BOOKING_STEPS.CREATED}
        />
      ) : (
        steps.map(({ id, title, render: Render, footer: Footer }) => (
          <Step
            bookingRequestId={requestId}
            footer={Footer}
            id={id}
            key={id}
            onMessageClick={fromConnection && handleMessageClick}
            valid={validatedSteps.has(id)}
          >
            {bookingInfo && (
              <Render
                bookingRequestDetail={bookingInfo}
                connectionId={Number(fromConnection)}
                id={id}
                onValidation={handleValidStep}
                title={title}
                token={token}
              />
            )}
          </Step>
        ))
      )}
    </StepperLayout>
  );
};

export default RecoverBookingStepHandler;
