import { addFavorite, removeFavorite } from "datamodel/favorites/api";

export const parsePagination = ({
  current_page: currentPage,
  total_pages: totalPages,
  total,
}) => {
  return { currentPage, totalPages, total };
};

export const toggleFavorite = async (token, roomId, isFavorite) => {
  const apiCall = isFavorite ? removeFavorite : addFavorite;
  await apiCall(token, roomId);
};
