import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const Link = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const ImageWrapper = styled.div`
  > img {
    display: block;
  }
`;

export const TransactionNum = styled.div`
  border-radius: ${unitizedPx(3)};
  padding: ${unitizedPx(0.5)} ${unitizedPx(1)};
  margin-left: ${unitizedPx(1)};
  background-color: ${(props) => props.color};
`;
