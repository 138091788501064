import React from "react";

import { Card, Heading, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import {
  CardBorder,
  List,
  ListItem,
  ListLink,
  SvgIcon,
} from "./styled-components";

// TODO: move this to a babel plugin (https://github.com/airbnb/babel-plugin-inline-react-svg)
const Icon = () => {
  return (
    <SvgIcon height="12" width="12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4 2.73l-9.04 9.05a.8.8 0 0 1-1.14-1.14L9.27 1.6H3.19a.8.8 0 0 1 0-1.6h8c.45 0 .81.36.81.8v8a.8.8 0 1 1-1.6 0V2.74z"
        fill="#333"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

const PolicyCard = () => {
  return (
    <Card withShadow={false}>
      <CardBorder>
        <Spacer bottom={2} left={2} right={2} top={2}>
          <Spacer bottom={1}>
            <Heading level={3}>
              {translate("booking.preview.safety_info.heading")}
            </Heading>
          </Spacer>
          <Spacer bottom={3}>
            <Text body={3}>
              {translate("booking.preview.safety_info.description")}
            </Text>
          </Spacer>
          <List>
            <ListItem>
              <Icon />
              <Text body={3}>
                <ListLink
                  href={translate("booking.preview.safety_info.guarantee_link")}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {translate("booking.preview.safety_info.guarantee_text")}
                </ListLink>
              </Text>
            </ListItem>
            <ListItem>
              <Icon />
              <Text body={3}>
                <ListLink
                  href={translate("sitemap.tc.link")}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {translate("booking.preview.safety_info.policy_text")}
                </ListLink>
              </Text>
            </ListItem>
          </List>
        </Spacer>
      </CardBorder>
    </Card>
  );
};

export default PolicyCard;
