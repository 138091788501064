import { BADI_COLORS } from "@badi/badi-components";

import {
  AirConditioning,
  Tv,
  Wifi,
  Parking,
  Elevator,
  Heating,
  WashingMachine,
  Dryer,
  Doorman,
  FurnishedRoom,
  Pool,
  PrivateBathroom,
  Balcony,
  NaturalLight,
  Dishwasher,
  WheelchairFriendly,
  Garden,
  Smoker,
  Pet,
  Couples,
  Terrace,
  WorkingSpace,
  BedType,
  Lift,
  Area,
  Facade,
  PropertyType,
  Bathrooms,
  Default,
} from "components/Icons/Amenities";

export const ICONS = {
  airConditioner: AirConditioning,
  tv: Tv,
  wifi: Wifi,
  parking: Parking,
  elevator: Elevator,
  heating: Heating,
  washingMachine: WashingMachine,
  dryer: Dryer,
  doorman: Doorman,
  furnished: FurnishedRoom,
  pool: Pool,
  ownBathroom: PrivateBathroom,
  balcony: Balcony,
  exteriorView: NaturalLight,
  dishwasher: Dishwasher,
  wheelchairFriendly: WheelchairFriendly,
  garden: Garden,
  smokerFriendly: Smoker,
  petFriendly: Pet,
  couplesAccepted: Couples,
  terrace: Terrace,
  apartment_balcony: Balcony,
  workingSpace: WorkingSpace,
  bedType: BedType,
  lift: Lift,
  area: Area,
  facade: Facade,
  propertyType: PropertyType,
  bathrooms: Bathrooms,
  default: Default,
};

export const STYLES_CONFIG = {
  abstract: {
    backgroundColor: BADI_COLORS.SEMANTIC.INFO.LIGHTEST,
    text: BADI_COLORS.BRAND.TERTIARY.HOVER,
    fontSize: "14-Bold",
    iconColor: "#6D8BFF",
  },
  default: {
    backgroundColor: undefined,
    text: undefined,
    fontSize: "12-Regular",
    iconColor: BADI_COLORS.NEUTRAL.NEUTRAL_80,
  },
};
