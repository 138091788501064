import React from "react";
import PropTypes from "prop-types";
import { IconWrapper } from "./styled-components";
import { BADI_COLORS } from "app/badi-components";
import { Icon } from "@badi/badi-components";

const InputIcon = ({ iconSrc, hasError }) => {
  return (
    <IconWrapper>
      {!!iconSrc && !hasError && (
        <Icon iconMaxHeight={24} iconMaxWidth={24} src={iconSrc} />
      )}
      {!!hasError && (
        <Icon
          color={BADI_COLORS.SEMANTIC.DANGER.DEFAULT}
          iconMaxHeight={24}
          iconMaxWidth={24}
          name="alert"
        />
      )}
    </IconWrapper>
  );
};

InputIcon.propTypes = {
  hasError: PropTypes.bool,
  iconSrc: PropTypes.string,
};

export default InputIcon;
