import { PLANS_CATEGORIES } from "datamodel/plans/constants";

export const getButtonsText = (category) => {
  switch (category) {
    case PLANS_CATEGORIES.UPSELL_BADI_PLUS:
      return {
        main: "boost_upselling.add.boost.cta",
        secondary: "boost_upselling.maybe.later.cta",
      };
    case PLANS_CATEGORIES.STANDALONE_BOOSTING:
      return { main: "boost_standalone.continue.cta" };
    case PLANS_CATEGORIES.MATCHING:
    default:
      return { main: "plans.screen_badi.gold.select.plan.cta" };
  }
};

export const getPlanColors = () => ["#ffe6a9", "#ffc970"];
