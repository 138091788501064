import React, { useCallback } from "react";
import { isLegalPropAccepted } from "datamodel/User/utils";
import { LEGAL_RESPONSE, USER_LEGAL_CONSENT } from "datamodel/User/constants";
import NotificationSection from "../notification-section";

const MarketingNotifications = ({
  marketingConsent,
  label,
  onChange = () => {},
  updateLegal,
}) => {
  const active = isLegalPropAccepted(marketingConsent);

  const handleToggleClick = useCallback(() => {
    updateLegal({ [USER_LEGAL_CONSENT.MARKETING]: !active });
    onChange();
  }, [active]);

  return (
    <NotificationSection
      isActive={active}
      notification={{ label, type: USER_LEGAL_CONSENT.MARKETING }}
      toggleButton={handleToggleClick}
    />
  );
};

export default MarketingNotifications;
