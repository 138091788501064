import React, { useState, useEffect } from "react";

import { Flex } from "@badi/badi-components";
import PhoneVerificationForm from "components/Verification/Phone/phone-verification-mapping";
import EmailVerificationForm from "components/Verification/Email/EmailVerificationMapping";
import BankAccountForm from "components/bank-account";
import ContactInformationForm from "components/contact-information";
import { DEFAULT_EXPIRATION_HOURS } from "datamodel/booking/constants";

import { translate } from "base/shared/Localization";
import BookingPreview from "components/booking-preview";

import { getBookingPreview } from "datamodel/booking/api";
import { isLister } from "datamodel/User/utils";
import BookingStepLayout from "../step-layout";

const PaymentStep = ({
  ccLastDigits = "",
  connectionId = 0,
  hasBankAccountNumber = false,
  hasContactInformation = false,
  hasPaymentCard = false,
  hasVerifiedEmail = false,
  hasVerifiedPhone = false,
  moveOut = "",
  monthsLength = 0,
  onValidation = () => {},
  roomId = 0,
  title = "",
  getBankAccount,
  getPaymentDetails,
  id,
  moveIn,
  token,
  seekerId = null,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [room, setRoom] = useState(null);
  const [user, setUser] = useState(null);
  const [lengthInMonths, setLengthInMonths] = useState(null);
  const [exactMonthsLength, setExactMonthsLength] = useState(null);
  const [bookingValue, setBookingValue] = useState(null);
  const [expirationHours, setExpirationHours] = useState(
    DEFAULT_EXPIRATION_HOURS,
  );
  const [price, setPrice] = useState(null);

  useEffect(() => {
    getBookingPreview({
      token,
      connectionId,
      moveIn,
      moveOut,
      monthsLength,
      roomId,
      seekerId,
    }).then((response) => {
      setRoom(response.room);
      setUser(isLister(response.userRole) ? response.seeker : response.lister);
      setUserRole(response.userRole);
      setPrice(response.price);
      setExpirationHours(response.expirationHours);
      setLengthInMonths(response.lengthInMonths);
      setExactMonthsLength(response.exactMonthsLength);
      setBookingValue(response.bookingValue);
    });
  }, [connectionId, ccLastDigits]);

  useEffect(() => {
    if (!userRole) return;

    const paymentDetailsRequest = isLister(userRole)
      ? getBankAccount
      : getPaymentDetails;

    paymentDetailsRequest().then(() => {
      setLoaded(true);
    });
  }, [userRole]);

  useEffect(() => {
    const hasPaymentInformation = isLister(userRole)
      ? hasBankAccountNumber && hasContactInformation
      : hasPaymentCard;

    onValidation({
      id,
      valid: hasPaymentInformation && hasVerifiedPhone && hasVerifiedEmail,
      payload: {
        discount: price ? price.discount : null,
      },
    });
  }, [
    userRole,
    hasBankAccountNumber,
    hasContactInformation,
    hasPaymentCard,
    hasVerifiedPhone,
    hasVerifiedEmail,
    price,
  ]);

  const sidebar = [];

  const titleDescription =
    userRole && !isLister(userRole)
      ? translate("booking_flow_step3_description")
      : "";

  if (room && user && price) {
    sidebar.unshift(
      <BookingPreview
        bookingValue={bookingValue}
        exactMonthsLength={exactMonthsLength}
        isFullInfoBr={false}
        key="booking-preview"
        lengthInMonths={lengthInMonths}
        moveIn={moveIn}
        moveOut={moveOut}
        price={price}
        room={room}
        user={user}
        userRole={userRole}
      />,
    );
  }

  return (
    <BookingStepLayout
      expirationHours={expirationHours}
      hideBadiGuaranteeCard={true}
      sidebar={sidebar}
      sidebarTitle={translate("booking.new.request_details_title")}
      title={translate(title)}
      titleDescription={titleDescription}
      userRole={userRole}
    >
      {loaded && (
        <Flex direction="column" gutter={2}>
          {isLister(userRole) && [
            <BankAccountForm key="bank-account" />,
            <ContactInformationForm key="contact-information" />,
          ]}
          <EmailVerificationForm />
          <PhoneVerificationForm />
        </Flex>
      )}
    </BookingStepLayout>
  );
};

export default PaymentStep;
