import React from "react";
import { translate } from "base/shared/Localization";
import SuccessCheck from "components/Icons/SuccessCheck";
import { Text, Flex, BADI_COLORS } from "@badi/badi-components";
import { getReferralSteps } from "../../utils";
import { Divider } from "../../styled-components";

const PendingReferral = ({ status, refereeName }) => {
  const referralSteps = getReferralSteps(status);

  return (
    <Flex direction="column" gutter={1}>
      <Text body="14-Bold" dataQa="pending-referee-name">
        {refereeName}
      </Text>
      <Flex direction="row" gutter={5} justify="flexStart">
        {referralSteps.map(({ id, label, achieved }) => (
          <Flex direction="row" gutter={1} key={id}>
            <SuccessCheck
              color={
                achieved
                  ? BADI_COLORS.BRAND.PRIMARY.DEFAULT
                  : BADI_COLORS.NEUTRAL.NEUTRAL_10
              }
              size={16}
            />
            <Text
              body="12-Regular"
              color={
                achieved
                  ? BADI_COLORS.NEUTRAL.NEUTRAL_80
                  : BADI_COLORS.NEUTRAL.NEUTRAL_50
              }
              dataQa={`pending-referral-step-${id}-label`}
            >
              {translate(label)}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Divider />
    </Flex>
  );
};

export default PendingReferral;
