import React from "react";
import { BADI_COLORS } from "@badi/badi-components";
import CalendarSVG from "assets/icons/calendar-default.svg";

export const Trusted = ({
  size = 24,
  color = BADI_COLORS.NEUTRAL.NEUTRAL_00,
}) => (
  <svg
    height={size}
    style={{ display: "block" }}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.348 1.139c1.357 1.293 2.985 1.895 5.122 1.895.277 0 .5.22.5.49l.002.35L14 5.558v.386c-.014 3.314-.583 7.244-5.836 9.029a.508.508 0 01-.327 0c-5.458-1.855-5.859-6.027-5.835-9.415l.027-1.684.002-.35c0-.27.224-.49.5-.49 2.137 0 3.765-.602 5.121-1.895a.507.507 0 01.697 0zm3.277 4.339a1 1 0 00-1.327.077L7.47 8.384l-1.06-1.06-.087-.078a1 1 0 00-1.327 1.491l1.768 1.768.087.078a1 1 0 001.327-.078l3.535-3.535.078-.088a1 1 0 00-.078-1.327z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export const Calendar = () => <CalendarSVG style={{ display: "block" }} />;
