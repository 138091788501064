import { connect } from "react-redux";

import Actions from "datamodel/Auth/actions";
import RequirementsActions from "datamodel/Requirements/actions";
import UserActions from "datamodel/User/actions";
import { getIsUserListerSelector, getUserId } from "datamodel/User/selectors";
import DesktopBar from "./desktop-bar";

const mapStateToProps = (state) => ({
  isLister: getIsUserListerSelector(state),
  isLoggedIn: !!getUserId(state),
  picture: state.User.mainPicture,
});

const mapDispatchToProps = (dispatch) => ({
  openLogin: () => dispatch(RequirementsActions.setLogin(true)),
  logout: () => dispatch(Actions.logout()),
  switchUser: (mode) =>
    dispatch(UserActions.setUserMode({ mode, isActiveSwitching: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopBar);
