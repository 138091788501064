import React, { useState, useEffect } from "react";
import { Spacer } from "@badi/badi-components";

import { getFavorites } from "datamodel/favorites/api";
import LayoutWrapper from "components/Layout/Wrapper";
import { translate } from "base/shared/Localization";

import { Header, SearchWrapper } from "./styled-components";

import FavoriteList from "./favorites-list";
import FavoritesEmpty from "./favorites-empty";

const Favorites = ({ token }) => {
  const [favoritesListResponse, setFavoriteListResponse] = useState({});
  const [loaded, setLoaded] = useState(false);

  const getFavoritesList = (page = 1) => {
    getFavorites({ token, page }).then((favoritesListResponseApi) => {
      setFavoriteListResponse(favoritesListResponseApi);
      setLoaded(true);
    });
  };

  const hasNextStep = (page) => {
    return favoritesListResponse.pagination.totalPages >= page;
  };

  const getFavoritesPerPage = (page) => {
    if (hasNextStep(page)) {
      getFavoritesList(page);
    }
  };

  useEffect(() => {
    getFavoritesList();
  }, []);

  const { favorites, pagination } = favoritesListResponse;
  const showList = !!favorites && !!favorites.length;

  return (
    <LayoutWrapper>
      <SearchWrapper className="SearchResultsWrapper_list">
        <Spacer bottom={3} left={3} right={3} top={3}>
          <Header>{translate("room.favorites.section.title")}</Header>
        </Spacer>
        {loaded && showList && (
          <FavoriteList
            favorites={favorites}
            getFavoritesPerPage={getFavoritesPerPage}
            pagination={pagination}
          />
        )}
        {loaded && !showList && <FavoritesEmpty />}
      </SearchWrapper>
    </LayoutWrapper>
  );
};

export default Favorites;
