import React from "react";
import { Container, Col, Row } from "reactstrap";

import {
  BADI_COLORS,
  Flex,
  Heading,
  LinkButton,
  Spacer,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Benefit from "./benefit";
import { Content, Wrapper, AdditionalSpace } from "./styled-components";
import { BENEFITS } from "./constants";

const Professionals = () => (
  <>
    <Flex alignItems="flexEnd" as={Wrapper}>
      <Container wide={true}>
        <Row>
          <Col>
            <Content>
              <Flex direction="column" gutter={4}>
                <Heading
                  color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                  data-qa="about-badi-professionals-title"
                  level="28-Extrabold"
                >
                  {translate("aboutbadi.professionals.title")}
                </Heading>
                <Heading
                  color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                  data-qa="about-badi-professionals-desc"
                  level="16-Medium"
                >
                  {translate("aboutbadi.professionals.description")}
                </Heading>
                <Flex direction="column" gutter={2}>
                  {BENEFITS.map((benefit) => (
                    <Benefit key={benefit}>{translate(benefit)}</Benefit>
                  ))}
                </Flex>
                <Spacer left={5} lg={{ left: 0 }}>
                  <LinkButton
                    data-qa="about-badi-professionals-cta"
                    href="mailto:sales@badi.com"
                  >
                    {translate("aboutbadi.professionals.cta")}
                  </LinkButton>
                </Spacer>
              </Flex>
            </Content>
          </Col>
        </Row>
      </Container>
    </Flex>
    <AdditionalSpace />
  </>
);

export default Professionals;
