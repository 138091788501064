import { BREAKPOINTS } from "@badi/badi-components";

export const SLIDER_SETTINGS = {
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: BREAKPOINTS.xl,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: BREAKPOINTS.sm,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
