import React, { useCallback } from "react";
import { Flex, Spacer, colors, Text, TextButton } from "@badi/badi-components";

import { openBookingGuaranteeModal } from "components/booking-guarantee-modal";
import { translate } from "base/shared/Localization";

import { AnnotationHeading, AnnotationHeadingText } from "./styled-components";
import { BADI_GUARANTEE_POPUP_ACTION } from "./constants";

export const getActionForPopup = (action) => {
  switch (action) {
    case BADI_GUARANTEE_POPUP_ACTION:
      return openBookingGuaranteeModal;
    default:
      return null;
  }
};

export const PopupAnnotation = ({
  message,
  heading,
  linkText = null,
  popupKey = "",
  userRole = "",
}) => {
  const popupAction = getActionForPopup(popupKey);
  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      if (!popupAction) return;

      popupAction({
        userRole,
        trackLearnMoreClick: () => {},
      });
    },
    [popupAction, userRole],
  );
  return (
    <Spacer bottom={4} top={4}>
      {heading && (
        <Spacer bottom={1}>
          <AnnotationHeading>
            <Text align="center" body={2} color={colors.midGrey}>
              <AnnotationHeadingText data-qa="annotation-heading">
                {heading}
              </AnnotationHeadingText>
            </Text>
          </AnnotationHeading>
        </Spacer>
      )}
      <Text
        align="center"
        body={3}
        color={colors.midGrey}
        data-qa="annotation-content"
      >
        {message}
      </Text>
      {linkText && (
        <Spacer top={1}>
          <Flex justify="center">
            <TextButton data-qa="annotation-link" onClick={handleClick}>
              {translate(linkText)}
            </TextButton>
          </Flex>
        </Spacer>
      )}
    </Spacer>
  );
};

export default PopupAnnotation;
