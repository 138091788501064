class Capability {
  constructor(entity) {
    this.entity = entity || {};
  }

  get id() {
    return this.entity.id;
  }

  get name() {
    return this.entity.name;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}

class Capabilities {
  constructor(entity) {
    this.entity = entity || {};
  }

  get capabilities() {
    const capabilities = this.entity?.capabilities || [];

    return capabilities.map((capability) => new Capability(capability));
  }

  get error() {
    return this.entity.error || null;
  }

  toJSON() {
    return {
      capabilities: this.capabilities.map((capability) => capability.toJSON()),
      error: this.error,
    };
  }
}

export default Capabilities;
