import React from "react";
import { useSelector } from "react-redux";
import { Flex, Heading, Text } from "@badi/badi-components";
import { statusesCountersSelector } from "datamodel/MyListings/selectors";

const EmptyState = ({ activeTab }) => {
  const statusesCounters = useSelector(statusesCountersSelector);

  const { emptyState } = statusesCounters.find(
    (status) => status.id === activeTab,
  );

  return (
    <Flex direction="column" gutter={2}>
      <Heading data-qa="mylistings-empty-state-title" level="24-Bold">
        {emptyState.title}
      </Heading>
      <Text data-qa="mylistings-empty-state-text">
        {emptyState.description}
      </Text>
    </Flex>
  );
};

export default EmptyState;
