import React from "react";

const Info = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 15)">
      <circle cx="7.5" cy="7.5" r="7.5" fill={color} />
      <g fill="#FFF" transform="translate(6.5 2.5)">
        <rect width="2" height="7" rx="1" />
        <circle cx="1" cy="9" r="1" />
      </g>
    </g>
  </svg>
);

Info.defaultProps = {
  color: "#bbb",
};

export default Info;
