import { connect } from "react-redux";
import {
  getCreditCardLastDigits,
  getPaymentError,
  getPaymentStatus,
} from "datamodel/Payment/selectors";
import { getToken } from "app/datamodel/Auth/selectors";
import { setPaymentMethod, getPayment } from "datamodel/Payment/actions";

import PaymentProvider from "./payment-provider";

const mapStateToProps = (state) => ({
  token: getToken(state),
  cardLastDigits: getCreditCardLastDigits(state),
  error: getPaymentError(state),
  status: getPaymentStatus(state),
});

const mapDispatchToProps = {
  setPaymentMethod,
  getPayment,
};

const mergeProps = (state, dispatch, ownProps) => ({
  ...state,
  ...ownProps,
  setPaymentMethod: (id) => dispatch.setPaymentMethod(id, state.token),
  getPaymentDetails: () => dispatch.getPayment(state.token),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PaymentProvider);
