import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "reactstrap";
import { getRoomMetrics } from "datamodel/Analytics/api";
import { translate } from "base/shared/Localization";
import {
  routerEventListenerInitiator,
  isAndroidWebView,
} from "base/shared/mobile-web-view";
import { Spacer, Flex, colors, Heading } from "@badi/badi-components";
import Footer from "components/Layout/Footer";
import LoadingSpinner from "components/Icons/LoadingSpinner";
import LayoutWrapper from "components/Layout/Wrapper";
import RoomPreview from "components/room-preview";
import Select from "components/Form/Select/Select";
import { areGraphsAvailable } from "./utils";
import ListerMetricsEmpty from "./components/lister-metrics-empty";
import Metrics from "./components/metrics";
import Hint from "./components/hint";
import RoomDetailsSection from "./components/sections/room-details-section";

const ListerMetrics = ({
  isIOSApp,
  router,
  token,
  getListerHints,
  getRoomDetails,
  fetchedRooms,
  getRooms,
  paramRoomId = null,
  resetAnalytics,
  rooms,
  sgid = null,
  listerHints,
}) => {
  const routerEventListener = routerEventListenerInitiator(router, isIOSApp);

  const [metrics, setMetrics] = useState(null);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const [selectedRoomId, setSelectedRoom] = useState("");
  const [isWebView, setIsWebView] = useState(false);

  const fetchRoomDetailsIfNotFetched = (roomId) => {
    const selectedRoom = rooms[roomId];
    if (!selectedRoom.detailsFetched) {
      getRoomDetails({ roomId: selectedRoom.id, sgid });
    }
  };

  const handleRoomChange = useCallback((event) => {
    const roomId = event.target.value;
    setSelectedRoom(roomId);
    fetchRoomDetailsIfNotFetched(roomId);
    getListerHints({ roomId, sgid });
  });

  const roomsExist = Object.keys(rooms).length > 0;

  useEffect(() => {
    setIsWebView(isAndroidWebView() || isIOSApp);
    getRooms({ sgid });
    const unlisten = routerEventListener();
    return () => {
      unlisten();
      resetAnalytics();
    };
  }, []);

  useEffect(() => {
    if (selectedRoomId) {
      setMetricsLoading(true);

      getRoomMetrics({ token, roomId: selectedRoomId, sgid })
        .then((res) => {
          setMetrics(res);
          setMetricsLoading(false);
        })
        .catch(() => setMetricsLoading(false));
    }
  }, [selectedRoomId]);

  useEffect(() => {
    if (fetchedRooms) {
      const roomIds = Object.keys(rooms);
      if (roomIds.length) {
        const roomId = roomIds.includes(paramRoomId) ? paramRoomId : roomIds[0];
        setSelectedRoom(roomId);
        fetchRoomDetailsIfNotFetched(roomId);
        getListerHints({ roomId, sgid });
      }
    }
  }, [fetchedRooms]);

  const getContent = () =>
    fetchedRooms && (
      <Spacer bottom={3} left={3} right={3} top={3}>
        <Container>
          <Row>
            <Col>
              <Heading data-qa="lister-metrics-header" level={1}>
                {translate("lister.analytics_title")}
              </Heading>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 7, offset: 0, order: 1 }}
              md={{ size: 12, offset: 0, order: 2 }}
              xs={{ size: 12, offset: 0, order: 2 }}
            >
              {listerHints.length > 0 && (
                <Hint
                  clickable={true}
                  roomId={selectedRoomId}
                  {...listerHints[0]}
                />
              )}
              {metricsLoading && (
                <Flex
                  alignItems="center"
                  direction="column"
                  fullHeight={true}
                  justify="center"
                >
                  <LoadingSpinner
                    color={colors.darkGrey}
                    data-qa="lister-metrics-spinner"
                  />
                </Flex>
              )}
              {metrics && <Metrics {...metrics} />}
              {!areGraphsAvailable(metrics) && !metricsLoading && (
                <Spacer top={1}>
                  <ListerMetricsEmpty roomsAvailable={roomsExist} />
                </Spacer>
              )}
            </Col>
            <Col
              lg={{ size: 4, offset: 1, order: 2 }}
              md={{ size: 12, offset: 0, order: 1 }}
              xs={{ size: 12, offset: 0, order: 1 }}
            >
              <Spacer top={3}>
                <Select
                  disabled={!roomsExist}
                  name="roomSelector"
                  onChange={handleRoomChange}
                  options={Object.keys(rooms).map((roomId) => ({
                    title: rooms[roomId].title,
                    value: roomId,
                  }))}
                  placeholder={
                    !roomsExist
                      ? translate("lister.analytics_dropdown_placeholder")
                      : null
                  }
                  value={selectedRoomId}
                />
                {!!metrics?.favorites && (
                  <RoomDetailsSection favorites={metrics.favorites} />
                )}
                {rooms[selectedRoomId]?.detailsFetched && (
                  <RoomPreview
                    clickable={true}
                    room={rooms[selectedRoomId]}
                    token={token}
                  />
                )}
              </Spacer>
            </Col>
          </Row>
        </Container>
      </Spacer>
    );

  return isWebView ? (
    getContent()
  ) : (
    <LayoutWrapper footer={<Footer campaign="lister_metrics_footer" />}>
      {getContent()}
    </LayoutWrapper>
  );
};

export default ListerMetrics;
