import { getRelativePath } from "base/shared/Utils";

const BASE_PATH = "/booking";

export const getNewBookingPath = ({ connectionId, roomId, seekerId }) => {
  let path;

  if (roomId && seekerId) {
    path = `${BASE_PATH}/new/${roomId}?invite_to=${seekerId}`;
  } else if (roomId) {
    path = `${BASE_PATH}/new/${roomId}`;
  } else {
    path = `${BASE_PATH}/new?from_connection=${connectionId}`;
  }

  return getRelativePath(path);
};

export const getBookingRequestPath = ({ requestId, connectionId }) => {
  const fromConnectionQuery = connectionId
    ? `?from_connection=${connectionId}`
    : "";
  return getRelativePath(
    `${BASE_PATH}/requests/${requestId}${fromConnectionQuery}`,
  );
};

export const getReplyBookingRequestPath = ({ requestId, connectionId }) => {
  const fromConnectionQuery = connectionId
    ? `?from_connection=${connectionId}`
    : "";
  return getRelativePath(
    `${BASE_PATH}/requests/${requestId}/reply${fromConnectionQuery}`,
  );
};

export const getRecoveryBookingPath = ({ requestId, connectionId }) => {
  const fromConnectionQuery = connectionId
    ? `?from_connection=${connectionId}`
    : "";
  return getRelativePath(
    `${BASE_PATH}/requests/${requestId}/recover${fromConnectionQuery}`,
  );
};

export const getBookingReference = ({ referenceId }) => {
  return getRelativePath(`${BASE_PATH}/details/${referenceId}`);
};
