import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const DescriptionWrapper = styled.div`
  text-align: center;
`;

export const Root = styled.div`
  max-width: ${unitizedPx(52)};
  margin: ${unitizedPx(3)} ${unitizedPx(1)};
`;
