import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex } from "@badi/badi-components";

import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import Input from "components/form-text-input";
import BirthDate, { breakDownDate } from "components/form-birth-date";
import { useForm, useFormValidation } from "components/hooks";
import ContactInformationField from "../field/contact-information-field";
import {
  INPUT_BIRTHDATE,
  INPUT_NAME,
  INPUT_SURNAME,
  INPUT_POSTALCODE,
  INPUT_ADDRESS,
  INPUT_CITY,
  VALIDATIONS_NAME,
  VALIDATIONS_SURNAME,
  VALIDATIONS_POSTALCODE,
  VALIDATIONS_ADDRESS,
  VALIDATIONS_CITY,
} from "../static";

const ContactInformationEdit = ({
  birthdate: initialBirthdate = "",
  error = null,
  name: initialName = "",
  surname: initialSurname = "",
  postalcode: initialPostalcode = "",
  address: initialAddress = "",
  city: initialCity = "",
  updating = false,
  enabled = true,
  onSubmit,
  closeEditing,
  verified,
}) => {
  const [saving, setSaving] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [values, setInputValue] = useForm({
    [INPUT_BIRTHDATE]: initialBirthdate,
    [INPUT_NAME]: initialName,
    [INPUT_SURNAME]: initialSurname,
    [INPUT_POSTALCODE]: initialPostalcode,
    [INPUT_ADDRESS]: initialAddress,
    [INPUT_CITY]: initialCity,
  });

  const [isFormValid, setFieldValidation] = useFormValidation({
    [INPUT_BIRTHDATE]: !!initialBirthdate,
    [INPUT_NAME]: !!initialName,
    [INPUT_SURNAME]: !!initialSurname,
    [INPUT_POSTALCODE]: !!initialPostalcode,
    [INPUT_ADDRESS]: !!initialAddress,
    [INPUT_CITY]: !!initialCity,
  });

  useEffect(() => {
    setDirty(true);
  }, [
    values[INPUT_BIRTHDATE],
    values[INPUT_NAME],
    values[INPUT_SURNAME],
    values[INPUT_POSTALCODE],
    values[INPUT_ADDRESS],
    values[INPUT_CITY],
  ]);

  useEffect(() => {
    setDirty(false);
  }, [
    initialName,
    initialSurname,
    initialPostalcode,
    initialAddress,
    initialCity,
    initialBirthdate,
  ]);

  useEffect(() => {
    if (updating) {
      return;
    }

    if (error) {
      UserFeedback.exception("Something went wrong", error.message, error);
    } else if (saving) {
      UserFeedback.success(
        "User account updated",
        "Your user account has been updated successfully.",
      );
      setSaving(false);
      closeEditing();
    }
  }, [error, updating]);

  const handleChange = ({ name, value }) => {
    setInputValue(name, value);
  };

  const handleValidation = ({ name, isValid }) => {
    setFieldValidation(name, isValid);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    onSubmit(values);
  };

  const { day, month, year } = useMemo(
    () => breakDownDate(values[INPUT_BIRTHDATE]),
    [values[INPUT_BIRTHDATE]],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gutter={3}>
        <Flex
          direction="column"
          gutter={2}
          md={{ direction: "row", gutter: 2 }}
        >
          <ContactInformationField
            grow={1}
            title={translate("payout.contact_information.name")}
          >
            <Input
              disabled={updating}
              initialValue={values[INPUT_NAME]}
              name={INPUT_NAME}
              onChange={handleChange}
              onInputValidation={handleValidation}
              placeholder={translate(
                "payout.contact_information.name.placeholder",
              )}
              required={true}
              validations={VALIDATIONS_NAME}
            />
          </ContactInformationField>
          <ContactInformationField
            grow={1}
            title={translate("payout.contact_information.surname")}
          >
            <Input
              disabled={updating}
              initialValue={values[INPUT_SURNAME]}
              name={INPUT_SURNAME}
              onChange={handleChange}
              onInputValidation={handleValidation}
              placeholder={translate(
                "payout.contact_information.surname.placeholder",
              )}
              required={true}
              validations={VALIDATIONS_SURNAME}
            />
          </ContactInformationField>
        </Flex>
        <ContactInformationField
          grow={1}
          title={translate("payout.contact_information.birth_date")}
        >
          <BirthDate
            day={day}
            dayPlaceholder={translate("day")}
            disabled={updating}
            grow={1}
            minYear={1899}
            month={month}
            monthPlaceholder={translate("month")}
            name={INPUT_BIRTHDATE}
            onChange={handleChange}
            onValidation={handleValidation}
            required={true}
            year={year}
            yearPlaceholder={translate("year")}
          />
        </ContactInformationField>
        <ContactInformationField
          grow={1}
          title={translate("payout.contact_information.address")}
        >
          <Input
            disabled={updating}
            initialValue={values[INPUT_ADDRESS]}
            name={INPUT_ADDRESS}
            onChange={handleChange}
            onInputValidation={handleValidation}
            placeholder={translate(
              "payout.contact_information.address.placeholder",
            )}
            required={true}
            validations={VALIDATIONS_ADDRESS}
          />
        </ContactInformationField>
        <Flex direction="row" gutter={2}>
          <ContactInformationField
            grow={2}
            title={translate("payout.contact_information.city")}
          >
            <Input
              disabled={updating}
              initialValue={values[INPUT_CITY]}
              name={INPUT_CITY}
              onChange={handleChange}
              onInputValidation={handleValidation}
              placeholder={translate(
                "payout.contact_information.city.placeholder",
              )}
              required={true}
              validations={VALIDATIONS_CITY}
            />
          </ContactInformationField>
          <ContactInformationField
            grow={1}
            justify="flexEnd"
            title={translate("payout.contact_information.postal_code")}
          >
            <Input
              disabled={updating}
              initialValue={values[INPUT_POSTALCODE]}
              name={INPUT_POSTALCODE}
              onChange={handleChange}
              onInputValidation={handleValidation}
              placeholder={translate(
                "payout.contact_information.postal_code.placeholder",
              )}
              required={true}
              validations={VALIDATIONS_POSTALCODE}
            />
          </ContactInformationField>
        </Flex>
        <Flex gutter={1} justify="flexEnd">
          <Button
            dataQa="contact-information-edit__cancel-button"
            disabled={updating}
            onClick={closeEditing}
            type="button"
            variant="secondary"
          >
            {translate("payout.bank_account.cancel")}
          </Button>
          <Button
            dataQa="contact-information-edit__submit-button"
            disabled={updating || !dirty || !isFormValid}
            type="submit"
          >
            {translate("payout.contact_information.save_button")}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default ContactInformationEdit;
