import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "ANALYTICS_ROOMS_REQUEST",
  "ANALYTICS_ROOMS_SUCCESS",
  "ANALYTICS_ROOMS_ERROR",
  "ANALYTICS_ROOM_DETAILS_REQUEST",
  "ANALYTICS_ROOM_DETAILS_SUCCESS",
  "ANALYTICS_ROOM_DETAILS_ERROR",
  "ANALYTICS_LISTER_HINTS_REQUEST",
  "ANALYTICS_LISTER_HINTS_SUCCESS",
  "ANALYTICS_LISTER_HINTS_ERROR",
  "RESET_ANALYTICS",
]);
