import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

const TOP_BAR_HEIGHT = "80px";
const HEADER_HEIGHT = "64px";

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - ${TOP_BAR_HEIGHT} - ${HEADER_HEIGHT});
  padding-top: ${unitizedPx(3)};

  & > div {
    max-height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
