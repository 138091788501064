import React, { Component } from "react";
import classNames from "classnames";
import Cross from "components/Icons/Cross";
import Retry from "components/Icons/Retry";
import LoadingSpinner from "components/Icons/LoadingSpinner";

class Preview extends Component {
  constructor(props) {
    super(props);

    this.removeHandler = this.removeHandler.bind(this);
    this.retryHandler = this.retryHandler.bind(this);
  }

  removeHandler(e) {
    const { tempId, onRemove } = this.props;
    e.preventDefault();
    e.stopPropagation();

    onRemove(tempId);
  }

  retryHandler(e) {
    const { tempId, onRetry } = this.props;
    e.preventDefault();
    e.stopPropagation();

    onRetry(tempId);
  }

  render() {
    const { isCover, picture, uploadStatus } = this.props;
    const isLoading = uploadStatus === "loading";
    const hasError = uploadStatus === "error";
    const picturePreview = picture
      ? { backgroundImage: `url('${picture}')` }
      : null;

    return (
      <div className="DropZone__Preview">
        <div className="DropZone__Preview--box">
          <div
            className={classNames("DropZone__Preview--img", {
              "DropZone__Preview--img-cover": isCover,
            })}
            style={picturePreview}
          />
          {isLoading && (
            <div className="DropZone__Preview--loader">
              <LoadingSpinner />
            </div>
          )}
          {hasError && <div className="DropZone__Preview--error" />}
          <div className="DropZone__Preview--action">
            {!isLoading && (
              <div
                className="DropZone__Preview--close"
                onClick={this.removeHandler}
                role="presentation"
              >
                <Cross color="#fff" />
              </div>
            )}
            {hasError && (
              <div
                className="DropZone__Preview--retry"
                onClick={this.retryHandler}
                role="presentation"
              >
                <Retry />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Preview.defaultProps = {
  picture: "",
};

export default Preview;
