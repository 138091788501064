export const BANNERS = {
  DIGITAL: {
    cta: "home.banners.digital.cta",
    description: "home.banners.digital.description",
    image: "/assets/images/home/banner_renting.jpg",
    link: "/visit-book",
    title: "home.banners.digital.title",
  },
  PROFESSIONALS: {
    cta: "home.banners.professional.cta",
    description: "home.banners.professional.description",
    image: "/assets/images/home/banner_unity.jpg",
    link: "/agent",
    title: "home.banners.professional.title",
  },
};

export const INTRODUCTION = [
  {
    selector: "entire",
    title: "home.introduction.entire",
    image: "/assets/images/home/header_entire.jpg",
    mobileImage: "/assets/images/home/header_entire_mobile.jpg",
  },
  {
    selector: "room",
    title: "home.introduction.room",
    image: "/assets/images/home/header_room.jpg",
    mobileImage: "/assets/images/home/header_room_mobile.jpg",
  },
];

export const LINKED_CARDS = {
  title: "home.linkedcards.title",
  resources: [
    {
      id: "rent_with_us",
      image: "/assets/images/home/rent_with_us.jpg",
      title: "home.linkedcards.rent.title",
      description: "home.linkedcards.rent.description",
    },
    {
      id: "booking_request",
      image: "/assets/images/home/booking_request.jpg",
      link: "/visit-book",
      title: "home.linkedcards.booking.title",
      description: "home.linkedcards.booking.description",
    },
    {
      id: "verify_property",
      image: "/assets/images/home/verify_property.jpg",
      link: "/loyalty-program",
      title: "home.linkedcards.loyalty.title",
      description: "home.linkedcards.loyalty.subtitle",
    },
  ],
};

export const FEATURED_CITIES = {
  title: "home.cities.title",
  description: "home.cities.description",
  resources: [
    {
      id: "barcelona",
      image: "/assets/images/home/barcelona.jpg",
      link: "/s/Barcelona--Spain",
      title: "Barcelona",
      properties: 8000,
    },
    {
      id: "madrid",
      image: "/assets/images/home/madrid.jpg",
      link: "/s/Madrid--Spain",
      title: "Madrid",
      properties: 7000,
    },
    {
      id: "valencia",
      image: "/assets/images/home/valencia.jpg",
      link: "/s/Valencia--Spain",
      title: "Valencia",
      properties: 5000,
    },
    {
      id: "sevilla",
      image: "/assets/images/home/sevilla.jpg",
      link: "/s/Sevilla--Spain",
      title: "Sevilla",
      properties: 2500,
    },
    {
      id: "granada",
      image: "/assets/images/home/granada.jpg",
      link: "/s/Granada--Spain",
      title: "Granada",
      properties: 2000,
    },
    {
      id: "bilbao",
      image: "/assets/images/home/bilbao.jpg",
      link: "/s/Bilbao--Spain",
      title: "Bilbao",
      properties: 2000,
    },
    {
      id: "palma",
      image: "/assets/images/home/palma.jpg",
      link: "/s/Palma-de-Mallorca--Spain",
      title: "Palma",
      properties: 2000,
    },
    {
      id: "malaga",
      image: "/assets/images/home/malaga.jpg",
      link: "/s/Málaga--Spain",
      title: "Málaga",
      properties: 2000,
    },
  ],
};

export const FEATURED_SEARCHES = {
  title: "home.featured.searches.title",
  description: "home.featured.searches.description",
  resources: [
    {
      id: "full_flats",
      image: "/assets/images/home/full_flats.jpg",
      title: "home.featured.searches.flats.title",
      description: "home.featured.searches.flats.description",
      search: {
        label: "Barcelona, Spain",
        placeTypes: 2,
      },
    },
    {
      id: "flat_sharing",
      image: "/assets/images/home/flat_sharing.jpg",
      title: "home.featured.searches.rooms.title",
      description: "home.featured.searches.rooms.description",
      search: {
        label: "Barcelona, Spain",
        placeTypes: 1,
      },
    },
    {
      id: "temporal",
      image: "/assets/images/home/temporal.jpg",
      title: "home.featured.searches.temporary.title",
      description: "home.featured.searches.temporary.description",
      link: "/s/Barcelona--España?stay=short%2Cmedium",
    },
    {
      id: "pets_allowed",
      image: "/assets/images/home/pets_allowed.jpg",
      title: "home.featured.searches.pets.title",
      description: "home.featured.searches.pets.description",
      link: "/s/Barcelona--España?amenities=6",
    },
  ],
};

export const SEO_LINKS = {
  title: "home.seo.links.title",
  links: {
    es: [
      {
        city: "Madrid",
        links: [
          {
            link: "home.seo.links.madrid.rooms",
            title: "home.seo.links.city.title",
          },
          {
            link: "home.seo.links.madrid.flats",
            title: "home.seo.links.city.flat.title",
          },
        ],
      },
      {
        city: "Barcelona",
        links: [
          {
            link: "home.seo.links.barcelona.rooms",
            title: "home.seo.links.city.title",
          },
          {
            link: "home.seo.links.barcelona.flats",
            title: "home.seo.links.city.flat.title",
          },
        ],
      },
      {
        city: "Valencia",
        links: [
          {
            link: "home.seo.links.valencia.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Sevilla",
        links: [
          {
            link: "home.seo.links.sevilla.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Málaga",
        links: [
          {
            link: "home.seo.links.malaga.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Palma de Mallorca",
        links: [
          {
            link: "home.seo.links.palma.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Granada",
        links: [
          {
            link: "home.seo.links.granada.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Alicante",
        links: [
          {
            link: "home.seo.links.alicante.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Bilbao",
        links: [
          {
            link: "home.seo.links.bilbao.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Murcia",
        links: [
          {
            link: "home.seo.links.murcia.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
    ],
    en: [
      {
        city: "Madrid",
        links: [
          {
            link: "home.seo.links.madrid.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Barcelona",
        links: [
          {
            link: "home.seo.links.barcelona.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Valencia",
        links: [
          {
            link: "home.seo.links.valencia.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Sevilla",
        links: [
          {
            link: "home.seo.links.sevilla.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Málaga",
        links: [
          {
            link: "home.seo.links.malaga.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Palma de Mallorca",
        links: [
          {
            link: "home.seo.links.palma.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Granada",
        links: [
          {
            link: "home.seo.links.granada.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Alicante",
        links: [
          {
            link: "home.seo.links.alicante.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Bilbao",
        links: [
          {
            link: "home.seo.links.bilbao.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
      {
        city: "Murcia",
        links: [
          {
            link: "home.seo.links.murcia.rooms",
            title: "home.seo.links.city.title",
          },
        ],
      },
    ],
  },
};

export const META_DATA = {
  title: "meta.title.home",
  meta: [
    {
      name: "title",
      content: "meta.title.home",
    },
    {
      name: "description",
      content: "meta.description.home",
    },
    {
      property: "og:title",
      content: "meta.title.home",
    },
    {
      property: "og:description",
      content: "meta.description.home",
    },
  ],
};
