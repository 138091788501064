import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";

export const addDiscard = (token, roomId) => {
  const { BADI_DISCARDS } = envVars();
  const serverUrl = getServerUrl(BADI_DISCARDS);
  return fetch(serverUrl, {
    params: { room_id: roomId },
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const removeDiscard = (token, roomId) => {
  const { BADI_DISCARDS } = envVars();
  const serverUrl = getServerUrl(BADI_DISCARDS);
  return fetch(serverUrl, {
    params: { room_id: roomId },
    requiredParams: true,
    method: "DELETE",
    token,
  });
};
