import { connect } from "react-redux";
import {
  getReferralAccountSelector,
  getPendingReferralsSelector,
  getReferralProductsSelector,
} from "datamodel/referrals/selectors";
import {
  getPendingReferralsAction,
  getReferralAccountAction,
  resetReferralsAction,
  getReferralProductsAction,
} from "datamodel/referrals/actions";
import { getUserMode } from "datamodel/User/utils";
import { getToken, isLoggedUser } from "datamodel/Auth/selectors";
import { getIsSafari, isMobileSelector } from "datamodel/Device/selectors";

import Referrals from "./referrals";

const mapStateToProps = (state) => ({
  referralAccount: getReferralAccountSelector(state),
  pendingReferrals: getPendingReferralsSelector(state),
  referralProducts: getReferralProductsSelector(state),
  isLister: getUserMode(state.User.mode) === "lister",
  token: getToken(state),
  isLoggedIn: isLoggedUser(state),
  isIOSApp: getIsSafari(state) && isMobileSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReferralAccount: (sgid) => dispatch(getReferralAccountAction(sgid)),
  getPendingReferrals: (paginationToken, sgid) =>
    dispatch(getPendingReferralsAction(paginationToken, sgid)),
  getReferralProducts: (sgid) => dispatch(getReferralProductsAction(sgid)),
  resetReferrals: () => dispatch(resetReferralsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
