import React, { useMemo } from "react";
import GoogleMap from "google-map-react";

import MapTip from "./map-tip";
import { GOOGLE_MAP_OPTIONS } from "./constants";
import { getCenter } from "./utils";

const RoomLocation = ({
  latitude,
  longDisplacement = 0,
  longitude,
  onChange = () => {},
  tip = null,
  zoom = 15,
}) => {
  const defaultCenter = useMemo(
    () => getCenter({ latitude, longDisplacement, longitude }),
    [],
  );
  const center = useMemo(
    () => getCenter({ latitude, longDisplacement, longitude }),
    [latitude, longDisplacement, longitude],
  );

  return (
    <>
      <GoogleMap
        center={center}
        defaultCenter={defaultCenter}
        defaultZoom={zoom}
        onChange={onChange}
        options={GOOGLE_MAP_OPTIONS}
      />
      {tip && <MapTip text={tip} />}
    </>
  );
};

export default RoomLocation;
