import React, { Component } from "react";

import { TextButton, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getSelectedElements } from "../utils";

import SortByFields from "./sort-by-fields";
import { SubmitFormWrapper, StyledForm } from "../styled-components";

class SortByForm extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedSortById: 0, updated: false };
    this.sortBy = translate(props.sortBy);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.updated) {
      return { updated: false };
    }

    const { sortBy } = props;
    const selectedSortBy = sortBy.find((sort) => sort.selected) || sortBy[0];

    return { selectedSortById: selectedSortBy.id };
  }

  handleChangeSortBy = (selectedSortById) => {
    this.setState({ selectedSortById, updated: true });
  };

  handleApplyFilter = () => {
    const { selectedSortById } = this.state;
    const { onFilterApplied, setSortBy, sortBy } = this.props;
    const parsedSortBy = getSelectedElements(sortBy, [selectedSortById]);

    setSortBy(parsedSortBy);
    onFilterApplied();
  };

  render() {
    const { selectedSortById } = this.state;

    return (
      <Flex as={StyledForm} direction="column">
        <SortByFields
          onChange={this.handleChangeSortBy}
          selectedSortById={selectedSortById}
          sortBy={this.sortBy}
        />
        <SubmitFormWrapper>
          <TextButton onClick={this.handleApplyFilter} type="button">
            {translate("search.filters.button")}
          </TextButton>
        </SubmitFormWrapper>
      </Flex>
    );
  }
}

export default SortByForm;
