import React from "react";
import { withRouter } from "react-router";

import { getExpressVisitPath } from "Sections/visits/routes";
import VisitActions from "./visit-actions-mapping";

const VisitActionsRoute = ({ router, ...props }) => {
  const { visitId } = props;

  const handleOpenVideocall = () => {
    router.push(getExpressVisitPath({ visitId }));
  };

  return <VisitActions openVideoCall={handleOpenVideocall} {...props} />;
};

export default withRouter(VisitActionsRoute);
