import React from "react";
import { Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { OTHER_ACTIONS_MAPPER } from "./constants";
import ActionCard from "./action-card";

const OtherActions = ({ actions, navigateTo }) => {
  const getActions = () =>
    actions.map((action) => {
      if (!OTHER_ACTIONS_MAPPER[action.id]) return null;
      const { label, icon, text, path } = OTHER_ACTIONS_MAPPER[action.id];

      const handleOnClick = () => {
        navigateTo(path);
      };

      return (
        <ActionCard
          icon={icon}
          label={label}
          onClick={handleOnClick}
          text={text}
        />
      );
    });

  const actionsList = getActions();

  return actionsList.length ? (
    <>
      <Spacer bottom={1}>
        <Text body="14-Bold">{translate("listing.more.options")}</Text>
      </Spacer>
      {actionsList}
    </>
  ) : null;
};

export default OtherActions;
