import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { BUTTON_TYPES, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getAvailableRoomsFromPlan } from "datamodel/premium/api";
import PurchaseButton from "modules/stripe/purchase";
import RoomSelector, { RoomSelectorEmptyState } from "components/room-selector";
import { Footer } from "./styled-components";

const UpgradeRoomSelector = ({
  buttonText = "badi.gold.select_room_cta",
  category,
  description = "premium_room_selector_description",
  id,
  onCancel,
  onContinue = null,
  onLoad = null,
  productId,
  productReference,
  title = "premium.select_room_title",
  token,
  type = null,
}) => {
  const [rooms, setRooms] = useState(null);
  const [selectedRoomId, setSelectedRoom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const updateRooms = ({ rooms: newRooms, pagination }) => {
    setRooms(newRooms);
    setCurrentPage(pagination.current_page);
    setTotalPages(pagination.total_pages);
  };

  useEffect(() => {
    getAvailableRoomsFromPlan({ token }, { planId: id }).then(updateRooms);

    if (onLoad) {
      onLoad();
    }
  }, []);

  const handleSelectRoom = (event) => {
    setSelectedRoom(Number(event.currentTarget.value));
  };

  const handlePageChange = (page) => {
    getAvailableRoomsFromPlan({ token }, { planId: id, page }).then(
      updateRooms,
    );
  };

  if (!rooms) {
    return null;
  }

  return (
    <Flex direction="column">
      {rooms.length ? (
        <>
          <RoomSelector
            currentPage={currentPage}
            onClose={onCancel}
            onPageChange={handlePageChange}
            onSelect={handleSelectRoom}
            pageDescription={translate(description)}
            pageTitle={translate(title)}
            roomId={selectedRoomId}
            roomItems={rooms}
            totalPages={totalPages}
          />
          <Footer data-qa="upgrade-room-selector-footer">
            <Container>
              <Row>
                <Col lg={{ size: 10, offset: 1 }} md={{ size: 10 }} sm={12}>
                  <PurchaseButton
                    category={category}
                    data-qa="premium-room-selector-purchase-button"
                    disabled={!selectedRoomId}
                    onClick={onContinue}
                    productId={productId}
                    purchasableId={productReference}
                    roomId={selectedRoomId}
                    type={type}
                    variant={BUTTON_TYPES.PREMIUM_SECONDARY}
                  >
                    {translate(buttonText)}
                  </PurchaseButton>
                </Col>
              </Row>
            </Container>
          </Footer>
        </>
      ) : (
        <RoomSelectorEmptyState
          buttonText={translate("premium.select_room_empty_cta")}
          description={translate("badi.gold.select_room_empty_subtitle")}
          onClose={onCancel}
          pageDescription={translate("badi.gold.select_room_subtitle")}
          pageTitle={translate("badi.gold.select_room_title")}
          title={translate("badi.gold.select_room_empty_title")}
        />
      )}
    </Flex>
  );
};

export default UpgradeRoomSelector;
