import styled, { css } from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";

const visibleTransition = css`
  transform-origin: 0% 0%;
  transform: translate(100vw, 0);
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

const hiddenTransition = css`
  transform: translate(-100vw, 0);
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

export const Root = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div`
  cursor: initial;
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  padding: 24px;
  box-sizing: border-box;
  z-index: 1000;
  overflow-y: auto;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  list-style-type: none;

  ${({ visible }) => (visible ? visibleTransition : hiddenTransition)}
`;

export const Anchor = styled.a`
  position: relative;

  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-decoration-color: none;
  width: 100%;
`;

export const MenuItemSeparator = styled.div`
  width: 100%;
  margin: 12px 0;
  height: 1px;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
`;

export const Highlight = styled.div`
  position: relative;
  display: inline;

  :after {
    position: absolute;
    right: -8px;
    top: -2px;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 4px;
    box-sizing: border-box;
    background: ${BADI_COLORS.SEMANTIC.INFO.DEFAULT};
  }
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const MenuButton = styled(Button)`
  position: relative;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  text-align: left;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 5px;
`;
