import React, { useEffect, useState } from "react";
import {
  openCompleteProfileModal,
  COMPLETE_PROFILE_FROM,
} from "components/complete-profile";

import { getRedirectLocation, getAuthAction } from "./utils";
import { AUTH_ACTIONS } from "./constants";

export default (ComposeComponent) => {
  const Authenticate = ({
    authtoken,
    ghost,
    id,
    isGdprAnswered,
    location,
    requireCompleteProfile,
    router,
    setRequirements,
    setRegister,
    ...rest
  }) => {
    const [action, setAction] = useState(AUTH_ACTIONS.SHOW_NOTHING);

    useEffect(() => {
      if (!id) {
        router.push(getRedirectLocation(location));
        setRegister(true);

        return;
      }

      if (!isGdprAnswered) {
        setRequirements({ gdpr: { show: true } });
      }

      const currentAction = getAuthAction({
        id,
        ghost,
        location,
        requireCompleteProfile,
      });

      if (currentAction === AUTH_ACTIONS.SHOW_MODAL) {
        openCompleteProfileModal({
          from: COMPLETE_PROFILE_FROM.DEFAULT,
          onCancel: () => router.push(getRedirectLocation()),
        });
      }
      setAction(currentAction);
    }, [id, isGdprAnswered, ghost, location, requireCompleteProfile]);

    const props = {
      ...rest,
      authtoken,
      ghost,
      id,
      isGdprAnswered,
      location,
      router,
      setRegister,
      setRequirements,
    };

    if (action === AUTH_ACTIONS.SHOW_CONTENT) {
      return <ComposeComponent {...props} />;
    }
    return null;
  };

  Authenticate.requiredActions = ComposeComponent.requiredActions;

  return Authenticate;
};
