import React from "react";
import { Heading, Flex, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { useSelector } from "react-redux";
import { getHomePath } from "Sections/Home/routes";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import { getIsIOS } from "app/datamodel/Device/selectors";

import Menu, {
  MenuItemButton,
  MenuItemLink,
  MenuItemSeparator,
} from "../reponsive-menu";
import { getDownloadAppLink } from "base/shared/app-links";

const PublicMenu = ({ onLogin, onListRoomClick, onRegister }) => {
  const isIOS = useSelector(getIsIOS);
  return (
    <Menu>
      <MenuItemLink dataQa="topbar-public-menu-home" to={getHomePath()}>
        {translate("topbar.element.home.title")}
      </MenuItemLink>
      <MenuItemSeparator />
      <MenuItemLink
        dataQa="topbar-public-menu-download"
        external={true}
        to={getDownloadAppLink({ ios: isIOS })}
      >
        {translate("topbar.element.download.title")}
      </MenuItemLink>
      <MenuItemLink
        dataQa="topbar-public-menu-rent-button"
        imageSrc="/assets/images/room-info-menu.svg"
        onClick={onListRoomClick}
        to={getRentRoomPath()}
      >
        <Flex direction="column">
          <Heading as="span" level="16-Medium">
            {translate("topbar.element.rentyourroom.title")}
          </Heading>
          <Text body="14-Regular">
            {translate("topbar.element.rentroom.description")}
          </Text>
        </Flex>
      </MenuItemLink>
      <MenuItemSeparator />
      <MenuItemButton dataQa="topbar-public-menu-register" onClick={onRegister}>
        {translate("topbar.element.signup.title")}
      </MenuItemButton>
      <MenuItemButton dataQa="topbar-public-menu-login" onClick={onLogin}>
        {translate("topbar.element.signin.title")}
      </MenuItemButton>
    </Menu>
  );
};

export default PublicMenu;
