import { browserHistory } from "react-router";
import { getSearchUrl } from "datamodel/SearchFilters/utils";
import { ET_PARAMS_PREFIX } from "base/shared/tracking-headers";
import { getParams } from "../shared/Utils";

const searchMiddleware = (store) => (next) => (action) => {
  const { searchUpdate } = action;

  if (searchUpdate && browserHistory) {
    next(action);

    const { SearchFilters: searchFilters } = store.getState();

    const utmParams = getParams(location.search, "utm_");
    const trackParams = getParams(location.search, ET_PARAMS_PREFIX);
    const frontendFeatureToggleParams = getParams(location.search, "feature_");
    const additionalParams = {
      ...utmParams,
      ...trackParams,
      ...frontendFeatureToggleParams,
    };
    const searchUrl = getSearchUrl(searchFilters, additionalParams);

    browserHistory.push(searchUrl);
  } else next(action);
};

export default searchMiddleware;
