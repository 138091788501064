import { useState } from "react";

export const useFormValidation = (inputs) => {
  const [fieldsValidation, setFieldValidation] = useState(inputs);

  const updateField = (input, value) => {
    setFieldValidation((state) => ({
      ...state,
      [input]: value,
    }));
  };

  const isValid = Object.values(fieldsValidation).every(
    (fieldValid) => !!fieldValid,
  );

  return [isValid, updateField];
};

export const useForm = (fields) => {
  const [values, setFieldsValue] = useState(fields);

  const setInputValue = (name, value) => {
    setFieldsValue((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFieldsValue({ ...fields });
  };

  return [values, setInputValue, resetForm];
};
