class VisitExpress {
  constructor(entity) {
    this.entity = entity || {};
  }

  get appKey() {
    return this.entity.app_key;
  }

  get authTicket() {
    return this.entity.ticket;
  }

  get receiverName() {
    return this.entity.receiver_name || "";
  }

  get receiverId() {
    return this.entity.receiver_id;
  }

  get endTime() {
    return this.entity.finish_hour;
  }

  get consent() {
    return this.entity.consent;
  }

  toJSON() {
    return {
      appKey: this.appKey,
      authTicket: this.authTicket,
      receiverName: this.receiverName,
      receiverId: this.receiverId,
      endTime: this.endTime,
      consent: this.consent,
    };
  }
}

export default VisitExpress;
