import {
  LANGUAGES,
  GENDERS,
  PROFESSIONS,
  STUDIES,
  OCCUPATIONS,
} from "base/shared/StaticData";

import { translate } from "base/shared/Localization";
import dayjs from "dayjs";

import UserSocialProfile from "datamodel/User/models/user-social-profile-model";

export const getElementByValue = (elements, value) =>
  elements.find((e) => e.value === value);

export const getGender = (value) =>
  getElementByValue(GENDERS, value) || { title: "", icon: "" };

export const getStudies = (value) => getElementByValue(STUDIES, value);

export const getUserLanguages = (langs = []) =>
  langs.map((lang) => LANGUAGES.find((l) => l.value === lang.language_id));

export const getProfession = (value) => getElementByValue(PROFESSIONS, value);

// TODO deprecate this implementation in favor of `getUserOccupation` for consistency with Inbox implementation
export const getOccupation = (value) =>
  getElementByValue(OCCUPATIONS, value) || { title: "", icon: "" };

export const getYearsOld = (date) => {
  const userDate = date && dayjs(date);

  if (!userDate || !userDate.isValid()) {
    return "";
  }

  const years = dayjs().diff(userDate, "year");
  return Math.max(0, years);
};

export const getVerificationLevel = (badges) => {
  if (!badges || !badges.length) return "";

  const trusted = !!badges.find((badge) => badge.badge_type === 2);
  const verified = !!badges.find((badge) => badge.badge_type === 1);

  if (trusted) return "trusted";
  if (verified) return "verified";

  return "";
};

export function getUserOccupation(occupation) {
  if (!occupation) {
    return {
      action: null,
      occupation: null,
    };
  }
  const action = occupation.main_occupation === 2 ? "works" : "studies";

  const professionOrStudies =
    action === "studies"
      ? getStudies(occupation.pursued_level_of_study)
      : getProfession(occupation.work_industry);

  return {
    action,
    occupation: professionOrStudies ? professionOrStudies.title : "",
  };
}

export const getProfessionOrStudies = (occupation) => {
  try {
    const userOccupation = getUserOccupation(occupation);
    return userOccupation.occupation || "";
  } catch (error) {
    return "";
  }
};

export const composeLanguages = (languagesArray, limit) => {
  if (!languagesArray.length) return null;
  return translate(languagesArray, "title")
    .slice(0, limit || languagesArray.length)
    .map((lang) => lang.title)
    .join(", ");
};

export const getTrustedItems = (trust) =>
  !trust || !trust.items ? [] : trust.items;

export const getListerScore = (trust) =>
  trust && trust.lister_score ? trust.lister_score : "";

export const getSocialProfiles = (socialProfiles) => {
  if (!socialProfiles) return [];

  return socialProfiles
    .map((profile) => new UserSocialProfile(profile))
    .filter((profile) => profile.order > 0)
    .sort((prof1, prof2) => prof1.order - prof2.order);
};

export const getLanguagesString = (languages = []) => {
  const languagesString = languages
    .map(({ title }) => translate(title))
    .join(", ");

  return translate("inbox.info.user.speaks", {
    languages: languagesString,
  });
};

export const getOccupationString = (occupation) => {
  if (!occupation) return "";

  // this is a patch so if occupation doesn't have the legacy shape
  if (typeof occupation === "string") return translate(occupation);

  return translate(`inbox.info.user.${occupation.action}`, {
    occupation: translate(occupation.occupation),
  });
};
