import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex } from "@badi/badi-components";
import { getSearchSelectedCard } from "datamodel/search-rooms/selectors";
import Actions from "datamodel/search-rooms/actions";

import RoomCard from "../room-card";
import { parseMapRoomCardPictures } from "./utils";
import { Background, RoomCardWrapper } from "./styled-components";

const MapRoomCard = ({ handleEtParams }) => {
  const { pictures, subheading, headline, extraInfo, labels, lister, roomId } =
    useSelector(getSearchSelectedCard) || {};

  const dispatch = useDispatch();
  const unSelectRoom = () => dispatch(Actions.selectRoom(0));

  return roomId ? (
    <Background onClick={unSelectRoom}>
      <Flex
        alignItems="center"
        direction="column"
        fullHeight={true}
        justify="center"
      >
        <RoomCardWrapper>
          <RoomCard
            extraInfo={extraInfo}
            handleEtParams={handleEtParams}
            headline={headline}
            htmlId={`list-room-card-${roomId}`}
            isMapCard={true}
            key={roomId}
            labels={labels}
            lister={lister}
            pictures={parseMapRoomCardPictures(pictures)}
            roomId={roomId}
            subheading={subheading}
          />
        </RoomCardWrapper>
      </Flex>
    </Background>
  ) : null;
};

export default MapRoomCard;
