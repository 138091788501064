import React, { useState } from "react";
import { Text, Flex, colors, Spacer } from "@badi/badi-components";
import Select from "components/Form/Select/Select";
import Hourglass from "components/Icons/Hourglass";
import { translate } from "base/shared/Localization";
import { SearchLengthStayWrapper } from "../styled-components";

export const getSelectedElement = (elements, id) =>
  elements.map((elem) => ({
    ...elem,
    selected: Number(id) === elem.id,
  }));

const LengthStaySelector = ({
  id = "",
  onChange = () => {},
  stayOptions,
  initialStay,
}) => {
  const translatedStayOptions = translate(stayOptions, [
    "title",
    "description",
  ]);

  const [value, setValue] = useState(initialStay);

  const handleMoveOutMonthChange = (event) => {
    setValue(event.target.value);
    onChange(getSelectedElement(translatedStayOptions, event.target.value));
  };

  return (
    <Flex
      dataQa="search-bar-availability-length_stay_selector"
      direction="column"
    >
      <label htmlFor="length-stay-selector">
        <Text body="12-Medium">{translate(`search.home_length_label`)}</Text>
        <SearchLengthStayWrapper
          name={"search-bar-availability-length_stay_select"}
          onChange={handleMoveOutMonthChange}
          required={true}
          value={value}
        >
          <option disabled={true} value={""}>
            {translate("booking.dates.length_label")}
          </option>

          {translatedStayOptions.map(
            ({ title, value: itemVal, description }) => (
              <option key={`${title}-${value}`} value={itemVal.toString()}>
                {description ? `${title}: ${description}` : title}
              </option>
            ),
          )}
        </SearchLengthStayWrapper>
      </label>
    </Flex>
  );
};

export default LengthStaySelector;
