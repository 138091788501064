import React from "react";
import { Flex, Choice, Text } from "@badi/badi-components";

import Image from "components/Image";

const FeedbackChoice = ({ checked, id, name, onChange, value, children }) => {
  const handleChange = (event) => {
    onChange(event.currentTarget.value);
  };
  return (
    <Choice
      align="left"
      checked={checked}
      id={`${id}-feedback`}
      name={name}
      onChange={handleChange}
      type="radio"
      value={value}
    >
      <Flex alignItems="center" gutter={2}>
        <Image
          alt="yes feedback"
          lazy={false}
          src={`/assets/images/visits/visit-feedback-${id}.svg`}
        />
        <Text body={2}>{children}</Text>
      </Flex>
    </Choice>
  );
};

export default FeedbackChoice;
