import React, { useEffect, useState } from "react";
import {
  BREAKPOINTS_PX,
  Button,
  Heading,
  Spacer,
  Text,
  useMatchMedia,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import { getRoomDetailPath } from "Sections/Room/routes";
import MultiShare from "components/multi-share";
import Image from "components/Image/Image";
import RecommendedTenants from "./components/recommended-tenants";
import { Root } from "./styled-components";

const STEPS = {
  PUBLISHED_LISTING: 1,
  RECOMMENDED_TENANTS: 2,
};

const PublishedListing = ({ onClose, roomId, room = null }) => {
  const [step, setStep] = useState(STEPS.PUBLISHED_LISTING);
  const [isWideScreen] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);

  const handleClick = () => {
    setStep(STEPS.RECOMMENDED_TENANTS);
  };

  const url = getRoomDetailPath({ roomId });

  if (step === STEPS.RECOMMENDED_TENANTS) {
    return <RecommendedTenants onClose={onClose} room={room} roomId={roomId} />;
  }

  return (
    <Root alignItems="center" direction="column" fullHeight={true} gutter={2}>
      <Spacer bottom={1} top={3}>
        <Image lazy={false} src="/assets/images/fists.svg" />
      </Spacer>
      <Spacer top={1}>
        <Heading data-qa="published-listing-title" level="20-Bold">
          {translate("published_listing.modal.title")}
        </Heading>
      </Spacer>
      <Text data-qa="published-listing-text">
        {translate("published_listing.modal.text")}
      </Text>
      <Spacer bottom={6}>
        <MultiShare
          grow={1}
          target={url}
          title={translate("listing.share.text")}
        />
      </Spacer>
      <Button
        dataQa="published-listing-button"
        fullWidth={true}
        onClick={handleClick}
        wide={!isWideScreen}
      >
        {translate("published_listing.modal.cta")}
      </Button>
    </Root>
  );
};

export default PublishedListing;
