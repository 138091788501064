import React, { Component } from "react";

import { Choice, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import FilterSectionWrapper from "../../../wrappers/filter-section-wrapper";
import { FormSectionChoiceElement } from "../styled-components";

class SortByFields extends Component {
  handleSortByChange = (e) => {
    const sortById = parseInt(e.currentTarget.value, 10);

    if (!isNaN(sortById)) {
      this.props.onChange(sortById);
    }
  };

  render() {
    const { sortBy, selectedSortById } = this.props;

    return (
      <FilterSectionWrapper title={`${translate("search.filters.sortby")}:`}>
        <Spacer bottom={2}>
          {sortBy.map((sort) => (
            <Spacer md={{ top: 2 }} xs={{ top: 1 }}>
              <FormSectionChoiceElement
                as={Choice}
                checked={selectedSortById === sort.id}
                id={`sort-by-${sort.id}`}
                key={sort.id}
                name="sort-by"
                onChange={this.handleSortByChange}
                type="radio"
                value={sort.value}
              >
                {sort.title}
              </FormSectionChoiceElement>
            </Spacer>
          ))}
        </Spacer>
      </FilterSectionWrapper>
    );
  }
}

export default SortByFields;
