import createReducer from "base/shared/createReducer";
import Types from "./types";
import { SearchRoomsModel } from "./models";
import { SEARCHROOMS_STATUSES as STATUS } from "./static";

function reset() {
  return { ...SearchRoomsModel };
}

function roomsError(state, action) {
  return {
    ...state,
    status: STATUS.error,
    error: action.error,
    isLoginRequired: action.error.code === "login_required",
  };
}

function roomsRequest(state) {
  return {
    ...state,
    status: STATUS.fetching,
    error: null,
  };
}

function roomsSuccess(state, action) {
  const {
    cards,
    previousTokenCards,
    nextTokenCards,
    markers,
    nextTokenMarkers,
    metadata,
  } = action.result;

  return {
    ...state,
    status: STATUS.loaded,
    cards,
    nextTokenCards,
    previousTokenCards,
    markers,
    nextTokenMarkers,
    error: null,
    metadata,
  };
}

function selectRoom(state, action) {
  return {
    ...state,
    selectedRoomId: action.result,
  };
}

function resetLoginRequired(state) {
  return {
    ...state,
    isLoginRequired: false,
  };
}

const actionHandlers = {
  [Types.SEARCH_ROOMS_ERROR]: roomsError,
  [Types.SEARCH_ROOMS_REQUEST]: roomsRequest,
  [Types.SEARCH_ROOMS_SUCCESS]: roomsSuccess,
  [Types.SEARCH_ROOMS_RESET]: reset,
  [Types.SEARCH_ROOMS_SELECT_ROOM]: selectRoom,
  [Types.SEARCH_ROOMS_RESET_LOGIN_REQUIRED]: resetLoginRequired,
};

export default createReducer(actionHandlers, { ...SearchRoomsModel });
