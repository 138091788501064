import styled from "styled-components";
import { unitizedPx, BADI_COLORS } from "@badi/badi-components";

export const Card = styled.div`
  position: relative;
  overflow: hidden;
  height: ${(props) => unitizedPx(props.height)};
  border-radius: ${unitizedPx(0.5)};
  border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  text-align: center;
  padding: ${unitizedPx(3)} ${unitizedPx(2)};
`;

export const WidgetValue = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
`;

export const Link = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const MetricsNotFoundDescription = styled.div`
  text-align: center;
`;

export const MetricsNotFoundTitle = styled.h3`
  text-align: center;
`;

export const MetricsNotFoundWrapper = styled.div`
  background-color: white;
  height: ${unitizedPx(42.5)};
  margin: 0 auto;
`;

export const EvolutionGraphWrapper = styled.div`
  position: relative;
  height: 45vh;
`;

export const HintIconWrapper = styled.div`
  width: 40px;
`;

export const Square = styled.div`
  position: relative;
  width: 20px;
  height: 20px;

  border-radius: 4px;
  border: solid 1px ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
  box-sizing: border-box;
  background-color: ${({ color }) => color};
`;
