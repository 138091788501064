import React from "react";

import { translate } from "base/shared/Localization";
import { getProfession, getStudies } from "base/shared/User";
import { UserCardBlock } from "../styled-components";

const WorkAndStudies = ({ studies, visibleStudies, visibleWork, work }) => {
  const userWork = getProfession(work);
  const userStudies = getStudies(studies);

  return (
    <UserCardBlock>
      {visibleWork && userWork && (
        <div className="UserCard__block UserCard__block_work">
          <h3>{translate("user.section.work")}</h3>
          <p>{translate(userWork.title)}</p>
        </div>
      )}
      {visibleStudies && userStudies && (
        <div className="UserCard__block UserCard__block_studies">
          <h3>{translate("user.section.study")}</h3>
          <p>{translate(userStudies.title)}</p>
        </div>
      )}
    </UserCardBlock>
  );
};

export default WorkAndStudies;
