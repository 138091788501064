import createReducer from "base/shared/createReducer";
import { setLocalization as setLocaleUtils } from "base/shared/Localization";

import Types from "../types";
import { LocaleModel } from "../models";

function setLocale(state, action) {
  return { ...state, locale: action.result };
}

function setLocalization(state, action) {
  const { locale, phrases } = action.result;
  setLocaleUtils(locale, phrases);

  return { locale, phrases };
}

const actionHandlers = {
  [Types.SET_LOCALE]: setLocale,
  [Types.SET_LOCALIZATION]: setLocalization,
};

export default createReducer(actionHandlers, { ...LocaleModel });
