export const getHtmlTag = (level, hero) => {
  let tag = "h1";

  if (!hero) {
    switch (level) {
      case "24-Bold":
      case 2:
        tag = "h2";
        break;
      case "20-Bold":
      case 3:
        tag = "h3";
        break;
      case "16-Bold":
      case 4:
        tag = "h4";
        break;
      case "16-Medium":
      case 5:
        tag = "h5";
        break;
      default:
    }
  }

  return tag;
};
