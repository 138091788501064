import React, { Component } from "react";
import { connect } from "react-redux";

import envVars from "base/shared/Env";
import { translate } from "base/shared/Localization";
import FacebookButton from "Sections/auth/forms/authentication-form/components/facebook-button";

import styles from "./styles.css";

class FacebookConfirmation extends Component {
  constructor(props) {
    super(props);

    this.getContent = this.getContent.bind(this);
  }

  getContent() {
    const { isConfirmed } = this.props;
    const { BADI_AUTH_FACEBOOK_APP_ID } = envVars();

    if (isConfirmed) {
      return (
        <div className="form__half">
          <div className="form__element">
            <div className="form__text">
              {translate("Your Facebook has been connected.")}
            </div>
          </div>
          <div className="form__element text_right">
            <div className="form__true">{translate("Connected")}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="form__half">
        <div className="form__element">
          <div className="form__title">
            <span className="form__name">{translate("Facebook")}</span>
          </div>
          <div className="form__text">
            {translate(
              "Connect your profile with Facebook and see if you have mutual friends and interests.",
            )}
          </div>
        </div>
        <div className="form__element text_right">
          <FacebookButton appId={BADI_AUTH_FACEBOOK_APP_ID} confirmation={true}>
            {translate("Connect")}
          </FacebookButton>
        </div>
      </div>
    );
  }

  render() {
    return <div className="form__row">{this.getContent()}</div>;
  }
}

export default connect((state) => ({
  isConfirmed: state.User.confirmations.facebook,
}))(FacebookConfirmation);
