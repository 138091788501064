import React from "react";
import { Flex, Text } from "@badi/badi-components";
import { REVIEWER_TYPES } from "datamodel/reviews/constants";
import SuccessCheck from "components/Icons/SuccessCheck";
import { translate } from "base/shared/Localization";

const renderThirdHint = (reviewerType) => {
  switch (reviewerType) {
    case REVIEWER_TYPES.LISTER:
      return (
        <ReviewHint
          dataQa="review_text_hint_3"
          text={translate("review.popup_hint_3_lister")}
        />
      );
    case REVIEWER_TYPES.SEEKER:
      return (
        <ReviewHint
          dataQa="review_text_hint_3"
          text={translate("review.popup_hint_3_seeker")}
        />
      );
    default:
      return null;
  }
};

const ReviewHint = ({ text, dataQa }) => (
  <Flex direction="row" gutter={1}>
    <SuccessCheck size={24} />
    <Text body="14-Regular" dataQa={dataQa}>
      {text}
    </Text>
  </Flex>
);

const ReviewHints = ({ reviewerType }) => (
  <Flex direction="column" gutter={1}>
    <ReviewHint
      dataQa="review_text_hint_1"
      text={translate("review.popup_hint_1")}
    />
    <ReviewHint
      dataQa="review_text_hint_2"
      text={translate("review.popup_hint_2")}
    />
    {renderThirdHint(reviewerType)}
  </Flex>
);

export default ReviewHints;
