export const VISIT_CATEGORIES = {
  IN_PLACE: 1,
  VIDEOCALL: 2,
  BOTH: 3,
};

export const GENDERS_ROOMMATES_PARSER = [
  {
    id: "female",
    value: 1,
  },
  {
    id: "male",
    value: 2,
  },
  {
    id: "nonBinary",
    value: 3,
  },
];
