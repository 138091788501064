import styled from "styled-components";
import { BREAKPOINTS_PX, unitizedPx } from "@badi/badi-components";

export const RoomCardWrapper = styled.div`
  flex-direction: column;
`;

export const RoomCardLink = styled.a`
  display: block;
  width: 242px;

  @media screen and (min-width: ${BREAKPOINTS_PX.md}) {
    width: calc(47vw - 12px);
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.lg}) {
    width: 100%;
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.xl}) {
    width: 242px;
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.xxl}) {
    width: 258px;
  }

  &:hover,
  &:visited,
  &:link,
  &:active {
    cursor: pointer;
    text-decoration: none;
    color: unset;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: ${unitizedPx(1)};
  border-radius: ${unitizedPx(0.5)};
  @media screen and (min-width: ${BREAKPOINTS_PX.lg}) {
    height: 163px;
    width: ${unitizedPx(33)};
    margin-right: ${unitizedPx(3)};
    flex-shrink: 1;
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.xl}) {
    width: 100%;
    margin-bottom: ${unitizedPx(2)};
    margin-right: 0;
  }

  ${RoomCardLink}:hover & {
    box-shadow: 0px ${unitizedPx(1)} ${unitizedPx(2)} 0px rgba(0, 0, 0, 0.2);
  }
`;

export const Title = styled.h4`
  padding: 0;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`;
