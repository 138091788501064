import { connect } from "react-redux";
import {
  getBankAccountLastDigits,
  getBankAccountCountry,
  getBankAccountError,
  getBankAccountUpdatingStatus,
} from "datamodel/Payouts/selectors";
import PayoutsActions from "datamodel/Payouts/actions";

import BankAccount from "./bank-account";

export default connect(
  (state) => ({
    verified: !!getBankAccountLastDigits(state),
    bankAccountLastDigits: getBankAccountLastDigits(state),
    country: getBankAccountCountry(state),
    error: getBankAccountError(state),
    updating: getBankAccountUpdatingStatus(state),
  }),
  {
    setCountry: PayoutsActions.setCountry,
    setBankAccount: PayoutsActions.setPayoutsMethod,
  },
)(BankAccount);
