import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "RESET_SEARCHFILTERS",
  "RESET_SEARCHFILTERS_AVAILABILITY",
  "RESET_SEARCHFILTERS_SORT_BY",
  "RESET_SEARCHFILTERS_BUDGET",
  "RESET_SEARCHFILTERS_FLATMATES",
  "RESET_SEARCHFILTERS_FLAT",
  "RESET_SEARCHFILTERS_ROOM",
  "RESET_SEARCHFILTERS_MARKETPLACE_SEGMENTS",
  "SET_SEARCHFILTERS_AVAILABILITY",
  "SET_SEARCHFILTERS_SORT_BY",
  "SET_SEARCHFILTERS_BUDGET",
  "SET_SEARCHFILTERS_FLATMATES",
  "SET_SEARCHFILTERS_FLAT",
  "SET_SEARCHFILTERS_ROOM",
  "SET_SEARCHFILTERS_LOCATION",
  "SET_SEARCHFILTERS_PARAMS",
  "SET_SEARCHFILTERS_UPDATED_FILTERS",
  "SET_SEARCHFILTERS_PAGE",
  "SET_BOUNDS_RECALCULATION",
  "SET_SEARCHFILTERS_MARKETPLACE_SEGMENTS",
  "SET_SEARCHFILTERS_TOKEN",
]);
