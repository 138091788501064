import { connect } from "react-redux";

import { getUserId } from "datamodel/User/selectors";
import { getVisitRequest, saveVisitFeedback } from "datamodel/visits/api";
import { getToken } from "datamodel/Auth/selectors";
import VisitFeedback from "./visit-feedback";

const mapStateToProps = (state) => ({
  token: getToken(state),
  userId: getUserId(state),
});
const mergeProps = (state, _, ownProps) => ({
  ...state,
  getVisitRequest: (params) => getVisitRequest({ token: state.token }, params),
  onVisitFeedback: (params) =>
    saveVisitFeedback({ token: state.token }, params),
  ...ownProps,
});

export default connect(mapStateToProps, null, mergeProps)(VisitFeedback);
