import React from "react";
import { withRouter } from "react-router";
import { getAboutBadiPath } from "Sections/Home/routes";
import FindYourMatchMapping from "./find-your-match-mapping";

const FindYourMatchRoute = ({ router, ...props }) => {
  const redirectToAboutBadi = () => {
    router.push(getAboutBadiPath());
  };

  return <FindYourMatchMapping {...props} onLearnMore={redirectToAboutBadi} />;
};

export default withRouter(FindYourMatchRoute);
