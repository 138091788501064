// this is used as a seperation layer for the stepper layout, just for readability
const Step = ({ children }) => {
  return children;
};

Step.defaultProps = {
  children: null,
};

export default Step;
