import styled, { css } from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const Ul = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;
  width: 100%;
`;

export const Li = styled.li`
  cursor: pointer;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: calc(100% - 40px);
    height: 1px;
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
    top: 0;
    left: 20px;
  }
`;

export const Button = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  padding: ${unitizedPx(1)} ${unitizedPx(2)} 0;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: ${unitizedPx(2)};
    width: 3px;
    height: ${unitizedPx(3)};
    background-image: linear-gradient(to bottom, #08c781, #00bfb3);
    margin-right: 10px;
  }
`;

export const ActionsWrapper = styled.div`
  max-height: 0;
  transition: all 0.25s ease-out;
  overflow: hidden;

  ${({ selected }) =>
    selected &&
    css`
      max-height: 500px;
      transition: all 0.25s ease-in;
    `}
`;
