import { connect } from "react-redux";

import { getUserMode } from "datamodel/User/utils";

import {
  getFastTrackEnquiriesCount,
  getStandardEnquiriesCount,
} from "datamodel/Room/selectors";
import RoomActions from "datamodel/Room/actions";
import RequirementsActions from "datamodel/Requirements/actions";
import { getIsSwipingSeekerExperienceEnabledSelector } from "datamodel/Features/selectors";

import {
  getRoom,
  getRoomError,
  getSimilarRooms,
  isRoomInReviewSelector,
  getUserRole,
  isEntiredPropertySelector,
  getLabels,
} from "datamodel/Room/selectors";
import {
  getUserStatus,
  getIsUserWithFastTrack,
} from "datamodel/User/selectors";
import {
  isShowPriceDiscountsSelector,
  getMatchProfileSelector,
  isFavoriteTogglerEnabledSelector,
} from "datamodel/Features/selectors";
import { getMessageEnquiryFetched } from "datamodel/Conversation/selectors";
import { getToken, getUserId } from "datamodel/Auth/selectors";
import ConversationActions from "datamodel/Conversation/actions";

import RoomDetail from "./RoomDetail";

export default connect(
  (state) => ({
    room: getRoom(state),
    error: getRoomError(state),
    token: getToken(state),
    userId: getUserId(state),
    userMode: getUserMode(state.User.mode),
    isInReview: isRoomInReviewSelector(state),
    isEntireProperty: isEntiredPropertySelector(state),
    messageEnquiryFetched: getMessageEnquiryFetched(state),
    similarRooms: getSimilarRooms(state),
    fetched: state.Room.fetched,
    userStatus: getUserStatus(state),
    userRole: getUserRole(state),
    showPriceDiscount: isShowPriceDiscountsSelector(state),
    matchProfileFlag: getMatchProfileSelector(state),
    isFavoriteToggleButtonEnabled: isFavoriteTogglerEnabledSelector(state),
    labels: getLabels(state),
    isUserWithFastTrack: getIsUserWithFastTrack(state),
    standardEnquiriesCount: getStandardEnquiriesCount(state),
    fastTrackRoomConnectionsCount: getFastTrackEnquiriesCount(state),
    isSwipingSeekerExperienceEnabled:
      getIsSwipingSeekerExperienceEnabledSelector(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;

    return {
      ...stateProps,
      ...ownProps,
      ...dispatchProps,
      clearRoom: () => dispatch(RoomActions.clearRoom()),
      getOwner: (id) =>
        dispatch(RoomActions.getOwner({ id, token: stateProps.token })),
      getRoom: (props) =>
        dispatch(RoomActions.getRoom({ ...props, token: stateProps.token })),
      setRegister: () => dispatch(RequirementsActions.setRegister(true)),
      getSimilarRooms: ({ roomId, isLoggedUser }) =>
        dispatch(
          RoomActions.getSimilarRooms({
            roomId,
            token: stateProps.token,
            isLoggedUser,
          }),
        ),
      sendMessageEnquiry: ({ roomId, message, isFastTrack }) => {
        return dispatch(
          ConversationActions.sendMessageEnquiry({
            token: stateProps.token,
            roomId,
            message,
            isFastTrack,
          }),
        );
      },
    };
  },
)(RoomDetail);
