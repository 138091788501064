import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const FormWrapper = styled.div`
  max-width: auto;

  ${mediaquery.lg`
    max-width: ${unitizedPx(47)};
  `}
`;

export const PopoverWrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: block;
  max-width: ${unitizedPx(47)};
  margin-top: -14px;
`;
