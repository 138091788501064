import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import RoomModel from "./models/room-model";
import { parseSimilarRooms } from "./utils";

export const publishDraft = (token, id) => {
  const { BADI_DRAFT_PUBLISH_SERVICE } = envVars();
  const serverUrl = getServerUrl(
    BADI_DRAFT_PUBLISH_SERVICE.replace("{id}", id),
  );

  return fetch(serverUrl, {
    params: { id },
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const saveDraft = (token, room, id) => {
  const { BADI_DRAFT_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_DRAFT_SERVICE) + (id ? `/${id}` : "");

  return fetch(serverUrl, {
    params: room,
    requiredParams: true,
    method: id ? "PUT" : "POST",
    token,
  });
};

export const fetchDrafts = (token, additionalParams, placeType) => {
  const { BADI_ROOM_GET_DRAFTS } = envVars();
  const serverUrl = getServerUrl(BADI_ROOM_GET_DRAFTS);

  return fetch(serverUrl, {
    token,
    additionalParams,
    params: placeType && { place_type: placeType },
  }).then((data) => ({
    room: data.length ? data[0] : null,
  }));
};

export const fetchDraftById = (token, additionalParams, draftId) => {
  const { BADI_DRAFT_SERVICE } = envVars();
  const serverUrl = `${getServerUrl(BADI_DRAFT_SERVICE)}/${draftId}`;

  return fetch(serverUrl, {
    method: "GET",
    additionalParams,
    token,
  }).then((res) => ({ room: res }));
};

export const fetchSignature = (token, additionalParams) => {
  const { BADI_DOMAIN, BADI_GOOGLE_STATIC_MAPS_SIGNATURE_SERVICE } = envVars();
  const serverUrl = `${BADI_DOMAIN}${BADI_GOOGLE_STATIC_MAPS_SIGNATURE_SERVICE}`;

  return fetch(serverUrl, { token, additionalParams });
};

export const fetchRoom = (token, roomId, isLoggedUser, additionalParams) => {
  if (isNaN(roomId))
    return Promise.reject(new Error("Bad params for request!"));

  const { BADI_APP_ROOM_SERVICE, BADI_ROOM_SERVICE } = envVars();
  const endpointUrl = isLoggedUser ? BADI_ROOM_SERVICE : BADI_APP_ROOM_SERVICE;
  const serverUrl = getServerUrl(`${endpointUrl}/${roomId}`);

  return fetch(serverUrl, { token, additionalParams }).then((data) => ({
    room: data,
  }));
};

export const getRoom = ({
  token,
  roomId,
  isLoggedUser = false,
  additionalParams = null,
}) =>
  fetchRoom(token, roomId, isLoggedUser, additionalParams).then(({ room }) =>
    new RoomModel(room).toJSON(),
  );

export const fetchSimilarRooms = (token, roomId, isLoggedUser) => {
  const { BADI_ROOM_SIMILAR, BADI_APP_ROOM_SIMILAR } = envVars();
  const endpointUrl = isLoggedUser ? BADI_ROOM_SIMILAR : BADI_APP_ROOM_SIMILAR;
  const serverUrl = getServerUrl(endpointUrl.replace("{id}", roomId));

  return fetch(serverUrl, { token }).then((response) =>
    parseSimilarRooms(response.data.similar_rooms),
  );
};

export const deleteRoom = (id, token) => {
  const { BADI_ROOM_SERVICE } = envVars();
  const serverUrl = getServerUrl(`${BADI_ROOM_SERVICE}/${id}`);

  return fetch(serverUrl, { method: "DELETE", token });
};

export const updateRoom = (token, params, id) => {
  const { BADI_ROOM_SERVICE } = envVars();
  const serverUrl = getServerUrl(`${BADI_ROOM_SERVICE}/${params.id || id}`);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "PUT",
    token,
  });
};

export const sendRequest = (id, token) => {
  const { BADI_CREATE_REQUEST_SERVICE } = envVars();
  const serverUrl = getServerUrl(
    BADI_CREATE_REQUEST_SERVICE.replace("{id}", id),
  );

  return fetch(serverUrl, { method: "POST", token });
};

export const markRoomAsRented = updateRoom;

export const publishRoom = (token, { id }) => {
  const { BADI_PUBLISH_ROOM_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_PUBLISH_ROOM_SERVICE.replace("{id}", id));

  return fetch(serverUrl, {
    params: { id },
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const unPublishRoom = (token, { id }) => {
  const { BADI_UNPUBLISH_ROOM_SERVICE } = envVars();
  const serverUrl = getServerUrl(
    BADI_UNPUBLISH_ROOM_SERVICE.replace("{id}", id),
  );

  return fetch(serverUrl, {
    params: { id },
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const reportRoom = (token, { id, reason }) => {
  const { BADI_ROOM_REPORT_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_ROOM_REPORT_SERVICE.replace("{id}", id));

  return fetch(serverUrl, {
    params: { reason },
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const fetchDescriptionTranslation = (
  token,
  { roomId, targetLanguage },
) => {
  const { BADI_DESCRIPTION_TRANSLATIONS } = envVars();
  const serverUrl = getServerUrl(
    BADI_DESCRIPTION_TRANSLATIONS.replace("{id}", roomId),
  );

  return fetch(serverUrl, {
    params: { target_language: targetLanguage },
    requiredParams: true,
    method: "GET",
    token,
  });
};
