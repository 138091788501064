import React from "react";

const Hourglass = ({ className, color, height, width }) => (
  <svg
    className={className}
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <rect
        height="47"
        rx="4"
        stroke="#333"
        transform="translate(-13 -12)"
        width="369"
        x=".5"
        y=".5"
      />
      <path
        d="M16.313 3a2.877 2.877 0 012.87 2.706l.005.169v.969c0 1.4-.82 2.611-2.348 4.017l-.247.224c-.131.117-.25.22-.39.34l-.68.574.746.63c.155.132.286.247.43.378.18.16.348.317.506.472a1 1 0 11-1.397 1.431l-.142-.136-.304-.28c-.134-.12-.255-.228-.401-.353l-1.082-.915A1.629 1.629 0 0113.313 12c0-.471.21-.916.566-1.226l1.205-1.025.178-.157c1.204-1.073 1.847-1.933 1.919-2.621l.006-.127v-.969a.878.878 0 00-.765-.868L16.312 5H7.876a.878.878 0 00-.868.766L7 5.875v.969c0 .667.573 1.503 1.706 2.549l.398.356 1.205 1.025c.356.31.566.755.566 1.226s-.21.916-.566 1.226l-1.205 1.025-.179.157c-1.203 1.073-1.847 1.933-1.918 2.621L7 17.156v.969c0 .445.335.814.766.868l.109.007h8.438a.878.878 0 00.868-.766l.006-.109a1 1 0 012 0 2.877 2.877 0 01-2.706 2.87l-.169.005H7.876a2.877 2.877 0 01-2.87-2.706L5 18.125v-.969c0-1.4.82-2.611 2.348-4.017l.247-.224c.164-.146.307-.27.5-.434L8.664 12l-.781-.662a25.36 25.36 0 01-.288-.253c-1.602-1.428-2.502-2.64-2.588-4.022L5 6.843v-.968a2.877 2.877 0 012.706-2.87L7.875 3h8.438z"
        fill={color}
      />
    </g>
  </svg>
);

Hourglass.defaultProps = {
  className: "",
  color: "#7D7D7D",
  height: 24,
  width: 24,
};

export default Hourglass;
