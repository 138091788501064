export const CATEGORIES = (translate) => [
  {
    value: "BADI",
    title: translate("sitemap.badi.aboutUs").toUpperCase(),
    subCategories: [
      {
        title: "Badi Plus",
        url: translate("sitemap.badi.badi-plus-url"),
        target: "_blank",
      },
      {
        title: translate("sitemap.jobs"),
        url: "https://www.linkedin.com/company/badi/",
        target: "_blank",
      },
      ,
      { title: "Blog", url: translate("general.url.blog"), target: "_blank" },
    ],
  },
  {
    value: "CONTACT",
    title: translate("sitemap.need-help.title").toUpperCase(),
    subCategories: [
      {
        title: translate("sitemap.faqs"),
        url: translate("sitemap.faqs.new.link"),
        target: "_blank",
      },
      { title: translate("sitemap.customer"), url: "mailto:info@badi.com" },
      {
        title: translate("sitemap.safety"),
        url: translate("sitemap.safety.url"),
        target: "_blank",
      },
    ],
  },
  {
    value: "LEGAL",
    title: "LEGAL",
    subCategories: [
      {
        title: translate("sitemap.tc"),
        url: translate("sitemap.tc.link"),
        target: "_blank",
      },
      {
        title: translate("sitemap.privacy"),
        url: translate("sitemap.privacy.link"),
        target: "_blank",
      },
    ],
  },
];
