import React from "react";

import RecoverBooking from "./recover-booking";

const RecoverBookingRoute = ({ authtoken, location, params, router }) => {
  const { from_connection: fromConnection = 0 } = location.query;
  const { requestId } = params;

  return (
    <RecoverBooking
      fromConnection={Number(fromConnection)}
      requestId={requestId}
      router={router}
      token={authtoken}
    />
  );
};

export default RecoverBookingRoute;
