import { ROOM_STATUS } from "datamodel/Room/constants";
import { ROOM_ACTIONS } from "./constants";

const getOwnedRoomActions = (status) => {
  switch (status) {
    case ROOM_STATUS.BOOKED:
      return [ROOM_ACTIONS.BOOKED];
    case ROOM_STATUS.PENDING:
      return [ROOM_ACTIONS.PENDING, ROOM_ACTIONS.SETTINGS];
    default:
      return [ROOM_ACTIONS.SETTINGS];
  }
};

export const switchDeprecatedActions = ({
  status,
  inboxConnectionId,
  allowToChat,
  messageEnquiryFetched,
  allowToContact,
  allowToVisit,
}) => {
  switch (status) {
    case ROOM_STATUS.PUBLISHED:
      if (inboxConnectionId) {
        return [
          ...(allowToChat ? [ROOM_ACTIONS.CHAT] : [ROOM_ACTIONS.INBOX]),
          ROOM_ACTIONS.BOOK,
        ];
      }
      if (messageEnquiryFetched) {
        return [ROOM_ACTIONS.REQUESTED, ROOM_ACTIONS.BOOK];
      }
      if (allowToVisit) {
        return [ROOM_ACTIONS.VISIT, ROOM_ACTIONS.BOOK];
      }
      if (allowToContact) {
        return [ROOM_ACTIONS.CONTACT, ROOM_ACTIONS.BOOK];
      }

      return [ROOM_ACTIONS.BOOK];
    case ROOM_STATUS.RENTED:
    case ROOM_STATUS.BOOKED:
      return [ROOM_ACTIONS.RENTED];
    default:
      return [ROOM_ACTIONS.NOT_AVAILABLE];
  }
};

export const switchActions = ({
  status,
  inboxConnectionId,
  allowToContact,
  messageEnquiryFetched,
}) => {
  switch (status) {
    case ROOM_STATUS.PUBLISHED:
      if (allowToContact && inboxConnectionId) {
        return [ROOM_ACTIONS.CHAT];
      }
      if (messageEnquiryFetched) {
        return [ROOM_ACTIONS.REQUESTED];
      }
      if (allowToContact) {
        return [ROOM_ACTIONS.CONTACT];
      }

      return [ROOM_ACTIONS.CONTACT];
    case ROOM_STATUS.RENTED:
    case ROOM_STATUS.BOOKED:
      return [ROOM_ACTIONS.RENTED];
    default:
      return [ROOM_ACTIONS.NOT_AVAILABLE];
  }
};

export const getActions = ({
  allowToContact,
  room = {},
  messageEnquiryFetched,
} = {}) => {
  const { inboxConnectionId, owned, status } = room;

  if (owned) {
    return getOwnedRoomActions(status);
  }

  return switchActions({
    status,
    inboxConnectionId,
    allowToContact,
    messageEnquiryFetched,
  });
};
