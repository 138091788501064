import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { fetchUser } from "datamodel/User/api";
import RoomInvite from "./room-invite-mapping";

export const closeRoomInviteModal = () => {
  modal.remove(MODAL_ID.ROOM_INVITE);
};

const MODAL_OPTIONS = {
  id: MODAL_ID.ROOM_INVITE,
  type: MODAL_TYPE.LATERAL,
  onClose: closeRoomInviteModal,
};

export const openRoomInviteModal = ({
  userId,
  room,
  token,
  onInviteSent,
  trackingParams,
  isRecommendation,
}) => {
  fetchUser(userId, token).then((user) => {
    modal.add(
      <RoomInvite
        isRecommendation={isRecommendation}
        onInviteSent={onInviteSent}
        room={room}
        trackingParams={trackingParams}
        user={user}
      />,
      MODAL_OPTIONS,
    );
  });
};
