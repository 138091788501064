import React from "react";
import classNames from "classnames";
import { debounce } from "radash";
import { translate } from "base/shared/Localization";

import "./TextAreaExt.css";

class TextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value || "" };

    this.debouncedChange = debounce({ delay: 300 }, () => this.setValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value || "" });
  }

  onChange = (e) => {
    this.setState({ value: e.currentTarget.value });
    this.debouncedChange();
  };

  setValue = () => this.props.onChange(this.state.value);

  render() {
    const {
      className,
      maxLength,
      minLength,
      name,
      placeholder,
      setReference,
      rows,
    } = this.props;
    const { value } = this.state;
    const { length } = value;
    let counterText = null;

    if (length < minLength) {
      counterText = translate("chars_min", { smart_count: minLength });
    } else if (maxLength) {
      counterText = translate("general.form.textarea.counter", {
        smart_count: maxLength - value.length,
      });
    }

    return (
      <div className={classNames("Form__TextArea--wrapper", className)}>
        <textarea
          className={classNames({ "Form__TextArea--textarea-active": !!value })}
          maxLength={maxLength || undefined}
          minLength={minLength || undefined}
          name={name}
          onChange={this.onChange}
          placeholder={placeholder}
          ref={setReference}
          rows={rows}
          value={value}
        />
        {counterText && (
          <div className="Form__TextArea--counter">{counterText}</div>
        )}
      </div>
    );
  }
}

TextArea.defaultProps = {
  className: "",
  maxLength: 0,
  minLength: 0,
  onChange: () => {},
  placeholder: "",
  rows: 4,
  setReference: () => {},
  value: "",
};

export default TextArea;
