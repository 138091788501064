import React from "react";
import { Heading, Spacer, Flex, Card, colors } from "@badi/badi-components";
import { Container, Col, Row } from "reactstrap";

import { translate } from "base/shared/Localization";
import { getMultiShareText } from "Sections/Room/Detail/utils";
import MultiShare from "components/multi-share";
import { ShareRoomWrapper } from "./styled-components";

const RoomDetailShare = ({ city, owned = false, url }) => (
  <Container>
    <Row>
      <Col xs={12}>
        <Card backgroundColor={colors.white}>
          <ShareRoomWrapper
            alignItems="center"
            as={Flex}
            justify="spaceBetween"
          >
            <Flex alignItems="center">
              <Heading level={4}>{translate("Share this room")}</Heading>
            </Flex>
            <Spacer sm={{ top: 0 }} xs={{ top: 2 }}>
              <MultiShare
                alignItems="center"
                as={Flex}
                target={url}
                title={translate(getMultiShareText(owned), { name: city })}
              />
            </Spacer>
          </ShareRoomWrapper>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default RoomDetailShare;
