import React, { useContext } from "react";
import {
  Flex,
  Heading,
  Text,
  colors,
  Spacer,
  Tooltip,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import ListerScoreBadge from "components/lister-score-badge";
import PropertyTypeLabel from "Sections/Room/Detail/components/property-type-label";
import MatchProfile from "./components/match-profile-badge";

import ExtraBenefitBadge from "./components/extra-benefit";
import { boundariesGenerator } from "./utils";
import { Title } from "./styled-components";

const Details = ({
  lister,
  price,
  previousPrice,
  title,
  billsIncluded,
  matchProfile,
  placeType,
  flatSize,
  maleTenants,
  femaleTenants,
  sizeUnit,
  type,
  contractOffered,
  singleBedrooms,
  doubleBedrooms,
}) => {
  const boundariesGen = () => {
    return boundariesGenerator(type)();
  };

  const tooltipBoundaries = boundariesGen(type);
  const isTypeSearch = type === "search";

  return (
    <Flex direction="column" fullHeight={true} gutter={1}>
      <Flex alignItems="center" justify="spaceBetween">
        <Flex alignItems="center" gutter={1}>
          <Text body={5} data-qa="room-card-details-owner" inline={true}>
            {lister.firstName}, {lister.age}
          </Text>
          {lister.score && (
            <ListerScoreBadge
              data-qa="room-card-details-score"
              score={lister.score}
              small={true}
            />
          )}
        </Flex>
        {matchProfile && (
          <Tooltip
            boundaries={tooltipBoundaries}
            dataQa="tooltip-room-result-card"
            text={translate("trust_items.match_profile_text")}
            zIndex={5}
          >
            <MatchProfile />
          </Tooltip>
        )}
      </Flex>
      {isTypeSearch && (
        <PropertyTypeLabel
          room={{
            placeType,
            flatSize,
            maleTenants,
            femaleTenants,
            sizeUnit,
            singleBedrooms,
            doubleBedrooms,
          }}
        />
      )}
      <Title as={Heading} data-qa="room-card-details-title" grow={1} level={4}>
        {title}
      </Title>
      <Flex alignItems="center" gutter={0.5} md={{ gutter: 1 }}>
        {previousPrice && (
          <Text
            body={3}
            color={colors.midGrey}
            data-qa="room-card-details-previous-price"
          >
            <strike>{previousPrice}</strike>
          </Text>
        )}
        <Text body={3} data-qa="room-card-details-price">
          {price}/{translate("month")}
        </Text>
        {billsIncluded && (
          <Spacer left={1}>
            <ExtraBenefitBadge text={translate("bills.included")} />
          </Spacer>
        )}
        {contractOffered && (
          <Spacer left={1}>
            <ExtraBenefitBadge
              text={translate("card.detail.contract.provided")}
            />
          </Spacer>
        )}
      </Flex>
    </Flex>
  );
};

export default Details;
