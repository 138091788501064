import React from "react";
import { Spacer, Text, Flex } from "@badi/badi-components";
import Image from "components/Image";

import { translate } from "base/shared/Localization";
import { getColor } from "base/shared/get-color";

import { IconWrap } from "../styled-components";

const BulletPoint = ({ content, color, icon }) => {
  return (
    <Spacer top={2}>
      <Flex alignItems="center">
        <IconWrap>
          <Image
            data-qa="info-modal-bullet-icon"
            src={`/assets/images/dynamic-modal/${icon}.svg`}
          />
        </IconWrap>
        <Text color={getColor(color)}>{translate(content)}</Text>
      </Flex>
    </Spacer>
  );
};

export default BulletPoint;
