import React, { useMemo } from "react";
import { Container } from "reactstrap";
import { Flex, Heading, Spacer } from "@badi/badi-components";
import Actions from "../actions";
import { getActions } from "./utils";
import { HeaderRoot } from "./styled-components";

const ChatHeader = ({
  connection,
  detailsButtonVisible = false,
  onRedirect,
}) => {
  const { status: { statusDetail } = {}, actions } = connection;

  const { primary, secondary } = useMemo(
    () =>
      getActions({
        actions,
      }),
    [actions],
  );

  return (
    <HeaderRoot>
      <Container>
        <Spacer bottom={1} lg={{ top: 0, bottom: 0 }} top={1}>
          <Flex
            alignItems="flexStart"
            direction="column"
            gutter={1}
            justify="flexStart"
            lg={{
              alignItems: "center",
              direction: "row",
              justify: "spaceBetween",
              gutter: 0,
            }}
          >
            <Flex
              alignItems="center"
              justify="spaceBetween"
              wide={detailsButtonVisible}
            >
              <Heading data-qa="conversation-header-title" level="16-Bold">
                {statusDetail}
              </Heading>
            </Flex>
            <Actions
              connection={connection}
              onRedirect={onRedirect}
              primary={primary}
              reduced={detailsButtonVisible}
              secondary={secondary}
              wide={true}
            />
          </Flex>
        </Spacer>
      </Container>
    </HeaderRoot>
  );
};

export default ChatHeader;
