import styled, { css } from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

const errorStyles = css`
  content: attr(data-error);
  background-image: url("${getCDNUrl()}/assets/icons/icon-error-text.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: right;
`;

export const Root = styled.section`
  position: relative;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
      `}

  &:before {
    position: absolute;
    right: 0;
    top: 4px;
    height: 30px;
    width: 100px;

    font-size: 0.93rem;
    font-weight: 500;
    line-height: 1.14;
    color: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
    text-align: right;
    content: attr(data-success);

    opacity: ${({ saved }) => (saved ? 1 : 0)};
    transition: opacity 0.4s;
  }

  &:after {
    position: absolute;
    right: 0;
    top: 4px;
    line-height: 15px;
    padding-right: 20px;
    color: ${BADI_COLORS.SEMANTIC.DANGER.DEFAULT};

    height: 15px;

    ${({ error }) => error && errorStyles}
  }
`;
