import React from "react";
import { Text, Heading, Flex, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getColor } from "base/shared/get-color";
import { PriceLabel, PriceBoxRoot } from "../styled-components";

const PriceBox = ({ title, subtitle, price }) => {
  return (
    <Spacer top={3}>
      <Flex justify="center">
        <PriceBoxRoot grow={1}>
          <PriceLabel
            backgroundColor={getColor(title.background_color)}
            body="12-Medium"
            color={getColor(title.color)}
            dataQa="info-modal-price-title"
          >
            {translate(title.content)}
          </PriceLabel>
          <Text
            align="center"
            body="16-Regular"
            color={getColor(subtitle.color)}
          >
            {translate(subtitle.content)}
          </Text>
          <Heading
            align="center"
            color={getColor(price.color)}
            data-qa="info-modal-price-label"
            level="20-Bold"
          >
            {translate(price.content)}
          </Heading>
        </PriceBoxRoot>
      </Flex>
    </Spacer>
  );
};

export default PriceBox;
