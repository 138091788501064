import React, { useEffect } from "react";

import { translate } from "base/shared/Localization";

import {
  Button,
  BUTTON_TYPES,
  Text,
  Heading,
  Flex,
} from "@badi/badi-components";

const CancelConfirmation = ({ id, onLeaveClick, onKeepEditingClick }) => {
  const handleLeave = () => {
    onLeaveClick();
  };

  const handleCancel = () => {
    onKeepEditingClick();
  };

  return (
    <Flex direction="column" gutter={2}>
      <Heading body="16-Bold">
        {translate("publish.warning_leave_title")}
      </Heading>
      <Text body="14-Regular">
        {translate("publish.warning_leave_subtitle_desktop")}
      </Text>
      <Flex gutter={2}>
        <Button
          data-qa="listing-flow-cancel-modal-button"
          fullWidth={true}
          grow={1}
          onClick={handleCancel}
          variant={BUTTON_TYPES.SECONDARY}
        >
          {translate("publish.warning_leave_cta_cancel")}
        </Button>
        <Button
          data-qa="listing-flow-close-modal-button"
          fullWidth={true}
          grow={1}
          onClick={handleLeave}
          variant={BUTTON_TYPES.PRIMARY}
        >
          {translate("publish.warning_leave_cta_leave")}
        </Button>
      </Flex>
    </Flex>
  );
};

CancelConfirmation.defaultProps = {
  id: null,
};

export default CancelConfirmation;
