import React from "react";
import { withRouter } from "react-router";

import { getNewBookingPath } from "Sections/booking/routes";
import VisitFeedback from "./visit-feedback-mapping";

const VisitFeedbackRoute = ({ router, ...props }) => {
  const handleBookingRequest = (connectionId) => {
    router.push(getNewBookingPath({ connectionId }));
  };

  return <VisitFeedback {...props} onBookingRequest={handleBookingRequest} />;
};

export default withRouter(VisitFeedbackRoute);
