export const parseReviewStarsFromApi = (stars) => {
  if (!stars) return {};

  return Object.keys(stars).reduce((res, key) => {
    const star = stars[key];

    if (!star) return res;

    res[key] = {
      value: star.value,
      title: star.title,
    };
    return res;
  }, {});
};

export const parseReviewStarsToApi = (stars) => {
  if (!stars) return {};

  return Object.keys(stars).reduce((res, key) => {
    res[key] = {
      value: stars[key].value,
    };
    return res;
  }, {});
};

export const parseReviewToApi = ({ text, stars }) => ({
  text,
  stars: parseReviewStarsToApi(stars),
});
