const PaymentModel = {
  completed: false,
  email: null,
  error: null,
  fetching: false,
  payment: null,
  phone: null,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Payment) {
    return { ...PaymentModel, error: "There was an error in PaymentModel." };
  }
  return state;
}

export { PaymentModel, setInitialState };
