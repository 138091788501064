import { connect } from "react-redux";

import { getToken, getUserId } from "datamodel/Auth/selectors";
import ConversationActions from "datamodel/Conversation/actions";
import RoomActions from "datamodel/Room/actions";
import {
  getConnectionRoomDetails,
  getConnectionUser,
} from "datamodel/Inbox/selectors";
import { getUserRole as getUserRoleFromConversation } from "datamodel/Conversation/selectors";
import {
  getUserRole as getUserRoleFromRoom,
  getRoomSummary,
  getRoomUser,
  getRoom,
} from "datamodel/Room/selectors";
import { getSkipMessageStepSelector } from "datamodel/Features/selectors";

import NewBooking from "./new-booking";

const mapStateToProps = (state) => ({
  token: getToken(state),
  currentUserId: getUserId(state),
  connectionRoom: getConnectionRoomDetails(state),
  connectionUser: getConnectionUser(state),
  roomUser: getRoomUser(state),
  roomDetail: getRoom(state),
  roomSummary: getRoomSummary(state),
  skipMessageStep: getSkipMessageStepSelector(state),
  userRoleFromRoom: getUserRoleFromRoom(state),
  userRoleFromConversation: getUserRoleFromConversation(state),
});

const mapDispatchToProps = {
  getConnectionDetails: ConversationActions.getConnectionDetails,
  getRoomDetails: RoomActions.getRoom,
};

const mergeProps = (state, dispatch, ownProps) => ({
  ...state,
  ...dispatch,
  ...ownProps,
  user: ownProps.isInstantBooking ? state.roomUser : state.connectionUser,
  room: ownProps.isInstantBooking ? state.roomSummary : state.connectionRoom,
  userRole: ownProps.isInstantBooking
    ? state.userRoleFromRoom
    : state.userRoleFromConversation,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(NewBooking);
