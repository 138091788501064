import { translate } from "base/shared/Localization";
import {
  MOVE_ALONE_ID,
  MOVE_WITH_SOMEONE_ID,
  MOVE_IN_GROUP_OPTIONS,
} from "./constants";

export const getMoveInGroupOptions = () => [
  {
    value: MOVE_ALONE_ID,
    title: translate(
      MOVE_IN_GROUP_OPTIONS.find(({ value }) => value === MOVE_ALONE_ID).label,
    ),
  },
  {
    value: MOVE_WITH_SOMEONE_ID,
    title: translate("onboarding.preferences.seeker_who_with_someone"),
  },
];
export const getPartnerOptions = () =>
  MOVE_IN_GROUP_OPTIONS.filter((option) => option.value !== MOVE_ALONE_ID).map(
    (option) => ({ ...option, label: translate(option.label) }),
  );

export const isNotAlone = (value) => !!value && value !== MOVE_ALONE_ID;
