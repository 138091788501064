import { connect } from "react-redux";

import { getUserConfirmation, getGhost } from "datamodel/User/selectors";
import {
  getRoomInitialStepSelector,
  getRoomIdSelector,
  getRoomStatusSelector,
  getRoomFieldsSelector,
  getRoomPropertySelector,
} from "datamodel/RoomList/selectors";
import { getToken } from "datamodel/Auth/selectors";

import RoomListActions from "datamodel/RoomList/actions";
import UserActions from "app/datamodel/User/actions";
import Actions from "datamodel/Requirements/actions";

import NewRoom from "./new-room";

const mapStateToProps = (state) => ({
  initialStep: getRoomInitialStepSelector(state),
  confirmedPhone: getUserConfirmation(state, "phone"),
  roomId: getRoomIdSelector(state),
  isUserGhost: getGhost(state),
  fields: getRoomFieldsSelector(state),
  roomStatus: getRoomStatusSelector(state),
  publish: state.RoomList.publish,
  token: getToken(state),
  placeType: getRoomPropertySelector(state).placeType,
});

const mapDispatchToProps = {
  setLister: UserActions.setLister,
  getDrafts: RoomListActions.getDrafts,
  getDraftById: RoomListActions.getDraftById,
  setPhone: Actions.setPhone,
  setId: RoomListActions.setRoomId,
};

const mergeProps = (state, dispatch, ownProps) => {
  return {
    ...state,
    ...dispatch,
    ...ownProps,
    getPendingDraft: () =>
      dispatch.getDrafts({ token: state.token }, state.placeType),
    getPendingDraftById: (draftId) =>
      dispatch.getDraftById({ token: state.token }, draftId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(NewRoom);
