import { connect } from "react-redux";
import RequirementsActions from "datamodel/Requirements/actions";
import { getUserId } from "datamodel/User/selectors";
import ResponsiveBar from "./responsive-bar";

const mapStateToProps = (state) => ({
  isLoggedIn: !!getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  openLogin: () => dispatch(RequirementsActions.setLogin(true)),
  registerHandler: () => dispatch(RequirementsActions.setRegister(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveBar);
