import React from "react";
import { translate } from "base/shared/Localization";
import styled from "styled-components";

import { Text } from "@badi/badi-components";
import { BUTTON_TYPES, Button, BADI_COLORS } from "app/badi-components";
import { BoltIcon } from "../fast-track/icons";

const FindOutMoreAboutFastTrackWrapper = styled.div`
  background: ${({ inverted }) =>
    inverted
      ? BADI_COLORS.NEUTRAL.NEUTRAL_05
      : BADI_COLORS.GRADIENT.GRADIENT_02};
  border-radius: 25px 25px 0 0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ inverted }) => (inverted ? "0.5px solid black" : "none")}';
`;

const FindOutMoreAboutFastTrack = ({ onClick, inverted = false }) => (
  <FindOutMoreAboutFastTrackWrapper inverted={inverted}>
    <Text align="center" body="14-Regular" style={{ padding: "10px 45px" }}>
      {translate("enquiry.fast-track.benefits-summary")}
    </Text>
    <Button
      fullWidth
      variant={
        inverted ? BUTTON_TYPES.GOLD_GRADIENT : BUTTON_TYPES.PRIMARY_INVERTED
      }
      onClick={onClick}
      icon={<BoltIcon />}
      style={{ color: "black" }}
    >
      {translate("enquiry.fast-track.find-out-more-cta")}
    </Button>
  </FindOutMoreAboutFastTrackWrapper>
);

export default FindOutMoreAboutFastTrack;
