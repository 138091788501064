import React from "react";

const Report = ({ color, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill={color}
      stroke={color}
      strokeWidth="0.5"
      d="M27 7.131c0-.874-.703-1.585-1.567-1.585h-3.757c-.85 0-1.542.805-1.565 1.61h-1.296c-.43 0-.842-.218-1.132-.539l-.66-.746a2.618 2.618 0 0 0-1.932-.869L9.773 5a2.612 2.612 0 0 0-2.508 1.935l-2.17 7.997a2.632 2.632 0 0 0 1.761 3.227l4.433 1.36-.655 3.43a4.262 4.262 0 0 0-.066.815v.62c0 1.45 1.142 2.616 2.575 2.616h.509c.698 0 1.265-.547 1.29-1.253l.047-1.273v-.03c-.019-.85.128-1.305.389-1.807a.474.474 0 0 0 .02-.042l1.323-3.2a4.304 4.304 0 0 1 3.487-2.013 1.57 1.57 0 0 0 1.468 1.037h3.758c.863 0 1.566-.711 1.566-1.586V7.131zM15.817 18.834l-.015.024a.543.543 0 0 0-.042.08l-1.331 3.223c-.316.613-.521 1.242-.5 2.293l-.045 1.254a.227.227 0 0 1-.232.22h-.51c-.848 0-1.514-.686-1.514-1.545v-.619c0-.204.008-.41.046-.609l.754-3.906a.539.539 0 0 0-.37-.617l-4.893-1.5a1.564 1.564 0 0 1-1.046-1.917l2.169-7.999A1.549 1.549 0 0 1 9.774 6.07l5.318-.002c.435 0 .854.186 1.148.511l.66.764c.491.542 1.189.886 1.915.886h1.294v8.083a5.358 5.358 0 0 0-4.292 2.523zm10.123-2a.51.51 0 0 1-.507.512h-3.757a.51.51 0 0 1-.507-.513V7.132a.51.51 0 0 1 .507-.513h3.758c.279 0 .506.23.506.512v9.702z"
    />
  </svg>
);

Report.defaultProps = {
  color: "#333",
  className: "",
};

export default Report;
