import styled from "styled-components";
import { colors } from "@badi/badi-components";

export const MarkerButton = styled.button`
  font-family: modelica;
  font-size: 16px;
  position: relative;
  display: inline-block;
  transform: translate(-50%, -100%);
  border: none;
  margin: 0;
  padding: 4px 12px;

  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? colors.darkGrey : colors.white};
  z-index: ${({ selected }) => (selected ? "1" : "unset")};

  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  &:after {
    position: absolute;
    bottom: -3px;
    left: calc(50% - 4px);
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: ${({ selected }) =>
      selected ? colors.darkGrey : colors.white};
    transform: rotate(45deg);
  }
`;

export const ReducedMarker = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${colors.white};
  border: ${colors.darkGrey} solid 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
`;
