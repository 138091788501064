import React from "react";
import { Flex, Spacer } from "@badi/badi-components";

import CheckoutHeader, { VARIANT } from "./components/header";
import CheckoutContent from "./components/content";

const CheckoutLayout = ({
  children = null,
  headerContent = null,
  title,
  description,
  variant = VARIANT.TERTIARY,
  image = null,
}) => {
  return (
    <Flex direction="column">
      <CheckoutHeader
        description={description}
        image={image}
        title={title}
        variant={variant}
      >
        {headerContent}
      </CheckoutHeader>
      <Spacer bottom={10}>
        <CheckoutContent>{children}</CheckoutContent>
      </Spacer>
    </Flex>
  );
};

export default CheckoutLayout;
