import React from "react";

import { SEARCH_VIEW } from "datamodel/SearchFilters/static";

import Rooms from "../components/rooms";
import Map from "../components/map";
import {
  MapWrapper,
  SearchRoomListWrapperMain,
  SearchRoomListWrapperContainer,
} from "../styled-components";

const FavoritesView = ({
  favorites,
  pagination,
  view,
  getFavoritesPerPage,
}) => (
  <>
    <SearchRoomListWrapperMain>
      <SearchRoomListWrapperContainer id="search-rooms-list">
        <Rooms
          cardType={"favorite"}
          getFavoritesPerPage={getFavoritesPerPage}
          pagination={pagination}
          rooms={favorites}
          showArrows={view === SEARCH_VIEW.list}
        />
      </SearchRoomListWrapperContainer>
    </SearchRoomListWrapperMain>
    {view === SEARCH_VIEW.both && (
      <MapWrapper className="SearchMapWrapper" data-qa="favorites-map-wrapper">
        <Map rooms={favorites} />
      </MapWrapper>
    )}
  </>
);

export default FavoritesView;
