import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { PlansInfo, StatusCounter } from "./models";

const fetchMyListingsSummary = (token, params) => {
  const { BADI_MY_LISTINGS_SUMMARY_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_MY_LISTINGS_SUMMARY_SERVICE);

  return fetch(serverUrl, {
    params,
    token,
  }).then(({ data }) => ({
    statusesCounters: data.statuses_counters.map((statusCounter) =>
      new StatusCounter(statusCounter).toJSON(),
    ),
    plansInfo: new PlansInfo(data.plans_info).toJSON(),
  }));
};

const fetchMyListingsByStatus = (token, params) => {
  const { BADI_MY_LISTINGS_BY_STATUS_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_MY_LISTINGS_BY_STATUS_SERVICE);

  const { externalAgencyId, ...otherParams } = params;
  const parsedParams = otherParams;
  if (externalAgencyId) parsedParams.agency_external_id = externalAgencyId;

  return fetch(serverUrl, {
    params: parsedParams,
    token,
  }).then((res) => res);
};

export { fetchMyListingsSummary, fetchMyListingsByStatus };
