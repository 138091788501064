import { connect } from "react-redux";
import InboxActions from "datamodel/Inbox/actions";
import {
  getRoomsSummary,
  getInboxCategory,
  getInboxRoomId,
  getRoomsSummaryCurrentPage,
  getRoomsSummaryTotalPages,
} from "datamodel/Inbox/selectors";
import { getToken, getUserId } from "datamodel/Auth/selectors";
import RoomSelectorPage from "./room-selector-page";
import { isSwipingInboxExperienceSelector } from "../inbox-lister/selectors";

const mapStateToProps = (state) => ({
  fetched: state.Inbox.fetched,
  fetching: state.Inbox.fetching,
  token: getToken(state),
  rooms: getRoomsSummary(state),
  userId: getUserId(state),
  roomId: getInboxRoomId(state),
  category: getInboxCategory(state),
  currentPage: getRoomsSummaryCurrentPage(state),
  totalPages: getRoomsSummaryTotalPages(state),
  isSwipingToggleActive: isSwipingInboxExperienceSelector(state),
});

const mapDispatchToProps = {
  getRooms: InboxActions.getInboxRoomsSummary,
  setInboxRoom: InboxActions.setInboxRoom,
};

const mergeProps = (state, dispatchProps, ownProps) => ({
  ...state,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(RoomSelectorPage);
