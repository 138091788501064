import moment from "moment";

import * as CookiesHandler from "base/shared/CookiesHandler";

export const setRequestVerificationCookie = (name) => {
  const blockedUntil = moment().add(6, "minutes").format("X");

  CookiesHandler.setCookie(name, blockedUntil);
};

export const checkRequestVerificationCookie = (name) => {
  if (CookiesHandler.existsCookie(name)) {
    const cookie = CookiesHandler.getCookie(name);

    if (Number(cookie) <= Number(moment().format("X"))) {
      CookiesHandler.removeCookie(name);
      return 0;
    }

    return Number(cookie);
  }
  return 0;
};

const formatCountryToOption = (country) => ({
  label: country.country,
  value: country.country_code,
});

export const countryCodesOptions = ({
  non_featured: nonFeatured = [],
  featured = [],
}) => {
  const notFeaturedCountries = nonFeatured.map(formatCountryToOption);
  const featuredCountries = featured.map(formatCountryToOption);

  return [
    ...featuredCountries,
    // this is the separator
    { value: "non-selectable", label: "──────────", disabled: true },
    ...notFeaturedCountries,
  ];
};
