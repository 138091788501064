import React from "react";
import { Flex, Heading, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";

const IMAGE_SIZE = { height: "30px" };

const StepFlow = ({ icon, title, description }) => {
  return (
    <Flex direction="row" gutter={3}>
      <Image src={icon} styles={IMAGE_SIZE} />
      <Flex direction="column" gutter={1}>
        <Heading data-qa="about-badi-flow-step-title" level="16-Bold">
          {translate(title)}
        </Heading>
        <Text body="14-Regular" dataQa="about-badi-flow-step-desc">
          {translate(description)}
        </Text>
      </Flex>
    </Flex>
  );
};

export default StepFlow;
