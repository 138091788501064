import React from "react";
import styled from "styled-components";

import { Badge, colors } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

const Root = styled.div`
  text-transform: uppercase;
`;

const OwnedBadge = () => {
  return (
    <Root>
      <Badge backgroundColor={colors.white} color={colors.darkGrey}>
        {translate("search.rooms.room.owned")}
      </Badge>
    </Root>
  );
};

export default OwnedBadge;
