import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import Image from "components/Image/Image";
import {
  TopBarMenuElement,
  MenuTitle,
  ContentWrapper,
  MenuDescription,
} from "./styled-components";

const MenuElement = ({
  callback,
  dataQa = "menu-item",
  description = "",
  notifications = false,
  onClick = null,
  title,
  to = "",
}) => {
  const [href, setHref] = useState(null);

  const handleClick = () => {
    callback();
    if (onClick) onClick();
  };

  useEffect(() => {
    if (to && !to.startsWith("/")) {
      setHref(to);
    }
  }, [to]);

  const getElement = () => {
    if (href) return "a";
    if (to) return Link;
    if (onClick) return "button";
    return "div";
  };

  const DynamicElement = getElement();

  return (
    <TopBarMenuElement complex={!!description}>
      <ContentWrapper
        as={DynamicElement}
        data-qa={dataQa}
        href={href}
        onClick={handleClick}
        to={href ? null : to}
      >
        <MenuTitle highlighted={!!description} notifications={notifications}>
          {title}
        </MenuTitle>
        {!!description && <MenuDescription>{description}</MenuDescription>}
      </ContentWrapper>
    </TopBarMenuElement>
  );
};

export default MenuElement;
