import React, { Component } from "react";
import { Flex, Spacer, Heading, Text, Button } from "@badi/badi-components";
import { Container, Col, Row } from "reactstrap";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import PhoneVerificationForm from "components/Verification/Phone/phone-verification-mapping";
import EmailVerificationForm from "components/Verification/Email/EmailVerificationMapping";
import BankAccount from "components/bank-account";
import ContactInformation from "components/contact-information";
import PayThroughBadiInfo from "components/pay-through-badi-info";
import PaymentProvider from "components/PaymentProvider";
import { ProfileFooterRoot } from "./styled-components";
import { DATA_CONTEXT } from "./constants";

class PaymentSetupProfile extends Component {
  componentDidMount() {
    const { getAccount, loaded } = this.props;
    if (!loaded) getAccount();
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error) {
      UserFeedback.exception("Something went wrong", error.message, error);
    }
  }

  handleSkip = () => {
    this.props.onSkip();
  };

  render() {
    const {
      email,
      loaded,
      verified,
      error,
      onComplete,
      previousStep,
      isLister,
    } = this.props;

    return (
      <Spacer bottom={12} top={4}>
        <Container>
          <Row>
            <Col>
              <Spacer bottom={1}>
                <Heading>{translate("payout.setup.step.title")}</Heading>
              </Spacer>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <Spacer bottom={4}>
                <Text body={1}>{translate("payout.setup.step.subtitle")}</Text>
              </Spacer>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 6, order: 1 }}
              md={{ size: 12, order: 2 }}
              xs={{ order: 2 }}
            >
              {loaded && (
                <Flex direction="column" gutter={3}>
                  {isLister ? (
                    [
                      <BankAccount key="bank-account" />,
                      <ContactInformation
                        dataContext={DATA_CONTEXT}
                        key="contact-information"
                      />,
                    ]
                  ) : (
                    <PaymentProvider />
                  )}

                  <EmailVerificationForm
                    dataQa={"edit-profile-email-verification"}
                    email={email}
                  />
                  <PhoneVerificationForm
                    dataQa={"edit-profile-phone-verification"}
                  />
                </Flex>
              )}
              <ProfileFooterRoot>
                <Container>
                  <Spacer bottom={1} top={1}>
                    <Flex
                      direction="column"
                      gutter={1}
                      justify="spaceBetween"
                      md={{
                        justify: "flexEnd",
                        direction: "row",
                        gutter: 2,
                        alignItems: "stretch",
                      }}
                      style={{ zIndex: 1 }}
                    >
                      <Button
                        name="back"
                        onClick={previousStep}
                        variant="secondary"
                      >
                        {translate("Back")}
                      </Button>
                      {verified && !error ? (
                        <Button name="next" onClick={onComplete}>
                          {translate("Continue")}
                        </Button>
                      ) : (
                        <Button name="next" onClick={this.handleSkip}>
                          {translate("payout.setup.button.skip")}
                        </Button>
                      )}
                    </Flex>
                  </Spacer>
                </Container>
              </ProfileFooterRoot>
            </Col>
            <Col
              lg={{ size: 3, order: 2 }}
              md={{ size: 12, order: 1 }}
              xs={{ order: 1 }}
            >
              <PayThroughBadiInfo />
              <Spacer bottom={2} />
            </Col>
          </Row>
        </Container>
      </Spacer>
    );
  }
}

PaymentSetupProfile.defaultProps = {
  error: null,
};

export default PaymentSetupProfile;
