import { isLoggedUser } from "datamodel/Auth/selectors";
import { getIsChrome, getIsFirefox } from "datamodel/Device/selectors";
import { getPushNotifications } from "datamodel/Features/selectors";

export const getIsAbleToPushNotifications = (state) => {
  return (
    getPushNotifications(state) &&
    isLoggedUser(state) &&
    (getIsChrome(state) || getIsFirefox(state))
  );
};
