export const validateRegexp = (value, regexp) => regexp.test(value);

export const validateEmail = (email) => {
  const emailRegex = /^[^/]+@[^/]+\.[^/]+$/;

  return validateRegexp(email, emailRegex);
};

export const validateRequired = (value) => {
  return value.length > 0;
};

export const validateMaxLength = (value, threshold) =>
  value.length <= threshold;

export const validateMinLength = (value, threshold) => value.length > threshold;

export const validatePassword = (password) => {
  const passRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d^a-zA-Z0-9].{5,}$/;

  return validateRegexp(password, passRegex);
};

const builtInValidations = {
  email: validateEmail,
  maxLength: validateMaxLength,
  minLength: validateMinLength,
  required: validateRequired,
  matchRegexp: validateRegexp,
  password: validatePassword,
};

export const validateValue = ({ value, validations = [] }) => {
  // there is not need to validate
  if (validations.length < 1) return true;

  // check if all the cases are valid for the given value
  return validations.every((validation) => {
    if (typeof validation === "function") return validation(value);

    // check if it's a tuple for a validation with exta params
    const [type, args] = Array.isArray(validation)
      ? validation
      : [validation, null];

    if (builtInValidations[type]) {
      return builtInValidations[type](value, args);
    }

    console.warn(`${type} validation does not exists`);
    return true;
  });
};
