import React from "react";
import { debounce } from "radash";

import Input from "components/form-text-input";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";

import { amountValidation } from "../utils";

const MaxBudget = ({ handleSaveSeekerPreference, budget = null }) => {
  const debouncedSaveSeekerPreferences = debounce(
    { delay: 300 },
    handleSaveSeekerPreference,
  );

  const handleChange = ({ value }) => {
    const parsedValue = value ? Number(value) : null;
    debouncedSaveSeekerPreferences({ budget: parsedValue });
  };

  return (
    <Input
      dataQa="seeker-preferences-max-budget-input"
      iconSrc={getPublicAsset("icons/seeker-preferences-wallet.svg")}
      initialValue={budget}
      name="max-budget"
      newType={true}
      onChange={handleChange}
      onInputValidation={() => {}}
      placeholder={translate("seeker.preferences.budget.any")}
      type="number"
      validations={[["maxLength", 12], amountValidation]}
    />
  );
};
export default MaxBudget;
