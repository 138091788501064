import React, { Fragment, memo, useEffect, useState } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { PROFESSIONS } from "base/shared/StaticData";

import FormBlock from "components/form-block";
import Select from "components/Form/Select/SelectWithFormsy";
import { Hidden } from "./styled-components";

import { getOrderedOptions, optionAtEnd } from "../../utils";

const Work = ({ work = 0, hasAJob, isValid, saved }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const professions = optionAtEnd(
      getOrderedOptions(translate(PROFESSIONS)),
      "value",
      22,
    );
    setOptions(professions);
  }, []);

  const Wrapper = hasAJob ? Fragment : Hidden;

  return (
    <Wrapper>
      <FormBlock
        id="formblock-work"
        isRequired={true}
        saved={saved}
        title={translate("profile.form.label.work")}
        valid={isValid}
      >
        <Select
          className="centered-flex-row"
          dataQa="profile-edit-work"
          name="work"
          options={options}
          placeholder={translate("profile.form.fields.work")}
          placeholderValue="0"
          validations="requiredOnWork"
          value={hasAJob ? work : 0}
        />
        <Spacer bottom={3} />
      </FormBlock>
    </Wrapper>
  );
};

export default memo(Work);
