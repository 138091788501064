import React, { Component } from "react";
import classNames from "classnames";

import Close from "components/Icons/Close";

import "./LateralModalExt.css";

class LateralModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpening: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isOpening: true }), 300);
  }

  render() {
    const {
      children,
      className,
      closable,
      closeHandler,
      mainClassName,
      isOpen,
      isClosing,
      outsideClickHandler,
      style,
    } = this.props;

    return (
      <div className={className}>
        {isOpen && (
          <div
            className="LateralModal__background"
            onClick={outsideClickHandler}
          />
        )}
        <div
          className={classNames(
            "LateralModal",
            {
              LateralModal__visible: this.state.isOpening && !isClosing,
            },
            mainClassName,
          )}
          style={style}
        >
          {closable && (
            <div className="LateralModal__close" onClick={closeHandler}>
              <Close className="LateralModal__close--icon" format="medium" />
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}

LateralModal.defaultProps = {
  children: null,
  className: null,
  closable: true,
  closeOnOutsideClick: true,
  forceClose: false,
  isClosing: false,
  mainClassName: "",
  closeHandler: () => {},
  outsideClickHandler: () => {},
};

export default LateralModal;
