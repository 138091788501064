import { connect } from "react-redux";

import UserActions from "app/datamodel/User/actions";

import { getPaymentPhone } from "app/datamodel/Payment/selectors";
import { getUserConfirmation } from "app/datamodel/User/selectors";
import { getToken } from "app/datamodel/Auth/selectors";
import { getContactPhone } from "app/datamodel/Payouts/selectors";

import { getPayment } from "datamodel/Payment/actions";

import PhoneVerification from "./phone-verification";

const mapStateToProps = (state) => ({
  authtoken: getToken(state),
  phoneNumber: getPaymentPhone(state) || getContactPhone(state),
  verified: getUserConfirmation(state, "phone"),
});

const mapDispatchToProps = {
  setConfirmation: UserActions.setConfirmation,
  getPaymentDetails: getPayment,
};

const mergeProps = (state, dispatch, ownProps) => ({
  ...state,
  ...dispatch,
  ...ownProps,
  getPaymentDetails: () => dispatch.getPaymentDetails(state.authtoken),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PhoneVerification);
