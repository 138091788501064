import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import {
  getAPIUrl as getServerUrl,
  removeUndefinedAndNullFields,
} from "base/shared/Utils";

export const getPurchaseSession = ({
  purchasableId,
  productId,
  roomId,
  token,
  type,
  successUrl,
  cancelUrl,
}) => {
  const { BADI_PURCHASE_STRIPE_SESSION } = envVars();
  const serviceUrl = getServerUrl(BADI_PURCHASE_STRIPE_SESSION);
  const paramsToApi = removeUndefinedAndNullFields({
    product_reference: purchasableId,
    product_id: productId,
    room_id: roomId,
    success_url: successUrl,
    cancel_url: cancelUrl,
    mode: type,
  });

  return fetch(serviceUrl, {
    method: "POST",
    params: paramsToApi,
    token,
  });
};

export const getSepaDetailsSession = ({ token, successUrl, cancelUrl }) => {
  const { BADI_SEPA_SETUP } = envVars();
  const serviceUrl = getServerUrl(BADI_SEPA_SETUP);
  const paramsToApi = {
    success_url: successUrl,
    cancel_url: cancelUrl,
  };

  return fetch(serviceUrl, {
    method: "POST",
    params: paramsToApi,
    token,
  });
};

export const getBookingCheckoutSession = ({ token, bookingRequestId }) => {
  const { BADI_BOOKING_STRIPE_CHECKOUT } = envVars();
  const serviceUrl = getServerUrl(
    BADI_BOOKING_STRIPE_CHECKOUT.replace(":id", bookingRequestId),
  );

  return fetch(serviceUrl, {
    method: "POST",
    token,
  });
};
