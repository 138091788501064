const NotificationsModel = {
  requests: 0,
  invitations: 0,
  messages: 0,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Notifications) {
    return {
      ...NotificationsModel,
      error: "There was an error in NotificationsModel.",
    };
  }
  return state;
}

export { NotificationsModel, setInitialState };
