import React, { useState } from "react";
import { useForm } from "components/hooks";

import {
  Spacer,
  Button,
  BUTTON_TYPES,
  Heading,
  Text,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { Container, Row, Col } from "reactstrap";
import RadioButtonGroup from "components/form-radio-button-group";
import { USER_ONBOARDING_TYPE } from "datamodel/User/constants";
import { STEPS } from "./constants";
import {
  ButtonsWrapper,
  FormWrapper,
  HeadingWrapper,
} from "./styled-components";
import "./onboardingModalExt.css";

const Onboarding = ({ onboardingType, updateOnboarding, callback }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [valid, setValid] = useState(false);
  const steps = STEPS[onboardingType];
  const { nameField, title, description, options, intro } = steps[activeStep];
  const defaultMode =
    onboardingType === USER_ONBOARDING_TYPE.LISTER ? "lister" : null;
  const [values, setInputValue, resetForm] = useForm({
    mode: defaultMode,
    role: null,
  });

  const handleChange = ({ name: inputName, value }) => {
    setInputValue(inputName, value);
    setValid(true);
  };

  const submit = () => {
    updateOnboarding(values);
    callback(values);
  };

  const handleGoBack = () => {
    resetForm();
    setActiveStep(activeStep - 1);
    setValid(false);
  };

  const handleContinue = () => {
    if (activeStep === 0) {
      if (values[nameField] === "seeker") {
        submit(values);
        return;
      }
      setActiveStep(1);
      setValid(false);
    } else {
      submit(values);
    }
  };

  const isFormValid = valid || intro;

  return (
    <Container>
      <Row>
        <HeadingWrapper>
          <Col sm={12}>
            <Spacer bottom={4}>
              <Spacer bottom={2}>
                <Heading
                  data-qa={`onboarding-title-step-${activeStep}`}
                  level="24-Bold"
                >
                  {translate(title)}
                </Heading>
              </Spacer>
              <Text dataQa={`onboarding-subtitle-step-${activeStep}`}>
                {translate(description)}
              </Text>
            </Spacer>
          </Col>
        </HeadingWrapper>
      </Row>
      <Row>
        <FormWrapper>
          {options && (
            <Col sm={12}>
              <RadioButtonGroup
                dataQa={`onboarding-radio-buttons-step-${activeStep}`}
                initialValue={null}
                key={nameField}
                layout="area"
                name={nameField}
                onChange={handleChange}
                options={translate(options)}
                required={true}
              />
            </Col>
          )}
        </FormWrapper>
      </Row>
      <ButtonsWrapper>
        <Button
          dataQa="onboarding-back-button"
          fullWidth={true}
          key="back"
          onClick={handleGoBack}
          style={{ visibility: activeStep === 1 ? "visible" : "hidden" }}
          variant={BUTTON_TYPES.SECONDARY}
        >
          {translate("button.text.back")}
        </Button>
        <Button
          dataQa="onboarding-continue-button"
          disabled={!isFormValid}
          fullWidth={true}
          key="continue"
          onClick={handleContinue}
          variant={BUTTON_TYPES.PRIMARY}
        >
          {translate("button.text.continue")}
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

export default Onboarding;
