import styled from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const ContentWrapper = styled.div`
  text-align: center;
  height: 100%;
`;

export const Anchor = styled.a`
  display: inline-block;
  color: inherit;
  text-decoration: underline;
`;

export const ConfirmRoot = styled.div`
  ${mediaquery.md`  
    min-width: 424px;
  `}
`;

export const ImageWrapper = styled.div`
  /* sorry for this hack be it was not possible with the current Image component implementation */
  img {
    max-height: 150px;
  }
  ${mediaquery.sm`
    img {
      max-height: 300px;
    } 
  `}
`;

export const Align = styled.span`
  display: inline-flex;
  text-align: ${(props) => props.direction};
`;
