import styled, { css } from "styled-components";
import { colors, unitizedPx } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const TextAreaWrapper = styled.div`
  > div {
    width: 100%;
  }
`;

export const CharCounter = styled.p`
  height: ${unitizedPx(2)};
  color: ${({ canSendMessage }) =>
    canSendMessage ? "inherit" : colors.midGrey};
  font-size: ${unitizedPx(1.5)};
  font-weight: 400;
  letter-spacing: 0px;
  line-height: ${unitizedPx(2)};
`;

export const Form = styled.form`
  height: 100%;
  ${({ includeFastTrackIcon }) =>
    includeFastTrackIcon &&
    css`
      background-image: url("${getCDNUrl()}/assets/icons/fast-track/fast-track-bolt.svg");
      background-repeat: no-repeat;
      background-size: 180px;
      background-position: right -35px top -35px;
    `};
`;

export const FeedbackWrapper = styled.div`
  height: calc(100vh - 154px);
  p {
    text-align: center;
  }
`;

export const MessageEnquiryStepWrapper = styled.div`
  height: 100%;
`;
