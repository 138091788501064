import { SEARCHROOMS_STATUSES as STATUS } from "../static";

const SearchRoomsModel = {
  status: STATUS.initial,
  error: null,
  cards: [],
  markers: [],
  selectedRoomId: 0,
  nextTokenCards: null,
  previousTokenCards: null,
  nextTokenMarkers: null,
  metadata: {},
  isLoginRequired: false,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.SearchRooms) {
    return {
      ...SearchRoomsModel,
      error: "There was an error in SearchRoomsModel.",
    };
  }
  return state;
}

export { SearchRoomsModel, setInitialState };
