export const getRoomPriceInfo = ({
  billsIncluded,
  deposit,
  translate = (text) => text,
}) => {
  const key = "search.rooms.room.";
  const billsText = billsIncluded ? "billsIncluded" : "billsNotIncluded";
  const depositText = deposit ? "depositRequired" : "depositNotRequired";

  return `${translate(key + billsText)}・${translate(key + depositText)}`;
};

export const getTooltipRoomsListBoundaries = () => {
  const containerRef = document.getElementsByClassName(
    "SearchResultsWrapper_list",
  )[0];
  if (!containerRef) return {};

  const maxBottom = window.innerHeight;
  const maxTop = containerRef.getBoundingClientRect().top;

  return { maxBottom, maxTop };
};

export const getTooltipCollectionBoundaries = (rect) => {
  if (!rect) return {};

  const maxBottom = window.innerHeight;
  const maxRight = rect.right;
  const maxLeft = rect.left;

  return { maxBottom, maxRight, maxLeft };
};

export const boundariesGenerator = (cardType) => {
  switch (cardType) {
    case "search":
    case "favorite":
      return getTooltipRoomsListBoundaries;
    case "collection":
      return getTooltipCollectionBoundaries;
    default:
      return getTooltipRoomsListBoundaries;
  }
};
