import createReducer from "base/shared/createReducer";
import Types from "./types";
import { MyListingsModel } from "./models";
import { parseListings, removeDuplicatedValues } from "./utils";

const findFocusState = (statuses) => {
  const activeTab = statuses.find((status) => status.focus) || statuses[0];
  return activeTab.id;
};

function myListingsSummaryRequest(state) {
  return {
    ...state,
    fetchedSummary: false,
    error: null,
  };
}

function myListingsSummaryError(state, action) {
  return { ...state, error: action.error };
}

function myListingsSummarySuccess(state, action) {
  const { plansInfo, statusesCounters } = action.result;
  return {
    ...state,
    error: null,
    fetchedSummary: true,
    focusState: findFocusState(statusesCounters),
    plansInfo,
    statusesCounters,
  };
}

function initialListingsByStatusRequest(state) {
  return {
    ...state,
    fetchingInitialListingsByStatus: true,
    fetchedInitialListingsByStatus: false,
    error: null,
  };
}

function initialListingsByStatusError(state, action) {
  return {
    ...state,
    fetchingInitialListingsByStatus: false,
    fetchedInitialListingsByStatus: false,
    error: action.error,
  };
}

function initialListingsByStatusSuccess(state, action) {
  const {
    result: {
      data: { rooms },
      pagination: { current_page: currentPage, total_pages: totalPages },
    },
    listingStatusId,
  } = action;

  return {
    ...state,
    fetchingInitialListingsByStatus: false,
    error: null,
    fetchedInitialListingsByStatus: true,
    currentPage,
    totalPages,
    rooms: parseListings(rooms, listingStatusId),
  };
}

function nextListingsByStatusRequest(state) {
  return { ...state, error: null };
}

function nextListingsByStatusError(state, action) {
  return { ...state, error: action.error };
}

function nextListingsByStatusSuccess(state, action) {
  const existingRooms = [...state.rooms];
  const {
    result: {
      data: { rooms },
      pagination: { current_page: currentPage, total_pages: totalPages },
    },
    listingStatusId,
  } = action;

  return {
    ...state,
    error: null,
    currentPage,
    totalPages,
    rooms: existingRooms.concat(parseListings(rooms, listingStatusId)),
  };
}

function resetListings() {
  return { ...MyListingsModel };
}

function selectListingsById(state, action) {
  const safeIds = removeDuplicatedValues(
    state.selectedListingsIds,
    action.result,
  );

  return {
    ...state,
    selectedListingsIds: [...state.selectedListingsIds, ...safeIds],
  };
}

function unSelectListingsById(state, action) {
  const selectedListingsIds = action.result.reduce(
    (selectedIds, unSelectedId) => {
      const index = selectedIds.indexOf(unSelectedId);
      if (index >= 0) {
        selectedIds.splice(index, 1);
      }
      return selectedIds;
    },
    [...state.selectedListingsIds],
  );
  return {
    ...state,
    selectedListingsIds,
  };
}

function clearSelectedListings(state) {
  return { ...state, selectedListingsIds: [] };
}

const actionHandlers = {
  [Types.MY_LISTINGS_SUMMARY_ERROR]: myListingsSummaryError,
  [Types.MY_LISTINGS_SUMMARY_REQUEST]: myListingsSummaryRequest,
  [Types.MY_LISTINGS_SUMMARY_SUCCESS]: myListingsSummarySuccess,
  [Types.INITIAL_LISTINGS_BY_STATUS_ERROR]: initialListingsByStatusError,
  [Types.INITIAL_LISTINGS_BY_STATUS_REQUEST]: initialListingsByStatusRequest,
  [Types.INITIAL_LISTINGS_BY_STATUS_SUCCESS]: initialListingsByStatusSuccess,
  [Types.NEXT_LISTINGS_BY_STATUS_ERROR]: nextListingsByStatusError,
  [Types.NEXT_LISTINGS_BY_STATUS_REQUEST]: nextListingsByStatusRequest,
  [Types.NEXT_LISTINGS_BY_STATUS_SUCCESS]: nextListingsByStatusSuccess,
  [Types.RESET_LISTINGS]: resetListings,
  [Types.SELECT_LISTINGS]: selectListingsById,
  [Types.UNSELECT_LISTINGS]: unSelectListingsById,
  [Types.CLEAR_SELECTED_LISTINGS]: clearSelectedListings,
};

export default createReducer(actionHandlers, { ...MyListingsModel });
