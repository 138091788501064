import React from "react";
import Helmet from "react-helmet";

import { locale, translate } from "base/shared/Localization";
import { getCDNUrl, getDomain } from "base/shared/Utils";

const Head = () => {
  const currentLocale = locale();
  const domainUrl = getDomain();
  const cdnUrl = getCDNUrl();
  const title = `Badi - ${translate("Rooms for rent and roommates")}`;
  const description = translate(
    "List your room and find your ideal roommate according to your age, tastes and hobbies · No Intermediaries · Publish your room for free.",
  );

  return (
    <Helmet
      htmlAttributes={{ lang: currentLocale }}
      meta={[
        { property: "og:title", content: title },
        { name: "apple-itunes-app", content: "app-id=1008653746" },
        { property: "og:url", content: domainUrl },
        {
          property: "og:image",
          content: `${cdnUrl}/assets/images/badi-app.jpg`,
        },
        { name: "description", content: description },
        { property: "og:description", content: description },
      ]}
      title={title}
    />
  );
};

export default Head;
