export const MOVE_IN_OPTIONS = [
  { title: "seeker.preferences.movein.now", value: 1 },
  { title: "seeker.preferences.movein.any", value: 2 },
  { title: "seeker.preferences.movein.date", value: 3 },
];

export const PLACE_TYPES = [
  { title: "seeker.preferences.property.type.private", value: 1 },
  { title: "seeker.preferences.property.type.entire", value: 2 },
  { title: "seeker.preferences.property.type.any", value: 3 },
];

export const DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";

export const NEXT_STEPS = {
  MAIN_PROFILE: "main_profile",
};
