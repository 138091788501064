import React, { memo } from "react";
import { Spacer, Choice } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import AmenityChoice from "components/Form/Choice/Choice";

import FilterSectionWrapper from "../../../wrappers/filter-section-wrapper";
import FilterSubSectionWrapper from "../../../wrappers/filter-sub-section-wrapper";
import { MultiChoice, StyledFlex } from "../styled-components";
import RangeField from "../../components/range-field";

const FlatFields = ({
  flatAmenityIds,
  houseRuleIds,
  onFlatSelect,
  onHouseRuleSelect,
  onBenefitSelect,
  houseRules,
  flatAmenities,
  flatBenefits,
  flatBenefitsIds,
  onMinFlatSizeValueChange,
  onMaxFlatSizeValueChange,
  minFlatSize,
  maxFlatSize,
}) => {
  const handleFlatBenefitsSelect = (e) => {
    const flatBenefitsId = parseInt(e.currentTarget.value, 10);
    if (isNaN(flatBenefitsId)) {
      return;
    }

    let newFlatBenefitsIds = [...flatBenefitsIds];
    if (e.currentTarget.checked) {
      newFlatBenefitsIds.push(flatBenefitsId);
    } else {
      newFlatBenefitsIds = newFlatBenefitsIds.filter(
        (_flatBenefitsId) => _flatBenefitsId !== flatBenefitsId,
      );
    }

    onBenefitSelect(newFlatBenefitsIds);
  };

  return (
    <FilterSectionWrapper title={translate("search.filters.flat")}>
      <FilterSubSectionWrapper title={translate("filter.services")}>
        <StyledFlex justify="spaceBetween" wrap="wrap">
          {flatBenefits.map((benefit, index) => (
            <MultiChoice even={index % 2 === 0}>
              <Choice
                checked={flatBenefitsIds.includes(benefit.id)}
                id={`benefit-${benefit.id}`}
                key={benefit.id}
                name="benefit"
                onChange={handleFlatBenefitsSelect}
                type="checkbox"
                value={benefit.value}
              >
                {benefit.title}
              </Choice>
            </MultiChoice>
          ))}
        </StyledFlex>
      </FilterSubSectionWrapper>
      <FilterSubSectionWrapper
        title={translate("search.filters.flat.amenities")}
      >
        <Spacer bottom={1} top={1}>
          <AmenityChoice
            className="SearchFlatForm__Amenities"
            items={flatAmenities}
            name="flatAmenities"
            onChange={onFlatSelect}
            value={flatAmenityIds}
          />
        </Spacer>
      </FilterSubSectionWrapper>
      <FilterSubSectionWrapper
        title={translate("search.filters.flat.houseRules")}
      >
        <Spacer bottom={1} top={1}>
          <AmenityChoice
            className="SearchFlatForm__Amenities"
            items={houseRules}
            name="flatAmenities"
            onChange={onHouseRuleSelect}
            value={houseRuleIds}
          />
        </Spacer>
      </FilterSubSectionWrapper>

      <Spacer bottom={1}>
        <RangeField
          max={maxFlatSize}
          min={minFlatSize}
          onMaxValueChange={onMaxFlatSizeValueChange}
          onMinValueChange={onMinFlatSizeValueChange}
          title={translate("room.detail.flatSize.title")}
        />
      </Spacer>
    </FilterSectionWrapper>
  );
};

export default memo(FlatFields);
