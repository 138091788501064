export const STEPS_SEEKER = [
  {
    step: 1,
    title: "booking_seeker_next_steps_1",
    description: "booking_seeker_next_steps_1_text",
  },
  {
    step: 2,
    title: "booking_seeker_next_steps_2",
    description: "booking_seeker_next_steps_2_text",
  },
  {
    step: 3,
    title: "booking_seeker_next_steps_3",
    description: "booking_seeker_next_steps_3_text",
  },
];

export const STEPS_LISTER = [
  {
    step: 1,
    title: "booking_flow_lister_next_steps_1",
    description: "booking_flow_lister_next_steps_1_text",
  },
  {
    step: 2,
    title: "booking_flow_lister_next_steps_2",
    description: "booking_flow_lister_next_steps_2_text",
  },
  {
    step: 3,
    title: "booking_flow_lister_next_steps_3",
    description: "booking_flow_lister_next_steps_3_text",
  },
];

export const STEPS_SEEKER_TITLE = "booking_seeker_next_steps_title";

export const STEPS_LISTER_TITLE = "booking_flow_next_steps";
