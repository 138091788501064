import React from "react";
import { Button } from "@badi/badi-components";

const ChatAction = ({
  config: { dataQa, Icon = null, label },
  onClick,
  ...buttonProps
}) => {
  return (
    <Button
      dataQa={dataQa}
      icon={Icon && <Icon />}
      onClick={onClick}
      {...buttonProps}
    >
      {label}
    </Button>
  );
};

export default ChatAction;
