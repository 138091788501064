import React, { PureComponent } from "react";
import { Container, Col, Row } from "reactstrap";

import { translate } from "base/shared/Localization";

import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import { Header, List, Section } from "components/Layout/Landing";
import { Faqs, Intro, TopBar } from "./components";

import { getTrust, getCommunication, getSafety, getFaqs } from "./utils";
import "./SecurityExt.css";

class Security extends PureComponent {
  render() {
    const trust = getTrust();
    const communication = getCommunication();
    const safety = getSafety();
    const faqs = getFaqs();

    return (
      <LayoutWrapper
        footer={<Footer campaign="security_footer" />}
        padding={false}
        topBar={<TopBar />}
      >
        <div className="Security__Content">
          <Header
            imageClassName="Security__LandingImage"
            imageUrl="/assets/images/security-landing.jpg"
            title={translate("security.title")}
          />
          <Container>
            <Intro text={translate("security.intro")} />
            <Section subtitle={translate("security.section.trust.title")}>
              <List {...trust} />
            </Section>
            <Section
              subtitle={translate("security.section.connect.subtitle")}
              title={translate("security.section.connect.title")}
            >
              <List {...communication} />
              <List {...safety} />
            </Section>
            <Section
              subtitle={translate("security.section.faqs.subtitle")}
              title={translate("security.section.faqs.title")}
            >
              <Row className="LandingSection">
                <Col lg={6} md={11}>
                  <Faqs {...faqs} />
                </Col>
              </Row>
            </Section>
          </Container>
        </div>
      </LayoutWrapper>
    );
  }
}

export default Security;
