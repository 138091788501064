import React from "react";
import { Container } from "reactstrap";
import { Heading, Spacer, Flex } from "@badi/badi-components";
import { Text } from "app/badi-components";
import { translate } from "base/shared/Localization";

import FeaturedCity from "./FeaturedCity";

import { CityGrid, GridScroll } from "./styled-components";

const FeaturedCities = ({ countryCode, resources, title, description }) => {
  return (
    <Container>
      <Spacer bottom={0.5}>
        <Heading level="24-Bold">{translate(title)}</Heading>
      </Spacer>
      <Spacer bottom={3}>
        <Text>{translate(description)}</Text>
      </Spacer>
      <GridScroll>
        <CityGrid as={Flex} wrap="wrap">
          {resources.map((city) => (
            <FeaturedCity key={city.id} {...city} countryCode={countryCode} />
          ))}
        </CityGrid>
      </GridScroll>
    </Container>
  );
};

FeaturedCities.defaultProps = {
  countryCode: null,
};

export default FeaturedCities;
