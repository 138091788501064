import React from "react";

import { DEFAULT_SOCIAL_NETWORKS, SOCIAL_NETWORKS } from "./constants";
import { getSocialComponent } from "./utils";
import { Root } from "./styled-components";

const MultiShare = ({
  social = DEFAULT_SOCIAL_NETWORKS,
  target = null,
  title = "",
}) => {
  return (
    <Root>
      {social.map((s) => {
        const SocialComponent = getSocialComponent(s);
        return (
          SocialComponent && (
            <SocialComponent key={s} target={target} title={title} />
          )
        );
      })}
    </Root>
  );
};

export default MultiShare;
