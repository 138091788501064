import createReducer from "base/shared/createReducer";

import { getRoom } from "./utils";
import Types from "./types";
import { RoomModel } from "./redux-state";

function clearRoom() {
  return RoomModel;
}

function ownerRequest(state) {
  return { ...state, fetching: true };
}

function ownerError(state, action) {
  const error = {
    ...action.error,
    isNotFound: action.error.code === "resource_not_found",
  };
  return { ...state, fetching: false, error };
}

function ownerSuccess(state, action) {
  const owner = action.result;

  return {
    ...state,
    fetching: false,
    room: { ...state.room, owner },
    error: null,
  };
}

function roomRequest(state) {
  return { ...state, fetching: true, fetched: false };
}

function roomError(state, action) {
  const error = {
    ...action.error,
    isNotFound: action.error.code === "resource_not_found",
  };
  return { ...state, fetching: false, error };
}

function roomSuccess(state, action) {
  const { room } = action.result;

  return {
    ...state,
    fetching: false,
    fetched: true,
    room: getRoom(room),
    error: null,
  };
}

function roomSimilarRequest(state) {
  return { ...state, fetching: true };
}

function roomSimilarError(state, action) {
  const { error } = action;
  return { ...state, fetching: false, error };
}

function roomSimilarSuccess(state, action) {
  const { result: similarRooms } = action;

  return {
    ...state,
    fetching: false,
    similarRooms,
    error: null,
  };
}

function updateRoom(state, action) {
  return { ...state, room: { ...state.room, ...action.result } };
}

const actionHandlers = {
  [Types.ROOMOWNER_REQUEST]: ownerRequest,
  [Types.ROOMOWNER_SUCCESS]: ownerSuccess,
  [Types.ROOMOWNER_ERROR]: ownerError,
  [Types.ROOM_REQUEST]: roomRequest,
  [Types.ROOM_SUCCESS]: roomSuccess,
  [Types.ROOM_ERROR]: roomError,
  [Types.CLEAR_ROOM]: clearRoom,
  [Types.ROOM_SIMILAR_REQUEST]: roomSimilarRequest,
  [Types.ROOM_SIMILAR_ERROR]: roomSimilarError,
  [Types.ROOM_SIMILAR_SUCCESS]: roomSimilarSuccess,
  [Types.ROOM_UPDATE]: updateRoom,
};

export default createReducer(actionHandlers, RoomModel);
