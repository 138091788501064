import { StepCard } from "@badi/badi-components";

export const BOOKING_BENEFITS = [
  { id: 1, description: "contact.flow.stepper_benefit_one" },
  { id: 2, description: "contact.flow.stepper_benefit_two" },
  {
    id: 3,
    description: "contact.flow.stepper_benefit_three",
  },
];

export const STEPS = [
  {
    stepNumber: 1,
    title: "contact.flow.stepper_contact",
    description: "contact.flow.stepper_contact_text",
    progress: true,
    status: StepCard.STATUS.IN_PROGRESS,
  },
  {
    stepNumber: 2,
    title: "contact.flow.stepper_meet",
    description: "contact.flow.stepper_meet_text",
    progress: true,
    status: StepCard.STATUS.NOT_STARTED,
  },
  {
    stepNumber: 3,
    title: "contact.flow.stepper_book",
    status: StepCard.STATUS.NOT_STARTED,
  },
];

export const MESSAGE_ENQUIRY_MAX_LENGTH = 500;
