export const GRAPH_TYPES = {
  VIEW_EVOLUTION: "view_evolution",
  CONNECTION_EVOLUTION: "connection_evolution",
  RESPONSE_RATE: "response_rate",
  RESPONSE_TIME: "response_time",
  PROPERTIES_PICTURES: "room_properties_pictures",
  PROPERTIES_AMENITIES: "room_properties_amenities",
  PROPERTIES_DESCRIPTION: "room_properties_description",
  FAVORITES: "favorites",
};

export const TARGET_VALUES = {
  [GRAPH_TYPES.VIEW_EVOLUTION]: 26,
  [GRAPH_TYPES.CONNECTION_EVOLUTION]: 2,
  [GRAPH_TYPES.RESPONSE_RATE]: 100,
  [GRAPH_TYPES.RESPONSE_TIME]: 120,
  [GRAPH_TYPES.PROPERTIES_PICTURES]: 20,
  [GRAPH_TYPES.PROPERTIES_AMENITIES]: 7,
  [GRAPH_TYPES.PROPERTIES_DESCRIPTION]: 2000,
  [GRAPH_TYPES.FAVORITES]: 10,
};

export const BEHAVIOUR_LEVELS = {
  POOR: "poor",
  FAIR: "fair",
  GOOD: "good",
};
