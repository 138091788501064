import React from "react";
import LayoutModal from "../Layout";
import { Wrapper } from "./styled-components";

const DisplayModal = ({ children, isClosed, outsideClickHandler }) => {
  return (
    <LayoutModal
      isClosed={isClosed}
      onIconClose={outsideClickHandler}
      outsideClickHandler={outsideClickHandler}
      outsideCloseIcon={true}
      overlayOpacity={0.8}
      wrapper={Wrapper}
    >
      {children}
    </LayoutModal>
  );
};

DisplayModal.defaultProps = {
  children: null,
  isClosed: false,
  outsideClickHandler: () => {},
};

export default DisplayModal;
