import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  > button:last-child {
    margin-left: ${unitizedPx(1)};
  }
`;
export const HeadingWrapper = styled.div`
  height: ${unitizedPx(18)};
  width: 100%;
`;

export const FormWrapper = styled.div`
  height: ${unitizedPx(33)};
  width: 100%;
`;
