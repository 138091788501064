import { connect } from "react-redux";
import {
  getLoadedStatus,
  getContactPhone,
  getContactEmail,
  getError,
} from "datamodel/Payouts/selectors";
import PayoutsActions from "datamodel/Payouts/actions";

import Payout from "./payout";

export default connect(
  (state) => ({
    loaded: getLoadedStatus(state),
    error: getError(state),
    email: getContactEmail(state),
    phoneNumber: getContactPhone(state),
  }),
  {
    getAccount: PayoutsActions.getAccount,
  },
)(Payout);
