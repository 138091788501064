import React, { Component } from "react";
import { Flex } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import {
  SeekerBarInfo,
  SeekerBarAvatarWrapper,
  StyledImage,
} from "../styled-components";

class SeekerBar extends Component {
  render() {
    const {
      room: {
        seeker: { hasRented, coverPicture, firstName },
      },
      onClick,
      variation,
    } = this.props;

    const rootText = hasRented
      ? translate("mylistings.rentedTo")
      : translate("mylistings.bookedBy");
    return (
      <SeekerBarInfo
        onClick={onClick}
        role="presentation"
        variation={variation}
      >
        <Flex alignItems="center">
          <SeekerBarAvatarWrapper>
            <StyledImage alt="seeker-avatar" src={coverPicture} />
          </SeekerBarAvatarWrapper>
          <span>{`${rootText} ${firstName}`}</span>
        </Flex>
      </SeekerBarInfo>
    );
  }
}

SeekerBar.defaultProps = {
  className: "",
  onClick: () => {},
};

export default SeekerBar;
