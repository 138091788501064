import { useState, useEffect } from "react";
import { getSeekerPreferencesPath } from "Sections/seeker-preferences/routes";
import { useSelector } from "react-redux";
import {
  seekerPreferencesRequiredSelector,
  getUserRole,
  getUserFetchedSelector,
} from "datamodel/User/selectors";

import { LISTER_USER_ROLE } from "datamodel/User/constants";

import { getUserId } from "datamodel/Auth/selectors";
import {
  getSource,
  redirectToSource,
  handleSwitchUserModeRedirect,
} from "./utils";

const RedirectController = ({ location, navigateTo }) => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const seekerPreferencesRequired = useSelector(
    seekerPreferencesRequiredSelector,
  );
  const isUserLister = useSelector(getUserRole) === LISTER_USER_ROLE;
  const isUserFetched = useSelector(getUserFetchedSelector);
  const userId = useSelector(getUserId);
  const { search, pathname } = location || {};

  const source = getSource(search);

  const handleRedirect = () => {
    if (redirectUrl) navigateTo(redirectUrl);
  };

  useEffect(() => {
    if (!!userId && !!isUserFetched && !redirectUrl) {
      if (seekerPreferencesRequired && !isUserLister) {
        setRedirectUrl(getSeekerPreferencesPath());
      } else if (source) {
        setRedirectUrl(redirectToSource(source, isUserLister));
      } else {
        setRedirectUrl(handleSwitchUserModeRedirect(pathname, isUserLister));
      }
    }

    return () => {
      if (!userId) setRedirectUrl(null);
    };
  }, [isUserLister, userId, seekerPreferencesRequired]);

  useEffect(() => {
    handleRedirect();
  }, [redirectUrl]);

  return null;
};

export default RedirectController;
