import React from "react";
import { useSelector } from "react-redux";
import { Flex, Text } from "@badi/badi-components";
import { getToken } from "datamodel/Auth/selectors";
import { leaveInboxConversation } from "datamodel/Conversation/api";
import ContextualButton from "components/ContextualButton";
import { translate } from "base/shared/Localization";

const LeaveConnection = ({ connectionId, onCancel, onLeave }) => {
  const token = useSelector(getToken);

  const handleLeaveClick = () => {
    leaveInboxConversation(connectionId, token).then(() => {
      onLeave();
    });
  };

  return (
    <Flex direction="column" gutter={3}>
      <Text body="14-Regular" dataQa="leave-connection-title">
        {translate("conversation.leave.confirmation")}
      </Text>
      <ContextualButton
        dataQa="leave-connection-leave"
        icon="leave"
        onClick={handleLeaveClick}
        text={translate("conversation.leave")}
      />
      <ContextualButton
        dataQa="leave-connection-cancel"
        icon="close"
        onClick={onCancel}
        text={translate("Cancel")}
      />
    </Flex>
  );
};

export default LeaveConnection;
