export const HAS_LIFT = [
  { title: "room.list.step2.lift.yes", value: "yes" },
  { title: "room.list.step2.lift.no", value: "no" },
];

export const ORIENTATION = [
  { title: "room.list.step2.orientation.interior", value: "interior" },
  { title: "room.list.step2.orientation.exterior", value: "exterior" },
];

export const FURNISHED_ROOM = [
  { title: "room.list.step2.room.bedIncluded.yes", value: "yes" },
  { title: "room.list.step2.room.bedIncluded.no", value: "no" },
];

export const CERTIFICATION = [
  { title: "room.list.step2.certification.a", value: "a" },
  { title: "room.list.step2.certification.b", value: "b" },
  { title: "room.list.step2.certification.c", value: "c" },
  { title: "room.list.step2.certification.d", value: "d" },
  { title: "room.list.step2.certification.e", value: "e" },
  { title: "room.list.step2.certification.f", value: "f" },
  { title: "room.list.step2.certification.g", value: "g" },
  {
    title: "room.list.step2.certification.not.available",
    value: "unavailable",
  },
  { title: "room.list.step2.certification.processing", value: "requested" },
];

export const BED_TYPES = [
  { title: "room.list.step3.bedType.sofa", value: 1, icon: "SofaBed" },
  { title: "room.list.step3.bedType.single", value: 2, icon: "SingleBed" },
  { title: "room.list.step3.bedType.double", value: 3, icon: "DoubleBed" },
  { title: "room.list.step3.bedType.no", value: 4, icon: "NoBed" },
];

export const PLACE_TYPES = {
  ROOM: "room",
  ENTIRE_PLACE: "entirePlace",
};

export const SUB_PLACE_TYPES = {
  [PLACE_TYPES.ROOM]: [
    { title: "room.list.step2.property.coliving", value: "coliving" },
    { title: "room.list.step2.property.sharedPlace", value: "shared_place" },
  ],
  [PLACE_TYPES.ENTIRE_PLACE]: [
    { title: "room.list.step2.property.apartment", value: "apartment" },
    { title: "room.list.step2.property.penthouse", value: "penthouse" },
    { title: "room.list.step2.property.duplex", value: "duplex" },
    { title: "room.list.step2.property.loft", value: "studio" },
  ],
};

export const COUNTRY_CODES = {
  SPAIN: "ES",
};

const ENTIRE_PLACE_AMENITIES = [
  "wifi",
  "air_conditioner",
  "heating",
  "washing_machine",
  "doorman",
  "entire_place_furnished",
  "pool",
  "dishwasher",
  "dryer",
  "tv",
  "garden",
  "terrace",
  "entire_place_working_space",
  "apartment_balcony",
  "parking",
  "wheelchair_friendly",
];

const ROOM_AMENITIES = [
  "balcony",
  "furnished",
  "own_bathroom",
  "exterior_view",
  "working_space",
];

const ENTIRE_PLACE_RULES = ["smoker_friendly", "pet_friendly"];

const ROOM_RULES = ["smoker_friendly", "pet_friendly", "couples_accepted"];

export const AMENITIES_TYPES = {
  AMENITIES: "amenities",
  RULES: "rules",
};

export const LIST = {
  amenities: {
    [PLACE_TYPES.ENTIRE_PLACE]: ENTIRE_PLACE_AMENITIES,
    [PLACE_TYPES.ROOM]: ROOM_AMENITIES,
  },
  rules: {
    [PLACE_TYPES.ENTIRE_PLACE]: ENTIRE_PLACE_RULES,
    [PLACE_TYPES.ROOM]: ROOM_RULES,
  },
};

export const AMENITIES = [
  { value: "tv", title: "room.amenities.tv", icon: "Tv" },
  { value: "wifi", title: "room.amenities.wifi", icon: "Wifi" },
  {
    value: "air_conditioner",
    title: "room.amenities.airConditioning",
    icon: "AirConditioning",
  },
  { value: "parking", title: "room.amenities.parking", icon: "Parking" },
  { value: "smoker_friendly", title: "room.amenities.smoker", icon: "Smoker" },
  { value: "pet_friendly", title: "room.amenities.pet", icon: "Pet" },
  { value: "heating", title: "room.amenities.heating", icon: "Heating" },
  {
    value: "washing_machine",
    title: "room.amenities.washingMachine",
    icon: "WashingMachine",
  },
  { value: "dryer", title: "room.amenities.dryer", icon: "Dryer" },
  { value: "doorman", title: "room.amenities.doorman", icon: "Doorman" },
  {
    value: "couples_accepted",
    title: "room.amenities.couples",
    icon: "Couples",
  },
  {
    value: "furnished",
    title: "room.amenities.furnishedRoom",
    icon: "FurnishedRoom",
  },
  {
    value: "entire_place_furnished",
    title: "room.amenities.furnishedRoom",
    icon: "FurnishedRoom",
  },
  { value: "pool", title: "room.amenities.pool", icon: "Pool" },
  {
    value: "own_bathroom",
    title: "room.amenities.privateBathroom",
    icon: "PrivateBathroom",
  },
  { value: "balcony", title: "room.amenities.balcony", icon: "Balcony" },
  {
    value: "exterior_view",
    title: "room.amenities.naturalLight",
    icon: "NaturalLight",
  },
  {
    value: "dishwasher",
    title: "room.amenities.dishwasher",
    icon: "Dishwasher",
  },
  {
    value: "wheelchair_friendly",
    title: "room.amenities.wheelchairFriendly",
    icon: "WheelchairFriendly",
  },
  { value: "garden", title: "room.amenities.garden", icon: "Garden" },
  { value: "terrace", title: "room.amenities.terrace", icon: "Terrace" },
  {
    value: "working_space",
    title: "room.amenities.workingSpace",
    icon: "WorkingSpace",
  },
  {
    value: "entire_place_working_space",
    title: "room.amenities.workingSpace",
    icon: "WorkingSpace",
  },
];
