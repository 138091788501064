import React from "react";
import { Flex, Heading, Spacer, StepCard, Button } from "@badi/badi-components";
import Image from "components/Image/Image";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { translate } from "base/shared/Localization";
import { MODAL_BOOKING_BENEFITS } from "./constants";

export const closeBookingBenefitsModal = () => {
  modal.remove(MODAL_ID.BOOKING_BENEFITS);
};

export const openBookingBenefitsModal = ({ handleBookNow } = {}) => {
  modal.add(<BookingBenefitsModal handleBookNow={handleBookNow} />, {
    id: MODAL_ID.BOOKING_BENEFITS,
    onClose: closeBookingBenefitsModal,
    closable: true,
    closeOnOutsideClick: true,
  });
};

const BookingBenefitsModal = ({ handleBookNow = null }) => {
  const handleClick = () => {
    closeBookingBenefitsModal();
    handleBookNow();
  };

  return (
    <Flex
      alignItems="center"
      data-qa="booking-benefits-modal"
      direction="column"
      gutter={4}
    >
      <Image
        alt={translate("roomdetail.bottomsheet_title")}
        data-qa="booking-benefits-image"
        src="/assets/images/booking/booking-benefits-modal.svg"
      />
      <Flex direction="column" gutter={3}>
        <Heading data-qa="booking-benefits-heading">
          {translate("roomdetail.bottomsheet_title")}
        </Heading>
        {MODAL_BOOKING_BENEFITS.map(({ number, title, description }) => (
          <StepCard
            description={translate(description)}
            key={`booking-benefit-${number}`}
            step={number}
            title={translate(title)}
          />
        ))}
      </Flex>
      {handleBookNow && (
        <Button data-qa="booking-benefits-button" onClick={handleClick}>
          {translate("room.detail.cta_request_to_book")}
        </Button>
      )}
      <Spacer bottom={3} />
    </Flex>
  );
};

export default BookingBenefitsModal;
