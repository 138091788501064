import { bindActionCreators } from "redux";
import { v4 as uuidv4 } from "uuid";

import ConversationActions from "datamodel/Conversation/actions";
import NotificationsActions from "datamodel/Notifications/actions";
import SocketActions from "datamodel/Socket/actions";
import InboxActions from "datamodel/Inbox/actions";
import RoomListActions from "datamodel/RoomList/actions";
import { openDynamicInfoModal } from "components/dynamic-modal";

const CHANNELS = {
  INBOX: "InboxChannel",
  MODERATION: "ModerationChannel",
};

export default class SocketMiddleware {
  constructor(wsUrl, token, userId, locale, dispatch) {
    this.actions = bindActionCreators(
      {
        ...ConversationActions,
        ...NotificationsActions,
        ...SocketActions,
        ...InboxActions,
        ...RoomListActions,
      },
      dispatch,
    );

    const guid = uuidv4();
    const socket =
      userId && token
        ? new WebSocket(
            `${wsUrl}?access_token=${token}&uuid=${guid}&locale=${locale}`,
          )
        : null;
    this.socket = socket;
    this.userId = userId;
    this.uuid = guid;
    this.token = token;

    this.actions.setSocket(this);
  }

  init = () => {
    if (!this.socket) return;
    this.socket.onopen = this.onOpen;
    this.socket.onmessage = this.onMessage;
    this.socket.onclose = this.onClose;
    this.channels = Object.values(CHANNELS);
    this.identifiers = this.channels.reduce((identifiers, channelName) => {
      const channel = { channel: channelName, user_id: this.userId };
      return { ...identifiers, [channelName]: JSON.stringify(channel) };
    }, {});
  };

  close = () => {
    if (!this.socket) return;
    this.socket.close();
  };

  isClosed = () => {
    if (!this.socket) return true;
    return this.socket.readyState === 3;
  };

  isOpen = () => {
    if (!this.socket) return false;
    return this.socket.readyState === 1;
  };

  onClose = () => {
    this.actions.setUnReady();
  };

  onOpen = () => {
    this.channels.forEach((channel) => {
      const identifier = this.identifiers[channel];
      const command = { command: "subscribe", identifier };
      this.socket.send(JSON.stringify(command));
    });
  };

  onDefaultType = (type) => {
    const types = {
      confirm_subscription: this.actions.setReady,
    };

    if (types[type]) types[type]();
  };

  onCustomMessage = ({ data, event_type: eventType }) => {
    const {
      addMessage,
      setNotificationsCount,
      updateConnection,
      setModerationResult,
    } = this.actions;
    const { connection_id: connectionId } = data;

    switch (eventType) {
      case "message_created":
        addMessage(connectionId, data.message);
        break;
      case "connection_updated":
        updateConnection(data.connection);
        break;
      case "inbox_updated":
        setNotificationsCount(data);
        break;
      case "moderation_completed":
        setModerationResult(data.moderation_result, data.room_id);
        break;
      default:
        break;
    }
  };

  onMessage = ({ data }) => {
    const { message = null, type = null, trigger = null } = JSON.parse(data);

    if (type) {
      if (trigger) {
        openDynamicInfoModal({ type, trigger }, this.token);
      } else {
        this.onDefaultType(type);
      }
    } else {
      this.onCustomMessage(message);
    }
  };

  send = (action, data = {}, channel = CHANNELS.INBOX) => {
    if (!this.socket || this.socket.readyState !== 1) return;
    const jsonData = JSON.stringify({ action, ...data });
    const identifier = this.identifiers[channel];
    const command = {
      command: "message",
      identifier,
      data: jsonData,
    };

    this.socket.send(JSON.stringify(command));
  };
}
