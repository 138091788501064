import styled from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const StyledFlex = styled.div`
  flex-direction: column;
  ${mediaquery.md`  
    flex-direction: row;
    & > div {
      width: 18%;
    }
  `}
`;

export const LinkBlock = styled.div``;
