import React from "react";
import { translate } from "base/shared/Localization";
import Checkbox from "components/Form/checkbox-with-formsy";

import { Flex, Spacer, Text, Label } from "@badi/badi-components";
import { CheckIcon } from "./check-icon";
import { DETAIL_FIELDS } from "./constants";

export const PhotosVerification = ({ checked = false, name }) => (
  <Spacer top={3}>
    <Label size="small" variant="general-inverted">
      {translate("photos.verification.label")}
    </Label>
    <Spacer top={0.5}>
      <Text body="14-Bold">{translate("photos.verification.title")}</Text>
    </Spacer>
    <Spacer top={1}>
      <Text body="14-Regular">{translate("photos.verification.subtitle")}</Text>
    </Spacer>
    {DETAIL_FIELDS.map((order) => (
      <Spacer
        data-qa={`photos-verification-detail-${order}`}
        key={order}
        top={2.5}
      >
        <Flex>
          <Spacer right={1}>
            <CheckIcon />
          </Spacer>
          <div>
            <Text body="14-Bold" inline={true}>
              {translate(`photos.verification.detail.${order}.bold`)}
            </Text>
            <Text body="14-Regular" inline={true}>
              {translate(`photos.verification.detail.${order}.normal`)}
            </Text>
          </div>
        </Flex>
      </Spacer>
    ))}
    <Spacer top={4}>
      <Flex alignItems="center">
        <Spacer right={1}>
          <Checkbox
            checked={checked}
            dataQa="photos-verification-checkbox"
            id={name}
            name={name}
          >
            <Text body="14-Regular" inline={true}>
              {translate("photos.verification.checkbox.label")}
            </Text>
          </Checkbox>
        </Spacer>
      </Flex>
    </Spacer>
  </Spacer>
);

export default PhotosVerification;
