import { getDisplayPrice } from "base/shared/Utils";
import { getListerScore } from "base/shared/User";
import RoomModel, {
  DEFAULT_PRICE_DETAILS,
} from "datamodel/Room/models/room-model";

class FavoriteRoomModel extends RoomModel {
  constructor(data) {
    super();
    this.entity = data || {};
  }

  get verified() {
    return this.entity.verified;
  }

  get coordinates() {
    return { lat: this.entity.lat, lng: this.entity.lng };
  }

  get lister() {
    const { lister } = this.entity;
    if (!lister) {
      return {};
    }
    return {
      age: lister.age,
      firstName: lister.first_name,
      score: getListerScore(lister),
    };
  }

  get pictures() {
    return this.entity.pictures;
  }

  get previousPrice() {
    const { previous, currency } = this.entity.monthly_price;
    if (!previous) return DEFAULT_PRICE_DETAILS.previousPrice;
    return getDisplayPrice(previous, currency);
  }

  get price() {
    const {
      current,
      currency,
      bills_included: billsIncluded,
    } = this.entity.monthly_price;
    const displayText = getDisplayPrice(current, currency);
    if (!displayText) return DEFAULT_PRICE_DETAILS.displayText;

    return { displayText, billsIncluded };
  }

  get url() {
    return `/room/${this.entity.id}`;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      verified: this.verified,
      matchProfile: this.matchProfile,
      coordinates: this.coordinates,
      lister: this.lister,
      pictures: this.pictures,
      url: this.url,
      previousPrice: this.previousPrice,
      price: this.price,
    };
  }
}

export default FavoriteRoomModel;
