import React, { useMemo } from "react";
import {
  BREAKPOINTS_PX,
  Flex,
  Spacer,
  useMatchMedia,
} from "@badi/badi-components";

import { publishRoom } from "datamodel/Room/api";
import { fetchUser } from "datamodel/User/api";
import { buildActions } from "Sections/my-listings/utils";
import { findStatusId } from "datamodel/MyListings/utils";
import { setRentals } from "datamodel/search-rooms/api";
import UserFeedback from "base/shared/user-feedback";

import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { v4 as uuidv4 } from "uuid";
import { getDomain, getLocalePath } from "base/shared/Utils";

import {
  TopSectionOtherProfile,
  UserWrapper,
  LowSection,
} from "components/User/Sections";
import ShareRoom from "components/Room/ShareRoom";
import { openRoomPublishedModal } from "components/published-listing";
import MenuElement from "./MyListingsCardElements/menu-element";
import FilterLayer from "./filter-layer";
import CardDetails from "./MyListingsCardElements/card-details";
import CardActionsDesktop from "./MyListingsCardElements/card-actions/desktop-card-actions";
import CardActionsMobile from "./MyListingsCardElements/card-actions/mobile-card-actions";
import {
  DeleteRoom,
  PublishSchedule,
  UnPostRoom,
} from "./MyListingsCardElements/modals";

import { openEditRoomModal } from "./MyListingsCardElements/modals/EditRoom";

import { openRentedToModal } from "./MyListingsCardElements/modals/rented-to/rented-to-modal";
import { Root } from "./styled-components";

const MyListingsCard = ({
  closeAllActions,
  listingIdMoreActionsOpen,
  listingObjectMobileActionsOpen,
  openBadiGold,
  openBooking,
  openEditRoomForm,
  openMoreActionsMenu,
  openRoomDetails,
  resetMyListings,
  room,
  setMobileActionsMenu,
  token,
}) => {
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.lg})`]);
  const actions = useMemo(() => ({ ...buildActions(room) }), [room]);

  const share = () => {
    modal.add(
      <ShareRoom
        roomImageUrl={room.coverPicture}
        roomUrl={`${getDomain()}${getLocalePath()}/room/${room.id}`}
        title={
          "I have a spare room for rent in my flat, anyone interested? Check it out at @badiapp"
        }
      />,
    );
  };

  const publish = () => {
    publishRoom(token, { id: room.id }).then(() => {
      resetMyListings(findStatusId(room.listingStatus));

      openRoomPublishedModal({ roomId: room.id, room });
    });
  };

  const checkPublish = () => {
    if (!room) return;

    const { editRequired } = room;
    if (editRequired) {
      openEditRoomModal({ id: room.id });
      return;
    }

    publish();
  };

  const preview = () => {
    const { id: roomId, title } = room;

    openRoomDetails({ roomId, title });
  };

  const seeBooking = () => {
    if (room && room.bookingReferenceId) {
      openBooking(room.bookingReferenceId);
    }
  };

  const handleRentRoom = (params) => {
    setRentals(params, token)
      .then(() => {
        resetMyListings(findStatusId(room.listingStatus));

        modal.remove(MODAL_ID.MARK_ROOM_AS_RENTED);
      })
      .catch((error) => {
        UserFeedback.exception(
          "Something went wrong",
          error.code ? error.message : "Please, try again or contact support.",
          error,
        );
      });
  };

  const markRoomAsRentedSecondStep = ({
    rented_to_user: userId,
    id: roomId,
  }) => {
    setTimeout(() => {
      modal.update(
        MODAL_ID.MARK_ROOM_AS_RENTED,
        <PublishSchedule
          id={roomId}
          onSubmit={handleRentRoom}
          userId={userId}
        />,
        { id: MODAL_ID.MARK_ROOM_AS_RENTED, mainClassName: "popup__main_thin" },
      );
    }, 400);
  };

  const markAsRented = () => {
    openRentedToModal({
      id: room.id,
      onUserSelected: markRoomAsRentedSecondStep,
      token,
    });
  };

  const unpublish = () => {
    const modalUuid = uuidv4();

    modal.add(
      <UnPostRoom
        callback={(unPublished) => {
          if (unPublished) {
            resetMyListings(findStatusId(room.listingStatus));
          } else {
            markAsRented(room);
          }
          modal.remove(modalUuid);
        }}
        id={room.id}
        token={token}
      />,
      { id: modalUuid, mainClassName: "popup__main_thin" },
    );
  };

  const edit = () => {
    openEditRoomForm({
      roomId: room.id,
      publish: room.extraFeatures.forcePublish,
    });
  };

  const deleteListing = (askIsRented) => {
    const modalUuid = uuidv4();

    modal.add(
      <DeleteRoom
        askIsRented={askIsRented}
        id={room.id}
        onCancel={() => {
          modal.remove(modalUuid);
        }}
        onSuccess={(additionalModalId) => {
          resetMyListings(findStatusId(room.listingStatus));

          modal.remove(modalUuid);

          if (additionalModalId) modal.remove(additionalModalId);
        }}
        token={token}
      />,
      { id: modalUuid, mainClassName: "popup__main_thin" },
    );
  };

  const deleteWithReasons = () => {
    deleteListing(true);
  };

  const viewSeekerProfile = () => {
    const seekerId = room.seeker.id;

    fetchUser(seekerId, token).then((user) => {
      const modalUuid = uuidv4();

      modal.add(
        <UserWrapper modalId={modalUuid}>
          <TopSectionOtherProfile format={"otherProfile"} user={user} />
          <LowSection user={user} />
        </UserWrapper>,
        {
          id: modalUuid,
          className: "popup_profile",
        },
      );
    });
  };

  const renderActionsInMenu = (actionsToRender) => {
    const actionFunction = {
      delete: () => deleteListing(false),
      deleteWithReasons,
      edit,
      markAsRented,
      publish: checkPublish,
      share,
      unpublish,
      preview,
      seeBooking,
      viewSeekerProfile,
      upgradeListToGold: () => openBadiGold(room.id),
    };

    return actionsToRender.map((action) => (
      <MenuElement
        action={action}
        actionFunction={actionFunction}
        dataQa={action.key}
        key={action.key}
      />
    ));
  };

  const { visibleActions, nonVisibleActions, mobileActions, seekerActions } =
    actions;

  return (
    <Flex
      as={Root}
      dataQa="my-listings-card"
      direction="column"
      lg={{ direction: "row" }}
    >
      <FilterLayer
        closeAllActions={closeAllActions}
        listingIdMoreActionsOpen={listingIdMoreActionsOpen}
      />
      <Spacer bottom={2} grow={1} lg={{ bottom: 0, right: 2 }} right={0}>
        <CardDetails
          room={room}
          showPreview={() => preview(room)}
          viewSeekerProfile={() => viewSeekerProfile(room)}
        />
      </Spacer>
      {isDesktop ? (
        <CardActionsDesktop
          isMoreActionsOpened={listingIdMoreActionsOpen === room.id}
          nonVisibleActions={nonVisibleActions}
          openMoreActionsMenu={openMoreActionsMenu(room.id)}
          renderActionsInMenu={renderActionsInMenu}
          visibleActions={visibleActions}
        />
      ) : (
        <CardActionsMobile
          listingObjectMobileActionsOpen={listingObjectMobileActionsOpen}
          mobileActions={mobileActions}
          renderActionsInMenu={renderActionsInMenu}
          room={room}
          seekerActions={seekerActions}
          setMobileActionsMenu={setMobileActionsMenu(room.id)}
        />
      )}
    </Flex>
  );
};

export default MyListingsCard;
