import createReducer from "base/shared/createReducer";

import Types from "../types";
import { NotificationsModel } from "../models";

function resetNotifications() {
  return { ...NotificationsModel };
}

function setNotifications(state, action) {
  const { messages } = action.result;
  return { messages };
}

const actionHandlers = {
  [Types.RESET_NOTIFICATIONS]: resetNotifications,
  [Types.SET_NOTIFICATIONS]: setNotifications,
};

export default createReducer(actionHandlers, { ...NotificationsModel });
