import { getRelativePath } from "base/shared/Utils";

const BASE_PATH = "/visits";

export const getExpressVisitPath = ({ visitId } = {}) => {
  if (!visitId) {
    return null;
  }

  return getRelativePath(`${BASE_PATH}/${visitId}/express`);
};
