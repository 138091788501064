import { connect } from "react-redux";

import {
  getTransactionsSummarySelector,
  getTransactionsSelector,
} from "datamodel/Transactions/selectors";
import TransactionsList from "./transactions-list";

const mapStateToProps = (state) => ({
  transactionsSummary: getTransactionsSummarySelector(state),
  transactions: getTransactionsSelector(state),
});

export default connect(mapStateToProps)(TransactionsList);
