import EventEmitter from "eventemitter3";

import { isProductionEnvironment } from "base/shared/Env/env";
import { STATUS, LOAD_CALLBACK_NAME, LOAD_EVENT_NAME } from "./constants";
import { addStylesTag, getSiteUrl } from "./utils";

const eventEmitter = new EventEmitter();

export const RECAPTCHA_ACTION_NAMES = {
  LOGIN: "login",
  ROOM_DETAIL: "roomdetail",
};

class RecaptchaApi {
  constructor() {
    this.captcha = null;
    this.url = "";
    this.status = STATUS.IDLE;
    this.badgeStyles = {
      id: "recaptcha-hide-styles",
      styles: ".grecaptcha-badge { visibility: hidden }",
    };
    this.userId = 0;
  }

  init(siteId) {
    if (!siteId) {
      this.disable();
      return;
    }

    this.siteId = siteId;
    this.url = getSiteUrl(siteId);

    // should return something success o no
    if (this.isIdle() || this.isDisabled()) {
      this.loadScript();
    }
  }

  setUserId(userId = 0) {
    this.userId = userId;
  }

  disable() {
    this.status = STATUS.DISABLED;
  }

  loadScript() {
    this.status = STATUS.LOADING;
    window[LOAD_CALLBACK_NAME] = this.onLoaded.bind(this);

    return new Promise((resolve, reject) => {
      addStylesTag(this.badgeStyles);

      const script = document.createElement("script");
      script.src = this.url;
      script.async = true;
      script.onerror = () => {
        this.status = STATUS.ERROR;
        reject();
      };
      script.onload = () => {
        this.status = STATUS.LOADED;
        resolve();
      };

      document.body.appendChild(script);
    });
  }

  onLoaded() {
    this.status = STATUS.READY;
    this.captcha = isProductionEnvironment()
      ? window.grecaptcha.enterprise
      : window.grecaptcha;

    eventEmitter.emit(LOAD_EVENT_NAME);
  }

  isIdle() {
    return this.status === STATUS.IDLE;
  }

  isReady() {
    return this.status === STATUS.READY;
  }

  isDisabled() {
    return this.status === STATUS.DISABLED;
  }

  async executeAction(actionName) {
    try {
      const token = await this.captcha.execute(this.siteId, {
        action: actionName,
      });

      return { token };
    } catch (error) {
      return { error };
    }
  }

  async execute({ actionName, onExecute = () => {}, onError = () => {} }) {
    if (this.isDisabled()) {
      onExecute();
      return;
    }

    if (!this.isReady()) {
      eventEmitter.once(LOAD_EVENT_NAME, () =>
        this.execute({ actionName, onExecute, onError }),
      );
      return;
    }

    const { token, error } = await this.executeAction(actionName);

    if (error) {
      onError(error);
      return;
    }

    onExecute(token);
  }
}

const instance = new RecaptchaApi();

export default instance;
