import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";

export const Wrapper = styled.div`
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  width: 100%;
  padding: ${unitizedPx(8)} 0px;

  ${mediaquery.lg`
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  `};
`;
