import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import classNames from "classnames";

import { translate } from "base/shared/Localization";
import { compareWithWindowWidth } from "base/shared/Utils";

import Select from "../Select/Select";
import { getMonths, getRangeOptions, parseDate } from "./utils";
import "./SelectDateExt.css";

class SelectDate extends Component {
  constructor(props) {
    super(props);

    this.yearOptions = getRangeOptions(props.minYear, props.maxYear).reverse();
  }

  render() {
    const { className, dayText, monthText, onChange, yearText, value } =
      this.props;

    const { day, daysInMonth, month, year } = parseDate(value);
    const monthOptions = getMonths(
      year,
      compareWithWindowWidth(800) < 1 || this.props.isMobile,
    );
    const dayOptions = getRangeOptions(1, daysInMonth);

    return (
      <div className={classNames("SelectDate", className)}>
        <Row>
          <Col xs={4} className="SelectDate__input">
            <Select
              name="year"
              onChange={onChange}
              options={this.yearOptions}
              placeholder={translate(yearText)}
              value={year}
            />
          </Col>
          <Col xs={5} className="SelectDate__input">
            <Select
              name="month"
              options={monthOptions}
              onChange={onChange}
              placeholder={translate(monthText)}
              value={month}
            />
          </Col>
          <Col xs={3} className="SelectDate__input">
            <Select
              name="day"
              onChange={onChange}
              options={dayOptions}
              placeholder={translate(dayText)}
              value={day}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

SelectDate.defaultProps = {
  className: "",
  dayText: "day",
  minYear: moment().year() - 120,
  maxYear: moment().year() - 18,
  monthText: "month",
  value: null,
  yearText: "year",
};

export default SelectDate;
