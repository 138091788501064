import React, { useEffect, useState } from "react";
import { fetchSignature } from "datamodel/Room/api";
import { MapWrapper } from "./styled-components";
import {
  DEFAULT_SIZE,
  MAP_TYPES,
  DEFAULT_ZOOM,
  GOOGLE_API_KEY,
  GOOGLE_MAPS_DOMAIN,
} from "./constants";
import { parseMarker, getUrlPath } from "./utils";

const StaticMap = ({
  alt = "Google maps",
  latitude = 0,
  longitude = 0,
  marker = {},
  size = DEFAULT_SIZE,
  mapType = MAP_TYPES.ROADMAP,
  zoom = DEFAULT_ZOOM,
  token,
  dataQa = "static-map",
}) => {
  if (!latitude || !longitude) return null;
  const markerParam = parseMarker(marker);

  const [signedPath, setSignedPath] = useState("");

  useEffect(() => {
    const paramsObject = {
      center: `${latitude},${longitude}`,
      zoom,
      size,
      mapType,
      key: GOOGLE_API_KEY,
      markers: markerParam,
    };

    const pathForSignature = getUrlPath(paramsObject);

    fetchSignature(token, { url: pathForSignature }).then(({ signature }) => {
      setSignedPath(signature);
    });
  }, []);

  const staticMapUrl = `${GOOGLE_MAPS_DOMAIN}${signedPath}`;

  return signedPath ? (
    <MapWrapper data-qa={dataQa}>
      <img alt={alt} src={staticMapUrl} />
    </MapWrapper>
  ) : null;
};

export default StaticMap;
