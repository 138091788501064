import React from "react";

import Report from "components/Report/Report";
import ReportIds from "components/Report/ReportIds";
import { modal } from "containers/ReduxModal/emitter";

const MODAL_ID = "report-room";

export const openReportModal = ({ roomId, userId, token }) => {
  const reportIds = new ReportIds(roomId, userId);

  modal.add(
    <Report
      callback={() => modal.remove(MODAL_ID)}
      ids={reportIds}
      token={token}
    />,
    { className: "Report--Modal", id: MODAL_ID },
  );
};
