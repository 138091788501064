import { getRelativePath } from "base/shared/Utils";
import { DEFAULT_SORT_BY_VALUE } from "../static";
import { benefitsObjectToArray } from "datamodel/search-rooms/utils";
import { sortByQueryParamHandler } from "base/shared/tracking-headers";
import { HEADER_PARAMS } from "base/shared/tracking-headers/static";

const ET_SEARCH_SOURCE_KEY = HEADER_PARAMS.SEARCH_SOURCES.keyVal;

export const sanitizeParam = (param) => {
  if (typeof param === "string") {
    return param.trim().replace(/([\s,])/g, "-");
  }
  return "";
};

const parseFromBounds = (bounds) => {
  if (!bounds) return "";

  return `${parseFromLatLng(bounds.ne)};${parseFromLatLng(bounds.sw)}`;
};

const parseFromBool = (value) => {
  if (typeof value === "boolean") return value ? "1" : "0";
  return "";
};

const parseFromLatLng = (value) => {
  if (!value) return "";
  return `${value.lat},${value.lng}`;
};

const parseFromArray = (items = [], property = "id") => {
  if (Array.isArray(items)) {
    return items
      .filter((item) => item.selected)
      .map((item) => item[property])
      .join(",");
  }
  return "";
};

export const getSearchUrl = (params, additionalParams) => {
  if (!params) return null;

  const { label, meta, text, etParam } = params;
  const searchParams = {
    ...params,
    city: meta ? meta.city : null,
    text: label || text,
  };

  const etQueryStringParam = etParam ? { [ET_SEARCH_SOURCE_KEY]: etParam } : {};

  const qsParams = parseQueryFromParams(searchParams, {
    ...additionalParams,
    ...etQueryStringParam,
  });

  const searchUrl = `/s/${sanitizeParam(searchParams.text)}?${qsParams}`;

  const searchUrlOutput = sortByQueryParamHandler(
    searchUrl,
    params.sortBy,
    params.sortFilter,
  );

  return getRelativePath(searchUrlOutput);
};

const xor = (a, b) => Boolean(a ? !b : b);

export const parseQueryFromParams = (params, additionalParams = {}) => {
  const parsedParams = { ...additionalParams };
  if (!params) return "";

  const flatAmenities = parseFromArray(params.flatAmenities);
  const roomAmenities = parseFromArray(params.roomAmenities);
  const houseRules = parseFromArray(params.houseRules);

  parsedParams.amenities = [flatAmenities, roomAmenities, houseRules]
    .filter((a) => !!a)
    .join(",");
  parsedParams.benefits = benefitsObjectToArray(params.flatBenefits).join(",");
  parsedParams.bed = parseFromArray(params.bedTypes);
  parsedParams.g = parseFromArray(params.genders);
  parsedParams.stay = parseFromArray(params.stayOptions, "apivalue");

  const marketplaceSegmentBadiCommunity =
    params?.marketplaceSegmentsFilter?.badiCommunity;
  const marketplaceSegmentBadiRooms =
    params?.marketplaceSegmentsFilter?.badiRooms;
  const isOneSelected = xor(
    marketplaceSegmentBadiCommunity,
    marketplaceSegmentBadiRooms,
  );

  if (isOneSelected && marketplaceSegmentBadiCommunity) {
    parsedParams.marketplaceSegmentBadiCommunity =
      marketplaceSegmentBadiCommunity;
  }
  if (isOneSelected && marketplaceSegmentBadiRooms) {
    parsedParams.marketplaceSegmentBadiRooms = marketplaceSegmentBadiRooms;
  }

  const sortby = parseFromArray(params.sortBy);
  if (Number(sortby) !== DEFAULT_SORT_BY_VALUE.id) parsedParams.sort = sortby;

  parsedParams.place = parseFromArray(params.placeTypes);

  parsedParams.bounds = parseFromBounds(params.bounds);
  parsedParams.center = parseFromLatLng(params.coordinates);

  if (params.tenants) parsedParams.t = params.tenants;
  if (params.max) parsedParams.max = params.max;
  if (params.min) parsedParams.min = params.min;
  if (params.token) parsedParams.token = params.token;

  if (params.bookable) parsedParams.b = parseFromBool(params.bookable);
  if (params.now) parsedParams.now = parseFromBool(params.now);

  parsedParams.d = params.distance;
  parsedParams.from = params.fromDate;
  parsedParams.city = params.city;
  parsedParams.pid = params.placeId;
  parsedParams.z = params.zoom || "";

  const qsParams = Object.keys(parsedParams).reduce((prev, name) => {
    const parsedParam = parsedParams[name];
    if (
      typeof parsedParam === "undefined" ||
      parsedParam === "" ||
      parsedParam === null
    )
      return prev;
    return `${prev}${prev ? "&" : ""}${name}=${parsedParam}`;
  }, "");

  return qsParams;
};
