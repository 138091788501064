import React from "react";
import { Flex, Heading, Spacer, Text } from "@badi/badi-components";

import SuccessCheck from "components/Icons/SuccessCheck";
import { translate } from "base/shared/Localization";
import ListerScoreBadge from "components/lister-score-badge";
import { UserCardBlock } from "../styled-components";

const Trust = ({ items, score }) => {
  const hasItems = !!items.length;

  return (
    <UserCardBlock>
      <Flex alignItems="center" data-qa="lister-score" direction="row">
        <Heading level={3}>{translate("lister_score_badge")}</Heading>
        <Spacer left={1}>
          <ListerScoreBadge score={score} />
        </Spacer>
      </Flex>

      {hasItems && (
        <>
          <Spacer bottom={3} data-qa="lister-score-description" top={2}>
            <Text>{translate("lister_score_badge_description")}</Text>
          </Spacer>
          {items.map((item) => (
            <Spacer bottom={2} data-qa="trust-items" key={item.description}>
              <Flex alignItems="center" direction="row" gutter={2}>
                <SuccessCheck size={24} />
                <div grow={1}>{item.description}</div>
              </Flex>
            </Spacer>
          ))}
        </>
      )}
    </UserCardBlock>
  );
};

Trust.defaultProps = {
  items: [],
};

export default Trust;
