import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import ReferralRoomModel from "./models/referral-room-model";
import ReferralAccount from "./models/referral-account-model";
import PendingReferrals from "./models/pending-referrals-model";
import ReferralProduct from "./models/referral-product-model";
import { parsePagination } from "./utils";

export const getReferralAccount = ({ token }, params) => {
  const { BADI_REFERRALS_ACCOUNT } = envVars();
  const { sgid } = params;
  const parsedParams = {
    ...(sgid && { sgid }),
  };

  const serviceUrl = getServerUrl(BADI_REFERRALS_ACCOUNT);
  return fetch(serviceUrl, {
    token,
    params: parsedParams,
  }).then(({ data }) => new ReferralAccount(data).toJSON());
};

export const getPendingReferrals = ({ token }, params) => {
  const { paginationToken, sgid } = params;
  const { BADI_REFERRALS_PENDING } = envVars();

  const parsedParams = {
    ...(sgid && { sgid }),
    ...(paginationToken && { token: paginationToken }),
  };

  const serviceUrl = getServerUrl(BADI_REFERRALS_PENDING);
  return fetch(serviceUrl, {
    token,
    params: parsedParams,
  }).then(({ data }) => new PendingReferrals(data).toJSON());
};

export const getRooms = ({ token }, params) => {
  const { BADI_REFERRALS_ROOMS } = envVars();
  const { page, sgid } = params;
  const parsedParams = {
    page,
    ...(sgid && { sgid }),
  };

  const serviceUrl = getServerUrl(BADI_REFERRALS_ROOMS);
  return fetch(serviceUrl, {
    token,
    params: parsedParams,
  }).then(({ data, pagination }) => {
    const rooms = data.rooms.map((room) =>
      new ReferralRoomModel(room).toJSON(),
    );
    const parsedPagination = parsePagination(pagination);
    return { rooms, pagination: parsedPagination };
  });
};

export const getProducts = ({ token }, params) => {
  const { BADI_REFERRALS_REDEEM } = envVars();
  const { sgid } = params;
  const parsedParams = {
    ...(sgid && { sgid }),
  };

  const serviceUrl = getServerUrl(BADI_REFERRALS_REDEEM);
  return fetch(serviceUrl, {
    token,
    params: parsedParams,
  }).then(({ data }) =>
    data.products.map((product) => new ReferralProduct(product).toJSON()),
  );
};

export const redeemProduct = ({ token }, params) => {
  const { BADI_REFERRALS_REDEEM } = envVars();
  const { name, roomId, sgid } = params;
  const parsedParams = {
    name,
    room_id: roomId,
    ...(sgid && { sgid }),
  };

  const serviceUrl = getServerUrl(BADI_REFERRALS_REDEEM);
  return fetch(serviceUrl, {
    method: "POST",
    token,
    params: parsedParams,
  });
};
