import React, { useContext } from "react";
import {
  Label,
  Spacer,
  Icon,
  Flex,
  Text,
  BADI_COLORS,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { IconWrapper } from "./styled-components";

const PremiumLabel = ({ top = 0, bottom = 0, right = 0, left = 0 }) => {
  return (
    <Spacer bottom={bottom} left={left} right={right} top={top}>
      <Label data-qa="premium-label" size="small" variant="premium">
        <Flex alignItems="center" gutter={0.5} justify="center">
          <Text body="10-Medium">
            {translate("trust_items.upgrade_label.gold")}
          </Text>
        </Flex>
      </Label>
    </Spacer>
  );
};

export default PremiumLabel;
