import React from "react";
import { withRouter } from "react-router";

import { getEditRoomPath, getRoomDetailPath } from "Sections/Room/routes";
import { getBookingReference } from "Sections/booking/routes";
import { getGoldUpgradePath } from "Sections/plans/routes";
import { HEADER_PARAMS } from "base/shared/tracking-headers";
import MyListingsCardMapping from "./my-listings-card";

const MyListingsCardRoute = ({ router, ...rest }) => {
  const openBooking = (referenceId) => {
    router.push(getBookingReference({ referenceId }));
  };

  const openEditRoomForm = ({ roomId, publish }) => {
    router.push(getEditRoomPath({ id: roomId, publish }));
  };

  const openRoomDetails = ({ roomId, title }) => {
    const {
      keyVal,
      options: { MY_LISTINGS },
    } = HEADER_PARAMS.ROOM_VIEW_SOURCE;
    router.push(
      getRoomDetailPath({
        roomId,
        title,
        params: { keyVal, options: MY_LISTINGS },
      }),
    );
  };

  const openBadiGold = (roomId) => {
    router.push(
      getGoldUpgradePath({ roomId, feature: "", initiator: location.pathname }),
    );
  };

  return (
    <MyListingsCardMapping
      openBadiGold={openBadiGold}
      openBooking={openBooking}
      openEditRoomForm={openEditRoomForm}
      openRoomDetails={openRoomDetails}
      {...rest}
    />
  );
};

export default withRouter(MyListingsCardRoute);
