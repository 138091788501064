export const languages = [
  {
    locale: "en",
    displaName: "EN (UK)",
    title: "English (UK)",
    isoCode: "en-GB",
    googleMapsLanguageCode: "en-GB",
  },
  {
    locale: "es",
    displaName: "ES",
    title: "Español",
    isoCode: "es-ES",
    googleMapsLanguageCode: "es",
  },
];

export const DEFAULT_LOCALE_ISO = "en-GB";
export const DEFAULT_LOCALE = "en";
export const DEFAULT_GOOGLE_MAPS_LANGUAGE_CODE = "en-GB";
