import React, { PureComponent } from "react";
import { Flex, Switch, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

class NotificationSection extends PureComponent {
  render() {
    const { notification, isActive, toggleButton } = this.props;
    const { label, type } = notification;

    return (
      <div className="form__fieldset">
        <div className="form__row">
          <div className="form__half form__half_middle">
            <Flex justify="spaceBetween">
              <Text>{translate(label)}</Text>
              <Switch name={type} onChange={toggleButton} selected={isActive} />
            </Flex>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationSection;
