import { getDomain } from "base/shared/Utils";
import { BADI_COLORS } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { GRAPH_TYPES, TARGET_VALUES, BEHAVIOUR_LEVELS } from "./constants";
import { getListerMetricsPath } from "./routes";

export const isExternalLink = (link) => new URL(link).origin !== getDomain();

export const areGraphsAvailable = (metrics) => {
  if (!metrics) return false;

  return Object.entries(metrics)
    .reduce((res, [key, val]) => {
      if (key !== "roomProperties" && key !== "favorites") {
        res.push(val);
      }
      return res;
    }, [])
    .some((e) => e !== null);
};

export const getNumberWithSign = (number) => (number < 0 ? "" : "+") + number;

export const getResponseRateValueContent = (value) => {
  if (!Number.isInteger(value) || value > 100 || value < 0) return null;

  if (value >= 0 && value < 50) {
    return {
      text: translate("lister.analytics_response_rate_poor"),
      color: BADI_COLORS.SEMANTIC.DANGER.DEFAULT,
    };
  } else if (value >= 50 && value < 75) {
    return {
      text: translate("lister.analytics_response_rate_fair"),
      color: BADI_COLORS.SEMANTIC.WARNING.DEFAULT,
    };
  }

  return {
    text: translate("lister.analytics_response_rate_good"),
    color: BADI_COLORS.BRAND.PRIMARY.DEFAULT,
  };
};

export const getResponseRateTargetContent = (value) => {
  if (!Number.isInteger(value)) return null;

  const difference = value - TARGET_VALUES[GRAPH_TYPES.RESPONSE_RATE];
  const text = translate("lister.analytics_response_rate_average", {
    percent: getNumberWithSign(difference),
  });

  return {
    text,
    color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
  };
};

export const getResponseRateTrendContent = (trend) => {
  if (!Number.isInteger(trend)) return null;

  const text = translate("lister.analytics_response_rate_yesterday", {
    percent: getNumberWithSign(trend),
  });

  if (trend === 0) {
    return {
      text,
      color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
    };
  } else if (trend < 0) {
    return {
      text,
      color: BADI_COLORS.SEMANTIC.DANGER.DEFAULT,
    };
  }

  return {
    text,
    color: BADI_COLORS.BRAND.PRIMARY.DEFAULT,
  };
};

export const getResponseTimeValueContent = (value) => {
  if (!Number.isInteger(value) || value < 0) return null;

  const valueHours = Math.round(value / 60);

  const text =
    value < 60
      ? translate("lister.analytics_response_time_minutes", { minutes: value })
      : translate("lister.analytics_response_time_hours", {
          hours: valueHours,
        });

  if (valueHours >= 0 && valueHours <= 2) {
    return {
      text,
      color: BADI_COLORS.BRAND.PRIMARY.DEFAULT,
    };
  } else if (valueHours > 2 && valueHours <= 5) {
    return {
      text,
      color: BADI_COLORS.SEMANTIC.WARNING.DEFAULT,
    };
  }

  return {
    text,
    color: BADI_COLORS.SEMANTIC.DANGER.DEFAULT,
  };
};

export const getResponseTimeTargetContent = (value) => {
  if (!Number.isInteger(value)) return null;

  const difference = value - TARGET_VALUES[GRAPH_TYPES.RESPONSE_TIME];

  const text =
    Math.abs(difference) < 60
      ? translate("lister.analytics_response_time_average_minutes", {
          minutes: getNumberWithSign(difference),
        })
      : translate("lister.analytics_response_time_average_hours", {
          hours: getNumberWithSign(Math.round(difference / 60)),
        });

  return {
    text,
    color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
  };
};

export const getResponseTimeTrendContent = (trend, previousValue) => {
  if (
    !Number.isInteger(trend) ||
    !Number.isInteger(previousValue) ||
    previousValue === 0
  )
    return null;

  const trendInPercent = Math.round((trend / previousValue) * 100);

  const text = translate("lister.analytics_response_time_yesterday", {
    percent: getNumberWithSign(trendInPercent),
  });

  if (trendInPercent === 0) {
    return {
      text,
      color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
    };
  } else if (trendInPercent < 0) {
    return {
      text,
      color: BADI_COLORS.BRAND.PRIMARY.DEFAULT,
    };
  }

  return {
    text,
    color: BADI_COLORS.SEMANTIC.DANGER.DEFAULT,
  };
};

const getRoomPropertiesText = (level, graphType, isTarget, target) => {
  return isTarget
    ? translate(`lister.analytics_${graphType}_${level}_average`, {
        target,
      })
    : translate(`lister.analytics_room_properties_${level}`);
};

export const getRoomPropertiesContent = (value, graphType, isTarget) => {
  if (!Number.isInteger(value)) return null;

  const target = TARGET_VALUES[graphType];
  const fairThreshold = Math.round(target * 0.7);

  if (value >= 0 && value < fairThreshold) {
    return {
      text: getRoomPropertiesText(
        BEHAVIOUR_LEVELS.POOR,
        graphType,
        isTarget,
        target,
      ),
      color: isTarget
        ? BADI_COLORS.NEUTRAL.NEUTRAL_80
        : BADI_COLORS.SEMANTIC.DANGER.DEFAULT,
    };
  } else if (value >= fairThreshold && value < target) {
    return {
      text: getRoomPropertiesText(
        BEHAVIOUR_LEVELS.FAIR,
        graphType,
        isTarget,
        target,
      ),
      color: isTarget
        ? BADI_COLORS.NEUTRAL.NEUTRAL_80
        : BADI_COLORS.SEMANTIC.WARNING.DEFAULT,
    };
  }

  return {
    text: getRoomPropertiesText(
      BEHAVIOUR_LEVELS.GOOD,
      graphType,
      isTarget,
      target,
    ),
    color: isTarget
      ? BADI_COLORS.NEUTRAL.NEUTRAL_80
      : BADI_COLORS.BRAND.PRIMARY.DEFAULT,
  };
};

export const getFavoritesValueContent = (value) => {
  if (!Number.isInteger(value)) return null;

  const text = `${value}`;

  return {
    text,
    color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
  };
};

export const getFavoritesTargetContent = (value) => {
  if (!Number.isInteger(value)) return null;

  const difference = value - TARGET_VALUES[GRAPH_TYPES.FAVORITES];
  const text = translate("lister.analytics_saved_as_favourite_average", {
    difference: getNumberWithSign(difference),
  });

  return {
    text,
    color: BADI_COLORS.NEUTRAL.NEUTRAL_80,
  };
};

export const getPlanAnnotations = (dates, labels, color) =>
  dates.map((date) => ({
    type: "line",
    scaleID: "x",
    value: labels.indexOf(date),
    borderColor: color,
    borderWidth: 4,
    label: {
      enabled: false,
    },
  }));

export const getPlansAnnotations = (
  plans,
  labels,
  boostingLegendVisibile,
  badiGoldLegendVisibile,
) => {
  const { standaloneBoostingDates = [], badiGoldDates = [] } = plans;

  const boostingAnnotations = boostingLegendVisibile
    ? getPlanAnnotations(
        standaloneBoostingDates,
        labels,
        BADI_COLORS.ADDITIONAL.ADDITIONAL_05,
      )
    : [];

  const badiGoldAnnotations = badiGoldLegendVisibile
    ? getPlanAnnotations(
        badiGoldDates,
        labels,
        BADI_COLORS.ADDITIONAL.ADDITIONAL_08,
      )
    : [];

  return [...boostingAnnotations, ...badiGoldAnnotations];
};

export const isListerMetricsPage = () => {
  if (typeof window !== "undefined") {
    const { pathname } = window.location;
    return pathname && pathname.startsWith(getListerMetricsPath());
  }
  return false;
};
