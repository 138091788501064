import { reportRoom } from "datamodel/Room/api";
import { reportUser } from "datamodel/User/api";

const roomOptions = [
  {
    title: "report.room.option.5.title",
    description: "report.room.option.5.description",
    value: "fake",
  },
  {
    title: "report.room.option.10.title",
    description: "report.room.option.10.description",
    value: "holiday",
  },
  {
    title: "report.room.option.2.title",
    description: "report.room.option.2.description",
    value: "explicit",
  },
  {
    title: "report.room.option.3.title",
    description: "report.room.option.3.description",
    value: "duplicated",
  },
];

const userOptions = [
  {
    title: "report.user.option.1.title",
    description: "report.user.option.1.description",
    value: "badBehaviour",
    children: true,
    placeholder: "report.user.option.1.placeholder",
  },
  {
    title: "report.user.option.2.title",
    description: "report.user.option.2.description",
    value: "explicit",
  },
  {
    title: "report.user.option.6.title",
    description: "report.user.option.6.description",
    value: "scam",
  },
];

const types = [
  { title: "report.reportType.option.1.title", value: "room" },
  { title: "report.reportType.option.2.title", value: "user" },
];

const getReportTypeId = (type) => {
  switch (type) {
    case "room":
      return 1;
    case "user":
      return 2;
    default:
      return 0;
  }
};

const getUserReasonId = (reason) => {
  let reasonId;
  switch (reason) {
    case "badBehaviour":
      return 1;
    case "explicit":
      return 2;
    case "scam":
      return 6;
    default:
  }
  return reasonId;
};

const getRoomReasonId = (reason) => {
  let reasonId;
  switch (reason) {
    case "fake":
      return 5;
    case "holiday":
      return 10;
    case "explicit":
      return 2;
    case "duplicated":
      return 3;
    default:
      break;
  }
  return reasonId;
};

const getReasonId = (type, reason) =>
  type === "user" ? getUserReasonId(reason) : getRoomReasonId(reason);

const getOptions = (type) => {
  switch (type) {
    case "room":
      return roomOptions;
    case "user":
      return userOptions;
    default:
      return types;
  }
};

const getReportType = (id) => {
  switch (id) {
    case 1:
      return "room";
    case 2:
      return "user";
    default:
      return null;
  }
};

const report = (token, type, id, reasonId, description) => {
  let apiCall;
  switch (type) {
    case "room":
      apiCall = reportRoom;
      break;
    case "user":
      apiCall = reportUser;
      break;
    default:
      return null;
  }

  return apiCall(token, { id, reason: reasonId, description });
};

export { getOptions, getReportType, report, getReportTypeId, getReasonId };
