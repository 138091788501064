import React from "react";
import classNames from "classnames";
import Image from "components/Image/Image";

import "./RadioButtonExt.css";

class RadioButton extends React.PureComponent {
  clickHandler = (e) => {
    e.preventDefault();
    this.props.onChange({ target: this.input });
  };

  render() {
    const {
      checked,
      children,
      className,
      description,
      id,
      label,
      name,
      onChange,
      layout,
      value,
      imageUrl,
      dataQa,
    } = this.props;

    return layout === "default" ? (
      <div
        className={classNames("Form__RadioButtonDefault--wrapper", className)}
      >
        <input
          checked={checked}
          data-qa={`${dataQa}-${value}`}
          id={id}
          name={name}
          onChange={onChange}
          type="radio"
          value={value}
        />
        <label htmlFor={id}>
          <span className="Form__RadioButtonDefault--custom">
            <span className="Form__RadioButtonDefault--custom-selected" />
          </span>
          {label && label}
        </label>
        {children}
      </div>
    ) : (
      <div
        aria-checked={checked}
        className={classNames(
          "Form__RadioButton--wrapper",
          { selected: checked },
          className,
        )}
        onClick={this.clickHandler}
        role="menuitemradio"
        tabIndex="0"
      >
        {imageUrl && <Image src={imageUrl} />}
        <input
          checked={checked}
          data-qa={`${dataQa}-${value}`}
          id={id}
          name={name}
          onChange={onChange}
          ref={(i) => {
            this.input = i;
          }}
          type="radio"
          value={value}
        />
        <label htmlFor={id}>{label}</label>
        <p>{description}</p>
        <div className="Form__RadioButton--additional">{children}</div>
      </div>
    );
  }
}

RadioButton.defaultProps = {
  checked: false,
  children: null,
  className: "",
  dataQa: "",
  description: null,
  imageUrl: null,
  label: "",
  layout: "default",
  onChange: () => {},
};

export default RadioButton;
