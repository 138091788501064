import styled from "styled-components";

export const BackcoverWrapper = styled.div`
  position: relative;
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const BackCoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: 3;
`;

export const SeeAll = styled.div`
  border: 1px solid ${({ color }) => color};
  padding: 16px 24px;
  border-radius: 4px;
`;

export const ContentWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
`;
