import { getRelativePathNameWithoutLang } from "base/shared/Utils";
import * as CookiesHandler from "base/shared/CookiesHandler";
import { locale } from "base/shared/Localization";
import badiPushNotifications from "lib/badi-push-notifications";
import UserFeedback from "base/shared/user-feedback";
import moment from "moment";

import { getOverviewPath } from "Sections/overview/routes";
import { getHomePath } from "Sections/Home/routes";

import { COOKIES } from "base/shared/StaticData";

export const shouldInitializePushNotifications = (
  currentPropsUserId,
  nextPropsUserId,
  currentPropsNotificationsFlag,
  nextPropsNotificationsFlag,
  arePushNotificationsSupported,
) => {
  if (
    (!currentPropsUserId && nextPropsUserId && nextPropsNotificationsFlag) ||
    (!currentPropsNotificationsFlag &&
      nextPropsNotificationsFlag &&
      nextPropsUserId)
  ) {
    badiPushNotifications.initialize(nextPropsUserId, locale(), {
      arePushNotificationsEnabled: nextPropsNotificationsFlag,
      arePushNotificationsSupported,
      forceSaveToken: true,
    });
  }
};

export const attempt = (condition, attempts, timeout, callback) => {
  if (condition()) {
    callback();
    return;
  }

  let i = 1;

  const repeat = setInterval(() => {
    if (condition()) callback();
    if (condition() || i >= attempts) clearInterval(repeat);
    i += 1;
  }, timeout);
};

export const authHasBeenUpdated = (prev, next) => {
  if (prev === next || next.fetching) return false;
  if (prev.access_token !== next.access_token) {
    return true;
  }
  return false;
};

export const getCurrentPage = (location) => {
  const currentLocale = locale();
  const url = getRelativePathNameWithoutLang(location.pathname, currentLocale);
  const urlParts = url.split("/").filter((u) => !!u);

  return urlParts.length ? urlParts[0] : "/";
};

export const onWindowScroll = () => {
  if (window.scrollY > 0) {
    document.body.classList.add("page_scrolled");
  } else {
    document.body.classList.remove("page_scrolled");
  }
};

export const setLanguageCookie = () => {
  const lang = locale();
  if (CookiesHandler.getCookie("badi_lang") !== lang) {
    const expires = moment().add(1, "year").toDate();
    CookiesHandler.setCookie("badi_lang", lang, expires);
  }
};

export const isNewUser = (prev, next) => !prev.new_user && next.new_user;

export const isLoaded = (prev, next) => prev.fetching && !next.fetching;

export const isSocketClosed = (socket) => !socket || socket.isClosed();

export const propHasBeenUpdated = (prev, next, prop) =>
  prev[prop] !== next[prop];

export const getLoggedUserMode = (mode, id) => {
  if (mode === "default" && !!id) {
    return "seeker";
  }
  return mode;
};

export const resetCookies = () => {
  CookiesHandler.removeCookie(COOKIES.ADVERTISED_MONEYBACK_ROOMS);
  CookiesHandler.removeCookie(COOKIES.ADVERTISED_MONEYBACK_OVERVIEW);
};

export const setAuthCookie = (auth) => {
  const {
    access_token: accessToken,
    creationTime,
    error,
    expirationTime,
    token_type: tokenType,
    user_id: userId,
    lister,
    new_user: newUser,
    refresh_token: refreshToken,
    mode: userMode,
    isRememberMeChecked,
  } = auth;

  if (error) {
    return;
  }

  if (accessToken) {
    CookiesHandler.setCookie(
      "t_app",
      [
        accessToken,
        tokenType,
        expirationTime,
        creationTime,
        userId,
        lister,
        userMode,
        newUser,
        refreshToken,
      ],
      isRememberMeChecked ? expirationTime : null,
    );
  } else {
    UserFeedback.exception(
      "Something went wrong",
      "App auth error message",
      error,
    );
  }
};

export const handleActiveSwitchUserModeRedirect = (newUserMode) => {
  let redirectUrl = null;

  if (newUserMode === "lister") {
    redirectUrl = getOverviewPath();
  } else {
    redirectUrl = getHomePath();
  }

  return redirectUrl;
};
