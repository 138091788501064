import React, { Suspense } from "react";

import { modal, MODAL_TYPE, MODAL_ID } from "containers/ReduxModal/emitter";
import UserFeedback from "base/shared/user-feedback";

export function closeVisitProposalModal() {
  modal.remove(MODAL_ID.PROPOSE_VISIT);
}

export function openVisitProposalModal({
  connectionId,
  modalId = "",
  onSuccess,
  roomId,
  seekerId,
  visitId,
}) {
  try {
    const VisitProposal = React.lazy(() => import("./visit-proposal-mapping"));
    const renderedComponent = (
      <Suspense fallback={<div />}>
        <VisitProposal
          connectionId={connectionId}
          onClose={closeVisitProposalModal}
          onSuccess={onSuccess}
          roomId={roomId}
          seekerId={seekerId}
          visitId={visitId}
        />
      </Suspense>
    );
    const modalOptions = {
      id: MODAL_ID.PROPOSE_VISIT,
      type: MODAL_TYPE.LATERAL,
    };

    if (modalId) {
      modal.update(modalId, renderedComponent, modalOptions);
    } else {
      modal.add(renderedComponent, modalOptions);
    }
  } catch (e) {
    UserFeedback.exception("Something went wrong", e.message, e);
  }
}
