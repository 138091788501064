import React, { Fragment, memo } from "react";

import {
  Spacer,
  Flex,
  Text,
  colors,
  TextButton,
  Icon,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Calendar from "components/Icons/Calendar";

import BookingRequestValueSection from "./components/booking-request-value-section";
import { BlockWrapper, TextRightAligned } from "./styled-components";

const BookingRequestSummary = ({
  price,
  room,
  moveIn,
  moveOut = "",
  userRole,
  handleModifyClick,
  lengthInMonths,
  isFullInfoBr = true,
  ...rest
}) => {
  return (
    <Fragment>
      <BlockWrapper>
        <Spacer bottom={4} top={4}>
          <Flex alignItems="center" justify="spaceBetween">
            <div data-qa="booking-move-in-section">
              <Text body={3}>{translate("booking.preview.movein_label")}</Text>
              <Text body={2}>{moveIn}</Text>
            </div>
            <Calendar color={colors.midGrey} />
            <TextRightAligned data-qa="booking-move-out-section">
              <Text body={3}>{translate("booking.preview.moveout_label")}</Text>
              <Text body={2}>
                {moveOut || translate("booking.preview.moveout_undefined")}
              </Text>
            </TextRightAligned>
          </Flex>
          {handleModifyClick && isFullInfoBr && (
            <Spacer top={2}>
              <TextButton
                data-qa="booking-modify-button"
                onClick={handleModifyClick}
                withIcon={
                  <Icon
                    alt={translate("booking.request.received.button.modify")}
                    color={colors.green}
                    name="edit"
                  />
                }
              >
                {translate("booking.request.received.button.modify")}
              </TextButton>
            </Spacer>
          )}
        </Spacer>
      </BlockWrapper>
      {isFullInfoBr && (
        <BookingRequestValueSection
          {...{
            price,
            room,
            userRole,
            lengthInMonths,
            ...rest,
          }}
          deposit={room.deposit}
        />
      )}
    </Fragment>
  );
};

export default memo(BookingRequestSummary);
