import React from "react";

import { Button, Flex, Heading, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { BookingLeaveRoot } from "./styled-components";

const BookingLeave = ({ onCancel, onLeave }) => {
  return (
    <BookingLeaveRoot>
      <Spacer bottom={1} left={0.5} right={0.5} top={1}>
        <Flex direction="column">
          <Heading data-qa="booking-leave-modal-title" level={2}>
            {translate("booking.leave_confirmation.title")}
          </Heading>
          <Spacer bottom={4} top={2}>
            <Text data-qa="booking-leave-modal-description">
              {translate("booking.leave_confirmation.text")}
            </Text>
          </Spacer>
          <Flex
            direction="column"
            gutter={2}
            md={{ direction: "row", gutter: 2 }}
          >
            <Button
              basis="0"
              data-qa="booking-leave-modal-cancel-button"
              fullWidth={true}
              grow={1}
              onClick={onCancel}
              variant="secondary"
            >
              {translate("booking.leave_confirmation.actions.cancel")}
            </Button>
            <Button
              basis="0"
              data-qa="booking-leave-modal-button"
              fullWidth={true}
              grow={1}
              onClick={onLeave}
            >
              {translate("booking.leave_confirmation.actions.leave")}
            </Button>
          </Flex>
        </Flex>
      </Spacer>
    </BookingLeaveRoot>
  );
};

export default BookingLeave;
