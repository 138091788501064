import styled, { css } from "styled-components";

import { getBodyFont } from "../font-sizes";

export const StyledText = styled.p`
  margin: ${(props) => props.margin || "0"};
  color: ${(props) => props.textColor};
  text-align: ${(props) => props.align};
  white-space: ${(props) => (props.noWrap ? "nowrap" : "normal")};
  ${(props) => getBodyFont(props.body)};
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;
