import React, { useEffect, useState, useMemo } from "react";
import { Container } from "reactstrap";
import { BREAKPOINTS_PX, Spacer, useMatchMedia } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getVisitRequest } from "datamodel/visits/api";
import { openVisitFeedbackModal } from "Sections/visits/visit-feedback";
import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import RoomPreview, { openRoomPreviewModal } from "components/room-preview";
import { getVisitScheduleStatus } from "./utils";
import { ConnectionFailed, Finished, NotStarted, TimedOut } from "./components";
import { VISIT_STATUS } from "./constants";

const VisitExpress = ({ id, onLeaveVisit, token, userId }) => {
  const [visit, setVisit] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.lg})`]);
  const [status, setStatus] = useState(VISIT_STATUS.IDLE);

  const roomDetails = useMemo(() => {
    if (!visit) {
      return null;
    }

    return {
      offset: 3,
      room: visit.room,
      token,
      user: { ...otherUser, avatar: otherUser.picture },
    };
  }, [otherUser, visit]);

  const leaveVisit = () => {
    onLeaveVisit();
  };

  const leaveFeedback = () => {
    openVisitFeedbackModal({ visitId: visit.id });
    onLeaveVisit();
  };

  const viewDetailsHandler = () => {
    openRoomPreviewModal(roomDetails);
  };

  const finishVisitHandler = (failure) => {
    setStatus(failure ? VISIT_STATUS.FAILED : VISIT_STATUS.FINISHED);
  };

  useEffect(() => {
    getVisitRequest({ token }, { id }).then((data) => {
      const { expressInfo, lister, localEndAt, localStartAt, seeker } = data;

      if (!expressInfo) {
        setStatus(getVisitScheduleStatus(localStartAt, localEndAt));
      }

      setCurrentUser(userId === seeker.id ? seeker : lister);
      setOtherUser(userId !== seeker.id ? seeker : lister);
      setVisit(data);
    });
  }, []);

  const getContent = () => {
    switch (true) {
      case !visit:
        return null;
      case status === VISIT_STATUS.FINISHED:
        return (
          <Finished
            compact={!isDesktop}
            onLeaveFeedback={leaveFeedback}
            onLeaveVisit={leaveVisit}
            roomDetails={roomDetails}
            userImage={currentUser.picture}
          />
        );
      case status === VISIT_STATUS.FAILED:
        return (
          <ConnectionFailed
            compact={!isDesktop}
            roomDetails={roomDetails}
            userImage={currentUser.picture}
          />
        );
      case status === VISIT_STATUS.TIMED_OUT:
        return (
          <TimedOut
            compact={!isDesktop}
            date={visit.displayDate}
            onLeaveFeedback={leaveFeedback}
            onLeaveVisit={leaveVisit}
            roomDetails={roomDetails}
            time={visit.displayTime}
            userImage={currentUser.picture}
            userName={otherUser.name}
          />
        );
      case status === VISIT_STATUS.NOT_STARTED:
        return (
          <NotStarted
            compact={!isDesktop}
            date={visit.displayDate}
            roomDetails={roomDetails}
            time={visit.displayTime}
            userImage={currentUser.picture}
          />
        );
      default:
        return null;
    }
  };

  return (
    <LayoutWrapper
      footer={isDesktop ? <Footer campaign="visit_express_footer" /> : null}
    >
      <Spacer md={{ top: 8 }} top={4} />
      <Container>{getContent()}</Container>
    </LayoutWrapper>
  );
};

export default VisitExpress;
