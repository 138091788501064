import { connect } from "react-redux";
import { isSepaDebitDetailsSelector } from "datamodel/Features/selectors";

import SideBarMenu from "./side-bar-menu";

const mapStateToProps = (state) => ({
  isSepaShown: isSepaDebitDetailsSelector(state),
});

export default connect(mapStateToProps)(SideBarMenu);
