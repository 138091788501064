export const BED_TYPE = {
  translationKey: "room.list.step3.bedType",
  items: [
    { id: 2, name: "single" },
    { id: 3, name: "double" },
    { id: 1, name: "sofa" },
    { id: 4, name: "no" },
  ],
};

export const STAY_OPTIONS = {
  translationKey: "search.availability.options",
  items: [
    {
      id: 1,
      name: "short",
      apivalue: "short",
      label: "short",
      description: true,
    },
    {
      id: 2,
      name: "medium",
      apivalue: "medium",
      label: "medium",
      description: true,
    },
    {
      id: 3,
      name: "long",
      apivalue: "long",
      label: "long",
      description: true,
    },
  ],
};

export const GENDERS = {
  translationKey: "search.filters.flatmates.gender",
  items: [
    { id: 1, name: "females", apivalue: "female" },
    { id: 2, name: "males", apivalue: "male" },
    { id: 3, name: "non_binary", apivalue: "non_binary" },
  ],
};

export const PLACE_TYPE = {
  translationKey: "room.list.step2.property",
  items: [
    { id: 1, name: "private" },
    { id: 2, name: "entire" },
    { id: 3, name: "shared" },
  ],
};

export const FLAT_AMENITIES = {
  translationKey: "room.amenities",
  items: [
    { id: 2, name: "wifi", icon: "Wifi" },
    { id: 1, name: "tv", icon: "Tv" },
    { id: 7, name: "elevator", icon: "Elevator" },
    { id: 8, name: "heating", icon: "Heating" },
    { id: 3, name: "airConditioning", icon: "AirConditioning" },
    { id: 17, name: "balcony", icon: "Balcony" },
    { id: 22, name: "terrace", icon: "Terrace" },
    { id: 21, name: "garden", icon: "Garden" },
    { id: 9, name: "washingMachine", icon: "WashingMachine" },
    { id: 19, name: "dishwasher", icon: "Dishwasher" },
    { id: 10, name: "dryer", icon: "Dryer" },
    { id: 4, name: "parking", icon: "Parking" },
    { id: 15, name: "pool", icon: "Pool" },
    { id: 12, name: "doorman", icon: "Doorman" },
    { id: 20, name: "wheelchairFriendly", icon: "WheelchairFriendly" },
  ],
};

export const HOUSE_AMENITIES = {
  translationKey: "room.amenities",
  items: [
    { id: 5, name: "smoker", icon: "Smoker" },
    { id: 6, name: "pet", icon: "Pet" },
    { id: 13, name: "couples", icon: "Couples" },
  ],
};

export const ROOM_AMENITIES = {
  translationKey: "room.amenities",
  items: [
    { id: 14, name: "furnishedRoom", icon: "FurnishedRoom" },
    { id: 18, name: "naturalLight", icon: "NaturalLight" },
    { id: 16, name: "privateBathroom", icon: "PrivateBathroom" },
  ],
};

export const FLAT_BENEFITS = {
  translationKey: "filter.services",
  items: [
    { id: 1, value: "rental_contract", name: "rentalContract" },
    { id: 2, value: "cleaning_services", name: "cleaningServices" },
    { id: 3, value: "maintenance", name: "maintenance" },
    { id: 4, value: "registration_available", name: "registrationAvailable" },
  ],
};

export const SORT_BY = {
  translationKey: "search.filters.sortBy",
  items: [
    { id: 4, name: "relevance", query: "relevance", value: "relevance" },
    { id: 1, name: "recent", query: "recent", value: "recent" },
    {
      id: 2,
      name: "priceAsc",
      query: "cheapest",
      value: "priceAsc",
    },
    {
      id: 3,
      name: "priceDesc",
      query: "more_expensive",
      value: "priceDesc",
    },
  ],
};

export const DEFAULT_SORT_BY_VALUE = SORT_BY.items[0];

export const DEFAULT_FILTER_VALUE = {
  value: null,
  cluster: 0,
};

export const SEARCH_VIEW = {
  list: "list",
  map: "map",
  form: "form",
  both: "both",
};

export const MARKETPLACE_SEGMENTS = {
  badiCommunity: false,
  badiRooms: false,
};
