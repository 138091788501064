import styled, { css } from "styled-components";
import { colors, mediaquery, unitizedPx } from "@badi/badi-components";
import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from "./constants";

export const GhostOffset = styled.div`
  display: block;
  height: ${HEADER_MOBILE_HEIGHT}px;

  ${mediaquery.lg`
    height: ${HEADER_HEIGHT}px;
  `}
`;

export const Root = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;

  background: ${colors.white};

  ${(props) =>
    props.isFixed &&
    css`
      position: fixed;
    `}
`;

export const Header = styled.header`
  height: ${HEADER_MOBILE_HEIGHT}px;
  position: relative;
  border-bottom: 1px solid ${colors.lightGrey};

  ${mediaquery.lg`
    height: ${HEADER_HEIGHT}px;
  `}
`;

export const Navigation = styled.nav`
  height: 100%;
  padding: 0 ${unitizedPx(3)};
`;

export const NavigationList = styled.ul`
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: 0;
  padding: 0;
  list-style: none;
`;
