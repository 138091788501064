import React from "react";

import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";

import CountryVat from "./country-vat-mapping";

export const closeCountryVatModal = () => {
  modal.remove(MODAL_ID.COUNTRY_VAT);
};

const handleCloseModal = (onClose) => {
  closeCountryVatModal();
  onClose();
};

export const openCountryVatModal = ({ onClose }) => {
  modal.add(<CountryVat callback={() => handleCloseModal(onClose)} />, {
    id: MODAL_ID.COUNTRY_VAT,
    closable: false,
    closeOnOutsideClick: false,
    type: MODAL_TYPE.DEFAULT,
  });
};
