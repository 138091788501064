import styled from "styled-components";

export const DateWrapper = styled.div`
  overflow-y: auto;
`;

export const CapitalizeWrapper = styled.div`
  text-transform: capitalize;
`;

export const ImageWrapper = styled.div`
  text-align: center;
`;
