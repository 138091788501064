import React, { memo, useState, useEffect } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { OCCUPATIONS } from "base/shared/StaticData";

import FormBlock from "components/form-block";
import Choice from "components/Form/Choice/ChoiceWithFormsy";

const Occupation = ({ occupation = 0, isValid, saved }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(translate(OCCUPATIONS));
  }, []);

  return (
    <>
      <FormBlock
        dataQa="profile-form-occupation-section"
        id="formblock-occupation"
        isRequired={true}
        saved={saved}
        title={translate("profile.form.label.occupation")}
        valid={isValid}
      >
        <Choice
          className="centered-flex-row"
          items={options}
          multi={false}
          name="occupation"
          required={true}
          validations={{
            minValue: 1,
          }}
          value={occupation}
        />
      </FormBlock>
      <Spacer bottom={2} />
    </>
  );
};

export default memo(Occupation);
