import React from "react";
import { Flex, Text, BADI_COLORS } from "app/badi-components";
import { Spacer } from "@badi/badi-components";
import InputIcon from "./input-icon";
import { Root, MainWrapper } from "./styled-components";

const Input = ({
  disabled = false,
  hasError,
  type = "text",
  name,
  onBlur = null,
  onChange = null,
  onFocus = null,
  placeholder = "",
  iconSrc = "",
  value = "",
  label = "",
  required = false,
  dataQa = "",
  helper = "",
  maxLength = null,
}) => {
  return (
    <MainWrapper as={Flex} direction="column" disabled={disabled}>
      <Spacer bottom={1}>
        <Text
          body="12-Medium"
          color={
            disabled
              ? BADI_COLORS.NEUTRAL.NEUTRAL_10
              : BADI_COLORS.NEUTRAL.NEUTRAL_80
          }
          dataQa={`${dataQa}-label`}
        >
          {label}
        </Text>
      </Spacer>
      <Flex alignItems="center">
        <Root
          basis="100%"
          data-qa={dataQa}
          hasError={hasError}
          maxLength={maxLength || undefined}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          required={required}
          shrink="0"
          type={type}
          value={value}
        />
        <InputIcon hasError={hasError} iconSrc={iconSrc} />
      </Flex>
      <Spacer top={helper ? 1 : 3}>
        <Text
          align="right"
          body="12-Regular"
          color={
            hasError
              ? BADI_COLORS.SEMANTIC.DANGER.DEFAULT
              : BADI_COLORS.NEUTRAL.NEUTRAL_50
          }
          dataQa={`${dataQa}-helper`}
        >
          {helper}
        </Text>
      </Spacer>
    </MainWrapper>
  );
};

export default Input;
