import moment from "moment";

const getMonths = (year, short = false) => {
  if (!year) return [];

  const months = short ? moment.monthsShort() : moment.months();
  return months.map((name, i) => ({ title: name, value: (i + 1).toString() }));
};

const getRangeOptions = (min, max) => {
  const options = [];

  for (let i = min; i <= max; i += 1) {
    const value = i.toString();
    options.push({ title: value, value });
  }

  return options;
};

const parseDate = (value) => ({
  day: value ? value.date().toString() : "",
  daysInMonth: value ? value.daysInMonth() : 0,
  month: value ? (value.month() + 1).toString() : "",
  year: value ? value.year().toString() : "",
});

const updateDateFromValue = (currentDate, { name, value }) => {
  const newDate = {
    day: currentDate ? currentDate.date() : null,
    month: currentDate ? currentDate.month() + 1 : null,
    year: currentDate ? currentDate.year() : null,
  };
  newDate[name] = value;

  const tempValue = moment(
    `${newDate.year}-${newDate.month}-${newDate.day}`,
    "YYYY-MM-DD",
  );
  const daysInMonth = tempValue.daysInMonth();
  newDate.day = !daysInMonth || newDate.day > daysInMonth ? null : newDate.day;

  return moment(
    `${newDate.year}-${newDate.month}-${newDate.day}`,
    "YYYY-MM-DD",
  );
};

export { getMonths, getRangeOptions, parseDate, updateDateFromValue };
