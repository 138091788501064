import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchInbox, getInboxRoomsSummary } from "./api";
import InboxRoomModel from "./models/inbox-rooms-model";

export default {
  getInbox(params) {
    return {
      types: generateFetchTypes(Types.INBOX_REQUEST),
      request: fetchInbox,
      requireAuth: true,
      params,
    };
  },
  getInboxRoomsSummary(params) {
    return {
      types: generateFetchTypes(Types.INBOX_ROOMS_SUMMARY_REQUEST),
      request: getInboxRoomsSummary,
      requireAuth: true,
      params,
    };
  },
  getUnreadConnections(params) {
    return {
      types: generateFetchTypes(Types.INBOX_UNREAD_CONNECTIONS_REQUEST),
      request: fetchInbox,
      requireAuth: true,
      params: {
        ...params,
        per: 1,
      },
    };
  },
  updateInboxConnection(connection) {
    return {
      type: Types.INBOX_CONNETION_UPDATE,
      connection,
    };
  },
  setInboxCategory(category) {
    return {
      type: Types.INBOX_CATEGORY_SET,
      payload: category,
    };
  },
  setInboxRoom(room) {
    return {
      type: Types.INBOX_ROOM_SET,
      payload: room,
    };
  },
  cleanInboxRoom() {
    return {
      type: Types.INBOX_ROOM_CLEAN,
      payload: new InboxRoomModel().toJSON(),
    };
  },
  setConnectionsPerPage(perPage) {
    return {
      type: Types.INBOX_CONNTECTIONS_PER_PAGE_SET,
      payload: perPage,
    };
  },
  setNotificationsCount(data) {
    return {
      type: Types.INBOX_NOTIFICATIONS_COUNT,
      payload: { count: data.badge_count },
    };
  },
  clearInboxConnections() {
    return {
      type: Types.INBOX_CLEAR_CONNECTIONS,
    };
  },
};
