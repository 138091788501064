import React from "react";
import { translate } from "base/shared/Localization";
import classNames from "classnames";

import UserLanguages from "components/UserLanguages";
import TagList from "components/Form/Tags/TagList";
import Link from "components/Button/Link";
import ProgressBar from "components/ProgressBar";
import { getLocalePath } from "base/shared/Utils";

import {
  getProfilePercentage,
  hasSelectedTags,
  parseTags,
} from "datamodel/User/utils";

import "../UserExt.css";

export const Languages = ({ visible, user }) =>
  visible.languages &&
  user.languages && (
    <UserLanguages
      className="UserCard__block UserCard__block_languages"
      languages={user.languages}
      title="user.section.languages"
    />
  );

export const Tags = ({ visible, user }) => {
  const tags = parseTags(user.tags);
  const userTagsVisible = hasSelectedTags(user.tags);
  return (
    visible.tags &&
    userTagsVisible && (
      <div className="UserCard__block UserCard__block_tags">
        <h3>{translate("user.section.tags")}</h3>
        <TagList tags={tags} value={user.tags} />
      </div>
    )
  );
};

export const UserId = ({ visible, user }) =>
  visible.id && (
    <div className="UserCard__block">
      <h3>{translate("user.section.id")}</h3>
      <p>{user.id}</p>
    </div>
  );

export const EditBlock = ({ visible, onClickEditBlock }) =>
  visible.edit && (
    <div className="UserCard__block UserCard__block_edit">
      <Link
        light={false}
        onClick={onClickEditBlock}
        text={translate("user.section.edit")}
        to={`${getLocalePath()}/users/edit`}
      />
    </div>
  );

export const Block = ({ children }) => (
  <div className={"UserCard__block basic"}>{children}</div>
);

export const Header = ({ title, visible, user }) => (
  <div>
    {title && <h2>{title}</h2>}
    {visible.percentage && (
      <div className="UserCard__block">
        <ProgressBar colors={true} percentage={getProfilePercentage(user)} />
      </div>
    )}
  </div>
);

export const UserWrapper = ({ format, children }) => (
  <section className={classNames(`UserCard ${format}`)}>
    <div className="UserCard__wrapper">{children}</div>
  </section>
);

export const PageRow = ({ children }) => (
  <div className="UserCard_row">{children}</div>
);
