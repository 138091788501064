import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "ME_REQUEST",
  "ME_SUCCESS",
  "ME_ERROR",
  "LEGAL_REQUEST",
  "LEGAL_SUCCESS",
  "LEGAL_ERROR",
  "ONBOARDING_REQUEST",
  "ONBOARDING_SUCCESS",
  "ONBOARDING_ERROR",
  "ONBOARDING_UPDATE_REQUEST",
  "ONBOARDING_UPDATE_SUCCESS",
  "ONBOARDING_UPDATE_ERROR",
  "LEGALUPDATE_REQUEST",
  "LEGALUPDATE_SUCCESS",
  "LEGALUPDATE_ERROR",
  "RESET_ME",
  "SET_CONFIRMATION",
  "SET_LISTER",
  "SET_USERMODE",
  "SAVE_PROFILE_REQUEST",
  "SAVE_PROFILE_SUCCESS",
  "SAVE_PROFILE_ERROR",
  "UPDATE_PROFILE_REQUEST",
  "UPDATE_PROFILE_SUCCESS",
  "UPDATE_PROFILE_ERROR",
  "LISTER_SCORE_REQUEST",
  "LISTER_SCORE_ERROR",
  "LISTER_SCORE_SUCCESS",
]);
