import React from "react";
import { BADI_COLORS } from "@badi/badi-components";
import { Modal } from "./styled-components";
import LayoutModal from "../Layout";

const OnboardingModal = ({
  backgroundColor = BADI_COLORS.NEUTRAL.NEUTRAL_00,
  children = null,
  closable = false,
  isClosed = false,
  outsideClickHandler = () => {},
  padding = null,
}) => {
  return (
    <LayoutModal
      backgroundColor={backgroundColor}
      isClosed={isClosed}
      onIconClose={closable ? outsideClickHandler : null}
      outsideClickHandler={outsideClickHandler}
      wrapper={Modal}
      padding={padding}
    >
      {children}
    </LayoutModal>
  );
};

export default OnboardingModal;
