import React, { useState, useEffect } from "react";

import envVars from "base/shared/Env";
import { locale, localeISO } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { loadFacebookSdk, openLogin } from "base/shared/FacebookSdk";

import { checkFacebookConfirmation } from "datamodel/User/api";
import AuthButton from "../button";

const FacebookButton = ({
  children,
  confirmation = false,
  cookie = true,
  login,
  onSuccess = () => {},
  scope = "public_profile,email",
  setConfirmation,
  token,
  version = "v3.3",
  xfbml = false,
}) => {
  const [loaded, setLoaded] = useState(false);

  const confirmFacebook = (accessToken, data) => {
    checkFacebookConfirmation(token, { facebook_token: accessToken })
      .then(() => {
        setConfirmation("facebook");
        onSuccess(data);
      })
      .catch((e) => {
        UserFeedback.exception("Something went wrong", e.message, e);
      });
  };

  const checkLoginState = (response) => {
    const data = {
      accessToken: response.authResponse
        ? response.authResponse.accessToken
        : null,
      status: response.status === "connected" ? "success" : response.status,
      provider: "facebook",
      error: response.error,
    };

    if (data.status === "success") {
      if (confirmation) {
        confirmFacebook(data.accessToken, data);
      } else {
        login({
          socialToken: data.accessToken,
          provider: "facebook",
          locale: locale(),
        });
      }
    } else {
      UserFeedback.exception("Something went wrong", "", data.error);
    }
  };

  const clickHandler = () => {
    openLogin(scope, checkLoginState);
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    loadFacebookSdk(
      {
        appId: envVars().BADI_AUTH_FACEBOOK_APP_ID,
        cookie,
        language: localeISO(),
        version,
        xfbml,
      },
      () => setLoaded(true),
    );
  }, []);

  return (
    <AuthButton
      dataQa="facebook-button"
      iconAlt="facebook button"
      iconSrc="/assets/icons/new-fb.svg"
      loaded={loaded}
      onClick={clickHandler}
    >
      {children}
    </AuthButton>
  );
};

export default FacebookButton;
