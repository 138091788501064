import styled, { css } from "styled-components";
import { mediaquery, BADI_COLORS, unitizedPx } from "@badi/badi-components";

const activeStyles = css`
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

  &:after {
    content: "";
    position: relative;
    top: ${unitizedPx(1)};
    display: block;
    width: ${unitizedPx(4)};
    height: 3px;
    border-radius: 1.2px;
    background-image: linear-gradient(to right, #08c781, #00bfb3);
  }
`;

const edgeShadow = css`
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 85%
  );
  height: 100%;
  width: 20px;
  top: 0;
  pointer-events: none;
`;

export const Root = styled.div`
  border-bottom: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  right: 30px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  transition: opacity 0.2s ease;
  position: relative;

  &:after {
    ${edgeShadow}
    transform: rotate(180deg);
    right: 0;
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: .6;
  `}
`;

export const Wrap = styled.ul`
  margin: 0;
  padding-left: 0;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.li`
  display: inline-block;
  list-style: none;
  padding: 0 ${unitizedPx(1.5)};
  margin-bottom: ${unitizedPx(1)};
  cursor: pointer;
  text-transform: uppercase;

  ${mediaquery.md`
    padding: 0 ${unitizedPx(4)} ;
  `}

  &:first-child {
    padding-left: 0;
  }

  ${(props) => props.isActive && activeStyles};
`;
