import styled, { css } from "styled-components";
import { mediaquery, unitizedPx, BREAKPOINTS_PX } from "@badi/badi-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.backgroundColor}};
  width: auto;
  height: auto;
  max-height: ${({ isSafari }) =>
    isSafari ? "fill-available" : "calc(100vh - 40px)"};
  overflow-y: scroll;
  max-width: calc(100% - 40px);
  margin: 0 auto;

  border-radius: ${unitizedPx(0.5)};
  transform: scale(0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(1);
      opacity: 1;
    `}

  ${({ isClosed }) =>
    isClosed &&
    css`
      transform: scale(0);
      opacity: 0;
    `}

  ${mediaquery.md`  
    max-width: ${unitizedPx(71)};
  `}

  @media screen and (max-width: ${BREAKPOINTS_PX.sm}) {
    width: 100%;
    height: 100%;
    max-width: unset;  
  }
`;
