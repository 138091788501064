import React, { Children, Component } from "react";

import { Col, Container, Row } from "reactstrap";
import { Spacer, Text, BADI_COLORS } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import LayoutWrapper from "components/Layout/Wrapper";

import TopBar from "./top-bar";
import { FooterRoot } from "./styled-components";

class Stepper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: props.initialStep || 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // check if the next children count is less than the current step to avoid overflow
    const childrenCount = Children.count(nextProps.children);
    if (childrenCount - 1 < prevState.currentStep) {
      return {
        currentStep: 0,
      };
    }

    return null;
  }

  getCurrentChild = () => {
    const { children } = this.props;
    const { currentStep } = this.state;

    const currentChild = Children.toArray(children).find(
      (_, index) => index === currentStep,
    );

    if (currentChild.props.children) {
      // cloning the children to pass new props
      return this.addChildParameters(currentChild);
    }
    return currentChild;
  };

  addChildParameters = (currentChild) => {
    const child = currentChild.props.children;

    const newChildren = React.cloneElement(child, {
      nextStep: this.nextStep,
      previousStep: this.previousStep,
    });

    return React.cloneElement(currentChild, { children: newChildren });
  };

  handleClose = () => {
    const currentChild = this.getCurrentChild();

    this.props.onClose(currentChild ? currentChild.props.id : "");
  };

  nextStep = () => {
    const { children } = this.props;
    const { currentStep } = this.state;
    if (currentStep >= Children.count(children)) return null;

    return this.setState((state) => ({
      currentStep: state.currentStep + 1,
    }));
  };

  previousStep = () => {
    const { currentStep } = this.state;
    if (currentStep === 0) return null;
    return this.setState((state) => ({
      currentStep: state.currentStep - 1,
    }));
  };

  render() {
    const {
      children,
      customLayout,
      onSubmit,
      onDecline,
      disabled,
      onClose,
      showProgress,
    } = this.props;
    const { currentStep } = this.state;

    if (Children.count(children) <= 0) return null;

    const currentChild = this.getCurrentChild();

    const Footer = (currentChild && currentChild.props.footer) || null;

    const currentStepNumber = currentStep + 1;
    const stepsTotal = Children.count(children);
    const percentage = Math.round((currentStepNumber / stepsTotal) * 100);

    const { props: childProps = {} } = currentChild;
    const { valid: stepIsValid, ...stepsProps } = childProps;

    const footerComponent = (
      <FooterRoot>
        <Container>
          <Spacer bottom={1} top={1}>
            {Footer && (
              <Footer
                currentStep={currentStepNumber}
                disabled={disabled}
                isFirstStep={currentStep === 0}
                isLastStep={currentStep === stepsTotal - 1}
                nextStep={this.nextStep}
                onDecline={onDecline}
                onSubmit={onSubmit}
                previousStep={this.previousStep}
                stepIsValid={!!stepIsValid}
                totalSteps={stepsTotal}
                {...stepsProps}
              />
            )}
          </Spacer>
        </Container>
      </FooterRoot>
    );

    return (
      <LayoutWrapper
        topBar={
          <TopBar
            onClose={onClose ? this.handleClose : null}
            percentage={percentage}
            showProgress={showProgress}
          />
        }
      >
        {customLayout ? (
          <>
            {currentChild}
            {footerComponent}
          </>
        ) : (
          <Spacer md={{ top: 8 }} top={2}>
            <Container>
              <Row>
                <Col sm={8}>
                  <Text
                    body={3}
                    color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
                    data-qa="stepper-heading"
                  >
                    {stepsTotal > 1 &&
                      translate("stepper-layout.step-indicator", {
                        currentStepNumber,
                        stepsTotal,
                      })}
                  </Text>
                </Col>
              </Row>
              {currentChild}
              {footerComponent}
            </Container>
          </Spacer>
        )}
      </LayoutWrapper>
    );
  }
}

Stepper.defaultProps = {
  children: null,
  customLayout: false,
  disabled: false,
  initialStep: 0,
  onClose: null,
  onDecline: () => {},
  onSubmit: () => {},
  showProgress: true,
};

export default Stepper;
