import styled from "styled-components";
import { colors } from "@badi/badi-components";
import { HEADER_HEIGHT } from "components/Layout/TopBar/components/top-bar-base/constants";

export const LayoutWrapper = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  flex: 1 0 auto;
  align-self: flex-start;
`;

export const LayoutBody = styled.div`
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  background-color: ${colors.white};
`;

export const LayoutContent = styled.div`
  position: relative;
`;
