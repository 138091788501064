import { connect } from "react-redux";

import RecommendationActions from "datamodel/Recommendations/actions";
import ViewersActions from "datamodel/viewers/actions";

import { getToken } from "datamodel/Auth/selectors";
import { getMessageEnquiry } from "datamodel/Conversation/selectors";

import RoomInvite from "./room-invite";

const mapStateToProps = (state) => ({
  token: getToken(state),
  messageEnquiry: getMessageEnquiry(state),
});

const mapDispatchToProps = {
  acceptRecommendation: RecommendationActions.acceptRecommendation,
  contactViewer: ViewersActions.acceptViewer,
};

const mergeProps = (stateProps, dispatch, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    sendInvite: ({ roomId, message, userId }) =>
      dispatch.acceptRecommendation(
        {
          roomId,
          userId,
          message,
        },
        stateProps.token,
      ),
    sendViewerInvite: ({ roomId, message, userId }) =>
      dispatch.contactViewer(
        {
          roomId,
          userId,
          message,
        },
        stateProps.token,
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(RoomInvite);
