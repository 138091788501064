import React from "react";
import Helmet from "react-helmet";
import { Col, Row } from "reactstrap";
import {
  Flex,
  Spacer,
  Heading,
  LinkButton,
  mediaquery,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { BADI_COLORS, Text } from "app/badi-components";
import { getPublicAsset, getRelativePath } from "base/shared/Utils";

import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import { Container } from "reactstrap";

import TopBar from "../components/top-bar";
import Header from "./components/header";
import Testimonial from "./components/testimonial";
import styled from "styled-components";
import { getBadiPlusPath } from "Sections/Home/routes";
import { UTM_PARAMS } from "../constants";

const Section = ({ title, description }) => (
  <Row>
    <Col>
      <Heading data-qa="landing-section-title" level="28-Extrabold">
        {title}
      </Heading>
      <Spacer top={2}>
        <Text dataQa="landing-section-desc">{description}</Text>
      </Spacer>
    </Col>
  </Row>
);

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 40px;

  ${mediaquery.md`
    flex-direction: row;
  `}
`;

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${BADI_COLORS.ADDITIONAL.ADDITIONAL_10};
  border-radius: 20px;
  justify-content: space-between;
  padding: 30px;
  width: 100%;

  ${mediaquery.md`
    width: 50%;
    `}
`;

const Panel = ({ translations, children }) => (
  <PanelWrapper>
    <Heading align="center" style={{ marginBottom: "10px" }}>
      {translate(translations.title)}
    </Heading>
    <Text>{translate(translations.subtitle)}</Text>
    <ul>
      {translations.lines.map((line, index) => (
        <li key={index}>{translate(line)}</li>
      ))}
    </ul>
    {children}
  </PanelWrapper>
);

const CtaLink = styled.div`
  text-align: center;
`;

const PANEL_TRANSLATIONS = {
  MARKETPLACE: {
    title: "rentRoom.sections.marketplace.title",
    subtitle: "rentRoom.sections.marketplace.subtitle",
    lines: [
      "rentRoom.sections.marketplace.1",
      "rentRoom.sections.marketplace.2",
      "rentRoom.sections.marketplace.3",
    ],
  },
  BADI_PLUS: {
    title: "rentRoom.sections.badi-plus.title",
    subtitle: "rentRoom.sections.badi-plus.subtitle",
    lines: [
      "rentRoom.sections.badi-plus.1",
      "rentRoom.sections.badi-plus.2",
      "rentRoom.sections.badi-plus.3",
    ],
  },
};

const InformationForListers = () => {
  const description = translate("listroomapartment.meta.description");
  const title = translate("listroomapartment.meta.title");

  return (
    <LayoutWrapper
      footer={<Footer campaign="rentroom_footer" />}
      padding={false}
      topBar={<TopBar />}
    >
      <Header />
      <Container>
        <Spacer top={8} />
        <Flex direction="column" gutter={8}>
          <Section
            description={translate("rentRoom.sections.why.subTitle")}
            title={translate("rentRoom.sections.why.title")}
          />
          <Section
            title={translate("rentRoom.sections.two-models.title")}
            description={translate("rentRoom.sections.two-models.description")}
          />
          <PanelContainer>
            <Panel translations={PANEL_TRANSLATIONS.MARKETPLACE}>
              <CtaLink>
                <LinkButton href={getRelativePath("/list/new")}>
                  {translate("rentRoom.sections.marketplace.cta")}
                </LinkButton>
              </CtaLink>
            </Panel>
            <Panel translations={PANEL_TRANSLATIONS.BADI_PLUS}>
              <CtaLink>
                <LinkButton
                  href={getBadiPlusPath(
                    translate("rentRoom.sections.badi-plus.cta-path"),
                    { query: UTM_PARAMS },
                  )}
                >
                  {translate("rentRoom.sections.badi-plus.cta")}
                </LinkButton>
              </CtaLink>
            </Panel>
          </PanelContainer>
          <Testimonial
            author={translate("rentRoom.testimonial.author")}
            imageSrc={getPublicAsset("images/landings/testimonial.jpg")}
            text={translate("rentRoom.testimonial.text")}
          />
        </Flex>
        <Spacer bottom={8} />
      </Container>
      <Helmet
        meta={[
          { property: "og:title", content: title },
          { name: "description", content: description },
          { property: "og:description", content: description },
        ]}
        title={title}
      />
    </LayoutWrapper>
  );
};

export default InformationForListers;
