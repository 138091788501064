import React from "react";
import { Col, Row } from "reactstrap";
import { translate } from "base/shared/Localization";
import { Heading, Spacer } from "@badi/badi-components";
import BehaviourCardWidget from "../widgets/behaviour-card-widget";
import {
  getResponseRateTrendContent,
  getResponseRateValueContent,
  getResponseRateTargetContent,
  getResponseTimeValueContent,
  getResponseTimeTrendContent,
  getResponseTimeTargetContent,
} from "../../utils";

const ResponseSection = ({ responseRate = null, responseTime = null }) => (
  <Spacer top={3}>
    <Heading align="left" data-qa="reactivity-section-title" level="24-Bold">
      {translate("lister.analytics_subtitle_response")}
    </Heading>
    <Row className="align-items-end justify-content-center">
      {responseTime && (
        <Col lg={6} xs={12}>
          <BehaviourCardWidget
            dataQa="response-time-widget"
            emptyText={translate("lister.analytics_response_time_empty_text", {
              days: responseTime.daysConsidered,
            })}
            height={23}
            subTitle={translate("lister.analytics_response_time_text", {
              days: responseTime.daysConsidered,
            })}
            target={getResponseTimeTargetContent(responseTime.value)}
            title={translate("lister.analytics_response_time_title")}
            trend={getResponseTimeTrendContent(
              responseTime.trend,
              responseTime.previousValue,
            )}
            value={getResponseTimeValueContent(responseTime.value)}
          />
        </Col>
      )}
      {responseRate && (
        <Col lg={6} xs={12}>
          <BehaviourCardWidget
            dataQa="response-rate-widget"
            emptyText={translate("lister.analytics_response_rate_empty_text", {
              days: responseRate.daysConsidered,
            })}
            height={23}
            subTitle={translate("lister.analytics_response_rate_text", {
              days: responseRate.daysConsidered,
            })}
            target={getResponseRateTargetContent(responseRate.value)}
            title={translate("lister.analytics_response_rate_title")}
            trend={getResponseRateTrendContent(responseRate.trend)}
            value={getResponseRateValueContent(responseRate.value)}
          />
        </Col>
      )}
    </Row>
  </Spacer>
);

export default ResponseSection;
