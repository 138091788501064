import React, { useState, useEffect } from "react";
import envVars from "base/shared/Env";
import { localeISO } from "base/shared/Localization";
import {
  loadFacebookSdk,
  sendFacebookMessage,
  sendMessengerMessage,
} from "base/shared/FacebookSdk";
import { LinkWrapper } from "../../styled-components";

const MessengerShareButton = ({
  children = null,
  disabled = false,
  isDesktop = true,
  onClick = () => {},
  url,
}) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);

  const handleOnClick = () => {
    if (!sdkLoaded || disabled) return;

    if (isDesktop) {
      sendFacebookMessage(url);
    } else {
      sendMessengerMessage(url);
    }

    onClick();
  };

  useEffect(() => {
    loadFacebookSdk(
      {
        appId: envVars().BADI_AUTH_FACEBOOK_APP_ID,
        language: localeISO(),
      },
      () => setSdkLoaded(true),
    );
  }, []);

  return (
    <LinkWrapper
      disabled={disabled}
      onClick={handleOnClick}
      role="link"
      tabIndex="-1"
    >
      {children}
    </LinkWrapper>
  );
};

export default MessengerShareButton;
