import React from "react";
import { Spacer } from "@badi/badi-components";
import InviteLink from "../invite-link";
import SharingOptions from "../sharing-options";

const HeaderContent = ({ inviteUrl }) => {
  return (
    <>
      <InviteLink url={inviteUrl} />
      <Spacer bottom={2} />
      <SharingOptions url={inviteUrl} />
    </>
  );
};

export default HeaderContent;
