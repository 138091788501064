import React from "react";

const Notification = ({ payload, ...props }) => {
  return (
    <div style={{ backgroundColor: "#f0f0f0" }} data-testid="notification">
      <p>NotificationComponent prop is missing!</p>
      <span>Notification event props:</span>
      {Object.entries(props).map(([key, value]) => (
        <p key={key} style={{ fontSize: "0.7rem", marginBottom: 0 }}>
          <b>{key}:</b>
          {value}
        </p>
      ))}
      <br />
      <span>Custom props received:</span>
      {Object.entries(payload).map(([key, value]) => (
        <p key={key} style={{ fontSize: "0.7rem", marginBottom: 0 }}>
          <b>{key}:</b>
          {value}
        </p>
      ))}
    </div>
  );
};
export default Notification;
