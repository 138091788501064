import React from "react";
import { Flex, Heading, Spacer, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import Image from "components/Image/Image";

const EmptyInbox = () => (
  <Flex
    alignItems="center"
    className="Inbox--Empty"
    direction="column"
    justify="center"
  >
    <Spacer bottom={4} top={19}>
      <Image
        alt={translate("inbox.empty.title")}
        src="/assets/images/inbox-empty.svg"
      />
    </Spacer>
    <Heading level={3}>{translate("inbox.empty.title")}</Heading>
    <Spacer bottom={19} top={1}>
      <Text>{translate("inbox.empty.description")}</Text>
    </Spacer>
  </Flex>
);

export default EmptyInbox;
