import { translate } from "base/shared/Localization";
import { getTranslatedData } from "components/Layout/Landing/utils";
import { COMMUNICATION, FAQS, SAFETY, TRUST } from "./static";

export const getTrust = () => getTranslatedData(TRUST);

export const getSafety = () => getTranslatedData(SAFETY);

export const getCommunication = () => getTranslatedData(COMMUNICATION);

export const getFaqs = () => {
  const faqs = getTranslatedData(FAQS);

  faqs.buttonText = translate(`${FAQS.translationkey}.all.text`);
  faqs.buttonLink = translate(`${FAQS.translationkey}.all.link`);

  return faqs;
};
