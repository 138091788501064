import React from "react";
import { modal, MODAL_TYPE, MODAL_ID } from "containers/ReduxModal/emitter";
import { browserHistory } from "react-router";
import { getLocalePath } from "base/shared/Utils";
import CompleteProfile from "./complete-profile";
import { COMPLETE_PROFILE_FROM } from "./constants";

export const closeCompleteProfileModal = () => {
  modal.remove(MODAL_ID.COMPLETE_PROFILE);
};

export const openCompleteProfileModal = ({
  from,
  onCancel,
  onSuccess = () => browserHistory.push(`${getLocalePath()}/users/edit`),
} = {}) => {
  const closable = from !== COMPLETE_PROFILE_FROM.DEFAULT;

  modal.add(
    <CompleteProfile
      cancel={() => {
        closeCompleteProfileModal();
        if (onCancel) {
          onCancel();
        }
      }}
      from={from}
      success={() => {
        closeCompleteProfileModal();
        onSuccess();
      }}
    />,
    {
      id: MODAL_ID.COMPLETE_PROFILE,
      onClose: closeCompleteProfileModal,
      closable,
      closeOnOutsideClick: closable,
      type: MODAL_TYPE.DEFAULT,
    },
  );
};
