import React from "react";
import { Button } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import DefaultLayout from "./layout";
import CompactedLayout from "./compacted-layout";

const Finished = ({
  onLeaveFeedback,
  onLeaveVisit,
  roomDetails,
  userImage,
  compact = false,
}) => {
  const handleInboxClick = (event) => {
    event.preventDefault();
    onLeaveVisit();
  };

  const handleFeedbackClick = (event) => {
    event.preventDefault();
    onLeaveFeedback();
  };

  const Layout = compact ? CompactedLayout : DefaultLayout;

  return (
    <Layout
      description={translate("videocall.finished_text")}
      image="/assets/images/visits/visit-finished.svg"
      roomDetails={roomDetails}
      title={translate("videocall.finished_title")}
      userImage={userImage}
    >
      <Button
        data-qa="finished-inbox-button"
        fullWidth={true}
        onClick={handleInboxClick}
        variant="secondary"
      >
        {translate("videocall.finished_primary_cta")}
      </Button>
      <Button
        data-qa="finished-feedback-button"
        fullWidth={true}
        onClick={handleFeedbackClick}
      >
        {translate("videocall.finished_secondary_cta")}
      </Button>
    </Layout>
  );
};

export default Finished;
