import { connect } from "react-redux";

import MessengerButton from "./Messenger";

export default connect(
  (state) => ({
    desktop: state.Device.isDesktop,
  }),
  null,
)(MessengerButton);
