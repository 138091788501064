export const STEPS_LISTER_BENEFITS = [
  {
    step: 1,
    text: "booking.request.received.benefits.lister_step_1",
  },
  {
    step: 2,
    text: "booking.request.received.benefits.lister_step_2",
  },
  {
    step: 3,
    text: "booking.request.received.benefits.lister_step_3",
  },
];
