import styled from "styled-components";
import { Col } from "reactstrap";
import { Heading, BREAKPOINTS_PX, colors } from "@badi/badi-components";

export const OverviewMainFrame = styled.div`
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS_PX.lg}) {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      background-color: ${colors.main};
      height: 262px;
    }
  }
`;
export const ShrinkingHeading = styled(Heading)`
  @media (max-width: ${BREAKPOINTS_PX.sm}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const RoomCardImage = styled.div`
  position: relative;
  width: 152px;
  height: 112px;
  flex-shrink: 0;
  @media screen and (max-width: ${BREAKPOINTS_PX.sm}) {
    height: 96px;
    width: 96px;
  }

  > img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RoomCardDetails = styled.div`
  letter-spacing: normal;
  position: relative;

  h4,
  p {
    margin: 0;
    display: -webkit-box;
    max-width: 100%;
    line-height: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }

  h4 {
    cursor: pointer;
    line-height: 20px;
    -webkit-line-clamp: 2;
  }

  p {
    -webkit-line-clamp: 1;
  }
`;

export const OverviewAction = styled.div`
  display: flex;
  flex-basis: 100%;
  height: 72px;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};

  ${(props) => props.clickable && `cursor: pointer`}
`;

export const OverviewSectionWrapper = styled(Col)`
  @media screen and (min-width: ${BREAKPOINTS_PX.md}) {
    padding: 0;
  }
`;

export const ActionLabelWrapper = styled.div`
  width: 95%;
`;

export const CardLink = styled.a`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const CardLinkBackground = styled.div`
  @media screen and (max-width: ${BREAKPOINTS_PX.lg}) {
    background-color: ${colors.white};
    border-radius: 4px;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 56px;
  margin: 200px auto;
`;

export const ScoreLink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;
