import styled, { css } from "styled-components";
import { colors } from "@badi/badi-components";

export const Root = styled.input`
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  width: 100%;
  margin: 0;
  padding: 8px;
  transition: 0.125s linear;
  color: ${colors.darkGrey};
  border: 1px solid ${colors.darkGrey};
  border-radius: 4px;
  outline: none;
  height: 48px;
  background: ${colors.white};
  appearance: none;

  ${({ hasError }) =>
    hasError
      ? css`
          border-color: ${colors.red};
        `
      : null}

  &:focus {
    border-color: ${colors.blueBooking};
  }
`;
