import React, { Fragment, memo, useState, useEffect } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { STUDIES } from "base/shared/StaticData";

import FormBlock from "components/form-block";
import Select from "components/Form/Select/SelectWithFormsy";
import { Hidden } from "./styled-components";

const Studies = ({ studies = 0, isStudent, isValid, saved }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(translate(STUDIES));
  }, []);

  const Wrapper = isStudent ? Fragment : Hidden;

  return (
    <Wrapper>
      <FormBlock
        id="formblock-studies"
        isRequired={true}
        saved={saved}
        title={translate("profile.form.label.studies")}
        valid={isValid}
      >
        <Select
          className="centered-flex-row"
          name="studies"
          options={options}
          placeholder={translate("profile.form.fields.studies")}
          placeholderValue="0"
          validations="requiredOnStudy"
          value={isStudent ? studies : 0}
        />
        <Spacer bottom={3} />
      </FormBlock>
    </Wrapper>
  );
};

export default memo(Studies);
