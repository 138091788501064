import { connect } from "react-redux";

import Actions from "datamodel/User/actions";
import AuthActions from "datamodel/Auth/actions";
import { getToken } from "datamodel/Auth/selectors";

import FacebookButton from "./facebook-button";

export default connect(
  (state) => ({
    token: getToken(state),
  }),
  {
    setConfirmation: Actions.setConfirmation,
    login: AuthActions.login,
  },
)(FacebookButton);
