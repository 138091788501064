import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";

import RoomSelector from "components/room-selector";
import { Flex, Spacer, Button } from "@badi/badi-components";
import {
  fetchListingsToDuplicate,
  duplicateListing,
} from "datamodel/RoomList/api";
import { translate } from "base/shared/Localization";
import { Footer } from "./styled-components";

const DraftSelection = ({ onClose, navigateToDraft }) => {
  const token = useSelector(getToken);
  const [selectedListingId, setSelectedListing] = useState(0);
  const [listings, setListings] = useState(null);

  useEffect(() => {
    fetchListingsToDuplicate().then((res) => {
      setListings(res);
    });
  }, []);

  const handleOnClickButton = () => {
    duplicateListing({ token, listingId: selectedListingId }).then(({ id }) => {
      navigateToDraft(id);
    });
  };

  const handleSelectRoom = (event) => {
    setSelectedListing(Number(event.currentTarget.value));
  };

  return listings ? (
    <Flex direction="column">
      <RoomSelector
        onClose={onClose}
        onSelect={handleSelectRoom}
        pageDescription={translate("listing.duplicate.description")}
        pageTitle={translate("listing.duplicate.title")}
        roomId={selectedListingId}
        roomItems={listings}
        withPagination={false}
      />
      <Footer data-qa="listing-duplicate-footer">
        <Spacer bottom={1} left={1} md={{ right: 16 }} right={1} top={1}>
          <Flex justify="center" md={{ justify: "flexEnd" }}>
            <Button
              data="listing-duplicate-footer-button"
              disabled={!selectedListingId}
              onClick={handleOnClickButton}
            >
              {translate("listing.duplicate.button")}
            </Button>
          </Flex>
        </Spacer>
      </Footer>
    </Flex>
  ) : null;
};

export default DraftSelection;
