export const getContent = (askIsRented) =>
  askIsRented
    ? {
        title: "Are you sure?",
        description: "Why do you want to delete it?",
        confirmButtonText: "I rented it :)",
        cancelButtonText: "I don't want to rent it",
      }
    : {
        title: "Are you sure?",
        description: "You are going to delete this room",
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
      };
