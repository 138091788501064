import styled from "styled-components";
import { colors, unitizedPx, Card } from "@badi/badi-components";

export const BlockWrapper = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const TextRightAligned = styled.div`
  text-align: right;
`;

export const Anchor = styled.a`
  /* this is needed for design purposes, the link is different from the global one */
  display: inline-block;
  margin-left: ${unitizedPx(0.5)};

  color: inherit;
  text-decoration: underline;
`;

export const BookingSummaryHighlight = styled(Card)`
  padding: ${unitizedPx(2)};
`;

export const InfoButton = styled.div`
  cursor: pointer;
`;

export const Line = styled.div`
  border: 1px solid ${colors.softGrey};
  height: 1px;
  width: 100%;
`;

export const Wrapper = styled.div`
  min-width: ${unitizedPx(10)};
`;
