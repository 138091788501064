import { v4 } from "uuid";
import { translate } from "base/shared/Localization";

export const parsePicturesToFormData = (pictures) =>
  pictures.map((picture) => ({
    file: { preview: picture.width_500_url },
    pictureId: picture.id,
    tempId: v4(),
    uploadStatus: "success",
  }));

export const parseOptionsToFormData = (options) =>
  options.map(({ title, value: optionValue }) => ({
    label: translate(title),
    value: optionValue,
  }));
