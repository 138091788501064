import { locale } from "base/shared/Localization";

const STRIPE_ID = "strip-api";
const STRIPE_API_URL = "https://js.stripe.com/v3/";
const STRIPE_FIELD_STYLES = {
  base: {
    iconColor: "#666EE8",
    color: "#31325F",
    lineHeight: "48px",
    fontWeight: 300,
    fontFamily: '"modelica", sans-serif',
    fontSize: "15px",
    "::placeholder": {
      color: "#b2b2b2",
    },
  },
};

let stripeApi = null;
const subscribers = [];

class StripeApi {
  constructor({ token }) {
    this.token = token;
    this.api = null;
    this.elements = null;
    this.card = null;
    this.loaded = false;
  }

  init() {
    if (!window) {
      console.warn(`Only call the API on the client`);
      return Promise.reject();
    }

    // if exists just run onload
    if (StripeApi.scriptExists()) {
      this.initialiseApi();
      return Promise.resolve();
    }

    const script = StripeApi.loadScript();

    return script.then(() => {
      this.initialiseApi();
      return Promise.resolve();
    });
  }

  static scriptExists() {
    return document.getElementById(STRIPE_ID);
  }

  static loadScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = STRIPE_API_URL;
      script.id = STRIPE_ID;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject();

      document.body.appendChild(script);
    });
  }

  initialiseApi() {
    if (!window.Stripe) {
      return;
    }

    this.api = window.Stripe(this.token);
    this.elements = this.api.elements({ locale: locale() });
    this.loaded = true;

    subscribers.forEach((subscriber) => {
      subscriber(this);
    });
    subscribers.splice(0, subscribers.length);
  }

  createCreditCardField = ({ id, mountOn }) => {
    const creditCard = this.elements.create(id, {
      classes: {
        base: "payment",
      },
      style: STRIPE_FIELD_STYLES,
      hidePostalCode: true,
    });

    creditCard.mount(mountOn);
    this.card = creditCard;

    return creditCard;
  };

  saveCreditCard = () => {
    return this.api.createToken(this.card);
  };

  handleCardPayment = (clientSecret) => {
    return this.api.handleCardPayment(clientSecret);
  };

  redirectToCheckout = (sessionId) => {
    return this.api.redirectToCheckout({ sessionId }).then((result) => {
      const { error } = result;
      return error;
    });
  };

  authorizeCardPayment = (clientSecret) => {
    return this.handleCardPayment(clientSecret)
      .then((result) => {
        const { error, paymentIntent } = result;

        return { error, paymentIntentId: paymentIntent.id };
      })
      .catch((error) => {
        return { error };
      });
  };
}

export const loadStripeApi = ({ token, onLoaded }) => {
  if (stripeApi?.loaded) {
    onLoaded(stripeApi);
    return;
  }

  subscribers.push(onLoaded);

  if (!stripeApi) {
    stripeApi = new StripeApi({ token });
    stripeApi.init();
  }
};

export default StripeApi;
