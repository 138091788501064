import React from "react";
import ArrowIcon from "components/Icons/arrow-v2";
import { ArrowWrapperBase } from "./styled-components";

import { ARROW_WRAPPER_TYPE } from "./static";

const Arrow = ({
  direction,
  currentSlide,
  slideCount,
  className,
  type,
  onClick: onClickFromSlider,
  onClickCallback,
  ...props
}) => {
  const handleOnClick = (event) => {
    if (!onClickFromSlider) return null;
    onClickFromSlider(event);
    onClickCallback();
  };

  const ArrowWrapperExtended = ARROW_WRAPPER_TYPE[type];
  const opacity = onClickFromSlider ? 1 : 0.6;
  return (
    <ArrowWrapperBase
      as={ArrowWrapperExtended}
      onClick={handleOnClick}
      opacity={opacity}
      position={direction}
      type={type}
    >
      <ArrowIcon direction={direction} {...props} />
    </ArrowWrapperBase>
  );
};

Arrow.defaultProps = {
  className: "",
  currentSlide: 0,
  onClick: null,
  onClickCallback: () => {},
  slideCount: 0,
  type: "default",
};

export default Arrow;
