import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";
import { getIsSafari, isMobileSelector } from "datamodel/Device/selectors";
import {
  getRooms,
  getFetchedRooms,
  getListerHints,
} from "datamodel/Analytics/selectors";
import {
  getRoomsAction,
  getRoomDetailsAction,
  getListerHintsAction,
  resetAnalyticsAction,
} from "datamodel/Analytics/actions";

import ListerMetrics from "./lister-metrics";

const mapStateToProps = (state) => ({
  token: getToken(state),
  rooms: getRooms(state),
  fetchedRooms: getFetchedRooms(state),
  listerHints: getListerHints(state),
  isIOSApp: getIsSafari(state) && isMobileSelector(state),
});

const mapDispatchToProps = {
  getRooms: getRoomsAction,
  getRoomDetails: getRoomDetailsAction,
  getListerHints: getListerHintsAction,
  resetAnalytics: resetAnalyticsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListerMetrics);
