import React from "react";
import { Button } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { ROOM_STATUS } from "datamodel/Room/constants";

const EditRoomButton = ({ disabled, onEditRoom, roomId, roomStatus }) => {
  const handleClick = () => {
    onEditRoom({ id: roomId, publish: roomStatus === ROOM_STATUS.PENDING });
  };

  return (
    <Button dataQa="room-action-edit" disabled={disabled} onClick={handleClick}>
      {translate("room.detail.header.edit")}
    </Button>
  );
};

export default EditRoomButton;
