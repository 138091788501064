import React, { memo } from "react";
import { Container, Col, Row } from "reactstrap";
import { LinkUppercase, Text, Spacer } from "@badi/badi-components";
import { getRoomDetailPath } from "Sections/Room/routes";
import { translate } from "base/shared/Localization";

import { ActionBar } from "../styled-components";

const ViewerBar = ({ roomId, roomTitle }) => {
  const roomPath = getRoomDetailPath({ roomId });
  return (
    <ActionBar>
      <Spacer bottom={1.5}>
        <Container>
          <Row>
            <Col
              lg={{ offset: 1, size: 8 }}
              xs={{ size: 10, offset: 0, order: 1 }}
            >
              <Spacer bottom={1} top={1}>
                <Text body="14-Regular">{roomTitle}</Text>
                <LinkUppercase
                  href={roomPath}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {translate("recommendations.bar.link")}
                </LinkUppercase>
              </Spacer>
            </Col>
          </Row>
        </Container>
      </Spacer>
    </ActionBar>
  );
};

export default memo(ViewerBar);
