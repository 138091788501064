import React from "react";
import { withRouter } from "react-router";
import {
  HEADER_PARAMS,
  interpolateQueryParam,
  getFullPath,
} from "base/shared/tracking-headers";

import Map from "./map-mapping";

const MapRoute = ({ router }) => {
  const setMapUsageTrackingParam = () => {
    const {
      keyVal,
      options: { NEW_SEARCH },
    } = HEADER_PARAMS.MAP_USAGE;
    const fullPathName = getFullPath(window.location);

    router.push(interpolateQueryParam(fullPathName, keyVal, NEW_SEARCH));
  };

  return <Map setMapUsageTrackingParam={setMapUsageTrackingParam} />;
};

export default withRouter(MapRoute);
