import React from "react";
import { Link } from "react-router";
import { LinkButton } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getHomePath } from "Sections/Home/routes";
import BadiLogo from "components/Icons/BadiLogo";
import { TopBarBase } from "components/Layout/TopBar";
import { getRelativePath } from "base/shared/Utils";

const TopBar = () => (
  <TopBarBase>
    <Link to={getHomePath()}>
      <BadiLogo />
    </Link>
    <LinkButton
      dataQa="landing-topbar-button"
      href={getRelativePath("/list/new")}
    >
      {translate("rentRoom.button.text")}
    </LinkButton>
  </TopBarBase>
);

export default TopBar;
