import { connect } from "react-redux";

import HomeActions from "datamodel/Home/actions";
import RequirementsActions from "datamodel/Requirements/actions";
import { getMainCity, getMainCountry } from "datamodel/Home/selectors";
import { getPendingReviewsSelector } from "datamodel/reviews/selectors";
import { getPendingReviewsAction } from "datamodel/reviews/actions";
import { isMobileSelector } from "datamodel/Device/selectors";

import Home from "./home-route";

export default connect(
  (state) => ({
    countryCode: state.Home.countryCode,
    featuredCities: state.Home.featuredCities,
    mainCity: getMainCity(state),
    mainCountry: getMainCountry(state),
    token: state.Auth.access_token,
    userId: state.Auth.user_id,
    isLister: state.User.lister,
    isLoggedIn: !!state.User.id,
    isDesktop: state.Device.isDesktop,
    isMobile: isMobileSelector(state),
    accountMode: state.User.onboarding.mode,
    fetched: state.Home.fetched,
    pendingReviews: getPendingReviewsSelector(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    return {
      ...stateProps,
      ...ownProps,
      ...dispatchProps,
      getHomeCities: () =>
        dispatch(
          HomeActions.getHomeCities({
            token: stateProps.token,
            isLoggedUser: !!stateProps.userId,
            countryCode: null,
          }),
        ),
      getPendingReviews: () => dispatch(getPendingReviewsAction()),
      loginHandler: () => dispatch(RequirementsActions.setLogin(true)),
    };
  },
)(Home);
