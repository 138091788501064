import React from "react";
import { Col } from "reactstrap";
import { Heading, Spacer, Text } from "@badi/badi-components";

import Image from "components/Image/Image";
import { translate } from "base/shared/Localization";

const ListerScoreCard = ({ id }) => (
  <Col md={{ size: 4 }} sm={{ size: 12 }}>
    <Image
      alt={translate(`lister_score_component_high_action_${id}_title`)}
      height="80"
      src={`/assets/icons/lister-score-${id}.svg`}
    />
    <Spacer bottom={1} top={2}>
      <Heading body={4} data-qa="card-title">
        {translate(`lister_score_component_high_action_${id}_title`)}
      </Heading>
    </Spacer>
    <Text data-qa="card-text">
      {translate(`lister_score_component_high_action_${id}_text`)}
    </Text>
  </Col>
);

export default ListerScoreCard;
