import React, { Component } from "react";
import classNames from "classnames";
import SlickSlider from "react-slick";
import LeftArrow from "components/direction/Left";
import RightArrow from "components/direction/Right";
import ProgressiveImg from "components/ProgressiveImg";

import { getPicture } from "base/shared/Utils";

import "./stylesExt.css";

const areArrowsVisible = (config, length) => {
  switch (config) {
    case "always":
      return true;
    case "never":
      return false;
    default:
      return length > 1;
  }
};

class Slider extends Component {
  constructor(props) {
    super(props);

    this.getMappedPictures = this.getMappedPictures.bind(this);
  }

  getMappedPictures(pictures) {
    let pics = pictures;

    if (!pictures?.length) {
      const defaultPic = getPicture(null, "width_500_url", 0, this.props.type);
      pics = [
        {
          width_500_url: defaultPic,
          width_27_url: defaultPic,
          id: 0,
        },
      ];
    }

    return pics.map((p) => (
      <div key={p.id}>
        <ProgressiveImg
          className="Slider--Progressive-wrapper"
          key={p.id}
          src={p.width_500_url}
          tinySrc={p.width_27_url}
        />
      </div>
    ));
  }

  render() {
    const { arrows, className, pictures, dots, type, ...settings } = this.props;
    const toshow = this.getMappedPictures(pictures);
    const arrowsVisibility = areArrowsVisible(arrows, toshow.length);
    const visibleDots = dots && toshow.length > 1;

    return (
      <div
        className={classNames(
          `${type}-item-slide`,
          "slider-radius-top",
          className,
        )}
      >
        {toshow.length > 0 && (
          <SlickSlider
            {...settings}
            arrows={arrowsVisibility}
            dots={visibleDots}
          >
            {toshow}
          </SlickSlider>
        )}
      </div>
    );
  }
}

Slider.defaultProps = {
  arrows: "default",
  className: null,
  dots: false,
  draggable: false,
  infinite: true,
  lazyLoad: true,
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
  type: "user",
};

export default Slider;
