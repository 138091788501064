import React from "react";
import {
  Flex,
  Heading,
  Spacer,
  Text,
  BADI_COLORS,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import TransactionCard from "../../components/transaction-card";
import TransactionEmptyCard from "../../components/transaction-card/transaction-empty-card";

import { TransactionNum } from "../../components/transactions-heading/styled-components";

const TransactionsList = ({
  transactionsSummary: {
    total_amount: totalAmount,
    active_transactions: activeTransactions,
  } = {},
  transactions = [],
}) => {
  const transactionsCompleted = activeTransactions > 0;

  return (
    <Spacer bottom={1.5} left={2} right={2} top={1.5}>
      <Heading
        data-qa="transactions-view-title"
        level="28-Extrabold"
        textColor={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
      >
        {translate("transactions.summary_modal_header")}
      </Heading>
      <Spacer bottom={1} />
      <Heading
        data-qa="transactions-view-amount"
        level="28-Extrabold"
        textColor={BADI_COLORS.SEMANTIC.INFO.DEFAULT}
      >
        {totalAmount}
      </Heading>
      <Spacer bottom={2} />
      {transactionsCompleted ? (
        <Flex alignItems="center" justify="spaceBetween">
          <Heading color={BADI_COLORS.BRAND.TERTIARY.DEFAULT} level="20-Bold">
            {translate("transactions.summary_modal_title")}
          </Heading>
          <TransactionNum color={BADI_COLORS.SEMANTIC.INFO.DEFAULT}>
            <Text
              body="10-Medium"
              color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
              data-qa="transactions-view-count"
            >
              {activeTransactions}
            </Text>
          </TransactionNum>
        </Flex>
      ) : (
        <Spacer top={2}>
          <TransactionEmptyCard />
        </Spacer>
      )}
      <Spacer top={3}>
        {transactions.map((transaction) => (
          <Spacer bottom={3}>
            <TransactionCard
              key={`transaction-card-${transaction.title}`}
              {...transaction}
            />
          </Spacer>
        ))}
      </Spacer>
    </Spacer>
  );
};

export default TransactionsList;
