import { getUserOccupation } from "base/shared/User";

class UserCompact {
  constructor(entity) {
    this.entity = entity;
  }

  get picture() {
    const { cover_picture: coverPicture } = this.entity;
    const picture = coverPicture ? coverPicture.width_100_url : null;

    return picture;
  }

  get id() {
    return this.entity.id;
  }

  get name() {
    return this.entity.first_name || "";
  }

  get age() {
    return this.entity.age;
  }

  get occupation() {
    return getUserOccupation(this.entity.occupation_detail);
  }

  toJSON() {
    return {
      name: this.name,
      age: this.age,
      occupation: this.occupation,
      picture: this.picture,
      id: this.id,
    };
  }
}

export default UserCompact;
