import { connect } from "react-redux";

import Actions from "datamodel/Requirements/actions";
import {
  getTermsAndConditions,
  getCountryVatRequirement,
} from "datamodel/Requirements/selectors";
import { isImmediateListingFlowSelector } from "datamodel/Features/selectors";

import Requirements from "./requirements";

export default connect(
  (state) => ({
    gdpr: state.Requirements.gdpr,
    countryVat: getCountryVatRequirement(state),
    onboarding: state.Requirements.onboarding,
    onboardingType: state.User.onboarding.type,
    login: state.Requirements.login,
    profile: state.Requirements.profile,
    phone: state.Requirements.phone,
    register: state.Requirements.register,
    isImmediateListingFlow: isImmediateListingFlowSelector(state),
    termsAndConditions: getTermsAndConditions(state),
  }),
  (dispatch) => ({
    setGDPR: ({ visible }) => dispatch(Actions.setGDPR(visible)),
    setCountryVat: ({ visible }) => dispatch(Actions.setCountryVat(visible)),
    setOnboarding: (onboarding, callback) =>
      dispatch(Actions.setOnboarding(onboarding, callback)),
    setLogin: (login, callback) => dispatch(Actions.setLogin(login, callback)),
    setProfile: (profile, callback) =>
      dispatch(Actions.setProfile(profile, callback)),
    setPhone: (phone, callback) => dispatch(Actions.setPhone(phone, callback)),
    setRegister: (register, callback) =>
      dispatch(Actions.setRegister(register, callback)),
    setRequirements: (requirements) =>
      dispatch(Actions.setRequirements(requirements)),
    setTermsAndConditions: (termsAndConditions, callback) =>
      dispatch(Actions.setTermsAndConditions(termsAndConditions, callback)),
  }),
)(Requirements);
