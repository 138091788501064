import styled from "styled-components";
import {
  Text,
  BREAKPOINTS_PX,
  colors,
  unitizedPx,
} from "@badi/badi-components";

export const ShareRoomWrapper = styled.div`
  padding: ${unitizedPx(3)};

  @media screen and (max-width: ${BREAKPOINTS_PX.sm}) {
    flex-wrap: wrap;
  }
`;

export const BlockWrapper = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const IconWrapper = styled.div`
  padding-top: ${unitizedPx(0.5)};
`;

export const RoomHeaderButton = styled.button`
  z-index: 1;
  height: 28px;
  display: flex;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: ${unitizedPx(2)};
  left: ${unitizedPx(2)};
  display: flex;
  z-index: 1;
`;

export const UpperCaseText = styled(Text)`
  text-transform: uppercase;
  color: ${colors.midGrey};
  font-size: ${unitizedPx(1.25)};
  line-height: ${unitizedPx(1.25)};
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;
