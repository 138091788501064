import React from "react";
import {
  Heading,
  Text,
  Badge,
  Button,
  Spacer,
  Flex,
  BADI_COLORS,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { PremiumLabel, usePremium } from "modules/premium";
import { PREMIUM_FEATURES } from "datamodel/premium/constants";
import { TIPS } from "../constants";

const ViewerTips = ({ onClick }) => {
  const { isPremium } = usePremium({
    capabilityId: PREMIUM_FEATURES.ROOM_VIEWERS,
  });
  return (
    <Spacer bottom={2} left={3} right={3} top={2}>
      {isPremium && <PremiumLabel bottom={1} />}
      <Spacer bottom={1}>
        <Heading>{translate("viewers.tip_title")}</Heading>
      </Spacer>
      <Spacer bottom={2}>
        <Text body="16-Regular">{translate("viewers.tip_subtitle")}</Text>
      </Spacer>
      <Flex direction="column" gutter={3}>
        {TIPS.map((tip) => (
          <div key={tip.description}>
            <Spacer bottom={1}>
              <Badge
                backgroundColor={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
                rounded={true}
              >
                {tip.number}
              </Badge>
            </Spacer>
            <Heading level="16-Bold">{translate(tip.heading)}</Heading>
            <Text body="14-Regular">{translate(tip.description)}</Text>
          </div>
        ))}
      </Flex>
      <Spacer top={5}>
        <Flex justify="center">
          <Button onClick={onClick}>{translate("viewers.tip_cta")}</Button>
        </Flex>
      </Spacer>
    </Spacer>
  );
};

export default ViewerTips;
