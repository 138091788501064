import { connect } from "react-redux";

import ViewersActions from "datamodel/viewers/actions";
import RoomActions from "datamodel/Room/actions";

import { getRoom } from "datamodel/Room/selectors";
import { getToken } from "datamodel/Auth/selectors";
import {
  getViewersList,
  isFetchingViewers,
  hadFetchedViewers,
  getViewersPagination,
} from "datamodel/viewers/selectors";
import Viewers from "./viewers";

const mapStateToProps = (state) => ({
  viewers: getViewersList(state),
  pagination: getViewersPagination(state),
  fetching: isFetchingViewers(state),
  fetched: hadFetchedViewers(state),
  token: getToken(state),
  room: getRoom(state),
});

const mapDispatchToProps = {
  getRoom: RoomActions.getRoom,
  resetViewers: ViewersActions.resetViewers,
  getViewers: ViewersActions.getViewers,
  contactedViewer: ViewersActions.contactedViewer,
};

const mergeProps = (stateProps, dispatch, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    ...dispatch,
    getRoom: (roomId) => dispatch.getRoom({ roomId, token: ownProps.token }),
    getViewers: (roomId, paginationToken) =>
      dispatch.getViewers({
        roomId,
        token: stateProps.token,
        paginationToken,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Viewers);
