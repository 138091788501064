import React from "react";
import { withFormsy } from "formsy-react";
import { debounce } from "radash";
import SocialProfile from "./SocialProfile";
import { getMediaTypesProps, getUpdatedSocialProfiles } from "./utils";

const SocialProfilesWithFormsy = ({
  getValue,
  setValue,
  setInputValidation,
}) => {
  const handleValueChange = ({ name, value, isValid }) => {
    if (isValid) {
      const safeValue = getValue();
      const updatedSocialProfiles = getUpdatedSocialProfiles(
        safeValue,
        name,
        value,
      );
      setValue(updatedSocialProfiles);
    }
  };

  const onDebouncedValueChange = debounce({ delay: 500 }, handleValueChange);

  const handleInputValidation = ({ name, isValid }) =>
    isValid === null
      ? setInputValidation(name, true)
      : setInputValidation(name, isValid);

  const value = getValue();
  return getMediaTypesProps(value).map((props) => (
    <SocialProfile
      {...props}
      key={props.media}
      onChange={onDebouncedValueChange}
      onInputValidation={handleInputValidation}
    />
  ));
};

export default withFormsy(SocialProfilesWithFormsy);
