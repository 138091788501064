import styled from "styled-components";
import { unitizedPx, BADI_COLORS } from "@badi/badi-components";

import { TRANSACTION_STATUS } from "../../constants";

export const PriceBox = styled.div`
  border-radius: ${unitizedPx(1)};
  padding: ${unitizedPx(1)} ${unitizedPx(2)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
`;

export const StatusRowStyle = styled.div`
  position: relative;
  padding-left: ${unitizedPx(2.5)};
  &:before {
    position: absolute;
    content: "";
    border-radius: 50%;
    width: ${unitizedPx(1.5)};
    height: ${unitizedPx(1.5)};
    left: 0px;
    border: ${(props) => {
      switch (props.status) {
        case TRANSACTION_STATUS.done:
          return `2px solid ${BADI_COLORS.BRAND.PRIMARY.DEFAULT}`;
        case TRANSACTION_STATUS.inProgress:
          return `2px solid ${BADI_COLORS.BRAND.PRIMARY.DEFAULT}`;
        default:
          return `2px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_50}`;
      }
    }};
    background-color: ${(props) => {
      switch (props.status) {
        case TRANSACTION_STATUS.inProgress:
          return BADI_COLORS.BRAND.PRIMARY.DEFAULT;
        default:
          return `transparent`;
      }
    }};
  }
  &:after {
    position: absolute;
    content: "";
    width: 2px;
    height: ${unitizedPx(2.5)};
    left: 5px;
    top: ${unitizedPx(2)};
    ${(props) => {
      if (props.status === TRANSACTION_STATUS.pending) {
        return `
          background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
        `;
      }
      return `
        background-color: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
      `;
    }};
    ${(props) => (props.lastChild ? "display: none" : "")}
  }
`;
