import React, { useEffect, useState } from "react";

import { translate } from "base/shared/Localization";
import { countryCodesOptions } from "components/Verification/utils";

import { Flex, Spacer, Heading, Button } from "@badi/badi-components";
import { getVatCountries } from "app/datamodel/Payment/api";

import Select from "components/Form/Select/Select";
import CheckBox from "components/Form/CheckBox/CheckBox";

const CountryVat = ({ token, setCountryVat, callback }) => {
  const [countryCode, setCountryCode] = useState("DEFAULT");
  const [overseas, setOverseas] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setLoading(true);
    getVatCountries({ token }).then((r) => {
      const parsedCountries = countryCodesOptions(r.data).map((c) => ({
        ...c,
        title: c.label,
      }));
      setCountries(parsedCountries);
      setLoading(false);
    });
  }, []);

  const handleChange = ({ target }) => {
    setOverseas(false);
    setCountryCode(target.value);
  };

  const handleSubmit = () => {
    setCountryVat({ countryCode, spainOverseas: overseas });
    callback();
  };

  return (
    <Spacer bottom={2} left={7} right={7} top={5}>
      <Flex direction="column" gutter={2}>
        <Heading level={2}>{translate("location.popup_title")}</Heading>
        <Select
          disabled={loading}
          onChange={handleChange}
          options={countries}
          placeholder={translate("location.popup_country_placeholder")}
          placeholderValue="DEFAULT"
          required={true}
          value={countryCode}
        />
        {countryCode === "ES" && (
          <CheckBox
            checked={overseas}
            label={translate("location.popup.check_spanish_islands")}
            name="overseas"
            onChange={setOverseas}
            value={overseas}
          />
        )}
        <Spacer top={2}>
          <Flex alignItems="center" justify="center">
            <Button disabled={countryCode === "DEFAULT"} onClick={handleSubmit}>
              {translate("location.popup_CTA")}
            </Button>
          </Flex>
        </Spacer>
      </Flex>
    </Spacer>
  );
};

export default CountryVat;
