import React, { PureComponent } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { LANGUAGES } from "base/shared/StaticData";

import SelectMultiple from "components/Form/SelectMultiple/SelectMultipleWithFormsy";
import FormBlock from "components/form-block";

import { getOrderedOptions } from "../../utils";

class Languages extends PureComponent {
  getLanguages = () => {
    if (!this.languages) {
      this.languages = getOrderedOptions(translate(LANGUAGES)).map(
        ({ title, value }) => ({
          label: title,
          value,
        }),
      );
    }
    return this.languages;
  };

  render() {
    const { languages, isValid, saved } = this.props;
    const options = this.getLanguages();

    return (
      <FormBlock
        dataQa="profile-form-languages-section"
        id="formblock-languages"
        isRequired={true}
        saved={saved}
        title={translate("profile.form.label.languages")}
        valid={isValid}
      >
        <SelectMultiple
          dataQa="profile-edit-languages"
          name="languages"
          options={options}
          placeholder={translate("profile.form.fields.languages")}
          validations={{
            isSomeValueSelected: true,
          }}
          value={languages}
        />
        <Spacer bottom={3} />
      </FormBlock>
    );
  }
}

Languages.defaultProps = {
  languages: [],
};

export default Languages;
