import React from "react";
import { Flex } from "@badi/badi-components";

import { StepsWrapper } from "./styled-components";
import Step from "./step";

const Stepper = ({ status, steps = [] }) => {
  return (
    <Flex as={StepsWrapper} justify="spaceBetween">
      {steps.map(({ activeStatusList, completedStatusList, ...stepProps }) => (
        <Step
          {...stepProps}
          active={activeStatusList.includes(status)}
          completed={completedStatusList.includes(status)}
          key={stepProps.name}
        />
      ))}
    </Flex>
  );
};

export default Stepper;
