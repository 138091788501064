import React from "react";
import { Flex, Heading, Spacer, Text } from "@badi/badi-components";
import Image from "components/Image/Image";

import { translate } from "base/shared/Localization";

import {
  CardsNotFoundWrapper,
  CardsNotFoundDescription,
} from "./styled-components";

const CardsNotFound = () => (
  <CardsNotFoundWrapper>
    <Flex
      alignItems="center"
      direction="column"
      fullHeight={true}
      justify="center"
    >
      <Spacer bottom={4}>
        <Image height={160} src="/assets/images/search-empty-state.svg" />
      </Spacer>
      <Heading align="center" level={3}>
        {translate("search.rooms.empty.title")}
      </Heading>
      <Spacer top={2}>
        <CardsNotFoundDescription>
          <Text align="center">
            {translate("search.rooms.empty.description")}
          </Text>
        </CardsNotFoundDescription>
      </Spacer>
    </Flex>
  </CardsNotFoundWrapper>
);

export default CardsNotFound;
