import React from "react";
import { BADI_COLORS, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { Anchor } from "./styled-components";

const ContactSales = () => {
  return (
    <Text
      align="center"
      body="10-Medium"
      color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
    >
      <Anchor
        data-qa="plan-contact-sales"
        href={`mailto:sales@badi.com?subject=${translate(
          "plans.screen_badi.pro.subject_email",
        )}`}
      >
        {translate("plans.screen_badi.pro.contact.us.cta")}
      </Anchor>
    </Text>
  );
};

export default ContactSales;
