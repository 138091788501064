import { connect } from "react-redux";

import Actions from "datamodel/RoomList/actions";
import { getRoomLocationSelector } from "datamodel/RoomList/selectors";
import { onboardingRoleSelector } from "datamodel/User/selectors";
import Location from "./location";

const mapStateToProps = (state) => ({
  location: getRoomLocationSelector(state),
  loaded: getRoomLocationSelector(state).country, // checks if the city has beenloaded
  onboardingRole: onboardingRoleSelector(state),
});

const mapDispatchToProps = {
  setRoomLocation: Actions.setRoomLocation,
  setCountryTemplateFields: Actions.setCountryTemplateFields,
};

const mergeProps = (state, dispatch, ownProps) => {
  return {
    ...state,
    ...dispatch,
    ...ownProps,
    setCountryTemplateFields: (countryCode) =>
      dispatch.setCountryTemplateFields({ token: state.token, countryCode }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Location);
