import styled, { css } from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";

const notificationsStyles = css`
  position: absolute;
  right: 8px;
  top: -2px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #489cff;
`;

export const TopbarElementWrapper = styled.li`
  position: relative;
  padding: 0 16px;
  text-align: center;
  display: flex;

  margin-left: ${({ position }) => (position === "right" ? "auto" : "unset")};

  :after {
    ${({ notifications }) => notifications && notificationsStyles}
  }

  > * {
    position: relative;
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
    font-size: 0.93rem;
    font-weight: 500;
    cursor: pointer;
    border: 0;

    :hover {
      nav > ul {
        visibility: visible;

        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }

  > a {
    text-decoration: none;
    outline: none;

    :hover,
    :active {
      color: inherit;
      text-decoration: none;
    }
  }

  a,
  button {
    > * {
      cursor: pointer;
    }
  }
`;
