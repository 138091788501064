export const PAYOUT_STATUS = {
  INITIAL: 0,
  FETCHING: 1,
  COUNTRY_UPDATING: 2,
  BANK_ACCOUNT_UPDATING: 3,
  CONTACT_INFO_UPDATING: 4,
  FETCHED: 5,
  ERROR: 6,
};

const EU_VALIDATION =
  /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[a-zA-Z0-9]{7}([a-zA-Z0-9]?){0,16}/;

export const ADDITIONAL_FIELD_TYPES = {
  ROUTING: 1,
  SORTCODE: 2,
  NONE: 0,
};

export const BANK_ACCOUNT_COUNTRIES = [
  {
    label: "Spain",
    value: "ES",
    validation: EU_VALIDATION,
    additionalField: ADDITIONAL_FIELD_TYPES.NONE,
  },
  {
    label: "United Kingdom",
    value: "GB",
    additionalField: ADDITIONAL_FIELD_TYPES.SORTCODE,
    validation: /[0-9]{8}/,
  },
  {
    label: "Italy",
    value: "IT",
    validation: EU_VALIDATION,
    additionalField: ADDITIONAL_FIELD_TYPES.NONE,
  },
  {
    label: "France",
    value: "FR",
    validation: EU_VALIDATION,
    additionalField: ADDITIONAL_FIELD_TYPES.NONE,
  },
  {
    label: "Germany",
    value: "DE",
    validation: EU_VALIDATION,
    additionalField: ADDITIONAL_FIELD_TYPES.NONE,
  },
];
