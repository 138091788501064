import { CONNECTION_STATUS_CODE } from "datamodel/Conversation/constants";

export const CATEGORIES_ENUM = {
  BOOKING: { value: "booking", label: "inbox.filter.categories.booking" },
  DEFAULT: { value: "default", label: "inbox.filter.categories.default" },
  PENDING: { value: "pending", label: "inbox.filter.categories.pending" },
  MESSAGING: { value: "messaging", label: "inbox.filter.categories.messaging" },
  CLOSED: { value: "closed", label: "inbox.filter.categories.closed" },
};

export const CATEGORIES = [
  CATEGORIES_ENUM.BOOKING,
  CATEGORIES_ENUM.DEFAULT,
  CATEGORIES_ENUM.PENDING,
  CATEGORIES_ENUM.MESSAGING,
  CATEGORIES_ENUM.CLOSED,
];

export const DEFAULT_CATEGORY_VALUE = CATEGORIES_ENUM.DEFAULT.value;

export const HIGHEST_PRIORITY_STATUSES = [
  CONNECTION_STATUS_CODE.BOOKING_REQUEST_PENDING,
  CONNECTION_STATUS_CODE.BOOKING_REQUEST_PAYMENT_FAILED,
];

export const CURRENT_USER_ROLES = {
  LISTER: "lister",
  SEEKER: "seeker",
};
