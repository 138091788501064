import React from "react";
import { Choice, Text, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import FilterSectionWrapper from "../../wrappers/filter-section-wrapper";
import merge from "lodash.merge";

import { FormSectionChoiceElement } from "../forms/styled-components";

const MarketplaceSegmentsModalForm = ({ filtersState, setFiltersState }) => {
  const { badiRooms, badiCommunity } = filtersState;

  const toggleFilter = (type) => {
    switch (type) {
      case "badi-rooms":
        setFiltersState(
          merge(filtersState, { badiRooms: !filtersState.badiRooms }),
        );
        break;
      case "badi-community":
        setFiltersState(
          merge(filtersState, { badiCommunity: !filtersState.badiCommunity }),
        );
        break;
    }
  };

  const segmentsSections = [
    {
      name: "badi-rooms",
      label: "Badi",
      isChecked: badiRooms,
      spacerProps: { bottom: 2 },
    },
    {
      name: "badi-community",
      label: translate(
        "search.filters.marketplace_segments.segments.badiCommunity",
      ),
      isChecked: badiCommunity,
      spacerProps: { md: { top: 2 }, xs: { top: 1 } },
    },
  ];
  return (
    <FilterSectionWrapper
      title={`${translate("search.filters.marketplace_segments.title")}:`}
    >
      <Spacer bottom={3}>
        <Text body={3}>
          {translate("search.filters.marketplace_segments.description")}
        </Text>
      </Spacer>
      {segmentsSections.map(({ name, label, isChecked, spacerProps }) => {
        const key = `marketplace-segment-${name}`;
        return (
          <Spacer {...{ ...spacerProps, key }}>
            <FormSectionChoiceElement
              as={Choice}
              checked={isChecked}
              id={key}
              name={key}
              type="checkbox"
              value={key}
              onChange={() => toggleFilter(name)}
            >
              {label}
            </FormSectionChoiceElement>
          </Spacer>
        );
      })}
    </FilterSectionWrapper>
  );
};

export default MarketplaceSegmentsModalForm;
