import { isSeeker } from "datamodel/User/utils";
import {
  STEPS_SEEKER,
  STEPS_LISTER,
  STEPS_SEEKER_TITLE,
  STEPS_LISTER_TITLE,
} from "./constants";

export const getSteps = (userRole) => {
  if (isSeeker(userRole)) return STEPS_SEEKER;
  return STEPS_LISTER;
};

export const getTitle = (userRole) => {
  if (isSeeker(userRole)) return STEPS_SEEKER_TITLE;
  return STEPS_LISTER_TITLE;
};
