import React, { memo } from "react";
import { Flex } from "@badi/badi-components";
import TopBar from "components/Layout/TopBar";
import InfoBar from "Sections/info-bar";

import { LayoutWrapper, LayoutBody, LayoutContent } from "./styled-components";

const Wrapper = ({
  children = null,
  footer = null,
  preContent = null,
  infoBar = true,
  topBar = <TopBar />,
}) => (
  <Flex as={LayoutWrapper} direction="column">
    <>
      {topBar}
      {infoBar && <InfoBar />}
    </>
    <Flex as={LayoutBody} direction="column" fullHeight={true} grow={1}>
      {preContent}
      <Flex as={LayoutContent} direction="column">
        {children}
      </Flex>
      {footer}
    </Flex>
  </Flex>
);

export default memo(Wrapper);
