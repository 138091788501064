import { connect } from "react-redux";
import { loginTypeSelector } from "datamodel/User/selectors";
import { getToken } from "datamodel/Auth/selectors";
import Notifications from "./notifications";

const mapStateToProps = (state) => ({
  token: getToken(state),
  loginType: loginTypeSelector(state),
});

export default connect(mapStateToProps)(Notifications);
