import React, { Component } from "react";
import { colors, Heading } from "@badi/badi-components";
import { MarkerButton } from "./styled-components";

class Marker extends Component {
  shouldComponentUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) return true;
    return false;
  }

  handleMarkerClick = () => {
    const { id, onClick } = this.props;
    onClick(id);
  };

  render() {
    const { children, selected, dataQa } = this.props;
    return (
      <MarkerButton
        data-qa={dataQa}
        onClick={this.handleMarkerClick}
        selected={selected}
        type="button"
      >
        <Heading color={selected ? colors.white : colors.darkGrey} level={4}>
          {children}
        </Heading>
      </MarkerButton>
    );
  }
}

Marker.defaultProps = {
  dataQa: "map-marker",
};

export default Marker;
