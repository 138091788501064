import React from "react";

import SearchIcon from "components/Icons/Search";
import SearchModal from "components/search-modal";

import Element from "../topbar-element";

const Search = ({ dataQa = "search" }) => {
  return (
    <SearchModal>
      <Element dataQa={dataQa} position="right">
        <SearchIcon />
      </Element>
    </SearchModal>
  );
};

export default Search;
