import { createSelector } from "reselect";
import { PAYOUT_STATUS } from "./static";

const getPayoutDetails = (state) => state.Payouts;

export const getStatus = createSelector(
  getPayoutDetails,
  (payouts) => payouts.status,
);

export const getError = createSelector(
  getPayoutDetails,
  (payouts) => payouts.error,
);

export const getBankAccountError = createSelector(
  getPayoutDetails,
  (payouts) => payouts.bankAccountError,
);

export const getBankAccountCountry = createSelector(
  getPayoutDetails,
  (payouts) => payouts.contactCountry,
);

export const getBankAccountLastDigits = createSelector(
  getPayoutDetails,
  (payouts) => payouts.accountNumber,
);

export const getBankName = createSelector(
  getPayoutDetails,
  (payouts) => payouts.bankName,
);

export const getContactEmail = createSelector(
  getPayoutDetails,
  (payouts) => payouts.email,
);

export const getContactPhone = createSelector(
  getPayoutDetails,
  (payouts) => payouts.phone,
);

export const getContactInformation = createSelector(
  getPayoutDetails,
  ({
    contactCountry,
    name,
    surname,
    city,
    address,
    postalcode,
    birthdate,
  }) => ({
    contactCountry,
    name,
    surname,
    city,
    address,
    postalcode,
    birthdate,
  }),
);

export const getContactVerification = createSelector(
  getContactInformation,
  (contact) => !Object.values(contact).some((value) => !value),
);

export const getContactCountry = createSelector(
  getPayoutDetails,
  (payouts) => payouts.contactCountry,
);

export const getContactName = createSelector(
  getPayoutDetails,
  (payouts) => payouts.name,
);

export const getContactSurname = createSelector(
  getPayoutDetails,
  (payouts) => payouts.surname,
);

export const getContactCity = createSelector(
  getPayoutDetails,
  (payouts) => payouts.city,
);

export const getContactAddress = createSelector(
  getPayoutDetails,
  (payouts) => payouts.address,
);

export const getContactPostalcode = createSelector(
  getPayoutDetails,
  (payouts) => payouts.postalcode,
);

export const getContactBirthdate = createSelector(
  getPayoutDetails,
  (payouts) => payouts.birthdate,
);

export const getContactError = createSelector(
  getPayoutDetails,
  (payouts) => payouts.contactError,
);

export const getLoadedStatus = createSelector(
  getStatus,
  (status) =>
    status !== PAYOUT_STATUS.INITIAL && status !== PAYOUT_STATUS.FETCHING,
);

export const getBankAccountUpdatingStatus = createSelector(
  getStatus,
  (status) =>
    status === PAYOUT_STATUS.BANK_ACCOUNT_UPDATING ||
    status === PAYOUT_STATUS.COUNTRY_UPDATING,
);

export const getContactInfoUpdatingStatus = createSelector(
  getStatus,
  (status) => status === PAYOUT_STATUS.CONTACT_INFO_UPDATING,
);

export const getUpdatingStatus = createSelector(
  [getBankAccountUpdatingStatus, getContactInfoUpdatingStatus],
  (bankAccountUpdating, contactInfoUpdating) =>
    bankAccountUpdating || contactInfoUpdating,
);
