import React from "react";
import { Spacer, Text } from "@badi/badi-components";
import { Avatar } from "app/badi-components";
import { withRouter } from "react-router";

import { openOtherUserProfileModal } from "components/other-user-profile-modal";

import { getNextPath } from "./utils";

import Status from "./components/status";
import Preview from "./components/preview";

import {
  InboxRowAvatar,
  InboxRowBorder,
  InboxRowCol,
  InboxRowContent,
  InboxRowDate,
  InboxRowWrapper,
} from "./styled-components";

export const InboxRow = ({ connection, router, token }) => {
  const { id, nextStep, room, user, visitId } = connection;

  const handleClick = () => {
    getNextPath({ id, nextStep, token, visitId }).then(({ path }) => {
      if (path) {
        router.push(path);
      }
    });
  };

  const handleOpenUserProfile = (event) => {
    event.stopPropagation();
    openOtherUserProfileModal(token, user.id);
  };

  return (
    <InboxRowCol data-qa="inbox-col" onClick={handleClick}>
      <InboxRowBorder>
        <Spacer bottom={3} top={3}>
          <InboxRowWrapper>
            <InboxRowAvatar>
              <Avatar
                badge={user.avatarBadge}
                onClick={handleOpenUserProfile}
                src={user.picture}
              />
            </InboxRowAvatar>
            <InboxRowContent>
              <Status
                hasNotifications={connection.hasNotifications}
                label={connection.statusBadgeLabel}
                level={connection.statusLevel}
                title={connection.statusTitle}
                userAge={user.age}
                userName={user.name}
              />
              <Preview
                billsIncluded={room.billsIncluded}
                externalAgencyId={room.externalAgencyId}
                hasNotifications={connection.hasNotifications}
                lastMessagePreview={connection.lastMessagePreview}
                roomPrice={room.price}
                roomTitle={room.title}
              />
            </InboxRowContent>
            <InboxRowDate>
              <Text body={3} data-qa="last-activity-date">
                {connection.lastActivityInWords}
              </Text>
            </InboxRowDate>
          </InboxRowWrapper>
        </Spacer>
      </InboxRowBorder>
    </InboxRowCol>
  );
};

export default withRouter(InboxRow);
