import styled from "styled-components";
import { colors, mediaquery, unitizedPx } from "@badi/badi-components";

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: ${unitizedPx(1)};

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.24);
  background-color: ${colors.white};

  button {
    width: 100%;

    ${mediaquery.md`
    width: auto;
    float: right;
   `}
  }
`;
