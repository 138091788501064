import styled, { css } from "styled-components";

import { unitizedPx } from "@badi/badi-components";

export const Picture = styled.img`
  display: block;
  object-fit: cover;
  width: ${unitizedPx(36)};
  height: ${unitizedPx(20)};
  max-width: 100%;
  border-radius: ${unitizedPx(1)};

  ${(props) =>
    props.isCurrentUser
      ? css`
          border-bottom-right-radius: 0;
        `
      : css`
          border-bottom-left-radius: 0;
        `}

  &:hover {
    cursor: pointer;
  }
`;
