import React, { Suspense } from "react";
import { fetchDynamicPopup } from "datamodel/Modals/api";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import DynamicInfo from "./dynamic-info";
import DynamicFeedback from "./dynamic-feedback";
import { CLOSABLE_TYPE } from "./constants";

const closeDynamicFeedbackModal = () => {
  modal.remove(MODAL_ID.INFO_FEEDBACK);
};

const openDynamicFeedbackModal = (info) => {
  const handleSubmit = () => {
    closeDynamicFeedbackModal();
  };

  modal.add(<DynamicFeedback info={info} onSubmit={handleSubmit} />, {
    id: MODAL_ID.INFO_FEEDBACK,
    type: MODAL_TYPE.DEFAULT,
    closeOnOutsideClick: true,
  });
};

export const closeDynamicInfoModal = () => {
  modal.remove(MODAL_ID.DYNAMIC_INFO);
};

export const submitDynamicInfoModal = (info) => {
  closeDynamicInfoModal();
  openDynamicFeedbackModal(info);
};

export const openDynamicInfoModal = (params, token) => {
  fetchDynamicPopup(params, token).then((info) => {
    const isClosable = info.data?.metadata?.type === CLOSABLE_TYPE;
    modal.add(
      <Suspense fallback={<div />}>
        <DynamicInfo
          info={info}
          onCancel={closeDynamicInfoModal}
          onSubmit={() => submitDynamicInfoModal(info)}
        />
      </Suspense>,
      {
        id: MODAL_ID.DYNAMIC_INFO,
        type: MODAL_TYPE.INFO,
        closeOnOutsideClick: isClosable,
        closable: isClosable,
      },
    );
  });
};
