import React, { Component } from "react";
import moment from "moment";
import merge from "lodash.merge";

import { Button, Flex, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { getSelectedIds, getSelectedElements } from "./utils";

import AvailabilityFields from "./availability/availability-fields";
import FlatFields from "./flat/flat-fields";
import RoomFields from "./room/room-fields";
import FlatmatesFields from "./flatmates/flatmates-fields";
import FlatmatesUnavailable from "./flatmates/flatmates-unavailable";
import SortByFields from "./sort-by/sort-by-fields";
import { SearchForm, FormActions, FormSection } from "./styled-components";
import { Separator } from "../../wrappers/styled-components";
import RangeField from "../components/range-field";
import MarketplaceSegmentsModalForm from "Sections/Search/search-v2/filters/marketplace-segments/marketplace-segments-modal-form";

class FiltersForm extends Component {
  constructor(props) {
    super(props);
    const { marketplaceSegmentsFilter } = this.props;

    this.state = {
      now: false,
      from: null,
      updated: false,
      min: 0,
      max: 0,
      flatAmenityIds: [],
      flatBenefitsIds: [],
      houseRuleIds: [],
      tenants: 0,
      bedTypeIds: [],
      bookable: false,
      roomAmenityIds: [],
      placeTypeId: 0,
      selectedSortById: 0,
      availableFlatmates: true,
      selectedGenders: [],
      marketplaceSegmentsFilter,
    };

    this.sortBy = translate(props.sortBy);
    this.flatAmenities = translate(props.flatAmenities);
    this.houseRules = translate(props.houseRules);
    this.flatBenefits = translate(props.flatBenefits);
    this.genders = translate(props.genders);
    this.roomAmenities = translate(props.roomAmenities);
    this.placeTypes = translate(props.placeTypes);
    this.bedTypes = translate(props.bedTypes);
    this.stayOptions = translate(props.stayOptions, ["title", "description"]);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      bedTypes,
      bookable,
      now,
      from,
      min,
      max,
      genders,
      tenants,
      flatAmenities,
      flatBenefits,
      houseRules,
      placeTypes,
      roomAmenities,
      sortBy,
      stayOptions,
    } = props;
    const selectedplaceType = placeTypes.find(
      (placeType) => placeType.selected,
    ) || { id: 0 };
    const placeTypeId = selectedplaceType.id;

    const selectedGenders =
      genders.filter((g) => g.selected).map(({ id }) => id) || [];

    const selectedSortBy = sortBy.find((sort) => sort.selected) || sortBy[0];

    if (state.updated) {
      return { updated: false };
    }

    return {
      availableFlatmates: placeTypeId !== 2,
      now,
      from: from ? moment(from) : null,
      min,
      max,
      selectedGenders,
      tenants,
      flatAmenityIds: getSelectedIds(flatAmenities),
      houseRuleIds: getSelectedIds(houseRules),
      flatBenefitsIds: getSelectedIds(flatBenefits),
      bedTypeIds: getSelectedIds(bedTypes),
      stayOptionsIds: getSelectedIds(stayOptions),
      bookable,
      placeTypeId,
      roomAmenityIds: getSelectedIds(roomAmenities),
      selectedSortById: selectedSortBy.id,
    };
  }

  handleChangeSortBy = (selectedSortById) => {
    this.setState({ selectedSortById, updated: true });
  };

  handleFromDateSelect = (fromDate) => {
    this.setState({
      fromDate,
      now: false,
      visibleCalendar: false,
      updated: true,
    });
  };

  handleNowSelect = () => {
    this.setState({ fromDate: "", now: true, updated: true });
  };

  handleMinPriceChange = (min) => {
    this.setState({ min, updated: true });
  };

  handleMaxPriceChange = (max) => {
    this.setState({ max, updated: true });
  };

  handleGenderChange = (selectedGenders) => {
    this.setState({ selectedGenders, updated: true });
  };

  handleTenantsChange = (tenants) => {
    this.setState({ tenants, updated: true });
  };

  handleBedTypeChange = (bedTypeIds) => {
    this.setState({ bedTypeIds, updated: true });
  };

  handleStayOptionChange = (stayOptionsIds) => {
    this.setState({ stayOptionsIds, updated: true });
  };

  handlePlaceTypeChange = (placeTypeId) => {
    const availableFlatmates = placeTypeId !== 2;
    const flatmates = {};

    if (!availableFlatmates) {
      flatmates.tenants = 0;
      flatmates.selectedGenders = [];
    }

    this.setState({
      availableFlatmates,
      placeTypeId,
      updated: true,
      ...flatmates,
    });
  };

  handleRoomAmenitySelect = (roomAmenityIds) => {
    this.setState({ roomAmenityIds, updated: true });
  };

  handleBookableChange = (bookable) => {
    this.setState({ bookable, updated: true });
  };

  handleFlatSelect = (flatAmenityIds) => {
    this.setState({ flatAmenityIds, updated: true });
  };

  handleBenefitSelect = (flatBenefitsIds) => {
    this.setState({ flatBenefitsIds, updated: true });
  };

  handleHouseRuleSelect = (houseRuleIds) => {
    this.setState({ houseRuleIds, updated: true });
  };

  setMarketplaceSegments = (newMarketplaceSegmentsFilterState) => {
    this.setState({
      marketplaceSegmentsFilter: merge(
        this.state.marketplaceSegmentsFilter,
        newMarketplaceSegmentsFilterState,
      ),
    });
  };

  handleApplyFilter = () => {
    const {
      fromDate,
      selectedGenders,
      flatAmenityIds,
      houseRuleIds,
      flatBenefitsIds,
      bedTypeIds,
      placeTypeId,
      roomAmenityIds,
      selectedSortById,
      stayOptionsIds,
    } = this.state;
    const {
      sortBy,
      bedTypes,
      roomAmenities,
      placeTypes,
      genders,
      houseRules,
      flatAmenities,
      flatBenefits,
      onClose,
      setParams,
      stayOptions,
    } = this.props;

    const search = {
      ...this.state,
      fromDate,
      flatAmenities: getSelectedElements(flatAmenities, flatAmenityIds),
      houseRules: getSelectedElements(houseRules, houseRuleIds),
      flatBenefits: getSelectedElements(flatBenefits, flatBenefitsIds),
      genders: getSelectedElements(genders, selectedGenders),
      placeTypes: getSelectedElements(placeTypes, [placeTypeId]),
      roomAmenities: getSelectedElements(roomAmenities, roomAmenityIds),
      bedTypes: getSelectedElements(bedTypes, bedTypeIds),
      stayOptions: getSelectedElements(stayOptions, stayOptionsIds),
      sortBy: getSelectedElements(sortBy, [selectedSortById]),
    };
    setParams(search);
    onClose();
  };

  render() {
    const {
      availableFlatmates,
      selectedGenders,
      tenants,
      marketplaceSegmentsFilter,
    } = this.state;
    const { onClose } = this.props;

    return (
      <SearchForm>
        <Flex direction="column">
          <FormSection>
            <SortByFields
              {...this.state}
              onChange={this.handleChangeSortBy}
              sortBy={this.sortBy}
            />
          </FormSection>
          {
            <FormSection>
              <MarketplaceSegmentsModalForm
                filtersState={marketplaceSegmentsFilter}
                setFiltersState={this.setMarketplaceSegments}
              />
            </FormSection>
          }
          <FormSection>
            <RangeField
              {...this.state}
              description={translate("search.filters.budget.currency")}
              onMaxValueChange={this.handleMaxPriceChange}
              onMinValueChange={this.handleMinPriceChange}
              title={translate("search.filters.budget")}
            />
          </FormSection>
          <Separator />
          <FormSection>
            <AvailabilityFields
              {...this.state}
              onFromDateSelect={this.handleFromDateSelect}
              onNowSelect={this.handleNowSelect}
              onStayOptionChange={this.handleStayOptionChange}
              stayOptions={this.stayOptions}
            />
          </FormSection>

          <Separator />
          <FormSection>
            {availableFlatmates ? (
              <FlatmatesFields
                genders={this.genders}
                onGenderChange={this.handleGenderChange}
                onTenantsChange={this.handleTenantsChange}
                selectedGenders={selectedGenders}
                tenants={tenants}
              />
            ) : (
              <FlatmatesUnavailable />
            )}
          </FormSection>
          <Separator />
          <FormSection>
            <RoomFields
              {...this.state}
              bedTypes={this.bedTypes}
              onBedTypeChange={this.handleBedTypeChange}
              onBookableChange={this.handleBookableChange}
              onPlaceTypeChange={this.handlePlaceTypeChange}
              onRoomAmenitySelect={this.handleRoomAmenitySelect}
              placeTypes={this.placeTypes}
              roomAmenities={this.roomAmenities}
            />
          </FormSection>
          <Separator />
          <FormSection>
            <FlatFields
              {...this.state}
              flatAmenities={this.flatAmenities}
              flatBenefits={this.flatBenefits}
              houseRules={this.houseRules}
              onBenefitSelect={this.handleBenefitSelect}
              onFlatSelect={this.handleFlatSelect}
              onHouseRuleSelect={this.handleHouseRuleSelect}
            />
          </FormSection>
          <FormActions>
            <Spacer bottom={1} left={2} right={2} top={1}>
              <Flex direction="row" gutter={1}>
                <Button
                  fullWidth={true}
                  grow={1}
                  onClick={onClose}
                  variant="secondary"
                >
                  {translate("search.filters.cancel")}
                </Button>
                <Button
                  fullWidth={true}
                  grow={1}
                  onClick={this.handleApplyFilter}
                >
                  {translate("search.filters.button")}
                </Button>
              </Flex>
            </Spacer>
          </FormActions>
        </Flex>
      </SearchForm>
    );
  }
}

FiltersForm.defaultProps = {
  fromDate: "",
};

export default FiltersForm;
