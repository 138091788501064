import styled from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const LogoWrapper = styled.div`
  position: absolute;
  left: calc(50% - 31px);

  > a {
    display: flex;
  }

  ${mediaquery.lg`
    position: static;
    left: initial;
  `}
`;
