import styled from "styled-components";
import { unitizedPx, BADI_COLORS } from "@badi/badi-components";

export const Root = styled.div`
  min-height: ${unitizedPx(75)};
  height: ${unitizedPx(75)};
`;

export const ImageWrapper = styled.a`
  cursor: pointer;
`;

export const Description = styled.div`
  max-width: ${unitizedPx(75)};
`;

export const Divider = styled.div`
  padding-top: ${unitizedPx(2)};
  border-top: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
`;
