import React from "react";
import { withFormsy } from "formsy-react";
import DropZoneMapping from "./DropZoneMapping";

class DropZoneWithFormsy extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onUpdate = this.onUpdate.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onUpdate(value) {
    const { getValue, setValue } = this.props;
    const files = getValue() || [];

    if (Array.isArray(value)) {
      setValue(value);
    } else {
      const values = [...files];
      const i = values.findIndex((v) => v.tempId === value.tempId);

      if (i >= 0) values[i] = value;
      else values.push(value);

      setValue(values);
    }
  }

  onRemove(id) {
    const files = this.props.getValue();
    this.props.setValue(files.filter((f) => f.tempId !== id));
  }

  render() {
    const { getValue, ...props } = this.props;
    const safeValues = getValue();

    return (
      <DropZoneMapping
        onRemove={this.onRemove}
        onUpdate={this.onUpdate}
        files={safeValues}
        {...props}
      />
    );
  }
}

export default withFormsy(DropZoneWithFormsy);
