import React from "react";
import { modal, MODAL_TYPE, MODAL_ID } from "containers/ReduxModal/emitter";
import AuthenticationFormMapping from "./authentication-form-mapping";

export const closeAuthenticationModal = () => {
  modal.remove(MODAL_ID.AUTH);
};

export const openAuthenticationModal = ({ mode, onSuccess, onClose }) => {
  modal.add(
    <AuthenticationFormMapping
      initialMode={mode}
      onSuccess={() => {
        onClose();
        if (typeof onSuccess === "function") onSuccess();
      }}
    />,
    {
      id: MODAL_ID.AUTH,
      override: true,
      type: MODAL_TYPE.CUSTOM,
      onClose,
      onIconClose: onClose,
    },
  );
};
