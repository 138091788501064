import React from "react";
import { Button } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import DefaultLayout from "./layout";
import CompactedLayout from "./compacted-layout";

const TimedOut = ({
  date,
  onLeaveFeedback,
  onLeaveVisit,
  roomDetails,
  time,
  userImage,
  userName,
  compact = false,
}) => {
  const handleMessageClick = (event) => {
    event.preventDefault();
    onLeaveVisit();
  };

  const handleFeedbackClick = (event) => {
    event.preventDefault();
    onLeaveFeedback();
  };

  const Layout = compact ? CompactedLayout : DefaultLayout;

  return (
    <Layout
      description={translate("videocall.not.available_text", {
        visit_date: `${date}, ${time}`,
      })}
      image="/assets/images/not-found.svg"
      roomDetails={roomDetails}
      title={translate("videocall.not.available_title")}
      userImage={userImage}
    >
      <Button
        data-qa="timed-out-message-button"
        fullWidth={true}
        onClick={handleMessageClick}
        variant="secondary"
      >
        {translate("videocall.not.available_primary_cta", { name: userName })}
      </Button>
      <Button
        data-qa="timed-out-feedback-button"
        fullWidth={true}
        onClick={handleFeedbackClick}
      >
        {translate("videocall.not.available_secondary_cta")}
      </Button>
    </Layout>
  );
};

export default TimedOut;
