import React from "react";
import { MenuList, MenuListItem } from "./styled-components";

const MenuWrapper = ({ children }) => (
  <MenuList data-qa="menu-options-list">
    {children.map((child) =>
      child ? (
        <MenuListItem
          key={child.props["data-qa"] || child.props.dataQa || child.key}
        >
          {child}
        </MenuListItem>
      ) : null,
    )}
  </MenuList>
);

export default MenuWrapper;
