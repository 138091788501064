import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const SearchLocationWrapper = styled.div`
  > div {
    .geosuggest__input {
      border: none;
      outline: none;
      background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
      font-size: 14px;
      color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
      padding: 0px;
      min-height: auto;
      font-weight: normal;
    }

    .geosuggest__suggests.extendedWidth {
      top: ${unitizedPx(7.25)};
      left: -${unitizedPx(5)};
      z-index: 3;
      border: none;
      box-shadow:
        0 2px 6px rgba(0, 0, 0, 0.05),
        0 0 0 1px rgba(0, 0, 0, 0.07);
      width: calc(100% + 40px);
      text-overflow: ellipsis;

      li {
        height: ${unitizedPx(8)};
        padding: 0;
        display: flex;
        align-items: center;

        > div > span {
          font-size: 14px;
          line-height: 1.43;
        }
      }
    }
  }
`;
