import React from "react";
import GoogleMapReact from "google-map-react";

const GOOGLE_MAP_OPTIONS = {
  fullscreenControl: false,
};

const GoogleMap = (args) => {
  const props = {
    options: GOOGLE_MAP_OPTIONS,
    ...args,
  };
  return <GoogleMapReact {...props} />;
};

export default GoogleMap;
