import React, { memo } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import FormBlock from "components/form-block";
import { useForm } from "components/hooks";
import SocialProfilesWithFormsy from "./SocialProfilesWithFormsy";

const SocialProfiles = ({ socialProfiles, saved }) => {
  const [validations, setInputValidation] = useForm({
    facebook: true,
    instagram: true,
    twitter: true,
    linkedin: true,
  });

  const isValidBlock = !Object.values(validations).some(
    (validation) => !validation,
  );

  return (
    <FormBlock
      dataQa="profile-form-social-profiles-section"
      id="formblock-social-profiles"
      saved={saved}
      subTitle={translate("profile.form.label.social_media.text")}
      title={translate("profile.form.label.social_media.title")}
      valid={isValidBlock}
    >
      <SocialProfilesWithFormsy
        name="social-profiles"
        setInputValidation={setInputValidation}
        value={socialProfiles}
      />
      <Spacer bottom={3} />
    </FormBlock>
  );
};

export default memo(SocialProfiles);
