import React, { useCallback } from "react";
import { Button, Flex, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import UserProfileBadge from "components/user-profile-badge";
import LockIcon from "./lock-icon";
import { RecommendationRoot } from "../styled-components";

const Recommendation = ({
  acceptRecommendation,
  blocked,
  user,
  rejectRecommendation,
  showUserModal,
  isHidden = false,
}) => {
  const { id: userId } = user;

  const onAcceptRecommendation = useCallback(() => {
    acceptRecommendation(userId);
  }, [acceptRecommendation, userId]);

  const onRejectRecommendation = useCallback(() => {
    rejectRecommendation(userId);
  }, [rejectRecommendation, userId]);

  return (
    <RecommendationRoot data-qa="recommendation" isHidden={isHidden}>
      <Spacer bottom={3} top={3}>
        <Flex
          direction="column"
          gutter={2}
          md={{
            direction: "row",
            alignItems: "center",
            justify: "spaceBetween",
            gutter: 0.1, // this until we fix the gutter 0
          }}
        >
          <UserProfileBadge {...user} onClick={showUserModal} />
          <Flex gutter={1}>
            <Button
              data-qa="recommendation-reject-invite"
              fullWidth={true}
              grow={1}
              name="recommendation-reject-invite"
              onClick={onRejectRecommendation}
              variant="secondary"
            >
              {translate("recommendations.action.ignore")}
            </Button>
            <Button
              data-qa="recommendation-send-invite"
              fullWidth={true}
              grow={1}
              name="recommendation-send-invite"
              onClick={onAcceptRecommendation}
            >
              {blocked && (
                <Spacer right={1}>
                  <LockIcon />
                </Spacer>
              )}
              {translate("recommendations.action.contact")}
            </Button>
          </Flex>
        </Flex>
      </Spacer>
    </RecommendationRoot>
  );
};

export default Recommendation;
