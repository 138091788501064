import { connect } from "react-redux";

import Room from "Sections/Room/components/Steps/Room";

import Actions from "datamodel/RoomList/actions";

import { onboardingRoleSelector } from "datamodel/User/selectors";
import {
  getRoomIdSelector,
  getRoomDetailsSelector,
  getSizeUnitSelector,
  showGermanPropertyRegulationSelector,
  isEntiredPropertySelector,
} from "datamodel/RoomList/selectors";

const mapStateToProps = (state) => ({
  roomId: getRoomIdSelector(state),
  storeRoom: getRoomDetailsSelector(state),
  sizeUnit: getSizeUnitSelector(state),
  showGermanDisclaimer: showGermanPropertyRegulationSelector(state),
  isEntiredProperty: isEntiredPropertySelector(state),
  onboardingRole: onboardingRoleSelector(state),
});

const mapDispatchToProps = {
  setRoomRoom: Actions.setRoomRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
