import styled from "styled-components";
import { colors, mediaquery, unitizedPx } from "@badi/badi-components";

export const Wrapper = styled.div`
  border-top: 1px solid ${colors.lightGrey};
  padding-bottom: ${unitizedPx(4)};
  margin-bottom: ${unitizedPx(6)};

  ${mediaquery.lg`
    border-top: 0;
  `}
`;
