import React from "react";
import { Heading, Text, Spacer, Flex } from "@badi/badi-components";
import { getPathName } from "base/shared/Utils";

import { translate } from "base/shared/Localization";

import { LinkBlock } from "./styled-components";

const SeoLink = ({ links, city }) => {
  return (
    <LinkBlock as={Flex} direction="column">
      <Heading level="16-Bold">{translate(city)}</Heading>
      <Spacer bottom={1} />
      {links.map((link) => (
        <Spacer bottom={1} key={translate(link.title)}>
          <a href={getPathName(translate(link.link))} target="_blank">
            <Text>{translate(link.title, { city })}</Text>
          </a>
        </Spacer>
      ))}
    </LinkBlock>
  );
};

export default SeoLink;
