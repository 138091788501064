import { NotificationAction, NotificationType } from "../models";

export const NOTIFICATION_EVENT_NAME = "notification_update";

class NotificationsEventHandler {
  static subscribe(eventCallback) {
    window.addEventListener(NOTIFICATION_EVENT_NAME, eventCallback);
  }

  static unsubscribe(eventCallback) {
    window.removeEventListener(NOTIFICATION_EVENT_NAME, eventCallback);
  }

  static emit(eventName, detail) {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  }

  static add(payload) {
    const {
      id,
      duration = 5000,
      preventDuplicated = false,
      type = NotificationType.INFO,
    } = payload;
    NotificationsEventHandler.emit(NOTIFICATION_EVENT_NAME, {
      id,
      duration,
      preventDuplicated,
      type,
      uid: Date.now(),
      payload,
      action: NotificationAction.ADD,
    });
  }

  static remove(id) {
    NotificationsEventHandler.emit(NOTIFICATION_EVENT_NAME, {
      id,
      uid: Date.now(),
      action: NotificationAction.REMOVE,
    });
  }
}

export default NotificationsEventHandler;
