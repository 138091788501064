import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Button, BUTTON_TYPES } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { isLister } from "app/datamodel/User/utils";
import { getUserRole } from "app/datamodel/User/selectors";

import BookingFooter from "../footer";

const ConfirmationFooter = ({
  isFirstStep = false,
  onDecline = () => {},
  onSubmit = () => {},
  previousStep = () => {},
  stepIsValid,
  disabled,
}) => {
  const userRole = useSelector(getUserRole);

  const defaultButtonGroup = [
    {
      dataQa: "decline-button",
      onClick: onDecline,
      disabled,
      variant: BUTTON_TYPES.SECONDARY,
      alt: "decline button",
      text: "booking.new.decline_button",
    },
    {
      dataQa: "submit-button",
      disabled: !stepIsValid || disabled,
      onClick: onSubmit,
      variant: BUTTON_TYPES.PRIMARY,
      alt: "submit button",
      text: "booking.reply.submit_button",
    },
  ];

  const secondButtonGroup = [
    {
      dataQa: "back-button",
      disable: disabled,
      onClick: previousStep,
      variant: "secondary",
      text: "booking.reply.back_button",
    },
    {
      dataQa: "submit-button",
      disable: !stepIsValid || disabled,
      onClick: onSubmit,
      variant: BUTTON_TYPES.PRIMARY,
      text: "booking.reply.submit_button",
    },
  ];

  return (
    <BookingFooter showBookingIsFreeCopy={isLister(userRole)}>
      {isFirstStep
        ? defaultButtonGroup.map(({ src, text, ...props }) => (
            <Button {...props} key={props.dataQa}>
              {translate(text)}
            </Button>
          ))
        : secondButtonGroup.map(
            ({ dataQa, disable, onClick, variant, text }) => (
              <Button
                data-qa={dataQa}
                disabled={disable}
                fullWidth={true}
                grow={1}
                onClick={onClick}
                variant={variant}
              >
                {translate(text)}
              </Button>
            ),
          )}
    </BookingFooter>
  );
};

export default memo(ConfirmationFooter);
