import { getPicture } from "base/shared/Utils";
import { getUserOccupation, getUserLanguages } from "base/shared/User";

const parseViewer = (viewer) => {
  const {
    id,
    age,
    cover_picture: pictures,
    first_name: firstName,
    occupation_detail: occupation,
    languages_by_affinity: languages,
    viewed_ago: viewerInfo,
  } = viewer;

  return {
    age,
    avatar: getPicture([pictures], "width_100_url"),
    id,
    name: firstName,
    occupation: getUserOccupation(occupation),
    languages: getUserLanguages(languages),
    viewerInfo,
  };
};

const makeViewersSet = (viewers, id) =>
  viewers.map((viewer) => {
    return {
      ...viewer,
      contacted: viewer.id === id || viewer.contacted,
    };
  });

const parseViewersArray = (viewers) => viewers.map(parseViewer);

export { parseViewer, parseViewersArray, makeViewersSet };
