import React, { memo } from "react";
import { BADI_COLORS } from "../constants";
import { Text } from "@badi/badi-components";
import { TextAreaWrapper, StyledText } from "./styled-components";

const TextArea = ({
  dataQa = "textarea-text",
  maxlength = 0,
  minlength = 0,
  name,
  onChange = null,
  placeholder = "",
  setReference = null,
  value = "",
  counterText = "",
  width = "100%",
  height = "104px",
  disabled = false,
  error = false,
  inactive = false,
  onBlur = null,
  onFocus = null,
  borderColor,
}) => {
  return (
    <TextAreaWrapper>
      <StyledText
        data-qa={dataQa}
        disabled={disabled}
        error={error}
        inactive={inactive}
        maxlength={maxlength || undefined}
        minlength={minlength || undefined}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        ref={setReference}
        textHeight={height}
        textWidth={width}
        value={value}
        borderColor={borderColor}
      />
      <Text
        align="right"
        body="12-Regular"
        color={
          error
            ? BADI_COLORS.SEMANTIC.DANGER.DEFAULT
            : BADI_COLORS.NEUTRAL.NEUTRAL_50
        }
        dataQa={`${dataQa}-helper`}
      >
        {counterText}
      </Text>
    </TextAreaWrapper>
  );
};

export default memo(TextArea);
