import React from "react";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";

import BookingGuarantee from "./booking-guarantee";

export const closeBookingGuaranteeModal = () => {
  modal.remove(MODAL_ID.BOOKING_GUARANTEE);
};

export const openBookingGuaranteeModal = ({ userRole }) => {
  modal.add(
    <BookingGuarantee
      onClick={closeBookingGuaranteeModal}
      userRole={userRole}
    />,
    {
      id: MODAL_ID.BOOKING_GUARANTEE,
      closable: false,
    },
  );
};
