import React from "react";
import { Checkbox, Flex, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const ConsentCheckbox = ({ id, accepted, onChange, label, link = "" }) => (
  <Flex key={id}>
    <Spacer right={1} top={0.5}>
      <Checkbox
        checked={accepted}
        id={id}
        name={`${id}-checkbox`}
        onChange={onChange}
      />
    </Spacer>

    <Text>
      {link ? (
        <span
          dangerouslySetInnerHTML={{
            __html: translate(label, {
              link: translate(link),
            }),
          }}
          data-qa="gdpr-consent-link"
        />
      ) : (
        <span data-qa="gdpr-consent-label">{translate(label)}</span>
      )}
    </Text>
  </Flex>
);

export default ConsentCheckbox;
