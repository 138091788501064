import React, { memo } from "react";

import { translate } from "base/shared/Localization";
import { LOGIN_TYPE } from "datamodel/User/constants";

import LayoutWrapper from "components/Layout/Wrapper";
import SideBarMenu from "components/SideBarMenu";

import { getSettingsMenuItems } from "../utils";

const Privacy = ({ loginType }) => {
  const menuItems = translate(
    getSettingsMenuItems(loginType !== LOGIN_TYPE.EMAIL, "privacy"),
  );

  return (
    <LayoutWrapper>
      <div className="layout__inner">
        <div className="layout__columns">
          <SideBarMenu
            autoScroll={false}
            className="info__item_link"
            items={menuItems}
            itemType="link"
            title="settings.title"
          />
          <main className="content privacy__content" role="main">
            <section className="section section_checked">
              <header className="section__head">
                <div className="section__actions" />
                <h2 className="section__title">
                  {translate("settings.privacy.privacypolicy_title")}
                </h2>
                <div
                  className="form__text"
                  dangerouslySetInnerHTML={{
                    __html: translate("settings.privacy.privacypolicy_desc", {
                      terms_link: translate("sitemap.privacy.link"),
                    }),
                  }}
                />
              </header>
              <div className="section__main" />
            </section>
          </main>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default memo(Privacy);
