import React from "react";
import MultiShare from "components/multi-share";

import { translate } from "base/shared/Localization";

const ShareRoom = (props) => {
  const { modalTitle, roomUrl, social, title } = props;

  return (
    <div>
      <h3 className="title_h2_light text_center">{translate(modalTitle)}</h3>
      <div className="popup__social">
        <MultiShare social={social} target={roomUrl} title={translate(title)} />
      </div>
    </div>
  );
};

ShareRoom.defaultProps = {
  modalTitle: "Get more visibility by sharing your ad",
  social: ["messenger", "facebook", "twitter", "copy"],
};

export default ShareRoom;
