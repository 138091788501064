import React from "react";
import { Card, Flex, Text } from "@badi/badi-components";
import Image from "components/Image/Image";
import { Wrapper } from "./styled-components";

const RadioButtonCard = ({
  checked,
  imageUrl = null,
  onChange,
  value,
  label = "",
  key,
  dataQa,
}) => {
  const handleClick = () => {
    if (!checked) onChange({ currentTarget: { value } });
  };
  return (
    <Wrapper
      aria-checked={checked}
      as={Card}
      data-qa={`${dataQa}-${value}`}
      key={key}
      onClick={handleClick}
      role="menuitemradio"
      selected={checked}
      tabIndex="0"
      withShadow={false}
    >
      <Flex alignItems="center" gutter={2}>
        {imageUrl && <Image src={imageUrl} />}
        <Text bold={checked}>{label}</Text>
      </Flex>
    </Wrapper>
  );
};

export default RadioButtonCard;
