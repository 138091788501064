import React, { Component } from "react";

import UserFeedback from "base/shared/user-feedback";

import ReportIds from "./ReportIds";
import { getReportType, report } from "./utils";

import ReportForm from "./ReportForm";
import ReportResult from "./ReportResult";
import "./ReportExt.css";

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      reportType: props.ids.getType(),
      success: undefined,
    };
    this.onFormSubmitted = this.onFormSubmitted.bind(this);
    this.report = this.report.bind(this);
  }

  onFormSubmitted(id, description) {
    const { reportType } = this.state;

    if (reportType === "reportType") {
      this.setState({ reportType: getReportType(id) });
    } else {
      this.setState({ loading: true }, () =>
        this.report(reportType, id, description),
      );
    }
  }

  report(reportType, optionId, description) {
    const { callback, ids, token } = this.props;
    report(token, reportType, ids.getId(reportType), optionId, description)
      .then((data) => {
        if (data === 201) this.setState({ loading: false, success: true });
      })
      .catch((e) => {
        if (
          e.code === "user_already_reported" ||
          e.code === "room_already_reported"
        ) {
          this.setState({ loading: false, success: false });
        } else {
          UserFeedback.exception("Something went wrong", e.message, e);
          callback();
        }
      });
  }

  render() {
    const { loading, reportType, success } = this.state;

    return typeof success !== "undefined" ? (
      <ReportResult
        type={reportType}
        success={success}
        onClick={this.props.callback}
      />
    ) : (
      <ReportForm
        loading={loading}
        type={reportType}
        onSubmit={this.onFormSubmitted}
      />
    );
  }
}

export default Report;
