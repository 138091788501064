import React from "react";
import classNames from "classnames";

const TagCategoryItem = ({
  className,
  disabled,
  id,
  label,
  mode,
  onClick,
  selected,
}) => {
  const classname = classNames(
    "TagCategory__item",
    { TagCategory__item_selected: selected },
    className,
  );

  const clickHandler = () => onClick(id);

  return mode === "edit" ? (
    <button
      className={classname}
      disabled={disabled}
      onClick={clickHandler}
      type="button"
    >
      {label}
    </button>
  ) : (
    <div className={classname}>{label}</div>
  );
};

TagCategoryItem.defaultProps = {
  className: null,
  disabled: false,
  selected: true,
  onClick: () => {},
};

export default TagCategoryItem;
