import React from "react";
import { Icon, Flex, colors } from "@badi/badi-components";

import { InfoBarWrapper, CloseButton } from "./styled-components";

const InfoBarWrapperUi = ({
  onClose = null,
  onClick = null,
  children = null,
}) => (
  <InfoBarWrapper data-qa="info-bar-wrapper" onClick={onClick}>
    <Flex alignItems="center" fullHeight={true} gutter={1} justify="center">
      {children}
    </Flex>
    <CloseButton data-qa="info-bar-wrapper-close" onClick={onClose}>
      <Icon
        color={colors.white}
        data-qa="info-bar-wrapper-icon"
        height={14}
        name="close"
        width={14}
      />
    </CloseButton>
  </InfoBarWrapper>
);

export default InfoBarWrapperUi;
