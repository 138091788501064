import React from "react";
import SuccessPage from "components/success-page";
import { getRelativePath } from "base/shared/Utils";

const FastTrackPurchaseSuccess = ({ location: { query } }) => {
  const { roomId } = query;
  const href = getRelativePath(
    roomId ? `/room/${roomId}?open_flow=enquiry` : "/",
  );
  return (
    <SuccessPage
      title={"badi.fast-track.success.title"}
      description={"badi.fast-track.success.subtitle"}
      cta={"badi.fast-track.success.cta"}
      asset="/icons/fast-track/fast-track-profile-card.svg"
      href={href}
    />
  );
};

export default FastTrackPurchaseSuccess;
