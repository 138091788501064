import React, { Component } from "react";

import UserFeedback from "base/shared/user-feedback";

import RoomForm from "Sections/Room/components/room-form";

import { mapRoomData } from "../utils";

const STATUS = {
  INITIAL: 0,
  FETCHING: 1,
  PUBLISHING: 2,
  SAVED: 3,
  ERROR: 4,
  IDLE: 5,
};
const isFormDisabled = (status) => {
  return [STATUS.FETCHING, STATUS.PUBLISHING].some((item) => item === status);
};

class EditRoom extends Component {
  state = {
    status: STATUS.INITIAL,
    waitForModeration: false,
  };

  componentDidMount() {
    const { getRoom, roomId } = this.props;

    getRoom(roomId);
  }

  handleSuccessfulModeration = () => {
    const { onSuccessfulModeration } = this.props;

    UserFeedback.success(
      "room.list.edit.saved.title",
      "room.list.edit.saved.description",
    );

    this.setState({ status: STATUS.SAVED }, onSuccessfulModeration);
  };

  handleModerationTimeout = () => {
    const { isInReview, onSuccessfulModeration } = this.props;

    if (isInReview) {
      UserFeedback.info("Room update", "The room is under review");
    } else {
      UserFeedback.success(
        "room.list.edit.saved.title",
        "room.list.edit.saved.description",
      );
    }
    onSuccessfulModeration();
  };

  handleError = (error) => {
    this.setState({ status: STATUS.ERROR });

    UserFeedback.exception(
      "Whoops!",
      "Something went wrong, try again or contact support.",
      error,
    );
  };

  handleSubmit = async (payload = {}) => {
    const { roomId, fields, forcePublish, updateRoom, publishRoom } =
      this.props;

    const params = mapRoomData({ ...fields, ...payload });

    this.setState({ status: STATUS.FETCHING });

    try {
      this.setState({ waitForModeration: true });
      await updateRoom(roomId, params);
      if (forcePublish) {
        await publishRoom(roomId);
      }
      this.setState({ status: STATUS.SAVED });
    } catch (error) {
      this.handleError(error);
      this.setState({ waitForModeration: false });
    }
  };

  disableWaitForModeration = () => this.setState({ waitForModeration: false });

  render() {
    const {
      onLeaveFlow,
      roomStatus,
      registerOnLeaving,
      onNotOwnedRoom,
      owned,
      roomId,
    } = this.props;
    const { status, waitForModeration } = this.state;

    if (owned !== undefined && owned === false) {
      registerOnLeaving(() => true);
      onNotOwnedRoom();
    }

    return (
      <RoomForm
        allowCanceling={status === STATUS.SAVED}
        disableWaitForModeration={this.disableWaitForModeration}
        disabled={isFormDisabled(status)}
        onLeaveFlow={onLeaveFlow}
        onModerationTimeout={this.handleModerationTimeout}
        onSubmit={this.handleSubmit}
        onSuccessfulModeration={this.handleSuccessfulModeration}
        registerOnLeaving={registerOnLeaving}
        roomId={roomId}
        status={roomStatus}
        waitForModeration={waitForModeration}
      />
    );
  }
}

EditRoom.defaultProps = {
  fields: {},
  forcePublish: false,
  roomStatus: 0,
};

export default EditRoom;
