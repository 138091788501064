import React from "react";
import { Col, Row } from "reactstrap";
import {
  BADI_COLORS,
  Heading,
  Flex,
  Spacer,
  Text,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";
import { ICON_CONTENT_TYPE } from "../../constants";
import { ImageWrapper, NumberWrapper, Root } from "./styled-components";

const ListRoomApartmentContentList = ({ disposition = {}, items }) => (
  <Root as={Row}>
    {items.map(({ title, description, icon }) => (
      <Col key={title} {...disposition}>
        <Flex direction="column" gutter={1}>
          <Spacer bottom={2}>
            {icon.type === ICON_CONTENT_TYPE.IMAGE && (
              <ImageWrapper>
                <Image lazy={false} src={icon.value} />
              </ImageWrapper>
            )}
            {icon.type === ICON_CONTENT_TYPE.NUMBER && (
              <NumberWrapper>
                <Heading
                  color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                  data-qa="content-list-item-number"
                  level="20-Bold"
                >
                  {icon.value}
                </Heading>
              </NumberWrapper>
            )}
          </Spacer>
          {!!title && (
            <Heading data-qa="content-list-item-title" level="20-Bold">
              {translate(title)}
            </Heading>
          )}
          <Text data-qa="content-list-item-text">{translate(description)}</Text>
        </Flex>
        <Spacer bottom={4} />
      </Col>
    ))}
  </Root>
);

export default ListRoomApartmentContentList;
