import RoomModel from "datamodel/Room/models/room-model";

class ReferralRoomModel extends RoomModel {
  get picture() {
    return this.coverPicture;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      picture: this.picture,
    };
  }
}

export default ReferralRoomModel;
