import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  BUTTON_TYPES,
} from "@badi/badi-components";
import { MODAL_ID } from "containers/ReduxModal/emitter";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { deleteRoom } from "datamodel/Room/api";
import { setRentals } from "datamodel/search-rooms/api";
import { getToken } from "datamodel/Auth/selectors";
import { openRentedToModal } from "../rented-to";
import { getContent } from "./utils";

const DeleteRoom = ({ askIsRented = false, onSuccess, onCancel, id }) => {
  const token = useSelector(getToken);

  const handleError = (error) => {
    UserFeedback.exception(
      "Something went wrong",
      error.code ? error.message : "Please, try again or contact support.",
      error,
    );
  };

  const handleDeleteRoom = (modalId) => {
    deleteRoom(id, token)
      .then(({ succeed }) => {
        if (succeed) {
          onSuccess(modalId);
        }
      })
      .catch(handleError);
  };

  const handleCancelClick = () => {
    if (askIsRented) {
      handleDeleteRoom();
    } else {
      onCancel();
    }
  };

  const setRentalsAndDeleteRoom = (params) =>
    setRentals(params, token)
      .then(() => {
        handleDeleteRoom(MODAL_ID.MARK_ROOM_AS_RENTED);
      })
      .catch(handleError);

  const handleConfirmClick = () => {
    if (!askIsRented) {
      handleDeleteRoom();
      return;
    }

    openRentedToModal({
      id,
      onUserSelected: setRentalsAndDeleteRoom,
      token,
    });
  };

  const { title, description, confirmButtonText, cancelButtonText } =
    getContent(askIsRented);

  return (
    <Flex direction="column">
      <Heading data-qa="delete-room-title" level="20-Bold">
        {translate(title)}
      </Heading>
      <Spacer top={2}>
        <Text body="14-Regular" dataQa="delete-room-desc">
          {translate(description)}
        </Text>
      </Spacer>
      <Spacer top={7}>
        <Button
          data-qa="delete-room-submit"
          fullWidth={true}
          onClick={handleConfirmClick}
        >
          {translate(confirmButtonText)}
        </Button>
      </Spacer>
      <Spacer top={2}>
        <Button
          data-qa="delete-room-cancel"
          fullWidth={true}
          onClick={handleCancelClick}
          variant={BUTTON_TYPES.SECONDARY}
        >
          {translate(cancelButtonText)}
        </Button>
      </Spacer>
    </Flex>
  );
};

export default DeleteRoom;
