import React from "react";
import { Text, Spacer, Flex, TextButton, Icon } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { PrecontentBar } from "../styled-components";

const LowerBar = ({ onClick }) => (
  <PrecontentBar>
    <Flex alignItems="center" fullHeight={true} justify="flexStart">
      <Spacer left={3}>
        <TextButton
          data-qa="enquiry-back-button"
          onClick={onClick}
          withIcon={<Icon src={getPublicAsset("icons/enquiry-back.svg")} />}
        >
          <Text body="12-Bold">{translate("Back").toUpperCase()}</Text>
        </TextButton>
      </Spacer>
    </Flex>
  </PrecontentBar>
);

export default LowerBar;
