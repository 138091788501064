import React from "react";
import { Link } from "react-router";
import { HEADER_PARAMS } from "base/shared/tracking-headers/static";
import { stringify } from "qs";
import { Heading, Text, Flex } from "@badi/badi-components";
import Image from "components/Image/Image";
import { translate } from "base/shared/Localization";
import { CityCard, StyledImage } from "./styled-components";

const ET_SEARCH_SOURCE_KEY = HEADER_PARAMS.SEARCH_SOURCES.keyVal;

const FeaturedCity = ({
  id,
  image,
  link,
  title,
  properties,
  countryCode = null,
}) => {
  const searchSourceParam = stringify({
    [ET_SEARCH_SOURCE_KEY]: "main-city-button",
  });
  const urlWithSearchSourceParam = `${link}?${searchSourceParam}`;
  return (
    <CityCard>
      <Link to={urlWithSearchSourceParam}>
        <Flex gutter={1.75}>
          <StyledImage alt={title} as={Image} src={image} />
          <Flex direction="column" fullHeight={true} gutter={0.5}>
            <Heading level="16-Bold">{title}</Heading>
            <Text body="12-Regular">
              {translate("home.cities.properties", { properties })}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </CityCard>
  );
};

export default FeaturedCity;
