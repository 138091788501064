import { connect } from "react-redux";
import MarketplaceSegmentsFilterButton from "./marketplace-segments-filter-button";
import Actions from "datamodel/SearchFilters/actions";
import Types from "datamodel/SearchFilters/types";
import { getSearchFiltersMarketplaceSegmentsValues } from "datamodel/SearchFilters/selectors";

const mapStateToProps = (state, props) => ({
  selectedSegments: getSearchFiltersMarketplaceSegmentsValues(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onCancelFilter: () =>
    dispatch({
      type: Types.RESET_SEARCHFILTERS_MARKETPLACE_SEGMENTS,
      searchUpdate: true,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketplaceSegmentsFilterButton);
