class NotificationPermissionController {
  askPermission = () => {
    if ("Notification" in window && "requestPermission" in Notification) {
      return Notification.requestPermission();
    }
    return null;
  };

  checkIfPermissionGranted = async () => {
    const permission = await this.askPermission();
    return permission === "granted";
  };
}

export default NotificationPermissionController;
