import React from "react";
import { Label, Card, colors, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const Verified = () => (
  <Card backgroundColor={colors.blueLight} withShadow={false}>
    <Spacer bottom={2} left={2} right={2} top={2}>
      <Label>{translate("trust_items.confirmed_room_label")}</Label>
      <Spacer top={1}>
        <Text body={3}>{translate("trust_items.confirmed_room_text")}</Text>
      </Spacer>
    </Spacer>
  </Card>
);

export default Verified;
