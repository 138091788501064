import ReferralModel from "./referral-model";

export default class PendingReferrals {
  constructor(data) {
    this.entity = data || {};
  }

  get results() {
    return this.entity.results
      ? this.entity.results.map((referral) =>
          new ReferralModel(referral).toJSON(),
        )
      : [];
  }

  get nextToken() {
    return this.entity.next_token;
  }

  get previousToken() {
    return this.entity.previous_token;
  }

  toJSON() {
    return {
      results: this.results,
      nextToken: this.nextToken,
      previousToken: this.previousToken,
    };
  }
}
