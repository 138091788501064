import React from "react";

import { modal, MODAL_ID } from "containers/ReduxModal/emitter";

import CancelConfirmation from "Sections/Room/components/cancel-confirmation";

export const closeCancelListRoomModal = () => {
  modal.remove(MODAL_ID.CANCEL_LIST_ROOM);
};

export const openCancelListRoomModal = ({
  onLeave,
  onClose = closeCancelListRoomModal,
}) => {
  modal.add(
    <CancelConfirmation
      onKeepEditingClick={closeCancelListRoomModal}
      onLeaveClick={onLeave}
    />,
    {
      id: MODAL_ID.CANCEL_LIST_ROOM,
      onClose,
    },
  );
};
