import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "GET_COUNTRY_VAT_REQUEST",
  "GET_COUNTRY_VAT_SUCCESS",
  "GET_COUNTRY_VAT_ERROR",
  "SET_COUNTRY_VAT_REQUEST",
  "SET_COUNTRY_VAT_SUCCESS",
  "SET_COUNTRY_VAT_ERROR",
  "UPDATE_COUNTRY_VAT_REQUEST",
  "UPDATE_COUNTRY_VAT_SUCCESS",
  "UPDATE_COUNTRY_VAT_ERROR",
]);
