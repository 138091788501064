class SepaDetailsModel {
  constructor(data) {
    this.data = data;
  }

  get sepaDetails() {
    const sepaDetails = this.data.sepa_debit_details;
    if (!sepaDetails || !sepaDetails.length) return null;

    return sepaDetails.map((sepaDetail) => ({
      lastFourDigits: sepaDetail.last_four,
    }));
  }

  toJSON() {
    return { sepaDetails: this.sepaDetails };
  }
}

export default SepaDetailsModel;
