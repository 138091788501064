import React from "react";
import { BADI_COLORS, Flex } from "@badi/badi-components";
import Menu from "./menu";
import Logo from "../../Logo";

const ResponsiveBar = ({ isLoggedIn, openLogin, registerHandler }) => (
  <>
    <Flex alignItems="center" justify="spaceBetween">
      <Logo color={BADI_COLORS.NEUTRAL.NEUTRAL_00} dataQa="topbar-logo" />
      <Menu
        isLoggedIn={isLoggedIn}
        onLogin={openLogin}
        onRegister={registerHandler}
      />
    </Flex>
  </>
);

export default ResponsiveBar;
