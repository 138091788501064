import React from "react";

import { withRouter } from "react-router";
import { getConversationPath } from "Sections/chat/routes";
import ChatButton from "./chat-button";

const ChatButtonRoute = ({ router, ...rest }) => {
  const handleOpenConversation = ({ id }) => {
    router.push(getConversationPath({ connectionId: id }));
  };

  return <ChatButton onOpenConversation={handleOpenConversation} {...rest} />;
};

export default withRouter(ChatButtonRoute);
