import React from "react";
import {
  StyledAvatar,
  StyledAvatarBadge,
  BadgeIcon,
} from "./styled-components";
import { BADI_COLORS, colors } from "../constants";
import TrustedIcon from "app/assets/icons/trusted.svg";
import VerifiedIcon from "app/assets/icons/verified.svg";
import { getBadge } from "../helpers";
import FastTrackBolt from "app/assets/icons/fast-track/fast-track-bolt.svg";

export const AVATAR_SIZES = [40, 48, 64, 80, 160];
export const BADGES_CONFIG = {
  verified: {
    icon: VerifiedIcon,
    badgeBorderColor: colors.green,
  },
  trusted: {
    icon: TrustedIcon,
    badgeBorderColor: colors.blueBooking,
  },
  "fast-track": {
    icon: FastTrackBolt,
    badgeBorderColor: BADI_COLORS.ADDITIONAL.ADDITIONAL_06,
  },
};

const BADGES_LIST = [...Object.keys(BADGES_CONFIG), ""];

const Avatar = ({ alt, badge, className, size, src, onClick, ...props }) => {
  const { icon: IconComponent, badgeBorderColor } = getBadge(
    BADGES_CONFIG,
    badge,
  );
  return (
    <StyledAvatarBadge
      badge={badge}
      badgeBorderColor={badgeBorderColor}
      className={className}
      onClick={onClick}
      size={size}
    >
      {badge && <BadgeIcon as={IconComponent} />}
      <StyledAvatar {...props} alt={alt} badge={badge} size={size} src={src} />
    </StyledAvatarBadge>
  );
};

Avatar.sizes = AVATAR_SIZES;

Avatar.defaultProps = {
  alt: "",
  badge: "",
  className: "",
  onClick: null,
  size: 48,
};

export default Avatar;
