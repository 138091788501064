import { getHomePath } from "Sections/Home/routes";
import { AUTH_ACTIONS, WHITE_LIST_PAGES } from "./constants";

export const getRedirectLocation = ({ pathname = "", search } = {}) => {
  const sourceParams = search ? encodeURIComponent(search) : "";
  return getHomePath({ source: pathname + sourceParams });
};

export const getAuthAction = ({
  id,
  ghost,
  location,
  requireCompleteProfile,
}) => {
  const { pathname } = location || {};

  if (ghost && requireCompleteProfile) {
    if (WHITE_LIST_PAGES.some((path) => pathname.endsWith(path))) {
      return AUTH_ACTIONS.SHOW_CONTENT;
    }

    return AUTH_ACTIONS.SHOW_MODAL;
  }

  return id ? AUTH_ACTIONS.SHOW_CONTENT : AUTH_ACTIONS.SHOW_NOTHING;
};
