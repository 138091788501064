import React from "react";

import { translate } from "base/shared/Localization";
import { getListerMetricsPath } from "Sections/analytics/rooms/routes";

import InfoCard from "../info-card";

const ListerMetricsCard = () => (
  <InfoCard
    content={translate("overview.element.analytics_text")}
    eventName={"listerMetricsBannerClicked"}
    href={getListerMetricsPath()}
    icon="/assets/icons/overview-lister-metrics.svg"
    title={translate("overview.element.analytics_title")}
  />
);

export default ListerMetricsCard;
