import React, { useCallback, useEffect } from "react";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import TopBanner from "components/top-banner";

const PendingTopBanner = ({ onClick, reviewId, screen }) => {
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <TopBanner
      buttonText={translate("review.banner_cta")}
      dataQa="review-top-banner"
      imageSrc={getPublicAsset("icons/icon_trusted.svg")}
      onClick={handleClick}
      subTitle={translate("review.banner_subtitle")}
      title={translate("review.banner_title")}
    />
  );
};

export default PendingTopBanner;
