import React from "react";
import { Icon } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";

export const Both = ({ checked = true }) => {
  const srcPath = checked
    ? "icons/icon-work-and-study-on.svg"
    : "icons/icon-work-and-study-off.svg";
  return (
    <Icon
      alt="work and study"
      iconMaxHeight={40}
      iconMaxWidth={40}
      src={getPublicAsset(srcPath)}
    />
  );
};

export const Study = ({ checked = true }) => {
  const srcPath = checked
    ? "icons/icon-study-on.svg"
    : "icons/icon-study-off.svg";
  return (
    <Icon
      alt="study"
      iconMaxHeight={40}
      iconMaxWidth={40}
      src={getPublicAsset(srcPath)}
    />
  );
};

export const Work = ({ checked = true }) => {
  const srcPath = checked
    ? "icons/icon-work-on.svg"
    : "icons/icon-work-off.svg";
  return (
    <Icon
      alt="work"
      iconMaxHeight={40}
      iconMaxWidth={40}
      src={getPublicAsset(srcPath)}
    />
  );
};

export const getOccupationIconById = (occupation) => {
  switch (occupation) {
    case 1:
      return <Study />;
    case 2:
      return <Work />;
    case 3:
      return <Both />;
    default:
      return null;
  }
};
