import React from "react";
import {
  BUTTON_TYPES,
  Button,
  Text,
  Flex,
  Heading,
  Spacer,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";

const EmptyList = ({ redirectToAnalytics }) => (
  <Spacer sm={{ top: 7 }} xs={{ top: 4 }}>
    <Flex alignItems="center" direction="column">
      <Image src="/assets/icons/empty-recommended-tenant.svg" />
      <Spacer bottom={1}>
        <Heading data-qa="empty-viewers-title" level="20-Bold">
          {translate("viewers.screen_empty_title")}
        </Heading>
      </Spacer>
      <Spacer bottom={3}>
        <Text body="16-Regular" dataQa="empty-viewers-desc">
          {translate("viewers.screen_empty_subtitle")}
        </Text>
      </Spacer>
      <Button
        dataQa="empty-viewers-button"
        onClick={redirectToAnalytics}
        variant={BUTTON_TYPES.PREMIUM_SECONDARY}
      >
        {translate("viewers.screen_empty_cta")}
      </Button>
    </Flex>
  </Spacer>
);

export default EmptyList;
