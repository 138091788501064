const SOCIAL_MEDIA_TYPES = ["instagram", "twitter", "linkedin", "facebook"];

const getMediaTypeProps = (initialValue, type) => ({
  initialValue,
  media: type,
  withSubLabel: type === "linkedin" || type === "facebook",
});

export const getMediaTypesProps = (safeValue) => {
  const mediaTypesProps = [];
  SOCIAL_MEDIA_TYPES.forEach((type) => {
    const initialValue =
      safeValue.find((profile) => profile.media === type)?.value || "";
    const props = getMediaTypeProps(initialValue, type);
    mediaTypesProps.push(props);
  });

  return mediaTypesProps;
};

export const getUpdatedSocialProfiles = (safeValue, name, value) => {
  const socialProfiles = [...safeValue];
  const profileObject = {
    media: name,
    value,
  };
  const index = socialProfiles.findIndex((profile) => profile.media === name);
  if (index > -1) {
    socialProfiles[index] = profileObject;
  } else {
    socialProfiles.push(profileObject);
  }

  return socialProfiles;
};
