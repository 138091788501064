import styled from "styled-components";
import { mediaquery, unitizedPx, BADI_COLORS } from "@badi/badi-components";

export const Root = styled.div`
  position: relative;
  height: 100%;
  max-width: ${unitizedPx(71.25)};
  margin: 0 auto;
  padding: ${unitizedPx(6)} ${unitizedPx(2)} ${unitizedPx(3)};
  border: 1px solid rgb(240, 240, 240);
  border-radius: ${unitizedPx(1)};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

  ${mediaquery.md`
    padding: ${unitizedPx(5.5)} ${unitizedPx(3)};
  `}

  ${mediaquery.lg`
    padding: ${unitizedPx(5.5)} ${unitizedPx(6)};
  `}

  &::after {
    content: "";
    position: absolute;
    top: ${unitizedPx(3)};
    left: ${unitizedPx(2)};
    background: linear-gradient(
      -90deg,
      ${({ colors }) => colors[0]} 0%,
      ${({ colors }) => colors[1]} 100%
    );
    border-radius: ${unitizedPx(0.25)};
    height: ${unitizedPx(0.5)};
    width: ${unitizedPx(6)};

    ${mediaquery.md`
      left: ${unitizedPx(6)};
    `}
  }
`;

export const PriceWrapper = styled.div`
  background-color: ${BADI_COLORS.SEMANTIC.INFO.LIGHTEST};
  padding: ${unitizedPx(1)};
`;

export const Anchor = styled.a`
  display: inline-block;
  padding-left: ${unitizedPx(0.5)};
  color: inherit;
  text-decoration: underline;

  :hover {
    color: inherit;
  }
`;
