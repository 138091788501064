export const STATUS = {
  INITIAL: 0,
  IDLE: 1,
  LOADED: 2,
};

export const INITIAL_STATE = {
  address: null,
  addessLine: null,
  distance: 23, // area of the biggest city
  city: null,
  country: undefined,
  countryCode: null,
  latitude: null,
  longitude: null,
  postalCode: null,
  street: null,
  streetNumber: null,
  label: null,

  canSubmit: false,
  status: STATUS.INITIAL,
};
