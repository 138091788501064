import React, { useEffect } from "react";

import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { translate } from "base/shared/Localization";
import GeoSuggest from "components/geo-suggest";
import { getPlaceType } from "datamodel/SearchFilters/utils";
import { CancelButton, SearchModalWrapper } from "./styled-components";
import "./SearchModalExt.css";
import { SEARCH_TYPES } from "../../constants";

const SearchModal = ({
  setParams,
  initialValue,
  setLocation,
  children,
  onOpenModal,
  onCloseModal,
  searchType,
}) => {
  const onClose = () => {
    modal.remove(MODAL_ID.Search);
    onCloseModal();
  };

  const isMissingLocation = (bounds, coordinates, text) =>
    text && (!bounds || !coordinates);

  const locationHandler = (suggest) => {
    onClose();

    const { label: text, meta } = suggest;
    const city = meta ? meta.city : "";
    const selectedType = searchType === SEARCH_TYPES.ENTIRE ? 2 : 1;
    const placeTypes = getPlaceType();
    const index = placeTypes.findIndex(({ id }) => id === selectedType);
    placeTypes[index] = { ...placeTypes[index], selected: true };

    setParams({ placeTypes }, true);
    setLocation({ ...suggest, city, text });
  };

  const clickHandler = () => {
    modal.add(
      <SearchModalWrapper>
        <GeoSuggest
          defaultsHidden={false}
          focus={true}
          includeTypeLabels={true}
          initialValue={initialValue}
          nearby={true}
          onSuggestSelect={locationHandler}
          placeholder={translate("topbar.element.search.placeholder")}
          recent={true}
          types={["geocode"]}
        />
        <CancelButton onClick={onClose}>
          {translate("search.modal.close")}
        </CancelButton>
      </SearchModalWrapper>,
      {
        mainClassName: "SearchModal",
        closable: false,
        id: MODAL_ID.Search,
        type: "lateral",
      },
    );
  };

  useEffect(() => {
    if (onOpenModal) {
      clickHandler();
    }
  }, [onOpenModal]);

  return children ? (
    <>{React.cloneElement(children, { onClick: clickHandler })}</>
  ) : null;
};

SearchModal.defaultProps = {
  dataQa: "search",
  initialValue: null,
  onCloseModal: () => {},
  onOpenModal: false,
  searchType: null,
};

export default SearchModal;
