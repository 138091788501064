import React, { Component } from "react";
import { withFormsy } from "formsy-react";

import SelectDate from "./SelectDateMapping";
import { updateDateFromValue } from "./utils";

class SelectDateWithFormsy extends Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
  }

  onValueChange(e) {
    const { isValidValue, name, onChange, setValue } = this.props;
    const { getValue } = this.props;

    const prevValue = getValue();
    const value = updateDateFromValue(prevValue, e.currentTarget);

    setValue(value);

    if (onChange) onChange(name, value, isValidValue(value));
  }

  render() {
    const { getValue, ...rest } = this.props;

    const safeValue = getValue();

    return (
      <SelectDate {...rest} onChange={this.onValueChange} value={safeValue} />
    );
  }
}

SelectDateWithFormsy.defaultProps = {
  onChange: undefined,
};

export default withFormsy(SelectDateWithFormsy);
