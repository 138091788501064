import { translate } from "base/shared/Localization";

const match = ({ badiCommunity, badiRooms }) =>
  [badiCommunity, badiRooms].map(String).join(" ");

const getTitleFor = (selectedSegments) => {
  switch (match(selectedSegments)) {
    case match({ badiCommunity: true, badiRooms: false }):
      return translate(
        "search.filters.marketplace_segments.badi-community.title",
      );
    case match({ badiCommunity: false, badiRooms: true }):
      return translate("search.filters.marketplace_segments.badi-rooms.title");
    default:
      return translate("search.filters.marketplace_segments.any-lister.title");
  }
};

export { getTitleFor };
