import React, { memo } from "react";
import { Link } from "react-router";
import { Flex, Spacer } from "@badi/badi-components";

import { getPicture, getRelativePath } from "base/shared/Utils";

import Details from "./details";
import {
  ImageWrapper,
  RoomCardWrapper,
  RoomCardLink,
} from "./styled-components";
import LabelsBar from "app/Sections/Search/search-v2/room-card/components/labels-bar";
import ProgressiveImg from "app/components/ProgressiveImg";

const getDefaultPicture = () => {
  const defaultPic = getPicture(null, "width_500_url", 0, "room");
  return {
    width_500_url: defaultPic,
    width_27_url: defaultPic,
    id: 0,
  };
};

const Picture = ({ picture }) => {
  const pictureToRender = picture || getDefaultPicture();

  return (
    <div key={pictureToRender.id}>
      <ProgressiveImg
        key={pictureToRender.id}
        src={pictureToRender.width_500_url}
        tinySrc={pictureToRender.width_27_url || pictureToRender.width_500_url}
      />
    </div>
  );
};

const RoomCard = ({
  htmlId = undefined,
  pictures,
  priceText,
  labels,
  title,
  url,
  neighborhood,
}) => {
  const linkUrl = getRelativePath(url);

  return (
    <RoomCardLink
      as={Link}
      data-qa="room-card-link"
      id={htmlId}
      target={"_blank"}
      to={linkUrl}
    >
      <RoomCardWrapper as={Flex}>
        {labels && <LabelsBar dataQa="room-card-labels" labels={labels} />}
        <ImageWrapper>
          <Picture picture={pictures[0]} />
        </ImageWrapper>
        <Spacer basis={"45%"}>
          <Details
            price={priceText}
            title={title}
            neighborhood={neighborhood}
          />
        </Spacer>
      </RoomCardWrapper>
    </RoomCardLink>
  );
};

export default memo(RoomCard);
