import React, { memo } from "react";
import SlickSlider from "react-slick";
import { translate } from "base/shared/Localization";
import Arrow from "components/arrow";
import {
  interpolateAllQueryParam,
  HEADER_PARAMS,
} from "base/shared/tracking-headers";

import { Heading, Spacer, Text } from "@badi/badi-components";
import { SimilarRoomsWrapper } from "./styled-components";
import RoomCard from "./room-card";

import { SLIDER_SETTINGS } from "./static";

const SimilarRooms = ({
  similarRoomsList,
  parentRoomId,
  userId,
  etParams = [],
  dataQa = "similar-rooms",
}) => {
  const handleClick = (roomId) => {
    const params = {
      parent_room_id: parentRoomId,
      room_id: roomId,
      user_id: userId,
    };
  };

  const onClickArrowCallback = () => {
    const params = {
      parent_room_id: parentRoomId,
      user_id: userId,
    };
  };

  const {
    keyVal,
    options: { SIMILAR_ROOMS },
  } = HEADER_PARAMS.ROOM_VIEW_SOURCE;

  return (
    <Spacer top={8}>
      <SimilarRoomsWrapper data-qa={dataQa}>
        <Spacer bottom={2}>
          <Heading level={2}>{translate("room.detail.similar.title")}</Heading>
        </Spacer>
        <Spacer bottom={3}>
          <Text body={3}>{translate("room.detail.similar.description")}</Text>
        </Spacer>

        <SlickSlider
          {...SLIDER_SETTINGS}
          nextArrow={
            <Arrow
              direction="right"
              onClickCallback={onClickArrowCallback}
              type="similarRooms"
            />
          }
          prevArrow={
            <Arrow
              direction="left"
              onClickCallback={onClickArrowCallback}
              type="similarRooms"
            />
          }
        >
          {similarRoomsList.map((room) => {
            const { pictureUrl, displayPrice, url, title, ownerBadge, id } =
              room;

            let etParamsArray = [[keyVal, SIMILAR_ROOMS]];

            if (etParams.length) {
              etParamsArray = etParams.concat(etParamsArray);
            }

            const parsedUrl = interpolateAllQueryParam(url, etParamsArray);

            return (
              <RoomCard
                displayPrice={displayPrice}
                handleClick={handleClick}
                id={id}
                key={id}
                ownerBadge={ownerBadge}
                pictureUrl={pictureUrl}
                title={title}
                url={parsedUrl}
              />
            );
          })}
        </SlickSlider>
      </SimilarRoomsWrapper>
    </Spacer>
  );
};

export default memo(SimilarRooms);
