import React, { useEffect, useState } from "react";

import { Heading, Text, Flex, Icon, Badge } from "@badi/badi-components";
import { BADI_COLORS } from "app/badi-components";
import { Avatar } from "app/badi-components";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { fetchUser } from "datamodel/User/api";
import { openPriorityCandidateInformationModal as _openPriorityCandidateInformationModal } from "app/Sections/inbox/priority-candidate-information/open-priority-candidate-information-modal";
import {
  TopSectionOtherProfile,
  UserWrapper,
  LowSection,
} from "components/User/Sections";
import { isLister } from "datamodel/User/utils";
import { DEFAULT_USER_ROLE } from "datamodel/User/constants";
import { getPublicAsset } from "base/shared/Utils";
import { getOccupationString } from "base/shared/User";

import ListerScoreBadge from "components/lister-score-badge";
import { translate } from "base/shared/Localization";
import { Root } from "./styled-components";
import styled from "styled-components";
import { CONNECTION_STATUS_LEVEL } from "app/datamodel/Conversation/constants";
import { getStatusColor } from "app/Sections/inbox/utils";

const USER_ID_MODAL = MODAL_ID.InboxOtherUserProfile;

const fastTrackStyle = {
  border: `1.5px solid ${BADI_COLORS.ADDITIONAL.ADDITIONAL_06}`,
  borderRadius: "10px",
  padding: "5px",
};

const IconWrapper = styled.span`
  margin-left: 5px;
`;

const openPriorityCandidateInformationModal = (event) => {
  event.stopPropagation();
  _openPriorityCandidateInformationModal();
};

const FastTrackInformationIcon = () => (
  <IconWrapper onClick={openPriorityCandidateInformationModal}>
    <Icon src={getPublicAsset("icons/info_black.svg")} />
  </IconWrapper>
);

const UserProfileBadge = ({
  occupation = null,
  age = 0,
  id = null,
  name = "",
  avatar = "",
  token = "",
  onClick = null,
  clickable = true,
  userRole = DEFAULT_USER_ROLE,
  subHeading = null,
  avatarSize = 64,
  viewerInfo = null,
  avatarBadge = null,
  status,
}) => {
  const userOccupation = getOccupationString(occupation);
  const [userFullInfo, setUserFullInfo] = useState({});

  useEffect(() => {
    if (id) {
      fetchUser(id, token).then((info) => setUserFullInfo(info));
    }
  }, [id]);

  const handleShowUserProfile = () => {
    if (!clickable) return;
    modal.add(
      <UserWrapper modalId={USER_ID_MODAL}>
        <TopSectionOtherProfile format="otherProfile" user={userFullInfo} />
        <LowSection user={userFullInfo} />
      </UserWrapper>,
      {
        id: USER_ID_MODAL,
        onClose: () => {
          modal.remove(USER_ID_MODAL);
        },
        type: "lateral",
      },
    );
  };
  const heading = age ? `${name}, ${age}` : name;
  const onlyHeading =
    !userOccupation && !subHeading && !userFullInfo.listerScore;
  const isPriorityUser =
    status?.statusLevel == CONNECTION_STATUS_LEVEL.PRIORITY;

  return (
    <Root
      clickable={clickable}
      onClick={onClick || handleShowUserProfile}
      role="button"
      tabIndex={0}
      style={isPriorityUser ? fastTrackStyle : {}}
    >
      <Flex direction="row" gutter={2} justify="flexStart">
        <Avatar alt={name} badge={avatarBadge} size={avatarSize} src={avatar} />
        <Flex
          direction="column"
          fullHeight={true}
          justify={onlyHeading ? "center" : "flexStart"}
        >
          <Heading level="16-Bold">
            {heading}
            {isPriorityUser && <FastTrackInformationIcon />}
          </Heading>
          {userOccupation && <Text body="14-Regular">{userOccupation}</Text>}
          {!userOccupation && subHeading && (
            <Text body="14-Regular">{subHeading}</Text>
          )}
          {userFullInfo.listerScore && isLister(userRole) ? (
            <Flex direction="row" gutter={1}>
              <Text body="14-Bold">{translate("lister_score_badge")}</Text>
              <ListerScoreBadge
                score={userFullInfo.listerScore}
                small={false}
              />
            </Flex>
          ) : (
            status?.statusBadgeLabel && (
              <Badge
                backgroundColor={getStatusColor(status.statusLevel)}
                rounded={true}
                shrink={0}
                onClick={openPriorityCandidateInformationModal}
              >
                {status.statusBadgeLabel}
              </Badge>
            )
          )}
          {viewerInfo && (
            <Flex alignItems="center" direction="row" gutter={0.5}>
              <Icon
                alt={"clock icon"}
                data-qa="user-profile-badge-viewer-info"
                src={getPublicAsset("icons/icon-16px-time.svg")}
              />
              <Text body="12-Regular" color={BADI_COLORS.NEUTRAL.NEUTRAL_50}>
                {viewerInfo}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Root>
  );
};

export default UserProfileBadge;
