import { BADI_COLORS } from "app/badi-components";
import {
  CONNECTION_STATUS_LEVEL,
  CONVERSATION_NEXT_STEP,
} from "datamodel/Conversation/constants";
import { fetchInboxConnection } from "datamodel/Conversation/api";
import {
  getReplyBookingRequestPath,
  getRecoveryBookingPath,
} from "Sections/booking/routes";
import { getConversationPath } from "Sections/chat/routes";
import { getEnquiryPath } from "Sections/enquiry/routes";
import { openVisitOverviewModal } from "Sections/visits/visit-overview";
import { openVisitFeedbackModal } from "Sections/visits/visit-feedback";

export const getStatusColor = (status = "") => {
  switch (status) {
    case CONNECTION_STATUS_LEVEL.SUCCESS:
      return BADI_COLORS.BRAND.PRIMARY.DEFAULT;
    case CONNECTION_STATUS_LEVEL.WARNING:
      return BADI_COLORS.SEMANTIC.WARNING.DEFAULT;
    case CONNECTION_STATUS_LEVEL.FAILURE:
      return BADI_COLORS.SEMANTIC.DANGER.DEFAULT;
    case CONNECTION_STATUS_LEVEL.PRIORITY:
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_06;
    default:
      return BADI_COLORS.NEUTRAL.NEUTRAL_50;
  }
};

export const getNextPath = async ({ id, nextStep, token, visitId }) => {
  let getPath;
  let requireConnectionDetails = false;

  const {
    REPLY_BOOKING_REQUEST,
    RECOVER_BOOKING_REQUEST,
    REPLY_ENQUIRY,
    VISIT_PROPOSAL,
    VISITS_FEEDBACK_LISTER,
    VISITS_FEEDBACK_SEEKER,
    VISIT_OVERVIEW,
  } = CONVERSATION_NEXT_STEP;

  switch (nextStep) {
    case REPLY_BOOKING_REQUEST:
      getPath = getReplyBookingRequestPath;
      requireConnectionDetails = true;
      break;
    case RECOVER_BOOKING_REQUEST:
      getPath = getRecoveryBookingPath;
      requireConnectionDetails = true;
      break;
    case REPLY_ENQUIRY:
    case VISIT_PROPOSAL:
      getPath = getEnquiryPath;
      break;
    case VISITS_FEEDBACK_SEEKER:
    case VISITS_FEEDBACK_LISTER:
      openVisitFeedbackModal({ visitId });
      return { path: null };
    case VISIT_OVERVIEW:
      openVisitOverviewModal({ visitId });
      return { path: null };
    default:
      getPath = getConversationPath;
  }

  if (!requireConnectionDetails) {
    return { path: getPath({ connectionId: id }) };
  }

  const connectionDetail = await fetchInboxConnection(id, token);
  const params = {
    requestId: connectionDetail.bookingRequestId,
    connectionId: id,
  };
  return { path: getPath(params) };
};
