import { BADI_COLORS } from "@badi/badi-components";

export const DESKTOP_MARGINS = {
  bottom: 25,
  top: 8,
};

export const MOBILE_MARGINS = {
  bottom: 23,
  top: 4,
};

export const DESKTOP_FLEX_CONFIG = { gutter: 2, direction: "column" };

export const HEADER_CONFIG_SIZE = {
  lg: { size: 8, offset: 2 },
  sm: 12,
};

export const LAYOUT_TYPES = {
  PLUS: "plus",
  BOOSTING: "boosting",
};

export const AVATAR_STYLES = {
  backgroundColor: BADI_COLORS.BRAND.SECONDARY.DEFAULT,
  borderRadius: "50%",
  border: `2px ${BADI_COLORS.BRAND.SECONDARY.DEFAULT} solid`,
  position: "absolute",
  left: "calc(50% + 24px)",
  top: "8px",
};
