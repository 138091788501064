import { connect } from "react-redux";

import { getUserRole } from "datamodel/Conversation/selectors";

import PopupAnnotation from "./popup-annotation";

const mapStateToProps = (state) => ({
  userRole: getUserRole(state),
});

export default connect(mapStateToProps)(PopupAnnotation);
