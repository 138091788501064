import React, { Component } from "react";

import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";

import { unPublishRoom } from "datamodel/Room/api";

import {
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  BUTTON_TYPES,
} from "@badi/badi-components";

class UnPostRoom extends Component {
  unPublishHandler = () => {
    const { callback, id, token } = this.props;

    unPublishRoom(token, { id })
      .then((data) => {
        if (data.status === 2) callback(true);
      })
      .catch((e) =>
        UserFeedback.exception(
          "Something went wrong",
          e.code ? e.message : "Please, try again or contact support.",
          e,
        ),
      );
  };

  rentedHandler = () => {
    this.props.callback(false);
  };

  render() {
    return (
      <Flex direction="column">
        <Heading level="20-Bold">{translate("Are you sure?")}</Heading>
        <Spacer top={2}>
          <Text body="14-regular">
            {translate("Why do you want to unpost it?")}
          </Text>
        </Spacer>
        <Spacer top={7}>
          <Button
            data-qa="listings-unpublish-rented-submit"
            fullWidth={true}
            onClick={this.rentedHandler}
            variant={BUTTON_TYPES.PRIMARY}
          >
            {translate("I rented it :)")}
          </Button>
        </Spacer>
        <Spacer top={2}>
          <Button
            data-qa="listings-unpublish-hide-submit"
            fullWidth={true}
            onClick={this.unPublishHandler}
            variant={BUTTON_TYPES.SECONDARY}
          >
            {translate("I want to hide it")}
          </Button>
        </Spacer>
      </Flex>
    );
  }
}

export default UnPostRoom;
