import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "LISTROOM_RESET",
  "LISTROOM_INITIALSTEP",
  "LISTROOM_FLATMATESTEP",
  "LISTROOM_ID",
  "LISTROOM_LOCATIONSTEP",
  "LISTROOM_PHOTOSSTEP",
  "LISTROOM_PROPERTYSTEP",
  "LISTROOM_ROOMSTEP",
  "LISTROOM_TOTALSTEPS",
  "LISTROOM_ERROR",
  "LISTROOM_REQUEST",
  "LISTROOM_SUCCESS",
  "LISTROOM_VISITSTEP",
  "DRAFTFETCH_ERROR",
  "DRAFTFETCH_REQUEST",
  "DRAFTFETCH_SUCCESS",
  "ROOM_TEMPLATE_REQUEST",
  "ROOM_TEMPLATE_ERROR",
  "ROOM_TEMPLATE_SUCCESS",
  "FETCH_DRAFT_BY_ID_REQUEST",
  "FETCH_DRAFT_BY_ID_ERROR",
  "FETCH_DRAFT_BY_ID_SUCCESS",
  "ROOM_MODERATION_RESULT_REQUEST",
  "ROOM_MODERATION_RESULT_ERROR",
  "ROOM_MODERATION_RESULT_SUCCESS",
  "ROOM_MODERATION_RESULT_SET",
]);
