const fetchTypes = ["REQUEST", "SUCCESS", "ERROR"];

export function typeBuilder(types) {
  const data = {};
  types.map((type) => {
    data[type] = type;
  });
  return data;
}

export function generateFetchTypes(initialType) {
  if (!initialType) {
    throw new Error(
      "Missing fetch type. You should add your request types in your action file!",
    );
  }
  const splittedInitialType = initialType.split("_");
  const type = splittedInitialType
    .slice(0, splittedInitialType.length - 1)
    .join("_");
  const result = fetchTypes.reduce((acc, item) => {
    acc.push(`${type}_${item}`);
    return acc;
  }, []);
  return result;
}
