import { BREAKPOINTS_PX } from "@badi/badi-components";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.lg})`];

export const BOOSTING_STATUS = {
  enabled: "can_be_boosted",
  disabled: "boosted",
};

export const TRANSACTIONS_COMPONENT = "transactions";

export const TRANSACTION_STATUS = {
  pending: "pending",
  done: "done",
  inProgress: "in_progress",
};
