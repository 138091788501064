import React, { useContext } from "react";
import { withRouter } from "react-router";
import { Button, BUTTON_TYPES } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { useDispatch } from "react-redux";
import { getGoldUpgradePath } from "Sections/plans/routes";
import { setPremiumOrigin } from "datamodel/premium/actions";

const PremiumButton = ({
  roomId,
  capabilityId = "",
  router,
  fullWidth = false,
}) => {
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(setPremiumOrigin(window.location.pathname));
    router.push(getGoldUpgradePath({ roomId, feature: capabilityId }));
  };

  return (
    <Button
      data-qa="premium-button"
      fullWidth={fullWidth}
      onClick={clickHandler}
      variant={BUTTON_TYPES.PREMIUM}
    >
      {translate("listing.overview.badi_gold.desktop_cta")}
    </Button>
  );
};

export default withRouter(PremiumButton);
