import React, { Component } from "react";

import envVars from "base/shared/Env";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import {
  Heading,
  StatusCard,
  Button,
  Flex,
  Spacer,
} from "@badi/badi-components";

import StripeApi from "base/shared/stripe-api";

import "../paymentExt.css";

class AddCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ccIsValid: false,
    };

    this.stripe = new StripeApi({ token: envVars().BADI_PAYMENT_TOKEN });
  }

  componentDidMount() {
    this.stripe.init().then(this.onStripeLoaded).catch(this.onStripeLoadError);
  }

  onStripeLoaded = () => {
    this.props.onLoading(false);

    const creditCard = this.stripe.createCreditCardField({
      id: "card",
      mountOn: "#card-element",
    });

    creditCard.addEventListener("change", this.handleCreditCardInputChange);
  };

  onStripeLoadError = (error) => {
    this.setState({ isLoading: false });
    // TODO move to error boundary
    UserFeedback.exception(
      "payment.creditcard.notifications.error_title",
      "payment.creditcard.notifications.error_message",
      error,
    );
  };

  handleCreditCardInputChange = ({ error, complete }) => {
    if (!error && complete) {
      this.setState({ ccIsValid: true });
    } else {
      this.setState({ ccIsValid: false });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmit } = this.props;

    this.props.onLoading(true);

    this.stripe.saveCreditCard().then((result) => {
      if (result.error) {
        this.props.onLoading(false);

        // TODO move to error boundary
        UserFeedback.exception(
          "payment.creditcard.notifications.error_title",
          result.error.message,
          null,
        );
      }

      if (result.token) {
        onSubmit({ token: result.token });
      }
    });
  };

  render() {
    const { canCancel, onCancel, disabled } = this.props;
    const { ccIsValid } = this.state;
    const cancelButtonLabel = canCancel
      ? "payment.creditcard.edit_button"
      : "payment.creditcard.save_button";
    return (
      <StatusCard isVerified={canCancel}>
        <Spacer bottom={3}>
          <Heading level={3}>{translate("payment.creditcard.title")}</Heading>
        </Spacer>
        <Spacer bottom={2}>
          <div id="card-element" />
        </Spacer>
        <Flex gutter={2} justify="flexEnd">
          {canCancel && (
            <Button
              disabled={disabled}
              onClick={onCancel}
              type="button"
              variant="secondary"
            >
              {translate("payment.creditcard.cancel_button")}
            </Button>
          )}
          <Button
            disabled={disabled || !ccIsValid}
            onClick={this.handleSubmit}
            type="submit"
          >
            {translate(cancelButtonLabel)}
          </Button>
        </Flex>
      </StatusCard>
    );
  }
}

AddCard.defaultProps = {
  canCancel: false,
  disabled: false,
  onLoading: () => {},
};

export default AddCard;
