import { v4 as uuidv4 } from "uuid";
import { sortByAlphabetically } from "base/shared/Utils";
import moment from "moment";

export const getOrderedOptions = (options) => {
  const recommendedOptions = options.filter((o) => o.recommended);
  const generalOptions = options.filter((o) => !o.recommended);

  return recommendedOptions
    .sort(sortByAlphabetically("title"))
    .concat(generalOptions.sort(sortByAlphabetically("title")));
};

export const optionAtEnd = (options, value, id) => {
  const index = options.findIndex((option) => option[value] === id);
  options.push(options.splice(index, 1)[0]);
  return options;
};

export const isBlockValid = (name, forceValidation, validations) => {
  if (!forceValidation) return true;

  if (typeof name === "string") return validations[name];

  return !name.find((n) => !validations[n]);
};

export const isValidAndSetted = (valid, value, fieldName) =>
  valid && valid[fieldName] && value && value[fieldName];

export const parseProfile = (user) =>
  user
    ? {
        ...user,
        languages: user.languages.map((l) => l.value),
        pictures: user.pictures.map((picture) => ({
          file: { preview: picture.width_500_url },
          pictureId: picture.id,
          tempId: uuidv4(),
          uploadStatus: "success",
        })),
        birthDate: user.birthDate ? moment(user.birthDate) : null,
        // TODO this line has been added to profile doesn't break
        occupation: user.workStudiesStatus,
      }
    : { pictures: [], languages: [] };

export const tagListFieldHasValues = (value) =>
  !!(value && Object.keys(value).find((key) => !!value[key].length));
