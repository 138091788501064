import createReducer from "base/shared/createReducer";

import Types from "../types";
import { RequirementsModel } from "../models";

const setGDPR = (state, action) => ({
  ...state,
  gdpr: { ...state.gdpr, ...action.result },
});

const setCountryVat = (state, action) => ({
  ...state,
  countryVat: { ...state.countryVat, ...action.result },
});

const setOnboarding = (state, action) => ({
  ...state,
  onboarding: { ...state.onboarding, ...action.result },
});

const setProfile = (state, action) => ({
  ...state,
  profile: { ...state.profile, ...action.result },
});

const setPhone = (state, action) => ({
  ...state,
  phone: { ...state.phone, ...action.result },
});

const setLogin = (state, action) => ({
  ...state,
  login: { ...state.login, ...action.result },
});

const setRegister = (state, action) => ({
  ...state,
  register: { ...state.register, ...action.result },
});

const setTermsAndConditions = (state, action) => ({
  ...state,
  termsAndConditions: { ...state.termsAndConditions, ...action.result },
});

const setRequirements = (state, action) => {
  const { result } = action;
  const requirements = { ...state };

  Object.keys(action.result).forEach((r) => {
    const requirement = result[r];
    requirements[r] =
      typeof requirement === "boolean"
        ? { ...requirements[r], show: requirement }
        : requirement;
  });
  return requirements;
};

const resetRequirements = () => ({
  ...RequirementsModel,
});

const actionHandlers = {
  [Types.SET_GDPR]: setGDPR,
  [Types.SET_COUNTRY_VAT]: setCountryVat,
  [Types.SET_ONBOARDING]: setOnboarding,
  [Types.SET_PROFILE]: setProfile,
  [Types.SET_PHONE]: setPhone,
  [Types.SET_LOGIN]: setLogin,
  [Types.SET_REGISTER]: setRegister,
  [Types.SET_TERMS_AND_CONDITIONS]: setTermsAndConditions,
  [Types.SET_REQUIREMENTS]: setRequirements,
  [Types.RESET_REQUIREMENTS]: resetRequirements,
};

export default createReducer(actionHandlers, { ...RequirementsModel });
