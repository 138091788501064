import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchRoomsData } from "./api";

export default {
  getRooms(params) {
    return {
      types: generateFetchTypes(Types.SEARCH_ROOMS_REQUEST),
      request: fetchRoomsData,
      requireAuth: true,
      params,
    };
  },
  selectRoom(id) {
    return { type: Types.SEARCH_ROOMS_SELECT_ROOM, result: id };
  },
  reset() {
    return { type: Types.SEARCH_ROOMS_RESET };
  },
  resetLoginRequired() {
    return { type: Types.SEARCH_ROOMS_RESET_LOGIN_REQUIRED };
  },
};
