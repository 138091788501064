import styled from "styled-components";
import { colors } from "@badi/badi-components";

export const Root = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  overflow: hidden;
`;

export const SeparatorLine = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${colors.lightGrey};
`;
