import React, { useMemo, useEffect } from "react";
import { Flex, Heading, Spacer } from "@badi/badi-components";
import { Col, Row } from "reactstrap";

import { translate } from "base/shared/Localization";
import { useForm, useFormValidation } from "components/hooks";

import RadioButtonGroup from "components/form-radio-button-group";
import StepperLayout, { Step } from "components/Layout/stepper-layout";
import BirthDate, { breakDownDate } from "components/form-birth-date";
import Input from "components/form-text-input";
import { GENDERS, OCCUPATIONS } from "base/shared/StaticData";
import DropZone, { isUploaded } from "components/DropZone";
import Label from "./components/label";
import Footer from "./components/footer";
import { parseOptionsToFormData, parsePicturesToFormData } from "./utils";

import {
  INPUT_BIRTHDATE,
  INPUT_NAME,
  INPUT_LASTNAME,
  INPUT_GENDER,
  INPUT_OCCUPATION,
  INPUT_PICTURES,
} from "./constants";

const MainProfile = ({
  birthDate = null,
  gender = null,
  lastName = "",
  name = "",
  occupation = null,
  onClose,
  pictures,
  saveProfile,
}) => {
  const [values, setInputValue] = useForm({
    [INPUT_BIRTHDATE]: birthDate,
    [INPUT_NAME]: name || "",
    [INPUT_LASTNAME]: lastName || "",
    [INPUT_GENDER]: gender,
    [INPUT_OCCUPATION]: occupation,
    [INPUT_PICTURES]: parsePicturesToFormData(pictures),
  });
  const [isFormValid, setFieldValidation] = useFormValidation({
    [INPUT_BIRTHDATE]: !!birthDate,
    [INPUT_NAME]: !!name,
    [INPUT_LASTNAME]: !!lastName,
    [INPUT_GENDER]: !!gender,
    [INPUT_OCCUPATION]: !!occupation,
    [INPUT_PICTURES]: !!pictures.length,
  });

  const handleChange = ({ name: inputName, value }) => {
    setInputValue(inputName, value);
  };

  const handleValidation = ({ name: inputName, isValid }) => {
    setFieldValidation(inputName, isValid);
  };

  const handleRemovePicture = (id) => {
    const updatedPictures = values[INPUT_PICTURES].filter(
      (f) => f.tempId !== id,
    );
    setInputValue(INPUT_PICTURES, updatedPictures);
    setFieldValidation(INPUT_PICTURES, !!updatedPictures.length);
  };

  const handleUpdatePicture = (value) => {
    let updatedPictures = value;
    const currentPictures = values[INPUT_PICTURES];

    if (!Array.isArray(value)) {
      updatedPictures = [...currentPictures];
      const i = updatedPictures.findIndex((v) => v.tempId === value.tempId);

      if (i >= 0) updatedPictures[i] = value;
      else updatedPictures.push(value);
    }

    const isValid =
      !!updatedPictures.length &&
      updatedPictures.every((pic) => isUploaded(pic));

    setInputValue(INPUT_PICTURES, updatedPictures);
    setFieldValidation(INPUT_PICTURES, isValid);
  };

  const { day, month, year } = useMemo(
    () => breakDownDate(values[INPUT_BIRTHDATE]),
    [values[INPUT_BIRTHDATE]],
  );

  const genderOptions = useMemo(() => {
    return parseOptionsToFormData(GENDERS);
  }, []);

  const occupationOptions = useMemo(
    () => parseOptionsToFormData(OCCUPATIONS),
    [],
  );

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    saveProfile(values).then(() => {
      onClose();
    });
  };

  return (
    <StepperLayout
      disabled={!isFormValid}
      onClose={handleClose}
      onSubmit={handleSubmit}
      showProgress={false}
    >
      <Step footer={Footer} id="main-profile-step">
        <>
          <Row>
            <Col>
              <Heading data-qa="main-profile-heading" level="28-Extrabold">
                {translate("onboarding.profile.seeker_title")}
              </Heading>
              <Spacer bottom={4} />
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 5, order: 1 }} xs={{ size: 12, order: 2 }}>
              <Flex direction="column" gutter={4}>
                <Flex
                  direction="column"
                  gutter={3}
                  lg={{ direction: "row", justify: "spaceBetween", gutter: 2 }}
                >
                  <Input
                    dataQa="main-profile-name"
                    grow={1}
                    initialValue={name || ""}
                    label={translate("onboarding.profile.seeker_name")}
                    name={INPUT_NAME}
                    newType={true}
                    onChange={handleChange}
                    onInputValidation={handleValidation}
                    placeholder={translate(
                      "onboarding.profile.seeker_name_placeholder",
                    )}
                    required={true}
                  />
                  <Input
                    dataQa="main-profile-surname"
                    grow={1}
                    initialValue={lastName || ""}
                    label={translate("onboarding.profile.seeker_surname")}
                    name={INPUT_LASTNAME}
                    newType={true}
                    onChange={handleChange}
                    onInputValidation={handleValidation}
                    placeholder={translate(
                      "onboarding.profile.seeker_surname_placeholder",
                    )}
                    required={true}
                  />
                </Flex>
                <div>
                  <Label dataQa="main-profile-birth-label">
                    {translate("onboarding.profile.seeker_date_birth")}
                  </Label>
                  <BirthDate
                    day={day}
                    dayPlaceholder={translate("day")}
                    grow={1}
                    minYear={1899}
                    month={month}
                    monthPlaceholder={translate("month")}
                    name={INPUT_BIRTHDATE}
                    onChange={handleChange}
                    onValidation={handleValidation}
                    required={true}
                    year={year}
                    yearPlaceholder={translate("year")}
                  />
                  <Spacer bottom={2} />
                </div>
                <Flex direction="column" gutter={3}>
                  <Heading data-qa="main-profile-gender-title" level="20-Bold">
                    {translate("onboarding.profile.seeker_gender")}
                  </Heading>
                  <RadioButtonGroup
                    initialValue={gender}
                    name={INPUT_GENDER}
                    onChange={handleChange}
                    onValidation={handleValidation}
                    options={genderOptions}
                    required={true}
                  />
                </Flex>
                <Flex direction="column" gutter={3}>
                  <Heading
                    data-qa="main-profile-occupation-title"
                    level="20-Bold"
                  >
                    {translate("onboarding.profile.seeker_occupation")}
                  </Heading>
                  <RadioButtonGroup
                    initialValue={occupation}
                    name={INPUT_OCCUPATION}
                    onChange={handleChange}
                    onValidation={handleValidation}
                    options={occupationOptions}
                    required={true}
                  />
                </Flex>
                <Spacer bottom={4} />
              </Flex>
            </Col>
            <Col
              lg={{ size: 6, order: 2, offset: 1 }}
              sm={{ size: 12, order: 1 }}
            >
              <DropZone
                files={values[INPUT_PICTURES]}
                forceValidation={false}
                maxFiles={6}
                name="pictures"
                onRemove={handleRemovePicture}
                onUpdate={handleUpdatePicture}
                sectionName={"room"}
                validationErrors={{
                  areUploaded: translate(
                    "Please wait until the image is uploaded",
                  ),
                  minLength: translate("You need to add one image of yourself"),
                }}
                validations={{
                  areUploaded: true,
                  minLength: 1,
                }}
              />
              <Spacer bottom={4} />
            </Col>
          </Row>
        </>
      </Step>
    </StepperLayout>
  );
};

export default MainProfile;
