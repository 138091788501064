export const TIPS = [
  {
    number: 1,
    heading: "viewers.tip_step_1_title",
    description: "viewers.tip_step_1_text",
  },
  {
    number: 2,
    heading: "viewers.tip_step_2_title",
    description: "viewers.tip_step_2_text",
  },
  {
    number: 3,
    heading: "viewers.tip_step_3_title",
    description: "viewers.tip_step_3_text",
  },
];
