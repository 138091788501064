import { getRelativePath } from "base/shared/Utils";
import { getMyListingsPath } from "Sections/Room/routes";
import { getInboxPath } from "Sections/inbox/routes";
import { getRecommendationsPath } from "Sections/recommendations/routes";
import { getViewersPath } from "Sections/viewers/routes";

export const overviewSettings = {
  per: 5,
};

export const getRoomNextStepPath = (id, nextStepId) => {
  const nextStepParser = {
    chat_requests: getInboxPath({ roomId: id, category: "pending" }),
    recommendations: getRecommendationsPath({ roomId: id }),
    booking_activity: getInboxPath({ roomId: id, category: "booking" }),
    room_viewers: getViewersPath({ roomId: id }),
    room_viewers_empty: getViewersPath({ roomId: id }),
  };
  if (nextStepId === null || !nextStepParser[nextStepId]) return null;
  return nextStepParser[nextStepId];
};

export const splitSections = (sections) => {
  if (sections) {
    const clonedSections = [...sections];
    const mainSection = clonedSections.splice(0, 1)[0];
    const restOfSections = clonedSections;
    return { mainSection, restOfSections };
  }
  return null;
};

export const getStatusNextStepPath = (nextStepData) => {
  const nextStepParser = {
    list_a_room: getRelativePath("/list?draft=1"),
    my_listings: getMyListingsPath(),
  };

  const { id, focus } = nextStepData;
  if (!nextStepParser[id]) return null;

  return `${nextStepParser[id]}${focus ? `?focus=${focus}` : ""}`;
};

export const getTransactionsHeadingString = (
  transactionsCompleted,
  existingTransactions,
) => {
  if (transactionsCompleted) {
    return [
      "transactions.overview_in_progress_subheader",
      "transactions.overview_in_progress_learn_more",
    ];
  } else if (existingTransactions) {
    return [
      "transactions.overview_pending_subheader",
      "transactions.overview_pending_learn_more",
    ];
  }
  return [
    "transactions.overview_empty_subheader",
    "transactions.overview_empty_learn_more",
  ];
};
