import React from "react";
import { translate } from "base/shared/Localization";
import ReducedFilterContainer from "./reduced-filter-mapping";
import { FILTERS_ID } from "../../static";
import { MarketplaceSegmentsFilterButton } from "../../marketplace-segments/";
import ReducedFilter from "./reduced-filter";

import "./ReducedFiltersExt";

const ReducedFilters = ({ filters }) => (
  <div className="ReducedFilters">
    {filters.map((filter) =>
      filter.id == FILTERS_ID.marketplace_segments ? (
        <MarketplaceSegmentsFilterButton
          component={ReducedFilter}
          key={filter.id}
        />
      ) : (
        <ReducedFilterContainer
          key={filter.id}
          {...filter}
          title={translate(filter.title)}
        />
      ),
    )}
  </div>
);

export default ReducedFilters;
