import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { DEFAULT_USER_ROLE } from "datamodel/User/constants";
import BookingPaymentInfo from "./booking-payment-info";

export const closeBookingPaymentInfoModal = () => {
  modal.remove(MODAL_ID.BOOKING_PAYMENT_INFO);
};

export const openBookingPaymentInfoModal = ({
  userRole = DEFAULT_USER_ROLE,
}) => {
  modal.add(
    <BookingPaymentInfo
      onLeave={() => {
        closeBookingPaymentInfoModal();
      }}
      userRole={userRole}
    />,
    {
      closeOnOutsideClick: true,
      id: MODAL_ID.BOOKING_PAYMENT_INFO,
      type: MODAL_TYPE.DEFAULT,
    },
  );
};
