import styled from "styled-components";
import { colors } from "@badi/badi-components";

export const TopBarRoot = styled.header`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.softGrey};
`;

export const FooterRoot = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${colors.softGrey};
  background-color: ${colors.white};
`;

export const LogoWrap = styled.div`
  height: 25px;
  overflow: hidden;
`;

export const TextButtonWithIcon = styled.button`
  display: flex;
  svg {
    margin-right: 4px;
  }
`;

export const PercentageRoot = styled.div`
  position: relative;
  background-color: ${colors.lightGrey};
`;

export const PercentageBar = styled.div`
  width: ${(props) => props.percentage}%;
  height: 4px;
  background: ${colors.green};
  transition: width 0.2s ease;
`;
