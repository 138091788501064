import dayjs from "dayjs";

export const parseAvailableDays = (days) => {
  if (!days) return [];

  return days
    .filter(({ available }) => available)
    .map(({ date }) => {
      const parsedDay = dayjs(date);
      return {
        date,
        day: parsedDay.format("MMM, D"),
        weekday: parsedDay.format("ddd"),
      };
    });
};

export const parseAvailableTimes = (times, schedule = "") => {
  if (!times) return [];

  return times.map(({ localStartAt, available }, index) => {
    const time = dayjs(localStartAt);

    return {
      time: time.format("H:mm"),
      available,
      localStartAt,
      id: `${schedule}-${index}`,
    };
  });
};

export const getDynamicTexts = (reschedule) =>
  reschedule
    ? {
        title: "visit.reschedule.title",
        subtitle: "visit.reschedule.subtitle",
        cta: "visits.reschedule.cta",
      }
    : {
        title: "visit.proposal.title",
        subtitle: "visit.proposal.subtitle",
        cta: "visit.proposal.cta",
      };
