import React from "react";
import { Badge, colors } from "@badi/badi-components";

const BookingExpirationBadge = ({ expiration = "" }) => (
  <Badge backgroundColor={colors.orange} rounded={true}>
    {expiration}
  </Badge>
);

export default BookingExpirationBadge;
