import React from "react";
import { translate } from "base/shared/Localization";
import {
  Flex,
  Text,
  Spacer,
  StepCard,
  BADI_COLORS,
} from "@badi/badi-components";

import { STEPS_LISTER_BENEFITS } from "./constants";

const BenefitsDescriptionCard = () => {
  return (
    <Spacer top={4}>
      <Flex direction="column" gutter={3}>
        <Text body="14-Bold" data-qa="benefits-description-heading">
          {translate("booking_flow_next_steps.received")}
        </Text>
        {STEPS_LISTER_BENEFITS.map(({ step, text }) => (
          <StepCard
            key={`benefits-description-${step}`}
            step={step}
            stepColor={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
          >
            <Text body="14-Regular">{translate(text)}</Text>
          </StepCard>
        ))}
      </Flex>
    </Spacer>
  );
};

export default BenefitsDescriptionCard;
