import createReducer from "base/shared/createReducer";
import types from "./types";
import { ListerMetricsModel } from "./models";

const analyticsRoomsRequest = (state) => ({
  ...state,
  fetchingRooms: true,
  fetchedRooms: false,
  error: null,
});

const analyticsRoomsSuccess = (state, action) => {
  const rooms = action.result.reduce((roomsObject, room) => {
    const roomObject = {};
    roomObject[room.id] = { ...room, detailsFetched: false };
    return Object.assign(roomsObject, roomObject);
  }, {});

  return {
    ...state,
    error: null,
    fetchingRooms: false,
    fetchedRooms: true,
    rooms,
  };
};

const analyticsRoomsError = (state, action) => ({
  ...state,
  fetchingRooms: false,
  fetchedRooms: false,
  error: action.error,
});

const analyticsRoomDetailsRequest = (state) => ({
  ...state,
  fetchingRoomDetails: true,
  fetchedRoomDetails: false,
  error: null,
});

const analyticsRoomDetailsSuccess = (state, action) => {
  const fetchedRoom = { ...action.result, detailsFetched: true };

  const rooms = { ...state.rooms };
  rooms[fetchedRoom.id] = fetchedRoom;

  return {
    ...state,
    error: null,
    fetchingRoomDetails: false,
    fetchedRoomDetails: true,
    rooms,
  };
};

const analyticsRoomDetailsError = (state, action) => ({
  ...state,
  fetchingRoomDetails: false,
  fetchedRoomDetails: false,
  error: action.error,
});

const analyticsListerHintsRequest = (state) => ({
  ...state,
  fetchingListerHints: true,
  fetchedListerHints: false,
  error: null,
});

const analyticsListerHintsSuccess = (state, action) => ({
  ...state,
  error: null,
  fetchingListerHints: false,
  fetchedListerHints: true,
  listerHints: action.result,
});

const analyticsListerHintsError = (state, action) => ({
  ...state,
  fetchingListerHints: false,
  fetchedListerHints: false,
  error: action.error,
});

const resetAnalytics = () => ({
  ...ListerMetricsModel,
});

const actionHandlers = {
  [types.ANALYTICS_ROOMS_REQUEST]: analyticsRoomsRequest,
  [types.ANALYTICS_ROOMS_SUCCESS]: analyticsRoomsSuccess,
  [types.ANALYTICS_ROOMS_ERROR]: analyticsRoomsError,
  [types.ANALYTICS_ROOM_DETAILS_REQUEST]: analyticsRoomDetailsRequest,
  [types.ANALYTICS_ROOM_DETAILS_SUCCESS]: analyticsRoomDetailsSuccess,
  [types.ANALYTICS_ROOM_DETAILS_ERROR]: analyticsRoomDetailsError,
  [types.ANALYTICS_LISTER_HINTS_REQUEST]: analyticsListerHintsRequest,
  [types.ANALYTICS_LISTER_HINTS_SUCCESS]: analyticsListerHintsSuccess,
  [types.ANALYTICS_LISTER_HINTS_ERROR]: analyticsListerHintsError,
  [types.RESET_ANALYTICS]: resetAnalytics,
};

export default createReducer(actionHandlers, { ...ListerMetricsModel });
