import React from "react";

import CreateRoom from "./create-room-mapping";

const CreateRoomRoute = ({ location, route, router }) => {
  const {
    query: { draft: accessDraft },
  } = location;

  return <CreateRoom accessDraft={accessDraft} route={route} router={router} />;
};

export default CreateRoomRoute;
