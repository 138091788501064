import React, { memo, useState } from "react";
import { Choice, Text, Heading, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { VISIT_CATEGORIES } from "datamodel/RoomList/constants";
import Image from "components/Image";

const VIDEOCALL_FIELD_ID = "videocall_visit";
const INPLACE_FIELD_ID = "inplace_visit";

const CategoryField = memo(({ config, onChange }) => {
  const [videocallChecked, setVideocallChecked] = useState(
    () => config !== VISIT_CATEGORIES.IN_PLACE,
  );
  const [inPlaceChecked, setInPlaceChecked] = useState(
    () => config === VISIT_CATEGORIES.IN_PLACE,
  );

  const handleChange = (event) => {
    const { id } = event.currentTarget;

    if (id === VIDEOCALL_FIELD_ID) {
      setVideocallChecked(true);
      setInPlaceChecked(false);
      onChange(VISIT_CATEGORIES.VIDEOCALL);
    } else {
      setInPlaceChecked(true);
      setVideocallChecked(false);
      onChange(VISIT_CATEGORIES.IN_PLACE);
    }
  };

  const videocallVisible = config !== VISIT_CATEGORIES.IN_PLACE;
  const inPlaceVisible = config !== VISIT_CATEGORIES.VIDEOCALL;

  return (
    <Flex direction="column" gutter={1}>
      <Text body="14-Bold" dataQa="visit-proposal-type-title">
        {translate("visits.type.title")} *
      </Text>
      {videocallVisible && (
        <Choice
          align="left"
          checked={videocallChecked}
          height="96px"
          id={VIDEOCALL_FIELD_ID}
          name="visit_category"
          onChange={handleChange}
          type="radio"
        >
          <Image src="/assets/icons/mobile-phone-success.svg" />
          <Flex direction="column">
            <Heading data-qa="video-call-title" level="16-Bold">
              {translate("visits.type.video.title")}
            </Heading>
            <Text body="14-Regular" dataQa="video-call-subtitle" inline={true}>
              {translate("visits.type.video.subtitle")}
            </Text>
          </Flex>
        </Choice>
      )}
      {inPlaceVisible && (
        <Choice
          align="left"
          checked={inPlaceChecked}
          height="96px"
          id={INPLACE_FIELD_ID}
          name="visit_category"
          onChange={handleChange}
          type="radio"
        >
          <Image src="/assets/icons/home.svg" />
          <Flex direction="column">
            <Heading data-qa="inplace-title" level="16-Bold">
              {translate("visits.type.home.title")}
            </Heading>
            <Text body="14-Regular" dataQa="inplace-subtitle" inline={true}>
              {translate("visits.type.home.subtitle")}
            </Text>
          </Flex>
        </Choice>
      )}
    </Flex>
  );
});

export default CategoryField;
