import React, { Component } from "react";

import { Checkbox, Choice, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import AmenityChoice from "components/Form/Choice/Choice";
import FilterSectionWrapper from "../../../wrappers/filter-section-wrapper";
import FilterSubSectionWrapper from "../../../wrappers/filter-sub-section-wrapper";

import "./RoomExt";
import {
  FormSectionChoices,
  FormSectionChoiceElement,
} from "../styled-components";

class RoomFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      any: true,
      updated: false,
    };
  }

  static getDerivedStateFromProps(props) {
    const { placeTypeId } = props;

    return {
      any: !placeTypeId,
    };
  }

  handleBedTypeSelect = (e) => {
    const { bedTypeIds, onBedTypeChange } = this.props;
    const bedTypeId = parseInt(e.currentTarget.value, 10);

    if (isNaN(bedTypeId)) {
      return;
    }

    let newBedTypeIds = [...bedTypeIds];

    if (e.currentTarget.checked) {
      newBedTypeIds.push(bedTypeId);
    } else {
      newBedTypeIds = newBedTypeIds.filter(
        (_bedTypeId) => _bedTypeId !== bedTypeId,
      );
    }

    onBedTypeChange(newBedTypeIds);
  };

  handlePlaceTypeSelect = (e) => {
    const { onPlaceTypeChange } = this.props;
    const placeTypeId = parseInt(e.currentTarget.value, 10);

    if (!isNaN(placeTypeId)) {
      onPlaceTypeChange(placeTypeId);
    }
  };

  handleAnyChange = (e) => {
    const { onPlaceTypeChange } = this.props;

    if (e.currentTarget) onPlaceTypeChange(0);
  };

  render() {
    const {
      bedTypeIds,
      bedTypes,
      placeTypeId,
      placeTypes,
      roomAmenities,
      roomAmenityIds,
      onRoomAmenitySelect,
    } = this.props;
    const { any } = this.state;

    return (
      <FilterSectionWrapper title={translate("search.filters.room")}>
        <FilterSubSectionWrapper
          title={translate("search.filters.room.placeType")}
        >
          <div className="SearchRoomForm__AnyPlaceType">
            <Checkbox
              checked={any}
              id="any-placetype"
              name="any-placetype"
              onChange={this.handleAnyChange}
            />
            <Text body={3}>
              {translate("search.filters.room.placeType.any")}
            </Text>
          </div>
          <FormSectionChoices>
            {placeTypes.map((placeType) => (
              <FormSectionChoiceElement>
                <Choice
                  checked={placeTypeId === placeType.id}
                  id={`placeType-${placeType.id}`}
                  key={placeType.id}
                  name="placeType"
                  onChange={this.handlePlaceTypeSelect}
                  type="radio"
                  value={placeType.value}
                >
                  {placeType.title}
                </Choice>
              </FormSectionChoiceElement>
            ))}
          </FormSectionChoices>
          <Text body={3}>
            {translate("search.filters.room.placeType.note")}
          </Text>
        </FilterSubSectionWrapper>
        <FilterSubSectionWrapper
          title={translate("search.filters.room.bedType")}
        >
          <div className="SearchRoomForm__BedTypes">
            {bedTypes.map((bedType) => (
              <Choice
                checked={bedTypeIds.includes(bedType.id)}
                id={`bedType-${bedType.id}`}
                key={bedType.id}
                name="bedType"
                onChange={this.handleBedTypeSelect}
                type="checkbox"
                value={bedType.value}
              >
                {bedType.title}
              </Choice>
            ))}
          </div>
        </FilterSubSectionWrapper>
        <FilterSubSectionWrapper
          title={translate("search.filters.room.roomAmenities")}
        >
          <AmenityChoice
            className="SearchRoomForm__Amenities"
            items={roomAmenities}
            name="roomAmenities"
            onChange={onRoomAmenitySelect}
            value={roomAmenityIds}
          />
        </FilterSubSectionWrapper>
      </FilterSectionWrapper>
    );
  }
}

export default RoomFields;
