import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import SortByForm from "./sort-by-form";

export default connect(
  (state) => ({
    sortBy: state.SearchFilters.sortBy,
  }),
  (dispatch) => ({
    setSortBy: (id) => dispatch(Actions.setSortBy(id)),
  }),
)(SortByForm);
