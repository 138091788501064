import React from "react";
import {
  Card,
  Flex,
  Heading,
  Spacer,
  Text,
  BADI_COLORS,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import StatusRow from "./status-row";
import { PriceBox } from "./styled-components";

const TransactionCard = ({
  amount = "",
  bookingPaidOut = {},
  bookingRequestAccepted = {},
  bookingRequestSent = {},
  seekerMovedIn = {},
  title = "",
}) => {
  return (
    <Card backgroundColor={BADI_COLORS.ADDITIONAL.ADDITIONAL_07}>
      <Spacer bottom={3} left={2.5} right={2.5} top={3}>
        <Heading
          data-qa="transaction-card-title"
          level="16-Bold"
          textColor={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
        >
          {title}
        </Heading>
        <Spacer bottom={2} />
        <Flex alignItems="center" as={PriceBox} justify="spaceBetween">
          <Flex direction="column">
            <Text body="14-Bold" color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}>
              {translate("transactions.summary_modal_amount_label")}
            </Text>
            <Spacer bottom={0.5} />
            <Text body="14-Regular" color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}>
              {translate("transactions.summary_modal_amount_tip")}
            </Text>
          </Flex>
          <Heading
            data-qa="transaction-card-amount"
            level="20-Bold"
            textColor={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
          >
            {amount}
          </Heading>
        </Flex>
        <Spacer bottom={3} />
        <Text body="14-Bold" color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}>
          {translate("transactions.summary_modal_list_title")}
        </Text>
        <Spacer bottom={1.5} />
        <StatusRow
          label={translate("transactions.summary_modal_bullet_1")}
          {...bookingPaidOut}
        />
        <StatusRow
          label={translate("transactions.summary_modal_bullet_2")}
          {...seekerMovedIn}
        />
        <StatusRow
          label={translate("transactions.summary_modal_bullet_3")}
          {...bookingRequestAccepted}
        />
        <StatusRow
          label={translate("transactions.summary_modal_bullet_4")}
          lastChild={true}
          {...bookingRequestSent}
        />
      </Spacer>
    </Card>
  );
};

export default TransactionCard;
