import React from "react";
import {
  BADI_COLORS,
  BUTTON_TYPES,
  Flex,
  Heading,
  Text,
  Spacer,
  LinkButton,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import TopBar from "components/Layout/stepper-layout/top-bar";
import Illustration from "Sections/booking/success-illustration";
import Image from "components/Image";
import InjectHtml from "components/inject-html";
import { getPublicAsset } from "base/shared/Utils";
import { StyledLink } from "./styled-components";

const SuccessPage = ({
  title,
  description,
  href,
  cta = "purchase.modal.success_cta",
  asset = null,
}) => {
  return (
    <>
      <TopBar data-qa="success-topbar" showProgress={false} />
      <Flex
        alignItems="center"
        direction="column"
        fullHeight={true}
        gutter={2}
        justify="center"
      >
        <Spacer bottom={2} top={8}>
          {asset ? (
            <Image src={getPublicAsset(asset)} />
          ) : (
            <Illustration data-qa="success-illustration" />
          )}
        </Spacer>
        <Heading
          color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
          data-qa="success-heading-title"
          level="24-Bold"
        >
          {translate(title)}
        </Heading>
        <Text
          align="center"
          body="16-Regular"
          color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
          dataQa="success-text-subtitle"
        >
          <InjectHtml>{translate(description)}</InjectHtml>
        </Text>
        <Spacer top={4} wide={true}>
          <LinkButton
            as={StyledLink}
            data-qa="success-confirmation-button"
            href={href}
            variant={BUTTON_TYPES.PREMIUM_SECONDARY}
          >
            {translate(cta)}
          </LinkButton>
        </Spacer>
      </Flex>
    </>
  );
};

export default SuccessPage;
