import React from "react";
import { colors } from "@badi/badi-components";

const busStyle = { display: "block " };

const BusIcon = ({ selected }) => {
  const color = selected ? colors.green : colors.darkGrey;
  return (
    <svg
      height="19"
      style={busStyle}
      viewBox="0 0 15 19"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color} fillRule="evenodd" stroke={color} strokeWidth=".4">
        <path d="M13.343 3.615H1.65v-.98c0-.54.438-.981.974-.981h9.745a.98.98 0 0 1 .974.98v.981zM1.65 10.808h11.693V4.269H1.65v6.539zm11.693 3.596a.98.98 0 0 1-.974.98H2.787c-.52 0-1.137-.44-1.137-.98v-2.942h11.693v2.942zm-.65 2.452a.48.48 0 0 1-.486.49h-.325a.48.48 0 0 1-.488-.49v-.818h.975c.114 0 .227-.016.325-.032v.85zm-9.094 0a.48.48 0 0 1-.488.49h-.324a.48.48 0 0 1-.488-.49v-.9c.163.05.325.082.488.082h.812v.818zM12.369 1H2.624C1.731 1 1 1.736 1 2.635v11.769c0 .507.276.932.666 1.226 0 .016-.016.049-.016.082v1.144c0 .637.503 1.144 1.137 1.144h.324c.634 0 1.137-.507 1.137-1.144v-.818h6.497v.818c0 .637.503 1.144 1.137 1.144h.325c.633 0 1.136-.507 1.136-1.144v-1.144c.39-.295.65-.769.65-1.308V2.634c0-.898-.73-1.634-1.624-1.634z" />
        <path d="M6.847 2.308H4.736a.327.327 0 0 0 0 .654h2.11a.327.327 0 0 0 0-.654M8.796 2.308h-.487a.327.327 0 0 0 0 .654h.487a.327.327 0 0 0 0-.654M3.436 12.933a.327.327 0 0 1 0 .654.327.327 0 0 1 0-.654m0 1.307a.98.98 0 0 0 0-1.961.98.98 0 0 0 0 1.961M11.557 12.933a.327.327 0 0 1 0 .654.327.327 0 0 1 0-.654m0 1.307a.98.98 0 0 0 0-1.961.98.98 0 0 0 0 1.961" />
      </g>
    </svg>
  );
};

BusIcon.defaultProps = {
  selected: false,
};

export default BusIcon;
