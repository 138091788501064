import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";

export const HeaderWrapper = styled.div`
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-position-x: ${unitizedPx(-45)};

  position: relative;
  width: 100%;
  display: flex;
  height: ${unitizedPx(72)};
  padding-bottom: ${unitizedPx(7)};

  ${mediaquery.md`
    height: ${unitizedPx(54)};
    background-position-x: center;
  `}

  ${mediaquery.lg`
    height: ${unitizedPx(79)};
  `}
`;

export const ContentWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: ${unitizedPx(19)};
  padding: ${unitizedPx(2)};
  width: ${unitizedPx(29)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-top-left-radius: ${unitizedPx(1)};
  border-bottom-left-radius: ${unitizedPx(1)};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);

  ${mediaquery.md`
    bottom: ${unitizedPx(3)};
  `}

  ${mediaquery.lg`
    bottom: ${unitizedPx(19)};
    width: ${unitizedPx(87)};
    padding: ${unitizedPx(3)} ${unitizedPx(15)} ${unitizedPx(3)} ${unitizedPx(
      5,
    )};
  `}
`;

export const CustomHeading = styled.h1`
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;

  ${mediaquery.lg`
    font-size: 40px;
     48px;
  `}
`;
