import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { locale } from "base/shared/Localization";

const getPaymentMethod = ({ token }) => {
  const { BADI_PAYMENT_ACCOUNT_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYMENT_ACCOUNT_SERVICE);

  return fetch(serviceUrl, { token });
};

const setPaymentMethod = (params, token) => {
  const { BADI_PAYMENT_METHOD_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYMENT_METHOD_SERVICE);

  return fetch(serviceUrl, {
    method: "POST",
    params,
    token,
    paramsRequired: true,
  });
};

const getVatCountries = ({ token }) => {
  const { BADI_PAYMENT_COUNTRIES } = envVars();
  const serverUrl = getServerUrl(BADI_PAYMENT_COUNTRIES);
  return fetch(serverUrl, {
    token,
    additionalParams: { locale: locale() },
  });
};

export { getPaymentMethod, setPaymentMethod, getVatCountries };
