import moment from "moment";
import dayjs from "dayjs";
import { translate } from "base/shared/Localization";
import { convertAffirmationToBool } from "base/shared/Utils";

import { reverseParseGenders } from "datamodel/RoomList/utils";

const fromStringToNumber = (input) => {
  if (typeof input === "string") {
    return Number(input);
  }
  return input;
};

const ALLOW_24_7_VISIT_PREFERENCES = {
  visits_enabled: true,
  category: 1,
  visits_start_at: dayjs().format("YYYY-MM-DD"),
  monday_enabled: true,
  tuesday_enabled: true,
  wednesday_enabled: true,
  thursday_enabled: true,
  friday_enabled: true,
  saturday_enabled: true,
  sunday_enabled: true,
  mornings_enabled: true,
  afternoons_enabled: true,
  evenings_enabled: true,
};

const parseDeposit = (depositVal, noDepositVal) => {
  const deposit = !depositVal && !noDepositVal ? null : depositVal;

  if (deposit === null || deposit === undefined) {
    return null;
  }
  return +deposit || 0;
};

export const mapRoomData = (roomData) => {
  const pictureIds = roomData.pictures
    ? roomData.pictures.map((picture) => picture.pictureId)
    : [];

  const room = {
    address: roomData.address,
    address_line_2: roomData.addressLine,
    agency_external_id: roomData.agencyReferenceId,
    available_from: moment(roomData.availableFrom).toISOString(true),
    available_to: roomData.availableTo
      ? moment(roomData.availableTo).toISOString(true)
      : null,
    bed_type: convertAffirmationToBool(roomData.bedIncluded)
      ? roomData.bedType
      : null,
    benefits: roomData.benefits,
    facade_side: roomData.orientation,
    single_bedrooms: roomData.singleBedrooms,
    double_bedrooms: roomData.doubleBedrooms,
    bathrooms: roomData.bathrooms,
    toilets: roomData.toilets,
    entire_place_amenities_ids: roomData.flatAmenities,
    room_amenities_ids: roomData.roomAmenities,
    house_rules_ids: roomData.rules,
    lift: convertAffirmationToBool(roomData.lift),
    city: roomData.city,
    country: roomData.country,
    description: roomData.description,
    male_tenants: roomData.maleTenants,
    female_tenants: roomData.femaleTenants,
    non_binary_tenants: roomData.nonBinaryTenants,
    resident: roomData.resident,
    latitude: roomData.latitude,
    longitude: roomData.longitude,
    min_days: fromStringToNumber(roomData.minimumStay),
    max_days:
      roomData.maximumStay !== "noMax"
        ? fromStringToNumber(roomData.maximumStay)
        : null,
    pictures: pictureIds,
    place_type: roomData.placeType,
    postal_code: roomData.postalCode,
    preferences:
      roomData.placeType === 2
        ? null
        : {
            genders: Array.isArray(roomData.genders)
              ? roomData.genders
              : reverseParseGenders(roomData.genders),
            occupation: roomData.occupation,
            min_age: roomData.min_age,
            max_age: roomData.max_age,
          },
    settings: {
      interested_in_verification: roomData.photosVerification,
    },
    prices_attributes: [
      {
        price_type: 3,
        price: +roomData.price,
        currency: roomData.currency,
        bills_included: !!roomData.billsIncluded,
        deposit: parseDeposit(roomData.deposit, roomData.noDeposit),
        agency_fee: roomData.hasAgencyFee,
        price_per_square_unit: +roomData.areaPrice,
      },
    ],
    entire_place_type: roomData.propertyType,
    street: roomData.street,
    street_number: roomData.streetNumber,
    tenants: [],
    title: roomData.title,
    undefined_tenants: 0,
    room_type: "shared_place",
    size: fromStringToNumber(roomData.roomSize),
    size_unit: roomData.sizeUnit.value || 1,
    flat_size: fromStringToNumber(roomData.flatSize),
    visit_preferences: ALLOW_24_7_VISIT_PREFERENCES,
  };

  return room;
};

export const getStepTitle = (step, title) =>
  `${translate("Step")} ${step}: ${title}`;

export const getAvailableStartDate = (availableFrom, minimumStay) => {
  const a = moment(availableFrom).clone();
  a.add(+minimumStay, "days");

  return a;
};

export const parseAgesToObject = (ages) =>
  ages
    ? {
        max_age: ages[1],
        min_age: ages[0],
      }
    : {};

export const getButtonText = (status) => {
  switch (status) {
    case undefined:
    case 0:
    case 6:
      return "list";
    case 7:
      return "updateAndPublish";
    default:
      return "update";
  }
};

export const buildOptions = (numberOfMonths, isMaxOptions) => {
  const stayOptions = [];
  for (let monthNumber = 1; monthNumber <= numberOfMonths; monthNumber += 1) {
    const monthNumberString = monthNumber.toString();
    stayOptions.push({
      value: (monthNumberString * 30).toString(),
      title: `${translate("num_months", { smart_count: monthNumber })}`,
    });
  }
  if (isMaxOptions) {
    stayOptions.unshift({
      value: "noMax",
      title: translate("room.list.step3.availability.nomax"),
    });
  }
  return stayOptions;
};

export const getMaximumStayValue = (
  availableToEnabled,
  maximumStayValue,
  minimumStay,
) =>
  !!availableToEnabled || Number(maximumStayValue) < Number(minimumStay)
    ? "noMax"
    : maximumStayValue;

export const getAvailableTo = (
  availableTo,
  availableStartDate,
  minimumStay,
  minimumStayByState,
  availableToEnabled,
) =>
  availableTo &&
  availableStartDate <= availableTo &&
  minimumStay === minimumStayByState &&
  availableToEnabled
    ? availableTo
    : null;

export const setMaxAvailableOptions = (maxStayOptions, minimumStay) =>
  maxStayOptions.filter(
    (maxOption) =>
      isNaN(maxOption.value) ||
      (!isNaN(maxOption.value) &&
        Number(maxOption.value) >= Number(minimumStay)),
  );
