import React, { memo } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import FormBlock from "components/form-block";
import TextArea from "components/Form/TextArea/TextAreaWithFormsy";

const AboutMe = ({ aboutMe = null, isValid }) => (
  <FormBlock
    dataQa="profile-form-about_me-section"
    title={translate("profile.form.label.description")}
    valid={isValid}
  >
    <TextArea
      className="Profile__form--description"
      maxLength={399}
      name="aboutMe"
      placeholder={translate("profile.form.fields.description")}
      required={false}
      validations={{
        maxLength: 399,
      }}
      value={aboutMe}
    />
    <Spacer bottom={3} />
  </FormBlock>
);

export default memo(AboutMe);
