import styled from "styled-components";
import { mediaquery, unitizedPx, BADI_COLORS } from "@badi/badi-components";
import { Col } from "reactstrap";

export const PlanWrapper = styled(Col)`
  margin-bottom: ${unitizedPx(6)};
`;

export const PriceWrapper = styled.div`
  background-color: ${BADI_COLORS.BRAND.TERTIARY.DEFAULT};
  padding: ${unitizedPx(1)} ${unitizedPx(2)} ${unitizedPx(2)};

  ${mediaquery.md`
    padding: ${unitizedPx(3)} ${unitizedPx(12)} ${unitizedPx(3)};
   `}
`;

export const BenefitsWrapper = styled.div`
  padding: ${unitizedPx(2)} ${unitizedPx(2)} 0;

  ${mediaquery.md`
    padding: ${unitizedPx(3)} ${unitizedPx(6)} 0;
   `}
`;

export const ActionBar = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  height: ${unitizedPx(9)};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
`;
