import React, { useCallback, useEffect } from "react";
import {
  Spacer,
  Text,
  Heading,
  Button,
  BUTTON_TYPES,
  Flex,
  useMatchMedia,
  BREAKPOINTS_PX,
} from "@badi/badi-components";
import Image from "components/Image";
import { translate } from "base/shared/Localization";
import { getColor } from "base/shared/get-color";
import { CONTENT_TYPES } from "./constants";

import PriceBox from "./components/price-box";
import BulletPoint from "./components/bullet-point";
import { OverlayHeader, OverlayBody } from "./styled-components";

const DynamicInfo = ({ info, onSubmit, onCancel, userId }) => {
  const {
    data: {
      header: {
        illustration,
        title,
        subtitle,
        background_color: backgorundColor,
      },
      body,
      primary_cta: primaryCta,
      secondary_cta: secondaryCta,
    },
  } = info;
  const isSingleButton = !secondaryCta;
  const onSubmitClick = useCallback(() => {
    onSubmit();
  }, []);
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.lg})`]);
  return (
    <>
      <OverlayHeader backgroundColor={getColor(backgorundColor)}>
        <Flex justify="center">
          <Image
            alt={translate("payment.guarantee_popup_title")}
            data-qa="info-modal-image"
            src={`/assets/images/dynamic-modal/${illustration}.svg`}
          />
        </Flex>
        <Spacer top={2} />
        <Heading
          align="center"
          color={getColor(title.color)}
          data-qa="info-modal-title"
          level="28-Extrabold"
        >
          {translate(title.content)}
        </Heading>
        <Spacer top={1} />
        <Heading
          align="center"
          color={getColor(subtitle.color)}
          data-qa="info-modal-subtitle"
          level="16-Medium"
        >
          {translate(subtitle.content)}
        </Heading>
      </OverlayHeader>
      <OverlayBody>
        {body.map(({ type, attrs }) => {
          switch (type) {
            case CONTENT_TYPES.PRICE:
              return (
                <PriceBox key={`${type}-${attrs.title.content}`} {...attrs} />
              );
            case CONTENT_TYPES.BULLET:
              return (
                <BulletPoint key={`${type}-${attrs.content}`} {...attrs} />
              );
            case CONTENT_TYPES.TEXT:
              return (
                <Spacer key={`${type}-${attrs.content}`} top={2}>
                  <Text>{translate(attrs.content)}</Text>
                </Spacer>
              );
            default:
              return false;
          }
        })}
        <Spacer top={3.5}>
          <Flex gutter={1} justify="flexEnd">
            {!isSingleButton && (
              <Button
                dataQa="info-modal-cancel-button"
                fullWidth={!isDesktop}
                grow={isDesktop ? 0 : 1}
                onClick={onCancel}
                variant={BUTTON_TYPES.SECONDARY}
              >
                {translate(secondaryCta.content)}
              </Button>
            )}
            <Button
              dataQa="info-modal-submit-button"
              fullWidth={!isDesktop}
              grow={isDesktop ? 0 : 1}
              onClick={onSubmitClick}
              variant={BUTTON_TYPES.PRIMARY}
            >
              {translate(primaryCta.content)}
            </Button>
          </Flex>
        </Spacer>
      </OverlayBody>
    </>
  );
};

export default DynamicInfo;
