import { connect } from "react-redux";
import UserActions from "datamodel/User/actions";
import { getMarketingConsent } from "datamodel/User/selectors";
import { getToken } from "datamodel/Auth/selectors";
import MarketingNotifications from "./marketing-notifications";

const mapStateToProps = (state) => ({
  marketingConsent: getMarketingConsent(state),
  token: getToken(state),
});

const mapDispatchToProps = {
  updateLegal: UserActions.updateLegal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketingNotifications);
