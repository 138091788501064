import styled from "styled-components";
import { unitizedPx, mediaquery } from "@badi/badi-components";

export const RoomCardWrapper = styled.div`
  width: 100%;
  height: ${unitizedPx(42)};
  position: relative;
  display: inline-block;
  margin-bottom: ${unitizedPx(3)};

  ${mediaquery.lg`
    width: ${unitizedPx(49.5)};
    `}

  ${mediaquery.xl`
  ${({ index }) =>
    index % 2 === 0 &&
    `margin-right: ${unitizedPx(3)};
  `}
  `}
`;

export const RoomCardLink = styled.a`
  &:hover,
  &:visited,
  &:link,
  &:active {
    cursor: pointer;
    text-decoration: none;
    color: unset;
  }
`;
