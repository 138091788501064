import React from "react";
import {
  Text,
  InlineMessage,
  MESSAGE_TYPES,
  Spacer,
  Flex,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const defaultMessage = () => (
  <Text body="14-Regular" dataQa="moderation-info-default-message">
    {translate("moderation.message.reason.various")}
  </Text>
);

const rulesMessage = (failingRules) => (
  <>
    <Text body="14-Bold" dataQa="moderation-info-header">
      {translate("moderation.message.title")}
    </Text>
    <>
      <Spacer bottom={1} left={2}>
        {failingRules.map((failedRule) => (
          <Text
            body="14-Regular"
            key={failedRule.ruleName}
          >{`  - ${failedRule.userFeedback}`}</Text>
        ))}
      </Spacer>
      <Text body="14-Medium" dataQa="moderation-info-footer">
        {translate("moderation.message.text")}
      </Text>
    </>
  </>
);

const rulesPresent = (failedRules) => failedRules?.length > 0;

const ModerationInfo = ({ moderationResult }) => {
  const { manualCheck, autoCheck, failedRules } = moderationResult;

  const message =
    manualCheck === false || (!autoCheck && !rulesPresent(failedRules))
      ? defaultMessage()
      : rulesMessage(failedRules);

  return (
    <Spacer bottom={4} left={4} right={4} top={2}>
      <Flex justify="center">
        <InlineMessage variant={MESSAGE_TYPES.WARNING}>{message}</InlineMessage>
      </Flex>
    </Spacer>
  );
};

export default ModerationInfo;
