import React from "react";
import Helmet from "react-helmet";
import { Flex, Spacer, LinkButton, Heading } from "@badi/badi-components";
import { Text } from "app/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { getHomePath } from "app/Sections/Home/routes";

import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import { Container } from "reactstrap";

import TopBar from "../components/top-bar";
import Header from "./components/header";
import { Col, Row } from "reactstrap";
import ContentList from "./components/content-list/content-list";
import Testimonial from "./components/testimonial";
import { HOW, WHY } from "./constants";

const Section = ({ children, description = null, title }) => (
  <Row>
    <Col>
      <Heading data-qa="landing-section-title" level="28-Extrabold">
        {title}
      </Heading>
      <Spacer bottom={6} top={2}>
        <Text dataQa="landing-section-desc">{description}</Text>
      </Spacer>
      {children}
    </Col>
  </Row>
);

const ListRoomApartment = () => {
  const description = translate("listroomapartment.meta.description");
  const title = translate("listroomapartment.meta.title");

  return (
    <LayoutWrapper
      footer={<Footer campaign="rentroom_footer" />}
      padding={false}
      topBar={<TopBar />}
    >
      <Header />
      <Container>
        <Spacer top={8} />
        <Flex direction="column" gutter={8}>
          <Section
            description={translate("rentRoom.sections.why.subTitle")}
            title={translate("rentRoom.sections.why.title")}
          >
            <ContentList disposition={{ md: 6, sm: 5, lg: 4 }} items={WHY} />
          </Section>
          <Section title={translate("rentRoom.sections.how.title")}>
            <ContentList disposition={{ md: 6, sm: 5, lg: 3 }} items={HOW} />
          </Section>
          <Testimonial
            author={translate("rentRoom.testimonial.author")}
            imageSrc={getPublicAsset("images/landings/testimonial.jpg")}
            text={translate("rentRoom.testimonial.text")}
          >
            <LinkButton
              dataQa="list-room-apartment-button"
              href={`${getHomePath()}list/new`}
            >
              {translate("rentRoom.button.text")}
            </LinkButton>
          </Testimonial>
        </Flex>
        <Spacer bottom={8} />
      </Container>
      <Helmet
        meta={[
          { property: "og:title", content: title },
          { name: "description", content: description },
          { property: "og:description", content: description },
        ]}
        title={title}
      />
    </LayoutWrapper>
  );
};

export default ListRoomApartment;
