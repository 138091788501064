import createReducer from "base/shared/createReducer";
import Types from "../types";
import { DeviceModel } from "../models";
import {
  isDesktop,
  isIE,
  isSafari,
  isChrome,
  isFirefox,
  isIOS,
  isAndroid,
} from "../utils";

function setDevice(state, action) {
  const browser = action.result;
  const { device, os } = browser;
  const desktop = isDesktop(os.family);

  return {
    os: {
      name: os.family,
      version: os.toString(),
    },
    device: {
      name: device.family,
      version: device.toString(),
    },
    browser: {
      name: browser.family,
      version: browser.toString(),
    },
    isMobile: !desktop,
    isDesktop: desktop,
    isIE: isIE(browser.family),
    isSafari: isSafari(browser.family),
    isChrome: isChrome(browser.family),
    isFirefox: isFirefox(browser.family),
    isIOS: isIOS(os.family),
    isAndroid: isAndroid(os.family),
  };
}

const actionHandlers = {
  [Types.SET_DEVICE]: setDevice,
};

export default createReducer(actionHandlers, { ...DeviceModel });
