import { connect } from "react-redux";

import AuthActions from "datamodel/Auth/actions";
import { authErrorSelector, getUserId } from "datamodel/Auth/selectors";

import AuthTokenManager from "./token-manager";

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  error: authErrorSelector(state),
});

const mapDispatchToProps = {
  refreshToken: AuthActions.refreshToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthTokenManager);
