import React from "react";

import RoomActions from "datamodel/Room/actions";
import { getEditProfilePath, NEXT_ACTIONS } from "Sections/user-profile/routes";
import { getRoomDetailPath } from "../routes";

import RoomDetail from "./RoomDetailMapping";

const RoomDetailRoute = ({ router, params = {}, location = {} }) => {
  const { open_flow: openFlow } = location.query;

  const handleOpenProfile = (roomDetailAction) => {
    const roomDetailPath = getRoomDetailPath({
      roomId: params.roomId,
      params: { keyVal: "open_flow", options: roomDetailAction },
    });

    router.push(
      getEditProfilePath({
        nextAction: NEXT_ACTIONS.ENQUIRY,
        value: roomDetailPath,
      }),
    );
  };

  return (
    <RoomDetail
      initialFlow={openFlow}
      location={location}
      openProfile={handleOpenProfile}
      params={params}
      router={router}
    />
  );
};

RoomDetailRoute.requiredActions = [RoomActions.getRoom];

export default RoomDetailRoute;
