import React, { useState, useEffect } from "react";
import { Flex } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import BookingPreview from "components/booking-preview";

import { getBookingPreview } from "datamodel/booking/api";
import { isLister } from "datamodel/User/utils";
import { DEFAULT_EXPIRATION_HOURS } from "datamodel/booking/constants";

import { TextMessage, MessageMaxChars } from "./styled-components";
import BookingStepLayout from "../step-layout";

const CHARS_LIMIT = 500;

const MessageStep = ({
  connectionId = 0,
  moveOut = "",
  onValidation = () => {},
  roomId = 0,
  title = "",
  message = "",
  monthsLength = 0,
  moveIn,
  token,
  id,
  seekerId = null,
}) => {
  const [userMessage, setMessage] = useState(message);
  const [userRole, setUserRole] = useState("");
  const [expirationHours, setExpirationHours] = useState(
    DEFAULT_EXPIRATION_HOURS,
  );
  const [room, setRoom] = useState(null);
  const [user, setUser] = useState(null);
  const [lengthInMonths, setLengthInMonths] = useState(null);
  const [price, setPrice] = useState(null);

  const handleMessageChange = (event) => {
    const value = event.target.value;
    if (value.length <= CHARS_LIMIT) {
      setMessage(value);
    }
  };

  useEffect(() => {
    getBookingPreview({
      token,
      connectionId,
      moveIn,
      moveOut,
      monthsLength,
      roomId,
      seekerId,
    }).then((response) => {
      setUserRole(response.userRole);
      setRoom(response.room);
      setUser(isLister(response.userRole) ? response.seeker : response.lister);
      setPrice(response.price);
      setExpirationHours(response.expirationHours);
      setLengthInMonths(response.lengthInMonths);
    });
  }, [connectionId]);

  useEffect(() => {
    onValidation({
      id,
      valid: true,
      payload: {
        message: userMessage,
      },
    });
  }, [userMessage]);

  const charsLeft = CHARS_LIMIT - userMessage.length;
  const charsLeftMessage =
    charsLeft === CHARS_LIMIT
      ? "booking.new.steps.message.chars_max"
      : "booking.new.steps.message.chars_left";
  const sidebar = [];

  if (room && user && price) {
    sidebar.push(
      <BookingPreview
        isFullInfoBr={false}
        key="booking-preview"
        lengthInMonths={lengthInMonths}
        moveIn={moveIn}
        moveOut={moveOut}
        price={price}
        room={room}
        user={user}
        userRole={userRole}
      />,
    );
  }

  return (
    <BookingStepLayout
      expirationHours={expirationHours}
      hideBadiGuaranteeCard={true}
      sidebar={sidebar}
      sidebarTitle={translate("booking.new.request_details_title")}
      subtitle={translate("booking.new.steps.message.sub-heading")}
      title={translate(title)}
      userRole={userRole}
    >
      <Flex direction="column" gutter={1}>
        <TextMessage
          data-qa="message-step-text-message"
          grow={1}
          name="message"
          onChange={handleMessageChange}
          placeholder={translate("booking.new.steps.message.placeholder")}
          value={userMessage}
        />
        <MessageMaxChars data-qa="message-step-max" grow={1}>
          {`${charsLeft} ${translate(charsLeftMessage)}`}
        </MessageMaxChars>
      </Flex>
    </BookingStepLayout>
  );
};

export default MessageStep;
