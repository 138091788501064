import { tokenInfo, refreshToken as getAccessToken } from "datamodel/Auth/api";

export const getRefreshedToken = async (
  accessToken,
  refreshToken,
  { ssr } = {},
) => {
  try {
    await tokenInfo(accessToken);
    return null;
  } catch (e) {
    if (e.code === "invalid_token_intercept") {
      return getAccessToken({ refreshToken }, ssr);
    }
    throw e;
  }
};
