import styled from "styled-components";
import { unitizedPx, colors } from "@badi/badi-components";

export const TextMessage = styled.textarea`
  display: block;
  width: 100%;
  min-height: ${unitizedPx(15)};
  padding: ${unitizedPx(2)};
  font-size: 16px;
  border-radius: ${unitizedPx(0.5)};
  border: 1px solid ${colors.softGrey};

  &:focus {
    border-color: ${colors.darkGrey};
  }
`;

export const MessageMaxChars = styled.div`
  text-align: right;
`;
