import styled, { css } from "styled-components";
import {
  BREAKPOINTS_PX,
  unitizedPx,
  Flex,
  colors,
} from "@badi/badi-components";

export const RoomCardDetails = styled.div`
  height: 100%;
  min-width: ${unitizedPx(33)};
  flex-shrink: 0;
  border-bottom-left-radius: ${unitizedPx(0.5)};
  border-bottom-right-radius: ${unitizedPx(0.5)};

  @media screen and (max-width: ${BREAKPOINTS_PX.xl}) and (min-width: ${BREAKPOINTS_PX.lg}) {
    max-width: calc(100% - ${unitizedPx(33)});
  }

  ${(props) =>
    props.floating &&
    `
    background-color: white;
    padding: 16px;
  `}
`;

const searchType = css`
  @media screen and (min-width: ${BREAKPOINTS_PX.xs}) {
    flex-direction: column;
  }
  @media screen and (min-width: ${BREAKPOINTS_PX.lg}) {
    flex-direction: row;
  }
  @media screen and (min-width: ${BREAKPOINTS_PX.xl}) {
    flex-direction: column;
  }
`;

const collectionType = css`
  flex-direction: column;
  width: ${unitizedPx(33)};
`;

export const RoomCardWrapper = styled.div`
  ${({ type }) => (type !== "collection" ? searchType : collectionType)}
`;

export const RoomCardLink = styled.a`
  display: block;

  ${({ type }) =>
    `@media screen and (min-width: ${BREAKPOINTS_PX.md}) {
    width: calc(47vw - 12px);
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.lg}) {
    width: 100%;
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.xl}) {
    width: 372px;
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.xxl}) {
    width: 396px;
  }`}

  &:hover,
  &:visited,
  &:link,
  &:active {
    cursor: pointer;
    text-decoration: none;
    color: unset;
  }
`;

const imageWrapperSearch = css`
  @media screen and (min-width: ${BREAKPOINTS_PX.lg}) {
    width: ${unitizedPx(33)};
    margin-bottom: 0;
    margin-right: ${unitizedPx(3)};
    flex-shrink: 1;
  }

  @media screen and (min-width: ${BREAKPOINTS_PX.xl}) {
    width: 100%;
    margin-bottom: ${unitizedPx(2)};
    margin-right: 0;
  }
`;

const imageWrapperCollection = css`
  width: ${unitizedPx(33)};
  margin-bottom: ${unitizedPx(2)};
  margin-right: ${unitizedPx(3)};
  flex-shrink: 1;
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: ${unitizedPx(1)};
  border-radius: ${unitizedPx(0.5)};
  ${({ type }) =>
    type !== "collection" ? imageWrapperSearch : imageWrapperCollection}

  ${({ floating }) =>
    floating &&
    `border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;`}

  ${RoomCardLink}:hover & {
    box-shadow: 0px ${unitizedPx(1)} ${unitizedPx(2)} 0px rgba(0, 0, 0, 0.2);
  }
`;

export const Title = styled.h4`
  padding: 0;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`;

export const Price = styled.h4`
  font-size: 20px;
  line-height: 20px;
`;

export const BoostedLabel = styled.div`
  color: ${colors.midGrey};
  font-size: ${unitizedPx(1.5)};
  line-height: ${unitizedPx(1.5)};
  text-align: right;
`;

export const BadgeBar = styled(Flex)`
  position: absolute;
  padding: ${unitizedPx(1)};
  z-index: 1;
`;
