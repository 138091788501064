import React from "react";

const ArchiveConnection = ({ color, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill={color}
      stroke={color}
      strokeWidth="0.5"
      d="M6.926 24h18.148A2.936 2.936 0 0 0 28 21.061V11.94A2.936 2.936 0 0 0 25.074 9H6.926A2.936 2.936 0 0 0 4 11.939v9.122A2.936 2.936 0 0 0 6.926 24zm18.148-1.071H6.926a1.865 1.865 0 0 1-1.86-1.868v-3.49h5.856l.992 2.143h8.347l.923-2.143h5.75v3.49c0 1.03-.835 1.868-1.86 1.868zM6.926 10.07h18.148c1.025 0 1.86.838 1.86 1.868V16.5h-6.45l-.923 2.143h-6.967l-.992-2.143H5.067v-4.561c0-1.03.834-1.868 1.859-1.868z"
    />
  </svg>
);

ArchiveConnection.defaultProps = {
  color: "#333",
  className: "",
};

export default ArchiveConnection;
