import { getCDNUrl } from "base/shared/Utils";

export const getImageUrl = (condition, domainUrl, path = "") => {
  if (condition)
    return path.startsWith("http")
      ? path
      : `${domainUrl || getCDNUrl()}${path}`;
  return "";
};

export const getBackGroundImage = (url) => (url ? `url(${url})` : "");

export const getHtmlProps = ({ alt, className, styles, tag }, url) => {
  const props = { alt, className, style: { ...styles } };

  if (tag === "img") {
    props.src = url;
  } else {
    props.style.backgroundImage = getBackGroundImage(url);
  }
  return props;
};
