import React from "react";

import { Heading, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const FormBlock = ({ title, children }) => (
  <Spacer top={6}>
    <Heading level="20-Bold">{translate(title)}</Heading>
    <Spacer top={3}>{children}</Spacer>
  </Spacer>
);

export default FormBlock;
