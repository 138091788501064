import React, { PureComponent } from "react";

class Help extends PureComponent {
  render() {
    const { color, onClick, className } = this.props;

    return (
      <svg
        className={className}
        height="26"
        onClick={onClick}
        viewBox="0 0 26 26"
        width="26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 1C6.38 1 1 6.38 1 13s5.38 12 12 12 12-5.38 12-12S19.62 1 13 1zm0 1.333A10.657 10.657 0 0 1 23.667 13 10.657 10.657 0 0 1 13 23.667 10.657 10.657 0 0 1 2.333 13 10.657 10.657 0 0 1 13 2.333zm0 4c-2.172 0-4 1.712-4 3.747a.618.618 0 0 0 .33.548.706.706 0 0 0 .673 0 .618.618 0 0 0 .33-.548c0-1.315 1.263-2.498 2.667-2.498s2.667 1.183 2.667 2.498c.01 1.083-.615 1.641-1.438 2.342-.411.35-.866.717-1.24 1.18-.373.464-.656 1.058-.656 1.757v.341a.618.618 0 0 0 .33.548.706.706 0 0 0 .673 0 .618.618 0 0 0 .33-.548v-.341c0-.402.13-.688.386-1.005.256-.318.648-.644 1.073-1.005.848-.723 1.885-1.7 1.875-3.269v-.01c-.006-2.03-1.831-3.737-4-3.737zM13 18a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
          fill={color}
          fillRule="nonzero"
          stroke={color}
          strokeWidth=".5"
        />
      </svg>
    );
  }
}

Help.defaultProps = {
  className: "",
  color: "#333333",
  onClick: () => {},
};

export default Help;
