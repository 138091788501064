import React, { Suspense } from "react";
import { translate } from "base/shared/Localization";
import { GRAPH_TYPES, TARGET_VALUES } from "../../constants";

const MetricEvolutionGraph = React.lazy(
  () => import("./metric-evolution-graph"),
);

const ConnectionEvolutionGraph = ({ evolution }) => (
  <Suspense fallback={<div />}>
    <MetricEvolutionGraph
      dataQa="connection-metric-evolution-graph"
      metricEvolution={evolution}
      subTitle={translate(
        "lister.analytics_subtitle_connection_evolution_text",
      )}
      targetValue={TARGET_VALUES[GRAPH_TYPES.CONNECTION_EVOLUTION]}
      title={translate("lister.analytics_subtitle_connection_evolution")}
      xLabel={translate("lister.analytics_evo_item_date")}
      yLabel={translate("lister.analytics_evo_item_connections")}
    />
  </Suspense>
);

export default ConnectionEvolutionGraph;
