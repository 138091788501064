import { translate } from "base/shared/Localization";
import { REVIEWER_TYPES } from "datamodel/reviews/constants";

export const getStarReviewSubtitle = (value) => {
  switch (value) {
    case 1:
      return translate("review.popup_rating_1_star");
    case 2:
      return translate("review.popup_rating_2_stars");
    case 3:
      return translate("review.popup_rating_3_stars");
    case 4:
      return translate("review.popup_rating_4_stars");
    case 5:
      return translate("review.popup_rating_5_stars");
    default:
      return translate("review.popup_rating_explanation");
  }
};

export const isLister = (reviewerType) =>
  reviewerType === REVIEWER_TYPES.LISTER;

export const isSeeker = (reviewerType) =>
  reviewerType === REVIEWER_TYPES.SEEKER;
