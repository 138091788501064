import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import MarketplaceSegmentsForm from "./marketplace-segments-form";

import { getSearchFiltersMarketplaceSegmentsValues } from "datamodel/SearchFilters/selectors";

const mapStateToProps = (state) => ({
  filters: getSearchFiltersMarketplaceSegmentsValues(state),
});

export default connect(mapStateToProps, (dispatch) => ({
  setMarketplaceSegmentsFilters: (filters) =>
    dispatch(Actions.setMarketplaceSegmentsFilters({ filters })),
}))(MarketplaceSegmentsForm);
