import { createSelector } from "reselect";

import { SEEKER_USER_ROLE, LISTER_USER_ROLE } from "datamodel/User/constants";

export const getInboxConnection = (state) => state.InboxConnection;

export const getConnectionHistory = createSelector(
  getInboxConnection,
  (connection) => connection.history,
);

export const getConnection = createSelector(
  getInboxConnection,
  (connection) => connection.connection,
);

export const getConnectionId = createSelector(
  getConnection,
  (connection) => connection.id,
);

export const getConnectionStatus = createSelector(
  getConnection,
  ({ status }) => status,
);

export const getRoom = createSelector(
  getConnection,
  (connection) => connection.room,
);

export const getConnectionRoomId = createSelector(
  getRoom,
  (room) => room?.id || 0,
);

export const isMessagingAllowed = createSelector(
  getConnection,
  (connection) => connection.allowedActions.message,
);

export const getMessageEnquiryFetched = (state) =>
  state.InboxConnection.newMessageEnquiry.fetched;

export const getMessageEnquiry = (state) =>
  state.InboxConnection.newMessageEnquiry;

export const getNewMessage = createSelector(
  getInboxConnection,
  (connection) => connection.newMessage,
);

export const isSendingMessage = createSelector(
  getNewMessage,
  (newMessage) => newMessage.fetching,
);

export const getMessageSentError = createSelector(
  getNewMessage,
  (newMessage) => newMessage.error,
);

export const getUserRole = createSelector(getRoom, (room) => {
  if (!room) return null;

  return room.owned ? LISTER_USER_ROLE : SEEKER_USER_ROLE;
});

export const getMessagesHistory = createSelector(
  getConnectionHistory,
  (history) => history.messages,
);

export const getMessagesHistoryCurrentPage = createSelector(
  getConnectionHistory,
  (history) => history.pagination.currentPage,
);

export const getMessagesHistoryTotalPages = createSelector(
  getConnectionHistory,
  (history) => history.pagination.totalPages,
);

export const getLastMessageIdSelector = createSelector(
  getInboxConnection,
  (connection) => connection.lastMessageId,
);
