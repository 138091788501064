import React from "react";
import Helmet from "react-helmet";

import { getAlternates } from "./utils";

const HeaderLang = ({ canonicalUrl = "", relativePath, translations = [] }) => {
  const alternates = getAlternates({
    canonicalUrl,
    relativePath,
    translations,
  });

  return <Helmet link={alternates} />;
};

export default HeaderLang;
