export const SITE_URL =
  "https://www.google.com/recaptcha/%version%.js?render=%site%&onload=%callback%";

export const STATUS = {
  LOADED: "loaded",
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  READY: "ready",
  DISABLED: "disabled",
};

export const LOAD_CALLBACK_NAME = "onCaptchaLoaded";
export const LOAD_EVENT_NAME = "recaptcha_loaded";
