import React from "react";

const InjectHtml = ({ children, dataQa = null }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      data-qa={dataQa}
    />
  );
};

export default InjectHtml;
