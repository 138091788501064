import React from "react";

import Map from "components/map-search-v2";

const MapWrapper = ({ bounds, ...rest }) =>
  bounds ? <Map {...rest} bounds={bounds} /> : null;

MapWrapper.defaultProps = {
  bounds: null,
};

export default MapWrapper;
