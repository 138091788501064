export const ONBOARDING_ROLE = {
  PARTICULAR: "particular",
  PROFESSIONAL: "professional",
};

export const STEPS = {
  general: [
    {
      nameField: "mode",
      title: "onboarding.general.one.title",
      description: "onboarding.general.one.description",
      options: [
        {
          title: "onboarding.general.one.options.one.title",
          value: "lister",
          imageUrl: "/assets/images/illustration-lister.svg",
        },
        {
          title: "onboarding.general.one.options.two.title",
          value: "seeker",
          imageUrl: "/assets/images/illustration-seeker.svg",
        },
      ],
    },
    {
      nameField: "role",
      title: "onboarding.two.title",
      description: "onboarding.two.description",
      options: [
        {
          title: "onboarding.two.options.particular.title",
          value: "particular",
        },
        {
          title: "onboarding.two.options.professional.title",
          value: "professional",
        },
      ],
    },
  ],
  lister_role: [
    {
      intro: true,
      title: "onboarding.lister.one.title",
      description: "onboarding.lister.one.description",
      options: null,
    },
    {
      nameField: "role",
      title: "onboarding.two.title",
      description: "onboarding.two.description",
      options: [
        {
          title: "onboarding.two.options.particular.title",
          value: ONBOARDING_ROLE.PARTICULAR,
        },
        {
          title: "onboarding.two.options.professional.title",
          value: ONBOARDING_ROLE.PROFESSIONAL,
        },
      ],
    },
  ],
};
