import React, { useEffect, useState } from "react";
import { Flex } from "@badi/badi-components";

import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";
import { VISIT_FEEDBACK_STATUS } from "datamodel/visits/constants";
import VisitSteps from "../components/visit-steps";
import NegativeFeedback from "./components/negative-feedback";
import PositiveFeedback from "./components/positive-feedback";
import VisitFeedbackForm from "./components/visit-feedback-form";

const VISIT_FEEDBACK_SCREEN = {
  FORM: "form",
  POSITIVE_FEEDBACK: "yes",
  NEGATIVE_FEEDBACK: "no",
};

const VisitFeedback = ({
  getVisitRequest,
  onBookingRequest,
  onClose = null,
  onVisitFeedback,
  userId,
  visitId = 0,
}) => {
  const [visitDetails, setVisitDetails] = useState(null);
  const [screen, setScreen] = useState(VISIT_FEEDBACK_SCREEN.FORM);

  const handleSubmit = (value) => {
    onVisitFeedback({ visitId, outcome: value }).then(() => {
      switch (Number(value)) {
        case VISIT_FEEDBACK_STATUS.UNINTERESTED:
          setScreen(VISIT_FEEDBACK_SCREEN.NEGATIVE_FEEDBACK);
          break;
        case VISIT_FEEDBACK_STATUS.INTERESTED:
          setScreen(VISIT_FEEDBACK_SCREEN.POSITIVE_FEEDBACK);
          break;
        default:
          break;
      }
    });
  };

  const handleBookRequest = () => {
    const { connectionId } = visitDetails;
    onClose();
    onBookingRequest(connectionId);
  };

  const getContent = () => {
    const userRole =
      visitDetails.seeker.id === userId ? SEEKER_USER_ROLE : LISTER_USER_ROLE;

    switch (screen) {
      case VISIT_FEEDBACK_SCREEN.POSITIVE_FEEDBACK:
        return (
          <PositiveFeedback
            grow={1}
            onSubmit={handleBookRequest}
            screenRole={userRole}
          />
        );
      case VISIT_FEEDBACK_SCREEN.NEGATIVE_FEEDBACK:
        return (
          <NegativeFeedback grow={1} onClose={onClose} screenRole={userRole} />
        );
      default:
        return (
          <VisitFeedbackForm
            date={visitDetails.displayDate}
            grow={1}
            onSubmit={handleSubmit}
            roomDetails={visitDetails.room}
            screenRole={userRole}
            userDetails={visitDetails.seeker}
          />
        );
    }
  };

  useEffect(() => {
    getVisitRequest({ id: visitId }).then(setVisitDetails);
  }, []);

  if (!visitDetails) {
    return null;
  }
  const content = getContent();

  return (
    <Flex direction="column" fullHeight={true}>
      <VisitSteps status={visitDetails.status} />
      {content}
    </Flex>
  );
};

export default VisitFeedback;
