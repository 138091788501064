import React from "react";

import { Heading, Text, Flex, Spacer, Button } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Image from "components/Image";

const NegativeFeedback = ({ screenRole, onClose }) => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      fullHeight={true}
      gutter={2}
      justify="center"
    >
      <Image
        alt={translate(`inbox_popup_success.${screenRole}.title`)}
        lazy={false}
        src="/assets/images/visits/thank-you-feedback.svg"
      />
      <Spacer top={2}>
        <Heading align="center" data-qa="visit-negative-feedback-title">
          {translate(`inbox_popup_success.${screenRole}.title`)}
        </Heading>
      </Spacer>
      <Text align="center" dataQa="visit-negative-feedback-desc" grow={1}>
        {translate(`inbox_popup_success.${screenRole}.description`)}
      </Text>
      <Button
        dataQa="visit-negative-feedback-cta"
        fullWidth={true}
        onClick={onClose}
        wide={true}
      >
        {translate(
          `inbox_popup_success.${screenRole}.description.primary.button`,
        )}
      </Button>
    </Flex>
  );
};

export default NegativeFeedback;
