import React from "react";
import { useSelector } from "react-redux";
import { Button, Flex, Spacer, Text } from "@badi/badi-components";
import { getToken } from "datamodel/Auth/selectors";
import { leaveInboxConversation } from "datamodel/Conversation/api";
import { translate } from "base/shared/Localization";
import { Root } from "./styled-components";

const LeaveConnection = ({ connectionId, onCancel, onLeave }) => {
  const token = useSelector(getToken);

  const handleLeaveClick = () => {
    leaveInboxConversation(connectionId, token).then(() => {
      onLeave();
    });
  };

  return (
    <Flex as={Root} direction="column" gutter={1}>
      <Spacer bottom={2} top={2}>
        <Text body="14-Regular" dataQa="leave-connection-title">
          {translate("visits.leave.text")}
        </Text>
      </Spacer>
      <Button
        dataQa="leave-connection-leave"
        fullWidth={true}
        onClick={handleLeaveClick}
      >
        {translate("visits.leave.delete.cta")}
      </Button>
      <Button
        dataQa="leave-connection-cancel"
        fullWidth={true}
        onClick={onCancel}
        variant="secondary"
      >
        {translate("visits.leave.close.cta")}
      </Button>
    </Flex>
  );
};

export default LeaveConnection;
