import React from "react";

import { Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import Info from "components/Icons/Info";
import FilterSectionWrapper from "../../../wrappers/filter-section-wrapper";

import "./FlatmatesUnavailableExt";

const FlatmatesUnavailable = () => (
  <FilterSectionWrapper title={translate("search.filters.flatmates")}>
    <div className="FlatmatesUnavailable">
      <Info />
      <Text body={3}>
        {translate("search.filters.availability.unavailable")}
      </Text>
    </div>
  </FilterSectionWrapper>
);

export default FlatmatesUnavailable;
