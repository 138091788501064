import React from "react";
import { FilterButton } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import {
  DEFAULT_CATEGORY_VALUE,
  CATEGORIES_ENUM,
} from "datamodel/Inbox/constants";
import Select from "components/form-select";
import styled from "styled-components";

const CATEGORIES = [CATEGORIES_ENUM.DEFAULT, CATEGORIES_ENUM.CLOSED];

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Header = ({
  disabled,
  category,
  onChangeCategory,
  onFilterClick,
  onCancelFilter,
  roomTitle = null,
  className,
}) => {
  const categories = translate(CATEGORIES, "label");

  return (
    <Container className={className}>
      <Select
        disabled={disabled}
        initialValue={category || DEFAULT_CATEGORY_VALUE}
        name="categories"
        onChange={({ value }) => onChangeCategory(value)}
        options={categories}
        fullWidth={false}
      />
      <FilterButton
        icon={getPublicAsset("icons/bed_16.svg")}
        id="filter_by_room"
        onCancelFilter={onCancelFilter}
        onFilterClick={onFilterClick}
        title={translate("inbox.dropdown_all")}
        value={roomTitle}
      />
    </Container>
  );
};

export default Header;
