import React, { PureComponent } from "react";
import { connect } from "react-redux";

import UserFeedback from "base/shared/user-feedback";
import { fetchTags } from "datamodel/User/api";
import Interests from "./Interests";

class InterestsMapping extends PureComponent {
  state = { tagList: [] };

  componentDidMount() {
    fetchTags(this.props.token)
      .then((tags) => this.setState({ tagList: tags }))
      .catch((e) =>
        UserFeedback.exception("Something went wrong", e.message, e),
      );
  }

  render() {
    const { token, ...interestsProps } = this.props;
    return <Interests {...interestsProps} tagList={this.state.tagList} />;
  }
}

export default connect((state) => ({
  token: state.Auth.access_token,
}))(InterestsMapping);
