import { createSelector } from "reselect";

export const getAuthInfo = (state) => state.Auth;
export const getToken = createSelector(
  getAuthInfo,
  (auth) => auth.access_token,
);
export const getRefreshToken = createSelector(
  getAuthInfo,
  (auth) => auth.refresh_token,
);
export const getUserId = createSelector(getAuthInfo, (auth) => auth.user_id);
export const isLoggedUser = createSelector(getUserId, (userId) => !!userId);
export const getAuthMethod = createSelector(
  getAuthInfo,
  (auth) => auth.authMethod,
);
export const authFetchingSelector = createSelector(
  getAuthInfo,
  (auth) => auth.fetching,
);
export const authErrorSelector = createSelector(
  getAuthInfo,
  (auth) => auth.error,
);
export const newUserSelector = createSelector(
  getAuthInfo,
  (auth) => auth.new_user,
);
