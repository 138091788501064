const ModalsModel = {
  modals: [],
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Modals) {
    return { ...ModalsModel, error: "There was an error in ModalsModel." };
  }
  return state;
}

export { ModalsModel, setInitialState };
