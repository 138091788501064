import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "SET_GDPR",
  "SET_COUNTRY_VAT",
  "SET_ONBOARDING",
  "SET_LOGIN",
  "SET_REGISTER",
  "SET_PROFILE",
  "SET_PHONE",
  "SET_REQUIREMENTS",
  "SET_TERMS_AND_CONDITIONS",
  "RESET_REQUIREMENTS",
]);
