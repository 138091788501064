import styled from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";

export const BlockWrapper = styled.div`
  border-bottom: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
`;

export const ClickableText = styled.p`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
