class ReportIds {
  constructor(roomId = 0, userId = 0) {
    this.room = roomId;
    this.user = userId;
  }

  getId(type) {
    switch (type) {
      case "room":
        return this.room;
      case "user":
        return this.user;
      default:
        return 0;
    }
  }

  getType() {
    if (this.room && this.user) return "reportType";
    if (this.room) return "room";
    return "user";
  }
}

export default ReportIds;
