import { getLegalResponse } from "../utils";

export default class UserLegal {
  constructor(data) {
    this.entity = data || {};
  }

  get consentList() {
    return this.entity.consents_to_present || [];
  }

  get latestTosConsent() {
    return getLegalResponse(this.entity.tos_consent);
  }

  get privacyConsent() {
    return getLegalResponse(this.entity.privacy_policy_consent);
  }

  get profilingConsent() {
    return getLegalResponse(this.entity.profiling_consent);
  }

  get marketingConsent() {
    return getLegalResponse(this.entity.marketing_consent);
  }

  get videocallConsent() {
    return getLegalResponse(this.entity.video_call_consent);
  }

  toJSON() {
    return {
      consentList: this.consentList,
      marketingConsent: this.marketingConsent,
      privacyConsent: this.privacyConsent,
      profilingConsent: this.profilingConsent,
      latestTosConsent: this.latestTosConsent,
      videocallConsent: this.videocallConsent,
    };
  }
}
