import { createSelector } from "reselect";

export const getViewers = (state) => state.Viewers;

export const getViewersList = createSelector(
  getViewers,
  (viewers) => viewers.list,
);

export const isFetchingViewers = createSelector(
  getViewers,
  (viewers) => viewers.fetching,
);

export const hadFetchedViewers = createSelector(
  getViewers,
  (viewers) => viewers.fetched,
);

export const getViewersPagination = createSelector(
  getViewers,
  (viewers) => viewers.pagination,
);
