import React from "react";

import {
  Anchor,
  BADI_COLORS,
  Card,
  BUTTON_TYPES,
  Heading,
  Text,
  Flex,
  Spacer,
  StepCard,
  Button,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import BookingBenefits from "components/booking-benefits";
import { getVisitSteps } from "./utils";

const PositiveFeedback = ({ screenRole, onSubmit }) => {
  const steps = getVisitSteps(screenRole);

  return (
    <Flex direction="column" fullHeight={true} gutter={2}>
      <Heading data-qa="visit-positive-feedback-title" level="28-Extrabold">
        {translate(`inbox_popup_visit.feedback.${screenRole}.positive.title`)}
      </Heading>
      <Text body="14-Regular" data-qa="visit-positive-feedback-text">
        {translate(
          `inbox_popup_visit.feedback.${screenRole}.positive.description`,
        )}
      </Text>
      <Flex direction="column">
        {steps.map(
          ({
            stepNumber,
            title,
            description,
            link,
            linkText,
            progress,
            status,
          }) => (
            <StepCard
              description={translate(description)}
              key={stepNumber}
              progress={progress}
              status={status}
              step={stepNumber}
              stepColor={BADI_COLORS.BRAND.PRIMARY.DEFAULT}
              title={translate(title)}
            >
              {!!linkText && (
                <Anchor
                  bodySize="12-Regular"
                  color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
                  decoration="underline"
                  href={translate(link)}
                >
                  {translate(linkText)}
                </Anchor>
              )}
            </StepCard>
          ),
        )}
      </Flex>
      <Card
        backgroundColor={BADI_COLORS.SEMANTIC.SUCCESS.LIGHTEST}
        grow={1}
        withShadow={false}
      >
        <Spacer bottom={2} left={1} right={1} top={2}>
          <BookingBenefits userRole={screenRole} />
        </Spacer>
      </Card>
      <Button
        data-qa="visit-feedback-book-button"
        fullWidth={true}
        onClick={onSubmit}
        size="regular"
        style={{ hyphens: "auto" }}
        variant={BUTTON_TYPES.PRIMARY}
        wide={true}
      >
        {translate("conversation.actions.request_to_book")}
      </Button>
    </Flex>
  );
};

export default PositiveFeedback;
