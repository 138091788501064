import * as AuthModel from "datamodel/Auth/models";
import * as CountryVatModel from "datamodel/CountryVat/models";
import * as DeviceModel from "datamodel/Device/models";
import * as FeaturesModel from "datamodel/Features/models";
import * as InboxModel from "datamodel/Inbox/models/inbox-model";
import * as InboxConnectionModel from "datamodel/Conversation/models";
import * as LocaleModel from "datamodel/Localization/models";
import * as ModalsModel from "datamodel/Modals/models";
import * as NotificationsModel from "datamodel/Notifications/models";
import * as RoomModel from "datamodel/Room/redux-state";
import * as RoomListModel from "datamodel/RoomList/models";
import * as SocketModel from "datamodel/Socket/models";
import * as UserModel from "datamodel/User/models";
import * as PayoutsModel from "datamodel/Payouts/models";
import * as PaymentModel from "datamodel/Payment/models";
import * as HomeModel from "datamodel/Home/models";
import * as RequirementsModel from "datamodel/Requirements/models";
import * as MyListingsModel from "datamodel/MyListings/models";
import * as SearchFiltersModel from "datamodel/SearchFilters/models";
import * as PremiumModel from "datamodel/premium/models";
import * as ViewersModel from "app/datamodel/viewers/models";

const modelIndex = [
  AuthModel,
  CountryVatModel,
  DeviceModel,
  FeaturesModel,
  HomeModel,
  InboxModel,
  InboxConnectionModel,
  LocaleModel,
  ModalsModel,
  MyListingsModel,
  NotificationsModel,
  PaymentModel,
  PayoutsModel,
  RequirementsModel,
  RoomModel,
  RoomListModel,
  SocketModel,
  UserModel,
  SearchFiltersModel,
  PremiumModel,
  ViewersModel,
];

export default { modelIndex };
