import { getRelativePath } from "base/shared/Utils";

const BASE_PATH = "/reviews";

export const getNewReviewPath = ({ reviewId } = {}) => {
  if (!reviewId) {
    return null;
  }

  return getRelativePath(`${BASE_PATH}/new/${reviewId}`);
};
