import { connect } from "react-redux";
import MyListingsActions from "datamodel/MyListings/actions";
import { isPremiumEnabledSelector } from "modules/premium/selectors";
import {
  isProSelector,
  myListingsRoomsSelector,
} from "datamodel/MyListings/selectors";
import Listings from "./my-listings";
import { generateSummaryParams } from "./utils";

export default connect(
  (state) => ({
    token: state.Auth.access_token,
    rooms: myListingsRoomsSelector(state),
    currentPage: state.MyListings.currentPage,
    totalPages: state.MyListings.totalPages,
    fetchingInitialListingsByStatus:
      state.MyListings.fetchingInitialListingsByStatus,
    fetchedInitialListingsByStatus:
      state.MyListings.fetchedInitialListingsByStatus,
    isPremiumEnabled: isPremiumEnabledSelector(state),
    isPro: isProSelector(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      clearSelectedListings: () =>
        dispatch(MyListingsActions.clearSelectedListings()),
      getMyListingsSummary: () => {
        const params = generateSummaryParams(ownProps);
        dispatch(
          MyListingsActions.getMyListingsSummary(stateProps.token, params),
        );
      },
      getMyListings: ({ statusId, page, per, externalAgencyId }) =>
        dispatch(
          MyListingsActions.getInitialListingsByStatus(stateProps.token, {
            status: statusId,
            page,
            per,
            externalAgencyId,
          }),
        ),
      resetListings: () => dispatch(MyListingsActions.resetListings()),
    };
  },
)(Listings);
