import styled from "styled-components";
import {
  BADI_COLORS,
  mediaquery,
  Text,
  unitizedPx,
} from "@badi/badi-components";

export const Root = styled.div`
  position: relative;
  width: 100%;
  min-height: ${unitizedPx(15.5)};

  ${mediaquery.md`
    min-height: ${unitizedPx(22)};
  `}

  ${mediaquery.lg`
    min-height: ${unitizedPx(27.5)};
  `}
`;

export const ClickableLayer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-left: ${unitizedPx(-1)};

  width: ${unitizedPx(13)};
  height: ${unitizedPx(13)};
  flex-shrink: 0;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

  ${mediaquery.md`
    margin-left: ${unitizedPx(2)};
    width: 200px;
    height: 100%;
    flex-shrink: inherit;
  `}

  ${mediaquery.lg`
    width: 270px;
  `}
`;

export const ContentWrapper = styled.div`
  padding: ${unitizedPx(2)} ${unitizedPx(3)} ${unitizedPx(2)} ${unitizedPx(2)};
  width: 100%;

  ${mediaquery.lg`
    padding-left: ${unitizedPx(10)}
  `}
`;

export const Address = styled(Text)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${mediaquery.md`
    -webkit-line-clamp: 2;
  `}
`;

export const PremiumWrapper = styled.div`
  position: absolute;
  top: ${unitizedPx(1)};
  left: 0;
`;
