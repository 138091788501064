const AuthModel = {
  fetching: false,
  access_token: "",
  token_type: "",
  expirationTime: null,
  isRememberMeChecked: false,
  creationTime: null,
  error: null,
  user_id: 0,
  new_user: false,
  refresh_token: "",
  authMethod: null,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Auth) {
    return { ...AuthModel, error: "There was an error in AuthModel." };
  }
  return state;
}

export { AuthModel, setInitialState };
