import React from "react";
import { BADI_COLORS, Flex, StepCard, Text } from "@badi/badi-components";
import InjectHtml from "components/inject-html";

const PlanBenefits = ({ benefits }) => {
  return (
    <Flex direction="column" gutter={1.375}>
      {benefits.map(({ id, name }) => (
        <StepCard
          bodyTitle="16-Regular"
          key={id}
          status={4}
          step={0}
          stepColor={BADI_COLORS.NEUTRAL.NEUTRAL_00}
          textColor={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
        >
          <Text
            body="16-Regular"
            color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
            dataQa="plan-benefit"
            inline={true}
          >
            <InjectHtml>{name}</InjectHtml>
          </Text>
        </StepCard>
      ))}
    </Flex>
  );
};

export default PlanBenefits;
