import React, { useEffect, useState } from "react";
import { translate } from "base/shared/Localization";
import CheckoutLayout, { VARIANT } from "components/Layout/checkout-layout";
import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import { getRooms, redeemProduct } from "datamodel/referrals/api";
import RoomSelector from "components/room-selector";
import UserFeedback from "base/shared/user-feedback";
import { isAndroidWebView } from "base/shared/mobile-web-view";
import { Flex } from "@badi/badi-components";
import ReferralAccount from "./components/referral-account";
import PendingReferrals from "./components/pending-referrals";
import HowToRedeem from "./components/how-to-redeem";
import HeaderContent from "./components/header-content";
import { getInviteUrl } from "./utils";

const Referrals = ({
  getReferralAccount,
  getPendingReferrals,
  getReferralProducts,
  isIOSApp,
  isLister,
  isLoggedIn,
  onNotAuthorized,
  pendingReferrals,
  redirectToBadiGold,
  referralAccount,
  referralProducts,
  resetReferrals,
  sgid = null,
  token,
}) => {
  if (!isLoggedIn && !sgid) {
    onNotAuthorized();
  }

  const [roomSelectorActive, setRoomSelector] = useState(false);
  const [roomsResponse, setRoomsResponse] = useState({});
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [isWebView, setIsWebView] = useState(false);

  const fetchRooms = (page = 1) =>
    getRooms({ token }, { page, sgid }).then((res) => {
      setRoomsResponse(res);
    });

  const handleRoomSelector = () => setRoomSelector(!roomSelectorActive);

  const handleRedeemClick = () => {
    redirectToBadiGold();
  };

  const handleClose = () => {
    setSelectedProductName(null);
    handleRoomSelector();
  };

  const handlePageChange = (page) => fetchRooms(page);

  const handleSelect = (event) => {
    const roomId = Number(event.currentTarget.value);
    redeemProduct({ token }, { roomId, name: selectedProductName, sgid })
      .then(() => {
        UserFeedback.success(
          translate("referrals.content.redeem.lister.toast_title"),
          translate("referrals.content.redeem.lister.toast_text"),
        );
        getReferralAccount(sgid);
      })
      .catch(() => {
        UserFeedback.exception(
          "Something went wrong",
          "Please, try again or contact support.",
        );
      });
    handleRoomSelector();
    setSelectedProductName(null);
    getReferralProducts(sgid);
  };

  useEffect(() => {
    setIsWebView(isAndroidWebView() || isIOSApp);
    getReferralAccount(sgid);
    getPendingReferrals(null, sgid);
    getReferralProducts(sgid);
    fetchRooms();

    return () => resetReferrals();
  }, []);

  const inviteUrl = getInviteUrl(
    referralAccount.code,
    referralAccount.deactivated,
  );

  const getContent = () => (
    <CheckoutLayout
      description={translate("referrals.header.description")}
      headerContent={<HeaderContent inviteUrl={inviteUrl} />}
      title={translate("referrals.header.title")}
      variant={VARIANT.LIGHT}
    >
      <Flex direction="column" gutter={5}>
        <ReferralAccount {...referralAccount} />
        {pendingReferrals.results.length > 0 && (
          <PendingReferrals
            nextToken={pendingReferrals.nextToken}
            onShowMore={getPendingReferrals}
            referrals={pendingReferrals.results}
            sgid={sgid}
          />
        )}
        {referralProducts.length > 0 && (
          <HowToRedeem
            isLister={isLister || !isLoggedIn}
            isWebView={isWebView}
            onRedeemClick={handleRedeemClick}
            products={referralProducts}
          />
        )}
      </Flex>
    </CheckoutLayout>
  );

  if (roomSelectorActive) {
    const { rooms, pagination } = roomsResponse;
    const { currentPage, totalPages } = pagination;

    return (
      <RoomSelector
        currentPage={currentPage}
        onClose={handleClose}
        onPageChange={handlePageChange}
        onSelect={handleSelect}
        roomItems={rooms}
        totalPages={totalPages}
      />
    );
  }

  return isWebView ? (
    getContent()
  ) : (
    <LayoutWrapper footer={<Footer campaign="referrals_footer" />}>
      {getContent()}
    </LayoutWrapper>
  );
};

export default Referrals;
