import React from "react";
import InfoBarWrapper from "components/info-bar-wrapper";
import { translate } from "base/shared/Localization";

import {
  Text,
  BADI_COLORS,
  MatchMedia,
  BREAKPOINTS_PX,
} from "@badi/badi-components";

const InfoBarUi = ({ onCloseNotification, onClick, link, text }) => (
  <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.lg})`}>
    <InfoBarWrapper onClose={onCloseNotification}>
      <Text
        body="14-Regular"
        color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
        data-qa="info-bar-link"
        onClick={onClick}
        style={{
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {translate(link)}
      </Text>
      <Text
        body="14-Regular"
        color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
        data-qa="info-bar-text"
      >
        {translate(text)}
      </Text>
    </InfoBarWrapper>
  </MatchMedia>
);

export default InfoBarUi;
