import React, { memo } from "react";
import { Flex } from "@badi/badi-components";

const BookingFooter = ({ children = null }) => {
  return (
    <Flex
      direction="column"
      gutter={1}
      justify="spaceBetween"
      md={{
        justify: "flexEnd",
        direction: "row",
        gutter: 2,
        alignItems: "center",
      }}
    >
      <Flex alignItems="center" gutter={1}>
        {children}
      </Flex>
    </Flex>
  );
};

export default memo(BookingFooter);
