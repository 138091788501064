import { MODAL_TYPE } from "containers/ReduxModal/emitter";
import LeaveConnection from "./leave-connection";
import SimpleLeaveConnection from "./simple-leave-connection";
import { LEAVE_CONNECTION_MODAL_TYPE } from "./constants";

export const getModalConfig = (type) =>
  type === LEAVE_CONNECTION_MODAL_TYPE.SIMPLE
    ? {
        modalType: MODAL_TYPE.DEFAULT,
        ModalComponent: SimpleLeaveConnection,
      }
    : { modalType: MODAL_TYPE.WITH_AVATAR, ModalComponent: LeaveConnection };
