import React from "react";
import { Heading, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import SearchLocation from "./components/location";
import PropertyType from "./components/property-type";
import FormBlock from "./components/form-block";
import MaxBudget from "./components/max-budget";
import MoveIn from "./components/move-in";
import MoveInGroup from "./components/move-in-group";

const SeekerPreferencesForm = ({
  handleSaveSeekerPreference,
  setIsLocationCompleted,
  seekerPreferences: {
    location,
    moveInDate,
    moveInType,
    propertyType,
    budget,
    moveInGroup,
  } = {},
}) => {
  return (
    <Spacer bottom={12}>
      <Heading level="28-Extrabold">
        {translate("seeker.preferences.header")}
      </Heading>
      <FormBlock title="seeker.preferences.location.title">
        <SearchLocation
          existingLocation={location}
          onUpdate={handleSaveSeekerPreference}
          setIsLocationCompleted={setIsLocationCompleted}
        />
      </FormBlock>
      <FormBlock title="seeker.preferences.property.type.title">
        <PropertyType
          handleSaveSeekerPreference={handleSaveSeekerPreference}
          propertyType={propertyType}
        />
      </FormBlock>
      <FormBlock title="seeker.preferences.budget.title">
        <MaxBudget
          budget={budget}
          handleSaveSeekerPreference={handleSaveSeekerPreference}
        />
      </FormBlock>
      <FormBlock title="seeker.preferences.movein.title">
        <MoveIn
          handleSaveSeekerPreference={handleSaveSeekerPreference}
          moveInDate={moveInDate}
          moveInType={moveInType}
        />
      </FormBlock>
      <FormBlock title="onboarding.preferences.seeker_who_with">
        <MoveInGroup
          handleSaveSeekerPreference={handleSaveSeekerPreference}
          moveInGroup={moveInGroup}
        />
      </FormBlock>
    </Spacer>
  );
};

export default SeekerPreferencesForm;
