import createReducer from "base/shared/createReducer";
import Types from "./types";
import { FeaturesModel } from "./models";

export const featuresRequest = (state) => ({
  ...state,
  fetching: true,
  error: null,
});

export const featuresError = (state, action) => ({
  ...state,
  fetching: false,
  error: action.error,
});

export const featuresSuccess = (state, action) => ({
  fetching: false,
  error: null,
  ...action.result,
});

const actionHandlers = {
  [Types.FEATURES_REQUEST]: featuresRequest,
  [Types.FEATURES_SUCCESS]: featuresSuccess,
  [Types.FEATURES_ERROR]: featuresError,
};

export default createReducer(actionHandlers, { ...FeaturesModel });
