import { VISIT_STATUS } from "datamodel/visits/constants";

export const isFinished = (status) => VISIT_STATUS.FINISHED === status;

export const getDescription = ({
  express,
  status,
  userRole = "seeker",
  isCurrentUserProposer,
} = {}) => {
  const visitType = express ? "videocall" : "visit";

  switch (status) {
    case VISIT_STATUS.ACCEPTED:
      return `${userRole}.confirmed.${visitType}.subtitle`;
    case VISIT_STATUS.REJECTED:
    case VISIT_STATUS.CANCELLED:
      return `${userRole}.cancelled.visit.text`;
    case VISIT_STATUS.PROPOSED:
      if (isCurrentUserProposer) {
        return `${userRole}.pending.visit.subtitle`;
      }
    default:
      return `${userRole}.rescheduled.visit.subtitle`;
  }
};

export const isMessageVisible = ({ message, userId, status } = {}) =>
  !!message && message.userId !== userId && status !== VISIT_STATUS.CANCELLED;
