import { createSelector, createStructuredSelector } from "reselect";

export const GERMANY_ISO = "DE";

export const getRoomListSelector = (state) => state.RoomList;

export const getRoomIdSelector = createSelector(
  getRoomListSelector,
  (room) => room.id,
);

export const getRoomFetchedSelector = createSelector(
  getRoomListSelector,
  (room) => room.fetched,
);

export const getRoomStatusSelector = createSelector(
  getRoomListSelector,
  (room) => room.status,
);

export const getRoomFlatmateSelector = createSelector(
  getRoomListSelector,
  (room) => room.flatmate,
);

export const getRoomLocationSelector = createSelector(
  getRoomListSelector,
  (room) => room.location,
);

export const getRoomPropertySelector = createSelector(
  getRoomListSelector,
  (room) => room.property,
);

export const getRoomPhotosSelector = createSelector(
  getRoomListSelector,
  (room) => room.photos,
);

export const getRoomDetailsSelector = createSelector(
  getRoomListSelector,
  (room) => room.room,
);

export const getCountryCodeSelector = createSelector(
  getRoomLocationSelector,
  (location) => location.countryCode,
);

export const getRoomInitialStepSelector = createSelector(
  getRoomListSelector,
  (room) => room.initialStep,
);

export const getSizeUnitSelector = createSelector(
  getRoomListSelector,
  (room) => room.sizeUnit,
);

export const getRoomOwnedSelector = createSelector(
  getRoomListSelector,
  (room) => room.owned,
);

// this is so we can get the `sizeUnit` for the form fields
export const getStructuredSizeUnitSelector = createStructuredSelector({
  sizeUnit: getSizeUnitSelector,
});

export const getRoomVisitPreferencesSelector = createSelector(
  getRoomListSelector,
  (room) => room.visitPreferences,
);

export const getRoomFieldsSelector = createSelector(
  getRoomFlatmateSelector,
  getRoomLocationSelector,
  getRoomPropertySelector,
  getRoomPhotosSelector,
  getRoomDetailsSelector,
  getRoomVisitPreferencesSelector,
  getStructuredSizeUnitSelector,
  (
    flatmate,
    location,
    property,
    photos,
    details,
    visitPreferences,
    structure,
  ) => {
    return {
      ...flatmate,
      ...location,
      ...property,
      ...photos,
      ...details,
      visitPreferences,
      ...structure,
    };
  },
);

export const isEntiredPropertySelector = createSelector(
  getRoomPropertySelector,
  (property) => property.placeType === 2,
);

export const isPrivateRoomSelector = createSelector(
  getRoomPropertySelector,
  (property) => property.placeType === 1,
);

export const showGermanPropertyRegulationSelector = createSelector(
  [isEntiredPropertySelector, getRoomLocationSelector],
  (isEntiredProperty, { countryCode }) =>
    countryCode === GERMANY_ISO && isEntiredProperty,
);

export const getRoomModerationResultSelector = createSelector(
  getRoomListSelector,
  (room) => room.moderationResult,
);
