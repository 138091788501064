import React from "react";

import VerificationFlag from "components/VerificationFlag";
import Slider from "components/Slider";

const AvatarSlider = ({ pictures = [], showVerificationFlag, user = {} }) => {
  const sliderPictures =
    Array.isArray(pictures) && pictures.length > 0 ? pictures : user.pictures;

  return (
    <div className="UserCard__block UserCard__block_slider">
      <Slider
        className="UserCard__slider"
        dots={true}
        pictures={sliderPictures}
        type="user"
      />
      {showVerificationFlag && (
        <VerificationFlag
          className="UserCard__flag"
          verificationLevel={user.verificationLevel}
        />
      )}
    </div>
  );
};

export default AvatarSlider;
