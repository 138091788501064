import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const ReviewWrapper = styled.div`
  padding-top: ${unitizedPx(2)};
  padding-bottom: ${unitizedPx(2)};
  ${({ withDivider }) =>
    withDivider &&
    `border-bottom: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};`}
`;

export const TextWrapper = styled.div`
  word-break: break-word;
`;
