import { generateFetchTypes } from "base/shared/TypeHelper";
import { fetchUser } from "datamodel/User/api";
import Types from "./types";
import { fetchRoom, fetchSimilarRooms } from "./api";

export default {
  getOwner({ token, id }) {
    return {
      types: generateFetchTypes(Types.ROOMOWNER_REQUEST),
      request: fetchUser(id, token),
    };
  },
  getRoom({ token, roomId, isLoggedUser, ip, locale, userAgent, etParams }) {
    return {
      types: generateFetchTypes(Types.ROOM_REQUEST),
      request: fetchRoom(token, roomId, isLoggedUser, {
        locale,
        ip,
        userAgent,
        ...etParams,
      }),
    };
  },
  getSimilarRooms({ token, roomId, isLoggedUser }) {
    return {
      types: generateFetchTypes(Types.ROOM_SIMILAR_REQUEST),
      request: fetchSimilarRooms(token, roomId, isLoggedUser),
    };
  },
  clearRoom() {
    return { type: Types.CLEAR_ROOM };
  },
  updateRoom(props) {
    return { type: Types.ROOM_UPDATE, result: props };
  },
};
