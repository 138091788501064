import styled from "styled-components";
import { BADI_COLORS, Flex, Text, unitizedPx } from "@badi/badi-components";

export const Root = styled(Flex)`
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${BADI_COLORS.SEMANTIC.INFO.LIGHTEST};
  right: 0;
  height: ${unitizedPx(19)};
`;

export const DetailsWrapper = styled(Flex)`
  max-width: ${unitizedPx(23)};
`;

export const Card = styled.div`
  position: relative;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: ${unitizedPx(1)};
  border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  width: ${unitizedPx(42)};
  padding: ${unitizedPx(2)};
`;

export const RecommendAction = styled.div`
  display: flex;
  flex-basis: 100%;
  padding-top: ${unitizedPx(2)};
  align-items: center;
  border-top: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
`;

export const ActionLabelWrapper = styled.div`
  width: 95%;
`;

export const ListingImage = styled.img`
  height: ${unitizedPx(12)};
  width: ${unitizedPx(12)};
  border-radius: 5px;
  object-fit: cover;
`;

export const TruncatedText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
