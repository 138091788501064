import { TAGS_ORDER } from "base/shared/StaticData";
import { sortByAlphabetically } from "base/shared/Utils";

const isSelected = (elements, id) => !!elements.find((e) => e.id === id);

const parseTags = (tags, selectedTags, hide) => {
  if (!tags) return [];
  if (hide) return tags.filter((t) => isSelected(selectedTags, t.id));
  return tags.map((t) => ({ ...t, selected: isSelected(selectedTags, t.id) }));
};

const parseCategoryTags = (tags, value, hide = true) =>
  tags
    ? tags
        .map((tag) => ({
          ...tag,
          order: TAGS_ORDER[tag.id] || 0,
          tags: parseTags(tag.tags, value ? value[tag.id] : [], hide),
        }))
        .sort(sortByAlphabetically("order"))
    : [];

const getValues = (tags) =>
  tags
    ? tags.reduce((prev, curr) => {
        const values = prev;
        values[curr.id] = curr.tags
          .filter((t) => t.selected)
          .map((t) => ({ id: t.id }));
        return values;
      }, {})
    : null;

const toggleTagById = (tags, id) =>
  tags.map((t) => ({
    ...t,
    selected: t.id === id ? !t.selected : t.selected,
    disabled: false,
  }));

const setEnableState = (tags, limit) => {
  const { length } = tags.filter((tagItem) => tagItem.selected);

  if (length >= limit) {
    return tags.map((tagItem) =>
      tagItem.selected ? tagItem : { ...tagItem, disabled: true },
    );
  }
  return tags;
};

const toggleCategoryTag = (categories, categoryId, tagId) => {
  const i = categories.findIndex((category) => category.id === categoryId);
  if (i < 0) return categories;

  const category = categories[i];
  const newCategories = [...categories];

  const newCategory = {
    ...category,
    tags: toggleTagById(category.tags, tagId),
  };

  newCategories[i] = newCategory;
  return newCategories;
};

export { getValues, parseCategoryTags, toggleCategoryTag, setEnableState };
