import React, { useCallback } from "react";
import { Button, Flex, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import UserProfileModal from "components/other-user-profile-modal";

import {
  ModalRoot,
  MainContentWrapper,
  ButtonWrapper,
} from "../styled-components";

const RecommendedUserModal = ({
  user,
  acceptRecommendation,
  onClose,
  rejectRecommendation,
}) => {
  const { id: userId } = user;

  const handleAcceptRecommendation = useCallback(() => {
    acceptRecommendation(userId);
    onClose();
  }, [userId]);

  const handleRejectRecommendation = useCallback(() => {
    rejectRecommendation(userId);
    onClose();
  }, [userId]);

  return (
    <ModalRoot>
      <MainContentWrapper>
        <UserProfileModal user={user} />
      </MainContentWrapper>
      <ButtonWrapper>
        <Spacer bottom={1} left={2} right={2} top={1}>
          <Flex gutter={2}>
            <Button
              fullWidth={true}
              grow={1}
              onClick={handleRejectRecommendation}
              variant="secondary"
            >
              {translate("recommendations.action.ignore")}
            </Button>
            <Button
              fullWidth={true}
              grow={1}
              onClick={handleAcceptRecommendation}
            >
              {translate("recommendations.action.invite")}
            </Button>
          </Flex>
        </Spacer>
      </ButtonWrapper>
    </ModalRoot>
  );
};

export default RecommendedUserModal;
