import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchMyListingsSummary, fetchMyListingsByStatus } from "./api";

export default {
  getMyListingsSummary(token, params) {
    return {
      types: generateFetchTypes(Types.MY_LISTINGS_SUMMARY_REQUEST),
      request: fetchMyListingsSummary(token, params),
    };
  },
  getInitialListingsByStatus(token, params) {
    return {
      types: generateFetchTypes(Types.INITIAL_LISTINGS_BY_STATUS_REQUEST),
      request: fetchMyListingsByStatus(token, params),
      listingStatusId: params.status,
    };
  },
  getNextListingsByStatus(token, params) {
    return {
      types: generateFetchTypes(Types.NEXT_LISTINGS_BY_STATUS_REQUEST),
      request: fetchMyListingsByStatus(token, params),
      listingStatusId: params.status,
    };
  },
  resetListings() {
    return {
      type: Types.RESET_LISTINGS,
    };
  },
  selectListingsById(ids) {
    return {
      type: Types.SELECT_LISTINGS,
      result: ids,
    };
  },
  unSelectListingsById(ids) {
    return {
      type: Types.UNSELECT_LISTINGS,
      result: ids,
    };
  },
  clearSelectedListings() {
    return {
      type: Types.CLEAR_SELECTED_LISTINGS,
    };
  },
};
