import React, { useMemo, useState, useEffect } from "react";
import { Input } from "app/badi-components";
import { validateValue } from "./validations";
import { Root } from "./styled-components";

const TextInput = ({
  removeWhitespace = false,
  disabled = false,
  type = "text",
  name,
  validations = [],
  onInputValidation = null,
  onChange = null,
  placeholder = "",
  required = false,
  initialValue = "",
  newType = false,
  iconSrc = "",
  label = "",
  dataQa = "",
  warning = "",
  helper = "",
  maxLength = null,
}) => {
  // support native validation if exists
  const validationList = useMemo(
    () => (required ? [...validations, "required"] : validations),
    [required, validations],
  );

  const [inputValue, setInputValue] = useState(initialValue);
  const [isValid, setInputValidation] = useState(
    initialValue
      ? validateValue({ value: initialValue, validations: validationList })
      : null,
  );
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (onInputValidation)
      onInputValidation({ name, isValid, value: inputValue });
  }, [isValid]);

  const handleChange = (event) => {
    let value = event.target.value;

    if (removeWhitespace) value = value.replace(/\s/g, "");

    const isInputValid = validateValue({
      value,
      validations: validationList,
    });

    if (onChange) onChange({ name, value, isValid: isInputValid });

    setInputValidation(isInputValid);
    setInputValue(value);
  };

  const handleBlur = () => {
    if (isValid || (!inputValue && !required)) return;

    setError(true);
  };

  const handleFocus = () => {
    setError(false);
  };

  const baseProps = {
    maxLength,
    disabled,
    hasError,
    name,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    placeholder,
    required,
    type,
    value: inputValue,
    helper: hasError && warning ? warning : helper, // falta testear que si recibe warning lo muestra cuando hay error y que si no tiene helper muestra el helper
  };

  return (
    <>
      {newType ? (
        <Input dataQa={dataQa} iconSrc={iconSrc} label={label} {...baseProps} />
      ) : (
        <Root {...baseProps} data-qa={dataQa} />
      )}
    </>
  );
};

export default TextInput;
