import createReducer from "base/shared/createReducer";

import visitTypes from "datamodel/visits/types";
import {
  requestVisitRequestReducer,
  requestVisitErrorReducer,
  requestVisitSuccessReducer,
  proposeVisitRequestReducer,
  proposeVisitErrorReducer,
  proposeVisitSuccessReducer,
  updateVisitSuccessReducer,
  updateVisitRequestReducer,
  updateVisitErrorReducer,
} from "datamodel/visits/reducers";

import Types from "./types";
import { InboxConnectionModel } from "./models";
import {
  mergeMessages,
  parseConnectionData,
  parseMessage,
  parseMessages,
  parsePagination,
} from "./utils";

function addMessage(state, action) {
  const parsedMessage = parseMessage(action.message);

  const history =
    state.connection.id === action.connectionId
      ? {
          ...state.history,
          messages: [parsedMessage, ...state.history.messages],
        }
      : state.history;

  return {
    ...state,
    history,
    lastMessageId: parsedMessage.id,
  };
}

function inboxConnectionError(state, action) {
  return { ...state, error: action.error };
}

function inboxConnectionRequest(state) {
  return { ...state, fetching: true, error: null };
}

function inboxConnectionSuccess(state, action) {
  const { visits, ...connection } = action.result;
  return {
    ...state,
    fetching: false,
    connection,
    visits,
  };
}

function inboxConnectionReset() {
  return {
    ...InboxConnectionModel,
  };
}

function inboxConnectionUpdate(state, action) {
  if (
    state.connection.id !== action.connection.id ||
    !Boolean(action.connection.other_user)
  ) {
    return state;
  }

  const { visits, ...connection } = parseConnectionData(action.connection);

  return { ...state, visits, connection };
}

function inboxHistoryError(state) {
  return state;
}

function inboxHistoryRequest(state) {
  return {
    ...state,
    history: {
      ...state.history,
      fetching: true,
    },
  };
}

function inboxHistorySuccess(state, action) {
  const parsedMessages = parseMessages(action.result.data.messages);
  const messages = mergeMessages(state.history.messages, parsedMessages);
  const lastMessageId = messages.length ? messages[0].id : 0;

  return {
    ...state,
    history: {
      fetching: false,
      error: null,
      messages,
      pagination: parsePagination(action.result.pagination),
    },
    lastMessageId,
  };
}

function inboxNewMessageError(state, action) {
  return {
    ...state,
    newMessage: {
      fetching: false,
      error: action.error,
    },
  };
}

function inboxNewMessageRequest(state) {
  return {
    ...state,
    newMessage: {
      fetching: true,
      error: null,
    },
  };
}

function inboxNewMessageSuccess(state) {
  return {
    ...state,
    newMessage: {
      fetching: false,
      error: null,
    },
  };
}

function sendMessageRequest(state) {
  return {
    ...state,
    newMessageEnquiry: { ...state.newMessageEnquiry, fetching: true },
  };
}

function sendMessageError(state, action) {
  const { error } = action;
  return {
    ...state,
    newMessageEnquiry: { ...state.newMessageEnquiry, fetching: false, error },
  };
}

function sendMessageSuccess(state, action) {
  const { message } = action.result.data;

  return {
    ...state,
    newMessageEnquiry: {
      fetching: false,
      fetched: true,
      error: null,
      message,
    },
  };
}

function resetMessageEnquiryError(state) {
  return {
    ...state,
    newMessageEnquiry: {
      ...state.newMessageEnquiry,
      error: null,
    },
  };
}

const actionHandlers = {
  [Types.INBOX_ADD_MESSAGE]: addMessage,
  [Types.INBOX_CONNECTION_ERROR]: inboxConnectionError,
  [Types.INBOX_CONNECTION_REQUEST]: inboxConnectionRequest,
  [Types.INBOX_CONNECTION_SUCCESS]: inboxConnectionSuccess,
  [Types.INBOX_CONNECTION_RESET]: inboxConnectionReset,
  [Types.INBOX_CONNETION_UPDATE]: inboxConnectionUpdate,
  [Types.INBOX_HISTORY_ERROR]: inboxHistoryError,
  [Types.INBOX_HISTORY_REQUEST]: inboxHistoryRequest,
  [Types.INBOX_HISTORY_SUCCESS]: inboxHistorySuccess,
  [Types.INBOX_NEW_MESSAGE_ERROR]: inboxNewMessageError,
  [Types.INBOX_NEW_MESSAGE_REQUEST]: inboxNewMessageRequest,
  [Types.INBOX_NEW_MESSAGE_SUCCESS]: inboxNewMessageSuccess,
  [Types.SEND_MESSAGE_ENQUIRY_REQUEST]: sendMessageRequest,
  [Types.SEND_MESSAGE_ENQUIRY_SUCCESS]: sendMessageSuccess,
  [Types.SEND_MESSAGE_ENQUIRY_ERROR]: sendMessageError,
  [Types.RESET_MESSAGE_ENQUIRY_ERROR]: resetMessageEnquiryError,

  [visitTypes.REQUEST_VISIT_REQUEST]: requestVisitRequestReducer,
  [visitTypes.REQUEST_VISIT_ERROR]: requestVisitErrorReducer,
  [visitTypes.REQUEST_VISIT_SUCCESS]: requestVisitSuccessReducer,
  [visitTypes.PROPOSE_VISIT_REQUEST]: proposeVisitRequestReducer,
  [visitTypes.PROPOSE_VISIT_ERROR]: proposeVisitErrorReducer,
  [visitTypes.PROPOSE_VISIT_SUCCESS]: proposeVisitSuccessReducer,
  [visitTypes.ACCEPT_VISIT_REQUEST]: updateVisitRequestReducer,
  [visitTypes.ACCEPT_VISIT_ERROR]: updateVisitErrorReducer,
  [visitTypes.ACCEPT_VISIT_SUCCESS]: updateVisitSuccessReducer,
  [visitTypes.REJECT_VISIT_REQUEST]: updateVisitRequestReducer,
  [visitTypes.REJECT_VISIT_ERROR]: updateVisitErrorReducer,
  [visitTypes.REJECT_VISIT_SUCCESS]: updateVisitSuccessReducer,
};

export default createReducer(actionHandlers, { ...InboxConnectionModel });
