import React, { Component } from "react";
import { Button, BADI_COLORS, Flex, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Spinner from "components/Icons/LoadingSpinner.jsx";
import { SpinnerWrapper } from "../../styled-components";

import RoomCard from "../room-card";
import { overviewSettings } from "../../utils";

export default class RoomsOverview extends Component {
  componentDidMount = () => {
    const params = { page: 1, per: overviewSettings.per };
    this.props.getInitialRoomsOverview(params);
  };

  askNextPage = () => {
    const { getNextRoomsOverview, currentPage } = this.props;
    const nextPage = currentPage + 1;
    getNextRoomsOverview({ page: nextPage, per: overviewSettings.per });
  };

  render() {
    const {
      roomsCards,
      isNextPageAvailable,
      fetchingInitialOverviewCards,
      fetchedInitialOverviewCards,
      currentUserId,
      isStandaloneBoostingSelector,
    } = this.props;
    const { askNextPage } = this;

    return (
      <Flex alignItems="center" direction="column">
        {fetchingInitialOverviewCards && (
          <SpinnerWrapper>
            <Spinner color={BADI_COLORS.BRAND.PRIMARY.DEFAULT} />
          </SpinnerWrapper>
        )}
        {fetchedInitialOverviewCards &&
          roomsCards.map((room) => {
            return (
              <RoomCard
                {...room}
                currentUserId={currentUserId}
                isStandaloneBoostingSelector={isStandaloneBoostingSelector}
                key={room.id}
                wide={true}
              />
            );
          })}
        {isNextPageAvailable && (
          <Spacer top={2}>
            <Button onClick={askNextPage} variant="secondary">
              {translate("overview.rooms.loadMore.btn")}
            </Button>
          </Spacer>
        )}
      </Flex>
    );
  }
}
