import React from "react";
import { Spacer } from "@badi/badi-components";
import { Container, Row, Col } from "reactstrap";

const MyListingsContentWrapper = ({ children }) => (
  <Container>
    <Row>
      <Col>
        <Spacer top={4}>{children}</Spacer>
      </Col>
    </Row>
  </Container>
);

export default MyListingsContentWrapper;
