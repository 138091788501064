import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";

import { modal, MODAL_TYPE } from "containers/ReduxModal/emitter";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import { LOGIN_TYPE } from "datamodel/User/constants";

import LayoutWrapper from "components/Layout/Wrapper";
import SideBarMenu from "components/SideBarMenu";
import Button from "components/Button/Button";

import { getSettingsMenuItems } from "../utils";

class Support extends Component {
  UNSAFE_componentWillMount() {
    this.menuItems = translate(
      getSettingsMenuItems(
        this.props.loginType !== LOGIN_TYPE.EMAIL,
        "support",
      ),
    );
  }

  onDelete = (success) => {
    modal.remove("delete-account");
    if (success) {
      this.props.logout();
    }
  };

  clickHandler = async () => {
    const { mode, token } = this.props;

    try {
      const DeleteAccount = React.lazy(() => import("./delete-account"));
      modal.add(
        <Suspense fallback={<div />}>
          <DeleteAccount callback={this.onDelete} mode={mode} token={token} />
        </Suspense>,
        {
          type: MODAL_TYPE.DEFAULT,
          id: "delete-account",
        },
      );
    } catch (e) {
      UserFeedback.exception("Something went wrong", e.message, e);
    }
  };

  render() {
    return (
      <LayoutWrapper>
        <div className="layout__inner">
          <div className="layout__columns">
            <SideBarMenu
              autoScroll={false}
              className="info__item_link"
              items={this.menuItems}
              itemType="link"
              title="Settings"
            />
            <main className="content" role="main">
              <section className="section section_checked">
                <header className="section__head">
                  <div className="section__actions" />
                  <Container fluid={true}>
                    <h2 className="section__title">
                      {translate("support.deleteaccount.title")}
                    </h2>
                  </Container>
                </header>
                <div className="section__main">
                  <div className="section__content">
                    <Container fluid={true}>
                      <Row>
                        <Col sm={8}>
                          {translate("support.deleteaccount.description")}
                        </Col>
                        <Col sm={4}>
                          <Button
                            config={{
                              color: "pink",
                              restrain: true,
                              wide: true,
                            }}
                            onClick={this.clickHandler}
                            text={translate(
                              "support.deleteaccount.button.deleteAccount",
                            )}
                            type="button"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default Support;
