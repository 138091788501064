import React from "react";
import { translate } from "base/shared/Localization";
import Stepper from "components/stepper";
import { VISIT_STEPS } from "./constants";

const VisitSteps = ({ status }) => (
  <Stepper status={status} steps={translate(VISIT_STEPS)} />
);

export default VisitSteps;
