import { getRoomPictureBySize } from "base/shared/utils/room";

import { SECTIONS } from "./constants";

const parseActionItem = (actionItem) => {
  if (!actionItem) return null;
  const {
    heading,
    item_id: actionId,
    next_step: nextStepId,
    subheading,
    value,
    url,
  } = actionItem;
  return {
    heading,
    actionId,
    nextStepId,
    subheading,
    clickable: !!nextStepId,
    value,
    url,
  };
};

const parseActionItems = (actionItems) =>
  actionItems.map((actionItem) => parseActionItem(actionItem));

export const parseRoomCard = (listing) => {
  const {
    address,
    id,
    title,
    published_since: publishedSince,
    price_description: priceDescription,
    cover_picture: coverPicture,
    action_items: actionItems,
    boosting,
    premium,
    analytics_link_action_item: analyticsActionItem,
    availability_status: availabilityStatus,
  } = listing;

  return {
    address,
    id,
    title,
    publishedSince,
    priceDescription,
    availabilityStatus,
    coverPicture: getRoomPictureBySize(coverPicture),
    actionItems: parseActionItems(actionItems),
    boosting,
    premium,
    analyticsActionItem: parseActionItem(analyticsActionItem),
  };
};

export const parseSections = (fetchedSections) => {
  const sections = [...fetchedSections];

  return sections.reduce((sectionsArray, section, index) => {
    const components = section.components
      .map((component) => SECTIONS[component])
      .filter((component) => !!component);
    if (components.length)
      sectionsArray.push({ ...section, components, id: index + 1 });
    return sectionsArray;
  }, []);
};

export const parseListingsStatuses = (listingsStatusesArray) =>
  listingsStatusesArray.map((actionItem) => {
    const {
      heading,
      item_id: actionId,
      next_step_data: nextStepData,
      subheading,
      value,
    } = actionItem;

    return {
      heading,
      actionId,
      nextStepData: nextStepData || {},
      clickable: !!nextStepData,
      subheading,
      value,
    };
  });
