const isIE = (browserName) =>
  !!browserName &&
  (browserName.indexOf("IE") >= 0 || browserName.indexOf("Edge") >= 0);

const isSafari = (browserName) =>
  !!browserName && browserName.indexOf("Safari") >= 0;

const isFirefox = (browserName) =>
  !!browserName && browserName.indexOf("Firefox") >= 0;

const isChrome = (browserName) =>
  !!browserName && browserName.indexOf("Chrome") >= 0;

const isIOS = (osName) => !!(osName && osName.match(/ios/i));

const isAndroid = (osName) => !!(osName && osName.match(/android/i));

const isDesktop = (osName) =>
  !isIOS(osName) && !isAndroid(osName) && osName !== "Windows Phone";

export { isDesktop, isIE, isSafari, isFirefox, isChrome, isIOS, isAndroid };
