import React from "react";
import { withRouter } from "react-router";
import { getHomePath } from "Sections/Home/routes";

import { checkIsHome } from "base/shared/tracking-headers";
import { getSeekerPreferencesPath } from "Sections/seeker-preferences/routes";
import {
  getEditProfilePath,
  getMainProfilePath,
  NEXT_ACTIONS,
} from "Sections/user-profile/routes";

import { getNewRoomPath } from "Sections/Room/routes";
import { NEXT_STEPS } from "Sections/seeker-preferences/constants";
import Requirements from "./requirements-mapping";

const ROOT_ROOM_DETAILS_PATH = "/room/";

const RequirementsRoute = ({ router, ...rest }) => {
  const handleOpenSeekerPreferences = () => {
    router.push(
      getSeekerPreferencesPath({
        nextStep: NEXT_STEPS.MAIN_PROFILE,
      }),
    );
  };

  const handleOpenMainProfile = () => {
    const { pathname, search } = router.location;
    router.push(getMainProfilePath(pathname + search));
  };

  const handleOpenListRoom = () => {
    router.push(getNewRoomPath());
  };

  const handleOpenEditProfile = () => {
    const { pathname } = router.location;
    let params;
    if (pathname.includes(ROOT_ROOM_DETAILS_PATH)) {
      params = { nextAction: NEXT_ACTIONS.ENQUIRY, value: pathname };
    }

    router.push(getEditProfilePath(params));
  };

  const isHomepage = () => checkIsHome(router.location.pathname);

  const isEditProfilePage = () => {
    return router.location.pathname === getEditProfilePath();
  };

  const handleRedirectToHome = () => {
    router.push(getHomePath());
  };

  return (
    <Requirements
      isEditProfilePage={isEditProfilePage}
      isHomepage={isHomepage}
      onOpenEditProfile={handleOpenEditProfile}
      onOpenListRoom={handleOpenListRoom}
      onOpenMainProfile={handleOpenMainProfile}
      onOpenSeekerPreferences={handleOpenSeekerPreferences}
      redirectToHome={handleRedirectToHome}
      {...rest}
    />
  );
};

export default withRouter(RequirementsRoute);
