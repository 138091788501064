import React, { Component } from "react";
import { getPercentage } from "base/shared/Utils";

import LayoutWrapper from "components/Layout/Wrapper";
import Steps from "components/Steps/Steps";
import Step from "components/Steps/Step";
import { translate } from "base/shared/Localization";

import { isModerationSuccessful } from "app/datamodel/RoomList/utils";
import FormTopBar from "../form-top-bar";
import * as StepsComponents from "../Steps";

import { getStepTitle, getStepsArray } from "./utils";

import "../../styles/RoomFormExt.css";

import {
  openCancelListRoomModal,
  closeCancelListRoomModal,
} from "./cancel-form-modal";

import ModerationInfo from "../moderation-info";
import ModerationSpinner from "../moderation-spinner";

export class RoomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowCanceling: false,
      currentStepTitle: "",
      currentStepNumber: props.initialStep,
      waitingForModeration: false,
    };
  }

  componentDidMount() {
    // this will prevent the user to leave the flow without notice
    const { registerOnLeaving, roomId, getModerationResult } = this.props;
    registerOnLeaving(this.onLeavingFlow);
    if (roomId) getModerationResult({ roomId });
  }

  componentDidUpdate(prevProps) {
    const { moderationResult, waitForModeration, roomId } = this.props;
    const {
      moderationResult: prevModerationResult,
      waitForModeration: prevWaitForModeration,
    } = prevProps;

    if (prevModerationResult?.moderatedAt !== moderationResult?.moderatedAt) {
      clearTimeout(this.moderationTimeoutId);
      this.handleModerationUpdate();
    }

    if (prevWaitForModeration !== waitForModeration) {
      if (waitForModeration) {
        this.moderationTimeoutId = this.moderationTimeout();
      } else {
        clearTimeout(this.moderationTimeoutId);
      }
    }
  }

  componentWillUnmount() {
    const { resetRoomList } = this.props;

    resetRoomList();
  }

  onLeavingFlow = () => {
    const { allowCanceling } = this.props;

    if (allowCanceling || !this.shouldShowLeaveModal()) return true;

    openCancelListRoomModal({
      onLeave: this.leaveFlow,
    });

    return false;
  };

  getSteps = () => {
    const {
      status,
      disabled,
      isEntireProperty,
      onLeaveFlow,
      isListingFlowWithBankAccountEnabled,
      isDuplicate,
    } = this.props;

    const stepsArray = getStepsArray({
      status,
      disabled,
      isEntireProperty,
      onLeaveFlow,
      isListingFlowWithBankAccountEnabled,
      StepsComponents,
      isDuplicate,
    });

    return stepsArray.map((step, index) => (
      <Step {...step} order={index + 1} title={translate(step.title)} />
    ));
  };

  moderationTimeoutId = null;

  moderationTimeout = () =>
    setTimeout(() => {
      const { onSuccessfulModeration } = this.props;
      onSuccessfulModeration();
    }, 10000);

  handleModerationUpdate = () => {
    const {
      moderationResult,
      waitForModeration,
      disableWaitForModeration,
      onSuccessfulModeration,
      onUnsuccessfulModeration,
      roomId,
    } = this.props;

    if (waitForModeration) {
      disableWaitForModeration();

      if (isModerationSuccessful(moderationResult)) onSuccessfulModeration();
      else onUnsuccessfulModeration(roomId);
    }
  };

  shouldShowLeaveModal = () => {
    const { allowCanceling } = this.state;
    return !allowCanceling;
  };

  handleStepChange = (
    currentStepNumber,
    currentStepTitle,
    totalSteps,
    params,
  ) => {
    const { onStepChange } = this.props;

    this.setState({
      currentStepNumber,
      currentStepTitle,
      allowCanceling: false,
    });
    onStepChange(params);
  };

  handleOnFinish = (params) => {
    const { onSubmit, token } = this.props;

    onSubmit(params);
  };

  handleCloseForm = () => {
    openCancelListRoomModal({
      onLeave: this.leaveFlow,
    });
  };

  leaveFlow = () => {
    const { onLeaveFlow } = this.props;
    this.setState({ allowCanceling: true }, () => {
      closeCancelListRoomModal();
      onLeaveFlow();
    });
  };

  render() {
    const { onLeaveFlow, moderationResult, waitForModeration, isRoomFetched } =
      this.props;
    const { currentStepNumber } = this.state;
    const steps = this.getSteps();

    const currentStep = steps.find(
      ({ props }) => props.order == currentStepNumber,
    ) || { props: { title: "" } };
    const percentage = getPercentage(steps.length, currentStepNumber);
    const title = getStepTitle(currentStepNumber, currentStep.props.title);
    const displayModerationInfo =
      moderationResult === null
        ? false
        : !isModerationSuccessful(moderationResult);

    return (
      <>
        {waitForModeration && <ModerationSpinner />}
        <LayoutWrapper
          topBar={
            <FormTopBar
              goPreviousStep={onLeaveFlow}
              onCloseForm={this.handleCloseForm}
              percentage={percentage}
              title={title}
            />
          }
        >
          <div className="RoomList">
            {displayModerationInfo && (
              <ModerationInfo moderationResult={moderationResult} />
            )}
            {isRoomFetched && (
              <Steps
                currentStep={currentStepNumber}
                onChange={this.handleStepChange}
                onFinish={this.handleOnFinish}
              >
                {steps}
              </Steps>
            )}
          </div>
        </LayoutWrapper>
      </>
    );
  }
}

RoomForm.defaultProps = {
  allowCanceling: false,
  id: 0,
  initialStep: 1,
  isListingFlowWithBankAccountEnabled: false,
  moderationResult: null,
  onStepChange: () => {},
  onSubmit: () => {},
  onUnsuccessfulModeration: () => {},
  roomId: null,
};

export default RoomForm;
