import React, { useState } from "react";
import { BADI_COLORS, Badge } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { toggleDiscard } from "app/datamodel/discards/utils";

import DiscardButtonContent from "./discard-toggle-button-content";
import { FEEDBACK_TITLE, FEEDBACK_DESCRIPTION } from "./constants";
import { RoomHeaderButton } from "../../styled-components";

const DiscardToggleButton = ({ token, id, discarded }) => {
  const [isDiscarded, setIsDiscarded] = useState(discarded);
  const [isDisabled, setIsDisabled] = useState(false);

  const showFeedback = () => {
    UserFeedback.success(
      translate(FEEDBACK_TITLE),
      translate(FEEDBACK_DESCRIPTION),
    );
  };

  const handleDiscardResponse = async () => {
    const isDiscardedValue = isDiscarded;
    try {
      setIsDiscarded(!isDiscardedValue);
      await toggleDiscard(token, id, isDiscardedValue);
      if (!isDiscarded) showFeedback();
    } catch (e) {
      setIsDiscarded(isDiscardedValue);
    }
  };

  const handleOnClick = async () => {
    setIsDisabled(true);
    await handleDiscardResponse();
    setIsDisabled(false);
  };

  return (
    <Badge
      as={RoomHeaderButton}
      backgroundColor={BADI_COLORS.NEUTRAL.NEUTRAL_00}
      data-qa="room-details-discard-button"
      disabled={isDisabled}
      onClick={handleOnClick}
      rounded={false}
    >
      <DiscardButtonContent isDiscarded={isDiscarded} />
    </Badge>
  );
};

export default DiscardToggleButton;
