import React from "react";

import { TopBarBase } from "components/Layout/TopBar";
import Logo from "components/Layout/TopBar/components/Logo/Logo";

const SecurityTopBar = () => (
  <TopBarBase>
    <Logo wrapperClassName="" />
  </TopBarBase>
);

export default SecurityTopBar;
