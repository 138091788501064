class MarkerModel {
  constructor(data) {
    this.data = data;
  }

  get roomId() {
    return this.data.room_id;
  }

  get lat() {
    return this.data.lat;
  }

  get lng() {
    return this.data.lng;
  }

  toJSON() {
    return { roomId: this.roomId, lat: this.lat, lng: this.lng };
  }
}

export default MarkerModel;
