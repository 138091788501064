import SuggestedSearchModel from "./models/suggested-search-model";
import SearchPreferences from "./models/search-preferences-model";

export const parseSeekerPreferencesResponse = ({
  suggested_search: suggestedSearchApi,
  ...preferences
}) => {
  const suggestedSearch = suggestedSearchApi
    ? new SuggestedSearchModel(suggestedSearchApi).toJSON()
    : null;
  const seekerPreferences = new SearchPreferences(preferences).toJSON();
  const parsedResponse = { ...seekerPreferences, suggestedSearch };
  return parsedResponse;
};

export const parseParamsToApi = ({
  location,
  budget,
  moveInDate,
  moveInGroup,
  moveInType,
  propertyType,
} = {}) => {
  const { label: q, placeId: place_id, livingThere: living_there } = location;
  const parsedLocation = {
    q,
    place_id,
    living_there,
  };

  return {
    budget,
    location: parsedLocation,
    move_in_date: moveInDate,
    move_in_group: moveInGroup,
    move_in_type: moveInType,
    property_type: propertyType,
  };
};
