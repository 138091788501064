import React from "react";
import { colors } from "@badi/badi-components";

const FilterIcon = ({ selected }) => (
  <svg
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={selected ? colors.greenDark : colors.darkGrey} fillRule="nonzero">
      <path d="M6.207 0C4.965 0 3.91.863 3.569 2.046H.745c-.435 0-.745.351-.745.799 0 .447.341.8.776.8H3.6A2.75 2.75 0 0 0 6.238 5.69c1.272 0 2.327-.863 2.669-2.046h9.313a.78.78 0 0 0 .776-.8.78.78 0 0 0-.776-.798H8.907C8.534.863 7.479 0 6.207 0zm.03 1.598c.652 0 1.211.544 1.211 1.247 0 .703-.559 1.247-1.21 1.247-.652 0-1.21-.544-1.21-1.247 0-.703.558-1.247 1.21-1.247zM6.207 12c-1.242 0-2.297.863-2.638 2.046H.745c-.435 0-.745.351-.745.799 0 .447.341.8.776.8H3.6a2.75 2.75 0 0 0 2.638 2.045c1.272 0 2.327-.863 2.669-2.046h9.313a.78.78 0 0 0 .776-.8.78.78 0 0 0-.776-.798H8.907C8.534 12.863 7.479 12 6.207 12zm.03 1.598c.652 0 1.211.544 1.211 1.247 0 .703-.559 1.247-1.21 1.247-.652 0-1.21-.544-1.21-1.247 0-.703.558-1.247 1.21-1.247zM12.789 6c1.241 0 2.296.863 2.638 2.046h2.824c.434 0 .745.351.745.799a.78.78 0 0 1-.776.8h-2.824a2.75 2.75 0 0 1-2.638 2.045c-1.272 0-2.328-.863-2.669-2.046H.776a.78.78 0 0 1-.776-.8c0-.447.341-.798.776-.798h9.313C10.461 6.863 11.517 6 12.789 6zm-.031 1.598c-.652 0-1.21.544-1.21 1.247 0 .703.558 1.247 1.21 1.247.652 0 1.21-.544 1.21-1.247 0-.703-.558-1.247-1.21-1.247z" />
    </g>
  </svg>
);

FilterIcon.defaultProps = {
  selected: false,
};

export default FilterIcon;
