import React from "react";
import { Container, Row, Col } from "reactstrap";
import {
  Card,
  Spacer,
  BADI_COLORS,
  MatchMedia,
  BREAKPOINTS_PX,
} from "@badi/badi-components";

import { Root } from "./styled-components";

const DESKTOP_MARGINS = {
  bottom: 8,
  left: 12.5,
  right: 12.5,
  top: 8,
};

const MOBILE_MARGINS = {
  bottom: 2,
  left: 2,
  right: 2,
  top: 2,
};

const CheckoutContent = ({ children }) => {
  return (
    <Root>
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} md={{ size: 10 }} sm={12}>
            <Card backgroundColor={BADI_COLORS.NEUTRAL.NEUTRAL_00}>
              <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.md})`}>
                {(matches) => {
                  const margins = matches ? DESKTOP_MARGINS : MOBILE_MARGINS;
                  return <Spacer {...margins}>{children}</Spacer>;
                }}
              </MatchMedia>
            </Card>
          </Col>
        </Row>
      </Container>
    </Root>
  );
};

export default CheckoutContent;
