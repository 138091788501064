import RoomCompact from "datamodel/Room/models/room-compact-model";
import ReviewUser from "./review-user-model";
import { parseReviewStarsFromApi } from "../utils";

export default class ReviewDetailed {
  constructor(data) {
    this.entity = data || {};
  }

  get id() {
    return this.entity.id;
  }

  get reviewedUser() {
    return new ReviewUser(this.entity.reviewed);
  }

  get text() {
    return this.entity.text || "";
  }

  get stars() {
    return parseReviewStarsFromApi(this.entity.stars);
  }

  get collectedAt() {
    return this.entity.collected_at || "";
  }

  get submittable() {
    return this.entity.submittable || false;
  }

  get room() {
    return this.entity.room ? new RoomCompact(this.entity.room).toJSON() : null;
  }

  get reviewerType() {
    return this.entity.reviewer_type;
  }

  toJSON() {
    return {
      id: this.id,
      reviewedUser: this.reviewedUser.toJSON(),
      text: this.text,
      stars: this.stars,
      collectedAt: this.collectedAt,
      submittable: this.submittable,
      reviewerType: this.reviewerType,
      room: this.room,
    };
  }
}
