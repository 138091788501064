import styled from "styled-components";
import {
  getGoldenGradient,
  unitizedPx,
  BADI_COLORS,
} from "@badi/badi-components";
import { TOP_BANNER_TYPES } from "./constants";

export const Root = styled.div`
  width: 100%;
  overflow: hidden;
  background: ${(props) =>
    props.type === TOP_BANNER_TYPES.PREMIUM
      ? getGoldenGradient()
      : BADI_COLORS.BRAND.TERTIARY.DEFAULT};
`;

export const ImageWrapper = styled.div`
  width: ${({ width = 5 }) => unitizedPx(width)};

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  direction: row;
  justify-content: space-between;
  cursor: pointer;
`;
