class Room {
  constructor(entity) {
    this.entity = entity || {};
  }

  get id() {
    return this.entity.id;
  }

  get loaded() {
    return (
      this.entity.loaded || !!this.entity.capabilities || !!this.entity.error
    );
  }

  get error() {
    return this.entity.error || null;
  }

  get capabilities() {
    return this.entity.capabilities || [];
  }

  toJSON() {
    return {
      id: this.id,
      loaded: this.loaded,
      error: this.error,
      capabilities: this.capabilities,
    };
  }
}

export default Room;
