import React from "react";
import { withRouter } from "react-router";
import { routerEventsListenerInitiator } from "base/shared/tracking-headers";
import { isBrowser } from "base/shared/Utils";

import GeoSuggest from "./geo-suggest";

const GeoSuggestRoute = ({ router, ...props }) => {
  const routerEventsListener = routerEventsListenerInitiator(router);

  return <GeoSuggest routerEventsListener={routerEventsListener} {...props} />;
};

// polyfill for server
if (!isBrowser()) {
  global.Element = function () {};
}

export default withRouter(GeoSuggestRoute);
