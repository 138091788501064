import React, { PureComponent } from "react";
import Action from "../../layout-elements/action";
import { getStatusNextStepPath } from "../../utils";

class ListingsStatuses extends PureComponent {
  componentDidMount() {
    this.props.getListingsStatuses();
  }

  render() {
    const { listingsStatuses, fetchedListingsStatuses } = this.props;

    return (
      <div>
        {fetchedListingsStatuses &&
          listingsStatuses.map((listingStatus) => {
            const {
              heading,
              actionId,
              nextStepData,
              clickable,
              subheading,
              value,
            } = listingStatus;
            return (
              <Action
                actionId={actionId}
                clickable={clickable}
                focus={nextStepData.focus}
                heading={heading}
                key={actionId}
                nextStepPath={getStatusNextStepPath(nextStepData)}
                subheading={subheading}
                value={value}
              />
            );
          })}
      </div>
    );
  }
}

export default ListingsStatuses;
