import Types from "../types";

export default {
  setReady() {
    return { type: Types.SET_STATUS, result: 0 };
  },
  setUnReady() {
    return { type: Types.SET_STATUS, result: 1 };
  },
  setLoading() {
    return { type: Types.SET_STATUS, result: 2 };
  },
  setSocket(socket) {
    return { type: Types.SET_SOCKET, result: socket };
  },
};
