import React from "react";
import { Row, Col } from "reactstrap";
import { SortableContainer } from "react-sortable-hoc";

import SortableItem from "./SortableItem";
import AddPicture from "./AddPicture";

const SortableList = SortableContainer(
  ({ addPicture, items, maxFiles, ...rest }) => (
    <Row className="DropZone__container" key="dropzone-container">
      {items.map((value, index) => (
        <SortableItem
          key={value.tempId}
          index={index}
          sortIndex={index}
          item={value}
          {...rest}
        />
      ))}
      {items.length < maxFiles && (
        <Col xs={4} key="add-picture" className="DropZone__container--item">
          <AddPicture addPicture={addPicture} />
        </Col>
      )}
    </Row>
  ),
);

export default SortableList;
