import React, { useCallback } from "react";
import {
  Card,
  colors,
  Flex,
  Text,
  TextButton,
  Spacer,
  Icon,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { openBookingGuaranteeModal } from "components/booking-guarantee-modal";

import { IconWrap } from "./styled-components";

const BadiGuaranteeCard = ({
  userRole,
  textDirection = "row",
  alignText = true,
}) => {
  const handleGuaranteePopup = useCallback(() => {
    openBookingGuaranteeModal({ userRole });
  }, [userRole]);

  return (
    <Card backgroundColor={colors.irisBlue}>
      <Spacer bottom={1.5} left={2} right={2} top={1.5}>
        <Flex alignItems="center" gutter={2}>
          <IconWrap>
            <Icon height={40} name="secure" width={38} />
          </IconWrap>
          <Flex
            direction="column"
            grow={1}
            md={{
              direction: textDirection,
              justify: "spaceBetween",
              alignItems: alignText ? "center" : "flexStart",
            }}
          >
            <Text body={1} color={colors.white} data-qa="badi-guarantee-title">
              {translate("booking_flow_guarantee_title")}
            </Text>
            <TextButton
              color={colors.white}
              data-qa="badi-guarantee-link"
              hoverColor={colors.white}
              onClick={handleGuaranteePopup}
            >
              {translate("booking_flow_guarantee_CTA")}
            </TextButton>
          </Flex>
        </Flex>
      </Spacer>
    </Card>
  );
};

export default BadiGuaranteeCard;
