import { generateFetchTypes } from "base/shared/TypeHelper";
import { onLogoutSuccess } from "./onLogoutSuccess";
import Types from "../types";
import * as AuthAPI from "../api";

export default {
  authenticate(params) {
    if (params && params.auth) {
      return { type: Types.AUTH_SUCCESS, result: params.auth };
    }
    return {
      types: generateFetchTypes(Types.AUTH_REQUEST),
      request: AuthAPI.authenticate(),
    };
  },
  login(params) {
    return {
      types: generateFetchTypes(Types.AUTH_REQUEST),
      request: AuthAPI.login(params),
      authMethod: params.provider,
    };
  },
  logout({ stopPushNotifications = true } = {}) {
    return {
      types: generateFetchTypes(Types.AUTH_REQUEST),
      request: AuthAPI.authenticate(),
      onSuccess: stopPushNotifications ? onLogoutSuccess : null,
    };
  },
  register(params) {
    return {
      types: generateFetchTypes(Types.AUTH_REQUEST),
      request: AuthAPI.register(params),
      authMethod: "email",
    };
  },
  refreshToken() {
    return {
      types: generateFetchTypes(Types.REFRESH_REQUEST),
      request: AuthAPI.refreshToken,
      requireAuth: true,
    };
  },
  setError(code, message) {
    return { type: Types.AUTH_ERROR, error: { code, message } };
  },
  setIsRememberMeChecked(isRememberMeChecked) {
    return {
      type: Types.AUTH_IS_REMEMBER_ME_CHECKED,
      payload: isRememberMeChecked,
    };
  },
};
