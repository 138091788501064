import React from "react";
import { FacebookShareButton } from "react-share";

import "./shareExt.css";

const Facebook = ({ target, title }) => (
  <FacebookShareButton className="Button__share" quote={title} url={target}>
    <div className="Button__share_facebook" />
  </FacebookShareButton>
);

export default Facebook;
