import React, { Component } from "react";
import moment from "moment";

import { DayPickerSingleDateController as DayPicker } from "react-dates";
import { Choice, Overlay } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getImageUrl } from "components/Image/utils";

import FilterSectionWrapper from "../../../wrappers/filter-section-wrapper";
import FilterSubSectionWrapper from "../../../wrappers/filter-sub-section-wrapper";
import {
  FormSectionChoices,
  FormSectionChoiceElement,
} from "../styled-components";

import "./AvailabilityExt";

class AvailabilityFields extends Component {
  static DEFAULT_MIN_STAY = 6;

  state = {
    visibleCalendar: false,
  };

  handleFromDateSelect = (fromDate) => {
    this.setState(
      {
        visibleCalendar: false,
      },
      () => this.props.onFromDateSelect(fromDate.format("YYYY-MM-DD")),
    );
  };

  handleStayOptionSelect = (e) => {
    const { stayOptionsIds, onStayOptionChange } = this.props;
    const stayOptionId = parseInt(e.currentTarget.value, 10);

    if (isNaN(stayOptionId)) {
      return;
    }

    let newStayOptionTypeIds = [...stayOptionsIds];

    if (e.currentTarget.checked) {
      newStayOptionTypeIds.push(stayOptionId);
    } else {
      newStayOptionTypeIds = newStayOptionTypeIds.filter(
        (_stayOptionId) => _stayOptionId !== stayOptionId,
      );
    }

    onStayOptionChange(newStayOptionTypeIds);
  };

  handleShowCalendar = () => {
    this.setState({ visibleCalendar: true });
  };

  handleHideCalendar = () => {
    this.setState({ visibleCalendar: false });
  };

  isElementSelected = (id) => {
    const { stayOptionsIds } = this.props;
    return Array.isArray(stayOptionsIds)
      ? stayOptionsIds.includes(id)
      : stayOptionsIds === id;
  };

  render() {
    const { now, fromDate, onNowSelect, stayOptions } = this.props;
    const { visibleCalendar } = this.state;

    const fromDateText = fromDate
      ? moment(fromDate).format("DD MMMM")
      : translate("Choose date");

    return (
      <FilterSectionWrapper title={translate("search.filters.availability")}>
        <FilterSubSectionWrapper
          title={translate("search.filters.availability.fromDate")}
        >
          <div className="SearchAvailabilityForm__FromDate">
            <Choice
              checked={now}
              id="availability-now"
              name="availability"
              onChange={onNowSelect}
              type="radio"
            >
              {translate("Now")}
            </Choice>
            <Choice
              checked={!!fromDate}
              icon={getImageUrl(
                true,
                "",
                "/assets/icons/filters/filter-availability.svg",
              )}
              id="availability-from"
              name="availability"
              onClick={this.handleShowCalendar}
              type="radio"
            >
              {fromDateText}
            </Choice>
            {visibleCalendar && (
              <div className="SearchAvailabilityForm__Calendar">
                <Overlay
                  onClick={this.handleHideCalendar}
                  opacity={0}
                  zIndex={3}
                />
                <DayPicker
                  hideKeyboardShortcutsPanel={true}
                  numberOfMonths={1}
                  onDateChange={this.handleFromDateSelect}
                />
              </div>
            )}
          </div>
        </FilterSubSectionWrapper>
        <FilterSubSectionWrapper
          title={translate("search.filters.availability.minStay")}
        >
          <FormSectionChoices>
            {stayOptions.map((item) => (
              <FormSectionChoiceElement>
                <Choice
                  checked={this.isElementSelected(item.id)}
                  description={item.description}
                  id={`${"availabilityOption"}-${item.id}`}
                  key={item.id}
                  name={"availabilityOption"}
                  onChange={this.handleStayOptionSelect}
                  type="checkbox"
                  value={item.value}
                >
                  {item.title}
                </Choice>
              </FormSectionChoiceElement>
            ))}
          </FormSectionChoices>
        </FilterSubSectionWrapper>
      </FilterSectionWrapper>
    );
  }
}

AvailabilityFields.defaultProps = {
  fromDate: "",
};

export default AvailabilityFields;
