import React from "react";
import AutoSearchButton from "./auto-search-button";
import RedoSearchButton from "./redo-search-button";

const SearchOnMoveButton = ({
  auto,
  onAutoClick,
  onRedoSearchClick,
  redoSearch,
}) =>
  redoSearch ? (
    <RedoSearchButton onRedoSearchClick={onRedoSearchClick} />
  ) : (
    <AutoSearchButton auto={auto} onAutoClick={onAutoClick} />
  );

export default SearchOnMoveButton;
