import NotificationPermissionController from "./notification-permission-controller";
import FirebaseMessagingController from "./firebase-messaging-controller";
import {
  FIREBASE_CONFIG,
  BADI_PUBLIC_VAPID_KEY,
  DEVICE_TOKEN_ERROR,
} from "./static";

import {
  findActivatedServiceWorker,
  shouldNotInitialize,
  getAccessToken,
  getAuthTokensFromCookie,
} from "./utils";
import { saveTokenToApi, deleteTokenFromApi } from "../api";

export default class PushNotifications {
  constructor(osCodeNumber, appVersion) {
    this.firebaseMessagingController = new FirebaseMessagingController(
      BADI_PUBLIC_VAPID_KEY,
      FIREBASE_CONFIG,
    );
    this.notificationPermissionController =
      new NotificationPermissionController();
    this.forceSaveToken = false;
    this.apiCallParams = { os: osCodeNumber, app_version: appVersion };
  }

  initialize = async (
    userId,
    locale,
    {
      forceSaveToken = false,
      arePushNotificationsEnabled,
      arePushNotificationsSupported,
    } = {},
  ) => {
    if (
      shouldNotInitialize(
        userId,
        arePushNotificationsEnabled,
        arePushNotificationsSupported,
      )
    ) {
      return;
    }

    this.addApiCallParam("locale", locale);
    this.setForceSaveToken(forceSaveToken);

    const isPermissionGranted =
      await this.notificationPermissionController.checkIfPermissionGranted();

    this.activeRegistration = await findActivatedServiceWorker();

    if (isPermissionGranted) {
      this.handlePermissionGranted();
    } else if (this.activeRegistration) {
      this.activeRegistration.unregister();
    }
  };

  handlePermissionGranted = async () => {
    this.deviceToken = await this.firebaseMessagingController.install();
    if (!this.deviceToken) return;
    this.addApiCallParam("token", this.deviceToken);
    if (!this.activeRegistration || this.forceSaveToken) {
      this.saveToken();
    }
    this.registerTokenRefresh();
  };

  registerTokenRefresh = () => {
    this.firebaseMessagingController.messaging.onTokenRefresh(async () => {
      const updatedToken = await this.firebaseMessagingController.getToken();
      const accessToken = getAuthTokensFromCookie().accessToken;
      deleteTokenFromApi({ token: this.deviceToken }, accessToken).then(() => {
        this.addApiCallParam("token", updatedToken);
        this.deviceToken = updatedToken;
        saveTokenToApi(this.apiCallParams, accessToken);
      });
    });
  };

  setForceSaveToken = (forceSaveToken) => {
    this.forceSaveToken = forceSaveToken;
  };

  addApiCallParam = (paramkey, paramValue) => {
    this.apiCallParams = { ...this.apiCallParams, [paramkey]: paramValue };
  };

  saveToken = async () => {
    const accessToken = getAuthTokensFromCookie().accessToken;
    saveTokenToApi(this.apiCallParams, accessToken);
  };

  stopPushNotifications = async () => {
    const accessToken = await getAccessToken();

    if (!this.deviceToken) {
      return Promise.reject(DEVICE_TOKEN_ERROR);
    }

    return deleteTokenFromApi({ token: this.deviceToken }, accessToken);
  };
}
