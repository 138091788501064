import styled from "styled-components";
import { colors } from "@badi/badi-components";

export const ProfileFooterRoot = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  border-top: 1px solid ${colors.softGrey};
  background-color: ${colors.white};
`;

export const FinishSetupWrapper = styled.div`
  min-height: 600px;
  height: 600px;
`;

export const Align = styled.div`
  text-align: ${(props) => props.align};
`;
