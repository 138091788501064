import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useInView } from "react-intersection-observer";
import { BADI_COLORS, Button, Flex, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Fact from "./fact";
import { Separator, Wrapper } from "./styled-components";

const FACT_DESKTOP_MARGINS = { bottom: 0, left: 10, right: 10, top: 0 };
const FACTS_DESKTOP_DISTRIBUTION = { direction: "row", alignItems: "center" };

const Community = ({ onClick }) => {
  const [communityRef, isInView] = useInView({
    rootMargin: "200px 0px 0px 0px",
    triggerOnce: true,
  });

  return (
    <Wrapper ref={communityRef} visibleBackground={isInView}>
      <Container>
        <Row>
          <Col>
            <Flex direction="column" gutter={4}>
              <Text
                color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                dataQa="community-title"
                level="14-Bold"
              >
                {translate("home.community.title")}
              </Text>
              <Flex direction="column" lg={FACTS_DESKTOP_DISTRIBUTION}>
                <Fact
                  description={translate("home.community.fact1.desc")}
                  value={translate("home.community.fact1.value")}
                />
                <Spacer bottom={3} lg={FACT_DESKTOP_MARGINS} top={3}>
                  <Separator />
                </Spacer>
                <Fact
                  description={translate("home.community.fact2.desc")}
                  value={translate("home.community.fact2.value")}
                />
                <Spacer bottom={3} lg={FACT_DESKTOP_MARGINS} top={3}>
                  <Separator />
                </Spacer>
                <Fact
                  description={translate("home.community.fact3.desc")}
                  value={translate("home.community.fact3.value")}
                />
              </Flex>
              <Button dataQa="community-cta" onClick={onClick}>
                {translate("home.community.cta")}
              </Button>
            </Flex>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Community;
