import React from "react";
import { BADI_COLORS, Flex, Text } from "@badi/badi-components";
import { StepWrapper } from "./styled-components";

const Step = ({ active, completed, icon: Icon, name, title }) => {
  const color =
    completed || active
      ? BADI_COLORS.BRAND.PRIMARY.DEFAULT
      : BADI_COLORS.NEUTRAL.NEUTRAL_50;

  return (
    <StepWrapper active={active} alignItems="center" as={Flex} gutter={1}>
      <Icon color={color} />
      <Text body="12-Bold" color={color} dataQa={`stepper-step-${name}`}>
        {title}
      </Text>
    </StepWrapper>
  );
};

export default Step;
