export default class BookingRoom {
  constructor(data) {
    this.entity = data;
  }

  set model(data) {
    this.entity = data;
  }

  get id() {
    return this.entity.id;
  }

  get picture() {
    return this.entity.cover_picture
      ? this.entity.cover_picture.width_100_url
      : null;
  }

  get title() {
    return this.entity.title;
  }

  get address() {
    return this.entity.address;
  }

  get monthlyPrice() {
    return this.entity.price_description;
  }

  get deposit() {
    return this.entity.deposit;
  }

  get owned() {
    return this.entity.owned;
  }

  get fullAddress() {
    return this.entity.full_address;
  }

  toJSON() {
    return {
      id: this.id,
      picture: this.picture,
      title: this.title,
      address: this.address,
      monthlyPrice: this.monthlyPrice,
      deposit: this.deposit,
      owned: this.owned,
      fullAddress: this.fullAddress,
    };
  }
}
