import { getRelativePath } from "base/shared/Utils";
import { getFriendlyUrl } from "datamodel/search-rooms/utils";
import { locale, translate } from "base/shared/Localization";
import { interpolateQueryParam } from "base/shared/tracking-headers";

const BASE_PATH = "/rooms";

export const getEditRoomPath = ({ id, publish = false } = {}) => {
  if (!id) {
    return null;
  }

  const params = publish ? "?publish=true" : "";
  return getRelativePath(`${BASE_PATH}/edit/${id}${params}`);
};

export const getNewRoomPath = () => getRelativePath(`/list/new`);

export const getDuplicateListingPath = () => getRelativePath(`/list/duplicate`);

export const getMyListingsPath = () => getRelativePath(`/listings`);

export const getRoomDetailPath = ({
  roomId,
  title,
  city = null,
  params = {},
}) => {
  const url = title
    ? getFriendlyUrl(translate, title, city, roomId)
    : getRelativePath(`/room/${roomId}`);

  const { keyVal, options } = params;

  return interpolateQueryParam(url, keyVal, options);
};
