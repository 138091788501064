import React, { useState } from "react";
import styled from "styled-components";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { getPublicAsset } from "base/shared/Utils";
import { Heading } from "@badi/badi-components";
import { Button, Text, BADI_COLORS, BUTTON_TYPES } from "app/badi-components";
import { translate } from "base/shared/Localization";
import { getAvailableRoomsFromPlan, getPlans } from "datamodel/premium/api";
import { PLANS_CATEGORIES } from "datamodel/plans/constants";
import PurchaseButton from "modules/stripe/purchase";
import UpgradeRoomSelector from "Sections/plans/plans/components/upgrade-room-selector";

const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45rem;
    width: 25rem;
    justify-content: space-evenly;
  `,
  ImageSection: styled.div`
    background-image: url("${getPublicAsset(
      "images/swiping/rocket-ship-with-headshots.svg",
    )}");
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    height: 15rem;
    width: 100%;
  `,
  BottomSectionContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10%;
    align-items: center;
    width: 80%;
    border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10}};
    border-radius: 1rem 1rem 0 0;
    padding: 5%;
    z-index: 99;
    background: white;
  `,
  Label: styled.div`
    background-color: black;
    color: white;
    padding: 2.5%;
    border-radius: 1rem;
    font-size: 10px;
  `,
  Heading: styled(Heading)`
    text-align: center;
  `,
  Text: styled(Text)`
    text-align: center;
    z-index: 99;
  `,
  TermsAndConditions: styled(Text)`
    text-align: center;
    z-index: 99;
    width: 75%;
    padding-top: 5%;
  `,
  Button: styled(Button)`
    width: 125%;
    position: relative;
    top: 2.5rem;
  `,
  PurchaseButton: styled(PurchaseButton)`
    width: 125%;
    position: relative;
    top: 2.5rem;
  `,
  OrangeSection: styled.div`
    position: absolute;
    background: ${BADI_COLORS.GRADIENT.GRADIENT_02};
    bottom: 0;
    height: 35%;
    width: 100%;
    z-index: 0;
    border-radius: 2rem 2rem 0 0;
  `,
};

const closeModal = () => {
  modal.remove(MODAL_ID.NEED_MORE_CANDIDATES);
};

const getBoostableRoomId = (rooms) => {
  if (rooms.length === 1) {
    return rooms[0].id;
  }
  return null;
};

const NeedMoreCandidates = ({ plan, boostableRooms }) => {
  const {
    priceDetails: { purchaseValue },
    additionalInfo: { description },
    token,
  } = plan;
  const boostableRoomId = getBoostableRoomId(boostableRooms);
  const isOnlyBoostableRoom = Boolean(boostableRoomId);
  const [isRoomSelectorVisible, setIsRoomSelectorOpen] = useState(false);
  if (isRoomSelectorVisible) {
    return (
      <div>
        <UpgradeRoomSelector {...plan} token={token} />
      </div>
    );
  }
  return (
    <Components.Container>
      <Components.Label>BOOST</Components.Label>
      <Components.ImageSection />
      <Components.BottomSectionContainer>
        <Components.Heading>
          {translate("inbox-lister.want-more-candidates.title")}
        </Components.Heading>
        <Components.Text>
          {translate("inbox-lister.want-more-candidates.subtitle")}
        </Components.Text>
        <Components.Heading
          level={3}
          color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
        >
          {purchaseValue}/listing
        </Components.Heading>
        {isOnlyBoostableRoom ? (
          <Components.PurchaseButton
            {...plan}
            roomId={boostableRoomId}
            variant={BUTTON_TYPES.PREMIUM_SECONDARY}
          >
            {translate("inbox-lister.want-more-candidates.cta")}
          </Components.PurchaseButton>
        ) : (
          <Components.Button
            variant={BUTTON_TYPES.PREMIUM_SECONDARY}
            onClick={() => setIsRoomSelectorOpen(true)}
          >
            {translate("inbox-lister.want-more-candidates.cta")}
          </Components.Button>
        )}
      </Components.BottomSectionContainer>
      <Components.OrangeSection />
      <Components.TermsAndConditions
        align="center"
        body="12-Medium"
        color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
      >
        {description}
      </Components.TermsAndConditions>
    </Components.Container>
  );
};

const openNeedMoreCandidatesModal = async ({ token }) => {
  const { plans } = await getPlans(
    {
      token,
    },
    {
      category: PLANS_CATEGORIES.STANDALONE_BOOSTING,
    },
  );
  const plan = plans[0];
  if (!plan) {
    return;
  }

  const { rooms } = await getAvailableRoomsFromPlan(
    { token },
    { planId: plan.id },
  );
  if (rooms.length == 0) {
    return;
  }
  modal.add(
    <NeedMoreCandidates plan={plan} boostableRooms={rooms} token={token} />,
    {
      onIconClose: closeModal,
      id: MODAL_ID.NEED_MORE_CANDIDATES,
      onClose: closeModal,
      type: MODAL_TYPE.ONBOARDING,
      containerProps: { padding: "0" },
    },
  );
};

export default openNeedMoreCandidatesModal;
