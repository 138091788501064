import React from "react";
import { withFormsy } from "formsy-react";
import DatePicker from "./DatePicker";

class DatePickerWithFormsy extends React.PureComponent {
  onChange = (data) => {
    this.props.setValue(data);
  };

  render() {
    const { getValue, setValue, value, ...props } = this.props;
    const safeValue = value ? getValue() : null;

    return <DatePicker {...props} onChange={this.onChange} value={safeValue} />;
  }
}

export default withFormsy(DatePickerWithFormsy);
