import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchFeatures } from "./api";

export default {
  getFeatures({ token, isLoggedUser, ip, locale, userAgent }) {
    return {
      types: generateFetchTypes(Types.FEATURES_REQUEST),
      request: fetchFeatures(token, isLoggedUser, { ip, locale, userAgent }),
    };
  },
};
