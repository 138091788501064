import React, { useState, useEffect } from "react";
import { translate } from "base/shared/Localization";
import { Row, Col } from "reactstrap";
import { Text, Heading, Flex, Spacer } from "@badi/badi-components";
import TextArea from "components/Form/TextArea/TextArea";
import UserProfileBadge from "components/user-profile-badge";
import RoomDetailBadge from "components/room-detail-badge";
import StarReview from "../star-review";
import { Divider } from "../styled-components";
import ReviewHints from "../review-hints";
import { isSeeker } from "../../utils";

const InProgressStep = ({ description, onValidation, review = {}, title }) => {
  const { stars: initialStars, reviewerType, room, reviewedUser } = review;

  const [stars, setStars] = useState(initialStars);
  const [text, setText] = useState("");

  const handleTextChange = (newText) => {
    setText(newText);
  };

  const handleStarClick = (payload) => {
    setStars({
      ...stars,
      ...payload,
    });
  };

  const areStarQuestionsAnswered = Object.keys(stars)
    .map((key) => stars[key].value !== null)
    .every((item) => item === true);

  useEffect(() => {
    onValidation({
      valid: areStarQuestionsAnswered && text.trim().length >= 20,
      payload: {
        text: text.trim(),
        stars,
      },
    });
  }, [stars, text]);

  const renderBadge = () => {
    if (isSeeker(reviewerType) && room) {
      const { address, title: roomTitle, id, picture } = room;
      return (
        <RoomDetailBadge
          address={address}
          clickable={true}
          data-qa="room-detail-badge"
          id={id}
          picture={picture}
          title={roomTitle}
        />
      );
    } else if (reviewedUser) {
      const { age, name, picture } = reviewedUser;
      return (
        <UserProfileBadge
          age={age}
          avatar={picture}
          clickable={false}
          data-qa="reviewed-user-profile-badge"
          name={name}
        />
      );
    }
    return null;
  };

  return (
    <Spacer bottom={12}>
      <Row>
        <Col md={8}>
          <Flex direction="column" gutter={2}>
            <Heading
              data-qa="new-review-in-progress-step-title"
              level="28-Extrabold"
            >
              {title}
            </Heading>
            <Text
              body="16-Regular"
              dataQa="new-review-in-progress-step-description"
            >
              {description}
            </Text>
            {renderBadge()}
            <Divider />
            {Object.keys(stars).map((key) => (
              <Spacer bottom={2} key={key}>
                <StarReview
                  initialValue={stars[key].value}
                  onClick={handleStarClick}
                  title={stars[key].title}
                  type={key}
                />
              </Spacer>
            ))}
            {areStarQuestionsAnswered && (
              <>
                <Spacer bottom={2}>
                  <Text
                    body="14-Bold"
                    dataQa="new-review-in-progress-step-text-area-title"
                  >
                    {translate("review.popup_write_title")}
                  </Text>
                </Spacer>
                <TextArea
                  dataQa="new-review-in-progress-step-text-area"
                  maxLength={500}
                  minLength={20}
                  name="review-text"
                  onChange={handleTextChange}
                  placeholder={translate("review.popup_placeholder")}
                  required={true}
                  value={text}
                />
                <Spacer top={6}>
                  <ReviewHints reviewerType={reviewerType} />
                </Spacer>
              </>
            )}
          </Flex>
        </Col>
      </Row>
    </Spacer>
  );
};

export default InProgressStep;
