import { v4 as uuidv4 } from "uuid";
import { COOKIES } from "base/shared/StaticData";
import { getCookie } from "base/shared/CookiesHandler";

export const badiUniqueIdHeader = {
  setValue(valueId) {
    this.value = valueId || getCookie(COOKIES.BADI_DEVICE_ID);
  },

  getValue() {
    if (!this.value) {
      this.setValue();
    }
    return this.value;
  },
};

export const setBadiUniqueId = (req, res) => {
  const badiUniqueIdCookie = req.universalCookies.get(COOKIES.BADI_DEVICE_ID);
  const badiUniqueIdValue = badiUniqueIdCookie || uuidv4();
  badiUniqueIdHeader.setValue(badiUniqueIdValue);

  if (!badiUniqueIdCookie) {
    res.cookie(COOKIES.BADI_DEVICE_ID, badiUniqueIdValue);
  }
};

export const BADI_DEVICE_ID_HEADER = "badi-device-id";
