import styled from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const GeosuggestWrapper = styled.div`
  > div {
    .geosuggest__input {
      padding: 15px;
      max-height: 48px;
      border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
      background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
    }

    .geosuggest__suggests {
      margin-top: -3px;
      border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
      border-top: none;
      z-index: 1;
      padding-bottom: 33px;

      :after {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        background-image: url("${getCDNUrl()}/assets/images/powered_by_google_on_white_hdpi.png");
        background-size: 144px 18px;
        content: "";
        width: 144px;
        height: 18px;
      }
    }

    .geosuggest__item,
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      font-weight: 500;
    }
  }
`;
