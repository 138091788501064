import styled from "styled-components";
import { unitizedPx, colors, mediaquery } from "@badi/badi-components";

export const FooterBar = styled.div`
  height: auto;
  padding: ${unitizedPx(1)} 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${colors.softGrey};
  box-shadow: 0px 0px ${unitizedPx(1)} 0px rgba(0, 0, 0, 0.24);
  background-color: ${colors.white};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mediaquery.md`
    height: auto;
   `}
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaquery.md`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${mediaquery.md`
    min-width: 450px;
    flex-basis: auto;
   `}
`;
