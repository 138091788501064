import React, { memo, useState } from "react";
import { Text, Flex, TextArea } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

export const TEXT_FIELD_ID = "message_visit";

const TextField = memo(({ disabled = false, onChange }) => {
  const [value, setValue] = useState("");

  const handleTextChange = (event) => {
    const { value: updatedValue } = event.target;

    setValue(updatedValue);
    onChange(updatedValue);
  };

  return (
    <Flex direction="column" gutter={1}>
      <Text body="14-Bold" dataQa="visit-proposal-message-title">
        {translate("visit.proposal.message.title")}
      </Text>
      <TextArea
        disabled={disabled}
        name={TEXT_FIELD_ID}
        onChange={handleTextChange}
        placeholder={translate("visit.proposal.message.text")}
        value={value}
      />
    </Flex>
  );
});

TextField.displayName = "TextField";

export default TextField;
