import {
  BUTTON_TYPES,
  PRIMARY_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
  EXPRESS_BOOKING_BUTTON_TYPE,
  PRIMARY_INVERTED_BUTTON_TYPE,
  NEGATIVE_BUTTON_TYPE,
  GOLD_GRADIENT,
  PREMIUM_SECONDARY_BUTTON_TYPE,
  PREMIUM_BUTTON_TYPE,
} from "./constants";

export const getButtonStyles = (buttonType) => {
  switch (buttonType) {
    case BUTTON_TYPES.PREMIUM:
      return PREMIUM_BUTTON_TYPE;
    case BUTTON_TYPES.PREMIUM_SECONDARY:
      return PREMIUM_SECONDARY_BUTTON_TYPE;
    case BUTTON_TYPES.SECONDARY:
      return SECONDARY_BUTTON_TYPE;
    case BUTTON_TYPES.EXPRESS_BOOKING:
      return EXPRESS_BOOKING_BUTTON_TYPE;
    case BUTTON_TYPES.GOLD_GRADIENT:
      return GOLD_GRADIENT;
    case BUTTON_TYPES.GRADIENT:
      console.warn("Deprecation notice: This button style is deprecated");
      return PRIMARY_BUTTON_TYPE;
    case BUTTON_TYPES.PRIMARY_INVERTED:
      return PRIMARY_INVERTED_BUTTON_TYPE;
    case BUTTON_TYPES.NEGATIVE:
      return NEGATIVE_BUTTON_TYPE;
    case BUTTON_TYPES.PRIMARY:
    default:
      return PRIMARY_BUTTON_TYPE;
  }
};
