import React, { useCallback, useEffect } from "react";
import { Avatar } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { getBase64FromFile } from "datamodel/Picture/utils";
import { upload } from "datamodel/Picture/api";
import MessageForm from "components/chat-message-form";
import { ChatInputRoot } from "./styled-components";
import { MESSAGE_TYPES } from "../../constants";
import { handleInputPlaceholder } from "./utils";

const ChatInput = ({
  enabled,
  error = null,
  fetching,
  sendMessage,
  token,
  userName,
  userPicture,
  userRole,
  otherUserName,
}) => {
  const handleOnSubmit = (text) => {
    if (!text) return;

    sendMessage(MESSAGE_TYPES.TEXT, text);
  };

  const handleUploadFile = useCallback(
    async (file) => {
      try {
        const encodedFile = await getBase64FromFile(file);
        const uploadedFile = await upload(token, { file: encodedFile });

        sendMessage(
          MESSAGE_TYPES.PICTURE,
          uploadedFile.url || uploadedFile.width_1080_url,
        );
      } catch (uploadError) {
        UserFeedback.exception("Something went wrong", null, uploadError);
      }
    },
    [token, sendMessage],
  );

  useEffect(() => {
    if (error) {
      UserFeedback.exception("Something went wrong", error.message, error);
    }
  }, [error]);

  const placeholder = handleInputPlaceholder({ userRole, enabled });

  return (
    <ChatInputRoot data-qa="chat-input">
      <MessageForm
        avatar={<Avatar alt={userName} src={userPicture} />}
        disabled={!enabled || fetching}
        onSubmit={handleOnSubmit}
        placeholder={translate(placeholder, {
          name: otherUserName,
        })}
        sendMessageText={translate("conversation.new_message.send")}
        uploadImage={handleUploadFile}
      />
    </ChatInputRoot>
  );
};

export default ChatInput;
