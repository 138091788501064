import React from "react";

import BookingRequest from "./booking-request";

const BookingRequestRoute = ({ router, location, authtoken, params }) => {
  const { requestId } = params;
  const { query } = location;
  const { from_connection: fromConnection = 0 } = query;

  return (
    <BookingRequest
      connectionId={Number(fromConnection)}
      requestId={requestId}
      router={router}
      token={authtoken}
    />
  );
};

export default BookingRequestRoute;
