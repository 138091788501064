class InboxRoomModel {
  constructor(entity) {
    this.room = entity || {};
  }

  get id() {
    return this.room.id || 0;
  }
  get title() {
    return this.room.title || null;
  }
  get picture() {
    return this.room.cover_picture?.width_100_url || null;
  }

  get premiumPlan() {
    return !!this.room.premium_plan;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      picture: this.picture,
      premiumPlan: this.premiumPlan,
    };
  }
}

export default InboxRoomModel;
