import React from "react";
import classNames from "classnames";

import "./CheckBoxExt.css";

const CheckBox = ({ checked, className, label, name, onChange }) => {
  const onClick = () => onChange(!checked);

  return (
    <div className={classNames("RoomList__CheckBox--wrapper", className)}>
      <input
        checked={checked}
        id={name}
        name={name}
        onChange={onClick}
        type="checkbox"
      />
      <span className="RoomList__CheckBox--custom" onClick={onClick} />
      {label && <label htmlFor={name}>{label}</label>}
    </div>
  );
};

CheckBox.defaultProps = {
  checked: false,
  className: "",
  label: null,
};

export default CheckBox;
