import { BOOKING_STATUS } from "datamodel/booking/constants";
import dayjs from "dayjs";

export const getStatusOnCheckoutFlow = (status) => {
  if (status === BOOKING_STATUS.PAYMENT_REQUIRED) {
    return BOOKING_STATUS.PAYMENT_REQUIRED_CHECKOUT;
  }
  return status;
};

export const getFormattedDate = (date) =>
  date ? dayjs(date).format("D MMM YYYY") : "";
