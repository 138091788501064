import { SOCIAL_MEDIA_TYPES } from "datamodel/User/constants";

export default class UserSocialProfile {
  constructor(data) {
    this.entity = data;
  }

  get media() {
    return this.entity.media || "";
  }

  get value() {
    return this.entity.value || "";
  }

  get order() {
    return (
      SOCIAL_MEDIA_TYPES.find((type) => type.media === this.media)?.order || -1
    );
  }

  get icon() {
    return (
      SOCIAL_MEDIA_TYPES.find((type) => type.media === this.media)?.icon || ""
    );
  }

  toJSON() {
    return {
      media: this.media,
      value: this.value,
      order: this.order,
      icon: this.icon,
    };
  }
}
