import createReducer from "base/shared/createReducer";
import { BadiError } from "base/shared/Error";
import Types from "../types";
import { AuthModel } from "../models";
import { AUTH_TOKEN_BANNED } from "../constants";

function trackUserAuth(userId, isSameUser, isNewRegisteredUser) {}

function authRequest(state) {
  return { ...state, fetching: true, error: null };
}

function authError(state, action) {
  const { error } = action;
  const newState = { ...state, fetching: false };

  if (error instanceof BadiError) {
    newState.error = error;
  } else {
    newState.error = {
      code: error.code,
      message: error.message || "Server error",
    };
  }
  return newState;
}

function authSuccess(state, action) {
  const {
    access_token: token,
    created_at: createdAt,
    expires_in: expiresIn,
    token_type: tokenType,
    user_id: userId,
    new_user: newUser,
    refresh_token: refreshToken,
  } = action.result;

  const { authMethod } = action;

  let created;
  let expires;

  // Badi born date in milliseconds (Jan-1, 2015)
  // It is impossible to get users created before that date
  if (createdAt < 1420070400000) {
    created = new Date(createdAt * 1000);
    expires = new Date();
    expires.setSeconds(expiresIn);
  } else {
    created = new Date(createdAt);
    expires = new Date(expiresIn);
  }

  trackUserAuth(userId, state.user_id === userId, newUser);

  return {
    ...state,
    fetching: false,
    error: null,
    access_token: token,
    token_type: tokenType,
    expirationTime: expires.getTime(),
    creationTime: created.getTime(),
    user_id: userId || AuthModel.user_id,
    new_user: newUser || AuthModel.new_user,
    refresh_token: refreshToken || AuthModel.refresh_token,
    authMethod,
  };
}

function refreshRequest(state) {
  return { ...state, fetching: true };
}

function refreshError(state, action) {
  const { error } = action;

  return {
    ...state,
    fetching: false,
    error: { ...error, code: AUTH_TOKEN_BANNED },
  };
}

function refreshSuccess(state, action) {
  return authSuccess(state, action);
}

const setIsRememberMeChecked = (state, { payload }) => {
  return {
    ...state,
    isRememberMeChecked: payload,
  };
};

const actionHandlers = {
  [Types.AUTH_REQUEST]: authRequest,
  [Types.AUTH_SUCCESS]: authSuccess,
  [Types.AUTH_ERROR]: authError,
  [Types.REFRESH_REQUEST]: refreshRequest,
  [Types.REFRESH_SUCCESS]: refreshSuccess,
  [Types.REFRESH_ERROR]: refreshError,
  [Types.AUTH_IS_REMEMBER_ME_CHECKED]: setIsRememberMeChecked,
};

export default createReducer(actionHandlers, { ...AuthModel });
