import styled from "styled-components";

import { colors, unitizedPx } from "@badi/badi-components";

export const MessageRoot = styled.div`
  position: relative;
`;

export const MessageCaret = styled.div`
  position: absolute;
  left: ${unitizedPx(3)};
  /* this number is because the layout has this specific number */
  top: ${unitizedPx(-0.75)};

  width: 0;
  height: 0;
  border-left: ${unitizedPx(1)} solid transparent;
  border-right: ${unitizedPx(1)} solid transparent;

  border-bottom: ${unitizedPx(1)} solid ${colors.lightGrey};
`;
