import React, { useEffect, useState } from "react";
import { BADI_COLORS, Text, Icon, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { isBannerVisible, removeBanner } from "./utils";
import { CloseIconWrapper, Root } from "./styled-components";
import AppLinks from "../app-links/";

const DownloadAppBanner = ({}) => {
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    removeBanner();
    setVisible(false);
  };

  useEffect(() => {
    const banner = isBannerVisible();

    if (banner) {
      setVisible(true);
    }
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Flex as={Root} direction="column">
      <Text
        body="12-Bold"
        color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
        dataQa="download-banner-title"
        style={{ marginBottom: "8px" }}
      >
        {translate("download_app.banner.title")}
      </Text>
      <AppLinks campaign="home" />
      <CloseIconWrapper
        data-qa="download-app-close"
        onClick={handleClose}
        type="button"
      >
        <Icon
          color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
          height={16}
          name="close"
          width={16}
        />
      </CloseIconWrapper>
    </Flex>
  );
};

export default DownloadAppBanner;
