import { translate } from "base/shared/Localization";
import { NotificationType } from "app/lib/web-notifications/models";
import { NotificationsEventHandler } from "app/lib/web-notifications";

const handleError = (error, context) => {};

const show = (title = null, description = null, options = {}) => {
  const {
    id = Date.now().toString(),
    type = NotificationType.INFO,
    error,
    context = null,
    timeOut = 5000,
  } = options;

  NotificationsEventHandler.add({
    id,
    description: translate(description),
    duration: timeOut,
    title: translate(title),
    type,
  });

  if (error) {
    handleError(error, context);
  }
};

const exception = (
  title = null,
  description = null,
  error = null,
  options = {},
) => {
  show(title, description, {
    ...options,
    error,
    type: NotificationType.WARNING,
  });
};

const success = (title = null, description = null, options) => {
  show(title, description, { ...options, type: NotificationType.SUCCESS });
};

const info = (title = null, description = null, options) => {
  show(title, description, { ...options, type: NotificationType.INFO });
};

const UserFeedback = {
  exception,
  info,
  success,
};

export default UserFeedback;
