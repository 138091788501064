import styled from "styled-components";
import { mediaquery, unitizedPx, BADI_COLORS } from "@badi/badi-components";
import { MainHomeLayer } from "../../styled-components";

export const Wrapper = styled.div`
  position: relative;
  padding-top: ${({ spacing, withForm }) =>
    withForm ? unitizedPx(10) : unitizedPx(spacing)};
  padding-bottom: ${unitizedPx(3)};
  height: ${({ withForm }) => (withForm ? "auto" : "39vh")};
  &:before {
    content: "";
    width: 100%;
    opacity: 0.5;
    height: ${unitizedPx(20)};
    position: absolute;
    top: 0px;
    z-index: 1;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.39399509803921573) 0%,
      rgba(0, 0, 0, 0.304359243697479) 56%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  ${mediaquery.md`
    height: 39vh;
    padding-top: ${({ spacing }) => unitizedPx(spacing)};
  `}
`;

export const AbsolutLayer = styled(MainHomeLayer)`
  position: ${({ withForm }) => (withForm ? "relative" : "absolute")};
  width: 100%;
  transition: all 0.6s;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  left: ${({ visible }) => (visible ? "0px" : "-10px")};
  ${mediaquery.md`
    position: absolute;
    top: initial;
  `}
`;

export const TextWrap = styled.div`
  width: ${({ withForm }) => (withForm ? "100%" : "80%")};
  ${mediaquery.md`
    width: 45%;
  `}
  h2 {
    display: ${({ withForm }) => (withForm ? "none" : "initial")};
    ${mediaquery.md`
      display: initial;
    `}
  }
`;

export const Title = styled.h1`
  position: relative;
  z-index: 1;
  font-size: 2.13rem;
  font-weight: bold;
  line-height: 1.25;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  padding-bottom: ${unitizedPx(2)};

  ${mediaquery.md`
    font-size: 2.66rem;
  `}

  ${mediaquery.xl`
    font-size: 3.73rem;
  `}
`;

export const ImageWrap = styled.div`
  transition: all 0.4s;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  left: ${({ visible }) => (visible ? "0px" : "-10px")};
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const FormWrap = styled.div`
  max-width: ${unitizedPx(50)};
  padding: 0px ${unitizedPx(2)};
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  ${mediaquery.md`
    margin-right: initial;
  `}
  ${mediaquery.lg`
    position: absolute;
    padding: 0px;
    right: 15%;
    top: 50%;
    transform: translate(0, -44%);
  `}
`;
