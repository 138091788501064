import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const TopBarFrame = styled.div`
  z-index: 9;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${({ withSearch }) => (withSearch ? "300px" : "auto")};
  pointer-events: all;
  list-style-type: none;
  padding: ${unitizedPx(2)};
  ${mediaquery.lg`  
    padding: ${unitizedPx(3)} ${unitizedPx(10)};
  `}
`;

export const BackgroundLayer = styled.div`
  background-color: black;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: -1;
`;
