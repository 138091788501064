import styled, { css } from "styled-components";
import { NotificationPosition } from "./models";

const bottomLeftPosition = css`
  bottom: 16px;
  left: 16px;
  justify-content: flex-end;
`;

const bottomRightPosition = css`
  bottom: 16px;
  right: 16px;
  justify-content: flex-end;
`;

const topLeftPosition = css`
  top: 16px;
  left: 16px;
`;

const topRightPosition = css`
  top: 16px;
  right: 16px;
`;

export const Root = styled.div`
  position: fixed;
  height: auto;
  max-height: 100vh;
  z-index: ${({ zIndex }) => zIndex};
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ position }) => {
    switch (position) {
      case NotificationPosition.BOTTOM_RIGHT:
        return bottomRightPosition;
      case NotificationPosition.TOP_LEFT:
        return topLeftPosition;
      case NotificationPosition.TOP_RIGHT:
        return topRightPosition;
      default:
        return bottomLeftPosition;
    }
  }}
`;
