import React from "react";
import { withRouter } from "react-router";
import { getBoostingPath } from "Sections/plans/routes";
import { getRoomDetailPath } from "Sections/Room/routes";
import RoomCard from "./room-card";

const RoomCardRoute = ({ router, id, ...props }) => {
  const handlePreviewRoom = () => {
    router.push(getRoomDetailPath({ roomId: id }));
  };

  const handleBoostRoom = () => {
    router.push(getBoostingPath({ roomId: id }));
  };

  return (
    <RoomCard
      {...props}
      onBoostRoom={handleBoostRoom}
      onPreviewRoom={handlePreviewRoom}
      roomId={id}
    />
  );
};

export default withRouter(RoomCardRoute);
