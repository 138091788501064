import React from "react";

import { BADI_COLORS, Text, Spacer, Flex } from "@badi/badi-components";

import { StyledMessage, MessageMeta, TextWrapper } from "./styled-components";

const ChatMessage = ({
  avatar,
  date,
  message = "",
  name,
  isCurrentUser = false,
  isFastTrack = false,
}) => (
  <Flex alignItems="flexEnd" gutter={2} reverse={isCurrentUser}>
    {avatar}
    <StyledMessage
      grow={1}
      isCurrentUser={isCurrentUser}
      isFastTrack={isFastTrack}
    >
      <Spacer bottom={2} left={2} right={2} top={2}>
        <TextWrapper>
          <Text dataQa="chat-message">{message}</Text>
        </TextWrapper>
        <Spacer top={2}>
          <MessageMeta>
            <Text body={3} color={BADI_COLORS.NEUTRAL.NEUTRAL_50}>
              {`${name} · ${date}`}
            </Text>
          </MessageMeta>
        </Spacer>
      </Spacer>
    </StyledMessage>
  </Flex>
);

export default ChatMessage;
