import React from "react";
import styled from "styled-components";
import { colors, TextButton, Icon, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

export const TopBarRoot = styled.header`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.softGrey};
`;

export const TextButtonWithIcon = styled.button`
  display: flex;
  svg {
    margin-right: 4px;
  }
`;

const TopBar = ({ onClose }) => {
  return (
    <TopBarRoot>
      <Spacer bottom={3} left={3} right={3} top={3}>
        <TextButton
          as={TextButtonWithIcon}
          color={colors.darkGrey}
          hoverColor={colors.darkGrey}
          onClick={onClose}
          withIcon={<Icon name="close" />}
        >
          {translate("Close")}
        </TextButton>
      </Spacer>
    </TopBarRoot>
  );
};

TopBar.defaultProps = {
  onClose: () => {},
};

export default TopBar;
