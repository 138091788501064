import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeCookie } from "base/shared/CookiesHandler";
import { getOverviewPath } from "Sections/overview/routes";
import { COOKIES } from "base/shared/StaticData";
import { getUserId } from "datamodel/User/selectors";
import FeaturesActions from "datamodel/Features/actions";
import SuccessPage from "components/success-page";
import { getToken } from "datamodel/Auth/selectors";
import { getContent } from "./utils";
import { DEFAULT_ORIGIN } from "./constants";

const PurchaseSuccess = ({ location }) => {
  const {
    origin = DEFAULT_ORIGIN,
    roomId,
    category = null,
    initiator = "",
  } = location.query;
  const {
    description,
    pathUrl = getOverviewPath(),
    cta,
    title,
  } = getContent({
    origin,
    roomId,
    category,
    initiator,
  });

  const token = useSelector(getToken);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  useEffect(() => {
    removeCookie(COOKIES.ADVERTISED_MONEYBACK_OVERVIEW);

    dispatch(FeaturesActions.getFeatures({ token, isLoggedUser: true }));
  }, []);

  return (
    <SuccessPage
      cta={cta}
      description={description}
      href={pathUrl}
      title={title}
    />
  );
};

export default PurchaseSuccess;
