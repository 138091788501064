import React from "react";
import styled from "styled-components";
import { LinkButton, BADI_COLORS } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";

const StyledLinkButton = styled(LinkButton)`
  height: 28px;
  margin-left: 8px;
  font-size: 0.86rem;
  padding-left: 45px;
  padding-right: 16px;
  background: url(${getPublicAsset("icons/3D.svg")}),
    ${BADI_COLORS.SEMANTIC.INFO.DEFAULT};
  background-repeat: no-repeat;
  background-position: 8% center;
  &:hover {
    background: url(${getPublicAsset("icons/3D.svg")}),
      ${BADI_COLORS.SEMANTIC.INFO.HOVER};
    background-repeat: no-repeat;
    background-position: 8% center;
  }
`;

const BookVirtualTourButton = () => (
  <StyledLinkButton
    href="https://form.typeform.com/to/QsAwv5rg"
    target="_blank"
  >
    {translate("room.detail.header.virtual-tour-cta")}
  </StyledLinkButton>
);

export default BookVirtualTourButton;
