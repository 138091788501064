import { translate } from "base/shared/Localization";

export const Config = {
  aboutMe: true,
  basic: true,
  edit: false,
  id: false,
  languages: true,
  percentage: false,
  slider: true,
  studies: true,
  tags: true,
  work: true,
  trust: true,
  publishedRooms: true,
};

export const generateVisible = (config) => {
  if (config) {
    return Object.keys(Config).reduce((prev, current) => {
      const accumulator = prev;
      const value = config[current];

      accumulator[current] =
        typeof value === "undefined" ? Config[current] : value;
      return accumulator;
    }, {});
  }

  return Config;
};

export const getSocialMediaProfileHref = (media, value) => {
  switch (media) {
    case "twitter":
      return `https://twitter.com/${value}`;
    case "instagram":
      return `https://instagram.com/${value}`;
    default:
      return value;
  }
};

export const getSocialMediaProfileText = (media, value) => {
  switch (media) {
    case "twitter":
      return `@${value}`;
    case "instagram":
      return `@${value}`;
    default:
      return translate(`user.section.social_media.${media}`);
  }
};
