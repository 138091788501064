import React from "react";
import { Button, Text, Heading } from "@badi/badi-components";
import { BADI_COLORS } from "app/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image/Image";
import styled from "styled-components";
import { BoltIcon } from "app/components/fast-track/icons";

const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    padding-top: 6rem;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  `,
  Image: styled(Image)`
    max-width: 70%;
  `,
  Button: styled(Button)`
    width: 100%;
  `,
  EnquiryCountContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  `,
  FindOutMoreAboutFastTrackContainer: styled.div`
    display: flex;
    flex-direction: horizontal;
    align-items: center;
    background: ${BADI_COLORS.ADDITIONAL.ADDITIONAL_09};
    padding: 1.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  `,
  FindOutMoreAboutFastTrackButton: styled.button`
    display: inline-flex;
    align-items: center;
    font-size: 0.8rem;
    background: ${BADI_COLORS.GRADIENT.GRADIENT_02};
    color: black;
    border: none;
    border-radius: 1rem;
    padding: 0.5rem;
    padding-right: 1rem;
    white-space: nowrap;
  `,
  BottomSectionContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
};

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M109-120q-11 0-20-5.5T75-140q-5-9-5.5-19.5T75-180l370-640q6-10 15.5-15t19.5-5q10 0 19.5 5t15.5 15l370 640q6 10 5.5 20.5T885-140q-5 9-14 14.5t-20 5.5H109Zm371-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm0-120q17 0 28.5-11.5T520-400v-120q0-17-11.5-28.5T480-560q-17 0-28.5 11.5T440-520v120q0 17 11.5 28.5T480-360Z" />
  </svg>
);

const MessageEnquiryFeedbackWithFastTrackInfoSwipingSeekerExperience = ({
  onContinue = null,
  onFastTrackFindOutMoreClick,
  standardEnquiriesCount,
}) => {
  const handleClick = () => {
    if (onContinue) onContinue();
  };
  return (
    <Components.Container>
      <Components.Image src="/assets/images/swiping/request-sent-profile-card.svg" />
      <Heading level="24-Bold">
        {translate("enquiry.swiping-experience.feedback.title")}
      </Heading>
      <Text align="center">
        {translate("enquiry.swiping-experience.feedback.description")}
      </Text>
      {onContinue && (
        <Components.Button onClick={handleClick}>
          {translate("enquiry.swiping-experience.feedback.cta")}
        </Components.Button>
      )}
      <Components.BottomSectionContainer>
        {Number.isInteger(standardEnquiriesCount) && (
          <Components.EnquiryCountContainer>
            <WarningIcon />
            <Text body="14-Regular">
              {translate("enquiry.fast-track.enquiries-count-warning", {
                smart_count: standardEnquiriesCount,
              })}
            </Text>
          </Components.EnquiryCountContainer>
        )}
        <Components.FindOutMoreAboutFastTrackContainer>
          <Text body="14-Regular">
            {translate(
              "enquiry.swiping-experience.feedback.fast-track-benefits-summary",
            )}
          </Text>
          <Components.FindOutMoreAboutFastTrackButton
            onClick={onFastTrackFindOutMoreClick}
          >
            <BoltIcon />
            {translate("Saber más")}
          </Components.FindOutMoreAboutFastTrackButton>
        </Components.FindOutMoreAboutFastTrackContainer>
      </Components.BottomSectionContainer>
    </Components.Container>
  );
};

export default MessageEnquiryFeedbackWithFastTrackInfoSwipingSeekerExperience;
