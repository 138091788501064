export const VISIT_ACTIONS = {
  CANCEL: "cancel",
  CONFIRM: "confirm",
  RESCHEDULE: "reschedule",
  VIDEO_VISIT: "join_video_visit",
};

export const VISIT_FEEDBACK_STATUS = {
  INTERESTED: 1,
  UNINTERESTED: 2,
};

export const VISIT_STATUS = {
  REQUESTED: 1,
  PROPOSED: 2,
  ACCEPTED: 3,
  REJECTED: 4,
  CANCELLED: 5,
  FINISHED: 6,
};
