import { BREAKPOINTS_PX } from "@badi/badi-components";

export const STEPS_SEEKER = [
  {
    step: 1,
    title: "booking.popup_first_payment",
    description: "booking.popup_first_payment_desc_seeker",
  },
  {
    step: 2,
    title: "booking.popup_service_fee",
    description: "booking.popup_service_fee_desc",
  },
  {
    step: 3,
    title: "booking.popup_other_payments",
    description: "booking.popup_other_payments_desc",
  },
];

export const STEPS_LISTER = [
  {
    step: 1,
    title: "booking.popup_first_payment",
    description: "booking.popup_first_payment_desc_lister",
  },
  {
    step: 2,
    title: "booking.popup_service_fee",
    description: "booking.popup_service_fee_desc",
  },
  {
    step: 3,
    title: "booking.popup_other_payments",
    description: "booking.popup_other_payments_desc",
  },
];

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.md})`];
