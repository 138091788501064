import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import { getStayOptions } from "datamodel/SearchFilters/utils";
import SearchBarHome from "./search-bar-home";

const mapStateToProps = (state) => ({
  stayOptions: getStayOptions(),
});

const mapDispatchToProps = (dispatch) => ({
  setParams: (searchFilters) =>
    dispatch(
      Actions.setParams({
        searchFilters: { ...searchFilters, updatedFilters: true },
      }),
    ),
  setBoundsRecalculation: () => {
    dispatch(Actions.setBoundsRecalculation(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarHome);
