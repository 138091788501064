import React from "react";
import { Label } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const MatchProfile = () => (
  <Label size="small" variant="general-inverted">
    {translate("trust_items.match_profile_label")}
  </Label>
);

export default MatchProfile;
