import React, { useMemo } from "react";
import {
  interpolateQueryParam,
  HEADER_PARAMS,
} from "base/shared/tracking-headers";

import { Text, Flex, Spacer } from "@badi/badi-components";

import { getRelativePath } from "base/shared/Utils";
import { browserHistory } from "react-router";

import { Root, Image, TextWrapper } from "./styled-components";

const RoomDetailBadge = ({
  id = 0,
  title = "",
  address = "",
  picture = "",
  clickable = true,
  clearRoom = () => {},
  currentRoomId,
}) => {
  const {
    keyVal,
    options: { INBOX },
  } = HEADER_PARAMS.ROOM_VIEW_SOURCE;
  const path = getRelativePath(`/room/${id}`);
  const parsedUrl = interpolateQueryParam(path, keyVal, INBOX);

  const handleShowRoomDetail = clickable
    ? () => {
        if (currentRoomId !== id) clearRoom();
        browserHistory.push(parsedUrl);
      }
    : null;

  const buttonProps = useMemo(() => {
    return clickable
      ? {
          role: "button",
          tabIndex: 0,
        }
      : {};
  }, [clickable]);
  return (
    <Root clickable={clickable} onClick={handleShowRoomDetail} {...buttonProps}>
      <Flex justify="spaceBetween">
        <Spacer right={3}>
          <TextWrapper>
            <Text body={2}>{title}</Text>
          </TextWrapper>
          <Text body={3}>{address}</Text>
        </Spacer>
        <Image alt={title} src={picture} />
      </Flex>
    </Root>
  );
};

export default RoomDetailBadge;
