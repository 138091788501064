import { ERROR_CODES } from "./constants";

export const getLocationError = (error) => {
  const status = {
    code: ERROR_CODES.UNKNOWN_ERROR,
    message: "search.location.options.nearby.unknown",
  };

  if (!error) return status;

  switch (error.code) {
    case error.PERMISSION_DENIED:
      status.code = ERROR_CODES.PERMISSION_DENIED;
      status.message = "search.location.options.nearby.denied";
      break;
    case error.POSITION_UNAVAILABLE:
      status.code = ERROR_CODES.POSITION_UNAVAILABLE;
      status.message = "search.location.options.nearby.unavailable";
      break;
    case error.TIMEOUT:
      status.code = ERROR_CODES.TIMEOUT;
      status.message = "search.location.options.nearby.timeout";
      break;
    case error.NOT_SUPPORTED:
      status.code = ERROR_CODES.NOT_SUPPORTED;
      status.message = "search.location.options.nearby.notsupported";
      break;
    default:
  }

  return status;
};

export const getGeoLocation = ({ onSuccess, onError }) => {
  if (!navigator.geolocation) {
    const error = getLocationError({
      code: ERROR_CODES.NOT_SUPPORTED,
    });

    onError(error);
    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords;
      onSuccess({ latitude, longitude });
    },
    (error) => {
      const locationError = getLocationError(error);
      onError(locationError);
    },
    {
      timeout: 10000,
    },
  );
};
