import styled, { css } from "styled-components";
import { unitizedPx, BREAKPOINTS_PX } from "@badi/badi-components";

export const Root = styled.div`
  position: relative;
  width: 100%;
  min-width: ${unitizedPx(32.5)};
  padding: ${unitizedPx(2)} 0;
  border-radius: 10px;
  box-shadow: 0 ${unitizedPx(1)} ${unitizedPx(2)} 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
`;

export const StyledImage = styled.img`
  border-radius: ${(props) =>
    props.alt === "seeker-avatar" ? css`50%` : css`5px`};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SeekerBarInfo = styled.div`
  font-size: 0.93rem;
  font-weight: 700;
  cursor: pointer;
  ${(props) =>
    props.variation === "menu"
      ? css`
          width: 90%;
          z-index: inherit;
        `
      : css`
          position: absolute;
          bottom: 0px;
          @media screen and (max-width: ${BREAKPOINTS_PX.lg}) {
            display: none;
          }
        `}
`;

export const SeekerBarAvatarWrapper = styled.div`
  width: ${unitizedPx(4)};
  height: ${unitizedPx(4)};
  margin-right: ${unitizedPx(2)};
`;

export const FilterLayerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;
