import * as CookiesHandler from "base/shared/CookiesHandler";
import badiPushNotifications from "lib/badi-push-notifications";
import UserFeedback from "base/shared/user-feedback";

const removeCookiesAtLogout = () => {
  CookiesHandler.removeCookie("badi_recos_tips_shown");
};

export const stopPushNotificationsAndLogout = async (successAction, next) => {
  const permissionGranted = Notification.permission === "granted";
  if (permissionGranted) {
    try {
      await badiPushNotifications.stopPushNotifications();
      next(successAction);
      removeCookiesAtLogout();
    } catch (error) {
      UserFeedback.exception(
        "error.logout_unexpected_title",
        "error.logout_unexpected_message",
        error,
      );
    }
  } else {
    next(successAction);
    removeCookiesAtLogout();
  }
};
