import React from "react";
import { BADI_COLORS, Flex, Heading, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { Root } from "./styled-components";

const FormBlock = ({
  children = null,
  dataQa = "form-block",
  color = BADI_COLORS.NEUTRAL.NEUTRAL_80,
  error = null,
  title = null,
  saved = false,
  subTitle = null,
  valid = true,
  isRequired = false,
  disabled = false,
}) => {
  const requiredSymbol = String.fromCharCode(160, 42);
  return (
    <Root
      data-error={error}
      data-qa={dataQa}
      data-success={translate("formblock.message.save")}
      disabled={disabled}
      error={!valid || !!error}
      saved={saved && valid}
    >
      <Flex direction="column" gutter={2}>
        {title && (
          <Heading color={color} data-qa={`${dataQa}-title`} level="20-Bold">
            {title}
            {isRequired ? requiredSymbol : ""}
          </Heading>
        )}
        {subTitle && (
          <Text body="14-Regular" color={color} dataQa={`${dataQa}-subtitle`}>
            {subTitle}
          </Text>
        )}
        {children}
      </Flex>
    </Root>
  );
};

export default FormBlock;
