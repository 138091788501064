import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import FlatmatesForm from "./flatmates-form";

const isAvailable = (placeTypes) => {
  const selectedPlaceType =
    placeTypes.find((placeType) => placeType.selected) || {};

  if (selectedPlaceType.id === 2) return false;

  return true;
};

export default connect(
  (state) => ({
    genders: state.SearchFilters.genders,
    tenants: state.SearchFilters.tenants,
    available: isAvailable(state.SearchFilters.placeTypes),
  }),
  (dispatch) => ({
    setFlatmates: (genders, tenants) =>
      dispatch(Actions.setFlatmates({ genders, tenants })),
  }),
)(FlatmatesForm);
