import { connect } from "react-redux";

import {
  getConnectionId,
  getMessageSentError,
  isSendingMessage,
  isMessagingAllowed,
} from "datamodel/Conversation/selectors";
import { getDefaultPicture, getNameSelector } from "datamodel/User/selectors";
import Actions from "datamodel/Conversation/actions";
import { getUserRole } from "datamodel/Room/selectors";

import ChatInput from "./input";

const mapStateToProps = (state) => ({
  connectionId: getConnectionId(state),
  enabled: isMessagingAllowed(state),
  error: getMessageSentError(state),
  fetching: isSendingMessage(state),
  token: state.Auth.access_token,
  userName: getNameSelector(state),
  userPicture: getDefaultPicture(state),
  userRole: getUserRole(state),
});

const mapDispatchToProps = {
  sendMessage: Actions.newChatMessage,
};

const mergeProps = (state, dispatch, ownProps) => {
  const { connectionId, ...stateProps } = state;

  return {
    ...stateProps,
    ...ownProps,
    sendMessage: (type, content) =>
      dispatch.sendMessage(type, content, connectionId, state.token),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ChatInput);
