import styled from "styled-components";
import { unitizedPx, colors } from "@badi/badi-components";
import { BADI_COLORS } from "app/badi-components";
import { transparentize } from "polished";

export const MessageMeta = styled.div`
  margin-top: 1rem;
`;

const fastTrackMessageBackgroundColor = transparentize(
  0.4,
  BADI_COLORS.ADDITIONAL.ADDITIONAL_06,
);

export const StyledMessage = styled.div`
  overflow: hidden;
  border-radius: ${unitizedPx(1)};
  border-bottom-left-radius: ${(props) =>
    !props.isCurrentUser ? "0" : unitizedPx(1)};
  border-bottom-right-radius: ${(props) =>
    props.isCurrentUser ? "0" : unitizedPx(1)};

  background-color: ${({ isCurrentUser, isFastTrack }) => {
    if (isCurrentUser) return "#d0f4e8";
    if (isFastTrack) return fastTrackMessageBackgroundColor;
    return colors.lightGrey;
  }};
`;

export const TextWrapper = styled.div`
  word-break: break-word;
`;
