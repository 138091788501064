export const PLANS_CATEGORIES = {
  STANDALONE_BOOSTING: "standalone_boosting",
  UPSELL_BADI_PLUS: "upsell_badi_plus",
  MATCHING: "badi_matching",
  SEEKER_PREMIUM: "seeker_premium",
};

export const PLAN_FEATURES = {
  ACCEPT_MATCH: "accept_match",
  RECOMMENDED_SEEKERS: "recommended_seekers",
  ROOM_VIEWERS: "room_viewers",
  BOOSTING: "1x_free_boosting",
};
