import CountryVat from "./country-vat-model";

const countryVat = new CountryVat().toJSON();

const CountryVatModel = {
  fetching: false,
  error: null,
  ...countryVat,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.CountryVat) {
    return {
      ...CountryVatModel,
      error: "There was an error in CountryVatModel.",
    };
  }
  return state;
}

export { CountryVatModel, setInitialState };
