import React, { useMemo, useState } from "react";
import { Spacer } from "@badi/badi-components";

import Select from "components/form-select";
import RadioButtonGroup from "components/Form/RadioButton/radio-button-group";

import { translate } from "base/shared/Localization";
import { getMoveInGroupOptions, getPartnerOptions, isNotAlone } from "./utils";
import { MOVE_ALONE_ID, MOVE_WITH_SOMEONE_ID } from "./constants";

const MoveInGroup = ({ handleSaveSeekerPreference, moveInGroup = null }) => {
  const [moveInGroupValue, setMoveInGroupValue] = useState(
    isNotAlone(moveInGroup) ? MOVE_WITH_SOMEONE_ID : moveInGroup,
  );
  const [partner, setPartner] = useState(
    isNotAlone(moveInGroup) ? moveInGroup : "",
  );
  const moveInOptions = useMemo(getMoveInGroupOptions, []);
  const partnerOptions = useMemo(getPartnerOptions, []);

  const handleMoveInGroupChange = (selectedMoveInGroup) => {
    setMoveInGroupValue(selectedMoveInGroup);

    if (selectedMoveInGroup) {
      setPartner("");
      handleSaveSeekerPreference({ moveInGroup: selectedMoveInGroup });
    }
  };

  const handlePartnerChange = ({ value }) => {
    setPartner(value);
    handleSaveSeekerPreference({ moveInGroup: value });
  };

  const disabled =
    moveInGroupValue === null || moveInGroupValue === MOVE_ALONE_ID;
  const required = moveInGroupValue === MOVE_WITH_SOMEONE_ID;

  return (
    <div data-qa="seeker-preferences-move-in-group">
      <RadioButtonGroup
        initialValue={moveInGroupValue}
        items={moveInOptions}
        name="move-in-group"
        onChangeCallback={handleMoveInGroupChange}
        required={true}
      />
      <Spacer top={3} />
      <Select
        disabled={disabled}
        initialValue={partner}
        name="move-in-group-partner"
        onChange={handlePartnerChange}
        options={partnerOptions}
        placeholder={translate("onboarding.preferences.seeker_who_with")}
        required={required}
      />
    </div>
  );
};

export default MoveInGroup;
