import { combineReducers } from "redux";

import Auth from "datamodel/Auth/reducers";
import CountryVat from "datamodel/CountryVat/reducers";
import Device from "datamodel/Device/reducers";
import Features from "datamodel/Features/reducers";
import Inbox from "datamodel/Inbox/reducers";
import InboxConnection from "datamodel/Conversation/reducers";
import Localization from "datamodel/Localization/reducers";
import Modals from "datamodel/Modals/reducers";
import Notifications from "datamodel/Notifications/reducers";
import Room from "datamodel/Room/reducers";
import RoomList from "datamodel/RoomList/reducers";
import SearchRoomsResult from "datamodel/search-rooms/reducers";
import Socket from "datamodel/Socket/reducers";
import User from "datamodel/User/reducers";
import Payouts from "datamodel/Payouts/reducers";
import Payment from "datamodel/Payment/reducers";
import Home from "datamodel/Home/reducers";
import Requirements from "datamodel/Requirements/reducers";
import MyListings from "datamodel/MyListings/reducers";
import Recommendations from "datamodel/Recommendations/reducers";
import SearchFilters from "datamodel/SearchFilters/reducers";
import Overview from "datamodel/Overview/reducers";
import ListerMetrics from "datamodel/Analytics/reducers";
import Premium from "datamodel/premium/reducers";
import Reviews from "datamodel/reviews/reducers";
import Transactions from "datamodel/Transactions/reducers";
import Viewers from "datamodel/viewers/reducers";
import Referrals from "datamodel/referrals/reducers";

export default combineReducers({
  Auth,
  CountryVat,
  Device,
  Features,
  Home,
  Inbox,
  InboxConnection,
  Localization,
  Modals,
  MyListings,
  Notifications,
  Payment,
  Payouts,
  Requirements,
  Room,
  RoomList,
  SearchRoomsResult,
  Socket,
  User,
  SearchFilters,
  Overview,
  Recommendations,
  ListerMetrics,
  Premium,
  Reviews,
  Transactions,
  Viewers,
  Referrals,
});
