import { connect } from "react-redux";

import Actions from "datamodel/User/actions";
import { consentListSelector } from "datamodel/User/selectors";

import { getAuthMethod } from "datamodel/Auth/selectors";

import Gdpr from "./gdpr";

const mapStateToProps = (state) => ({
  authMethod: getAuthMethod(state),
  consentListNames: consentListSelector(state),
});

const mapDispatchToProps = {
  updateLegal: Actions.updateLegal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gdpr);
