import React from "react";
import classNames from "classnames";

import "./TextButtonExt.css";

const TextButton = ({ children, className, name, onClick, dataQa }) => (
  <button
    className={classNames("Text__button", className)}
    data-qa={dataQa}
    name={name}
    onClick={onClick}
  >
    {children}
  </button>
);

TextButton.defaultProps = {
  className: null,
  dataQa: "",
  name: undefined,
};

export default TextButton;
