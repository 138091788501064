import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl, getDomain } from "base/shared/Utils";
import { trackSignUp } from "../tracking";

const authenticate = () => {
  const { BADI_SERVER_AUTH_URL, BADI_AUTH_SERVICE } = envVars();
  const serverUrl = getServerUrl(
    BADI_AUTH_SERVICE,
    BADI_SERVER_AUTH_URL || getDomain(),
  );

  return fetch(serverUrl, {}).then((res) => res);
};

const login = (params) => {
  const { BADI_LOGIN_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_LOGIN_SERVICE, getDomain());

  return fetch(serverUrl, { method: "POST", params }).then((res) => res);
};

const register = (params) => {
  const { BADI_REGISTER_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_REGISTER_SERVICE, getDomain());

  return fetch(serverUrl, { method: "POST", params }).then((res) => {
    trackSignUp();
    return res;
  });
};

const refreshTokenServer = (token) => {
  const { BADI_REFRESH_TOKEN_SERVICE, BADI_SERVER_AUTH_URL } = envVars();
  const serverUrl = getServerUrl(
    BADI_REFRESH_TOKEN_SERVICE,
    BADI_SERVER_AUTH_URL,
  );
  const params = { refresh_token: token };

  return { serverUrl, params };
};

const refreshTokenClient = (token) => {
  const { BADI_AUTH_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_AUTH_SERVICE, getDomain());
  const params = { refreshToken: token };

  return { serverUrl, params };
};

const refreshToken = ({ refreshToken: token }, ssr = false) => {
  const { serverUrl, params } = ssr
    ? refreshTokenServer(token)
    : refreshTokenClient(token);

  return fetch(serverUrl, {
    method: "POST",
    params,
  });
};

const reset = (params) => {
  const { BADI_API_URL, BADI_RESET_PASSWORD_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_RESET_PASSWORD_SERVICE, BADI_API_URL);

  return fetch(serverUrl, { method: "POST", params }).then((res) => res);
};

const tokenInfo = (token) => {
  const { BADI_API_URL, BADI_AUTH_STATUS_URL } = envVars();
  const serverUrl = getServerUrl(BADI_AUTH_STATUS_URL, BADI_API_URL);

  return fetch(serverUrl, { token });
};

export { authenticate, login, register, refreshToken, reset, tokenInfo };
