import React from "react";
import classNames from "classnames";
import { Link } from "react-router";

import { translate } from "base/shared/Localization";

const LinkButton = ({ className, light, onClick, text, to }) => {
  const LinkElem = to.startsWith("/") ? Link : "a";

  return (
    <LinkElem
      className={classNames(
        "Link__button",
        { Button__light: light },
        className,
      )}
      onClick={onClick}
      to={to}
    >
      {translate(text)}
    </LinkElem>
  );
};

LinkButton.defaultProps = {
  className: null,
  light: true,
  onClick: () => {},
};

export default LinkButton;
