import { connect } from "react-redux";

import RoomListActions from "datamodel/RoomList/actions";
import { publishRoom, updateRoom } from "datamodel/Room/api";
import { getToken } from "datamodel/Auth/selectors";
import {
  getRoomIdSelector,
  getRoomStatusSelector,
  getRoomFieldsSelector,
  getRoomOwnedSelector,
} from "datamodel/RoomList/selectors";
import { ROOM_STATUS } from "datamodel/Room/constants";

import EditRoom from "./edit-room";

const mapStateToProps = (state) => ({
  roomId: getRoomIdSelector(state),
  token: getToken(state),
  roomStatus: getRoomStatusSelector(state),
  isInReview: getRoomStatusSelector(state) === ROOM_STATUS.PENDING,
  fields: getRoomFieldsSelector(state),
  owned: getRoomOwnedSelector(state),
});

const mapDispatchToProps = {
  resetRoomList: RoomListActions.resetRoomList,
  getRoom: RoomListActions.getRoom,
};

const mergeProps = (state, dispatch, ownProps) => ({
  ...state,
  ...dispatch,
  ...ownProps,
  getRoom: (roomId) => dispatch.getRoom({ token: state.token, roomId }),
  publishRoom: (roomId) => publishRoom(state.token, { id: roomId }),
  updateRoom: (roomId, params) => updateRoom(state.token, params, roomId),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(EditRoom);
