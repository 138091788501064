import React, { Suspense } from "react";
import UserFeedback from "base/shared/user-feedback";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";

import "./FastTrackEnquiryModalExt.css";

export const closeFastTrackModal = () => {
  modal.remove(MODAL_ID.FAST_TRACK_INFORMATION);
};

export const openFastTrackInformationModal = ({ id }) => {
  try {
    const FastTrackInformation = React.lazy(
      () => import("./fast-track-information-mapping.jsx"),
    );
    modal.add(
      <Suspense fallback={<div />}>
        <FastTrackInformation roomId={id} />
      </Suspense>,
      {
        id: MODAL_ID.FAST_TRACK_INFORMATION,
        type: MODAL_TYPE.LATERAL,
        onClose: () => closeFastTrackModal(),
        mainClassName: "fast_track_modal",
      },
    );
  } catch (e) {
    UserFeedback.exception("Something went wrong", e.message, e);
  }
};
