import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { parsePagination } from "./utils";
import FavoriteRoomModel from "./favorite-model";

export const addFavorite = (token, roomId) => {
  const { BADI_FAVORITES } = envVars();
  const serverUrl = getServerUrl(BADI_FAVORITES);
  return fetch(serverUrl, {
    params: { room_id: roomId },
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const removeFavorite = (token, roomId) => {
  const { BADI_FAVORITES } = envVars();
  const serverUrl = getServerUrl(BADI_FAVORITES);
  return fetch(serverUrl, {
    params: { room_id: roomId },
    requiredParams: true,
    method: "DELETE",
    token,
  });
};

export const getFavorites = ({ token, page = 1 }) => {
  const { BADI_FAVORITES } = envVars();
  const serverUrl = getServerUrl(BADI_FAVORITES);
  const params = { page };
  return fetch(serverUrl, {
    params,
    token,
  }).then((res) => {
    const favorites = res.data.rooms.map((room) =>
      new FavoriteRoomModel(room).toJSON(),
    );
    const pagination = parsePagination(res.pagination);
    return { favorites, pagination };
  });
};
