import React from "react";
import { BADI_COLORS } from "@badi/badi-components";

const Padlock = ({
  color = BADI_COLORS.NEUTRAL.NEUTRAL_80,
  height = 24,
  width = 24,
  customStyles = null,
}) => {
  return (
    <svg
      height={height}
      style={customStyles}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2c2.704 0 4.886 2.277 4.996 5.09l.004.223V8a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4v-6a4 4 0 0 1 4-4h.002L7 7.953v-.64C7 4.397 9.225 2 12 2Zm5 8H7a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2Zm-5 1c.547 0 1.081.11 1.575.322a1 1 0 0 1-.678 1.878l-.11-.04a2 2 0 1 0 1.208 1.989L14 15a1 1 0 0 1 2 0 4 4 0 1 1-4-4Zm0-7c-1.58 0-2.906 1.371-2.995 3.123L9 7.313v.64L8.997 8H15v-.688C15 5.471 13.636 4 12 4Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Padlock;
