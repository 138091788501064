import { BADI_COLORS } from "@badi/badi-components";
import { TOP_BANNER_TYPES } from "./constants";

export const getButtonVariant = (type) => {
  switch (type) {
    case TOP_BANNER_TYPES.PREMIUM:
      return "premium-secondary";
    default:
      return "primary-inverted";
  }
};

export const getTextColor = (type) => {
  switch (type) {
    case TOP_BANNER_TYPES.PREMIUM:
      return BADI_COLORS.NEUTRAL.NEUTRAL_80;
    default:
      return BADI_COLORS.NEUTRAL.NEUTRAL_00;
  }
};
