import React, { PureComponent } from "react";
import classNames from "classnames";
import { Flex, Heading, Text, colors, Spacer } from "@badi/badi-components";
import LabelModel from "datamodel/Room/models/label-model";

import { getFormatedDate } from "Sections/Room/Detail/utils";
import { isPublishedThisWeek } from "datamodel/Room/utils";
import { translate } from "base/shared/Localization";

import Gallery from "components/Gallery";
import RoomConnections from "./RoomConnections";
import FastTrackRoomConnections from "./FastTrackRoomConnections";
import FavoriteToggleButton from "./components/favorite-toggle-button";
import PropertyTypeLabel from "./components/property-type-label";
import RoomAction from "./components/room-action";

import DiscardToggleButton from "./components/discard-toggle-button";
import { ButtonsWrapper } from "./styled-components";

class RoomDetailHeader extends PureComponent {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    const { error } = this.props;

    if (!error) {
      const header = document.getElementById("topbar");

      document.removeEventListener("scroll", this.onScroll);
      header.classList.remove("header_hide");
    }
  }

  onScroll = () => {
    if (!this.roomViewElem) return;

    const header = document.getElementById("topbar");
    const roomSlider = this.roomViewElem.clientHeight + header.clientHeight;
    const windscroll = window.scrollY;

    if (windscroll > roomSlider) {
      if (!this.state.scrolled) this.setState({ scrolled: true });
      header.classList.add("header_hide");
    } else {
      if (this.state.scrolled) this.setState({ scrolled: false });
      header.classList.remove("header_hide");
    }
  };

  render() {
    const {
      room,
      showPriceDiscount,
      token,
      areButtonsVisible,
      openFastTrackInformationModal,
      messageEnquiryFetched,
      userId,
      onEnquiryClick,
      standardEnquiriesCount,
      fastTrackRoomConnectionsCount,
    } = this.props;

    const {
      pictures,
      price: { displayText: priceText },
      previousPrice,
      publishedAt,
      title,
      favorited,
      discarded,
      labels,
    } = room;
    const { scrolled } = this.state;
    const isBadiHome = Boolean(
      labels.find(({ id }) => id === LabelModel.IDS.badi_homes),
    );

    const showPreviousPrice = showPriceDiscount && previousPrice;
    const formattedPublishedAt = getFormatedDate(
      publishedAt,
      "YYYY-MM-DD HH:mm",
    );
    const publishedText = isPublishedThisWeek(formattedPublishedAt)
      ? "Published this week"
      : "Over a week ago";
    const priceWithCurrency = `${priceText}/${translate("month")}`;

    return (
      <section className={classNames("room", { room_scrolled: scrolled })}>
        <div
          className="room__view"
          ref={(i) => {
            this.roomViewElem = i;
          }}
        >
          {areButtonsVisible && (
            <ButtonsWrapper>
              <FavoriteToggleButton
                favorited={favorited}
                id={room.id}
                token={token}
              />
              <Spacer left={1} />
              <DiscardToggleButton
                discarded={discarded}
                id={room.id}
                token={token}
              />
            </ButtonsWrapper>
          )}
          <Gallery
            className="room__list"
            pictures={pictures}
            isBadiHome={isBadiHome}
          />
        </div>
        <div className="room__main">
          <div className="room__bar">
            <div className="room__inner">
              <div className="room__content">
                <div className="room__actions">
                  <RoomAction
                    messageEnquiryFetched={messageEnquiryFetched}
                    onEnquiryClick={onEnquiryClick}
                    openFastTrackInformationModal={
                      openFastTrackInformationModal
                    }
                    room={room}
                    userId={userId}
                    isBadiHome={isBadiHome}
                  />
                </div>
                <div className="room__text">
                  <Flex alignItems="flexStart" direction="column" gutter={1}>
                    <PropertyTypeLabel room={room} />
                    <div className="room__bar--line room__bar--line-responsive">
                      <h1 className="title_h2 room__title">{title}</h1>
                    </div>
                    <Flex gutter={1}>
                      {showPreviousPrice && (
                        <Text
                          color={colors.midGrey}
                          data-qa="room-details-previous-price"
                        >
                          <strike>{previousPrice}</strike>
                        </Text>
                      )}
                      <Heading data-qa="room-details-current-price" level={4}>
                        {priceWithCurrency}
                      </Heading>
                    </Flex>
                    <Text body={6}>
                      <time dateTime={formattedPublishedAt}>
                        {translate(publishedText)}
                      </time>
                    </Text>
                    {Number.isInteger(standardEnquiriesCount) && (
                      <RoomConnections connections={standardEnquiriesCount} />
                    )}
                    {Number.isInteger(fastTrackRoomConnectionsCount) && (
                      <FastTrackRoomConnections
                        connections={fastTrackRoomConnectionsCount}
                      />
                    )}
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

RoomDetailHeader.defaultProps = {
  error: {},
};

export default RoomDetailHeader;
