import React, { useState, useCallback, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { translate } from "base/shared/Localization";
import SearchModal from "components/search-modal";
import {
  Flex,
  Text,
  Icon,
  useMatchMedia,
  BADI_COLORS,
  Heading,
} from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";
import { getPlaceType } from "datamodel/SearchFilters/utils";
import { getSearchDefaultValues, getLastSearchLocation } from "./utils";
import { QUERIES, DATE_FORMAT } from "./constants";
import { SEARCH_TYPES } from "../../../../constants";
import {
  SearchBarLayer,
  SearchBarWrap,
  SearchButton,
  NavigationSearch,
  SearchMenuButton,
  SearchFields,
  SearchMinified,
  StyledFlex,
} from "./styled-components";
import LocationSelectorAutosuggest from "./components/location-selector-autosuggest";
import DateSelector from "./components/date-selector";
import LengthStaySelector from "./components/length-stay-selector";

const SearchBarHome = ({
  stayOptions,
  setParams,
  toggleSearchCallback,
  interscrollSearch = true,
  searchType,
  setSearchType,
  title,
}) => {
  const { moveInDate, lengthStay, stayOption } =
    getSearchDefaultValues(stayOptions);
  const [location, setLocation] = useState(null);
  const [fromDate, setMoveIn] = useState(moveInDate);
  const [stayOptionsSelected, setStayOptions] = useState(stayOption);
  const [showPopover, setShowPopover] = useState(false);
  const [isDesktop] = useMatchMedia(QUERIES);
  const [searchMinified, setSearchMinified] = useState(false);
  const [animations, setAnimations] = useState({});
  const [openMobileSearch, setOpenMobileSearch] = useState(false);

  const handleLocationChange = useCallback((suggest) => {
    const { label: text, meta } = suggest;
    const city = meta ? meta.city : "";
    setShowPopover(false);
    setLocation({ ...suggest, city, text });
  }, []);

  const handleMoveInDateChange = useCallback((moveInSelected) => {
    const date = moveInSelected ? moveInSelected.format(DATE_FORMAT) : null;
    setMoveIn(date);
  }, []);

  const handleLengthStayChange = useCallback((length) => {
    setStayOptions(length);
  }, []);

  const handleSearch = () => {
    if (searchMinified) {
      return;
    }
    if (!isDesktop) {
      setOpenMobileSearch(true);
      return;
    }
    if (!location) {
      setShowPopover(true);
    } else {
      const placeTypes = getPlaceType();
      const placeTypeSelectedId = searchType === SEARCH_TYPES.ENTIRE ? 2 : 1;

      const index = placeTypes.findIndex(
        ({ id }) => id === placeTypeSelectedId,
      );
      placeTypes[index] = { ...placeTypes[index], selected: true };

      setParams({
        ...location,
        fromDate,
        now: false,
        stayOptions: stayOptionsSelected,
        placeTypes,
      });
    }
  };

  const mobileSearchClose = () => {
    setOpenMobileSearch(false);
  };

  useEffect(() => {
    const suggest = getLastSearchLocation();
    if (!location && suggest) {
      handleLocationChange(suggest);
    }
  }, []);

  // ANIMATIONS

  const handleRefSearch = useRef(null);
  const handleRefNav = useRef(null);
  const searchBarWrapRef = useRef(null);
  const searchFieldsRef = useRef(null);
  const searchMinifiedRef = useRef(null);

  const openSearchAnimations = () => {
    animations.scale.reverse();
    animations.mainFields.reverse();
    if (animations.minifiedText) animations.minifiedText.reverse();
    gsap.to(handleRefSearch.current, {
      ease: "none",
      duration: 0.2,
      y: 23,
    });
    setSearchMinified(false);
  };

  const closeSearchAnimations = () => {
    animations.scale.play();
    animations.position.play();
    animations.mainFields.play();
    if (animations.minifiedText) animations.minifiedText.play();
    gsap.to(handleRefSearch.current, {
      ease: "none",
      duration: 0.2,
      y: -142,
    });
    setSearchMinified(true);
  };

  const searchBarClickHandler = () => {
    if (searchMinified) {
      openSearchAnimations();
      toggleSearchCallback();
    } else if (!isDesktop) {
      setOpenMobileSearch(true);
    }
  };

  useEffect(() => {
    if (interscrollSearch === false && !searchMinified) {
      closeSearchAnimations();
    }
  }, [interscrollSearch]);

  useEffect(() => {
    const tempAnimations = {};
    const startTrigger = "top top";
    gsap.registerPlugin(ScrollTrigger);
    tempAnimations.position = gsap.to(handleRefSearch.current, {
      ease: "none",
      duration: 0.2,
      y: -142,
      marginTop: 0,
      scrollTrigger: {
        trigger: "body",
        start: startTrigger,
        toggleActions: "play none none reverse",
        onEnter: () => {
          setSearchMinified(true);
        },
        onLeaveBack: () => {
          setSearchMinified(false);
        },
      },
    });

    tempAnimations.mainFields = gsap.to(
      [handleRefNav.current, searchFieldsRef.current],
      {
        ease: "none",
        duration: 0.2,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: "body",
          start: startTrigger,
          toggleActions: "play none none reverse",
        },
      },
    );

    tempAnimations.scale = gsap.to(searchBarWrapRef.current, {
      ease: "none",
      duration: 0.2,
      width: "300px",
      scale: isDesktop ? 0.8 : 0.6,
      marginLeft: isDesktop ? "-35vw" : "10vw",
      scrollTrigger: {
        trigger: "body",
        start: startTrigger,
        toggleActions: "play none none reverse",
      },
    });
    if (isDesktop) {
      tempAnimations.minifiedText = gsap.to(searchMinifiedRef.current, {
        ease: "none",
        duration: 0.2,
        autoAlpha: 1,
        scrollTrigger: {
          trigger: "body",
          start: startTrigger,
          toggleActions: "play none none reverse",
        },
      });
    }
    setAnimations(tempAnimations);
  }, []);

  // END ANIMATIONS

  const handleSearchRoomClick = () => {
    setSearchType(SEARCH_TYPES.ROOM);
  };

  const handleSearchApartmentClick = () => {
    setSearchType(SEARCH_TYPES.ENTIRE);
  };

  return (
    <SearchBarLayer ref={handleRefSearch}>
      <Flex
        alignItems="center"
        as={StyledFlex}
        direction="column"
        gutter={2}
        justify="center"
      >
        <Heading color={BADI_COLORS.NEUTRAL.NEUTRAL_00} align="center">
          {title}
        </Heading>
        <NavigationSearch ref={handleRefNav} wide={true}>
          <Flex alignItems="center" as={Flex} justify="center">
            <SearchMenuButton
              onClick={handleSearchRoomClick}
              selected={searchType === SEARCH_TYPES.ROOM}
            >
              <Text body="16-Regular" color={BADI_COLORS.NEUTRAL.NEUTRAL_00}>
                {translate("topbar.element.search.room")}
              </Text>
            </SearchMenuButton>
            <SearchMenuButton
              onClick={handleSearchApartmentClick}
              selected={searchType === SEARCH_TYPES.ENTIRE}
            >
              <Text body="16-Regular" color={BADI_COLORS.NEUTRAL.NEUTRAL_00}>
                {translate("topbar.element.search.entire")}
              </Text>
            </SearchMenuButton>
          </Flex>
        </NavigationSearch>
        <SearchBarWrap
          onClick={searchBarClickHandler}
          ref={searchBarWrapRef}
          searchMinified={!isDesktop || searchMinified}
        >
          <SearchFields ref={searchFieldsRef}>
            <Flex alignItems="center" gutter={1}>
              <LocationSelectorAutosuggest
                grow={1}
                handleLocationChange={handleLocationChange}
                initialValue={location ? location.text : ""}
                showPopover={showPopover}
              />
              <DateSelector
                fromDate={fromDate}
                grow={1}
                handleMoveInDateChange={handleMoveInDateChange}
              />
              <LengthStaySelector
                grow={1}
                id="length-stay-selector"
                initialStay={lengthStay}
                onChange={handleLengthStayChange}
                stayOptions={stayOptions}
              />
            </Flex>
          </SearchFields>

          <SearchMinified ref={searchMinifiedRef}>
            <Text body="16-Regular" color={BADI_COLORS.NEUTRAL.NEUTRAL_80}>
              {translate("topbar.element.search.mini")}
            </Text>
          </SearchMinified>
          <SearchButton
            data-qa="search-bar-availability-button"
            onClick={handleSearch}
          >
            <Icon
              alt="search"
              iconMaxHeight={42}
              iconMaxWidth={42}
              src={getPublicAsset("icons/home/search_btn.svg")}
            />
          </SearchButton>
        </SearchBarWrap>
        {!isDesktop && (
          <SearchModal
            onCloseModal={mobileSearchClose}
            onOpenModal={openMobileSearch}
            searchType={searchType}
          >
            <div />
          </SearchModal>
        )}
      </Flex>
    </SearchBarLayer>
  );
};

export default SearchBarHome;
