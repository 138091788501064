import Cookies from "universal-cookie";

const existsCookie = (name) => {
  const cookies = new Cookies();
  return !!cookies.get(name);
};

const getCookie = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

const getCookies = (names) =>
  names.reduce((prev, current) => {
    return {
      ...prev,
      [current]: getCookie(current),
    };
  }, {});

const setCookie = (name, val, expires) => {
  if (val) {
    const value =
      typeof val === "string" ? val : val.reduce((v, vnext) => `${v}|${vnext}`);
    const cookies = new Cookies();
    if (expires) {
      cookies.set(name, value, {
        path: "/",
        expires: new Date(expires),
        sameSite: "lax",
      });
    } else {
      cookies.set(name, value, { path: "/", sameSite: "lax" });
    }
  }
};

const removeCookie = (name) => {
  const cookies = new Cookies();
  cookies.remove(name, { path: "/" });
};

const removeAllCookies = () => {
  const cookies = new Cookies();
  Object.keys(cookies.getAll()).forEach((cookie) => removeCookie(cookie));
};

export {
  existsCookie,
  getCookie,
  getCookies,
  removeCookie,
  removeAllCookies,
  setCookie,
};
