import React, { useState } from "react";
import { Flex, Heading, FilterButton } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { CATEGORIES, DEFAULT_CATEGORY_VALUE } from "datamodel/Inbox/constants";
import Select from "components/form-select";
import InboxHelpButton from "./help-icon";
import { FilterWrapper } from "./styled-components";

const Header = ({
  defaultCategory = null,
  disabled,
  onChangeCategory,
  onFilterClick,
  onCancelFilter,
  roomTitle = null,
  showFilterRoom,
}) => {
  const [category, setCategory] = useState("");

  const categories = translate(CATEGORIES, "label");

  const handleCategoryChange = ({ value }) => {
    setCategory(value);
    onChangeCategory(value);
  };

  return (
    <Flex direction="column" gutter={3}>
      <Flex
        direction="column"
        gutter={2}
        sm={{
          direction: "row",
          justify: "spaceBetween",
          gutter: 0,
        }}
      >
        <Flex gutter={2} justify="spaceBetween">
          <Heading data-qa="inbox-title" level="28-Extrabold">
            {translate("inbox.title")}
          </Heading>
          <InboxHelpButton alignself="center" />
        </Flex>
        <Flex alignItems="center" gutter={1}>
          <FilterWrapper>
            <Select
              disabled={disabled}
              initialValue={defaultCategory || DEFAULT_CATEGORY_VALUE}
              name="categories"
              onChange={handleCategoryChange}
              options={categories}
              value={category}
            />
          </FilterWrapper>
          {showFilterRoom && (
            <FilterButton
              icon={getPublicAsset("icons/bed_16.svg")}
              id="filter_by_room"
              onCancelFilter={onCancelFilter}
              onFilterClick={onFilterClick}
              title={translate("inbox.dropdown_all")}
              value={roomTitle}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
