import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { getCapabilities, getRoomCapabilities, getPlans } from "./api";

export const getPremiumPlans = () => ({
  types: generateFetchTypes(Types.PREMIUM_PLANS_REQUEST),
  request: getPlans,
  requireAuth: true,
});

export const getPremiumCapabilities = () => ({
  types: generateFetchTypes(Types.PREMIUM_CAPABILITIES_REQUEST),
  request: getCapabilities,
  requireAuth: true,
});

export const getPremiumRoomCapabilities = ({ roomId }) => ({
  types: generateFetchTypes(Types.PREMIUM_ROOM_CAPABILITIES_REQUEST),
  request: getRoomCapabilities,
  params: { roomId },
  requireAuth: true,
});

export const setPremiumOrigin = (pathOrigin) => ({
  type: Types.PREMIUM_ORIGIN_SET,
  result: pathOrigin,
});
