import React, { useCallback } from "react";
import { withRouter } from "react-router";
import { Badge, Text, BADI_COLORS, Spacer } from "@badi/badi-components";

import { usePremium, PremiumLabel } from "modules/premium";
import { PREMIUM_FEATURES } from "app/datamodel/premium/constants";

import Image from "components/Image/Image";
import { OverviewAction, ActionLabelWrapper } from "../styled-components";

const RECOMMENDED_TENANTS_ID = "active_recommendations";
const isRecommendedTenantsAction = (actionId) =>
  actionId === RECOMMENDED_TENANTS_ID ||
  actionId === "non_active_recommendations";

const isViewersAction = (actionId) =>
  actionId === "active_room_viewers" || actionId === "non_active_room_viewers";
const ANALYTICS_ID = "analytics";

const isAnalyticsAction = (actionId) => actionId === ANALYTICS_ID;

export const Action = ({
  focus = 0,
  heading,
  subheading = "",
  actionId,
  value = 0,
  nextStepPath = "",
  clickable,
  roomId,
  router,
  dataQa,
}) => {
  const getPremiumActionInfo = () => {
    if (isRecommendedTenantsAction(actionId)) {
      return usePremium({ capabilityId: PREMIUM_FEATURES.RECOMMENDED_SEEKERS });
    }
    if (isViewersAction(actionId)) {
      return usePremium({ capabilityId: PREMIUM_FEATURES.ROOM_VIEWERS });
    }
    return { premiumEnabled: false, isPremium: false };
  };

  const { premiumEnabled, isPremium } = getPremiumActionInfo();

  const handleOnClick = useCallback(() => {
    if (!clickable) return;

    if (nextStepPath) {
      router.push(nextStepPath);
    }
  }, [nextStepPath, roomId]);

  return (
    <OverviewAction
      clickable={clickable}
      data-qa={dataQa}
      onClick={handleOnClick}
    >
      <Spacer right={3}>
        <Image height={40} src={`/assets/icons/${actionId}.svg`} width={40} />
      </Spacer>
      <ActionLabelWrapper>
        {premiumEnabled && isPremium && <PremiumLabel bottom={1} />}
        <Text body={clickable ? 2 : 3} data-qa="overview-action-heading">
          {heading}
        </Text>
        {subheading && (
          <Text body="12-Regular" data-qa="overview-action-subheading">
            {subheading}
          </Text>
        )}
      </ActionLabelWrapper>
      {clickable && (
        <>
          {isAnalyticsAction(actionId) ? (
            <Text
              body="14-Bold"
              color={BADI_COLORS.SEMANTIC.INFO.DEFAULT}
              dataQa="overview-action-analytics-text"
            >
              {value}
            </Text>
          ) : (
            <Badge
              backgroundColor={BADI_COLORS.SEMANTIC.INFO.DEFAULT}
              rounded={true}
            >
              {value}
            </Badge>
          )}
        </>
      )}
    </OverviewAction>
  );
};

export default withRouter(Action);
