import React from "react";
import { BADI_COLORS, Flex, Text } from "@badi/badi-components";
import SuccessCheck from "components/Icons/SuccessCheck";

const Benefit = ({ children }) => {
  return (
    <Flex alignItems="center" gutter={1}>
      <SuccessCheck color="transparent" />
      <Text
        body="14-Regular"
        color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
        dataQa="about-badi-professionals-benefit"
      >
        {children}
      </Text>
    </Flex>
  );
};

export default Benefit;
