import React from "react";
import {
  Card,
  Flex,
  Heading,
  Spacer,
  Text,
  BADI_COLORS,
} from "@badi/badi-components";
import { Link as ReactRouterLink } from "react-router";
import { translate } from "base/shared/Localization";
import { getInboxPath } from "Sections/inbox/routes";

import { getTransactionsPath } from "../../routes";
import { Link, TransactionNum } from "./styled-components";
import { getTransactionsHeadingString } from "../../utils";

const TransactionsHeading = ({
  transactions: {
    total_amount: totalAmount,
    active_transactions: activeTransactions,
    pending_transactions: pendingTransactions,
  } = {},
}) => {
  const transactionsCompleted = activeTransactions > 0;
  const existingTransactions = transactionsCompleted || pendingTransactions > 0;
  const [transactionsText, transactionsCta] = getTransactionsHeadingString(
    transactionsCompleted,
    existingTransactions,
  );
  const cardLink =
    !transactionsCompleted && existingTransactions
      ? getInboxPath()
      : getTransactionsPath();

  return (
    <Link as={ReactRouterLink} to={cardLink}>
      <Card backgroundColor={BADI_COLORS.ADDITIONAL.ADDITIONAL_07}>
        <Spacer bottom={1.5} left={2} right={2} top={1.5}>
          <Heading
            data-qa="transaction-card-title"
            level="16-Bold"
            textColor={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
          >
            {translate("transactions.overview_empty_header")}
          </Heading>
          <Spacer bottom={1} />
          <Heading
            data-qa="transaction-card-amount"
            level="28-Extrabold"
            textColor={BADI_COLORS.SEMANTIC.INFO.DEFAULT}
          >
            {totalAmount}
          </Heading>
          <Spacer bottom={1} />
          <Flex justify="spaceBetween">
            <Text body="12-Regular" color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}>
              {translate(transactionsText)}
            </Text>
            <Flex alignItems="center">
              <Text body="12-Bold" color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}>
                {translate(transactionsCta)}
              </Text>
              {existingTransactions && (
                <TransactionNum
                  color={
                    transactionsCompleted
                      ? BADI_COLORS.SEMANTIC.INFO.DEFAULT
                      : BADI_COLORS.SEMANTIC.WARNING.DEFAULT
                  }
                >
                  <Text
                    body="10-Medium"
                    color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                    data-qa="transaction-card-count"
                  >
                    {transactionsCompleted
                      ? activeTransactions
                      : pendingTransactions}
                  </Text>
                </TransactionNum>
              )}
            </Flex>
          </Flex>
        </Spacer>
      </Card>
    </Link>
  );
};

export default TransactionsHeading;
