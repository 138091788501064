import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import SearchBar from "./search-bar";

const mergeStateToProps = (state) => ({
  bounds: state.SearchFilters.bounds,
  coordinates: state.SearchFilters.coordinates,
  placeId: state.SearchFilters.placeId,
  text: state.SearchFilters.text,
});

const mergeDispatchToProps = (dispatch) => ({
  setLocation: (location) => {
    dispatch(Actions.setLocation(location));
  },
  setBoundsRecalculation: () => {
    dispatch(Actions.setBoundsRecalculation(false));
  },
  setParams: (searchFilters, searchUpdate) => {
    dispatch(Actions.setParams({ searchFilters, searchUpdate }));
  },
});

export default connect(mergeStateToProps, mergeDispatchToProps)(SearchBar);
