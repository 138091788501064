import React from "react";
import { Link as ReactRouterLink } from "react-router";
import { isExternalLink } from "../utils";
import { Link } from "../styled-components";

const getProps = (isExternal, to) =>
  isExternal
    ? { href: to, target: "_blank" }
    : { as: ReactRouterLink, to: new URL(to).pathname };

const HintLink = ({ children, onClick, to }) => {
  const isExternal = isExternalLink(to);
  const linkProps = getProps(isExternal, to);
  return (
    <Link onClick={onClick} {...linkProps}>
      {children}
    </Link>
  );
};

export default HintLink;
