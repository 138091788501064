import React from "react";
import { translate } from "base/shared/Localization";
import { DEFAULT_USER_ROLE } from "datamodel/User/constants";
import { DEFAULT_EXPIRATION_HOURS } from "datamodel/booking/constants";
import {
  Flex,
  Heading,
  Spacer,
  StepCard,
  Text,
  colors,
} from "@badi/badi-components";
import { Anchor } from "components/booking-request-summary/styled-components";

import { getSteps, getTitle } from "./utils";

const StepsDescriptionCard = ({
  userRole = DEFAULT_USER_ROLE,
  expirationHours = DEFAULT_EXPIRATION_HOURS,
}) => {
  const steps = getSteps(userRole);
  const cardTitle = getTitle(userRole);

  return (
    <Spacer top={4}>
      <Flex direction="column" gutter={3}>
        <Heading data-qa="steps-description-heading" level={2}>
          {translate(cardTitle)}
        </Heading>
        {steps.map(({ step, title, description }) => (
          <StepCard
            description={translate(description, {
              expiration_time: expirationHours,
            })}
            key={`steps-description-${step}`}
            step={step}
            title={translate(title)}
          />
        ))}
        <Text
          body={5}
          color={colors.midGrey}
          data-qa="steps-description-learn-more"
        >
          {translate("booking_flow_learn_more")}
          <Anchor
            data-qa="steps-description-booking-policy"
            href={translate("booking_flow_learn_more_link")}
            rel="noopener noreferrer"
            target="_blank"
          >
            {translate("booking_flow_booking_policy")}
          </Anchor>
        </Text>
      </Flex>
    </Spacer>
  );
};

export default StepsDescriptionCard;
