import { connect } from "react-redux";

import RoomActions from "datamodel/RoomList/actions";
import {
  isAccountDetailsProfileSelector,
  isPaymentDetailsProfileSelector,
} from "datamodel/Features/selectors";

import { getUserRole, getGhost } from "datamodel/User/selectors";
import { isLister } from "datamodel/User/utils";
import { getToken } from "datamodel/Auth/selectors";
import {
  getRoomIdSelector,
  getRoomModerationResultSelector,
} from "datamodel/RoomList/selectors";
import FeaturesActions from "datamodel/Features/actions";
import UserActions from "app/datamodel/User/actions";

import ProfileSetup from "./ProfileSetup";

const mapStateToProps = (state) => ({
  isAccountDetailEnabled: isAccountDetailsProfileSelector(state),
  isPaymentDetailEnabled: isPaymentDetailsProfileSelector(state),
  isLister: isLister(getUserRole(state)),
  isGhost: getGhost(state),
  draftId: getRoomIdSelector(state),
  token: getToken(state),
  moderationResult: getRoomModerationResultSelector(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  return {
    ...stateProps,
    ...ownProps,
    getMe: () => dispatch(UserActions.getMe(stateProps.token)),
    setInitialStep: ({ initialStep }) =>
      dispatch(RoomActions.setInitialStep({ initialStep })),
    setLister: (value) => dispatch(UserActions.setLister(value)),
    resetRoomList: () => dispatch(RoomActions.resetRoomList()),
    getFeatures: () =>
      dispatch(
        FeaturesActions.getFeatures({
          token: stateProps.token,
          isLoggedUser: true,
        }),
      ),
  };
};

export default connect(mapStateToProps, null, mergeProps)(ProfileSetup);
