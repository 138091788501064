import React from "react";
import { withRouter } from "react-router";
import { getEditProfilePath } from "Sections/user-profile/routes";
import CompleteProfileBar from "./complete-profile-bar";

const CompleteProfileBarRoute = ({ router }) => {
  const handleClick = () => {
    router.push(getEditProfilePath());
  };
  return <CompleteProfileBar onClick={handleClick} />;
};

export default withRouter(CompleteProfileBarRoute);
