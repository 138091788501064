import createReducer from "base/shared/createReducer";
import types from "./types";
import { ReviewsModel } from "./models";

const pendingReviewsRequest = (state) => ({
  ...state,
  fetchingPendingReviews: true,
  fetchedPendingReviews: false,
  error: null,
});

const pendingReviewsSuccess = (state, action) => ({
  ...state,
  error: null,
  fetchingPendingReviews: false,
  fetchedPendingReviews: true,
  pendingReviews: action.result,
});

const pendingReviewsError = (state, action) => ({
  ...state,
  fetchingPendingReviews: false,
  fetchedPendingReviews: false,
  error: action.error,
});

const resetReviews = () => ({
  ...ReviewsModel,
});

const actionHandlers = {
  [types.REVIEWS_PENDING_REQUEST]: pendingReviewsRequest,
  [types.REVIEWS_PENDING_SUCCESS]: pendingReviewsSuccess,
  [types.REVIEWS_PENDING_ERROR]: pendingReviewsError,
  [types.RESET_REVIEWS]: resetReviews,
};

export default createReducer(actionHandlers, { ...ReviewsModel });
