import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl, removeUndefinedAndNullFields } from "base/shared/Utils";

import Visit from "./models/visit-model";
import VisiProposal from "./models/visit-proposal-model";

export const requestVisit = ({ token }, params) => {
  const { connectionId } = params;
  const { BADI_VISITS_REQUEST } = envVars();
  const serviceUrl = getAPIUrl(BADI_VISITS_REQUEST);

  const paramsForApi = {
    inbox_connection_id: connectionId,
  };

  return fetch(serviceUrl, {
    method: "POST",
    token,
    params: paramsForApi,
  }).then(({ data }) => {
    return new Visit(data).toJSON();
  });
};

export const proposeVisit = ({ token }, params) => {
  const {
    connectionId,
    express = false,
    localStartAt,
    roomId,
    seekerId,
    message,
    localEndAt,
    visitId,
  } = params;
  const { BADI_VISITS_PROPOSAL, BADI_VISITS_RESCHEDULE } = envVars();
  let apiUrl;
  switch (true) {
    case !!visitId:
      apiUrl = BADI_VISITS_RESCHEDULE;
      break;
    case !!connectionId:
      apiUrl = BADI_VISITS_PROPOSAL;
      break;
    default:
      throw new Error("Missing visitId and connectionId");
  }
  const serviceUrl = getAPIUrl(apiUrl);

  const paramsForApi = removeUndefinedAndNullFields({
    inbox_connection_id: connectionId,
    local_start_at: localStartAt,
    local_end_at: localEndAt,
    express,
    message: message || null,
    room_id: roomId,
    seeker_id: seekerId,
    visit_id: visitId,
  });

  return fetch(serviceUrl, {
    method: "POST",
    token,
    params: paramsForApi,
  }).then(({ data }) => {
    return new Visit(data).toJSON();
  });
};

export const getVisitRequest = ({ token }, params) => {
  const { id } = params;
  const { BADI_VISITS_VIEW } = envVars();
  const serviceUrl = getAPIUrl(BADI_VISITS_VIEW.replace(":id", id));

  return fetch(serviceUrl, {
    method: "GET",
    token,
  }).then(({ data }) => {
    return new Visit(data).toJSON();
  });
};

export const getInitialVisitData = ({ token }, params) => {
  const { connectionId, roomId, fromDate } = params;

  const { BADI_VISIT_PREFILL_INFO } = envVars();
  const serviceUrl = getAPIUrl(BADI_VISIT_PREFILL_INFO);

  const paramsForApi = removeUndefinedAndNullFields({
    from_date: fromDate,
    inbox_connection_id: connectionId,
    room_id: roomId,
  });

  return fetch(serviceUrl, {
    method: "GET",
    token,
    params: paramsForApi,
  }).then(({ data }) => {
    return new VisiProposal(data).toJSON();
  });
};

export const acceptVisitProposal = ({ token }, params) => {
  const { visitId } = params;

  const { BADI_VISITS_ACCEPT } = envVars();
  const serviceUrl = getAPIUrl(BADI_VISITS_ACCEPT.replace(":id", visitId));

  return fetch(serviceUrl, {
    method: "PUT",
    token,
  }).then(({ data }) => {
    return new Visit(data).toJSON();
  });
};

export const cancelVisitProposal = ({ token }, params) => {
  const { visitId } = params;

  const { BADI_VISITS_CANCEL } = envVars();
  const serviceUrl = getAPIUrl(BADI_VISITS_CANCEL.replace(":id", visitId));

  return fetch(serviceUrl, {
    method: "PUT",
    token,
  }).then(({ data }) => {
    return new Visit(data).toJSON();
  });
};

export const saveVisitFeedback = ({ token }, { visitId, outcome }) => {
  const { BADI_VISITS_FEEDBACK } = envVars();
  const serviceUrl = getAPIUrl(BADI_VISITS_FEEDBACK.replace(":id", visitId));

  return fetch(serviceUrl, {
    method: "POST",
    token,
    params: {
      outcome,
    },
  }).then(({ data }) => new Visit(data).toJSON());
};
