import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import {
  fetchOverviewSummary,
  fetchRoomsOverview,
  fetchListingStatuses,
} from "./api";

export default {
  getOverviewSummary(token) {
    return {
      types: generateFetchTypes(Types.OVERVIEW_SUMMARY_REQUEST),
      request: fetchOverviewSummary(token),
    };
  },
  getInitialRoomsOverview(token, params) {
    return {
      types: generateFetchTypes(Types.INITIAL_ROOMS_OVERVIEW_SUMMARY_REQUEST),
      request: fetchRoomsOverview(token, params),
    };
  },
  getNextRoomsOverview(token, params) {
    return {
      types: generateFetchTypes(Types.NEXT_ROOMS_OVERVIEW_SUMMARY_REQUEST),
      request: fetchRoomsOverview(token, params),
    };
  },
  getListingsStatuses(token) {
    return {
      types: generateFetchTypes(Types.OVERVIEW_LISTINGS_STATUSES_REQUEST),
      request: fetchListingStatuses(token),
    };
  },
  resetOverview() {
    return {
      type: Types.RESET_OVERVIEW,
    };
  },
};
