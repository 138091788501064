import React from "react";
import {
  Card,
  Flex,
  Heading,
  Spacer,
  Text,
  BADI_COLORS,
} from "@badi/badi-components";
import Image from "components/Image";
import { translate } from "base/shared/Localization";

const TransactionEmptyCard = () => {
  return (
    <Card backgroundColor={BADI_COLORS.ADDITIONAL.ADDITIONAL_07}>
      <Spacer bottom={3} left={2.5} right={2.5} top={3}>
        <Heading
          data-qa="transaction-empty-card-title"
          level="16-Bold"
          textColor={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
        >
          {translate("transactions.info_modal_title")}
        </Heading>
        <Spacer bottom={1.5} />
        <Text
          body="14-Medium"
          color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
          dataQa="transaction-empty-card-subtitle"
        >
          {translate("transactions.info_modal_subtitle")}
        </Text>
        <Spacer bottom={3} />
        <Flex alignItems="center">
          <Image src="/assets/images/transactions/transactions-spot.svg" />
          <Spacer right={2} />
          <Text
            body="14-Medium"
            color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
            dataQa="transaction-empty-card-bullet"
          >
            {translate("transactions.info_modal_bullet_1")}
          </Text>
        </Flex>
        <Spacer bottom={2} />
        <Flex alignItems="center">
          <Image src="/assets/images/transactions/transactions-room.svg" />
          <Spacer right={2} />
          <Text
            body="14-Medium"
            color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
            dataQa="transaction-empty-card-bullet"
          >
            {translate("transactions.info_modal_bullet_2")}
          </Text>
        </Flex>
        <Spacer bottom={2} />
        <Flex alignItems="center">
          <Image src="/assets/images/transactions/transactions-hand.svg" />
          <Spacer right={2} />
          <Text
            body="14-Medium"
            color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
            dataQa="transaction-empty-card-bullet"
          >
            {translate("transactions.info_modal_bullet_3")}
          </Text>
        </Flex>
      </Spacer>
    </Card>
  );
};

export default TransactionEmptyCard;
