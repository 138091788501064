import {
  getListerScore,
  getUserLanguages,
  getVerificationLevel,
} from "base/shared/User";
import { getOccupation } from "datamodel/Conversation/utils";

export default class BookingUser {
  constructor(data) {
    this.entity = data;
  }

  set model(data) {
    this.entity = data;
  }

  get id() {
    return this.entity.id;
  }

  get languages() {
    return getUserLanguages(this.entity.languages_by_affinity);
  }

  get occupation() {
    return getOccupation(this.entity.occupation_detail);
  }

  get age() {
    return this.entity.age;
  }

  get name() {
    return this.entity.first_name;
  }

  get badge() {
    try {
      return getVerificationLevel(this.entity.badges);
    } catch (error) {
      return "";
    }
  }

  get avatar() {
    return this.entity.cover_picture
      ? this.entity.cover_picture.width_100_url
      : null;
  }

  get email() {
    return this.entity.email;
  }

  get phone() {
    return this.entity.phone;
  }

  get listerScore() {
    return getListerScore(this.entity.trust);
  }

  toJSON() {
    return {
      languages: this.languages,
      occupation: this.occupation,
      age: this.age,
      id: this.id,
      name: this.name,
      avatar: this.avatar,
      badge: this.badge,
      email: this.email,
      phone: this.phone,
      listerScore: this.listerScore,
    };
  }
}
