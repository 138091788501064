import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: ${unitizedPx(8)};
  padding-bottom: ${unitizedPx(4)};
  background: white;
  z-index: 6;

  ${mediaquery.lg`
    top: ${unitizedPx(10)};
  `}
`;
