import React, { useState, useEffect } from "react";
import { routerEventsListenerInitiator } from "base/shared/tracking-headers";
import { getSearchUrl } from "datamodel/SearchFilters/utils";
import { getMainProfilePath } from "Sections/user-profile/routes";
import { getHomePath } from "Sections/Home/routes";

import SeekerPreferencesMapping from "./seeker-preferences-mapping";
import { NEXT_STEPS } from "./constants";

const SeekerPreferencesRoute = ({ router, location }) => {
  const { query } = location;
  const [nextStep, setNextStep] = useState(null);

  useEffect(() => {
    setNextStep(query.ns || null);
  }, [query]);

  const handleClose = () => {
    router.push(getHomePath());
  };

  const routerEventListener = routerEventsListenerInitiator(router);

  const handleNextStep = (props) => {
    if (nextStep === NEXT_STEPS.MAIN_PROFILE) {
      router.push(getMainProfilePath(getSearchUrl(props)));
    }
  };

  return (
    <SeekerPreferencesMapping
      onClose={handleClose}
      onNextStep={nextStep && handleNextStep}
      routerEventListener={routerEventListener}
    />
  );
};
export default SeekerPreferencesRoute;
