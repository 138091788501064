import styled, { css } from "styled-components";
import { colors } from "@badi/badi-components";

export const RootView = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  ${(props) =>
    props.left
      ? css`
          left: 0;
          padding-left: 16px;
          padding-right: 8px;
        `
      : css`
          right: 0;
          padding-right: 16px;
          padding-left: 8px;
        `};
`;

export const ViewWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${colors.white};
`;

export const GradientSeparator = styled.div`
  position: absolute;
  top: 0;
  ${({ left }) =>
    left
      ? css`
          left: -20px;
        `
      : css`
          right: -20px;
        `}
  z-index: 1;

  height: 100%;
  width: 20px;

  background: linear-gradient(
    ${({ left }) => (left ? "to left" : "to right")},
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
`;

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  border: none;
  background-color: inherit;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const FilterButtonLabel = styled.button`
  margin-left: 8px;
  text-transform: uppercase;
  font-weight: bold;
`;
