import styled, { css } from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const Screenshot = styled.div`
  margin-right: -20%;
  height: ${unitizedPx(29)};
  padding-top: ${unitizedPx(2)};

  > img {
    height: 100%;
    float: right;
    border-radius: ${unitizedPx(2)};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  }

  ${mediaquery.md`
    margin-right: -20%;
    height: ${unitizedPx(43)};
    padding-top: ${unitizedPx(5)};

    ${({ reverse }) =>
      reverse
        ? css`
            margin-right: initial;
            margin-left: -20%;
          `
        : css`
            margin-right: -20%;
          `}

    > img {
      float: ${({ reverse }) => (reverse ? "left" : "right")};
    }
  `}
`;

export const StepsWrapper = styled.div`
  ${mediaquery.md`
    max-width: ${unitizedPx(40)};
  `}

  ${mediaquery.lg`
    max-width: ${unitizedPx(46)};
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  background-image: url("${({ backgroundImage }) => backgroundImage}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 80%;
  height: ${unitizedPx(25)};
  background-position-y: center;
  border-radius: ${unitizedPx(2)};

  ${mediaquery.md`
    width: ${unitizedPx(37)};
  `}

  ${mediaquery.lg`
    width: ${unitizedPx(56)};
    height: 100%;
  `}

  ${mediaquery.xl`
    width: ${unitizedPx(75)};
    height: ${unitizedPx(46)};
  `}
`;
