import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Flex, Heading, Spacer, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Image from "components/Image/Image";

import { getConsents, parseToAPIObject, toggleConsent } from "./utils";
import ConsentCheckbox from "./components/consent-checkbox";

const Root = styled.div`
  overflow-y: scroll;
`;

const Gdpr = ({
  consentListNames = [],
  onResponse = () => {},
  updateLegal,
}) => {
  const [consentList, setConsentList] = useState(getConsents(consentListNames));

  const onChange = (event) => {
    const { id } = event.currentTarget;
    const updatedList = toggleConsent(consentList, id);

    setConsentList(updatedList);
  };

  const handleClick = () => {
    const legalConsent = parseToAPIObject(consentList);
    updateLegal(legalConsent);
    onResponse();
  };

  const isSaveEnabled = useMemo(
    () => consentList.some(({ required, accepted }) => required && !accepted),
    [consentList],
  );

  return (
    <Flex as={Root} direction="column" fullHeight={true} gutter={2}>
      <Spacer alignself="center" bottom={1} top={3}>
        <Image alt="GDPR" src="/assets/images/terms-and-conditions.svg" />
      </Spacer>
      <Heading align="center" data-qa="gdpr-heading">
        {translate("settings.privacy.termsandcond_title")}
      </Heading>
      <Text data-qa="gdpr-description">
        {translate("settings.privacy.termsandcond_text")}
      </Text>
      <Spacer bottom={0} grow={1} md={{ bottom: 4, top: 2 }} top={2}>
        <Flex direction="column" gutter={2}>
          {consentList.map(({ id, label, link, accepted }) => (
            <ConsentCheckbox
              accepted={accepted}
              id={id}
              key={id}
              label={label}
              link={link}
              onChange={onChange}
            />
          ))}
        </Flex>
      </Spacer>
      <Button
        data-qa="gdpr-button"
        disabled={isSaveEnabled}
        fullWidth={true}
        onClick={handleClick}
      >
        {translate("settings.privacy.termsandcond_button")}
      </Button>
    </Flex>
  );
};

export default Gdpr;
