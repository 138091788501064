import React from "react";
import { translate } from "base/shared/Localization";
import { getCDNUrl } from "base/shared/Utils";
import Image from "components/Image/Image";
import {
  Spacer,
  Button,
  Text,
  BADI_COLORS,
  BREAKPOINTS_PX,
} from "@badi/badi-components";
import {
  Wrapper,
  ImageWrap,
  AbsolutLayer,
  TextWrap,
  FormWrap,
} from "./styled-components";

const Introduction = ({
  visibleLayer = null,
  form = null,
  resources,
  spacing = 30,
  clickHandler = () => {},
}) => {
  return (
    <Wrapper spacing={spacing} withForm={!!form}>
      {resources.map(
        ({ title, description, cta, selector, image, mobileImage }) => (
          <div key={selector}>
            <ImageWrap visible={!visibleLayer || visibleLayer === selector}>
              <picture>
                <source
                  media={`(min-width: ${BREAKPOINTS_PX.lg})`}
                  srcSet={`${getCDNUrl()}${image}`}
                />
                <source
                  media="(min-width: 0px)"
                  srcSet={`${getCDNUrl()}${mobileImage}`}
                />
                <Image alt={translate(title)} src={image} />
              </picture>
            </ImageWrap>
            <AbsolutLayer
              visible={!visibleLayer || visibleLayer === selector}
              withForm={!!form}
            >
              <TextWrap withForm={!!form}>
                {description && (
                  <Spacer top={2}>
                    <Text
                      color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                      level="14-Regular"
                    >
                      {translate(description)}
                    </Text>
                  </Spacer>
                )}
                {cta && (
                  <Spacer top={3}>
                    <Button onClick={clickHandler} type="submit">
                      {translate(cta)}
                    </Button>
                  </Spacer>
                )}
              </TextWrap>
            </AbsolutLayer>
          </div>
        ),
      )}
      {form && <FormWrap>{form}</FormWrap>}
    </Wrapper>
  );
};

export default Introduction;
