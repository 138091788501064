import React from "react";

import SortByForm from "../../forms/sort-by";
import FlatForm from "../../forms/flat";
import RoomForm from "../../forms/room";
import FlatmatesForm from "../../forms/flatmates";
import BudgetForm from "../../forms/budget";
import AvailabilityForm from "../../forms/availability";
import { MarketplaceSegmentsForm } from "../../marketplace-segments";

import { FILTERS_ID } from "../../static";

const filters = Object.values(FILTERS_ID);

const AdvancedFilterForm = ({ filter, ...rest }) => {
  switch (filter) {
    case FILTERS_ID.sort:
      return <SortByForm {...rest} />;
    case FILTERS_ID.marketplace_segments:
      return <MarketplaceSegmentsForm {...rest} />;
    case FILTERS_ID.flat:
      return <FlatForm {...rest} />;
    case FILTERS_ID.room:
      return <RoomForm {...rest} />;
    case FILTERS_ID.flatmates:
      return <FlatmatesForm {...rest} />;
    case FILTERS_ID.budget:
      return <BudgetForm {...rest} />;
    case FILTERS_ID.availability:
      return <AvailabilityForm {...rest} />;
    default:
      return null;
  }
};

export default AdvancedFilterForm;
