import { connect } from "react-redux";

import { getToken, getUserId } from "datamodel/Auth/selectors";
import { getGhost, isGdprAnsweredSelector } from "datamodel/User/selectors";
import Actions from "datamodel/Requirements/actions";

import AuthWrapper from "./auth-wrapper";

const mapStateToProps = (state) => ({
  id: getUserId(state),
  ghost: getGhost(state),
  isGdprAnswered: isGdprAnsweredSelector(state),
  authtoken: getToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRegister: (register) => dispatch(Actions.setRegister(register)),
  setRequirements: (requirements) =>
    dispatch(Actions.setRequirements(requirements)),
});

export default (ComposeComponent, { requireCompleteProfile = true } = {}) => {
  const mergeProps = (state, dispatch, ownProps) => ({
    ...state,
    ...dispatch,
    ...ownProps,
    requireCompleteProfile,
  });
  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )(AuthWrapper(ComposeComponent));
};
