import React from "react";
import { withFormsy } from "formsy-react";
import Choice from "./Choice";

class ChoiceWithFormsy extends React.PureComponent {
  onChangeValue = (data) => {
    const { name, onChange, setValue } = this.props;

    setValue(data);
    if (onChange) onChange(name, data);
  };

  render() {
    const { getValue, name, onChange, setValue, value, ...rest } = this.props;
    const formsyValue = getValue() || value;

    return (
      <Choice
        name={name}
        onChange={this.onChangeValue}
        value={formsyValue}
        {...rest}
      />
    );
  }
}

ChoiceWithFormsy.defaultProps = {
  onChange: undefined,
};

export default withFormsy(ChoiceWithFormsy);
