import React, { useEffect } from "react";
import Footer from "components/Layout/Footer";
import LayoutWrapper from "components/Layout/Wrapper";
import { Header } from "./components/";
import { getPathToRequests, getPathToChats, SUBPATHS } from "./routes";
import styled from "styled-components";
import { mapToModel } from "./utils";
import { parseConnections } from "datamodel/Inbox/utils";
import { fetchDataForChats, fetchDataForRequests } from "./hooks";
import { BADI_COLORS, COLORS } from "app/badi-components";
import { Link } from "react-router";
import { InboxListerModel as model } from "./models";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { acceptEnquiry } from "app/datamodel/Conversation/api";
import { leaveInboxConversation } from "datamodel/Conversation/api";
import {
  openNeedMoreCandidatesModal,
  openRequestAcceptedModal,
} from "./modals";
import matchPaths from "./utils/matchPaths";
import LocalStorage, { STORAGE_KEYS } from "lib/local-storage/LocalStorage";
import { getInboxPath } from "../inbox/routes";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 40px;
  margin-top: 20px;
`;

Header.Inbox = styled.h1`
  text-align: center;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 4px solid ${COLORS.NEUTRAL_10};
  margin-bottom: 30px;
  margin-top: 20px;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
`;

Nav.Link = styled(Link)`
  ${({ emphasised }) => `font-weight: ${emphasised ? "bold" : "normal"};`}
  padding: 1px;
  padding-bottom: 10px;
  :hover {
    text-decoration: none;
    box-shadow: 0 0 0 4px ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
    clip-path: inset(0px 1px -5px 1px);
  }
`;

Nav.Icon = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-left: 10px;
`;

const HeaderAndNavContainer = styled.div`
  margin-left: initial;
  width: 100%;
  text-align: center;
`;

const getPathToRequest = (id) => {
  if (!id) {
    return null;
  }
  return getPathToRequests(id);
};

const InboxLister = ({
  params: { id },
  location,
  router,
  token,
  reload,
  children,
  isUserInListerMode,
}) => {
  if (!isUserInListerMode) {
    router.push(getInboxPath());
    return null;
  }
  const { room: roomId, category, page } = location.query;
  const requestResponse = fetchDataForRequests(token, id);
  const { currentRequest, idOfPreviousRequest, idOfNextRequest } =
    requestResponse;

  const matches = matchPaths(
    [SUBPATHS.REQUESTS, SUBPATHS.CHATS, SUBPATHS.CONVERSATIONS],
    location.pathname,
    { default: SUBPATHS.REQUESTS },
  );
  const chats = fetchDataForChats(
    token,
    {
      roomId,
      category,
    },
    { page },
    [matches[SUBPATHS.CONVERSATIONS]], // in order to refresh when back-button'ing out of conversations screen.
  );
  const requestModel = currentRequest
    ? mapToModel(model, currentRequest)
    : null;
  const chatsModel = chats?.data ? parseConnections(chats.data) : [];

  const requestsCount = requestResponse.pagination.total;
  const chatsCount = chats.pagination.total;

  const onClickAcceptConnection = () => {
    acceptEnquiry({ token, connectionId: currentRequest.id }).then(() => {
      const hasUserSeenRequestedAcceptedModalAlready = LocalStorage.getItem(
        STORAGE_KEYS.HAS_USER_SEEN_REQUEST_ACCEPTED_MODAL,
      );
      if (!hasUserSeenRequestedAcceptedModalAlready) {
        openRequestAcceptedModal();
        LocalStorage.setItem(
          STORAGE_KEYS.HAS_USER_SEEN_REQUEST_ACCEPTED_MODAL,
          true,
        );
      }
      reload();
    });
  };

  const onClickRejectConnection = () => {
    leaveInboxConversation(currentRequest.id, token).then(() => {
      reload();
    });
  };

  useEffect(() => {
    if (
      matches[SUBPATHS.REQUESTS] &&
      requestResponse.isLoaded &&
      requestsCount === 0
    ) {
      openNeedMoreCandidatesModal({ token });
    }
  }, [matches[SUBPATHS.REQUESTS] && requestsCount, requestResponse.isLoaded]);

  const requestsText = Number.isInteger(requestsCount)
    ? translate("inbox-lister.nav-bar.requests.title-with-count", {
        count: requestsCount,
      })
    : translate("inbox-lister.nav-bar.requests.title");
  const chatsText = Number.isInteger(chatsCount)
    ? translate("inbox-lister.nav-bar.chats.title-with-count", {
        count: chatsCount,
      })
    : translate("inbox-lister.nav-bar.chats.title");

  const childProps = {
    connectionId: id,
    connections: chatsModel,
    location,
    router,
    chatsPagination: chats.pagination,
    requestsPagination: requestResponse.pagination,
    currentRequest: requestModel,
    pathToNextRequest: getPathToRequest(idOfNextRequest),
    pathToPreviousRequest: getPathToRequest(idOfPreviousRequest),
    onClickAcceptConnection,
    onClickRejectConnection,
    isLoaded: requestResponse.isLoaded,
  };

  return (
    <LayoutWrapper footer={<Footer campaign="inbox_footer" />}>
      <Container>
        <HeaderAndNavContainer>
          <Header.Inbox>Inbox</Header.Inbox>
          <Nav>
            <Nav.Link
              emphasised={matches[SUBPATHS.REQUESTS]}
              to={getPathToRequests()}
              decoration="none"
            >
              {requestsText}
              <Nav.Icon src={getPublicAsset("icons/requests.svg")} />
            </Nav.Link>
            <Nav.Link
              emphasised={
                matches[SUBPATHS.CHATS] || matches[SUBPATHS.CONVERSATIONS]
              }
              to={getPathToChats()}
            >
              {chatsText}
              <Nav.Icon src={getPublicAsset("icons/chats.svg")} />
            </Nav.Link>
          </Nav>
        </HeaderAndNavContainer>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, childProps),
        )}
      </Container>
    </LayoutWrapper>
  );
};

export default InboxLister;
