import { css } from "styled-components";
import { BREAKPOINTS_PX } from "./constants/breakpoints";

const media = Object.entries(BREAKPOINTS_PX).reduce(
  (accumulator, [key, value]) => ({
    ...accumulator,
    [key]: (...args) => css`
      @media (min-width: ${value}) {
        ${css(...args)}
      }
    `,
  }),
  {},
);

export default media;
