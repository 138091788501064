import React from "react";
import { withRouter } from "react-router";
import { getGoldUpgradePath } from "Sections/plans/routes";
import PremiumBlocker from "./premium-blocker";

const PremiumBlockerRoute = ({ router, ...props }) => {
  const handlePremiumClick = ({ roomId, feature }) => {
    router.push(getGoldUpgradePath({ roomId, feature }));
  };

  return <PremiumBlocker {...props} onClick={handlePremiumClick} />;
};

export default withRouter(PremiumBlockerRoute);
