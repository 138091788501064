import React, { useEffect } from "react";
import { getOverviewPath } from "Sections/overview/routes";
import Overview from "./overview-mapping";
import { TRANSACTIONS_COMPONENT } from "./constants";

const OverviewRoute = ({ router, params }) => {
  const handleCleanDetails = () => {
    router.push(getOverviewPath());
  };

  useEffect(() => {
    const { detail } = params;

    if (detail && detail !== TRANSACTIONS_COMPONENT) {
      handleCleanDetails();
    }
  }, [params]);

  return <Overview visibleTransactions={!!params.detail} />;
};

export default OverviewRoute;
