export const VARIANT = {
  PREMIUM: "premium",
  TERTIARY: "tertiary",
  LIGHT: "light",
};

export const DESKTOP_MARGINS = {
  bottom: 25,
  top: 8,
};

export const MOBILE_MARGINS = {
  bottom: 23,
  top: 4,
};

export const DESKTOP_FLEX_CONFIG = { gutter: 2, direction: "column" };
