import { connect } from "react-redux";

import { getToken } from "datamodel/Auth/selectors";
import { getPremiumOriginSelector } from "datamodel/premium/selectors";

import PurchaseButtonModule from "./purchase-button-module";

const mapStateToProps = (state) => ({
  token: getToken(state),
  origin: getPremiumOriginSelector(state),
});

const mergeProps = (stateProps, _, ownProps) => {
  const { origin, ...props } = ownProps;
  return {
    ...stateProps,
    ...props,
    origin: origin || stateProps.origin,
  };
};

export default connect(mapStateToProps, null, mergeProps)(PurchaseButtonModule);
