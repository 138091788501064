import React from "react";

import { Button, Flex, Heading, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { ContentWrapper, ConfirmRoot } from "./styled-components";

const ConfirmTermsAndConditions = ({ onCancel, onConfirm }) => (
  <ConfirmRoot>
    <Flex direction="column" fullHeight={true}>
      <ContentWrapper grow={1}>
        <Flex
          alignItems="center"
          direction="column"
          fullHeight={true}
          justify="center"
        >
          <Spacer bottom={2} md={{ left: 6, right: 6 }}>
            <Heading data-qa="t-and-c-title" level={3}>
              {translate("terms_and_conditions.confirm.title")}
            </Heading>
          </Spacer>
          <Spacer bottom={3} md={{ left: 6, right: 6 }}>
            <Text align="center" data-qa="t-and-c-text">
              {translate("terms_and_conditions.confirm.text")}
            </Text>
          </Spacer>
        </Flex>
      </ContentWrapper>
      <Flex gutter={0.5}>
        <Button
          data-qa="t-and-c-cancel-button"
          fullWidth={true}
          grow={1}
          onClick={onCancel}
          variant="secondary"
        >
          {translate("terms_and_conditions.actions.cancel")}
        </Button>
        <Button
          data-qa="t-and-c-reject-button"
          fullWidth={true}
          grow={1}
          onClick={onConfirm}
        >
          {translate("terms_and_conditions.actions.reject")}
        </Button>
      </Flex>
    </Flex>
  </ConfirmRoot>
);

export default ConfirmTermsAndConditions;
