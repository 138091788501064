import styled from "styled-components";
import { colors } from "@badi/badi-components";

import Svg from "assets/icons/location-blue-pin.svg";

export const BlockWrapper = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const LocationIcon = styled(Svg)`
  display: block;
`;
