import React, { Component } from "react";

import { TextButton, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { getSelectedElements } from "../utils";

import FlatmatesUnavailable from "./flatmates-unavailable";
import FlatmatesFields from "./flatmates-fields";
import { SubmitFormWrapper, StyledForm } from "../styled-components";

class FlatmatesForm extends Component {
  constructor(props) {
    super(props);
    this.state = { updated: false, selectedGenders: [], tenants: 0 };
    this.genders = translate(props.genders);
  }

  static getDerivedStateFromProps(props, state) {
    const { genders, tenants } = props;

    if (state.updated) {
      return { updated: false };
    }

    const selectedGenders =
      genders.filter((g) => g.selected).map(({ id }) => id) || [];
    return {
      selectedGenders,
      tenants,
    };
  }

  handleGenderChange = (selectedGenders) => {
    this.setState({ selectedGenders, updated: true });
  };

  handleTenantsChange = (tenants) => {
    this.setState({ tenants, updated: true });
  };

  handleApplyFilter = () => {
    const { selectedGenders, tenants } = this.state;
    const { onFilterApplied, genders, setFlatmates } = this.props;

    const parsedGenders = getSelectedElements(genders, selectedGenders);

    setFlatmates(parsedGenders, tenants);
    onFilterApplied();
  };

  render() {
    const { selectedGenders, tenants } = this.state;
    const { available } = this.props;

    return (
      <Flex as={StyledForm} direction="column">
        {available ? (
          <FlatmatesFields
            genders={this.genders}
            onGenderChange={this.handleGenderChange}
            onTenantsChange={this.handleTenantsChange}
            selectedGenders={selectedGenders}
            tenants={tenants}
          />
        ) : (
          <FlatmatesUnavailable />
        )}
        <SubmitFormWrapper>
          <TextButton onClick={this.handleApplyFilter}>
            {translate("search.filters.button")}
          </TextButton>
        </SubmitFormWrapper>
      </Flex>
    );
  }
}

FlatmatesForm.defaultProps = {
  tenants: 0,
};

export default FlatmatesForm;
