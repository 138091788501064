import { getOverviewPath } from "Sections/overview/routes";
import { getHomePath } from "Sections/Home/routes";
import { getListingsPath } from "Sections/my-listings/routes";

export const getSource = (searchParam) => {
  const search = new URLSearchParams(searchParam);
  return search.get("source");
};

export const redirectToSource = (source, isLister) => {
  let redirectUrl = null;
  if (source) {
    if (
      !isLister &&
      (source === getOverviewPath() || source === getListingsPath())
    ) {
      redirectUrl = getHomePath();
    } else {
      redirectUrl = source;
    }
  }
  return redirectUrl;
};

export const handleSwitchUserModeRedirect = (pathname, isLister) => {
  let redirectUrl = null;

  if (isLister && pathname === getHomePath()) {
    redirectUrl = getOverviewPath();
  } else if (
    !isLister &&
    (pathname === getOverviewPath() || pathname === getListingsPath())
  ) {
    redirectUrl = getHomePath();
  }

  return redirectUrl;
};
