import React from "react";
import { Card, Spacer, Text, Icon, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";

import { StyledCard } from "./styled-components";

const ActionCard = ({ label, onClick, text, icon }) => (
  <Spacer bottom={3} key={label}>
    <StyledCard as={Card} data-qa={label} onClick={onClick}>
      <Spacer bottom={2} left={2} right={2} top={2}>
        <Flex alignItems="center">
          <Icon
            alt={translate(text)}
            iconMaxHeight={32}
            iconMaxWidth={32}
            src={getPublicAsset(icon)}
          />
          <Spacer left={2}>
            <Text body="14-Regular">{translate(text)}</Text>
          </Spacer>
        </Flex>
      </Spacer>
    </StyledCard>
  </Spacer>
);

export default ActionCard;
