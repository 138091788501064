import React from "react";
import { Text, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import Input from "components/Form/Input/Input";

import FilterSectionWrapper from "../../wrappers/filter-section-wrapper";
import FilterSubSectionWrapper from "../../wrappers/filter-sub-section-wrapper";

import "./RangeFieldExt.css";

const RangeField = ({
  min,
  max,
  title,
  description = null,
  onMaxValueChange,
  onMinValueChange,
}) => {
  const handleValueChange = (value, onInputChange) => {
    const safeValue = Number(value);

    if (value && isNaN(safeValue)) return;

    onInputChange(isNaN(safeValue) ? 0 : safeValue);
  };

  const handleMinValueChange = (e) => {
    handleValueChange(e.currentTarget.value, onMinValueChange);
  };

  const handleMaxValueChange = (e) => {
    handleValueChange(e.currentTarget.value, onMaxValueChange);
  };

  return (
    <FilterSectionWrapper hasPadding={false} title={title}>
      <Spacer bottom={2} top={1}>
        {description && <Text body="16-Regular">({description})</Text>}
      </Spacer>
      <div className="SearchBudgetForm__Content">
        <FilterSubSectionWrapper
          title={translate("search.filters.budget.minimum")}
        >
          <Input
            maxlength={9}
            name="minValue"
            onChange={handleMinValueChange}
            placeholder={translate("search.filters.budget.placeholder")}
            value={min || ""}
          />
        </FilterSubSectionWrapper>
        <FilterSubSectionWrapper
          title={translate("search.filters.budget.maximum")}
        >
          <Input
            maxlength={9}
            name="maxValue"
            onChange={handleMaxValueChange}
            placeholder={translate("search.filters.budget.placeholder")}
            value={max || ""}
          />
        </FilterSubSectionWrapper>
      </div>
    </FilterSectionWrapper>
  );
};

RangeField.defaultProps = {
  description: null,
};

export default RangeField;
