import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: ${unitizedPx(2)};
  right: ${unitizedPx(2)};
  z-index: 1;
  cursor: pointer;

  svg {
    display: block;
  }
`;
