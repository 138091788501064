import React, { useState } from "react";
import {
  StatusCard,
  Button,
  Flex,
  Spacer,
  Heading,
  Text,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import TextInput from "components/form-text-input";

const EMAIL_VALIDATIONS = ["email", ["maxLength", 50]];

const RequestCode = ({ disabled, onSubmit, toggleStep, dataQa = "" }) => {
  const [canSubmit, setValidation] = useState(false);
  const [emailValue, setEmailValue] = useState("");

  const handleInputValidation = ({ isValid }) => {
    setValidation(isValid);
  };

  const handleInputOnChange = ({ value }) => {
    setEmailValue(value);
  };

  const handleFormSubmission = (event) => {
    event.preventDefault();
    onSubmit({ email: emailValue });
  };

  return (
    <StatusCard dataQa={dataQa} isEditable={!disabled}>
      <form onSubmit={handleFormSubmission}>
        <Heading level={3}>{translate("verifications.email.title")}</Heading>
        <Spacer bottom={1} top={3}>
          <Text body={2}>{translate("verifications.email.request.label")}</Text>
        </Spacer>
        <Spacer bottom={2}>
          <TextInput
            name="email"
            onChange={handleInputOnChange}
            onInputValidation={handleInputValidation}
            placeholder={translate(
              "verifications.email.request.input_placeholder",
            )}
            type="email"
            validations={EMAIL_VALIDATIONS}
          />
        </Spacer>
        <Flex gutter={2} justify="flexEnd">
          <Button
            disabled={disabled}
            onClick={toggleStep}
            type="button"
            variant="secondary"
          >
            {translate("verifications.email.request.code_button")}
          </Button>
          <Button disabled={!canSubmit || disabled} type="submit">
            {translate("verifications.email.request.submit_button")}
          </Button>
        </Flex>
      </form>
    </StatusCard>
  );
};

RequestCode.defaultProps = {
  disabled: false,
};

export default RequestCode;
