import React, { useCallback, useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import { colors, Spacer } from "@badi/badi-components";

import LayoutWrapper from "components/Layout/Wrapper";
import TopBar from "components/Layout/TopBar";
import Footer from "components/Layout/Footer";
import Spinner from "components/Icons/LoadingSpinner";
import UserFeedback from "base/shared/user-feedback";
import { openRoomInviteModal } from "components/room-invite";
import { PremiumLabel, usePremium } from "modules/premium";
import { PLAN_FEATURES } from "datamodel/plans/constants";

import {
  openRecommendationsModal,
  shouldShowRecommendationsModal,
} from "./recommendations-tips-modal";
import { openRecommededUserModal } from "./recommended-user-modal";
import RecommendationsBar from "./layout-elements/recommendation-bar";
import Recommendation from "./layout-elements/recommendation";
import RecommendationsHeader from "./layout-elements/recommendations-header";

import EmptyList from "./layout-elements/empty-list";

import {
  FEEDBACK_SET_RECOMMENDATION,
  checkUnsetRecommendations,
} from "./utils";
import { SpinnerWrapper } from "./styled-components";

const PLAN_FEATURE_ID = PLAN_FEATURES.RECOMMENDED_SEEKERS;

const Recommendations = ({
  fetched,
  fetching,
  getRecommendations,
  getRoom,
  recommendations,
  redirectToPremium = () => {},
  rejectRecommendation,
  removeRecommendation,
  resetRecommendations,
  room = {},
  roomId,
  token,
}) => {
  const { premiumEnabled, isPremium, hasCapability } = usePremium({
    roomId,
    capabilityId: PLAN_FEATURE_ID,
  });

  useEffect(() => {
    getRoom(roomId);
    getRecommendations(roomId);

    if (shouldShowRecommendationsModal()) {
      openRecommendationsModal();
    }

    return () => resetRecommendations();
  }, []);

  const handleInviteSent = useCallback(
    ({ userId }) => {
      removeRecommendation(userId);
    },
    [removeRecommendation],
  );

  const acceptRecommendation = useCallback(
    (userId) => {
      if (premiumEnabled && isPremium && !hasCapability) {
        redirectToPremium({ feature: PLAN_FEATURE_ID });
        return;
      }
      openRoomInviteModal({
        userId,
        room,
        token,
        onInviteSent: handleInviteSent,
      });
    },
    [handleInviteSent, openRoomInviteModal, room, redirectToPremium],
  );

  const rejectRecommendationHandler = useCallback(
    (userId) => {
      const feedbackTexts = FEEDBACK_SET_RECOMMENDATION.reject;

      rejectRecommendation({ roomId, userId });
      removeRecommendation(userId);

      UserFeedback.success(feedbackTexts.title, feedbackTexts.description);
    },
    [rejectRecommendation, removeRecommendation],
  );

  const showUserModal = useCallback(
    (userId) => () => {
      openRecommededUserModal({
        userId,
        token,
        onAccept: acceptRecommendation,
        onReject: rejectRecommendationHandler,
      });
    },
    [acceptRecommendation, rejectRecommendationHandler],
  );

  return (
    <LayoutWrapper
      footer={<Footer campaign="recommendations_footer" />}
      preContent={<RecommendationsBar roomId={roomId} roomTitle={room.title} />}
      topBar={<TopBar />}
    >
      <Container>
        <RecommendationsHeader onHelpClick={openRecommendationsModal}>
          {premiumEnabled && isPremium && <PremiumLabel bottom={1} />}
        </RecommendationsHeader>
        <Row>
          <Col
            lg={{ offset: 1, size: 10 }}
            xs={{ size: 12, offset: 0, order: 1 }}
          >
            {fetching && (
              <SpinnerWrapper>
                <Spinner color={colors.green} />
              </SpinnerWrapper>
            )}
            {fetched &&
              (recommendations.length &&
              checkUnsetRecommendations(recommendations) ? (
                recommendations.map(({ hidden, ...recommendedUser }) => (
                  <Recommendation
                    acceptRecommendation={acceptRecommendation}
                    blocked={premiumEnabled && isPremium && !hasCapability}
                    data-qa="recommendation"
                    isHidden={hidden}
                    key={recommendedUser.id}
                    rejectRecommendation={rejectRecommendationHandler}
                    showUserModal={showUserModal(recommendedUser.id)}
                    user={recommendedUser}
                  />
                ))
              ) : (
                <EmptyList />
              ))}
            <Spacer bottom={6} />
          </Col>
        </Row>
      </Container>
    </LayoutWrapper>
  );
};

export default Recommendations;
