import { connect } from "react-redux";

import { getToken } from "datamodel/Auth/selectors";
import { getRoomSummary, getRoomUser } from "datamodel/Room/selectors";
import {
  getConnectionRoomDetails,
  getConnectionUser,
} from "datamodel/Inbox/selectors";
import { getBookingMoveOutFeatureFlag } from "datamodel/Features/selectors";

import MoveInOutStep from "./move-in-out-step";

const getUser = ({ userId, isInstantBooking, userRoom, connectionUser }) => {
  if (userId) return null;
  return isInstantBooking ? userRoom : connectionUser;
};

const mapStateToProps = (state) => ({
  token: getToken(state),
  connectionUser: getConnectionUser(state),
  connectionRoom: getConnectionRoomDetails(state),
  room: getRoomSummary(state),
  user: getRoomUser(state),
  moveOutRequired: getBookingMoveOutFeatureFlag(state),
});

const mergeProps = (state, _, ownProps) => {
  const { isInstantBooking, seekerId, ...props } = ownProps;
  const { user, connectionUser, room, connectionRoom } = state;

  return {
    ...state,
    ...props,
    isInstantBooking,
    user: getUser({
      userId: seekerId,
      isInstantBooking,
      userRoom: user,
      connectionUser,
    }),
    userToInviteId: seekerId,
    room: isInstantBooking ? room : connectionRoom,
  };
};

export default connect(mapStateToProps, {}, mergeProps)(MoveInOutStep);
