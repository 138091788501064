import React from "react";

import Image from "components/Image/Image";

const AppLink = ({ description = null, icon = null, url }) => (
  <a href={url} rel="noopener noreferrer" target="_blank">
    <Image alt={description} height="40px" src={`/assets/icons/${icon}`} />
  </a>
);

export default AppLink;
