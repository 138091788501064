import React from "react";
import { SEARCH_VIEW } from "datamodel/SearchFilters/static";

import { BREAKPOINTS, MatchMedia } from "@badi/badi-components";
import FavoritesView from "./favorite-view";

import { ResultsWrapper } from "../styled-components";

const mq = `(min-width: ${BREAKPOINTS.lg}px)`;

const FavoritesList = ({ favorites, pagination, getFavoritesPerPage }) => {
  return (
    <ResultsWrapper>
      <MatchMedia query={mq}>
        {(matches) => (
          <FavoritesView
            favorites={favorites}
            getFavoritesPerPage={getFavoritesPerPage}
            pagination={pagination}
            view={matches ? SEARCH_VIEW.both : SEARCH_VIEW.list}
          />
        )}
      </MatchMedia>
    </ResultsWrapper>
  );
};

export default FavoritesList;
