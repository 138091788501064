import { connect } from "react-redux";
import { getToken } from "app/datamodel/Auth/selectors";
import { getPayment } from "datamodel/Payment/actions";
import PaymentCheckoutStep from "./payment-checkout";

const mapStateToProps = (state) => ({
  token: getToken(state),
});

const mapDispatchToProps = {
  getPaymentDetails: getPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentCheckoutStep);
