import { connect } from "react-redux";

import { getIsUserListerSelector } from "datamodel/User/selectors";

import NewRoomRoute from "./new-room-route";

const mapStateToProps = (state) => ({
  isUserLister: getIsUserListerSelector(state),
});

export default connect(mapStateToProps)(NewRoomRoute);
