import { isLister } from "datamodel/User/utils";
import MoveInOutStep, { MoveInOutStepFooter } from "../move-in-out-step";
import PaymentStep, { PaymentStepFooter } from "../payment-step";
import MessageStep, { MessageStepFooter } from "../message-step";
import { BOOKING_STEPS } from "../constants";

export const sanitizeString = (string) => (string ? string.trim() : "");

export const getFlowSteps = ({
  isInstantBooking,
  userRole,
  skipMessageStep,
} = {}) => {
  const defaultSteps = [
    {
      id: BOOKING_STEPS.MOVE_IN_OUT,
      render: MoveInOutStep,
      footer: MoveInOutStepFooter,
      title: isLister(userRole)
        ? "booking_flow_step_1_dates"
        : "booking.new.steps.moveinout.heading",
    },
    {
      id: BOOKING_STEPS.PAYMENT,
      render: PaymentStep,
      footer: PaymentStepFooter,
      title: "booking_flow_step3_title",
    },
  ];

  if (!skipMessageStep && isInstantBooking) {
    defaultSteps.splice(1, 0, {
      id: BOOKING_STEPS.MESSAGE,
      render: MessageStep,
      footer: MessageStepFooter,
      title: "booking.new.steps.message.heading",
    });
  }

  return defaultSteps;
};
