import React from "react";
import { Col, Container, Row } from "reactstrap";
import { translate } from "base/shared/Localization";
import { Flex, Text, Spacer, Heading } from "@badi/badi-components";
import { Female, Male, NoBinary } from "components/Icons/Gender";

const RoomMates = ({ femaleTenants, maleTenants, nonBinaryTenants, title }) => (
  <Container>
    <Row>
      <Col xs={12}>
        <Spacer bottom={4}>
          <Heading data-qa="flatmates-title" level="20-Bold">
            {translate(title)}
          </Heading>
        </Spacer>
      </Col>
    </Row>
    <Row>
      <Col md={4} xs={6}>
        <Flex alignItems="center" fullHeight={true} gutter={1}>
          <Female />
          <Text body="14-Regular" dataQa="flatmates-female">
            {translate("room.detail.fields.gender.female", {
              smart_count: femaleTenants,
            })}
          </Text>
        </Flex>
      </Col>
      <Col md={4} xs={6}>
        <Flex alignItems="center" fullHeight={true} gutter={1}>
          <Male />
          <Text body="14-Regular" dataQa="flatmates-male">
            {translate("room.detail.fields.gender.male", {
              smart_count: maleTenants,
            })}
          </Text>
        </Flex>
      </Col>
      <Col md={4} xs={6}>
        <Flex alignItems="center" fullHeight={true} gutter={1}>
          <NoBinary />
          <Text body="14-Regular" dataQa="flatmates-nonBinary">
            {translate("room.detail.fields.gender.non_binary", {
              smart_count: nonBinaryTenants,
            })}
          </Text>
        </Flex>
      </Col>
    </Row>
  </Container>
);

export default RoomMates;
