import styled, { css } from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const Modal = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.backgroundColor};
  width: 100vw;
  height: 100vh;
  max-height: fill-available;
  padding: ${({ padding }) => padding || "16px"};
  transform: scale(0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(1);
      opacity: 1;
    `}

  ${({ isClosed }) =>
    isClosed &&
    css`
      transform: scale(0);
      opacity: 0;
    `}

  ${mediaquery.md`
    width: auto;
    max-width: 570px;
    height: auto;
    max-height: unset;
    border-radius: 4px;
  `}
`;
