import React from "react";
import moment from "moment";
import { Text, Flex, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import DatePicker from "components/Form/DatePicker";
import BaseDatePicker from "components/DatePicker";
import { DISPLAY_DATE_FORMAT } from "../constants";
import { SearchDateWrapper } from "../styled-components";

const DateSelector = ({ handleMoveInDateChange, fromDate }) => (
  <Flex dataQa="search-bar-availability-date_selector" direction="column">
    <label htmlFor="move-in">
      <Text body="12-Medium">{translate("search.home_date_label")}</Text>
      <SearchDateWrapper>
        <BaseDatePicker
          data-qa="search-bar-availability-date_picker"
          date={moment(fromDate)}
          displayFormat={DISPLAY_DATE_FORMAT}
          id="move-in"
          name="move-in"
          onDateChange={handleMoveInDateChange}
          withPortal={false}
        />
      </SearchDateWrapper>
    </label>
  </Flex>
);

export default DateSelector;
