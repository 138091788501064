import { generateFetchTypes } from "base/shared/TypeHelper";
import FeaturesActions from "datamodel/Features/actions";

import Types from "../types";
import {
  fetchLegal,
  fetchMe,
  fetchUpdateLegal,
  fetchOnboarding,
  fetchUpdateOnboarding,
  fetchListerScore,
  updateProfile,
} from "../api";
import { parseProp, toPostParams } from "../utils";

export default {
  getMe(token, headerParams) {
    return {
      types: generateFetchTypes(Types.ME_REQUEST),
      request: fetchMe(token, headerParams),
    };
  },
  getLegal(token, headerParams) {
    return {
      types: generateFetchTypes(Types.LEGAL_REQUEST),
      request: fetchLegal(token, headerParams),
    };
  },
  getOnboarding(token, headerParams) {
    return {
      types: generateFetchTypes(Types.ONBOARDING_REQUEST),
      request: fetchOnboarding(token, headerParams),
    };
  },
  getListerScore(token, headerParams) {
    return {
      types: generateFetchTypes(Types.LISTER_SCORE_REQUEST),
      request: fetchListerScore(token, headerParams),
    };
  },
  resetMe() {
    return { type: Types.RESET_ME };
  },
  setConfirmation(type) {
    return { type: Types.SET_CONFIRMATION, result: type };
  },
  setLister(value) {
    return { type: Types.SET_LISTER, result: value };
  },
  setUserMode({ mode = "default", isActiveSwitching = false }) {
    return {
      type: Types.SET_USERMODE,
      result: { mode, isActiveSwitching },
    };
  },
  updateLegal(params) {
    return {
      types: generateFetchTypes(Types.LEGALUPDATE_REQUEST),
      request: fetchUpdateLegal,
      params,
      requireAuth: true,
    };
  },
  updateOnboarding(token, params) {
    return {
      types: generateFetchTypes(Types.ONBOARDING_UPDATE_REQUEST),
      request: fetchUpdateOnboarding(token, params),
      onSuccess: (successAction, dispatch, next) => {
        next(successAction);
        dispatch(FeaturesActions.getFeatures({ token, isLoggedUser: true }));
        dispatch(this.getOnboarding(token));
      },
    };
  },
  updateProfile(token, fieldName, value) {
    return {
      types: generateFetchTypes(Types.UPDATE_PROFILE_REQUEST),
      request: updateProfile(token, parseProp(fieldName, value)),
    };
  },
  saveProfile(token, params) {
    return {
      types: generateFetchTypes(Types.SAVE_PROFILE_REQUEST),
      request: updateProfile(token, toPostParams(params)),
    };
  },
};
