import React from "react";
import { useDispatch } from "react-redux";
import { getGoldUpgradePath } from "Sections/plans/routes";
import { setPremiumOrigin } from "datamodel/premium/actions";
import Viewers from "./viewers-mapping";
import { getListerMetricsPath } from "../analytics/rooms/routes";

const ViewersRoute = ({ router, params, authtoken }) => {
  const { roomId } = params;
  const dispatch = useDispatch();

  const redirectToPremium = ({ feature }) => {
    dispatch(setPremiumOrigin(window.location.pathname));
    router.push(getGoldUpgradePath({ roomId, feature }));
  };

  const redirectToAnalytics = () => {
    router.push(getListerMetricsPath({ roomId }));
  };

  return (
    <Viewers
      redirectToAnalytics={redirectToAnalytics}
      redirectToPremium={redirectToPremium}
      roomId={Number(roomId)}
      token={authtoken}
    />
  );
};

export default ViewersRoute;
