import React from "react";
import classNames from "classnames";
import moment from "moment";

import BaseDatePicker from "components/DatePicker";
import Calendar from "components/Icons/Calendar";

import "./DatePickerExt.css";

const DatePicker = ({
  className = "",
  disabled = false,
  displayFormat = "",
  initialVisibleMonth = () => moment(),
  isDayBlocked = () => false,
  isOutsideRange = (day) => day.isBefore(moment()), // defaults to not allowing date before today
  numberOfMonths = 1,
  onChange = () => {},
  onClose = () => {},
  placeholder = "",
  value = null,
  withPortal = true,
}) => (
  <div
    className={classNames(
      "Form__DatePicker--wrapper",
      { "Form__DatePicker--wrapper-disabled": disabled },
      className,
    )}
  >
    <BaseDatePicker
      date={value}
      disabled={disabled}
      displayFormat={displayFormat}
      initialVisibleMonth={initialVisibleMonth}
      isDayBlocked={isDayBlocked}
      isOutsideRange={isOutsideRange}
      numberOfMonths={numberOfMonths}
      onClose={onClose}
      onDateChange={onChange}
      placeholder={placeholder}
      withPortal={withPortal}
    />
    <Calendar
      className="DatePickerIcon"
      color={disabled ? "#bbb" : "#1e1e1e"}
    />
  </div>
);

export default DatePicker;
