import React from "react";

import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { fetchUser } from "datamodel/User/api";

import ViewerUser from "./layout-elements/viewer-user";

const closeViewerUserModal = () => {
  modal.remove(MODAL_ID.VIEWER_PROFILE_MODAL);
};

export const openViewerUserModal = ({ userId, contacted, token, onAccept }) => {
  fetchUser(userId, token).then((user) => {
    modal.add(
      <ViewerUser
        contacted={contacted}
        inviteViewer={onAccept}
        onClose={closeViewerUserModal}
        user={user}
      />,
      {
        id: MODAL_ID.VIEWER_PROFILE_MODAL,
        onClose: closeViewerUserModal,
        className: "popup_profile",
        type: MODAL_TYPE.LATERAL,
      },
    );
  });
};
