import React from "react";

import { ACTIONS_MAPPER } from "./constants";
import ActionCard from "./action-card";

const MainActions = ({ mainActions, onClick }) => {
  const handleClick = (placeType) => () => {
    onClick(placeType);
  };

  return mainActions.map((action) => {
    if (!ACTIONS_MAPPER[action.id]) return null;
    const { label, value, icon, text } = ACTIONS_MAPPER[action.id];
    return (
      <ActionCard
        icon={icon}
        label={label}
        onClick={handleClick(value)}
        text={text}
      />
    );
  });
};

export default MainActions;
