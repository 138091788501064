import envVars from "base/shared/Env";

export const GOOGLE_MAPS_DOMAIN = "https://maps.googleapis.com";
export const GOOGLE_MAPS_URL = "https://maps.googleapis.com/maps/api/staticmap";
export const GOOGLE_API_KEY = envVars().BADI_GOOGLE_API_KEY;
export const DEFAULT_ZOOM = 15;
export const DEFAULT_SIZE = "600x300";
export const DEFAULT_MARKER_ICON =
  "https://badi-website.s3.eu-central-1.amazonaws.com/images/pin.png";

export const MAP_TYPES = {
  ROADMAP: "roadmap",
  SATELLITE: "satellite",
  HYBRID: "hybrid",
  TERRAIN: "terrain",
};
