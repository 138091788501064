import React, { Component } from "react";
import { browserHistory } from "react-router";
import Formsy from "formsy-react";
import { Container, Row, Col } from "reactstrap";
import { Spacer, Flex, Button, Text } from "@badi/badi-components";

import UserFeedback from "base/shared/user-feedback";

import { translate } from "base/shared/Localization";
import { getLocalePath, scrollTo } from "base/shared/Utils";
import { validateFormChange } from "base/shared/Form";

import { modal } from "containers/ReduxModal/emitter";

import {
  openCompleteProfileModal,
  COMPLETE_PROFILE_FROM,
} from "components/complete-profile";
import * as Section from "./Sections";
import { ProfileFooterRoot } from "./styled-components";

import { isBlockValid, parseProfile, tagListFieldHasValues } from "../utils";

import "./ProfileFormExt.css";

const cancelClick = (pathname) => {
  const url =
    typeof pathname === "string" ? pathname : `${getLocalePath()}/my-profile`;
  browserHistory.push(url);
};

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    const profile = parseProfile(props.user);

    this.state = {
      canSubmit: false,
      forceValidation: false,
      leave: false,
      validations: {},
      values: {},
      countryCode: props.countryCode,
      spainOverseas: props.spainOverseas,
      ...profile,
    };

    this.formsy = React.createRef();
  }

  componentDidMount() {
    const {
      location: { query },
      getDrafts,
    } = this.props;

    if (query.publish) getDrafts();
    this.handleChange(null, true);
  }

  UNSAFE_componentWillReceiveProps({
    error,
    fetching,
    user,
    countryCode,
    spainOverseas,
  }) {
    if (fetching) return;
    if (error)
      UserFeedback.exception("Something went wrong", error.message, error);

    const profile = parseProfile(user);
    this.setState({ ...profile, countryCode, spainOverseas });
  }

  componentWillUnmount() {
    modal.remove("tags");
    if (!this.props.location.query.publish) this.props.resetRoomList();
  }

  onLeavingForm = (nextLocation) => {
    const { canSubmit, leave } = this.state;
    const { handleLeaveForm } = this.props;
    const locationState = nextLocation.state;
    if (leave || (locationState && locationState.force)) return true;

    handleLeaveForm((_, execRedirect) => {
      if (canSubmit) {
        execRedirect();
      }

      setTimeout(() => {
        openCompleteProfileModal({
          onSuccess: () => execRedirect(),
          from: COMPLETE_PROFILE_FROM.EDIT,
        });
      }, 300);
    });

    return false;
  };

  leaveForm = (nextLocation, modalId) => {
    this.setState({ leave: !!nextLocation }, () => {
      if (modalId) modal.remove(modalId);
      if (nextLocation) cancelClick(nextLocation.pathname);
    });
  };

  formValueChecker = { tags: tagListFieldHasValues };

  enableSubmit = () => {
    this.setState({ canSubmit: true });
  };

  disableSubmit = () => {
    this.setState({ canSubmit: false });
  };

  clearSavedField = () => {
    setTimeout(() => {
      if (!this.state.leave) this.setState({ savedField: null });
    }, 2000);
  };

  saveCountry = () => {
    const { setCountryVat, updateCountryVat, countryCode } = this.props;

    if (countryCode !== "DEFAULT") {
      return updateCountryVat({
        countryCode: this.state.countryCode,
        spainOverseas: this.state.spainOverseas,
      });
    }
    return setCountryVat({
      countryCode: this.state.countryCode,
      spainOverseas: this.state.spainOverseas,
    });
  };

  saveChanges = ({ name, value }) => {
    if (["countryCode", "spainOverseas"].indexOf(name) >= 0) {
      this.setState({ ...value });
      return;
    }

    const updateCallback = () =>
      this.setState({ savedField: name }, this.clearSavedField);
    this.props.updateProfile(name, value).then(updateCallback);
  };

  handleChange = (currentValues, changed) => {
    if (!this.formsy.current || !changed) return;
    const { hasValues, validations, values } = validateFormChange(
      this.formsy.current.inputs,
      this.formValueChecker,
    );

    this.setState({ values: hasValues, validations, ...values });
  };

  handleInvalidForm = () => {
    const { canSubmit, forceValidation } = this.state;

    if (!canSubmit) {
      if (forceValidation) this.scrollToInvalidField();
      else this.setState({ forceValidation: true }, this.scrollToInvalidField);
    }
  };

  handleSubmit = (data) => {
    if (!this.state.canSubmit) return;
    const { saveProfile, nextStep, onComplete, isSingleStep } = this.props;

    this.saveCountry();

    saveProfile(data).then(() => {
      if (isSingleStep) {
        return onComplete();
      }
      return nextStep();
    });
  };

  scrollToInvalidField = () => {
    const { validations } = this.state;
    const validationsNames = Object.keys(validations);

    let inputName = validationsNames.find((name) => !validations[name]) || "";
    inputName = inputName === "lastName" ? "name" : inputName;

    if (inputName === "pictures") {
      scrollTo(0, 200);
      return;
    }

    const element = document.getElementById(`formblock-${inputName}`);
    if (element) scrollTo(element.offsetTop, 200);
  };

  render() {
    const {
      forceValidation,
      name,
      lastName,
      gender,
      birthDate,
      languages,
      occupation,
      pictures,
      aboutMe,
      work,
      couple,
      savedField,
      studies,
      tags,
      validations,
      canSubmit,
      countryCode,
      spainOverseas,
      socialProfiles,
    } = this.state;

    const { isSingleStep, user, token } = this.props;

    return (
      <Spacer md={{ top: 4 }} top={2}>
        <div className="Profile">
          <Formsy
            onChange={this.handleChange}
            onInvalid={this.disableSubmit}
            onSubmit={this.handleSubmit}
            onValid={this.enableSubmit}
            ref={this.formsy}
          >
            <Container>
              <Row className="Profile__form--header-mobile">
                <Col>
                  <h1 className="Profile__form--title">
                    {translate("profile.form.title")}
                  </h1>
                  <Spacer top={2}>
                    <Text>{translate("profile.form.subtitle")}</Text>
                  </Spacer>
                </Col>
              </Row>
              <Row>
                <Col className="Profile__form" lg={6} md={12}>
                  <Row className="Profile__form--body">
                    <Col lg={{ size: 10 }} md={12}>
                      <Spacer bottom={2}>
                        <div className="Profile__form--title">
                          <h1>{translate("profile.form.title")}</h1>
                          <Spacer top={2}>
                            <Text>{translate("profile.form.subtitle")}</Text>
                          </Spacer>
                        </div>
                      </Spacer>
                      <Section.Name
                        isValid={isBlockValid(
                          ["name", "lastName"],
                          forceValidation,
                          validations,
                        )}
                        lastName={lastName}
                        name={name}
                        saved={
                          savedField === "name" || savedField === "lastName"
                        }
                      />
                      <Section.BirthDate
                        birthDate={birthDate}
                        isValid={isBlockValid(
                          ["birthDate"],
                          forceValidation,
                          validations,
                        )}
                        saved={savedField === "birthDate"}
                      />
                      <Section.Country
                        country={countryCode}
                        isValid={isBlockValid(
                          ["countryCode", "spainOverseas"],
                          forceValidation,
                          validations,
                        )}
                        saved={
                          savedField === "countryCode" ||
                          savedField === "spainOverseas"
                        }
                        spainOverseas={spainOverseas}
                        token={token}
                      />
                      <Section.Gender
                        gender={gender}
                        isValid={isBlockValid(
                          ["gender"],
                          forceValidation,
                          validations,
                        )}
                        saved={savedField === "gender"}
                      />
                      <Section.Occupation
                        isValid={isBlockValid(
                          ["occupation"],
                          forceValidation,
                          validations,
                        )}
                        occupation={occupation}
                        saved={savedField === "occupation"}
                      />
                      <Section.Studies
                        isStudent={occupation === 1 || occupation === 3}
                        isValid={isBlockValid(
                          "studies",
                          forceValidation,
                          validations,
                        )}
                        saved={savedField === "studies"}
                        studies={studies}
                      />
                      <Section.Work
                        hasAJob={occupation >= 2}
                        isValid={isBlockValid(
                          "work",
                          forceValidation,
                          validations,
                        )}
                        saved={savedField === "work"}
                        work={work}
                      />
                      <Section.Languages
                        isValid={isBlockValid(
                          "languages",
                          forceValidation,
                          validations,
                        )}
                        languages={languages}
                        saved={savedField === "languages"}
                      />
                      {!user.company && <Section.Couple couple={couple} />}
                      <Section.Interests
                        saved={savedField === "tags"}
                        tags={tags}
                      />
                      <Section.SocialProfiles
                        saved={savedField === "social-profiles"}
                        socialProfiles={socialProfiles}
                      />
                      <Section.AboutMe
                        aboutMe={aboutMe}
                        isValid={isBlockValid(
                          "aboutMe",
                          forceValidation,
                          validations,
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Section.Pictures
                  isValid={isBlockValid(
                    "pictures",
                    forceValidation,
                    validations,
                  )}
                  pictures={pictures}
                />
              </Row>
            </Container>
            <ProfileFooterRoot>
              <Container>
                <Spacer bottom={1} top={1}>
                  <Flex
                    direction="column"
                    gutter={1}
                    justify="spaceBetween"
                    md={{
                      justify: "flexEnd",
                      direction: "row",
                      gutter: 2,
                      alignItems: "stretch",
                    }}
                    style={{ zIndex: 1 }}
                  >
                    <Button
                      data-qa="previous-step"
                      onClick={this.onLeavingForm}
                      variant="secondary"
                    >
                      {translate("profile.form.button.cancel")}
                    </Button>
                    <Button
                      data-qa="submit-step"
                      disabled={!canSubmit}
                      onClick={this.handleInvalidForm}
                      type="submit"
                      variant="primary"
                    >
                      {isSingleStep
                        ? translate("profile.form.button.submit")
                        : translate("button.text.continue")}
                    </Button>
                  </Flex>
                </Spacer>
              </Container>
            </ProfileFooterRoot>
          </Formsy>
        </div>
      </Spacer>
    );
  }
}

ProfileForm.defaultProps = {
  error: null,
  isSingleStep: true,
};

export default ProfileForm;
