import createReducer from "base/shared/createReducer";
import Types from "./types";
import { OverviewModel } from "./models";
import { parseRoomCard, parseSections, parseListingsStatuses } from "./utils";

function overviewSummaryRequest(state) {
  return {
    ...state,
    fetchingSummary: true,
    fetchedSummary: false,
    error: null,
  };
}

function overviewSummaryError(state, action) {
  return { ...state, fetchingSummary: false, error: action.error };
}

function overviewSummarySuccess(state, action) {
  const { heading, sections } = action.result;
  return {
    ...state,
    fetchingSummary: false,
    error: null,
    fetchedSummary: true,
    heading,
    sections: parseSections(sections),
  };
}

function initialOverviewCardsRequest(state) {
  return {
    ...state,
    roomsOverview: {
      ...state.roomsOverview,
      fetchingInitialOverviewCards: true,
      fetchedInitialOverviewCards: false,
    },
  };
}

function initialOverviewCardsError(state, action) {
  const { error } = action;
  return {
    ...state,
    roomsOverview: {
      ...state.roomsOverview,
      fetchingInitialOverviewCards: false,
      error,
    },
  };
}

function initialOverviewCardsSuccess(state, action) {
  const {
    data: { rooms_cards: roomsCards },
    pagination: { current_page: currentPage, total_pages: totalPages },
  } = action.result;

  const parsedRoomsCards = roomsCards.map((card) => parseRoomCard(card));

  return {
    ...state,
    roomsOverview: {
      ...state.roomsOverview,
      roomsCards: parsedRoomsCards,
      currentPage,
      isNextPageAvailable: currentPage < totalPages,
      fetchingInitialOverviewCards: false,
      fetchedInitialOverviewCards: true,
    },
  };
}

function nextOverviewCardsRequest(state) {
  return {
    ...state,
    roomsOverview: {
      ...state.roomsOverview,
    },
  };
}

function nextOverviewCardsError(state, action) {
  const { error } = action;
  return {
    ...state,
    roomsOverview: {
      ...state.roomsOverview,
      error,
    },
  };
}

function nextOverviewCardsSuccess(state, action) {
  const {
    data: { rooms_cards: roomsCards },
    pagination: { current_page: currentPage, total_pages: totalPages },
  } = action.result;

  const parsedRoomsCards = roomsCards.map((card) => parseRoomCard(card));
  const roomsCardsUpdated = [...state.roomsOverview.roomsCards].concat(
    parsedRoomsCards,
  );

  return {
    ...state,
    roomsOverview: {
      ...state.roomsOverview,
      roomsCards: roomsCardsUpdated,
      currentPage,
      isNextPageAvailable: currentPage < totalPages,
    },
  };
}

function listingsStatusesRequest(state) {
  return {
    ...state,
    listingsStatuses: {
      ...state.listingsStatuses,
      fetchingListingsStatuses: true,
      fetchedListingsStatuses: false,
    },
  };
}

function listingsStatusesError(state, action) {
  const { error } = action;
  return {
    ...state,
    listingsStatuses: {
      ...state.listingsStatuses,
      fetchingListingsStatuses: false,
      error,
    },
  };
}

function listingsStatusesSuccess(state, action) {
  const { action_items: listingsStatuses } = action.result;

  return {
    ...state,
    listingsStatuses: {
      ...state.listingsStatuses,
      list: parseListingsStatuses(listingsStatuses),
      fetchingListingsStatuses: false,
      fetchedListingsStatuses: true,
    },
  };
}

function resetOverview() {
  return { ...OverviewModel };
}

const actionHandlers = {
  [Types.OVERVIEW_SUMMARY_ERROR]: overviewSummaryError,
  [Types.OVERVIEW_SUMMARY_REQUEST]: overviewSummaryRequest,
  [Types.OVERVIEW_SUMMARY_SUCCESS]: overviewSummarySuccess,
  [Types.INITIAL_ROOMS_OVERVIEW_SUMMARY_REQUEST]: initialOverviewCardsRequest,
  [Types.INITIAL_ROOMS_OVERVIEW_SUMMARY_ERROR]: initialOverviewCardsError,
  [Types.INITIAL_ROOMS_OVERVIEW_SUMMARY_SUCCESS]: initialOverviewCardsSuccess,
  [Types.NEXT_ROOMS_OVERVIEW_SUMMARY_REQUEST]: nextOverviewCardsRequest,
  [Types.NEXT_ROOMS_OVERVIEW_SUMMARY_ERROR]: nextOverviewCardsError,
  [Types.NEXT_ROOMS_OVERVIEW_SUMMARY_SUCCESS]: nextOverviewCardsSuccess,
  [Types.OVERVIEW_LISTINGS_STATUSES_REQUEST]: listingsStatusesRequest,
  [Types.OVERVIEW_LISTINGS_STATUSES_ERROR]: listingsStatusesError,
  [Types.OVERVIEW_LISTINGS_STATUSES_SUCCESS]: listingsStatusesSuccess,
  [Types.RESET_OVERVIEW]: resetOverview,
};

export default createReducer(actionHandlers, { ...OverviewModel });
