import React from "react";
import { Flex, Spacer } from "@badi/badi-components";

import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { archiveInboxConversation } from "datamodel/Conversation/api";
import { translate } from "base/shared/Localization";
import Report from "components/Report/Report";
import ReportIds from "components/Report/ReportIds";
import ContextualButton from "components/ContextualButton";
import RoomPreview from "components/room-preview";
import { openLeaveConnectionConfirmModal } from "../leave-connection";
import { ConnectionDetailsRoot } from "./styled-components";

const modalReportId = MODAL_ID.Report;

const ConnectionDetails = ({
  allowedActions,
  connectionId,
  isInvite = false,
  onLeaveConnection,
  room,
  token,
  user,
  status,
}) => {
  const handleArchiveConnectionClick = () => {
    archiveInboxConversation(connectionId, token).then(onLeaveConnection);
  };

  const handleLeaveConnectionClick = () => {
    openLeaveConnectionConfirmModal({
      connectionId,
      user,
      onLeave: onLeaveConnection,
    });
  };

  const handleReportClick = () => {
    const reportIds = new ReportIds(0, user.id);

    modal.add(
      <Report
        callback={() => modal.remove(modalReportId)}
        ids={reportIds}
        token={token}
      />,
      {
        className: "Report--Modal",
        id: modalReportId,
      },
    );
  };

  const { archive, leave, report } = allowedActions;

  return (
    <ConnectionDetailsRoot data-qa="connection-details">
      <RoomPreview
        isInvite={isInvite}
        room={room}
        token={token}
        user={user}
        status={status}
      />
      <Spacer bottom={4} top={4}>
        <Flex direction="column" gutter={1}>
          {archive && (
            <ContextualButton
              icon="archive"
              onClick={handleArchiveConnectionClick}
              text={translate("conversation.archive")}
            />
          )}
          {report && (
            <ContextualButton
              icon="report"
              onClick={handleReportClick}
              text={translate("Report user")}
            />
          )}
          {leave && (
            <ContextualButton
              icon="leave"
              onClick={handleLeaveConnectionClick}
              text={translate("conversation.leave")}
            />
          )}
        </Flex>
      </Spacer>
    </ConnectionDetailsRoot>
  );
};

export default ConnectionDetails;
