import React from "react";
import classNames from "classnames";
import { Col } from "reactstrap";

import "./ListItemExt.css";

const LandingListItem = ({
  className,
  decoratorClassName,
  description,
  disposition,
  id,
  title,
}) => (
  <Col
    className={classNames("LandingListItem", className)}
    {...disposition}
    tag="li"
  >
    <div
      className={classNames(
        "LandingListItem__Decorator",
        decoratorClassName
          ? `${decoratorClassName} ${decoratorClassName}--${id}`
          : null,
      )}
    />
    <h3 className="LandingListItem__Title">{title}</h3>
    <p>{description}</p>
  </Col>
);

LandingListItem.defaultProps = {
  className: null,
  decoratorClassName: null,
  description: null,
  disposition: {},
  title: null,
};

export default LandingListItem;
