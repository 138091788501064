import React from "react";
import { withFormsy } from "formsy-react";
import TextArea from "./TextArea";

class TextAreaWithFormsy extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.value !== nextProps.value ||
      this.props.required !== nextProps.required
    ) {
      return true;
    }
    return false;
  }

  handleValueChange = (value) => {
    const { isValidValue, onChange, setValue } = this.props;
    const key = this.textarea.name;

    setValue(value);
    if (onChange) onChange(key, value, isValidValue(value));
  };

  render() {
    const { getValue, ...rest } = this.props;
    const safeValue = getValue();

    return (
      <TextArea
        {...rest}
        onChange={this.handleValueChange}
        setReference={(node) => {
          this.textarea = node;
        }}
        value={safeValue}
      />
    );
  }
}

TextAreaWithFormsy.defaultProps = {
  onChange: undefined,
  required: false,
};

export default withFormsy(TextAreaWithFormsy);
