import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { getVatCountry, setVatCountry, updateVatCountry } from "./api";

export const getCountryAction = () => ({
  types: generateFetchTypes(Types.GET_COUNTRY_VAT_REQUEST),
  request: getVatCountry,
  requireAuth: true,
});

export const setCountryAction = ({ countryCode, spainOverseas }) => ({
  types: generateFetchTypes(Types.SET_COUNTRY_VAT_REQUEST),
  request: setVatCountry,
  params: { countryCode, spainOverseas },
  requireAuth: true,
});

export const updateCountryAction = ({ countryCode, spainOverseas }) => ({
  types: generateFetchTypes(Types.UPDATE_COUNTRY_VAT_REQUEST),
  request: updateVatCountry,
  params: { countryCode, spainOverseas },
  requireAuth: true,
});
