import styled, { css } from "styled-components";
import {
  Text,
  colors,
  unitizedPx,
  mediaquery,
  Flex,
} from "@badi/badi-components";

export const SearchForm = styled.form`
  padding: ${unitizedPx(5)} 0 ${unitizedPx(8)} 0;
  overflow-y: auto;
`;

export const FormSection = styled.div`
  padding-left: ${unitizedPx(2)};
  padding-right: ${unitizedPx(2)};
`;

export const FormActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${unitizedPx(8)};
  background-color: ${colors.white};
  box-shadow: 0 0 ${unitizedPx(2)} 0 ${colors.softGrey};
`;

export const FormSectionChoices = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${unitizedPx(2)};

  ${mediaquery.sm`
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${unitizedPx(2)};
`}
`;

export const FormSectionChoiceElement = styled.div`
  width: 100%;
  margin-top: ${unitizedPx(1)};

  ${mediaquery.sm`
    width: 31%;
`}
`;

export const StyledForm = styled.form`
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
`;

export const SubmitFormWrapper = styled.div`
  float: right;
`;

export const UpperCasedText = styled(Text)`
  text-transform: uppercase;
`;

export const StyledFlex = styled(Flex)`
  & > div {
    width: 100%;
    ${mediaquery.md`
      width : 50%;
    `}
  }
`;

export const MultiChoice = styled.div`
  margin-bottom: ${unitizedPx(2)};
  ${mediaquery.md`
    ${(props) =>
      props.even &&
      css`
        margin-right: ${unitizedPx(1)};
      `}
    ${(props) =>
      !props.even &&
      css`
        margin-left: ${unitizedPx(1)};
      `}
  `}
  label > div {
    padding-right: ${unitizedPx(2)};
  }
`;
