import React from "react";

import { translate } from "base/shared/Localization";

import { UserCardBlock } from "../styled-components";

const AboutMe = ({ isCompany = false, text }) => {
  const title = isCompany ? "user.section.aboutagency" : "user.section.aboutme";

  return (
    <UserCardBlock>
      <h3>{translate(title)}</h3>
      <p>{text}</p>
    </UserCardBlock>
  );
};

export default AboutMe;
