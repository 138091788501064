import { BADI_COLORS } from "@badi/badi-components";

export const getNeutralColor = (colorId) => {
  switch (colorId) {
    case "neutral-00":
      return BADI_COLORS.NEUTRAL.NEUTRAL_00;
    case "neutral-05":
      return BADI_COLORS.NEUTRAL.NEUTRAL_05;
    case "neutral-10":
      return BADI_COLORS.NEUTRAL.NEUTRAL_10;
    case "neutral-50":
      return BADI_COLORS.NEUTRAL.NEUTRAL_50;
    case "neutral-80":
      return BADI_COLORS.NEUTRAL.NEUTRAL_80;
    default:
      return BADI_COLORS.NEUTRAL.NEUTRAL_00;
  }
};

export const getAdditionalColor = (colorId) => {
  switch (colorId) {
    case "additional-01":
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_01;
    case "additional-02":
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_02;
    case "additional-03":
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_03;
    case "additional-04":
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_04;
    case "additional-05":
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_05;
    case "additional-06":
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_06;
    default:
      return BADI_COLORS.ADDITIONAL.ADDITIONAL_01;
  }
};

export const getGradientColor = (colorId) => {
  switch (colorId) {
    case "gradient-01":
      return BADI_COLORS.GRADIENT.GRADIENT_01;
    case "gradient-02":
      return BADI_COLORS.GRADIENT.GRADIENT_02;
    default:
      return BADI_COLORS.GRADIENT.GRADIENT_01;
  }
};

export const getRestColors = (colorId) => {
  switch (colorId) {
    case "primary":
      return BADI_COLORS.BRAND.PRIMARY.DEFAULT;
    case "secondary":
      return BADI_COLORS.BRAND.SECONDARY.DEFAULT;
    case "tertiary":
      return BADI_COLORS.BRAND.TERTIARY.DEFAULT;
    case "success":
      return BADI_COLORS.SEMANTIC.SUCCESS.DEFAULT;
    case "warning":
      return BADI_COLORS.SEMANTIC.WARNING.DEFAULT;
    case "danger":
      return BADI_COLORS.SEMANTIC.DANGER.DEFAULT;
    case "info":
      return BADI_COLORS.SEMANTIC.INFO.DEFAULT;
    case "semantic.info.lightest":
      return BADI_COLORS.SEMANTIC.INFO.LIGHTEST;
    default:
      return BADI_COLORS.BRAND.PRIMARY.DEFAULT;
  }
};

export const getColor = (colorId) => {
  switch (true) {
    case colorId.includes("neutral"):
      return getNeutralColor(colorId);
    case colorId.includes("additional"):
      return getAdditionalColor(colorId);
    case colorId.includes("gradient"):
      return getGradientColor(colorId);
    default:
      return getRestColors(colorId);
  }
};
