import createReducer from "base/shared/createReducer";

import Types from "./types";
import { RecommendationsModel } from "./models";
import { parseRecommendationsArray, makeRecommendationSet } from "./utils";

function recommendationsRequest(state) {
  return { ...state, fetching: true, fetched: false };
}

function recommendationsError(state, action) {
  const { error } = action;
  return { ...state, fetching: false, error };
}

function recommendationsSuccess(state, action) {
  const list = parseRecommendationsArray(action.result);

  return {
    ...state,
    fetching: false,
    fetched: true,
    list,
    error: null,
  };
}

function setRecommendationStatusRequest(state) {
  return state;
}

function setRecommendationStatusError(state) {
  return state;
}

function setRecommendationStatusSuccess(state) {
  return state;
}

function recommendationRemove(state, action) {
  const { id } = action;

  return {
    ...state,
    list: makeRecommendationSet(state.list, id),
  };
}

function recommendationsReset() {
  return {
    ...RecommendationsModel,
  };
}

const actionHandlers = {
  [Types.RECOMMENDATIONS_REQUEST]: recommendationsRequest,
  [Types.RECOMMENDATIONS_SUCCESS]: recommendationsSuccess,
  [Types.RECOMMENDATIONS_ERROR]: recommendationsError,
  [Types.RECOMMENDATION_SET_STATUS_REQUEST]: setRecommendationStatusRequest,
  [Types.RECOMMENDATION_SET_STATUS_SUCCESS]: setRecommendationStatusSuccess,
  [Types.RECOMMENDATION_SET_STATUS_ERROR]: setRecommendationStatusError,
  [Types.RECOMMENDATION_REMOVE]: recommendationRemove,
  [Types.RECOMMENDATIONS_RESET]: recommendationsReset,
};

export default createReducer(actionHandlers, { ...RecommendationsModel });
