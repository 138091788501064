import React, { PureComponent, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import Formsy from "formsy-react";
import moment from "moment";
import {
  InlineMessage,
  MESSAGE_TYPES,
  Text,
  Flex,
  Button,
  Spacer,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { ROOM_MIN_DAYS_STAY, CLEANING_OPTIONS } from "datamodel/Room/constants";
import { parseBenefits } from "datamodel/RoomList/utils";
import RadioButtonGroup from "components/Form/RadioButton/RadioButtonGroupWithFormsy";

import FormBlock from "components/form-block";
import DatePicker from "components/Form/DatePicker/DatePickerWithFormsy";
import CheckBox from "components/Form/checkbox-with-formsy";
import Input from "components/Form/Input/InputWithFormsy";
import Select from "components/Form/Select/SelectWithFormsy";
import { ONBOARDING_ROLE } from "components/onboarding/constants";
import ButtonsWrapper from "../buttons-wrapper";
import {
  getAvailableStartDate,
  buildOptions,
  getMaximumStayValue,
  getAvailableTo,
  setMaxAvailableOptions,
} from "../../utils";
import DecorativeRightImage from "../decorative-right-image";
import { FormCompoundTitle, FormCompoundExtra } from "../styled-components";
import { AGENCY_FEE } from "./constants";

const dateValidations = {
  isExisty: true,
  isMoment: true,
};

const dateErrors = {
  isExisty: translate("Mandatory field"),
  isMoment: translate("Please select a valid date"),
};

class RoomListRoom extends PureComponent {
  constructor(props) {
    super(props);

    const {
      availableFrom,
      availableTo,
      availableToEnabled,
      benefits,
      billsIncluded,
      minimumStay,
      maximumStay,
      price,
      areaPrice,
      noDeposit,
      deposit,
      currency,
      hasAgencyFee,
    } = props.storeRoom;

    const availableFromSafe = availableFrom === null ? moment() : availableFrom;
    const parsedBenefits = parseBenefits(benefits);

    this.state = {
      availableFrom: availableFromSafe,
      availableTo,
      availableToEnabled,
      billsIncluded,
      benefits: parsedBenefits,
      currency,
      minimumStay,
      maximumStay,
      price,
      areaPrice,
      noDeposit,
      deposit,
      availableStartDate: getAvailableStartDate(availableFromSafe, minimumStay),
      canSubmit: false,
      cleaningServiceSelected:
        parsedBenefits && !!parsedBenefits.cleaningServices,
      hasAgencyFee,
    };

    this.agencyFeeOptions = translate(AGENCY_FEE);

    this.minStayOptions = buildOptions(24);
    this.maxStayOptions = setMaxAvailableOptions(
      buildOptions(24, true),
      minimumStay,
    );
  }

  onChange = (formData) => {
    const {
      availableFrom,
      availableToEnabled,
      cleaningServices,
      cleaningServiceSelected,
      maintenance,
      minimumStay,
      maximumStay: maximumStayValue,
      noDeposit,
      deposit: depositValue,
      registrationAvailable,
      rentalContract,
      hasAgencyFee,
    } = formData;

    const availableStartDate = getAvailableStartDate(
      availableFrom,
      minimumStay,
    );

    const deposit = noDeposit ? "" : depositValue;
    const maximumStay = getMaximumStayValue(
      availableToEnabled,
      maximumStayValue,
      minimumStay,
    );

    const benefits = {
      cleaningServices: cleaningServiceSelected ? cleaningServices : false,
      maintenance,
      registrationAvailable,
      rentalContract,
    };

    this.maxStayOptions = setMaxAvailableOptions(
      buildOptions(24, true),
      minimumStay,
    );

    this.setState({
      ...formData,
      deposit,
      availableTo: getAvailableTo(
        formData.availableTo,
        availableStartDate,
        minimumStay,
        this.state.minimumStay,
        formData.availableToEnabled,
      ),
      maximumStay,
      availableStartDate,
      hasAgencyFee,
      noDeposit,
      benefits,
      cleaningServiceSelected,
    });
  };

  getFormData = () => {
    const {
      availableFrom,
      availableTo,
      availableToEnabled,
      billsIncluded,
      currency,
      minimumStay,
      maximumStay,
      price,
      areaPrice,
      noDeposit,
      deposit,
      benefits,
      hasAgencyFee,
    } = this.state;

    return {
      availableFrom,
      availableTo,
      availableToEnabled,
      billsIncluded,
      currency,
      minimumStay,
      maximumStay,
      price,
      areaPrice,
      noDeposit,
      deposit,
      benefits,
      hasAgencyFee,
    };
  };

  getEndDateStart = () => this.state.availableStartDate;

  isEndDayBlocked = (day) => day < this.state.availableStartDate;

  handleSubmit = () => {
    const { setRoomRoom, nextStep } = this.props;

    const payload = this.getFormData();
    setRoomRoom(payload);
    nextStep(payload);
  };

  handleGoPreviousStep = () => {
    const { setRoomRoom, previousStep } = this.props;

    const payload = this.getFormData();

    setRoomRoom(payload);
    previousStep(payload);
  };

  disableSubmit = () => {
    this.setState({ canSubmit: false });
  };

  enableSubmit = () => {
    this.setState({ canSubmit: true });
  };

  render() {
    const {
      availableFrom,
      availableTo,
      availableToEnabled,
      billsIncluded,
      canSubmit,
      currency,
      minimumStay,
      maximumStay,
      price,
      areaPrice,
      noDeposit,
      deposit,
      benefits,
      cleaningServiceSelected,
      hasAgencyFee,
    } = this.state;
    const {
      showGermanDisclaimer,
      isEntiredProperty,
      sizeUnit: { label: sizeUnitLabel },
      onboardingRole,
    } = this.props;

    const isMinStayOneMonth = minimumStay === ROOM_MIN_DAYS_STAY;
    const {
      rentalContract,
      cleaningServices,
      registrationAvailable,
      maintenance,
    } = benefits;

    const isAgency = onboardingRole === ONBOARDING_ROLE.PROFESSIONAL;

    return (
      <Spacer bottom={12} top={4}>
        <Container>
          <Row>
            <Col className="RoomList__form" lg={6} md={12}>
              <Formsy
                onChange={this.onChange}
                onInvalid={this.disableSubmit}
                onSubmit={this.handleSubmit}
                onValid={this.enableSubmit}
              >
                <Row className="RoomList__form--body">
                  <Col lg={{ size: 10, offset: 1 }} md={12}>
                    <Flex direction="column" gutter={5}>
                      <FormBlock
                        isRequired={true}
                        title={translate("listing.setup.availability.title")}
                      >
                        <Container fluid={true}>
                          <Row>
                            <Col style={{ paddingLeft: "0" }} xs={6}>
                              <p
                                style={{
                                  lineHeight: "24px",
                                  marginBottom: "16px",
                                }}
                              >
                                {translate("room.list.step3.when.from")}
                              </p>
                              <DatePicker
                                name="availableFrom"
                                validationErrors={dateErrors}
                                validations={dateValidations}
                                value={availableFrom}
                              />
                            </Col>
                            <Col
                              style={{ paddingRight: "0", paddingLeft: "0" }}
                              xs={6}
                            >
                              <CheckBox
                                checked={availableToEnabled}
                                name="availableToEnabled"
                                value={availableToEnabled}
                              >
                                <Text body="16-Regular" inline={true}>
                                  {translate("room.list.step3.when.to")}
                                </Text>
                              </CheckBox>
                              <DatePicker
                                disabled={!availableToEnabled}
                                initialVisibleMonth={this.getEndDateStart}
                                isDayBlocked={this.isEndDayBlocked}
                                name="availableTo"
                                value={availableTo}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ paddingLeft: "0" }} xs={6}>
                              <p
                                style={{
                                  lineHeight: "24px",
                                  margin: "16px 0px",
                                }}
                              >
                                {translate("room.list.step3.availability.min")}
                              </p>
                              <Select
                                className="centered-flex-row"
                                name="minimumStay"
                                options={this.minStayOptions}
                                value={minimumStay}
                              />
                            </Col>
                            <Col
                              style={{ paddingRight: "0", paddingLeft: "0" }}
                              xs={6}
                            >
                              <p
                                style={{
                                  lineHeight: "24px",
                                  margin: "16px 0px",
                                }}
                              >
                                {translate("room.list.step3.availability.max")}
                              </p>
                              <Select
                                className="centered-flex-row"
                                disabled={availableToEnabled}
                                name="maximumStay"
                                options={this.maxStayOptions}
                                value={maximumStay}
                              />
                            </Col>
                          </Row>
                          {showGermanDisclaimer && isMinStayOneMonth && (
                            <Row>
                              <InlineMessage variant={MESSAGE_TYPES.INFO}>
                                <Text body={3}>
                                  {translate(
                                    "listroom.disclaimer.berlin_regulation",
                                  )}
                                </Text>
                              </InlineMessage>
                            </Row>
                          )}
                        </Container>
                      </FormBlock>

                      <FormBlock
                        isRequired={true}
                        title={translate("room.list.step3.price.title")}
                      >
                        <Input
                          digitsType="number"
                          label={`${currency}/${translate("month")}`}
                          maxlength={9}
                          name="price"
                          required={true}
                          value={price}
                        />
                        <CheckBox
                          checked={billsIncluded}
                          name="billsIncluded"
                          value={billsIncluded}
                        >
                          <Text body="16-Regular" inline={true}>
                            {translate("room.list.step3.price.billsIncluded")}
                          </Text>
                        </CheckBox>
                      </FormBlock>
                      {isEntiredProperty && (
                        <FormBlock
                          title={translate("room.list.step3.areaPrice.title")}
                        >
                          <Input
                            dataQa="price-per-square-unit"
                            digitsType="number"
                            grow={1}
                            label={`${currency}/${sizeUnitLabel}`}
                            maxlength={9}
                            name="areaPrice"
                            value={areaPrice}
                          />
                        </FormBlock>
                      )}

                      <FormBlock
                        isRequired={true}
                        title={translate("room.list.step3.deposit.title")}
                      >
                        <CheckBox
                          checked={noDeposit}
                          name="noDeposit"
                          required={!deposit}
                          value={noDeposit}
                        >
                          <Text body="16-Regular" inline={true}>
                            {translate("room.list.step3.deposit.checkbox")}
                          </Text>
                        </CheckBox>
                        <Input
                          className={"deposit"}
                          digitsType="number"
                          disabled={noDeposit}
                          label={currency}
                          maxlength={9}
                          name="deposit"
                          required={!noDeposit}
                          value={deposit}
                        />
                      </FormBlock>

                      {isAgency && (
                        <FormBlock
                          isRequired={true}
                          title={translate("room.list.step3.agencyFee.title")}
                        >
                          <RadioButtonGroup
                            dataQa="has-agency-fee"
                            items={this.agencyFeeOptions}
                            name="hasAgencyFee"
                            required={true}
                            value={hasAgencyFee}
                          />
                        </FormBlock>
                      )}

                      <FormBlock
                        title={
                          <Fragment>
                            <FormCompoundTitle>
                              {translate("listing.setup.services.title")}
                            </FormCompoundTitle>
                            <FormCompoundExtra>{`(${translate(
                              "listing.setup.services.optional",
                            )})`}</FormCompoundExtra>
                          </Fragment>
                        }
                      >
                        <Container fluid={true}>
                          <Row>
                            <Col style={{ paddingLeft: "0" }} xs={12}>
                              <Spacer bottom={2.5} top={0.5}>
                                <CheckBox
                                  checked={rentalContract}
                                  name="rentalContract"
                                  value={rentalContract}
                                >
                                  <Text body="16-Regular" inline={true}>
                                    {translate(
                                      "listing.setup.services.contract",
                                    )}
                                  </Text>
                                </CheckBox>
                              </Spacer>
                              <Spacer bottom={2.5}>
                                <CheckBox
                                  checked={cleaningServiceSelected}
                                  name="cleaningServiceSelected"
                                  value={cleaningServiceSelected}
                                >
                                  <Text body="16-Regular" inline={true}>
                                    {translate(
                                      "listing.setup.services.cleaning",
                                    )}
                                  </Text>
                                </CheckBox>
                                {cleaningServiceSelected && (
                                  <Select
                                    name="cleaningServices"
                                    options={CLEANING_OPTIONS.map((option) => ({
                                      title: translate(
                                        `listing.setup.services.cleaning.${option.key}`,
                                      ),
                                      value: option.value,
                                    }))}
                                    placeholder={translate(
                                      "listing.setup.services.cleaning.placeholder",
                                    )}
                                    required={true}
                                    value={cleaningServices}
                                  />
                                )}
                              </Spacer>
                              <Spacer bottom={2.5}>
                                <CheckBox
                                  checked={registrationAvailable}
                                  name="registrationAvailable"
                                  value={registrationAvailable}
                                >
                                  <Text body="16-Regular" inline={true}>
                                    {translate(
                                      "listing.setup.services.registration",
                                    )}
                                  </Text>
                                </CheckBox>
                              </Spacer>
                              <CheckBox
                                checked={maintenance}
                                name="maintenance"
                                value={maintenance}
                              >
                                <Text body="16-Regular" inline={true}>
                                  {translate(
                                    "listing.setup.services.maintenance",
                                  )}
                                </Text>
                              </CheckBox>
                            </Col>
                          </Row>
                        </Container>
                      </FormBlock>
                    </Flex>
                  </Col>
                </Row>
                <ButtonsWrapper>
                  <Flex gutter={2}>
                    <Button
                      basis="50%"
                      fullWidth={true}
                      name="back"
                      onClick={this.handleGoPreviousStep}
                      variant="secondary"
                    >
                      {translate("Back")}
                    </Button>
                    <Button
                      basis="50%"
                      disabled={!canSubmit}
                      fullWidth={true}
                      name="next"
                      type="submit"
                    >
                      {translate("Continue")}
                    </Button>
                  </Flex>
                </ButtonsWrapper>
              </Formsy>
            </Col>
            <DecorativeRightImage
              alt="Room decorative image"
              src="/assets/images/roomlist-room.svg"
            />
          </Row>
        </Container>
      </Spacer>
    );
  }
}

RoomListRoom.defaultProps = {
  showGermanDisclaimer: false,
};

export default RoomListRoom;
