import React from "react";
import { Flex, Pagination } from "@badi/badi-components";

const ListingsListActions = ({
  children = null,
  currentPage,
  onSelectPage,
  rooms,
  totalPages,
}) => {
  return (
    <Flex alignItems="center" justify={children ? "spaceBetween" : "flexEnd"}>
      {children}
      <Flex alignItems="center">
        {!!rooms.length && totalPages > 1 && (
          <Pagination
            compact={true}
            currentPage={currentPage}
            onSelectPage={onSelectPage}
            totalPages={totalPages}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ListingsListActions;
