import styled from "styled-components";
import { BADI_COLORS, Flex, unitizedPx } from "@badi/badi-components";

export const Root = styled.div`
  min-width: ${unitizedPx(33.75)};
  height: 100%;
  position: relative;
  padding: ${unitizedPx(1)};
  border-left: 1px solid rgb(240, 240, 240);
`;

export const AdditionalActions = styled(Flex)`
  position: absolute;
  width: ${unitizedPx(25)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: ${unitizedPx(0.5)};
  border: solid 1px ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  z-index: 5;
  top: ${unitizedPx(22.5)};
  left: ${unitizedPx(2.5)};

  > * {
    border-bottom: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
    padding: ${unitizedPx(1)};

    :last-child {
      border-bottom: none;
    }
  }
`;
