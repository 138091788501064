import React from "react";
import { Spacer } from "@badi/badi-components";

import { Root } from "./styled-components";

const BlockSeparator = ({ children, dataQa = null, space = 3 }) => (
  <Root data-qa={dataQa}>
    <Spacer bottom={space} top={space}>
      {children}
    </Spacer>
  </Root>
);

export default BlockSeparator;
