export default class BookingPrice {
  constructor(entity) {
    this.entity = entity;
  }

  get discount() {
    return this.entity.discount;
  }

  get fee() {
    return this.entity.fee;
  }

  get netFee() {
    return this.entity.net_fee;
  }

  get total() {
    return this.entity.total_amount;
  }

  get refundTotal() {
    return this.entity.refund_plan_price_with_currency || null;
  }

  get method() {
    // depending on the role that will be the price displayed
    return (
      this.entity.payout_method_description ||
      this.entity.payment_method_description
    );
  }

  toJSON() {
    return {
      discount: this.discount,
      fee: this.fee,
      netFee: this.netFee,
      method: this.method,
      total: this.total,
      refundTotal: this.refundTotal,
    };
  }
}
