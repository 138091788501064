import { COMPLETE_PROFILE_FROM } from "./constants";

export const getContent = (from) => {
  switch (from) {
    case COMPLETE_PROFILE_FROM.DRAFT:
      return {
        title: "completeprofile.draftBeforeProfile.title",
        text: ["completeprofile.draftBeforeProfile.p1"],
        skipButton: "completeprofile.cancel",
        acceptButton: "completeprofile.accept",
      };
    case COMPLETE_PROFILE_FROM.ROOM_DETAIL:
      return {
        title: "Complete your profile",
        text: ["room.detail.header.modal.profile.description"],
        skipButton: "completeprofile.skip",
        acceptButton: "completeprofile.accept",
      };
    case COMPLETE_PROFILE_FROM.EDIT:
      return {
        title: "profile.form.cancel.title",
        text: ["profile.form.cancel.description"],
        skipButton: "profile.form.cancel.button.back",
        acceptButton: "profile.form.cancel.button.leave",
      };
    default:
      return {
        title: "completeprofile.title",
        text: ["completeprofile.p1", "completeprofile.p2"],
        skipButton: "completeprofile.skip",
        acceptButton: "completeprofile.accept",
      };
  }
};
