import React, { useState } from "react";
import { BADI_COLORS, Badge } from "@badi/badi-components";
import { toggleFavorite } from "datamodel/favorites/utils";

import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";

import FavoriteButtonContent from "./favorite-toggle-button-content";
import { FEEDBACK_TITLE, FEEDBACK_DESCRIPTION } from "./constants";
import { RoomHeaderButton } from "../../styled-components";

const FavoriteToggleButton = ({ token, id, favorited }) => {
  const [isFavorited, setIsFavorited] = useState(favorited);
  const [isDisabled, setIsDisabled] = useState(false);

  const showFeedback = () => {
    UserFeedback.success(
      translate(FEEDBACK_TITLE),
      translate(FEEDBACK_DESCRIPTION),
    );
  };

  const handleFavoriteResponse = async () => {
    const isFavoritedValue = isFavorited;
    try {
      setIsFavorited(!isFavoritedValue);
      await toggleFavorite(token, id, isFavoritedValue);
      if (!isFavorited) showFeedback();
    } catch (e) {
      setIsFavorited(isFavoritedValue);
    }
  };

  const handleOnClick = async () => {
    setIsDisabled(true);
    await handleFavoriteResponse();
    setIsDisabled(false);
  };

  return (
    <Badge
      as={RoomHeaderButton}
      backgroundColor={BADI_COLORS.NEUTRAL.NEUTRAL_00}
      data-qa="room-details-favorite-button"
      disabled={isDisabled}
      onClick={handleOnClick}
      rounded={false}
    >
      <FavoriteButtonContent isFavorited={isFavorited} />
    </Badge>
  );
};

export default FavoriteToggleButton;
