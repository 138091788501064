import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import SearchRoomsActions from "datamodel/search-rooms/actions";
import Search from "./search-v2";

const SearchRoute = ({ params, location }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(Actions.reset());
      dispatch(SearchRoomsActions.reset());
    };
  }, []);

  return <Search location={location} params={params} />;
};

SearchRoute.requiredActions = [Actions.setParams];

export default SearchRoute;
