export default class SearchPreferences {
  constructor(data) {
    this.data = data || {};
  }

  get budget() {
    return this.data.budget || null;
  }

  get location() {
    const {
      location: {
        q: label = null,
        place_id: placeId = null,
        living_there: livingThere = null,
      } = {},
    } = this.data;
    return {
      label,
      placeId,
      livingThere,
    };
  }

  get moveInDate() {
    return this.data.move_in_date || null;
  }

  get moveInType() {
    return this.data.move_in_type || null;
  }

  get propertyType() {
    return this.data.property_type || null;
  }

  get moveInGroup() {
    return this.data.move_in_group || null;
  }

  toJSON() {
    return {
      budget: this.budget,
      location: this.location,
      moveInDate: this.moveInDate,
      moveInType: this.moveInType,
      propertyType: this.propertyType,
      moveInGroup: this.moveInGroup,
    };
  }
}
