import React from "react";
import { translate } from "base/shared/Localization";
import DefaultLayout from "./layout";
import CompactedLayout from "./compacted-layout";

const NotStarted = ({
  date,
  roomDetails,
  time,
  userImage,
  compact = false,
}) => {
  const Layout = compact ? CompactedLayout : DefaultLayout;

  return (
    <Layout
      description={translate("videocall.not.started_text", {
        visit_date: `${date}, ${time}`,
      })}
      image="/assets/images/not-found.svg"
      roomDetails={roomDetails}
      title={translate("videocall.not.started_title")}
      userImage={userImage}
    />
  );
};

export default NotStarted;
