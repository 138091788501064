import React, { Component } from "react";
import Geosuggest from "react-geosuggest";

import { getGeoLocation } from "base/shared/geo-location";
import { getCityLocation } from "Sections/Room/components/location-step/utils";
import { HEADER_PARAMS } from "base/shared/tracking-headers";

import {
  getCity,
  getSuggestType,
  getRecentSearches,
  parseSuggest,
  saveRecentSearch,
  isPrefilled,
} from "./utils";

import { DEFAULT_RADIUS_GEOSUGGEST } from "./constants";
import RecentSuggests from "./components/recent-suggest-list";
import Nearby from "./components/nearby";
import SuggestItem from "./components/suggest-item";
import { GeosuggestWrapper } from "./styled-components";

class GeoSuggest extends Component {
  constructor(props) {
    super(props);

    this.geosuggest = React.createRef();

    this.state = {
      recentSearches: props.defaultsHidden ? [] : getRecentSearches(),
      location: null,
      etParam: null,
    };
  }

  componentDidMount() {
    const { routerEventsListener, focus } = this.props;
    if (focus) this.focusInput();

    if (routerEventsListener) {
      const { etParam } = this.state;
      this.unlisten = routerEventsListener(etParam);
    }

    if ("geolocation" in navigator && "permissions" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(({ state: permissionState }) => {
          if (permissionState === "granted") {
            getGeoLocation({
              onSuccess: this.setGeolocation,
              onError: () => {},
            });
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialValue, prefillableSearch, routerEventsListener } =
      this.props;
    const { etParam } = this.state;
    if (
      routerEventsListener &&
      (etParam !== prevState.etParam ||
        isPrefilled(etParam, initialValue, prefillableSearch))
    ) {
      const { SEARCH_BAR: defaultEtParam } =
        HEADER_PARAMS.SEARCH_SOURCES.options;
      const param = etParam || defaultEtParam;

      this.unlisten();
      this.unlisten = routerEventsListener(param);
    }
  }

  onFocus = () => {
    if (!this.state.recentSearches.length) {
      this.setState({ recentSearches: getRecentSearches() });
    }
    this.props.onFocus();
  };

  onSelect = (suggest) => {
    if (!suggest) {
      this.props.onSelectCallback();
      return;
    }

    if (this.props.onSuggestSelect) this.props.onSuggestSelect(suggest);

    if (this.geosuggest && this.geosuggest.current) {
      this.geosuggest.current.update(suggest.label);
      this.geosuggest.current.hideSuggests();
    }
  };

  onSuggestSelect = (suggest) => {
    const parsedSuggest = parseSuggest(suggest);

    const {
      options: { SEARCH_BAR },
    } = HEADER_PARAMS.SEARCH_SOURCES;

    if (this.props.recent) {
      const recentSearches = saveRecentSearch(
        this.state.recentSearches,
        parsedSuggest,
      );

      this.setState({ recentSearches, etParam: SEARCH_BAR }, () =>
        this.onSelect({ ...parsedSuggest, etParam: SEARCH_BAR }),
      );
    } else {
      this.setState({ etParam: SEARCH_BAR }, () =>
        this.onSelect({ ...parsedSuggest, etParam: SEARCH_BAR }),
      );
    }
  };

  onRecentSelect = (suggest) => {
    const {
      options: { RECENT_SEARCH },
    } = HEADER_PARAMS.SEARCH_SOURCES;

    this.setState({ etParam: RECENT_SEARCH }, () => {
      this.onSelect({
        ...suggest,
        etParam: HEADER_PARAMS.SEARCH_SOURCES.options.RECENT_SEARCH,
      });
    });
  };

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  onLocationSelect = (suggest) => {
    const parsedSuggest = parseSuggest(suggest);

    const {
      options: { NEARBY_SEARCH },
    } = HEADER_PARAMS.SEARCH_SOURCES;

    if (this.props.recent) {
      const recentSearches = saveRecentSearch(
        this.state.recentSearches,
        parsedSuggest,
      );
      this.setState({ recentSearches, etParam: NEARBY_SEARCH }, () =>
        this.onSelect({ ...parsedSuggest, etParam: NEARBY_SEARCH }, false),
      );
    }
  };

  setGeolocation = ({ latitude: lat, longitude: lng }) => {
    const latLng = { lat, lng };
    const location = new google.maps.LatLng(latLng);
    this.setState({ location });
  };

  getSuggestLabelWithType = (suggest) => {
    const type = getSuggestType(suggest.type);

    return <SuggestItem description={suggest.description} type={type} />;
  };

  focusInput = () => {
    if (this.geosuggest && this.geosuggest.current) {
      this.geosuggest.current.input.focus();
    }
  };

  render() {
    const {
      className,
      includeTypeLabels,
      nearby,
      recent,
      defaultsHidden,
      placeDetailFields,
      suggestsClassName,
      extendedWidth,
      ...rest
    } = this.props;
    const { recentSearches, location } = this.state;
    const renderSuggestItem = includeTypeLabels
      ? this.getSuggestLabelWithType
      : undefined;

    const remappedLocation = getCityLocation(rest);
    const radius =
      remappedLocation || location ? DEFAULT_RADIUS_GEOSUGGEST : null;

    const suggestsListClassName = `${suggestsClassName} ${
      extendedWidth && "extendedWidth"
    }`;

    return (
      <GeosuggestWrapper>
        <Geosuggest
          {...rest}
          autoActivateFirstSuggest={true}
          autoComplete="off"
          className={className}
          defaultsHidden={defaultsHidden}
          location={remappedLocation || location}
          minLength={3}
          onFocus={this.onFocus}
          onSuggestSelect={this.onSuggestSelect}
          placeDetailFields={placeDetailFields}
          queryDelay={400}
          radius={radius}
          ref={this.geosuggest}
          renderSuggestItem={renderSuggestItem}
          suggestsClassName={suggestsListClassName}
        >
          {nearby && (
            <Nearby onClick={this.onLocationSelect} onError={this.focusInput} />
          )}
          {recent && (
            <RecentSuggests
              onSelect={this.onRecentSelect}
              suggests={recentSearches}
            />
          )}
        </Geosuggest>
      </GeosuggestWrapper>
    );
  }
}

GeoSuggest.defaultProps = {
  className: "",
  defaultsHidden: true,
  disabled: false,
  extendedWidth: true,
  focus: false,
  includeTypeLabels: false,
  initialValue: "",
  inputClassName: "",
  loadingClassName: null,
  nearby: false,
  onChange: () => {},
  onFocus: () => {},
  onSelectCallback: () => {},
  placeDetailFields: ["formatted_address", "address_components", "types"],
  placeholder: "",
  prefillableSearch: false,
  queryDelay: 100,
  recent: false,
  suggestsClassName: "",
};

export default GeoSuggest;
