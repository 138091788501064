import React, { PureComponent } from "react";
import classNames from "classnames";

import SelectItem from "./SelectItem";
import "./SelectExt.css";

class Select extends PureComponent {
  render() {
    const {
      className,
      disabled,
      name,
      onChange,
      options,
      placeholder,
      placeholderValue,
      required,
      selectClassName,
      value,
      icon,
      dataQa,
    } = this.props;

    return (
      <div
        className={classNames("Form__Select--wrapper", { disabled }, className)}
      >
        <select
          className={classNames(
            selectClassName,
            { disabled },
            { "Form__Select--select-active": !!value },
            { "Form__Select--select-with-icon": !!icon },
          )}
          data-qa={dataQa}
          disabled={disabled}
          name={name}
          onChange={onChange}
          required={required}
          value={value}
        >
          {!!placeholder && (
            <option disabled={true} selected={true} value={placeholderValue}>
              {placeholder}
            </option>
          )}
          {options.map((o) => (
            <SelectItem dataQa={dataQa} key={o.value} {...o} name={name} />
          ))}
        </select>
        {!!icon && (
          <div className={classNames("Form__Select--icon")}>{icon}</div>
        )}
      </div>
    );
  }
}

Select.defaultProps = {
  className: "",
  disabled: false,
  icon: null,
  placeholder: "",
  placeholderValue: "",
  required: false,
  selectClassName: null,
  value: "",
  dataQa: "",
};

export default Select;
