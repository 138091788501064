import React from "react";

import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import PhoneVerification from "./phone-verification-mapping";
import { VARIANTS } from "./constants";

export const closePhoneVerificationModal = () => {
  modal.remove(MODAL_ID.Phone);
};

export const openPhoneVerificationModal = ({
  onSuccess = () => {},
  onClose = () => {},
}) => {
  modal.add(
    <PhoneVerification
      onSuccess={() => {
        closePhoneVerificationModal();
        onSuccess();
      }}
      variant={VARIANTS.LIGHT}
    />,
    {
      id: MODAL_ID.Phone,
      closable: true,
      closeOnOutsideClick: true,
      onClose: () => {
        closePhoneVerificationModal();
        onClose();
      },
    },
  );
};
