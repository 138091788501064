import React, { useState } from "react";
import {
  Checkbox as CheckboxBadiComponent,
  Spacer,
} from "@badi/badi-components";

const Checkbox = ({
  checked,
  setValue,
  name,
  children = null,
  dataQa = "",
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleOnChange = () => {
    setValue(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <Spacer bottom={2}>
      <CheckboxBadiComponent
        checked={isChecked}
        data-qa={dataQa}
        id={name}
        name={name}
        onChange={handleOnChange}
      >
        {children}
      </CheckboxBadiComponent>
    </Spacer>
  );
};

export default Checkbox;
