import {
  AUTH_TOKEN_INVALID,
  AUTH_TOKEN_BANNED,
} from "datamodel/Auth/constants";
import { AUTH_STATUS } from "./constants";

export const getAuthStatus = (error) => {
  const { OK, INVALID, BANNED, ERROR } = AUTH_STATUS;

  if (!error) {
    return OK;
  }

  switch (error.code) {
    case AUTH_TOKEN_INVALID:
      return INVALID;
    case AUTH_TOKEN_BANNED:
      return BANNED;
    default:
      return ERROR;
  }
};
