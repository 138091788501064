import React, { Fragment } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getRelativePath } from "base/shared/Utils";
import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";
import { isLister } from "datamodel/User/utils";

import TextButton from "components/Button/TextButton";
import SwitchIcon from "../switch-icon";
import MenuWrapper from "./menu-wrapper";
import { Avatar, MenuLink, Root } from "./styled-components";

const UserMenu = ({ logout, mode, picture, switchMode = null }) => {
  const lister = isLister(mode);
  const switchText = translate(
    `topbar.element.${lister ? "switchseeker" : "switchrenter"}.title`,
  );

  return (
    <Root>
      <Avatar
        data-qa="topbar-menu-avatar"
        lister={lister}
        picture={picture}
        to={getRelativePath("/my-profile")}
      />
      <MenuWrapper>
        <MenuLink
          data-qa="topbar-menu-my-profile"
          to={getRelativePath("/my-profile")}
        >
          {translate("topbar.element.myprofile.title")}
        </MenuLink>
        <MenuLink
          data-qa="topbar-menu-settings"
          to={getRelativePath("/settings/notifications")}
        >
          {translate("topbar.element.settings.title")}
        </MenuLink>
        {lister && (
          <MenuLink
            data-qa="topbar-menu-booking-settings"
            to={getRelativePath("/booking-settings")}
          >
            {translate("topbar.element.booking.title")}
          </MenuLink>
        )}
        {!lister && (
          <MenuLink
            data-qa="topbar-menu-seeker-preferences"
            to={getRelativePath("/preferences")}
          >
            {translate("topbar.element.seekerPreferences.title")}
          </MenuLink>
        )}
        <TextButton dataQa="topbar-menu-logout" name="logout" onClick={logout}>
          {translate("topbar.element.logout.title")}
        </TextButton>
        {switchMode && (
          <Fragment key="switch">
            <TextButton dataQa="topbar-menu-switch" onClick={switchMode}>
              {switchText}
            </TextButton>
            <Spacer left={2}>
              <SwitchIcon height={28} width={24} />
            </Spacer>
          </Fragment>
        )}
      </MenuWrapper>
    </Root>
  );
};

export default UserMenu;
