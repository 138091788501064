import TimeSlots from "./timeslots-model";

class VisitProposal {
  constructor(data) {
    this.entity = data;
  }

  get category() {
    return this.entity.visits_category;
  }

  get days() {
    return this.entity.dayslots;
  }

  /**
   * @deprecated Since version 2 of visits - Use mornings, afternoons and evenings instead.
   */
  get times() {
    return this.entity.timeslots.map((timeslot) =>
      new TimeSlots(timeslot).toJSON(),
    );
  }

  get mornings() {
    if (!this.entity.morning_timeslots) {
      return [];
    }

    return this.entity.morning_timeslots.map((timeslot) =>
      new TimeSlots(timeslot).toJSON(),
    );
  }

  get afternoons() {
    if (!this.entity.afternoon_timeslots) {
      return [];
    }

    return this.entity.afternoon_timeslots.map((timeslot) =>
      new TimeSlots(timeslot).toJSON(),
    );
  }

  get evenings() {
    if (!this.entity.evening_timeslots) {
      return [];
    }

    return this.entity.evening_timeslots.map((timeslot) =>
      new TimeSlots(timeslot).toJSON(),
    );
  }

  toJSON() {
    return {
      afternoons: this.afternoons,
      category: this.category,
      days: this.days,
      evenings: this.evenings,
      mornings: this.mornings,
      /**
       * @deprecated Since version 2 of visits - Use mornings, afternoons and evenings instead.
       */
      times: this.times,
    };
  }
}

export default VisitProposal;
