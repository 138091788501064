import React from "react";
import { Col, Row } from "reactstrap";
import { translate } from "base/shared/Localization";
import { Heading, Spacer } from "@badi/badi-components";
import BehaviourCardWidget from "../widgets/behaviour-card-widget";
import { GRAPH_TYPES } from "../../constants";
import { getRoomPropertiesContent } from "../../utils";

const RoomPropertiesSection = ({ roomProperties }) => {
  const { numberOfPictures, numberOfAmenities, lengthOfDescription } =
    roomProperties;

  return (
    <Spacer top={3}>
      <Heading
        align="left"
        data-qa="room-properites-section-title"
        level="24-Bold"
      >
        {translate("lister.analytics_subtitle_room_properties")}
      </Heading>
      <Row className="align-items-end justify-content-center">
        <Col lg={4} xs={12}>
          <BehaviourCardWidget
            dataQa="room-properties-pictures"
            height={20}
            target={getRoomPropertiesContent(
              numberOfPictures,
              GRAPH_TYPES.PROPERTIES_PICTURES,
              true,
            )}
            title={translate("lister.analytics_room_properties_pictures")}
            value={getRoomPropertiesContent(
              numberOfPictures,
              GRAPH_TYPES.PROPERTIES_PICTURES,
            )}
          />
        </Col>
        <Col lg={4} xs={12}>
          <BehaviourCardWidget
            dataQa="room-properties-amenities"
            height={20}
            target={getRoomPropertiesContent(
              numberOfAmenities,
              GRAPH_TYPES.PROPERTIES_AMENITIES,
              true,
            )}
            title={translate("lister.analytics_room_properties_amenities")}
            value={getRoomPropertiesContent(
              numberOfAmenities,
              GRAPH_TYPES.PROPERTIES_AMENITIES,
            )}
          />
        </Col>
        <Col lg={4} xs={12}>
          <BehaviourCardWidget
            dataQa="room-properties-description"
            height={20}
            target={getRoomPropertiesContent(
              lengthOfDescription,
              GRAPH_TYPES.PROPERTIES_DESCRIPTION,
              true,
            )}
            title={translate("lister.analytics_room_properties_description")}
            value={getRoomPropertiesContent(
              lengthOfDescription,
              GRAPH_TYPES.PROPERTIES_DESCRIPTION,
            )}
          />
        </Col>
      </Row>
    </Spacer>
  );
};

export default RoomPropertiesSection;
