import React, { useState, useEffect } from "react";

import { LinkUppercase, Spacer, Flex, Text } from "@badi/badi-components";

import { SEEKER_USER_ROLE } from "datamodel/User/constants";
import { BOOKING_STATUS } from "datamodel/booking/constants";
import PaymentProvider from "components/PaymentProvider";
import PolicyCard from "components/policy-card";
import BookingPreview from "components/booking-preview";

import { translate } from "base/shared/Localization";
import StatusBadge from "../booking-status-badge";
import ExpirationBadge from "../booking-expiration-badge";
import BookingStepLayout from "../step-layout";

const PaymentFailStep = ({
  connectionId = 0,
  getPaymentDetails,
  hasPaymentCard = false,
  title = "",
  id,
  onValidation = () => {},
  bookingRequestDetail,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getPaymentDetails().then(() => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    onValidation({
      id,
      valid: hasPaymentCard,
    });
  }, [hasPaymentCard]);

  const {
    expirationText,
    moveIn,
    moveOut,
    price = {},
    room,
    lister,
    status,
    lengthInMonths,
    exactMonthsLength,
    bookingValue,
  } = bookingRequestDetail;

  const hasFailed = status === BOOKING_STATUS.PAYMENT_REQUIRED;
  const { method, ...priceWithoutMethod } = price;

  return (
    <BookingStepLayout
      description={translate("recovery.payment_solve_text")}
      sidebar={[
        <Flex gutter={1} key="badges">
          <StatusBadge status={status} userRole={SEEKER_USER_ROLE} />
          {expirationText && <ExpirationBadge expiration={expirationText} />}
        </Flex>,
        <BookingPreview
          bookingValue={bookingValue}
          connectionId={connectionId}
          exactMonthsLength={exactMonthsLength}
          key="booking-preview"
          lengthInMonths={lengthInMonths}
          moveIn={moveIn}
          moveOut={moveOut}
          price={priceWithoutMethod}
          room={room}
          user={lister}
          userRole="seeker"
        />,
        <PolicyCard key="policy-card" />,
      ]}
      sidebarTitle={translate("recovery.payment_booking_request_details")}
      subtitle={translate("recovery.payment_solve_title")}
      title={translate(title)}
      titleDescription={translate("recovery.payment_subtitle")}
    >
      {loaded && hasFailed && <PaymentProvider />}
      <Spacer top={3}>
        <Text data-qa="step-fix">
          {translate("recovery.payment_solve_fixed")}
        </Text>
        <LinkUppercase
          data-qa="annotation-link"
          href={translate("recovery.payment_learn_more_link")}
          rel="noopener noreferrer"
          target="_blank"
        >
          {translate("recovery.payment_learn_more_link_text")}
        </LinkUppercase>
      </Spacer>
    </BookingStepLayout>
  );
};

export default PaymentFailStep;
