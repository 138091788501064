import React from "react";
import { translate } from "base/shared/Localization";

import { Text, Anchor, BADI_COLORS } from "@badi/badi-components";

const CancellationPolicyLink = () => {
  return (
    <div data-qa="cancellation-policy">
      <Text body="14-Regular" inline={true}>
        {translate("recovery.payment_checkout_cancellation_text")}{" "}
      </Text>
      <Anchor
        bodySize="14-Regular"
        color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
        decoration="underline"
        hover={false}
        href={translate("sitemap.tc.link")}
        target="_blank"
      >
        {translate("recovery.payment_checkout_cancellation_link")}
      </Anchor>
    </div>
  );
};

export default CancellationPolicyLink;
