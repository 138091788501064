import { connect } from "react-redux";

import Actions from "datamodel/Auth/actions";
import InboxActions from "datamodel/Inbox/actions";
import RequirementsActions from "datamodel/Requirements/actions";
import UserActions from "datamodel/User/actions";
import { getInboxConnectionsPerPage } from "datamodel/Inbox/selectors";
import { getUserMode } from "datamodel/User/utils";

import TopBar from "./top-bar";

const mapStateToProps = (state) => ({
  city: state.SearchFilters.text,
  inboxFetched: !!state.Inbox.fetched,
  isLister: state.User.lister,
  isLoggedIn: !!state.User.id,
  isSocketReady: !state.Socket.status,
  messages: !!state.Inbox.notificationsCount,
  mode: getUserMode(state.User.mode),
  picture: state.User.mainPicture,
  socket: state.Socket.socket,
  perPage: getInboxConnectionsPerPage(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUnreadConnections: () => dispatch(InboxActions.getUnreadConnections()),
  loginHandler: () => dispatch(RequirementsActions.setLogin(true)),
  logout: () => dispatch(Actions.logout()),
  registerHandler: () => dispatch(RequirementsActions.setRegister(true)),
  switchUser: (mode) =>
    dispatch(UserActions.setUserMode({ mode, isActiveSwitching: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
