import React from "react";
import { Flex, Heading } from "@badi/badi-components";
import { Text } from "app/badi-components";

import { translate } from "base/shared/Localization";

import { Title } from "./styled-components";

const Details = ({ price, title, neighborhood }) => {
  return (
    <Flex direction="column" fullHeight={true} gutter={0.5}>
      <Title as={Heading} data-qa="room-card-details-title" grow={1} level={4}>
        {title}
      </Title>
      {neighborhood && <Text body="12-Medium">{neighborhood}</Text>}
      <Flex gutter={0.5} md={{ gutter: 1 }}>
        <Text body={3} data-qa="room-card-details-price">
          {price}/{translate("month")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Details;
