import styled, { css } from "styled-components";
import { unitizedPx, hexToRgb } from "../helpers";
import { BADI_COLORS } from "../constants";
import { getBodyFont } from "../font-sizes";

export const TextAreaWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: ${unitizedPx(2)};
  position: relative;
  width: 335px;
`;

export const StyledText = styled.textarea`
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  box-sizing: border-box;
  padding: ${unitizedPx(2)} ${unitizedPx(2)};
  text-align: left;
  width: ${(props) => props.textWidth};
  height: ${(props) => props.textHeight};
  letter-spacing: 0;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  ${getBodyFont("14-Medium")};
  border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  border-radius: ${unitizedPx(1)};

  ${({ error }) =>
    error
      ? css`
          background-color: ${BADI_COLORS.SEMANTIC.DANGER.LIGHTEST};
          border: 1px solid ${BADI_COLORS.SEMANTIC.DANGER.LIGHTEST};
          color: ${BADI_COLORS.SEMANTIC.DANGER.DEFAULT};
          ${getBodyFont("14-Medium")};
        `
      : null}

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${hexToRgb(BADI_COLORS.NEUTRAL.NEUTRAL_05, 0.5)};
          color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
          ${getBodyFont("14-Regular")};
        `
      : null}

  ${({ inactive }) =>
    inactive
      ? css`
          color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
          ${getBodyFont("14-Regular")};
        `
      : null}

  &:focus {
    outline: none;
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
    border: ${({ borderColor }) =>
      `1px solid ${borderColor || BADI_COLORS.SEMANTIC.SUCCESS.DEFAULT};`};
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
    ${getBodyFont("14-Regular")};
  }
`;
