import React, { useContext } from "react";
import { Heading, Spacer } from "@badi/badi-components";
import Image from "components/Image";

import MenuContext from "./menu-context";
import { MenuButton, ImageWrapper } from "./styled-components";

const MenuItemButton = ({
  children,
  dataQa = null,
  onClick = null,
  imageSrc = null,
}) => {
  const { onClose } = useContext(MenuContext);

  const clickHandler = () => {
    onClose();
    onClick();
  };

  return (
    <MenuButton data-qa={dataQa} onClick={clickHandler}>
      <Spacer bottom={2} top={2}>
        <Heading as="span" level="16-Medium">
          {children}
        </Heading>
        {imageSrc && (
          <ImageWrapper>
            <Image src={imageSrc} />
          </ImageWrapper>
        )}
      </Spacer>
    </MenuButton>
  );
};

export default MenuItemButton;
