import React from "react";

import ToggleView from "components/toggle-list-map-button";
import { RootView, GradientSeparator, ViewWrapper } from "./styled-components";

const MapView = ({ onSelectView, view: currentView }) => {
  const handleClick = (view) => {
    onSelectView(view);
  };

  return (
    <RootView left={false}>
      <ViewWrapper>
        <ToggleView onViewChange={handleClick} view={currentView} />
        <GradientSeparator left={true} />
      </ViewWrapper>
    </RootView>
  );
};

export default MapView;
