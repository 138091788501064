import classNames from "classnames";
import moment from "moment";

export const getButtonClass = ({
  className,
  color,
  disabled,
  light,
  link,
  loading,
  restrain,
  size,
  underline,
  wide,
}) => {
  const cName = classNames(
    "Button",
    { [`Button__${color}`]: color },
    className,
    { Button__light: light },
    { Button__link: link },
    { Button__loading: loading },
    { Button__restrain: restrain },
    { [`Button__${size}`]: size },
    { Button__underline: underline },
    { Button__wide: wide },
    { Button__disabled: disabled },
  );

  return cName;
};

const formatTimeWithLeadingZeroToString = (number) => `0${number}`.slice(-2);

export const getTimeRemaining = (endtime) => {
  const t = Number(endtime) - Number(moment().format("X"));
  const seconds = formatTimeWithLeadingZeroToString(Math.floor(t % 60));
  const minutes = formatTimeWithLeadingZeroToString(Math.floor((t / 60) % 60));

  return `${minutes}:${seconds}`;
};
