import React from "react";
import { Flex } from "@badi/badi-components";
import MenuElement from "../menu-element";
import { AdditionalActions, Root } from "./styled-components";

const CardActionsDesktop = ({
  visibleActions,
  nonVisibleActions = [],
  renderActionsInMenu,
  isMoreActionsOpened,
  openMoreActionsMenu,
}) => {
  const openMoreActions = () => {
    openMoreActionsMenu("more");
  };

  return (
    <Root>
      {visibleActions.length && (
        <Flex direction="column" gutter={2}>
          {renderActionsInMenu(visibleActions)}
          {!!nonVisibleActions.length && (
            <MenuElement
              action={{
                key: "seeMore",
                icon: "/assets/icons/ml-dots.svg",
                text: "mylistings.moreActions",
              }}
              actionFunction={{ seeMore: openMoreActions }}
              dataQa="see-more"
            />
          )}
        </Flex>
      )}
      {isMoreActionsOpened && (
        <AdditionalActions direction="column">
          {renderActionsInMenu(nonVisibleActions)}
        </AdditionalActions>
      )}
    </Root>
  );
};

export default CardActionsDesktop;
