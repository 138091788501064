import React from "react";
import {
  BADI_COLORS,
  Heading,
  BREAKPOINTS_PX,
  Spacer,
  Flex,
  MatchMedia,
  Pagination,
  Choice,
  Text,
} from "@badi/badi-components";
import TopBar from "components/Layout/stepper-layout/top-bar";
import { translate } from "base/shared/Localization";
import {
  RoomPicture,
  ChoiceWrapper,
  ContentWrapper,
  TextWrapper,
} from "./styled-components";

const RoomSelector = ({
  roomId = null,
  roomItems,
  onSelect,
  onClose,
  pageDescription = "",
  pageTitle = "inbox.popup_select_room",
  currentPage = 1,
  totalPages = 1,
  onPageChange = () => {},
  withPagination = true,
}) => {
  const isElementSelected = (id) => {
    return roomId === id;
  };

  return (
    <Flex direction="column">
      <TopBar
        data-qa="room-selector-topbar"
        onClose={onClose}
        showProgress={false}
      />
      <Spacer
        bottom={8}
        left={2}
        lg={{ bottom: 8, left: 8, right: 16, top: 8 }}
        right={4}
        top={2}
      >
        <Flex direction="column" gutter={4}>
          <Flex direction="column" gutter={1}>
            <Heading
              color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
              data-qa="room-selector-heading"
            >
              {translate(pageTitle)}
            </Heading>
            {pageDescription && (
              <Text
                color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
                data-qa="room-selector-description"
              >
                {translate(pageDescription)}
              </Text>
            )}
          </Flex>
          <ContentWrapper>
            <Flex
              direction="column"
              gutter={2}
              md={{ direction: "row", wrap: "wrap", gutter: 2 }}
              wrap="nowrap"
            >
              {roomItems.map(({ picture, title, id }) => (
                <ChoiceWrapper data-qa={`room-selector-item-${id}`} key={id}>
                  <Choice
                    align="left"
                    checked={isElementSelected(id)}
                    height="80px"
                    id={`${id}`}
                    key={id}
                    name={title}
                    onChange={onSelect}
                    selectedBackgroundColor="#feeec3"
                    selectedBorderColor={BADI_COLORS.ADDITIONAL.ADDITIONAL_06}
                    type="radio"
                    unselectedBorderColor={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
                    value={id}
                    width="100%"
                  >
                    <Flex alignItems="center" gutter={2}>
                      <RoomPicture>
                        <img alt="room" src={picture} />
                      </RoomPicture>
                      <TextWrapper>
                        <Text
                          body="14-Regular"
                          color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
                        >
                          {title}
                        </Text>
                      </TextWrapper>
                    </Flex>
                  </Choice>
                </ChoiceWrapper>
              ))}
            </Flex>
          </ContentWrapper>
          {totalPages > 1 && withPagination && (
            <Spacer bottom={2}>
              <MatchMedia query={`(max-width: ${BREAKPOINTS_PX.sm})`}>
                {(match) => (
                  <Pagination
                    compact={match}
                    currentPage={currentPage}
                    onSelectPage={onPageChange}
                    totalPages={totalPages}
                  />
                )}
              </MatchMedia>
            </Spacer>
          )}
        </Flex>
      </Spacer>
    </Flex>
  );
};

export default RoomSelector;
