import React from "react";
import { BADI_COLORS, Flex, Heading, Text } from "@badi/badi-components";

const Fact = ({ description, value }) => {
  return (
    <Flex direction="column" gutter={1}>
      <Heading
        color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
        data-qa="fact-title"
        level="32-Extrabold"
      >
        {value}
      </Heading>
      <Text
        body="14-Regular"
        color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
        data-qa="fact-desc"
      >
        {description}
      </Text>
    </Flex>
  );
};

export default Fact;
