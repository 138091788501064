import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Flex } from "@badi/badi-components";

import Layer from "components/Layout/Layer/Layer";
import Image from "components/Image/Image";

const DecorativeRightImage = ({ src, alt }) => {
  return (
    <Layer className="RoomList__fixed d-none d-lg-block">
      <Container fluid={true}>
        <Row>
          <Col lg={{ size: 6, offset: 6 }}>
            <Flex justifyContent="center">
              <Image alt={alt} src={src} />
            </Flex>
          </Col>
        </Row>
      </Container>
    </Layer>
  );
};

export default DecorativeRightImage;
