import { locale } from "./Localization";
import { getLocalePath } from "./Utils";

export const getAppStoreUrl = () =>
  `https://itunes.apple.com${getLocalePath()}/app/badi-alquiler-habitaciones/id1008653746?mt=8`;

export const getPlayStoreUrl = () =>
  `https://play.google.com/store/apps/details?id=es.inmovens.badi&hl=${locale()}`;

const getVisibility = (os) => {
  if (!os) return { ios: false, android: false, desktop: true };

  const android = os === "Android";
  const ios = os === "iOS";

  return { android, ios, desktop: !android && !ios };
};

export const getLinks = (os) => {
  const links = [];
  const visibility = getVisibility(os);

  if (visibility.desktop || visibility.ios) {
    links.push({
      url: getAppStoreUrl(),
      icon: "apple-store.svg",
      description: "Apple store BadiApp",
    });
  }
  if (visibility.desktop || visibility.android) {
    links.push({
      url: getPlayStoreUrl(),
      icon: "google-play.svg",
      description: "Google play BadiApp",
    });
  }
  return links;
};

export const getDownloadAppLink = ({ ios }) =>
  ios ? getAppStoreUrl() : getPlayStoreUrl();
