import React, { useCallback } from "react";
import {
  Flex,
  LinkUppercase,
  Spacer,
  colors,
  Text,
} from "@badi/badi-components";

const TextAnnotation = ({ link = null, message, linkText = "" }) => {
  return (
    <Spacer bottom={4} top={4}>
      <Text
        align="center"
        body={3}
        color={colors.midGrey}
        data-qa="annotation-content"
      >
        {message}
      </Text>
      {link && (
        <Spacer top={1}>
          <Flex justify="center">
            <LinkUppercase
              data-qa="annotation-link"
              href={link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {linkText}
            </LinkUppercase>
          </Flex>
        </Spacer>
      )}
    </Spacer>
  );
};

export default TextAnnotation;
