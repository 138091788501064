import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "../types";
import {
  fetchAccount,
  setCountry as setCountryToApi,
  setPayoutsMethod as setPayoutsMethodToApi,
  updateAccount as updateAccountToApi,
} from "../api";

export const getAccount = () => ({
  types: generateFetchTypes(Types.PAY_ACCOUNT_REQUEST),
  request: fetchAccount,
  requireAuth: true,
});
export const setCountry = (country) => ({
  types: generateFetchTypes(Types.PAY_COUNTRY_SAVING_REQUEST),
  request: setCountryToApi,
  params: { country },
  requireAuth: true,
});
export const setPayoutsMethod = (bankAccount) => ({
  types: generateFetchTypes(Types.PAY_METHOD_SAVING_REQUEST),
  request: setPayoutsMethodToApi,
  params: bankAccount,
  requireAuth: true,
});
export const updateAccount = (method) => ({
  types: generateFetchTypes(Types.PAY_CONTACT_SAVING_REQUEST),
  request: updateAccountToApi,
  params: method,
  requireAuth: true,
});
export const resetPayouts = () => ({ type: Types.RESET_PAYOUTS });

export default {
  setPayoutsMethod,
  updateAccount,
  setCountry,
  getAccount,
  resetPayouts,
};
