const OverviewModel = {
  fetchingSummary: false,
  fetchedSummary: false,
  error: null,
  roomsOverview: {
    currentPage: 0,
    roomsCards: [],
    isNextPageAvailable: false,
    fetchingInitialOverviewCards: false,
    fetchedInitialOverviewCards: false,
  },
  listingsStatuses: {
    fetchedListingsStatuses: false,
    fetchingListingsStatuses: false,
    list: [],
  },
  currentPage: 1,
  totalPages: 0,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Overview) {
    return { ...OverviewModel, error: "There was an error in OverviewModel." };
  }
  return state;
}

export { OverviewModel, setInitialState };
