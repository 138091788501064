import React from "react";

import { getInboxPath } from "Sections/inbox/routes";
import VisitExpress from "./visit-express-mapping";

const VisitExpressRoute = ({ router, params }) => {
  const handleLeaveVisit = () => {
    router.push(getInboxPath());
  };

  return <VisitExpress id={params.id || 0} onLeaveVisit={handleLeaveVisit} />;
};

export default VisitExpressRoute;
