import React from "react";
import { Button, BUTTON_TYPES, Text, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import BookingCheckoutButton from "modules/stripe/booking-checkout";
import { openBookingBenefitsModal } from "components/booking-benefits";
import BookingFooter from "../footer";
import { ClickableText } from "./styled-components";

const ConfirmationCheckoutFooter = ({ bookingRequestId, onMessageClick }) => {
  const handleOpenModal = () => {
    openBookingBenefitsModal();
  };

  return (
    <BookingFooter>
      <Flex alignItems="center" fullHeight={true}>
        <Text
          as={ClickableText}
          body={3}
          data-qa="booking-is-free-copy"
          inline={true}
          onClick={handleOpenModal}
        >
          {translate("recovery.why.seeker.booking")}
        </Text>
      </Flex>
      {onMessageClick && (
        <Button
          data-qa="message-checkout-button"
          fullWidth={true}
          grow={1}
          onClick={onMessageClick}
          variant={BUTTON_TYPES.SECONDARY}
        >
          {translate("recovery.message_cta")}
        </Button>
      )}

      <BookingCheckoutButton
        bookingRequestId={bookingRequestId}
        callback={() => {}}
        dataQa="submit-booking-checkout-button"
        fullWidth={true}
        grow={1}
        variant="primary"
      >
        {translate("recovery.payment_checkout_cta")}
      </BookingCheckoutButton>
    </BookingFooter>
  );
};

export default ConfirmationCheckoutFooter;
