import RoomModel from "datamodel/Room/models/room-compact-model";
import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";
import UserModel from "./user-compact-model";
import VisitExpress from "./visit-express-model";

class Visit {
  constructor(data) {
    this.entity = data || {};
  }

  get visit() {
    return this.entity.visit || {};
  }

  get id() {
    return this.visit.id;
  }

  get bookingRequestId() {
    return this.entity.booking_request_id || 0;
  }

  get status() {
    return this.visit.status;
  }

  get actions() {
    return this.entity.overview_actions || [];
  }

  get isCurrentUserProposer() {
    if (!this.entity.overview_actions) return false;
    return !this.entity.overview_actions.some(
      ({ action }) => action === "confirm",
    );
  }

  get localStartAt() {
    return this.visit.local_start_at;
  }

  get localEndAt() {
    return this.visit.local_end_at;
  }

  get displayDate() {
    return this.visit.display_local_date;
  }

  get displayTime() {
    return this.visit.display_local_hour;
  }

  get roomAddress() {
    return this.visit.display_room_address;
  }

  get seeker() {
    const { seeker } = this.visit;
    return seeker ? new UserModel(seeker).toJSON() : null;
  }

  get lister() {
    const { room } = this.visit;
    if (!room) {
      return null;
    }

    const { lister } = room;
    return lister ? new UserModel(lister).toJSON() : null;
  }

  get message() {
    const { content, user } = this.visit.message?.body?.attributes || {};

    return user
      ? {
          content,
          userId: user.id,
          userRole:
            this.visit.seeker?.id === user.id
              ? SEEKER_USER_ROLE
              : LISTER_USER_ROLE,
        }
      : null;
  }

  get room() {
    const { room } = this.visit;
    return room ? new RoomModel(room).toJSON() : null;
  }

  get connectionId() {
    return this.visit.inbox_connection_id;
  }

  get virtualTourLink() {
    return this.entity.virtual_tour_link;
  }

  get express() {
    return !!this.visit.express;
  }

  get expressInfo() {
    const { express_info: expressInfo } = this.entity;
    return expressInfo ? new VisitExpress(expressInfo) : null;
  }

  toJSON() {
    const expressInfo = this.expressInfo;

    return {
      id: this.id,
      status: this.status,
      actions: this.actions,
      bookingRequestId: this.bookingRequestId,
      lister: this.lister,
      localStartAt: this.localStartAt,
      localEndAt: this.localEndAt,
      message: this.message,
      displayDate: this.displayDate,
      displayTime: this.displayTime,
      roomAddress: this.roomAddress,
      seeker: this.seeker,
      connectionId: this.connectionId,
      room: this.room,
      virtualTourLink: this.virtualTourLink,
      express: this.express,
      expressInfo: expressInfo && expressInfo.toJSON(),
      isCurrentUserProposer: this.isCurrentUserProposer,
    };
  }
}

export default Visit;
