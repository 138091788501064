import React, { memo } from "react";

import {
  BREAKPOINTS_PX,
  Heading,
  Flex,
  Spacer,
  useMatchMedia,
} from "@badi/badi-components";
import Close from "components/Icons/Close";
import ImgButton from "components/Button/ImageButton";

import { TopBarBase } from "components/Layout/TopBar";
import Logo from "components/Layout/TopBar/components/Logo";
import ProgressBar from "./progress-bar";

import { TopBarInner } from "./styled-components";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.lg})`];

const FormTopBar = ({ title, percentage = 0, onCloseForm = undefined }) => {
  const [isDesktop] = useMatchMedia(QUERIES);

  return (
    <TopBarBase withNavigationWrapper={false}>
      <TopBarInner>
        <Flex alignItems="center" fullHeight={true} justify="spaceBetween">
          <Flex>
            {isDesktop && <Logo />}
            <Spacer left={3}>
              <Heading level={3}>{title}</Heading>
            </Spacer>
          </Flex>
          {onCloseForm && (
            <ImgButton onClick={onCloseForm}>
              <Close format="medium" />
            </ImgButton>
          )}
        </Flex>
      </TopBarInner>
      <ProgressBar percentage={percentage} />
    </TopBarBase>
  );
};

export default memo(FormTopBar);
