import React, { Component } from "react";
import { withFormsy } from "formsy-react";

import TagList from "./TagList";

class TagListWithFormsy extends Component {
  onValueChange = (value) => {
    this.props.setValue(value);
  };

  render() {
    const { getValue, ...rest } = this.props;
    const safeValue = getValue();

    return (
      <TagList {...rest} value={safeValue} onChange={this.onValueChange} />
    );
  }
}

export default withFormsy(TagListWithFormsy);
