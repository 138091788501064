import { snakeToCamel } from "base/shared/Utils";

export const parseIconTypeSection = (section) => {
  if (!section || !section.elements) return section;
  const { elements: elementsToParse, ...rest } = section;
  const elements = elementsToParse.map(({ icon, content }) => ({
    icon: snakeToCamel(icon),
    content,
  }));
  return { elements, ...rest };
};
