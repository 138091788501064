import React from "react";
import {
  Heading,
  Text,
  Badge,
  Button,
  Spacer,
  Flex,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { PremiumLabel, usePremium } from "modules/premium";
import { PREMIUM_FEATURES } from "datamodel/premium/constants";

const TIPS = [
  {
    number: 1,
    heading: "recommendations.tips.first.title",
    description: "recommendations.tips.first.description",
  },
  {
    number: 2,
    heading: "recommendations.tips.second.title",
    description: "recommendations.tips.second.description",
  },
  {
    number: 3,
    heading: "recommendations.tips.third.title",
    description: "recommendations.tips.third.description",
  },
];

const RecommendationTips = ({ onClick }) => {
  const { isPremium } = usePremium({
    capabilityId: PREMIUM_FEATURES.RECOMMENDED_SEEKERS,
  });
  return (
    <div className="RecommendationTips--Wrapper">
      {isPremium && <PremiumLabel bottom={1} />}
      <Spacer bottom={1}>
        <Heading>{translate("recommendations.tips.header.title")}</Heading>
      </Spacer>
      <Spacer bottom={2}>
        <Text body={1}>
          {translate("recommendations.tips.header.description")}
        </Text>
      </Spacer>
      <Flex direction="column" gutter={3}>
        {TIPS.map((tip) => (
          <div>
            <Spacer bottom={1}>
              <Badge backgroundColor="#943ffa" rounded={true}>
                {tip.number}
              </Badge>
            </Spacer>
            <Heading level={4}>{translate(tip.heading)}</Heading>
            <Text body={3}>{translate(tip.description)}</Text>
          </div>
        ))}
      </Flex>
      <Spacer top={5}>
        <Flex justify="flexEnd">
          <Button onClick={onClick}>
            {translate("recommendations.tips.button")}
          </Button>
        </Flex>
      </Spacer>
    </div>
  );
};

export default RecommendationTips;
