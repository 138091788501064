import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const RoomCardWrapper = styled.div`
  width: ${unitizedPx(40)};
  margin: 0 auto;
  background-color: white;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
`;
