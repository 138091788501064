import React, { Component } from "react";
import { debounce } from "radash";
import badiPushNotifications from "lib/badi-push-notifications";
import moment from "moment";
import { BADI_COLORS, Toast } from "@badi/badi-components";
import { NotificationsManager } from "app/lib/web-notifications";
import { USER_ONBOARDING_TYPE, LEGAL_RESPONSE } from "datamodel/User/constants";
import "react-dates/initialize";
import { Recaptcha } from "lib/recaptcha";
import "base/shared/RegisterInterface";
import "base/shared/Validations";
import envVars from "base/shared/Env";
import { locale, setLocalization } from "base/shared/Localization";
import { EE } from "base/shared/Emitter";
import SocketMiddleware from "base/middleware/socket-middleware";
import { AUTH_REFRESH_TOKEN_EVENT } from "datamodel/Auth/constants";
import { isLegalPropAccepted } from "datamodel/User/utils";

import ReduxModal from "containers/ReduxModal";
import AuthTokenManager from "Sections/auth/token-manager";
import Requirements from "Sections/auth/requirements";
import RedirectController from "Sections/auth/redirect-controller";
import SwitchSpinner from "components/SwitchSpinner";

import * as Utils from "./utils";
import Head from "./Head";
import "../../styles";

export class App extends Component {
  constructor(props) {
    super(props);

    const { AuthModel, phrases } = this.props;

    this.state = {
      isSwitchingTo: null,
    };

    this.createSocket = this.createSocket.bind(this);
    this.switchingUserMode = this.switchingUserMode.bind(this);
    this.debouncedCreateSocket = debounce({ delay: 500 }, this.createSocket);

    this.isLoadingSocket = false;

    if (typeof window !== "undefined") {
      setLocalization(this.props.locale, phrases);
      this.debouncedCreateSocket(AuthModel.access_token, AuthModel.user_id);
    }

    moment.locale(locale());
  }

  componentDidMount() {
    const {
      AuthModel,
      isGdprAnswered,
      setGDPR,
      User,
      router,
      location: { pathname },
      arePushNotificationsEnabled,
      arePushNotificationsSupported,
      getCountryVat,
    } = this.props;
    const { lister, mode, id } = User;

    Utils.setAuthCookie({ ...AuthModel, lister, mode });

    badiPushNotifications.initialize(id, locale(), {
      arePushNotificationsEnabled,
      arePushNotificationsSupported,
    });

    window.addEventListener("scroll", Utils.onWindowScroll);

    Utils.setLanguageCookie();

    EE.on(AUTH_REFRESH_TOKEN_EVENT, () => {
      this.props.setInvalidToken();
    });

    if (AuthModel.user_id) {
      getCountryVat(AuthModel.access_token);

      if (isGdprAnswered) {
        this.setTermsAndOnbording(this.props);
      } else {
        setGDPR({ visible: true });
      }
    }

    Recaptcha.setUserId(id);
    Recaptcha.init(envVars().BADI_RECAPTCHA_SITE_ID);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { AuthModel, arePushNotificationsEnabled } = this.props;
    if (nextProps.fetchingFeatures || nextProps.AuthModel.error) return;

    const nextUserId = nextProps.AuthModel.user_id;

    Utils.shouldInitializePushNotifications(
      AuthModel.user_id,
      nextUserId,
      arePushNotificationsEnabled,
      nextProps.arePushNotificationsEnabled,
      nextProps.arePushNotificationsSupported,
    );

    if (Utils.authHasBeenUpdated(AuthModel, nextProps.AuthModel)) {
      const { lister, mode } = nextProps.User;
      Utils.setAuthCookie({
        ...nextProps.AuthModel,
        lister,
        mode,
      });

      if (this.checkRefreshToken(nextProps)) return;

      Recaptcha.setUserId(nextUserId);

      this.getUserInfo(nextProps);
    }

    this.checkUserInfo(nextProps);
    this.checkUserMode(this.props, nextProps);
    this.checkSocket(nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", Utils.onWindowScroll);
  }

  async getUserInfo(nextProps) {
    const {
      AuthModel,
      socket,
      resetMe,
      resetNotifications,
      resetPayment,
      resetPayouts,
      resetReviews,
      resetRequirements,
      getFeatures,
      getMe,
      getCountryVat,
      getLegal,
      getOnboarding,
      getPremiumCapabilities,
    } = nextProps;
    if (this.props.AuthModel.user_id && !AuthModel.user_id) {
      resetMe();
      resetNotifications();
      resetPayouts();
      resetPayment();
      resetRequirements();
      resetReviews();
      Utils.resetCookies();
      getFeatures({ token: AuthModel.access_token, isLoggedUser: false });

      if (!Utils.isSocketClosed(socket)) {
        socket.close();
      }
    } else if (AuthModel.user_id) {
      getMe(AuthModel.access_token);
      getCountryVat(AuthModel.access_token);
      await getFeatures({ token: AuthModel.access_token, isLoggedUser: true });
      getLegal(AuthModel.access_token);
      getOnboarding(AuthModel.access_token);
      getPremiumCapabilities();
    }
  }

  setTermsAndOnbording = (props) => {
    const { setRequirements, termsAndConditions, User } = props;
    const onboardingType = User.onboarding.type;

    setRequirements({
      termsAndConditions: {
        show: !isLegalPropAccepted(termsAndConditions),
      },
      onboarding: {
        show:
          onboardingType === USER_ONBOARDING_TYPE.GENERAL ||
          onboardingType === USER_ONBOARDING_TYPE.LISTER,
      },
    });
  };

  checkSocket(nextProps) {
    const { AuthModel, socket, socketError, socketStatus } = nextProps;
    if (Utils.isSocketClosed(socket) && !socketError) {
      this.debouncedCreateSocket(AuthModel.access_token, AuthModel.user_id);
    } else if (this.props.socketStatus !== socketStatus && socketStatus !== 2) {
      this.isLoadingSocket = false;
    }
  }

  checkUserInfo(next) {
    if (!next.User.id || !next.isLegalLoaded || next.fetchingFeatures) {
      return;
    }

    if (
      next.User.id &&
      !next.isGhost &&
      next.countryCodeVat === "DEFAULT" &&
      !next.fetchingCountryVat
    ) {
      next.setCountryVat({ visible: true });
    }

    if (!next.isGdprAnswered) {
      if (next.AuthModel.new_user) {
        next.setRequirements({
          gdpr: { show: true },
          onboarding: { show: true },
        });
        return;
      }
      next.setGDPR({ visible: true });
    } else if (next.User.fetched) {
      this.setTermsAndOnbording(next);
    }
  }

  checkUserMode(props, next) {
    const { AuthModel, User } = next;
    if (
      Utils.propHasBeenUpdated(props.User, User, "mode") &&
      AuthModel.user_id
    ) {
      this.setState({ isSwitchingTo: User.mode }, () => {
        setTimeout(() => {
          this.switchingUserMode(User, AuthModel);
        }, 2250);
      });
    }
  }

  switchingUserMode = ({ lister, mode, isActiveSwitching }, authModel) => {
    Utils.setAuthCookie({
      ...authModel,
      lister,
      mode,
    });
    const { router } = this.props;

    this.setState({ isSwitchingTo: null }, () => {
      const redirectUrl = Utils.handleActiveSwitchUserModeRedirect(mode);
      if (isActiveSwitching && redirectUrl) {
        router.push(redirectUrl);
      }
    });
  };

  checkRefreshToken(nextProps) {
    if (
      this.props.AuthModel.user_id === nextProps.AuthModel.user_id &&
      this.props.AuthModel.refresh_token !== nextProps.AuthModel.refresh_token
    ) {
      window.location.reload();
      return true;
    }
    return false;
  }

  createSocket(accessToken, userId) {
    if (!userId || this.isLoadingSocket) return;

    this.isLoadingSocket = true;
    const wsUrl = envVars().BADI_WS_URL;
    const socketMiddleware = new SocketMiddleware(
      wsUrl,
      accessToken,
      userId,
      locale(),
      this.props.dispatch(),
    );

    if (socketMiddleware) {
      socketMiddleware.init();
    }
  }

  render() {
    const { children, AuthModel, User, router } = this.props;

    return (
      <>
        <RedirectController />
        <AuthTokenManager />
        <SwitchSpinner switchingTo={this.state.isSwitchingTo} />
        <NotificationsManager
          NotificationComponent={Toast}
          portalId="notifications-root"
          zIndex={2001} // TODO: improve the z-index of the modals to reduce this one
        />
        <Head />
        <ReduxModal />
        <Requirements />
        {children}
      </>
    );
  }
}

App.defaultProps = {
  socket: null,
};

export default App;
