import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const Root = styled.div`
  width: 75%;
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;

  padding: 72px 24px 24px;
`;

export const TextWrapper = styled.div`
  max-width: initial;

  ${mediaquery.lg`
    max-width: ${unitizedPx(64)};
  `}
`;
