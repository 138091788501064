import React, { memo } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import FormBlock from "components/form-block";
import SelectDate from "components/Form/SelectDate/SelectDateWithFormsy";

const BirthDate = ({ birthDate = null, isValid, saved }) => (
  <FormBlock
    dataQa="profile-form-birthday"
    id="formblock-birthDate"
    isRequired={true}
    saved={saved}
    title={translate("profile.form.label.birth")}
    valid={isValid}
  >
    <SelectDate
      name="birthDate"
      required={true}
      validations="isValidMoment"
      value={birthDate}
    />
    <Spacer bottom={1} />
  </FormBlock>
);

export default memo(BirthDate);
