import { connect } from "react-redux";

import RequirementsActions from "datamodel/Requirements/actions";
import { getUserId, getUserRole } from "datamodel/User/selectors";
import { isLister } from "datamodel/User/utils";

import AboutBadi from "./about-badi";

export default connect(
  (state) => ({
    isLoggedIn: !!getUserId(state),
    isLister: isLister(getUserRole(state)),
  }),
  (dispatch) => ({
    onRegister: () => dispatch(RequirementsActions.setRegister(true)),
  }),
)(AboutBadi);
