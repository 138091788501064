const RecommendationsModel = {
  list: [],
  fetching: false,
  fetched: false,
  error: null,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Recommendations) {
    return {
      ...RecommendationsModel,
      error: "There was an error in RecommendationsModel.",
    };
  }
  return state;
}

export { RecommendationsModel, setInitialState };
