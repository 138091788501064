import React, { memo, useState, useEffect } from "react";
import { Choice, Text, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { ScheduleBar } from "./styled-components";
import ScheduleButton from "./schedule-button";
import { getFirstAvailableScheduleAndTime } from "./utils";

export const TIME_FIELD_ID = "time_visit";

const TimeField = memo(
  ({ disabled = false, morning, evening, afternoon, onChange }) => {
    const [schedule, setSchedule] = useState(null);
    const [time, setTime] = useState(null);
    const schedules = { morning, afternoon, evening };
    const selectedSchedule = schedule ? schedules[schedule] : [];

    const handleTimeChange = (event) => {
      const { id } = event.currentTarget;
      const { localStartAt } = selectedSchedule.find(
        ({ id: scheduleId }) => scheduleId === id,
      );

      setTime(localStartAt);
      onChange(localStartAt);
    };

    useEffect(() => {
      const firstAvailable = getFirstAvailableScheduleAndTime(schedules);

      setSchedule(firstAvailable.schedule);
      setTime(firstAvailable.time);
      onChange(firstAvailable.time);
    }, [morning, evening, afternoon]);

    return (
      <Flex direction="column" gutter={1}>
        <Text body="14-Bold" dataQa="visit-proposal-date-time">
          {translate("visits.date.time")} *
        </Text>
        <div>
          <Flex>
            {Object.entries(schedules).map(([scheduleName, availableTimes]) => (
              <ScheduleButton
                disabled={disabled || !availableTimes.length}
                grow={1}
                key={scheduleName}
                onClick={setSchedule}
                schedule={scheduleName}
                selected={schedule === scheduleName}
              />
            ))}
          </Flex>
          <ScheduleBar />
        </div>
        <Flex gutter={1} wrap="wrap">
          {selectedSchedule.map(
            ({ available, localStartAt, id, time: availableTime }) => (
              <Choice
                checked={time === localStartAt}
                disabled={disabled || !available}
                height="auto"
                id={id}
                key={id}
                name={TIME_FIELD_ID}
                onChange={handleTimeChange}
                type="radio"
                width="80px"
              >
                {availableTime}
              </Choice>
            ),
          )}
        </Flex>
      </Flex>
    );
  },
);

TimeField.displayName = "TimeField";

export default TimeField;
