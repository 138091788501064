import React, { useCallback } from "react";
import { Button, Flex, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import UserProfileBadge from "components/user-profile-badge";
import { ViewerRoot } from "../styled-components";

const Viewer = ({ acceptViewer, user, showUserModal, isContacted = false }) => {
  const { id: userId } = user;

  const onAcceptViewer = useCallback(() => {
    acceptViewer(userId);
  }, [acceptViewer, userId]);

  return (
    <ViewerRoot data-qa="viewer">
      <Spacer bottom={3} top={3}>
        <Flex
          direction="column"
          gutter={2}
          md={{
            direction: "row",
            alignItems: "center",
            justify: "spaceBetween",
            gutter: 0,
          }}
        >
          <UserProfileBadge {...user} onClick={showUserModal} />
          <Flex gutter={1}>
            <Button
              data-qa="viewer-send-invite"
              disabled={isContacted}
              fullWidth={true}
              grow={1}
              name="viewer-send-invite"
              onClick={onAcceptViewer}
            >
              {isContacted
                ? translate("viewers.screen_cta_contacted")
                : translate("viewers.screen_cta_contact")}
            </Button>
          </Flex>
        </Flex>
      </Spacer>
    </ViewerRoot>
  );
};

export default Viewer;
