import React, { Component } from "react";

import { PAYMENT_STATUS } from "datamodel/Payment/constants";
import UserFeedback from "base/shared/user-feedback";

import AddCard from "./statuses/add-card";
import Verified from "./statuses/verified";

const PAYMENT_PROVIDER_MODE = { EDIT: "edit", VIEW: "view" };

class PaymentProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // needs an initial call so we ensure the payment details had been loaded
      needInitialRequest: props.status === PAYMENT_STATUS.INITIAL,
      mode: props.cardLastDigits
        ? PAYMENT_PROVIDER_MODE.VIEW
        : PAYMENT_PROVIDER_MODE.EDIT,
    };
  }

  componentDidMount() {
    const { getPaymentDetails } = this.props;
    const { needInitialRequest } = this.state;

    if (!needInitialRequest) return;

    getPaymentDetails().then(() => {
      this.setState({ needInitialRequest: false });
    });
  }

  componentDidUpdate(prevProps) {
    const { error, cardLastDigits } = this.props;

    if (error && prevProps.error !== error) {
      UserFeedback.exception("Something went wrong", error.message, error);
    }
    if (prevProps.cardLastDigits !== cardLastDigits) {
      this.handleCancelEdition();
    }
  }

  handleCancelEdition = () => {
    this.setState({
      mode: PAYMENT_PROVIDER_MODE.VIEW,
    });
  };

  handleCardSaving = ({ token }) => {
    this.props.setPaymentMethod(token.id);
  };

  handleEditCard = () => {
    this.setState({
      mode: PAYMENT_PROVIDER_MODE.EDIT,
    });
  };

  render() {
    const { cardLastDigits, status } = this.props;
    const { mode, needInitialRequest } = this.state;

    if (needInitialRequest) return null;

    if (mode === PAYMENT_PROVIDER_MODE.VIEW) {
      return (
        <Verified
          cardLastDigits={cardLastDigits}
          onButtonClick={this.handleEditCard}
        />
      );
    }

    return (
      <AddCard
        canCancel={!!cardLastDigits}
        disabled={status === PAYMENT_STATUS.FETCHING}
        onCancel={this.handleCancelEdition}
        onSubmit={this.handleCardSaving}
      />
    );
  }
}

PaymentProvider.defaultProps = {
  cardLastDigits: "",
  error: null,
  status: PAYMENT_STATUS.INITIAL,
};

export default PaymentProvider;
