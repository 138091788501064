import React, { useEffect, useState } from "react";
import {
  colors,
  Icon,
  useMatchMedia,
  BREAKPOINTS_PX,
} from "@badi/badi-components";
import { Wrapper } from "./styled-components";
import { CloseIconWrapper } from "../Layout/styled-components";

const FullScreenModal = ({
  children,
  isClosed = false,
  backgroundColor = colors.white,
  onClose = null,
  options = {},
  closable = true,
}) => {
  const { startOpen } = options;
  const [isOpen, setIsOpen] = useState(startOpen);
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);
  const iconSize = isDesktop ? 32 : 16;

  useEffect(() => {
    if (startOpen) return () => {};
    const animationTimeout = setTimeout(() => setIsOpen(true), 300);
    return () => clearTimeout(animationTimeout);
  }, []);

  return (
    <>
      {closable && (
        <CloseIconWrapper onClick={onClose}>
          <Icon
            alt="full-screen-modal-icon-close"
            color={colors.white}
            data-qa="full-screen-modal-icon-close"
            height={iconSize}
            name="close"
            width={iconSize}
          />
        </CloseIconWrapper>
      )}
      <Wrapper
        backgroundColor={backgroundColor}
        data-qa="full-screen-modal-wrapper"
        isClosed={isClosed}
        isOpen={isOpen}
      >
        {children}
      </Wrapper>
    </>
  );
};

export default FullScreenModal;
