import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import { Spacer, useMatchMedia, BREAKPOINTS_PX } from "@badi/badi-components";

import LayoutWrapper from "components/Layout/Wrapper";

import {
  getConnectionEnquiry,
  acceptEnquiry,
} from "datamodel/Conversation/api";
import { openLeaveConnectionConfirmModal } from "Sections/chat/components/leave-connection";
import LowerBar from "./components/lower-bar";
import Footer from "./components/footer";
import RoomDetail from "./components/room-detail";
import ContactRequest from "./components/contact-request";
import { getHintColor } from "./utils";

const Enquiry = ({
  matchingModelEnabled,
  connectionId,
  onAcceptEnquiry,
  onCancel,
  onLeaveConnection,
  openBadiGold,
  token,
}) => {
  const [connectionEnquiry, setConnectionEnquiry] = useState(null);
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);

  const acceptConnection = () => {
    acceptEnquiry({ token, connectionId }).then(() => {
      onAcceptEnquiry();
    });
  };

  const leaveConnection = (callback = null) => {
    if (typeof callback === "function") {
      callback();
    }
    onLeaveConnection();
  };

  const leaveConnectionWithConfirmation = () => {
    const { user } = connectionEnquiry;

    openLeaveConnectionConfirmModal({
      connectionId,
      user,
      onLeave: leaveConnection,
    });
  };

  useEffect(() => {
    getConnectionEnquiry({ connectionId, token }).then((data) => {
      setConnectionEnquiry(data);
    });
  }, []);

  if (!connectionEnquiry) {
    return null;
  }

  const { payToMatchRequired, room, user, visitRequested, message } =
    connectionEnquiry;

  return (
    <LayoutWrapper preContent={isDesktop && <LowerBar onClick={onCancel} />}>
      <Spacer bottom={12} md={{ bottom: 8, top: 8 }} top={4}>
        <Container>
          <Row>
            <Col lg={7} xs={12}>
              <ContactRequest
                message={message}
                token={token}
                user={user}
                visitRequested={visitRequested}
              />
            </Col>
            <Col lg={{ offset: 1, size: 4 }} xs={12}>
              <RoomDetail isDesktop={isDesktop} room={room} token={token} />
            </Col>
          </Row>
        </Container>
      </Spacer>
      <Footer
        hint={
          matchingModelEnabled
            ? room.matchesStatusLabel
            : "lister.contact.request_hint_book"
        }
        hintColor={getHintColor({
          matchingModelEnabled,
          payToMatchRequired,
          premiumPlan: room.premiumPlan,
        })}
        onAccept={acceptConnection}
        onReject={leaveConnectionWithConfirmation}
        openBadiGold={openBadiGold}
        payToMatchRequired={payToMatchRequired}
        roomId={room.id}
      />
    </LayoutWrapper>
  );
};

export default Enquiry;
