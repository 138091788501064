import React from "react";
import { translate } from "base/shared/Localization";
import { getCDNUrl } from "base/shared/Utils";
import {
  Root,
  SuggestOption,
  SuggestTag,
  SuggestText,
} from "./styled-components";

const SuggestItem = ({
  dataQa = "geosuggest-item",
  description,
  disabled = false,
  onClick = null,
  type = null,
  icon = "assets/icons/location.svg",
}) => {
  const clickHandler = (e) => {
    e.preventDefault();

    onClick();
  };

  return (
    <Root disabled={disabled} icon={`${getCDNUrl()}/${icon}`}>
      <SuggestText disabled={disabled}>{description}</SuggestText>
      {type && <SuggestTag disabled={disabled}>{translate(type)}</SuggestTag>}
      {onClick && (
        <SuggestOption
          data-qa={dataQa}
          onClick={clickHandler}
          role="button"
          tabIndex={0}
        />
      )}
    </Root>
  );
};

export default SuggestItem;
