import React from "react";
import { getOverviewPath } from "Sections/overview/routes";
import Plans from "./plans";
import { getPathFromFeature } from "./utils";

const PlansRoute = ({ router, location, params, authtoken, categories }) => {
  const { query } = location;
  const { feature, initiator = "" } = query;
  const { roomId = null } = params;

  const path =
    decodeURIComponent(initiator) || getPathFromFeature({ feature, roomId });

  const handleClose = () => {
    router.push(path || getOverviewPath());
  };

  return (
    <Plans
      categories={categories}
      initiator={path}
      onClose={handleClose}
      origin={feature}
      roomId={Number(roomId)}
      token={authtoken}
    />
  );
};

export default PlansRoute;
