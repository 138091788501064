import React from "react";
import classNames from "classnames";
import { Avatar, Overlay, Spacer } from "@badi/badi-components";

import "./ModalWithAvatarExt.css";

const ModalWithAvatar = ({
  children,
  className,
  isClosing,
  isOpen,
  outsideClickHandler,
  options,
}) => (
  <div
    className={classNames(
      "ModalWithAvatar__Wrapper",
      isOpen ? "popup_active" : null,
      isClosing ? "popup_closed" : null,
    )}
  >
    <div className={classNames("ModalWithAvatar", className)}>
      <Spacer bottom={4} left={3} right={3} top={8}>
        <Avatar
          alt={options.userName}
          className="ModalWithAvatar__Avatar"
          size={80}
          src={options.avatar}
        />
        {children}
      </Spacer>
    </div>
    <Overlay backgroundColor="black" onClick={outsideClickHandler} />
  </div>
);

ModalWithAvatar.defaultProps = {
  className: "",
  isClosing: false,
  isOpen: false,
  options: {},
  outsideClickHandler: () => {},
};

export default ModalWithAvatar;
