import React from "react";
import { Button, Flex, Heading, Text, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const CountryConfirmation = ({ onAccept, onCancel }) => (
  <Flex direction="column">
    <Spacer bottom={2}>
      <Heading level={2}>
        {translate("payout.bank_account.country.confirmation.title")}
      </Heading>
    </Spacer>
    <Text>
      {translate("payout.bank_account.country.confirmation.description")}
    </Text>
    <Spacer top={8}>
      <Flex gutter={2}>
        <Button
          dataQa="cancel"
          fullWidth={true}
          grow={1}
          onClick={onCancel}
          variant="secondary"
        >
          {translate("payout.bank_account.country.confirmation.cancel_button")}
        </Button>
        <Button dataQa="confirm" fullWidth={true} grow={1} onClick={onAccept}>
          {translate("payout.bank_account.country.confirmation.accept_button")}
        </Button>
      </Flex>
    </Spacer>
  </Flex>
);

export default CountryConfirmation;
