import createReducer from "base/shared/createReducer";

import { parseCities } from "./utils";

import Types from "./types";
import { HomeModel } from "./models";

const homeError = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  error: action.error,
});

const homeRequest = (state) => ({
  ...state,
  fetching: true,
  fetched: false,
  error: null,
});

const homeSuccess = (state, action) => {
  const {
    data: { cities },
    metadata,
  } = action.result;
  const featuredCities = parseCities(cities.other);
  const topRooms = parseCities(cities.top);
  const mainCity = topRooms.length ? topRooms[0] : null;

  return {
    ...state,
    fetching: false,
    fetched: true,
    countryCode: metadata.user_location,
    error: null,
    isDefault: metadata.default_home,
    mainCity,
    featuredCities,
  };
};

const actionHandlers = {
  [Types.HOME_ERROR]: homeError,
  [Types.HOME_REQUEST]: homeRequest,
  [Types.HOME_SUCCESS]: homeSuccess,
};

export default createReducer(actionHandlers, { ...HomeModel });
