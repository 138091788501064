import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, BUTTON_TYPES } from "@badi/badi-components";

import { getUserId } from "datamodel/User/selectors";
import { PLAN_TYPES } from "datamodel/premium/constants";
import { loadStripeApi } from "base/shared/stripe-api";
import envVars from "base/shared/Env";
import { getPurchaseSession } from "datamodel/stripe/api";
import UserFeedback from "base/shared/user-feedback";
import {
  DEFAULT_PURCHASE_SUCCESS_URL,
  DEFAULT_PURCHASE_CANCEL_URL,
} from "./constants";
import { getSuccessUrlParsed } from "./utils";

const PurchaseButtonModule = ({
  as = null,
  disabled = false,
  token,
  productId,
  purchasableId,
  children,
  roomId = 0,
  variant = BUTTON_TYPES.PREMIUM,
  dataQa = "purchase-button",
  initiator = "",
  successUrl = DEFAULT_PURCHASE_SUCCESS_URL,
  cancelUrl = DEFAULT_PURCHASE_CANCEL_URL,
  onClick = null,
  origin = null,
  callback = null,
  category = null,
  planId,
  type = null,
  style = {},
  className,
}) => {
  const [stripeApi, setStripeApi] = useState(null);
  const userId = useSelector(getUserId);

  useEffect(() => {
    loadStripeApi({
      token: envVars().BADI_PAYMENT_TOKEN,
      onLoaded: setStripeApi,
    });
  }, []);

  const handleRedirectToCheckout = () => {
    if (onClick) {
      onClick({ category, planId, roomId });
    }

    const safeRoomId = roomId || null;

    getPurchaseSession({
      token,
      purchasableId,
      productId,
      roomId: safeRoomId,
      successUrl: getSuccessUrlParsed({
        category,
        successUrl,
        origin,
        roomId: safeRoomId,
        initiator,
      }),
      cancelUrl,
      type,
    })
      .then((response) => {
        stripeApi.redirectToCheckout(response.session_id);
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        UserFeedback.exception(
          "Something went wrong",
          "Please, try again or contact support.",
          error,
        );
      });
  };

  return (
    <Button
      as={as}
      data-qa={dataQa}
      disabled={disabled}
      onClick={handleRedirectToCheckout}
      variant={variant}
      style={style}
      className={className}
    >
      {children}
    </Button>
  );
};

export default PurchaseButtonModule;
