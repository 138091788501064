export const SOCIAL_SRC = [
  {
    image: "/assets/icons/new-twitter.svg",
    url: "https://twitter.com/badi",
    id: "twitter",
  },
  {
    image: "/assets/icons/new-fb.svg",
    url: "https://www.facebook.com/badiapp",
    id: "facebook",
  },
  {
    image: "/assets/icons/new-instagram.svg",
    url: "https://www.instagram.com/badiapp/",
    id: "instagram",
  },
  {
    image: "/assets/icons/new-linkedin.svg",
    url: "https://www.linkedin.com/company/badi",
    id: "linkedin",
  },
];
