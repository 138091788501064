import React from "react";
import { Heading, Text, Spacer } from "@badi/badi-components";
import { getPathName } from "base/shared/Utils";
import Image from "components/Image/Image";

import { translate } from "base/shared/Localization";

import { FeaturedSearchStyled, StyledImage } from "./styled-components";

const FeaturedSearch = ({
  handleSearch,
  id,
  image,
  link = null,
  search = null,
  title,
  description,
}) => {
  const handleClick = () => {
    if (search) {
      handleSearch(search);
    }
  };

  return (
    <FeaturedSearchStyled
      href={link ? getPathName(link) : null}
      onClick={handleClick}
      target="_self"
    >
      <StyledImage alt={translate(title)} as={Image} src={image} />
      <Spacer left={2} right={2} top={2}>
        <Heading level="16-Bold">{translate(title)}</Heading>
        <Spacer bottom={0.5} />
        <Text body="14-Regular">{translate(description)}</Text>
      </Spacer>
    </FeaturedSearchStyled>
  );
};

export default FeaturedSearch;
