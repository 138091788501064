import React from "react";

import {
  LowSection,
  TopSectionOtherProfile,
  UserWrapper,
} from "components/User/Sections";

import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { fetchUser } from "datamodel/User/api";

const modalId = MODAL_ID.OtherUserProfile;

export const callback = (token, userId) =>
  fetchUser(userId, token).then((userFullInfo) => {
    modal.add(<UserProfileModal user={userFullInfo} />, {
      id: modalId,
      onClose: () => {
        modal.remove(modalId);
      },
      type: "lateral",
    });
  });

const UserProfileModal = ({ user }) => {
  return (
    <UserWrapper modalId={modalId}>
      <TopSectionOtherProfile format="otherProfile" user={user} />
      <LowSection user={user} />
    </UserWrapper>
  );
};

export default UserProfileModal;
