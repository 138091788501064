import styled from "styled-components";

export const Root = styled.div`
  overflow: hidden;
  background: ${({ background }) => background};
`;

export const ImageWrapper = styled.div`
  text-align: center;
`;
