import styled, { css } from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

const searchMovingStyles = css`
  position: absolute;
  left: ${unitizedPx(1.25)};
  top: ${unitizedPx(1.25)};

  padding: ${unitizedPx(1)} ${unitizedPx(2)};
  border-radius: ${unitizedPx(0.5)};

  display: flex;
  flex-direction: row;
  align-items: center;

  box-shadow: 0 ${unitizedPx(0.25)} ${unitizedPx(0.5)} 0 rgba(0, 0, 0, 0.16);
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
`;

export const AutoSearch = styled.div`
  ${searchMovingStyles}
`;

export const AutoSearchLabel = styled.label`
  cursor: pointer;
  display: flex;
`;

export const RedoSearchButton = styled.button`
  cursor: pointer;
  ${searchMovingStyles}
`;
