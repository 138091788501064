import { connect } from "react-redux";

import { getToken } from "datamodel/Auth/selectors";

import MessageStep from "./message-step";

const mapStateToProps = (state) => ({
  token: getToken(state),
});

export default connect(mapStateToProps)(MessageStep);
