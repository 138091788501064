import {
  getBookingReference,
  getBookingRequestPath,
  getNewBookingPath,
  getRecoveryBookingPath,
  getReplyBookingRequestPath,
} from "Sections/booking/routes";
import { getEnquiryPath } from "Sections/enquiry/routes";
import { Calendar, Trusted } from "./icons";

export const ACTIONS = {
  INVITE_TO_BOOK: {
    path: getNewBookingPath,
    dataQa: "invite-to-book",
  },
  REPLY_BOOKING_REQUEST: {
    path: getReplyBookingRequestPath,
    dataQa: "reply-booking-request",
  },
  REQUEST_TO_BOOK: {
    path: getNewBookingPath,
    dataQa: "request-to-book",
  },
  PAYMENT_REQUIRED: {
    path: getRecoveryBookingPath,
    dataQa: "booking-payment-required",
  },
  SEE_BOOKING_REFERENCE: {
    path: getBookingReference,
    dataQa: "see-booking-reference",
  },
  SEE_BOOKING_REQUEST: {
    path: getBookingRequestPath,
    dataQa: "see-booking-request",
  },
  SEE_EXPIRED_REQUEST: {
    path: getNewBookingPath,
    dataQa: "see-expired-booking-resend",
  },
  REVIEW_CANDIDATE: {
    path: getEnquiryPath,
    dataQa: "review-candidate",
  },
  PROPOSE_VISIT: {
    callbackAction: "propose-visit",
    dataQa: "propose-visit",
    Icon: Calendar,
  },
  VISIT_OVERVIEW: {
    callbackAction: "visit-overview",
    dataQa: "visit-overview",
  },
  PROVIDE_VISIT_FEEDBACK: {
    callbackAction: "provide-visit-feedback",
    dataQa: "visit-feedback",
  },
};
