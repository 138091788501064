import { MODAL_TYPE } from "containers/ReduxModal/emitter";
import NiceModal from "./NiceModal";
import LateralModal from "./Lateral/LateralModal";
import ModalWithAvatar from "./WithAvatar/modal-with-avatar";
import OnboardingModal from "./onboarding-modal";
import DefaultModal from "./default-modal";
import DisplayModal from "./display-modal";
import CustomModal from "./custom-modal";
import FullScreenModal from "./fullscreen-modal";
import InfoModal from "./info-modal";

export const getModalComponent = (type) => {
  const MODAL_TYPES = {
    [MODAL_TYPE.LATERAL]: LateralModal,
    [MODAL_TYPE.WITH_AVATAR]: ModalWithAvatar,
    [MODAL_TYPE.ONBOARDING]: OnboardingModal,
    [MODAL_TYPE.DEFAULT]: DefaultModal,
    [MODAL_TYPE.DISPLAY]: DisplayModal,
    [MODAL_TYPE.CUSTOM]: CustomModal,
    [MODAL_TYPE.FULLSCREEN]: FullScreenModal,
    [MODAL_TYPE.INFO]: InfoModal,
  };
  // * We are returning by default the deprecated NiceModal because we
  // * need to keep old modals until we refactor all of them
  return MODAL_TYPES[type] || NiceModal;
};
