class ListingFlowActionsModel {
  constructor(data) {
    this.data = data;
  }

  get mainActions() {
    return this.data.main_actions;
  }

  get otherActions() {
    return this.data.other_actions;
  }

  toJSON() {
    return {
      mainActions: this.mainActions,
      otherActions: this.otherActions,
    };
  }
}

export default ListingFlowActionsModel;
