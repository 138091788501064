import createReducer from "base/shared/createReducer";
import Types from "./types";
import { CountryVatModel } from "./models";

export const countryVatRequest = (state) => ({ ...state, fetching: true });

export const countryVatError = (state) => ({ ...state, fetching: false });

export const countryVatSuccess = (state, action) => {
  return {
    ...state,
    ...action.result,
    fetching: false,
  };
};

const actionHandlers = {
  [Types.GET_COUNTRY_VAT_REQUEST]: countryVatRequest,
  [Types.GET_COUNTRY_VAT_SUCCESS]: countryVatSuccess,
  [Types.GET_COUNTRY_VAT_ERROR]: countryVatError,
  [Types.SET_COUNTRY_VAT_REQUEST]: countryVatRequest,
  [Types.SET_COUNTRY_VAT_SUCCESS]: countryVatSuccess,
  [Types.SET_COUNTRY_VAT_ERROR]: countryVatError,
  [Types.UPDATE_COUNTRY_VAT_REQUEST]: countryVatRequest,
  [Types.UPDATE_COUNTRY_VAT_SUCCESS]: countryVatSuccess,
  [Types.UPDATE_COUNTRY_VAT_ERROR]: countryVatError,
};

export default createReducer(actionHandlers, { ...CountryVatModel });
