import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { locale } from "base/shared/Localization";
import { CountryVat } from "./models";

export const getVatCountry = ({ token }) => {
  const { BADI_PAYMENT_VAT } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYMENT_VAT);

  return fetch(serviceUrl, {
    token,
    additionalParams: { locale: locale() },
  }).then((data) => {
    return new CountryVat(data).toJSON();
  });
};

export const setVatCountry = ({ token }, { countryCode, spainOverseas }) => {
  const { BADI_PAYMENT_VAT } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYMENT_VAT);
  const paramsForApi = {
    country_code: countryCode,
    spain_overseas: spainOverseas,
  };

  return fetch(serviceUrl, {
    method: "POST",
    params: paramsForApi,
    token,
    paramsRequired: true,
    additionalParams: { locale: locale() },
  }).then((data) => {
    return new CountryVat(data).toJSON();
  });
};

export const updateVatCountry = ({ token }, { countryCode, spainOverseas }) => {
  const { BADI_PAYMENT_VAT } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYMENT_VAT);
  const paramsForApi = {
    country_code: countryCode,
    spain_overseas: spainOverseas,
  };

  return fetch(serviceUrl, {
    method: "PUT",
    params: paramsForApi,
    additionalParams: { locale: locale() },
    token,
    paramsRequired: true,
  }).then((data) => {
    return new CountryVat(data).toJSON();
  });
};
