import styled from "styled-components";
import { colors } from "@badi/badi-components";

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const TransportButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 110px;

  width: 40px;
  height: 28px;

  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;

  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.298039);
`;

export const TransportIconOpacity = styled.div`
  opacity: ${({ selected }) => (selected ? 1 : 0.85)};

  &:hover {
    opacity: 1;
  }
`;
