import React, { useState, useEffect } from "react";

import { Link } from "react-router";
import TextButton from "components/Button/TextButton";
import ImageButton from "components/Button/ImageButton";
import { TopbarElementWrapper } from "./styled-components";

const TopbarElement = (props) => {
  const {
    children = null,
    dataQa = undefined,
    name = undefined,
    notifications = undefined,
    onClick = undefined,
    position = undefined,
    src = undefined,
    to = undefined,
  } = props;
  const [href, setHref] = useState(null);

  useEffect(() => {
    if (to && !to.startsWith("/")) {
      setHref(to);
    }
  }, [to]);

  const getElement = () => {
    if (href) return "a";
    if (to) return Link;
    if (src) return ImageButton;
    if (onClick) return TextButton;
    return "div";
  };

  const DynamicElement = getElement();
  const safeDataQaProp =
    to || typeof DynamicElement === "string"
      ? { "data-qa": dataQa }
      : { dataQa };

  return (
    <TopbarElementWrapper notifications={notifications} position={position}>
      <DynamicElement
        {...safeDataQaProp}
        href={href}
        name={name}
        onClick={onClick}
        src={src}
        to={href ? undefined : to}
      >
        {children}
      </DynamicElement>
    </TopbarElementWrapper>
  );
};

export default TopbarElement;
