import React from "react";
import { Label, Flex, Text, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getCDNUrl } from "base/shared/Utils";
import {
  ActionLabelWrapper,
  Background,
  Card,
  DetailsWrapper,
  ListingImage,
  RecommendAction,
  Root,
  TruncatedText,
} from "./styled-components";

const OverviewExample = ({
  address,
  coverPicture,
  priceDescription,
  title,
}) => {
  return (
    <Root alignItems="center" direction="column">
      <Background />
      <Spacer top={3}>
        <Card>
          <Flex gutter={2}>
            <ListingImage alt="" src={coverPicture} />
            <DetailsWrapper direction="column" gutter={0.5}>
              <TruncatedText body="14-Bold" dataQa="overview-example-title">
                {title}
              </TruncatedText>
              <TruncatedText
                body="14-Regular"
                dataQa="overview-example-address"
              >
                {address}
              </TruncatedText>
              <Text body="14-Bold" dataQa="overview-example-price">
                {priceDescription}
              </Text>
            </DetailsWrapper>
          </Flex>
          <Spacer top={2}>
            <RecommendAction>
              <Spacer right={3}>
                <img
                  alt=""
                  height={40}
                  src={`${getCDNUrl()}/assets/icons/active_recommendations.svg`}
                  width={40}
                />
              </Spacer>
              <ActionLabelWrapper>
                <Label
                  dataQa="overview-example-label"
                  size="small"
                  variant="temporary"
                >
                  {translate("plus.opt.in_paid.version.label")}
                </Label>
                <Spacer top={0.5}>
                  <Text body="14-Bold" data-qa="overview-example-action">
                    {translate("plus.opt.in_recommended.tenants.title")}
                  </Text>
                </Spacer>
              </ActionLabelWrapper>
            </RecommendAction>
          </Spacer>
        </Card>
      </Spacer>
    </Root>
  );
};

export default OverviewExample;
