import React from "react";
import { Flex, Text } from "@badi/badi-components";

const ContactInformationField = ({ title, children }) => (
  <Flex direction="column" gutter={1}>
    <Text body={2} data-qa="contact-info-field-title">
      {title}
    </Text>
    {children}
  </Flex>
);

export default ContactInformationField;
