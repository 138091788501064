import React from "react";
import { BADI_COLORS, Flex, Heading, Spacer } from "@badi/badi-components";

import { Root, TextWrapper } from "./styled-components";

const Testimonial = ({ imageSrc, text, author }) => (
  <Root data-qa="testimonial" image={imageSrc}>
    <Spacer bottom={3} left={2} right={2} top={4}>
      <Flex as={TextWrapper} direction="column" gutter={2}>
        <Heading
          color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
          data-qa="testimonial-text"
          level="28-Extrabold"
        >
          {text}
        </Heading>
        <Heading
          color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
          data-qa="testimonial-author"
          level="20-Bold"
        >
          {author}
        </Heading>
      </Flex>
    </Spacer>
  </Root>
);

export default Testimonial;
