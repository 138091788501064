import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { parseBankAccountToApi, parseContactToApi } from "../utils";

const fetchAccount = ({ token }) => {
  const { BADI_PAYOUTS_ACCOUNT_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_PAYOUTS_ACCOUNT_SERVICE);

  return fetch(serverUrl, { token });
};

const acceptBooking = (id, token) => {
  const { BADI_BOOKING_ACCEPT } = envVars();
  const serverUrl = getServerUrl(BADI_BOOKING_ACCEPT);

  return fetch(serverUrl.replace("{id}", id), { method: "PUT", token });
};

const getBooking = (id, token) => {
  const { BADI_BOOKING_GET } = envVars();
  const serverUrl = getServerUrl(BADI_BOOKING_GET);

  return fetch(serverUrl.replace("{id}", id), { token });
};

const getPrefillInfo = (params, token) => {
  const { BADI_PREFILL_BOOKING_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_PREFILL_BOOKING_SERVICE);

  return fetch(serviceUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const rejectBooking = (id, token) => {
  const { BADI_BOOKING_REJECT } = envVars();
  const serverUrl = getServerUrl(BADI_BOOKING_REJECT);

  return fetch(serverUrl.replace("{id}", id), { method: "PUT", token });
};

const setBookingRequest = (params, token) => {
  const { BADI_REQUEST_BOOKING_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_REQUEST_BOOKING_SERVICE);

  return fetch(serviceUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const setCountry = ({ token }, params) => {
  const { BADI_PAYOUTS_ACCOUNT_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYOUTS_ACCOUNT_SERVICE);

  return fetch(serviceUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const setPayoutsMethod = ({ token }, params) => {
  const { BADI_PAYOUTS_METHOD_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYOUTS_METHOD_SERVICE);
  const bankAccountParams = parseBankAccountToApi(params);

  return fetch(serviceUrl, {
    params: bankAccountParams,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const updateAccount = ({ token }, params) => {
  const { BADI_PAYOUTS_ACCOUNT_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_PAYOUTS_ACCOUNT_SERVICE);

  return fetch(serviceUrl, {
    params: parseContactToApi(params),
    requiredParams: true,
    method: "PUT",
    token,
  });
};

export {
  acceptBooking,
  fetchAccount,
  getBooking,
  getPrefillInfo,
  rejectBooking,
  setBookingRequest,
  setCountry,
  setPayoutsMethod,
  updateAccount,
};
