import React, { useEffect } from "react";
import { withRouter } from "react-router";

import {
  Flex,
  Heading,
  Spacer,
  Card,
  colors,
  Text,
  Button,
} from "@badi/badi-components";
import { Col, Container, Row } from "reactstrap";

import CompleteProfileButton from "components/Button/Button";
import { translate } from "base/shared/Localization";
import { getLocalePath } from "base/shared/Utils";
import { getProfilePercentage, isLister } from "datamodel/User/utils";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import LayoutWrapper from "components/Layout/Wrapper";

import PhoneVerificationForm from "components/Verification/Phone/phone-verification-mapping";
import EmailVerificationForm from "components/Verification/Email/EmailVerificationMapping";
import Image from "components/Image/Image";

import { TopSectionMyProfile, LowSection } from "components/User/Sections";

import FacebookConfirmation from "./wrappers/FacebookConfirmation";
import GoogleConfirmation from "./wrappers/GoogleConfirmation";

const CompleteProfileCard = ({ completeProfileClickHandler }) => {
  return (
    <Spacer bottom={3}>
      <Card backgroundColor={colors.pink}>
        <Spacer bottom={2} left={2} right={2} top={2}>
          <Flex alignItems="center" gutter={2} justify="spaceBetween">
            <Text color={colors.white}>
              {translate(
                "Please, finish completing your profile to get more visibility and trust from other Badis",
              )}
            </Text>
            <div basis="250px">
              <CompleteProfileButton
                config={{ color: "white", light: true }}
                dataQa="complete-profile-button"
                onClick={completeProfileClickHandler}
                text={translate("Complete Profile")}
              />
            </div>
          </Flex>
        </Spacer>
      </Card>
    </Spacer>
  );
};

CompleteProfileCard.defaultProps = {
  completeProfileClickHandler: () => {},
};

const getVerifyCode = (queryParams) =>
  Object.prototype.hasOwnProperty.call(queryParams, "verify-code")
    ? queryParams["verify-code"]
    : null;

const MyProfile = ({
  authtoken,
  getAccount,
  location,
  missingData,
  router,
  user,
}) => {
  useEffect(() => {
    getAccount(authtoken);
  }, []);

  const config = { id: true, percentage: true, edit: true };
  const profilePercentage = getProfilePercentage(user);

  const completeProfileClickHandler = (event) => {
    event.preventDefault();
    router.push(`${getLocalePath()}/users/edit`);
  };

  const redirectToBooking = () => {
    router.push(`${getLocalePath()}/booking-settings`);
  };

  const handleOnClickRentARoom = () => {
    router.push(getRentRoomPath());
  };

  return (
    <LayoutWrapper>
      <Spacer md={{ top: 4 }} top={2}>
        <Container>
          <Row>
            <Col lg={4}>
              <Spacer bottom={6}>
                <Card backgroundColor={colors.white}>
                  <Spacer bottom={2} left={2} right={2} top={2}>
                    <TopSectionMyProfile
                      config={config}
                      format="myProfile"
                      showVerificationFlag={false}
                      title={translate("My Profile")}
                      user={user}
                    />
                    <LowSection config={config} user={user} />
                  </Spacer>
                </Card>
              </Spacer>
            </Col>
            <Col lg={8}>
              <main className="content">
                {profilePercentage < 100 && (
                  <CompleteProfileCard
                    completeProfileClickHandler={completeProfileClickHandler}
                  />
                )}
                <Spacer bottom={2}>
                  <Heading level={1}>
                    {translate("Verify your Profile")}
                  </Heading>
                </Spacer>
                <Flex direction="column" gutter={2}>
                  <EmailVerificationForm
                    code={getVerifyCode(location.query)}
                    dataQa="my-profile-email-verification"
                  />
                  <PhoneVerificationForm dataQa="my-profile-phone-verification" />
                  {missingData && isLister(user.mode) && (
                    <Card backgroundColor={colors.white}>
                      <Spacer bottom={2} left={2} right={2} top={2}>
                        <div
                          onClick={redirectToBooking}
                          role="link"
                          tabIndex="0"
                        >
                          <Flex alignItems="center" justify="spaceBetween">
                            <Text>
                              {translate(
                                "Verify your Account to become a Trusted User",
                              )}
                            </Text>
                            <Image
                              alt="Trusted User"
                              src="/assets/images/shield-trusted-user-button.svg"
                            />
                          </Flex>
                        </div>
                      </Spacer>
                    </Card>
                  )}
                </Flex>

                <Spacer bottom={2} top={4}>
                  <Heading level={1}>
                    {translate("profile.social.title")}
                  </Heading>
                </Spacer>
                <Flex direction="column" gutter={2}>
                  <Card backgroundColor={colors.white}>
                    <Spacer bottom={2} left={2} right={2} top={2}>
                      <FacebookConfirmation />
                    </Spacer>
                  </Card>
                  <Card backgroundColor={colors.white}>
                    <Spacer bottom={2} left={2} right={2} top={2}>
                      <GoogleConfirmation />
                    </Spacer>
                  </Card>
                </Flex>

                {!user.lister && (
                  <Spacer bottom={4} md={{ top: 8 }} top={4}>
                    <Card backgroundColor={colors.white}>
                      <Spacer bottom={2} left={2} right={2} top={2}>
                        <Flex
                          direction="column"
                          gutter={2}
                          justify="spaceBetween"
                          md={{
                            direction: "row",
                            alignItems: "center",
                            gutter: 1,
                          }}
                        >
                          <Heading level={2}>
                            {translate("You don’t have any listings yet!")}
                          </Heading>
                          <Button
                            onClick={handleOnClickRentARoom}
                            variant="gradient"
                          >
                            {translate("List a Room")}
                          </Button>
                        </Flex>
                      </Spacer>
                    </Card>
                  </Spacer>
                )}
              </main>
            </Col>
          </Row>
        </Container>
      </Spacer>
    </LayoutWrapper>
  );
};

export default withRouter(MyProfile);
