import createReducer from "base/shared/createReducer";

import Types from "./types";
import { TransactionsModel } from "./models/transactions-model";

import { parseTransactions, parsePagination } from "./utils";

function transactionsError(state, action) {
  return {
    ...state,
    fetched: Date.now(),
    fetching: false,
    error: action.error,
  };
}

function transactionsRequest(state) {
  return { ...state, fetching: true, error: null };
}

function transactionsSuccess(state, action) {
  const {
    data: { summary, transactions },
    pagination,
  } = action.result;

  return {
    ...state,
    fetched: Date.now(),
    fetching: false,
    error: null,
    transactions: parseTransactions(transactions),
    pagination: parsePagination(pagination),
    summary,
  };
}

const actionHandlers = {
  [Types.TRANSACTIONS_ERROR]: transactionsError,
  [Types.TRANSACTIONS_REQUEST]: transactionsRequest,
  [Types.TRANSACTIONS_SUCCESS]: transactionsSuccess,
};

export default createReducer(actionHandlers, { ...TransactionsModel });
