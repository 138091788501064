import { connect } from "react-redux";

import ConversationActions from "datamodel/Conversation/actions";

import { getToken } from "datamodel/Auth/selectors";
import {
  getLastMessageIdSelector,
  getMessagesHistory,
  getMessagesHistoryCurrentPage,
  getMessagesHistoryTotalPages,
} from "datamodel/Conversation/selectors";
import { markConnectionAsRead } from "datamodel/Conversation/api";
import {
  getConnectionId,
  getConnectionStatusLevel,
} from "datamodel/Inbox/selectors";
import { getUserId } from "datamodel/User/selectors";

import MessageList from "./message-list";

const mapStateToProps = (state) => ({
  connectionId: getConnectionId(state),
  currentPage: getMessagesHistoryCurrentPage(state),
  lastMessageId: getLastMessageIdSelector(state),
  messages: getMessagesHistory(state),
  token: getToken(state),
  totalPages: getMessagesHistoryTotalPages(state),
  userId: getUserId(state),
  statusLevel: getConnectionStatusLevel(state),
});

const mapDispatchToProps = {
  getMessages: ConversationActions.getConnectionHistory,
};

const mergeProps = (state, dispatch, ownProps) => {
  const { connectionId, token, ...stateProps } = state;

  return {
    ...stateProps,
    ...ownProps,
    connectionId,
    getMessages: (params) => dispatch.getMessages(connectionId, params, token),
    markConnectionAsRead: () => markConnectionAsRead(connectionId, token),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(MessageList);
