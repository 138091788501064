export default class ReviewUser {
  constructor(entity) {
    this.entity = entity || {};
  }

  get id() {
    return this.entity.id;
  }

  get picture() {
    const { cover_picture: coverPicture } = this.entity;
    const picture = coverPicture ? coverPicture.width_100_url : null;

    return picture;
  }

  get name() {
    return this.entity.first_name || "";
  }

  get age() {
    return this.entity.age;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      age: this.age,
      picture: this.picture,
    };
  }
}
