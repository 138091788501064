class ListingMinimalCardModel {
  constructor(listingData) {
    this.listingData = listingData;
  }

  get id() {
    return this.listingData.room_id;
  }

  get picture() {
    return this.listingData.picture.url;
  }

  get title() {
    return this.listingData.headline;
  }

  toJSON() {
    return {
      id: this.id,
      picture: this.picture,
      title: this.title,
    };
  }
}

export default ListingMinimalCardModel;
