const HomeModel = {
  fetching: false,
  fetched: false,
  error: null,
  mainCity: null,
  featuredCities: [],
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Home) {
    return { ...HomeModel, error: "There was an error in HomeModel." };
  }
  return state;
}

export { HomeModel, setInitialState };
