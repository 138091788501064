class Hint {
  constructor(data) {
    this.entity = data || {};
  }

  get id() {
    return this.entity.id || 0;
  }

  get hintType() {
    return this.entity.hint_type || 1;
  }

  get title() {
    return this.entity.title || "";
  }

  get content() {
    return this.entity.content || "";
  }

  get link() {
    return this.entity.link || null;
  }

  toJSON() {
    return {
      id: this.id,
      hintType: this.hintType,
      title: this.title,
      content: this.content,
      link: this.link,
    };
  }
}

export default Hint;
