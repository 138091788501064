import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const FormWrapper = styled.div`
  max-width: ${unitizedPx(50)};
  margin: ${unitizedPx(8)} ${unitizedPx(1)} 0 ${unitizedPx(1)};

  ${mediaquery.md`
    margin: ${unitizedPx(8)} auto 0 auto;
  `}
`;
