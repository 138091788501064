import moment from "moment";
import { DATE_FORMAT } from "./constants";

export const defaultFromDate = moment().add("3", "days").format(DATE_FORMAT);

export const generateParams = (value, date) => {
  const params = { moveInType: value, moveInDate: date };
  if (value !== 3) {
    params.moveInDate = null;
  }
  return params;
};

export const amountValidation = (value) => /^[1-9][0-9]*$/.test(value);
