import React, { useState, useEffect } from "react";

import { Flex, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import { isSeeker } from "datamodel/User/utils";
import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";

import VisitDetails from "./visit-details";
import VisitSteps from "../components/visit-steps";
import VisitActions from "./visit-actions";

import { getDescription, isMessageVisible, isFinished } from "./utils";
import VisitFinished from "./visit-finished";

const VisitOverview = ({
  getVisitRequest,
  onClose = null,
  userId,
  visitId = 0,
}) => {
  const [visitDetails, setVisitDetails] = useState(null);

  const handleUpdate = (updatedVisit) => {
    if (updatedVisit) {
      setVisitDetails(updatedVisit);
    } else {
      getVisitRequest({ id: visitId }).then(setVisitDetails);
    }
  };

  useEffect(() => {
    getVisitRequest({ id: visitId }).then(setVisitDetails);
  }, []);

  if (!visitDetails) {
    return null;
  }

  const {
    actions,
    displayDate,
    displayTime,
    express,
    id,
    message,
    room,
    roomAddress,
    seeker,
    status,
    isCurrentUserProposer,
  } = visitDetails;

  const userRole =
    visitDetails.seeker.id === userId ? SEEKER_USER_ROLE : LISTER_USER_ROLE;

  const description = getDescription({
    express,
    status,
    userRole,
    isCurrentUserProposer,
  });

  return (
    <Flex direction="column" fullHeight={true}>
      <Spacer bottom={4}>
        <VisitSteps status={status} />
      </Spacer>
      {isFinished(status) ? (
        <VisitFinished grow={1} room={room} seeker={seeker} userId={userId} />
      ) : (
        <VisitDetails
          address={roomAddress}
          date={displayDate}
          description={translate(description)}
          express={express}
          grow={1}
          message={
            isMessageVisible({ message, userId, status }) ? message : null
          }
          onClose={onClose}
          status={status}
          time={displayTime}
          videocall={express}
          {...(isSeeker(userRole) ? { room } : { userProfile: seeker })}
        />
      )}
      <VisitActions
        actions={actions}
        onUpdate={handleUpdate}
        roomId={room?.id}
        userRole={userRole}
        visitId={id}
      />
    </Flex>
  );
};

export default VisitOverview;
