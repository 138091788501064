export const SUGGEST_COOKIE = "badi_recent_suggests";
export const SUGGEST_TYPES = {
  administrative_area_level_1: "region",
  administrative_area_level_2: "region",
  country: "country",
  establishment: "establishment",
  locality: "city",
  neighborhood: "neighborhood",
  route: "street",
  street_address: "street",
  sublocality_level_1: "district",
  transit_station: "transit station",
};

export const LOCATION_STATUS = {
  NOT_INITIALIZED: -1,
  NOT_SUPPORTED: 0,
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
  UNKNOWN_ERROR: 4,
  OK: 5,
  PENDING: 6,
};

export const DEFAULT_RADIUS_GEOSUGGEST = 1;
