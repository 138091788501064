import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";

export const fetchViewers = ({ roomId, paginationToken }, token) => {
  const { BADI_VIEWERS_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_VIEWERS_SERVICE.replace("{id}", roomId));
  const params = paginationToken ? { token: paginationToken } : null;
  return fetch(serverUrl, { params, token }).then((res) => res);
};

export const sendInvite = ({ roomId, userId, message }, token) => {
  const { BADI_VIEWERS_CONTACT_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_VIEWERS_CONTACT_SERVICE);

  const parsedParams = {
    room_id: roomId,
    user_id: userId,
    message: message || null,
  };

  return fetch(serverUrl, {
    params: parsedParams,
    requiredParams: true,
    method: "POST",
    token,
  });
};
