import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const MarkerButton = styled.button`
  font-family: modelica;
  font-size: ${unitizedPx(2)};
  position: relative;
  display: inline-block;
  transform: translate(-50%, -100%);
  border: none;
  margin: 0;
  padding: ${unitizedPx(0.5)} ${unitizedPx(1.5)};
  white-space: nowrap;

  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? BADI_COLORS.NEUTRAL.NEUTRAL_80 : BADI_COLORS.NEUTRAL.NEUTRAL_00};
  z-index: ${({ selected }) => (selected ? "1" : "unset")};

  border-radius: ${unitizedPx(0.25)};
  box-shadow: 0px ${unitizedPx(0.25)} ${unitizedPx(0.5)} 0px rgba(0, 0, 0, 0.16);

  &:after {
    position: absolute;
    bottom: -3px;
    left: calc(50% - ${unitizedPx(0.5)});
    content: "";
    width: ${unitizedPx(1)};
    height: ${unitizedPx(1)};
    border-radius: ${unitizedPx(0.25)};
    background-color: ${({ selected }) =>
      selected
        ? BADI_COLORS.NEUTRAL.NEUTRAL_80
        : BADI_COLORS.NEUTRAL.NEUTRAL_00};
    transform: rotate(45deg);
  }
`;

export const ReducedMarker = styled.div`
  position: relative;
  width: ${unitizedPx(1.5)};
  height: ${unitizedPx(1.5)};
  border-radius: ${unitizedPx(1)};
  box-sizing: border-box;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border: ${BADI_COLORS.NEUTRAL.NEUTRAL_80} solid 2px;
  box-shadow: 0px ${unitizedPx(0.25)} ${unitizedPx(0.5)} 0px rgba(0, 0, 0, 0.16);
`;
