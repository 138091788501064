class ConnectionResponse {
  constructor(data) {
    this.entity = data || {};
  }

  get daysConsidered() {
    return this.entity.days_considered;
  }

  get value() {
    return this.entity.value;
  }

  get previousValue() {
    return this.entity.previous_value;
  }

  get trend() {
    return this.entity.trend;
  }

  toJSON() {
    return {
      daysConsidered: this.daysConsidered,
      value: this.value,
      previousValue: this.previousValue,
      trend: this.trend,
    };
  }
}

export default ConnectionResponse;
