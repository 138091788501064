import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import SepaDetailsModel from "./model";

const getSepaExistingDetails = ({ token }) => {
  const { BADI_SEPA_EXISTING_DETAILS } = envVars();
  const serviceUrl = getServerUrl(BADI_SEPA_EXISTING_DETAILS);

  return fetch(serviceUrl, {
    token,
  }).then(({ data }) => new SepaDetailsModel(data).toJSON());
};

export { getSepaExistingDetails };
