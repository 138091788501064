import React from "react";
import { Flex, Spacer, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { Metric, MetricUserCardBlock } from "../styled-components";

const Metrics = ({ format, metrics }) => {
  if (!metrics) return null;

  return (
    <MetricUserCardBlock>
      <Flex>
        {metrics.map((metric) => {
          const show = !(
            format === "myProfile" && metric.title === "user.metrics.lastActive"
          );
          if (!show) return null;

          return (
            <Metric basis="0" grow={1} key={metric.title}>
              <Spacer left={1}>
                <Flex direction="column">
                  <Text body={2} shrink={1}>
                    {translate(metric.title)}
                  </Text>
                  <Text body={3} shrink={1}>
                    {metric.value}
                  </Text>
                </Flex>
              </Spacer>
            </Metric>
          );
        })}
      </Flex>
    </MetricUserCardBlock>
  );
};

Metrics.defaultProps = {
  metrics: [],
};

export default Metrics;
