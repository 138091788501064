import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BUTTON_TYPES } from "@badi/badi-components";
import { isSepaDebitDetailsSelector } from "datamodel/Features/selectors";

import { getToken } from "datamodel/Auth/selectors";

import StripeApi from "base/shared/stripe-api";
import envVars from "base/shared/Env";
import { getSepaDetailsSession } from "datamodel/stripe/api";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import { getSepaDirectDebitPath } from "Sections/Settings/routes";

export const DEFAULT_PURCHASE_SUCCESS_URL = getSepaDirectDebitPath();
export const DEFAULT_PURCHASE_CANCEL_URL = getSepaDirectDebitPath();

const withStripeSepaRedirect =
  (Component) =>
  ({
    children,
    variant = BUTTON_TYPES.EXPRESS_BOOKING,
    dataQa = "sepa-direct-debit-button",
    successUrl = DEFAULT_PURCHASE_SUCCESS_URL,
    cancelUrl = DEFAULT_PURCHASE_CANCEL_URL,
    callback = null,
  }) => {
    const [stripeApi, setStripeApi] = useState(null);
    const isSepaEnabled = useSelector(isSepaDebitDetailsSelector);
    const token = useSelector(getToken);

    useEffect(() => {
      setStripeApi(new StripeApi({ token: envVars().BADI_PAYMENT_TOKEN }));
    }, []);

    useEffect(() => {
      if (!stripeApi) return;
      stripeApi.init();
    }, [stripeApi]);

    const handleRedirectToCheckout = () => {
      getSepaDetailsSession({
        token,
        successUrl,
        cancelUrl,
      })
        .then(({ data }) => {
          stripeApi.redirectToCheckout(data.session_id);
          if (callback) {
            callback();
          }
        })
        .catch(() => {
          UserFeedback.exception(
            translate("Something went wrong, try again or contact support."),
          );
        });
    };

    return (
      <Component
        dataQa={dataQa}
        handleRedirectToCheckout={handleRedirectToCheckout}
        isSepaEnabled={isSepaEnabled}
        variant={variant}
      >
        {children}
      </Component>
    );
  };

export default withStripeSepaRedirect;
