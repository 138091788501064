import { generateFetchTypes } from "base/shared/TypeHelper";
import types from "./types";
import { getPendingReviews } from "./api";

export const getPendingReviewsAction = () => ({
  types: generateFetchTypes(types.REVIEWS_PENDING_REQUEST),
  request: getPendingReviews,
  requireAuth: true,
});

export const resetReviewsAction = () => ({
  type: types.RESET_REVIEWS,
});
