import React, { Component } from "react";

import envVars from "base/shared/Env";
import { localeISO } from "base/shared/Localization";

import {
  loadFacebookSdk,
  sendFacebookMessage,
  sendMessengerMessage,
} from "base/shared/FacebookSdk";

import "./shareExt.css";

class MessengerShareButton extends Component {
  constructor(props) {
    super(props);

    this.state = { isSdkLoaded: false };
    this.onClickHandler = this.onClickHandler.bind(this);
    this.sdkLoaded = this.sdkLoaded.bind(this);
  }

  componentDidMount() {
    loadFacebookSdk(
      {
        ...this.props,
        appId: envVars().BADI_AUTH_FACEBOOK_APP_ID,
        language: localeISO(),
      },
      this.sdkLoaded,
    );
  }

  onClickHandler() {
    if (!this.state.isSdkLoaded) return;

    const { desktop, target } = this.props;

    if (desktop) {
      sendFacebookMessage(target);
    } else {
      sendMessengerMessage(target);
    }
  }

  sdkLoaded() {
    this.setState({ isSdkLoaded: true });
  }

  render() {
    return (
      <div
        className="Button__share"
        onClick={this.onClickHandler}
        role="link"
        tabIndex="-1"
      >
        <div className="Button__share_messenger" />
      </div>
    );
  }
}

export default MessengerShareButton;
