import { locale } from "base/shared/Localization";
import { Recaptcha, RECAPTCHA_ACTION_NAMES } from "lib/recaptcha";

import { MODE, LOGIN_TEXTS, REGISTER_TEXTS } from "./constants";

export const socialHandler = (callback, response, inviteCode) => {
  const { accessToken: socialToken, error, provider, status } = response;

  if (status === "success") {
    callback({
      socialToken,
      provider,
      locale: locale(),
      inviteCode,
    });
  } else if (
    provider !== "facebook" &&
    status !== "unknown" &&
    error !== "popup_closed_by_user"
  ) {
    const parsedError = new Error(error.message);
    parsedError.name = error.code;
    return parsedError;
  }
  return null;
};

export const getTexts = (mode = MODE.LOGIN) => {
  return mode === MODE.LOGIN ? LOGIN_TEXTS : REGISTER_TEXTS;
};

export const isRegisterMode = (mode) => mode === MODE.REGISTER;

const getRegisterConfig = ({ onRegister, onLogin, onError, inviteCode }) => ({
  handleSocialChange: (data) => {
    const error = socialHandler(onLogin, data, inviteCode);

    if (error) {
      onError(error);
    }
  },
  handleEmailChange: ({ email, password }) => {
    onRegister({ username: email, password, locale: locale(), inviteCode });
  },
  texts: REGISTER_TEXTS,
});

const getLoginConfig = ({ onLogin, onError }) => ({
  handleSocialChange: (data) => {
    Recaptcha.execute({
      actionName: RECAPTCHA_ACTION_NAMES.LOGIN,
      onExecute: (securityToken) => {
        const error = socialHandler(onLogin, {
          ...data,
          securityToken,
        });

        if (error) {
          onError(error);
        }
      },
      onError,
    });
  },
  handleEmailChange: ({ email, password }) => {
    Recaptcha.execute({
      actionName: RECAPTCHA_ACTION_NAMES.LOGIN,
      onExecute: (securityToken) =>
        onLogin({ securityToken, username: email, password }),
      onError,
    });
  },
  texts: LOGIN_TEXTS,
});

export const getFormConfig = ({ mode, ...rest } = {}) => {
  if (!rest) return null;
  return isRegisterMode(mode) ? getRegisterConfig(rest) : getLoginConfig(rest);
};
