import React from "react";
import { Label, Card, colors, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const MatchProfile = ({ dataQa = "match-profile-badge" }) => (
  <Card backgroundColor={colors.lightGrey} dataQa={dataQa} withShadow={false}>
    <Spacer bottom={2} left={2} right={2} top={2}>
      <Label variant="general-inverted">
        {translate("trust_items.match_profile_label")}
      </Label>
      <Spacer top={1}>
        <Text body={3}>{translate("trust_items.match_profile_text")}</Text>
      </Spacer>
    </Spacer>
  </Card>
);

export default MatchProfile;
