import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BADI_COLORS,
  Flex,
  Overlay,
  Icon,
  useMatchMedia,
  BREAKPOINTS_PX,
} from "@badi/badi-components";
import { getIsSafari } from "datamodel/Device/selectors";
import { Wrapper, Root, CloseIconWrapper } from "./styled-components";

const LayoutModal = ({
  children = null,
  isClosed = false,
  wrapper: ModalWrapper = Wrapper,
  outsideClickHandler = () => {},
  onIconClose = undefined,
  overlayOpacity = 0.72,
  backgroundColor = BADI_COLORS.NEUTRAL.NEUTRAL_00,
  outsideCloseIcon = false,
  padding = null,
}) => {
  const isSafari = useSelector(getIsSafari);
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);
  const iconSize = isDesktop ? 32 : 16;
  const white = BADI_COLORS.NEUTRAL.NEUTRAL_00;

  useEffect(() => {
    const animationTimeout = setTimeout(() => setIsOpen(true), 300);
    return () => clearTimeout(animationTimeout);
  }, []);

  return (
    <>
      {outsideCloseIcon && onIconClose && (
        <CloseIconWrapper onClick={onIconClose}>
          <Icon
            alt="modal-icon-close"
            color={white}
            data-qa="modal-icon-close"
            height={iconSize}
            name="close"
            width={iconSize}
          />
        </CloseIconWrapper>
      )}
      <Flex alignItems="center" as={Root} direction="column" justify="center">
        <Overlay
          backgroundColor={BADI_COLORS.NEUTRAL.NEUTRAL_80}
          onClick={outsideClickHandler}
          opacity={overlayOpacity}
        />
        <ModalWrapper
          backgroundColor={backgroundColor}
          isClosed={isClosed}
          isOpen={isOpen}
          isSafari={isSafari}
          padding={padding}
        >
          {onIconClose && !outsideCloseIcon && (
            <CloseIconWrapper data-qa="modal-close-icon" onClick={onIconClose}>
              <Icon
                alt="modal-icon-close"
                color={
                  backgroundColor === white
                    ? BADI_COLORS.NEUTRAL.NEUTRAL_80
                    : white
                }
                data-qa="modal-icon-close"
                height={14}
                name="close"
                width={14}
              />
            </CloseIconWrapper>
          )}
          {children}
        </ModalWrapper>
      </Flex>
    </>
  );
};

export default LayoutModal;
