import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Heading, Text, Flex, Spacer } from "@badi/badi-components";

const DynamicSectionTypeText = ({ title, elements, dataQa }) => (
  <Container data-qa={dataQa}>
    {title && (
      <Row>
        <Col xs={12}>
          <Spacer bottom={4} top={4}>
            <Heading data-qa={`${dataQa}-title`} level="20-Bold">
              {title}
            </Heading>
          </Spacer>
        </Col>
      </Row>
    )}
    <Row>
      {elements.map(
        ({ title: elementTitle, elements: innerElements }, index) => {
          return (
            <Col key={`${dataQa}-element-${index}`} md={4} xs={6}>
              <Spacer bottom={1}>
                <Flex direction="column" gutter={1}>
                  {elementTitle && (
                    <Heading
                      data-qa={`${dataQa}-subtitle-${index}`}
                      level="16-Bold"
                    >
                      {elementTitle}
                    </Heading>
                  )}
                  {innerElements.map(({ content }, indexElement) => (
                    <Text
                      body="14-Regular"
                      dataQa={`${dataQa}-element-${index}-${indexElement}`}
                      key={indexElement}
                    >
                      {content}
                    </Text>
                  ))}
                </Flex>
              </Spacer>
            </Col>
          );
        },
      )}
    </Row>
  </Container>
);

export default DynamicSectionTypeText;
