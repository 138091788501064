import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  BREAKPOINTS_PX,
  Button,
  Heading,
  Spacer,
  Text,
  useMatchMedia,
} from "@badi/badi-components";
import { getToken } from "datamodel/Auth/selectors";
import { getRoom } from "datamodel/Room/api";
import { translate } from "base/shared/Localization";
import InjectHtml from "components/inject-html";
import OverviewExample from "../overview-example";
import { Root } from "../../styled-components";

const RecommendedTenants = ({
  onClose,
  openRecommendations,
  roomId,
  room = null,
}) => {
  const [publishedRoom, setPublishedRoom] = useState(room);
  const [isWideScreen] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);
  const token = useSelector(getToken);

  const handleClick = () => {
    onClose();
    openRecommendations();
  };

  useEffect(() => {
    if (publishedRoom) {
      return;
    }

    getRoom({ token, roomId, isLoggedUser: true }).then(
      ({ address, picture: coverPicture, title, price }) => {
        setPublishedRoom({
          address,
          coverPicture,
          title,
          priceDescription: `${price.displayText}/${translate("month")}`,
        });
      },
    );
  }, []);

  return (
    <Root alignItems="center" direction="column" fullHeight={true}>
      <Spacer top={3} wide={true}>
        <Heading data-qa="recommended-tenants-modal-title" level="28-Extrabold">
          {translate("plus.opt.in_contact.tenants.title")}
        </Heading>
      </Spacer>
      <Spacer bottom={2} top={2}>
        <Text data-qa="recommended-tenants-modal-text1">
          {translate("plus.opt.in_contact.tenants.text.1")}
        </Text>
      </Spacer>
      <Text data-qa="recommended-tenants-modal-text2">
        <InjectHtml>
          {translate("plus.opt.in_contact.tenants.text.2")}
        </InjectHtml>
      </Text>
      <Spacer bottom={5} grow={1} top={4} wide={true}>
        {publishedRoom && <OverviewExample {...publishedRoom} />}
        <Spacer top={1}>
          <Text
            align="center"
            body="10-Medium"
            data-qa="recommended-tenants-modal-caption"
          >
            <InjectHtml>{translate("plus.opt.in_image.caption")}</InjectHtml>
          </Text>
        </Spacer>
      </Spacer>
      <Button
        dataQa="recommended-tenants-modal-button"
        fullWidth={true}
        onClick={handleClick}
        wide={!isWideScreen}
      >
        {translate("plus.opt.in_view.tenants.cta")}
      </Button>
    </Root>
  );
};

export default RecommendedTenants;
