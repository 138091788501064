import { getPathName } from "base/shared/Utils";
import { REFERRAL_STATUS } from "datamodel/referrals/constants";
import { getReferralsPath } from "./routes";

export const getInviteUrl = (code, deactivated) =>
  code && !deactivated ? `${getPathName(`/register?invite_code=${code}`)}` : "";

export const getReferralSteps = (status) => [
  {
    id: 1,
    label: "referrals.content.pending.status_signed_up",
    achieved: status >= REFERRAL_STATUS.USER_SIGNED_UP,
  },
  {
    id: 2,
    label: "referrals.content.pending.status_listed_or_requested_property",
    achieved: status >= REFERRAL_STATUS.LISTED_OR_REQUESTED_PROPERTY,
  },
];

export const getListerCardSize = (length) => {
  switch (length) {
    case 1:
      return 12;
    case 2:
      return 6;
    default:
      return 4;
  }
};

export const isReferralsPage = () => {
  if (typeof window !== "undefined") {
    const { pathname } = window.location;
    return pathname === getReferralsPath();
  }
  return false;
};
