import React from "react";

import { translate } from "base/shared/Localization";
import { getRelativePath } from "base/shared/Utils";

import InfoCard from "../components/info-card";

const TrustedUserCta = () => (
  <InfoCard
    content={translate("lister_score_element_verify_text")}
    href={getRelativePath("/booking-settings")}
    icon="/assets/icons/overview-trusted.svg"
    title={translate("lister_score_element_verify")}
  />
);

export default TrustedUserCta;
