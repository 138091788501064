import createReducer from "base/shared/createReducer";

import Types from "./types";
import { PremiumModel } from "./models";

function plansRequest(state) {
  return { ...state, fetching: true };
}

function plansError(state, action) {
  const { error } = action;
  return { ...state, fetching: false, error };
}

function plansSuccess(state, action) {
  const { plans } = action.result;

  return {
    ...state,
    fetching: false,
    plans,
    error: null,
  };
}

function capabilitiesSuccess(state, action) {
  const capabilities = action.result;

  return {
    ...state,
    ...capabilities,
  };
}

function roomCapabilitiesSuccess(state, action) {
  const room = action.result;

  const rooms = [...state.rooms];
  const roomIndex = rooms.findIndex((r) => r.id === room.id);

  if (roomIndex >= 0) {
    rooms[roomIndex] = room;
  } else {
    rooms.push(room);
  }

  return {
    ...state,
    rooms,
  };
}

function setPremiumOrigin(state, action) {
  return {
    ...state,
    origin: action.result,
  };
}

const actionHandlers = {
  [Types.PREMIUM_PLANS_REQUEST]: plansRequest,
  [Types.PREMIUM_PLANS_SUCCESS]: plansSuccess,
  [Types.PREMIUM_PLANS_ERROR]: plansError,
  [Types.PREMIUM_CAPABILITIES_SUCCESS]: capabilitiesSuccess,
  [Types.PREMIUM_ROOM_CAPABILITIES_SUCCESS]: roomCapabilitiesSuccess,
  [Types.PREMIUM_ORIGIN_SET]: setPremiumOrigin,
};

export default createReducer(actionHandlers, { ...PremiumModel });
