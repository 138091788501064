import React, { memo } from "react";

import { Button, BUTTON_TYPES } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import BookingFooter from "../footer";

const ConfirmationFooter = ({
  disabled,
  nextStep = () => {},
  onDecline = () => {},
  onMessageClick = () => {},
}) => (
  <BookingFooter>
    <Button
      data-qa="message-button"
      disabled={disabled}
      fullWidth={true}
      grow={1}
      onClick={onMessageClick}
      variant="secondary"
    >
      {translate("booking.reply.message_button")}
    </Button>
    <Button
      data-qa="decline-button"
      disabled={disabled}
      fullWidth={true}
      grow={1}
      onClick={onDecline}
      variant="secondary"
    >
      {translate("booking.reply.decline_button")}
    </Button>
    <Button
      data-qa="next-step-button"
      disabled={disabled}
      fullWidth={true}
      grow={1}
      onClick={nextStep}
      variant={BUTTON_TYPES.PRIMARY}
    >
      {translate("booking.reply.accept_button")}
    </Button>
  </BookingFooter>
);

export default memo(ConfirmationFooter);
