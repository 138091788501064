import React from "react";
import { Flex, Heading, Button, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";
import StepFlow from "./step-flow";
import { ImageWrapper, Screenshot, StepsWrapper } from "./styled-components";

const Flow = ({
  title,
  photo,
  screenshot,
  steps,
  onClick,
  cta,
  reverse = false,
}) => {
  return (
    <Flex direction="column" gutter={3} lg={{ direction: "column", gutter: 6 }}>
      <Heading data-qa="about-badi-flow-title" level="28-Extrabold">
        {translate(title)}
      </Heading>
      <Flex
        direction="column"
        md={{ direction: "row", justify: "spaceBetween", reverse }}
      >
        <ImageWrapper backgroundImage={photo}>
          <Screenshot reverse={reverse}>
            <Image src={screenshot} />
          </Screenshot>
        </ImageWrapper>
        <Spacer md={{ top: 0 }} top={6}>
          <StepsWrapper>
            <Flex direction="column" gutter={3}>
              {steps.map((step) => (
                <StepFlow key={step.title} {...step} />
              ))}
              <Spacer left={6}>
                <Button dataQa="about-badi-flow-cta" onClick={onClick}>
                  {translate(cta)}
                </Button>
              </Spacer>
            </Flex>
          </StepsWrapper>
        </Spacer>
      </Flex>
    </Flex>
  );
};

export default Flow;
