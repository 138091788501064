export const SOCIAL_NETWORKS = {
  MESSENGER: "messenger",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  COPY: "copy",
  WHATSAPP: "whatsapp",
};

export const DEFAULT_SOCIAL_NETWORKS = [
  SOCIAL_NETWORKS.MESSENGER,
  SOCIAL_NETWORKS.FACEBOOK,
  SOCIAL_NETWORKS.TWITTER,
  SOCIAL_NETWORKS.COPY,
];
