import React from "react";
import { withRouter } from "react-router";
import { getAboutBadiPath } from "Sections/Home/routes";
import Community from "./community";

const CommunityRoute = ({ router, ...props }) => {
  const redirectToAboutBadi = () => {
    router.push(getAboutBadiPath());
  };

  return <Community {...props} onClick={redirectToAboutBadi} />;
};
export default withRouter(CommunityRoute);
