import { generateFetchTypes } from "base/shared/TypeHelper";
import { fetchDrafts, fetchRoom, fetchDraftById } from "datamodel/Room/api";
import { fetchCountryTemplate, fetchRoomModerationResult } from "./api";

import Types from "./types";

export default {
  resetRoomList() {
    return { type: Types.LISTROOM_RESET };
  },
  setInitialStep({ initialStep, title }) {
    return { type: Types.LISTROOM_INITIALSTEP, step: initialStep, title };
  },
  setRoomFlatmate(flatmate, publish = false) {
    return { type: Types.LISTROOM_FLATMATESTEP, flatmate, publish };
  },
  setRoomId(id) {
    return { type: Types.LISTROOM_ID, id };
  },
  setRoomLocation(location, publish = false) {
    return { type: Types.LISTROOM_LOCATIONSTEP, location, publish };
  },
  setRoomPhotos(photos, publish = false) {
    return { type: Types.LISTROOM_PHOTOSSTEP, photos, publish };
  },
  setRoomProperty(property, publish = false) {
    return { type: Types.LISTROOM_PROPERTYSTEP, property, publish };
  },
  setRoomRoom(room, publish = false) {
    return { type: Types.LISTROOM_ROOMSTEP, room, publish };
  },
  setRoomVisitPreferences(visitPreferences, publish = false) {
    return { type: Types.LISTROOM_VISITSTEP, visitPreferences, publish };
  },
  setTotalSteps(totalSteps) {
    return { type: Types.LISTROOM_TOTALSTEPS, totalSteps };
  },
  getRoom({ token, roomId, ip, locale }) {
    return {
      types: generateFetchTypes(Types.LISTROOM_REQUEST),
      request: fetchRoom(token, roomId, true, { locale, ip }),
    };
  },
  getDrafts({ token, ip, locale }, placeType) {
    return {
      types: generateFetchTypes(Types.DRAFTFETCH_REQUEST),
      request: fetchDrafts(token, { locale, ip }, placeType),
    };
  },
  getDraftById({ token, ip, locale }, draftId) {
    return {
      types: generateFetchTypes(Types.FETCH_DRAFT_BY_ID_REQUEST),
      request: fetchDraftById(token, { locale, ip }, draftId),
    };
  },
  setCountryTemplateFields({ token, countryCode }) {
    return {
      types: generateFetchTypes(Types.ROOM_TEMPLATE_REQUEST),
      request: fetchCountryTemplate(token),
      payload: { countryCode },
    };
  },
  getModerationResult({ token, roomId }) {
    return {
      types: generateFetchTypes(Types.ROOM_MODERATION_RESULT_REQUEST),
      request: fetchRoomModerationResult(token, roomId),
    };
  },
  setModerationResult(moderationResult, roomId) {
    return {
      type: Types.ROOM_MODERATION_RESULT_SET,
      payload: {
        moderationResult,
        roomId,
      },
    };
  },
};
