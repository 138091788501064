import React from "react";
import { Heading, Text, Spacer, Flex } from "@badi/badi-components";
import { Container, Row, Col } from "reactstrap";

import Image from "components/Image";

import {
  DESKTOP_FLEX_CONFIG,
  DESKTOP_MARGINS,
  MOBILE_MARGINS,
  VARIANT,
} from "./constants";
import { Root, ImageWrapper } from "./styled-components";
import { getVariantStyles } from "./utils";

const CheckoutHeader = ({
  children = null,
  image = null,
  title,
  description,
  variant = VARIANT.TERTIARY,
}) => {
  const { descriptionColor, titleColor, background } =
    getVariantStyles(variant);

  return (
    <Root background={background}>
      <Spacer {...MOBILE_MARGINS} md={DESKTOP_MARGINS}>
        <Container>
          <Row>
            <Col lg={{ size: 6, offset: 3 }} sm={12}>
              <Flex direction="column" gutter={2} md={DESKTOP_FLEX_CONFIG}>
                {image && (
                  <ImageWrapper>
                    <Image alt="checkout image" lazy={false} src={image} />
                  </ImageWrapper>
                )}
                <Heading
                  align="center"
                  color={titleColor}
                  data-qa="checkout-layout-title"
                  level="32-Extrabold"
                >
                  {title}
                </Heading>
                <Text
                  align="center"
                  color={descriptionColor}
                  dataQa="checkout-layout-description"
                >
                  {description}
                </Text>
                {children}
              </Flex>
            </Col>
          </Row>
        </Container>
      </Spacer>
    </Root>
  );
};

export default CheckoutHeader;
