import React from "react";
import styled from "styled-components";
import { colors } from "@badi/badi-components";

export const StyledImage = styled.span`
  display: inline-block;
  svg {
    display: block;
  }
`;

const SuccessCheck = ({ size = 32, color = colors.green, className = "" }) => (
  <StyledImage className={className}>
    <svg
      height={size}
      viewBox="0 0 48 48"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="nonzero">
        <circle cx="24" cy="24" fill={color} r="24" />
        <path
          d="M20.97 30s0 0 0 0a.68.68 0 01-.47-.19l-5.3-5.06a.6.6 0 010-.89.7.7 0 01.93 0l4.84 4.62 10.9-10.3a.7.7 0 01.94 0 .6.6 0 010 .9L21.44 29.81a.68.68 0 01-.47.18z"
          stroke="#FFF"
          strokeWidth="2.25"
        />
      </g>
    </svg>
  </StyledImage>
);

export default SuccessCheck;
