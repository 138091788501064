const RequirementsModel = {
  gdpr: { show: false, callback: null },
  onboarding: { show: false, callback: null },
  profile: { show: false, callback: null },
  phone: { show: false, callback: null },
  login: { show: false, callback: null },
  register: { show: false, callback: null },
  termsAndConditions: { show: false, callback: null },
  countryVat: { show: false, callback: null },
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Requirements) {
    return {
      ...RequirementsModel,
      error: "There was an error in RequirementsModel.",
    };
  }
  return state;
}

export { RequirementsModel, setInitialState };
