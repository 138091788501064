import React from "react";
import {
  Flex,
  Heading,
  Button,
  BUTTON_TYPES,
  Text,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Image from "components/Image";
import { ImageWrapper } from "./styled-components";

const ThankYouScreen = ({ onClose }) => {
  return (
    <Flex direction="column" fullHeight={true}>
      <Flex
        align-items="center"
        direction="column"
        fullHeight={true}
        grow={1}
        gutter={1}
        justify="center"
      >
        <ImageWrapper>
          <Image
            alt="visit proposal scheduled"
            src="/assets/images/booking-success-bump.svg"
          />
        </ImageWrapper>
        <Heading
          align="center"
          data-qa="visit-proposal-scheduled-title"
          level={2}
        >
          {translate("inbox_popup_visit.proposal.success_title")}
        </Heading>
        <Text align="center">
          {translate("inbox_popup_visit.proposal.success_description")}
        </Text>
      </Flex>
      <Button
        dataQa="visit-proposal-scheduled-button"
        fullWidth={true}
        onClick={onClose}
        variant={BUTTON_TYPES.PRIMARY}
      >
        {translate("inbox_popup_visit.proposal.success_button_text")}
      </Button>
    </Flex>
  );
};

export default ThankYouScreen;
