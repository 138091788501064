import Review from "./review-model";

export default class ReviewsSummary {
  constructor(entity) {
    this.entity = entity || {};
  }

  get reviews() {
    return this.entity.reviews.map((review) => new Review(review).toJSON());
  }

  get total() {
    return this.entity.total || 0;
  }

  toJSON() {
    return {
      reviews: this.reviews,
      total: this.total,
    };
  }
}
