import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { FeatureFlag } from "./models";

const fetchFeatures = (token, isLoggedUser, additionalParams) => {
  const { BADI_API_URL, BADI_FEATURES, BADI_FEATURES_CONTEXT } = envVars();
  const service = isLoggedUser ? BADI_FEATURES_CONTEXT : BADI_FEATURES;

  return fetch(BADI_API_URL + service, { token, additionalParams }).then(
    (data) => new FeatureFlag(data).toJSON(),
  );
};

export { fetchFeatures };
