import { connect } from "react-redux";
import {
  showBillsIncludedFlagSelector,
  isShowPriceDiscountsSelector,
  getMatchProfileSelector,
} from "datamodel/Features/selectors";

import Rooms from "./rooms";

const mapStateToProps = (state) => ({
  showBillsIncludedFlag: showBillsIncludedFlagSelector(state),
  showPriceDiscount: isShowPriceDiscountsSelector(state),
  matchProfileFlag: getMatchProfileSelector(state),
});

export default connect(mapStateToProps)(Rooms);
