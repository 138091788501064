import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPremiumCapabilitiesSelector,
  isPremiumRoomLoadedSelector,
  hasRoomCapabilitySelector,
} from "datamodel/premium/selectors";
import { getPremiumRoomCapabilities } from "datamodel/premium/actions";
import { PREMIUM_FEATURES } from "datamodel/premium/constants";
import { isPremiumEnabledSelector } from "./selectors";

export const PREMIUM_CAPABILITIES = PREMIUM_FEATURES;

export const usePremium = ({ roomId, capabilityId } = {}) => {
  const dispatch = useDispatch();
  const enabled = useSelector(isPremiumEnabledSelector);
  const capabilities = useSelector(getPremiumCapabilitiesSelector);

  const isPremium = useMemo(() => {
    if (!capabilityId || !capabilities) {
      return false;
    }

    return !!capabilities.find((capability) => capability.id === capabilityId);
  }, [capabilityId]);
  const loaded =
    !!roomId &&
    useSelector((state) => isPremiumRoomLoadedSelector(state, roomId));
  const hasCapability =
    !!roomId &&
    useSelector((state) =>
      hasRoomCapabilitySelector(state, { roomId, capabilityId }),
    );

  useEffect(() => {
    if (enabled && !loaded && roomId) {
      dispatch(getPremiumRoomCapabilities({ roomId }));
    }
  }, [dispatch, enabled, loaded, roomId]);

  return {
    premiumEnabled: enabled,
    isPremium,
    hasCapability,
  };
};
