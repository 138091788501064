import React from "react";
import { ProgressBarRoot, Uncompleted } from "./styled-components";

const ProgressBar = ({ percentage }) => {
  if (percentage < 0) return null;

  return (
    <ProgressBarRoot>
      <Uncompleted percentage={100 - percentage} />
    </ProgressBarRoot>
  );
};

export default ProgressBar;
