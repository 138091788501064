import { BREAKPOINTS_PX } from "@badi/badi-components";

export const IMAGE_STYLES = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "100%",
  width: "100%",
};

export const SCREEN_SIZE_QUERIES = [
  `(min-width: ${BREAKPOINTS_PX.md})`,
  `(min-width: ${BREAKPOINTS_PX.lg})`,
];
