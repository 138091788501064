import styled from "styled-components";
import { BADI_COLORS, mediaquery } from "@badi/badi-components";

export const GeosuggestWrapper = styled.div`
  .geosuggest {
    font-size: 1em;
    position: relative;
    width: 100%;
    text-align: left;
  }
  .geosuggest__input-wrapper {
    height: 100%;
    width: 100%;
  }

  .geosuggest__input {
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.1px;
    text-align: left;
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};

    line-height: 1.25;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 24px 0 10px;
    transition: 0.15s linear;
    border: 2px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
    border-radius: 4px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    :placeholder {
      font-weight: 500;
      text-align: left;
      color: ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
    }
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    margin-top: -2px;
    border: 0;
    border-bottom: 2px solid #eaeaea;
    border-right: 2px solid #eaeaea;
    border-left: 2px solid #eaeaea;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-sizing: border-box;
    background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
    -webkit-transition: none;
    transition: none;
    box-shadow: none;

    width: 100%;

    &.extendedWidth {
      ${mediaquery.lg`
        width: 100%;
        min-width: 600px;
      `}
    }
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
    visibility: hidden;
  }

  .geosuggest__item {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};

    padding: 9px;
    cursor: pointer;
    line-height: normal;

    ${mediaquery.lg`
      font-size: 1rem;
      padding-left: 52px;
    `}
  }
  .geosuggest__item_min li {
    padding: 9px 9px 9px 26px;
  }
  .geosuggest__item:hover,
  .geosuggest__item:focus {
    font-weight: bold;
    text-align: left;
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  }
  .geosuggest__item--active {
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
    span {
      background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
    }
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
  }

  .geosuggest__option {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .geosuggest__text {
    display: inline-block;

    margin: 0;

    display: -webkit-box;
    max-width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }

  .geosuggest__tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    display: inline-block;

    padding: 2px 8px;

    white-space: nowrap;

    color: #0edc84;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

    ${mediaquery.lg`
      font-size: 15px;
      line-height: 24px;
    `}
  }
`;
