import React from "react";
import { Spacer, TextButton, Flex, Icon, colors } from "@badi/badi-components";

import BadiLogo from "components/Icons/BadiLogo";
import { translate } from "base/shared/Localization";

import {
  TopBarRoot,
  LogoWrap,
  TextButtonWithIcon,
  PercentageRoot,
  PercentageBar,
} from "./styled-components";

const ProgressBar = ({ percentage }) => {
  return (
    <PercentageRoot>
      <PercentageBar percentage={percentage} />
    </PercentageRoot>
  );
};

ProgressBar.defaultProps = {
  percentage: 100,
};

const TopBar = ({
  onClose = null,
  percentage = 100,
  showProgress = true,
  showLogo = true,
}) => {
  return (
    <TopBarRoot>
      <Spacer bottom={3} left={3} right={3} top={3}>
        <Flex alignItems="center" justify="spaceBetween">
          {showLogo && (
            <LogoWrap>
              <BadiLogo data-qa="topbar-badi-logo" />
            </LogoWrap>
          )}
          {onClose && (
            <TextButton
              as={TextButtonWithIcon}
              color={colors.darkGrey}
              data-qa={"topbar-close-button"}
              hoverColor={colors.darkGrey}
              onClick={onClose}
              small={true}
              withIcon={<Icon name="close" />}
            >
              {translate("stepper_layout.topbar.close").toUpperCase()}
            </TextButton>
          )}
        </Flex>
      </Spacer>
      {showProgress && (
        <ProgressBar data-qa="stepper-progress-bar" percentage={percentage} />
      )}
    </TopBarRoot>
  );
};

export default TopBar;
