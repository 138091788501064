export default class DeleteReasons {
  constructor(data) {
    this.entity = data;
  }

  get reasons() {
    return this.entity.reasons.map(
      ({ id, label, placeholder, open_field: requireTextarea }) => ({
        id,
        label,
        placeholder,
        requireTextarea,
      }),
    );
  }

  toJSON() {
    return {
      reasons: this.reasons,
    };
  }
}
