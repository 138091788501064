import React from "react";
import { connect } from "react-redux";
import envVars from "base/shared/Env";
import Actions from "datamodel/Auth/actions";
import { getUserId, newUserSelector } from "datamodel/Auth/selectors";
import AuthenticationForm from "./authentication-form";
import { GoogleOAuthProvider } from "@react-oauth/google";

const mapStateToProps = (state) => ({
  newUser: newUserSelector(state),
  userId: getUserId(state),
  locale: state.Localization.locale,
});

const mapDispatchToProps = {
  login: Actions.login,
  register: Actions.register,
  setIsRememberMeChecked: Actions.setIsRememberMeChecked,
};

const { BADI_AUTH_GOOGLE_APP_ID } = envVars();

const ConnectedAuthenticationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticationForm);

const AuthenticationFormWithGoogleOAuth = (props) => (
  <GoogleOAuthProvider clientId={BADI_AUTH_GOOGLE_APP_ID}>
    <ConnectedAuthenticationForm {...props} />
  </GoogleOAuthProvider>
);

export default AuthenticationFormWithGoogleOAuth;
