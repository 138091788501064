import React from "react";

import { PLANS_CATEGORIES } from "datamodel/plans/constants";
import Plans from "../plans";

const Boosting = (props) => {
  return (
    <Plans {...props} categories={[PLANS_CATEGORIES.STANDALONE_BOOSTING]} />
  );
};

export default Boosting;
