import styled from "styled-components";
import { unitizedPx, mediaquery } from "@badi/badi-components";

export const BookingWrapper = styled.div`
  position: relative;
`;

/**
 * @deprecated Since new contact flow
 */
export const InfoWrapper = styled.div`
  ${({ single }) =>
    single
      ? `
          position: absolute;
          top: ${unitizedPx(8)};
          width: 100%;
          left: 0;
          p {
            width: 100%;

            ${mediaquery.md` 
              text-align: center; 
            `}
          }
        `
      : `
          position: absolute;
          top: ${unitizedPx(8)};
          left: calc(50% - ${unitizedPx(12.5)});
          width: ${unitizedPx(29)};
        `}
`;

export const ContactInfo = styled.div`
  position: absolute;
  top: ${unitizedPx(8)};
  width: 100%;
  left: 0;
  p {
    width: 100%;
    text-align: center;
  }
`;

export const BenefitsWrapper = styled.div`
  position: absolute;
  bottom: ${unitizedPx(-4)};
  left: calc(50% - ${unitizedPx(12.5)});
  width: ${unitizedPx(25)};
  text-align: center;
`;
