import React from "react";

import NewBooking from "./new-booking-mapping";

const NewBookingRoute = ({ router, location, params, authtoken }) => {
  const { query } = location;
  const { from_connection: fromConnection, invite_to: seekerId } = query;
  const { roomId } = params;
  const isInstantBooking = !!roomId;

  return (
    <NewBooking
      connectionId={Number(fromConnection) || null}
      isInstantBooking={isInstantBooking}
      roomId={Number(roomId)}
      router={router}
      seekerId={Number(seekerId) || null}
      token={authtoken}
    />
  );
};

export default NewBookingRoute;
