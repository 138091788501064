import { connect } from "react-redux";

import Actions from "datamodel/RoomList/actions";
import { getUserConfirmation, getGhost } from "datamodel/User/selectors";
import { getRoomFlatmateSelector } from "datamodel/RoomList/selectors";
import Flatmate from "./flatmate-fields";

const mapStateToProps = (state) => ({
  storeFlatmate: getRoomFlatmateSelector(state),
  ghost: getGhost(state),
  phoneConfirmation: getUserConfirmation(state, "phone"),
});

const mapDispatchToProps = {
  setRoomFlatmate: Actions.setRoomFlatmate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Flatmate);
