import Room from "./models/room-model";

const emptyRoom = new Room().toJSON();

export const RoomModel = {
  fetching: false,
  fetched: false,
  error: null,
  room: emptyRoom,
  similarRooms: [],
};

export function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Room) {
    return { ...RoomModel, error: "There was an error in RoomModel." };
  }
  return state;
}
