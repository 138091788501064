import createReducer from "base/shared/createReducer";

import Types from "./types";
import { ViewersModel } from "./models";
import { parseViewersArray, makeViewersSet } from "./utils";

function viewersRequest(state) {
  return { ...state, fetching: true, fetched: false };
}

function viewersError(state, action) {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error,
  };
}

function viewersSuccess(state, { result }) {
  const { data } = result;
  const list = data.results?.length > 0 && parseViewersArray(data.results);
  const { previous_token, next_token } = data;

  return {
    ...state,
    fetching: false,
    fetched: true,
    list,
    error: null,
    pagination: {
      previousToken: previous_token,
      nextToken: next_token,
    },
  };
}

function setViewerStatusRequest(state) {
  return state;
}

function setViewerStatusError(state) {
  return state;
}

function setViewerStatusSuccess(state) {
  return state;
}

function viewersReset() {
  return {
    ...ViewersModel,
  };
}

function viewerContacted(state, { id }) {
  return {
    ...state,
    list: makeViewersSet(state.list, id),
  };
}

const actionHandlers = {
  [Types.VIEWERS_REQUEST]: viewersRequest,
  [Types.VIEWERS_SUCCESS]: viewersSuccess,
  [Types.VIEWERS_ERROR]: viewersError,
  [Types.VIEWER_SET_STATUS_REQUEST]: setViewerStatusRequest,
  [Types.VIEWER_SET_STATUS_SUCCESS]: setViewerStatusSuccess,
  [Types.VIEWER_SET_STATUS_ERROR]: setViewerStatusError,
  [Types.VIEWERS_RESET]: viewersReset,
  [Types.VIEWER_CONTACTED]: viewerContacted,
};

export default createReducer(actionHandlers, { ...ViewersModel });
