import React, { Component } from "react";

import envVars from "base/shared/Env";
import StepperLayout, { Step } from "components/Layout/stepper-layout";
import StripeApi from "base/shared/stripe-api";

import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import {
  getBookingRequest,
  confirmBookingRequest,
  declineBookingRequest,
} from "datamodel/booking/api";
import { getOtherUserRole, isSeeker, isLister } from "datamodel/User/utils";
import { getRelativePath } from "base/shared/Utils";
import { getConversationPath } from "Sections/chat/routes";
import { openBookingLeaveModal } from "components/booking-leave-modal";

import PaymentStep from "../payment-pending-booking-step";
import UserMessageStep, { UserMessageStepFooter } from "../user-message-step";
import AcceptedStep from "../accepted-step";
import ConfirmationFooter from "./confirmation-footer";
import { openDiscardModal, closeDiscardModal } from "../discard-modal";
import { getBookingRequestErrorMessage } from "./utils";
import { BOOKING_STEPS } from "../constants";
import { STATUS, ACCEPTED_STEP_CONTENT } from "./constants";

class PendingBooking extends Component {
  state = {
    validatedSteps: new Set(),
    fields: {},
    status: STATUS.IDLE,
    loading: false,
    steps: [],
    bookingInfo: null,
    userRole: "",
    otherUserRole: "",
    userBookingMessage: "",
    message: "",
  };

  componentDidMount() {
    const { token, requestId } = this.props;
    const steps = [
      {
        id: BOOKING_STEPS.PAYMENT,
        render: PaymentStep,
        footer: ConfirmationFooter,
        title: translate("booking.new.steps.payment.heading"),
      },
    ];

    getBookingRequest({
      token,
      requestId,
    })
      .then((response) => {
        const otherRole = getOtherUserRole(response.userRole);
        const userBookingMessage = response.message;

        if (isSeeker(response.userRole) && userBookingMessage) {
          steps.unshift({
            id: BOOKING_STEPS.MESSAGE,
            render: UserMessageStep,
            footer: UserMessageStepFooter,
            title: translate("booking.pending.steps.message.heading"),
          });
        }

        this.setState({
          bookingInfo: response,
          userRole: response.userRole,
          otherUserRole: otherRole,
          seeker: response.seeker.name,
          steps,
          userBookingMessage: response.message,
        });
      })
      .catch((error) => {
        UserFeedback.exception(
          "booking.reply.notifications.request_error_title",
          error.message,
          error,
        );
      });

    this.stripe.init();
  }

  stripe = new StripeApi({ token: envVars().BADI_PAYMENT_TOKEN });

  handleValidStep = ({ id, valid, payload }) => {
    this.setState(({ fields, validatedSteps }) => {
      // check if we remove or add the item
      if (valid) {
        validatedSteps.add(id);
      } else {
        validatedSteps.delete(id);
      }

      return {
        fields: {
          ...fields,
          ...payload,
        },
        validatedSteps,
      };
    });
  };

  leaveFlow = () => {
    const { router } = this.props;

    router.push(getRelativePath("/inbox"));
  };

  handleFlowClose = (stepId) => {
    const { fromConnection, router } = this.props;

    if (stepId === BOOKING_STEPS.CREATED) {
      this.leaveFlow();
    } else if (this.state.status !== STATUS.ERROR) {
      openBookingLeaveModal({ onLeave: this.leaveFlow });
    } else {
      router.push(getConversationPath({ connectionId: fromConnection }));
    }
  };

  handleMessageClick = () => {
    const { fromConnection, router } = this.props;

    router.push(getConversationPath({ connectionId: fromConnection }));
  };

  handleModalClose = ({ nextRoute } = {}) => {
    const { router } = this.props;

    closeDiscardModal();

    if (!nextRoute) return;

    router.replace(getRelativePath(nextRoute));
  };

  handleBookingDecline = () => {
    const { bookingInfo, otherUserRole } = this.state;
    const { token, requestId, fromConnection } = this.props;

    const user = bookingInfo[otherUserRole];

    openDiscardModal({
      fromConnection,
      token,
      userName: user.name,
      userAvatar: user.avatar,
      onModalClose: this.handleModalClose,
      onDiscard: () => {
        return declineBookingRequest({
          token,
          requestId,
        });
      },
    });
  };

  handleAcceptBookingRequest = async (params) => {
    const { error } = await confirmBookingRequest(params);
    this.setState({ loading: false });

    if (error) {
      const { userRole } = this.state;
      const errorMessage = getBookingRequestErrorMessage({
        userRole,
        error,
      });

      if (isSeeker(userRole)) {
        UserFeedback.exception("", errorMessage, error);
      }

      this.setState({ status: STATUS.ERROR, message: errorMessage });
      return;
    }

    this.setState({ status: STATUS.ACCEPTED });
  };

  handleOnSubmit = () => {
    const { token, requestId } = this.props;

    const params = {
      token,
      requestId,
      stripeVerification: this.stripe.authorizeCardPayment,
    };
    this.setState({ loading: true });

    return this.handleAcceptBookingRequest(params);
  };

  isBookingRequestAnswered = () => {
    const { status, userRole } = this.state;

    return (
      status === STATUS.ACCEPTED ||
      (status === STATUS.ERROR && isLister(userRole))
    );
  };

  render() {
    const {
      validatedSteps,
      steps,
      status,
      loading,
      bookingInfo,
      userRole,
      message,
      userBookingMessage,
      seeker,
    } = this.state;
    const { fromConnection, token } = this.props;

    const { SUCCESS_CTA, SUCCESS_DESCRIPTION, SUCCESS_TITLE } =
      ACCEPTED_STEP_CONTENT[userRole.toUpperCase()] || {};

    return (
      <StepperLayout
        disabled={loading}
        onClose={this.handleFlowClose}
        onDecline={this.handleBookingDecline}
        onSubmit={this.handleOnSubmit}
      >
        {this.isBookingRequestAnswered() ? (
          <AcceptedStep
            connectionId={fromConnection}
            cta={SUCCESS_CTA}
            description={SUCCESS_DESCRIPTION}
            id={BOOKING_STEPS.CREATED}
            message={message}
            seeker={seeker}
            status={status}
            title={SUCCESS_TITLE}
          />
        ) : (
          steps.map(({ id, title, render: Render, footer: Footer }) => (
            <Step
              footer={Footer}
              id={id}
              key={id}
              onMessageClick={this.handleMessageClick}
              valid={validatedSteps.has(id)}
            >
              {bookingInfo && (
                <Render
                  bookingRequestDetail={bookingInfo}
                  connectionId={Number(fromConnection)}
                  id={id}
                  leaveFlow={this.leaveFlow}
                  message={userBookingMessage}
                  onValidation={this.handleValidStep}
                  title={title}
                  token={token}
                  userRole={userRole}
                />
              )}
            </Step>
          ))
        )}
      </StepperLayout>
    );
  }
}

PendingBooking.defaultProps = {
  fromConnection: 0,
  router: {},
};

export default PendingBooking;
