import { createSelector } from "reselect";

const getSearchFilters = (state) => state.SearchFilters;

export const getSearchPage = createSelector(
  getSearchFilters,
  (filters) => filters.page,
);
export const getSearchFiltersZoom = createSelector(
  getSearchFilters,
  (filters) => filters.zoom,
);
export const getSearchFiltersBounds = createSelector(
  getSearchFilters,
  (filters) => filters.bounds,
);
export const getSearchFiltersCoordinates = createSelector(
  getSearchFilters,
  (filters) => filters.coordinates,
);

export const getSearchFiltersRecalculateBounds = createSelector(
  getSearchFilters,
  (filters) => filters.recalculateBounds,
);

export const getSearchFiltersStayOptions = createSelector(
  getSearchFilters,
  (filters) => filters.stayOptions,
);

export const getSearchFiltersSelector = createSelector(
  getSearchFilters,
  ({ recalculateBounds, ...restFilter }) => restFilter,
);

export const getSearchFiltersPreviousToken = createSelector(
  getSearchFilters,
  (filters) => filters.previousToken,
);

export const getSearchFiltersMarketplaceSegmentsValues = createSelector(
  getSearchFilters,
  (filters) => ({
    badiCommunity: filters.marketplaceSegmentsFilter.badiCommunity,
    badiRooms: filters.marketplaceSegmentsFilter.badiRooms,
  }),
);
