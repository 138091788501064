import React from "react";

export default ({ color = "#1E3255" }) => (
  <svg
    className="badi-logo"
    height="25"
    width="62"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.09 7.7a6.07 6.07 0 015.38 2.76l.1-2.46 3.9-.01V24.2h-3.9l-.1-2.47a6.07 6.07 0 01-5.38 2.77c-4.34 0-7.68-3.34-7.68-8.33 0-5.08 3.34-8.47 7.68-8.47zM54.13.74V24.2h-3.9l-.09-2.47a6.07 6.07 0 01-5.38 2.77c-4.34 0-7.68-3.34-7.68-8.33 0-5.08 3.33-8.47 7.68-8.47 2.43 0 4.19.99 5.32 2.67V.74h4.05zM4.05.74v9.6a6.09 6.09 0 015.32-2.65c4.32 0 7.68 3.4 7.68 8.47 0 5-3.36 8.34-7.67 8.34a6.09 6.09 0 01-5.39-2.74L3.9 24.2H0V.74h4.05zM60.8 8V24.2h-4.05V7.99h4.05zm-33.82 3.19c-2.62 0-4.4 1.98-4.4 4.93 0 2.92 1.78 4.87 4.4 4.87 2.64 0 4.43-1.95 4.43-4.9 0-2.92-1.79-4.9-4.43-4.9zm-18.53 0c-2.65 0-4.4 1.98-4.4 4.9 0 2.95 1.75 4.9 4.4 4.9 2.62 0 4.4-1.95 4.4-4.87 0-2.95-1.78-4.93-4.4-4.93zm37.2 0c-2.63 0-4.41 1.98-4.41 4.93 0 2.92 1.78 4.87 4.4 4.87 2.65 0 4.44-1.95 4.44-4.9 0-2.92-1.79-4.9-4.44-4.9zM58.77.5c1.4 0 2.38 1.02 2.38 2.44a2.3 2.3 0 01-2.38 2.4c-1.4 0-2.4-1-2.4-2.4A2.34 2.34 0 0158.77.5z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);
