import styled from "styled-components";
import { Button, mediaquery, unitizedPx } from "@badi/badi-components";

export const ArrowWrapper = styled.div`
  ${mediaquery.md`
    margin-left: ${unitizedPx(-1)};
  `}
`;

export const TextWrapper = styled.span`
  display: none;

  ${mediaquery.md`
    display: block;
  `}
`;

export const StyledButton = styled(Button)`
  padding: 0 ${unitizedPx(1)};

  ${mediaquery.md`
    padding: 0 ${unitizedPx(2)};
  `}
`;
