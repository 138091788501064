import React from "react";

import { Text, Spacer, Flex, colors, unitizedPx } from "@badi/badi-components";

import { Picture } from "./styled-components";

const AVATAR_SIZE = unitizedPx(8);

const PictureMessage = ({
  avatar = null,
  isCurrentUser,
  date,
  name,
  onClick = () => {},
  url,
}) => (
  <>
    <Flex alignItems="flexEnd" gutter={2} reverse={isCurrentUser}>
      {avatar}
      <Picture
        isCurrentUser={isCurrentUser}
        onClick={onClick}
        role="link"
        src={url}
      />
    </Flex>
    <Spacer top={1}>
      <Flex reverse={isCurrentUser}>
        <div basis={AVATAR_SIZE} />
        <Text
          body={3}
          color={colors.midGrey}
          data-qa="inbox-message-author"
          inline={true}
        >{`${date} · ${name}`}</Text>
      </Flex>
    </Spacer>
  </>
);

export default PictureMessage;
