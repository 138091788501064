import React, { memo } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import FormBlock from "components/form-block";
import TagList from "components/Form/Tags/TagListWithFormsy";

const Interests = ({ tagList, tags, saved }) => (
  <FormBlock
    dataQa="profile-form-interests"
    saved={saved}
    title={translate("profile.form.label.tags")}
  >
    <TagList mode="add" name="tags" tags={tagList} value={tags} />
    <Spacer bottom={1} />
  </FormBlock>
);

export default memo(Interests);
