import { getDisplayPrice } from "base/shared/Utils";
import InboxRoomModel from "./models/inbox-rooms-model";
import { HIGHEST_PRIORITY_STATUSES } from "./constants";

function parseRoom(room) {
  return {
    id: room.id,
    title: room.title,
    price: getDisplayPrice(
      room.monthly_price.price,
      room.monthly_price.currency,
    ),
    billsIncluded: room.monthly_price.bills_included,
    premiumPlan: !!room.premium_plan,
    externalAgencyId: room.agency_external_id,
  };
}

function parseUser(user) {
  const picture = user.cover_picture ? user.cover_picture.width_100_url : null;

  return {
    id: user.id,
    name: user.first_name,
    age: user.age,
    avatarBadge: user.avatar_badge,
    picture,
  };
}

export function parseConnection(connection) {
  return {
    id: connection.id,
    hasNotifications: connection.has_notifications,
    lastActivity: connection.last_activity,
    lastActivityInWords: connection.last_activity_in_words,
    lastMessagePreview: connection.last_message_preview,
    nextStep: connection.next_step,
    room: parseRoom(connection.room),
    status: connection.status,
    statusBadgeLabel: connection.status_badge_label,
    statusLabel: connection.status_label,
    statusLevel: connection.status_level,
    statusTitle: connection.status_title,
    user: parseUser(connection.other_user),
    visitId: connection.visit_id,
  };
}

export function parseConnections(connections) {
  return connections
    .filter((connection) => Boolean(connection.other_user))
    .map((connection) => parseConnection(connection));
}

export function parsePagination(data) {
  const { current_page: currentPage, total_pages: totalPages, total } = data;

  return {
    currentPage,
    totalPages,
    total,
  };
}

export function parseRoomsSummary(rooms) {
  return rooms.map((room) => new InboxRoomModel(room).toJSON());
}

export const sortByStatusAndLastActivity = (a, b) => {
  const aPriority = HIGHEST_PRIORITY_STATUSES.includes(a.status);
  const bPriority = HIGHEST_PRIORITY_STATUSES.includes(b.status);

  if ((aPriority && bPriority) || (!aPriority && !bPriority)) {
    return new Date(b.lastActivity) - new Date(a.lastActivity);
  } else if (bPriority) {
    return 1;
  }
  return -1;
};

export function updateConnections(
  category,
  perPage,
  connections,
  newConnection,
) {
  const parsedConnection = parseConnection(newConnection);
  let newConnections = connections;
  const connectionIndex = connections.findIndex(
    (connection) => connection.id === parsedConnection.id,
  );

  if (connectionIndex < 0) {
    if (!newConnection.categories.includes(category)) return newConnections;
    newConnections = [...connections, parsedConnection];
  } else {
    newConnections = [...connections];
    newConnections[connectionIndex] = parsedConnection;
  }

  return newConnections.sort(sortByStatusAndLastActivity);
}
