import React, { useState, useEffect } from "react";
import { Flex, Icon, Spacer } from "@badi/badi-components";
import GeoSuggest from "components/geo-suggest/geo-suggest";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";

import { SearchLocationWrapper, GeosuggestWrapper } from "./styled-components";

const Location = ({
  onUpdate,
  setIsLocationCompleted,
  existingLocation = {},
}) => {
  const [location, setLocation] = useState(existingLocation);

  const handleSaveLocation = (updatedLocation) => {
    setLocation(updatedLocation);
    onUpdate({ location: updatedLocation });
    setIsLocationCompleted(true);
  };

  const handleInputChange = (param) => {
    if (param === "") {
      const emptyLocation = { label: null, placeId: null };

      handleSaveLocation(emptyLocation, false);
    }
  };

  const handleSuggestSelect = ({ placeId, label: locationLabel }) => {
    handleSaveLocation({
      label: locationLabel,
      placeId,
    });
  };

  useEffect(() => {
    setIsLocationCompleted(!!location.label);
  }, []);

  return (
    <SearchLocationWrapper>
      <Flex alignItems="center" fullHeight={true} justify="flexStart">
        <GeosuggestWrapper basis="100%">
          <GeoSuggest
            data-qa="seeker-preferences-location-input"
            includeTypeLabels={true}
            initialValue={location.label}
            nearby={true}
            onChange={handleInputChange}
            onSuggestSelect={handleSuggestSelect}
            placeholder={translate("search.home_city_placeholder")}
            prefillableSearch={true}
            recent={true}
            types={["(cities)"]}
          />
        </GeosuggestWrapper>
        <Spacer right={2} top={1}>
          <Icon
            alt={translate("search.home_city_placeholder")}
            height={24}
            iconMaxHeight={24}
            iconMaxWidth={24}
            src={getPublicAsset("icons/seeker-preferences-location.svg")}
            width={24}
          />
        </Spacer>
      </Flex>
    </SearchLocationWrapper>
  );
};

export default Location;
