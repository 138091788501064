import React from "react";

import ListerMetrics from "./lister-metrics-mapping";

const ListerMetricsRoute = ({ params, location, router }) => {
  const { id } = params;
  const { query = {} } = location;
  const { sgid } = query;

  return <ListerMetrics paramRoomId={id} router={router} sgid={sgid} />;
};

export default ListerMetricsRoute;
