import { SEEKER_USER_ROLE, LISTER_USER_ROLE } from "datamodel/User/constants";

import BookingRoom from "./booking-room-model";
import BookingUser from "./booking-user-model";
import BookingPrice from "./booking-price-model";

export default class BookingReference {
  constructor(data) {
    this.data = data;
    this.userPrice = data.seeker_pricing || data.lister_pricing;
  }

  get room() {
    return new BookingRoom(this.data.room);
  }

  get id() {
    return this.data.id;
  }

  get connectionId() {
    return this.data.connection_id;
  }

  get lister() {
    return new BookingUser(this.data.lister);
  }

  get seeker() {
    return new BookingUser(this.data.seeker);
  }

  get price() {
    return new BookingPrice(this.userPrice);
  }

  get moveIn() {
    return this.data.move_in_date;
  }

  get moveOut() {
    return this.data.move_out_date;
  }

  get role() {
    const { owned } = this.room;
    return owned ? LISTER_USER_ROLE : SEEKER_USER_ROLE;
  }

  get lengthInMonths() {
    return this.data.length_in_months || 0;
  }

  get exactMonthsLength() {
    return this.data.exact_months_length || false;
  }

  get bookingValue() {
    return this.data.booking_value || "";
  }

  toJSON() {
    return {
      id: this.id,
      connectionId: this.connectionId,
      moveIn: this.moveIn,
      moveOut: this.moveOut,
      room: this.room.toJSON(),
      lister: this.lister.toJSON(),
      seeker: this.seeker.toJSON(),
      price: this.price.toJSON(),
      role: this.role,
      lengthInMonths: this.lengthInMonths,
      exactMonthsLength: this.exactMonthsLength,
      bookingValue: this.bookingValue,
    };
  }
}
