import { PAYOUT_STATUS } from "../static";

const PayoutsModel = {
  country: "",
  contactCountry: "",
  bankName: "",
  bankAccount: "",
  name: "",
  surname: "",
  city: "",
  address: "",
  postalcode: "",
  birthdate: "",
  email: "",
  phone: "",
  missingData: true,
  status: PAYOUT_STATUS.INITIAL,
  bankAccountError: null,
  contactError: null,
  error: null,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Payouts) {
    return { ...PayoutsModel, error: "There was an error in PayoutsModel." };
  }
  return state;
}

export { PayoutsModel, setInitialState };
