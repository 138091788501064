import React from "react";
import { withRouter } from "react-router";
import ChatHeader from "./header";

const ChatHeaderRoute = ({ router, ...props }) => {
  const handleRedirect = (path) => {
    router.push(path);
  };

  return <ChatHeader {...props} onRedirect={handleRedirect} />;
};

export default withRouter(ChatHeaderRoute);
