import React, { Component } from "react";
import { SingleDatePicker } from "react-dates";

import { translate } from "base/shared/Localization";

import "./stylesExt.css";

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarfocused: false,
    };

    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onFocusChange({ focused }) {
    this.setState({ calendarfocused: focused });
  }

  render() {
    const { calendarfocused } = this.state;
    const {
      date,
      placeholder,
      id,
      initialVisibleMonth,
      isDayBlocked,
      isOutsideRange,
      onDateChange,
      numberOfMonths,
      displayFormat,
      disabled,
      onClose,
      withPortal,
    } = this.props;
    let months = numberOfMonths || 2;
    months =
      typeof window !== "undefined" && window.innerWidth <= 800 ? 1 : months;

    return (
      <SingleDatePicker
        date={date}
        disabled={disabled}
        displayFormat={displayFormat || "DD/MM/YYYY"}
        focused={calendarfocused}
        hideKeyboardShortcutsPanel={true}
        id={id || "date_input"}
        initialVisibleMonth={initialVisibleMonth}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        numberOfMonths={months}
        onClose={onClose}
        onDateChange={onDateChange}
        onFocusChange={this.onFocusChange}
        placeholder={translate(placeholder)}
        screenReaderInputMessage=" "
        withPortal={withPortal}
      />
    );
  }
}

DatePicker.defaultProps = {
  withPortal: true,
};

export default DatePicker;
