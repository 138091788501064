import React from "react";
import { Flex, Heading, Text, Spacer } from "@badi/badi-components";
import Image from "components/Image";
import { ButtonsWrapper, MainWrapper } from "./styled-components";

const UnavailableCompactedLayout = ({
  children = null,
  image,
  title,
  description,
}) => {
  return (
    <Spacer top={3}>
      <Flex as={MainWrapper} direction="column">
        <Flex alignItems="center" direction="column">
          <Image height={160} src={image} />
          <Spacer bottom={1} top={4}>
            <Heading data-qa="unavailable-ev-title">{title}</Heading>
          </Spacer>
          <Text data-qa="unavailable-ev-description">{description}</Text>
        </Flex>

        <ButtonsWrapper>
          <Flex direction="column" gutter={2}>
            {children}
          </Flex>
        </ButtonsWrapper>
      </Flex>
    </Spacer>
  );
};

export default UnavailableCompactedLayout;
