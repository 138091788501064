import { locale } from "base/shared/Localization";
import { getRelativePath } from "base/shared/Utils";

export const SEO_ROOM_PATHS = {
  RENT_ROOM: {
    ES: "/anunciantes",
    EN: "/listers",
  },
  LEGACY_RENT_ROOM: {
    ES: "/publicar-anuncio",
    EN: "/list-room-apartment",
  },
};

export const getRentRoomPath = () =>
  getRelativePath(
    locale() === "es"
      ? SEO_ROOM_PATHS.RENT_ROOM.ES
      : SEO_ROOM_PATHS.RENT_ROOM.EN,
  );
