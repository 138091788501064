import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "REQUEST_VISIT_REQUEST",
  "REQUEST_VISIT_ERROR",
  "REQUEST_VISIT_SUCCESS",
  "PROPOSE_VISIT_REQUEST",
  "PROPOSE_VISIT_ERROR",
  "PROPOSE_VISIT_SUCCESS",
  "ACCEPT_VISIT_REQUEST",
  "ACCEPT_VISIT_ERROR",
  "ACCEPT_VISIT_SUCCESS",
  "REJECT_VISIT_REQUEST",
  "REJECT_VISIT_ERROR",
  "REJECT_VISIT_SUCCESS",
  "CANCEL_VISIT_REQUEST",
  "CANCEL_VISIT_ERROR",
  "CANCEL_VISIT_SUCCESS",
]);
