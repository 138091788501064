import React from "react";
import { Flex, Text } from "@badi/badi-components";

const VisitDetailSection = ({ children, dataQa = "", title }) => {
  return (
    <Flex direction="column" gutter={0.5}>
      <Text body="14-Bold" dataQa={dataQa}>
        {title}
      </Text>
      {children}
    </Flex>
  );
};

export default VisitDetailSection;
