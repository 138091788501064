import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const UsersListWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  height: ${unitizedPx(34)};
  text-align: center;
  border-bottom: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  border-top: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  background-image: url("${getCDNUrl()}/assets/images/rentedto-placeholder.svg");
  background-repeat: no-repeat;
  background-position-x: 0;
`;

export const UsersList = styled.ul`
  display: block;
  margin: 0;
  padding: ${unitizedPx(2)} 0 0 0;
  list-style: none;
  text-align: left;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  min-width: 190px;
  min-height: calc(${unitizedPx(34)} - 2px);
  animation: fadein 1s;
`;

export const UserButton = styled.button`
  position: relative;
  display: inline-block;
  margin-left: ${unitizedPx(6)};
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  outline: none;
  background: none;
`;
