import React from "react";
import { Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image/Image";
import { RedoSearchButton as RedoSearchButtonStyled } from "./styled-components";

const RedoSearchButton = ({ onRedoSearchClick }) => (
  <RedoSearchButtonStyled onClick={onRedoSearchClick}>
    <Spacer right={1}>
      <Image src="/assets/icons/redo-search.svg" />
    </Spacer>
    <Text body={6} inline={true}>
      {translate("search.map.redosearch")}
    </Text>
  </RedoSearchButtonStyled>
);

export default RedoSearchButton;
