import React from "react";
import {
  Card,
  TextButton,
  Flex,
  Spacer,
  Text,
  colors,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { openBookingBenefitsModal } from "components/booking-benefits";
import Image from "components/Image";
import { Button, BannerWrapper } from "./styled-components";

const BookingBanner = ({ compact = true }) => {
  const handleClick = () => {
    openBookingBenefitsModal();
  };

  return (
    <BannerWrapper>
      <Card backgroundColor={colors.tertiary} withShadow={false}>
        <Spacer bottom={2} left={2} right={2} top={2}>
          <Flex alignItems="center" gutter={2}>
            <Image src="/assets/images/good-neighbour.svg" />
            <Flex direction="column" grow={1}>
              <Text
                body="14-Bold"
                color={colors.white}
                dataQa="booking-banner-title"
              >
                {translate("search.banner.booking_benefits.title")}
              </Text>
              <Text
                body="12-Regular"
                color={colors.white}
                dataQa="booking-banner-description"
              >
                {translate("search.banner.booking_benefits.description")}
              </Text>
              {compact && (
                <Spacer top={1}>
                  <TextButton
                    color={colors.white}
                    data-qa="booking-banner-button-compact"
                    hoverColor={colors.white}
                    onClick={handleClick}
                    small={true}
                  >
                    {translate("search.banner.booking_benefits.cta")}
                  </TextButton>
                </Spacer>
              )}
            </Flex>
            {!compact && (
              <Button data-qa="booking-banner-button" onClick={handleClick}>
                {translate("search.banner.booking_benefits.cta")}
              </Button>
            )}
          </Flex>
        </Spacer>
      </Card>
    </BannerWrapper>
  );
};

export default BookingBanner;
