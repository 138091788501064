import React from "react";
import {
  Button,
  Flex,
  Text,
  Heading,
  StatusCard,
  Spacer,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const BankAccountVerified = ({ bankAccountLastDigits, onUpdateClick }) => (
  <StatusCard isEditable={true} isVerified={true}>
    <Spacer bottom={3}>
      <Heading level={3}>{translate("payout.bank_account.title")}</Heading>
    </Spacer>
    <Flex direction="column" gutter={1}>
      <Text body={2}>{translate("payout.bank_account.number")}</Text>
      <Text>**** {bankAccountLastDigits}</Text>
      <Flex justify="flexEnd">
        <Button onClick={onUpdateClick}>
          {translate("payout.bank_account.update")}
        </Button>
      </Flex>
    </Flex>
  </StatusCard>
);

export default BankAccountVerified;
