import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { getPriceByType } from "datamodel/Room/utils";
import { getOccupation } from "base/shared/utils/room";

import { convertBoolToAffirmation } from "base/shared/Utils";

import { RoomListModel, VisitPreferencesModel } from "./models";
import { BENEFITS_OPTIONS } from "../Room/constants";
import { VISIT_CATEGORIES, GENDERS_ROOMMATES_PARSER } from "./constants";

export const reverseParseGenders = (gendersValuesObject) => {
  return GENDERS_ROOMMATES_PARSER.reduce((acc, { id, value }) => {
    if (gendersValuesObject[id]) {
      acc.push(value);
    }
    return acc;
  }, []);
};

export const parseFlatmatesToApi = (flatmate) => {
  const { max_age: maxAge, min_age: minAge, occupation, genders } = flatmate;

  return {
    max_age: maxAge,
    min_age: minAge,
    occupation,
    genders,
  };
};

export const parseGenders = (gendersValuesArray) => {
  if (!Array.isArray(gendersValuesArray)) return gendersValuesArray;
  return GENDERS_ROOMMATES_PARSER.reduce((acc, { id, value }) => {
    acc[id] = gendersValuesArray.includes(value);
    return acc;
  }, {});
};

const parsePreferences = (preferences) => {
  const field = typeof preferences.gender === "string" ? "name" : "value";
  return {
    occupation: getOccupation(preferences.occupation, field),
    minAge: preferences.min_age,
    maxAge: preferences.max_age,
    genders: parseGenders(preferences.genders),
  };
};

export const parseBenefits = (benefits) => {
  if (!benefits || !benefits.length) return {};
  return benefits.reduce((acc, key) => {
    switch (key) {
      case BENEFITS_OPTIONS.rentalContract:
        acc.rentalContract = true;
        break;
      case BENEFITS_OPTIONS.registrationAvailable:
        acc.registrationAvailable = true;
        break;
      case BENEFITS_OPTIONS.maintenance:
        acc.maintenance = true;
        break;
      case BENEFITS_OPTIONS.cleaningBiweekly:
      case BENEFITS_OPTIONS.cleaningWeekly:
      case BENEFITS_OPTIONS.cleaningBimonthly:
      case BENEFITS_OPTIONS.cleaningMonthly:
        acc.cleaningServices = key;
        break;
      default:
        break;
    }
    return acc;
  }, {});
};

export const unparseBenefits = (obj) => {
  const benefits = [];
  if (!obj) return benefits;
  if (obj.rentalContract) benefits.push(BENEFITS_OPTIONS.rentalContract);
  if (obj.registrationAvailable)
    benefits.push(BENEFITS_OPTIONS.registrationAvailable);
  if (obj.maintenance) benefits.push(BENEFITS_OPTIONS.maintenance);
  if (obj.cleaningServices) benefits.push(obj.cleaningServices);
  return benefits;
};

export const filterPicture = (pictures) =>
  pictures.map((picture) => ({
    file: { preview: picture.width_500_url },
    pictureId: picture.id,
    tempId: uuidv4(),
    uploadStatus: "success",
  }));

const parseDeposit = (deposit) => {
  if (!deposit) {
    return "";
  }
  return deposit.toString();
};

export const parseNoDeposit = (deposit) => {
  if (deposit === null || deposit === undefined || !!deposit) {
    return false;
  }
  return true;
};

export const setPropertyDraftNull = ({ place_type: placeType }) =>
  placeType ? { property: { ...RoomListModel.property, placeType } } : {};

export const convertNullToZero = (value) => (value === null ? 0 : value);

export const parseAPIRoom = (room, isRoomDraftNull) => {
  if (!room) return {};
  if (isRoomDraftNull) return setPropertyDraftNull(room);

  const {
    facade_side: orientation,
    toilets,
    entire_place_amenities_ids: flatAmenities,
    room_amenities_ids: roomAmenities,
    house_rules_ids: rules,
    lift,
    entire_place_type: propertyType,
    room_type: roomType,
    address,
    address_line_2: addressLine,
    agency_external_id: agencyReferenceId,
    allowed_to_book: bookable,
    allowed_to_edit: editable,
    has_booking_activity: hasBookings,
    available_from: availableFrom,
    available_to: availableTo,
    bed_type: bedType,
    benefits,
    single_bedrooms: singleBedrooms,
    double_bedrooms: doubleBedrooms,
    bathrooms,
    city,
    country,
    description,
    female_tenants: femaleTenants,
    id,
    last_booking_proposal: lastBookingProposal,
    latitude,
    longitude,
    male_tenants: maleTenants,
    non_binary_tenants: nonBinaryTenants,
    min_days: minimumStay,
    max_days: maximumStay,
    owned,
    pictures,
    place_type: placeType,
    postal_code: postalCode,
    preferences,
    settings,
    prices_attributes: priceAttr,
    published_at: publishedAt,
    status,
    street,
    street_number: streetNumber,
    title,
    size: roomSize,
    flat_size: flatSize,
    resident,
  } = room;

  const { price, billsIncluded, currency, deposit, areaPrice, hasAgencyFee } =
    getPriceByType(priceAttr);

  const { minAge, maxAge, occupation, genders } = parsePreferences(
    preferences || RoomListModel.flatmate,
  );

  const { interested_in_verification: photosVerification } = settings || {};

  return {
    id,
    status,
    publishedAt,
    owned,
    bookable,
    editable,
    hasBookings,
    lastBookingProposal,
    location: {
      address,
      addressLine,
      city,
      country,
      latitude,
      longitude,
      postalCode,
      street,
      streetNumber,
      agencyReferenceId,
    },
    property: {
      orientation,
      femaleTenants,
      roomAmenities: roomAmenities || [],
      lift: convertBoolToAffirmation(lift),
      propertyType,
      roomType,
      flatAmenities: flatAmenities || [],
      maleTenants,
      nonBinaryTenants,
      placeType,
      rules: rules || [],
      flatSize: flatSize ? flatSize.toString() : "",
      singleBedrooms: convertNullToZero(singleBedrooms),
      doubleBedrooms: convertNullToZero(doubleBedrooms),
      bathrooms: convertNullToZero(bathrooms),
      toilets: convertNullToZero(toilets),
      bedType,
      roomSize: roomSize ? roomSize.toString() : "",
      resident,
    },
    room: {
      availableFrom: availableFrom ? moment(availableFrom) : null,
      availableTo: availableTo ? moment(availableTo) : null,
      availableToEnabled: !!availableTo,
      bedType,
      benefits,
      singleBedrooms,
      doubleBedrooms,
      billsIncluded,
      currency,
      minimumStay: minimumStay
        ? minimumStay.toString()
        : RoomListModel.room.minimumStay,
      maximumStay: maximumStay
        ? maximumStay.toString()
        : RoomListModel.room.maximumStay,
      price: price ? price.toString() : "",
      noDeposit: parseNoDeposit(deposit),
      deposit: parseDeposit(deposit),
      areaPrice,
      hasAgencyFee,
    },
    photos: {
      description,
      pictures: filterPicture(pictures),
      title,
      photosVerification,
    },
    flatmate: {
      occupation: occupation ? occupation.value : 0,
      min_age: minAge,
      max_age: maxAge,
      genders,
    },
    visitPreferences: new VisitPreferencesModel(room).toJSON(),
  };
};

export const parseVisitPreferencesToApi = ({
  enabled: visitEnabled,
  category,
  startDate,
  availableTimes,
  availableDays,
}) => {
  const days = availableDays.reduce((prev, { day, enabled }) => {
    return { ...prev, [`${day}_enabled`]: enabled };
  }, {});
  const times = availableTimes.reduce((prev, { schedule, enabled }) => {
    return { ...prev, [`${schedule}_enabled`]: enabled };
  }, {});

  return {
    visits_enabled: visitEnabled,
    category: VISIT_CATEGORIES.IN_PLACE,
    visits_start_at: visitEnabled ? startDate : null,
    ...days,
    ...times,
  };
};

export const isModerationSuccessful = (moderationResult) => {
  if (!moderationResult) return null;
  const { manualCheck, autoCheck } = moderationResult;
  if (manualCheck) return true;
  else if (manualCheck === null) {
    if (autoCheck === true) return true;
  }
  return false;
};
