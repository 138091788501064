import React from "react";

import { Button, BUTTON_TYPES } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import BookingFooter from "../footer";

const MoveInOutStepFooter = ({
  stepIsValid,
  nextStep,
  skipMessageStep,
  buttonVariant = BUTTON_TYPES.PRIMARY,
}) => {
  const handleNextClick = () => {
    nextStep();
  };

  return (
    <BookingFooter showProtectedMark={false}>
      <Button
        data-qa="booking-dates-continue"
        disabled={!stepIsValid}
        fullWidth={true}
        grow={1}
        onClick={handleNextClick}
        variant={buttonVariant}
      >
        {translate("booking.new.continue_button")}
      </Button>
    </BookingFooter>
  );
};

export default MoveInOutStepFooter;
