import React, { useMemo, useState } from "react";
import {
  Button,
  TextButton,
  Flex,
  Heading,
  Spacer,
  Text,
} from "@badi/badi-components";
import moment from "moment";
import { isInclusivelyAfterDay } from "react-dates";
import { translate } from "base/shared/Localization";
import DatePicker from "components/Form/DatePicker";

export const DATE_FORMAT = "YYYY-MM-DD";

const PublishSchedule = ({ id, onSubmit, userId = 0 }) => {
  const [date, setDate] = useState(null);
  const startDate = useMemo(() => moment().add(1, "days"), []);

  const handleClick = (e) => {
    e.preventDefault();

    onSubmit({
      id,
      rented_to_user: userId || null,
      rented_to_date: date ? date.format("YYYY-MM-DDTHH:mmZ") : null,
    });
  };

  return (
    <Flex alignItems="center" direction="column">
      <Heading align="center" data-qa="publish-schedule-title" level="16-Bold">
        {translate("Schedule a reminder")}
      </Heading>
      <Spacer bottom={3} top={3}>
        <Text align="center" data-qa="publish-schedule-desc">
          {translate(
            "When will it be available for rent again? We’ll send you a reminder.",
          )}
        </Text>
      </Spacer>
      <DatePicker
        disabled={false}
        displayFormat={DATE_FORMAT}
        initialVisibleMonth={() => startDate}
        isOutsideRange={(day) => !isInclusivelyAfterDay(day, startDate)}
        onChange={setDate}
        placeholder="Select day"
        value={date}
      />
      <Spacer bottom={1} top={3} wide={true}>
        <Button
          dataQa="publish-schedule-button"
          disabled={!date}
          fullWidth={true}
          onClick={handleClick}
        >
          {translate("Schedule")}
        </Button>
      </Spacer>
      <Flex gutter={1}>
        <Text dataQa="publish-schedule-skip-text" inline={true}>
          {translate("Don’t want a reminder?")}
        </Text>
        <TextButton
          data-qa="publish-schedule-skip-button"
          onClick={handleClick}
          small={false}
        >
          {translate("Skip")}
        </TextButton>
      </Flex>
    </Flex>
  );
};

export default PublishSchedule;
