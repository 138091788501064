class TimeSlot {
  constructor(data) {
    this.entity = data;
  }

  get localStartAt() {
    return this.entity.local_start_at;
  }

  get localEndAt() {
    return this.entity.local_end_at;
  }

  get available() {
    return this.entity.available;
  }

  toJSON() {
    return {
      localStartAt: this.localStartAt,
      localEndAt: this.localEndAt,
      available: this.available,
    };
  }
}

export default TimeSlot;
