import React from "react";

import { translate } from "base/shared/Localization";

import Button from "components/Button/Button";
import { Container, Row, Col } from "reactstrap";

import "./ReportExt.css";

const ReportResult = ({ onClick, success, type }) => (
  <div className="Report--result">
    <Container fluid={true}>
      <Row>
        <Col sm={{ size: 10, offset: 1 }}>
          <h1>
            {translate(`report.${success ? "success" : "error"}.${type}.title`)}
          </h1>
          <p>
            {translate(
              `report.${success ? "success" : "error"}.${type}.description`,
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Button
            config={{ color: "green", restrain: true, wide: false }}
            type="button"
            text={translate(
              `report.${success ? "success" : "error"}.${type}.button`,
            )}
            onClick={onClick}
          />
        </Col>
      </Row>
    </Container>
  </div>
);

export default ReportResult;
