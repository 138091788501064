import React from "react";
import {
  BREAKPOINTS_PX,
  Flex,
  Heading,
  Text,
  Spacer,
  useMatchMedia,
} from "@badi/badi-components";
import Image from "components/Image";
import RoomPreview from "components/room-preview";
import { MainWrapper, SideWrapper, Picture } from "./styled-components";

const UnavailableLayout = ({
  children = null,
  userImage,
  roomDetails,
  image,
  title,
  description,
}) => {
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.lg})`]);

  return (
    <Spacer bottom={2}>
      <Flex gutter={3}>
        <Flex
          alignItems="center"
          as={MainWrapper}
          direction="column"
          justify="center"
        >
          <Image height={160} src={image} />
          <Spacer bottom={1} top={4}>
            <Heading data-qa="unavailable-ev-title">{title}</Heading>
          </Spacer>

          <Text data-qa="unavailable-ev-description">{description}</Text>

          <Spacer top={3}>
            <Flex gutter={2}>{children}</Flex>
          </Spacer>
        </Flex>
        {isDesktop && (
          <SideWrapper data-qa="unavailable-ev-sidebar">
            <Image lazy={false} src={userImage} tag={Picture} />
            <Spacer top={3}>
              <RoomPreview {...roomDetails} />
            </Spacer>
          </SideWrapper>
        )}
      </Flex>
    </Spacer>
  );
};

export default UnavailableLayout;
