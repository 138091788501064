import React from "react";

import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { COOKIES } from "base/shared/StaticData";
import { existsCookie, setCookie } from "base/shared/CookiesHandler";

import ViewerTips from "./layout-elements/viewer-tips";

const handleCloseModal = () => {
  modal.remove(MODAL_ID.VIEWERS_MODAL);

  setCookie(COOKIES.VIEWERS_MODAL, "true");
};

export const shouldShowViewersModal = () => {
  return !existsCookie(COOKIES.VIEWERS_MODAL);
};

export const openViewersModal = () => {
  modal.add(<ViewerTips onClick={handleCloseModal} />, {
    closable: true,
    type: MODAL_TYPE.DEFAULT,
    id: MODAL_ID.VIEWERS_MODAL,
  });
};
