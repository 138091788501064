import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import FlatForm from "./flat-form";

export default connect(
  (state) => ({
    flatAmenities: state.SearchFilters.flatAmenities,
    flatBenefits: state.SearchFilters.flatBenefits,
    houseRules: state.SearchFilters.houseRules,
    minFlatSize: state.SearchFilters.minFlatSize,
    maxFlatSize: state.SearchFilters.maxFlatSize,
  }),
  (dispatch) => ({
    setFlatFilter: (
      flatAmenities,
      flatBenefits,
      houseRules,
      minFlatSize,
      maxFlatSize,
    ) =>
      dispatch(
        Actions.setFlat({
          flatAmenities,
          flatBenefits,
          houseRules,
          minFlatSize,
          maxFlatSize,
        }),
      ),
  }),
)(FlatForm);
