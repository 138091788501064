import React from "react";
import { Text, Icon, Flex, Spacer } from "@badi/badi-components";
import { DEFAULT_USER_IMAGE } from "base/shared/StaticData";
import { ListerAvatarWrapper, AvatarBorder, Label } from "./styled-components";
import { getIcon } from "./utils";

const ListerAvatar = ({
  picture,
  label: { content, color, backgroundColor, icon },
  dataQa,
}) => (
  <ListerAvatarWrapper data-qa={dataQa}>
    <AvatarBorder src={picture?.url || DEFAULT_USER_IMAGE} />
    <Label alignItems="center" as={Flex} backgroundColor={backgroundColor}>
      <Icon iconMaxHeight={12} iconMaxWidth={12} src={getIcon(icon)} />
      <Spacer left={0.4} right={0.1}>
        <Text body="10-Medium" color={color}>
          {content}
        </Text>
      </Spacer>
    </Label>
  </ListerAvatarWrapper>
);

export default ListerAvatar;
