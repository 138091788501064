import React from "react";
import { getPublicAsset } from "base/shared/Utils";
import { Text, Flex, Icon } from "@badi/badi-components";
import { SharingOptionWrapper } from "../../styled-components";

const SharingOption = ({
  dataQa = "sharing-option",
  icon,
  title = null,
  disabled,
}) => (
  <SharingOptionWrapper data-qa={dataQa} disabled={disabled}>
    <Flex alignItems="center" direction="row" gutter={1}>
      <Icon iconMaxHeight={24} iconMaxWidth={24} src={getPublicAsset(icon)} />
      {title && (
        <Text body="12-Bold" dataQa={`${dataQa}-title`}>
          {title}
        </Text>
      )}
    </Flex>
  </SharingOptionWrapper>
);

export default SharingOption;
