import React from "react";
import { InView } from "react-intersection-observer";

import { getHtmlProps, getImageUrl } from "./utils";

class Image extends React.PureComponent {
  state = { url: "", inView: false };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { domainUrl, lazy, src } = nextProps;

    const url = getImageUrl(!lazy || prevState.inView, domainUrl, src);

    if (url && url !== prevState.url) return { url };
    return null;
  }

  visibilityHandler = (inView) => {
    const { domainUrl, src } = this.props;
    this.setState({
      url: getImageUrl(!!inView, domainUrl, src),
      inView: !!inView,
    });
  };

  render() {
    const { domainUrl, lazy, src, tag, styles, ...lazyProps } = this.props;
    const htmlProps = getHtmlProps(this.props, this.state.url);

    if (!lazy) {
      const Tag = tag;
      return <Tag {...htmlProps} />;
    }

    return (
      <InView
        as={tag}
        {...htmlProps}
        {...lazyProps}
        onChange={this.visibilityHandler}
      />
    );
  }
}

Image.defaultProps = {
  alt: "",
  className: null,
  domainUrl: "",
  lazy: true,
  rootMargin: "0px 0px 400px 0px",
  styles: {},
  tag: "img",
  triggerOnce: true,
};

export default Image;
