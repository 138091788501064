import { connect } from "react-redux";
import Actions from "datamodel/SearchFilters/actions";
import ReducedFilter from "./reduced-filter";
import { parseFilter } from "../utils";

const mapStateToProps = (state, props) => ({
  ...parseFilter(state.SearchFilters[props.field]),
});

const mapDispatchToProps = (dispatch, props) => ({
  onCancelFilter: () => dispatch(Actions[props.resetAction]()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReducedFilter);
