import { User } from "datamodel/User/models";
import { isVerified } from "base/shared/utils/room";
import { getPicture, getDisplayPrice } from "base/shared/Utils";
import Review from "datamodel/reviews/models/review-model";
import LabelModel from "datamodel/Room/models/label-model";
import { parseIconTypeSection } from "./utils";
import {
  isNotFuture,
  getStayMonths,
  getCanonicalUrl,
  getShortTitle,
  getPriceByType,
} from "../utils";
import { parseBenefits } from "../../RoomList/utils";

const DEFAULT_PREFERENCES = {
  genders: [1, 2, 3],
  occupation: 3,
  min_age: 18,
  max_age: 40,
};

export const DEFAULT_PRICE_DETAILS = {
  billsIncluded: false,
  displayText: "",
  price: 0,
  previousPrice: null,
  deposit: 0,
  currency: "",
  displayTextDeposit: "",
};

class RoomModel {
  constructor(data) {
    this.entity = data || {};
  }

  get address() {
    return this.entity.address || "";
  }

  get abstract() {
    return this.entity.abstract
      ? parseIconTypeSection(this.entity.abstract)
      : null;
  }

  get propertyDetails() {
    return this.entity.property_details || null;
  }

  get flatAmenities() {
    return this.entity.flat_amenities
      ? parseIconTypeSection(this.entity.flat_amenities)
      : null;
  }

  get roomAmenities() {
    return this.entity.room_amenities
      ? parseIconTypeSection(this.entity.room_amenities)
      : null;
  }

  get priceDetails() {
    return this.entity.price_details || null;
  }

  get servicesIncluded() {
    return this.entity.services_included || null;
  }

  get allowToEdit() {
    return this.entity.allowed_to_edit || false;
  }

  get allowToVisit() {
    return this.entity.allowed_to_visit || false;
  }

  get allowToContact() {
    return this.entity.allowed_to_contact || false;
  }

  get amenities() {
    const amenities = this.entity.amenities_attributes || [];
    return amenities.map((a) => a.amenity_type);
  }

  get availableFrom() {
    return this.entity.available_from || null;
  }

  get benefits() {
    return parseBenefits(this.entity.benefits);
  }

  get boosting() {
    return this.entity.boosting || {};
  }

  get isAvailableNow() {
    return isNotFuture(this.entity.available_from);
  }

  get isAvailabilityExpired() {
    return isNotFuture(this.entity.available_to);
  }

  get availableTo() {
    return this.entity.available_to || null;
  }

  get bedType() {
    return this.entity.bed_type || null;
  }

  get singleBedrooms() {
    return this.entity.single_bedrooms || null;
  }

  get doubleBedrooms() {
    return this.entity.double_bedrooms || null;
  }

  get bathrooms() {
    return this.entity.bathrooms || null;
  }

  get city() {
    return this.entity.city_id || this.entity.city || "";
  }

  get country() {
    return this.entity.country || "";
  }

  get currencySymbol() {
    return this.price.currency;
  }

  get description() {
    return this.entity.description || "";
  }

  get displayAddress() {
    return this.entity.display_address || null;
  }

  get femaleTenants() {
    return this.entity.female_tenants || 0;
  }

  get id() {
    return this.entity.id || 0;
  }

  get inboxConnectionId() {
    return this.entity.inbox_connection_id;
  }

  get canonicalUrl() {
    return this.entity.id ? getCanonicalUrl(this.entity.id) : null;
  }

  get matchProfile() {
    return this.entity.match_profile;
  }

  get minStay() {
    return getStayMonths(this.entity.min_days) || null;
  }

  get latitude() {
    return this.entity.latitude || null;
  }

  get locationHref() {
    return this.entity.locationHref || "";
  }

  get longitude() {
    return this.entity.longitude || null;
  }

  get maxStay() {
    return this.entity.max_days ? getStayMonths(this.entity.max_days) : null;
  }

  get maleTenants() {
    return this.entity.male_tenants || 0;
  }

  get nonBinaryTenants() {
    return this.entity.non_binary_tenants || 0;
  }

  get owned() {
    return this.entity.owned || false;
  }

  get picture() {
    return getPicture(this.pictures, "width_500_url", 0, "room");
  }

  get pictures() {
    return this.entity.pictures || [];
  }

  get postalCode() {
    return this.entity.postal_code || "";
  }

  get publishedAt() {
    return this.entity.published_at || null;
  }

  get coverPicture() {
    // for legacy purposes, getPictures only works with arrays
    const coverPictures = this.entity.cover_picture
      ? [this.entity.cover_picture]
      : [];
    return getPicture(coverPictures, "width_100_url", 0, "room");
  }

  get recomended() {
    return this.entity.recomended || false;
  }

  get placeType() {
    return this.entity.place_type || null;
  }

  get activeBookingProposals() {
    return this.entity.active_booking_proposals || 0;
  }

  get activeConnectionsCount() {
    return this.entity.active_connections_count || 0;
  }

  get preferences() {
    return this.entity.preferences || DEFAULT_PREFERENCES;
  }

  get price() {
    const price = getPriceByType(this.entity.prices_attributes || []);
    return price || DEFAULT_PRICE_DETAILS;
  }

  get previousPrice() {
    if (this.entity.monthly_prices?.previous) {
      const previousPrice = this.entity.monthly_prices.previous;
      const currency = this.price.currency;

      return getDisplayPrice(previousPrice, currency);
    }
    return DEFAULT_PRICE_DETAILS.previousPrice;
  }

  get requested() {
    return this.entity.requested || false;
  }

  get shortTitle() {
    return this.entity.title ? getShortTitle(this.entity.title) : "";
  }

  get status() {
    return this.entity.status || null;
  }

  get tenants() {
    return this.entity.tenants || [];
  }

  get street() {
    return this.entity.street || "";
  }

  get title() {
    return this.entity.title || "";
  }

  // TODO the naming should be replaced in order to describe the role as OWNER
  get user() {
    return this.tenants.length ? new User(this.tenants[0]).toJSON() : {};
  }

  get owner() {
    return this.entity.owner || null;
  }

  get flatSize() {
    return this.entity.flat_size;
  }

  get roomSize() {
    return this.entity.size;
  }

  get sizeUnit() {
    return this.entity.size_unit;
  }

  get verified() {
    return isVerified(this.entity.trust);
  }

  get virtualTourLink() {
    return this.entity.virtual_tour_link || null;
  }

  get favorited() {
    return this.entity.favorited || false;
  }

  get editRequired() {
    return this.entity.edit_required || false;
  }

  get reviews() {
    const { reviews } = this.entity;
    return reviews ? reviews.map((review) => new Review(review).toJSON()) : [];
  }

  get reviewsTotal() {
    return this.entity.reviews_total || 0;
  }

  get discarded() {
    return this.entity.discarded || false;
  }

  get descriptionLanguage() {
    return this.entity.description_language || null;
  }

  get labels() {
    return (this.entity.labels || []).map((label) => new LabelModel(label));
  }

  get checkAvailabilityUrl() {
    return this.entity.check_availability_url || null;
  }

  get isFastTrackAllowed() {
    return Boolean(this.entity.fast_track_allowed);
  }

  get standardEnquiriesCount() {
    return this.entity.enquiry_stats?.standard_enquiries;
  }

  get fastTrackEnquiriesCount() {
    return this.entity.enquiry_stats?.fast_track_enquiries;
  }

  toJSON() {
    return {
      abstract: this.abstract,
      address: this.address,
      allowToEdit: this.allowToEdit,
      allowToContact: this.allowToContact,
      amenities: this.amenities,
      availableFrom: this.availableFrom,
      benefits: this.benefits,
      boosting: this.boosting,
      isAvailableNow: this.isAvailableNow,
      isAvailabilityExpired: this.isAvailabilityExpired,
      availableTo: this.availableTo,
      bedType: this.bedType,
      singleBedrooms: this.singleBedrooms,
      doubleBedrooms: this.doubleBedrooms,
      bathrooms: this.bathrooms,
      canonicalUrl: this.canonicalUrl,
      city: this.city,
      country: this.country,
      currencySymbol: this.currencySymbol,
      description: this.description,
      displayAddress: this.displayAddress,
      id: this.id,
      inboxConnectionId: this.inboxConnectionId,
      latitude: this.latitude,
      locationHref: this.locationHref,
      matchProfile: this.matchProfile,
      longitude: this.longitude,
      minStay: this.minStay,
      maxStay: this.maxStay,
      femaleTenants: this.femaleTenants,
      flatSize: this.flatSize,
      maleTenants: this.maleTenants,
      nonBinaryTenants: this.nonBinaryTenants,
      owned: this.owned,
      picture: this.picture,
      pictures: this.pictures,
      postalCode: this.postalCode,
      publishedAt: this.publishedAt,
      recomended: this.recomended,
      placeType: this.placeType,
      activeBookingProposals: this.activeBookingProposals,
      activeConnectionsCount: this.activeConnectionsCount,
      preferences: this.preferences,
      price: this.price,
      previousPrice: this.previousPrice,
      requested: this.requested,
      shortTitle: this.shortTitle,
      status: this.status,
      street: this.street,
      tenants: this.tenants,
      title: this.title,
      user: this.user,
      owner: this.owner,
      roomSize: this.roomSize,
      sizeUnit: this.sizeUnit,
      verified: this.verified,
      virtualTourLink: this.virtualTourLink,
      promo: false,
      favorited: this.favorited,
      editRequired: this.editRequired,
      reviews: this.reviews,
      reviewsTotal: this.reviewsTotal,
      allowToVisit: this.allowToVisit,
      discarded: this.discarded,
      descriptionLanguage: this.descriptionLanguage,
      propertyDetails: this.propertyDetails,
      flatAmenities: this.flatAmenities,
      roomAmenities: this.roomAmenities,
      priceDetails: this.priceDetails,
      servicesIncluded: this.servicesIncluded,
      checkAvailabilityUrl: this.checkAvailabilityUrl,
      labels: this.labels.map((label) => label.toJSON()),
      isFastTrackAllowed: this.isFastTrackAllowed,
      fastTrackEnquiriesCount: this.fastTrackEnquiriesCount,
      standardEnquiriesCount: this.standardEnquiriesCount,
    };
  }
}

export default RoomModel;
