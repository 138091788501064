import React, { useState } from "react";
import {
  StatusCard,
  Button,
  Flex,
  Spacer,
  Heading,
  Text,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import TextInput from "components/form-text-input";

const alphanumericValidation = (value) => {
  const regex = /^([a-zA-Z0-9]+)$/;
  return regex.test(value);
};

const INPUT_CODE_NAME = "code";

const CodeVerification = ({ toggleStep, onSubmit, disabled, dataQa = "" }) => {
  const [canSubmit, setValidation] = useState(false);
  const [codeValue, setCodeValue] = useState("");

  const handleInputChange = ({ value }) => {
    setCodeValue(value);
  };

  const handleInputValidation = ({ isValid }) => {
    setValidation(isValid);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ [INPUT_CODE_NAME]: codeValue });
  };

  return (
    <StatusCard dataQa={dataQa}>
      <form onSubmit={handleSubmit}>
        <Heading level={3}>{translate("verifications.email.title")}</Heading>
        <Spacer bottom={1} top={3}>
          <Text body={2}>{translate("verifications.email.code.label")}</Text>
        </Spacer>
        <Spacer bottom={2}>
          <TextInput
            name={INPUT_CODE_NAME}
            onChange={handleInputChange}
            onInputValidation={handleInputValidation}
            placeholder={translate(
              "verifications.email.code.input_placeholder",
            )}
            required={true}
            type="text"
            validations={[alphanumericValidation, ["maxLength", 6]]}
          />
        </Spacer>
        <Flex gutter={2} justify="flexEnd">
          <Button
            disabled={disabled}
            onClick={toggleStep}
            type="button"
            variant="secondary"
          >
            {translate("verifications.email.code.cancel_button")}
          </Button>
          <Button
            disabled={!canSubmit || disabled}
            type="submit"
            variant="primary"
          >
            {translate("verifications.email.code.submit_button")}
          </Button>
        </Flex>
      </form>
    </StatusCard>
  );
};

CodeVerification.defaultProps = {
  disabled: false,
};

export default CodeVerification;
