import React from "react";
import { Link } from "react-router";
import {
  BUTTON_TYPES,
  Flex,
  Icon,
  Text,
  BADI_COLORS,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getInboxPath } from "Sections/inbox/routes";
import { getPublicAsset } from "base/shared/Utils";
import UserMenu from "components/Layout/TopBar/components/user-menu";
import { getAboutBadiPath, getBadiPlusPath } from "../../../routes";
import Logo from "../../Logo";
import {
  LoginButton,
  MenuLink,
  MenuButton,
  UserMenuWrapper,
} from "./styled-components";

const InformationLink = () => (
  <MenuLink as={Link} data-qa="about-badi-button" to={getAboutBadiPath()}>
    <Text body="14-Medium" color={BADI_COLORS.NEUTRAL.NEUTRAL_00}>
      {translate("topbar.element.info")}
    </Text>
  </MenuLink>
);

const utmParams = {
  utm_source: "marketplace",
  utm_medium: "web",
  utm_campaign: "navbar",
};
const badiPlusUrlWithUtmParams = getBadiPlusPath("/", { query: utmParams });

const BadiPlusLink = () => (
  <MenuLink data-qa="about-badi-button" href={badiPlusUrlWithUtmParams}>
    <Text body="14-Medium" color={BADI_COLORS.NEUTRAL.NEUTRAL_00}>
      Badi Plus
    </Text>
  </MenuLink>
);

const DesktopBar = ({
  isLister,
  isLoggedIn,
  logout,
  onListYourProperty,
  openLogin,
  openMyProfile,
  picture,
  switchUser,
}) => {
  const handleListRoomClick = () => {
    onListYourProperty(isLister);
  };

  const switchToLister = () => switchUser("lister");
  const isBadiPlusCollectionToggleActive = true;

  return (
    <Flex alignItems="center" justify="spaceBetween">
      <Logo color={BADI_COLORS.NEUTRAL.NEUTRAL_00} dataQa="topbar-logo" />
      <Flex alignItems="center" gutter={1.5}>
        <MenuButton
          data-qa="topbar-seeker-menu-list-rent"
          onClick={handleListRoomClick}
          variant={BUTTON_TYPES.SECONDARY}
        >
          <Text body="12-Medium" color={BADI_COLORS.NEUTRAL.NEUTRAL_00}>
            {translate("topbar.element.listproperty.title")}
          </Text>
        </MenuButton>
        {isBadiPlusCollectionToggleActive ? (
          <BadiPlusLink />
        ) : (
          <InformationLink />
        )}
        {isLoggedIn ? (
          [
            <MenuLink
              as={Link}
              data-qa="topbar-seeker-menu-inbox"
              key="inbox"
              to={getInboxPath()}
            >
              <Text body="14-Medium" color={BADI_COLORS.NEUTRAL.NEUTRAL_00}>
                {translate("topbar.element.inbox")}
              </Text>
            </MenuLink>,
            <UserMenuWrapper key="menu">
              <UserMenu
                data-qa="topbar-seeker-menu"
                logout={logout}
                mode="seeker"
                picture={picture}
                switchMode={isLister ? switchToLister : null}
              />
            </UserMenuWrapper>,
          ]
        ) : (
          <LoginButton
            data-qa="topbar-public-menu-login"
            onClick={isLoggedIn ? openMyProfile : openLogin}
          >
            <Icon
              alt="login"
              iconMaxHeight={40}
              iconMaxWidth={40}
              src={getPublicAsset("icons/home/login.svg")}
            />
          </LoginButton>
        )}
      </Flex>
    </Flex>
  );
};

export default DesktopBar;
