import React from "react";

import { Button, BUTTON_TYPES } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import BookingFooter from "../footer";

const PaymentStepFooter = ({
  stepIsValid,
  previousStep,
  nextStep,
  disabled,
  buttonVariant = BUTTON_TYPES.PRIMARY,
}) => {
  const handleNextClick = () => {
    nextStep();
  };

  return (
    <BookingFooter>
      <Button
        data-qa="previous-step"
        fullWidth={true}
        grow={1}
        onClick={previousStep}
        variant="secondary"
      >
        {translate("booking.new.back_button")}
      </Button>
      <Button
        data-qa="next-step"
        disabled={!stepIsValid || disabled}
        fullWidth={true}
        grow={1}
        onClick={handleNextClick}
        variant={buttonVariant}
      >
        {translate("booking.onboarding.actions.next")}
      </Button>
    </BookingFooter>
  );
};

export default PaymentStepFooter;
