import moment from "moment";
import { getRecentSearches } from "components/geo-suggest/utils";

import { DATE_FORMAT } from "./constants";

export const getSearchDefaultValues = (stayOptions) => {
  const stayOption = stayOptions.map((elem) => ({
    ...elem,
    selected: Number(1) === elem.id,
  }));

  const moveIn = moment().add("3", "days").format(DATE_FORMAT);

  return {
    moveInDate: moveIn,
    lengthStay: "1",
    stayOption,
  };
};

export const getLastSearchLocation = () => {
  const lastSavedSearch = getRecentSearches()[0];
  return lastSavedSearch || null;
};
