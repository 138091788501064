import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { Spacer, Heading, Text } from "@badi/badi-components";
import BadiGuaranteeCard from "components/badi-guarantee-card";
import StepsDescriptionCard from "components/steps-description-card";
import { DEFAULT_EXPIRATION_HOURS } from "datamodel/booking/constants";
import { isSeeker } from "datamodel/User/utils";
import BookingSidebar from "../sidebar";

const BookingStepLayout = ({
  description = "",
  title,
  titleDescription = "",
  subtitle = "",
  children,
  sidebar = null,
  sidebarTitle = null,
  userRole,
  expirationHours = DEFAULT_EXPIRATION_HOURS,
  hideBadiGuaranteeCard = false,
}) => {
  const bookingBenefitsHidden = isSeeker(userRole);

  return (
    <Spacer bottom={12} sm={{ bottom: 6 }}>
      <Row>
        <Col lg={7} sm={12}>
          <Spacer bottom={1} top={1}>
            <Heading data-qa="step-heading">{title}</Heading>
          </Spacer>
          {titleDescription && (
            <Spacer bottom={4}>
              <Text body={1} data-qa="step-title-description">
                {titleDescription}
              </Text>
            </Spacer>
          )}
          {subtitle && (
            <Spacer bottom={2}>
              <Text body={1} data-qa="step-subtitle">
                {subtitle}
              </Text>
            </Spacer>
          )}
          {description && (
            <Spacer bottom={2}>
              <Text body={1} data-qa="step-description">
                {description}
              </Text>
            </Spacer>
          )}
          <Spacer bottom={6}>{children}</Spacer>
        </Col>
        {sidebar && (
          <BookingSidebar title={sidebarTitle}>
            <>
              {sidebar}
              {!!userRole && !hideBadiGuaranteeCard && (
                <Spacer top={2}>
                  <BadiGuaranteeCard
                    alignText={false}
                    textDirection="column"
                    userRole={userRole}
                  />
                  {!bookingBenefitsHidden && (
                    <StepsDescriptionCard
                      expirationHours={expirationHours}
                      userRole={userRole}
                    />
                  )}
                </Spacer>
              )}
            </>
          </BookingSidebar>
        )}
      </Row>
    </Spacer>
  );
};

export default BookingStepLayout;
