import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { parseSeekerPreferencesResponse, parseParamsToApi } from "./utils";

export const saveSeekerPreference = (token, paramsToParse) => {
  const { BADI_SEEKER_PREFERENCES } = envVars();
  const serverUrl = getServerUrl(BADI_SEEKER_PREFERENCES);
  const params = parseParamsToApi(paramsToParse);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "PUT",
    token,
  }).then(({ data }) => parseSeekerPreferencesResponse(data));
};

export const getSeekerPreference = (token) => {
  const { BADI_SEEKER_PREFERENCES } = envVars();
  const serverUrl = getServerUrl(BADI_SEEKER_PREFERENCES);
  return fetch(serverUrl, {
    token,
  }).then(({ data }) => parseSeekerPreferencesResponse(data));
};
