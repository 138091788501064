import React from "react";
import { Text, Flex } from "@badi/badi-components";
import UserProfileBadge from "components/user-profile-badge";
import { ReviewWrapper, TextWrapper } from "./styled-components";

const ReviewItem = ({ reviewer, text, collectedAt, withDivider = false }) => {
  const { id, name, picture } = reviewer;

  return (
    <ReviewWrapper withDivider={withDivider}>
      <Flex direction="column" gutter={1}>
        <UserProfileBadge
          avatar={picture}
          avatarSize={40}
          clickable={!!id}
          data-qa="review-user-profile-badge"
          id={id}
          name={name}
          subHeading={collectedAt}
        />
        <TextWrapper>
          <Text dataQa="review-text">{text}</Text>
        </TextWrapper>
      </Flex>
    </ReviewWrapper>
  );
};

export default ReviewItem;
