export const SEARCHROOMS_STATUSES = {
  initial: 0,
  fetching: 1,
  loaded: 2,
  error: 3,
  nomore: 4,
};

export const SEARCHROOMS_ROOMS_PER_PAGE = {
  default: 20,
  markers: 200,
};

export const CARD_TYPES = {
  ROOM_CARD: "room_card",
};

export const BANNER_TYPES = ["static_banner_booking"];
