import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const getAnimations = (
  topBarFrameRef,
  menuRef,
  isDesktop,
  additionalHeight = 0,
) => {
  const tempAnimations = {};
  const startTrigger = "0px top";
  gsap.registerPlugin(ScrollTrigger);
  tempAnimations.mainFrame = gsap.to(topBarFrameRef.current, {
    ease: "none",
    duration: 0.2,
    height: isDesktop ? unitizedPx(10) : unitizedPx(9 + additionalHeight),
    paddingTop: "15px",
    paddingBottom: "15px",
    scrollTrigger: {
      trigger: topBarFrameRef.current,
      start: startTrigger,
      toggleActions: "play none none reverse",
    },
  });

  tempAnimations.mainMenu = gsap.to(menuRef.current, {
    ease: "none",
    duration: 0.2,
    scrollTrigger: {
      trigger: topBarFrameRef.current,
      start: startTrigger,
    },
  });

  tempAnimations.background = gsap.to(topBarFrameRef.current, {
    ease: "none",
    duration: 0.2,
    backgroundColor: BADI_COLORS.NEUTRAL.NEUTRAL_80,
    scrollTrigger: {
      trigger: topBarFrameRef.current,
      start: startTrigger,
      toggleActions: "play none none reverse",
    },
  });

  return tempAnimations;
};
