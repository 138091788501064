const TransactionsModel = {
  fetched: 0,
  fetching: false,
  error: null,
  transactions: [],
  pagination: {},
  perPage: 5,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Transactions) {
    return {
      ...TransactionsModel,
      error: "There was an error in TransactionsModel",
    };
  }
  return state;
}

export { TransactionsModel, setInitialState };
