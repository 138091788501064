import React from "react";
import { Flex } from "@badi/badi-components";
import Loader from "components/loader";
import { LoadingMapWrapper, LoadingAssetWrapper } from "./styled-components";

const LoadingMap = () => (
  <LoadingMapWrapper>
    <Flex
      alignItems="center"
      direction="column"
      fullHeight={true}
      justify="center"
    >
      <LoadingAssetWrapper>
        <Flex
          alignItems="center"
          direction="column"
          fullHeight={true}
          justify="center"
        >
          <Loader />
        </Flex>
      </LoadingAssetWrapper>
    </Flex>
  </LoadingMapWrapper>
);

export default LoadingMap;
