import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Flex, Heading, Spacer, Text } from "@badi/badi-components";
import { Link } from "react-router";

import { translate } from "base/shared/Localization";
import { getRelativePath } from "base/shared/Utils";
import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";

import ListerScoreCard from "./components/lister-score-card";
import ListerScoreTopBar from "./components/top-bar";
import { Back, Caret } from "./styled-components";

const ListerScore = () => (
  <LayoutWrapper
    footer={<Footer campaign="listerscore_footer" />}
    topBar={<ListerScoreTopBar />}
  >
    <Spacer bottom={20} md={{ top: 9 }} top={4}>
      <Container>
        <Row>
          <Col>
            <Back as={Link} to={getRelativePath("/overview")}>
              <Caret />
              <Text body={4}>{translate("Back")}</Text>
            </Back>
          </Col>
        </Row>
        <Spacer bottom={8} top={3}>
          <Row>
            <Col lg={6}>
              <Flex direction="column" gutter={3}>
                <Heading data-qa="hero" hero={true}>
                  {translate("lister_score_component_title")}
                </Heading>
                <Text data-qa="description">
                  {translate("lister_score_component_text")}
                </Text>
              </Flex>
            </Col>
          </Row>
        </Spacer>
        <Row>
          <Col lg={6}>
            <Heading level={1}>
              {translate("lister_score_component_high_action")}
            </Heading>
          </Col>
        </Row>
        <Spacer bottom={4} top={4}>
          <Row>
            <ListerScoreCard id={1} />
            <ListerScoreCard id={2} />
          </Row>
        </Spacer>
        <Spacer bottom={4}>
          <Row>
            <ListerScoreCard id={3} />
            <ListerScoreCard id={4} />
          </Row>
        </Spacer>
        <Row>
          <ListerScoreCard id={5} />
          <ListerScoreCard id={6} />
        </Row>
      </Container>
    </Spacer>
  </LayoutWrapper>
);

export default ListerScore;
