import React from "react";

import SuggestItem from "../suggest-item";

const RecentSuggestItem = ({ onSelect, suggest }) => {
  const { label, type } = suggest;

  const clickHandler = () => {
    onSelect(suggest);
  };

  return (
    <li>
      <SuggestItem description={label} onClick={clickHandler} type={type} />
    </li>
  );
};

export default RecentSuggestItem;
