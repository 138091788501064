import {
  AMENITIES,
  GENDERS_ROOMMATES_CHECKBOXES,
  OCCUPATIONS_ROOMMATE,
} from "base/shared/StaticData";

import { getPictureBySize } from "base/shared/Utils";
import dayjs from "dayjs";

const MSECONDS_PER_MONTH = 1000 * 3600 * 24 * 30;

export const getAmenities = (type = "amenities") => {
  const houseRulesIds = [5, 6, 13];

  if (type === "rules")
    return AMENITIES.filter((amenity) => houseRulesIds.includes(amenity.value));
  return AMENITIES.filter((amenity) => !houseRulesIds.includes(amenity.value));
};

export const getGender = (gendersId) => {
  if (!Array.isArray(gendersId) || !gendersId.length)
    return "room.detail.fields.genders.female.male.nonBinary";
  let output = "room.detail.fields.genders";
  const selectedGenders = Object.values(GENDERS_ROOMMATES_CHECKBOXES)
    .filter(({ value }) => gendersId.includes(value))
    .sort();
  selectedGenders.forEach(({ name }) => {
    output += `.${name}`;
  });
  return output;
};

export const getOccupation = (occupationId, field = "value") =>
  OCCUPATIONS_ROOMMATE.find((o) => o[field] === occupationId);

export const getRoomPictureBySize = getPictureBySize("room");

export const isVerified = (trust) => {
  if (!trust) return false;

  return !!trust.verified;
};

export const getMonthsFromDates = (dateIn, dateOut) => {
  if (!dateIn || !dateOut) return null;
  const dateInObj = new Date(dateIn);
  const dateOutObj = new Date(dateOut);
  if (isNaN(dateInObj.getTime()) || isNaN(dateOutObj.getTime())) return null;
  const differenceInMS = Math.abs(dateOutObj.getTime() - dateInObj.getTime());
  const differenceInMonths = differenceInMS / MSECONDS_PER_MONTH;
  return Math.max(Math.round(differenceInMonths), 1);
};

export const getDateFromMonths = (dateIn, months) => {
  if (!dateIn || !months) return null;
  const dateInObj = new Date(dateIn);
  if (isNaN(dateInObj.getTime()) || isNaN(months)) return null;
  const monthsInMS = months * MSECONDS_PER_MONTH;
  const dateOutInMS = dateInObj.getTime() + monthsInMS;
  const dateOutObj = dayjs(new Date(dateOutInMS));
  return dateOutObj.format("YYYY-MM-DD");
};
