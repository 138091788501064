import React from "react";
import { Button, Flex, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const ContactInformationEmpty = ({ handleAddInfo }) => (
  <Flex direction="column" gutter={1}>
    <Text body={1} dataQa="contact-information-add-info-field">
      {translate("payout.setup.account_holder.subtitle")}
    </Text>
    <Flex justify="flexEnd">
      <Button dataQa="contact-information-add-button" onClick={handleAddInfo}>
        {translate("payout.setup.account_holder.placeholder")}
      </Button>
    </Flex>
  </Flex>
);

export default ContactInformationEmpty;
