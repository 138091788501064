import { SEEKER_USER_ROLE, LISTER_USER_ROLE } from "datamodel/User/constants";

import BookingRoom from "./booking-room-model";
import BookingUser from "./booking-user-model";
import BookingPrice from "./booking-price-model";

export default class BookingDetails {
  constructor(data) {
    this.data = data;
    this.userPrice = data.seeker_pricing || data.lister_pricing;
  }

  get room() {
    return new BookingRoom(this.data.room);
  }

  get lister() {
    return new BookingUser(this.data.lister);
  }

  get seeker() {
    return new BookingUser(this.data.seeker);
  }

  get price() {
    return new BookingPrice(this.userPrice);
  }

  get moveIn() {
    return this.data.move_in_date;
  }

  get moveOut() {
    return this.data.move_out_date;
  }

  get userRole() {
    const { owned } = this.room;
    return owned ? LISTER_USER_ROLE : SEEKER_USER_ROLE;
  }

  get message() {
    return this.data.message;
  }

  get status() {
    return this.data.status;
  }

  get expirationText() {
    return this.data.expires_in_words;
  }

  get expirationHours() {
    return this.data.expires_in_hours;
  }

  get lengthInMonths() {
    return this.data.length_in_months || 0;
  }

  get exactMonthsLength() {
    return this.data.exact_months_length || false;
  }

  get bookingValue() {
    return this.data.booking_value || "";
  }

  get hasMoreBookings() {
    return this.data.seeker_booking_confirmed || false;
  }

  get useCheckout() {
    return this.data.use_checkout || false;
  }

  toJSON() {
    return {
      expirationText: this.expirationText,
      expirationHours: this.expirationHours,
      moveIn: this.moveIn,
      moveOut: this.moveOut,
      room: this.room.toJSON(),
      lister: this.lister.toJSON(),
      seeker: this.seeker.toJSON(),
      price: this.price.toJSON(),
      userRole: this.userRole,
      message: this.message,
      status: this.status,
      lengthInMonths: this.lengthInMonths,
      exactMonthsLength: this.exactMonthsLength,
      bookingValue: this.bookingValue,
      hasMoreBookings: this.hasMoreBookings,
      useCheckout: this.useCheckout,
    };
  }
}
