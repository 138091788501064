import styled from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";

export const RecentSuggestListTitle = styled.li`
  max-height: 32px;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.67;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
`;
