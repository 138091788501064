import Plans from "./plans-model";
import Capabilities from "./capabilities-model";

const { plans } = new Plans().toJSON();
const capabilities = new Capabilities().toJSON();

const PremiumModel = {
  plans,
  ...capabilities,
  rooms: [],
  origin: null,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Premium) {
    return {
      ...PremiumModel,
      error: "There was an error in PremiumModel",
    };
  }
  return state;
}

export { PremiumModel, setInitialState };
