import React from "react";
import { BUTTON_TYPES, Button } from "@badi/badi-components";
import PurchaseButtonModule from "modules/stripe/purchase";
import { PLAN_TYPES } from "datamodel/premium/constants";
import { ButtonWrapper } from "./styled-components";

const PlanButton = ({
  category,
  dataQa = null,
  planId = null,
  roomId = 0,
  openRoomSelector,
  onClick = () => {},
  origin = null,
  productId,
  productReference = null,
  initiator,
  children,
  type = null,
  upsellCategory = null,
  variant = BUTTON_TYPES.PREMIUM_SECONDARY,
}) => {
  const handleClick = () => {
    if (upsellCategory) {
      onClick({ planId, upsellCategory });
      return;
    }

    onClick({ planId, category });
    openRoomSelector(planId);
  };

  if (upsellCategory) {
    return (
      <Button
        as={ButtonWrapper}
        dataQa={dataQa || "plan-upsell-button"}
        onClick={handleClick}
        variant={variant}
      >
        {children}
      </Button>
    );
  }

  if (productReference && (!!roomId || type === PLAN_TYPES.SUBSCRIPTION)) {
    return (
      <PurchaseButtonModule
        as={ButtonWrapper}
        cancelUrl={initiator}
        category={category}
        dataQa={dataQa || "plan-checkout-payment-button"}
        initiator={initiator}
        onClick={onClick}
        origin={origin}
        planId={planId}
        productId={productId}
        purchasableId={productReference}
        roomId={roomId}
        type={type}
        variant={variant}
      >
        {children}
      </PurchaseButtonModule>
    );
  }

  return (
    <Button
      as={ButtonWrapper}
      dataQa={dataQa || "plan-room-selector-button"}
      disabled={!roomId && !productReference}
      onClick={handleClick}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default PlanButton;
