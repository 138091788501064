import { createSelector } from "reselect";

export const getFetchedListingsStatuses = (state) =>
  state.Overview.listingsStatuses.fetchedListingsStatuses;

export const getFetchedInitialOverviewCardsStatus = (state) =>
  state.Overview.roomsOverview.fetchedInitialOverviewCards;

const getOverview = (state) => state.Overview;
const getUser = (state) => state.User;

export const getFetchedSummary = createSelector(
  getOverview,
  (overview) => overview.fetchedSummary,
);

export const getOverviewSections = createSelector(
  getOverview,
  (overview) => overview.sections,
);

export const getOverviewheading = createSelector(
  getOverview,
  (overview) => overview.heading,
);

export const getUserListerScore = createSelector(
  getUser,
  (user) => user.listerScore,
);

export const getUserPicture = createSelector(
  getUser,
  (user) => user.mainPicture,
);
