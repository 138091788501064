import { BADI_COLORS } from "@badi/badi-components";

export const getHintColor = ({
  matchingModelEnabled,
  payToMatchRequired,
  premiumPlan,
} = {}) => {
  if (!matchingModelEnabled) {
    return BADI_COLORS.NEUTRAL.NEUTRAL_80;
  }
  if (!payToMatchRequired && !premiumPlan) {
    return BADI_COLORS.BRAND.PRIMARY.DEFAULT;
  }
  if (!payToMatchRequired && premiumPlan) {
    return BADI_COLORS.BRAND.SECONDARY.DEFAULT;
  }
  if (payToMatchRequired && !premiumPlan) {
    return BADI_COLORS.ADDITIONAL.ADDITIONAL_06;
  }
  return BADI_COLORS.NEUTRAL.NEUTRAL_80;
};
