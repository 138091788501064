import React from "react";

import { Female, Male, NoBinary } from "components/Icons/Gender";
import { Both, Study, Work } from "components/Icons/Occupation";
import {
  AirConditioning,
  Tv,
  Wifi,
  Parking,
  Elevator,
  Heating,
  WashingMachine,
  Dryer,
  Doorman,
  FurnishedRoom,
  Pool,
  PrivateBathroom,
  Balcony,
  NaturalLight,
  Dishwasher,
  WheelchairFriendly,
  Garden,
  Smoker,
  Pet,
  Couples,
  Terrace,
  WorkingSpace,
} from "components/Icons/Amenities";

const icons = {
  AirConditioning,
  Tv,
  Wifi,
  Parking,
  Elevator,
  Heating,
  WashingMachine,
  Dryer,
  Doorman,
  FurnishedRoom,
  Pool,
  PrivateBathroom,
  Balcony,
  NaturalLight,
  Dishwasher,
  WheelchairFriendly,
  Garden,
  Smoker,
  Pet,
  Couples,
  Female,
  Male,
  NoBinary,
  Both,
  Study,
  Work,
  Terrace,
  WorkingSpace,
};

class ChoiceItem extends React.PureComponent {
  render() {
    const { checked, item, name, onChange, dataQa } = this.props;
    const IconComponent = icons[item.icon];
    const { title, value } = item;
    const styles = { color: checked ? "#1e1e1e" : "#bbb" };

    return (
      <div className="Form__ChoiceItem--wrapper">
        <label htmlFor={`${name}-${value}`}>
          <IconComponent checked={checked} color={styles.color} />

          <input
            checked={checked}
            data-qa={`${dataQa}-${value}`}
            id={`${name}-${value}`}
            name={name}
            onChange={onChange}
            type="checkbox"
            value={value}
          />

          <span style={styles}>{title}</span>
        </label>
      </div>
    );
  }
}

export default ChoiceItem;
