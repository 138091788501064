import { languages } from "base/shared/Localization";
import { getLocalePath } from "base/shared/Utils";

export const LANGUAGE_OPTIONS = languages.map(({ locale, title }) => ({
  label: title,
  value: locale,
}));

export const getLocalizedPath = ({ path, language }) => {
  const currentLocalePath = getLocalePath();
  const localePath = getLocalePath(language);

  const localizedPath = path.startsWith(currentLocalePath)
    ? path.replace(currentLocalePath, localePath) || "/"
    : localePath.concat(path);

  return localizedPath;
};
