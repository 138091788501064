import React, { Fragment } from "react";
import { Link } from "react-router";
import {
  Heading,
  Avatar,
  Text,
  Spacer,
  Flex,
  useMatchMedia,
  colors,
} from "@badi/badi-components";
import { getRelativePath } from "base/shared/Utils";
import ListerScoreBadge from "components/lister-score-badge";
import { translate } from "base/shared/Localization";
import { ScoreLink } from "../styled-components";
import { QUERIES, TRANSACTIONS_COMPONENT } from "../constants";
import TransactionsHeading from "../components/transactions-heading";

const SectionHeader = ({
  mainSectionHeading = "",
  heading = "",
  subheading,
  listerScore = "",
  userPicture = "",
  components = [],
  transactions = null,
}) => {
  if (mainSectionHeading) {
    const [isDesktop] = useMatchMedia(QUERIES);
    const textColor = isDesktop ? colors.darkGrey : colors.white;

    return (
      <Fragment>
        <Spacer bottom={1} top={isDesktop ? 0 : 4}>
          <Flex direction={isDesktop ? "column" : "row"}>
            {!isDesktop && (
              <Spacer right={2}>
                <Avatar
                  alt={mainSectionHeading}
                  badge={""}
                  size={64}
                  src={userPicture}
                />
              </Spacer>
            )}
            <Flex
              className="test"
              direction={isDesktop ? "row" : "column"}
              fullHeight={!isDesktop}
              justify="spaceBetween"
            >
              <Heading textColor={textColor}>{mainSectionHeading}</Heading>
              {listerScore && (
                <ScoreLink as={Link} to={getRelativePath("/lister-score")}>
                  <Flex
                    alignItems="flexEnd"
                    data-qa="overview-lister-score"
                    gutter={1}
                  >
                    <Text body={2} color={textColor}>
                      {translate("overview.summary.subtitle")}
                    </Text>
                    <ListerScoreBadge score={listerScore} small={false} />
                  </Flex>
                </ScoreLink>
              )}
            </Flex>
          </Flex>
        </Spacer>
        <Text color={textColor}>{subheading}</Text>
        {transactions && components.includes(TRANSACTIONS_COMPONENT) && (
          <Spacer top={3}>
            <TransactionsHeading transactions={transactions} />
          </Spacer>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Spacer bottom={1}>
        <Heading level={3}>{heading}</Heading>
      </Spacer>
      <Text>{subheading}</Text>
    </Fragment>
  );
};

export default SectionHeader;
