import React from "react";
import {
  Button,
  BUTTON_TYPES,
  BUTTON_SIZES,
  Text,
  Heading,
  Flex,
} from "@badi/badi-components";

import { modal } from "containers/ReduxModal/emitter";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";

const DISCARD_MODAL_ID = "discard-booking-modal";

export const openDiscardModal = ({
  fromConnection,
  token,
  userName,
  userAvatar,
  onModalClose,
  onDiscard,
}) => {
  modal.add(
    <DiscardBookingModal
      fromConnection={Number(fromConnection)}
      onDiscard={onDiscard}
      onModalClose={onModalClose}
      token={token}
      userName={userName}
    />,
    {
      id: DISCARD_MODAL_ID,
      type: userAvatar ? "withAvatar" : null,
      avatar: userAvatar,
    },
  );
};

export const closeDiscardModal = () => {
  modal.remove(DISCARD_MODAL_ID);
};

const DiscardBookingModal = ({
  userName,
  onModalClose,
  fromConnection,
  onDiscard,
}) => {
  const handleDiscardAndMessage = () => {
    onDiscard()
      .then(() => {
        const returnUrl = fromConnection
          ? `/conversation/${fromConnection}`
          : "/inbox";

        onModalClose({ nextRoute: returnUrl });
      })
      .catch((error) => {
        UserFeedback.exception(
          "booking.reply.notifications.decline_error_title",
          error.message,
          error,
        );
      });
  };

  return (
    <Flex direction="column" gutter={2}>
      <Heading align="center" body={4} fullWidth={true}>
        {translate("user_decline.booking.title")}
      </Heading>
      <Text align="center" body={3}>
        {translate("user_decline.booking.desc", { user_name: userName })}
      </Text>
      <Button
        data-qa="booking-request-close-modal-button"
        fullWidth={true}
        onClick={onModalClose}
        size={BUTTON_SIZES.SMALL}
        variant={BUTTON_TYPES.SECONDARY}
      >
        {translate("user_decline.booking.secondary.cta")}
      </Button>
      <Button
        data-qa="booking-request-discard-button"
        fullWidth={true}
        onClick={handleDiscardAndMessage}
        size={BUTTON_SIZES.SMALL}
        variant={BUTTON_TYPES.PRIMARY}
      >
        {translate("user_decline.booking.primary.cta")}
      </Button>
    </Flex>
  );
};

DiscardBookingModal.defaultProps = {
  fromConnection: 0,
  userName: "",
};

export default DiscardBookingModal;
