import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "AUTH_REQUEST",
  "AUTH_SUCCESS",
  "AUTH_ERROR",
  "AUTH_IS_REMEMBER_ME_CHECKED",
  "REFRESH_REQUEST",
  "REFRESH_SUCCESS",
  "REFRESH_ERROR",
]);
