import React from "react";
import { Link, withRouter } from "react-router";

import BadiLogo from "components/Icons/BadiLogo";

import { getRelativePath } from "base/shared/Utils";

import { LogoWrapper } from "./styled-components";

const Logo = ({ lister, dataQa }) => {
  const homeLinkPath = lister ? "/overview" : "/";
  return (
    <LogoWrapper data-qa={dataQa}>
      <Link to={getRelativePath(homeLinkPath)}>
        <BadiLogo />
      </Link>
    </LogoWrapper>
  );
};

Logo.defaultProps = {
  dataQa: "logo-badi",
  lister: false,
  router: {},
};

export default withRouter(Logo);
