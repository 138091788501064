import React from "react";
import { Icon, Spacer, Flex, Text, BADI_COLORS } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { openReviewsSummaryModal } from "components/reviews-summary-modal";
import { REVIEW_MODAL_TYPE } from "components/reviews-summary-modal/constants";
import { getReviewsSummaryHeaderText } from "components/reviews-summary-modal/utils";
import SocialProfile from "./social-profile";
import { UserCardBlock, LinkWrapper } from "../styled-components";

const Links = ({
  isCompany = false,
  profileUserId = 0,
  reviewsTotal = 0,
  socialProfiles = null,
}) => {
  const handleReviewsClick = () => {
    if (reviewsTotal > 0) {
      openReviewsSummaryModal(profileUserId, REVIEW_MODAL_TYPE.USER);
    }
  };

  return (
    <UserCardBlock>
      {isCompany && (
        <Spacer top={1}>
          <Flex gutter={1}>
            <Icon
              alt="user filled"
              iconMaxHeight={16}
              iconMaxWidth={16}
              src={getPublicAsset("icons/icon-user-filled-blue.svg")}
            />
            <Text
              body="14-Regular"
              color={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
              dataQa="user-company-description"
            >
              {translate("user.section_profile.agency")}
            </Text>
          </Flex>
        </Spacer>
      )}
      <Spacer top={1}>
        <Flex gutter={1}>
          <Icon
            alt="star filled"
            iconMaxHeight={16}
            iconMaxWidth={16}
            src={getPublicAsset("icons/reviews-star-filled.svg")}
          />
          <LinkWrapper onClick={handleReviewsClick}>
            <Text body="14-Regular" dataQa="user-reviews-description">
              {`${reviewsTotal} ${getReviewsSummaryHeaderText(reviewsTotal)}`}
            </Text>
          </LinkWrapper>
        </Flex>
      </Spacer>
      {!!socialProfiles?.length &&
        socialProfiles.map((profile) => (
          <SocialProfile
            icon={profile.icon}
            key={profile.media}
            media={profile.media}
            profileUserId={profileUserId}
            value={profile.value}
          />
        ))}
    </UserCardBlock>
  );
};

export default Links;
