import React, { PureComponent } from "react";
import UserFeedback from "base/shared/user-feedback";
import BankAccountVerifed from "./statuses/verified";
import BankAccountEdit from "./statuses/edit";
import { VERIFIED, EDIT, LOADING } from "./constants";

class BankAccount extends PureComponent {
  state = { status: LOADING };

  static getDerivedStateFromProps(props, state) {
    const { verified } = props;
    const { status } = state;

    if (status === LOADING) {
      return verified ? { status: VERIFIED } : { status: EDIT };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { error, updating, verified } = this.props;

    if (error && error !== prevProps.error) {
      UserFeedback.exception("Something went wrong", error.message, error);
      return;
    }
    if (prevProps.updating && !updating && verified) {
      UserFeedback.info(
        "",
        "Your info has been successfully sent and it will be verified in 4-5 days. Once verified you will become a trusted user!",
      );
      this.handleCloseForm();
    }
  }

  handleUpdateClick = () => {
    this.setState({ status: EDIT });
  };

  handleCloseForm = () => {
    this.setState({ status: VERIFIED });
  };

  render() {
    const { status } = this.state;
    const {
      country,
      bankAccountLastDigits,
      updating,
      setCountry,
      setBankAccount,
      verified,
    } = this.props;

    switch (status) {
      case VERIFIED:
        return (
          <BankAccountVerifed
            bankAccountLastDigits={bankAccountLastDigits}
            onUpdateClick={this.handleUpdateClick}
          />
        );
      case EDIT:
        return (
          <BankAccountEdit
            country={country}
            onClose={this.handleCloseForm}
            setBankAccount={setBankAccount}
            setCountry={setCountry}
            updating={updating}
            verified={verified}
          />
        );
      default:
        return null;
    }
  }
}

BankAccount.defaultProps = {
  country: "",
  error: null,
  updating: false,
  bankAccountLastDigits: "",
};

export default BankAccount;
