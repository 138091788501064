import React, { useState, useEffect } from "react";
import { Flex, BADI_COLORS } from "@badi/badi-components";
import UserFeedback from "base/shared/user-feedback";
import ReviewsSummary from "./reviews-summary";
import LoadingSpinner from "components/Icons/LoadingSpinner";
import { getReviewsForUser, getReviewsForRoom } from "datamodel/reviews/api";
import { REVIEW_MODAL_TYPE } from "./constants";

const ReviewsSummaryContainer = ({ id, token, type }) => {
  const [reviewsSummary, setReviewsSummary] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFetched = (fetchedReviewsSummary) => {
    setLoading(false);
    setReviewsSummary(fetchedReviewsSummary);
  };

  const handleError = () => {
    setLoading(false);
    UserFeedback.exception(
      "Something went wrong",
      "Please, try again or contact support.",
    );
  };

  useEffect(() => {
    if (type === REVIEW_MODAL_TYPE.ROOM) {
      setLoading(true);
      getReviewsForRoom({ token, roomId: id })
        .then((res) => handleFetched(res))
        .catch(() => handleError());
    } else if (type === REVIEW_MODAL_TYPE.USER) {
      setLoading(true);
      getReviewsForUser({ token, userId: id })
        .then((res) => handleFetched(res))
        .catch(() => handleError());
    }
  }, []);

  return (
    <>
      {loading && (
        <Flex
          alignItems="center"
          direction="column"
          fullHeight={true}
          justify="center"
        >
          <LoadingSpinner
            color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
            data-qa="reviews-summary-spinner"
          />
        </Flex>
      )}
      {!loading && reviewsSummary && (
        <ReviewsSummary reviewsSummary={reviewsSummary} />
      )}
    </>
  );
};

export default ReviewsSummaryContainer;
