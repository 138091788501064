import React, { Component } from "react";

import { TextButton, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { SubmitFormWrapper, StyledForm } from "../styled-components";
import RangeField from "../../components/range-field";

class BudgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = { updated: false, min: 0, max: 0 };
  }

  static getDerivedStateFromProps(props, state) {
    const { min, max } = props;

    if (state.updated) {
      return { updated: false };
    }

    return { min, max };
  }

  handleMinPriceChange = (min) => {
    this.setState({ min, updated: true });
  };

  handleMaxPriceChange = (max) => {
    this.setState({ max, updated: true });
  };

  handleApplyFilter = () => {
    const { min, max } = this.state;
    const { onFilterApplied, setBudget } = this.props;

    setBudget(min, max);
    onFilterApplied();
  };

  render() {
    const { min, max } = this.state;

    return (
      <Flex as={StyledForm} direction="column">
        <RangeField
          description={translate("search.filters.budget.currency")}
          max={max}
          min={min}
          onMaxValueChange={this.handleMaxPriceChange}
          onMinValueChange={this.handleMinPriceChange}
          title={translate("search.filters.budget")}
        />
        <SubmitFormWrapper>
          <TextButton onClick={this.handleApplyFilter}>
            {translate("search.filters.button")}
          </TextButton>
        </SubmitFormWrapper>
      </Flex>
    );
  }
}

export default BudgetForm;
