import styled, { css } from "styled-components";
import { colors } from "app/badi-components";

export const MainWrapper = styled.div`
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : null}
`;

export const Root = styled.input`
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  width: 100%;
  margin: 0;
  padding: 8px;
  transition: 0.125s linear;
  color: ${colors.darkGrey};
  background-color: ${colors.lightGrey};
  outline: none;
  height: 48px;
  appearance: none;
  border-style: none;
  border-radius: 8px;
  ${({ hasError }) =>
    hasError
      ? css`
          background-color: ${colors.redLight};
        `
      : null}
  &:focus {
    background-color: ${colors.white};
    border: 2px solid ${colors.green};
  }
`;

export const IconWrapper = styled.div`
  img,
  svg {
    position: relative;
    left: -40px;
    display: block;
  }
`;
