import React from "react";

import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { fetchUser } from "datamodel/User/api";

import RecommendedUser from "./layout-elements/recommendation-user";

const { RecommendationProfile: RECOMMENDATION_PROFILE_MODAL_ID } = MODAL_ID;

const closeRecommendedUserModal = () => {
  modal.remove(RECOMMENDATION_PROFILE_MODAL_ID);
};

export const openRecommededUserModal = ({
  userId,
  token,
  onAccept,
  onReject,
}) => {
  fetchUser(userId, token).then((user) => {
    modal.add(
      <RecommendedUser
        acceptRecommendation={onAccept}
        onClose={closeRecommendedUserModal}
        rejectRecommendation={onReject}
        user={user}
      />,
      {
        id: RECOMMENDATION_PROFILE_MODAL_ID,
        onClose: closeRecommendedUserModal,
        className: "popup_profile",
        type: MODAL_TYPE.LATERAL,
      },
    );
  });
};
