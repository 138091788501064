import React, { useState, useEffect } from "react";

import AuthButton from "../button";

const GoogleButton = ({ appId, children, onError, onSuccess }) => {
  const [loaded, setLoaded] = useState(false);

  const initGoogleApi = () => {
    window.gapi.auth2
      .init({
        client_id: appId,
      })
      .then(() => {
        setLoaded(true);
      })
      .catch(({ error: code, details: message }) => {
        onError({
          accessToken: null,
          provider: "google",
          status: "error",
          error: { code, message },
        });
      });
  };

  const handleGoogleLoad = () => {
    window.gapi.load("auth2", initGoogleApi);
  };

  const loadSdkAsynchronously = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/api.js";
      js.defer = true;
      js.async = true;
      js.onload = handleGoogleLoad;
      js.onreadystatechange = () => {
        if (this.readyState === "complete") this.onload();
      };
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "googleauth-jssdk");
  };

  const clickHandler = () => {
    window.gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((googleUser) =>
        onSuccess({
          accessToken: googleUser.getAuthResponse().id_token,
          provider: "google",
          status: "success",
          error: null,
        }),
      )
      .catch(({ error }) => {
        if (error === "popup_closed_by_user") {
          return;
        }
        onError({
          accessToken: null,
          provider: "google",
          status: "error",
          error: new Error(error),
        });
      });
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    if (window.gapi) {
      initGoogleApi();
    } else {
      loadSdkAsynchronously();
      window.handleGoogleLoad = handleGoogleLoad;
    }
  }, []);

  return (
    <AuthButton
      dataQa="google-button"
      iconAlt="google button"
      iconSrc="/assets/icons/icon-google-colors.svg"
      loaded={loaded}
      onClick={clickHandler}
    >
      {children}
    </AuthButton>
  );
};

export default GoogleButton;
