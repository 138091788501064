import { connect } from "react-redux";

import { getRoom } from "datamodel/Room/selectors";
import RoomActions from "datamodel/Room/actions";

import RoomDetail from "./room-detail-badge";

const mapStateToProps = (state) => ({
  currentRoomId: getRoom(state).id,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    clearRoom: () => dispatch(RoomActions.clearRoom()),
  };
};

export default connect(mapStateToProps, null, mergeProps)(RoomDetail);
