import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "MY_LISTINGS_SUMMARY_ERROR",
  "MY_LISTINGS_SUMMARY_REQUEST",
  "MY_LISTINGS_SUMMARY_SUCCESS",
  "INITIAL_LISTINGS_BY_STATUS_ERROR",
  "INITIAL_LISTINGS_BY_STATUS_REQUEST",
  "INITIAL_LISTINGS_BY_STATUS_SUCCESS",
  "NEXT_LISTINGS_BY_STATUS_ERROR",
  "NEXT_LISTINGS_BY_STATUS_REQUEST",
  "NEXT_LISTINGS_BY_STATUS_SUCCESS",
  "RESET_LISTINGS",
  "SELECT_LISTINGS",
  "UNSELECT_LISTINGS",
  "CLEAR_SELECTED_LISTINGS",
]);
