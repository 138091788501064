import { createSelector } from "reselect";

const getPremium = (state) => state.Premium;

export const getPremiumPlansSelector = createSelector(
  getPremium,
  (premium) => premium.plans,
);

export const getPremiumCapabilitiesSelector = createSelector(
  getPremium,
  (premium) => premium.capabilities,
);

export const getPremiumErrorSelector = createSelector(
  getPremium,
  (premium) => premium.error,
);

export const getPremiumRoomsCapabilitiesSelector = createSelector(
  getPremium,
  (premium) => premium.rooms,
);

export const getPremiumRoomSelector = (state, roomId) => {
  if (!roomId) {
    return null;
  }

  return getPremiumRoomsCapabilitiesSelector(state).find(
    (room) => room.id === roomId,
  );
};

export const getPremiumRoomCapabilitiesSelector = (state, roomId) => {
  const room = getPremiumRoomSelector(state, roomId);

  return room?.capabilities || [];
};

export const isPremiumRoomLoadedSelector = (state, roomId) => {
  const room = getPremiumRoomSelector(state, roomId);

  return room?.loaded || false;
};

export const hasRoomCapabilitySelector = (state, { roomId, capabilityId }) =>
  !!getPremiumRoomCapabilitiesSelector(state, roomId).find(
    (capability) => capability.id === capabilityId,
  );

export const getPremiumOriginSelector = createSelector(
  getPremium,
  (premium) => premium.origin,
);
