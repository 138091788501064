import React from "react";
import { Spacer, useMatchMedia } from "@badi/badi-components";

import TrustedUserCta from "../layout-elements/trusted-user-cta";
import ListARoom from "../layout-elements/list-a-room";
import ListerScoreCard from "../components/lister-score-card";
import ListerMetricsCard from "../components/lister-metrics-card";
import RoomsOverview from "./rooms-overview/rooms-overview-mapping";
import ListingsStatuses from "./listings-statuses/listings-statuses-mapping";
import { QUERIES } from "../constants";

const COMPONENTS = {
  roomsCards: RoomsOverview,
  listingStatuses: ListingsStatuses,
  becomeTrustedUser: TrustedUserCta,
  listARoom: ListARoom,
  listerScore: ListerScoreCard,
  listerMetrics: ListerMetricsCard,
};

const SectionComponents = ({ components }) =>
  components.reduce((accumulator, current) => {
    const availableComponents = accumulator;
    const Component = COMPONENTS[current];
    const [isDesktop] = useMatchMedia(QUERIES);

    if (Component)
      availableComponents.push(
        <Spacer key={current} top={isDesktop ? 3 : 0}>
          <Component />
        </Spacer>,
      );

    return availableComponents;
  }, []);

export default SectionComponents;
