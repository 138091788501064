import React, { useEffect, useMemo, useState } from "react";
import {
  Spacer,
  Text,
  Heading,
  Button,
  BUTTON_TYPES,
  Flex,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { openPhoneVerificationModal } from "components/Verification/Phone";

import { VISIT_CATEGORIES } from "datamodel/RoomList/constants";

import ThankYouScreen from "./components/thank-you-screen";
import CategoryField from "./components/category-field";
import DateField from "./components/date-field";
import TimeField from "./components/time-field";
import TextField from "./components/text-field";
import {
  getDynamicTexts,
  parseAvailableDays,
  parseAvailableTimes,
} from "./utils";

const VisitProposal = ({
  confirmedPhone,
  connectionId = 0,
  getInitialVisitData,
  onClose,
  onSuccess = null,
  proposeVisit,
  roomId,
  seekerId = 0,
  userId,
  visitId = null,
}) => {
  const [success, setSuccess] = useState(false);
  const [categories, setCategories] = useState(0);
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState({
    morning: [],
    evening: [],
    afternoon: [],
  });
  const [category, setCategory] = useState(0);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [message, setMessage] = useState("");
  const [fetching, setFetching] = useState(false);
  const [valid, setValid] = useState(false);
  const { title, subtitle, cta } = useMemo(
    () => getDynamicTexts(!!visitId),
    [visitId],
  );

  const getAvailabilityFromDate = (fromDate = null) => {
    setFetching(true);
    getInitialVisitData({ roomId, fromDate }).then((data) => {
      const availableDays = parseAvailableDays(data.days);

      setDays(availableDays);
      setDate(fromDate || availableDays[0]?.date || null);
      setTimes({
        morning: parseAvailableTimes(data.mornings, "morning"),
        evening: parseAvailableTimes(data.evenings, "evening"),
        afternoon: parseAvailableTimes(data.afternoons, "afternoon"),
      });

      if (!categories) {
        setCategories(data.category);
      }
      setCategory(
        data.category === VISIT_CATEGORIES.IN_PLACE
          ? VISIT_CATEGORIES.IN_PLACE
          : VISIT_CATEGORIES.VIDEOCALL,
      );

      setFetching(false);
    });
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleDateChange = (value) => {
    getAvailabilityFromDate(value);
  };

  const handleTimeChange = (value) => {
    setTime(value);
  };

  const handleMessageChange = (value) => {
    setMessage(value);
  };

  const sendVisitProposal = () => {
    proposeVisit({
      connectionId: connectionId || null,
      express: category === VISIT_CATEGORIES.VIDEOCALL,
      localStartAt: time,
      roomId,
      seekerId: seekerId || null,
      message,
      visitId,
    })
      .then((proposedVisit) => {
        setSuccess(true);

        if (onSuccess) {
          onSuccess(proposedVisit);
        }
      })
      .catch(() => {
        UserFeedback.exception("Something went wrong");
      });
  };

  const handleSubmit = () => {
    if (!confirmedPhone) {
      openPhoneVerificationModal({
        onSuccess: sendVisitProposal,
        onClose: () => {},
      });
    } else {
      sendVisitProposal();
    }
  };

  useEffect(() => {
    getAvailabilityFromDate();
  }, []);

  useEffect(() => {
    setValid(!!(category && date && time));
  }, [category, date, time]);

  if (!categories) {
    return null;
  }

  if (success) {
    return <ThankYouScreen onClose={onClose} />;
  }

  return (
    <Flex direction="column" fullHeight={true}>
      <Flex direction="column" grow={1} gutter={3}>
        <>
          <Spacer bottom={1}>
            <Heading data-qa="visit-proposal-title">{translate(title)}</Heading>
          </Spacer>
          <Text data-qa="visit-proposal-description">
            {translate(subtitle)}
          </Text>
        </>
        <CategoryField config={categories} onChange={handleCategoryChange} />
        <DateField
          days={days}
          disabled={fetching}
          onChange={handleDateChange}
        />
        <TimeField
          afternoon={times.afternoon}
          disabled={fetching}
          evening={times.evening}
          morning={times.morning}
          onChange={handleTimeChange}
        />
        <TextField disabled={fetching} onChange={handleMessageChange} />
      </Flex>
      <Spacer bottom={6} md={{ bottom: 1 }} top={3}>
        <Button
          data-qa="visit-proposal-submit"
          disabled={fetching || !valid}
          fullWidth={true}
          onClick={handleSubmit}
          variant={BUTTON_TYPES.PRIMARY}
        >
          {translate(cta)}
        </Button>
      </Spacer>
    </Flex>
  );
};

export default VisitProposal;
