import React, { useEffect } from "react";
import { Flex, Heading, Text, Spacer, Button } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import Image from "components/Image/Image";
import { COMPLETE_PROFILE_FROM } from "./constants";
import { getContent } from "./utils";

const CompleteProfile = ({
  cancel,
  from = COMPLETE_PROFILE_FROM.DEFAULT,
  success,
}) => {
  const acceptClickHandler = () => {
    success();
  };

  const skipClickHandler = () => {
    cancel();
  };

  const { title, text, acceptButton, skipButton } = getContent(from);
  return (
    <Flex alignItems="center" direction="column" gutter={3}>
      <Spacer bottom={2} left={5} right={5} top={2}>
        <Flex alignItems="center" direction="column" gutter={2}>
          <Image src="/assets/images/complete_profile_room.svg" />

          <Heading align="center" data-qa="complete-profile-title">
            {translate(title)}
          </Heading>
          {text.map((t) => (
            <Text align="center" dataQa="complete-profile-description" key={t}>
              {translate(t)}
            </Text>
          ))}
        </Flex>
      </Spacer>
      <Flex alignself="flexEnd" gutter={1}>
        <Button
          dataQa="complete-profile-skip"
          onClick={skipClickHandler}
          variant="secondary"
        >
          {translate(skipButton)}
        </Button>
        <Button dataQa="complete-profile-accept" onClick={acceptClickHandler}>
          {translate(acceptButton)}
        </Button>
      </Flex>
    </Flex>
  );
};

export default CompleteProfile;
