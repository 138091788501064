import { getCookie, setCookie } from "base/shared/CookiesHandler";
import { SUGGEST_TYPES, SUGGEST_COOKIE } from "./constants";

const decodeCookie = (cookie) => {
  if (!cookie) return [];

  // We are checking the type because our library to read cookies is
  // parsing the value to an object automatically when we save just one suggest.
  // When we save more than one, separated by double pipe it returns the string value.
  if (typeof cookie !== "string") return [cookie];

  const suggests = decodeURIComponent(cookie).split("||") || [];

  return suggests.reduce((parsedSuggests, suggest) => {
    if (suggest) {
      parsedSuggests.push(JSON.parse(suggest));
    }
    return parsedSuggests;
  }, []);
};

export const encodeCookie = (values) =>
  values
    .map((next) => {
      const {
        bounds,
        distance,
        label,
        coordinates,
        placeId,
        zoom,
        type,
        meta,
      } = next;

      const city = meta ? { city: meta.city } : null;

      return JSON.stringify({
        meta: city,
        bounds,
        distance,
        label,
        coordinates,
        placeId,
        type,
        zoom,
      });
    })
    .join("||");

export const getAddressComponent = (addressComponents, label) => {
  const nullAddress = {
    short_name: null,
    long_name: null,
  };

  if (!addressComponents) return nullAddress;
  return (
    addressComponents.find((i) => i.types.indexOf(label) > -1) || nullAddress
  );
};

export const getZoom = (distance) => {
  if (distance < 0.36) return 17;
  if (distance < 0.6) return 16;
  if (distance < 1) return 15;
  if (distance < 3) return 14;
  if (distance < 7) return 13;
  if (distance < 15) return 12;
  if (distance < 20) return 11;
  if (distance < 70) return 10;
  if (distance < 150) return 9;
  if (distance < 300) return 8;
  return 7;
};

export const getDistance = (bounds) => {
  const ne = bounds.getNorthEast();
  const center = bounds.getCenter();
  const radius =
    window.google.maps.geometry.spherical.computeDistanceBetween(center, ne) /
    1000;

  return radius < 0.35 ? 0.35 : radius;
};

export const getSuggestType = (type) => SUGGEST_TYPES[type];

export const getRecentSearches = () => decodeCookie(getCookie(SUGGEST_COOKIE));

export const parseGeocodeSuggest = ({
  address_components,
  formatted_address: description,
  place_id: placeId,
  geometry,
  types,
}) => {
  const result = {
    description,
    placeId,
    type: types.length ? types[0] : null,
    location: { lat: geometry.location.lat(), lng: geometry.location.lng() },
    gmaps: { address_components, geometry },
  };

  return result;
};

export const parseBounds = (bounds) => {
  if (!bounds) return null;
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return {
    ne: { lat: ne.lat(), lng: ne.lng() },
    sw: { lat: sw.lat(), lng: sw.lng() },
  };
};

export const getCityFromAddressComponents = (addressComponents) => {
  const identifiers = [
    "locality",
    "postal_town",
    "administrative_area_level_3",
    "administrative_area_level_1",
  ];

  for (const identifier of identifiers) {
    const { long_name: name } = getAddressComponent(
      addressComponents,
      identifier,
    );

    if (name) {
      return name;
    }
  }

  return null;
};

export const parseSuggest = (suggest) => {
  if (!suggest || !suggest.placeId || !suggest.location || !suggest.gmaps)
    return null;

  const { description, gmaps, location, placeId, type } = suggest;
  const { address_components: addressComponents } = gmaps;
  const city = getCityFromAddressComponents(addressComponents);
  const address = gmaps.formatted_address || "";
  const bounds = parseBounds(gmaps.geometry.bounds || gmaps.geometry.viewport);
  const distance = getDistance(
    gmaps.geometry.bounds || gmaps.geometry.viewport,
  );
  const coordinates = {};
  const meta = {};

  const country = getAddressComponent(addressComponents, "country");
  const postalCode = getAddressComponent(addressComponents, "postal_code");
  const street = getAddressComponent(addressComponents, "route");
  const streetNumber = getAddressComponent(addressComponents, "street_number");

  coordinates.lat = location.lat;
  coordinates.lng = location.lng;
  meta.city = city;
  meta.country = country.long_name;
  meta.countryCode = country.short_name;
  meta.postalCode = postalCode.long_name;
  meta.street = street.long_name;
  meta.streetNumber = streetNumber.long_name;

  return {
    address,
    distance,
    bounds,
    coordinates,
    label: description,
    meta,
    placeId,
    type: getSuggestType(type),
    zoom: getZoom(distance),
  };
};

export const parseGeocodeResult = (text, results = []) => {
  if (Array.isArray(results) && results.length) {
    const result = results[0];
    const {
      geometry: { location },
      place_id: placeId,
    } = result;
    const suggest = {
      description: text,
      placeId,
      gmaps: {
        ...result,
      },
      location: {
        lat: location.lat(),
        lng: location.lng(),
      },
    };
    return parseSuggest(suggest);
  }
  return null;
};

export const saveRecentSearch = (recentSearches = [], suggest) => {
  if (!suggest) return recentSearches;

  const existingSuggest = recentSearches.find(
    (recentSearch) => recentSearch.placeId === suggest.placeId,
  );

  if (existingSuggest) {
    const index = recentSearches.indexOf(existingSuggest);
    recentSearches.splice(index, 1);
  } else if (recentSearches.length >= 4) {
    recentSearches.pop();
  }

  recentSearches.unshift(suggest);

  setCookie(SUGGEST_COOKIE, encodeCookie(recentSearches));

  return recentSearches;
};

export const getCity = (suggest) =>
  suggest.meta ? suggest.meta.city : suggest.label;

export const isPrefilled = (etParam, initialValue, prefillableSearch) => {
  return !etParam && !!initialValue && !!prefillableSearch;
};
