import React from "react";

import { Spacer, Text, colors } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Input from "components/form-text-input";

import { VALIDATIONS } from "./validations";

const SocialProfile = ({
  initialValue,
  media,
  withSubLabel = false,
  onChange,
  onInputValidation,
}) => (
  <>
    <Text body={2} dataQa={`profile-form-social-profile-${media}-title`}>
      {translate(`profile.form.label.social_media.${media}`)}
    </Text>
    <Spacer bottom={2} top={1}>
      <Input
        dataQa={`profile-form-social-profile-${media}-input`}
        initialValue={initialValue}
        name={media}
        onChange={onChange}
        onInputValidation={onInputValidation}
        placeholder={translate(
          `profile.form.label.social_media.${media}_placeholder`,
        )}
        validations={VALIDATIONS[media]}
      />
      {withSubLabel && (
        <Spacer top={0.5}>
          <Text
            body={3}
            color={colors.midGrey}
            dataQa={`profile-form-social-profile-${media}-sublabel`}
          >
            {translate(`profile.form.label.social_media.${media}_hint`)}
          </Text>
        </Spacer>
      )}
    </Spacer>
  </>
);

export default SocialProfile;
