import createReducer from "base/shared/createReducer";
import types from "./types";
import { ReferralsModel } from "./models";

export const referralAccountRequest = (state) => ({
  ...state,
  fetchingAccount: true,
  fetchedAccount: false,
  error: null,
});

export const referralAccountSuccess = (state, action) => ({
  ...state,
  error: null,
  fetchingAccount: false,
  fetchedAccount: true,
  referralAccount: action.result,
});

export const referralAccountError = (state, action) => ({
  ...state,
  fetchingAccount: false,
  fetchedAccount: false,
  error: action.error,
});

export const pendingReferralsRequest = (state) => ({
  ...state,
  fetchingPending: true,
  fetchedPending: false,
  error: null,
});

export const pendingReferralsSuccess = (state, action) => {
  const oldResults = [...state.pendingReferrals.results];
  const newPendingReferrals = {
    ...action.result,
    results: oldResults.concat(action.result.results),
  };

  return {
    ...state,
    error: null,
    fetchingPending: false,
    fetchedPending: true,
    pendingReferrals: newPendingReferrals,
  };
};

export const pendingReferralsError = (state, action) => ({
  ...state,
  fetchingPending: false,
  fetchedPending: false,
  error: action.error,
});

export const referralProductsRequest = (state) => ({
  ...state,
  fetchingProducts: true,
  fetchedProducts: false,
  error: null,
});

export const referralProductsSuccess = (state, action) => ({
  ...state,
  error: null,
  fetchingProducts: false,
  fetchedProducts: true,
  referralProducts: action.result,
});

export const referralProductsError = (state, action) => ({
  ...state,
  fetchingProducts: false,
  fetchedProducts: false,
  error: action.error,
});

export const resetReferrals = () => ({
  ...ReferralsModel,
});

const actionHandlers = {
  [types.REFERRALS_PENDING_REQUEST]: pendingReferralsRequest,
  [types.REFERRALS_PENDING_SUCCESS]: pendingReferralsSuccess,
  [types.REFERRALS_PENDING_ERROR]: pendingReferralsError,
  [types.REFERRALS_ACCOUNT_REQUEST]: referralAccountRequest,
  [types.REFERRALS_ACCOUNT_SUCCESS]: referralAccountSuccess,
  [types.REFERRALS_ACCOUNT_ERROR]: referralAccountError,
  [types.REFERRAL_PRODUCTS_REQUEST]: referralProductsRequest,
  [types.REFERRAL_PRODUCTS_SUCCESS]: referralProductsSuccess,
  [types.REFERRAL_PRODUCTS_ERROR]: referralProductsError,
  [types.RESET_REFERRALS]: resetReferrals,
};

export default createReducer(actionHandlers, { ...ReferralsModel });
