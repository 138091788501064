import React, { PureComponent } from "react";

class LandingSectionHeader extends PureComponent {
  render() {
    const { className, subtitle, title } = this.props;
    return (
      <div className={className}>
        {title && <h2>{title}</h2>}
        {subtitle && <p>{subtitle}</p>}
      </div>
    );
  }
}

LandingSectionHeader.defaultProps = {
  className: null,
  subtitle: null,
  title: null,
};

export default LandingSectionHeader;
