import { createSelector } from "reselect";

export const getTransactions = (state) => state.Transactions;

export const getTransactionsPerPage = createSelector(
  getTransactions,
  (transactions) => transactions.perPage,
);

export const getTransactionsSelector = createSelector(
  getTransactions,
  (transactions) => transactions.transactions,
);

export const getTransactionsSummarySelector = createSelector(
  getTransactions,
  (transactions) => transactions.summary,
);

export const getPaginationSelector = createSelector(
  getTransactions,
  (transactions) => transactions.pagination,
);

export const getCurrentPageSelector = createSelector(
  getTransactions,
  (transactions) => transactions.pagination?.currentPage || 1,
);

export const getTotalPagesSelector = createSelector(
  getTransactions,
  (transactions) => transactions.pagination?.totalPages || 1,
);
