import React from "react";
import { Flex, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { PLACE_TYPES } from "base/shared/StaticData";
import { getSizeUnitLabel, getRoomsNumber } from "../utils";
import { UpperCaseText } from "../styled-components";

const PropertyTypeLabel = ({
  room: {
    flatSize,
    placeType,
    maleTenants,
    femaleTenants,
    sizeUnit,
    singleBedrooms,
    doubleBedrooms,
  },
}) => {
  if (!placeType) return null;
  const isEntireFlat = placeType === 2;
  const sizeUnitLabel = getSizeUnitLabel(sizeUnit);
  const typeObject = PLACE_TYPES.find((p) => p.value === placeType);
  const flatmates = maleTenants + femaleTenants;
  const roomsNumber = getRoomsNumber(singleBedrooms, doubleBedrooms);
  return (
    <Spacer bottom={0} top={0.5}>
      <Flex>
        <UpperCaseText data-qa={"property-type-title"}>
          {translate(typeObject.title)}{" "}
        </UpperCaseText>
        {isEntireFlat && !!flatSize && (
          <Flex>
            <Spacer left={0.5}>
              <UpperCaseText data-qa="property-type-entire">{`· ${flatSize} ${sizeUnitLabel}`}</UpperCaseText>
            </Spacer>
            {roomsNumber !== null && (
              <Spacer left={0.5}>
                <UpperCaseText data-qa="property-type-rooms">{`· ${translate(
                  "card.detail.rooms",
                  { smart_count: roomsNumber },
                )}`}</UpperCaseText>
              </Spacer>
            )}
          </Flex>
        )}
        {!isEntireFlat && (
          <Spacer left={0.5}>
            <UpperCaseText data-qa={"property-type-shared"}>{`· ${translate(
              "card.detail.flatmates_number",
              { smart_count: flatmates },
            )}`}</UpperCaseText>
          </Spacer>
        )}
      </Flex>
    </Spacer>
  );
};

export default PropertyTypeLabel;
