import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "PAY_ACCOUNT_REQUEST",
  "PAY_ACCOUNT_SUCCESS",
  "PAY_ACCOUNT_ERROR",
  "PAY_METHOD_SAVING_REQUEST",
  "PAY_METHOD_SAVING_SUCCESS",
  "PAY_METHOD_SAVING_ERROR",
  "PAY_COUNTRY_SAVING_REQUEST",
  "PAY_COUNTRY_SAVING_SUCCESS",
  "PAY_COUNTRY_SAVING_ERROR",
  "PAY_CONTACT_SAVING_REQUEST",
  "PAY_CONTACT_SAVING_SUCCESS",
  "PAY_CONTACT_SAVING_ERROR",
  "RESET_PAYOUTS",
]);
