import { getRoomPictureBySize } from "base/shared/utils/room";
import { getUserPictureBySize } from "base/shared/utils/user";

const LISTINGS_STATUS = {
  1: "active",
  2: "unpublished",
  4: "rented",
  5: "booked",
  7: "pending",
};

const findStatusId = (status) =>
  Object.keys(LISTINGS_STATUS).find((key) => LISTINGS_STATUS[key] === status);

const getActions = (actions) => {
  let filteredActions = { ...actions };
  filteredActions = Object.keys(filteredActions).reduce((result, key) => {
    const output = { ...result };
    if (filteredActions[key]) {
      output[key] = true;
    }
    return output;
  }, {});
  return filteredActions;
};

const checkExtraFeatures = (actions, statusId) => {
  const extraFeatures = { forcePublish: false, notAllowedToEdit: false };
  if (statusId === findStatusId("pending")) {
    extraFeatures.forcePublish = true;
  }
  if (statusId === findStatusId("active") && !actions.edit) {
    extraFeatures.notAllowedToEdit = true;
  }
  return extraFeatures;
};

const parseSeeker = (rentedTo, lastBooking) => {
  if (!rentedTo && !lastBooking) return null;

  const bookingId = lastBooking ? lastBooking.booking_proposal_id : null;
  const hasRented = !!rentedTo;
  const seekerInfo = rentedTo || lastBooking.user;
  const { cover_picture: coverPicture, first_name: firstName, id } = seekerInfo;

  return {
    coverPicture: getUserPictureBySize(coverPicture),
    firstName,
    bookingId,
    hasRented,
    id,
  };
};

const parseListing = (listing, listingStatusId) => {
  const {
    address,
    id,
    title,
    availability_status: availabilityStatus,
    price_description: priceDescription,
    cover_picture: coverPicture,
    actions,
    last_booking: lastBooking,
    rented_to: rentedTo,
    pending_booking_proposals: pendingBookingProposals,
    edit_required: editRequired,
    active_pro_room_plan_id: activeProRoomPlanId,
    plan_label: planLabel,
    plan_renewal_status: planRenewalStatus,
    agency_external_id: externalAgencyId,
  } = listing;

  // get the last booking reference Id
  const bookingReferenceId = lastBooking ? lastBooking.reference : null;

  return {
    activeProRoomPlanId,
    address,
    id,
    title,
    availabilityStatus,
    priceDescription,
    coverPicture: getRoomPictureBySize(coverPicture),
    actions: getActions(actions),
    seeker: parseSeeker(rentedTo, lastBooking),
    extraFeatures: checkExtraFeatures(actions, listingStatusId),
    listingStatus: LISTINGS_STATUS[listingStatusId],
    pendingBookingProposals,
    bookingReferenceId,
    editRequired,
    planLabel,
    planRenewalStatus,
    externalAgencyId,
  };
};

const parseListings = (listings, listingStatusId) =>
  listings.map((listing) => parseListing(listing, listingStatusId));

export const removeDuplicatedValues = (currentList, newList) => {
  const safeList = [];
  newList.forEach((value) => {
    if (!currentList.includes(value)) {
      safeList.push(value);
    }
  });
  return safeList;
};

export { parseListings, parseListing, findStatusId, parseSeeker };
