import React from "react";

const Large = ({ className }) => (
  <svg
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.459 16.014l8.239-8.194a.992.992 0 0 0 0-1.414 1.016 1.016 0 0 0-1.428 0l-8.232 8.187L7.73 6.284a1.009 1.009 0 0 0-1.428 0 1.015 1.015 0 0 0 0 1.432l8.302 8.303-8.332 8.286a.994.994 0 0 0 0 1.414 1.016 1.016 0 0 0 1.428 0l8.325-8.279 8.275 8.276a1.009 1.009 0 0 0 1.428 0 1.015 1.015 0 0 0 0-1.432l-8.269-8.27z"
      fill="#1e1e1e"
    />
  </svg>
);

const Medium = ({ className }) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop/Profile/Edit-interests"
        transform="translate(-814.000000, -29.000000)"
      >
        <rect x="0" y="0" width="1200" height="800" />
        <g
          id="icon/close"
          transform="translate(815.000000, 30.000000)"
          fill="#1E1E1E"
          fillRule="nonzero"
          stroke="#1E1E1E"
          strokeWidth="0.5"
        >
          <path
            d="M0.236442763,15.7586991 C0.393869442,15.9195664 0.600542416,16 0.807215389,16 C1.01388836,16 1.22056134,15.9195664 1.37798802,15.7586991 L8,8.99196308 L14.622012,15.7586991 C14.7794387,15.9195664 14.9861116,16 15.1927846,16 C15.3994576,16 15.6061306,15.9195664 15.7635572,15.7586991 C16.0788143,15.4365522 16.0788143,14.9143524 15.7635572,14.5917929 L9.14154525,7.82546936 L15.7635572,1.40851643 C16.0788143,1.08636947 16.0788143,0.564169658 15.7635572,0.241610219 C15.4487039,-0.0805367397 14.9368653,-0.0805367397 14.622012,0.241610219 L8,6.65856315 L1.37798802,0.241610219 C1.06313466,-0.0805367397 0.551296121,-0.0805367397 0.236442763,0.241610219 C-0.0788142542,0.563757178 -0.0788142542,1.08595699 0.236442763,1.40851643 L6.85805109,7.82546936 L0.236442763,14.5917929 C-0.0788142542,14.9139399 -0.0788142542,15.4361397 0.236442763,15.7586991 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

const Small = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    className={className}
  >
    <g fill="#333" fillRule="evenodd" transform="translate(-2 -1)">
      <rect width="16" height="2" y="6" rx="1" transform="rotate(45 8 7)" />
      <rect
        width="16"
        height="2"
        y="6"
        rx="1"
        transform="scale(-1 1) rotate(45 0 -12.314)"
      />
    </g>
  </svg>
);

class Close extends React.PureComponent {
  render() {
    const { className, format } = this.props;

    switch (format) {
      case "small":
        return <Small className={className} />;
      case "medium":
        return <Medium className={className} />;
      case "large":
        return <Large className={className} />;
      default:
        return null;
    }
  }
}

Close.defaultProps = {
  className: "",
  format: "medium",
};

export default Close;
