import React from "react";
import BadiLogo from "components/Icons/BadiLogo";
import { getRelativePath } from "base/shared/Utils";

import { Text, Flex, Icon, TextButton, colors } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { TopBarWrapper } from "./styled-components";

const HOME_PATH = "/";

const SeekerPreferencesTopBar = ({ navigateTo }) => {
  const handleClose = () => {
    navigateTo(getRelativePath(HOME_PATH));
  };
  return (
    <TopBarWrapper as={Flex} justify="spaceBetween">
      <BadiLogo />
      <TextButton
        color={colors.darkGrey}
        data-qa={"seeker-preferences-close"}
        hoverColor={colors.darkGrey}
        onClick={handleClose}
        withIcon={<Icon name="close" />}
      >
        <Text body="12-Bold">
          {translate("stepper_layout.topbar.close").toUpperCase()}
        </Text>
      </TextButton>
    </TopBarWrapper>
  );
};

export default SeekerPreferencesTopBar;
