import styled from "styled-components";
import { colors, mediaquery, unitizedPx } from "@badi/badi-components";

export const MainWrapper = styled.div``;

export const SideWrapper = styled.div`
  height: 100%;
  width: ${unitizedPx(34)};
`;

export const Picture = styled.div`
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ButtonsWrapper = styled.div`
  position: fixed;
  bottom: ${unitizedPx(5)};
  left: ${unitizedPx(2)};
  right: ${unitizedPx(2)};
`;
