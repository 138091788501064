import React, { useEffect, memo } from "react";
import { Button, Flex, Spacer, Heading, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Select from "components/form-select";
import TextInput from "components/form-text-input";
import { useForm, useFormValidation } from "components/hooks";

const validatePhoneNumber = (value) => {
  const regex = /^[0-9]{6,16}$/;

  return regex.test(value);
};

/**
 *
 * TODO: this.blockedUntil = checkRequestVerificationCookie('badi_phone_verification_timeout');
 * TODO: setRequestVerificationCookie('badi_phone_verification_timeout'); on submission
 */

const INPUT_PHONE_NUMBER = "phone_number";
const INPUT_COUNTRY_CODE = "country";

const RequestCode = ({ onSubmit, countryCodes, toggleStep }) => {
  const [inputsValue, setInputValue] = useForm({
    [INPUT_PHONE_NUMBER]: "",
    [INPUT_COUNTRY_CODE]: "",
  });

  const [isFormValid, updateFieldValidation] = useFormValidation({
    [INPUT_PHONE_NUMBER]: false,
    [INPUT_COUNTRY_CODE]: false,
  });

  useEffect(() => {
    // if the component has new "initial props" update the select value
    if (countryCodes.length > 0 && !inputsValue[INPUT_COUNTRY_CODE]) {
      setInputValue(INPUT_COUNTRY_CODE, countryCodes[0].value);
    }
  }, [countryCodes]);

  const handleInputValidation = ({ name, isValid }) => {
    updateFieldValidation(name, isValid);
  };

  const handlePhoneChange = ({ value }) => {
    setInputValue(INPUT_PHONE_NUMBER, value);
  };

  const handleCountryChange = ({ value }) => {
    setInputValue(INPUT_COUNTRY_CODE, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(inputsValue);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Heading level={3}>
        {translate("verifications.phone.request.title")}
      </Heading>
      <Spacer bottom={2} top={3}>
        <Flex
          alignItems="flexEnd"
          direction="column"
          gutter={2}
          md={{
            alignItems: "flexEnd",
            direction: "row",
            gutter: 2,
          }}
        >
          <div grow={1}>
            <Spacer bottom={1}>
              <Text body={2}>
                {translate("verifications.phone.request.country.label")}
              </Text>
            </Spacer>
            <Select
              name={INPUT_COUNTRY_CODE}
              onChange={handleCountryChange}
              onValidation={handleInputValidation}
              options={countryCodes}
              placeholder={translate(
                "verifications.phone.request.country.placeholder",
              )}
              value={inputsValue.country}
            />
          </div>
          <div basis="content" grow={1} wide={true}>
            <Spacer bottom={1}>
              <Text body={2}>
                {translate("verifications.phone.request.number.label")}
              </Text>
            </Spacer>
            <TextInput
              name={INPUT_PHONE_NUMBER}
              onChange={handlePhoneChange}
              onInputValidation={handleInputValidation}
              placeholder={translate(
                "verifications.phone.request.number.placeholder",
              )}
              required={true}
              validations={[validatePhoneNumber]}
            />
          </div>
        </Flex>
      </Spacer>
      <Flex gutter={2} justify="flexEnd">
        <Button
          dataQa="phone-enter-code"
          onClick={toggleStep}
          type="button"
          variant="secondary"
        >
          {translate("verifications.phone.request.code_button")}
        </Button>
        <Button disabled={!isFormValid} type="submit">
          {translate("verifications.phone.request.submit_button")}
        </Button>
      </Flex>
    </form>
  );
};

export default memo(RequestCode);
