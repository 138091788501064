import React from "react";
import { Flex, Text, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { IconWrapper } from "./styled-components";
import { BADI_COLORS } from "app/badi-components";

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    viewBox="75 -890 800 800"
    width="16"
  >
    <path
      fill={BADI_COLORS.ADDITIONAL.ADDITIONAL_06}
      d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
    />
  </svg>
);

const FastTrackRoomConnections = ({ connections }) => {
  return (
    <Flex alignItems={"center"} gutter={1}>
      <IconWrapper>
        <CheckIcon />
      </IconWrapper>
      <Text body={2} data-qa="room-connections-count-label">
        {translate("room.detail.fast_track_contact_counter", {
          smart_count: connections,
        })}
      </Text>
    </Flex>
  );
};

export default FastTrackRoomConnections;
