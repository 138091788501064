import React, { Component } from "react";
import { withFormsy } from "formsy-react";

import Select from "./Select";

class SelectWithFormsy extends Component {
  constructor(props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
  }

  onValueChange(e) {
    const { isValidValue, onChange, setValue } = this.props;
    const { name, value } = e.currentTarget;

    setValue(value);
    if (onChange) onChange(name, value, isValidValue(value));
  }

  render() {
    const { getValue, ...rest } = this.props;

    const safeValue = getValue();

    return <Select {...rest} onChange={this.onValueChange} value={safeValue} />;
  }
}

SelectWithFormsy.defaultProps = {
  onChange: undefined,
};

export default withFormsy(SelectWithFormsy);
