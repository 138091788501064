import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "OVERVIEW_SUMMARY_ERROR",
  "OVERVIEW_SUMMARY_REQUEST",
  "OVERVIEW_SUMMARY_SUCCESS",
  "INITIAL_ROOMS_OVERVIEW_SUMMARY_REQUEST",
  "INITIAL_ROOMS_OVERVIEW_SUMMARY_ERROR",
  "INITIAL_ROOMS_OVERVIEW_SUMMARY_SUCCESS",
  "NEXT_ROOMS_OVERVIEW_SUMMARY_REQUEST",
  "NEXT_ROOMS_OVERVIEW_SUMMARY_ERROR",
  "NEXT_ROOMS_OVERVIEW_SUMMARY_SUCCESS",
  "OVERVIEW_LISTINGS_STATUSES_REQUEST",
  "OVERVIEW_LISTINGS_STATUSES_ERROR",
  "OVERVIEW_LISTINGS_STATUSES_SUCCESS",
  "RESET_OVERVIEW",
]);
