import React, { Suspense } from "react";
import UserFeedback from "base/shared/user-feedback";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import "./MessageEnquiryModalExt.css";
import { translate } from "base/shared/Localization";

export const closeMessageEnquiryModal = () => {
  modal.remove(MODAL_ID.MessageEnquiry);
};

const updateModal = ({ block = false } = {}) => {
  modal.updateOptions(MODAL_ID.MessageEnquiry, {
    closable: !block,
    closeOnOutsideClick: !block,
  });
};

const blockModal = () => updateModal({ block: true });

const unBlockModal = () => updateModal();

export const openMessageEnquiryModal = ({
  owner,
  title,
  displayAddress,
  picture,
  id,
  city,
  onFastTrackFindOutMoreClick,
  isUserWithFastTrack,
}) => {
  try {
    if (isUserWithFastTrack) {
      const MessageEnquiryWrapper = React.lazy(
        () => import("./message-enquiry-wrapper-mapping"),
      );
      modal.add(
        <Suspense fallback={<div />}>
          <MessageEnquiryWrapper
            blockStep={blockModal}
            onGotItClick={closeMessageEnquiryModal}
            owner={owner}
            roomAddress={displayAddress}
            roomCity={city}
            roomId={id}
            roomPicture={picture}
            roomTitle={title}
            unBlockStep={unBlockModal}
            title={translate("enquiry.fast-track.title")}
            description={translate("enquiry.fast-track.description")}
            buttonText={translate("enquiry.fast-track.send-button")}
            isFastTrack={true}
          />
        </Suspense>,
        {
          id: MODAL_ID.MessageEnquiry,
          type: MODAL_TYPE.LATERAL,
          onClose: () => closeMessageEnquiryModal(),
          mainClassName: "message_enquiry_modal",
        },
      );
    } else {
      const MessageEnquiryWrapper = React.lazy(
        () => import("./message-enquiry-wrapper-mapping"),
      );
      modal.add(
        <Suspense fallback={<div />}>
          <MessageEnquiryWrapper
            blockStep={blockModal}
            onGotItClick={closeMessageEnquiryModal}
            owner={owner}
            roomAddress={displayAddress}
            roomCity={city}
            roomId={id}
            roomPicture={picture}
            roomTitle={title}
            unBlockStep={unBlockModal}
            onFastTrackFindOutMoreClick={() => {
              onFastTrackFindOutMoreClick();
            }}
          />
        </Suspense>,
        {
          id: MODAL_ID.MessageEnquiry,
          type: MODAL_TYPE.LATERAL,
          onClose: () => closeMessageEnquiryModal(),
          mainClassName: "message_enquiry_modal",
        },
      );
    }
  } catch (e) {
    UserFeedback.exception("Something went wrong", e.message, e);
  }
};
