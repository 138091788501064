import React, { Fragment } from "react";
import { Row } from "reactstrap";

import LandingListItem from "./ListItem";
import "./ListExt";

const LandingList = ({ className, items, title, ...props }) => (
  <Fragment>
    {title && (
      <div className="LandingList__Title">
        <h3>{title}</h3>
      </div>
    )}
    <Row className="LandingList" tag="ul">
      {items.map((item) => (
        <LandingListItem key={item.id} {...item} {...props} />
      ))}
    </Row>
  </Fragment>
);

LandingList.defaultProps = {
  className: null,
  title: null,
};

export default LandingList;
