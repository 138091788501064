import createReducer from "base/shared/createReducer";

import Types from "./types";
import { SearchFiltersModel } from "./models";
import * as Utils from "./utils";

const reset = () => ({
  ...SearchFiltersModel,
});

const resetAvailability = (state) => {
  const { now, fromDate, stayOptions } = SearchFiltersModel;
  return {
    ...state,
    page: 1,
    token: null,
    now,
    fromDate,
    stayOptions,
    availabilityFilter: Utils.getAvailabilityFilter(SearchFiltersModel),
    updatedFilters: true,
  };
};

const resetSortBy = (state) => {
  const { sortBy } = SearchFiltersModel;
  return {
    ...state,
    page: 1,
    token: null,
    sortBy,
    sortFilter: Utils.getSortByFilter(SearchFiltersModel),
    updatedFilters: true,
  };
};

const resetBudget = (state) => {
  const { max, min } = SearchFiltersModel;
  return {
    ...state,
    page: 1,
    token: null,
    max,
    min,
    budgetFilter: Utils.getBudgetFilter(SearchFiltersModel),
    updatedFilters: true,
  };
};

const resetFlatmates = (state) => {
  const { tenants, genders } = SearchFiltersModel;
  return {
    ...state,
    page: 1,
    token: null,
    tenants,
    genders,
    flatmatesFilter: Utils.getFlatmatesFilter(SearchFiltersModel),
    updatedFilters: true,
  };
};

const resetRoom = (state) => {
  const { bookable, placeTypes, bedTypes, roomAmenities } = SearchFiltersModel;
  return {
    ...state,
    page: 1,
    token: null,
    bookable,
    placeTypes,
    bedTypes,
    roomAmenities,
    roomFilter: Utils.getRoomFilter(SearchFiltersModel),
    updatedFilters: true,
  };
};

const resetFlat = (state) => {
  const { flatAmenities, flatBenefits, houseRules } = SearchFiltersModel;
  return {
    ...state,
    page: 1,
    token: null,
    flatAmenities,
    flatBenefits,
    houseRules,
    flatFilter: Utils.getFlatFilter(SearchFiltersModel),
    updatedFilters: true,
    minFlatSize: 0,
    maxFlatSize: 0,
  };
};

const resetMarketplaceSegments = (state) => {
  return {
    ...state,
    marketplaceSegmentsFilter: {
      badiCommunity: false,
      badiRooms: false,
    },
  };
};

const setLocation = (state, action) => ({
  ...state,
  ...action.result,
  page: 1,
  token: null,
  updatedFilters: true,
});

const setBoundsRecalculation = (state, action) => {
  const { result: recalculateBounds } = action;
  return {
    ...state,
    recalculateBounds,
  };
};

const setBudget = (state, action) => ({
  ...state,
  ...action.result,
  page: 1,
  token: null,
  budgetFilter: Utils.getBudgetFilter(action.result),
  updatedFilters: true,
});

const setSortBy = (state, action) => ({
  ...state,
  ...action.result,
  page: 1,
  token: null,
  sortFilter: Utils.getSortByFilter(action.result),
  updatedFilters: true,
});

const setAvailability = (state, action) => {
  const { fromDate, stayOptions, now } = action.result;

  return {
    ...state,
    page: 1,
    token: null,
    fromDate,
    now,
    stayOptions,
    availabilityFilter: Utils.getAvailabilityFilter(action.result),
    updatedFilters: true,
  };
};

const setFlatmates = (state, action) => ({
  ...state,
  ...action.result,
  page: 1,
  token: null,
  flatmatesFilter: Utils.getFlatmatesFilter(action.result),
  updatedFilters: true,
});

const setFlat = (state, action) => ({
  ...state,
  ...action.result,
  page: 1,
  token: null,
  flatFilter: Utils.getFlatFilter(action.result),
  updatedFilters: true,
});

const setMarketplaceSegmentsFilters = (state, action) => {
  return {
    ...state,
    marketplaceSegmentsFilter: {
      badiCommunity: action.result.filters.badiCommunity,
      badiRooms: action.result.filters.badiRooms,
    },
  };
};

const setRoom = (state, action) => {
  let newState = { ...state };
  const { placeTypes } = action.result;
  const placeType = placeTypes.find((_placeType) => _placeType.selected) || {};

  if (placeType.id === 2) {
    const { tenants, genders } = SearchFiltersModel;
    newState = setFlatmates(newState, { result: { tenants, genders } });
  }

  return {
    ...newState,
    ...action.result,
    page: 1,
    token: null,
    roomFilter: Utils.getRoomFilter(action.result),
    updatedFilters: true,
  };
};

const setPage = (state, action) => {
  return { ...state, page: action.result };
};

const setToken = (state, action) => {
  return { ...state, previousToken: state.token, token: action.result };
};

const setParams = (state, action) => {
  const newState = { ...state, page: 1, token: null, ...action.result };
  const filters = Utils.getFilters(newState);

  return {
    ...newState,
    ...filters,
  };
};

const setUpdatedFilters = (state, action) => ({
  ...state,
  updatedFilters: action.result,
});

const actionHandlers = {
  [Types.RESET_SEARCHFILTERS]: reset,
  [Types.RESET_SEARCHFILTERS_AVAILABILITY]: resetAvailability,
  [Types.RESET_SEARCHFILTERS_SORT_BY]: resetSortBy,
  [Types.RESET_SEARCHFILTERS_BUDGET]: resetBudget,
  [Types.RESET_SEARCHFILTERS_FLATMATES]: resetFlatmates,
  [Types.RESET_SEARCHFILTERS_FLAT]: resetFlat,
  [Types.RESET_SEARCHFILTERS_ROOM]: resetRoom,
  [Types.RESET_SEARCHFILTERS_MARKETPLACE_SEGMENTS]: resetMarketplaceSegments,

  [Types.SET_SEARCHFILTERS_AVAILABILITY]: setAvailability,
  [Types.SET_SEARCHFILTERS_SORT_BY]: setSortBy,
  [Types.SET_SEARCHFILTERS_BUDGET]: setBudget,
  [Types.SET_SEARCHFILTERS_FLATMATES]: setFlatmates,
  [Types.SET_SEARCHFILTERS_FLAT]: setFlat,
  [Types.SET_SEARCHFILTERS_ROOM]: setRoom,
  [Types.SET_SEARCHFILTERS_LOCATION]: setLocation,
  [Types.SET_SEARCHFILTERS_PAGE]: setPage,
  [Types.SET_SEARCHFILTERS_PARAMS]: setParams,
  [Types.SET_SEARCHFILTERS_UPDATED_FILTERS]: setUpdatedFilters,
  [Types.SET_SEARCHFILTERS_MARKETPLACE_SEGMENTS]: setMarketplaceSegmentsFilters,
  [Types.SET_BOUNDS_RECALCULATION]: setBoundsRecalculation,
  [Types.SET_SEARCHFILTERS_TOKEN]: setToken,
};

export default createReducer(actionHandlers, { ...SearchFiltersModel });
