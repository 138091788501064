import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const Wrapper = styled.div`
  border: ${({ selected }) =>
    selected
      ? `2px solid ${BADI_COLORS.BRAND.PRIMARY.DEFAULT}`
      : `2px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05}`};
  outline: none;
  cursor: pointer;
  padding: 0 ${unitizedPx(2.5)};
  height: ${unitizedPx(13.5)};
  display: flex;
  align-items: center;
`;
