import React from "react";
import { connect } from "react-redux";

/**
 * A higher-order component to try to clarify rendering rules at the routing layer.
 * @function Ternary
 * @param {function} a redux selector
 * @param {React.FC} TrueComponent - the component to render if the selector returns true
 * @param {React.FC} FalseComponent - the component to render if the selector returns false
 */
const Ternary = (selector, TrueComponent, FalseComponent) => {
  const mapStateToProps = (state) => ({
    isTrue: selector(state),
  });
  const Component = (props) => {
    const { isTrue } = props;
    return isTrue ? (
      <TrueComponent {...props} />
    ) : (
      <FalseComponent {...props} />
    );
  };
  Component.displayName = `Ternary(${TrueComponent.displayName} || ${FalseComponent.displayName})`;
  return connect(mapStateToProps)(Component);
};

export default Ternary;
