import React from "react";
import { Icon, Spacer, Flex, Text } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";
import {
  getSocialMediaProfileHref,
  getSocialMediaProfileText,
} from "../../utils";
import { LinkWrapper } from "../styled-components";

const SocialProfile = ({ media, value, icon, profileUserId }) => (
  <Spacer data-qa={`social-profiles-${media}-section`} top={1}>
    <LinkWrapper href={getSocialMediaProfileHref(media, value)} target="_blank">
      <Flex gutter={1}>
        <Icon
          alt={media}
          iconMaxHeight={16}
          iconMaxWidth={16}
          src={getPublicAsset(icon)}
        />
        <Text body="14-Regular" dataQa={`social-profiles-${media}`}>
          {getSocialMediaProfileText(media, value)}
        </Text>
      </Flex>
    </LinkWrapper>
  </Spacer>
);

export default SocialProfile;
