import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "PAYMENT_REQUEST",
  "PAYMENT_SUCCESS",
  "PAYMENT_ERROR",
  "PAYMENTMETHOD_REQUEST",
  "PAYMENTMETHOD_SUCCESS",
  "PAYMENTMETHOD_ERROR",
  "RESET_PAYMENT",
]);
