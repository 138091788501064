import React, { useState } from "react";

import {
  Root,
  Header,
  Navigation,
  NavigationList,
  GhostOffset,
} from "./styled-components";

export const TopBarBase = ({
  children,
  dataQa = "top-bar",
  withNavigationWrapper = true,
}) => {
  return (
    <>
      <Root data-qa={dataQa} id="topbar" isFixed={true} offset={0}>
        <Header>
          {withNavigationWrapper ? (
            <Navigation>
              <NavigationList>{children}</NavigationList>
            </Navigation>
          ) : (
            children
          )}
        </Header>
      </Root>
      <GhostOffset />
    </>
  );
};

TopBarBase.defaultProps = {
  dataQa: "top-bar",
  isMobile: false,
  withNavigationWrapper: true,
};

export default TopBarBase;
