import React from "react";

import { BADI_COLORS, Flex, Text, Badge } from "@badi/badi-components";

import { CONNECTION_STATUS_LEVEL } from "datamodel/Conversation/constants";

import { getStatusColor } from "../utils";
import { InboxRowStatus } from "../styled-components";

const Status = ({
  hasNotifications,
  level = CONNECTION_STATUS_LEVEL.INFO,
  label = "",
  title = "",
  userAge,
  userName,
}) => {
  const textColor = hasNotifications
    ? BADI_COLORS.NEUTRAL.NEUTRAL_80
    : BADI_COLORS.NEUTRAL.NEUTRAL_50;
  const fontWeight = hasNotifications ? "bold" : "regular";

  return (
    <InboxRowStatus>
      <Text
        color={textColor}
        style={{ fontWeight }}
      >{`${userName}, ${userAge}`}</Text>
      <Flex
        alignItems="center"
        direction="row"
        gutter={1}
        md={{ alignItems: "flexStart", direction: "column", gutter: 0 }}
      >
        {title && (
          <Text body={2} color={textColor}>
            {title}
          </Text>
        )}
        {label && (
          <Badge
            backgroundColor={getStatusColor(level)}
            rounded={true}
            shrink={0}
          >
            {label}
          </Badge>
        )}
      </Flex>
    </InboxRowStatus>
  );
};

export default Status;
