import React from "react";
import {
  Text,
  Heading,
  Spacer,
  BADI_COLORS,
  Flex,
  Icon,
} from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";
import { Card, WidgetValue } from "../../styled-components";

const BehaviourCardWidget = ({
  dataQa = "behaviour-card-widget",
  subTitle = null,
  title,
  target = null,
  trend = null,
  value = null,
  emptyText = "",
  height = 23,
  iconSrc = null,
}) => (
  <Spacer top={2}>
    <Card height={height}>
      <Flex
        alignItems="center"
        direction="column"
        fullHeight={true}
        justify="center"
      >
        <Spacer bottom={subTitle ? 1 : 2}>
          <Heading align="center" data-qa={`${dataQa}-title`} level="16-Bold">
            {title}
          </Heading>
        </Spacer>
        {subTitle && (
          <Spacer bottom={2}>
            <Text align="center" body="12-Medium" dataQa={`${dataQa}-subTitle`}>
              {subTitle}
            </Text>
          </Spacer>
        )}

        {value ? (
          <>
            <Flex direction="row" gutter={1} justify="center">
              {iconSrc && (
                <Icon
                  alt="value icon"
                  data-qa={`${dataQa}-value-icon`}
                  iconMaxHeight={24}
                  iconMaxWidth={24}
                  src={getPublicAsset(iconSrc)}
                />
              )}
              <Text
                as={WidgetValue}
                color={value.color}
                dataQa={`${dataQa}-value`}
                inline={true}
              >
                {value.text}
              </Text>
            </Flex>
            {target && (
              <Spacer top={1}>
                <Text body={3} color={target.color} dataQa={`${dataQa}-target`}>
                  {target.text}
                </Text>
              </Spacer>
            )}
            {trend && (
              <Spacer top={0.5}>
                <Text body={5} color={trend.color} dataQa={`${dataQa}-trend`}>
                  {trend.text}
                </Text>
              </Spacer>
            )}
          </>
        ) : (
          <Text
            body={3}
            color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
            dataQa={`${dataQa}-emptyText`}
          >
            {emptyText}
          </Text>
        )}
      </Flex>
    </Card>
  </Spacer>
);

export default BehaviourCardWidget;
