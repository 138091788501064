import React from "react";
import { useSelector } from "react-redux";
import { Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getDeviceOsFromStore } from "datamodel/Device/selectors";
import { getLinks } from "base/shared/app-links";
import AppLink from "./app-link";

const AppLinks = () => {
  const os = useSelector(getDeviceOsFromStore);
  const links = getLinks(os);

  return (
    <Flex gutter={1}>
      {links.map(({ url, icon, description }) => (
        <AppLink description={description} icon={icon} key={icon} url={url} />
      ))}
    </Flex>
  );
};

export default AppLinks;
