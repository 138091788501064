import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const IconWrap = styled.div`
  min-width: 6px;
  min-height: ${unitizedPx(3)};
  position: relative;

  svg {
    display: block;
    position: absolute;
    top: -1px;
    left: -3.5px;
    height: ${unitizedPx(3)};
    width: 10.5px;
  }
`;

export const StyledBadge = styled.div`
  width: 22px;
  height: 22px;
`;
