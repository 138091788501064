import styled from "styled-components";
import { colors, unitizedPx } from "@badi/badi-components";

export const IconWrap = styled.div`
  text-align: center;
`;

export const RowWrap = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const StyledImage = styled.img`
  display: block;
  margin: 0 auto;
`;

export const Anchor = styled.a`
  display: inline-block;
  padding-left: ${unitizedPx(0.5)};
  color: inherit;
  text-decoration: underline;

  :hover {
    color: inherit;
  }
`;
