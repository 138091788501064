import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { BUTTON_TYPES, Button } from "@badi/badi-components";

import { getToken } from "datamodel/Auth/selectors";

import StripeApi from "base/shared/stripe-api";
import envVars from "base/shared/Env";
import { getBookingCheckoutSession } from "datamodel/stripe/api";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";

const BookingCheckoutButton = ({
  children,
  variant = BUTTON_TYPES.PRIMARY,
  dataQa = "",
  successUrl = null,
  cancelUrl = null,
  callback = null,
  bookingRequestId,
  fullWidth = false,
  grow = 1,
}) => {
  const [stripeApi, setStripeApi] = useState(null);
  const token = useSelector(getToken);

  useEffect(() => {
    setStripeApi(new StripeApi({ token: envVars().BADI_PAYMENT_TOKEN }));
  }, []);

  useEffect(() => {
    if (!stripeApi) return;
    stripeApi.init();
  }, [stripeApi]);

  const handleRedirectToCheckout = () => {
    getBookingCheckoutSession({
      token,
      bookingRequestId,
      successUrl,
      cancelUrl,
    })
      .then(({ data }) => {
        stripeApi.redirectToCheckout(data.stripe_checkout_session_id);
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        UserFeedback.exception(
          translate("Something went wrong, try again or contact support."),
        );
      });
  };

  return (
    <Button
      data-qa={dataQa}
      fullWidth={fullWidth}
      grow={grow}
      onClick={handleRedirectToCheckout}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default BookingCheckoutButton;
