import React from "react";
import LoadingSpinner from "app/components/Icons/LoadingSpinner";
import { translate } from "base/shared/Localization";
import { Button, BADI_COLORS } from "@badi/badi-components";

const TranslateButton = ({
  loadingDescriptionTranslation,
  onClick,
  showTranslation,
}) => {
  const translateButtonText = showTranslation
    ? translate("auto.translations_show.original")
    : translate("auto.translations_translate");

  return (
    <Button
      basis="70%"
      disabled={loadingDescriptionTranslation}
      fullWidth={true}
      onClick={onClick}
      variant="secondary"
    >
      {loadingDescriptionTranslation ? (
        <LoadingSpinner color={BADI_COLORS.NEUTRAL.NEUTRAL_80} />
      ) : (
        translateButtonText
      )}
    </Button>
  );
};

export default TranslateButton;
