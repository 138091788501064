import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";

import Favorites from "./favorites";

const mapStateToProps = (state) => ({
  token: getToken(state),
});

export default connect(mapStateToProps)(Favorites);
