import React from "react";
import { withRouter } from "react-router";
import { getInboxPath } from "Sections/inbox/routes";
import ConnectionDetails from "./connection-details";

const ConnectionDetailsRoute = ({ router, ...props }) => {
  const handleLeaveConnection = () => {
    router.push(getInboxPath());
  };

  return (
    <ConnectionDetails {...props} onLeaveConnection={handleLeaveConnection} />
  );
};

export default withRouter(ConnectionDetailsRoute);
