const ListerMetricsModel = {
  fetchingRooms: false,
  fetchedRooms: false,
  fetchingRoomDetails: false,
  fetchedRoomDetails: false,
  error: null,
  rooms: {},
  listerHints: [],
};

const setInitialState = (initialState) => {
  const state = initialState;

  if (!state || !state.ListerMetrics) {
    return {
      ...ListerMetricsModel,
      error: "There was an error in ListerMetricsModel.",
    };
  }
  return state;
};

export { ListerMetricsModel, setInitialState };
