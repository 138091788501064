import React from "react";
import LayoutModal from "../Layout";
import { Wrapper } from "./styled-components";

const CustomModal = ({ children, isClosed, outsideClickHandler, options }) => {
  const { onIconClose, overlayOpacity, outsideCloseIcon, backgroundColor } =
    options;

  return (
    <LayoutModal
      backgroundColor={backgroundColor}
      isClosed={isClosed}
      onIconClose={onIconClose}
      outsideClickHandler={outsideClickHandler}
      outsideCloseIcon={outsideCloseIcon}
      overlayOpacity={overlayOpacity}
      wrapper={Wrapper}
    >
      {children}
    </LayoutModal>
  );
};

CustomModal.defaultProps = {
  children: null,
  isClosed: false,
  options: {},
  outsideClickHandler: () => {},
};

export default CustomModal;
