import React from "react";
import { getPublicAsset } from "base/shared/Utils";
import { Text, Popover, Icon, unitizedPx } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { LocationPopoverWrapper } from "../styled-components";

const LocationSelectorPopover = () => (
  <LocationPopoverWrapper>
    <Popover data-qa="search-bar-availability-popover">
      <Icon
        alt={translate("search.home_error_tooltip")}
        dataQa="location-popover-icon"
        iconMaxHeight={unitizedPx(3)}
        iconMaxWidth={unitizedPx(3)}
        src={getPublicAsset("icons/error_icon_popover.svg")}
      />
      <Text body={3} dataQa="search-bar-availability-popover-description-field">
        {translate("search.home_error_tooltip")}
      </Text>
    </Popover>
  </LocationPopoverWrapper>
);

export default LocationSelectorPopover;
