import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useInView } from "react-intersection-observer";
import { Button, Text, Spacer, Flex, Heading } from "@badi/badi-components";
import { locale, translate } from "base/shared/Localization";
import InjectHtml from "components/inject-html";
import { Content, Background, TextWrapper, Wrapper } from "./styled-components";

const FindYourMatch = ({ isLoggedIn, onLearnMore, onLogin }) => {
  const [componentRef, isInView] = useInView({
    rootMargin: "200px 0px 0px 0px",
    triggerOnce: true,
  });

  const handleClick = () => {
    if (isLoggedIn) {
      onLearnMore();
    }
    onLogin();
  };

  return (
    <Wrapper ref={componentRef}>
      <Background language={locale()} visibleBackground={isInView} />
      <Content>
        <Container>
          <Row>
            <Col>
              <Flex
                direction="column"
                lg={{ alignItems: "flexEnd", direction: "column" }}
              >
                <div>
                  <Heading data-qa="find-your-match-title" level="24-Bold">
                    {translate("home.find_your_match.title")}
                  </Heading>
                  <Spacer bottom={2} top={2}>
                    <TextWrapper>
                      <Text body="16-Regular" dataQa="find-your-match-desc">
                        <InjectHtml>
                          {translate("home.find_your_match.desc")}
                        </InjectHtml>
                      </Text>
                    </TextWrapper>
                  </Spacer>
                  <Button dataQa="find-your-match-cta" onClick={handleClick}>
                    {translate(
                      isLoggedIn
                        ? "home.find_your_match.cta"
                        : "home.find_your_match.new_user.cta",
                    )}
                  </Button>
                </div>
              </Flex>
            </Col>
          </Row>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default FindYourMatch;
