import { createSelector } from "reselect";
import { PAYMENT_STATUS } from "./constants";

export const getPaymentDetails = (state) => state.Payment || {};

export const getPaymentEmail = createSelector(
  getPaymentDetails,
  (details) => details.email || "",
);

export const getPaymentPhone = createSelector(
  getPaymentDetails,
  (details) => details.phone || "",
);

export const getCreditCardLastDigits = createSelector(
  getPaymentDetails,
  (details) => details.lastDigits || "",
);

export const getPaymentError = createSelector(
  getPaymentDetails,
  (details) => details.error,
);

export const getPaymentStatus = createSelector(
  getPaymentDetails,
  ({ completed, fetching }) => {
    if (fetching) return PAYMENT_STATUS.FETCHING;
    return completed ? PAYMENT_STATUS.COMPLETED : PAYMENT_STATUS.INITIAL;
  },
);
