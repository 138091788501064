import { connect } from "react-redux";

import { getToken } from "datamodel/Auth/selectors";
import { setCountryAction } from "app/datamodel/CountryVat/actions";
import CountryVat from "./country-vat-form";

const mapStateToProps = (state) => ({
  token: getToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCountryVat: ({ countryCode, spainOverseas }) =>
    dispatch(setCountryAction({ countryCode, spainOverseas })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryVat);
