import { connect } from "react-redux";
import {
  getLoadedStatus,
  getContactEmail,
  getError,
  getContactVerification,
  getBankAccountLastDigits,
} from "datamodel/Payouts/selectors";
import { getUserConfirmation } from "app/datamodel/User/selectors";

import PayoutsActions from "datamodel/Payouts/actions";

import PayoutStep from "./PayoutStep";

export default connect(
  (state) => ({
    loaded: getLoadedStatus(state),
    verified:
      !!getBankAccountLastDigits(state) &&
      !!getContactVerification(state) &&
      getUserConfirmation(state, "email"),
    error: getError(state),
    email: getContactEmail(state),
  }),
  {
    getAccount: PayoutsActions.getAccount,
  },
)(PayoutStep);
