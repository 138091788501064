import {
  getEditRoomPath,
  getNewRoomPath,
  getRoomDetailPath,
} from "app/Sections/Room/routes";
import { getListingsPath } from "app/Sections/my-listings/routes";
import { getMyProfilePath } from "app/Sections/user-profile/routes";
import React from "react";
import ProfileSetup from "./ProfileSetupMapping";

const ProfileSetupRoute = ({ router, location }) => {
  const { destination = null } = location.query;

  const handleUnsuccessfulModeration = (roomId) => {
    router.push(getEditRoomPath({ id: roomId }));
  };

  const handleClosePublishModal = () => {
    router.push(getListingsPath());
  };

  const redirectToNewListing = () => {
    router.push(getNewRoomPath());
  };

  const redirectToMyProfile = () => {
    router.push(getMyProfilePath());
  };

  const redirectToRoomDetails = (roomId) => {
    router.push(getRoomDetailPath({ roomId }));
  };

  const redirectToDestination = () => {
    router.push(destination);
  };

  return (
    <ProfileSetup
      location={location}
      onClosePublishModal={handleClosePublishModal}
      onUnsuccessfulModeration={handleUnsuccessfulModeration}
      redirectToDestination={redirectToDestination}
      redirectToMyProfile={redirectToMyProfile}
      redirectToNewListing={redirectToNewListing}
      redirectToRoomDetails={redirectToRoomDetails}
    />
  );
};

export default ProfileSetupRoute;
