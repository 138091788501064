import FeatureFlag from "./feature-flag-model";

const featureFlag = new FeatureFlag().toJSON();

const FeaturesModel = {
  fetching: false,
  error: null,
  ...featureFlag,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Features) {
    return { ...FeaturesModel, error: "There was an error in FeaturesModel." };
  }
  return state;
}

export { FeaturesModel, setInitialState };
