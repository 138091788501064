import React, { useState, useEffect, useRef } from "react";

import { translate } from "base/shared/Localization";
import StepperLayout, { Step } from "components/Layout/stepper-layout";
import { publishDraft } from "datamodel/Room/api";
import { openRoomPublishedModal } from "components/published-listing";
import UserFeedback from "base/shared/user-feedback";
import ModerationSpinner from "Sections/Room/components/moderation-spinner";
import { isModerationSuccessful } from "app/datamodel/RoomList/utils";

import {
  CREATED_SUCCESS_LISTER,
  COMPLETE_SUCCESS_LISTER,
  CREATED_SUCCESS_SEEKER,
  COMPLETE_SUCCESS_SEEKER,
} from "./constants";
import PaymentSetupProfile from "./PaymentSetupProfileMapping";
import ProfileForm from "./ProfileFormMapping";
import FinishSetup from "./FinishSetup";

const ProfileSetup = (props) => {
  const {
    moderationResult,
    onClosePublishModal,
    onUnsuccessfulModeration,
    redirectToMyProfile,
    redirectToNewListing,
  } = props;
  const [finished, setFinished] = useState(false);
  const [complete, setCompleted] = useState(false);
  // using React.useEffect to be able to mock the initial state in tests
  const [waitForModeration, setWaitForModeration] = React.useState(false);
  const [steps, setSteps] = useState([]);

  const defaultStep = {
    id: "profile",
    render: ProfileForm,
    title: translate("booking.new.steps.moveinout.heading"),
  };
  const paymentStep = {
    id: "bank",
    render: PaymentSetupProfile,
    title: translate("booking_flow_step3_title"),
  };

  useEffect(() => {
    const {
      isAccountDetailEnabled,
      isPaymentDetailEnabled,
      isLister,
      isGhost,
    } = props;
    if (
      (isAccountDetailEnabled && (isLister || isGhost)) ||
      (isPaymentDetailEnabled && (!isLister || isGhost))
    ) {
      setSteps([defaultStep, paymentStep]);
    } else {
      setSteps([defaultStep]);
    }
  }, []);

  const handleClosePublishModal = () => {
    props.setLister(true);
    onClosePublishModal();
  };

  const moderationTimeoutId = useRef(null);

  const handleSuccessfulModeration = () => {
    const { draftId, resetRoomList } = props;
    setTimeout(() => {
      setWaitForModeration(false);
      handleClosePublishModal();

      openRoomPublishedModal({
        roomId: draftId,
      });
      resetRoomList();
    }, 300);
  };

  const handleUnsuccessfulModeration = () => {
    const { draftId } = props;
    onUnsuccessfulModeration(draftId);
  };

  const handleModerationUpdate = () => {
    if (waitForModeration) {
      clearTimeout(moderationTimeoutId.current);
      if (isModerationSuccessful(moderationResult)) {
        handleSuccessfulModeration();
      } else handleUnsuccessfulModeration();
    }
  };

  const moderationTimeout = () =>
    setTimeout(() => {
      handleSuccessfulModeration();
    }, 10000);

  useEffect(handleModerationUpdate, [moderationResult]);

  const redirectLastStep = () => {
    props.setInitialStep({ initialStep: 5 });
    redirectToNewListing();
  };

  const isSingleStep = steps.length === 1;

  const handleError = (error) => {
    setWaitForModeration(false);
    clearTimeout(moderationTimeoutId);
    UserFeedback.exception(
      "Whoops!",
      "Something went wrong, try again or contact support.",
      error,
    );
  };

  const handlePublishDraft = () => {
    const { getFeatures, token, draftId } = props;
    setWaitForModeration(true);
    moderationTimeoutId.current = moderationTimeout();
    publishDraft(token, draftId)
      .then(() => {
        getFeatures();
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const redirectToFinish = () => {
    const {
      location: { query },
      redirectToDestination,
    } = props;
    if (query.destination) {
      redirectToDestination();
      return;
    }
    if (query.publish) {
      handlePublishDraft();
      return;
    }
    if (query.list) {
      redirectToNewListing();
    } else {
      redirectToMyProfile();
    }
  };

  const handleComplete = () => {
    if (isSingleStep) {
      redirectToFinish();
      return;
    }
    setFinished(true);
    setCompleted(true);
  };

  const handleFinish = () => {
    setFinished(true);
  };

  const makeRedirectsOnLeaving = () => {
    const {
      location: { query },
      redirectToDestination,
    } = props;

    if (query.destination) {
      return redirectToDestination();
    }
    if (query.publish) {
      return redirectLastStep();
    }

    return redirectToFinish();
  };

  const handleCancelOrClose = (_, callback) => {
    if (callback) {
      callback(makeRedirectsOnLeaving);
    } else {
      makeRedirectsOnLeaving();
    }
  };

  const getFinishSetupData = () => {
    if (complete) {
      return props.isLister ? COMPLETE_SUCCESS_LISTER : COMPLETE_SUCCESS_SEEKER;
    }
    return props.isLister ? CREATED_SUCCESS_LISTER : CREATED_SUCCESS_SEEKER;
  };

  return (
    <>
      {waitForModeration && <ModerationSpinner />}
      {finished ? (
        <FinishSetup
          {...getFinishSetupData()}
          handleFinishFlow={redirectToFinish}
        />
      ) : (
        <StepperLayout
          onClose={handleCancelOrClose}
          onSubmit={handleCancelOrClose}
        >
          {steps.map(({ id, title, render: Render, footer: Footer }) => (
            <Step footer={Footer} id={id} key={id}>
              <Render
                handleLeaveForm={handleCancelOrClose}
                id={id}
                isSingleStep={isSingleStep}
                onComplete={handleComplete}
                onSkip={handleFinish}
                title={title}
                {...props}
              />
            </Step>
          ))}
        </StepperLayout>
      )}
    </>
  );
};

ProfileSetup.defaultProps = {
  moderationResult: null,
};

export default ProfileSetup;
