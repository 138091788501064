import styled from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.24);
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
`;
