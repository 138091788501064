import { connect } from "react-redux";
import { getToken, getUserId } from "datamodel/Auth/selectors";

import OverviewActions from "datamodel/Overview/actions";
import { isStandaloneBoostingSelector } from "datamodel/Features/selectors";

import RoomsOverview from "./rooms-overview";

export default connect(
  (state) => ({
    token: getToken(state),
    currentUserId: getUserId(state),
    roomsCards: state.Overview.roomsOverview.roomsCards,
    currentPage: state.Overview.roomsOverview.currentPage,
    isNextPageAvailable: state.Overview.roomsOverview.isNextPageAvailable,
    fetchingInitialOverviewCards:
      state.Overview.roomsOverview.fetchingInitialOverviewCards,
    fetchedInitialOverviewCards:
      state.Overview.roomsOverview.fetchedInitialOverviewCards,
    isStandaloneBoostingSelector: isStandaloneBoostingSelector(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      getInitialRoomsOverview: (params) =>
        dispatch(
          OverviewActions.getInitialRoomsOverview(stateProps.token, params),
        ),
      getNextRoomsOverview: (params) =>
        dispatch(
          OverviewActions.getNextRoomsOverview(stateProps.token, params),
        ),
    };
  },
)(RoomsOverview);
