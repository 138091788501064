import { createSelector } from "reselect";
import { SEARCHROOMS_STATUSES, CARD_TYPES } from "./static";

const getSearchRooms = (state) => state.SearchRoomsResult;

export const getNextTokenCards = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.nextTokenCards,
);

export const getSearchMetadata = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.metadata,
);

export const getPreviousTokenCards = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.previousTokenCards,
);

export const getAllCards = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.cards,
);

export const getRoomCards = createSelector(getSearchRooms, (searchRooms) =>
  searchRooms.cards.filter((card) => card.type === CARD_TYPES.ROOM_CARD),
);

export const getRoomCardsIds = createSelector(getSearchRooms, (searchRooms) =>
  searchRooms.cards
    .filter((card) => card.type === CARD_TYPES.ROOM_CARD)
    .map((roomCard) => roomCard.roomId),
);

export const getRoomCardsMarker = createSelector([getRoomCards], (roomCards) =>
  roomCards.map((card) => card.marker).filter((marker) => !!marker),
);

export const getMarkers = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.markers,
);

export const getSearchSelectedRoomId = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.selectedRoomId,
);

export const getSearchStatus = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.status,
);

export const getSearchFetchingStatus = createSelector(
  getSearchStatus,
  (status) => status === SEARCHROOMS_STATUSES.fetching,
);

export const getSearchSelectedCard = createSelector(
  [getRoomCards, getSearchSelectedRoomId],
  (cards, selectedRoomId) =>
    cards.find((card) => card.roomId === selectedRoomId),
);

export const isLoginRequired = createSelector(
  getSearchRooms,
  (searchRooms) => searchRooms.isLoginRequired,
);
