import styled from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const SearchModalWrapper = styled.div`
  > div {
    .geosuggest__input {
      padding: 20px 104px 20px 16px;
      background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
      border: 0;
      border-bottom: 2px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
      border-radius: 0;

      font-size: 1.06rem;
      font-weight: 500;
      line-height: 1.5;
      color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
    }

    .geosuggest__suggests {
      margin-top: 0;
      border: 0;
      padding-top: 8px;
      padding-bottom: 36px;

      max-height: initial;
      visibility: visible;
      overflow: initial;

      li {
        height: 64px;
        display: flex;
        align-items: center;

        > div > span {
          font-size: 14px;
          font-weight: 700;
        }
      }

      :after {
        position: absolute;
        right: 16px;
        bottom: 16px;
        background-image: url("${getCDNUrl()}/assets/images/powered_by_google_on_white_hdpi.png");
        background-size: 144px 18px;
        content: "";
        width: 144px;
        height: 18px;
      }
    }
  }
`;

export const CancelButton = styled.button`
  font-size: 0.93rem;
  font-weight: 500;
  border: 0;
  outline: none;
  cursor: pointer;
  margin: 0;
  text-align: center;
  text-decoration: none;
  padding: 0;
  background-color: transparent;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  position: absolute;
  right: 16px;
  top: 20px;
`;
