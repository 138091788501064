import React from "react";
import classNames from "classnames";
import "./stylesExt.css";

const RightArrow = (props) => {
  const { btnClass, currentSlide, slideCount, ...remainingProps } = props;
  const cName = classNames("RightArrow", btnClass || "");

  return <button type="button" {...remainingProps} className={cName} />;
};

export default RightArrow;
