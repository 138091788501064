import { VISIT_ACTIONS } from "datamodel/visits/constants";

const VISIT_ACTION_STATIC_PROPERTIES = {
  [VISIT_ACTIONS.RESCHEDULE]: {
    dataQa: "visit-action-reschedule",
  },
  [VISIT_ACTIONS.CANCEL]: {
    dataQa: "visit-action-cancel",
  },
  [VISIT_ACTIONS.CONFIRM]: {
    dataQa: "visit-action-confirm",
  },
  [VISIT_ACTIONS.VIDEO_VISIT]: {
    dataQa: "visit-action-join-call",
  },
};

export const getVisitActions = (actions) =>
  actions.map(({ action, label, type: variant }) => {
    const staticProp = VISIT_ACTION_STATIC_PROPERTIES[action];
    return { ...staticProp, label, id: action, variant };
  });
