import Types from "../types";

export default {
  resetNotifications() {
    return { type: Types.RESET_NOTIFICATIONS };
  },
  setNotifications(result) {
    return { type: Types.SET_NOTIFICATIONS, result };
  },
};
