import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MARGIN_VALUE = unitizedPx(1);

export const PhotoGridWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const PicturesWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const SecondaryPicturesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const StyledImage = styled(LazyLoadImage)`
  height: calc(50% - ${MARGIN_VALUE});
  width: calc(50% - ${MARGIN_VALUE});
  border-radius: ${MARGIN_VALUE};
  margin-top: ${MARGIN_VALUE};
  margin-left: ${MARGIN_VALUE};
  object-fit: cover;
  transition: opacity 1s linear;
  opacity: ${(props) => (props.loaded ? "1" : "0")};

  ${(props) =>
    props.total === 2 &&
    `
      width: calc(100% - ${MARGIN_VALUE});
  `}

  ${(props) =>
    props.total === 3 &&
    `
      :last-child {
        width: calc(100% - ${MARGIN_VALUE});
      }
  `};

  :only-child {
    width: calc(100% - ${MARGIN_VALUE});
    height: calc(100% - ${MARGIN_VALUE});
  }

  &:hover {
    -webkit-filter: brightness(70%);
    -webkit-transition: all 0.7s ease;
    -moz-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    -ms-transition: all 0.7s ease;
    transition: all 0.7s ease;
  }
`;
