import createReducer from "base/shared/createReducer";
import { getPicture } from "base/shared/Utils";
import { User } from "datamodel/User/models";
import Types from "../types";
import { UserModel } from "../models";
import { LEGAL_STATUS } from "../constants";

function meError(state, action) {
  return { ...state, fetching: false, error: action.error, fetched: false };
}

function meRequest(state) {
  return { ...state, fetching: true, error: null, fetched: false };
}

function meSuccess(state, action) {
  const user = new User(action.result).toJSON();

  return {
    ...state,
    ...user,
    error: null,
    fetching: false,
    fetched: true,
    lister: user.lister || UserModel.lister,
    mainPicture: getPicture(user.pictures, "width_100_url"),
    mode: state.mode === "default" && user.lister ? "lister" : state.mode,
  };
}

function legalError(state, action) {
  return {
    ...state,
    legalStatus: LEGAL_STATUS.LOADED,
    error: action.error,
  };
}

function legalRequest(state) {
  return { ...state, error: null, legalStatus: LEGAL_STATUS.LOADING };
}

function legalSuccess(state, action) {
  return { ...state, legal: action.result, legalStatus: LEGAL_STATUS.LOADED };
}

function onboardingRequest(state) {
  return { ...state, fetching: true, error: null, fetched: false };
}

function onboardingSuccess(state, action) {
  return {
    ...state,
    fetching: false,
    onboarding: action.result,
    fetched: true,
  };
}

function onboardingError(state, action) {
  return { ...state, fetching: false, error: action.error, fetched: false };
}

function resetMe() {
  return { ...UserModel };
}

function setConfirmation(state, action) {
  const confirmations = { ...state.confirmations };
  confirmations[action.result] = true;

  return { ...state, confirmations };
}

function setLister(state, action) {
  const lister = action.result;
  return { ...state, lister, mode: lister ? "lister" : "seeker" };
}

function setUserMode(state, action) {
  const { mode, isActiveSwitching } = action.result;

  return {
    ...state,
    mode: state.lister && mode === "default" ? "lister" : mode,
    isActiveSwitching,
  };
}

function updateLegalError(state, action) {
  return {
    ...state,
    fetching: false,
    error: action.error,
    legalStatus: LEGAL_STATUS.LOADED,
  };
}

function updateLegalRequest(state) {
  return {
    ...state,
    fetching: true,
    error: null,
    legalStatus: LEGAL_STATUS.LOADING,
  };
}

function updateLegalSuccess(state, action) {
  return {
    ...state,
    fetching: false,
    legal: action.result,
    legalStatus: LEGAL_STATUS.LOADED,
  };
}

function updateOnboardingError(state, action) {
  return { ...state, fetching: false, error: action.error };
}

function updateOnboardingRequest(state) {
  return { ...state, fetching: true, error: null };
}

function updateOnboardingSuccess(state) {
  return { ...state, fetching: false, onboarding: { type: null } };
}

function updateProfile(state) {
  return state;
}

function listerScoreRequest(state) {
  return { ...state, fetching: true, error: null };
}

function listerScoreSuccess(state, action) {
  const { lister_score: listerScore } = action.result;

  return {
    ...state,
    listerScore,
  };
}

function listerScoreError(state, action) {
  return { ...state, fetching: false, error: action.error };
}

const actionHandlers = {
  [Types.ME_ERROR]: meError,
  [Types.ME_REQUEST]: meRequest,
  [Types.ME_SUCCESS]: meSuccess,

  [Types.LEGAL_ERROR]: legalError,
  [Types.LEGAL_REQUEST]: legalRequest,
  [Types.LEGAL_SUCCESS]: legalSuccess,

  [Types.LEGALUPDATE_ERROR]: updateLegalError,
  [Types.LEGALUPDATE_REQUEST]: updateLegalRequest,
  [Types.LEGALUPDATE_SUCCESS]: updateLegalSuccess,

  [Types.ONBOARDING_ERROR]: onboardingError,
  [Types.ONBOARDING_REQUEST]: onboardingRequest,
  [Types.ONBOARDING_SUCCESS]: onboardingSuccess,

  [Types.ONBOARDING_UPDATE_ERROR]: updateOnboardingError,
  [Types.ONBOARDING_UPDATE_REQUEST]: updateOnboardingRequest,
  [Types.ONBOARDING_UPDATE_SUCCESS]: updateOnboardingSuccess,

  [Types.SET_CONFIRMATION]: setConfirmation,
  [Types.SET_LISTER]: setLister,
  [Types.SET_USERMODE]: setUserMode,
  [Types.RESET_ME]: resetMe,

  [Types.SAVE_PROFILE_REQUEST]: meRequest,
  [Types.SAVE_PROFILE_SUCCESS]: meSuccess,
  [Types.SAVE_PROFILE_ERROR]: meError,

  [Types.UPDATE_PROFILE_SUCCESS]: updateProfile,
  [Types.UPDATE_PROFILE_ERROR]: updateProfile,

  [Types.LISTER_SCORE_REQUEST]: listerScoreRequest,
  [Types.LISTER_SCORE_SUCCESS]: listerScoreSuccess,
  [Types.LISTER_SCORE_ERROR]: listerScoreError,
};

export default createReducer(actionHandlers, { ...UserModel });
