import { connect } from "react-redux";
import Actions from "datamodel/SearchFilters/actions";

import Form from "./Form";

export default connect(
  (state) => ({
    ...state.SearchFilters,
  }),
  (dispatch) => ({
    setParams: (searchFilters) =>
      dispatch(
        Actions.setParams({
          searchFilters: { ...searchFilters, updatedFilters: true },
        }),
      ),
  }),
)(Form);
