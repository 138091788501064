import React from "react";
import { translate } from "base/shared/Localization";

import { getInboxPath } from "Sections/inbox/routes";
import { getHomePath } from "Sections/Home/routes";
import { getNewRoomPath } from "Sections/Room/routes";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import { getFavoritesPath } from "Sections/favorites/routes";
import { getMyProfilePath } from "Sections/user-profile/routes";
import { getSeekerPreferencesPath } from "Sections/seeker-preferences/routes";
import { getSettingsPath } from "Sections/Settings/routes";

import Menu, {
  MenuItemButton,
  MenuItemLink,
  MenuItemSeparator,
} from "../reponsive-menu";

const SeekerMenu = ({
  logout,
  isLister,
  messages,
  onSwitchUser,
  onListRoomClick,
}) => (
  <Menu>
    <MenuItemLink dataQa="topbar-seeker-menu-home" to={getHomePath()}>
      {translate("topbar.element.home.title")}
    </MenuItemLink>
    <MenuItemLink
      dataQa="topbar-seeker-menu-list-rent"
      onClick={onListRoomClick}
      to={isLister ? getNewRoomPath() : getRentRoomPath()}
    >
      {translate("topbar.element.rentroom.title")}
    </MenuItemLink>
    <MenuItemLink
      dataQa="topbar-seeker-menu-inbox"
      highlight={messages}
      to={getInboxPath()}
    >
      {translate("topbar.element.inbox.title")}
    </MenuItemLink>
    <MenuItemSeparator />
    <MenuItemLink dataQa="topbar-seeker-menu-profile" to={getMyProfilePath()}>
      {translate("topbar.element.profile.title")}
    </MenuItemLink>
    <MenuItemLink dataQa="topbar-seeker-menu-settings" to={getSettingsPath()}>
      {translate("topbar.element.settings.title")}
    </MenuItemLink>
    <MenuItemLink
      dataQa="topbar-seeker-menu-preferences"
      to={getSeekerPreferencesPath()}
    >
      {translate("topbar.element.seekerPreferences.title")}
    </MenuItemLink>
    <MenuItemLink dataQa="topbar-seeker-menu-favorites" to={getFavoritesPath()}>
      {translate("topbar.element.favorite.title")}
    </MenuItemLink>
    <MenuItemSeparator />
    {isLister && (
      <MenuItemButton
        dataQa="topbar-seeker-menu-switch"
        imageSrc="/assets/icons/switch.svg"
        onClick={onSwitchUser}
      >
        {translate("topbar.element.switchrenter.title")}
      </MenuItemButton>
    )}
    <MenuItemLink
      dataQa="topbar-seeker-menu-download"
      external={true}
      to="https://app.adjust.com/drpyca?campaign=mobileweb_menu&adgroup=link&creative=link_1"
    >
      {translate("topbar.element.download.title")}
    </MenuItemLink>
    <MenuItemLink
      dataQa="topbar-seeker-menu-help"
      to={translate("sitemap.faqs.new.link")}
    >
      {translate("topbar.element.help.title")}
    </MenuItemLink>
    <MenuItemButton dataQa="topbar-seeker-menu-logout" onClick={logout}>
      {translate("topbar.element.logout.title")}
    </MenuItemButton>
  </Menu>
);

export default SeekerMenu;
