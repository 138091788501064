import React from "react";
import { modal } from "containers/ReduxModal/emitter";
import { v4 as uuidv4 } from "uuid";
import EditRoom from "./edit-room-route";

const modalUuid = uuidv4();

export const closeEditRoomModal = () => {
  modal.remove(modalUuid);
};

export const openEditRoomModal = ({ id }) => {
  const handleSubmit = () => {
    closeEditRoomModal();
  };

  modal.add(<EditRoom id={id} onSubmit={handleSubmit} />, {
    id: modalUuid,
    mainClassName: "popup__main_thin",
  });
};
