import React from "react";
import classNames from "classnames";

const SelectMultipleItem = ({
  onClick,
  label,
  selected,
  value,
  dataQa = "",
}) => (
  <button
    aria-selected={selected}
    className={classNames("Form__SelectMultiple--option", {
      "Form__SelectMultiple--option-selected": selected,
    })}
    data-qa={dataQa}
    key={value}
    onClick={onClick}
    role="option"
    type="button"
    value={value}
  >
    {label}
  </button>
);

export default SelectMultipleItem;
