export default class ReferralAccount {
  constructor(data) {
    this.entity = data || {};
  }

  get id() {
    return this.entity.id;
  }

  get code() {
    return this.entity.code;
  }

  get deactivated() {
    return !!this.entity.deactivated;
  }

  get earnedCredit() {
    return this.entity.earned_credit || "-";
  }

  get redeemedCredit() {
    return this.entity.redeemed_credit || "-";
  }

  get availableCredit() {
    return this.entity.available_credit || "-";
  }

  toJSON() {
    return {
      id: this.id,
      code: this.code,
      deactivated: this.deactivated,
      earnedCredit: this.earnedCredit,
      redeemedCredit: this.redeemedCredit,
      availableCredit: this.availableCredit,
    };
  }
}
