import styled from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const MapWrapper = styled.div`
  display: flex;
  justify-content: center;

  height: 345px;
  margin-bottom: 100px;

  ${mediaquery.lg`
    height: calc(100vh - 212px);
    margin-bottom: 0;
   `}
`;
