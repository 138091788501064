import {
  PLACE_TYPES,
  LIST,
  AMENITIES,
} from "Sections/Room/components/Steps/Property/constants";

export const getAmenities = ({ amenityType, propertyType }) =>
  AMENITIES.filter((amenity) =>
    LIST[amenityType][propertyType].includes(amenity.value),
  );

export const getPropertyType = (isEntireProperty) =>
  isEntireProperty ? PLACE_TYPES.ENTIRE_PLACE : PLACE_TYPES.ROOM;
