import { parseUiElement, parseExtraInfo, parseBackCover } from "../utils";
import LabelModel from "datamodel/Room/models/label-model";

class RoomCardModel {
  constructor(data) {
    this.data = data;
    this.room = data.attributes || {};
  }

  get type() {
    return this.data.type;
  }

  get roomId() {
    return this.room.room_id || null;
  }

  get marker() {
    if (!this.room.marker) return null;
    const { label, ...coordinates } = this.room.marker;
    return { label: parseUiElement(label), coordinates, id: this.room.room_id };
  }

  get headline() {
    return this.room.headline ? parseUiElement(this.room.headline) : null;
  }

  get subheading() {
    return this.room.subheading ? parseUiElement(this.room.subheading) : null;
  }

  get labels() {
    return (this.room.labels || []).map((label) => new LabelModel(label));
  }

  get extraInfo() {
    return this.room.extra_info && this.room.extra_info.length
      ? parseExtraInfo(this.room.extra_info)
      : null;
  }

  get lister() {
    if (!this.room.lister) return null;
    const { label, ...rest } = this.room.lister;
    return { ...rest, label: parseUiElement(label) };
  }

  get pictures() {
    if (!this.room.pictures) return {};
    const { items, back_cover: backCover } = this.room.pictures;

    const itemsParsed = items.map((item) => item.url);
    return { urls: itemsParsed, backCover: parseBackCover(backCover) };
  }

  toJSON() {
    return {
      roomId: this.roomId,
      type: this.type,
      marker: this.marker,
      headline: this.headline,
      subheading: this.subheading,
      labels: this.labels.map((label) => label.toJSON()),
      extraInfo: this.extraInfo,
      lister: this.lister,
      pictures: this.pictures,
    };
  }
}

export default RoomCardModel;
