import { connect } from "react-redux";
import { getToken, getUserId } from "datamodel/Auth/selectors";
import { matchingModelSelector } from "datamodel/Features/selectors";
import Enquiry from "./enquiry";

const mapStateToProps = (state) => ({
  token: getToken(state),
  userId: getUserId(state),
  matchingModelEnabled: matchingModelSelector(state),
});
export default connect(mapStateToProps)(Enquiry);
