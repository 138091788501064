import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import AvailabilityForm from "./availability-form";

export default connect(
  (state) => ({
    now: state.SearchFilters.now,
    fromDate: state.SearchFilters.fromDate,
    stayOptions: state.SearchFilters.stayOptions,
  }),
  (dispatch) => ({
    setAvailability: (now, fromDate, stayOptions) =>
      dispatch(Actions.setAvailability({ now, fromDate, stayOptions })),
  }),
)(AvailabilityForm);
