import React from "react";
import { withFormsy } from "formsy-react";
import GeoSuggest from "components/geo-suggest";
import { GeosuggestWrapper } from "./styled-components";

class GeoSuggestWithFormsy extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.value !== nextProps.value ||
      this.props.disabled !== nextProps.disabled ||
      this.props.latitude !== nextProps.latitude ||
      this.props.longitude !== nextProps.longitude
    ) {
      return true;
    }
    return false;
  }

  onSuggestSelect = (data) => {
    if (this.props.onSuggestSelect) this.props.onSuggestSelect();
    this.props.setValue(data);
  };

  render() {
    const { placeDetailFields, onSuggestSelect, setValue, value, ...rest } =
      this.props;

    return (
      <GeosuggestWrapper>
        <GeoSuggest
          autoActivateFirstSuggest={true}
          extendedWidth={false}
          initialValue={value}
          onSuggestSelect={this.onSuggestSelect}
          placeDetailFields={placeDetailFields}
          {...rest}
        />
      </GeosuggestWrapper>
    );
  }
}

GeoSuggestWithFormsy.defaultProps = {
  disabled: false,
  inputClassName: "",
  latitude: 0,
  location: null,
  longitude: 0,
  onSuggestSelect: () => {},
  placeDetailFields: ["formatted_address", "address_components", "types"],
  placeholder: "",
  queryDelay: 500,
  radius: 0,
  types: undefined,
};

export default withFormsy(GeoSuggestWithFormsy);
