import React, { useCallback } from "react";
import { Button, Flex, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import UserProfileModal from "components/other-user-profile-modal";

import {
  ModalRoot,
  MainContentWrapper,
  ButtonWrapper,
} from "../styled-components";

const ViewerUserModal = ({ user, inviteViewer, onClose, contacted }) => {
  const { id: userId } = user;

  const handleInviteViewer = useCallback(() => {
    inviteViewer(userId);
    onClose();
  }, [userId]);

  return (
    <ModalRoot>
      <MainContentWrapper>
        <UserProfileModal user={user} />
      </MainContentWrapper>
      <ButtonWrapper>
        <Spacer bottom={1} left={2} right={2} top={1}>
          <Flex>
            <Button
              disabled={contacted}
              fullWidth={true}
              grow={1}
              onClick={handleInviteViewer}
            >
              {contacted
                ? translate("viewers.screen_cta_contacted")
                : translate("viewers.screen_cta_contact")}
            </Button>
          </Flex>
        </Spacer>
      </ButtonWrapper>
    </ModalRoot>
  );
};

export default ViewerUserModal;
