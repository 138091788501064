import React from "react";

import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { getModalConfig } from "./utils";
import { LEAVE_CONNECTION_MODAL_TYPE } from "./constants";

const closeLeaveConnectionConfirmModal = () => {
  modal.remove(MODAL_ID.LEAVE_CONNECTION);
};

export const openLeaveConnectionConfirmModal = ({
  connectionId,
  onLeave,
  user = null,
  type = LEAVE_CONNECTION_MODAL_TYPE.CONVERSATION,
}) => {
  const { modalType, ModalComponent } = getModalConfig(type);

  modal.add(
    <ModalComponent
      connectionId={connectionId}
      onCancel={closeLeaveConnectionConfirmModal}
      onLeave={() => {
        closeLeaveConnectionConfirmModal();
        onLeave();
      }}
    />,
    {
      id: MODAL_ID.LEAVE_CONNECTION,
      type: modalType,
      avatar: user?.avatar,
    },
  );
};
