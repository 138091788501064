import React from "react";
import {
  BADI_COLORS,
  BREAKPOINTS_PX,
  MatchMedia,
  Text,
  Spacer,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { InboxRowPreview, TextWithEllipsis } from "../styled-components";

const Preview = ({
  billsIncluded,
  hasNotifications,
  lastMessagePreview,
  roomPrice,
  roomTitle,
  externalAgencyId = "",
}) => {
  const textColor = hasNotifications
    ? BADI_COLORS.NEUTRAL.NEUTRAL_80
    : BADI_COLORS.NEUTRAL.NEUTRAL_50;
  const fontWeight = hasNotifications ? "bold" : "regular";
  const billsIncludedText = billsIncluded
    ? "bills.included"
    : "bills.not_included";

  return (
    <InboxRowPreview>
      <TextWithEllipsis color={textColor} style={{ fontWeight }}>
        {lastMessagePreview}
      </TextWithEllipsis>
      <Text body={3} color={textColor} dataQa="inbox-row-title">
        {roomTitle}
      </Text>
      <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.md})`}>
        <Text body={2} color={textColor} dataQa="inbox-row-price" inline={true}>
          {roomPrice}
        </Text>
        <Text
          body={3}
          color={textColor}
          dataQa="inbox-row-bills-included"
          inline={true}
        >
          {` · ${translate(billsIncludedText)}`}
        </Text>
        {externalAgencyId && (
          <Spacer top={1}>
            <Text
              body="12-Regular"
              color={textColor}
              data-qa="inbox-row-agency-listing-id"
            >
              {`${translate(
                "conversation.room.externalAgencyId",
              )}: ${externalAgencyId}`}
            </Text>
          </Spacer>
        )}
      </MatchMedia>
    </InboxRowPreview>
  );
};

export default Preview;
