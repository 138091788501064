import React from "react";
import classNames from "classnames";

import { getContentClass, getPositionClass, getSizeClass } from "./utils";
import "./LayerExt.css";

const Layer = ({ children, className, content, position, size }) => (
  <div
    className={classNames(
      getSizeClass(size),
      getPositionClass(position),
      className,
    )}
  >
    <div className={getContentClass(content)}>{children}</div>
  </div>
);

Layer.defaultProps = {
  size: "full",
  className: null,
  content: "center",
  position: null,
};

export default Layer;
