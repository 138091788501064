import { connect } from "react-redux";

import AuthActions from "datamodel/Auth/actions";
import Support from "./Support";

export default connect(
  (state) => ({
    loginType: state.User.loginType,
    mode: state.User.mode === "lister" ? "lister" : "seeker",
    token: state.Auth.access_token,
  }),
  (dispatch) => ({
    logout: () =>
      dispatch(AuthActions.logout({ stopPushNotifications: false })),
  }),
)(Support);
