import React from "react";
import { Flex, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Input from "components/form-text-input";

const routingValidation = (value) => /^\d{9}$/g.test(value);

const ROUTING_VALIDATIONS = [routingValidation];

const RoutingNumber = ({ disabled, name, onChange, onValidation }) => {
  return (
    <Flex direction="column" gutter={1}>
      <Text body={2}>{translate("payout.bank_account.routing_number")}</Text>
      <Input
        disabled={disabled}
        grow={1}
        name={name}
        onChange={onChange}
        onInputValidation={onValidation}
        placeholder={translate("payout.bank_account.routing.placeholder")}
        required={true}
        validations={ROUTING_VALIDATIONS}
      />
    </Flex>
  );
};

export default RoutingNumber;
