export function parseTransaction(transaction) {
  return {
    amount: transaction.amount,
    title: transaction.title,
    bookingPaidOut: transaction.booking_paid_out,
    bookingRequestAccepted: transaction.booking_request_accepted,
    bookingRequestSent: transaction.booking_request_sent,
    seekerMovedIn: transaction.seeker_moved_in,
  };
}

export function parseTransactions(transactions) {
  return transactions.map((transaction) => parseTransaction(transaction));
}

export function parsePagination(data) {
  const { current_page: currentPage, total_pages: totalPages, total } = data;

  return {
    currentPage,
    totalPages,
    total,
  };
}
