import React from "react";
import { useSelector } from "react-redux";

import {
  BREAKPOINTS_PX,
  BUTTON_TYPES,
  LinkButton,
  useMatchMedia,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import { getIsDesktop } from "datamodel/Device/selectors";

import TopBar from "../top-bar-base";
import Element from "../topbar-element";
import Menu from "./menu";
import Logo from "../Logo/Logo";
import SearchBar from "../search-bar";
import Search from "../Search";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.lg})`];

const PublicTopBar = ({
  city = null,
  loginHandler,
  registerHandler,
  withNavigationWrapper = true,
}) => {
  const desktopDevice = useSelector(getIsDesktop);
  const [isDesktop] = useMatchMedia(QUERIES, [desktopDevice]);

  return (
    <TopBar
      dataQa="topbar-public"
      withNavigationWrapper={withNavigationWrapper}
    >
      {isDesktop ? (
        <>
          <Logo dataQa="topbar-logo" />
          <SearchBar dataQa="topbar-search-bar" initialValue={city} />
          <Element dataQa="lister-info" position="right">
            <LinkButton
              data-qa="lister-info-button"
              href={getRentRoomPath()}
              variant={BUTTON_TYPES.SECONDARY}
            >
              {translate("topbar.element.listerinfo")}
            </LinkButton>
          </Element>
          <Element
            dataQa="topbar-public-menu-register"
            onClick={registerHandler}
            position="right"
          >
            {translate("topbar.element.signup.title")}
          </Element>
          <Element
            dataQa="topbar-public-menu-login"
            onClick={loginHandler}
            position="right"
          >
            {translate("topbar.element.signin.title")}
          </Element>
        </>
      ) : (
        <>
          <Menu onLogin={loginHandler} onRegister={registerHandler} />
          <Logo dataQa="topbar-logo" />
          <Search dataQa="topbar-search-button" initialValue={city} />
        </>
      )}
    </TopBar>
  );
};

export default PublicTopBar;
