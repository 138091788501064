import React from "react";
import { colors, Text } from "@badi/badi-components";
import { MessageTip } from "./styled-components";

const MapTip = ({ text }) => (
  <MessageTip>
    <Text
      body="12-Medium"
      color={colors.midGrey}
      data-qa="map-tip"
      inline={true}
    >
      {text}
    </Text>
  </MessageTip>
);

export default MapTip;
