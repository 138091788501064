import React from "react";
import { BUTTON_TYPES, BUTTON_SIZES } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Arrow from "components/Icons/arrow-v2";
import { ArrowWrapper, StyledButton, TextWrapper } from "./styled-components";

const GoTopButton = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <StyledButton
      dataQa="go-top-button"
      onClick={handleClick}
      size={BUTTON_SIZES.SMALL}
      variant={BUTTON_TYPES.SECONDARY}
    >
      <ArrowWrapper>
        <Arrow direction="up" />
      </ArrowWrapper>
      <TextWrapper>{translate("pro.overview_back.to.top.cta")}</TextWrapper>
    </StyledButton>
  );
};

export default GoTopButton;
