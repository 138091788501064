import React from "react";

import { withRouter } from "react-router";
import { getEditRoomPath } from "Sections/Room/routes";
import EditRoom from "./edit-room";

const EditRoomRoute = ({ router, id, onSubmit, ...rest }) => {
  const handleEditRoom = () => {
    router.push(getEditRoomPath({ id, publish: true }));
    onSubmit();
  };

  return <EditRoom onEditRoom={handleEditRoom} {...rest} />;
};

export default withRouter(EditRoomRoute);
