import React from "react";

import { translate } from "base/shared/Localization";
import Button from "components/Button/Button";
import Image from "components/Image/Image";

const DropZoneEmpty = ({ onClick, sectionName }) => (
  <div className="DropZone__Empty">
    <Image alt="" src="/assets/icons/icon_photo_upload.svg" />
    <h3 className="DropZone__Empty--title">
      {translate("dropzone.empty.title")}
    </h3>
    <p id="description_desktop">
      {translate("dropzone.empty.description_desktop")}
      &nbsp;
      <Button
        config={{ link: true }}
        onClick={onClick}
        text={translate("dropzone.empty.button")}
      />
    </p>
    <p id="description_desktop">
      {sectionName === "user"
        ? translate("dropzone.empty.long_description")
        : translate("dropzone.empty.long_description_room")}
    </p>

    <p id="description_mobile">
      {sectionName === "user"
        ? translate("dropzone.empty.description_mobile")
        : translate("dropzone.empty.description_mobile_room")}
    </p>
  </div>
);

DropZoneEmpty.defaultProps = {
  sectionName: "",
};

export default DropZoneEmpty;
