import { connect } from "react-redux";

import PayoutsActions from "datamodel/Payouts/actions";

import MyProfile from "./my-profile";

export default connect(
  (state) => ({
    authtoken: state.Auth.access_token,
    user: state.User,
    missingData: state.Payouts.missingData,
  }),
  {
    getAccount: PayoutsActions.getAccount,
  },
)(MyProfile);
