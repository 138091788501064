import { PREMIUM_FEATURES } from "datamodel/premium/constants";
import { getRecommendationsPath } from "Sections/recommendations/routes";
import { getMyListingsPath } from "Sections/Room/routes";
import { getViewersPath } from "Sections/viewers/routes";

export const PAID_LISTING = "publish_room";
export const PLUS_FEATURES_PATHS = new Map([
  [PAID_LISTING, getMyListingsPath],
  [PREMIUM_FEATURES.RECOMMENDED_SEEKERS, getRecommendationsPath],
  [PREMIUM_FEATURES.ROOM_VIEWERS, getViewersPath],
]);
