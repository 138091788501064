import React from "react";

import { getRelativePath } from "base/shared/Utils";

const CreateRoom = ({ router }) => {
  router.push(getRelativePath("/list/new"));
  return null;
};

export default CreateRoom;
