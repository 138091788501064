import React from "react";

import LoadingSpinner from "components/Icons/LoadingSpinner";
import Image from "components/Image/Image";

import { getButtonClass } from "./utils";

import "./ButtonExt.css";

const defaultButtonConfig = {
  color: "green",
  focused: false,
  imgUrl: null,
  light: false,
  link: false,
  loading: false,
  restrain: false,
  size: null,
  underline: false,
};

class Button extends React.PureComponent {
  componentDidMount() {
    if (this.props.config.focused) this.button.focus();
  }

  handleClick = (event) => {
    const { loading, disabled, onClick } = this.props;

    if (!!onClick && !disabled && !loading) onClick(event);
  };

  render() {
    const {
      className,
      config,
      config: { imgUrl },
      disabled,
      loading,
      text,
      textId,
      dataQa,
      ...rest
    } = this.props;

    const buttonClass = getButtonClass({
      className,
      ...defaultButtonConfig,
      ...config,
      disabled,
    });
    const buttonProps = { className: buttonClass, disabled, ...rest };

    return (
      <button
        {...buttonProps}
        data-qa={dataQa}
        onClick={this.handleClick}
        ref={(btn) => {
          this.button = btn;
        }}
      >
        {loading && <LoadingSpinner />}

        {!loading && imgUrl && (
          <Image alt="" className="Button__icon" src={imgUrl} />
        )}

        {!loading && !!text && (
          <span className="Button__text" id={textId}>
            {text}
          </span>
        )}
      </button>
    );
  }
}

Button.defaultProps = {
  className: "",
  config: defaultButtonConfig,
  dataQa: null,
  disabled: false,
  loading: false,
  onClick: null,
  text: null,
  textId: null,
};

export default Button;
