import PlansInfo from "./plans-info-model";

const plansInfo = new PlansInfo().toJSON();

const MyListingsModel = {
  currentPage: 1,
  error: null,
  fetchedInitialListingsByStatus: false,
  fetchedSummary: false,
  fetchingInitialListingsByStatus: false,
  focusState: 0,
  plansInfo,
  rooms: [],
  statusesCounters: [],
  selectedListingsIds: [],
  totalPages: 0,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.MyListings) {
    return {
      ...MyListingsModel,
      error: "There was an error in MyListingsModel.",
    };
  }
  return state;
}

export { MyListingsModel, setInitialState };
