import { getPicture } from "base/shared/Utils";
import {
  getGender,
  getUserLanguages,
  getYearsOld,
  getVerificationLevel,
  getUserOccupation,
} from "base/shared/User";

const parseRecommendation = (recommendation) => {
  const {
    id,
    badges,
    birth_date: birthDate,
    pictures,
    sorted_by_affinity_languages: languages,
    first_name: firstName,
    occupation_detail: occupation,
    biological_sex: gender,
  } = recommendation;

  return {
    age: getYearsOld(birthDate),
    avatar: pictures.length ? getPicture(pictures, "width_100_url") : "",
    badge: getVerificationLevel(badges),
    id,
    gender: getGender(gender),
    languages: getUserLanguages(languages),
    name: firstName,
    occupation: getUserOccupation(occupation),
  };
};

const makeRecommendationSet = (recommendations, id) =>
  recommendations.map((recommendation) => {
    return {
      ...recommendation,
      hidden: recommendation.id === id || recommendation.hidden,
    };
  });

const parseRecommendationsArray = (recommendations) =>
  recommendations.map(parseRecommendation);

export {
  parseRecommendation,
  parseRecommendationsArray,
  makeRecommendationSet,
};
