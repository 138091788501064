import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";

import SearchModal from "./search-modal";

const mapDispatchToProps = (dispatch) => ({
  setLocation: (location) => {
    dispatch(Actions.setLocation(location));
  },
  setParams: (searchFilters, searchUpdate) => {
    dispatch(Actions.setParams({ searchFilters, searchUpdate }));
  },
});

export default connect(null, mapDispatchToProps)(SearchModal);
