import React from "react";
import { getPublicAsset } from "base/shared/Utils";
import { Text, Popover, Icon, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { PopoverWrapper } from "./styled-components";

const MoveOutPopover = () => (
  <PopoverWrapper>
    <Popover>
      <Flex gutter={3}>
        <Icon
          alt={translate("booking.flow_step1_seeker_dates_hint_title")}
          dataQa="moveout-popover-icon"
          iconMaxHeight={76}
          iconMaxWidth={70}
          src={getPublicAsset("icons/calendar_short_icon.svg")}
        />
        <Flex direction="column">
          <Text body={2} dataQa="moveout-popover-title-field">
            {translate("booking.flow_step1_seeker_dates_hint_title")}
          </Text>
          <Text body={3} dataQa="moveout-popover-description-field">
            {translate("booking.flow_step1_seeker_dates_hint_description")}
          </Text>
        </Flex>
      </Flex>
    </Popover>
  </PopoverWrapper>
);

export default MoveOutPopover;
