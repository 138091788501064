const ReviewsModel = {
  fetchingPendingReviews: false,
  fetchedPendingReviews: false,
  error: null,
  pendingReviews: [],
};

const setInitialState = (initialState) => {
  const state = initialState;

  if (!state || !state.Reviews) {
    return {
      ...ReviewsModel,
      error: "There was an error in ReviewsModel.",
    };
  }
  return state;
};

export { ReviewsModel, setInitialState };
