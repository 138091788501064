import React from "react";
import { Button, Flex, useMatchMedia } from "@badi/badi-components";
import { QUERIES } from "../constants";

const NewReviewStepFooter = ({ onClick, stepIsValid, button }) => {
  const [isDesktop] = useMatchMedia(QUERIES);

  return (
    <Flex
      direction="row"
      justify="center"
      md={{
        justify: "flexEnd",
      }}
    >
      <Button
        dataQa="new-review-step-footer-button"
        disabled={!stepIsValid}
        fullWidth={true}
        grow={isDesktop ? 0 : 1}
        onClick={onClick}
      >
        {button}
      </Button>
    </Flex>
  );
};

export default NewReviewStepFooter;
