import { connect } from "react-redux";
import {
  getBankAccountLastDigits,
  getContactCountry,
  getContactVerification,
  getContactError,
  getContactName,
  getContactSurname,
  getContactCity,
  getContactPostalcode,
  getContactAddress,
  getContactBirthdate,
  getUpdatingStatus,
} from "datamodel/Payouts/selectors";
import PayoutsActions from "datamodel/Payouts/actions";
import ContactInformation from "./contact-information";

export default connect(
  (state) => ({
    enabled: !!getBankAccountLastDigits(state),
    verified: getContactVerification(state),
    updating: getUpdatingStatus(state),
    country: getContactCountry(state),
    name: getContactName(state),
    surname: getContactSurname(state),
    city: getContactCity(state),
    address: getContactAddress(state),
    postalcode: getContactPostalcode(state),
    birthdate: getContactBirthdate(state),
    error: getContactError(state),
  }),
  {
    onSubmit: PayoutsActions.updateAccount,
  },
)(ContactInformation);
