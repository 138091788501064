import moment from "moment";
import { locale } from "base/shared/Localization";
import { ROOM_SIZE_UNITS, DEFAULT_UNIT_ID } from "app/datamodel/Room/constants";

import {
  generateUrlWithNoEtParams,
  getFullPath,
} from "base/shared/tracking-headers";

export const getFormatedDate = (date, format) => {
  if (date) {
    const momentDate = moment(date);
    momentDate.locale(locale());

    return momentDate.format(format);
  }
  return "";
};

export const getMultiShareText = (owned) =>
  owned
    ? "I have a spare room for rent in my flat, anyone interested? Check it out at @badiapp"
    : "I found a great room for rent in {city} with @badiapp #FindYourPlace";

export const getSizeUnitLabel = (unitId) =>
  ROOM_SIZE_UNITS[unitId] || ROOM_SIZE_UNITS[DEFAULT_UNIT_ID];

export const replaceUrlWithNoEtParams = (router) => {
  router.replace(
    generateUrlWithNoEtParams(getFullPath(router.getCurrentLocation())),
  );
};

export const getRoomsNumber = (
  singleBedrooms = null,
  doubleBedrooms = null,
) => {
  if (doubleBedrooms === null && singleBedrooms === null) return null;
  return +singleBedrooms + +doubleBedrooms;
};
