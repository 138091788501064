export default class ReferralProduct {
  constructor(data) {
    this.entity = data || {};
  }

  get name() {
    return this.entity.name || "-";
  }

  get price() {
    return this.entity.price || "-";
  }

  get available() {
    return this.entity.available || false;
  }

  toJSON() {
    return {
      name: this.name,
      price: this.price,
      available: this.available,
    };
  }
}
