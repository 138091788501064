import { connect } from "react-redux";

import UserActions from "datamodel/User/actions";

import Onboarding from "./onboarding";

export default connect(
  (state) => ({
    token: state.Auth.access_token,
    onboarding: state.User.onboarding,
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    return {
      ...stateProps,
      ...ownProps,
      ...dispatchProps,
      updateOnboarding: (params) =>
        dispatch(UserActions.updateOnboarding(stateProps.token, params)),
    };
  },
)(Onboarding);
