import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Flex, Heading, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import {
  fetchedSummarySelector,
  focusStateSelector,
  statusesCountersSelector,
} from "datamodel/MyListings/selectors";
import TabList from "components/tab-list";

const Header = ({
  activeTab,
  fetchingInitialListingsByStatus,
  getMyListingsSummary,
  onStatusSelect,
}) => {
  const statusList = useRef();
  const fetchedSummary = useSelector(fetchedSummarySelector);
  const focusState = useSelector(focusStateSelector);
  const statusesCounters = useSelector(statusesCountersSelector);

  const autoScrollTab = (event) => {
    const listWidth = statusList.current.clientWidth;
    const selectedTabRect = event.currentTarget.getBoundingClientRect();
    const endOfSelectedTab = selectedTabRect.x + selectedTabRect.width;
    const beginningSelectedTab = selectedTabRect.x;
    let coord;
    if (
      listWidth - endOfSelectedTab <= 30 ||
      listWidth - endOfSelectedTab < 0
    ) {
      coord = selectedTabRect.x + 40;
    } else if (beginningSelectedTab <= 150 && beginningSelectedTab !== 15) {
      coord = selectedTabRect.x - 40;
    }

    statusList.current.scrollTo({
      left: coord,
      behavior: "smooth",
    });
  };

  const handleItemClick = (tabId, event) => {
    onStatusSelect(tabId);
    autoScrollTab(event);
  };

  useEffect(() => {
    getMyListingsSummary();
  }, []);

  useEffect(() => {
    if (fetchedSummary && !fetchingInitialListingsByStatus && !activeTab) {
      onStatusSelect(focusState);
    }
  }, [fetchedSummary, activeTab, fetchingInitialListingsByStatus]);

  return (
    <Flex direction="column">
      <Spacer bottom={4}>
        <Heading level="24-Bold">
          {translate("mylistings.header.title")}
        </Heading>
      </Spacer>
      <TabList
        activeItem={activeTab}
        dataQa="my-listing-tab"
        items={statusesCounters}
        onItemClick={handleItemClick}
        ref={statusList}
      />
    </Flex>
  );
};

export default Header;
