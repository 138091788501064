import { FILTERS_ID } from "../../static";

export const getFormWidth = (filterType) => {
  switch (filterType) {
    case FILTERS_ID.sort:
      return 360;
    case FILTERS_ID.budget:
      return 370;
    default:
      return 570;
  }
};
