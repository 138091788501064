import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Actions from "datamodel/RoomList/actions";
import { getOverviewPath } from "Sections/overview/routes";
import { getHomePath } from "Sections/Home/routes";
import { getEditProfilePath, NEXT_ACTIONS } from "Sections/user-profile/routes";
import { getEditRoomPath, getMyListingsPath } from "../routes";
import NewRoom from "./new-room-mapping";
import ListingType from "../components/listing-type";

const NewRoomRoute = ({ route, router, isUserLister, location }) => {
  const [startListing, setStartListing] = useState(false);
  const [nextPathname, setNextPath] = useState(
    isUserLister ? getOverviewPath() : getHomePath(),
  );
  const dispatch = useDispatch();
  const draftId = location?.query?.draft;
  const isDuplicate = location?.query?.dpl;

  const handleRoomPublished = () => {
    router.push(getMyListingsPath());
  };

  const registerLeavingHandler = (shouldLeave) => {
    // https://github.com/ReactTraining/react-router/blob/v3/docs/guides/ConfirmingNavigation.md
    router.setRouteLeaveHook(route, (nextPath) => {
      setNextPath(nextPath.pathname);
      if (shouldLeave()) return null;
      return false;
    });
  };

  const handleLeaveFlow = (avoidExit = false) => {
    if (avoidExit && startListing) {
      setStartListing(false);
      return;
    }
    router.push(nextPathname);
  };

  const navigateTo = (path) => router.push(path);

  const handleSetListingType = (placeType) => {
    dispatch(Actions.setRoomProperty({ placeType }));
    setStartListing(true);
  };

  const handleSuccessfulModeration = () => router.push(getMyListingsPath());

  const handleEditProfile = () => {
    const path = getEditProfilePath({ nextAction: NEXT_ACTIONS.PUBLISH_ROOM });
    router.push(path);
  };

  const handleUnsuccessfulModeration = (roomId) => {
    navigateTo(getEditRoomPath({ id: roomId, publish: true }));
  };

  return !startListing && !draftId ? (
    <ListingType
      handleSetListingType={handleSetListingType}
      navigateTo={navigateTo}
    />
  ) : (
    <NewRoom
      draftId={draftId}
      isDuplicate={isDuplicate}
      onEditProfile={handleEditProfile}
      onLeaveFlow={handleLeaveFlow}
      onRoomPublished={handleRoomPublished}
      onSuccessfulModeration={handleSuccessfulModeration}
      onUnsuccessfulModeration={handleUnsuccessfulModeration}
      registerOnLeaving={registerLeavingHandler}
    />
  );
};

export default NewRoomRoute;
