import React from "react";
import { Flex, Heading, Button, Text } from "@badi/badi-components";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";
import { translate } from "base/shared/Localization";

export const closeBookingModifyModal = () => {
  modal.remove(MODAL_ID.BOOKING_MODIFY);
};

export const openBookingModifyModal = () => {
  modal.add(<BookingModifyModal />, {
    id: MODAL_ID.BOOKING_MODIFY,
    onClose: closeBookingModifyModal,
    closeOnOutsideClick: true,
    closable: false,
  });
};

const BookingModifyModal = () => {
  const handleClick = () => {
    closeBookingModifyModal();
  };

  return (
    <Flex
      alignItems="center"
      data-qa="booking-modify"
      direction="column"
      gutter={4}
    >
      <Heading data-qa="booking-modify-heading">
        {translate("booking.request.modify.modal_title")}
      </Heading>
      <Text body={1} data-qa="booking-modify-subtitle">
        {translate("booking.request.modify.modal_subtitle")}
      </Text>
      <Button data-qa="booking-modify-button" onClick={handleClick}>
        {translate("booking.request.modify.modal_cta")}
      </Button>
    </Flex>
  );
};

export default BookingModifyModal;
