import styled from "styled-components";

export const Link = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const ImageWrapper = styled.div`
  > img {
    display: block;
  }
`;
