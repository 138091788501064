import React, { useState } from "react";
import Image from "components/Image/Image";
import { Flex, Text, Spacer } from "@badi/badi-components";
import { ImageWrapper } from "./styled-components";
import { getStarReviewSubtitle } from "../utils";

const StarReview = ({
  initialValue = null,
  size = 5,
  onClick = () => {},
  title = "",
  type,
}) => {
  const [stars, setStars] = useState(
    new Array(size).fill(false).map((star, index) => index < initialValue),
  );

  const handleClick = (e) => {
    const id = Number(e.target.id);
    const newSelection = stars.map((star, index) => index <= id);
    setStars(newSelection);
    onClick({
      [type]: {
        value: id + 1,
        title,
      },
    });
  };

  const currentValue = stars.lastIndexOf(true) + 1;

  return (
    <Flex direction="column">
      {title && (
        <Spacer bottom={1}>
          <Text body="14-Bold" dataQa={`${type}-title`}>
            {title}
          </Text>
        </Spacer>
      )}
      <Text body="14-Regular" dataQa={`${type}-subtitle`}>
        {getStarReviewSubtitle(currentValue)}
      </Text>
      <Spacer top={1}>
        <Flex data-qa={`${type}-star-review`} direction="row" gutter={2}>
          {stars.map((selected, i) => {
            const iconPath = selected
              ? "/assets/icons/reviews-star-filled.svg"
              : "/assets/icons/reviews-star-empty.svg";

            return (
              <ImageWrapper key={i}>
                <Image
                  alt={`reviews-star-${i}`}
                  data-qa={`reviews-star-${i}`}
                  id={i}
                  onClick={handleClick}
                  src={iconPath}
                />
              </ImageWrapper>
            );
          })}
        </Flex>
      </Spacer>
    </Flex>
  );
};

export default StarReview;
