import { getDisplayPrice } from "base/shared/Utils";
import RoomModel, {
  DEFAULT_PRICE_DETAILS,
} from "datamodel/Room/models/room-model";

class AnalyticsRoomModel extends RoomModel {
  get monthlyPrice() {
    const { current, currency } = this.entity.monthly_price || {};
    const displayText = getDisplayPrice(current, currency);
    if (!displayText) {
      return DEFAULT_PRICE_DETAILS.displayText;
    }
    return displayText;
  }

  get billsIncluded() {
    const { bills_included: billsIncluded } = this.entity.monthly_price || {};
    return billsIncluded || false;
  }

  toJSON() {
    return {
      id: this.id,
      status: this.status,
      monthlyPrice: this.monthlyPrice,
      billsIncluded: this.billsIncluded,
      address: this.address,
      availableFrom: this.availableFrom,
      minStay: this.minStay,
      title: this.title,
      picture: this.coverPicture,
    };
  }
}

export default AnalyticsRoomModel;
