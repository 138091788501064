import styled, { css } from "styled-components";
import { colors, unitizedPx } from "@badi/badi-components";

const maxWidth = css`
  max-width: 570px;
`;

export const Root = styled.div`
  min-height: 600px;
`;

export const Ul = styled.ul`
  ${maxWidth}
  margin: 0 auto;
  padding-left: ${unitizedPx(3)};
  list-style: none;
`;

export const Li = styled.li`
  position: relative;

  &:before {
    position: absolute;
    content: "•";
    color: ${colors.irisBlue};
    font-weight: bold;
    font-size: ${unitizedPx(4)};
    line-height: ${unitizedPx(3)};
    left: -${unitizedPx(3)};
  }
`;

export const Separator = styled.div`
  border-top: 1px solid ${colors.softGrey};
  width: 100%;
  ${maxWidth}
  height: 1px;
  margin: 0 auto;
`;
