import React, { useEffect, useState } from "react";
import { fetchListingFlowActions } from "datamodel/RoomList/api";
import { Heading, Spacer, Flex, useMatchMedia } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import LayoutWrapper from "components/Layout/Wrapper";
import { Container, Col, Row } from "reactstrap";
import Image from "components/Image/Image";
import { CroppedImage } from "./styled-components";
import { QUERIES } from "./constants";

import Topbar from "./top-bar";
import MainActions from "./main-actions";
import OtherActions from "./other-actions";

const ListingType = ({ handleSetListingType, navigateTo }) => {
  const [isDesktop] = useMatchMedia(QUERIES);

  const [mainActions, setActions] = useState(null);
  const [otherActions, setOtherActions] = useState(null);

  useEffect(() => {
    fetchListingFlowActions().then(
      ({ mainActions: mainActionsRes, otherActions: otherActionsRes }) => {
        setActions(mainActionsRes);
        setOtherActions(otherActionsRes);
      },
    );
  }, []);

  return (
    <LayoutWrapper topBar={<Topbar navigateTo={navigateTo} />}>
      <Spacer top={8}>
        <Container>
          <Row>
            <Col lg={6} xs={12}>
              <Heading level="28-Bold">
                {translate("listing.flow.title")}
              </Heading>
              <Spacer top={4}>
                {!!mainActions && (
                  <MainActions
                    mainActions={mainActions}
                    onClick={handleSetListingType}
                  />
                )}
              </Spacer>
              <Spacer top={4}>
                {!!otherActions && (
                  <OtherActions
                    actions={otherActions}
                    navigateTo={navigateTo}
                  />
                )}
              </Spacer>
            </Col>
            {isDesktop && (
              <Col lg={6} xs={12}>
                <Flex alignItems="center" fullHeight={true} justify="center">
                  <Spacer top={4}>
                    <CroppedImage>
                      <Image
                        src={getPublicAsset("images/howitworks-seeker-1.svg")}
                      />
                    </CroppedImage>
                  </Spacer>
                </Flex>
              </Col>
            )}
          </Row>
        </Container>
      </Spacer>
    </LayoutWrapper>
  );
};

export default ListingType;
