import styled, { keyframes } from "styled-components";
import { colors } from "@badi/badi-components";

export const animationEllipsis3 = keyframes`
0%, 25% {
  left: 32px;
  transform: scale(0);
}
50% {
  left: 32px;
  transform: scale(1);
}
75% {
  left: 100px;
}
100% {
  left: 168px;
  transform: scale(1);
}
`;
export const animationEllipsis2 = keyframes`
0% {
  transform: scale(1);
}
25%, 100% {
  transform: scale(0);
}
`;

export const animationEllipsis = keyframes`
0% {
  left: 32px;
  transform: scale(0);
}
25% {
  left: 32px;
  transform: scale(1);
}
50% {
  left: 100px;
}
75% {
  left: 168px;
  transform: scale(1);
}
100% {
  left: 168px;
  transform: scale(0);
}
`;

export const RootEllipsis = styled.div`
  position: relative;
  height: 32px;
  width: 68px;
`;

export const Ellipsis = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  transform: translate(-36.5px, 0) scale(0.395) translate(36.5px, 0);
`;

export const EllipsisContent = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${colors.green};
  position: absolute;
  top: 0px;
  left: 32px;
  animation: ${({ animation }) => animation || animationEllipsis} 2s
    cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation-delay: ${({ animationDelay }) => animationDelay || null};
`;
