import slugify from "slugify";
import { stringify } from "qs";
import { getAPIUrl as getServerUrl, getRelativePath } from "base/shared/Utils";

import { getColor } from "base/shared/get-color";
import { FLAT_BENEFITS } from "datamodel/SearchFilters/static";
import { SEARCHROOMS_ROOMS_PER_PAGE, CARD_TYPES, BANNER_TYPES } from "./static";

import { RoomCardModel, MarkerModel } from "./models";

const parseLocationToAPI = ({
  bounds,
  city,
  coordinates,
  distance,
  placeId,
  text,
}) => {
  if (bounds) return { bounds };

  if (coordinates) {
    return {
      coordinates: {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        distance,
      },
      location: { place_id: placeId, q: text },
      city,
    };
  }

  return {};
};

export const benefitsObjectToArray = (benefitsObject) => {
  if (!benefitsObject) return [];
  const benefitsArray = benefitsObject.reduce((acc, b) => {
    if (b.selected) {
      acc.push(FLAT_BENEFITS.items.find((o) => o.id === b.value).value);
    }
    return acc;
  }, []);
  return benefitsArray;
};

export const getBenefitsIds = (benefitString) => {
  if (!benefitString) return "";
  const stringArr = benefitString.split(",").reduce((acc, benenfit) => {
    const benId = FLAT_BENEFITS.items.find((o) => benenfit === o.value).id;
    return `${acc}${acc ? "," : ""}${benId}`;
  }, "");
  return stringArr;
};

const parseSortByToAPI = ({ sortBy }) => ({
  sort_by: sortBy.find((o) => o.selected).query,
});

const parseBudgetToAPI = ({ max, min }) => {
  const budget = {};

  if (max) budget.max_price = max;
  if (min) budget.min_price = min;

  return budget;
};

const parseAvailabilityToAPI = ({ fromDate, stayOptions, now }) => {
  const availability = {
    available_from: now ? new Date().toISOString().slice(0, 10) : fromDate,
    length_of_stay: stayOptions
      .filter((b) => b.selected)
      .map((b) => b.apivalue),
  };

  return availability;
};

export const parseFlatmatesToAPI = ({ genders, tenants }) => {
  const selectedGender =
    genders.filter((b) => b.selected).map((b) => b.apivalue) || [];

  return {
    genders: selectedGender,
    number_of_tenants: tenants ? { max: tenants } : null,
  };
};

const parseRoomPropsToAPI = ({ bookable, bedTypes, placeTypes }) => {
  const roomProps = {
    bed_types: bedTypes.filter((b) => b.selected).map((b) => b.id),
    place_types: placeTypes.filter((b) => b.selected).map((b) => b.id),
  };

  if (bookable) roomProps.bookable = bookable;

  return roomProps;
};

const parseAmenitiesToAPI = ({ flatAmenities, houseRules, roomAmenities }) => ({
  amenities: [...roomAmenities, ...flatAmenities, ...houseRules]
    .filter((a) => a.selected)
    .map((a) => a.id),
});

const parsePlaceTypesToAPI = ({ placeTypes }) => ({
  place_types: placeTypes.filter((b) => b.selected).map((b) => b.id),
});

const parseFlatSizeToAPI = ({ minFlatSize, maxFlatSize }) => {
  const flatSize = {};

  if (minFlatSize) flatSize.min_flat_size = minFlatSize;
  if (maxFlatSize) flatSize.max_flat_size = maxFlatSize;

  return flatSize;
};

export const parseMarketplaceSegmentsToAPI = ({
  marketplaceSegmentsFilter = {},
}) => {
  const filterKeys = Object.keys(marketplaceSegmentsFilter);
  const { badiCommunity, badiRooms } = marketplaceSegmentsFilter;
  const selectedFilters = [];
  if (badiRooms) {
    selectedFilters.push("badi_rooms");
  }
  if (badiCommunity) {
    selectedFilters.push("badi_community");
  }
  // We don't permit the user to filter out all rooms. And we don't bother filtering if they've selected all types.
  if (
    selectedFilters.length == 0 ||
    filterKeys.length == selectedFilters.length
  ) {
    return {};
  }
  return {
    marketplace_segments: selectedFilters,
  };
};

const parseBenefitsToAPI = ({ flatBenefits }) => ({
  benefits: benefitsObjectToArray(flatBenefits),
});

const cleanEmptyProps = (props) => {
  const safeProps = Object.keys(props).reduce((prev, name) => {
    const parsedParam = props[name];

    if (
      typeof parsedParam === "undefined" ||
      parsedParam === "" ||
      parsedParam === null ||
      (Array.isArray(parsedParam) && !parsedParam.length)
    ) {
      return prev;
    }

    return { ...prev, [name]: parsedParam };
  }, {});

  return safeProps;
};

export const parseFiltersToAPI = (filters) => {
  const paginationParams = {
    page: 1,
    per: SEARCHROOMS_ROOMS_PER_PAGE.default,
    price_types: [3],
  };

  if (!filters) return paginationParams;

  const params = {
    ...(!filters.token && paginationParams),
    token: filters.token,
    ...parseLocationToAPI(filters),
    ...parseSortByToAPI(filters),
    ...parseBudgetToAPI(filters),
    ...parseAvailabilityToAPI(filters),
    ...parseFlatmatesToAPI(filters),
    ...parseRoomPropsToAPI(filters),
    ...parseAmenitiesToAPI(filters),
    ...parseBenefitsToAPI(filters),
    ...parsePlaceTypesToAPI(filters),
    ...parseFlatSizeToAPI(filters),
    ...parseMarketplaceSegmentsToAPI(filters),
  };

  params.excluded_rooms = filters.excludedRooms;

  const safeParams = cleanEmptyProps(params);

  return safeParams;
};

export const switchModel = (type) => {
  switch (type) {
    case CARD_TYPES.ROOM_CARD:
      return RoomCardModel;
    default:
      return null;
  }
};

const getTypeModel = (result) => {
  const Model = switchModel(result.type);
  if (!Model) return null;
  return new Model(result).toJSON();
};

export const filterOutNotExistingModel = (result) => !!result;

export const parseSearchCards = (listOfCards) =>
  listOfCards
    .map((card) => {
      if (BANNER_TYPES.includes(card.type)) return card;
      return getTypeModel(card);
    })
    .filter(filterOutNotExistingModel);

export const parseSearchMarkers = (listOfResults) =>
  listOfResults.map((result) => new MarkerModel(result).toJSON());

export const generateSearchRequestUrl = (basicPath, filters) => {
  const serverUrl = getServerUrl(basicPath);
  const params = parseFiltersToAPI(filters);
  const queryParams = stringify(params, {
    encode: false,
    arrayFormat: "brackets",
  });

  return `${serverUrl}?${queryParams}`;
};

export const parseUiElement = ({
  content,
  color,
  background_color: backgroundColor,
  icon,
}) => {
  return {
    content,
    color: color && getColor(color),
    backgroundColor: backgroundColor && getColor(backgroundColor),
    icon,
  };
};

export const parseExtraInfo = (extraInfo) => {
  return extraInfo.map(({ title, body }) => ({
    title: parseUiElement(title),
    body: parseUiElement(body),
  }));
};

export const getAllRoomCardsIds = (allCards) =>
  allCards
    .filter((card) => card.type === CARD_TYPES.ROOM_CARD)
    .map((card) => card.roomId);

export const parseBackCover = (backCover) => {
  if (!backCover) return null;
  const {
    background: {
      picture: { url },
    },
    hint,
    cta,
  } = backCover;

  return {
    backgroundUrl: url,
    hint: parseUiElement(hint),
    cta: parseUiElement(cta),
  };
};

export const getShortTitle = (title) => {
  let shortTitle = "";
  if (title.length > 36) {
    const words = title.split(" ");
    for (let i = 0, len = words.length; i < len; i += 1) {
      const word = words[i];
      if (shortTitle.length + word.length > 36) break;
      shortTitle = `${shortTitle} ${word}`;
    }
  } else return title;
  return shortTitle;
};

export const getFriendlyUrl = (t, title, city, id) => {
  const shortTitle = getShortTitle(title);
  const formatedTitle = city
    ? slugify(`${shortTitle}-${t("Rent")}-${city}`)
    : slugify(`${shortTitle}-${t("Rent")}`);
  return getRelativePath(`/room/${formatedTitle}/${id}`);
};
