import React from "react";

const AddPicture = ({ addPicture }) => (
  <div className="DropZone__Preview" onClick={addPicture} role="button">
    <div className="DropZone__Preview--box">
      <div className="DropZone__Preview--add" />
    </div>
  </div>
);

export default AddPicture;
