import React from "react";
import classNames from "classnames";
import Image from "components/Image/Image";

import "./ButtonExt.css";

const ImageButton = ({
  alt,
  children,
  className,
  disabled,
  onClick,
  src,
  dataQa,
}) => (
  <button
    className={classNames("Button__img", className)}
    data-qa={dataQa}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    {src && <Image alt={alt} src={src} />}
    {children}
  </button>
);

ImageButton.defaultProps = {
  alt: "",
  children: null,
  className: null,
  dataQa: null,
  disabled: false,
  src: null,
};

export default ImageButton;
