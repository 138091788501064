import React, { memo, useEffect, useState } from "react";
import { Spacer } from "@badi/badi-components";

import RadioButtonGroup from "components/Form/RadioButton/RadioButtonGroupWithFormsy";
import { translate } from "base/shared/Localization";
import { COUPLE } from "base/shared/StaticData";
import FormBlock from "components/form-block";

const Couple = ({ couple }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(translate(COUPLE));
  }, []);

  return (
    <FormBlock
      dataQa="profile-form-couple-section"
      title={translate("user.section.who_is_moving")}
    >
      <RadioButtonGroup
        id="formblock-couple"
        items={options}
        name="couple"
        required={false}
        value={couple}
      />
      <Spacer bottom={3} />
    </FormBlock>
  );
};

export default memo(Couple);
