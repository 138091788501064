import React, { useMemo } from "react";
import { Container } from "reactstrap";
import { Heading, Spacer, Flex } from "@badi/badi-components";
import { translate, locale } from "base/shared/Localization";

import SeoLink from "./seo-link";
import { StyledFlex } from "./styled-components";
import { getLinksByLanguage } from "./utils";

const SeoLinks = ({ title, links }) => {
  const currentLocale = locale();
  const linksLoc = useMemo(
    () => getLinksByLanguage(currentLocale, links),
    [currentLocale],
  );
  return (
    <Container>
      <Spacer bottom={4} top={4}>
        <Heading level="24-Bold">{translate(title)}</Heading>
      </Spacer>
      <Flex
        alignItems="baseline"
        as={StyledFlex}
        data-qa={"links-wrap"}
        direction="column"
        justify="spaceBetween"
        md={{ direction: "row" }}
        wrap="wrap"
      >
        {linksLoc.map((search) => (
          <Spacer bottom={4} key={search.city} wide={true}>
            <SeoLink {...search} />
          </Spacer>
        ))}
      </Flex>
      <Spacer bottom={4} />
    </Container>
  );
};

export default SeoLinks;
