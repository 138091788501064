class FailingRuleModel {
  constructor(entity) {
    this.entity = entity || {};
  }

  get ruleName() {
    return this.entity.rule_name || "";
  }

  get userFeedback() {
    return this.entity.user_feedback || "";
  }

  toJSON() {
    return {
      ruleName: this.ruleName,
      userFeedback: this.userFeedback,
    };
  }
}

class ModerationResult {
  constructor(entity) {
    this.entity = entity || {};
  }

  get failedRules() {
    const failedRules = this.entity.failed_rules || [];

    return failedRules.map((failedRule) =>
      new FailingRuleModel(failedRule).toJSON(),
    );
  }

  get autoCheck() {
    return this.entity.auto_check;
  }

  get manualCheck() {
    return this.entity.manual_check;
  }

  get moderatedAt() {
    return this.entity.moderated_at;
  }

  toJSON() {
    return {
      failedRules: this.failedRules,
      manualCheck: this.manualCheck,
      moderatedAt: this.moderatedAt,
      autoCheck: this.autoCheck,
    };
  }
}

export default ModerationResult;
