export const getVisitTypeContent = (express) =>
  express
    ? {
        title: "inbox_popup_visit.proposal.visit_type.express",
        description: "inbox_popup_visit.proposal.visit_type.express_desc",
        icon: "/assets/icons/mobile-phone-success.svg",
      }
    : {
        title: "inbox_popup_visit.proposal.visit_type.default",
        description: "inbox_popup_visit.proposal.visit_type.default_desc",
        icon: "/assets/icons/home.svg",
      };
