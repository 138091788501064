import React, { PureComponent } from "react";

import Step from "components/Steps/Step";

class Steps extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      totalSteps: 1,
      currentStep: props.currentStep,
      title: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.selectStep(this.props.children, this.state.currentStep);
  }

  setNextStep = (currentStep, title, params) => {
    const { onChange } = this.props;

    this.setState({ currentStep, title }, () => {
      onChange(currentStep, title, this.state.totalSteps, params);
    });
  };

  setSteps = (currentStep, title, totalSteps) => {
    const { onChange } = this.props;

    this.setState({ currentStep, title, totalSteps });
  };

  getNextStep = () => {
    const { currentStep, totalSteps } = this.state;

    if (currentStep < totalSteps) {
      return this.props.children.find((c) => c.props.order === currentStep + 1)
        .props;
    }

    return null;
  };

  getPreviousStep = () => {
    const { currentStep } = this.state;

    if (currentStep > 1) {
      return this.props.children.find((c) => c.props.order === currentStep - 1)
        .props;
    }

    return null;
  };

  onFinish = (params) => {
    const { onFinish } = this.props;
    onFinish(params);
  };

  selectStep = (children, currentStep) => {
    const step = children.find(
      (component) => component.props.order === currentStep,
    ).props;

    this.setSteps(step.order, step.title, children.length);
  };

  render() {
    const { children } = this.props;
    const { currentStep } = this.state;
    const { component, order, title, ...rest } = children.find(
      (c) => c.props.order === currentStep,
    ).props;

    const nextStep = this.getNextStep();
    const previousStep = this.getPreviousStep();

    const stepProps = {
      ...rest,
      nextStep:
        nextStep &&
        ((params) => this.setNextStep(nextStep.order, nextStep.title, params)),
      previousStep:
        previousStep &&
        ((params) =>
          this.setNextStep(previousStep.order, previousStep.title, params)),
      /* This is a patch to follow the same convention and not break the current implementation */
      onFinish: !nextStep && this.onFinish,
    };

    return (
      <Step component={component} order={order} title={title} {...stepProps} />
    );
  }
}

Steps.defaultProps = {
  children: [],
  currentStep: 1,
  onChange: () => {},
  onFinish: () => {},
};

export default Steps;
