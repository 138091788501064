import React, { useState, useEffect } from "react";
import { RadioButton, Spacer } from "@badi/badi-components";
import RadioButtonCard from "../radio-button-card";

const RadioButtonGroup = ({
  disabled = false,
  initialValue = null,
  name,
  onChange,
  onValidation = null,
  options,
  required = false,
  layout = "default",
  dataQa,
}) => {
  const [value, setValue] = useState(initialValue);
  const [isValid, setValidation] = useState(!required || !!initialValue);

  const handleChange = ({ currentTarget: { value: newValue } }) => {
    const parsedValue = Number.isInteger(value || options[0].value)
      ? parseInt(newValue, 10)
      : newValue;
    setValue(parsedValue);
    setValidation(!required || !!parsedValue);
    onChange({
      name,
      value: parsedValue,
    });
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (onValidation) {
      onValidation({ name, isValid });
    }
  }, [isValid]);

  return options.map(
    ({
      label,
      value: optionValue,
      disabled: optionDisabled,
      imageUrl,
      description,
      title,
    }) =>
      layout === "default" ? (
        <RadioButton
          checked={optionValue === value}
          disabled={optionDisabled || disabled}
          id={`${name}-${optionValue}`}
          key={optionValue}
          label={label}
          name={name}
          onChange={handleChange}
          required={required}
          value={optionValue}
        />
      ) : (
        <Spacer bottom={2} top={2}>
          <RadioButtonCard
            checked={optionValue === value}
            dataQa={dataQa}
            description={description}
            id={`${name}-${optionValue}`}
            imageUrl={imageUrl}
            key={optionValue}
            label={title}
            onChange={handleChange}
            value={optionValue}
          />
        </Spacer>
      ),
  );
};

export default RadioButtonGroup;
