import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const SearchGrid = styled.article`
  position: relative;
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  column-gap: 4%;
  row-gap: 20%;
  width: 100%;
`;

export const SearchCard = styled.div`
  display: inline-block;
  padding-right: 20px;
  a:hover {
    text-decoration: none;
  }
`;

export const StyledImage = styled.img`
  height: ${unitizedPx(33.75)};
  width: 100%;
  object-fit: cover;
  position: relative;
  display: block;
`;

export const GridScroll = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  padding: ${unitizedPx(2)} 0px;
  ${mediaquery.lg`  
    overflow: visible;
  `}
`;

export const StyledFlex = styled.div`
  min-width: ${unitizedPx(90)};
  & > div {
    max-width: ${unitizedPx(33.75)};
    min-width: ${unitizedPx(33.75)};
    ${mediaquery.lg`  
      min-width: auto;
    `}
  }
`;

export const FeaturedSearchStyled = styled.a`
  position: relative;
  max-width: ${unitizedPx(33.75)};
  height: ${unitizedPx(44)};
  display: block;
  cursor: pointer;

  transition: all 0.3s;

  border-radius: ${unitizedPx(1)};
  box-shadow: 0 8px 16px 0 rgba(51, 51, 51, 0.08);
  background-color: var(--badi-white);
  overflow: hidden;

  display: inline-block;

  &:hover {
    margin-top: -4px;
    margin-bottom: 4px;
    box-shadow: 0 16px 32px 0 rgba(51, 51, 51, 0.16);
    text-decoration: none;
  }
`;

export const SliderNavButton = styled.div`
  transition: opacity 0.3s;
  cursor: pointer;
  width: 50px;
  display: none;
  opacity: 1;
  position: absolute;
  top: 50%;
  z-index: 9;
  margin-top: -20px;
  &.prev {
    left: ${unitizedPx(1)};
  }
  &.next {
    right: ${unitizedPx(6)};
  }
  &.swiper-button-disabled {
    opacity: 0;
  }
  ${mediaquery.lg`
    display: block;
  `}
`;
