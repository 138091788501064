import React from "react";
import { withRouter } from "react-router";

import { getNewReviewPath } from "Sections/reviews/routes";
import PendingTopBanner from "./pending-top-banner";

const PendingTopBannerRoute = ({ router, reviewId, screen }) => {
  const redirectToNewReviewFlow = () => {
    router.push(getNewReviewPath({ reviewId }));
  };

  return (
    <PendingTopBanner
      onClick={redirectToNewReviewFlow}
      reviewId={reviewId}
      screen={screen}
    />
  );
};

export default withRouter(PendingTopBannerRoute);
