import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { getAnimation } from "./animations";

const AnimatedSection = ({
  type = "default",
  children,
  bgColor = "transparent",
}) => {
  const handleRef = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const [from, to] = getAnimation(type, handleRef.current);
    gsap.fromTo(handleRef.current, from, to);
  }, []);
  return (
    <div ref={handleRef} style={{ backgroundColor: bgColor }}>
      {children}
    </div>
  );
};

export default AnimatedSection;
