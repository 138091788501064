import styled, { css } from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: white;
  width: auto;
  height: auto;
  max-height: fill-available;
  overflow-y: scroll;
  max-width: calc(100% - 40px);
  margin: 0 auto;

  border-radius: ${unitizedPx(0.5)};
  transform: scale(0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(1);
      opacity: 1;
    `}

  ${({ isClosed }) =>
    isClosed &&
    css`
      transform: scale(0);
      opacity: 0;
    `}

  ${mediaquery.md`  
    max-width: ${unitizedPx(71)};
    max-height: unset;
  `}
`;
