import React from "react";

const getRotationDegrees = (direction) => {
  switch (direction) {
    case "down":
      return "-90deg";
    case "right":
      return "180deg";
    case "up":
      return "90deg";
    default:
      return "0deg";
  }
};

const CaretSvg = (color, fillColor) => (
  <svg
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      {fillColor && <circle cx="18" cy="18" fill={fillColor} r="18" />}
      <g stroke={color} strokeLinecap="round" strokeWidth="2">
        <path d="M20.1 11.304l-7.082 6.76M20.1 25.145l-7.082-6.76" />
      </g>
    </g>
  </svg>
);

const ArrowSvg = (color) => (
  <svg
    height="11"
    viewBox="0 0 16 11"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M5.5.852a.489.489 0 0 1 .694 0 .485.485 0 0 1 0 .683l-3.52 3.498H14.29a.487.487 0 0 1 0 .973l-11.618.001 3.521 3.492a.489.489 0 1 1-.694.69L1.146 5.862a.473.473 0 0 1 0-.683L5.5.852z"
        fill={color}
        fillRule="nonzero"
        stroke={color}
        strokeWidth=".8"
      />
    </g>
  </svg>
);

const ArrowBigSvg = (color) => (
  <svg
    height="16"
    viewBox="0 0 19 16"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.294 7.285l7-7a1.03 1.03 0 0 1 1.422 0 1.006 1.006 0 0 1 0 1.42L3.398 7.022h13.679a1.006 1.006 0 0 1 0 2.01H3.46l5.254 5.254c.19.19.295.442.295.71 0 .268-.105.52-.294.71a.995.995 0 0 1-.71.294.995.995 0 0 1-.711-.294l-7-7A1 1 0 0 1 0 7.995c0-.27.105-.521.294-.711"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

const Arrow = ({ className, color, bgColor, direction, type }) => {
  let content;

  switch (type) {
    case "arrow":
      content = ArrowSvg(color);
      break;
    case "arrow_big":
      content = ArrowBigSvg(color);
      break;
    case "caret":
      content = CaretSvg(color, bgColor);
      break;
    default:
      content = null;
  }

  return (
    <div
      className={className}
      data-qa="arrow-icon"
      style={{ transform: `rotate(${getRotationDegrees(direction)})` }}
    >
      {content}
    </div>
  );
};

Arrow.defaultProps = {
  bgColor: null,
  className: "",
  color: "#3F3F3F",
  type: "arrow",
};

export default Arrow;
