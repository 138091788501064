import React, { Component } from "react";
import classNames from "classnames";

import ProgressiveImg from "components/ProgressiveImg";

const scrollTo = (element, to, offset) => {
  const thumbsWindow = element.scrollLeft + element.clientWidth;

  if (to === 0) element.scrollLeft = 0;
  else if (to + offset === element.scrollWidth)
    element.scrollLeft = element.scrollWidth;
  else if (to < element.scrollLeft) {
    if (element.scrollLeft - 300 < 0) {
      element.scrollLeft = 0;
    } else {
      element.scrollLeft -= 300;
    }
  } else if (to + offset > thumbsWindow) {
    if (element.scrollLeft + 300 > element.scrollWidth) {
      element.scrollLeft = element.scrollWidth;
    } else {
      element.scrollLeft += 300;
    }
  }
};

class Thumb extends Component {
  constructor(props) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.selected && nextProps.selected && this.thumbElem) {
      scrollTo(
        this.thumbElem.parentElement,
        this.thumbElem.offsetLeft,
        this.thumbElem.clientWidth,
      );
    }
  }

  clickHandler(e) {
    e.preventDefault();
    const { onClick, picture } = this.props;
    onClick(picture.id);
  }

  render() {
    const { picture, selected } = this.props;

    return (
      <li
        className="gallery__subitem"
        ref={(i) => {
          this.thumbElem = i;
        }}
      >
        <div
          className="gallery__subbox"
          onClick={this.clickHandler}
          role="presentation"
        >
          <ProgressiveImg
            className={classNames("gallery__thumb", {
              gallery_thumb_selected: selected,
            })}
            onClick={this.openGallery}
            src={picture.width_500_url}
            tinySrc={picture.width_27_url}
          />
        </div>
      </li>
    );
  }
}

export default Thumb;
