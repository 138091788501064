import { connect } from "react-redux";

import { getToken } from "datamodel/Auth/selectors";

import BookingPreview from "./booking-preview";

const mapStateToProps = (state) => ({
  token: getToken(state),
});

export default connect(mapStateToProps)(BookingPreview);
