import Types from "./types";

export default {
  reset() {
    return { type: Types.RESET_SEARCHFILTERS };
  },
  resetAvailability() {
    return { type: Types.RESET_SEARCHFILTERS_AVAILABILITY, searchUpdate: true };
  },
  resetSortBy() {
    return { type: Types.RESET_SEARCHFILTERS_SORT_BY, searchUpdate: true };
  },
  resetBudget() {
    return { type: Types.RESET_SEARCHFILTERS_BUDGET, searchUpdate: true };
  },
  resetFlatmates() {
    return { type: Types.RESET_SEARCHFILTERS_FLATMATES, searchUpdate: true };
  },
  resetFlat() {
    return { type: Types.RESET_SEARCHFILTERS_FLAT, searchUpdate: true };
  },
  resetRoom() {
    return { type: Types.RESET_SEARCHFILTERS_ROOM, searchUpdate: true };
  },
  resetMarketplaceSegments() {
    return {
      type: Types.RESET_SEARCHFILTERS_MARKETPLACE_SEGMENTS,
      searchUpdate: true,
    };
  },
  setAvailability(availability) {
    return {
      type: Types.SET_SEARCHFILTERS_AVAILABILITY,
      result: availability,
      searchUpdate: true,
    };
  },
  setSortBy(sortBy) {
    return {
      type: Types.SET_SEARCHFILTERS_SORT_BY,
      result: { sortBy },
      searchUpdate: true,
    };
  },
  // min, max
  setBudget(budget) {
    return {
      type: Types.SET_SEARCHFILTERS_BUDGET,
      result: budget,
      searchUpdate: true,
    };
  },
  // tenants, gender
  setFlatmates(flatmates) {
    return {
      type: Types.SET_SEARCHFILTERS_FLATMATES,
      result: flatmates,
      searchUpdate: true,
    };
  },
  // bedTypes, placeTypes, bookable, roomAmenities
  setRoom(room) {
    return {
      type: Types.SET_SEARCHFILTERS_ROOM,
      result: room,
    };
  },
  // houseRules, flatBenefits, flatAmenities
  setFlat(flat) {
    return {
      type: Types.SET_SEARCHFILTERS_FLAT,
      result: flat,
      searchUpdate: true,
    };
  },
  // bounds, coordinates, city, text, placeId, distance, zoom
  setLocation(location) {
    return {
      type: Types.SET_SEARCHFILTERS_LOCATION,
      result: location,
      searchUpdate: true,
    };
  },
  setPage(page) {
    return { type: Types.SET_SEARCHFILTERS_PAGE, result: page };
  },
  setToken(token) {
    return {
      type: Types.SET_SEARCHFILTERS_TOKEN,
      result: token,
      searchUpdate: true,
    };
  },
  setParams({ searchFilters, searchUpdate = true }) {
    return {
      type: Types.SET_SEARCHFILTERS_PARAMS,
      result: searchFilters,
      searchUpdate,
    };
  },
  setUpdatedFilters(updatedFilters) {
    return {
      type: Types.SET_SEARCHFILTERS_UPDATED_FILTERS,
      result: updatedFilters,
    };
  },
  setBoundsRecalculation(recalculateBounds) {
    return {
      type: Types.SET_BOUNDS_RECALCULATION,
      result: recalculateBounds,
    };
  },
  setMarketplaceSegmentsFilters(marketplaceSegments) {
    return {
      type: Types.SET_SEARCHFILTERS_MARKETPLACE_SEGMENTS,
      result: marketplaceSegments,
      searchUpdate: true,
    };
  },
};
