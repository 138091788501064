import React from "react";
import { colors } from "@badi/badi-components";

const Message = ({ className, color }) => {
  return (
    <svg
      className={className}
      height="32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.389 0H2.61C1.171 0 0 1.077 0 2.401V13.6C0 14.923 1.171 16 2.611 16H19.39C20.829 16 22 14.923 22 13.599V2.4C22 1.077 20.829 0 19.389 0zm0 13.88H2.61c-.211 0-.397-.131-.397-.281V3.882l7.588 6.134a.888.888 0 0 0 .557.195h1.282c.203 0 .401-.07.557-.195l7.588-6.134v9.717c0 .15-.186.281-.397.281zM11 8.106L3.62 2.12h14.76L11 8.106z"
        fill={color}
        fillRule="evenodd"
        stroke="#FFF"
        strokeWidth=".7"
        transform="translate(5 8)"
      />
    </svg>
  );
};

Message.defaultProps = {
  className: "",
  color: colors.darkGrey,
};

export default Message;
