import styled, { css } from "styled-components";
import { generateResponsiveStyles, toKebabCase, unitizedPx } from "../helpers";

import { hasValue, isValidFlexShrink } from "./helpers";

export const FlexChild = styled.div`
  ${({ order, grow, basis, alignself, shrink, wide = false }) => {
    return css`
      order: ${order || 0};
      flex-grow: ${grow || 0};
      flex-basis: ${hasValue(basis) ? basis : "auto"};
      ${alignself ? `align-self: ${toKebabCase(alignself)};` : null}
      flex-shrink: ${isValidFlexShrink(shrink) ? shrink : 1};
      width: ${wide && "100%"};
    `;
  }}
`;

const marginCss = (marginBottom, marginRight) => css`
  margin-right: ${marginRight};
  margin-bottom: ${marginBottom};
`;

const baseStyles = ({
  gutter,
  direction = "row",
  justify,
  alignItems,
  reverse,
  wrap,
}) => {
  const marginRight =
    direction === "row" || wrap === "wrap" ? unitizedPx(gutter) : "0";
  const marginBottom =
    direction !== "row" || wrap === "wrap" ? unitizedPx(gutter) : "0";

  return css`
    flex-direction: ${`${direction}${reverse ? "-reverse" : ""}`};
    justify-content: ${toKebabCase(justify)};
    align-items: ${toKebabCase(alignItems)};
    flex-wrap: ${toKebabCase(wrap)};

    ${gutter
      ? css`
          > ${FlexChild} {
            ${marginCss(marginBottom, marginRight)}

            ${reverse
              ? css`
                  &:first-child {
                    ${marginCss(0, 0)}
                  }
                  &:last-child {
                    ${marginCss(marginBottom, marginRight)}
                  }
                `
              : css`
                  &:first-child {
                    ${marginCss(marginBottom, marginRight)}
                  }
                  &:last-child {
                    ${marginCss(0, 0)}
                  }
                `}
          }
        `
      : null}
  `;
};

const responsiveStyles = generateResponsiveStyles(baseStyles);

export const Root = styled.div`
  display: flex;
  ${({ fullHeight }) => css`
    height: ${fullHeight ? "100%" : "auto"};
  `}
  ${baseStyles}
  ${responsiveStyles}
`;
