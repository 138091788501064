import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const HeaderRoot = styled.div`
  min-height: ${unitizedPx(8)};
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  margin: 0 150px;
`;
