import React, { memo } from "react";

import { Button } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import BookingFooter from "../footer";

const ConfirmationFooter = ({
  stepIsValid,
  disabled,
  onSubmit = () => {},
  onMessageClick = () => {},
}) => (
  <BookingFooter>
    <Button
      data-qa="message-button"
      fullWidth={true}
      grow={1}
      key="message-button"
      onClick={onMessageClick}
      variant="secondary"
    >
      {translate("recovery.payment_cta_message")}
    </Button>
    <Button
      data-qa="submit-button"
      disabled={!stepIsValid || disabled}
      fullWidth={true}
      grow={1}
      onClick={onSubmit}
      variant="primary"
    >
      {translate("recovery.payment_cta_retry")}
    </Button>
  </BookingFooter>
);

export default memo(ConfirmationFooter);
