import {
  getFlatAmenities,
  getFlatBenefits,
  getHouseRules,
  getRoomAmenities,
  getBedTypes,
  getPlaceType,
  getSortBy,
  getGenders,
  getStayOptions,
  getMarketplaceSegments,
} from "./utils";
import { DEFAULT_FILTER_VALUE, MARKETPLACE_SEGMENTS } from "./static";

const SearchFiltersModel = {
  updatedFilters: false,
  fetching: false,
  error: null,
  page: 1,
  bounds: null,
  coordinates: null,
  distance: undefined,
  city: null,
  text: null,
  placeId: null,
  zoom: 0,

  now: false,
  fromDate: "",
  sortBy: getSortBy(),
  min: 0,
  max: 0,
  tenants: 0,
  genders: getGenders(),
  bedTypes: getBedTypes(),
  placeTypes: getPlaceType(),
  bookable: false,
  roomAmenities: getRoomAmenities(),
  houseRules: getHouseRules(),
  flatAmenities: getFlatAmenities(),
  flatBenefits: getFlatBenefits(),
  stayOptions: getStayOptions(),
  token: null,
  previousToken: null,
  sortFilter: DEFAULT_FILTER_VALUE,
  budgetFilter: DEFAULT_FILTER_VALUE,
  bookingFilter: DEFAULT_FILTER_VALUE,
  availabilityFilter: DEFAULT_FILTER_VALUE,
  flatmatesFilter: DEFAULT_FILTER_VALUE,
  roomFilter: DEFAULT_FILTER_VALUE,
  flatFilter: DEFAULT_FILTER_VALUE,
  marketplaceSegmentsFilter: MARKETPLACE_SEGMENTS,
  boundsRecalculation: false,
  minFlatSize: 0,
  maxFlatSize: 0,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.SearchFilters) {
    return {
      ...SearchFiltersModel,
      error: "There was an error in SearchFiltersModel.",
    };
  }
  return state;
}

export { SearchFiltersModel, setInitialState };
