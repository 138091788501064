import React from "react";
import { translate } from "base/shared/Localization";
import { Heading, Spacer, Flex, TextButton } from "@badi/badi-components";
import { TextAlignCenter } from "../../styled-components";
import PendingReferral from "./pending-referral";

const PendingReferrals = ({
  referrals,
  nextToken = null,
  onShowMore,
  sgid = null,
}) => {
  const handleClick = () => onShowMore(nextToken, sgid);

  return (
    <>
      <Heading data-qa="pending-referrals-header" level="24-Bold">
        {translate("referrals.content.pending.title")}
      </Heading>
      <Spacer top={2} />
      <Flex direction="column" gutter={2}>
        {referrals.map(({ id, status, refereeName }) => (
          <PendingReferral key={id} refereeName={refereeName} status={status} />
        ))}
        {nextToken && (
          <TextAlignCenter>
            <TextButton
              data-qa="pending-referrals-show-more-button"
              onClick={handleClick}
            >
              {translate("referrals.content.pending.show_more")}
            </TextButton>
          </TextAlignCenter>
        )}
      </Flex>
    </>
  );
};

export default PendingReferrals;
