import React from "react";

import PendingBooking from "./pending-booking";

const PendingBookingRoute = ({ authtoken, location, params, router }) => {
  const { from_connection: fromConnection = 0 } = location.query;
  const { requestId } = params;

  return (
    <PendingBooking
      fromConnection={Number(fromConnection)}
      requestId={requestId}
      router={router}
      token={authtoken}
    />
  );
};

export default PendingBookingRoute;
