import { connect } from "react-redux";
import Actions from "datamodel/SearchFilters/actions";
import { getToken, getUserId } from "datamodel/Auth/selectors";

import SeekerPreferences from "./seeker-preferences";

const mapStateToProps = (state) => ({
  token: getToken(state),
  userId: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  setParams: (searchFilters, searchUpdate) => {
    dispatch(Actions.setParams({ searchFilters, searchUpdate }));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SeekerPreferences);
