import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";

export const saveTokenToApi = (params, token) => {
  const { BADI_SAVE_DEVICE_TOKEN } = envVars();
  const serverUrl = getServerUrl(BADI_SAVE_DEVICE_TOKEN);
  return fetch(serverUrl, {
    method: "POST",
    params,
    token,
  });
};

export const deleteTokenFromApi = (params, token) => {
  const { BADI_DELETE_DEVICE_TOKEN } = envVars();
  const serverUrl = getServerUrl(BADI_DELETE_DEVICE_TOKEN);
  return fetch(serverUrl, {
    method: "DELETE",
    params,
    token,
  });
};
