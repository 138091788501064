import React, { useCallback } from "react";
import { Avatar, Spacer } from "@badi/badi-components";
import { modal } from "containers/ReduxModal/emitter";
import { openOtherUserProfileModal } from "components/other-user-profile-modal";
import ChatMessage from "components/chat-message";
import PictureMessage from "components/chat-picture-message";
import SystemMessage from "components/system-message";
import { MESSAGE_TYPES } from "../../constants";

function openPictureModal(url) {
  modal.add(
    <img alt="chat shared media" className="message__picture_zoom" src={url} />,
    {
      className: "popup_image",
    },
  );
}

const Message = ({
  currentUserId,
  user = {},
  url = null,
  annotations = [],
  id,
  type,
  date,
  content,
  token,
  statusLevel,
}) => {
  const components = [];
  const isCurrentUserMessage = currentUserId === user.id;

  const handleShowUserProfile = useCallback(() => {
    if (isCurrentUserMessage) return null;

    return openOtherUserProfileModal(token, user.id);
  }, [user.id, currentUserId]);

  const handleEnlargePicture = useCallback(() => openPictureModal(url), [url]);

  const avatar = <Avatar onClick={handleShowUserProfile} src={user.avatar} />;

  switch (type) {
    case MESSAGE_TYPES.PICTURE:
      components.push(
        <Spacer bottom={4} key={id}>
          <PictureMessage
            avatar={avatar}
            date={date}
            isCurrentUser={isCurrentUserMessage}
            name={user.name}
            onClick={handleEnlargePicture}
            url={url}
          />
        </Spacer>,
      );
      break;
    default:
      components.push(
        <Spacer bottom={4} key={id}>
          <ChatMessage
            avatar={avatar}
            date={date}
            isCurrentUser={isCurrentUserMessage}
            message={content}
            name={user.name}
            isFastTrack={!isCurrentUserMessage && statusLevel === "priority"}
          />
        </Spacer>,
      );
  }

  annotations.map((annotation) =>
    components.push(<SystemMessage key={annotation.id} {...annotation} />),
  );

  return components;
};

export default Message;
