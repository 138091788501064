import { getPicture } from "base/shared/Utils";

export default class UserCompany {
  constructor(data) {
    this.entity = data;
  }

  get name() {
    return this.entity.name;
  }

  get description() {
    return this.entity.description;
  }

  get picture() {
    return getPicture([this.entity.picture], "width_100_url");
  }

  get pictures() {
    return [this.entity.picture];
  }

  toJSON() {
    return {
      name: this.name,
      description: this.description,
      picture: this.picture,
      pictures: this.pictures,
    };
  }
}
