import React, { useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  Button,
  Text,
  Spacer,
  Flex,
  BREAKPOINTS_PX,
  useMatchMedia,
} from "@badi/badi-components";
import Image from "components/Image";
import { Root, ImageWrapper, Wrapper } from "./styled-components";
import { TOP_BANNER_TYPES } from "./constants";
import { getTextColor, getButtonVariant } from "./utils";

const machmedia = [`(min-width: ${BREAKPOINTS_PX.lg})`];

const TopBanner = ({
  onClick,
  dataQa,
  imageSrc,
  title,
  subTitle,
  buttonText,
  type = TOP_BANNER_TYPES.DEFAULT,
}) => {
  const [visibleButton] = useMatchMedia(machmedia);

  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Root type={type}>
      <Container>
        <Row>
          <Col>
            <Wrapper data-qa={dataQa} onClick={handleClick} role="button">
              <Spacer bottom={1.5} top={1.5}>
                <Flex alignItems="center" gutter={2}>
                  <ImageWrapper width={7}>
                    <Image
                      alt={`${dataQa} image`}
                      lazy={false}
                      src={imageSrc}
                    />
                  </ImageWrapper>
                  <div>
                    <Text
                      bold={true}
                      color={getTextColor(type)}
                      dataQa={`${dataQa}-title`}
                    >
                      {title}
                    </Text>
                    <Text
                      body={3}
                      color={getTextColor(type)}
                      dataQa={`${dataQa}-subtitle`}
                    >
                      {subTitle}
                    </Text>
                  </div>
                </Flex>
              </Spacer>
              {visibleButton && (
                <Button
                  data-qa={`${dataQa}-button`}
                  onClick={handleClick}
                  variant={getButtonVariant(type)}
                >
                  {buttonText}
                </Button>
              )}
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </Root>
  );
};

export default TopBanner;
