import React, { PureComponent } from "react";

import {
  checkPhoneConfirmation,
  requestPhoneConfirmation,
} from "datamodel/User/api";
import UserFeedback from "base/shared/user-feedback";
import fetch from "base/shared/fetch-data";
import { StatusCard } from "@badi/badi-components";

import envVars from "base/shared/Env";
import { getAPIUrl } from "base/shared/Utils";
import { locale } from "base/shared/Localization";
import { countryCodesOptions } from "../utils";

import RequestCode from "./statuses/request-code";
import CheckCode from "./statuses/check-code";
import Verified from "./statuses/verified";

import { VARIANTS, STATUS } from "./constants";

/**
 * TODO: change `isConfirmed` to `verified` as email verification
 * TODO: change `phone` prop to `code` as email verification
 */

const getPhoneCountryCodes = (token) => {
  const { BADI_COUNTRY_CODES } = envVars();
  const serverUrl = getAPIUrl(BADI_COUNTRY_CODES);

  return fetch(serverUrl, {
    token,
    additionalParams: { locale: locale() },
  }).then((r) => countryCodesOptions(r.data));
};

const isLightVariant = (variant) => variant === VARIANTS.LIGHT;
const isStatusVerified = (status) => status === STATUS.VERIFIED;
const isStatusCheck = (status) => status === STATUS.CHECK;

const getStepComponent = (status) => {
  switch (status) {
    case STATUS.VERIFIED:
      return Verified;
    case STATUS.CHECK:
      return CheckCode;
    default:
      return RequestCode;
  }
};

class PhoneVerification extends PureComponent {
  state = {
    status: STATUS.REQUEST,
    countryCodes: [],
    isLoading: true,
  };

  componentDidMount() {
    const { authtoken } = this.props;

    getPhoneCountryCodes(authtoken).then((countryCodes) => {
      this.setState({
        countryCodes,
        isLoading: false,
      });
    });
  }

  static getDerivedStateFromProps(props) {
    const { verified } = props;
    // if the user is already verified
    if (verified) {
      return {
        status: STATUS.VERIFIED,
      };
    }

    return null;
  }

  handleCodeSubmission = (data) => {
    const { authtoken, onSuccess, setConfirmation, getPaymentDetails } =
      this.props;
    this.setState({ isLoading: true });

    checkPhoneConfirmation(authtoken, data)
      .then((result) => {
        if (!result.confirmed) {
          throw new Error();
        }

        getPaymentDetails();
        onSuccess();

        setConfirmation("phone");
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        UserFeedback.exception(
          "notifications.error.title",
          error.message,
          error,
        );
      });
  };

  handleCodeRequest = (data) => {
    const { authtoken } = this.props;

    requestPhoneConfirmation(authtoken, data)
      .then(() => {
        UserFeedback.success(
          "verifications.phone.notifications.sms_sent_title",
          "verifications.phone.notifications.sms_sent_message",
        );

        this.setState({ status: STATUS.CHECK });
      })
      .catch((e) => {
        UserFeedback.exception("notifications.error.title", e.message, e);
      });
  };

  toggleStep = () => {
    this.setState((state) => ({
      status: isStatusCheck(state.status) ? STATUS.REQUEST : STATUS.CHECK,
    }));
  };

  render() {
    const { phoneNumber, variant, dataQa } = this.props;
    const { status, countryCodes, isLoading } = this.state;

    const onSubmit = isStatusCheck(status)
      ? this.handleCodeSubmission
      : this.handleCodeRequest;

    const StepComponent = getStepComponent(status);
    let wrapperProps;
    let WrapperComponent;

    if (isLightVariant(variant)) {
      WrapperComponent = "div";
      wrapperProps = { "data-qa": dataQa };
    } else {
      WrapperComponent = StatusCard;
      wrapperProps = {
        dataQa,
        ...(isStatusVerified(status)
          ? { isEditable: false, isVerified: true }
          : {}),
      };
    }

    return (
      <WrapperComponent {...wrapperProps}>
        <StepComponent
          countryCodes={countryCodes}
          disabled={isLoading}
          onSubmit={onSubmit}
          phoneNumber={phoneNumber}
          toggleStep={this.toggleStep}
        />
      </WrapperComponent>
    );
  }
}

PhoneVerification.defaultProps = {
  dataQa: "phone-verification",
  getPaymentDetails: Promise.resolve,
  onSuccess: () => {},
  phoneNumber: "",
  variant: VARIANTS.DEFAULT,
};

export default PhoneVerification;
