export const getSelectedIds = (elements) => {
  if (!Array.isArray(elements)) return [];
  return elements.filter((elem) => elem.selected).map((elem) => elem.id);
};

export const getSelectedElements = (elements, ids) =>
  elements.map((elem) => ({
    ...elem,
    selected: ids.includes(elem.id),
  }));
