import React from "react";
import { Flex, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";
import { Root } from "./styled-components";

const MenuElement = ({
  action: { key, icon = "", text },
  actionFunction,
  dataQa = null,
}) => {
  const handleClick = () => {
    actionFunction[key]();
  };

  return (
    <Root data-qa={dataQa && `${dataQa}-menu-element`} onClick={handleClick}>
      <Flex alignItems="center" gutter={1}>
        {icon && <Image src={icon} styles={{ display: "block" }} />}
        <Text body="12-Bold" inline={true}>
          {translate(text)}
        </Text>
      </Flex>
    </Root>
  );
};

export default MenuElement;
