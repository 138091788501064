import React from "react";
import { Icon } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";

export const Female = ({ checked = true }) => {
  const srcPath = checked
    ? "icons/icon-girl-on.svg"
    : "icons/icon-girl-off.svg";
  return (
    <Icon
      alt="girl"
      iconMaxHeight={40}
      iconMaxWidth={40}
      src={getPublicAsset(srcPath)}
    />
  );
};

export const Male = ({ checked = true }) => {
  const srcPath = checked ? "icons/icon-boy-on.svg" : "icons/icon-boy-off.svg";
  return (
    <Icon
      alt="boy"
      iconMaxHeight={40}
      iconMaxWidth={40}
      src={getPublicAsset(srcPath)}
    />
  );
};

export const NoBinary = ({ checked = true }) => {
  const srcPath = checked
    ? "icons/no-binary-on.svg"
    : "icons/no-binary-off.svg";
  return (
    <Icon
      alt="no-binary"
      iconMaxHeight={40}
      iconMaxWidth={40}
      src={getPublicAsset(srcPath)}
    />
  );
};

export const getGenderIconById = (gender) => {
  switch (gender) {
    case 1:
      return <Female />;
    case 2:
      return <Male />;
    case 3:
      return <NoBinary />;
    default:
      return null;
  }
};
