import React from "react";

import { Badge, Icon, BADI_COLORS } from "@badi/badi-components";
import { StyledBadge, IconWrap } from "./styled-components";

export const CheckIcon = () => (
  <Badge
    as={StyledBadge}
    backgroundColor={BADI_COLORS.BRAND.PRIMARY.DEFAULT}
    data-qa="photo=verification-detail-badge"
    rounded={true}
  >
    <IconWrap data-qa="photo=verification-detail-tic">
      <Icon color={BADI_COLORS.NEUTRAL.NEUTRAL_00} name="tic" />
    </IconWrap>
  </Badge>
);
