import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

// this fixes inner top bar css assumption on the items being all <li>
export const TopBarInner = styled.div`
  height: 100%;
  padding: 0 ${unitizedPx(3)};
`;

export const ProgressBarRoot = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 4px;
  background-image: linear-gradient(
    to right,
    #38fea1,
    #3aa4e9 34%,
    #5e7bf0 48%,
    #943ffa 71%,
    #ff3888
  );
`;

export const Uncompleted = styled.div`
  float: right;
  height: 4px;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  width: ${({ percentage }) => percentage}%;
`;
