import styled from "styled-components";

import { colors } from "@badi/badi-components";

export const CardBorder = styled.div`
  border: 2px solid ${colors.softGrey};
  border-radius: 4px;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  margin-bottom: 8px;
`;

export const ListLink = styled.a`
  color: inherit;

  :hover {
    color: inherit;
  }
`;

export const SvgIcon = styled.svg`
  display: block;
  margin: 4px 8px 0 0;
  flex: 0 0 12px;
`;
