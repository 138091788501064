import React from "react";
import { getConversationPath } from "Sections/chat/routes";
import { getInboxPath } from "Sections/inbox/routes";
import { getGoldUpgradePath } from "Sections/plans/routes";
import { PLAN_FEATURES } from "datamodel/plans/constants";
import Enquiry from "./enquiry-mapping";

const EnquiryRoute = ({ router, params: { id } }) => {
  const connectionId = Number(id);

  const handleAcceptEnquiry = () => {
    router.replace(getConversationPath({ connectionId }));
  };

  const handleCancel = () => {
    router.goBack();
  };

  const handleLeaveConnection = () => {
    router.replace(getInboxPath());
  };

  const handleOpenBadiGold = (roomId) => {
    router.push(
      getGoldUpgradePath({
        feature: PLAN_FEATURES.ACCEPT_MATCH,
        roomId,
        initiator: location.pathname,
      }),
    );
  };

  return (
    <Enquiry
      connectionId={connectionId}
      onAcceptEnquiry={handleAcceptEnquiry}
      onCancel={handleCancel}
      onLeaveConnection={handleLeaveConnection}
      openBadiGold={handleOpenBadiGold}
    />
  );
};

export default EnquiryRoute;
