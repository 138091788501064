import React, { Children, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styled from "styled-components";
import "./stylesExt.css";
import { getPublicAsset } from "base/shared/Utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviousNextButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 20px;
`;

const ArrowButton = styled.button`
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-color: white;
  border: 2px solid grey;
  border-radius: 50%;
  cursor: pointer;
`;

const PreviousButton = styled(ArrowButton)`
  background-image: url("${getPublicAsset("icons/slider-arrow-prev.svg")}");
`;

const NextButton = styled(ArrowButton)`
  background-image: url("${getPublicAsset("icons/slider-arrow-next.svg")}");
`;

const Slider = ({ children }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start" });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <Container>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {Children.map(children, (child, index) => (
            <div className="embla__slide" key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
      <PreviousNextButtonsContainer>
        <PreviousButton onClick={scrollPrev} />
        <NextButton onClick={scrollNext} />
      </PreviousNextButtonsContainer>
    </Container>
  );
};

export default Slider;
