import React from "react";
import { Card, Spacer } from "@badi/badi-components";
import RoomAdminProfile from "components/Room/room-admin-profile/";

import {
  LowSection,
  UserWrapper,
  TopSectionOtherProfile,
} from "components/User/Sections";

import SidebarMeta from "./components/sidebar-meta";

const RoomDetailSidebar = ({
  inboxConnectionId,
  owned = false,
  owner = null,
  showEnquiry = true,
  roomId,
  sendEnquiry = () => {},
  token,
  user = null,
}) => {
  const userData = owner || user;
  const isRoomAdminProfileFeatureActive = true;

  return (
    <Spacer bottom={5} left={2} right={2} sm={{ left: 0, right: 0 }}>
      <Card>
        {userData && (
          <UserWrapper format="otherProfile">
            {isRoomAdminProfileFeatureActive ? (
              <RoomAdminProfile format="otherProfile" user={userData} />
            ) : (
              <TopSectionOtherProfile
                format="otherProfile"
                user={userData}
                showVerificationFlag={false}
              />
            )}
            <LowSection user={userData} />
            {!owned && owner && (
              <SidebarMeta
                inboxConnection={inboxConnectionId}
                name={userData.name}
                roomId={roomId}
                sendEnquiry={sendEnquiry}
                showEnquiry={showEnquiry}
                token={token}
                userId={userData.id}
              />
            )}
          </UserWrapper>
        )}
      </Card>
    </Spacer>
  );
};

export default RoomDetailSidebar;
