const getContentClass = (type) => {
  switch (type) {
    case "center":
      return "content--centered";
    default:
      return null;
  }
};

const getPositionClass = (type) => {
  switch (type) {
    case "bottom":
      return "layer--bottom";
    default:
      return null;
  }
};

const getSizeClass = (type) => {
  switch (type) {
    case "full":
      return "layer--full";
    default:
      return null;
  }
};

export { getContentClass, getPositionClass, getSizeClass };
