import React, { Component } from "react";
import classNames from "classnames";

import SelectMultipleItem from "./SelectMultipleItem";
import "./SelectMultipleExt.css";

class SelectMultiple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleOptions: false,
    };

    this.toggleOptionsHandler = this.toggleOptionsHandler.bind(this);
    this.clickOptionHandler = this.clickOptionHandler.bind(this);
  }

  clickOptionHandler(e) {
    e.preventDefault();

    this.props.onChange(e.currentTarget.value);
  }

  toggleOptionsHandler() {
    this.setState({ visibleOptions: !this.state.visibleOptions });
  }

  render() {
    const { className, options, placeholder, selectClassName, value, dataQa } =
      this.props;
    const { visibleOptions } = this.state;

    const newPlaceholder =
      options
        .filter((o) => value.includes(o.value))
        .map((o) => o.label)
        .join(", ") || placeholder;

    return (
      <div className={classNames("Form__SelectMultiple--wrapper", className)}>
        <div
          className={classNames(
            "Form__SelectMultiple--select",
            selectClassName,
          )}
          data-qa={dataQa}
        >
          <div
            className={classNames("Form__SelectMultiple--placeholder", {
              "Form__SelectMultiple--active": visibleOptions || !!value.length,
            })}
            onClick={this.toggleOptionsHandler}
            role="button"
            tabIndex="-1"
          >
            {newPlaceholder}
          </div>
          <div
            className={
              visibleOptions ? "Form__SelectMultiple--outer-background" : ""
            }
            onClick={this.toggleOptionsHandler}
            role="button"
            tabIndex="-2"
          />
          <div
            className={classNames(
              "Form__SelectMultiple--options",
              visibleOptions
                ? "Form__SelectMultiple--options-visible"
                : "Form__SelectMultiple--options-hidden",
            )}
          >
            <div className="Form__SelectMultiple--options-list">
              {options.map((v) => (
                <SelectMultipleItem
                  key={v.value}
                  {...v}
                  dataQa={`${dataQa}-option-${v.value}`}
                  onClick={this.clickOptionHandler}
                  selected={value.includes(v.value)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectMultiple.defaultProps = {
  className: "",
  dataQa: "",
  selectClassName: null,
  placeholder: "",
  value: [],
};

export default SelectMultiple;
