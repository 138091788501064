import React, { PureComponent } from "react";

import UserFeedback from "base/shared/user-feedback";

import RequestCode from "./statuses/request-code";
import CodeVerification from "./statuses/code-verification";
import Verified from "./statuses/verified";

const STATUS_REQUEST = "request";
const STATUS_CHECK = "check";
const STATUS_VERIFIED = "verified";

class EmailVerification extends PureComponent {
  state = {
    status: STATUS_REQUEST,
    isLoading: false,
  };

  static getDerivedStateFromProps(props) {
    // if the user is already verified
    if (props.verified) {
      return {
        status: STATUS_VERIFIED,
      };
    }

    // if we have a code requested
    if (props.code) {
      return {
        status: STATUS_CHECK,
      };
    }

    return null;
  }

  componentDidMount() {
    const { code } = this.props;

    if (code) this.handleCodeVerification(code);
  }

  handleRequestSubmit = (verificationCode) => {
    const { requestEmailConfirmation } = this.props;

    this.setState({ isLoading: true });

    // TODO check cookie functionality
    // setRequestVerificationCookie('badi_email_verification_timeout');
    requestEmailConfirmation(verificationCode)
      .then(() => {
        UserFeedback.success(
          "verifications.email.notifications.success_title",
          "verifications.email.notifications.success_message",
        );

        this.setState({ status: STATUS_CHECK, isLoading: false });
      })
      .catch((err) => {
        UserFeedback.exception("notifications.error.title", err.message, err);
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleCodeVerification = (verificationCode) => {
    this.setState({ isLoading: true });
    const { checkEmailConfirmation, setConfirmation, onSuccess } = this.props;

    checkEmailConfirmation(verificationCode)
      .then((result) => {
        if (!result.confirmed) {
          throw new Error("verifications.email.notifications.error_message");
        }

        onSuccess().then(() => {
          setConfirmation("email");
        });
      })
      .catch((error) => {
        UserFeedback.exception(
          "notifications.error.title",
          error.message,
          error,
        );
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  toggleStep = () => {
    this.setState((state) => ({
      status: state.status === STATUS_REQUEST ? STATUS_CHECK : STATUS_REQUEST,
    }));
  };

  render() {
    const { email, dataQa } = this.props;
    const { status, isLoading } = this.state;

    switch (status) {
      case STATUS_VERIFIED:
        return <Verified dataQa={dataQa} email={email} />;
      case STATUS_CHECK:
        return (
          <CodeVerification
            dataQa={dataQa}
            disabled={isLoading}
            onSubmit={this.handleCodeVerification}
            toggleStep={this.toggleStep}
          />
        );
      default:
        return (
          <RequestCode
            dataQa={dataQa}
            disabled={isLoading}
            onSubmit={this.handleRequestSubmit}
            toggleStep={this.toggleStep}
          />
        );
    }
  }
}

EmailVerification.defaultProps = {
  code: "",
  dataQa: "email-verification",
  email: "",
  onSuccess: Promise.resolve,
};

export default EmailVerification;
