import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "ADD_MODAL",
  "CLEAR_ALL",
  "DESTROY_MODAL",
  "REMOVE_MODAL",
  "UPDATE_MODAL",
  "UPDATE_MODALOPTIONS",
]);
