import React from "react";
import styled from "styled-components";

import { translate } from "base/shared/Localization";
import HelpIcon from "components/Icons/Help";

const Root = styled.a`
  display: block;

  > svg {
    display: block;
  }
`;

const InboxHelpButton = () => (
  <Root
    href={translate("inbox.help.url")}
    rel="noopener noreferrer"
    target="_blank"
  >
    <HelpIcon />
  </Root>
);

export default InboxHelpButton;
