import { createSelector } from "reselect";

export const getRequirements = (state) => state.Requirements;

export const getTermsAndConditions = createSelector(
  getRequirements,
  (requirements) => requirements.termsAndConditions,
);

export const getCountryVatRequirement = createSelector(
  getRequirements,
  (requirements) => requirements.countryVat,
);
