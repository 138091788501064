import { BREAKPOINTS_PX } from "@badi/badi-components";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.md})`];

export const STATUS = {
  IN_PROGRESS: 0,
  SUBMITTED: 1,
  EXPIRED: 2,
};

export const STEPS = {
  [STATUS.IN_PROGRESS]: {
    id: "in-progress-step",
    title: "review.popup_title",
    descriptionLister: "review.popup_subtitle_lister",
    descriptionSeeker: "review.popup_subtitle_seeker",
    button: "review.popup_cta_submit",
  },
  [STATUS.SUBMITTED]: {
    id: "submitted-step",
    title: "review.submitted.popup_title",
    description: "review.submitted.popup_subtitle",
    illustration: "/assets/images/fists.svg",
    button: "review.submitted.popup_cta_gotit",
  },
  [STATUS.EXPIRED]: {
    id: "expired-step",
    title: "review.expired_title",
    description: "review.expired_subtitle",
    illustration: "/assets/images/fail-scene-illustration.svg",
  },
};
