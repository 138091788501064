export const LISTER_USER_ROLE = "lister";
export const SEEKER_USER_ROLE = "seeker";
export const DEFAULT_USER_ROLE = SEEKER_USER_ROLE;

export const USER_STATUS = {
  UNREGISTERED: "unregistered",
  PROFILE_COMPLETE: "profile_complete",
  PROFILE_INCOMPLETE: "profile_uncomplete",
};

export const USER_IS_COUPLE = {
  SINGLE: 1,
  COUPLE: 2,
};

export const USER_ONBOARDING_TYPE = {
  GENERAL: "general",
  LISTER: "lister_role",
};

export const LEGAL_STATUS = {
  INITIAL: 1,
  LOADING: 2,
  LOADED: 3,
};

export const LEGAL_RESPONSE = {
  ACCEPTED: 1,
  REJECTED: 2,
  UNANSWERED: 0,
  UNKNOWN: 3,
};

export const LOGIN_TYPE = {
  UNKNOWN: 0,
  FACEBOOk: 1,
  GOOGLE: 2,
  EMAIL: 3,
};

export const USER_LEGAL_CONSENT = {
  MARKETING: "marketing_consent",
  TERMS: "tos_consent",
  PRIVACY: "privacy_policy_consent",
};

export const SOCIAL_MEDIA_TYPES = [
  {
    media: "instagram",
    icon: "icons/new-instagram.svg",
    order: 3,
  },
  {
    media: "twitter",
    icon: "icons/new-twitter.svg",
    order: 4,
  },
  {
    media: "linkedin",
    icon: "icons/new-linkedin.svg",
    order: 2,
  },
  {
    media: "facebook",
    icon: "icons/new-fb.svg",
    order: 1,
  },
];
