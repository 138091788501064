import { BREAKPOINTS_PX } from "@badi/badi-components";
import { getDuplicateListingPath } from "app/Sections/Room/routes";

export const ACTIONS_MAPPER = {
  rent_by_rooms: {
    label: "listing-type-option-room",
    text: "listing.flow.room",
    value: 1,
    icon: "icons/room.svg",
  },
  rent_entire_apartment: {
    label: "listing-type-option-entire-property",
    text: "listing.flow.entire",
    value: 2,
    icon: "icons/home.svg",
  },
};

export const OTHER_ACTIONS_MAPPER = {
  duplicate_room: {
    label: "listing-action-duplicate",
    text: "listing.flow.duplicate.button",
    icon: "icons/clone_listing.svg",
    path: getDuplicateListingPath(),
  },
};

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.lg})`];
