import { getPushNotifications } from "datamodel/Features/selectors";
import {
  getIsChrome,
  getIsFirefox,
  getIsIOS,
} from "datamodel/Device/selectors";

import { stopPushNotificationsAndLogout } from "./utils";

export const onLogoutSuccess = (successAction, dispatch, next, getState) => {
  const state = getState();
  const arePushNotificationsEnabled = getPushNotifications(state);
  const arePushNotificationsSupported =
    (getIsChrome(state) || getIsFirefox(state)) && !getIsIOS(state);

  if (!arePushNotificationsEnabled || !arePushNotificationsSupported) {
    next(successAction);
    return;
  }

  stopPushNotificationsAndLogout(successAction, next);
  googleLogout();
};

const googleLogout = () => {
  try {
    google.accounts.id.disableAutoSelect();
  } catch (err) {}
};
