import React from "react";

import { withRouter } from "react-router";
import { getEditRoomPath } from "Sections/Room/routes";
import EditRoomButton from "./edit-room-button";

const EditRoomButtonRoute = ({ router, ...rest }) => {
  const handleEditRoom = ({ id, publish }) => {
    router.push(getEditRoomPath({ id, publish }));
  };

  return <EditRoomButton onEditRoom={handleEditRoom} {...rest} />;
};

export default withRouter(EditRoomButtonRoute);
