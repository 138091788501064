import styled from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const Root = styled.footer`
  background-color: #f3f1e9;
  overflow: hidden;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaquery.xl`
    flex-direction: row;
  `}
`;

export const FooterNavigation = styled.div`
  ${mediaquery.lg`
    flex: 1;
  `}
`;
