import React, { memo, useState, useEffect } from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { GENDERS } from "base/shared/StaticData";

import FormBlock from "components/form-block";
import Choice from "components/Form/Choice/ChoiceWithFormsy";

const Gender = ({ gender = 0, isValid, saved }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(translate(GENDERS));
  }, []);

  return (
    <>
      <FormBlock
        dataQa="profile-form-gender-section"
        id="formblock-gender"
        isRequired={true}
        saved={saved}
        title={translate("profile.form.label.gender")}
        valid={isValid}
      >
        <Choice
          className="centered-flex-row"
          items={options}
          multi={false}
          name="gender"
          required={true}
          value={gender}
        />
      </FormBlock>
      <Spacer bottom={2} />
    </>
  );
};

export default memo(Gender);
