import React from "react";

const Calendar = ({ className, color, height, width }) => (
  <svg
    className={className}
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <rect
        height="47"
        rx="4"
        stroke={color}
        transform="translate(-16 -12)"
        width="369"
        x=".5"
        y=".5"
      />
      <path
        d="M16.004 2a1 1 0 01.993.883l.007.117v1H18a4 4 0 013.995 3.8L22 8v5.01a1 1 0 01-1.993.117L20 13.01V8a2 2 0 00-1.85-1.995L18 6h-.996v1a1 1 0 01-1.993.117L15.004 7V6h-6v1a1 1 0 01-1.993.117L7.004 7V6H6a2 2 0 00-1.995 1.85L4 8v10a2 2 0 001.85 1.995L6 20h12a2 2 0 001.995-1.85L20 18v-.933a1 1 0 011.993-.117l.007.117V18a4 4 0 01-3.8 3.995L18 22H6a4 4 0 01-3.995-3.8L2 18V8a4 4 0 013.8-3.995L6 4h1.004V3a1 1 0 011.993-.117L9.004 3v1h6V3a1 1 0 011-1zm-7 8a3 3 0 010 6h-1a3 3 0 010-6zm0 2h-1a1 1 0 000 2h1a1 1 0 000-2z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

Calendar.defaultProps = {
  className: "",
  color: "#1e1e1e",
  height: 24,
  width: 24,
};

export default Calendar;
