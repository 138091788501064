import React from "react";
import { Container, Col, Row } from "reactstrap";

import { Spacer } from "@badi/badi-components";

import { Root, Content } from "./styled-components";

const ButtonsWrapper = ({ children = null }) => {
  return (
    <Root>
      <Container>
        <Row>
          <Col lg={6} xs={12}>
            <Content>
              <Spacer bottom={3} top={3}>
                {children}
              </Spacer>
            </Content>
          </Col>
        </Row>
      </Container>
    </Root>
  );
};

export default ButtonsWrapper;
