import { v4 as uuidv4 } from "uuid";

export const PICTURE_STATUS = {
  success: "success",
  loading: "loading",
  error: "error",
};

const getFile = (file) =>
  file.tempId
    ? { ...file, uploadStatus: PICTURE_STATUS.loading }
    : {
        file,
        tempId: uuidv4(),
        uploadStatus: PICTURE_STATUS.loading,
      };

export const getFilesToUpload = (currentFiles, filesToUpload, maxFiles) => {
  const newValues = [...currentFiles];

  const filesToDrop = filesToUpload.reduce((prev, curr) => {
    const file = getFile(curr);

    const i = newValues.findIndex((f) => f.tempId === file.tempId);

    if (i >= 0) {
      newValues[i] = file;
      prev.push(file);

      return prev;
    }

    if (newValues.length < maxFiles) {
      newValues.push(file);
      prev.push(file);
    }

    return prev;
  }, []);

  return { values: newValues, files: filesToDrop };
};

export const isUploaded = ({ uploadStatus }) =>
  uploadStatus === PICTURE_STATUS.success;
