import React from "react";
import { Heading } from "@badi/badi-components";
import { StyledHeading } from "./styled-components";

const Headline = ({ content, color, dataQa }) => (
  <Heading as={StyledHeading} color={color} data-qa={dataQa} level="16-Bold">
    {content}
  </Heading>
);

export default Headline;
