const SocketModel = {
  status: 2,
  retries: 0,
  error: false,
  socket: null,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Socket) {
    return { ...SocketModel, error: "There was an error in SocketModel." };
  }
  return state;
}

export { SocketModel, setInitialState };
