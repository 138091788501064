import styled from "styled-components";
import { unitizedPx, mediaquery, BADI_COLORS } from "@badi/badi-components";

export const Header = styled.h2`
  font-size: 1.5rem;
  line-height: ${unitizedPx(4)};
  font-weight: bold;
  margin: 0;

  ${mediaquery.md`
    font-size: 1.75rem;
    line-height: ${unitizedPx(4.5)};
  `}
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${unitizedPx(8)});
  overflow: scroll;
  ${mediaquery.lg`
    height: calc(100vh - ${unitizedPx(10)});
  `};
`;
export const RoomsNotFoundDescription = styled.div`
  text-align: center;
`;

export const RoomsNotFoundTitle = styled.h3`
  text-align: center;
`;

export const RoomsNotFoundWrapper = styled.div`
  background-color: white;
  position: relative;
  height: ${unitizedPx(31.5)};
  margin: 0 auto;
  top: 5%;

  ${mediaquery.md`
    top: 20%;
  `};
`;

export const PaginationWrapper = styled.div`
  margin: 0 auto;
`;

export const MapWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const ResultsWrapper = styled.div`
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${mediaquery.lg`
    flex-direction: row;
    width: 100%;
  `}
`;

export const SearchRoomListWrapperMain = styled.div`
  position: relative;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  flex-grow: 1;

  ${mediaquery.lg`
    width: 50%;
    max-width: ${unitizedPx(73)};
    flex-shrink: 1;
`}

  ${mediaquery.xl`
    width: 65%;
    max-width: ${unitizedPx(102)};
`}

${mediaquery.xxl`
    max-width: ${unitizedPx(108)};
`}
`;

export const SearchRoomListWrapperContainer = styled.div`
  left: 0;
  top: 0;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  padding: 0 ${unitizedPx(1)};

  ${mediaquery.md`
      width: calc(100% + ${unitizedPx(15)});
      padding:  0 ${unitizedPx(18)} 0 ${unitizedPx(3)};

          &::-webkit-scrollbar {
            display: none;
          }
          scrollbar-width: none;
          &::-ms-overflow-style: none;
    `}
`;
