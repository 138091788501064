import ReviewUser from "./review-user-model";

export default class Review {
  constructor(data) {
    this.entity = data || {};
  }

  get id() {
    return this.entity.id;
  }

  get reviewer() {
    return new ReviewUser(this.entity.reviewer);
  }

  get text() {
    return this.entity.text || "";
  }

  get collectedAt() {
    return this.entity.collected_at || "";
  }

  toJSON() {
    return {
      id: this.id,
      reviewer: this.reviewer.toJSON(),
      text: this.text,
      collectedAt: this.collectedAt,
    };
  }
}
