import React from "react";
import { TwitterShareButton } from "react-share";

import "./shareExt.css";

const Twitter = ({ target, title }) => (
  <TwitterShareButton className="Button__share" title={title} url={target}>
    <div className="Button__share_twitter" />
  </TwitterShareButton>
);

export default Twitter;
