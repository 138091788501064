import React from "react";
import { getOverviewPath } from "Sections/overview/routes";
import { getHomePath } from "Sections/Home/routes";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import AboutBadiMapping from "./about-badi-mapping";

const AboutBadiRoute = ({ router }) => {
  const redirectToOverview = () => {
    router.push(getOverviewPath());
  };

  const redirectToHome = () => {
    router.push(getHomePath());
  };

  const redirectToRentRoom = () => {
    router.push(getRentRoomPath());
  };

  return (
    <AboutBadiMapping
      onListerJoin={redirectToOverview}
      onPublishRoom={redirectToRentRoom}
      onSearch={redirectToHome}
      onSeekerJoin={redirectToHome}
    />
  );
};
export default AboutBadiRoute;
