import React from "react";

import Image from "components/Image/Image";
import { Spacer, Heading, Flex, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const SuccessScreen = () => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      fullHeight={true}
      justify="center"
    >
      <Image src="/assets/images/message-enquiry-sent.svg" />
      <Spacer bottom={1} top={4}>
        <Heading level={2}>{translate("room_invite.success.heading")}</Heading>
      </Spacer>
      <Spacer left={1} right={1}>
        <Text align="center">
          {translate("room_invite.success.description")}
        </Text>
      </Spacer>
    </Flex>
  );
};

export default SuccessScreen;
