import { connect } from "react-redux";

import Property from "app/Sections/Room/components/Steps/Property/property-form";

import Actions from "datamodel/RoomList/actions";
import {
  getRoomPropertySelector,
  getSizeUnitSelector,
  isEntiredPropertySelector,
} from "datamodel/RoomList/selectors";

import { getPropertyType } from "./utils";

const mapStateToProps = (state) => ({
  storeProperty: getRoomPropertySelector(state),
  sizeUnit: getSizeUnitSelector(state),
  propertyType: getPropertyType(isEntiredPropertySelector(state)),
});

const mapDispatchToProps = {
  setRoomProperty: Actions.setRoomProperty,
};

const mergeProps = (state, dispatch, ownProps) => {
  return {
    ...state,
    ...dispatch,
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Property);
