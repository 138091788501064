import React, { Fragment } from "react";

import {
  Button,
  Flex,
  Heading,
  Icon,
  Spacer,
  Text,
  unitizedPx,
  MatchMedia,
  BREAKPOINTS_PX,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import SuccessCheck from "components/Icons/SuccessCheck";

import { IconWrap, RowWrap, StyledImage, Anchor } from "./styled-components";

export const BENEFITS = {
  seeker: [
    "badi_guarantee.popup.table.seeker.item_scammers",
    "badi_guarantee.popup.table.seeker.item_cancellation",
    "badi_guarantee.popup.table.seeker.item_payment",
  ],
  lister: [
    "badi_guarantee.popup.table.lister.item_cancellation",
    "badi_guarantee.popup.table.lister.item_damage",
    "badi_guarantee.popup.table.lister.item_breach",
  ],
};

const BookingGuarantee = ({
  onClick,
  userRole,
  trackLearnMoreClick = () => {},
}) => {
  const bookingBenefits = BENEFITS[userRole];
  const handleLearnMoreClick = () => {
    trackLearnMoreClick();
  };
  return (
    <Fragment>
      <Flex justify="center">
        <Icon height={80} name="secure" width={76} />
      </Flex>
      <Flex alignItems="center" direction="column" gutter={2}>
        <Heading
          align="center"
          data-qa="booking-guarantee-modal-title"
          level={2}
        >
          {translate("badi_guarantee.popup.title")}
        </Heading>
        <div>
          <Text align="center" data-qa="booking-guarantee-modal-description">
            {translate("badi_guarantee.popup.description")}
            <Anchor
              data-qa="booking-guarantee-modal-link"
              href={translate("booking_flow_learn_more_link")}
              onClick={handleLearnMoreClick}
              rel="noopener noreferrer"
              target="_blank"
            >
              {translate("badi_guarantee.popup.link_text")}
            </Anchor>
          </Text>
        </div>
      </Flex>
      <Spacer bottom={2} top={4}>
        <Flex gutter={1} justify="flexEnd">
          <Text align="center" basis={unitizedPx(11)} body={5}>
            {translate("badi_guarantee.popup.table.heading_without_badi")}
          </Text>
          <Text align="center" basis={unitizedPx(11)} body={4}>
            {translate("badi_guarantee.popup.table.heading_with_badi")}
          </Text>
        </Flex>
      </Spacer>
      {bookingBenefits.map((benefit) => (
        <RowWrap key={benefit}>
          <Spacer bottom={2} top={2}>
            <Flex alignItems="center" gutter={1}>
              <Text body={3} data-qa={`booking-benefit-${benefit}`} grow={1}>
                {translate(benefit)}
              </Text>
              <IconWrap basis={unitizedPx(11)} shrink={0}>
                <StyledImage
                  alt="not-included"
                  src={getPublicAsset("icons/icon-delete.svg")}
                />
              </IconWrap>
              <IconWrap basis={unitizedPx(11)} shrink={0}>
                <StyledImage as={SuccessCheck} size={24} />
              </IconWrap>
            </Flex>
          </Spacer>
        </RowWrap>
      ))}
      <Spacer top={4}>
        <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.md})`}>
          {(matches) => (
            <Flex justify="center">
              <Button
                data-qa="booking-guarantee-modal-button"
                fullWidth={true}
                grow={matches ? 0 : 1}
                onClick={onClick}
              >
                {translate("badi_guarantee.popup.button_text")}
              </Button>
            </Flex>
          )}
        </MatchMedia>
      </Spacer>
    </Fragment>
  );
};

export default BookingGuarantee;
