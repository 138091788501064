import React, { useContext } from "react";
import { Link } from "react-router";
import { Heading, Spacer } from "@badi/badi-components";
import Image from "components/Image";

import MenuContext from "./menu-context";
import { Anchor, Highlight, ImageWrapper } from "./styled-components";

const MenuItemLink = ({
  children,
  dataQa = null,
  external = false,
  highlight = false,
  imageSrc = null,
  onClick = null,
  to = "",
}) => {
  const { onClose } = useContext(MenuContext);
  const props = external ? { href: to, as: "a" } : { to, as: Link };

  const clickHandler = () => {
    onClose();

    if (typeof onClick === "function") {
      onClick();
    }
  };

  return (
    <Anchor data-qa={dataQa} onClick={clickHandler} {...props}>
      <Spacer bottom={2} top={2}>
        {typeof children === "string" ? (
          <Heading as="span" level="16-Medium">
            {children}
          </Heading>
        ) : (
          children
        )}
        {highlight && <Highlight />}
        {imageSrc && (
          <ImageWrapper>
            <Image src={imageSrc} />
          </ImageWrapper>
        )}
      </Spacer>
    </Anchor>
  );
};

export default MenuItemLink;
