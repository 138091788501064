import { translate } from "base/shared/Localization";
import { LISTER_USER_ROLE } from "datamodel/User/constants";

export const GENERIC_STRIPE_ERROR_CODE = "stripe_card_declined";
export const GENERIC_STRIPE_ERROR_MESSAGE = "errors.payment.stripe_failure";
export const GENERIC_BOOKING_ERROR_TITLE =
  "booking.reply.notifications.accept_error_title";

export const getBookingRequestErrorMessage = ({ userRole, error }) => {
  if (
    userRole === LISTER_USER_ROLE ||
    error.code === GENERIC_STRIPE_ERROR_CODE
  ) {
    return translate(GENERIC_STRIPE_ERROR_MESSAGE);
  }
  return `${translate(GENERIC_BOOKING_ERROR_TITLE)} : ${translate(
    error.message,
  )}`;
};
