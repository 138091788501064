import { getZoom } from "components/geo-suggest/utils";
import { getPlaceType, getStayOptions } from "datamodel/SearchFilters/utils";

import { getSelectedElements } from "Sections/Search/search-v2/filters/forms/utils";

import {
  getDistance,
  getSelectedElementsByKey,
  parseBounds,
  parseCoordinates,
} from "./utils";

export default class SuggestedSearchModel {
  constructor(data) {
    this.data = data;
    this.filters = data.filters;

    this.filters.bounds = parseBounds(this.filters.bounds);
    this.geometry = this.filters.bounds
      ? new window.google.maps.LatLngBounds(
          this.filters.bounds.sw,
          this.filters.bounds.ne,
        )
      : null;
  }

  get distance() {
    if (!this.geometry) return null;
    const ne = this.geometry.getNorthEast();
    const center = this.geometry.getCenter();
    return ne && center ? getDistance(ne, center) : null;
  }

  get zoom() {
    return this.distance ? getZoom(this.distance) : null;
  }

  get text() {
    return this.filters.location ? this.filters.location.q : "";
  }

  get placeId() {
    return this.filters.location ? this.filters.location.place_id : "";
  }

  get placeTypes() {
    return this.filters.place_types
      ? getSelectedElements(getPlaceType(), this.filters.place_types)
      : getPlaceType();
  }

  get stayOptions() {
    return this.filters.length_of_stay
      ? getSelectedElementsByKey(
          getStayOptions(),
          this.filters.length_of_stay,
          "apivalue",
        )
      : getStayOptions();
  }

  get bounds() {
    return this.filters.bounds;
  }

  get coordinates() {
    if (this.filters.coordinates)
      return parseCoordinates(this.filters.coordinates);
    if (this.geometry) {
      return {
        lat: this.geometry.getCenter().lat(),
        lng: this.geometry.getCenter().lng(),
      };
    }

    return null;
  }

  get fromDate() {
    return this.filters.available_from ? this.filters.available_from : "";
  }

  get max() {
    return this.filters.max_price || 0;
  }

  toJSON() {
    return {
      placeId: this.placeId,
      bounds: this.bounds,
      coordinates: this.coordinates,
      text: this.text,
      placeTypes: this.placeTypes,
      stayOptions: this.stayOptions,
      fromDate: this.fromDate,
      max: this.max,
      distance: this.distance,
      zoom: this.zoom,
    };
  }
}
