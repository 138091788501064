import React from "react";
import { Col, Row } from "reactstrap";
import { translate } from "base/shared/Localization";
import { Heading, Spacer } from "@badi/badi-components";
import BehaviourCardWidget from "../widgets/behaviour-card-widget";
import {
  getFavoritesValueContent,
  getFavoritesTargetContent,
} from "../../utils";

const RoomDetailsSection = ({ favorites }) => {
  const { daysConsidered, value } = favorites;

  return (
    <Spacer top={3}>
      <Heading
        align="left"
        data-qa="room-details-section-title"
        level="24-Bold"
      >
        {translate("lister.analytics_room_details_title")}
      </Heading>
      <Row>
        <Col>
          <BehaviourCardWidget
            dataQa="room-properties-pictures"
            height={20}
            iconSrc="icons/icon-heart-outline.svg"
            subTitle={translate(
              "lister.analytics_saved_as_favourite_subtitle",
              {
                days: daysConsidered,
              },
            )}
            target={getFavoritesTargetContent(value)}
            title={translate("lister.analytics_saved_as_favourite_title")}
            value={getFavoritesValueContent(value)}
          />
        </Col>
      </Row>
    </Spacer>
  );
};

export default RoomDetailsSection;
