import { generateFetchTypes } from "base/shared/TypeHelper";
import types from "./types";
import { getRooms, getRoomDetails, getListerHints } from "./api";

export const getRoomsAction = ({ sgid }) => ({
  types: generateFetchTypes(types.ANALYTICS_ROOMS_REQUEST),
  request: getRooms,
  params: { sgid },
  requireAuth: true,
});

export const getRoomDetailsAction = ({ roomId, sgid }) => ({
  types: generateFetchTypes(types.ANALYTICS_ROOM_DETAILS_REQUEST),
  request: getRoomDetails,
  params: { roomId, sgid },
  requireAuth: true,
});

export const getListerHintsAction = ({ roomId, sgid }) => ({
  types: generateFetchTypes(types.ANALYTICS_LISTER_HINTS_REQUEST),
  request: getListerHints,
  params: { roomId, sgid },
  requireAuth: true,
});

export const resetAnalyticsAction = () => ({
  type: types.RESET_ANALYTICS,
});
