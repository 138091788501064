import styled from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const SearchBarRoot = styled.li`
  flex-grow: 2;
  padding: 0 16px;
  position: relative;
`;

export const GeosuggestWrapper = styled.div`
  .geosuggest {
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    min-width: 250px;
    max-width: 350px;

    ::after {
      position: absolute;
      left: 12px;
      top: 12px;
      background-image: url("${getCDNUrl()}/assets/icons/search-input.svg");
      background-size: cover;
      content: "";
      width: 23px;
      height: 24px;
    }
  }

  .geosuggest__input {
    border: 0;
    position: relative;
    font-size: 1.06rem;
    font-weight: 500;
    padding: 14px 12px 14px 47px;
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

    :focus {
      border: 0;
      background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
      font-size: 1.06rem;
      font-weight: 500;
    }
  }

  .geosuggest__suggests {
    position: absolute;
    left: -1px;
    right: -1px;
    margin-top: -1px;
    border: 0;
    padding-top: 8px;
    padding-bottom: 36px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    :after {
      position: absolute;
      right: 16px;
      right: 1rem;
      bottom: 16px;
      bottom: 1rem;
      background-image: url("${getCDNUrl()}/assets/images/powered_by_google_on_white_hdpi.png");
      background-size: 144px 18px;
      content: "";
      width: 144px;
      height: 18px;
    }

    li {
      height: 64px;
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
`;
