import React, { useMemo, useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { parse } from "qs";
import {
  BADI_COLORS,
  Icon,
  Flex,
  Heading,
  Text,
  TextButton,
  Checkbox,
  Spacer,
} from "@badi/badi-components";

import envVars from "base/shared/Env";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { getParams } from "base/shared/Utils";

import EmailForm from "../email-form";
import FacebookButton from "./components/facebook-button";
import EmailButton from "./components/email-button";

import { DescriptionWrapper, Root } from "./styled-components";
import { MODE } from "./constants";
import { getFormConfig } from "./utils";

const RememberMeCheckbox = ({ checked, onChange }) => (
  <Checkbox
    id="remember-me"
    name="remember-me"
    checked={checked}
    onChange={onChange}
  >
    <Text body="12-Regular" data-qa={"checkbox-text-remember-me"} inline={true}>
      {translate("login_and_register.remember_me")}
    </Text>
  </Checkbox>
);

const AuthenticationForm = ({
  initialMode = MODE.LOGIN,
  login,
  register,
  userId,
  locale,
  onSuccess = null,
  setIsRememberMeChecked: setIsRememberMeCheckedInAuthState,
}) => {
  const inviteCode = getParams(location.search, "invite_code").invite_code;

  const [email, setEmail] = useState(false);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [mode, setMode] = useState(initialMode);

  const { BADI_AUTH_FACEBOOK_APP_ID } = envVars();

  const handleEmailClick = () => {
    setEmail(!email);
  };

  const handleModeChange = () => {
    setMode(mode === MODE.LOGIN ? MODE.REGISTER : MODE.LOGIN);
  };

  const handleError = (error) => {
    UserFeedback.exception(
      translate("Something went wrong"),
      translate(error.message),
      error,
    );
  };

  const {
    handleEmailChange,
    handleSocialChange,
    texts: {
      TITLE,
      DESCRIPTION,
      SWITCH_TEXT,
      FACEBOOK_BUTTON_TEXT,
      GOOGLE_BUTTON_TEXT,
      EMAIL_BUTTON_TEXT,
    },
  } = useMemo(() => {
    const config = getFormConfig({
      mode,
      onError: handleError,
      onLogin: login,
      onRegister: register,
      inviteCode,
    });

    return {
      ...config,
    };
  }, [mode]);

  useEffect(() => {
    if (userId) {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    }
  }, [onSuccess, userId]);

  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  const handleSocialChangeForGoogle = (response) => {
    const { credential } = response;
    setIsRememberMeCheckedInAuthState(isRememberMeChecked);
    handleSocialChange({
      accessToken: credential,
      error: null,
      provider: "google",
      status: "success",
    });
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleSocialChangeForFacebook = (...args) => {
    setIsRememberMeCheckedInAuthState(isRememberMeChecked);
    return handleSocialChange(...args);
  };

  const setIsRememberMeStateAndHandleEmailChange = (...args) => {
    setIsRememberMeCheckedInAuthState(isRememberMeChecked);
    return handleEmailChange(...args);
  };

  return [
    <Root>
      <Flex alignItems="center" direction="column" gutter={3}>
        <Heading basis="100%" data-qa="auth-form-title" level="24-Bold">
          {translate(TITLE)}
        </Heading>
        <DescriptionWrapper>
          <Text align="center" dataQa="auth-form-desc" inline={true}>
            {translate(DESCRIPTION)}{" "}
            <TextButton
              color={BADI_COLORS.NEUTRAL.NEUTRAL_80}
              data-qa="auth-form-switch-btn"
              hoverColor={BADI_COLORS.NEUTRAL.NEUTRAL_80}
              onClick={handleModeChange}
              small={false}
            >
              {translate(SWITCH_TEXT)}
            </TextButton>
          </Text>
        </DescriptionWrapper>
        <Flex direction="column" gutter={1}>
          <FacebookButton
            appId={BADI_AUTH_FACEBOOK_APP_ID}
            onSuccess={handleSocialChangeForFacebook}
          >
            {translate(FACEBOOK_BUTTON_TEXT)}
          </FacebookButton>
          <GoogleLogin
            onSuccess={handleSocialChangeForGoogle}
            text={"continue_with"}
            width="260px"
            locale={locale}
          />
          {!email && (
            <EmailButton onClick={handleEmailClick}>
              {translate(EMAIL_BUTTON_TEXT)}
            </EmailButton>
          )}
          {mode == MODE.LOGIN && !email && (
            <Spacer>
              <RememberMeCheckbox
                checked={isRememberMeChecked}
                onChange={() => setIsRememberMeChecked(!isRememberMeChecked)}
              />
            </Spacer>
          )}
        </Flex>
      </Flex>
    </Root>,
    <Root>
      <Flex alignItems="center" direction="column" gutter={1}>
        {email && (
          <EmailForm
            mode={mode}
            onSubmit={setIsRememberMeStateAndHandleEmailChange}
            wide={true}
          />
        )}
        {mode == MODE.LOGIN && email && (
          <Spacer wide={"100%"} left="3">
            <RememberMeCheckbox
              wide={"100%"}
              checked={isRememberMeChecked}
              onChange={() => setIsRememberMeChecked(!isRememberMeChecked)}
            />
          </Spacer>
        )}
        <Text align="center" body={5}>
          <span
            dangerouslySetInnerHTML={{
              __html: translate("login_and_register.terms_and_privacy", {
                terms_link: translate("sitemap.tc.link"),
                privacy_link: translate("sitemap.privacy.link"),
              }),
            }}
            data-qa="auth-form-terms"
          />
        </Text>
      </Flex>
    </Root>,
  ];
};

export default AuthenticationForm;
