import { getCDNUrl } from "base/shared/Utils";
import User from "./user-model";
import Legal from "./user-legal-model";
import DeleteReasons from "./delete-reasons-model";
import { LEGAL_STATUS, USER_IS_COUPLE } from "../constants";

const cdnUrl = getCDNUrl();

const UserModel = {
  confirmations: {
    email: false,
    facebook: false,
    google: false,
    phone: false,
  },
  error: null,
  fetching: false,
  ghost: false,
  id: 0,
  languages: [],
  mainPicture: `${cdnUrl}/assets/icons/profile_picture.svg`,
  pictures: [],
  occupation_detail: {}, // add this to avoid breaking things (we should remove mixing the models and mutating them and unify the model)
  badges: [{ badge_id: 2 }], // mock
  legal: new Legal(),
  legalStatus: LEGAL_STATUS.INITIAL,
  onboarding: { type: null },
  lister: false,
  mode: "default",
  isActiveSwitching: false,
  trustItems: [],
  listerScore: null,
  publishedRooms: null,
  couple: USER_IS_COUPLE.SINGLE,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.User) {
    return { ...UserModel, error: "There was an error in UserModel." };
  }
  return state;
}

export { DeleteReasons, Legal, User, UserModel, setInitialState };
