import React from "react";
import {
  Spacer,
  Heading,
  Popover,
  POPOVER_ORIENTATION,
  POPOVER_POSITION,
  colors,
  Text,
} from "@badi/badi-components";

import UserProfileBadge from "components/user-profile-badge";
import { translate } from "base/shared/Localization";
import RequiredVisit from "./required-visit";
import { TextWrapper } from "../styled-components";

const ContactRequest = ({ user, token, message, visitRequested = false }) => {
  return (
    <div data-qa="enquiry-contact-request">
      <Spacer bottom={3}>
        <Heading level="28-Extrabold">
          {translate("lister.contact.request_title")}
        </Heading>
      </Spacer>
      <Spacer bottom={4}>
        <UserProfileBadge
          {...user}
          data-qa="user-profile-badge"
          token={token}
          userRole={user.role}
        />
        <Spacer bottom={4}>
          <Popover
            backgroundColor={colors.lightGrey}
            orientation={POPOVER_ORIENTATION.UP}
            position={POPOVER_POSITION.LEFT}
          >
            <TextWrapper>
              <Text body="14-Regular">{message}</Text>
            </TextWrapper>
          </Popover>
        </Spacer>
        {visitRequested && <RequiredVisit />}
      </Spacer>
    </div>
  );
};

export default ContactRequest;
