import React, { PureComponent } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  colors,
  Button,
  Flex,
  Heading,
  StatusCard,
  Spacer,
  Text,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { modal } from "containers/ReduxModal/emitter";
import { BANK_ACCOUNT_COUNTRIES } from "datamodel/Payouts/static";
import Input from "components/form-text-input";
import Select from "components/form-select";
import CountryConfimation from "./country-confirmation";
import AdditionalAccountNumber from "./additional-account-number";

class BankAccountEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      canSubmit: false,
      selectedCountry: props.country,
      additionalNumber: "",
      isAdditionalNumberValid: true,
      bankAccountNumber: "",
      isBankAccountNumberValid: false,
    };

    this.confirmationModalId = uuidv4();
    this.countries = translate(BANK_ACCOUNT_COUNTRIES, "label");
    this.bankAccountNumberValidations = [this.validateBankAccountNumber];
  }

  static getDerivedStateFromProps(props, state) {
    const { country } = props;
    const {
      isBankAccountNumberValid,
      isAdditionalNumberValid,
      selectedCountry,
    } = state;

    return {
      selectedCountry: country || selectedCountry,
      canSubmit: country
        ? isBankAccountNumberValid && isAdditionalNumberValid
        : !!selectedCountry,
    };
  }

  getSubmitButtonText = () => {
    let key = "savecountry";

    if (this.state.verified) key = "update";
    else if (this.props.country) key = "save";

    return `payout.bank_account.${key}`;
  };

  handleAcceptCountryConfirmation = () => {
    const { selectedCountry } = this.state;

    this.props.setCountry(selectedCountry);
    this.handleCloseCountryConfirmation();
  };

  handleCloseCountryConfirmation = () => {
    modal.destroy(this.confirmationModalId);
  };

  showCountryConfirmation = () => {
    modal.add(
      <CountryConfimation
        onAccept={this.handleAcceptCountryConfirmation}
        onCancel={this.handleCloseCountryConfirmation}
      />,
      {
        id: this.confirmationModalId,
      },
    );
  };

  handleCountrySelect = ({ value }) => {
    this.setState({ selectedCountry: value });
  };

  handleBankAccountNumberChange = ({ value }) => {
    this.setState({ bankAccountNumber: value });
  };

  handleBankAccountNumberValidation = ({ isValid }) => {
    this.setState({ isBankAccountNumberValid: !!isValid });
  };

  handleAdditionalNumberChange = ({ value }) => {
    this.setState({ additionalNumber: value });
  };

  handleAdditionalNumberValidation = ({ isValid }) => {
    this.setState({ isAdditionalNumberValid: isValid });
  };

  handleFormSubmission = (event) => {
    event.preventDefault();

    const { country, setBankAccount } = this.props;
    const { bankAccountNumber, additionalNumber } = this.state;

    if (!country) {
      this.showCountryConfirmation();
    } else {
      setBankAccount({ bankAccountNumber, additionalNumber });
    }
  };

  validateBankAccountNumber = (bankAccountNumber) => {
    const { country } = this.props;
    const bankAccountCountry = BANK_ACCOUNT_COUNTRIES.find(
      (c) => c.value === country,
    );

    if (!bankAccountCountry || !bankAccountNumber) {
      return false;
    }

    const parsedBankAccountNumber = bankAccountNumber.replace(/\W/, "");

    return bankAccountCountry.validation.test(parsedBankAccountNumber);
  };

  render() {
    const { canSubmit, selectedCountry, bankAccountNumber } = this.state;
    const { country, updating, onClose, verified } = this.props;
    const submitButtonText = this.getSubmitButtonText();

    return (
      <StatusCard isEditable={true} isVerified={verified}>
        <Spacer bottom={3}>
          <Heading level={3}>{translate("payout.bank_account.title")}</Heading>
        </Spacer>
        <form onSubmit={this.handleFormSubmission}>
          <Flex direction="column" gutter={1}>
            <Text body={2}>{translate("payout.bank_account.country")}</Text>
            <Select
              disabled={updating || !!country}
              initialValue={selectedCountry}
              name="country"
              onChange={this.handleCountrySelect}
              options={this.countries}
              placeholder={translate("payout.bank_account.country.placeholder")}
              required={true}
            />
            <Spacer top={2} />
            <AdditionalAccountNumber
              basis="0"
              country={country}
              grow={1}
              onChange={this.handleAdditionalNumberChange}
              onValidation={this.handleAdditionalNumberValidation}
              updating={updating}
            />
            {!!country && [
              <Text
                body={2}
                color={updating || !country ? colors.softGrey : colors.darkGrey}
                key="account-number-text"
              >
                {translate("payout.bank_account.number")}
              </Text>,
              <Input
                disabled={updating || !country}
                initialValue={bankAccountNumber}
                key="account-number-input"
                name="bankAccountNumber"
                onChange={this.handleBankAccountNumberChange}
                onInputValidation={this.handleBankAccountNumberValidation}
                placeholder={translate(
                  "payout.bank_account.number.placeholder",
                )}
                removeWhitespace={true}
                required={true}
                validations={this.bankAccountNumberValidations}
              />,
              <Text body={5}>
                {translate("payout.setup.account_number.hint")}
              </Text>,
            ]}
          </Flex>
          <Spacer top={3} />
          <Flex basis="0" grow={1} gutter={1} justify="flexEnd">
            <Spacer md={{ top: 9.5 }} top={0} />
            {verified && (
              <Button disabled={updating} onClick={onClose} variant="secondary">
                {translate("payout.bank_account.cancel")}
              </Button>
            )}
            <Button disabled={updating || !canSubmit} type="submit">
              {translate(submitButtonText)}
            </Button>
          </Flex>
        </form>
      </StatusCard>
    );
  }
}

BankAccountEdit.defaultProps = {
  country: "",
  updating: false,
  verified: false,
};

export default BankAccountEdit;
