import React from "react";
import { translate } from "base/shared/Localization";
import { Text } from "@badi/badi-components";
import SeekerBar from "../SeekerBar";
import MobileMenu from "./mobile-menu";

const CardActionsMobile = ({
  listingObjectMobileActionsOpen,
  mobileActions,
  renderActionsInMenu,
  room,
  seekerActions,
  setMobileActionsMenu,
}) => {
  const buildMobileMenus = () => {
    const sections = [];
    if (mobileActions.length) {
      sections.push({
        subCategories: mobileActions,
        renderHeader: () => (
          <Text alignself="center" body="14-Bold">
            {translate("mylistings.roomActions")}
          </Text>
        ),
        id: 1,
      });
    }
    if (seekerActions.length) {
      sections.push({
        subCategories: seekerActions,
        renderHeader: () => <SeekerBar room={room} variation={"menu"} />,
        id: 2,
      });
    }

    return sections;
  };

  if (!mobileActions.length && !seekerActions.length) {
    return null;
  }

  return (
    <MobileMenu
      listingObjectMobileActionsOpen={listingObjectMobileActionsOpen}
      renderActionsInMenu={renderActionsInMenu}
      roomId={room.id}
      sections={buildMobileMenus()}
      setMobileActionsMenu={setMobileActionsMenu}
    />
  );
};

export default CardActionsMobile;
