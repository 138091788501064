import styled from "styled-components";

export const ArrowWrapperBase = styled.div`
  z-index: 2;
  position: absolute;
  display: block;
  width: 90px;
  padding: 0 24px;
  cursor: pointer;
  ${({ position }) => `${position}: 0`}
`;

export const ArrowWrapperDefault = styled.div`
  top: 45%;
  opacity: 0.6;
`;

export const ArrowWrapperSimilarRooms = styled.div`
  top: 25%;
  opacity: ${({ opacity }) => opacity};
`;
