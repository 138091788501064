export const MOVE_ALONE_ID = 1;
export const MOVE_WITH_SOMEONE_ID = 0;
export const MOVE_IN_GROUP_OPTIONS = [
  { value: MOVE_ALONE_ID, label: "onboarding.preferences.seeker_who_me" },
  {
    value: 2,
    label: "onboarding.preferences.seeker_who_with_partner",
  },
  {
    value: 3,
    label: "onboarding.preferences.seeker_who_with_friend",
  },
  {
    value: 4,
    label: "onboarding.preferences.seeker_who_with_relative",
  },
];
