import React, { Component } from "react";
import { Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";

import { fetchSettings, updateSettings } from "datamodel/User/api";
import { LOGIN_TYPE } from "datamodel/User/constants";

import LayoutWrapper from "components/Layout/Wrapper";
import SideBarMenu from "components/SideBarMenu";
import NotificationSection from "./components/notification-section";
import MarketingNotifications from "./components/marketing-notifications";

import {
  getSettingsMenuItems,
  EMAIL_NOTIFICATIONS,
  PUSH_NOTIFICATIONS,
  EMAIL_AND_PUSH_NOTIFICATIONS,
} from "../utils";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.menuItems = translate(
      getSettingsMenuItems(
        this.props.loginType !== LOGIN_TYPE.EMAIL,
        "notifications",
      ),
    );
  }

  componentDidMount() {
    fetchSettings(this.props.token).then((data) => {
      this.setState({ ...data, settingsFetched: true });
    });
  }

  handleLanguageChange = (event) => {
    event.preventDefault();
    this.setState({ locale: event.currentTarget.value }, this.updateSettings);
  };

  toggleButton = (notificationType) => {
    this.setState(
      { [notificationType]: !this.state[notificationType] },
      this.updateSettings,
    );
  };

  updateSettings = () => {
    updateSettings(this.props.token, this.state)
      .then((data) => {
        this.setState(data);
        this.handleMessageToShow();
      })
      .catch((error) => {
        this.handleMessageToShow(error);
      });
  };

  handleMessageToShow = (error = null) => {
    if (error) {
      UserFeedback.exception("Something went wrong", error.message, error);
      return;
    }

    UserFeedback.success("Updated", "Your settings have been updated");
  };

  renderNotificationsSection = (notifications) =>
    notifications.map((notification) => (
      <NotificationSection
        isActive={this.state[notification.type]}
        key={notification.type}
        notification={notification}
        toggleButton={() => this.toggleButton(notification.type)}
      />
    ));

  render() {
    const { settingsFetched } = this.state;
    return (
      <LayoutWrapper>
        {settingsFetched && (
          <div className="layout__inner">
            <div className="layout__columns">
              <SideBarMenu
                autoScroll={false}
                className="info__item_link"
                items={this.menuItems}
                itemType="link"
                title="settings.title"
              />
              <main className="content notifications__content" role="main">
                <section className="section section_checked">
                  <header className="section__head">
                    <div className="section__actions" />
                    <h2 className="section__title">
                      {translate("settings.notifications.title")}
                    </h2>
                  </header>
                  <div className="section__main">
                    <div className="section__content">
                      <div className="form__separator" />
                      <h3 className="section__title">
                        {translate(
                          "settings.notifications.section.email.title",
                        )}
                      </h3>
                      {this.renderNotificationsSection(EMAIL_NOTIFICATIONS)}
                      <div className="form__separator" />
                      <h3 className="section__title">
                        {translate("settings.notifications.section.push.title")}
                      </h3>
                      {this.renderNotificationsSection(PUSH_NOTIFICATIONS)}
                      <div className="form__separator" />
                      <h3 className="section__title">
                        {translate("settings.privacy.notifications.emailpush")}
                      </h3>
                      {EMAIL_AND_PUSH_NOTIFICATIONS.map(
                        ({ label, buttonText, type }) => (
                          <MarketingNotifications
                            buttonText={buttonText}
                            key={type}
                            label={label}
                            onChange={this.handleMessageToShow}
                          />
                        ),
                      )}
                      <div className="form__separator" />
                      <h3 className="section__title">
                        {translate(
                          "settings.notifications.section.language.title",
                        )}
                      </h3>
                      <div className="form__fieldset">
                        <div className="form__row">
                          <div className="form__half form__half_middle">
                            <div className="form__element">
                              <div className="form__text">
                                {translate(
                                  "settings.notifications.section.language.label",
                                )}
                              </div>
                            </div>
                            <div className="form__element text_right">
                              <div className="form__input form__input_select form__input_unlabled">
                                <select
                                  className="form__select"
                                  onChange={this.handleLanguageChange}
                                  value={this.state.locale}
                                >
                                  <option value="en">
                                    {translate("general.languages.english")}
                                  </option>
                                  <option value="es">
                                    {translate("general.languages.spanish")}
                                  </option>
                                  <option value="it">
                                    {translate("general.languages.italian")}
                                  </option>
                                  <option value="fr">
                                    {translate("general.languages.french")}
                                  </option>
                                  <option value="de">
                                    {translate("general.languages.german")}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form__separator" />
                      <Text body={3}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "settings.notifications.description",
                              {
                                terms_url: translate("sitemap.tc.link"),
                                privacy_url: translate("sitemap.privacy.link"),
                              },
                            ),
                          }}
                        />
                      </Text>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
        )}
      </LayoutWrapper>
    );
  }
}

Notifications.defaultProps = {
  loginType: LOGIN_TYPE.UNKNOWN,
};

export default Notifications;
