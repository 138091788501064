import {
  getDomain,
  getLocalePath,
  getRelativePathNameWithoutLang,
} from "base/shared/Utils";
import { getLocalesCode } from "base/shared/Localization";

export const getUrlForLang = (relativePath, lang, translations) => {
  const url = relativePath;

  if (!Array.isArray(translations)) {
    return url;
  }

  return translations.reduce((path, nextTranslation) => {
    return path.replace(
      `{${nextTranslation.name}}`,
      nextTranslation[lang] || nextTranslation.en,
    );
  }, url);
};

export const getAlternates = ({ canonicalUrl, relativePath, translations }) => {
  const localeCodes = getLocalesCode();
  const langs = [...localeCodes, "x-default"];
  const domainUrl = getDomain();
  const pathNameWithoutLang = getRelativePathNameWithoutLang(relativePath);

  const languageUrlList = langs.map((lang) => {
    const link = { rel: "alternate", hreflang: lang };
    if (lang === "x-default") {
      link.href = `${domainUrl}${getUrlForLang(
        pathNameWithoutLang,
        "default",
        translations,
      )}`;
    } else {
      link.href = `${domainUrl}${getLocalePath(lang)}${getUrlForLang(
        pathNameWithoutLang,
        lang,
        translations,
      )}`;
    }
    return link;
  });

  if (canonicalUrl) {
    languageUrlList.push({
      rel: "canonical",
      href: `${domainUrl}${canonicalUrl}`,
    });
  } else {
    languageUrlList.push({
      rel: "canonical",
      href: `${domainUrl}${getUrlForLang(
        pathNameWithoutLang,
        "default",
        translations,
      )}`,
    });
  }
  return languageUrlList;
};
