import React, { useEffect, useState } from "react";
import { Flex } from "@badi/badi-components";
import {
  ADDITIONAL_FIELD_TYPES,
  BANK_ACCOUNT_COUNTRIES,
} from "datamodel/Payouts/static";
import SortCode from "./sort-code";
import RoutingNumber from "./routing-number";

const AdditionalAccountNumber = ({
  onChange,
  onValidation,
  country,
  updating,
}) => {
  const [additionalFieldType, setAdditionalFieldType] = useState({});

  useEffect(() => {
    const { additionalField } = BANK_ACCOUNT_COUNTRIES.find(
      (bankAccountCountry) => bankAccountCountry.value === country,
    ) || { additionalField: ADDITIONAL_FIELD_TYPES.NONE };
    setAdditionalFieldType(additionalField);

    if (additionalField === ADDITIONAL_FIELD_TYPES.NONE) {
      onValidation({ name, isValid: true });
    }
  }, [country]);

  if (additionalFieldType === ADDITIONAL_FIELD_TYPES.NONE) return null;

  return (
    <Flex basis="0" direction="column" fullHeight={true} gutter={1}>
      {additionalFieldType === ADDITIONAL_FIELD_TYPES.ROUTING ? (
        <RoutingNumber
          disabled={updating}
          name="routing"
          onChange={onChange}
          onValidation={onValidation}
        />
      ) : (
        <SortCode
          disabled={updating}
          name="sortCode"
          onSortCodeChange={onChange}
          onSortCodeValidation={onValidation}
        />
      )}
    </Flex>
  );
};

export default AdditionalAccountNumber;
