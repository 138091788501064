import React from "react";

import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { COOKIES } from "base/shared/StaticData";
import { existsCookie, setCookie } from "base/shared/CookiesHandler";

import RecommendationTips from "./layout-elements/recommendation-tips";

const { Recommendations: RECOMENDATIONS_MODAL_ID } = MODAL_ID;

const handleCloseModal = () => {
  modal.remove(RECOMENDATIONS_MODAL_ID);

  setCookie(COOKIES.RECOMMENDATIONS_MODAL, "true");
};

export const shouldShowRecommendationsModal = () => {
  return !existsCookie(COOKIES.RECOMMENDATIONS_MODAL);
};

export const openRecommendationsModal = () => {
  modal.add(<RecommendationTips onClick={handleCloseModal} />, {
    closable: false,
    type: MODAL_TYPE.DEFAULT,
    id: RECOMENDATIONS_MODAL_ID,
  });
};
