import React, { useState } from "react";
import { useMatchMedia, BREAKPOINTS_PX } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";
import { DEFAULT_ROOM_IMAGE } from "base/shared/StaticData";
import {
  SecondaryPicturesWrapper,
  PicturesWrapper,
  StyledImage,
  PhotoGridWrapper,
} from "./styled-components";

const PhotoGrid = ({ pictures, handleClick }) => {
  const [isDesktop] = useMatchMedia(
    [`(min-width: ${BREAKPOINTS_PX.md})`],
    [true],
  );
  const defaultImage = {
    id: 0,
    width_1080_url: getCDNUrl() + DEFAULT_ROOM_IMAGE,
  };
  const [loadedImages, setLoadedImages] = useState({});

  const setImageLoaded = (id) => {
    setLoadedImages({ ...loadedImages, [id]: true });
  };

  const mainPicture = pictures[0] || defaultImage;
  const otherPictures = pictures.slice(1, 5);
  const multiple = pictures.length > 1;

  return (
    <PhotoGridWrapper>
      <PicturesWrapper>
        <StyledImage
          onLoad={() => setImageLoaded(mainPicture.id)}
          id={mainPicture.id}
          loaded={!!loadedImages[mainPicture.id]}
          onClick={handleClick}
          src={mainPicture.width_1080_url}
        />
      </PicturesWrapper>
      {multiple && isDesktop && (
        <PicturesWrapper>
          <SecondaryPicturesWrapper>
            {otherPictures.map((picture) => (
              <StyledImage
                afterLoad={() => setImageLoaded(picture.id)}
                id={picture.id}
                key={picture.id}
                loaded={!!loadedImages[mainPicture.id]}
                onClick={handleClick}
                src={picture.width_1080_url}
                total={otherPictures.length}
              />
            ))}
          </SecondaryPicturesWrapper>
        </PicturesWrapper>
      )}
    </PhotoGridWrapper>
  );
};

export default PhotoGrid;
