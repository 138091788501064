import React, { useState, useCallback, useEffect } from "react";
import { translate } from "base/shared/Localization";
import { BADI_COLORS, BUTTON_TYPES, TextArea } from "app/badi-components";

import { Heading, Text, Spacer, Checkbox } from "@badi/badi-components";
import { Flex } from "app/badi-components";
import { Button } from "app/badi-components/button";

import UserProfileBadge from "components/user-profile-badge";
import RoomDetailBadge from "components/room-detail-badge";
import BlockSeparator from "components/block-separator";
import { getSavedMessageEnquiry } from "datamodel/Conversation/api";

import { CharCounter, Form, TextAreaWrapper } from "./styled-components";

import { MESSAGE_ENQUIRY_MAX_LENGTH } from "./constants";
import FindOutMoreAboutFastTrack from "./find-out-more-about-fast-track";

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M109-120q-11 0-20-5.5T75-140q-5-9-5.5-19.5T75-180l370-640q6-10 15.5-15t19.5-5q10 0 19.5 5t15.5 15l370 640q6 10 5.5 20.5T885-140q-5 9-14 14.5t-20 5.5H109Zm371-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm0-120q17 0 28.5-11.5T520-400v-120q0-17-11.5-28.5T480-560q-17 0-28.5 11.5T440-520v120q0 17 11.5 28.5T480-360Z" />
  </svg>
);

export const THEMES = {
  standard: {
    includeFastTrackIcon: false,
  },
  fast_track: {
    textFieldHighlightColor: BADI_COLORS.ADDITIONAL.ADDITIONAL_06,
    ctaButtonVariant: BUTTON_TYPES.GOLD_GRADIENT,
    saveMessageCheckboxColor: BADI_COLORS.ADDITIONAL.ADDITIONAL_06,
    includeFastTrackIcon: true,
  },
};

const MessageEnquiry = ({
  roomPicture,
  roomTitle,
  roomAddress,
  roomId,
  disabled,
  owner = null,
  description = "",
  onSubmit,
  title = "",
  placeholder = "",
  buttonText = "",
  messageRequired = true,
  token,
  isSaveMessageAvailable,
  displayFastTrackInformation = false,
  onFastTrackFindOutMoreClick,
  standardEnquiriesCount,
  theme = THEMES.standard,
}) => {
  const [canSendMessage, setMessageValidation] = useState(!messageRequired);
  const [textMessage, setTextMessage] = useState("");
  const [isSaveMessageEnabled, setIsSaveMessageEnabled] = useState(
    isSaveMessageAvailable,
  );

  useEffect(() => {
    if (isSaveMessageAvailable) {
      getSavedMessageEnquiry(token).then(({ data }) => {
        const message = data.default?.message;
        if (message) {
          setTextMessage(message);
          setMessageValidation(true);
        }
      });
    }
  }, []);

  const handleToggleCheckbox = () => {
    setIsSaveMessageEnabled(!isSaveMessageEnabled);
  };

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;

      const isMaxReached = value.length > MESSAGE_ENQUIRY_MAX_LENGTH;
      if (!isMaxReached) {
        if (messageRequired) {
          setMessageValidation(!!value.trim());
        }
        setTextMessage(value);
      }
    },
    [messageRequired],
  );

  const handleOnSubmit = useCallback((event) => {
    event.preventDefault();

    onSubmit({
      message: textMessage,
      saveMessage: isSaveMessageEnabled,
    });
  });

  return (
    <Form
      onSubmit={handleOnSubmit}
      includeFastTrackIcon={theme.includeFastTrackIcon}
    >
      <Flex direction="column" fullHeight={true}>
        <Flex
          direction="column"
          dataQa="message-enquiry-flex"
          style={{ padding: "70px 15px 15px" }}
        >
          <Spacer bottom={1}>
            <Heading
              style={{ maxWidth: "70%" }}
              data-qa="message-enquiry-heading"
            >
              {title}
            </Heading>
          </Spacer>
          {description && (
            <Text body={3} data-qa="message-enquiry-description">
              {description}
            </Text>
          )}
          <BlockSeparator>
            {owner && (
              <UserProfileBadge
                {...owner}
                avatar={owner.picture}
                clickable={false}
                data-qa="user-profile-badge"
              />
            )}
          </BlockSeparator>
          <BlockSeparator>
            <TextAreaWrapper>
              <TextArea
                canSendMessage={canSendMessage}
                name="enquiry-message"
                onChange={handleChange}
                placeholder={placeholder}
                value={textMessage}
                borderColor={theme.textFieldHighlightColor}
              />
            </TextAreaWrapper>
            <Flex
              alignItems="center"
              justify={isSaveMessageAvailable ? "spaceBetween" : "flexEnd"}
            >
              {isSaveMessageAvailable && (
                <Checkbox
                  checked={isSaveMessageEnabled}
                  data-qa="enquiry-message-checkbox"
                  onChange={handleToggleCheckbox}
                  color={theme.saveMessageCheckboxColor}
                >
                  <Text
                    body="12-Regular"
                    dataQa="enquiry-message-checkbox-label"
                  >
                    {translate("enquiry.message.checkbox")}
                  </Text>
                </Checkbox>
              )}
              <CharCounter
                canSendMessage={canSendMessage}
                data-qa="enquiry-message-counter"
              >
                {translate("characters_left", {
                  smart_count: MESSAGE_ENQUIRY_MAX_LENGTH - textMessage.length,
                })}
              </CharCounter>
            </Flex>
          </BlockSeparator>
          <BlockSeparator grow={1}>
            <RoomDetailBadge
              address={roomAddress}
              clickable={false}
              data-qa="room-detail-badge"
              id={roomId}
              picture={roomPicture}
              title={roomTitle}
            />
          </BlockSeparator>
          {displayFastTrackInformation &&
            Number.isInteger(standardEnquiriesCount) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                  marginBottom: "15px",
                  gap: "5px",
                }}
              >
                <WarningIcon />
                <Text body="14-Regular">
                  {translate("enquiry.fast-track.enquiries-count-warning", {
                    smart_count: standardEnquiriesCount,
                  })}
                </Text>
              </div>
            )}
          <Button
            dataQa="message-enquiry-submit"
            disabled={disabled || !canSendMessage}
            fullWidth={true}
            type="submit"
            variant={theme.ctaButtonVariant}
          >
            {buttonText}
          </Button>
        </Flex>
        {displayFastTrackInformation && (
          <FindOutMoreAboutFastTrack
            grow={2}
            onClick={onFastTrackFindOutMoreClick}
          />
        )}
      </Flex>
    </Form>
  );
};

export default MessageEnquiry;
