import React from "react";
import styled from "styled-components";

import { Badge, colors } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

const Root = styled.div`
  text-transform: uppercase;
`;

const VerifiedBadge = () => (
  <Root>
    <Badge backgroundColor={colors.blueBooking}>
      {translate("trust_items.confirmed_room_label")}
    </Badge>
  </Root>
);

export default VerifiedBadge;
