import styled, { css } from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LabelsBarWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  padding: ${unitizedPx(1)};
  height: ${unitizedPx(3)};
  z-index: 2;
`;

export const ListerAvatarWrapper = styled.div`
  position: absolute;
  top: ${unitizedPx(20.63)};
  right: ${unitizedPx(2)};
`;

export const AvatarBorder = styled.div`
  border: 2px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: 50%;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  width: ${unitizedPx(10)};
  height: ${unitizedPx(10)};
`;

export const Label = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  bottom: -4px;
  right: -4px;
  height: ${unitizedPx(2)};
  padding: ${unitizedPx(0.5)};
  border-radius: 2px;
`;

export const StyledHeading = styled.h4`
  ${ellipsis}
  width: calc(100% - ${unitizedPx(2)});
`;

export const StyledSubHeading = styled.p`
  ${ellipsis}
  width: calc(100% - ${unitizedPx(10)});
`;

export const Detail = styled.div`
  padding-right: ${unitizedPx(1)};
  border-right: ${({ showBorder }) =>
    showBorder ? `1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05}` : "none"};
`;
