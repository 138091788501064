import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";
import { Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { LOGIN_TYPE } from "datamodel/User/constants";
import { getUserLoginType } from "datamodel/User/selectors";

import { SetSepaDetailsButton } from "modules/stripe/sepa-details";
import { getSepaExistingDetails } from "datamodel/sepa-details/api";
import LayoutWrapper from "components/Layout/Wrapper";
import SideBarMenu from "components/SideBarMenu";
import BankAccountVerifed from "./verified";
import { getSettingsMenuItems } from "../utils";

const Sepa = () => {
  const loginType = useSelector(getUserLoginType);
  const menuItems = translate(
    getSettingsMenuItems(loginType !== LOGIN_TYPE.EMAIL, "sepa"),
  );
  const token = useSelector(getToken);
  const [existingSepaDetails, setExistingSepaDetails] = useState(null);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    getSepaExistingDetails({ token }).then(({ sepaDetails }) => {
      setExistingSepaDetails(sepaDetails);
      setIsFetched(true);
    });
  }, []);

  return (
    <LayoutWrapper>
      <div className="layout__inner">
        <div className="layout__columns">
          <SideBarMenu
            autoScroll={false}
            className="info__item_link"
            items={menuItems}
            itemType="link"
            title="settings.title"
          />
          <main className="content privacy__content" role="main">
            <section className="section section_checked">
              <header className="section__head">
                <div className="section__actions" />
                <h2 className="section__title">
                  {translate("settings.sepa.section.title")}
                </h2>
                {existingSepaDetails && isFetched && (
                  <Spacer top={4}>
                    <BankAccountVerifed
                      bankAccountLastDigits={
                        existingSepaDetails[0].lastFourDigits
                      }
                    />
                  </Spacer>
                )}
                {!existingSepaDetails && isFetched && (
                  <SetSepaDetailsButton>
                    {translate("settings.sepa.section.cta")}
                  </SetSepaDetailsButton>
                )}
              </header>
              <div className="section__main" />
            </section>
          </main>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default Sepa;
