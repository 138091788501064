import moment from "moment";
import {
  capitalizeString,
  getDisplayPrice,
  getDomain,
} from "base/shared/Utils";

import { getVerificationLevel } from "base/shared/User";
import { translate } from "base/shared/Localization";
import RoomModel from "./models/room-model";

export function getStayMonths(days) {
  if (!days) return 1;
  const months = parseInt(days / 30, 10);

  if (months < 1) return 1;
  return months;
}

export function getPrice(priceAttr) {
  if (!priceAttr) {
    return {
      billsIncluded: false,
      currency: null,
      displayText: null,
      price: 0,
      deposit: 0,
      areaPrice: 0,
      hasAgencyFee: null,
    };
  }
  return {
    billsIncluded: priceAttr.bills_included || false,
    currency: priceAttr.currency,
    displayText: getDisplayPrice(priceAttr.price, priceAttr.currency),
    price: priceAttr.price,
    deposit: priceAttr.deposit || 0,
    displayTextDeposit: getDisplayPrice(priceAttr.deposit, priceAttr.currency),
    areaPrice: priceAttr.price_per_square_unit,
    hasAgencyFee: priceAttr.agency_fee || null,
  };
}

export function getEmptyRoom() {
  return new RoomModel();
}

export function getPriceByType(prices, type = 3) {
  let priceByType = { currency: "EUR", price: 0, price_type: type };

  if (prices.length) {
    const i = prices.findIndex((p) => p.price_type === type);
    if (i >= 0) priceByType = prices[i];
  }

  return getPrice(priceByType);
}

export function getTimeAgo(date) {
  if (!date) return "";
  const mdate = moment(new Date(date));
  const now = moment(new Date());
  const diff = now.diff(mdate);
  const duration = moment.duration(diff);

  let d = parseInt(duration.asYears(), 10);
  if (d > 0) return translate("years_ago", { smart_count: d });

  d = parseInt(duration.asMonths(), 10);
  if (d > 0) return translate("months_ago", { smart_count: d });

  d = parseInt(duration.asWeeks(), 10);
  if (d > 0) return translate("weeks_ago", { smart_count: d });

  d = parseInt(duration.asDays(), 10);
  if (d > 0) return translate("days_ago", { smart_count: d });

  d = parseInt(duration.asHours(), 10);
  return translate("hours_ago", { smart_count: d });
}

export function getYearsOld(date) {
  if (!date) return "";
  const mdate = moment(new Date(date));
  const now = moment(new Date());
  const diff = now.diff(mdate);
  const duration = moment.duration(diff);

  const d = parseInt(duration.asYears(), 10);
  if (d > 0) return translate("years_old", { smart_count: d });
  return "";
}

export const isPublishedThisWeek = (date) =>
  date ? moment().diff(date, "days") < 8 : true;

export function getCanonicalUrl(id) {
  const domainUrl = getDomain();
  return id ? `${domainUrl}/room/${id}` : domainUrl;
}

export function getShortTitle(title) {
  const safeTitle = title || "";
  let shortTitle = "";

  if (safeTitle.length > 36) {
    const words = safeTitle.split(" ");
    for (let i = 0, len = words.length; i < len; i += 1) {
      const word = words[i];
      if (shortTitle.length + word.length > 35) break;
      shortTitle = `${shortTitle} ${word}`;
    }
  } else shortTitle = safeTitle;

  if (shortTitle.length > 35) shortTitle = shortTitle.substring(0, 34);
  return capitalizeString(shortTitle);
}

export const getAvailabilityDate = (date) => (date ? moment(date) : null);

export function getRoom(room) {
  if (!room) return getEmptyRoom();

  return new RoomModel(room).toJSON();
}

export const parseRoomBasicInfo = (room) => {
  const {
    id,
    pictures,
    prices_attributes: priceAttributes,
    title,
    tenants,
  } = room;

  const price = getPriceByType(priceAttributes);

  return {
    id,
    pictureUrl:
      Array.isArray(pictures) && pictures[0] && pictures[0].width_500_url
        ? pictures[0].width_500_url
        : "",
    displayPrice: price.displayText,
    title,
    url: `/room/${id}`,
    ownerBadge: getVerificationLevel(tenants[0].badges),
  };
};

export const parseSimilarRooms = (similarRooms) =>
  similarRooms.map(parseRoomBasicInfo);

export const isNotFuture = (date) => {
  if (!date) return false;
  return new Date().getTime() - new Date(date).getTime() >= 0;
};
