import styled, { css } from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const CategoryList = styled.ul`
  padding: 0;
  list-style-type: none;

  ${mediaquery.lg`
    display: flex;
  `}
`;

export const CategoryRoot = styled.li`
  cursor: pointer;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  & + & {
    border-top: 0;
  }

  ${mediaquery.lg`
    flex-grow: 1;
    border: none;
  `}
`;

const selectedStyles = css`
  max-height: 500px;
  transition: all 0.25s ease-in;
`;

export const CategoryContent = styled.div`
  max-height: 0;
  transition: all 0.25s ease-out;
  overflow: hidden;

  ${({ selected }) => selected && selectedStyles}

  ${mediaquery.lg`
    max-height: 400px;
  `}
`;

export const CategoryHeader = styled.div`
  width: 100%;

  ${mediaquery.lg`
    cursor: default;
  `}
`;

export const CategoryTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-size: 0.8rem;
  font-weight: bold;

  ${mediaquery.lg`
    padding-top: 0;
    pointer-events: none;
   `}
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 20px;
  width: 28px;

  ${mediaquery.lg`
    display: none;
  `}
`;

export const SubCategoryList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const SubCategory = styled.a`
  margin-bottom: 16px;
  font-size: 0.9rem;
  color: var(--badi-dark-grey);
`;
