import { createSelector } from "reselect";

export const getFeatureFlags = (state) => state.Features;
export const getUser = (state) => state.User;

export const getBookingMoveOutFeatureFlag = createSelector(
  getFeatureFlags,
  (features) => features.bookingMoveOutRequired,
);

export const getPushNotifications = createSelector(
  getFeatureFlags,
  (features) => features.pushNotifications,
);

export const showBillsIncludedFlagSelector = createSelector(
  getFeatureFlags,
  (features) => features.showBillsIncludedRoomCard,
);

export const getSkipMessageStepSelector = createSelector(
  getFeatureFlags,
  (features) => features.skipMessageStep,
);

export const fetchingFeaturesSelector = createSelector(
  getFeatureFlags,
  (features) => features.fetching,
);

export const isImmediateListingFlowSelector = createSelector(
  getFeatureFlags,
  (features) => features.immediateListingFlow,
);

export const isListingFlowWithBankAccountSelector = createSelector(
  getFeatureFlags,
  (features) => features.listingFlowWithBankAccount,
);

export const getSkipEnquiryAcceptAndDeclineFlag = createSelector(
  getFeatureFlags,
  (features) => features.skipEnquiryAcceptAndDecline,
);

export const isAccountDetailsProfileSelector = createSelector(
  getFeatureFlags,
  (features) => features.addAccountDetailsProfile,
);

export const isPaymentDetailsProfileSelector = createSelector(
  getFeatureFlags,
  (features) => features.addPaymentDetailsProfile,
);

export const isShowPriceDiscountsSelector = createSelector(
  getFeatureFlags,
  (features) => features.showPriceDiscounts,
);

export const getMatchProfileSelector = createSelector(
  getFeatureFlags,
  (features) => features.matchProfileBadge,
);

export const isFavoriteTogglerEnabledSelector = createSelector(
  getFeatureFlags,
  (features) => features.isFavoriteToggleActive,
);

export const visitsExpressFlagSelector = createSelector(
  getFeatureFlags,
  (features) => features.visitsExpress,
);

export const isLegalServicesEnabledSelector = createSelector(
  getFeatureFlags,
  (features) => features.legalServices,
);

export const isContractOfferedLabelEnabledSelector = createSelector(
  getFeatureFlags,
  getUser,
  (features, user) => !!user.id && features.contractOfferedLabel,
);

export const getProfessionalPhotosSelector = createSelector(
  getFeatureFlags,
  (features) => features.professionalPhotos,
);

export const isStandaloneBoostingSelector = createSelector(
  getFeatureFlags,
  (features) => features.standaloneBoosting,
);

export const isSepaDebitDetailsSelector = createSelector(
  getFeatureFlags,
  (features) => features.sepaDebitDetails,
);

export const matchingModelSelector = createSelector(
  getFeatureFlags,
  (features) => features.matchingModel,
);

export const completeProfileBannerSelector = createSelector(
  getFeatureFlags,
  (features) => features.completeProfileBanner,
);

export const swipingProfileCardsSelector = createSelector(
  getFeatureFlags,
  (features) => features.swipingProfileCards,
);

export const getIsSwipingSeekerExperienceEnabledSelector = createSelector(
  getFeatureFlags,
  (features) => features.swipingSeekerExperience,
);
