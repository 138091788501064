export const ICON_CONTENT_TYPE = {
  IMAGE: "image",
  NUMBER: "number",
};

export const WHY = [
  {
    title: "rentRoom.sections.why.1.title",
    description: "rentRoom.sections.why.1.description",
    icon: {
      type: ICON_CONTENT_TYPE.IMAGE,
      value: "/assets/icons/home-lister-outline.svg",
    },
  },
  {
    title: "rentRoom.sections.why.2.title",
    description: "rentRoom.sections.why.2.description",
    icon: {
      type: ICON_CONTENT_TYPE.IMAGE,
      value: "/assets/icons/user-outline.svg",
    },
  },
  {
    title: "rentRoom.sections.why.3.title",
    description: "rentRoom.sections.why.3.description",
    icon: {
      type: ICON_CONTENT_TYPE.IMAGE,
      value: "/assets/icons/chat-list-room-apartment-page.png",
    },
  },
];

export const HOW = [
  {
    title: "rentRoom.sections.how.1.title",
    description: "rentRoom.sections.how.1.description",
    icon: { type: ICON_CONTENT_TYPE.NUMBER, value: "1" },
  },
  {
    title: "rentRoom.sections.how.2.title",
    description: "rentRoom.sections.how.2.description",
    icon: { type: ICON_CONTENT_TYPE.NUMBER, value: "2" },
  },
  {
    title: "rentRoom.sections.how.3.title",
    description: "rentRoom.sections.how.3.description",
    icon: { type: ICON_CONTENT_TYPE.NUMBER, value: "3" },
  },
  {
    title: "rentRoom.sections.how.4.title",
    description: "rentRoom.sections.how.4.description",
    icon: { type: ICON_CONTENT_TYPE.NUMBER, value: "4" },
  },
];
