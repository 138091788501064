import React, { Component } from "react";
import { withFormsy } from "formsy-react";

import { debounce } from "radash";

import Input from "./Input";

class InputWithFormsy extends Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onValueValidation = this.onValueValidation.bind(this);
    this.debouncedValueValidation = debounce({ delay: 300 }, () => {
      this.onValueValidation();
    });
  }

  onValueChange(e) {
    const value = e.currentTarget.value;
    const { setValue, digitsType } = this.props;
    if (
      digitsType !== "number" ||
      (digitsType === "number" && Number(value) > 0) ||
      value === ""
    ) {
      setValue(value, false);
      this.debouncedValueValidation();
    }
  }

  onValueValidation() {
    const { isValidValue, onChange, setValue } = this.props;
    const key = this.input.name;
    const value = this.input.value;
    setValue(value);
    if (onChange) onChange(key, value, isValidValue(value));
  }

  render() {
    const { getValue, ...rest } = this.props;

    const safeValue = getValue();

    return (
      <Input
        {...rest}
        onChange={this.onValueChange}
        setReference={(node) => {
          this.input = node;
        }}
        value={safeValue}
      />
    );
  }
}

InputWithFormsy.defaultProps = {
  onChange: undefined,
  digitsType: "",
};

export default withFormsy(InputWithFormsy);
