import React, { memo } from "react";
import { Row, Col } from "reactstrap";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import FormBlock from "components/form-block";
import Input from "components/Form/Input/InputWithFormsy";

const Name = ({ isValid, lastName = "", name = "", saved }) => (
  <FormBlock
    dataQa="profile-form-name-section"
    id="formblock-name"
    isRequired={true}
    saved={saved}
    title={translate("profile.form.label.name")}
    valid={isValid}
  >
    <Row>
      <Col lg={6} md={12}>
        <Input
          inputType="text"
          maxlength={30}
          name="name"
          placeholder={translate("profile.form.fields.firstname")}
          required={true}
          validations={{
            maxLength: 30,
          }}
          value={name || ""}
        />
      </Col>
      <Col lg={6} md={12}>
        <Input
          inputType="text"
          maxlength={30}
          name="lastName"
          placeholder={translate("profile.form.fields.lastname")}
          required={true}
          validations={{
            maxLength: 30,
          }}
          value={lastName || ""}
        />
      </Col>
    </Row>
    <Spacer bottom={1} />
  </FormBlock>
);

export default memo(Name);
