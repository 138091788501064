import { connect } from "react-redux";

import RecommendationActions from "datamodel/Recommendations/actions";
import RoomActions from "datamodel/Room/actions";

import { getRoom } from "datamodel/Room/selectors";
import { getToken } from "datamodel/Auth/selectors";
import {
  getRecommendationsList,
  isFetchingRecommendations,
  hadFetchedRecommendations,
} from "datamodel/Recommendations/selectors";
import Recommendations from "./recommendations";

const mapStateToProps = (state) => ({
  recommendations: getRecommendationsList(state),
  fetching: isFetchingRecommendations(state),
  fetched: hadFetchedRecommendations(state),
  token: getToken(state),
  room: getRoom(state),
});

const mapDispatchToProps = {
  getRoom: RoomActions.getRoom,
  resetRecommendations: RecommendationActions.resetRecommendations,
  getRecommendations: RecommendationActions.getRecommendations,
  rejectRecommendation: RecommendationActions.rejectRecommendation,
  removeRecommendation: RecommendationActions.removeRecommendation,
};

const mergeProps = (stateProps, dispatch, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    ...dispatch,
    getRoom: (roomId) => dispatch.getRoom({ roomId, token: ownProps.token }),
    getRecommendations: (roomId) =>
      dispatch.getRecommendations({
        roomId,
        token: stateProps.token,
      }),
    rejectRecommendation: (params) => {
      dispatch.rejectRecommendation(params, stateProps.token);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Recommendations);
