import React, { useEffect } from "react";
import { getHomePath } from "Sections/Home/routes";

import Payout from "Sections/payout";

const BookingSettings = ({ userMode, router }) => {
  useEffect(() => {
    if (userMode !== "lister") {
      router.push(getHomePath());
    }
  }, []);

  return <Payout />;
};

export default BookingSettings;
