import React from "react";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import TopBanner from "components/top-banner";

const PremiumBanner = ({ onBadiGoldClick }) => {
  const { buttonText, onClick, subTitle, title } = {
    buttonText: "listing.overview.badi_gold.desktop_cta",
    subTitle: "listing.overview.badi_gold_subtitle",
    title: "listing.overview.badi_gold_title",
    onClick: onBadiGoldClick,
  };

  return (
    <TopBanner
      buttonText={translate(buttonText)}
      dataQa="plus-top-banner"
      imageSrc={getPublicAsset("images/badi-gold/badi-gold-logo.svg")}
      onClick={onClick}
      subTitle={translate(subTitle)}
      title={translate(title)}
      type="premium"
    />
  );
};

export default PremiumBanner;
