import React from "react";

const ConversationBubble = ({ className }) => {
  return (
    <svg
      className={className}
      height="32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#333" fillRule="nonzero" stroke="#333" strokeWidth=".4">
        <path d="M25.61 7h-12.7a2.36 2.36 0 0 0-2.32 2.38v8.1c0 1.3 1.05 2.37 2.33 2.37h6.38v2.11c0 .61.52 1.1 1.12 1.1.29 0 .58-.1.78-.32l2.9-2.9h1.5c1.32 0 2.4-1.09 2.4-2.43V9.45A2.43 2.43 0 0 0 25.61 7zm1.34 10.41c0 .75-.6 1.36-1.35 1.36h-1.72a.52.52 0 0 0-.37.16l-3.04 3.06h-.05s-.07 0-.07-.03v-2.65c0-.3-.18-.54-.47-.54h-6.96c-.7 0-1.27-.57-1.27-1.29v-8.1c0-.7.56-1.31 1.26-1.31h12.7c.74 0 1.34.64 1.34 1.38v7.96z" />
        <path d="M6.03 23.32h.92l2.29 2.33c.18.2.43.29.69.29a.97.97 0 0 0 .93-1v-1.62h4.62c1.14 0 1.97-.79 1.97-1.86 0-.3-.24-.54-.53-.54-.29 0-.52.25-.52.54 0 .54-.47.8-.92.8h-5.1c-.29 0-.58.21-.58.51v1.98L7.52 22.4a.46.46 0 0 0-.35-.15H6.03a1 1 0 0 1-.98-1v-6.31c0-.54.44-.98.97-.98h3c.28 0 .52-.24.52-.54 0-.3-.24-.53-.53-.53H6.02c-1.11 0-2.02.92-2.02 2.05v6.3c0 1.14.91 2.08 2.03 2.08z" />
      </g>
    </svg>
  );
};

ConversationBubble.defaultProps = {
  className: "",
};

export default ConversationBubble;
