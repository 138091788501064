import React from "react";

import { Heading, Text, Flex, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { SEEKER_USER_ROLE, LISTER_USER_ROLE } from "datamodel/User/constants";
import { isSeeker } from "datamodel/User/utils";

import SuccessCheck from "components/Icons/SuccessCheck";
import UserProfileBadge from "components/user-profile-badge";
import RoomDetailsBadge from "components/room-detail-badge";
import BlockSeparator from "components/block-separator";

import { SEEKER_TIPS, LISTER_TIPS } from "./constants";

const VisitFinished = ({ room, seeker, userId }) => {
  const screenRole = seeker.id === userId ? SEEKER_USER_ROLE : LISTER_USER_ROLE;

  const tips = isSeeker(screenRole) ? SEEKER_TIPS : LISTER_TIPS;

  return (
    <Flex direction="column" gutter={3}>
      <Flex direction="column" gutter={1}>
        <Spacer bottom={1}>
          <Heading data-qa="visit-finished-title">
            {translate(`inbox_popup_visit.completed.${screenRole}.title`)}
          </Heading>
        </Spacer>
        <Text data-qa="visit-finished-description">
          {translate(`inbox_popup_visit.completed.${screenRole}.description`)}
        </Text>
        <BlockSeparator>
          {isSeeker(screenRole) ? (
            <RoomDetailsBadge
              address={room.address}
              id={room.id}
              picture={room.picture}
              title={room.title}
            />
          ) : (
            <UserProfileBadge
              age={seeker.age}
              avatar={seeker.picture}
              id={seeker.id}
              name={seeker.name}
              occupation={seeker.occupation}
            />
          )}
        </BlockSeparator>
      </Flex>
      <Heading data-qa="visit-finished-text" level="16-Bold">
        {translate(`inbox_popup_visit.completed.${screenRole}.benefits.title`)}
      </Heading>
      {tips.map((tip) => (
        <Flex data-qa="visit-finished-tip" gutter={2} key={tip.title}>
          <SuccessCheck size={24} />
          <div>
            <Heading level="16-Bold">{translate(tip.title)}</Heading>
            <Text body="14-Regular">{translate(tip.description)}</Text>
          </div>
        </Flex>
      ))}
    </Flex>
  );
};

export default VisitFinished;
