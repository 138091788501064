import { createSelector } from "reselect";

import { getInboxConnection } from "datamodel/Conversation/selectors";

export const getVisitsFromConnectionSelector = createSelector(
  getInboxConnection,
  (connection) => connection.visits,
);

export const getVisitActionFromConnectionSelector = createSelector(
  getVisitsFromConnectionSelector,
  (visits) => visits.action,
);

export const getVisitStatusFromConnectionSelector = createSelector(
  getVisitsFromConnectionSelector,
  (visits) => visits.status,
);

export const getVisitIdFromConnectionSelector = createSelector(
  getVisitsFromConnectionSelector,
  (visits) => visits.id,
);

export const getVisitVirtualTourLinkFromConnectionSelector = createSelector(
  getVisitsFromConnectionSelector,
  (visits) => visits.virtualTourLink,
);
