import MetricEvolution from "./metric-evolution-model";
import ConnectionResponse from "./connection-response-model";
import RoomProperties from "./room-properties-model";
import Favorites from "./favorites-model";

class RoomMetrics {
  constructor(data) {
    this.entity = data?.metrics || { metrics: {} };
  }

  get viewEvolution() {
    if (!this.entity.view_evolution) return null;

    return new MetricEvolution(this.entity.view_evolution.data);
  }

  get connectionEvolution() {
    if (!this.entity.connection_evolution) return null;

    return new MetricEvolution(this.entity.connection_evolution.data);
  }

  get responseRate() {
    if (!this.entity.response_rate) return null;

    return new ConnectionResponse(this.entity.response_rate.data);
  }

  get responseTime() {
    if (!this.entity.response_time) return null;

    return new ConnectionResponse(this.entity.response_time.data);
  }

  get roomProperties() {
    if (!this.entity.room_properties) return null;

    return new RoomProperties(this.entity.room_properties.data);
  }

  get favorites() {
    if (!this.entity.favorites) return null;

    return new Favorites(this.entity.favorites.data);
  }

  toJSON() {
    return {
      viewEvolution: this.viewEvolution,
      connectionEvolution: this.connectionEvolution,
      responseRate: this.responseRate,
      responseTime: this.responseTime,
      roomProperties: this.roomProperties,
      favorites: this.favorites,
    };
  }
}

export default RoomMetrics;
