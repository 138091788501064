import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchHomeCities } from "./api";

export default {
  getHomeCities({ token, isLoggedUser, countryCode, ip, locale, userAgent }) {
    return {
      types: generateFetchTypes(Types.HOME_REQUEST),
      request: fetchHomeCities(token, isLoggedUser, countryCode, {
        ip,
        locale,
        userAgent,
      }),
    };
  },
};
