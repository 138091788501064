import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "RECOMMENDATIONS_REQUEST",
  "RECOMMENDATIONS_SUCCESS",
  "RECOMMENDATIONS_ERROR",
  "RECOMMENDATION_SET_STATUS_REQUEST",
  "RECOMMENDATION_SET_STATUS_ERROR",
  "RECOMMENDATION_SET_STATUS_SUCCESS",
  "RECOMMENDATION_REMOVE",
  "RECOMMENDATIONS_RESET",
]);
