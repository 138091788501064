import React from "react";
import { BADI_COLORS, Spacer } from "@badi/badi-components";
import { getButtonStyles } from "./utils";
import { BUTTON_TYPES, BUTTON_SIZES } from "./constants";
import { BaseButton } from "./styled-components";

const getIcon = ({ disabled, styles, icon, size }) => {
  if (!icon || size === BUTTON_SIZES.SMALL) {
    return null;
  }

  return React.cloneElement(icon, {
    color: disabled ? BADI_COLORS.NEUTRAL.NEUTRAL_50 : styles.color,
  });
};

const Button = ({
  children,
  dataQa = undefined,
  disabled = false,
  fullWidth = false,
  onClick = () => {},
  type = "button",
  variant = BUTTON_TYPES.PRIMARY,
  size = BUTTON_SIZES.REGULAR,
  icon = null,
  isIconOnTop = false,
  ...rest
}) => {
  const styles = getButtonStyles(variant);
  const renderIcon = getIcon({ disabled, styles, icon, size });

  return (
    <BaseButton
      {...styles}
      data-qa={dataQa}
      disabled={disabled}
      fullWidth={fullWidth}
      isIconOnTop={isIconOnTop}
      onClick={onClick}
      size={size}
      type={type}
      {...rest}
    >
      {renderIcon && (
        <Spacer right={isIconOnTop ? 0 : 1} top={isIconOnTop ? 1 : 0}>
          {renderIcon}
        </Spacer>
      )}
      {children}
    </BaseButton>
  );
};

export default Button;
