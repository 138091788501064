import React, { PureComponent } from "react";
import { Container, Row, Col } from "reactstrap";
import Formsy from "formsy-react";

import {
  BADI_COLORS,
  Flex,
  Text,
  InlineMessage,
  MESSAGE_TYPES,
  Button,
  Spacer,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Layer from "components/Layout/Layer/Layer";

import FormBlock from "components/form-block";
import Input from "components/Form/Input/InputWithFormsy";
import GeoSuggest from "components/Form/GeoSuggest/GeoSuggestWithFormsy";
import RoomLocation, { Marker } from "components/room-location";
import Image from "components/Image/Image";
import { ONBOARDING_ROLE } from "components/onboarding/constants";

import { getMapCenter, getCityLocation, isCitySet } from "./utils";
import { STATUS, INITIAL_STATE } from "./constants";
import ButtonsWrapper from "../buttons-wrapper";
import { FormContent } from "../styled-components";
import { MapWrapper } from "./styled-components";

class RoomListLocation extends PureComponent {
  constructor(props) {
    super(props);
    const { location } = props;

    this.state = {
      ...INITIAL_STATE,
      ...location,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.loaded || state.status === STATUS.LOADED) return null;

    return {
      ...state,
      ...props.location,
      status: STATUS.LOADED,
    };
  }

  getCityValue = () => {
    const { city, country } = this.state;

    return isCitySet({ city, country }) ? `${city}, ${country}` : "";
  };

  handleOnInvalid = () => {
    this.setState({ canSubmit: false });
  };

  handleOnValid = () => {
    this.setState({ canSubmit: true });
  };

  handleResetAddress = () => {
    this.setState(INITIAL_STATE);
  };

  handleCityChange = (data) => {
    const {
      distance,
      coordinates: { lat: latitude, lng: longitude },
      meta: { city, country, countryCode },
    } = data;

    this.setState({
      distance,
      city,
      country,
      countryCode,
      latitude,
      longitude,
    });
  };

  handleChange = (data) => {
    // if `data.city.address` exists is because it has been changed by geosuggest
    // so we update the local state
    if (data.city && data.city.address) {
      this.handleCityChange(data.city);
      this.resetStreeAddress();

      return;
    }

    // if `data.street.address` exists is because it has been changed by geosuggest
    // so we update the local state
    if (data.street && data.street.address) {
      this.handleStreetChange(data.street);
    }
    this.setState({
      addressLine: data.addressLine,
      agencyReferenceId: data.agencyReferenceId,
    });
  };

  handleStreetChange = (data) => {
    const {
      address,
      distance,
      coordinates: { lat: latitude, lng: longitude },
      meta: { city, country, countryCode, postalCode, street, streetNumber },
    } = data;

    this.setState({
      distance,
      address,
      city,
      country,
      countryCode,
      latitude,
      longitude,
      postalCode,
      street,
      streetNumber,
    });
  };

  handleSubmit = async () => {
    const { nextStep, setRoomLocation, id, setCountryTemplateFields } =
      this.props;
    const { canSubmit, ...location } = this.state;
    const { countryCode } = location;

    if (countryCode) {
      await setCountryTemplateFields(countryCode);
    }

    setRoomLocation({ ...location });

    nextStep({ stepId: id, payload: location });
  };

  handleStreeChange = (value) => {
    if (value !== "") return;

    this.resetStreeAddress();
  };

  resetStreeAddress = () => {
    this.setState({
      address: null,
      addressLine: null,
      agencyReferenceId: null,
      postalCode: null,
      street: null,
      streetNumber: null,
    });
  };

  handleMapChange = ({ center }) => {
    const { address: stateAddress } = this.state;

    getMapCenter(center).then((address) => {
      const { city, country, countryCode } = address;
      const newState = stateAddress
        ? address
        : {
            city,
            country,
            countryCode,
          };

      this.setState({
        latitude: center.lat,
        longitude: center.lng,
        ...newState,
      });
    });
  };

  render() {
    const {
      address,
      addressLine,
      agencyReferenceId,
      distance,
      canSubmit,
      city,
      country,
      latitude,
      longitude,
      street,
    } = this.state;
    const { onLeaveFlow, onboardingRole } = this.props;

    const handleLeaveFlow = () => {
      const avoidExit = true;
      onLeaveFlow(avoidExit);
    };

    const cityLocation = getCityLocation({ longitude, latitude });
    const showPin = !!street;
    const streetFormEnabled = isCitySet({ city, country });
    const isAgency = onboardingRole === ONBOARDING_ROLE.PROFESSIONAL;

    return (
      <Spacer bottom={12} top={4}>
        <Container>
          <Row>
            <Col lg={{ size: 5 }} sm={{ size: 12 }}>
              <Formsy
                onChange={this.handleChange}
                onInvalid={this.handleOnInvalid}
                onSubmit={this.handleSubmit}
                onValid={this.handleOnValid}
              >
                <Flex as={FormContent} direction="column" gutter={5}>
                  <FormBlock
                    isRequired={true}
                    title={translate("room.list.step1.city.title")}
                  >
                    <GeoSuggest
                      ignoreTab={true}
                      name="city"
                      onSuggestSelect={this.handleResetAddress}
                      placeholder={translate(
                        "room.list.step1.city.placeholder",
                      )}
                      required={true}
                      types={["(cities)"]}
                      value={this.getCityValue()}
                    />
                  </FormBlock>
                  <FormBlock
                    isRequired={true}
                    title={translate("room.list.step1.street.title")}
                  >
                    <GeoSuggest
                      disabled={!streetFormEnabled}
                      latitude={latitude}
                      location={cityLocation}
                      longitude={longitude}
                      name="street"
                      onChange={this.handleStreeChange}
                      placeholder={translate(
                        "room.list.step1.street.placeholder",
                      )}
                      radius={distance}
                      required={true}
                      types={["address"]}
                      value={address || ""}
                    />
                  </FormBlock>
                  <FormBlock title={translate("listing.location.stair")}>
                    <>
                      <Input
                        disabled={!address}
                        inputType="text"
                        maxlength={30}
                        name="addressLine"
                        placeholder={translate(
                          "listing.location.stair.placeholder",
                        )}
                        value={addressLine || ""}
                      />
                      <Text
                        body="14-Regular"
                        color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
                      >
                        {translate("listing.location.stair.text")}
                      </Text>
                    </>
                    <InlineMessage variant={MESSAGE_TYPES.INFO}>
                      <Text body={3}>{translate("listing.location.text")}</Text>
                    </InlineMessage>
                  </FormBlock>
                  {isAgency && (
                    <FormBlock
                      title={translate("listing.location.externalReference")}
                    >
                      <Input
                        dataQa="agency-reference-id"
                        inputType="text"
                        maxlength={15}
                        name="agencyReferenceId"
                        placeholder={translate(
                          "listing.location.externalReference.placeholder",
                        )}
                        value={agencyReferenceId}
                      />
                    </FormBlock>
                  )}
                </Flex>
                <ButtonsWrapper>
                  <Flex gutter={2}>
                    <Button
                      basis="50%"
                      fullWidth={true}
                      name="back"
                      onClick={handleLeaveFlow}
                      variant="secondary"
                    >
                      {translate("Back")}
                    </Button>
                    <Button
                      basis="50%"
                      disabled={!canSubmit}
                      fullWidth={true}
                      name="next"
                      type="submit"
                    >
                      {translate("Continue")}
                    </Button>
                  </Flex>
                </ButtonsWrapper>
              </Formsy>
            </Col>

            <Layer className="RoomList__fixed">
              <Container>
                <Row>
                  <Col lg={{ size: 6, offset: 6 }} sm={12}>
                    {latitude && longitude ? (
                      <FormBlock>
                        <MapWrapper>
                          <RoomLocation
                            latitude={latitude}
                            longitude={longitude}
                            onChange={this.handleMapChange}
                            tip={translate("room.list.step1.map.tip")}
                          />
                          {showPin && <Marker data-qa="room-map-marker" />}
                        </MapWrapper>
                      </FormBlock>
                    ) : (
                      <Image
                        alt="map-placeholder"
                        className="d-none d-lg-block"
                        src="/assets/images/roomlist-location.svg"
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </Layer>
          </Row>
        </Container>
      </Spacer>
    );
  }
}

RoomListLocation.defaultProps = {
  onLeaveFlow: () => {},
};

export default RoomListLocation;
