import React, { memo } from "react";
import { Spacer } from "@badi/badi-components";
import SectionComponents from "./section-component";
import SectionHeader from "../layout-elements/section-header";

const Section = ({
  heading,
  subheading,
  components,
  mainSectionHeading,
  listerScore,
  userPicture,
  transactions,
}) => {
  if (!components.length) return null;

  return (
    <Spacer bottom={4}>
      <SectionHeader
        components={components}
        heading={heading}
        listerScore={listerScore}
        mainSectionHeading={mainSectionHeading}
        subheading={subheading}
        transactions={transactions}
        userPicture={userPicture}
      />
      <SectionComponents components={components} />
    </Spacer>
  );
};

Section.defaultProps = {
  components: [],
  heading: "",
  listerScore: "",
  mainSectionHeading: "",
  subheading: "",
  transactions: null,
  userPicture: "",
};

export default memo(Section);
