import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";

export const fetchRecommendations = ({ roomId }, token) => {
  const { BADI_RECOMMENDATIONS_SERVICE } = envVars();
  const serverUrl = getServerUrl(
    BADI_RECOMMENDATIONS_SERVICE.replace("{id}", roomId),
  );

  return fetch(serverUrl, { token }).then((res) => res);
};

export const sendInvite = ({ roomId, userId, message }, token) => {
  const { BADI_ACCEPT_RECOMMENDATION_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_ACCEPT_RECOMMENDATION_SERVICE);

  const parsedParams = {
    room_id: roomId,
    user_id: userId,
    message: message || null,
  };

  return fetch(serverUrl, {
    params: parsedParams,
    requiredParams: true,
    method: "POST",
    token,
  });
};

export const rejectRecommendation = ({ roomId, userId }, token) => {
  const { BADI_REJECT_RECOMMENDATION_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_REJECT_RECOMMENDATION_SERVICE);

  const parsedParams = {
    room_id: roomId,
    user_id: userId,
  };

  return fetch(serverUrl, {
    params: parsedParams,
    requiredParams: true,
    method: "POST",
    token,
  });
};
