import React, { Component } from "react";
import classNames from "classnames";

import Arrow from "components/Icons/Arrow";
import Image from "components/Image/Image";

import { setEnableState } from "./utils";
import TagCategoryItem from "./TagCategoryItem";

class TagCategory extends Component {
  onClickTag = (id) => {
    this.props.onClickTag(this.props.id, id);
  };

  getAction = () => {
    const { expanded, mode } = this.props;
    switch (mode) {
      case "add":
        return {
          icon: <Image src="/assets/icons/more.svg" alt="" />,
          handler: this.openFormClick,
          role: "button",
        };
      case "edit":
        return {
          icon: <Arrow direction={expanded ? "up" : "down"} type="caret" />,
          handler: this.toggle,
          role: "button",
        };
      default:
        return { icon: null, handler: undefined, role: undefined };
    }
  };

  openFormClick = () => {
    const { id, onAddClick } = this.props;
    onAddClick(id);
  };

  toggle = () => {
    const { id, toggleCategory } = this.props;
    toggleCategory(id);
  };

  render() {
    const {
      className,
      expanded,
      mode,
      name,
      tagClassName,
      tags,
      title,
      icon,
      limit,
    } = this.props;
    const action = this.getAction();
    const discernedTagsByState = setEnableState(tags, limit);

    return (
      <li className={className}>
        <div
          className="TagCategory__header"
          onClick={action.handler}
          role={action.role}
        >
          <Image src={icon} alt="" />
          {mode === "add" ? <span>{title}</span> : <b>{name}</b>}
          <div className="TagCategory__action_icon">{action.icon}</div>
        </div>
        <div
          className={classNames("TagCategory__list", {
            TagCategory__list_collapsed: !expanded,
          })}
        >
          {discernedTagsByState.map((t) => (
            <TagCategoryItem
              key={t.id}
              {...t}
              className={tagClassName}
              onClick={this.onClickTag}
              mode={mode}
            />
          ))}
        </div>
      </li>
    );
  }
}

TagCategory.defaultProps = {
  className: null,
  expanded: true,
  mode: "display",
  limit: 10,
  tagClassName: null,
  toggleCategory: () => {},
};

export default TagCategory;
