import React, { Suspense } from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";

export const closeModal = () => {
  modal.remove(MODAL_ID.PRIORITY_CANDIDATE_INFORMATION);
};

export const openPriorityCandidateInformationModal = () => {
  try {
    const PriorityCandidateInformationModal = React.lazy(
      () => import("./priority-candidate-information-modal.jsx"),
    );
    modal.add(
      <Suspense fallback={<div />}>
        <PriorityCandidateInformationModal closeModal={closeModal} />
      </Suspense>,
      {
        id: MODAL_ID.PRIORITY_CANDIDATE_INFORMATION,
        type: MODAL_TYPE.LATERAL,
        onClose: () => closeModal(),
        mainClassName: "no-padding",
      },
    );
  } catch (e) {
    UserFeedback.exception("Something went wrong", e.message, e);
  }
};
