import React from "react";

import {
  Heading,
  StatusCard,
  Button,
  Spacer,
  Text,
  Flex,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const Verified = ({ cardLastDigits, onButtonClick }) => {
  return (
    <StatusCard isVerified={true}>
      <Spacer bottom={3}>
        <Heading level={3}>{translate("payment.verified.title")}</Heading>
      </Spacer>
      <Flex align="center" justify="spaceBetween">
        <div>
          <Text body={2}>{translate("payment.verified.label")}</Text>
          <Text body={1}>**** {cardLastDigits}</Text>
        </div>
        <Button onClick={onButtonClick} type="button">
          {translate("payment.verified.edit_button")}
        </Button>
      </Flex>
    </StatusCard>
  );
};

Verified.defaultProps = {
  onButtonClick: null,
};

export default Verified;
