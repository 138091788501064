import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import RoomForm from "./room-form";

export default connect(
  (state) => ({
    bedTypes: state.SearchFilters.bedTypes,
    bookable: state.SearchFilters.bookable,
    placeTypes: state.SearchFilters.placeTypes,
    roomAmenities: state.SearchFilters.roomAmenities,
  }),
  (dispatch) => ({
    setRoom: (bookable, bedTypes, placeTypes, roomAmenities) =>
      dispatch(
        Actions.setRoom({ bookable, bedTypes, placeTypes, roomAmenities }),
      ),
  }),
)(RoomForm);
