import React from "react";

import { translate } from "base/shared/Localization";
import RadioButtonGroup from "components/Form/RadioButton/radio-button-group";
import { PLACE_TYPES } from "../constants";

const PropertyType = ({ handleSaveSeekerPreference, propertyType = null }) => {
  const placeTypeItems = translate(PLACE_TYPES);

  const handeOnChange = (value) => {
    handleSaveSeekerPreference({ propertyType: value });
  };

  return (
    <RadioButtonGroup
      data-qa="seeker-preferences-place-type"
      initialValue={propertyType}
      items={placeTypeItems}
      name="place-type"
      onChangeCallback={handeOnChange}
      required={true}
    />
  );
};

export default PropertyType;
