import React from "react";
import { Button } from "@badi/badi-components";

import withStripeSepaRedirect from "./with-sepa-details-redirect";

const UpdateSepaDetailsButton = ({
  dataQa,
  handleRedirectToCheckout,
  children,
}) => (
  <Button data-qa={dataQa} onClick={handleRedirectToCheckout}>
    {children}
  </Button>
);

export default withStripeSepaRedirect(UpdateSepaDetailsButton);
