import { BADI_COLORS } from "@badi/badi-components";
import { LAYOUT_TYPES } from "./constants";

export const getColorsByType = (type) => {
  switch (type) {
    case LAYOUT_TYPES.BOOSTING:
      return {
        background: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        heading: BADI_COLORS.ADDITIONAL.ADDITIONAL_04,
        description: BADI_COLORS.NEUTRAL.NEUTRAL_00,
      };
    case LAYOUT_TYPES.PLUS:
    default:
      return {
        background: BADI_COLORS.GRADIENT.GRADIENT_02,
        heading: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        description: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
      };
  }
};
