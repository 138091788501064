const MAX_LENGTHS = {
  facebook: 200,
  instagram: 31,
  twitter: 16,
  linkedin: 200,
};

export const VALIDATIONS = {
  facebook: [
    ["maxLength", MAX_LENGTHS.facebook],
    ["matchRegexp", /^$|^(http(s)?:\/\/)?(www[.])?(m[.])?facebook.com\/\S+$/],
  ],
  instagram: [
    ["maxLength", MAX_LENGTHS.instagram],
    ["matchRegexp", /^(@)?[A-Za-z0-9_.]*$/],
  ],
  twitter: [
    ["maxLength", MAX_LENGTHS.twitter],
    ["matchRegexp", /^(@)?[A-Za-z0-9_]*$/],
  ],
  linkedin: [
    ["maxLength", MAX_LENGTHS.linkedin],
    [
      "matchRegexp",
      /^$|^(http(s)?:\/\/)?(www[.])?linkedin.com\/(in|company)\/\S+$/,
    ],
  ],
};
