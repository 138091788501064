import React from "react";

import { getNewRoomPath } from "Sections/Room/routes";

import DraftSelection from "./draft-selection";

const DraftSelectionRoute = ({ router }) => {
  const handleOnClose = () => {
    router.push(getNewRoomPath());
  };

  const navigateToDraft = (draftId) => {
    router.push(`${getNewRoomPath()}?draft=${draftId}`);

    router.push({
      pathname: getNewRoomPath(),
      search: `?draft=${draftId}&dpl=true`,
    });
  };

  return (
    <DraftSelection navigateToDraft={navigateToDraft} onClose={handleOnClose} />
  );
};

export default DraftSelectionRoute;
