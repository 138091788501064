import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchViewers, sendInvite } from "./api";

export default {
  getViewers({ roomId, paginationToken }, token) {
    return {
      types: generateFetchTypes(Types.VIEWERS_REQUEST),
      request: fetchViewers({ roomId, paginationToken }, token),
    };
  },
  acceptViewer({ roomId, userId, message = "" }, token) {
    return {
      types: generateFetchTypes(Types.VIEWER_SET_STATUS_REQUEST),
      request: sendInvite(
        {
          roomId,
          userId,
          message,
        },
        token,
      ),
      roomId,
    };
  },
  contactedViewer(id) {
    return {
      type: Types.VIEWER_CONTACTED,
      id,
    };
  },
  resetViewers() {
    return {
      type: Types.VIEWERS_RESET,
    };
  },
};
