import { VISIT_STATUS } from "datamodel/visits/constants";
import { Eye, Calendar, Home } from "./icons";

export const VISIT_STEPS = [
  {
    name: "schedule",
    activeStatusList: [
      VISIT_STATUS.PROPOSED,
      VISIT_STATUS.CANCELLED,
      VISIT_STATUS.REJECTED,
      VISIT_STATUS.REQUESTED,
    ],
    completedStatusList: [VISIT_STATUS.ACCEPTED, VISIT_STATUS.FINISHED],
    icon: Calendar,
    title: "visits.bar.schedule",
  },
  {
    name: "visit",
    activeStatusList: [VISIT_STATUS.ACCEPTED],
    completedStatusList: [VISIT_STATUS.FINISHED],
    icon: Eye,
    title: "visits.bar.visit",
  },
  {
    name: "rent",
    activeStatusList: [VISIT_STATUS.FINISHED],
    completedStatusList: [],
    icon: Home,
    title: "visits.bar.rent",
  },
];
