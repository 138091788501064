import moment from "moment";
import { getCurrencySymbol } from "base/shared/Utils";
import { getYearsOld } from "base/shared/User";

export const checkContactValid = (contact) =>
  contact && Object.keys(contact).every((key) => !!contact[key]);

const getStringValue = (value) => value || "";

export const parseContactFromApi = ({
  country: contactCountry,
  first_name: name,
  last_name: surname,
  city,
  address,
  postal_code: postalcode,
  birth_date: birthdate,
}) => ({
  address: getStringValue(address),
  name: getStringValue(name),
  surname: getStringValue(surname),
  city: getStringValue(city),
  postalcode: getStringValue(postalcode),
  birthdate: getStringValue(birthdate),
  contactCountry: getStringValue(contactCountry),
});

export const parsePayoutMethodFromApi = ({
  bank_name: bankName,
  last_four: accountNumber,
  country,
}) => ({
  accountNumber: getStringValue(accountNumber),
  bankName: getStringValue(bankName),
  country: getStringValue(country),
});

export const parsePayoutFromApi = (params) => {
  const {
    account,
    email,
    phone,
    methods,
    missing_data: missingData,
  } = params || {};

  const method = Array.isArray(methods) && methods.length > 0 ? methods[0] : {};
  const parsedMethod = parsePayoutMethodFromApi(method);
  const parsedContact = parseContactFromApi(account || {});

  return {
    email: getStringValue(email),
    missingData: typeof missingData === "undefined" ? true : missingData,
    phone: getStringValue(phone),
    ...parsedContact,
    ...parsedMethod,
  };
};

export function parseBookingData(data) {
  const {
    address,
    amount,
    booking,
    check_in_date: checkInDate,
    currency,
    destination_user: destinationUser,
    fee_amount: feeAmount,
    fee_percentage: feePercentage,
    room,
    source_user: sourceUser,
    status,
    total_amount: total,
  } = data;

  return {
    amount: {
      base: amount,
      currency: getCurrencySymbol(currency),
      fee: feeAmount,
      percentage: feePercentage,
      total,
    },
    entryDate: moment(checkInDate),
    room: {
      address,
      owned: room.owned,
      picture: room.pictures[0].width_100_url,
      pictureHQ: room.pictures[0].width_500_url,
      title: room.title,
    },
    lister: {
      name: destinationUser.first_name,
      age: getYearsOld(destinationUser.birth_date),
      picture: destinationUser.pictures[0].width_100_url,
    },
    seeker: {
      name: sourceUser.first_name,
      age: getYearsOld(sourceUser.birth_date),
      picture: sourceUser.pictures[0].width_100_url,
    },
    status,
    reference: booking ? booking.reference : "",
  };
}

export const parseBankAccountToApi = ({
  bankAccountNumber,
  additionalNumber,
}) => ({
  account_number: bankAccountNumber.replace(/\W/g, "").toUpperCase(),
  routing_number: additionalNumber || undefined,
});

export const parseContactToApi = (data) => ({
  address: data.address,
  city: data.city,
  postal_code: data.postalcode,
  birth_date: data.birthdate,
  first_name: data.name,
  last_name: data.surname,
});
