import React from "react";
import { BADI_COLORS } from "@badi/badi-components";

const SwitchIcon = ({ height = 32, width = 26 }) => (
  <svg
    height={height}
    viewBox="0 0 32 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={BADI_COLORS.NEUTRAL.NEUTRAL_80} fillRule="evenodd">
      <path
        d="M21.798 20.64a6.443 6.443 0 0 0 1.511-4.16c0-3.456-2.66-6.272-5.925-6.272-1.724 0-3.296.8-4.384 2.08-1.088-1.28-2.66-2.08-4.384-2.08-3.265 0-5.925 2.816-5.925 6.272 0 1.6.574 3.04 1.511 4.16C1.814 21.728.121 24.256.121 27.168v3.776c0 .448.332.8.756.8h24.246c.424 0 .756-.352.756-.8v-3.776c0-2.912-1.663-5.44-4.081-6.528zM10.4 30.144H1.633v-2.976c0-2.56 1.662-4.736 3.93-5.312.907.576 1.935.928 3.053.928a5.578 5.578 0 0 0 3.054-.928c2.267.608 3.93 2.752 3.93 5.312v2.976h-5.2zM4.202 16.48c0-2.592 1.996-4.672 4.414-4.672 2.419 0 4.414 2.112 4.414 4.672 0 2.592-1.995 4.672-4.414 4.672-2.418 0-4.414-2.08-4.414-4.672zm13.182-4.672c2.449 0 4.414 2.112 4.414 4.672 0 2.592-1.996 4.672-4.414 4.672-1.421 0-2.691-.704-3.507-1.824a6.439 6.439 0 0 0 0-5.696c.816-1.088 2.086-1.824 3.507-1.824zm6.983 18.336h-7.255v-2.976c0-1.856-.665-3.552-1.784-4.8a5.578 5.578 0 0 0 5.11-.544c2.267.608 3.93 2.752 3.93 5.312v3.008z"
        fillRule="nonzero"
      />
      <path d="M8.616 8.384c.424 0 .756-.352.756-.8V5.696c0-2.112 1.633-3.84 3.628-3.84s3.598 1.696 3.628 3.808l-.575-.608c-.302-.32-.786-.32-1.058 0-.302.32-.302.832 0 1.12L16.87 8.16c.15.16.332.224.544.224a.707.707 0 0 0 .544-.224l1.875-1.984c.302-.32.302-.832 0-1.12-.303-.32-.786-.32-1.059 0l-.634.576C18.11 2.656 15.812.224 13 .224c-2.842 0-5.14 2.432-5.14 5.44v1.888c0 .448.333.832.756.832z" />
    </g>
  </svg>
);

export default SwitchIcon;
