import MessengerButton from "components/Button/Share/MessengerMapping";
import CopyUrlButton from "components/Button/Share/CopyUrl";
import WhatsAppButton from "components/Button/Share/WhatsAppMapping";
import FacebookButton from "components/Button/Share/Facebook";
import TwitterButton from "components/Button/Share/Twitter";
import { SOCIAL_NETWORKS } from "./constants";

export const getSocialComponent = (social) => {
  switch (social) {
    case SOCIAL_NETWORKS.MESSENGER:
      return MessengerButton;
    case SOCIAL_NETWORKS.FACEBOOK:
      return FacebookButton;
    case SOCIAL_NETWORKS.TWITTER:
      return TwitterButton;
    case SOCIAL_NETWORKS.COPY:
      return CopyUrlButton;
    case SOCIAL_NETWORKS.WHATSAPP:
      return WhatsAppButton;
    default:
      return null;
  }
};
