const MY_LISTINGS_SETTINGS = {
  per: 10,
  actionsConfig: {
    pending: {
      edit: { show: true },
      delete: { show: true },
    },
    unpublished: {
      publish: { show: true },
      mark_as_rented: { show: true },
      edit: { show: false },
      delete: { show: false },
    },
    active: {
      edit: { show: true },
      mark_as_rented: { show: true },
      unpublish: { show: false },
      share: { show: false },
      upgrade_to_plus: { show: true },
      delete_with_reasons: { show: false },
    },
    booked: {
      seeBooking: { show: true },
    },
    rented: {
      publish: { show: true },
      edit: { show: true },
      delete: { show: false },
    },
  },
};

const ACTION_SETTINGS = {
  preview: {
    key: "preview",
    text: "mylistings.action.preview",
    icon: "/assets/icons/ml-preview.svg",
  },
  edit: {
    key: "edit",
    text: "mylistings.action.edit",
    icon: "/assets/icons/ml-edit.svg",
  },
  delete: {
    key: "delete",
    text: "mylistings.action.delete",
    icon: "/assets/icons/ml-delete.svg",
  },
  delete_with_reasons: {
    key: "deleteWithReasons",
    text: "mylistings.action.delete",
    icon: "/assets/icons/ml-delete.svg",
  },
  publish: {
    key: "publish",
    text: "mylistings.action.publish",
    icon: "/assets/icons/ml-publish.svg",
  },
  mark_as_rented: {
    key: "markAsRented",
    text: "mylistings.action.mark_as_rented",
    icon: "/assets/icons/ml-rented.svg",
  },
  unpublish: {
    key: "unpublish",
    text: "mylistings.action.unpublish",
    icon: "/assets/icons/ml-unpublish.svg",
  },
  share: {
    key: "share",
    text: "mylistings.action.share",
    icon: "/assets/icons/ml-share.svg",
  },
  seeBooking: {
    key: "seeBooking",
    text: "mylistings.action.seeBookingSummary",
    icon: "/assets/icons/ml-booking-summary.svg",
  },
  viewSeekerProfile: {
    key: "viewSeekerProfile",
    text: "mylistings.action.viewSeekerProfile",
    icon: "/assets/icons/ml-view-profile.svg",
  },
  upgrade_to_plus: {
    key: "upgradeListToGold",
    text: "plus_upgrade.cta",
    icon: "/assets/icons/gold-small.svg",
  },
};

const addSeeBookingAction = (
  hasRented,
  visibleActions,
  nonVisibleActions,
  seekerActions,
) => {
  if (hasRented) {
    nonVisibleActions.unshift(ACTION_SETTINGS.seeBooking);
  } else {
    visibleActions.push(ACTION_SETTINGS.seeBooking);
  }
  seekerActions.push(ACTION_SETTINGS.seeBooking);
};

const getAction = (key) => {
  return ACTION_SETTINGS[key];
};

const generateActionsMenu = (
  actionsConfig,
  actions,
  lastBooking,
  hasRented,
) => {
  let visibleActions = [];
  const nonVisibleActions = [];
  const mobileActions = [];
  const seekerActions = [];

  const actionsConfigKeys = Object.keys(actionsConfig);
  visibleActions.push(ACTION_SETTINGS.preview);
  actionsConfigKeys.forEach((key) => {
    if (!actions[key]) {
      return;
    }
    if (actionsConfig[key].show && visibleActions.length <= 3) {
      visibleActions.push(getAction(key));
    } else {
      nonVisibleActions.push(getAction(key));
    }
    mobileActions.push(getAction(key));
  });

  if (lastBooking) {
    addSeeBookingAction(
      hasRented,
      visibleActions,
      nonVisibleActions,
      seekerActions,
    );
  }

  if (lastBooking || hasRented) {
    seekerActions.unshift(ACTION_SETTINGS.viewSeekerProfile);
  }

  if (!!nonVisibleActions.length === 1 && visibleActions.length === 2) {
    visibleActions = visibleActions.concat(nonVisibleActions.splice(0, 1)[0]);
  }

  return { visibleActions, nonVisibleActions, mobileActions, seekerActions };
};

const buildActions = (room) => {
  const actionsConfig = MY_LISTINGS_SETTINGS.actionsConfig[room.listingStatus];
  const hasSeekerBooked =
    (!!room.seeker && !!room.seeker.bookingId) || room.bookingReferenceId;
  const hasSeekerRented = !!room.seeker && !!room.seeker.id;
  const generatedActions = generateActionsMenu(
    actionsConfig,
    room.actions,
    hasSeekerBooked,
    hasSeekerRented,
  );
  return generatedActions;
};

const generateSummaryParams = (props) => {
  const focus = props.location.query.focus;
  return focus ? { status_forced_focus: focus } : null;
};

export {
  MY_LISTINGS_SETTINGS,
  ACTION_SETTINGS,
  generateActionsMenu,
  buildActions,
  generateSummaryParams,
};
