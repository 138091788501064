import React, { useEffect } from "react";
import { modal, MODAL_ID } from "containers/ReduxModal/emitter";

import { USER_ONBOARDING_TYPE } from "datamodel/User/constants";
import { isLister } from "datamodel/User/utils";
import { openGdprModal } from "components/gdpr";
import Onboarding from "components/onboarding";
import {
  openCompleteProfileModal,
  COMPLETE_PROFILE_FROM,
} from "components/complete-profile";
import PhoneVerification from "components/Verification/Phone/phone-verification-mapping";
import { openTermsAndConditionsModal } from "components/terms-and-conditions";
import { openCountryVatModal } from "components/country-vat/country-vat-modal";

import {
  AUTHENTICATION_FORM_MODE,
  openAuthenticationModal,
  closeAuthenticationModal,
} from "../forms/authentication-form";

const { Onboarding: onboardingModalId, Phone: phoneFormModalId } = MODAL_ID;

const Requirements = ({
  countryVat,
  gdpr,
  isEditProfilePage,
  isImmediateListingFlow = false,
  login,
  onOpenEditProfile,
  onOpenListRoom,
  onOpenSeekerPreferences,
  onboarding,
  onboardingType = null,
  phone,
  profile,
  register,
  setCountryVat,
  setGDPR,
  setOnboarding,
  setPhone,
  setProfile,
  setRequirements,
  termsAndConditions,
  isHomepage,
  onOpenMainProfile,
  redirectToHome,
}) => {
  const hideAuthRequirements = () => {
    closeAuthenticationModal();

    setRequirements({
      login: { show: false, callback: null },
      register: { show: false, callback: null },
    });
  };

  const showAuthenticationForm = (callback, mode) => {
    openAuthenticationModal({
      mode,
      onSuccess: callback,
      onClose: hideAuthRequirements,
    });
  };

  const showGDPR = (callback) => {
    openGdprModal({
      onSubmit: () => {
        setGDPR({ visible: false });
        if (typeof callback === "function") callback();
      },
    });
  };

  const hideOnboarding = ({ mode }) => {
    modal.remove(onboardingModalId);
    setOnboarding(false);

    if (isImmediateListingFlow && isLister(mode)) {
      onOpenListRoom();
    } else if (isLister(mode)) {
      redirectToHome();
    } else if (isHomepage()) {
      onOpenSeekerPreferences();
    } else {
      onOpenMainProfile();
    }
  };

  const showOnboarding = () => {
    const { callback } = onboarding;
    const modalOnboardingType =
      onboardingType === USER_ONBOARDING_TYPE.GENERAL
        ? USER_ONBOARDING_TYPE.GENERAL
        : USER_ONBOARDING_TYPE.LISTER;

    modal.add(
      <Onboarding
        callback={(params) => {
          hideOnboarding(params);
          if (typeof callback === "function") callback();
        }}
        onboardingType={modalOnboardingType}
      />,
      {
        id: onboardingModalId,
        closeOnOutsideClick: false,
        closable: false,
        mainClassName: "Onboarding__modal",
      },
    );
  };

  const showCompleteProfile = () => {
    const onSuccess = profile.callback || onOpenEditProfile;

    openCompleteProfileModal({
      onSuccess: () => {
        setProfile(false, null);
        if (onSuccess) onSuccess();
      },
      onCancel: () => {
        setProfile(false, null);
      },
      from: COMPLETE_PROFILE_FROM.DEFAULT,
    });
  };

  const hidePhoneForm = () => {
    modal.remove(phoneFormModalId);
    setPhone(false, null);
  };

  const showPhoneForm = (callback) => {
    modal.add(
      <PhoneVerification
        layout="modal"
        onSuccess={() => {
          hidePhoneForm();
          if (typeof callback === "function") callback();
        }}
      />,
      {
        id: phoneFormModalId,
        closable: true,
        closeOnOutsideClick: true,
        onClose: () => {
          hidePhoneForm();
          if (typeof callback === "function") callback();
        },
      },
    );
  };

  const showCountryVat = (callback) => {
    if (isEditProfilePage()) {
      return;
    }

    openCountryVatModal({
      onClose: () => {
        setCountryVat({ visible: false });
        if (typeof callback === "function") callback();
      },
    });
  };

  const checkRequirements = () => {
    if (gdpr.show) {
      showGDPR(gdpr.callback);
    } else if (termsAndConditions.show) {
      openTermsAndConditionsModal(termsAndConditions.callback);
    } else if (onboarding.show) {
      showOnboarding();
    } else if (profile.show) {
      showCompleteProfile(profile.callback);
    } else if (phone.show) {
      showPhoneForm(phone.callback);
    } else if (login.show) {
      showAuthenticationForm(login.callback, AUTHENTICATION_FORM_MODE.LOGIN);
    } else if (register.show) {
      showAuthenticationForm(
        register.callback,
        AUTHENTICATION_FORM_MODE.REGISTER,
      );
    } else if (countryVat.show) {
      showCountryVat(countryVat.callback);
    }
  };

  useEffect(() => {
    checkRequirements();
  }, [
    gdpr.show,
    termsAndConditions.show,
    onboarding.show,
    profile.show,
    phone.show,
    login.show,
    register.show,
    countryVat.show,
  ]);

  return null;
};

export default Requirements;
