import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import RentedTo from "./rented-to";

export const closeRentedToModal = () => {
  modal.remove(MODAL_ID.MARK_ROOM_AS_RENTED);
};

export const openRentedToModal = ({ id, onUserSelected, token }) => {
  modal.add(<RentedTo id={id} selectUser={onUserSelected} token={token} />, {
    id: MODAL_ID.MARK_ROOM_AS_RENTED,
    closable: true,
    type: MODAL_TYPE.DEFAULT,
  });
};
