import { connect } from "react-redux";
import { areFiltersApplied } from "../utils";

import FilterView from "./filter-view";
import MapView from "./map-view";

export const FilterViewButton = connect((state) => {
  const {
    sortFilter,
    budgetFilter,
    bookingFilter,
    availabilityFilter,
    flatmatesFilter,
    roomFilter,
    flatFilter,
  } = state.SearchFilters;

  return {
    active: areFiltersApplied({
      sortFilter,
      budgetFilter,
      bookingFilter,
      availabilityFilter,
      flatmatesFilter,
      roomFilter,
      flatFilter,
    }),
  };
})(FilterView);

export const MapViewButton = MapView;
