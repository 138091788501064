import { CONVERSATION_ACTION } from "datamodel/Conversation/constants";
import { ACTIONS } from "./constants";

export const isVisitAvailable = (primaryAction) =>
  !(
    !primaryAction ||
    primaryAction === CONVERSATION_ACTION.REPLY_ENQUIRY ||
    primaryAction === CONVERSATION_ACTION.REPLY_INVITE
  );

const parseAction = ({ action }) => {
  if (!action) {
    return null;
  }

  const { action: actionId, label } = action;
  const parsedAction = ACTIONS[actionId.toUpperCase()];
  return parsedAction && { label, ...parsedAction };
};

export const getActions = ({ actions }) => ({
  primary: parseAction({
    action: actions.primary,
  }),
  secondary: parseAction({
    action: actions.secondary,
  }),
});
