import React, { useState } from "react";
import {
  Choice,
  Counter,
  Text,
  TextButton,
  Spacer,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import FilterSectionWrapper from "../../wrappers/filter-section-wrapper";
import FilterSubSectionWrapper from "../../wrappers/filter-sub-section-wrapper";

import {
  FormSectionChoices,
  FormSectionChoiceElement,
  SubmitFormWrapper,
} from "../forms/styled-components";

const MarketplaceSegmentsForm = ({
  filters,
  setMarketplaceSegmentsFilters,
  onFilterApplied,
}) => {
  const handleApplyFilter = () => {
    setMarketplaceSegmentsFilters(filtersState);
    onFilterApplied();
  };
  const [filtersState, setFiltersState] = useState({
    badiCommunity: filters.badiCommunity,
    badiRooms: filters.badiRooms,
  });
  const { badiCommunity, badiRooms } = filtersState;

  const toggleFilter = (type) => {
    switch (type) {
      case "badi-rooms":
        setFiltersState({
          ...filtersState,
          badiRooms: !filtersState.badiRooms,
        });
        break;
      case "badi-community":
        setFiltersState({
          ...filtersState,
          badiCommunity: !filtersState.badiCommunity,
        });
    }
  };

  const segmentsSections = [
    {
      name: "badi-rooms",
      label: "Badi",
      isChecked: badiRooms,
      spacerProps: { bottom: 2 },
    },
    {
      name: "badi-community",
      label: translate(
        "search.filters.marketplace_segments.segments.badiCommunity",
      ),
      isChecked: badiCommunity,
      spacerProps: { md: { top: 2 }, xs: { top: 1 } },
    },
  ];
  return (
    <FilterSectionWrapper
      title={`${translate("search.filters.marketplace_segments.title")}:`}
    >
      <Spacer bottom={3}>
        <Text body={3}>
          {translate("search.filters.marketplace_segments.description")}
        </Text>
      </Spacer>
      {segmentsSections.map(({ name, label, isChecked, spacerProps }) => {
        const key = `marketplace-segment-${name}`;
        return (
          <Spacer {...{ ...spacerProps, key }}>
            <FormSectionChoiceElement
              as={Choice}
              checked={isChecked}
              id={key}
              name={key}
              type="checkbox"
              value={key}
              onChange={() => toggleFilter(name)}
            >
              {label}
            </FormSectionChoiceElement>
          </Spacer>
        );
      })}
      <FilterSubSectionWrapper title="">
        <SubmitFormWrapper>
          <TextButton
            id="marketplace-segments-apply-button"
            onClick={handleApplyFilter}
          >
            {translate("search.filters.button")}
          </TextButton>
        </SubmitFormWrapper>
      </FilterSubSectionWrapper>
    </FilterSectionWrapper>
  );
};

export default MarketplaceSegmentsForm;
