import React from "react";
import { Card, Flex, Heading, Spacer, Text } from "@badi/badi-components";
import { Link as ReactRouterLink } from "react-router";

import Image from "components/Image/Image";

import { Link, ImageWrapper } from "./styled-components";

const InfoCard = ({
  badge = null,
  badgeTitle = "",
  content,
  href,
  icon,
  title,
}) => {
  return (
    <Link as={ReactRouterLink} to={href}>
      <Card>
        <Spacer bottom={2} left={2} right={2} top={2}>
          <Flex alignItems="flexEnd" gutter={1}>
            <ImageWrapper>
              <Image alt={title} src={icon} />
            </ImageWrapper>
            <Flex alignItems="flexEnd" gutter={1}>
              {badgeTitle && (
                <Text body={2} data-qa="overview-infocard-badge-title">
                  {badgeTitle}
                </Text>
              )}
              {badge}
            </Flex>
          </Flex>
          <Spacer bottom={1} top={1}>
            <Heading data-qa="overview-infocard-title" level={4}>
              {title}
            </Heading>
          </Spacer>
          <Text body={3} data-qa="overview-infocard-content">
            {content}
          </Text>
        </Spacer>
      </Card>
    </Link>
  );
};

export default InfoCard;
