import { ROOM_MIN_DAYS_STAY } from "datamodel/Room/constants";
import VisitPreferences from "./visit-preferences-model";

const visitPreferences = new VisitPreferences().toJSON();

const RoomListModel = {
  id: 0,
  totalSteps: 1,
  initialStep: 1,
  title: "",
  location: {},
  error: null,
  fetching: false,
  property: {
    flatAmenities: [],
    roomAmenities: [],
    femaleTenants: 0,
    maleTenants: 0,
    nonBinaryTenants: 0,
    rules: [],
    toilets: 0,
    bathrooms: 0,
    bedType: null,
    singleBedrooms: 0,
    doubleBedrooms: 0,
    resident: false,
    lift: null,
  },
  room: {
    availableFrom: null,
    availableTo: null,
    billsIncluded: false,
    currency: null,
    minimumStay: ROOM_MIN_DAYS_STAY,
    maximumStay: "noMax",
    price: "",

    deposit: 0,
    availableToEnabled: false,
    canSubmit: false,
    benefits: [],
  },
  status: 0,
  photos: {},
  flatmate: {
    occupation: 3,
    min_age: 18,
    max_age: 99,
    genders: { female: true, nonBinary: true, male: true },
  },
  publish: false,
  flatSize: 0,
  sizeUnit: {
    value: 1,
    label: "m²",
  },
  visitPreferences,
  moderationResult: null,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.RoomList) {
    return { ...RoomListModel, error: "There was an error in RoomListModel." };
  }
  return state;
}

export { RoomListModel, setInitialState };
