import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import ReviewsSummary from "./reviews-summary-modal-mapping";

export const closeReviewsSummaryModal = () => {
  modal.remove(MODAL_ID.REVIEWS_SUMMARY);
};

export const openReviewsSummaryModal = (id, type) => {
  modal.add(<ReviewsSummary id={id} type={type} />, {
    id: MODAL_ID.REVIEWS_SUMMARY,
    onClose: closeReviewsSummaryModal,
    closable: true,
    closeOnOutsideClick: true,
    type: MODAL_TYPE.LATERAL,
  });
};
