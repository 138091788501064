import React from "react";

const Cross = ({ color }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.56 2.56L8.623 6.5l3.939 3.94a1.5 1.5 0 0 1-2.121 2.12L6.5 8.622l-3.94 3.94c-.292.292-.676.439-1.06.439s-.767-.146-1.06-.44a1.5 1.5 0 0 1 0-2.121l3.938-3.94L.44 2.562A1.5 1.5 0 0 1 2.56.439L6.5 4.38l3.94-3.94a1.5 1.5 0 0 1 2.12 2.122z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

Cross.defaultProps = {
  color: "#000",
};

export default Cross;
