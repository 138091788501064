import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";

import ReviewsSummaryContainer from "./reviews-summary-container";

const mapStateToProps = (state) => ({
  token: getToken(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewsSummaryContainer);
