import React from "react";
import styled from "styled-components";

import { colors } from "../constants";
import { getHeadingFont } from "../font-sizes";
import { getHtmlTag } from "./utils";

export const ALIGNMENT_CHOICES = [
  "inherit",
  "left",
  "right",
  "center",
  "justify",
];

const StyledHeading = styled.h1`
  margin: 0;
  color: ${(props) => props.textColor};
  text-align: ${(props) => props.align};
  ${(props) =>
    props.hero ? getHeadingFont("32-Extrabold") : getHeadingFont(props.level)}
`;

const Heading = ({
  children,
  color = colors.darkGrey,
  hero = false,
  level = "28-Extrabold",
  align = "inherit",
  ...props
}) => {
  const tag = getHtmlTag(level, hero);

  return (
    <StyledHeading
      align={align}
      as={tag}
      hero={hero}
      level={level}
      textColor={color}
      {...props}
    >
      {children}
    </StyledHeading>
  );
};

export default Heading;
