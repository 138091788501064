const DeviceModel = {
  os: {
    name: null,
    version: null,
  },
  device: {
    name: null,
    version: null,
  },
  browser: {
    name: null,
    version: null,
  },
  isSafari: false,
  isChrome: false,
  isFirefox: false,
  isMobile: false,
  isDesktop: true,
  isIE: false,
  isIOS: false,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Auth) {
    return { ...DeviceModel, error: "There was an error in DeviceModel." };
  }
  return state;
}

export { DeviceModel, setInitialState };
