import React, { Component } from "react";

import { Flex, TextButton } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { getSelectedIds, getSelectedElements } from "../utils";

import RoomFields from "./room-fields";
import { SubmitFormWrapper, StyledForm } from "../styled-components";

class RoomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bedTypeIds: [],
      bookable: false,
      roomAmenityIds: [],
      placeTypeId: 0,
      updated: false,
    };
    this.roomAmenities = translate(props.roomAmenities);
    this.placeTypes = translate(props.placeTypes);
    this.bedTypes = translate(props.bedTypes);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.updated) {
      return { updated: false };
    }

    const { bedTypes, bookable, placeTypes, roomAmenities } = props;
    const selectedplaceType = placeTypes.find(
      (placeType) => placeType.selected,
    );
    const placeTypeId = selectedplaceType ? selectedplaceType.id : 0;

    return {
      bedTypeIds: getSelectedIds(bedTypes),
      bookable,
      placeTypeId,
      roomAmenityIds: getSelectedIds(roomAmenities),
    };
  }

  handleBedTypeChange = (bedTypeIds) => {
    this.setState({ bedTypeIds, updated: true });
  };

  handlePlaceTypeChange = (placeTypeId) => {
    this.setState({ placeTypeId, updated: true });
  };

  handleRoomAmenitySelect = (roomAmenityIds) => {
    this.setState({ roomAmenityIds, updated: true });
  };

  handleBookableChange = (bookable) => {
    this.setState({ bookable, updated: true });
  };

  handleApplyFilter = () => {
    const { bedTypeIds, bookable, placeTypeId, roomAmenityIds } = this.state;
    const { bedTypes, onFilterApplied, placeTypes, roomAmenities, setRoom } =
      this.props;

    const parsedPlaceTypes = getSelectedElements(placeTypes, [placeTypeId]);
    const parsedRoomAmenities = getSelectedElements(
      roomAmenities,
      roomAmenityIds,
    );

    const parsedBedTypes = getSelectedElements(bedTypes, bedTypeIds);

    setRoom(bookable, parsedBedTypes, parsedPlaceTypes, parsedRoomAmenities);
    onFilterApplied();
  };

  render() {
    return (
      <Flex as={StyledForm} direction="column">
        <RoomFields
          {...this.state}
          bedTypes={this.bedTypes}
          onBedTypeChange={this.handleBedTypeChange}
          onBookableChange={this.handleBookableChange}
          onPlaceTypeChange={this.handlePlaceTypeChange}
          onRoomAmenitySelect={this.handleRoomAmenitySelect}
          placeTypes={this.placeTypes}
          roomAmenities={this.roomAmenities}
        />
        <SubmitFormWrapper>
          <TextButton onClick={this.handleApplyFilter}>
            {translate("search.filters.button")}
          </TextButton>
        </SubmitFormWrapper>
      </Flex>
    );
  }
}

export default RoomForm;
