import BookingBanner from "components/booking-banner";

export const getBanner = (type) => {
  switch (type) {
    case "static_banner_booking":
      return BookingBanner;
    default:
      return BookingBanner;
  }
};
