import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { getAcceptedRequestUsers } from "datamodel/search-rooms/api";
import { User } from "datamodel/User/models";
import { UserButton, UsersList, UsersListWrapper } from "./styled-components";

const RentedTo = ({ id, selectUser, token }) => {
  const [fetching, setFetching] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setFetching(true);
    getAcceptedRequestUsers(id, token)
      .then((requests) => {
        setFetching(false);
        setUsers(
          requests.map(({ id: requestId, user }) => ({
            ...new User(user).toJSON(),
            requestId,
          })),
        );
      })
      .catch((error) => {
        setFetching(false);

        UserFeedback.exception(
          "Something went wrong",
          "Please, try again or contact support.",
          error,
        );
      });
  }, []);

  const clickHandler = (event) => {
    const rentedId = parseInt(event.currentTarget.value, 10);

    selectUser({
      id,
      rented_to_user: rentedId || null,
      rented_to_date: null,
    });
  };

  return (
    <Flex alignItems="center" data-qa="rented-to" direction="column" gutter={4}>
      <Flex direction="column" gutter={3}>
        <Heading data-qa="rented-to-heading" level="20-Bold">
          {translate("Who did you rent it to?")}
        </Heading>
        <UsersListWrapper>
          {users.length > 0 && (
            <UsersList>
              {users.map((user) => (
                <li key={user.id}>
                  <UserButton
                    data-qa="rented-to-user"
                    onClick={clickHandler}
                    value={user.id}
                  >
                    <Flex alignItems="center" gutter={1}>
                      <Avatar alt={user.name} size={48} src={user.picture} />
                      <Heading level="16-Medium">{`${user.name}, ${user.age}`}</Heading>
                    </Flex>
                  </UserButton>
                  <Spacer bottom={2} />
                </li>
              ))}
            </UsersList>
          )}
        </UsersListWrapper>
      </Flex>
      <Flex alignItems="center" gutter={2} justify="spaceBetween">
        <Text dataQa="rented-to-question">
          {translate("Not rented through Badi?")}
        </Text>
        <Button
          dataQa="rented-to-button"
          disabled={fetching}
          onClick={clickHandler}
        >
          {translate("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default RentedTo;
