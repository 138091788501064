import React from "react";
import { BADI_COLORS, Icon } from "@badi/badi-components";
import LayoutModal from "../Layout";
import { CloseIconWrapper } from "./styled-components";

const DefaultModal = ({
  children,
  closable,
  isClosed,
  outsideClickHandler,
  backgroundColor,
}) => {
  return (
    <LayoutModal
      backgroundColor={backgroundColor}
      isClosed={isClosed}
      outsideClickHandler={outsideClickHandler}
    >
      {closable && (
        <CloseIconWrapper
          data-qa="default-modal-close"
          onClick={outsideClickHandler}
        >
          <Icon
            alt="close icon"
            color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
            data-qa="default-modal-close-icon"
            height={16}
            name="close"
            width={16}
          />
        </CloseIconWrapper>
      )}
      {children}
    </LayoutModal>
  );
};

DefaultModal.defaultProps = {
  children: null,
  closable: false,
  isClosed: false,
  outsideClickHandler: () => {},
};

export default DefaultModal;
