import styled, { css } from "styled-components";
import { colors, mediaquery, Text, unitizedPx } from "@badi/badi-components";

export const InboxRowAvatar = styled.div`
  margin-right: ${unitizedPx(2)};
`;

export const InboxRowBorder = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const InboxRowCol = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const InboxRowContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${mediaquery.md`
    flex-direction: row;
  `}
`;

export const InboxRowDate = styled.div`
  margin-left: ${unitizedPx(2)};
  flex-shrink: 0;
`;

export const InboxRowPreview = styled.div`
  min-width: 0;
`;

export const InboxRowStatus = styled.div`
  flex-shrink: 0;

  ${mediaquery.md`
    width: ${unitizedPx(26)};
  `}
`;

export const InboxRowWrapper = styled.div`
  display: flex;
`;

export const TextWithEllipsis = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const customChildCss = css`
  ${mediaquery.md`
    :last-child {
      float: right;
    }
  `}

  :first-child {
    border-right: 1px solid ${colors.lightGrey};
  }
`;
