import { createSelector } from "reselect";
import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";
import moment from "moment";
import { ROOM_STATUS } from "./constants";

const getRoomFromState = (state) => state.Room;

export const getRoom = createSelector(getRoomFromState, (room) => room.room);

export const isRoomFetchedSelector = createSelector(
  getRoomFromState,
  (room) => room.fetched,
);

export const getRoomError = createSelector(
  getRoomFromState,
  (room) => room.error,
);

export const getSimilarRooms = createSelector(
  getRoomFromState,
  (room) => room.similarRooms,
);

export const getUserRole = createSelector(getRoom, (room) => {
  if (!room) return null;

  return room.owned ? LISTER_USER_ROLE : SEEKER_USER_ROLE;
});

export const getRoomSummary = createSelector(getRoom, (room) => {
  if (!room) return null;

  const {
    address,
    id,
    title,
    picture,
    price,
    availableFrom,
    minStay,
    city,
    country,
  } = room;

  // I explictly add it here to avoid an abstraction and forget about removing it
  // TODO: remove momentjs from the store, THIS IS AN ANTIPATTERN
  const availableDate =
    availableFrom instanceof moment
      ? availableFrom.format("YYYY-MM-DD")
      : availableFrom;

  return {
    id,
    title,
    address,
    city,
    country,
    picture,
    availableFrom: availableDate,
    minStay,
    price: price.displayText,
    billsIncluded: price.billsIncluded,
  };
});

export const getRoomUser = createSelector(getRoom, (room) => {
  const { user } = room;

  if (!user) return null;

  const {
    id,
    age,
    picture,
    verificationLevel,
    languages,
    name,
    occupation,
    listerScore,
  } = user;

  return {
    id,
    age,
    avatar: picture,
    badge: verificationLevel,
    languages,
    name,
    occupation,
    role: LISTER_USER_ROLE,
    listerScore,
  };
});

export const isRoomInReviewSelector = createSelector(
  getRoom,
  (room) => room.status === ROOM_STATUS.PENDING,
);

export const isEntiredPropertySelector = createSelector(
  getRoom,
  (room) => room.placeType === 2,
);

export const getStandardEnquiriesCount = createSelector(
  getRoom,
  (room) => room.standardEnquiriesCount,
);

export const getFastTrackEnquiriesCount = createSelector(
  getRoom,
  (room) => room.fastTrackEnquiriesCount,
);

export const getLabels = createSelector(getRoom, (room) => room.labels);
