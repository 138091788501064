import React from "react";
import { Flex, Heading, Spacer, Text } from "@badi/badi-components";
import Image from "components/Image/Image";

import { translate } from "base/shared/Localization";

import {
  MetricsNotFoundWrapper,
  MetricsNotFoundDescription,
  MetricsNotFoundTitle,
} from "../styled-components";

const getEmptyText = (roomsAvailable) =>
  roomsAvailable
    ? translate("lister.analytics_empty_text")
    : translate("lister.analytics_empty_text_no_rooms");

const ListerMetricsEmpty = ({ roomsAvailable = true }) => (
  <MetricsNotFoundWrapper data-qa="lister-metrics-empty-wrapper">
    <Flex
      alignItems="center"
      direction="column"
      fullHeight={true}
      justify="flexEnd"
    >
      <Spacer bottom={4}>
        <Image
          data-qa="lister-metrics-empty-image"
          height={160}
          src="/assets/images/search-empty-state.svg"
        />
      </Spacer>
      <Heading
        as={MetricsNotFoundTitle}
        data-qa="lister-metrics-empty-title"
        level={3}
      >
        {translate("lister.analytics_empty_title")}
      </Heading>
      <Spacer left={2.5} right={2.5} top={2}>
        <MetricsNotFoundDescription>
          <Text data-qa="lister-metrics-empty-description">
            {getEmptyText(roomsAvailable)}
          </Text>
        </MetricsNotFoundDescription>
      </Spacer>
    </Flex>
  </MetricsNotFoundWrapper>
);

export default ListerMetricsEmpty;
