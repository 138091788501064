import React from "react";
import { Button, Text, Flex, Heading, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image/Image";

import { redirectOverview } from "../utils";

const EmptyList = () => (
  <Spacer sm={{ top: 7 }} xs={{ top: 4 }}>
    <div className="Recommendation__list_empty">
      <Flex alignItems="center" direction="column">
        <Image src="/assets/icons/empty-recommended-tenant.svg" />

        <Spacer bottom={1}>
          <Heading level={4}>
            {translate("recommendations.empty.description.first")}
          </Heading>
        </Spacer>
        <Spacer bottom={3}>
          <Text body={3}>
            {translate("recommendations.empty.description.second")}
          </Text>
        </Spacer>
        <Button onClick={redirectOverview}>
          {translate("recommendations.empty.button")}
        </Button>
      </Flex>
    </div>
  </Spacer>
);

export default EmptyList;
