import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Modal from "components/Modals/Modal";
import Actions from "app/datamodel/Modals/actions";

import { EE } from "./emitter";

import styles from "./styles.css";

class ReduxModal extends Component {
  constructor(props) {
    super(props);

    this.actions = bindActionCreators(Actions, props.dispatch);
    this.getModal = this.getModal.bind(this);
  }

  componentDidMount() {
    EE.on("add/modal", (component, options) => {
      this.actions.addModal({ component, options });
    });
    EE.on("clear/all", this.actions.clearAll);
    EE.on("remove/modal", (id) => this.actions.removeModal(id));
    EE.on("destroy/modal", (id) => this.actions.destroyModal(id));
    EE.on("update/modal", (id, component, options) =>
      this.actions.updateModal({ id, component, options }),
    );
    EE.on("updateOptions/modal", (id, options) =>
      this.actions.updateModalOptions({ id, options }),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const modalsLength = this.props.modals.length;
    const nextModalsLength = nextProps.modals.length;

    if (modalsLength === 0 && nextModalsLength > 0) {
      document.body.classList.add("popup_open");
    } else if (nextModalsLength === 0) {
      document.body.classList.remove("popup_open");
    }
  }

  componentWillUnmount() {
    EE.removeListener("add/modal");
    EE.removeListener("clear/all");
    EE.removeListener("destroy/all");
    EE.removeListener("remove/modal");
    EE.removeListener("update/modal");
  }

  closeHandler(customHandler) {
    if (typeof customHandler === "function") customHandler();
  }

  getModal(modal) {
    return (
      <Modal
        backgroundColor={modal.options.backgroundColor}
        className={modal.options.className}
        closable={modal.options.closable}
        closeOnOutsideClick={modal.options.closeOnOutsideClick}
        forceClose={modal.destroy}
        headContent={modal.options.headContent}
        isOpen={true}
        key={modal.options.id}
        mainClassName={modal.options.mainClassName}
        mobileType={modal.options.mobileType}
        modalId={modal.options.id}
        onClose={() => this.closeHandler(modal.options.onClose)}
        options={modal.options}
        type={modal.options.type}
      >
        {modal.component}
      </Modal>
    );
  }

  render() {
    const modalsContent = this.props.modals.map((modal) =>
      this.getModal(modal),
    );

    return <div className={styles.ModalsContainer}>{modalsContent}</div>;
  }
}

ReduxModal.defaultProps = {
  modals: [],
};

export default connect((state) => ({
  modals: state.Modals.modals,
}))(ReduxModal);
