import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import BudgetForm from "./budget-form";

export default connect(
  (state) => ({
    min: state.SearchFilters.min,
    max: state.SearchFilters.max,
  }),
  (dispatch) => ({
    setBudget: (min, max) => dispatch(Actions.setBudget({ min, max })),
  }),
)(BudgetForm);
