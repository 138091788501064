import React from "react";
import { getHomePath } from "Sections/Home/routes";
import MainProfileMapping from "./main-profile-mapping";

const MainProfileRoute = ({ router, location }) => {
  const { destination = null } = location.query;

  const handleClose = () => {
    router.push(destination || getHomePath());
  };

  return <MainProfileMapping onClose={handleClose} />;
};

export default MainProfileRoute;
