import { useState, useEffect } from "react";
import { NotificationsEventHandler } from "../utils";
import { removeItemById } from "../utils";
import { NotificationAction } from "../models";

export function useNotifications(maxNotifications) {
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    function handleNotificationChange({ detail: notificationEvent }) {
      if (notificationEvent.action === NotificationAction.REMOVE) {
        setNotificationList(
          removeItemById(notificationList, notificationEvent.id),
        );
        return;
      }

      if (notificationList.length >= maxNotifications) {
        return;
      }

      const { duration, id, preventDuplicated } = notificationEvent;

      if (
        preventDuplicated &&
        notificationList.some(({ id: notificationId }) => notificationId === id)
      ) {
        return;
      }

      setTimeout(() => {
        NotificationsEventHandler.remove(id);
      }, duration);

      setNotificationList([...notificationList, notificationEvent]);
    }

    NotificationsEventHandler.subscribe(handleNotificationChange);

    return () => {
      NotificationsEventHandler.unsubscribe(handleNotificationChange);
    };
  });

  return notificationList;
}
