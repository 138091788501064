import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";

export const Root = styled.div`
  overflow: hidden;
  background: ${({ background }) => background};
`;

export const ImageWrapper = styled.div`
  text-align: center;
  position: relative;
`;

export const ContentWrapper = styled.div`
  margin-top: ${unitizedPx(-18)};
`;

export const CustomHeading = styled.h1`
  color: ${({ color }) => color};
  font-size: ${unitizedPx(3.5)};
  line-height: ${unitizedPx(4.5)};
  font-weight: 700;
  text-align: center;

  ${mediaquery.md`
    font-size: ${unitizedPx(5)};
    line-height: ${unitizedPx(6)};
  `}
`;

export const Avatar = styled.img`
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: 50%;
  border: 2px ${BADI_COLORS.BRAND.SECONDARY.DEFAULT} solid;
  height: ${unitizedPx(10.5)};
  width: ${unitizedPx(10.5)};
  object-fit: cover;
`;
