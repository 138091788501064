import React from "react";
import { Button } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import DefaultLayout from "./layout";
import CompactedLayout from "./compacted-layout";

const ConnectionFailed = ({ roomDetails, userImage, compact = false }) => {
  const handleClick = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  const Layout = compact ? CompactedLayout : DefaultLayout;

  return (
    <Layout
      description={translate("videocall.error_text")}
      image="/assets/images/visits/connection-failed.svg"
      roomDetails={roomDetails}
      title={translate("videocall.error_title")}
      userImage={userImage}
    >
      <Button
        data-qa="visit-express-refresh-button"
        fullWidth={true}
        onClick={handleClick}
      >
        {translate("videocall.error_cta")}
      </Button>
    </Layout>
  );
};

export default ConnectionFailed;
