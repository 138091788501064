import React, { memo } from "react";
import { translate } from "base/shared/Localization";

import { Link } from "react-router";
import { colors, Flex, Heading, Spacer, Text } from "@badi/badi-components";
import { getRelativePath } from "base/shared/Utils";
import {
  RoomCardWrapper,
  ImageWrapper,
  SlideWrapper,
  RoomCardDetails,
  Title,
  LowDetailsSection,
} from "./styled-components";

const RoomCard = ({
  id,
  url,
  ownerBadge,
  displayPrice,
  title,
  pictureUrl,
  handleClick,
  ...props
}) => {
  return (
    <div onClick={() => handleClick(id)}>
      <SlideWrapper
        as={Link}
        target="_blank"
        to={getRelativePath(url)}
        {...props}
      >
        <Flex as={RoomCardWrapper} direction="column">
          <ImageWrapper>
            <img alt="room" src={pictureUrl} />
          </ImageWrapper>
          <RoomCardDetails grow={1}>
            <Flex direction="column" fullHeight={true} justify="spaceBetween">
              <Spacer bottom={2} top={2}>
                <Heading as={Title} level={4}>
                  {title}
                </Heading>
              </Spacer>
              <LowDetailsSection
                alignItems="center"
                as={Flex}
                justify="flexEnd"
                reverse={true}
              >
                <Flex alignItems="center">
                  <Heading level={4}>{`${displayPrice}`}</Heading>
                  <Text body={5} color={colors.darkGrey}>
                    /{translate("month")}
                  </Text>
                </Flex>
              </LowDetailsSection>
            </Flex>
          </RoomCardDetails>
        </Flex>
      </SlideWrapper>
    </div>
  );
};

RoomCard.defaultProps = {
  ownerBadge: "",
};

export default memo(RoomCard);
