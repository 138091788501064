import React from "react";
import { Spacer } from "@badi/badi-components";
import { getOtherUserRole } from "datamodel/User/utils";

import UserProfileBadge from "components/user-profile-badge";
import RoomDetailBadge from "components/room-detail-badge";
import { BlockWrapper } from "./styled-components";

const RoomListerSummary = ({ lister, room, userRole, token }) => {
  return (
    <>
      {lister && (
        <BlockWrapper data-qa="booking-preview-user">
          <Spacer bottom={4}>
            <UserProfileBadge
              {...lister}
              token={token}
              userRole={getOtherUserRole(userRole)}
            />
          </Spacer>
        </BlockWrapper>
      )}
      {room && (
        <BlockWrapper data-qa="booking-preview-room">
          <Spacer bottom={4} top={4}>
            <RoomDetailBadge {...room} />
          </Spacer>
        </BlockWrapper>
      )}
    </>
  );
};

export default RoomListerSummary;
