import React, { useCallback, useEffect } from "react";
import { Flex, Text } from "@badi/badi-components";
import { useForm } from "components/hooks";
import { translate } from "base/shared/Localization";
import Input from "components/form-text-input";

const formatSortCode = ({ firstCode = "", secondCode = "", thirdCode = "" }) =>
  `${firstCode}${secondCode}${thirdCode}`;

const sortCodeFormatValidation = (value) => /^\d{2}$/g.test(value);

const SORT_CODE_VALIDATIONS = [sortCodeFormatValidation];

const SortCode = ({
  disabled,
  name,
  onSortCodeChange,
  onSortCodeValidation,
}) => {
  const [values, setInputValue] = useForm({
    firstCode: "",
    secondCode: "",
    thirdCode: "",
  });
  const [validations, setInputValidation] = useForm({
    firstCode: false,
    secondCode: false,
    thirdCode: false,
  });

  const handleChange = useCallback(({ name: inputName, value }) => {
    setInputValue(inputName, value);
  }, []);

  const handleValidation = useCallback(({ name: inputName, isValid }) => {
    setInputValidation(inputName, isValid);
    const updatedValidations = { ...validations, [inputName]: isValid };

    onSortCodeValidation({
      name,
      isValid: !Object.values(updatedValidations).some(
        (validation) => !validation,
      ),
    });
  });

  useEffect(() => {
    onSortCodeChange({ name, value: formatSortCode(values) });
  }, [values]);

  return (
    <Flex direction="column" gutter={1}>
      <Text body={2}>{translate("payout.bank_account.sort_code")}</Text>
      <Flex direction="row" gutter={1}>
        <Input
          disabled={disabled}
          key="firstCode"
          name="firstCode"
          onChange={handleChange}
          onInputValidation={handleValidation}
          placeholder="00"
          required={true}
          validations={SORT_CODE_VALIDATIONS}
        />
        <Input
          disabled={disabled}
          key="secondCode"
          name="secondCode"
          onChange={handleChange}
          onInputValidation={handleValidation}
          placeholder="00"
          required={true}
          validations={SORT_CODE_VALIDATIONS}
        />
        <Input
          disabled={disabled}
          key="thirdCode"
          name="thirdCode"
          onChange={handleChange}
          onInputValidation={handleValidation}
          placeholder="00"
          required={true}
          validations={SORT_CODE_VALIDATIONS}
        />
      </Flex>
    </Flex>
  );
};

export default SortCode;
