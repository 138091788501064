import React from "react";
import Padlock from "assets/icons/padlock.svg";
import { IconWrapper } from "./styled-components";

const LockIcon = () => {
  return (
    <IconWrapper data-qa="recommendation-block-icon">
      <Padlock />
    </IconWrapper>
  );
};

export default LockIcon;
