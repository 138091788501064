import React, { useState, useEffect } from "react";
import GoogleMap from "components/google-map";
import { scrollTo } from "base/shared/Utils";
import TransportButton from "components/map/transport-button";

import { Marker } from "components/map/marker";
import { MapWrapper } from "components/map/styled-components";
import { generateBounds, getCenter, generateRoomCardIdString } from "../utils";

const GOOGLE_MAP_OPTIONS = {
  fullscreenControl: false,
  maxZoom: 15,
};

let internalMaps;
let internalMap;

const Map = ({ rooms }) => {
  const [transport, setTransport] = useState(false);
  const [center, setCenter] = useState(rooms[0].coordinates);

  const handleRoomClick = (id) => {
    const elementId = generateRoomCardIdString(id);
    const element = document.getElementById(elementId);
    const wrapperElement = document.getElementById("search-rooms-list");

    if (element) {
      scrollTo(element.offsetTop, 200, wrapperElement);
    }
  };

  const handleTransportClick = () => {
    setTransport(!transport);
  };

  const generateCoords = (map, maps) => {
    const bounds = generateBounds(map, maps, rooms);
    const coords = getCenter(bounds);
    setCenter(coords);
  };

  const onApiLoaded = ({ map, maps }) => {
    internalMaps = maps;
    internalMap = map;
    generateCoords(map, maps);
  };

  useEffect(() => {
    if (internalMaps && internalMap) {
      generateCoords(internalMap, internalMaps);
    }
  }, [rooms]);

  return (
    <>
      <MapWrapper>
        {center && (
          <>
            <GoogleMap
              center={center}
              layerTypes={transport ? ["TransitLayer"] : []}
              onGoogleApiLoaded={onApiLoaded}
              options={GOOGLE_MAP_OPTIONS}
              resetBoundsOnResize={true}
              yesIWantToUseGoogleMapApiInternals={true}
              zoom={12}
            >
              {rooms.map((room) => (
                <Marker
                  id={room.id}
                  key={room.id}
                  onClick={handleRoomClick}
                  {...room.coordinates}
                >
                  {room.price.displayText}
                </Marker>
              ))}
            </GoogleMap>
            <TransportButton
              onClick={handleTransportClick}
              selected={transport}
            />
          </>
        )}
      </MapWrapper>
    </>
  );
};

export default Map;
