import React from "react";
import { BUTTON_SIZES, BUTTON_TYPES, Flex } from "@badi/badi-components";
import { useSelector } from "react-redux";
import { isSeeker } from "datamodel/User/utils";
import { translate } from "base/shared/Localization";
import { getToken, getUserId } from "datamodel/Auth/selectors";
import { getUserRole } from "datamodel/Conversation/selectors";
import { getVisitIdFromConnectionSelector } from "datamodel/visits/selectors";
import { openVisitProposalModal } from "Sections/visits/visit-proposal";
import { openVisitOverviewModal } from "Sections/visits/visit-overview";
import { openVisitFeedbackModal } from "Sections/visits/visit-feedback";
import { openConnectionDetailsModal } from "../connection-details";
import ChatAction from "./action";

const ButtonsHandler = ({
  primary = null,
  secondary = null,
  connection,
  onRedirect,
  reduced = false,
}) => {
  const visitId = useSelector(getVisitIdFromConnectionSelector);
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);
  const token = useSelector(getToken);
  const {
    room: { id: roomId },
    bookingReferenceId,
    bookingRequestId,
    id: connectionId,
  } = connection;

  const actionsCallbacks = {
    "propose-visit": () =>
      openVisitProposalModal({
        roomId,
        connectionId,
        seekerId: isSeeker(userRole) ? userId : 0,
      }),
    "visit-overview": () => {
      openVisitOverviewModal({ visitId });
    },
    "provide-visit-feedback": () => {
      openVisitFeedbackModal({ visitId });
    },
    "connection-details": () => {
      const { allowedActions, room, user } = connection;

      openConnectionDetailsModal({
        actions: { leaveConnection: () => {} },
        allowedActions,
        connectionId,
        room,
        token,
        user,
      });
    },
  };

  const handleActionClick =
    ({ path: getPath, callbackAction }) =>
    () => {
      if (!getPath) {
        return actionsCallbacks[callbackAction]();
      }

      const path = getPath({
        connectionId,
        referenceId: bookingReferenceId,
        requestId: bookingRequestId,
      });
      onRedirect(path);
    };

  const secondaryUIProps = {
    basis: 0,
    fullWidth: true,
    grow: 1,
    size: reduced ? BUTTON_SIZES.SMALL : BUTTON_SIZES.REGULAR,
    variant: BUTTON_TYPES.SECONDARY,
  };

  return (
    <Flex direction={reduced ? "column" : "row"} gutter={1} justify="flexEnd">
      <Flex gutter={1}>
        {reduced && (
          <ChatAction
            {...secondaryUIProps}
            config={{
              dataQa: "conversation-see-details",
              label: translate("conversation.actions.see_details"),
            }}
            onClick={handleActionClick({
              callbackAction: "connection-details",
            })}
          />
        )}
        {!!secondary && (
          <ChatAction
            {...secondaryUIProps}
            config={secondary}
            onClick={handleActionClick(secondary)}
          />
        )}
      </Flex>
      {primary && (
        <ChatAction
          config={primary}
          fullWidth={true}
          onClick={handleActionClick(primary)}
          size={reduced ? BUTTON_SIZES.SMALL : BUTTON_SIZES.REGULAR}
        />
      )}
    </Flex>
  );
};

export default ButtonsHandler;
