const STORAGE_KEYS = {
  HAS_USER_SEEN_FIRST_TIME_LISTER_REQUESTS_INFORMATION:
    "has-user-seen-first-time-lister-requests-information",
  HAS_USER_SEEN_REQUEST_ACCEPTED_MODAL: "has-user-seen-request-accepted-modal",
};

const LocalStorage = {
  getItem: (key) => {
    const item = localStorage.getItem(key);
    if (item == "true") {
      return true;
    }
    if (item == "false") {
      return false;
    }
    return item;
  },
  setItem: (key, value) => {
    return localStorage.setItem(key, value);
  },
};

export { STORAGE_KEYS };
export default LocalStorage;
