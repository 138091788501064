import React from "react";
import { SubCategoryList, SubCategory } from "./styled-components";

const Category = ({ subCategories }) => {
  return (
    <SubCategoryList>
      {subCategories.map(({ target = "_self", title, url }) => (
        <SubCategory href={url} key={`${title}-${url}`} target={target}>
          {title}
        </SubCategory>
      ))}
    </SubCategoryList>
  );
};

export default Category;
