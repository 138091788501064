import styled, { css, keyframes } from "styled-components";

import { colors, unitizedPx } from "@badi/badi-components";

const cursorStyles = css`
  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      cursor: pointer;
    }
    `}
`;

const blink = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: .3;
  }
`;

export const ActionBar = styled.div`
  padding: 0 ${unitizedPx(2)};
  line-height: ${unitizedPx(6)};
  min-height: ${unitizedPx(6)};
  max-height: ${unitizedPx(6)};
  background-color: ${colors.lightGrey};
`;

export const ImageInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

export const SendButton = styled.button`
  border: none;
  background: none;
  text-transform: uppercase;

  ${cursorStyles}
`;

export const TextArea = styled.textarea`
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
  min-height: calc(70px + 32px);
  max-height: 480px;
  border: none;
  resize: vertical;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export const TextAreaWrapper = styled.form`
  box-sizing: border-box;
  border: 1px solid ${colors.softGrey};
`;

export const UploadImage = styled.label`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isBusy &&
    css`
      animation: ${blink} 1s linear infinite;
    `}

  ${cursorStyles}
`;

export const Root = styled.div`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
