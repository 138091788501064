import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
} from "react-share";
import { Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getNameSelector } from "datamodel/User/selectors";
import { getIsDesktop } from "datamodel/Device/selectors";
import { LinkWrapper } from "../../styled-components";
import SharingOption from "./sharing-option";
import MessengerShareButton from "./messenger-share-button";

const SharingOptions = ({ url }) => {
  const firstName = useSelector(getNameSelector);
  const isDesktop = useSelector(getIsDesktop);
  const disabled = !url;

  const handleFacebookClick = () => {};
  const handleTwitterClick = () => {};
  const handleEmailClick = () => {};
  const handleWhatsAppClick = () => {};
  const handleMessengerClick = () => {};

  return (
    <Flex direction="row" gutter={6} justify="center">
      <LinkWrapper disabled={disabled}>
        <Link
          onClick={handleWhatsAppClick}
          target="_blank"
          to={`${
            isDesktop ? "https://web.whatsapp.com/" : "whatsapp://"
          }send?text=${encodeURIComponent(
            translate("referrals.sharing.whatsapp.body"),
          )}%0a${encodeURIComponent(url)}`}
        >
          <SharingOption
            dataQa="whatsapp-sharing-option"
            disabled={disabled}
            icon="icons/new-whatsapp-blue.svg"
          />
        </Link>
      </LinkWrapper>
      <MessengerShareButton
        disabled={disabled}
        isDesktop={isDesktop}
        onClick={handleMessengerClick}
        url={url}
      >
        <SharingOption
          dataQa="messenger-sharing-option"
          disabled={disabled}
          icon="icons/new-messenger-blue.svg"
        />
      </MessengerShareButton>
      {isDesktop && (
        <FacebookShareButton
          beforeOnClick={handleFacebookClick}
          disabled={disabled}
          quote={translate("referrals.sharing.facebook.body")}
          url={url}
        >
          <SharingOption
            dataQa="facebook-sharing-option"
            disabled={disabled}
            icon="icons/new-fb-blue.svg"
          />
        </FacebookShareButton>
      )}
      <TwitterShareButton
        beforeOnClick={handleTwitterClick}
        disabled={disabled}
        title={translate("referrals.sharing.twitter.body")}
        url={url}
      >
        <SharingOption
          dataQa="twitter-sharing-option"
          disabled={disabled}
          icon="icons/new-twitter-blue.svg"
        />
      </TwitterShareButton>
      <EmailShareButton
        beforeOnClick={handleEmailClick}
        body={translate("referrals.sharing.email.body")}
        disabled={disabled}
        separator=" - "
        subject={translate("referrals.sharing.email.subject", {
          name: firstName,
        })}
        url={url}
      >
        <SharingOption
          dataQa="email-sharing-option"
          disabled={disabled}
          icon="icons/new-email-blue.svg"
        />
      </EmailShareButton>
    </Flex>
  );
};

export default SharingOptions;
