import React from "react";
import { translate } from "base/shared/Localization";
import { useSelector } from "react-redux";
import { getIsIOS } from "app/datamodel/Device/selectors";
import { getHomePath, getAboutBadiPath } from "Sections/Home/routes";
import { getInboxPath } from "Sections/inbox/routes";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import { getDownloadAppLink } from "base/shared/app-links";
import Menu from "components/Layout/TopBar/components/mobile-menu";

const PublicMenu = ({ onLogin, onRegister, isLoggedIn }) => {
  const isIOS = useSelector(getIsIOS);
  const getMenuElements = () => {
    return [
      {
        id: 1,
        to: getHomePath(),
        title: translate("topbar.element.home.title"),
        dataQa: "topbar-public-menu-home",
      },
      { id: 2 },
      {
        id: 3,
        to: getDownloadAppLink({ ios: isIOS }),
        title: translate("topbar.element.download.title"),
        dataQa: "topbar-public-menu-download",
      },
      {
        id: 4,
        to: getRentRoomPath(),
        title: translate("topbar.element.listproperty.title"),
        description: translate("topbar.element.listproperty.subtitle"),
        dataQa: "topbar-public-list-room-button",
      },
      {
        id: 5,
        to: getAboutBadiPath(),
        title: translate("topbar.element.info"),
        dataQa: "topbar-public-menu-aboutbadi-button",
      },
    ];
  };

  let menuElements = getMenuElements();

  const loggedOutElements = [
    { id: 6 },
    {
      id: 7,
      onClick: onRegister,
      title: translate("topbar.element.signup.title"),
      dataQa: "topbar-public-menu-register",
    },
    {
      id: 8,
      onClick: onLogin,
      title: translate("topbar.element.signin.title"),
      dataQa: "topbar-public-menu-login",
    },
  ];

  const loggedInElements = [
    {
      id: 8,
      to: getInboxPath(),
      title: translate("topbar.element.inbox"),
      dataQa: "topbar-public-menu-register",
    },
  ];

  if (isLoggedIn) {
    menuElements = menuElements.concat(loggedInElements);
  } else {
    menuElements = menuElements.concat(loggedOutElements);
  }

  return <Menu dataQa="topbar-menu" elements={menuElements} />;
};

export default PublicMenu;
