import React from "react";
import { Flex, Text, colors, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";

const RequiredVisit = () => (
  <Flex alignItems="flexStart">
    <Image src="/assets/icons/green-tic.svg" style={{ display: "block" }} />
    <Spacer left={0.5}>
      <Text body="12-Medium" color={colors.green}>
        {translate("lister.contact.request_hint_visit")}
      </Text>
    </Spacer>
  </Flex>
);

export default RequiredVisit;
