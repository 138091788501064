import dayjs from "dayjs";

class VisitPreferences {
  constructor(data) {
    this.entity = data?.visit_preferences || {};
  }

  get enabled() {
    return Boolean(this.entity.visits_enabled);
  }

  get startDate() {
    return this.entity.visits_start_at || dayjs().format("YYYY-MM-DD");
  }

  get availableDays() {
    return [
      { day: "monday", enabled: !!this.entity.monday_enabled },
      { day: "tuesday", enabled: !!this.entity.tuesday_enabled },
      { day: "wednesday", enabled: !!this.entity.wednesday_enabled },
      { day: "thursday", enabled: !!this.entity.thursday_enabled },
      { day: "friday", enabled: !!this.entity.friday_enabled },
      { day: "saturday", enabled: !!this.entity.saturday_enabled },
      { day: "sunday", enabled: !!this.entity.sunday_enabled },
    ];
  }

  get availableTimes() {
    return [
      {
        schedule: "mornings",
        enabled: !!this.entity.mornings_enabled,
      },
      {
        schedule: "afternoons",
        enabled: !!this.entity.afternoons_enabled,
      },
      {
        schedule: "evenings",
        enabled: !!this.entity.nights_enabled,
      },
    ];
  }

  toJSON() {
    return {
      enabled: this.enabled,
      startDate: this.startDate,
      availableDays: this.availableDays,
      availableTimes: this.availableTimes,
    };
  }
}

export default VisitPreferences;
