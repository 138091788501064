import React from "react";

import Arrow from "components/Icons/Arrow";

import "./FaqsExt";

const SecurityFaqs = ({ buttonLink, buttonText, items, title }) => (
  <div className="SecurityFaqs">
    <h3>{title}</h3>
    <ul>
      {items.map(({ id, link, text }) => (
        <li className="SecurityFaqs__Option" key={id}>
          <Arrow
            className="SecurityFaqs__Arrow"
            direction="right"
            color="#999999"
          />
          <a href={link}>{text}</a>
        </li>
      ))}
    </ul>
    <a className="SecurityFaqs__MainLink" href={buttonLink}>
      {buttonText}
    </a>
  </div>
);

export default SecurityFaqs;
