import React from "react";
import classNames from "classnames";

const NiceModal = ({
  children,
  className,
  closable,
  headContent,
  mainClassName,
  isClosing,
  isOpen,
  outsideClickHandler,
  closeHandler,
}) => (
  <div
    className={classNames(
      "popup",
      className,
      isOpen ? "popup_active" : "",
      isClosing ? "popup_closed" : "",
    )}
  >
    <div className="popup__bg" onClick={outsideClickHandler} />
    <div className={classNames("popup__main", mainClassName)}>
      <div className="popup__head">
        {closable && <div className="popup__close" onClick={closeHandler} />}
        {headContent}
      </div>
      <div className="popup__body">
        <div className="popup__content">{children}</div>
      </div>
    </div>
  </div>
);

NiceModal.defaultProps = {
  children: null,
  className: null,
  closable: true,
  closeOnOutsideClick: true,
  forceClose: false,
  headContent: null,
  isClosing: false,
  mainClassName: "",
  closeHandler: () => {},
  outsideClickHandler: () => {},
};

export default NiceModal;
