import createReducer from "base/shared/createReducer";

import Types from "../types";
import { PayoutsModel } from "../models";
import { PAYOUT_STATUS } from "../static";
import { parsePayoutFromApi, parsePayoutMethodFromApi } from "../utils";

function accountRequest(state) {
  return { ...state, status: PAYOUT_STATUS.FETCHING };
}

function accountError(state, action) {
  const error = { ...action.error };
  return { ...state, status: PAYOUT_STATUS.ERROR, error };
}

function accountSuccess(state, action) {
  const payout = parsePayoutFromApi(action.result);

  return {
    ...state,
    ...payout,
    status: PAYOUT_STATUS.FETCHED,
    error: null,
  };
}

function countryRequest(state) {
  return { ...state, status: PAYOUT_STATUS.COUNTRY_UPDATING };
}

function countryError(state, action) {
  return {
    ...state,
    status: PAYOUT_STATUS.ERROR,
    bankAccountError: action.error,
  };
}

function countrySuccess(state, action) {
  const payout = parsePayoutFromApi(action.result);

  return {
    ...state,
    ...payout,
    status: PAYOUT_STATUS.FETCHED,
    bankAccountError: null,
  };
}

function methodRequest(state) {
  return {
    ...state,
    status: PAYOUT_STATUS.BANK_ACCOUNT_UPDATING,
  };
}

function methodError(state, action) {
  return {
    ...state,
    status: PAYOUT_STATUS.ERROR,
    bankAccountError: action.error,
  };
}

function methodSuccess(state, action) {
  const method = parsePayoutMethodFromApi(action.result);

  return {
    ...state,
    ...method,
    status: PAYOUT_STATUS.FETCHED,
    bankAccountError: null,
  };
}

function contactRequest(state) {
  return {
    ...state,
    status: PAYOUT_STATUS.CONTACT_INFO_UPDATING,
  };
}

function contactError(state, action) {
  return {
    ...state,
    status: PAYOUT_STATUS.ERROR,
    contactError: action.error,
  };
}

function contactSuccess(state, action) {
  const payout = parsePayoutFromApi(action.result);

  return {
    ...state,
    ...payout,
    status: PAYOUT_STATUS.FETCHED,
    contactError: null,
  };
}

function reset() {
  return { ...PayoutsModel };
}

const actionHandlers = {
  [Types.PAY_ACCOUNT_REQUEST]: accountRequest,
  [Types.PAY_ACCOUNT_SUCCESS]: accountSuccess,
  [Types.PAY_ACCOUNT_ERROR]: accountError,
  [Types.PAY_METHOD_SAVING_REQUEST]: methodRequest,
  [Types.PAY_METHOD_SAVING_SUCCESS]: methodSuccess,
  [Types.PAY_METHOD_SAVING_ERROR]: methodError,
  [Types.PAY_COUNTRY_SAVING_REQUEST]: countryRequest,
  [Types.PAY_COUNTRY_SAVING_SUCCESS]: countrySuccess,
  [Types.PAY_COUNTRY_SAVING_ERROR]: countryError,
  [Types.PAY_CONTACT_SAVING_REQUEST]: contactRequest,
  [Types.PAY_CONTACT_SAVING_SUCCESS]: contactSuccess,
  [Types.PAY_CONTACT_SAVING_ERROR]: contactError,
  [Types.RESET_PAYOUTS]: reset,
};

export default createReducer(actionHandlers, { ...PayoutsModel });
