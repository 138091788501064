import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const AmenitiesListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: ${({ type }) => (type === "abstract" ? unitizedPx(2) : 0)};
  ${mediaquery.md`
    flex-wrap: no-wrap;
   `};
`;

export const Amenity = styled.div`
  flex-basis: 50%;
  align-items: center;
  height: ${unitizedPx(10)};
  display: flex;
  min-width: ${unitizedPx(27)};

  ${mediaquery.md`  
    flex-basis: 33%;
    min-width: inherit;
   `}
`;

export const IconComponentWrapper = styled.div`
  height: ${unitizedPx(6)};
  width: ${unitizedPx(6)};
`;
