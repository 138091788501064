import { createSelector } from "reselect";

export const getCountryVat = (state) => state.CountryVat || {};

export const getCountryVatCountry = createSelector(
  getCountryVat,
  (countryVat) => countryVat.country,
);

export const getCountryVatCountryCode = createSelector(
  getCountryVat,
  (countryVat) => countryVat.countryCode,
);

export const getCountryVatSpainOverseas = createSelector(
  getCountryVat,
  (countryVat) => countryVat.spainOverseas,
);

export const getCountryVatFetching = createSelector(
  getCountryVat,
  (countryVat) => countryVat.fetching,
);
