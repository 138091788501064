import React from "react";

import { Button, Flex, Heading, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";
import {
  Anchor,
  ContentWrapper,
  ImageWrapper,
  Align,
} from "./styled-components";

const TermsAndConditions = ({ onAccept, onReject }) => (
  <Flex direction="column" fullHeight={true}>
    <ContentWrapper grow={1}>
      <Flex
        alignItems="center"
        direction="column"
        fullHeight={true}
        justify="center"
      >
        <Spacer bottom={4} md={{ top: 2 }}>
          <ImageWrapper>
            <Image
              alt={translate("terms_and_conditions.image.alt")}
              lazy={false}
              src="/assets/images/terms-conditions.svg"
            />
          </ImageWrapper>
        </Spacer>
        <Spacer bottom={2} md={{ left: 6, right: 6 }}>
          <Heading data-qa="t-and-c-title" level={3}>
            <Align direction="center">
              {translate("terms_and_conditions.title")}
            </Align>
          </Heading>
        </Spacer>
        <Spacer md={{ left: 6, right: 6, bottom: 9 }}>
          <Text align="center" data-qa="t-and-c-text">
            {translate("terms_and_conditions.text")}
          </Text>
          <Anchor
            href={translate("sitemap.tc.link")}
            rel="noopener noreferrer"
            target="_blank"
          >
            {translate("terms_and_conditions.link.text")}
          </Anchor>
        </Spacer>
      </Flex>
    </ContentWrapper>
    <Flex gutter={0.5}>
      <Button
        data-qa="t-and-c-reject-button"
        fullWidth={true}
        grow={1}
        onClick={onReject}
        variant="secondary"
      >
        {translate("terms_and_conditions.actions.reject")}
      </Button>
      <Button
        data-qa="t-and-c-accept-button"
        fullWidth={true}
        grow={1}
        onClick={onAccept}
      >
        {translate("terms_and_conditions.actions.accept")}
      </Button>
    </Flex>
  </Flex>
);

export default TermsAndConditions;
