import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  BUTTON_TYPES,
  Text,
  Spacer,
  Card,
  Flex,
  useMatchMedia,
  BADI_COLORS,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { PremiumButton, usePremium, PremiumLabel } from "modules/premium";
import { getListerMetricsPath } from "Sections/analytics/rooms/routes";
import { BOOSTING_STATUS } from "Sections/overview/constants";
import Action from "../../layout-elements/action";
import {
  ShrinkingHeading,
  RoomCardImage,
  RoomCardDetails,
} from "../../styled-components";
import { PremiumWrapper } from "./styled-components";
import { getRoomNextStepPath } from "../../utils";
import { QUERIES } from "../../constants";

const RoomCard = ({
  roomId,
  coverPicture,
  title,
  address,
  priceDescription,
  actionItems,
  analyticsActionItem = null,
  boosting = null,
  premium = false,
  isStandaloneBoostingSelector,
  onBoostRoom,
  onPreviewRoom,
}) => {
  const [isDesktop] = useMatchMedia(QUERIES);
  const { premiumEnabled } = usePremium();

  const visiblePremiumButton = premiumEnabled && !premium;
  const visibleBoosting =
    boosting &&
    !visiblePremiumButton &&
    (premiumEnabled || isStandaloneBoostingSelector);
  const footerHasContent = visiblePremiumButton || visibleBoosting;

  const AnalyticsAction = () => {
    const { actionId, clickable, heading, value, subheading } =
      analyticsActionItem;

    return (
      <Action
        actionId={actionId}
        clickable={clickable}
        heading={heading}
        nextStepPath={getListerMetricsPath({ roomId })}
        roomId={roomId}
        subheading={subheading}
        value={value}
      />
    );
  };

  return (
    <Spacer bottom={1} top={3}>
      <Card
        backgroundColor={BADI_COLORS.NEUTRAL.NEUTRAL_00}
        dataQa={`overview-room-card-${roomId}`}
      >
        <Spacer bottom={2} left={2} right={2} top={2}>
          <Flex>
            <RoomCardImage data-qa="overview-room-image">
              <img alt="room" src={coverPicture} />
              {premiumEnabled && premium && (
                <PremiumWrapper>
                  <PremiumLabel />
                </PremiumWrapper>
              )}
            </RoomCardImage>
            <Spacer left={2} right={2}>
              <Flex as={RoomCardDetails} direction="column">
                <ShrinkingHeading
                  data-qa="overview-room-title"
                  level={4}
                  onClick={onPreviewRoom}
                >
                  {title}
                </ShrinkingHeading>
                <Spacer bottom={1} top={1}>
                  <Text body={3}>{address}</Text>
                </Spacer>
                <Text body={2}>{priceDescription}</Text>
              </Flex>
            </Spacer>
          </Flex>
          <div>
            {actionItems.map((action) => {
              const {
                heading,
                actionId,
                nextStepId,
                value,
                clickable,
                subheading,
              } = action;
              return (
                <Action
                  actionId={actionId}
                  clickable={clickable}
                  dataQa={`${actionId}-${roomId}`}
                  heading={heading}
                  key={`${roomId}-${action.actionId}`}
                  nextStepPath={getRoomNextStepPath(roomId, nextStepId)}
                  roomId={roomId}
                  subheading={subheading}
                  value={value}
                />
              );
            })}
            {analyticsActionItem && <AnalyticsAction />}
          </div>
          <Spacer top={footerHasContent ? 2 : 0}>
            <Flex justify="flexEnd">
              {visibleBoosting && (
                <Button
                  data-qa="boosting-button"
                  disabled={boosting.status === BOOSTING_STATUS.disabled}
                  fullWidth={true}
                  onClick={onBoostRoom}
                  variant={BUTTON_TYPES.PREMIUM_SECONDARY}
                  wide={!isDesktop}
                >
                  {translate(boosting.heading)}
                </Button>
              )}
              {visiblePremiumButton && (
                <PremiumButton
                  fullWidth={true}
                  roomId={roomId}
                  wide={!isDesktop}
                />
              )}
            </Flex>
          </Spacer>
        </Spacer>
      </Card>
    </Spacer>
  );
};

export default withRouter(RoomCard);
