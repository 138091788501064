import React, { Component } from "react";
import classNames from "classnames";

export class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.getProgressColor = this.getProgressColor.bind(this);
  }

  getProgressColor() {
    const { colors, percentage } = this.props;
    if (colors) {
      if (percentage < 50) return "progress__scale_low";
      if (percentage < 100) return "progress__scale_fit";
    }

    return "";
  }

  render() {
    const { percentage } = this.props;
    const progressBarContentStyle = {
      width: `${percentage}%`,
    };

    return (
      <div className="info__progress">
        <div className="progress">
          <div
            className={classNames("progress__scale", this.getProgressColor())}
          >
            <div className="progress__line" style={progressBarContentStyle} />
          </div>
          <div className="progress__percent">{`${percentage}%`}</div>
        </div>
      </div>
    );
  }
}

ProgressBar.defaultProps = {
  colors: false,
};

export default ProgressBar;
