import React, { useCallback, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import { colors, Spacer, PaginationToken } from "@badi/badi-components";

import LayoutWrapper from "components/Layout/Wrapper";
import TopBar from "components/Layout/TopBar";
import Footer from "components/Layout/Footer";
import Spinner from "components/Icons/LoadingSpinner";
import { openRoomInviteModal } from "components/room-invite";
import { PremiumLabel, usePremium } from "modules/premium";
import { PLAN_FEATURES } from "datamodel/plans/constants";

import { openViewersModal, shouldShowViewersModal } from "./viewers-tips-modal";
import { openViewerUserModal } from "./viewer-user-modal";
import ViewersBar from "./layout-elements/viewer-bar";
import Viewer from "./layout-elements/viewer";
import ViewersHeader from "./layout-elements/viewers-header";

import EmptyList from "./layout-elements/empty-list";

import { checkUnsetViewers } from "./utils";
import { SpinnerWrapper } from "./styled-components";

const PLAN_FEATURE_ID = PLAN_FEATURES.ROOM_VIEWERS;

const Viewers = ({
  fetched,
  fetching,
  getViewers,
  getRoom,
  viewers,
  redirectToPremium = () => {},
  contactedViewer,
  resetViewers,
  room = {},
  roomId,
  redirectToAnalytics,
  token,
  pagination,
}) => {
  const { premiumEnabled, isPremium, hasCapability } = usePremium({
    roomId,
    capabilityId: PLAN_FEATURE_ID,
  });

  useEffect(() => {
    getRoom(roomId);
    getViewers(roomId);

    if (shouldShowViewersModal()) {
      openViewersModal();
    }

    return () => resetViewers();
  }, []);

  const handleInviteSent = useCallback(
    ({ userId }) => {
      contactedViewer(userId);
    },
    [contactedViewer],
  );

  const acceptViewer = useCallback(
    (userId) => {
      if (premiumEnabled && isPremium && !hasCapability) {
        redirectToPremium({ feature: PLAN_FEATURE_ID });
        return;
      }

      openRoomInviteModal({
        userId,
        room,
        token,
        onInviteSent: handleInviteSent,
        isRecommendation: false,
      });
    },
    [handleInviteSent, openRoomInviteModal, room, redirectToPremium],
  );

  const showUserModal = useCallback(
    (userId, contacted) => () => {
      openViewerUserModal({
        userId,
        contacted,
        token,
        onAccept: acceptViewer,
      });
    },
    [acceptViewer],
  );

  const handlePagination = (paginationToken) =>
    getViewers(roomId, paginationToken);

  return (
    <LayoutWrapper
      footer={<Footer campaign="viewers_footer" />}
      preContent={<ViewersBar roomId={roomId} roomTitle={room.title} />}
      topBar={<TopBar />}
    >
      <Container>
        <ViewersHeader onHelpClick={openViewersModal}>
          {premiumEnabled && isPremium && <PremiumLabel bottom={1} />}
        </ViewersHeader>
        <Row>
          <Col
            lg={{ offset: 1, size: 10 }}
            xs={{ size: 12, offset: 0, order: 1 }}
          >
            {fetching && (
              <SpinnerWrapper>
                <Spinner color={colors.green} />
              </SpinnerWrapper>
            )}
            {fetched &&
              (viewers.length && checkUnsetViewers(viewers) ? (
                viewers.map(({ contacted, ...viewerUser }) => (
                  <Viewer
                    acceptViewer={acceptViewer}
                    data-qa="viewer"
                    isContacted={contacted}
                    key={viewerUser.id}
                    showUserModal={showUserModal(viewerUser.id, contacted)}
                    user={viewerUser}
                  />
                ))
              ) : (
                <EmptyList redirectToAnalytics={redirectToAnalytics} />
              ))}
            <Spacer bottom={6} />
          </Col>
        </Row>
      </Container>
      <Spacer bottom={2}>
        <PaginationToken
          nextToken={pagination.nextToken}
          onClick={handlePagination}
          previousToken={pagination.previousToken}
        />
      </Spacer>
    </LayoutWrapper>
  );
};

export default Viewers;
