import dayjs from "dayjs";

const LIMIT_SHORT_STAY = 4;

export const isLongStaySelected = ({ moveIn, moveOut }) => {
  if (moveIn && moveOut) {
    const dateMoveIn = dayjs(moveIn);
    const dateMoveOut = dayjs(moveOut);

    const diff = dateMoveOut.diff(dateMoveIn, "months");
    return diff > LIMIT_SHORT_STAY;
  }
  return false;
};
