import { colors } from "@badi/badi-components";
import { BOOKING_STATUS } from "datamodel/booking/constants";
import { SEEKER_USER_ROLE } from "datamodel/User/constants";

export const getBadgeColor = (status) => {
  switch (status) {
    case BOOKING_STATUS.PENDING:
      return colors.orange;
    case BOOKING_STATUS.ACCEPTED:
      return colors.green;
    case BOOKING_STATUS.REJECTED:
      return colors.midGrey;
    case BOOKING_STATUS.EXPIRED:
      return colors.midGrey;
    case BOOKING_STATUS.PAYMENT_REQUIRED:
      return colors.red;
    case BOOKING_STATUS.PAYMENT_REQUIRED_CHECKOUT:
      return colors.orange;
    default:
      return colors.midGrey;
  }
};

export const getBadgeText = (status, userRole) => {
  switch (status) {
    case BOOKING_STATUS.PENDING:
      return "booking_status.pending";
    case BOOKING_STATUS.ACCEPTED:
      return "booking_status.accepted";
    case BOOKING_STATUS.REJECTED:
      return "booking_status.rejected";
    case BOOKING_STATUS.EXPIRED:
      return "booking_status.expired";
    case BOOKING_STATUS.PAYMENT_REQUIRED:
      return userRole === SEEKER_USER_ROLE
        ? "recovery.payment_label_failed"
        : "recovery.payment_label_waiting";
    case BOOKING_STATUS.PAYMENT_REQUIRED_CHECKOUT:
      return "recovery.payment_checkout_label";
    default:
      return "booking_status.not_possible";
  }
};
