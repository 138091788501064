import React, { useState } from "react";
import { Heading, StatusCard, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import ContactInformationEdit from "./edit/contact-information-edit";
import ContactInformationFilled from "./info/contact-information-filled";
import ContactInformationEmpty from "./info/contact-information-empty";

const ContactInformation = (props) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleChangeState = () => {
    setIsEditing((isEditingState) => !isEditingState);
  };

  const hasSavedInfo = !!props.name;

  return (
    <StatusCard isEditable={props.enabled} isVerified={hasSavedInfo}>
      <Spacer bottom={3}>
        <Heading data-qa="contact-information-title-field" level={3}>
          {translate("payout.setup.account_holder.title")}
        </Heading>
      </Spacer>
      {isEditing && (
        <ContactInformationEdit {...props} closeEditing={handleChangeState} />
      )}
      {!isEditing &&
        (hasSavedInfo ? (
          <ContactInformationFilled
            onUpdateClick={handleChangeState}
            {...props}
          />
        ) : (
          <ContactInformationEmpty handleAddInfo={handleChangeState} />
        ))}
    </StatusCard>
  );
};

export default ContactInformation;
