import React, { Component } from "react";

class Step extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.component !== nextProps.component &&
      typeof window !== "undefined"
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: ChildComponent, ...rest } = this.props;
    return <ChildComponent {...rest} />;
  }
}

Step.defaultProps = {
  nextStep: undefined,
  previousStep: undefined,
};

export default Step;
