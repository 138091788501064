import React from "react";

import { getRelativePath } from "base/shared/Utils";

import NewReview from "./new-review-mapping";

const NewReviewRoute = ({ params, location, router }) => {
  const { id: reviewId } = params;
  const { query = {} } = location;
  const { sgid } = query;

  const handleNotAuthorized = () => {
    router.push(getRelativePath("/404"));
  };

  const handleLeaveFlow = () => {
    router.push(getRelativePath("/my-profile"));
  };

  return (
    <NewReview
      onLeaveFlow={handleLeaveFlow}
      onNotAuthorized={handleNotAuthorized}
      reviewId={Number(reviewId)}
      router={router}
      sgid={sgid}
    />
  );
};

export default NewReviewRoute;
