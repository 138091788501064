import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";

export const Separator = styled.div`
  height: 0px;
  width: 100%;
  border-bottom: 2px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_00};

  ${mediaquery.lg`
    height: ${unitizedPx(15)};
    width: 0px;
    border-bottom: none;
    border-right: 2px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  `};
`;

export const Wrapper = styled.div`
  background-image: ${({ visibleBackground }) =>
    visibleBackground
      ? `url('${getPublicAsset("images/home/community-responsive.jpg")}')`
      : "none"};
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: ${unitizedPx(4)} 0px;

  ${mediaquery.lg`
    padding: ${unitizedPx(20)} 0px;
    background-image: ${({ visibleBackground }) =>
      visibleBackground
        ? `url('${getPublicAsset("images/home/community.png")}')`
        : "none"};
  `};
`;
