import styled from "styled-components";
import { Link } from "react-router";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const MenuList = styled.ul`
  list-style-type: none;
  position: absolute;
  top: ${unitizedPx(4)};
  right: 0;
  left: auto;
  z-index: 2;

  background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  padding: ${unitizedPx(2)};
  text-align: left;
  white-space: nowrap;

  visibility: hidden;
  opacity: 0;

  transition: 0.125s ease-out;
`;

export const MenuListItem = styled.li`
  display: flex;
  align-items: center;

  padding: 12px 16px 12px 0;
`;

export const MenuLink = styled(Link)`
  :hover {
    text-decoration: none;
    outline: none;
  }
`;

export const Root = styled.nav`
  position: relative;
  box-sizing: border-box;
`;

export const Avatar = styled(Link)`
  display: block;
  width: ${unitizedPx(5.25)};
  height: ${unitizedPx(5.25)};

  border: 2px solid #fff;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url(${({ picture }) => picture});

  border-color: ${({ lister }) =>
    lister ? BADI_COLORS.ADDITIONAL.ADDITIONAL_06 : "transparent"};
`;
