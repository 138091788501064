import React, { useState } from "react";
import { Button, Flex, Heading, Spacer, Text } from "@badi/badi-components";

import Input from "components/form-text-input";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { reset } from "datamodel/Auth/api";
import { useForm, useFormValidation } from "components/hooks";
import LayoutWrapper from "components/Layout/Wrapper";
import Timer from "components/timer";
import { FormWrapper } from "./styled-components";

const EMAIL_FIELDNAME = "email";
const EMAIL_VALIDATIONS = ["email"];

const Reset = () => {
  const [available, setAvailable] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [values, setInputValue] = useForm({
    [EMAIL_FIELDNAME]: "",
  });
  const [isFormValid, setFieldValidation] = useFormValidation({
    [EMAIL_FIELDNAME]: false,
  });

  const handleEndTimer = () => {
    setAvailable(true);
  };

  const handleChange = ({ name: inputName, value }) => {
    setInputValue(inputName, value);
  };

  const handleValidation = ({ name: inputName, isValid }) => {
    setFieldValidation(inputName, !!isValid);
  };

  const handleSubmit = () => {
    setFetching(true);
    reset({ email: values[EMAIL_FIELDNAME] })
      .then(() => {
        setFetching(false);
        setAvailable(false);
        UserFeedback.success(
          translate("Done!"),
          translate("Your new password has been sent to you via email"),
        );
      })
      .catch((e) => {
        setFetching(false);
        UserFeedback.exception(
          translate("Something went wrong"),
          translate(e.message),
          e,
        );
      });
  };

  return (
    <LayoutWrapper>
      <FormWrapper>
        <Flex alignItems="center" direction="column" gutter={3}>
          <Heading
            align="center"
            data-qa="reset-password-title"
            level="24-Bold"
          >
            {translate("I forgot my password")}
          </Heading>
          <Text align="center" dataQa="reset-password-desc">
            {translate(
              "No problem! We'll send you an email so you can quickly change your password.",
            )}
          </Text>
          <Input
            dataQa="reset-password-email"
            initialValue=""
            name={EMAIL_FIELDNAME}
            newType={true}
            onChange={handleChange}
            onInputValidation={handleValidation}
            placeholder={translate("What’s your email?")}
            required={true}
            validations={EMAIL_VALIDATIONS}
            wide={true}
          />
          <Spacer top={4}>
            <Flex
              alignItems="center"
              direction="column"
              gutter={1}
              md={{ direction: "row", alignItems: "center", gutter: 1 }}
            >
              <Button
                dataQa="reset-password-button"
                disabled={!available || fetching || !isFormValid}
                onClick={handleSubmit}
              >
                {translate("Reset my password")}
              </Button>
              {!available && (
                <Timer
                  dataQa="available-timer"
                  onEnd={handleEndTimer}
                  reverse={true}
                  start={60}
                />
              )}
            </Flex>
          </Spacer>
        </Flex>
      </FormWrapper>
    </LayoutWrapper>
  );
};

export default Reset;
