import React from "react";
import classNames from "classnames";

import { Col, Container, Row } from "reactstrap";
import Image from "components/Image/Image";
import Title from "../Title/Title";

import "./HeaderExt";

export const LandingHeader = React.forwardRef(
  ({ children, imageClassName, imageUrl, title }, ref) => (
    <div className="LandingHeader" ref={ref}>
      <Image
        className={classNames("LandingHeader__Image", imageClassName)}
        lazy={false}
        src={imageUrl}
        tag="div"
      />
      <div className="LandingHeader__ContentWrapper">
        <Container>
          <Row>
            <Col className="LandingHeader__Content" lg={6} md={11}>
              <Title text={title} />
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  ),
);

LandingHeader.displayName = "LandingHeader";

LandingHeader.defaultProps = {
  children: null,
  imageClassName: null,
};

export default LandingHeader;
