import React from "react";
import { Link } from "react-router";

import BadiLogo from "components/Icons/BadiLogo";

import { getRelativePath } from "base/shared/Utils";
import { getOverviewPath } from "Sections/overview/routes";

import { LogoWrapper } from "./styled-components";

const Logo = ({ lister, hideOn, dataQa, color, ...rest }) => {
  const homeLinkPath = lister ? getOverviewPath() : "/";
  return (
    <LogoWrapper data-qa={dataQa} hideOn={hideOn}>
      <Link to={getRelativePath(homeLinkPath)} {...rest}>
        <BadiLogo color={color} />
      </Link>
    </LogoWrapper>
  );
};

Logo.defaultProps = {
  color: null,
  dataQa: "logo-badi",
  hideOn: null,
  lister: false,
};
export default Logo;
