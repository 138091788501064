import { useEffect, useState } from "react";
import UserFeedback from "base/shared/user-feedback";
import { removeAllCookies } from "base/shared/CookiesHandler";
import { getAuthStatus } from "./utils";
import { AUTH_STATUS } from "./constants";

const AuthTokenManager = ({ children = null, error = null, refreshToken }) => {
  const [refreshingToken, setRefreshingToken] = useState(false);

  useEffect(() => {
    const status = getAuthStatus(error);

    switch (status) {
      case AUTH_STATUS.INVALID:
        if (refreshingToken) {
          return;
        }
        setRefreshingToken(true);
        refreshToken();
        break;
      case AUTH_STATUS.ERROR:
        setRefreshingToken(false);
        UserFeedback.exception("Something went wrong", error.message, error);
        break;
      case AUTH_STATUS.BANNED:
        removeAllCookies();
        window.location.reload();
        break;
      case AUTH_STATUS.OK:
        setRefreshingToken(false);
        break;
      default:
    }
  }, [error]);

  return children;
};

export default AuthTokenManager;
