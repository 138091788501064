import React from "react";
import Chat from "./chat-mapping";

const ChatRoute = ({ params }) => {
  const { id } = params;

  return <Chat connectionId={Number(id)} />;
};

export default ChatRoute;
