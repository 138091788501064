import React from "react";
import { Badge } from "@badi/badi-components";

import { BOOKING_STATUS } from "datamodel/booking/constants";
import { translate } from "base/shared/Localization";
import { getBadgeColor, getBadgeText } from "./utils";

const BookingStatusBadge = ({
  status = BOOKING_STATUS.NOT_POSSIBLE,
  userRole = "",
}) => {
  const backgroundColor = getBadgeColor(status);
  const text = getBadgeText(status, userRole);

  return (
    <Badge backgroundColor={backgroundColor} rounded={true}>
      {translate(text)}
    </Badge>
  );
};

export default BookingStatusBadge;
