import React, { useEffect, useState } from "react";
import {
  BADI_COLORS,
  BREAKPOINTS_PX,
  Flex,
  Spacer,
  useMatchMedia,
} from "@badi/badi-components";
import { debounce } from "radash";

import Input from "components/form-text-input";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";

import LayoutWrapper from "components/Layout/Wrapper";
import Spinner from "components/Icons/LoadingSpinner";
import PremiumBanner from "Sections/plans/banner";
import ContentWrapper from "./components/content-wrapper";
import MyListingsCard from "./components/my-listings-card";
import Header from "./components/my-listings-header";
import EmptyState from "./components/empty-state";
import ListingsListActions from "./components/listings-list-actions";
import GoTopButton from "./components/go-top-button";
import { HeaderWrapper } from "./styled-components";

import { MY_LISTINGS_SETTINGS } from "./utils";

export const LARGE_SCREEN_QUERIES = [`(min-width: ${BREAKPOINTS_PX.md})`];

const MyListings = ({
  clearSelectedListings,
  currentPage,
  fetchedInitialListingsByStatus,
  fetchingInitialListingsByStatus,
  getMyListings,
  getMyListingsSummary,
  isPremiumEnabled,
  resetListings,
  rooms,
  token,
  totalPages,
}) => {
  const [isLargeScreen] = useMatchMedia(LARGE_SCREEN_QUERIES);
  const [listingIdMoreActionsOpen, setListingIdMoreActionsOpen] = useState(0);
  const [externalAgencyId, setExternalAgencyId] = useState("");
  const [listingObjectMobileActionsOpen, setListingObjectMobileActionsOpen] =
    useState({ listingId: 0, sectionId: 0 });
  const [activeTab, setActiveTab] = useState(0);

  const closeAllActions = () => {
    setListingIdMoreActionsOpen(0);
    setListingObjectMobileActionsOpen({ listingId: 0, sectionId: 0 });
  };

  const setMobileActionsMenu = (listingId) => (sectionId) => {
    if (sectionId) {
      setListingObjectMobileActionsOpen({ listingId, sectionId });
    } else {
      closeAllActions();
    }
  };

  const loadMoreListings = (page) => {
    getMyListings({ statusId: activeTab, page, per: MY_LISTINGS_SETTINGS.per });
  };

  const resetMyListings = (statusId) => {
    getMyListingsSummary();
    getMyListings({ statusId, page: 1, per: MY_LISTINGS_SETTINGS.per });
    clearSelectedListings();
  };

  const initializeByStatus = (tab) => {
    getMyListings({
      statusId: tab,
      page: 1,
      per: MY_LISTINGS_SETTINGS.per,
      externalAgencyId,
    });
    setActiveTab(tab);
    setListingIdMoreActionsOpen(0);
    setListingObjectMobileActionsOpen({ listingId: 0, sectionId: 0 });
  };

  const openMoreActionsMenu =
    (listingId) =>
    (action = "") => {
      if (listingIdMoreActionsOpen !== listingId && action === "more") {
        setListingIdMoreActionsOpen(listingId);
      } else {
        closeAllActions();
      }
    };

  const handleChange = ({ value }) => {
    setExternalAgencyId(value);
    getMyListings({
      statusId: activeTab,
      page: 1,
      per: MY_LISTINGS_SETTINGS.per,
      externalAgencyId: value,
    });
  };

  const debouncedHandleChange = debounce({ delay: 300 }, handleChange);

  useEffect(() => {
    return resetListings;
  }, []);

  return (
    <LayoutWrapper>
      <HeaderWrapper>
        <ContentWrapper>
          <Header
            activeTab={activeTab}
            closeAllActions={closeAllActions}
            fetchingInitialListingsByStatus={fetchingInitialListingsByStatus}
            getMyListingsSummary={getMyListingsSummary}
            onStatusSelect={initializeByStatus}
          />
          <Spacer top={1}>
            <Flex justify="flexEnd">
              <Input
                basis={isLargeScreen ? "40%" : "100%"}
                dataQa="my-listings-search-external-agency-id"
                iconSrc={getPublicAsset("icons/ico_search.svg")}
                maxLength={30}
                name="search-external-id"
                newType={true}
                onChange={debouncedHandleChange}
                placeholder={translate("search.external.agency.id")}
                value={externalAgencyId}
              />
            </Flex>
          </Spacer>
        </ContentWrapper>
      </HeaderWrapper>
      <ContentWrapper>
        <Spacer md={{ top: 38 }} top={42}>
          <Flex alignItems="center" direction="column" justify="center">
            <Spacer bottom={2} wide={true}>
              <ListingsListActions
                currentPage={currentPage}
                onSelectPage={loadMoreListings}
                rooms={rooms}
                totalPages={totalPages}
              />
            </Spacer>

            {fetchingInitialListingsByStatus && (
              <Spinner color={BADI_COLORS.BRAND.PRIMARY.DEFAULT} />
            )}
            {fetchedInitialListingsByStatus &&
              (rooms.length ? (
                rooms.map((room) => (
                  <MyListingsCard
                    closeAllActions={closeAllActions}
                    key={room.id}
                    listingIdMoreActionsOpen={listingIdMoreActionsOpen}
                    listingObjectMobileActionsOpen={
                      listingObjectMobileActionsOpen
                    }
                    openMoreActionsMenu={openMoreActionsMenu}
                    resetMyListings={resetMyListings}
                    room={room}
                    setMobileActionsMenu={setMobileActionsMenu}
                    token={token}
                    wide={true}
                  />
                ))
              ) : (
                <EmptyState activeTab={activeTab} alignself="flexStart" />
              ))}
            {!fetchingInitialListingsByStatus &&
              rooms.length === MY_LISTINGS_SETTINGS.per && (
                <Spacer bottom={3} top={2} wide={true}>
                  <ListingsListActions
                    currentPage={currentPage}
                    onSelectPage={loadMoreListings}
                    rooms={rooms}
                    totalPages={totalPages}
                  >
                    <GoTopButton />
                  </ListingsListActions>
                </Spacer>
              )}
          </Flex>
        </Spacer>
      </ContentWrapper>
    </LayoutWrapper>
  );
};

export default MyListings;
