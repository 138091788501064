import styled from "styled-components";
import {
  Text,
  unitizedPx,
  BADI_COLORS,
  mediaquery,
} from "@badi/badi-components";

export const PriceBoxRoot = styled.div`
  position: relative;
  width: 100%;
  max-width: ${unitizedPx(40)};
  padding: ${unitizedPx(2)} ${unitizedPx(2)} ${unitizedPx(1)} ${unitizedPx(2)};
  border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  border-radius: ${unitizedPx(0.5)};
  margin: auto;
`;

export const PriceLabel = styled(Text)`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: -${unitizedPx(1.5)};
  text-transform: uppercase;
  background-color: ${(props) =>
    props.backgroundColor || BADI_COLORS.BRAND.TERTIARY.DEFAULT};
  border 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  padding: ${unitizedPx(0.5)} ${unitizedPx(1)};
  border-radius: ${unitizedPx(0.25)};
`;

export const OverlayHeader = styled.div`
  padding: ${unitizedPx(6)} ${unitizedPx(2.5)} ${unitizedPx(4)}
    ${unitizedPx(2.5)};
  background-color: ${(props) =>
    props.backgroundColor || BADI_COLORS.BRAND.TERTIARY.DEFAULT};
  ${mediaquery.md`  
    padding: ${unitizedPx(6)} ${unitizedPx(8)} ${unitizedPx(3)} ${unitizedPx(
      8,
    )};
  `}
`;

export const OverlayBody = styled.div`
  padding: ${unitizedPx(4)} ${unitizedPx(2.5)} ${unitizedPx(1)}
    ${unitizedPx(2.5)};
  ${mediaquery.md`  
    padding: ${unitizedPx(4)} ${unitizedPx(5)};
  `}
`;

export const IconWrap = styled.div`
  position: relative;
  img {
    display: block;
    margin-right: ${unitizedPx(1.5)};
  }
`;

export const StyledBadge = styled.div`
  width: ${unitizedPx(2.75)};
  height: ${unitizedPx(2.75)};
`;

export const InfoFeedback = styled.div`
  max-width: ${unitizedPx(42)};
`;
