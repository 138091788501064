import React, { useMemo, useState, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import { Icon } from "@badi/badi-components";
import { HEADER_PARAMS } from "base/shared/tracking-headers";

import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import {
  saveSeekerPreference,
  getSeekerPreference,
} from "datamodel/seeker-preferences/api";

import LayoutWrapper from "components/Layout/Wrapper";
import TopBar from "./components/top-bar";
import Footer from "./components/footer";
import Form from "./form";

const SeekerPreferences = ({
  token,
  setParams,
  onClose,
  onNextStep = null,
  routerEventListener,
  userId,
}) => {
  const [seekerPreferences, setSeekerPreferences] = useState({});
  const [suggestedSearch, setSuggestedSearch] = useState();

  const [isLocationCompleted, setIsLocationCompleted] = useState(false);

  const handleSeekerePreferenceResponse = ({
    suggestedSearch: suggestedSearchApi,
    ...paramsApi
  }) => {
    setSuggestedSearch(suggestedSearchApi);
    setSeekerPreferences(paramsApi);
  };

  const handleSaveSeekerPreference = (params) => {
    saveSeekerPreference(token, { ...seekerPreferences, ...params }).then(
      handleSeekerePreferenceResponse,
    );
  };

  const handleFetchedSeekerPreferences = () => {
    getSeekerPreference(token).then(handleSeekerePreferenceResponse);
  };

  const handleMakeSuggestedSearch = () => {
    if (onNextStep) {
      onNextStep(isLocationCompleted ? suggestedSearch : null);
    } else {
      setParams(suggestedSearch);
    }
  };

  const handleCloseClick = () => {
    onClose();
  };

  useEffect(() => {
    handleFetchedSeekerPreferences();
  }, []);

  useEffect(() => {
    const { SEEKER_PREFERENCES } = HEADER_PARAMS.SEARCH_SOURCES.options;
    const unlisten = routerEventListener(SEEKER_PREFERENCES);
    return () => {
      unlisten();
    };
  }, []);

  const isFormDataLoaded = !!Object.keys(seekerPreferences).length;

  return (
    <LayoutWrapper
      footer={
        <Footer
          handleMakeSuggestedSearch={handleMakeSuggestedSearch}
          isLocationCompleted={isLocationCompleted}
          textButton={translate(
            onNextStep
              ? "onboarding.preferences.seeker_cta"
              : "seeker.preferences.continue",
          )}
        />
      }
      topBar={<TopBar onClose={handleCloseClick} />}
    >
      <Container>
        <Row>
          <Col lg={7} xs={12}>
            <Icon
              iconMaxHeight={64}
              iconMaxWidth={64}
              src={getPublicAsset("icons/seeker-preferences-main.svg")}
            />
            {isFormDataLoaded && (
              <Form
                handleSaveSeekerPreference={handleSaveSeekerPreference}
                seekerPreferences={seekerPreferences}
                setIsLocationCompleted={setIsLocationCompleted}
              />
            )}
          </Col>
        </Row>
      </Container>
    </LayoutWrapper>
  );
};

export default SeekerPreferences;
