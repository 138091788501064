import React from "react";
import { connect } from "react-redux";
import { FILTERS_ID } from "../static";
import { translate } from "base/shared/Localization";
import { getTitleFor } from "./utils";

const xor = (a, b) => Boolean(a ? !b : b);

const MarketplaceSegmentsFilterButton = (props) => {
  const Component = props.component;
  const { selectedSegments } = props;
  const title = getTitleFor(selectedSegments);
  const { badiCommunity, badiRooms } = selectedSegments;
  const isOneSegmentSelected = xor(badiCommunity, badiRooms);

  const componentProps = {
    ...props,
    title,
    id: FILTERS_ID.marketplace_segments,
    value: isOneSegmentSelected ? title : "",
  };
  return <Component {...componentProps} />;
};

export default MarketplaceSegmentsFilterButton;
