export const LOGIN_TEXTS = {
  TITLE: "logIn.title",
  DESCRIPTION: "logIn.dontHaveAnAccount",
  SWITCH_TEXT: "logIn.signUp",
  FACEBOOK_BUTTON_TEXT: "logIn.facebook",
  GOOGLE_BUTTON_TEXT: "logIn.google",
  EMAIL_BUTTON_TEXT: "logIn.email",
};

export const REGISTER_TEXTS = {
  TITLE: "signUp.title",
  DESCRIPTION: "signUp.alreadyHaveAnAccount",
  SWITCH_TEXT: "signUp.logIn",
  FACEBOOK_BUTTON_TEXT: "signUp.facebook",
  GOOGLE_BUTTON_TEXT: "signUp.google",
  EMAIL_BUTTON_TEXT: "signUp.email",
};

export const MODE = { LOGIN: "login", REGISTER: "register" };
