import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="8"
    viewBox="0 0 16 8"
  >
    <g fill="#333" fillRule="evenodd">
      <rect width="16" height="2" rx="1" />
      <rect width="16" height="2" y="6" rx="1" />
    </g>
  </svg>
);
