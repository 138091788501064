import { StepCard } from "@badi/badi-components";
import { isLister } from "datamodel/User/utils";

export const getVisitSteps = (screenRole) => [
  {
    title: `inbox_popup_visit.feedback.${screenRole}.timeline.1`,
    status: StepCard.STATUS.COMPLETED,
    progress: true,
    stepNumber: 1,
  },
  {
    title: `inbox_popup_visit.feedback.${screenRole}.timeline.2`,
    status: StepCard.STATUS.COMPLETED,
    progress: true,
    stepNumber: 2,
  },
  {
    title: `inbox_popup_visit.feedback.${screenRole}.timeline.3`,
    description: isLister(screenRole)
      ? ""
      : `inbox_popup_visit.feedback.${screenRole}.timeline.3.description`,
    status: StepCard.STATUS.IN_PROGRESS,
    stepNumber: 3,
  },
];
