import React, { useEffect } from "react";

import { translate } from "base/shared/Localization";

import GeoSuggest from "components/geo-suggest";
import { isMissingLocation, geocodeByAddressOrPlaceId } from "./utils";
import { GeosuggestWrapper, SearchBarRoot } from "./styled-components";

const SearchBar = ({
  bounds = null,
  coordinates = null,
  dataQa = "search-bar",
  initialValue = null,
  placeId = null,
  setBoundsRecalculation,
  setLocation,
  setParams,
  text = "",
}) => {
  const handleSuggestUpdate = (suggest) => {
    const { label, meta } = suggest;
    const city = meta ? meta.city : "";

    setBoundsRecalculation();
    setLocation({ ...suggest, city, text: label });
  };

  useEffect(() => {
    if (isMissingLocation(bounds, coordinates, text)) {
      geocodeByAddressOrPlaceId({
        address: text,
        placeId,
        setLocation: handleSuggestUpdate,
      });
    }
  });

  useEffect(() => {
    if (isMissingLocation(bounds, coordinates, text)) {
      geocodeByAddressOrPlaceId({
        address: text,
        placeId,
        setLocation: handleSuggestUpdate,
      });
    }
  }, [text]);

  return (
    <SearchBarRoot data-qa={dataQa}>
      <GeosuggestWrapper>
        <GeoSuggest
          includeTypeLabels={true}
          initialValue={initialValue}
          nearby={true}
          onSuggestSelect={handleSuggestUpdate}
          placeholder={translate("topbar.element.search.placeholder")}
          recent={true}
          types={["geocode"]}
        />
      </GeosuggestWrapper>
    </SearchBarRoot>
  );
};

export default SearchBar;
