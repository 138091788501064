import React from "react";
import { Checkbox, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import {
  AutoSearch as AutoSearchStyled,
  AutoSearchLabel,
} from "./styled-components";

const AutoSearch = ({ auto, onAutoClick }) => (
  <AutoSearchStyled>
    <Spacer right={1}>
      <Checkbox
        checked={auto}
        id="auto-search"
        name="auto-search"
        onChange={onAutoClick}
      />
    </Spacer>
    <AutoSearchLabel htmlFor="auto-search">
      <Text body={6} inline={true}>
        {translate("search.map.autosearch")}
      </Text>
    </AutoSearchLabel>
  </AutoSearchStyled>
);

export default AutoSearch;
