import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "INBOX_ADD_MESSAGE",
  "INBOX_CONNECTION_ERROR",
  "INBOX_CONNECTION_REQUEST",
  "INBOX_CONNECTION_SUCCESS",
  "INBOX_CONNECTION_RESET",
  "INBOX_CONNETION_UPDATE",
  "INBOX_HISTORY_ERROR",
  "INBOX_HISTORY_REQUEST",
  "INBOX_HISTORY_SUCCESS",
  "INBOX_NEW_MESSAGE_ERROR",
  "INBOX_NEW_MESSAGE_REQUEST",
  "INBOX_NEW_MESSAGE_SUCCESS",
  "INBOX_TOGGLE_NEW_MESSAGE_TEXTAREA",
  "SEND_MESSAGE_ENQUIRY_REQUEST",
  "SEND_MESSAGE_ENQUIRY_ERROR",
  "SEND_MESSAGE_ENQUIRY_SUCCESS",
  "RESET_MESSAGE_ENQUIRY_ERROR",
]);
