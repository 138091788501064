import { connect } from "react-redux";

import OverviewActions from "datamodel/Overview/actions";

import ListingsStatuses from "./listing-statuses";

export default connect(
  (state) => ({
    token: state.Auth.access_token,
    listingsStatuses: state.Overview.listingsStatuses.list,
    fetchedListingsStatuses:
      state.Overview.listingsStatuses.fetchedListingsStatuses,
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      getListingsStatuses: () =>
        dispatch(OverviewActions.getListingsStatuses(stateProps.token)),
    };
  },
)(ListingsStatuses);
