import React from "react";
import classNames from "classnames";
import "./stylesExt.css";

const LeftArrow = (props) => {
  const { btnClass, currentSlide, slideCount, ...remainingProps } = props;
  const cName = classNames("LeftArrow", btnClass || "");

  return <button type="button" {...remainingProps} className={cName} />;
};

export default LeftArrow;
