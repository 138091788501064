import React from "react";
import {
  BADI_COLORS,
  Card,
  Flex,
  Heading,
  Spacer,
  Text,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";
import UserProfileBadge from "components/user-profile-badge";
import RoomDetailsBadge from "components/room-detail-badge";
import Section from "./components/section";
import StatusBadge from "./components/status-badge";
import { getVisitTypeContent } from "./utils";
import { VisitDetailsWrapper } from "./styled-components";

const VisitDetails = ({
  videocall,
  date,
  description,
  address = "",
  message = null,
  time,
  userProfile = null,
  room = null,
  status,
}) => {
  const type = getVisitTypeContent(videocall);

  return (
    <>
      <Heading data-qa="visit-details-title" level={1}>
        {translate("visits.title")}
      </Heading>
      <Spacer bottom={1}>
        <StatusBadge status={status} />
      </Spacer>
      <Text data-qa="visit-details-description">{translate(description)}</Text>
      <Spacer bottom={4} top={4}>
        {room && <RoomDetailsBadge {...room} />}
        {userProfile && (
          <UserProfileBadge {...userProfile} avatar={userProfile.picture} />
        )}
      </Spacer>
      <Flex as={VisitDetailsWrapper} direction="column" gutter={3}>
        <Section
          dataQa="visit-details-type-label"
          title={translate("inbox_popup_visit.proposal.visit_type_label")}
        >
          <Spacer top={1}>
            <Flex alignItems="center" gutter={2}>
              <Image src={type.icon} styles={{ display: "block" }} />
              <Flex direction="column">
                <Text body="14-Regular" dataQa="visit-details-type">
                  {translate(type.title)}
                </Text>
                <Text
                  body="12-Regular"
                  dataQa="visit-details-type-desc"
                  inline={true}
                >
                  {translate(type.description)}
                </Text>
              </Flex>
            </Flex>
          </Spacer>
        </Section>
        <Section
          dataQa="visit-details-date-label"
          title={translate("inbox_popup_visit.proposal.date_label")}
        >
          <Text body="14-Regular" dataQa="visit-details-date-value">
            {date}
          </Text>
        </Section>
        <Section
          dataQa="visit-details-time-label"
          title={translate("inbox_popup_visit.proposal.time_label")}
        >
          <Text body="14-Regular" dataQa="visit-details-time-value">
            {time}
          </Text>
        </Section>
        <Section
          dataQa="visit-details-location-label"
          title={translate("inbox_popup_visit.proposal.address_label")}
        >
          {address ? (
            <Text body="14-Regular" dataQa="visit-details-location-value">
              {address}
            </Text>
          ) : (
            <Text
              body="14-Regular"
              color={BADI_COLORS.SEMANTIC.WARNING.DEFAULT}
              dataQa="visit-details-location-warning"
            >
              {translate("visits.home.address.text")}
            </Text>
          )}
        </Section>
        {message && (
          <Section
            dataQa="visit-details-message"
            title={translate(`visits.${message.userRole}.message`)}
          >
            <Card
              backgroundColor={BADI_COLORS.NEUTRAL.NEUTRAL_00}
              withShadow={false}
            >
              <Spacer bottom={2} left={2} right={2} top={2}>
                <Text body="14-Regular" dataQa="visit-details-message-value">
                  {message.content}
                </Text>
              </Spacer>
            </Card>
          </Section>
        )}
      </Flex>
    </>
  );
};

export default VisitDetails;
