import styled from "styled-components";
import { colors } from "@badi/badi-components";

export const Root = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: ${colors.white};
`;

export const Content = styled.div`
  border-top: 1px solid ${colors.lightGrey};
`;
