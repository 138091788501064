import React from "react";

import PushNotificationsBar from "./push-notification-bar";
import CompleteProfileBar from "./complete-profile-bar";

const InfoBarWrapper = ({
  isAbleToPushNotifications,
  showCompleteProfileBar,
}) => {
  switch (true) {
    case showCompleteProfileBar:
      return <CompleteProfileBar />;
    case isAbleToPushNotifications:
      return <PushNotificationsBar />;
    default:
      return null;
  }
};

export default InfoBarWrapper;
