class RoomProperties {
  constructor(data) {
    this.entity = data || {};
  }

  get numberOfPictures() {
    return this.entity.number_of_pictures;
  }

  get numberOfAmenities() {
    return this.entity.number_of_amenities;
  }

  get lengthOfDescription() {
    return this.entity.length_of_description;
  }

  toJSON() {
    return {
      numberOfPictures: this.numberOfPictures,
      numberOfAmenities: this.numberOfAmenities,
      lengthOfDescription: this.lengthOfDescription,
    };
  }
}

export default RoomProperties;
