import Types from "../types";

export default {
  setGDPR(gdpr, callback) {
    return { type: Types.SET_GDPR, result: { show: gdpr, callback } };
  },
  setCountryVat(countryVat, callback) {
    return {
      type: Types.SET_COUNTRY_VAT,
      result: { show: countryVat, callback },
    };
  },
  setOnboarding(onboarding, callback) {
    return {
      type: Types.SET_ONBOARDING,
      result: { show: onboarding, callback },
    };
  },
  setProfile(profile, callback) {
    return { type: Types.SET_PROFILE, result: { show: profile, callback } };
  },
  setPhone(phone, callback) {
    return { type: Types.SET_PHONE, result: { show: phone, callback } };
  },
  setLogin(login, callback) {
    return { type: Types.SET_LOGIN, result: { show: login, callback } };
  },
  setRegister(register, callback) {
    return { type: Types.SET_REGISTER, result: { show: register, callback } };
  },
  setTermsAndConditions(termsAndConditions, callback) {
    return {
      type: Types.SET_TERMS_AND_CONDITIONS,
      result: { show: termsAndConditions, callback },
    };
  },
  setRequirements(requirements) {
    return { type: Types.SET_REQUIREMENTS, result: requirements };
  },

  resetRequirements() {
    return { type: Types.RESET_REQUIREMENTS };
  },
};
