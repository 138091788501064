import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const BookingPaymentInfoRoot = styled.div`
  ${mediaquery.md`  
    min-width: ${unitizedPx(53)};
  `}
`;

export const BookingPaymentInfoImage = styled.span`
  display: none;
  ${mediaquery.md`  
    display: block;
  `}
`;

export const ButtonCenterer = styled.div`
  display: flex;
  justify-content: center;
`;
