import { translate } from "base/shared/Localization";

export const getMappedList = (length = 0) => {
  const items = [...Array(length).keys()].map((i) => i + 1);

  return items;
};

export const getTranslatedData = (data) => {
  const { itemFields, items, title, translationkey, ...props } = data;
  const translatedData = { ...props };
  const mappedItems = getMappedList(items);

  translatedData.items = mappedItems.map((item) => {
    const mappedItem = itemFields.reduce(
      (prev, field) => {
        prev[field] = translate(`${translationkey}.${item}.${field}`);
        return prev;
      },
      { id: item },
    );
    return mappedItem;
  });

  if (title) {
    translatedData.title = translate(`${translationkey}.list.title`);
  }
  return translatedData;
};
