import React, { Component } from "react";

import { TextButton, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import AvailabilityFields from "./availability-fields";
import { getSelectedIds, getSelectedElements } from "../utils";
import { SubmitFormWrapper, StyledForm } from "../styled-components";

class AvailabilityForm extends Component {
  static getDerivedStateFromProps(props, state) {
    const { now, fromDate, stayOptions } = props;

    if (state.updated) {
      return { updated: false };
    }

    return {
      now,
      fromDate,
      stayOptionsIds: getSelectedIds(stayOptions),
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      now: false,
      fromDate: "",
      updated: false,
      stayOptionsIds: [],
    };
    this.stayOptions = translate(props.stayOptions, ["title", "description"]);
  }

  handleFromDateSelect = (fromDate) => {
    this.setState({ fromDate, now: false, updated: true });
  };

  handleNowSelect = () => {
    this.setState({ fromDate: "", now: true, updated: true });
  };

  handleStayOptionChange = (stayOptionsIds) => {
    this.setState({ stayOptionsIds, updated: true });
  };

  handleApplyFilter = () => {
    const { now, fromDate, stayOptionsIds } = this.state;
    const { onFilterApplied, setAvailability, stayOptions } = this.props;

    const parsedStayOptions = getSelectedElements(stayOptions, stayOptionsIds);

    setAvailability(now, fromDate, parsedStayOptions);
    onFilterApplied();
  };

  render() {
    return (
      <Flex as={StyledForm} direction="column">
        <AvailabilityFields
          {...this.state}
          onFromDateSelect={this.handleFromDateSelect}
          onNowSelect={this.handleNowSelect}
          onStayOptionChange={this.handleStayOptionChange}
          stayOptions={this.stayOptions}
        />
        <SubmitFormWrapper>
          <TextButton onClick={this.handleApplyFilter}>
            {translate("search.filters.button")}
          </TextButton>
        </SubmitFormWrapper>
      </Flex>
    );
  }
}

AvailabilityForm.defaultProps = {
  fromDate: "",
};

export default AvailabilityForm;
