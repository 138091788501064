import React from "react";
import { Container, Row, Col } from "reactstrap";

import { Text, Spacer } from "@badi/badi-components";
import Checkbox from "components/Form/checkbox-with-formsy";

import { GENDERS_ROOMMATES_CHECKBOXES } from "base/shared/StaticData";
import { translate } from "base/shared/Localization";

import FormBlock from "components/form-block";

const GenderPreferences = ({ male, female, nonBinary }) => {
  const { MALE, FEMALE, NON_BINARY } = GENDERS_ROOMMATES_CHECKBOXES;

  return (
    <FormBlock
      dataQa="gender-preferences-form-block"
      subTitle={translate("room.list.step5.gender.subTitle")}
      title={translate("room.list.step5.gender.title")}
    >
      <Container fluid={true}>
        <Row>
          <Col style={{ paddingLeft: "0" }} xs={12}>
            <Spacer bottom={2.5} top={0.5}>
              <Checkbox
                checked={male}
                dataQa={MALE.dataQa}
                name={MALE.name}
                value={male}
              >
                <Text body="16-Regular" dataQa={MALE.dataQaLabel} inline={true}>
                  {translate(MALE.label)}
                </Text>
              </Checkbox>
            </Spacer>

            <Spacer bottom={2.5} top={0.5}>
              <Checkbox
                checked={female}
                dataQa={FEMALE.dataQa}
                name={FEMALE.name}
                value={female}
              >
                <Text
                  body="16-Regular"
                  dataQa={FEMALE.dataQaLabel}
                  inline={true}
                >
                  {translate(FEMALE.label)}
                </Text>
              </Checkbox>
            </Spacer>

            <Spacer bottom={2.5} top={0.5}>
              <Checkbox
                checked={nonBinary}
                dataQa={NON_BINARY.dataQa}
                name={NON_BINARY.name}
                value={nonBinary}
              >
                <Text
                  body="16-Regular"
                  dataQa={NON_BINARY.dataQaLabel}
                  inline={true}
                >
                  {translate(NON_BINARY.label)}
                </Text>
              </Checkbox>
            </Spacer>
          </Col>
        </Row>
      </Container>
    </FormBlock>
  );
};

export default GenderPreferences;
