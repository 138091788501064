import createReducer from "base/shared/createReducer";
import { v4 as uuidv4 } from "uuid";
import Types from "../types";
import { ModalsModel } from "../models";

function addModal(state, action) {
  const modal = { ...action.result };

  if (typeof modal.options.id === "undefined") modal.options.id = uuidv4();

  const i = state.modals.findIndex((m) => m.options.id === modal.options.id);

  if (i >= 0) {
    if (modal.options.override) {
      return {
        ...state,
        modals: [...state.modals.filter((m, j) => i !== j), modal],
      };
    }

    return state;
  }

  return {
    ...state,
    modals: [...state.modals, modal],
  };
}

function clearAll(state) {
  return {
    ...state,
    modals: [],
  };
}

function destroyModal(state, action) {
  const id = action.result;

  return {
    ...state,
    modals: [...state.modals.filter((m) => m.options.id !== id)],
  };
}

function removeModal(state, action) {
  const id = action.result;
  const modifiedModals = [...state.modals];
  const modalIndex = modifiedModals.findIndex((m) => m.options.id === id);

  if (modalIndex < 0) return state;

  const modalToDestroy = modifiedModals[modalIndex];
  modalToDestroy.destroy = true;

  return {
    ...state,
    modals: modifiedModals,
  };
}

function updateModal(state, action) {
  const result = { ...action.result };
  const modalId = result.id;
  const { id, ...modal } = result;

  return {
    ...state,
    modals: [...state.modals.filter((m) => m.options.id !== modalId), modal],
  };
}

function updateModalOptions(state, action) {
  const { id, options } = action.result;

  const modals = [...state.modals];
  const index = modals.findIndex((m) => m.options.id === id);
  const modal = modals[index];

  modals[index] = {
    ...modal,
    options: {
      ...modal.options,
      ...options,
    },
  };

  return {
    ...state,
    modals,
  };
}

const actionHandlers = {
  [Types.ADD_MODAL]: addModal,
  [Types.CLEAR_ALL]: clearAll,
  [Types.DESTROY_MODAL]: destroyModal,
  [Types.REMOVE_MODAL]: removeModal,
  [Types.UPDATE_MODAL]: updateModal,
  [Types.UPDATE_MODALOPTIONS]: updateModalOptions,
};

export default createReducer(actionHandlers, { ...ModalsModel });
