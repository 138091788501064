import React from "react";
import { Flex, Spacer, Text, BADI_COLORS } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { StatusRowStyle } from "./styled-components";
import { TRANSACTION_STATUS } from "../../constants";

const StatusRow = ({
  label = "",
  lastChild = false,
  date = "",
  status = "",
}) => {
  const color =
    status === TRANSACTION_STATUS.pending
      ? BADI_COLORS.NEUTRAL.NEUTRAL_50
      : BADI_COLORS.BRAND.TERTIARY.DEFAULT;
  return (
    <Spacer bottom={1.5}>
      <StatusRowStyle
        alignItems="center"
        as={Flex}
        justify="spaceBetween"
        lastChild={lastChild}
        status={status}
      >
        <Text body="14-Medium" color={color}>
          {label}
        </Text>
        <Text body="14-Medium" color={color}>
          {date}
        </Text>
      </StatusRowStyle>
    </Spacer>
  );
};

export default StatusRow;
