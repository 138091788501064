import React, { useEffect, useState, useCallback } from "react";

import { Col, Container, Row } from "reactstrap";
import { Spacer, Heading, Flex, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { BOOKING_STATUS } from "datamodel/booking/constants";

import LayoutWrapper from "components/Layout/Wrapper";
import StepsDescriptionCard from "components/steps-description-card";
import BookingRequestSummary from "components/booking-request-summary";

import { getBookingRequest } from "datamodel/booking/api";
import { getRelativePath } from "base/shared/Utils";

import TopBar from "../top-bar";
import StatusBadge from "../booking-status-badge";
import ExpirationBadge from "../booking-expiration-badge";

const BookingRequest = ({ connectionId, requestId, router, token }) => {
  const [bookingInfo, setBookingInfo] = useState(null);

  useEffect(() => {
    getBookingRequest({
      token,
      requestId,
    }).then((response) => setBookingInfo(response));
  }, [requestId]);

  const onClose = useCallback(() => {
    const nextRoute = connectionId ? `/conversation/${connectionId}` : "/inbox";
    router.push(getRelativePath(nextRoute));
  }, [connectionId]);

  return (
    <LayoutWrapper topBar={<TopBar onClose={onClose} />}>
      <Spacer bottom={8} md={{ top: 8 }} top={2}>
        <Container>
          {bookingInfo && (
            <Row>
              <Col lg={{ size: 7 }} sm={12}>
                <Flex direction="column" gutter={1}>
                  <Heading data-qa="booking-request-header">
                    {translate("booking.request.heading")}
                  </Heading>
                  <Flex gutter={1}>
                    {bookingInfo.status !== BOOKING_STATUS.PENDING && (
                      <StatusBadge
                        status={bookingInfo.status}
                        userRole={bookingInfo.userRole}
                      />
                    )}
                    {bookingInfo.expirationText && (
                      <ExpirationBadge
                        expiration={bookingInfo.expirationText}
                      />
                    )}
                  </Flex>
                  <Spacer bottom={1} />
                  {bookingInfo.status === BOOKING_STATUS.PAYMENT_REQUIRED && (
                    <Flex direction="column" gutter={2}>
                      <Text data-qa="booking-request-waiting-title">
                        {translate("booking.summary_waiting_payment_title")}
                      </Text>
                      <Heading data-qa="booking-request-waiting-text" level={4}>
                        {translate("booking.summary_waiting_payment_text")}
                      </Heading>
                    </Flex>
                  )}
                  {bookingInfo.status !== BOOKING_STATUS.PAYMENT_REQUIRED &&
                    bookingInfo.status !== BOOKING_STATUS.ACCEPTED && (
                      <Text data-qa="booking-request-title">
                        {translate("booking.request.sub-heading")}
                      </Text>
                    )}
                  <Spacer bottom={3} top={3}>
                    <BookingRequestSummary
                      bookingValue={bookingInfo.bookingValue}
                      exactMonthsLength={bookingInfo.exactMonthsLength}
                      lengthInMonths={bookingInfo.lengthInMonths}
                      moveIn={bookingInfo.moveIn}
                      moveOut={bookingInfo.moveOut}
                      price={bookingInfo.price}
                      room={bookingInfo.room}
                      userRole={bookingInfo.userRole}
                    />
                  </Spacer>
                </Flex>
              </Col>
              {bookingInfo.status === BOOKING_STATUS.PENDING && (
                <Col lg={{ size: 4, offset: 1 }} sm={12}>
                  <StepsDescriptionCard
                    data-qa="booking-request-steps-description"
                    userRole={bookingInfo.userRole}
                  />
                </Col>
              )}
            </Row>
          )}
        </Container>
      </Spacer>
    </LayoutWrapper>
  );
};

BookingRequest.defaultProps = {
  connectionId: 0,
};

export default BookingRequest;
