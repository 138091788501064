import dayjs from "dayjs";
import { getBenefitsIds } from "datamodel/search-rooms/utils";

import {
  BED_TYPE,
  GENDERS,
  FLAT_AMENITIES,
  FLAT_BENEFITS,
  HOUSE_AMENITIES,
  PLACE_TYPE,
  SORT_BY,
  ROOM_AMENITIES,
  DEFAULT_FILTER_VALUE,
  DEFAULT_SORT_BY_VALUE,
  STAY_OPTIONS,
  MARKETPLACE_SEGMENTS,
} from "../static";

const parseStaticData = (data) => {
  const { translationKey, items, ...props } = data;

  return items.map((item) => {
    const { name, value, ...itemProps } = item;
    const parsedItem = {
      value: item.id,
      ...itemProps,
      title: `${translationKey}.${name}`,
      description: `${translationKey}.${name}.description`,
      selected: false,
      ...props,
    };
    if (!item.description) delete parsedItem.description;
    return parsedItem;
  });
};

export const getStayOptions = () => parseStaticData(STAY_OPTIONS);

export const getBedTypes = () => parseStaticData(BED_TYPE);

export const getGenders = () => parseStaticData(GENDERS);

export const getFlatAmenities = () => parseStaticData(FLAT_AMENITIES);

export const getFlatBenefits = () => parseStaticData(FLAT_BENEFITS);

export const getRoomAmenities = () => parseStaticData(ROOM_AMENITIES);

export const getHouseRules = () => parseStaticData(HOUSE_AMENITIES);

export const getSortBy = (initialized = true) => {
  const sortBy = parseStaticData(SORT_BY);

  if (initialized) {
    const index = sortBy.findIndex(
      (sort) => sort.id === DEFAULT_SORT_BY_VALUE.id,
    );
    sortBy[index].selected = true;
  }

  return sortBy;
};

export const getPlaceType = () => parseStaticData(PLACE_TYPE);

export const unSanitizeParam = (param) => {
  if (typeof param === "string") {
    const withCommas = param.replace(/([-]{2})+/g, ", ");
    return withCommas.replace(/([-]{1})+/g, " ");
  }
  return "";
};

const parseToNum = (value, type) => {
  const parsedValue =
    type === "float" ? parseFloat(value) : parseInt(value, 10);
  return isNaN(parsedValue) ? undefined : parsedValue;
};

const parseToArray = (ids, initialElements, property = "id") => {
  if (!ids) return initialElements;

  return ids.split(",").reduce((prev, id) => {
    const elements = prev;
    const index = prev.findIndex(
      (p) => p[property] === (isNaN(p[property]) ? id : parseToNum(id)),
    );

    if (index >= 0) {
      elements[index] = { ...prev[index], selected: true };
    }

    return elements;
  }, initialElements);
};

const parseToLatLng = (value) => {
  if (!value) return undefined;
  const splittedVal = value.split(",");

  if (splittedVal.length < 2) return undefined;

  return {
    lat: parseToNum(splittedVal[0], "float"),
    lng: parseToNum(splittedVal[1], "float"),
  };
};

const parseToBounds = (value) => {
  if (!value) return undefined;

  const splittedVal = value.split(";");

  if (splittedVal.length < 2) return undefined;

  return {
    ne: parseToLatLng(splittedVal[0]),
    sw: parseToLatLng(splittedVal[1]),
  };
};

const parseParam = (name, value) => {
  let parsedValue = {};

  switch (name) {
    case "amenities":
      parsedValue.flatAmenities = parseToArray(value, getFlatAmenities());
      parsedValue.roomAmenities = parseToArray(value, getRoomAmenities());
      parsedValue.houseRules = parseToArray(value, getHouseRules());
      break;
    case "bed":
      parsedValue.bedTypes = parseToArray(value, getBedTypes());
      break;
    case "benefits":
      parsedValue.flatBenefits = parseToArray(
        getBenefitsIds(value),
        getFlatBenefits(),
      );
      break;
    case "g":
      parsedValue.genders = parseToArray(value, getGenders());
      break;
    case "t":
      parsedValue.tenants = parseToNum(value);
      break;
    case "stay":
      parsedValue.stayOptions = parseToArray(
        value,
        getStayOptions(),
        "apivalue",
      );
      break;
    case "bounds":
      parsedValue.bounds = parseToBounds(value);
      break;
    case "center":
      parsedValue.coordinates = parseToLatLng(value);
      break;
    case "max":
      parsedValue.max = parseToNum(value);
      break;
    case "min":
      parsedValue.min = parseToNum(value);
      break;
    case "d":
      parsedValue.distance = parseToNum(value);
      break;
    case "sort":
      parsedValue.sortBy = parseToArray(value, getSortBy(false));
      break;
    case "place":
      parsedValue.placeTypes = parseToArray(value, getPlaceType());
      break;
    case "now":
      parsedValue.now = !!+value;
      break;
    case "b":
      parsedValue.bookable = !!+value;
      break;
    case "from":
      parsedValue.fromDate = value;
      break;
    case "city":
      parsedValue.city = value;
      break;
    case "pid":
      parsedValue.placeId = value;
      break;
    case "text":
      parsedValue.text = unSanitizeParam(value);
      break;
    case "z":
      parsedValue.zoom = parseToNum(value || 0);
      break;
    case "token":
      parsedValue.token = value;
      break;
    case "marketplaceSegmentBadiRooms":
      parsedValue.marketplaceSegmentsFilter = {
        badiRooms: true,
        badiCommunity: false,
      };
      break;
    case "marketplaceSegmentBadiCommunity":
      parsedValue.marketplaceSegmentsFilter = {
        badiRooms: false,
        badiCommunity: true,
      };
      break;
    default:
      parsedValue = undefined;
  }
  return parsedValue;
};

export const parseQueryToParams = (params) => {
  if (!params) return {};

  return Object.keys(params).reduce((prev, key) => {
    const parsedParam = parseParam(key, params[key]);

    if (typeof parsedParam !== "undefined") {
      return { ...prev, ...parsedParam };
    }

    return prev;
  }, {});
};

export const getSortByFilter = ({ sortBy }) => {
  const { id } = DEFAULT_SORT_BY_VALUE;
  const selected = sortBy.find((sort) => sort.selected) || { id };

  return {
    value: selected.title,
    cluster: 0,
    showCancelButton: selected.id !== id,
  };
};

export const getBudgetFilter = ({ min, max }) => {
  if (!min && !max) return DEFAULT_FILTER_VALUE;

  let value = {};

  if (!max) {
    value.text = "search.filters.budget.minimum.value";
    value.value = { min };
  } else if (!min) {
    value.text = "search.filters.budget.maximum.value";
    value.value = { max };
  } else {
    value = `${min} - ${max}`;
  }

  return { ...DEFAULT_FILTER_VALUE, value };
};

export const getAvailabilityFilter = ({ fromDate, stayOptions, now }) => {
  let value = null;

  if (now) value = dayjs().format("DD MMM");

  if (fromDate) {
    value = dayjs(fromDate).format("DD MMM");
  }

  const selectedStayOptions = stayOptions
    .filter((o) => o.selected)
    .map((o) => o.label);

  if (selectedStayOptions.length) {
    const stayOutput = selectedStayOptions.join(".");

    value = {
      text: `search.filters.availability.filter.${stayOutput}`,
      value: {
        date: `${value || dayjs().format("DD MMM")}`,
      },
    };
  }

  return { ...DEFAULT_FILTER_VALUE, value };
};

export const getFlatmatesFilter = ({ tenants, genders }) => {
  let value = null;
  let cluster = 0;

  const selected = genders.filter((g) => g.selected);
  const { length } = selected;

  if (tenants && length > 0) {
    cluster = length + 1;
  } else if (tenants) {
    value = {
      text: "search.filters.flatmates.tenants.filter",
      value: { smart_count: tenants },
    };
  } else if (length === 1) {
    value = selected[0].title;
  } else if (length > 1) {
    cluster = length;
  }

  return { cluster, value };
};

export const getFlatFilter = ({
  flatAmenities,
  flatBenefits,
  houseRules,
  minFlatSize,
  maxFlatSize,
}) => {
  let value = null;
  let cluster = 0;

  let amenities = flatAmenities.filter((f) => f.selected);
  amenities = amenities.concat(houseRules.filter((h) => h.selected));
  amenities = amenities.concat(flatBenefits.filter((k) => k.selected));
  const { length } = amenities;
  const numFilters = length + (minFlatSize || maxFlatSize ? 1 : 0);

  if (numFilters > 1) cluster = numFilters;
  else if (numFilters === 1) {
    if (length) value = amenities[0].title;
    else if (minFlatSize && maxFlatSize)
      value = `${minFlatSize}-${maxFlatSize}m²`;
    else if (minFlatSize) value = `${minFlatSize}m² min`;
    else if (maxFlatSize) value = `${maxFlatSize}m² max`;
  }

  return { value, cluster };
};

export const getRoomFilter = ({
  bedTypes,
  placeTypes,
  bookable,
  roomAmenities,
}) => {
  let value = null;
  let cluster = 0;

  let selectedItems = bedTypes.filter((b) => b.selected);
  selectedItems = selectedItems.concat(placeTypes.filter((p) => p.selected));
  selectedItems = selectedItems.concat(roomAmenities.filter((r) => r.selected));

  const { length } = selectedItems;

  if (bookable && length > 0) {
    cluster = length + 1;
  } else if (bookable) {
    value = "search.filters.room.bookable";
  } else if (length > 1) {
    cluster = length;
  } else if (length === 1) {
    value = selectedItems[0].title;
  }

  return { value, cluster };
};

export const getFilters = (props) => {
  return {
    flatmatesFilter: getFlatmatesFilter(props),
    roomFilter: getRoomFilter(props),
    flatFilter: getFlatFilter(props),
    availabilityFilter: getAvailabilityFilter(props),
    sortFilter: getSortByFilter(props),
    budgetFilter: getBudgetFilter(props),
    marketplaceSegmentsFilter: {
      badiCommunity: props.marketplaceSegmentsFilter.badiCommunity,
      badiRooms: props.marketplaceSegmentsFilter.badiRooms,
    },
  };
};
