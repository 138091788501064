export default class ReferralAccount {
  constructor(data) {
    this.entity = data || {};
  }

  get id() {
    return this.entity.id;
  }

  get status() {
    return this.entity.status || 1;
  }

  get refereeName() {
    return this.entity.referee_name || "";
  }

  toJSON() {
    return {
      id: this.id,
      status: this.status,
      refereeName: this.refereeName,
    };
  }
}
