import React, { Component } from "react";
import { Flex, Spacer, Heading, Text } from "@badi/badi-components";
import { Container, Col, Row } from "reactstrap";
import LayoutWrapper from "components/Layout/Wrapper";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import PhoneVerificationForm from "components/Verification/Phone/phone-verification-mapping";
import EmailVerificationForm from "components/Verification/Email/EmailVerificationMapping";
import BankAccount from "components/bank-account";
import ContactInformation from "components/contact-information";

class Payout extends Component {
  componentDidMount() {
    const { getAccount, loaded } = this.props;

    if (!loaded) getAccount();
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error) {
      UserFeedback.exception("Something went wrong", error.message, error);
    }
  }

  render() {
    const { email, getAccount, loaded, phoneNumber } = this.props;

    return (
      <LayoutWrapper>
        <Spacer bottom={4} lg={{ top: 8 }} top={4}>
          <Container>
            <Row>
              <Col sm={12}>
                <Spacer bottom={1}>
                  <Heading>{translate("payout.title")}</Heading>
                </Spacer>
                <Spacer bottom={4}>
                  <Text body={1}>{translate("payout.subtitle")}</Text>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col lg={8} md={12}>
                {loaded && (
                  <Flex direction="column" gutter={3}>
                    <BankAccount />
                    <ContactInformation />
                    <EmailVerificationForm email={email} />
                    <PhoneVerificationForm
                      onSuccess={getAccount}
                      phoneNumber={phoneNumber}
                    />
                  </Flex>
                )}
              </Col>
            </Row>
          </Container>
        </Spacer>
      </LayoutWrapper>
    );
  }
}

Payout.defaultProps = {
  error: null,
};

export default Payout;
