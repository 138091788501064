import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

import Svg from "assets/icons/ico_down_black.svg";

export const Back = styled.a`
  display: inline-flex;
  text-transform: uppercase;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

export const Caret = styled(Svg)`
  margin-right: ${unitizedPx(1)};
  transform: rotate(90deg);
`;
