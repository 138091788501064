import styled from "styled-components";

export const Root = styled.div`
  outline: none;
  ${(props) =>
    props.clickable &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`;
