import React from "react";

const BoltIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="m381.5-139.5 40-274h-160L541-816h-2.5l-39 309h187l-307 367.5h2Z" />
  </svg>
);

const PriorityIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M400.5-435 430-535l-79-54h99l30-104 30 104h99l-78.5 54L560-435l-80-62.5-79.5 62.5Zm-105 309v-246.5q-37.5-36-58.25-84.299Q216.5-505.098 216.5-559.5q0-110 76.75-186.75T480-823q110 0 186.75 76.75t76.75 186.655q0 54.595-20.75 102.845T664.5-372.5V-126L480-179.036 295.5-126Zm184.618-195.5q99.382 0 168.632-69.368Q718-460.235 718-559.618 718-659 648.632-728.25q-69.367-69.25-168.75-69.25-99.382 0-168.632 69.368Q242-658.765 242-559.382 242-460 311.368-390.75q69.367 69.25 168.75 69.25Z" />
  </svg>
);

const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M137-187.5v-578.562q0-24.376 16.531-40.907Q170.062-823.5 194.28-823.5h571.44q24.218 0 40.749 16.531Q823-790.438 823-766.198v412.396q0 24.24-16.531 40.771-16.531 16.531-40.747 16.531H246l-109 109Zm132-241h263.5V-454H269v25.5Zm0-119h422V-573H269v25.5Zm0-119h422V-692H269v25.5Z" />
  </svg>
);

const GuaranteeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="m438.5-376.5 187-187-18-18.5-169 169-86-86-18 18 104 104.5ZM480-139q-112.955-35.37-188.227-139.935Q216.5-383.5 216.5-516.062v-204.31L480-819l263.5 98.628v204.31q0 132.562-75.273 237.127Q592.955-174.37 480-139Z" />
  </svg>
);

export { BoltIcon, PriorityIcon, ChatIcon, GuaranteeIcon };
