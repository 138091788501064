import React, { memo } from "react";
import { Link } from "react-router";
import { Flex, Spacer } from "@badi/badi-components";

import { getRelativePath } from "base/shared/Utils";
import { interpolateAllQueryParam } from "base/shared/tracking-headers";
import Slider from "components/Slider";
import Arrow from "components/arrow";
import { translate } from "base/shared/Localization";

import Details from "./details";
import VerifiedBadge from "./components/verified-badge";
import OwnedBadge from "./components/owned-badge";
import {
  ImageWrapper,
  RoomCardWrapper,
  RoomCardLink,
  BoostedLabel,
  BadgeBar,
} from "./styled-components";

const RoomCard = ({
  billsIncluded = false,
  floating = false,
  htmlId = undefined,
  id,
  lister,
  pictures,
  priceText,
  selectRoomById = () => {},
  selected = false,
  showArrows = false,
  paddedDetails = false,
  title,
  url,
  verified = false,
  boosting = {},
  matchProfile = false,
  owned = false,
  showBillsIncludedFlag,
  previousPrice,
  openSameTab = false,
  placeType,
  flatSize,
  maleTenants,
  femaleTenants,
  sizeUnit,
  type = "search",
  handleEtParams = null,
  benefits = {},
  contractOfferedLabel = false,
  singleBedrooms,
  doubleBedrooms,
}) => {
  const handleMouseEnterHandler = () => {
    selectRoomById(id);
  };

  const handleMouseLeaveHandler = () => {
    selectRoomById(0);
  };

  const etParams = handleEtParams ? handleEtParams() : null;
  const linkUrl = interpolateAllQueryParam(getRelativePath(url), etParams);
  const isBoosted = boosting?.status === "active";
  const isSearchCard = type === "search";
  const contractOffered = contractOfferedLabel && benefits.rentalContract;

  return (
    <RoomCardLink
      as={Link}
      data-qa="room-card-link"
      id={htmlId}
      onMouseEnter={handleMouseEnterHandler}
      onMouseLeave={handleMouseLeaveHandler}
      target={openSameTab ? "_self" : "_blank"}
      to={linkUrl}
      type={type}
    >
      <RoomCardWrapper as={Flex} type={type}>
        {isSearchCard && isBoosted && (
          <Spacer bottom={0.5}>
            <BoostedLabel>{translate("room.boosting.status")}</BoostedLabel>
          </Spacer>
        )}
        <ImageWrapper floating={floating} type={type}>
          <BadgeBar gutter={1}>
            {verified && <VerifiedBadge />}
            {owned && <OwnedBadge />}
          </BadgeBar>
          <Slider
            arrows={selected || showArrows ? "default" : "never"}
            nextArrow={<Arrow direction="right" />}
            pictures={pictures}
            prevArrow={<Arrow direction="left" />}
          />
        </ImageWrapper>
        <Spacer
          basis={"45%"}
          bottom={paddedDetails ? 1 : 0}
          left={paddedDetails ? 1 : 0}
          right={paddedDetails ? 1 : 0}
        >
          <Details
            billsIncluded={showBillsIncludedFlag && billsIncluded}
            contractOffered={contractOffered}
            doubleBedrooms={doubleBedrooms}
            femaleTenants={femaleTenants}
            flatSize={flatSize}
            lister={lister}
            maleTenants={maleTenants}
            matchProfile={!owned && matchProfile}
            placeType={placeType}
            previousPrice={previousPrice}
            price={priceText}
            singleBedrooms={singleBedrooms}
            sizeUnit={sizeUnit}
            title={title}
            type={type}
          />
        </Spacer>
      </RoomCardWrapper>
    </RoomCardLink>
  );
};

export default memo(RoomCard);
