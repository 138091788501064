export const TRUST = {
  items: 4,
  translationkey: "security.section.trust",
  disposition: { md: 6, sm: 5, lg: 3 },
  itemFields: ["title", "description"],
  decoratorClassName: "Security__ListItem_Trust",
  title: false,
};

export const COMMUNICATION = {
  decoratorClassName: "Security__ListItem_Communication",
  disposition: { md: 6, sm: 5, lg: 4 },
  items: 3,
  itemFields: ["title", "description"],
  translationkey: "security.section.connect.communication",
  title: true,
};

export const SAFETY = {
  decoratorClassName: "Security__ListItem_Safety",
  disposition: { md: 6, sm: 5, lg: 4 },
  items: 3,
  itemFields: ["title", "description"],
  translationkey: "security.section.connect.safety",
  title: true,
};

export const FAQS = {
  items: 6,
  itemFields: ["text", "link"],
  translationkey: "security.section.faqs",
  title: true,
};
