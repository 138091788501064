import React, { memo, useState } from "react";
import { Choice, Text, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { CapitalizeWrapper, DateWrapper } from "./styled-components";

export const DATE_FIELD_ID = "date_visit";

const DateField = memo(({ days, disabled = false, onChange }) => {
  const [date, setDate] = useState(days[0]?.date);

  const handleDateChange = (event) => {
    const { value } = event.currentTarget;

    setDate(value);
    onChange(value);
  };

  return (
    <Flex direction="column" gutter={1}>
      <Text body="14-Bold" dataQa="visit-proposal-date-title">
        {translate("visits.date.title")} *
      </Text>
      <Flex as={DateWrapper} gutter={2}>
        {days.map(({ date: dayDate, day, weekday }, i) => (
          <CapitalizeWrapper key={dayDate}>
            <Choice
              checked={dayDate === date}
              description={day}
              disabled={disabled}
              id={`${DATE_FIELD_ID}-${i}`}
              name={DATE_FIELD_ID}
              onChange={handleDateChange}
              type="radio"
              value={dayDate}
              width="100px"
            >
              {weekday}
            </Choice>
          </CapitalizeWrapper>
        ))}
      </Flex>
    </Flex>
  );
});

DateField.displayName = "DateField";

export default DateField;
