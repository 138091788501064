import React from "react";
import {
  Button,
  Flex,
  BREAKPOINTS_PX,
  MatchMedia,
} from "@badi/badi-components";

import { Container, Col, Row } from "reactstrap";

import { FooterBar } from "../styled-components";

const Footer = ({
  isLocationCompleted,
  handleMakeSuggestedSearch,
  textButton,
}) => {
  return (
    <FooterBar alignItems="center" as={Flex}>
      <Container basis="100%">
        <Row>
          <Col xs={12}>
            <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.md})`}>
              {(matches) => (
                <Flex alignItems="center" justify="flexEnd">
                  <Button
                    basis={matches ? "auto" : "100%"}
                    dataQa="seeker-preferences-button-search"
                    disabled={!isLocationCompleted}
                    fullWidth={!matches}
                    onClick={handleMakeSuggestedSearch}
                  >
                    {textButton}
                  </Button>
                </Flex>
              )}
            </MatchMedia>
          </Col>
        </Row>
      </Container>
    </FooterBar>
  );
};

export default Footer;
