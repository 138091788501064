import React from "react";
import classNames from "classnames";

import "./CounterExt.css";

class Counter extends React.PureComponent {
  isValidValue = (newValue) => {
    const { max, min } = this.props;
    return newValue >= min && newValue <= max;
  };

  updateValue = (value) => {
    const newValue = this.isValidValue(value) ? value : this.props.value;
    this.props.onChange(newValue);
  };

  decreaseValue = () => {
    this.updateValue(this.props.value - 1);
  };

  increaseValue = () => {
    this.updateValue(this.props.value + 1);
  };

  render() {
    const { className, label, name, max, min, value, dataQa } = this.props;

    return (
      <div
        className={classNames("Form__Counter--wrapper", className)}
        data-qa={dataQa}
      >
        <label htmlFor={name}>{label}</label>
        <div>
          <button
            data-qa={`${dataQa}-subtract`}
            name={`${name}-subtract`}
            onClick={this.decreaseValue}
            type="button"
          >
            -
          </button>
          <input
            data-qa={`${dataQa}-input`}
            max={max}
            min={min}
            name={name}
            readOnly={true}
            type="number"
            value={value}
          />
          <button
            data-qa={`${dataQa}-add`}
            name={`${name}-add`}
            onClick={this.increaseValue}
            type="button"
          >
            +
          </button>
        </div>
      </div>
    );
  }
}

Counter.defaultProps = {
  className: "",
  dataQa: null,
  label: "",
  max: 999,
  min: 0,
  onChange: () => {},
};

export default Counter;
