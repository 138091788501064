import styled from "styled-components";
import { unitizedPx, BADI_COLORS, mediaquery } from "@badi/badi-components";

export const TopBarWrapper = styled.div`
  width: 100%;
  height: ${unitizedPx(10)};
  padding: ${unitizedPx(3.5)};
  border-bottom: 4px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
`;

export const CroppedImage = styled.div`
  width: ${unitizedPx(59)};
  height: ${unitizedPx(40)};
  overflow: hidden;

  img {
    width: 140%;
    height: 140%;
    margin: -${unitizedPx(4)} 0 0 -${unitizedPx(10)};
  }
`;

export const StyledCard = styled.div`
  cursor: pointer;
  ${mediaquery.lg`  
    width: ${unitizedPx(53)};
  `}
`;
