import dayjs from "dayjs";
import { VISIT_STATUS } from "./constants";

export const visitHasFinished = (endDate) => {
  const date = dayjs(endDate);
  return date.isValid() && dayjs().isAfter(date);
};

export const visitHasNotStartedYet = (startDate) => {
  const date = dayjs(startDate);
  return date.isValid() && dayjs().isBefore(date);
};

export const getVisitScheduleStatus = (startDate, endDate) => {
  if (visitHasNotStartedYet(startDate)) {
    return VISIT_STATUS.NOT_STARTED;
  }

  if (visitHasFinished(endDate)) {
    return VISIT_STATUS.TIMED_OUT;
  }

  return VISIT_STATUS.ON_GOING;
};
