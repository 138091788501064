import { connect } from "react-redux";
import {
  getLoadedStatus,
  getContactEmail,
  getError,
  getBankAccountLastDigits,
} from "datamodel/Payouts/selectors";
import { getCreditCardLastDigits } from "app/datamodel/Payment/selectors";
import { getUserConfirmation } from "app/datamodel/User/selectors";

import PayoutsActions from "datamodel/Payouts/actions";
import { getUserRole } from "datamodel/User/selectors";
import { isLister } from "datamodel/User/utils";

import PaymentSetupProfile from "./PaymentSetupProfile";

const mapStateToProps = (state) => ({
  loaded: getLoadedStatus(state),
  verified:
    (isLister(getUserRole(state))
      ? !!getBankAccountLastDigits(state)
      : !!getCreditCardLastDigits(state)) &&
    getUserConfirmation(state, "email"),
  error: getError(state),
  email: getContactEmail(state),
  isLister: isLister(getUserRole(state)),
});

export default connect(mapStateToProps, {
  getAccount: PayoutsActions.getAccount,
})(PaymentSetupProfile);
