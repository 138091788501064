import React from "react";
import classNames from "classnames";

import "./TitleExt";

const LandingTitle = ({ className, text }) => (
  <div className={classNames("LandingTitle", className)}>
    <h1>{text}</h1>
  </div>
);

LandingTitle.defaultProps = {
  className: null,
};

export default LandingTitle;
