import { createSelector } from "reselect";
import { swipingProfileCardsSelector } from "datamodel/Features/selectors";
import { getIsUserInListerMode } from "datamodel/User/selectors";
import { isMobileSelector } from "datamodel/Device/selectors";

const negate =
  (fn) =>
  (...args) =>
    !fn(...args);
const isNotMobileSelector = negate(isMobileSelector);

export const isSwipingInboxExperienceSelector = createSelector(
  [swipingProfileCardsSelector, getIsUserInListerMode, isNotMobileSelector],
  (...args) => args.every(Boolean),
);
