import React from "react";
import { Flex, Spacer } from "@badi/badi-components";
import Arrow from "components/Icons/Arrow";
import { ActionsWrapper, Button, Li } from "./styled-components";

const MobileMenuItem = ({ selected, renderHeader, actionsInMenu, onClick }) => (
  <Li>
    <Button onClick={onClick}>
      <Flex justify="spaceBetween">
        {renderHeader()}
        <Arrow direction={selected ? "up" : "down"} type="caret" />
      </Flex>
    </Button>
    <ActionsWrapper selected={selected}>
      <Spacer bottom={1} left={2} right={2} top={1}>
        <Flex direction="column" gutter={2}>
          {actionsInMenu}
        </Flex>
      </Spacer>
    </ActionsWrapper>
  </Li>
);

export default MobileMenuItem;
