import React, { memo } from "react";
import { BADI_COLORS, Heading } from "@badi/badi-components";
import { MarkerButton } from "./styled-components";

const Marker = ({ selected, id, onClick, children, dataQa = "map-marker" }) => {
  const handleMarkerClick = () => {
    onClick(id);
  };

  return (
    <MarkerButton
      data-qa={dataQa}
      onClick={handleMarkerClick}
      selected={selected}
      type="button"
    >
      <Heading
        color={
          selected
            ? BADI_COLORS.NEUTRAL.NEUTRAL_00
            : BADI_COLORS.NEUTRAL.NEUTRAL_80
        }
        level={4}
      >
        {children}
      </Heading>
    </MarkerButton>
  );
};

export default memo(Marker);
