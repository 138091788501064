import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";

const upload = (token, params) => {
  const { BADI_UPLOAD_PICTURE_SERVICE } = envVars();
  const serviceUrl = getServerUrl(BADI_UPLOAD_PICTURE_SERVICE);

  return fetch(serviceUrl, {
    method: "POST",
    params,
    token,
    requiredParams: true,
  }).then((res) => res);
};

export { upload };
