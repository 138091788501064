import { createSelector } from "reselect";

const getReferrals = (state) => state.Referrals;

export const getPendingReferralsSelector = createSelector(
  getReferrals,
  (referrals) => referrals.pendingReferrals,
);

export const getReferralAccountSelector = createSelector(
  getReferrals,
  (referrals) => referrals.referralAccount,
);

export const getReferralProductsSelector = createSelector(
  getReferrals,
  (referrals) => referrals.referralProducts,
);
