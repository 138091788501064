import { connect } from "react-redux";

import Photos from "Sections/Room/components/Steps/Photos";

import Actions from "datamodel/RoomList/actions";
import {
  getRoomIdSelector,
  getRoomPhotosSelector,
  getRoomLocationSelector,
} from "datamodel/RoomList/selectors";
import { getProfessionalPhotosSelector } from "datamodel/Features/selectors";

const mapStateToProps = (state) => ({
  roomId: getRoomIdSelector(state),
  storePhotos: getRoomPhotosSelector(state),
  isProPhotosEnabled: getProfessionalPhotosSelector(state),
  city: getRoomLocationSelector(state).city,
});

const mapDispatchToProps = {
  setRoomPhotos: Actions.setRoomPhotos,
};

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
