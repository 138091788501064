import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Spacer } from "@badi/badi-components";

import Layer from "components/Layout/Layer/Layer";
import DropZone from "components/DropZone/DropZoneWithFormsy";

const Pictures = ({ isValid, pictures = [] }) => (
  <Layer className="Profile__form--pictures-wrapper">
    <Container>
      <Row>
        <Col lg={{ size: 6, offset: 6 }} md={12}>
          <Spacer bottom={5} lg={{ top: 0, bottom: 0 }} top={5}>
            <DropZone
              className="Profile__form--pictures"
              maxFiles={6}
              name="pictures"
              required={true}
              requiredMessage="You need to add one picture of yourself"
              sectionName={"user"}
              validations={{
                areUploaded: true,
                minLength: 1,
              }}
              value={pictures}
              warning={!isValid}
            />
          </Spacer>
        </Col>
      </Row>
    </Container>
  </Layer>
);

export default Pictures;
