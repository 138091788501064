import React from "react";
import { withFormsy } from "formsy-react";
import classNames from "classnames";
import RadioButton from "./RadioButton";

class RadioButtonGroupWithFormsy extends React.PureComponent {
  onChange = (e) => {
    const { value } = e.target;
    const parsedValue = parseInt(value, 10);

    this.props.setValue(isNaN(parsedValue) ? value : parsedValue);
  };

  render() {
    const { className, getValue, items, layout, name, dataQa } = this.props;
    const safeValue = getValue() || "";

    return (
      <div className={classNames("Form__RadioButtonGroup--wrapper", className)}>
        {items.map((item, i) => (
          <RadioButton
            checked={item.value === safeValue}
            dataQa={dataQa}
            description={item.description}
            id={`${name}-${i}`}
            imageUrl={item.imageUrl}
            key={item.value}
            label={item.title}
            layout={layout}
            name={name}
            onChange={this.onChange}
            value={item.value}
          >
            {item.children}
          </RadioButton>
        ))}
      </div>
    );
  }
}

RadioButtonGroupWithFormsy.defaultProps = {
  className: null,
  dataQa: "",
  layout: undefined,
};

export default withFormsy(RadioButtonGroupWithFormsy);
