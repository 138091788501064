import Polyglot from "node-polyglot";
import dayjs from "dayjs";
import { isDevelopmentEnvironment } from "base/shared/Env/env";

import { isoCodes } from "./utils";
import {
  languages,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_ISO,
  DEFAULT_GOOGLE_MAPS_LANGUAGE_CODE,
} from "./constants";

let instance;

export const locale = () => (instance ? instance.currentLocale : null);

export const localeISO = (localeValue = locale()) =>
  localeValue ? isoCodes[localeValue] : null;

const localesToGoogleMapsLanguageCodes = languages.reduce((sum, lang) => {
  return { [lang.locale]: lang.googleMapsLanguageCode, ...sum };
}, {});

export const getGoogleMapsLanguageCode = (localeValue = locale()) => {
  return (
    localesToGoogleMapsLanguageCodes[localeValue] ||
    DEFAULT_GOOGLE_MAPS_LANGUAGE_CODE
  );
};

export const loadStrings = (currentLocale) => {
  const langLocale = localeISO(currentLocale) || DEFAULT_LOCALE_ISO;

  const strings = require(`../../locale/${langLocale}.json`);

  return strings;
};

const defaultPhrases = loadStrings();
const defaultTranslations = new Polyglot({
  locale: DEFAULT_LOCALE,
  phrases: defaultPhrases,
  allowMissing: true,
  onMissingKey: (key) => {
    if (isDevelopmentEnvironment && key) {
      console.debug(`Missing translation key: ${key}`);
    }
    return key;
  },
});

const parseLocaleToDayJs = (currentLocale) => {
  switch (currentLocale) {
    case "en":
      return "en-gb";
    case "us":
      return "en";
    default:
      return currentLocale;
  }
};

const loadLocaleForDates = (currentLocale) => {
  const safeLocale = parseLocaleToDayJs(currentLocale);

  import(`dayjs/locale/${safeLocale}.js`).then((dayjsLocale) => {
    dayjs.locale(currentLocale, dayjsLocale);
  });
};

export const setLocalization = (currentLocale, phrases) => {
  if (instance && instance.currentLocale === currentLocale) return;

  instance = new Polyglot({
    locale: currentLocale,
    phrases,
  });

  loadLocaleForDates(currentLocale);
};

const translateText = (...props) => {
  if (!instance) return "";

  if (!instance.has(props[0])) {
    return defaultTranslations.t(...props);
  }

  return instance.t(...props);
};

const translateArray = (array, field = "title") =>
  array.map((elem) => {
    if (typeof elem === "string") return translateText(elem);

    let element = { ...elem };

    if (typeof field === "string") {
      element[field] = translateText(element[field]);
    } else if (Array.isArray(field)) {
      element = field.reduce((acc, key) => {
        acc[key] = translateText(acc[key]);
        return acc;
      }, element);
    }

    return element;
  });

export const translate = (...props) => {
  if (!props || props.length === 0) return "";

  return Array.isArray(props[0])
    ? translateArray(props[0], props[1])
    : translateText(...props);
};

export const getLocaleDisplayName = () => {
  const localeFound = languages.find(
    (language) => language.locale === instance.currentLocale,
  );
  return localeFound ? localeFound.displaName : undefined;
};

export const getLocalesCode = () => languages.map((lang) => lang.locale);
