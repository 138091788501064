import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const CloseIconWrapper = styled.button`
  width: auto;
  padding: 0;
  background: transparent;
  border: 0;
  position: absolute;
  right: ${unitizedPx(2)};
  top: ${unitizedPx(2)};
`;

export const Root = styled.div`
  background-color: ${BADI_COLORS.BRAND.TERTIARY.HOVER};
  padding: ${unitizedPx(2)};
  position: fixed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  width: 100%;
  z-index: 999;
`;
