import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Flex, Heading } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import AppLinks from "components/app-links";
import { Wrapper } from "./styled-components";

const DownloadApp = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <Flex direction="column" gutter={2}>
              <Heading data-qa="download-app-title" level="20-Bold">
                {translate("home.download_app.title")}
              </Heading>
              <AppLinks campaign="home" />
            </Flex>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default DownloadApp;
