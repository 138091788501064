import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";

import {
  Spacer,
  Text,
  Flex,
  InlineMessage,
  MESSAGE_TYPES,
  Checkbox,
} from "@badi/badi-components";

import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import { getMoveInDate, getMoveOutDate } from "datamodel/booking/api";
import { getOtherUserRole } from "datamodel/User/utils";
import DatePicker from "components/Form/DatePicker";
import RoomPreview from "components/room-preview";
import { DEFAULT_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";
import { fetchUser } from "datamodel/User/api";

import BookingStepLayout from "../step-layout";
import { FormWrapper } from "./styled-components";
import MoveOutPopover from "./move-out-popover";

import {
  DATE_FORMAT,
  DATE_DISPLAY_FORMAT,
  STATUS,
  STATUS_MESSAGES,
  INPUT_PLACEHOLDER,
} from "./constants";
import { isLongStaySelected } from "./utils";

const MoveInOutStep = ({
  id,
  moveOutRequired,
  connectionId,
  onValidation = () => {},
  user,
  token,
  moveIn: initialMoveIn,
  moveOut: initialMoveOut,
  room = null,
  roomId = 0,
  title = "",
  userToInviteId = 0,
}) => {
  const [moveInRange, setMoveInRange] = useState({});
  const [moveOutRange, setMoveOutRange] = useState({});
  const [statusType, setStatus] = useState(STATUS.INITIAL);
  const [otherUser, setOtherUser] = useState(user);
  const [isAnyMoveOut, setIsAnyMoveOut] = useState(
    initialMoveIn && !initialMoveOut,
  );

  const userRole = otherUser
    ? getOtherUserRole(otherUser.role)
    : DEFAULT_USER_ROLE;

  const [moveIn, setMoveIn] = useState(initialMoveIn);
  const [moveOut, setMoveOut] = useState(initialMoveOut);
  const [showMoveOutPopover, setShowMoveOutPopover] = useState(false);
  const [popoverClosed, setPopoverClosed] = useState(false);
  useEffect(() => {
    if (userToInviteId) {
      fetchUser(userToInviteId, token)
        .then((userToInvite) =>
          setOtherUser({
            ...userToInvite,
            avatar: userToInvite.picture,
            role: SEEKER_USER_ROLE,
          }),
        )
        .catch((error) =>
          UserFeedback.exception(
            "error.invitetobook_notpossible_title",
            "error.invitetobook_notpossible_text",
            error,
          ),
        );
    }

    getMoveInDate({ token, connectionId, roomId }).then(({ data }) => {
      const {
        is_available: isAvailable,
        is_accurate: isAccurate,
        move_in: moveInRangeFromApi,
      } = data;

      if (!isAvailable) {
        setStatus(STATUS.ERROR);
        return;
      }

      if (isAccurate) {
        setStatus(STATUS.OK);
      } else {
        setStatus(STATUS.INACCURATE);
      }

      if (moveInRangeFromApi) {
        const { min_date: minDate, max_date: maxDate } = moveInRangeFromApi;

        setMoveInRange({
          minDate,
          maxDate,
        });
      }
    });
  }, [connectionId, roomId, userToInviteId]);

  useEffect(() => {
    if (userToInviteId) {
      return;
    }
    setOtherUser(user);
  }, [user, userToInviteId]);

  useEffect(() => {
    if (!moveIn) return;

    getMoveOutDate({
      token,
      moveIn,
      roomId,
      connectionId,
    }).then(({ data }) => {
      if (!data.move_out) return;

      const {
        min_date: minDate,
        max_date: maxDate,
        min_months: minMonths,
        max_months: maxMonths,
      } = data.move_out;

      setMoveOutRange({
        minDate,
        maxDate,
        minMonths,
        maxMonths,
      });
    });
  }, [moveIn]);

  // runs as either `moveIn` or `moveOut` changed and call the validation callback
  useEffect(() => {
    if (moveOut && !popoverClosed) {
      const isLongStay = isLongStaySelected({ moveIn, moveOut });

      if (isLongStay) {
        setShowMoveOutPopover(true);
      } else {
        setShowMoveOutPopover(false);
      }
    }

    onValidation({
      id,
      valid: !!(isAnyMoveOut || !moveOutRequired ? moveIn : moveIn && moveOut),
      payload: {
        moveIn,
        moveOut: moveOut || "",
      },
    });
  }, [moveIn, moveOut, isAnyMoveOut]);

  // from here the memoized callbacks
  const handleMoveInDateChange = (moveInSelected) => {
    const date = moveInSelected ? moveInSelected.format(DATE_FORMAT) : null;
    setMoveIn(date);
    setMoveOut(null);
    setShowMoveOutPopover(false);
  };

  const handleMoveOutDateChange = (moveOutSelected) => {
    const date = moveOutSelected.format(DATE_FORMAT);
    setMoveOut(date);
  };

  const isMoveInOutsideRange = useCallback(
    (day) => !day.isBetween(moveInRange.minDate, moveInRange.maxDate),
    [moveInRange],
  );

  const isMoveOutOutsideRange = useCallback(
    (day) =>
      !day.isBetween(moveOutRange.minDate, moveOutRange.maxDate, "day", "[]"),
    [moveOutRange],
  );

  const initialMoveInVisibleMonth = useCallback(
    () => (moveIn ? moment(moveIn) : moment(moveInRange.minDate)),
    [moveInRange, moveIn],
  );

  const initialMoveOutVisibleMonth = useCallback(
    () => (moveOut ? moment(moveOut) : moment(moveOutRange.minDate)),
    [moveOutRange, moveOut],
  );

  const handleMoveOutClose = () => {
    if (showMoveOutPopover) {
      setShowMoveOutPopover(false);
      setPopoverClosed(true);
    }
  };

  const handleAnyMoveout = () => {
    setIsAnyMoveOut(!isAnyMoveOut);
    setMoveOut(null);
  };

  const sidebar = [];

  if (otherUser && otherUser.id && room) {
    sidebar.push(
      <Spacer top={2}>
        <RoomPreview
          key="booking-room-preview"
          room={room}
          showRoomDetails={false}
          token={token}
          user={otherUser}
        />
      </Spacer>,
    );
  }

  return (
    <BookingStepLayout
      hideBadiGuaranteeCard={true}
      sidebar={sidebar}
      sidebarTitle={translate("booking.new.request_details_title")}
      subtitle={translate("booking.new.steps.moveinout.sub-heading")}
      title={translate(title)}
      userRole={userRole}
    >
      <FormWrapper>
        <Flex direction="column" gutter={3}>
          <label htmlFor="move-in">
            <Spacer bottom={2}>
              <Text body={2}>{translate("booking.new.movein_label")}</Text>
            </Spacer>
            <DatePicker
              displayFormat={DATE_DISPLAY_FORMAT}
              id="move-in"
              initialVisibleMonth={initialMoveInVisibleMonth}
              isOutsideRange={isMoveInOutsideRange}
              name="move-in"
              onChange={handleMoveInDateChange}
              placeholder={moveIn || INPUT_PLACEHOLDER[statusType]}
              value={moveIn && moment(moveIn)}
            />
          </label>
          {statusType === STATUS.INACCURATE && (
            <InlineMessage
              data-qa="tip-card-warning-status"
              variant={MESSAGE_TYPES.WARNING}
            >
              <Text body={3}>
                {translate(STATUS_MESSAGES[STATUS.INACCURATE])}
              </Text>
            </InlineMessage>
          )}
          <label htmlFor="move-out">
            <Spacer bottom={2}>
              <Text body={2}>
                {translate(
                  `booking.new.moveout_label.${
                    moveOutRequired ? "required" : "optional"
                  }`,
                )}
              </Text>
            </Spacer>
            <Spacer bottom={2}>
              <Checkbox
                checked={isAnyMoveOut}
                data-qa="any-move-out-checkbox"
                id="any-move-out"
                name="any-move-out"
                onChange={handleAnyMoveout}
              >
                <Text body="12-Medium">
                  {translate("booking.new.any_moveout_checkbox_label")}
                </Text>
              </Checkbox>
            </Spacer>
            <DatePicker
              disabled={!moveIn || isAnyMoveOut}
              displayFormat={DATE_DISPLAY_FORMAT}
              id="move-out"
              initialVisibleMonth={initialMoveOutVisibleMonth}
              isOutsideRange={isMoveOutOutsideRange}
              name="move-out"
              onChange={handleMoveOutDateChange}
              onClose={handleMoveOutClose}
              placeholder={moveOut || INPUT_PLACEHOLDER[statusType]}
              value={moveOut && moment(moveOut)}
            />
            {showMoveOutPopover && !popoverClosed && <MoveOutPopover />}
          </label>
          {statusType === STATUS.ERROR && (
            <InlineMessage
              data-qa="tip-card-request-status"
              variant={MESSAGE_TYPES.ERROR}
            >
              <Text body={3}>{translate(STATUS_MESSAGES[STATUS.ERROR])}</Text>
            </InlineMessage>
          )}
        </Flex>
      </FormWrapper>
    </BookingStepLayout>
  );
};

export default MoveInOutStep;
