// Middleware que genera las 3 acciones de llamadas asincronas
// Any onSuccess callback will receive 3 parameters =>
// 1) default action to dispatch, 2) dispatch function, 3) getState function

import {
  getRefreshToken,
  getToken,
  isLoggedUser,
} from "datamodel/Auth/selectors";

export default function requestMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    const { request, types, onSuccess, params, requireAuth, ...rest } = action;

    if (!request) {
      return next(action);
    }

    const [REQUEST, SUCCESS, ERROR] = types;
    const handleSuccessAsyncResponse = (result) =>
      onSuccess
        ? onSuccess(
            { ...rest, result, type: SUCCESS },
            dispatch,
            next,
            getState,
          )
        : next({ ...rest, result, type: SUCCESS });
    const handleErrorAsyncResponse = (error) =>
      next({ ...rest, error, type: ERROR });

    next({ ...rest, type: REQUEST });

    if (requireAuth) {
      const state = getState();
      const token = getToken(state);
      const refreshToken = getRefreshToken(state);
      const loggedUser = isLoggedUser(state);

      return request(
        { isLoggedUser: loggedUser, refreshToken, token },
        params,
      ).then(handleSuccessAsyncResponse, handleErrorAsyncResponse);
    }
    return request.then(handleSuccessAsyncResponse, handleErrorAsyncResponse);
  };
}
