import React from "react";
import { withRouter } from "react-router";
import PremiumBanner from "./premium-banner";
import { getGoldUpgradePath } from "../routes";

const PremiumBannerRoute = ({ router }) => {
  const redirectToBadiGold = () => {
    router.push(getGoldUpgradePath());
  };

  return <PremiumBanner onBadiGoldClick={redirectToBadiGold} />;
};

export default withRouter(PremiumBannerRoute);
