import React from "react";
import { Flex, Heading, Button, Spacer } from "@badi/badi-components";
import { Container, Row, Col } from "reactstrap";
import ReviewItem from "components/review-item/review-item";
import { openReviewsSummaryModal } from "components/reviews-summary-modal";
import { REVIEW_MODAL_TYPE } from "components/reviews-summary-modal/constants";
import { getReviewsSummaryHeaderText } from "components/reviews-summary-modal/utils";
import { translate } from "base/shared/Localization";

const ReviewsSection = ({ reviews, roomId, total }) => {
  const buttonVisible = total > reviews.length;

  return (
    <Flex direction="column" gutter={2}>
      <Heading
        data-qa="room-reviews-section-header"
        level="16-Bold"
      >{`${total} ${getReviewsSummaryHeaderText(total)}`}</Heading>
      <Container>
        <Row>
          {reviews.map(({ id, text, collectedAt, reviewer }) => (
            <Col key={id} lg={6} md={12} xs={12}>
              <ReviewItem
                collectedAt={collectedAt}
                reviewer={reviewer}
                text={text}
              />
            </Col>
          ))}
        </Row>
      </Container>

      {buttonVisible && (
        <Spacer top={1}>
          <Button
            dataQa="room-details-reviews-section-button"
            onClick={() =>
              openReviewsSummaryModal(roomId, REVIEW_MODAL_TYPE.ROOM)
            }
            variant="secondary"
          >
            {translate("room.detail.reviews.cta")}
          </Button>
        </Spacer>
      )}
    </Flex>
  );
};

export default ReviewsSection;
