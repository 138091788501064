import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const GridScroll = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const CityGrid = styled.article`
  position: relative;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  row-gap: ${unitizedPx(2)};
  min-width: ${unitizedPx(90)};
  width: 100%;

  ${mediaquery.md`  
  row-gap: ${unitizedPx(3)};
  `}
`;

export const CityCard = styled.div`
  display: inline-block;
  padding-right: ${unitizedPx(1)};
  a:hover {
    text-decoration: none;
  }
`;

export const StyledImage = styled.img`
  height: ${unitizedPx(8.25)};
  width: ${unitizedPx(8.25)};
  object-fit: cover;
  position: relative;
  border-radius: 5px;
  display: block;
`;
