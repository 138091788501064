import React, { PureComponent } from "react";
import { Container, Row, Col } from "reactstrap";
import Formsy from "formsy-react";
import { Flex, Button, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import { OCCUPATIONS_ROOMMATE } from "base/shared/StaticData";

import FormBlock from "components/form-block";
import RadioButtonGroup from "components/Form/RadioButton/RadioButtonGroupWithFormsy";
import Slider from "components/Form/Slider/SliderWithFormsy";

import { parseAgesToObject } from "../../../utils";
import ButtonsWrapper from "../../buttons-wrapper";
import DecorativeRightImage from "../../decorative-right-image";
import GenderPreferences from "./gender-preferences";

class RoomListFlatmate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      occupation: 3,
      ages: [18, 99],
      isValid: false,
      male: true,
      female: true,
      nonBinary: true,
    };

    this.occupationItems = translate(OCCUPATIONS_ROOMMATE).map(
      (occupation) => ({
        ...occupation,
        label: occupation.title,
      }),
    );
  }

  UNSAFE_componentWillMount() {
    this.propsToState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.propsToState(nextProps);
  }

  onChange = (formData) => {
    this.setState(formData);
  };

  getFormData = () => {
    const { occupation, ages, male, female, nonBinary } = this.state;
    const parsedAges = parseAgesToObject(ages);
    const genders = { male, female, nonBinary };

    return {
      genders,
      occupation,
      ...parsedAges,
    };
  };

  disableSubmit = () => {
    this.setState({ isValid: false });
  };

  enableSubmit = () => {
    this.setState({ isValid: true });
  };

  handleGoPreviousStep = () => {
    const { previousStep, setRoomFlatmate } = this.props;
    const payload = this.getFormData();

    setRoomFlatmate(payload);
    previousStep(payload);
  };

  handleSubmit = () => {
    const { nextStep, onFinish, setRoomFlatmate } = this.props;

    const payload = this.getFormData();

    setRoomFlatmate(payload, true);

    if (nextStep) {
      nextStep(payload);
      return;
    }

    // this is an antipattern but because of the current implementation it's necessary
    // if this is the last step trigger onFinish
    if (onFinish) {
      onFinish(payload);
    }
  };

  propsToState = ({ storeFlatmate }) => {
    const flatmateHasInfo = !!Object.keys(storeFlatmate).length;

    if (flatmateHasInfo) {
      const {
        min_age: minAge,
        max_age: maxAge,
        genders,
        occupation,
      } = storeFlatmate;

      const { male, female, nonBinary } = genders;

      this.setState({
        ...this.state,
        ages: [minAge, maxAge],
        male,
        female,
        nonBinary,
        occupation,
      });
    }
  };

  getFormValidation = () => {
    const { isValid, male, female, nonBinary } = this.state;
    const { fetchingCreateRoom } = this.props;
    return !isValid || fetchingCreateRoom || !(male || female || nonBinary);
  };

  render() {
    const { ages, male, female, nonBinary, occupation } = this.state;
    const { nextStep, submitBtnText } = this.props;
    const isButtonDisabled = this.getFormValidation();

    return (
      <Spacer bottom={12} top={4}>
        <Container>
          <Row>
            <Col className="RoomList__form" lg={{ size: 6 }} xs={{ size: 12 }}>
              <Formsy
                onChange={this.onChange}
                onInvalid={this.disableSubmit}
                onSubmit={this.handleSubmit}
                onValid={this.enableSubmit}
              >
                <Row>
                  <Col lg={{ size: 10, offset: 1 }} md={12}>
                    <Flex direction="column" gutter={5}>
                      <GenderPreferences
                        female={female}
                        male={male}
                        nonBinary={nonBinary}
                      />

                      <FormBlock title={translate("room.list.step5.age.title")}>
                        <Slider
                          max={99}
                          min={18}
                          name="ages"
                          required={true}
                          value={ages}
                        />
                      </FormBlock>

                      <FormBlock
                        title={translate("room.list.step5.occupation.title")}
                      >
                        <RadioButtonGroup
                          items={this.occupationItems}
                          name="occupation"
                          required={true}
                          value={occupation}
                        />
                      </FormBlock>
                    </Flex>
                  </Col>
                </Row>
                <ButtonsWrapper>
                  <Flex gutter={2}>
                    <Button
                      basis="50%"
                      fullWidth={true}
                      name="back"
                      onClick={this.handleGoPreviousStep}
                      variant="secondary"
                    >
                      {translate("Back")}
                    </Button>
                    <Button
                      basis="50%"
                      disabled={isButtonDisabled}
                      fullWidth={true}
                      name="next"
                      type="submit"
                    >
                      {nextStep ? translate("Continue") : submitBtnText}
                    </Button>
                  </Flex>
                </ButtonsWrapper>
              </Formsy>
            </Col>
            <DecorativeRightImage
              alt="Room decorative image"
              src="/assets/images/roomlist-flatmate.svg"
            />
          </Row>
        </Container>
      </Spacer>
    );
  }
}

RoomListFlatmate.defaultProps = {
  nextStep: undefined,
  submitBtnText: "",
};

export default RoomListFlatmate;
