import React from "react";
import { Container } from "reactstrap";
import { Heading, Text, Spacer, Flex } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPlaceType } from "datamodel/SearchFilters/utils";

import FeaturedSearch from "./featured-search";
import { GridScroll, StyledFlex } from "./styled-components";

const FeaturedSearches = ({
  description,
  setParams,
  title,
  resources,
  dataQa,
}) => {
  const handleSearch = ({ label, placeTypes: selectedType }) => {
    const placeTypes = getPlaceType();

    const index = placeTypes.findIndex(({ id }) => id === selectedType);
    placeTypes[index] = { ...placeTypes[index], selected: true };

    setParams({
      label,
      placeTypes,
    });
  };
  return (
    <Container>
      <Spacer bottom={4} top={4}>
        <Heading level="24-Bold">{translate(title)}</Heading>
        <Spacer bottom={0.5} />
        <Text>{translate(description)}</Text>
      </Spacer>
      <GridScroll data-qa={dataQa}>
        <StyledFlex
          alignItems="flexStart"
          as={Flex}
          data-qa={"links-wrap"}
          gutter={2}
          justify="spaceBetween"
          wrap="nowrap"
        >
          {resources.map((search) => (
            <FeaturedSearch
              {...search}
              handleSearch={handleSearch}
              key={search.id}
            />
          ))}
        </StyledFlex>
      </GridScroll>
      <Spacer bottom={2} />
    </Container>
  );
};

export default FeaturedSearches;
