import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  position: relative;
  height: ${unitizedPx(108)};
  width: 100%;

  ${mediaquery.lg`
    height: ${unitizedPx(63)};
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  `};
`;

export const Background = styled.div`
  position: absolute;
  background-image: ${({ language = "en", visibleBackground }) =>
    visibleBackground
      ? `url('${getCDNUrl()}/assets/images/home/find-your-match_${language.toUpperCase()}.png')`
      : "none"};
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  width: 100%;
  height: 75%;

  ${mediaquery.lg`
    height: 100%;
    background-size: 75%;
    background-position-y: 22%;
    background-position-x: initial;
  `};
`;

export const Content = styled.div`
  position: relative;
  margin-top: auto;
  padding: ${unitizedPx(6)} ${unitizedPx(0.625)};
`;

export const TextWrapper = styled.div`
  ${mediaquery.lg`
    max-width: ${unitizedPx(34)};
  `};
`;
