import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import FeaturedSearches from "./featured-searches";

const mapDispatchToProps = (dispatch) => ({
  setParams: (searchFilters) =>
    dispatch(
      Actions.setParams({
        searchFilters: { ...searchFilters, updatedFilters: true },
      }),
    ),
});

export default connect(null, mapDispatchToProps)(FeaturedSearches);
