import { translate } from "base/shared/Localization";
import { CATEGORIES } from "./constants";

export const shouldShowItemForLocale = (supportedLocales, currentLocale) => {
  if (!supportedLocales) return true;

  return supportedLocales.some((lang) => lang === currentLocale);
};

export const getCategories = (currentLocale) => {
  return CATEGORIES(translate).filter(({ locale = null }) =>
    shouldShowItemForLocale(locale, currentLocale),
  );
};
