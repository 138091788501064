import { createSelector } from "reselect";

export const getRecommendations = (state) => state.Recommendations;

export const getRecommendationsList = createSelector(
  getRecommendations,
  (recommendations) => recommendations.list,
);

export const isFetchingRecommendations = createSelector(
  getRecommendations,
  (recommendations) => recommendations.fetching,
);

export const hadFetchedRecommendations = createSelector(
  getRecommendations,
  (recommendations) => recommendations.fetched,
);
