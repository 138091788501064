import { SETTINGS_MENU } from "base/shared/StaticData";
import { getMenuItems } from "base/shared/Utils";
import { USER_LEGAL_CONSENT } from "datamodel/User/constants";

const EMAIL_NOTIFICATIONS = [
  {
    label: "settings.notifications.section.email.digest.label",
    type: "email_digest",
  },
  {
    label: "settings.notifications.section.email.transactional.label",
    type: "email_transactional",
  },
];

const PUSH_NOTIFICATIONS = [
  {
    label: "settings.notifications.section.push.chat.label",
    type: "chat_messages_pushes",
  },
  {
    label: "settings.notifications.section.push.request.label",
    type: "request_pushes",
  },
  {
    label: "settings.notifications.section.push.match.label",
    type: "match_pushes",
  },
  {
    label: "settings.notifications.section.push.my_room.label",
    type: "my_room_pushes",
  },
  {
    label: "settings.notifications.section.push.booking.label",
    type: "booking_pushes",
  },
];

const EMAIL_AND_PUSH_NOTIFICATIONS = [
  {
    label: "settings.privacy.privacypolicy_promotionalcom",
    type: USER_LEGAL_CONSENT.MARKETING,
  },
];

const getSettingsMenuItems = (hideChangePassword, id) => {
  const menuItems = hideChangePassword
    ? SETTINGS_MENU.filter((item) => item.id !== "change-password")
    : SETTINGS_MENU;

  return getMenuItems(menuItems, id);
};

export {
  getSettingsMenuItems,
  EMAIL_NOTIFICATIONS,
  PUSH_NOTIFICATIONS,
  EMAIL_AND_PUSH_NOTIFICATIONS,
};
