import React, { useContext } from "react";
import {
  BREAKPOINTS_PX,
  BUTTON_TYPES,
  Flex,
  Heading,
  BADI_COLORS,
  Spacer,
  Text,
  useMatchMedia,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { PLAN_TYPES } from "datamodel/premium/constants";
import InjectHtml from "components/inject-html";
import { PriceWrapper, Root } from "./styled-components";
import PlanButton from "../plan-button";
import ContactSales from "../contact-sales";
import PlanBenefits from "../plan-benefits";
import { getButtonsText, getPlanColors } from "./utils";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.md})`];

const PlanCard = ({
  benefits,
  category,
  contactSales,
  disclaimer,
  id,
  initiator,
  onBenefitShown = null,
  onClick,
  openRoomSelector,
  previousPlan = null,
  origin = null,
  priceDetails,
  productId,
  productReference,
  roomId,
  subTitle = null,
  title,
  type,
  upsellCategory = null,
}) => {
  const [isLargeScreen] = useMatchMedia(QUERIES);

  const handlePlanClick = (planId = id) => {
    openRoomSelector(planId);
  };

  const handleBenefitShown = (benefit) => {
    if (!onBenefitShown) {
      return;
    }
    onBenefitShown(category, benefit);
  };

  const { main, secondary = null } = getButtonsText(category);
  const colors = getPlanColors(category);

  return (
    <Flex direction="column" fullHeight={true} gutter={1}>
      <Root colors={colors} data-qa="plan-card" grow={1}>
        <Flex direction="column" gutter={1}>
          <Heading
            color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
            data-qa="plan-title"
            level={isLargeScreen ? "28-Extrabold" : "24-Bold"}
          >
            {translate(title)}
          </Heading>
          {!!subTitle && (
            <Text
              color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
              data-qa="plan-desc"
            >
              {translate(subTitle)}
            </Text>
          )}
          <Spacer bottom={2} top={2}>
            <PriceWrapper>
              <Text
                align="center"
                color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
                dataQa="plan-prediscount-price"
                level="16-Regular"
              >
                <InjectHtml>{priceDetails.preDiscountValue}</InjectHtml>
              </Text>
              <Heading
                align="center"
                color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
                data-qa="plan-price"
                level="28-Extrabold"
              >
                {priceDetails.purchaseValue}
              </Heading>
              <Text
                align="center"
                body="10-Medium"
                color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
                dataQa="plan-vat"
              >
                {priceDetails.vat}
              </Text>
            </PriceWrapper>
          </Spacer>
          <Spacer bottom={2} md={{ bottom: 5 }}>
            <PlanButton
              category={category}
              initiator={initiator}
              onClick={onClick}
              openRoomSelector={handlePlanClick}
              origin={origin}
              planId={id}
              productId={productId}
              productReference={productReference}
              roomId={roomId}
              type={type}
              upsellCategory={upsellCategory}
            >
              {translate(main)}
            </PlanButton>
            {secondary && previousPlan && (
              <Spacer top={1}>
                <PlanButton
                  category={previousPlan.category}
                  dataQa="plan-secondary-button"
                  initiator={initiator}
                  onClick={onClick}
                  openRoomSelector={handlePlanClick}
                  origin={origin}
                  planId={previousPlan.id}
                  productId={previousPlan.productId}
                  productReference={previousPlan.productReference}
                  roomId={roomId}
                  type={previousPlan.type}
                  variant={BUTTON_TYPES.SECONDARY}
                >
                  {translate(secondary)}
                </PlanButton>
              </Spacer>
            )}
            {contactSales && (
              <Spacer top={1}>
                <ContactSales />
              </Spacer>
            )}
          </Spacer>
          <PlanBenefits
            benefits={benefits.data}
            onBenefitShown={handleBenefitShown}
          />
        </Flex>
      </Root>
      <Text
        align="center"
        body="12-Medium"
        color={BADI_COLORS.BRAND.TERTIARY.DEFAULT}
        dataQa="plan-disclaimer"
      >
        {disclaimer}
      </Text>
    </Flex>
  );
};

export default PlanCard;
