import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl } from "base/shared/Utils";
import {
  ListingFlowActionsModel,
  ListingMinimalCardModel,
  ModerationResultModel,
} from "./models";

const convertTemplatesToDictionary = (elements) =>
  elements.reduce((list, { country_code: code, fields }) => {
    return {
      ...list,
      [code.toUpperCase()]: fields,
    };
  }, {});

export const fetchCountryTemplate = (token) => {
  const { BADI_TEMPLATES_LIST_FLOW } = envVars();
  const serverUrl = getAPIUrl(BADI_TEMPLATES_LIST_FLOW);

  return fetch(serverUrl, {
    method: "GET",
    token,
  })
    .then((response) => {
      const { data } = response;
      return convertTemplatesToDictionary(data.templates);
    })
    .catch(() => ({}));
};

export const fetchListingFlowActions = (token) => {
  const { BADI_LISTING_FLOW_ACTIONS } = envVars();
  const serverUrl = getAPIUrl(BADI_LISTING_FLOW_ACTIONS);

  return fetch(serverUrl, {
    method: "GET",
    token,
  }).then(({ data }) => new ListingFlowActionsModel(data).toJSON());
};

export const fetchListingsToDuplicate = (token) => {
  const { BADI_LISTING_DUPLICATE } = envVars();
  const serverUrl = getAPIUrl(BADI_LISTING_DUPLICATE);

  return fetch(serverUrl, {
    method: "GET",
    params: { action_id: "duplicate_room" },
    token,
  }).then(({ data }) =>
    data.items.map((listing) => new ListingMinimalCardModel(listing).toJSON()),
  );
};

export const duplicateListing = ({ token, listingId }) => {
  const { BADI_LISTING_DUPLICATE_BY_ID } = envVars();
  const serverUrl = getAPIUrl(BADI_LISTING_DUPLICATE_BY_ID);

  return fetch(serverUrl, {
    method: "POST",
    params: { source_id: listingId },
    token,
  });
};

export const fetchRoomModerationResult = (token, id) => {
  const { BADI_ROOM_MODERATION_RESULT } = envVars();
  const serverUrl = getAPIUrl(BADI_ROOM_MODERATION_RESULT.replace("{id}", id));

  return fetch(serverUrl, {
    method: "GET",
    token,
  }).then(({ data }) => new ModerationResultModel(data).toJSON());
};
