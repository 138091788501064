import React, { PureComponent } from "react";
import { Container, Row, Col } from "reactstrap";
import Formsy from "formsy-react";
import {
  Flex,
  Button,
  Spacer,
  InlineMessage,
  MESSAGE_TYPES,
  Text,
} from "@badi/badi-components";
import PhotosVerification from "Sections/Room/components/photos-verification";
import { translate } from "base/shared/Localization";

import TextArea from "components/Form/TextArea/TextAreaWithFormsy";
import FormBlock from "components/form-block";
import DropZone from "components/DropZone/DropZoneWithFormsy";
import DecorativeRightImage from "../decorative-right-image";
import ButtonsWrapper from "../buttons-wrapper";
import { PHOTOS_VERIFICATION_CITIES } from "../photos-verification/constants";

class RoomListPhotos extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      canSubmit: false,
      description: "",
      pictures: [],
      photosVerification: false,
      title: "",
    };

    this.descriptionValidations = {
      maxLength: 3000,
      minLength: 80,
    };

    this.titleValidations = {
      maxLength: 55,
      minLength: 10,
    };
  }

  UNSAFE_componentWillMount() {
    this.propsToState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.propsToState(nextProps);
  }

  onChange = (formData) => {
    this.setState(formData, () => {
      const payload = this.getFormData();
      this.props.setRoomPhotos(payload);
    });
  };

  getFormData = () => {
    const { description, pictures, title, photosVerification } = this.state;

    return {
      description,
      pictures,
      title,
      photosVerification,
    };
  };

  handleGoPreviousStep = () => {
    const { previousStep, setRoomPhotos } = this.props;

    const payload = this.getFormData();

    setRoomPhotos(payload);
    previousStep(payload);
  };

  enableSubmit = () => {
    this.setState({ canSubmit: true });
  };

  disableSubmit = () => {
    this.setState({ canSubmit: false });
  };

  propsToState = ({ storePhotos }) => {
    const photosHasInfo = !!Object.keys(storePhotos).length;

    if (!photosHasInfo) return;

    const { description, pictures, title, photosVerification } = storePhotos;

    this.setState({
      description,
      pictures,
      title,
      photosVerification,
    });
  };

  handleSubmit = () => {
    const { nextStep, onFinish } = this.props;
    const payload = this.getFormData();

    if (nextStep) {
      nextStep(payload);
      return;
    }
    if (onFinish) {
      onFinish(payload);
      return;
    }
  };

  render() {
    const { canSubmit, description, pictures, photosVerification, title } =
      this.state;
    const { isProPhotosEnabled, city, isDuplicate, nextStep, submitBtnText } =
      this.props;

    const isProPhotosShown =
      PHOTOS_VERIFICATION_CITIES.includes(city) && isProPhotosEnabled;

    return (
      <>
        <Spacer bottom={14} top={4}>
          <Container>
            <Formsy
              onChange={this.onChange}
              onInvalid={this.disableSubmit}
              onSubmit={this.handleSubmit}
              onValid={this.enableSubmit}
            >
              <Row>
                <Col className="RoomList__form" lg={6} md={12}>
                  <Row className="RoomList__form--body">
                    <Col lg={{ size: 10, offset: 1 }} md={12}>
                      <Flex direction="column" gutter={5}>
                        {isDuplicate && (
                          <InlineMessage variant={MESSAGE_TYPES.WARNING}>
                            <Text body="14-Regular">
                              {translate("listing.duplicate.alert.photos")}
                            </Text>
                          </InlineMessage>
                        )}
                        <FormBlock>
                          <DropZone
                            className="RoomList__photos"
                            forceValidation={false}
                            maxFiles={50}
                            name="pictures"
                            required={true}
                            requiredMessage="You need to add one picture of yourself"
                            sectionName={"room"}
                            validationErrors={{
                              areUploaded: translate(
                                "Please wait until the image is uploaded",
                              ),
                              minLength: translate(
                                "You need to add one image of yourself",
                              ),
                            }}
                            validations={{
                              areUploaded: true,
                              minLength: 5,
                            }}
                            value={pictures}
                            warningMessage={
                              pictures.length < 5
                                ? translate("dropzone.alert.minimum", {
                                    min: 5,
                                  })
                                : null
                            }
                          />
                        </FormBlock>
                        {isProPhotosShown && (
                          <FormBlock>
                            <PhotosVerification
                              checked={photosVerification}
                              name="photosVerification"
                            />
                          </FormBlock>
                        )}
                        <FormBlock
                          title={`${translate(
                            "room.list.step4.title.title",
                          )} *`}
                        >
                          <TextArea
                            maxLength={55}
                            minLength={10}
                            name="title"
                            placeholder={translate(
                              "room.list.step4.title.placeholder",
                            )}
                            required={true}
                            validations={this.titleValidations}
                            value={title}
                          />
                        </FormBlock>
                        <FormBlock
                          title={`${translate(
                            "room.list.step4.description.title",
                          )} *`}
                        >
                          <TextArea
                            maxLength={this.descriptionValidations.maxLength}
                            minLength={this.descriptionValidations.minLength}
                            name="description"
                            placeholder={translate(
                              "room.list.step4.description.placeholder",
                            )}
                            required={true}
                            rows={16}
                            validations={this.descriptionValidations}
                            value={description}
                          />
                        </FormBlock>
                      </Flex>
                    </Col>
                  </Row>
                  <ButtonsWrapper>
                    <Flex gutter={2}>
                      <Button
                        basis="50%"
                        fullWidth={true}
                        name="back"
                        onClick={this.handleGoPreviousStep}
                        variant="secondary"
                      >
                        {translate("Back")}
                      </Button>
                      <Button
                        basis="50%"
                        disabled={!canSubmit}
                        fullWidth={true}
                        name="next"
                        type="submit"
                      >
                        {nextStep ? translate("Continue") : submitBtnText}
                      </Button>
                    </Flex>
                  </ButtonsWrapper>
                </Col>
                <DecorativeRightImage
                  alt="Room decorative image"
                  src="/assets/images/roomlist-photos.svg"
                />
              </Row>
            </Formsy>
          </Container>
        </Spacer>
      </>
    );
  }
}

export default RoomListPhotos;
