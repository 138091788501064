import React, { useState } from "react";
import { BADI_COLORS } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getGeoLocation } from "base/shared/geo-location";
import LoadingSpinner from "components/Icons/LoadingSpinner";

import { parseGeocodeSuggest } from "../../utils";

import { LOCATION_STATUS } from "../../constants";

import SuggestItem from "../suggest-item";
import { NearByRoot } from "./styled-components";

const SPINNER_STYLES = {
  position: "absolute",
  right: "8px",
  top: "calc(50% - 12px)",
  height: "20px",
};

const Nearby = ({ onClick, onError = () => {} }) => {
  const [code, setCode] = useState(LOCATION_STATUS.NOT_INITIALIZED);
  const [message, setMessage] = useState(null);

  const setLocation = ({ latitude: lat, longitude: lng }) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: { lat, lng } }, (results) => {
      const safeResult = results || [];
      const loc = safeResult.find(
        (r) =>
          !r.types.find(
            (t) => t === "establishment" || t === "point_of_interest",
          ),
      );

      setCode(LOCATION_STATUS.OK);
      setMessage(null);
      onClick(parseGeocodeSuggest(loc));
    });
  };

  const setError = ({ code: errorCode, message: errorMessage }) => {
    setCode(errorCode);
    setMessage(errorMessage);
    onError();
  };

  const checkGeoLocation = () => {
    getGeoLocation({ onSuccess: setLocation, onError: setError });
  };

  const clickHandler = () => {
    if (code !== LOCATION_STATUS.PENDING) {
      checkGeoLocation();
    }
  };

  const disabled =
    code !== LOCATION_STATUS.OK && code !== LOCATION_STATUS.NOT_INITIALIZED;
  const loading = code === LOCATION_STATUS.PENDING;

  return (
    <NearByRoot>
      <SuggestItem
        description={translate("search.location.options.nearby")}
        disabled={disabled && !loading}
        icon={
          disabled
            ? "assets/icons/nearby-disabled.svg"
            : "assets/icons/nearby.svg"
        }
        onClick={clickHandler}
        type={translate(message)}
      />
      {loading && (
        <LoadingSpinner
          color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
          style={SPINNER_STYLES}
        />
      )}
    </NearByRoot>
  );
};

Nearby.defaultProps = {
  onError: () => {},
};

export default Nearby;
