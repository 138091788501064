import { connect } from "react-redux";
import { getToken, isLoggedUser } from "datamodel/Auth/selectors";
import { getIsSafari, isMobileSelector } from "datamodel/Device/selectors";

import NewReview from "./new-review";

const mapStateToProps = (state) => ({
  token: getToken(state),
  isIOSApp: getIsSafari(state) && isMobileSelector(state),
  isLoggedIn: isLoggedUser(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewReview);
