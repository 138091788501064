import styled from "styled-components";
import { mediaquery, BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const SearchBarLayer = styled.div`
  position: relative;
  margin-top: 35px;
  ${mediaquery.lg`
    pointer-events: none;
  `}
`;

export const SearchBarWrap = styled.div`
  position: relative;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  border-radius: ${unitizedPx(6)};
  padding: ${unitizedPx(3)} ${unitizedPx(8)} ${unitizedPx(3)} ${unitizedPx(4)};
  cursor: ${({ searchMinified }) => (searchMinified ? `pointer` : `default`)};
  pointer-events: all;
  ${mediaquery.lg`
    max-width: ${unitizedPx(84)};
    padding: ${unitizedPx(1.5)} ${unitizedPx(8)} ${unitizedPx(1.5)}
      ${unitizedPx(4)};
  `}
`;

export const StyledFlex = styled.div`
  & > div {
    width: 100%;
  }
  ${mediaquery.lg`
    & > div{
      width: auto;
    }
  `}
`;

export const SearchButton = styled.div`
  position: absolute;
  right: ${unitizedPx(1.5)};
  top: ${unitizedPx(2)};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaquery.lg`
    top: ${unitizedPx(1.5)};
  `}
`;

export const NavigationSearch = styled.nav`
  width: 100%;
  pointer-events: all;
`;

export const SearchFields = styled.div`
  z-index: 1;
  display: none;
  ${mediaquery.lg`
    display: block;
  `}
`;

export const SearchMinified = styled.div`
  ${mediaquery.lg`
    position: absolute;
    top: 20px;
    opacity: 0;
    z-index: 0;
  `}
`;

export const SearchMenuButton = styled.div`
  transition: all 0.2s;
  opacity: 1;
  position: relative;
  padding: 2px 8px;
  cursor: pointer;

  &:after {
    transition: all 0.2s;
    content: "";
    width: ${({ selected }) => (selected ? "20%" : "0%")};
    height: 2px;
    background: white;
    position: absolute;
    left: 50%;
    bottom: -4px;
    margin-left: ${({ selected }) => (selected ? "-10%" : "0%")};
  }

  ${({ selected }) => {
    if (!selected) {
      return `
        &:hover{
          opacity: 0.7;
          &:after{
            width: 6%;
            margin-left: -3%;
          }
        }
      `;
    }
    return `
    p{
      font-weight: 500;
    }`;
  }}
`;

export const SearchDateWrapper = styled.div`
  .SingleDatePicker_picker {
    transform: translate(-36%, -10px);
  }

  .DayPicker_focusRegion {
    background: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
    padding-right: ${unitizedPx(2)};
  }

  .CalendarDay__selected {
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  }

  .DateInput_fang {
    display: none;
  }

  .DateInput_input {
    font-size: 14px;
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
    padding: 0px;
    min-height: auto;
    font-weight: normal;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
    border: 1px double ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
  }
`;

export const SearchLengthStayWrapper = styled.select`
  border: none;
  appearance: none;
  font-size: 14px;
  color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
  padding: 0px;
  min-height: auto;
  font-weight: normal;
  margin-left: -${unitizedPx(0.5)};
`;

export const LocationPopoverWrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: block;
  max-width: ${unitizedPx(47)};
  margin-top: -${unitizedPx(1)};
`;
