import { connect } from "react-redux";
import { getCreditCardLastDigits } from "app/datamodel/Payment/selectors";
import { getToken } from "app/datamodel/Auth/selectors";
import { getPayment } from "datamodel/Payment/actions";
import PaymentFailStep from "./payment-fail-step";

const mapStateToProps = (state) => ({
  token: getToken(state),
  hasPaymentCard: !!getCreditCardLastDigits(state),
});

const mapDispatchToProps = {
  getPaymentDetails: getPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFailStep);
