import React from "react";
import classNames from "classnames";

import InputWithLabel from "./InputWithLabel";

import "./InputExt.css";

class Input extends React.PureComponent {
  render() {
    const {
      className,
      disabled,
      inputClassName,
      inputType,
      label,
      maxlength,
      name,
      onChange,
      placeholder,
      required,
      setReference,
      value,
      dataQa,
    } = this.props;

    if (label) return <InputWithLabel {...this.props} />;

    return (
      <div
        className={classNames(
          "Form__Input--wrapper",
          { "Form__Input--input-active": !!value },
          { disabled },
          className,
        )}
      >
        <input
          className={classNames(inputClassName)}
          data-qa={dataQa}
          disabled={disabled}
          id={name}
          maxLength={maxlength || undefined}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          ref={setReference}
          required={required}
          type={inputType}
          value={value}
        />
      </div>
    );
  }
}

Input.defaultProps = {
  className: "",
  disabled: false,
  inputClassName: null,
  inputType: "text",
  label: "",
  maxlength: 0,
  placeholder: "",
  required: false,
  setReference: () => {},
  value: null,
};

export default Input;
