import React from "react";
import {
  Text,
  Heading,
  Flex,
  Spacer,
  BADI_COLORS,
  Button,
  BUTTON_SIZES,
} from "@badi/badi-components";
import { Row, Col } from "reactstrap";
import { translate } from "base/shared/Localization";
import { getListerCardSize } from "../../utils";

import { Card } from "../../styled-components";

const ListerCards = ({ isWebView, onRedeemClick, products }) => (
  <Row>
    {products.map(({ name, available, price }) => (
      <Col key={name} lg={getListerCardSize(products.length)} sm={12}>
        <Spacer bottom={2}>
          <Card height={28}>
            <Flex
              alignItems="center"
              direction="column"
              fullHeight={true}
              gutter={1.25}
              justify="center"
            >
              <Text body="16-Regular" dataQa={`redeem-lister-${name}-header`}>
                {translate("referrals.content.redeem.lister.get")}
              </Text>
              <Heading
                color={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
                data-qa={`redeem-lister-${name}-title`}
                level="20-Bold"
              >
                {translate(`referrals.content.redeem.lister.${name}`, {
                  price,
                })}
              </Heading>
              <Spacer bottom={1} top={0.25} />
              <Button
                alignself="center"
                dataQa={`redeem-lister-${name}-button`}
                disabled={!available || isWebView}
                onClick={() => onRedeemClick(name)}
                size={BUTTON_SIZES.SMALL}
              >
                {translate("referrals.content.redeem.lister.redeem_now")}
              </Button>
              <Text
                body="12-Regular"
                dataQa={`redeem-lister-${name}-header-description`}
              >
                {translate("referrals.content.redeem.lister.get.description")}
              </Text>
            </Flex>
          </Card>
        </Spacer>
      </Col>
    ))}
  </Row>
);

export default ListerCards;
