import { createLogger } from "redux-logger";
import { createStore, compose, applyMiddleware } from "redux";
import envVars from "base/shared/Env";
import rootReducer from "../reducers";
import requestMiddleware from "../middleware/request-middleware";
import searchMiddleware from "../middleware/search-middleware";

const { BADI_RAVEN_DSN, BADI_HASH_VERSION, NODE_ENV } = envVars();

function getEnhancer() {
  return (
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  );
}

function configureMiddlewares() {
  const middlewares = [requestMiddleware, searchMiddleware];

  if (NODE_ENV === "development") {
    middlewares.push(createLogger({ level: "info", collapsed: true }));
  }

  return applyMiddleware(...middlewares);
}

function configureStore(initialState) {
  const middlewares = configureMiddlewares();
  const composeEnhancer = getEnhancer();
  const enhancer = composeEnhancer(middlewares);
  const store = createStore(rootReducer, initialState, enhancer);

  if (module.hot) {
    module.hot.accept("../reducers", () => {
      const nextRootReducer = rootReducer;

      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default configureStore;
