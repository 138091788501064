import React from "react";

import { colors } from "@badi/badi-components";

const SearchMapView = ({ selected }) => (
  <svg
    height="22"
    viewBox="0 0 19 22"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={selected ? colors.green : colors.midGrey}
      strokeLinecap="round"
      strokeWidth="2"
    >
      <path
        d="M3.46 3.086C5.004 1.738 7.043 1 9.2 1c2.158 0 4.198.738 5.741 2.086 2.946 2.573 3.301 7.363.784 10.293l-6.524 8.23-.784-.988-5.725-7.223C.157 10.447.516 5.658 3.46 3.086z"
        strokeLinejoin="round"
      />
      <path d="M9.2 11c1.489 0 2.7-1.212 2.7-2.7 0-1.488-1.211-2.7-2.7-2.7a2.703 2.703 0 0 0-2.7 2.7c0 1.488 1.211 2.7 2.7 2.7z" />
    </g>
  </svg>
);

SearchMapView.defaultProps = {
  selected: false,
};

export default SearchMapView;
