import { parseGeocodeResult } from "components/geo-suggest/utils";

export const isMissingLocation = (bounds, coordinates, text) =>
  text && (!bounds || !coordinates);

export const geocodeByAddressOrPlaceId = ({
  address,
  placeId,
  setLocation,
}) => {
  if (!window.google?.maps) {
    return;
  }

  const params = placeId ? { placeId } : { address };
  const geocoder = new window.google.maps.Geocoder();

  geocoder.geocode(params, (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      const parsedResult = parseGeocodeResult(address, results);

      if (parsedResult) {
        setLocation(parsedResult);
      }
    }
  });
};
