import { connect } from "react-redux";

import RoomActions from "datamodel/Room/actions";
import RoomAction from "./room-action";
import { getIsUserWithFastTrack } from "app/datamodel/User/selectors";

const mapStateToProps = (state) => ({
  isUserWithFastTrack: getIsUserWithFastTrack(state),
});

const mapDispatchToProps = (dispatch) => ({
  onVisitSent: ({ connectionId }) =>
    dispatch(
      RoomActions.updateRoom({
        allowToVisit: false,
        inboxConnectionId: connectionId,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomAction);
