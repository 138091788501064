import styled from "styled-components";
import { colors, unitizedPx } from "@badi/badi-components";

export const UserCardBlock = styled.div`
  margin-bottom: ${unitizedPx(3)};
  position: relative;

  > h3 {
    font-size: 1.2rem;
  }
`;

export const AlignRight = styled.div`
  text-align: right;
`;

export const MetricUserCardBlock = styled(UserCardBlock)`
  margin-top: ${unitizedPx(2)};
`;

export const Metric = styled.div`
  height: 100%;
  border-left: 2px solid ${colors.blueBooking};
`;

export const Divider = styled.div`
  padding-top: ${unitizedPx(3)};
  border-top: 1px solid ${colors.lightGrey};
`;

export const LinkWrapper = styled.a`
  cursor: pointer;
  text-decoration: underline;

  :hover {
    text-decoration: underline;
  }
`;

export const ReviewWrapper = styled.div`
  padding-top: ${unitizedPx(2)};
  padding-bottom: ${unitizedPx(2)};
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const UserName = styled.h1`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
