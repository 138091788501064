import React, { useEffect } from "react";
import { Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { SEARCH_VIEW } from "datamodel/SearchFilters/static";
import FilterIcon from "components/Icons/Filter";
import {
  RootView,
  GradientSeparator,
  ViewWrapper,
  FilterButton,
  FilterButtonLabel,
} from "./styled-components";

const FilterViewButton = ({ view, onSelectView, active }) => {
  useEffect(() => {
    return () => {
      if (view === SEARCH_VIEW.form) {
        onSelectView(SEARCH_VIEW.list);
      }
    };
  }, []);

  const handleClick = () => {
    const { form, list } = SEARCH_VIEW;
    let selectedView = list;

    if (view !== form) {
      selectedView = form;
    }

    onSelectView(selectedView);
  };

  return (
    <RootView left={true}>
      <ViewWrapper>
        <FilterButton active={active} onClick={handleClick} type="button">
          <FilterIcon selected={active} />
          {!active && (
            <FilterButtonLabel as={Text} body={5} inline={true}>
              {translate("Filters")}
            </FilterButtonLabel>
          )}
        </FilterButton>
        <GradientSeparator left={false} />
      </ViewWrapper>
    </RootView>
  );
};

export default FilterViewButton;
