import React, { useCallback } from "react";
import { Spacer, Text, TextButton } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

const PremiumInfo = ({
  top = 0,
  bottom = 0,
  right = 0,
  left = 0,
  description = "",
  roomId,
  feature = "",
  onClick = null,
  onRedirect,
  includeButton = true,
}) => {
  const clickHandler = useCallback((event) => {
    event.preventDefault();

    if (onClick) {
      onClick({ callback: () => onRedirect({ roomId, feature }) });
    } else {
      onRedirect({ roomId, feature });
    }
  }, []);

  return (
    <Spacer bottom={bottom} left={left} right={right} top={top}>
      {description && (
        <Text body={3} data-qa="premium-description">
          {description}
        </Text>
      )}
      {includeButton && (
        <TextButton
          data-qa="premium-info-cta"
          onClick={clickHandler}
          small={false}
        >
          {translate("premium_feature_info_generic_cta")}
        </TextButton>
      )}
    </Spacer>
  );
};

export default PremiumInfo;
