import React, { useEffect } from "react";

import {
  ANNOTATION_ACTION_TYPE_LINK,
  ANNOTATION_ACTION_TYPE_POPUP,
} from "datamodel/Conversation/constants";

import PopupAnnotation from "./popup-annotation-mapping";
import TextAnnotation from "./text-annotation";
import { ANNOTATION_TYPE_WARNING } from "./constants";

const SystemMessage = ({
  link = null,
  content,
  actionType = ANNOTATION_ACTION_TYPE_LINK,
  heading = "",
  linkText = "",
  popupKey = null,
  type = "",
}) => {
  switch (actionType) {
    case ANNOTATION_ACTION_TYPE_POPUP:
      return (
        <PopupAnnotation
          heading={heading}
          linkText={linkText}
          message={content}
          popupKey={popupKey}
        />
      );
    default:
      return (
        <TextAnnotation link={link} linkText={linkText} message={content} />
      );
  }
};

export default SystemMessage;
