import React, { Component } from "react";
import { Flex, Spacer, Heading, Text, Button } from "@badi/badi-components";
import { Container, Col, Row } from "reactstrap";
import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import EmailVerificationForm from "components/Verification/Email/EmailVerificationMapping";
import BankAccount from "components/bank-account";
import ContactInformation from "components/contact-information";
import PayThroughBadiInfo from "components/pay-through-badi-info";
import ButtonsWrapper from "../buttons-wrapper";

class Payout extends Component {
  componentDidMount() {
    const { getAccount, loaded } = this.props;
    if (!loaded) getAccount();
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error) {
      UserFeedback.exception("Something went wrong", error.message, error);
    }
  }

  render() {
    const { email, loaded, verified, error, nextStep, previousStep } =
      this.props;

    return (
      <Spacer bottom={12} top={4}>
        <Container>
          <Row>
            <Col>
              <Spacer bottom={1}>
                <Heading>{translate("payout.setup.step.title")}</Heading>
              </Spacer>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <Spacer bottom={4}>
                <Text body={1}>{translate("payout.setup.step.subtitle")}</Text>
              </Spacer>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 6, order: 1 }}
              md={{ size: 12, order: 2 }}
              xs={{ order: 2 }}
            >
              {loaded && (
                <Flex direction="column" gutter={3}>
                  <BankAccount />
                  <ContactInformation />
                  <EmailVerificationForm email={email} />
                </Flex>
              )}
              <ButtonsWrapper>
                <Flex gutter={2}>
                  <Button
                    basis="50%"
                    fullWidth={true}
                    name="back"
                    onClick={previousStep}
                    variant="secondary"
                  >
                    {translate("Back")}
                  </Button>
                  {verified && !error ? (
                    <Button
                      basis="50%"
                      fullWidth={true}
                      name="next"
                      onClick={nextStep}
                    >
                      {translate("Continue")}
                    </Button>
                  ) : (
                    <Button
                      basis="50%"
                      fullWidth={true}
                      name="next"
                      onClick={nextStep}
                    >
                      {translate("payout.setup.button.skip")}
                    </Button>
                  )}
                </Flex>
              </ButtonsWrapper>
            </Col>
            <Col
              lg={{ size: 3, order: 2 }}
              md={{ size: 12, order: 1 }}
              xs={{ order: 1 }}
            >
              <PayThroughBadiInfo />
              <Spacer bottom={2} />
            </Col>
          </Row>
        </Container>
      </Spacer>
    );
  }
}

Payout.defaultProps = {
  error: null,
};

export default Payout;
