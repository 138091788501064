import React from "react";

import { Text, Flex, colors } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import { Svg } from "./styled-components";

const ExtraBenefitBadge = ({ text }) => (
  <Flex alignItems="center" gutter={0.5}>
    <Svg height="7" width="8" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.18 3.91c.79.98 1.93 3.11 3.45 2.24.82-.48 1.47-1.53 2.07-2.25.75-.91 1.46-1.85 2.16-2.8C8.44.3 7.1-.46 6.54.33a50.2 50.2 0 01-3.16 3.93c-.48.54-.34.57-.8.15-.5-.45-.9-1.06-1.32-1.58-.61-.76-1.69.32-1.08 1.08"
        fill={colors.green}
        fillRule="evenodd"
      />
    </Svg>
    <Text body={5} color={colors.green} dataQa="extra-benefit-text">
      {text}
    </Text>
  </Flex>
);

export default ExtraBenefitBadge;
