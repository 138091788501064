import styled from "styled-components";
import { mediaquery, unitizedPx, BADI_COLORS } from "@badi/badi-components";

export const PrecontentBar = styled.div`
  height: ${unitizedPx(5)};
  border-bottom: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  width: 100%;

  ${mediaquery.lg`
    height: ${unitizedPx(10)};
  `};
`;

export const TextWrapper = styled.div`
  word-break: break-word;
`;
