import { css } from "styled-components";
import { mediaquery } from "@badi/badi-components";

export const DESKTOP_SCREEN_SIZE = "desktop";
export const MOBILE_SCREEN_SIZE = "mobile";
export const TABLET_SCREEN_SIZE = "tablet";

const isDesktop = (rule) => rule === DESKTOP_SCREEN_SIZE;

export const getHideOnStyles = (hideOnBreakpoint = null) => {
  if (!hideOnBreakpoint) return "";

  const rules = ["initial", "none"];
  const displayRules = isDesktop(hideOnBreakpoint)
    ? rules
    : [...rules].reverse();

  return css`
    display: ${displayRules[0]};
    ${mediaquery.lg`
      display: ${displayRules[1]};
    `}
  `;
};
