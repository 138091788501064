import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BREAKPOINTS_PX, useMatchMedia } from "@badi/badi-components";
import { isMobileSelector } from "datamodel/Device/selectors";
import { getAnimations } from "./utils";
import SearchBarHome from "../search-bar-home";
import { TopBarFrame, BackgroundLayer } from "./styled-components";
import DesktopBar from "./desktop-bar";
import ResponsiveBar from "./responsive-bar";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.lg})`];

const TopBarHome = ({
  searchbar = true,
  searchType = null,
  setSearchType = () => {},
  title,
}) => {
  const isMobile = useSelector(isMobileSelector);
  const [isDesktop] = useMatchMedia(QUERIES);
  const [animations, setAnimations] = useState({});
  const [interscrollSearch, setInterscrollSearch] = useState();

  const topBarFrameRef = useRef(null);
  const menuRef = useRef(null);

  const disableSearchMode = () => {
    window.removeEventListener("scroll", disableSearchMode);
    animations.mainFrame.play();
    animations.mainMenu.play();
    setInterscrollSearch(false);
  };

  const toggleSearchCallback = () => {
    animations.mainFrame.reverse();
    animations.mainMenu.reverse();
    setInterscrollSearch(true);
    window.addEventListener("scroll", disableSearchMode);
  };

  useEffect(() => {
    const barAnimations = getAnimations(topBarFrameRef, menuRef, isDesktop);
    setAnimations(barAnimations);
    return () => window.removeEventListener("scroll", disableSearchMode);
  }, []);

  return (
    <TopBarFrame
      dataQa="topbar-public"
      ref={topBarFrameRef}
      withSearch={!!searchType}
    >
      <div ref={menuRef} style={{ marginTop: "9px" }}>
        {isMobile ? <ResponsiveBar /> : <DesktopBar />}
      </div>
      {searchbar && (
        <SearchBarHome
          interscrollSearch={interscrollSearch}
          searchType={searchType}
          setSearchType={setSearchType}
          toggleSearchCallback={toggleSearchCallback}
          title={title}
        />
      )}
      {interscrollSearch && <BackgroundLayer onClick={disableSearchMode} />}
    </TopBarFrame>
  );
};

export default TopBarHome;
