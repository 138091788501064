import React from "react";
import { BADI_COLORS, Spacer, Text } from "@badi/badi-components";

const Label = ({ dataQa = null, disabled = false, children }) => {
  return (
    <Spacer bottom={1}>
      <Text
        body="12-Medium"
        color={
          disabled
            ? BADI_COLORS.NEUTRAL.NEUTRAL_10
            : BADI_COLORS.NEUTRAL.NEUTRAL_80
        }
        dataQa={dataQa}
      >
        {children}
      </Text>
    </Spacer>
  );
};

export default Label;
