import React from "react";
import { Flex, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getGender, getOccupation } from "base/shared/User";
import { getGenderIconById } from "components/Icons/Gender";
import { getOccupationIconById } from "components/Icons/Occupation";
import { UserCardBlock } from "../styled-components";

const GenderAndOccupation = ({ gender = null, occupation }) => {
  const userGender = getGender(gender);
  // TODO refactor to use the same structure as `user.occupation` to have a cosistent user model
  const userOccupation = getOccupation(occupation);

  userGender.component = getGenderIconById(userGender.value);
  userOccupation.component = getOccupationIconById(userOccupation.value);

  return (
    <UserCardBlock>
      <Flex direction="row">
        <Flex alignItems="center" fullHeight={true} grow={1} gutter={1}>
          {userGender.component}
          <Text>{translate(userGender.title)}</Text>
        </Flex>
        <Flex alignItems="center" fullHeight={true} grow={1} gutter={1}>
          {userOccupation.component}
          <Text>{translate(userOccupation.title)}</Text>
        </Flex>
      </Flex>
    </UserCardBlock>
  );
};

export default GenderAndOccupation;
