import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Spacer, Flex, Text } from "@badi/badi-components";

import envVars from "base/shared/Env";
import { translate } from "base/shared/Localization";
import AppLinks from "components/app-links";
import LanguageSelector from "./components/language-selector";
import FooterCategories from "./components/categories";
import SocialLinks from "./components/social-links";

import {
  Root,
  FooterNavigation,
  LanguagesWrapper,
  TopSection,
} from "./styled-components";

const Footer = () => {
  return (
    <Root>
      <Spacer bottom={4} md={{ top: 8, bottom: 8 }} top={4}>
        <Container>
          <Row>
            <Col>
              <Spacer bottom={4}>
                <TopSection>
                  <FooterNavigation>
                    <FooterCategories />
                  </FooterNavigation>
                  <Flex
                    direction="column"
                    gutter={4}
                    lg={{
                      direction: "column",
                      gutter: 2,
                      alignItems: "flexStart",
                    }}
                    sm={{
                      direction: "row",
                      justify: "spaceBetween",
                      alignItems: "center",
                      gutter: 1,
                    }}
                  >
                    <LanguageSelector />
                    <SocialLinks />
                  </Flex>
                </TopSection>
              </Spacer>
              <Flex
                alignItems="flexStart"
                direction="column"
                md={{
                  direction: "row",
                  alignItems: "center",
                  justify: "spaceBetween",
                }}
              >
                <AppLinks />
                <Flex
                  alignItems="flexStart"
                  direction="column"
                  md={{ alignItems: "flexEnd", direction: "column" }}
                >
                  <Text>&#169; Badiapp Technologies, S.L.</Text>
                  <Text body={6}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: translate("general.recaptcha.protect"),
                      }}
                      data-qa="recaptcha-protect"
                    />
                  </Text>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Container>
      </Spacer>
    </Root>
  );
};

export default memo(Footer);
