import { countries } from "country-data";
import { locale, localeISO } from "base/shared/Localization";
import { parse } from "qs";
import getSymbolFromCurrency from "currency-symbol-map";
import Intl from "intl";
import envVars from "base/shared/Env";

const isBrowser = () => typeof window !== "undefined";

const capitalizeString = (word) => {
  if (!word) return "";

  return word.charAt(0).toUpperCase() + word.substr(1);
};

let cdnUrl;

const getCDNUrl = () => {
  if (!cdnUrl) {
    const { BADI_CDN_URL, BADI_HASH_VERSION } = envVars();

    cdnUrl = BADI_HASH_VERSION
      ? `${BADI_CDN_URL}/${BADI_HASH_VERSION}`
      : BADI_CDN_URL || "";
  }

  return cdnUrl;
};

const getPublicAsset = (path) => {
  const notAbsolutePath = path.startsWith("/") ? path.replace("/", "") : path;
  return `${getCDNUrl()}/assets/${notAbsolutePath}`;
};

const getCurrentLanguageUrl = (url, domain) => {
  if (!url || !url.en) return "";

  const loc = locale();
  let domainUrl = "";

  if (typeof domain === "string") {
    domainUrl = domain;
  } else if (typeof domain === "object") {
    if (!domain.en) domainUrl = "";
    else
      domainUrl = Object.prototype.hasOwnProperty.call(domain, loc)
        ? domain[loc]
        : domain.en;
  }

  return (
    domainUrl +
    (Object.prototype.hasOwnProperty.call(url, loc) ? url[loc] : url.en)
  );
};

const getDomain = () => {
  if (typeof window !== "undefined") {
    const { origin, protocol, hostname, port } = window.location;

    return origin || `${protocol}//${hostname}${port ? `:${port}` : ""}`;
  }
  return envVars().BADI_DOMAIN.replace(/\/$/, "");
};

const getAPIUrl = (servicePath, domain) => {
  const { BADI_API_URL } = envVars();
  const safeDomain = domain || BADI_API_URL;

  return `${safeDomain.replace(/\/$/, "")}${servicePath}`;
};

const getPathName = (relativePath) => {
  const domain = getDomain();
  if (relativePath) return domain + relativePath;
  return domain;
};

const getLocalePath = (lang) => {
  if (lang) {
    return lang === "en" ? "" : `/${lang}`;
  }

  const currentLocale = locale();
  return !currentLocale || currentLocale === "en" ? "" : `/${currentLocale}`;
};

const getRelativePath = (url, lang) => `${getLocalePath(lang)}${url}`;

const getRelativePathNameWithoutLang = (relativePath) => {
  if (!relativePath) return "/";

  const loc = getLocalePath();

  return loc && relativePath.startsWith(loc)
    ? relativePath.replace(loc, "")
    : relativePath;
};

const compareWithWindowWidth = (width) => {
  if (typeof window === "undefined") return 2;

  const windowWith =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  if (windowWith < width) return -1;
  if (windowWith > width) return 1;
  return 0;
};

const getPercentage = (total, current) => {
  if (current > total) return 100;
  return current > 0 ? 100 / (total / current) : -1;
};

const getWindowHeight = () => {
  if (typeof window === "undefined") return -1;

  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );
};

const getScrollDistanceToTop = (element) =>
  element ? element.scrollTop : window.pageYOffset;

const scrollTo = (to, duration, elementToScroll = null) => {
  if (duration <= 0) return;
  const difference = to - getScrollDistanceToTop(elementToScroll);

  const perTick = (difference / duration) * 10;

  setTimeout(() => {
    const distance = getScrollDistanceToTop(elementToScroll);
    const scrollingElement = elementToScroll || window;

    if (scrollingElement.scrollTo) {
      scrollingElement.scrollTo(0, distance + perTick);
    } else {
      return;
    }

    if (distance === to) return;

    scrollTo(to, duration - 10, elementToScroll);
  }, 10);
};

const compactObject = (obj) => {
  if (!obj) return undefined;

  return Object.entries(obj).reduce((newObject, [key, value]) => {
    if (value === null || value === undefined) return newObject;

    return {
      ...newObject,
      [key]: value,
    };
  }, {});
};

const getCurrencyCodeByCountryCode = (countryCode) => {
  if (!countryCode) return null;

  const country = countries[countryCode.toUpperCase()];

  if (country) {
    const currency = country.currencies[0];
    return currency;
  }

  return null;
};

const getDisplayPrice = (number, currency) => {
  if (typeof number !== "number" || !currency) return "-";
  const iso = localeISO();

  if (!iso) return null;

  const options = {
    style: "currency",
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };

  if (typeof window !== "undefined") {
    return number.toLocaleString(iso, options);
  }

  return new Intl.NumberFormat(iso, options).format(number);
};

const getCurrencySymbol = (currencyCode) => {
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  return currencySymbol === undefined ? "€" : currencySymbol;
};

const getMenuItems = (items, currentId) => {
  if (!items) return [];

  return items.map((item) => {
    const newItem = {
      ...item,
      url: item.url.replace("{locale}", getLocalePath()),
      active: item.id === currentId,
    };

    return newItem;
  });
};

const getPicture = (
  pictures,
  size = "width_500_url",
  pos = 0,
  type = "user",
) => {
  const defaultPic =
    getCDNUrl() +
    (type === "user"
      ? "/assets/icons/profile_picture.svg"
      : "/assets/images/placeholderRoom.png");

  if (!pictures || !pictures.length) return defaultPic;
  const picture = pictures[pos];
  if (!picture) return defaultPic;
  return picture[size] || defaultPic;
};

const navigationBack = () => {
  window.history.back();
};

const sortByAlphabetically =
  (field = "label") =>
  (a, b) => {
    const labelA = a[field];
    const labelB = b[field];

    if (typeof labelA.localeCompare !== "undefined") {
      return labelA.localeCompare(labelB);
    }
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  };

const parseBoolToNumberedString = (value) =>
  typeof value === "boolean" ? (+value).toString() : "";

const KEYCODE = {
  enter: 13,
  escape: 27,
  left: 37,
  right: 39,
  spacebar: 32,
};

const getPictureBySize = (type) => {
  const defaultPicPath =
    type === "room"
      ? "/assets/images/placeholderRoom.png"
      : "/assets/icons/profile_picture.svg";

  return (coverPictureObject, pictureFormat = "width_500_url") => {
    if (!coverPictureObject || !coverPictureObject[pictureFormat]) {
      return `${getCDNUrl()}${defaultPicPath}`;
    }
    return coverPictureObject[pictureFormat];
  };
};

const objectToQueryParams = (paramsObject) => {
  const params = new URLSearchParams();

  Object.entries(paramsObject).forEach(([key, value]) => {
    if (value) {
      params.append(key, value);
    }
  });

  return params.toString();
};

const getParams = (url, prefix) => {
  const urlParams = new URLSearchParams(url);
  const params = {};

  urlParams.forEach((value, key) => {
    if (key.startsWith(prefix)) params[key] = value;
  });

  return params;
};

const removeUndefinedAndNullFields = (obj) => {
  if (!obj) {
    return null;
  }

  Object.keys(obj).forEach(
    (key) => (obj[key] === undefined || obj[key] === null) && delete obj[key],
  );

  return obj;
};

const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([_][a-z])/g, (group) => group.toUpperCase().replace("_", ""));

const convertAffirmationToBool = (value) => {
  switch (value) {
    case "yes":
      return true;
    case "no":
      return false;
    default:
      return value;
  }
};
const convertBoolToAffirmation = (value) => (value ? "yes" : "no");

const getIsToggleActive = (featureName) => {
  if (typeof window == "undefined") return false;
  const queryString = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return Boolean(queryString[`feature_${featureName}`]);
};

export {
  capitalizeString,
  compactObject,
  getCDNUrl,
  getAPIUrl,
  getCurrencyCodeByCountryCode,
  getCurrencySymbol,
  getCurrentLanguageUrl,
  getDisplayPrice,
  getDomain,
  getLocalePath,
  getMenuItems,
  getPathName,
  getPercentage,
  getPicture,
  getRelativePath,
  getRelativePathNameWithoutLang,
  compareWithWindowWidth,
  getWindowHeight,
  KEYCODE,
  navigationBack,
  parseBoolToNumberedString,
  scrollTo,
  sortByAlphabetically,
  getPublicAsset,
  getPictureBySize,
  objectToQueryParams,
  getParams,
  isBrowser,
  removeUndefinedAndNullFields,
  snakeToCamel,
  convertAffirmationToBool,
  convertBoolToAffirmation,
  getIsToggleActive,
};
