import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  transform: rotate(
    ${({ direction }) => {
      switch (direction) {
        case "left":
          return "180deg";
        case "up":
          return "-90deg";
        case "down":
          return "90deg";
        default:
          return "0deg";
      }
    }}
  );
`;

const Arrow = ({ direction }) => (
  <Svg
    direction={direction}
    height="40px"
    version="1.1"
    viewBox="0 0 40 40"
    width="40px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      id="Web-mobile"
      stroke="none"
      strokeWidth="1"
    >
      <g
        id="Filters-bar---Default"
        transform="translate(-356.000000, -314.000000)"
      >
        <g id="Group-8" transform="translate(24.000000, 185.000000)">
          <g
            id="Group-5"
            transform="translate(352.000000, 149.000000) scale(-1, 1) translate(-352.000000, -149.000000) translate(332.000000, 129.000000)"
          >
            <path
              d="M20.7383333,12.2845438 C20.3606667,11.9051521 19.7016667,11.9051521 19.324,12.2845438 C18.9346667,12.6759903 18.9346667,13.3135559 19.3243333,13.7050024 L24.6166667,19.022179 L13,19.022179 C12.4486667,19.022179 12,19.472895 12,20.0267466 C12,20.5805982 12.4486667,21.0313143 13,21.0313143 L24.5546667,21.0313143 L19.3243333,26.2855379 C19.1353333,26.4754012 19.0313333,26.7275477 19.0313333,26.9957672 C19.0313333,27.2639868 19.1353333,27.5161333 19.324,27.7053268 C19.5123333,27.895525 19.7636667,28 20.0313333,28 C20.299,28 20.5503333,27.895525 20.7383333,27.7056617 L27.707,20.7054995 C27.8956667,20.5156362 28,20.2634897 28,19.9952702 C28,19.7267157 27.8956667,19.4745693 27.707,19.2850408 L20.7383333,12.2845438 Z"
              fill="#1E1E1E"
              id="Fill-1"
              transform="translate(20.000000, 20.000000) scale(-1, 1) translate(-20.000000, -20.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default Arrow;
