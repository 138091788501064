import React, { useState } from "react";

import { translate } from "base/shared/Localization";

import { Button, Flex, Spacer, Heading, Text } from "@badi/badi-components";

import TextInput from "components/form-text-input";

const INPUT_CODE_NAME = "code";

const CheckCode = ({ onSubmit, toggleStep, disabled }) => {
  const [canSubmit, setValidation] = useState(false);
  const [codeValue, setCodeValue] = useState("");

  const handleInputChange = ({ value }) => {
    setCodeValue(value);
  };

  const handleInputValidation = ({ isValid }) => {
    setValidation(isValid);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ [INPUT_CODE_NAME]: codeValue });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Heading level={3}>{translate("verifications.phone.code.title")}</Heading>
      <Spacer bottom={1} top={3}>
        <Text body={2}>{translate("verifications.phone.code.label")}</Text>
      </Spacer>
      <Spacer bottom={2}>
        <TextInput
          name={INPUT_CODE_NAME}
          onChange={handleInputChange}
          onInputValidation={handleInputValidation}
          placeholder={translate("verifications.phone.code.placeholder")}
          required={true}
          validations={[["maxLength", 4]]}
        />
      </Spacer>
      <Flex gutter={2} justify="flexEnd">
        <Button
          disabled={disabled}
          onClick={toggleStep}
          type="button"
          variant="secondary"
        >
          {translate("verifications.phone.code.cancel_button")}
        </Button>
        <Button disabled={!canSubmit || disabled} type="submit">
          {translate("verifications.phone.code.submit_button")}
        </Button>
      </Flex>
    </form>
  );
};

CheckCode.defaultProps = {
  disabled: false,
};

export default CheckCode;
