const LocaleModel = {
  fetching: false,
  error: null,
  locale: "en",
  phrases: {},
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Locale) {
    return { ...LocaleModel, error: "There was an error in LocaleModel." };
  }
  return state;
}

export { LocaleModel, setInitialState };
