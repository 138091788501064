import styled, { css } from "styled-components";

export const StyledAvatar = styled.img`
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  width: ${(props) => (props.badge ? props.size - 4 : props.size)}px;
  height: ${(props) => (props.badge ? props.size - 4 : props.size)}px;
`;

export const StyledAvatarBadge = styled.div`
  ${(props) =>
    props.badge &&
    `
  border: 2px solid ${props.badgeBorderColor};
  `}
  position: relative;
  box-sizing: border-box;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;

  ${(props) =>
    props.onClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const BadgeIcon = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -2px;
  right: -2px;
`;
