import React, { cloneElement, createElement, Children } from "react";

import { Root, FlexChild } from "./styled-components";
import { generateResponsiveProps } from "../helpers";

import {
  BASE_PROPS,
  JUSTIFY_CHOICES,
  ALIGN_CHOICES,
  WRAP_CHOICES,
} from "./constants";

const Flex = ({
  as: asComponent,
  className,
  direction,
  reverse,
  justify,
  alignItems,
  children,
  fullHeight,
  wrap,
  gutter,
  dataQa,
  style = {},
  ...rest
}) => {
  const responsiveProps = generateResponsiveProps(rest, BASE_PROPS);

  return (
    <Root
      alignItems={alignItems}
      as={asComponent}
      className={className}
      data-qa={dataQa}
      direction={direction}
      fullHeight={fullHeight}
      gutter={gutter}
      justify={justify}
      reverse={reverse}
      wrap={wrap}
      style={style}
      {...responsiveProps}
    >
      {Children.map(children, (child) => {
        if (!child) return child;

        const { alignself, basis, grow, order, shrink, wide } = child
          ? child.props
          : {};

        return createElement(
          FlexChild,
          {
            grow,
            order,
            basis,
            /**
             * * alignSelf is not being parsed as valid HTML attribute,
             * * so we are using lowercase instead.
             */
            alignself,
            shrink,
            wide,
          },
          // * We are overriding props to undefined avoiding their propagation
          cloneElement(child, {
            alignself: undefined,
            basis: undefined,
            grow: undefined,
            order: undefined,
            shrink: undefined,
            wide: undefined,
          }),
        );
      })}
    </Root>
  );
};

Flex.defaultProps = {
  alignItems: "stretch",
  as: "div",
  children: null,
  className: "",
  direction: "row",
  fullHeight: false,
  gutter: 0,
  justify: "flexStart",
  lg: undefined,
  md: undefined,
  reverse: false,
  sm: undefined,
  wrap: "nowrap",
  xl: undefined,
  xs: undefined,
  xxl: undefined,
};

export default Flex;
