import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "SEARCH_ROOMS_RESET",
  "SEARCH_ROOMS_ERROR",
  "SEARCH_ROOMS_REQUEST",
  "SEARCH_ROOMS_SUCCESS",
  "SEARCH_ROOMS_SELECT_ROOM",
  "SEARCH_ROOMS_RESET_LOGIN_REQUIRED",
]);
