import React from "react";
import { colors, Flex, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import Image from "components/Image";
import { getBenefitsByUserRole } from "./utils";

const BookingBenefits = ({ color = colors.darkGrey, userRole = "" }) => {
  const benefits = getBenefitsByUserRole(userRole);

  if (!userRole) {
    benefits.shift();
  }

  return (
    <Flex direction="column" gutter={1}>
      {benefits.map(({ id, description }) => (
        <Flex alignItems="center" gutter={1} key={id}>
          <Image
            src="/assets/icons/green-tic.svg"
            style={{ display: "block" }}
          />
          <Text body="12-Medium" color={color} data-qa="booking-benefit">
            {translate(description)}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default BookingBenefits;
