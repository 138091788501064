import React from "react";
import { withRouter } from "react-router";

import RedirectController from "./redirect-controller";

const RedirectControllerRoute = ({ router }) => {
  const { getCurrentLocation, push } = router;
  return (
    <RedirectController location={getCurrentLocation()} navigateTo={push} />
  );
};

export default withRouter(RedirectControllerRoute);
