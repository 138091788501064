import React from "react";
import { Col } from "reactstrap";
import { SortableElement } from "react-sortable-hoc";

import Preview from "./Preview";

const SortableItem = SortableElement(
  ({ sortIndex, item, onRemove, onRetry }) => (
    <Col xs={4} className="DropZone__container--item">
      <Preview
        isCover={sortIndex === 0}
        onRemove={onRemove}
        onRetry={onRetry}
        picture={item.serverUrl || item.file.preview}
        uploadStatus={item.uploadStatus}
        tempId={item.tempId}
      />
    </Col>
  ),
);

export default SortableItem;
