import React from "react";
import {
  useMatchMedia,
  BREAKPOINTS_PX,
  Button,
  Flex,
  Spacer,
  Text,
  Heading,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image";
import TopBar from "components/Layout/stepper-layout/top-bar";

const RoomSelectorEmpty = ({
  title,
  description,
  buttonText,
  pageDescription,
  pageTitle,
  onClose,
}) => {
  const [isDesktop] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);

  return (
    <Flex direction="column">
      <TopBar
        data-qa="room-selector-topbar"
        onClose={onClose}
        showLogo={isDesktop}
      />
      <Spacer
        bottom={8}
        left={2}
        lg={{ bottom: 8, left: 8, right: 16, top: 8 }}
        right={4}
        top={2}
      >
        <Flex direction="column" gutter={8}>
          {isDesktop && (
            <Flex direction="column" gutter={1}>
              <Heading data-qa="room-selector-heading">
                {translate(pageTitle)}
              </Heading>
              {pageDescription && (
                <Text data-qa="room-selector-description">
                  {translate(pageDescription)}
                </Text>
              )}
            </Flex>
          )}

          <Flex
            alignItems="center"
            direction="column"
            gutter={3}
            justify="center"
          >
            <Image
              alt={title}
              data-qa="empty-room-selector-image"
              src="/assets/images/no-rooms.svg"
            />
            <Flex direction="column" gutter={1}>
              <Heading
                align="center"
                data-qa="empty-room-selector-title"
                level={3}
              >
                {title}
              </Heading>
              <Text align="center" data-qa="empty-room-selector-description">
                {description}
              </Text>
            </Flex>
            <Button data-qa="empty-room-selector-button" onClick={onClose}>
              {buttonText}
            </Button>
          </Flex>
        </Flex>
      </Spacer>
    </Flex>
  );
};

export default RoomSelectorEmpty;
