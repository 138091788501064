import { getUserLanguages } from "base/shared/User";

import {
  LEGAL_RESPONSE,
  LISTER_USER_ROLE,
  SEEKER_USER_ROLE,
  USER_IS_COUPLE,
} from "../constants";

const hasSelectedTags = (tags) =>
  tags ? !!Object.keys(tags).find((t) => tags[t].length > 0) : false;

const validateField = (obj, fieldName) => {
  const value = obj[fieldName];

  if (fieldName === "tags") return hasSelectedTags(value);

  return (
    (!!value && !Array.isArray(value)) ||
    (Array.isArray(value) && !!value.length)
  );
};

const getProfilePercentage = (user) => {
  if (!user) return 0;

  const attributesSections = [
    "pictures",
    "gender",
    "birthDate",
    "occupation",
    ["name", "lastName"],
    "languages",
    "tags",
    "aboutMe",
  ];

  const completedSections = attributesSections.map((section) =>
    Array.isArray(section)
      ? section.every((s) => validateField(user, s))
      : validateField(user, section),
  );
  const completedSectionsNumber = completedSections.reduce(
    (sum, isCompleted) => (isCompleted ? sum + 1 : sum),
    0,
  );
  return Math.round(
    (completedSectionsNumber / attributesSections.length) * 100,
  );
};

export const getFullName = (firstName, lastName) => {
  if (!lastName) return firstName;
  else if (!firstName) return lastName;
  return `${firstName} ${lastName}`;
};

export const orderTagsByLabel = (tags = {}) =>
  Object.keys(tags).reduce((acc, key) => {
    acc[key] = tags[key].sort((a, b) => a.label.localeCompare(b.label));
    return acc;
  }, {});

export const metricsBuilder = (metrics = {}) => {
  if (Object.keys(metrics).length) {
    const memberSince = metrics.member_since
      ? { title: "user.metrics.memberSince", value: metrics.member_since }
      : undefined;
    const lastConnection = metrics.last_connection
      ? { title: "user.metrics.lastActive", value: metrics.last_connection }
      : undefined;
    return [memberSince, lastConnection];
  }

  return null;
};

const getUserMode = (mode) => (mode === "default" ? "seeker" : mode);

const parseProp = (fieldName, value) => {
  switch (fieldName) {
    case "languages":
      return {
        languages_attributes: value
          ? value.map((id) => ({ language_id: parseInt(id, 10) }))
          : [],
      };
    case "pictures":
      return {
        pictures:
          value && value.length
            ? value.map((picture) => picture.pictureId)
            : [],
      };
    case "birthDate":
      if (!value) return {};
      return {
        birth_date:
          typeof value === "string" ? value : value.format("YYYY-MM-DDTHH:mmZ"),
      };
    case "tags":
      return {
        tags: value
          ? Object.keys(value).reduce((current, next) => {
              const t = current;
              t[next] = value[next].map((tag) => tag.id);
              return t;
            }, {})
          : null,
      };
    case "name":
      return { first_name: value };
    case "lastName":
      return { last_name: value };
    case "gender":
      return { biological_sex: parseInt(value, 10) };
    case "aboutMe":
      return { small_bio: value };
    case "work":
    case "studies":
    case "occupation":
      return {
        occupation_detail: {
          main_occupation: parseInt(value.occupation || 0, 10),
          pursued_level_of_study: parseInt(value.studies || 0, 10),
          work_industry: parseInt(value.work || 0, 10),
        },
      };
    case "couple":
      return {
        is_couple: value === USER_IS_COUPLE.COUPLE,
      };
    case "social-profiles":
      if (!value) return null;
      return {
        social_profiles: value
          .map((profile) => ({
            media: profile.media,
            value: profile.value.replace(/^@/, ""), // remove starting @
          }))
          .filter((profile) => profile.value !== ""),
      };
    default:
      return null;
  }
};

const toPostParams = (values) => {
  const params = [
    "birthDate",
    "couple",
    "gender",
    "languages",
    "lastName",
    "name",
    "pictures",
    "aboutMe",
    "tags",
    "social-profiles",
  ];

  const occupationValues = parseProp("occupation", {
    occupation: values.occupation,
    work: values.work,
    studies: values.studies,
  });

  const parsedValues = params.reduce(
    (prev, fieldName) => ({
      ...prev,
      ...parseProp(fieldName, values[fieldName]),
    }),
    occupationValues,
  );

  return parsedValues;
};

const parseTags = (tags) =>
  tags
    ? Object.keys(tags).map((t) => ({
        icon: `/assets/icons/icon-${t}.svg`,
        id: t,
        title: `profile.form.fields.tags.category.${t}.title`,
        name: `profile.form.fields.tags.category.${t}.name`,
        tags: tags[t],
        expanded: true,
      }))
    : null;

const isLister = (userRole) => userRole === LISTER_USER_ROLE;

const isSeeker = (userRole) => userRole === SEEKER_USER_ROLE;

const getCouple = (isCouple) => {
  return isCouple ? USER_IS_COUPLE.COUPLE : USER_IS_COUPLE.SINGLE;
};

const getOtherUserRole = (userRole) =>
  userRole === LISTER_USER_ROLE ? SEEKER_USER_ROLE : LISTER_USER_ROLE;

const getLegalResponse = (legal) => {
  switch (legal) {
    case true:
      return LEGAL_RESPONSE.ACCEPTED;
    case false:
      return LEGAL_RESPONSE.REJECTED;
    case null:
      return LEGAL_RESPONSE.UNANSWERED;
    default:
      return LEGAL_RESPONSE.UNKNOWN;
  }
};

const isLegalPropAnswered = (answer) =>
  answer === LEGAL_RESPONSE.ACCEPTED || answer === LEGAL_RESPONSE.REJECTED;

const isLegalPropAccepted = (answer) => answer === LEGAL_RESPONSE.ACCEPTED;

const isLegalPropRejected = (answer) => answer === LEGAL_RESPONSE.REJECTED;

const isOtherReason = (reason) => reason === "other";

export {
  getProfilePercentage,
  parseTags,
  getUserLanguages,
  getUserMode,
  hasSelectedTags,
  toPostParams,
  parseProp,
  isLister,
  isSeeker,
  getOtherUserRole,
  getLegalResponse,
  isLegalPropAccepted,
  isLegalPropAnswered,
  isLegalPropRejected,
  isOtherReason,
  getCouple,
};
