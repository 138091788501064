import React from "react";
import Image from "../Image";

const Spinner = ({ fromSrc, toSrc, text }) => (
  <div className="spinner spinner_active">
    <div className="spinner__bg" />
    <div className="spinner__main">
      <div className="spinner__view">
        <div className="spinner__round" />
        <div className="spinner__start">
          <Image src={fromSrc} />
        </div>
        <div className="spinner__finish">
          <Image src={toSrc} />
        </div>
      </div>
      <div className="spinner__text">{text}</div>
    </div>
  </div>
);

export default Spinner;
