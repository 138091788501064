import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import { fetchTransactions } from "./api";

export default {
  getTransactions(params) {
    return {
      types: generateFetchTypes(Types.TRANSACTIONS_REQUEST),
      request: fetchTransactions,
      requireAuth: true,
      params,
    };
  },
};
