import React, { useState, createRef, useEffect } from "react";
import moment from "moment";
import { Text, colors, Flex } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import DatePicker from "components/DatePicker";
import RadioButtonGroup from "components/Form/RadioButton/radio-button-group";
import { SearchDateWrapper } from "../styled-components";
import {
  DATE_FORMAT,
  MOVE_IN_OPTIONS,
  DISPLAY_DATE_FORMAT,
} from "../constants";
import { defaultFromDate, generateParams } from "../utils";

const MoveIn = ({
  handleSaveSeekerPreference,
  moveInDate = null,
  moveInType = null,
}) => {
  const [moveInValue, setMoveInValue] = useState(moveInType);
  const [fromDate, setFromdate] = useState(moveInDate || defaultFromDate);
  const moveInOptions = translate(MOVE_IN_OPTIONS);

  const datePickerRef = createRef();

  const handleMoveInTypeChange = (value) => {
    const date = moment(fromDate).format(DATE_FORMAT);
    const params = generateParams(value, date);
    setMoveInValue(value);
    handleSaveSeekerPreference(params);
  };

  const handleMoveInDateChange = (moveInSelected) => {
    const date = moveInSelected ? moveInSelected.format(DATE_FORMAT) : null;
    setFromdate(date);
    handleSaveSeekerPreference({ moveInDate: date });
  };

  useEffect(() => {
    if (datePickerRef.current && !moveInDate && moveInValue === 3) {
      datePickerRef.current.scrollIntoView();
    }
    setMoveInValue(moveInType);
  }, [moveInType]);

  return (
    <div data-qa="seeker-preferences-move-in">
      <RadioButtonGroup
        initialValue={moveInType}
        items={moveInOptions}
        name="move-in"
        onChangeCallback={handleMoveInTypeChange}
        required={true}
      />
      {moveInValue === 3 && (
        <SearchDateWrapper ref={datePickerRef}>
          <Flex alignItems="center">
            <DatePicker
              data-qa="seeker-preferences-move-in-date-pickers"
              date={moment(fromDate)}
              displayFormat={DISPLAY_DATE_FORMAT}
              id="seeker-preferences-move-in-picker"
              numberOfMonths={1}
              onDateChange={handleMoveInDateChange}
            />
            <Text body="12-Bold" color={colors.green}>
              {translate("seeker.preferences.movein.modify")}
            </Text>
          </Flex>
        </SearchDateWrapper>
      )}
    </div>
  );
};

export default MoveIn;
