class EmptyState {
  constructor(entity) {
    this.entity = entity || {};
  }

  get description() {
    return this.entity.description || "";
  }

  get title() {
    return this.entity.title || "";
  }

  toJSON() {
    return {
      description: this.description,
      title: this.title,
    };
  }
}

class StatusCounter {
  constructor(entity) {
    this.entity = entity || {};
  }

  get id() {
    return this.entity.id || 0;
  }

  get label() {
    return this.entity.label || "";
  }

  get count() {
    return this.entity.count || 0;
  }

  get focus() {
    return this.entity.focus || false;
  }

  get emptyState() {
    return new EmptyState(this.entity.empty_state).toJSON();
  }

  toJSON() {
    return {
      id: this.id,
      label: this.label,
      count: this.count,
      focus: this.focus,
      emptyState: this.emptyState,
    };
  }
}

export default StatusCounter;
