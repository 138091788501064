import React, { useState, useEffect, useRef } from "react";

import {
  Text,
  Flex,
  BADI_COLORS,
  Icon,
  BREAKPOINTS_PX,
  useMatchMedia,
} from "@badi/badi-components";

import {
  ActionBar,
  ImageInput,
  SendButton,
  TextArea,
  TextAreaWrapper,
  UploadImage,
  Root,
} from "./styled-components";

import { AVATAR_SIZE, KEY_CODE_ENTER } from "./constants";
import { getFileFromEvent, sanitizeString, isValidMessage } from "./utils";

const NewChatMessage = ({
  sendMessageText,
  onSubmit,
  disabled,
  uploadImage = () => {},
  avatar = null,
  placeholder = "",
}) => {
  const formRef = useRef();
  const inputRef = useRef(null);

  const [canSendMessage, setCanSendMessage] = useState(false);
  const [textMessage, setTextValue] = useState("");
  const [isBusy, setBusyStatus] = useState(false);
  const [isNotSmallMq] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);

  const handleChange = (event) => {
    const { value } = event.target;

    setCanSendMessage(isValidMessage(sanitizeString(value)));
    setTextValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!canSendMessage) return;

    onSubmit(sanitizeString(textMessage));
    setTextValue("");
    setCanSendMessage(false);
  };

  const handleImageUpload = (event) => {
    setBusyStatus(true);
    const file = getFileFromEvent(event);

    return uploadImage(file).then(() => {
      setBusyStatus(false);
    });
  };

  const handleKeyPressed = (e) => {
    if (e.keyCode === KEY_CODE_ENTER) {
      e.preventDefault();
      formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressed);
    return () => {
      document.removeEventListener("keydown", handleKeyPressed);
    };
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  });

  const sendButtonColor = canSendMessage
    ? BADI_COLORS.BRAND.PRIMARY.DEFAULT
    : BADI_COLORS.NEUTRAL.NEUTRAL_50;

  return (
    <Root disabled={disabled}>
      <Flex alignItems="flexEnd" gutter={1}>
        <TextAreaWrapper grow={1} onSubmit={handleSubmit} ref={formRef}>
          <TextArea
            disabled={disabled}
            name="textMessage"
            onChange={handleChange}
            placeholder={placeholder}
            ref={inputRef}
            value={textMessage}
          />
          <ActionBar>
            <Flex alignItems="center" justify="spaceBetween">
              <UploadImage
                disabled={disabled}
                htmlFor="uploadImage"
                isBusy={isBusy}
              >
                <Icon color={BADI_COLORS.NEUTRAL.NEUTRAL_50} name="camera" />
                <ImageInput
                  disabled={disabled}
                  id="uploadImage"
                  name="uploadImage"
                  onChange={handleImageUpload}
                />
              </UploadImage>
              <SendButton disabled={!canSendMessage || disabled} type="submit">
                <Text body={4} color={sendButtonColor} inline={true}>
                  {sendMessageText}
                </Text>
              </SendButton>
            </Flex>
          </ActionBar>
        </TextAreaWrapper>
        {isNotSmallMq && <div basis={AVATAR_SIZE}>{avatar}</div>}
      </Flex>
    </Root>
  );
};

export default NewChatMessage;
