import React, { memo, useEffect, useState } from "react";

const secondsToMMSS = (seconds) =>
  new Date(seconds * 1000)
    .toUTCString()
    .match(/(\d\d:\d\d[^:])/)[0]
    .trimRight();

const Timer = ({
  dataQa = null,
  start = 0,
  reverse = false,
  onEnd = () => {},
}) => {
  const [seconds, setSeconds] = useState(start);

  useEffect(() => {
    const timer = setInterval(() => {
      if (reverse) {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          onEnd();
        }
      } else {
        setSeconds(seconds + 1);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  return <div data-qa={dataQa}>{secondsToMMSS(seconds)}</div>;
};

export default memo(Timer);
