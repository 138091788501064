import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "ROOM_REQUEST",
  "ROOM_SUCCESS",
  "ROOM_ERROR",
  "CLEAR_ROOM",
  "ROOMOWNER_REQUEST",
  "ROOMOWNER_ERROR",
  "ROOMOWNER_SUCCESS",
  "ROOM_SIMILAR_REQUEST",
  "ROOM_SIMILAR_ERROR",
  "ROOM_SIMILAR_SUCCESS",
  "ROOM_UPDATE",
]);
