import { getCookie, setCookie } from "base/shared/CookiesHandler";
import {
  DOWNLOAD_APP_BANNER_COOKIE,
  DOWNLOAD_BANNER_VISIBLE,
} from "./constants";

export const saveToCookies = ({ name = DOWNLOAD_APP_BANNER_COOKIE, value }) => {
  const expiresIn = new Date();

  expiresIn.setMonth(expiresIn.getMonth() + 1);
  setCookie(name, value, expiresIn);
};

export const isBannerVisible = () => {
  let type = getCookie(DOWNLOAD_APP_BANNER_COOKIE);

  if (typeof type == "undefined") {
    type = DOWNLOAD_BANNER_VISIBLE.YES;
    saveToCookies({ value: DOWNLOAD_BANNER_VISIBLE.YES });
  }

  return Boolean(type);
};

export const removeBanner = () => {
  saveToCookies({ value: DOWNLOAD_BANNER_VISIBLE.NO });
};
