import Plans from "./plans-model";

class MetricEvolution {
  constructor(data) {
    this.entity = data || {};
  }

  get labels() {
    return Object.keys(this.entity.evolution || {});
  }

  get data() {
    return Object.values(this.entity.evolution || {});
  }

  get plans() {
    return new Plans(this.entity.plans);
  }

  toJSON() {
    return {
      labels: this.labels,
      data: this.data,
      plans: this.plans.toJSON(),
    };
  }
}

export default MetricEvolution;
