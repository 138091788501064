import { isProductionEnvironment } from "base/shared/Env/env";
import { LOAD_CALLBACK_NAME, SITE_URL } from "./constants";

export const getSiteUrl = (siteId) => {
  const version = isProductionEnvironment() ? "enterprise" : "api";

  return SITE_URL.replace("%version%", version)
    .replace("%site%", siteId)
    .replace("%callback%", LOAD_CALLBACK_NAME);
};

export const addStylesTag = ({ id, styles }) => {
  if (document.getElementById(id)) return;

  const css = document.createElement("style");
  css.id = id;
  css.type = "text/css";
  css.innerHTML = styles;
  document.head.appendChild(css);
};
