import React from "react";

import { Spacer, Flex } from "@badi/badi-components";

import { SEEKER_USER_ROLE } from "datamodel/User/constants";
import BookingRequestSummary from "components/booking-request-summary";

import { translate } from "base/shared/Localization";

import StatusBadge from "../booking-status-badge";
import ExpirationBadge from "../booking-expiration-badge";
import BookingStepLayout from "../step-layout";
import RoomListerSummary from "./room-lister-summary";
import { getStatusOnCheckoutFlow, getFormattedDate } from "./utils";
import CancellationPolicyLink from "./components/cancellation-policy-link";

const PaymentCheckout = ({ title = "", bookingRequestDetail }) => {
  const {
    expirationText,
    moveIn,
    moveOut,
    price = {},
    room,
    lister,
    status,
    lengthInMonths,
    exactMonthsLength,
    bookingValue,
  } = bookingRequestDetail;

  const { method, ...priceWithoutMethod } = price;
  const mappedStatus = getStatusOnCheckoutFlow(status);

  return (
    <BookingStepLayout
      description={translate("recovery.payment_checkout_subtitle")}
      sidebar={[
        <Spacer top={2}>
          <RoomListerSummary lister={lister} room={room} userRole="seeker" />
        </Spacer>,
        <Spacer top={4}>
          <CancellationPolicyLink />
        </Spacer>,
      ]}
      sidebarTitle={translate("recovery.payment_booking_request_details")}
      title={translate(title)}
    >
      <Spacer bottom={3}>
        <Flex gutter={1} key="badges">
          <StatusBadge status={mappedStatus} userRole={SEEKER_USER_ROLE} />
          {expirationText && <ExpirationBadge expiration={expirationText} />}
        </Flex>
      </Spacer>
      <BookingRequestSummary
        bookingValue={bookingValue}
        exactMonthsLength={exactMonthsLength}
        key="booking-request-summary"
        lengthInMonths={lengthInMonths}
        moveIn={getFormattedDate(moveIn)}
        moveOut={getFormattedDate(moveOut)}
        price={priceWithoutMethod}
        room={room}
        userRole="seeker"
      />
    </BookingStepLayout>
  );
};

export default PaymentCheckout;
