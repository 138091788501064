import { connect } from "react-redux";

import RequirementsActions from "datamodel/Requirements/actions";
import { getUserId } from "datamodel/User/selectors";

import FindYourMatch from "./find-your-match";

export default connect(
  (state) => ({
    isLoggedIn: !!getUserId(state),
  }),
  (dispatch) => ({
    onLogin: () => dispatch(RequirementsActions.setLogin(true)),
  }),
)(FindYourMatch);
