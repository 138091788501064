import styled from "styled-components";
import { BADI_COLORS, mediaquery } from "@badi/badi-components";

const getColor = ({ disabled }) =>
  disabled ? BADI_COLORS.NEUTRAL.NEUTRAL_10 : BADI_COLORS.NEUTRAL.NEUTRAL_80;

export const SuggestText = styled.span`
  display: inline-block;
  display: -webkit-box;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${getColor};
  font-weight: bold;
`;

export const SuggestTag = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;

  display: inline-block;
  white-space: nowrap;
  border-radius: 4px;
  background: transparent;

  color: ${getColor};

  ${mediaquery.lg`
    font-size: 15px;
    line-height: 24px;
  `}
`;

export const SuggestOption = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const Root = styled.div`
  width: calc(100% - 48px);
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};

  ::after {
    position: absolute;
    content: "";
    left: 16px;
    top: 0px;
    height: 20px;
    width: 15px;
    background: url("${({ icon }) => icon}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;
