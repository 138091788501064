import React, { Component } from "react";

import { Choice, Counter, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

import FilterSectionWrapper from "../../../wrappers/filter-section-wrapper";
import FilterSubSectionWrapper from "../../../wrappers/filter-sub-section-wrapper";

import "./FlatmatesExt";
import {
  FormSectionChoices,
  FormSectionChoiceElement,
} from "../styled-components";

class FlatmatesFields extends Component {
  handleGenderChange = (e) => {
    const { onGenderChange, selectedGenders } = this.props;
    const genderId = parseInt(e.currentTarget.value, 10);

    if (isNaN(genderId)) {
      return;
    }

    let updatedSelectedGenders = [...selectedGenders];

    if (selectedGenders.includes(genderId)) {
      updatedSelectedGenders = selectedGenders.filter(
        (selectedGender) => selectedGender !== genderId,
      );
    } else {
      updatedSelectedGenders.push(genderId);
    }

    onGenderChange(updatedSelectedGenders);
  };

  render() {
    const { selectedGenders, genders, onTenantsChange, tenants } = this.props;

    return (
      <FilterSectionWrapper title={translate("search.filters.flatmates")}>
        <FilterSubSectionWrapper
          title={translate("search.filters.flatmates.tenants.title")}
        >
          <div className="SearchFlatmatesForm__Tenants">
            <Text body={3} inline={true}>
              {translate("search.filters.flatmates.tenants.description")}
            </Text>
            <Counter
              id="flatmates"
              max={10}
              min={0}
              name="flatmates"
              onChange={onTenantsChange}
              value={tenants}
            />
          </div>
        </FilterSubSectionWrapper>
        <FilterSubSectionWrapper
          title={translate("search.filters.flatmates.gender.title")}
        >
          <FormSectionChoices>
            {genders.map((gender) => (
              <FormSectionChoiceElement>
                <Choice
                  checked={selectedGenders.includes(gender.id)}
                  id={`gender-${gender.id}`}
                  key={gender.id}
                  name="gender"
                  onChange={this.handleGenderChange}
                  type="checkbox"
                  value={gender.value}
                >
                  {gender.title}
                </Choice>
              </FormSectionChoiceElement>
            ))}
          </FormSectionChoices>
        </FilterSubSectionWrapper>
      </FilterSectionWrapper>
    );
  }
}

FlatmatesFields.defaultProps = {
  tenants: 0,
};

export default FlatmatesFields;
