let envVars;

const getEnvVars = (val) => {
  if (typeof envVars === "undefined") {
    if (val) {
      envVars = val;
    } else if (typeof window === "undefined") {
      envVars = process && process.env ? process.env : undefined;
    } else {
      envVars = window.__ENV__;
    }
  }

  return envVars;
};

export const isProductionEnvironment = () =>
  getEnvVars().NODE_ENV === "production";

export const isDevelopmentEnvironment = () =>
  getEnvVars().NODE_ENV === "development";

export default getEnvVars;
