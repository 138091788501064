import React from "react";
import { withFormsy } from "formsy-react";

import CheckBox from "./CheckBox";

class CheckBoxWithFormsy extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const { setValue } = this.props;

    setValue(value);
  }

  render() {
    return <CheckBox onChange={this.onChange} {...this.props} />;
  }
}

export default withFormsy(CheckBoxWithFormsy);
