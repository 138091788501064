import { connect } from "react-redux";

import Actions from "datamodel/User/actions";
import {
  setCountryAction,
  updateCountryAction,
} from "app/datamodel/CountryVat/actions";
import RoomActions from "datamodel/RoomList/actions";
import FeaturesActions from "datamodel/Features/actions";
import {
  getCountryVatCountryCode,
  getCountryVatSpainOverseas,
} from "app/datamodel/CountryVat/selectors";
import ProfileForm from "./ProfileForm";

export default connect(
  (state) => ({
    error: state.User.error,
    fetching: state.User.fetching,
    profileCallback: state.Requirements.profile.callback,
    token: state.Auth.access_token,
    user: state.User,
    draftId: state.RoomList.id,
    countryCode: getCountryVatCountryCode(state),
    spainOverseas: getCountryVatSpainOverseas(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    return {
      ...stateProps,
      ...ownProps,
      ...dispatchProps,
      setCountryVat: ({ countryCode, spainOverseas }) =>
        dispatch(setCountryAction({ countryCode, spainOverseas })),
      updateCountryVat: ({ countryCode, spainOverseas }) =>
        dispatch(updateCountryAction({ countryCode, spainOverseas })),
      saveProfile: (params) =>
        dispatch(Actions.saveProfile(stateProps.token, params)),
      resetRoomList: () => dispatch(RoomActions.resetRoomList()),
      getDrafts: () =>
        dispatch(RoomActions.getDrafts({ token: stateProps.token })),
      getFeatures: () =>
        dispatch(
          FeaturesActions.getFeatures({
            token: stateProps.token,
            isLoggedUser: true,
          }),
        ),
      updateProfile: (fieldName, value) =>
        dispatch(Actions.updateProfile(stateProps.token, fieldName, value)),
    };
  },
)(ProfileForm);
