import createReducer from "base/shared/createReducer";

import Types from "../types";
import { SocketModel } from "../models";

function setStatus(state, action) {
  const status = action.result;
  const newState = { ...state, status };

  switch (status) {
    case 1:
      newState.retries = state.retries + 1;
      newState.error = newState.retries > 3;
      break;
    case 0:
      newState.retries = 0;
      break;
    default:
  }

  return newState;
}

function setSocket(state, action) {
  return {
    ...state,
    socket: action.result,
    status: 2,
  };
}

const actionHandlers = {
  [Types.SET_STATUS]: setStatus,
  [Types.SET_SOCKET]: setSocket,
};

export default createReducer(actionHandlers, { ...SocketModel });
