import React from "react";
import { withFormsy } from "formsy-react";
import Counter from "./Counter";

class CounterWithFormsy extends React.PureComponent {
  onChange = (data) => this.props.setValue(data);

  render() {
    const { setValue, ...rest } = this.props;
    return <Counter onChange={this.onChange} {...rest} />;
  }
}

export default withFormsy(CounterWithFormsy);
