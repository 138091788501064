import React, { Component } from "react";

import UserFeedback from "base/shared/user-feedback";

import { publishDraft, updateRoom, saveDraft } from "datamodel/Room/api";
import { unparseBenefits } from "datamodel/RoomList/utils";

import { openPhoneVerificationModal } from "components/Verification/Phone";
import { openRoomPublishedModal } from "components/published-listing";

import {
  openCompleteProfileModal,
  COMPLETE_PROFILE_FROM,
} from "components/complete-profile";
import { mapRoomData } from "../utils";
import RoomForm from "../components/room-form";

const STATUS = {
  INITIAL: 0,
  FETCHING: 1,
  PUBLISHING: 2,
  SAVED: 3,
  ERROR: 4,
  IDLE: 5,
};

const isFormDisabled = (status = -1) => {
  return [STATUS.FETCHING, STATUS.PUBLISHING, STATUS.SAVED].some(
    (item) => item === status,
  );
};

class NewRoom extends Component {
  state = {
    status: STATUS.IDLE,
    waitForModeration: false,
  };

  componentDidMount() {
    const {
      getPendingDraft,
      roomStatus,
      roomId,
      draftId,
      getPendingDraftById,
    } = this.props;

    if (draftId) {
      getPendingDraftById(draftId);
    } else if (!roomStatus) {
      getPendingDraft();
      return;
    }
  }

  onPhoneVerificationClose = () => {
    this.setState({ status: STATUS.IDLE });
    this.props.setPhone(false, null);
  };

  handleError = (error) => {
    this.setState({ status: STATUS.ERROR, waitForModeration: false });
    UserFeedback.exception(
      "Whoops!",
      "Something went wrong, try again or contact support.",
      error,
    );
  };

  showCompleteProfile = () => {
    openCompleteProfileModal({
      onSuccess: () => this.props.onEditProfile(),
      onCancel: () => {
        this.setState({ status: STATUS.IDLE });
      },
      from: COMPLETE_PROFILE_FROM.DRAFT,
    });
  };

  handleOnPublishModalClose = () => {
    const { setLister, onRoomPublished } = this.props;
    setLister(true);
    onRoomPublished();
  };

  publishDraft = async () => {
    const { token, roomId } = this.props;

    try {
      this.setState({ waitForModeration: true });
      await publishDraft(token, roomId);

      this.setState({ status: STATUS.SAVED });
    } catch (error) {
      this.handleError(error);
    }
  };

  handleSuccessfulModeration = () => {
    const { roomId, onSuccessfulModeration } = this.props;
    this.handleOnPublishModalClose();

    openRoomPublishedModal({
      roomId,
    });
    onSuccessfulModeration();
  };

  handleStepChange = async (payload = null) => {
    if (!payload) return;

    if (this.state.draftPublished) return;

    const { token, roomId, setId, fields } = this.props;

    const params = mapRoomData({ ...fields, ...payload });

    if (!Array.isArray(params.benefits)) {
      params.benefits = unparseBenefits(params.benefits);
    }

    try {
      const { id } = await saveDraft(token, params, roomId);
      if (payload.saveDraftCallback) {
        payload.saveDraftCallback();
      }

      this.setState({ status: STATUS.IDLE });

      // if the draft saved is not the same as the current one
      if (id !== roomId) setId(id);
    } catch (error) {
      this.handleError(error);
    }
  };

  handleSubmit = async (payload = {}) => {
    // save the last step data and then go to the publish logic
    this.setState({ status: STATUS.PUBLISHING });
    await this.handleStepChange(payload);

    const { confirmedPhone, isUserGhost, roomId, fields } = this.props;

    const onPhoneVerificationSuccess = isUserGhost
      ? this.showCompleteProfile
      : this.publishDraft;

    if (!confirmedPhone) {
      openPhoneVerificationModal({
        onSuccess: onPhoneVerificationSuccess,
        onClose: this.onPhoneVerificationClose,
      });
    } else if (isUserGhost) {
      this.showCompleteProfile();
    } else if (this.state.draftPublished) {
      try {
        const params = mapRoomData({ ...fields, ...payload });
        await updateRoom(roomId, params);
        this.setState({ status: STATUS.SAVED, draftPublished: true });
      } catch (error) {
        this.handleError(error);
        this.setState({ waitForModeration: false });
      }
    } else this.publishDraft();
  };

  disableWaitForModeration = () => this.setState({ waitForModeration: false });

  render() {
    const {
      initialStep,
      roomStatus,
      registerOnLeaving,
      onLeaveFlow,
      isDuplicate,
      onUnsuccessfulModeration,
      roomId,
    } = this.props;
    const { status, waitForModeration } = this.state;

    return (
      <RoomForm
        allowCanceling={true}
        disableWaitForModeration={this.disableWaitForModeration}
        disabled={isFormDisabled(status)}
        initialStep={initialStep}
        isDuplicate={isDuplicate}
        onLeaveFlow={onLeaveFlow}
        onStepChange={this.handleStepChange}
        onSubmit={this.handleSubmit}
        onSuccessfulModeration={this.handleSuccessfulModeration}
        onUnsuccessfulModeration={onUnsuccessfulModeration}
        registerOnLeaving={registerOnLeaving}
        roomId={roomId}
        status={roomStatus}
        waitForModeration={waitForModeration}
      />
    );
  }
}

NewRoom.defaultProps = {
  draftId: 0,
};

export default NewRoom;
