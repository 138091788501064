import { getDomain, getRelativePath } from "base/shared/Utils";

const RedirectToUrl = ({ location, router }) => {
  const { query = {} } = location;
  const { q } = query;

  let url;
  try {
    url = new URL(q);
  } catch (_) {
    router.push(getRelativePath("/"));
    return null;
  }
  const isInternalLink = url.origin === getDomain();

  if (isInternalLink) {
    router.push(`${url.pathname}${url.search}`);
  } else {
    router.push(getRelativePath("/"));
  }

  return null;
};

export default RedirectToUrl;
