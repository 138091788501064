import { getRelativePath } from "base/shared/Utils";

const BASE_PATH = { EDIT: "/users/edit", MAIN: "/main-profile" };

export const NEXT_ACTIONS = {
  LIST_ROOM: "list",
  PUBLISH_ROOM: "publish",
  ENQUIRY: "enquiry",
};

export const getMainProfilePath = (destination) => {
  const queryparams = destination
    ? `?destination=${encodeURIComponent(destination)}`
    : "";

  return getRelativePath(`${BASE_PATH.MAIN}${queryparams}`);
};

export const getEditProfilePath = ({ nextAction, value = "" } = {}) => {
  let queryparams = "?";

  switch (nextAction) {
    case NEXT_ACTIONS.LIST_ROOM:
      queryparams += "list=true";
      break;
    case NEXT_ACTIONS.PUBLISH_ROOM:
      queryparams += "publish=true";
      break;
    case NEXT_ACTIONS.ENQUIRY:
      queryparams += `destination=${encodeURIComponent(value)}`;
      break;
    default:
      queryparams = "";
  }

  return getRelativePath(`${BASE_PATH.EDIT}${queryparams}`);
};

export const getMyProfilePath = () => {
  return getRelativePath("/my-profile");
};
