import React from "react";
import {
  BREAKPOINTS_PX,
  InfoCard,
  InfoCardWide,
  Spacer,
  MatchMedia,
} from "@badi/badi-components";
import { Link } from "react-router";

import { translate } from "base/shared/Localization";
import { getRelativePath } from "base/shared/Utils";
import { CardLink, CardLinkBackground } from "../styled-components";

const ListARoom = () => (
  <CardLink as={Link} to={getRelativePath("/list")}>
    <MatchMedia query={`(max-width: ${BREAKPOINTS_PX.md})`}>
      {(matches) => {
        const Component = matches ? InfoCard : InfoCardWide;
        return (
          <Spacer top={3}>
            <CardLinkBackground>
              <Component
                content={translate("overview.empty.listRoom.description")}
                icon={"list-room"}
                title={translate("overview.empty.listRoom.title")}
              />
            </CardLinkBackground>
          </Spacer>
        );
      }}
    </MatchMedia>
  </CardLink>
);

export default ListARoom;
