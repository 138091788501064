import styled, { css } from "styled-components";

import { unitizedPx, BADI_COLORS } from "@badi/badi-components";
import { BUTTON_SIZES } from "./constants";

const smallStyles = css`
  height: ${unitizedPx(5)};
  font-size: ${unitizedPx(1.5)};
  padding: ${unitizedPx(1.25)} ${unitizedPx(3)};
`;

const regularStyles = css`
  height: ${unitizedPx(6)};
  padding: ${unitizedPx(1.75)} ${unitizedPx(3)};
  font-size: ${unitizedPx(1.75)};
`;

export const baseStyles = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: ${({ isIconOnTop }) => (isIconOnTop ? "column" : "row")};
  line-height: ${unitizedPx(2.5)};
  font-weight: 700;

  outline: none;
  border-radius: ${unitizedPx(0.5)};

  color: ${(props) => props.color};
  background: ${(props) => props.background};
  border: ${(props) => props.border || "none"};

  transition: 0.1s ease;
  transition-property: background, color;

  &:active {
    box-shadow:
      0 0 0 1px white,
      0 0 0 2px ${BADI_COLORS.SEMANTIC.INFO.DEFAULT};
  }

  &:hover {
    color: ${(props) => props.color};
  }

  ${({ size }) => (size === BUTTON_SIZES.SMALL ? smallStyles : regularStyles)}
`;

export const BaseButton = styled.button`
  ${baseStyles}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        filter: ${props.hoverFilter || "none"};
        border: ${props.hoverBorder || "none"};
      }
    `}


    &:disabled {
    color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
    background: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
    border: ${(props) => props.disabledBorder || "none"};
    border: none;
    cursor: default;
  }
`;
