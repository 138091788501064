import React from "react";
import { Text } from "@badi/badi-components";
import { StyledSubHeading } from "./styled-components";

const Subheading = ({ content, color, dataQa }) => (
  <Text as={StyledSubHeading} body="10-Medium" color={color} data-qa={dataQa}>
    {content}
  </Text>
);

export default Subheading;
