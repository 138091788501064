import React from "react";

import Report from "components/Icons/Report";
import LeaveConnection from "components/Icons/LeaveConnection";
import ArchiveConnection from "components/Icons/ArchiveConnection";
import Close from "components/Icons/Close";
import ConversationBubbles from "components/Icons/conversation-bubbles";
import Message from "components/Icons/message";

import { ICON_TYPES } from "./utils";

const Icon = ({ type, ...iconProps }) => {
  switch (type) {
    case "report":
      return <Report {...iconProps} />;
    case "leave":
      return <LeaveConnection {...iconProps} />;
    case "archive":
      return <ArchiveConnection {...iconProps} />;
    case "close":
      return <Close {...iconProps} format="large" />;
    case "conversation-bubbles":
      return <ConversationBubbles {...iconProps} />;
    case "message":
      return <Message {...iconProps} />;
    default:
      return null;
  }
};

export default Icon;
