export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_DISPLAY_FORMAT = "dddd, D MMM YYYY";

export const STATUS = {
  INITIAL: 0,
  ERROR: 1,
  INACCURATE: 2,
  OK: 3,
};

export const STATUS_MESSAGES = {
  [STATUS.ERROR]: "booking.new.moveout_error",
  [STATUS.INACCURATE]: "booking.new.moveout_unaccurate",
};

export const INPUT_PLACEHOLDER = {
  [STATUS.ERROR]: "booking.new.input_placeholder.unavailable",
  [STATUS.INACCURATE]: "booking.new.input_placeholder.no_entry",
  [STATUS.OK]: "booking.new.input_placeholder.no_entry",
};
