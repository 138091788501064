import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const CardsNotFoundDescription = styled.div`
  max-width: ${unitizedPx(39.5)};
`;

export const CardsNotFoundWrapper = styled.div`
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  flex-shrink: 0;
  padding: ${unitizedPx(3)};
  height: 100%;
  width: 100%;
`;

export const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  opacity: 0.5;
`;

export const PaginationWrapper = styled.div`
  margin: 0 auto;
`;
