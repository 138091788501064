import React from "react";
import { Flex } from "@badi/badi-components";
import BusIcon from "components/Icons/Bus";
import {
  TransportButton as StyledTransportButton,
  TransportIconOpacity,
} from "./styled-components";

const TransportButton = ({ selected, onClick }) => (
  <StyledTransportButton onClick={onClick} type="button">
    <TransportIconOpacity selected={selected}>
      <Flex alignItems="center" direction="column" justify="center">
        <BusIcon selected={selected} />
      </Flex>
    </TransportIconOpacity>
  </StyledTransportButton>
);

TransportButton.defaultProps = {
  selected: false,
};

export default TransportButton;
