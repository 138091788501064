function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) =>
      reject(`Error reading ${file.name}: ${e.target.result}`);
    reader.readAsDataURL(file);
  });
}

export { getBase64FromFile };
