const ViewersModel = {
  list: [],
  fetching: false,
  fetched: false,
  error: null,
  pagination: {},
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Viewers) {
    return {
      ...ViewersModel,
      error: "There was an error in ViewersModel.",
    };
  }
  return state;
}

export { ViewersModel, setInitialState };
