import React from "react";

import { translate } from "base/shared/Localization";

import {
  Button,
  Flex,
  Heading,
  Text,
  Spacer,
  BUTTON_TYPES,
} from "@badi/badi-components";

const EditRoom = ({ onEditRoom }) => {
  return (
    <Flex direction="column">
      <Heading data-qa="edit-room-republish-title" level="20-Bold">
        {translate("publish.warning_new_settings_title")}
      </Heading>
      <Spacer top={2}>
        <Text body="14-Regular" dataQa="edit-room-republish-subtitle">
          {translate("publish.warning_new_settings_subtitle")}
        </Text>
      </Spacer>
      <Spacer top={7}>
        <Button
          dataQa="edit-room-republish-submit"
          fullWidth={true}
          onClick={onEditRoom}
          variant={BUTTON_TYPES.PRIMARY}
        >
          {translate("publish.warning_new_settings_CTA")}
        </Button>
      </Spacer>
    </Flex>
  );
};

export default EditRoom;
