import React, { useState, useEffect } from "react";
import { BUTTON_TYPES } from "@badi/badi-components";

import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";
import StepperLayout, { Step } from "components/Layout/stepper-layout";
import {
  createBooking,
  createInstantBooking,
  getBookingPreview,
} from "datamodel/booking/api";
import { openBookingLeaveModal } from "components/booking-leave-modal";
import { getInboxPath } from "Sections/inbox/routes";
import { getRecommendationsPath } from "Sections/recommendations/routes";
import { getRoomDetailPath } from "Sections/Room/routes";

import CreatedStep from "../created-step";
import { BOOKING_STEPS } from "../constants";
import { getFlowSteps, sanitizeString } from "./utils";
import { STATUS } from "./constants";

const NewBooking = ({
  connectionId: connectionIdFromUrl,
  currentUserId,
  isInstantBooking = false,
  room = null,
  seekerId = null,
  user = null,
  userRole = null,
  skipMessageStep,
  token = "",
  router,
  roomId = 0,
  getConnectionDetails,
  getRoomDetails,
}) => {
  const [validatedSteps, setValidatedSteps] = useState(new Set());
  const [fields, setFields] = useState({});

  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [connectionId, setConnectionId] = useState(connectionIdFromUrl);
  const [status, setStatus] = useState(STATUS.IN_PROGRESS);

  useEffect(() => {
    if (userRole && !steps.length) {
      const flowSteps = getFlowSteps({
        isInstantBooking,
        userRole,
        skipMessageStep,
      });
      setSteps(flowSteps);
    }
  }, [userRole]);

  useEffect(() => {
    if (isInstantBooking) {
      getRoomDetails({ roomId, isLoggedUser: true, token });
    } else {
      getConnectionDetails(connectionId, token);
    }
  }, []);

  const handleValidStep = ({ id, valid, payload }) => {
    const newValidatedSteps = new Set(validatedSteps);
    if (valid) {
      newValidatedSteps.add(id);
    } else {
      newValidatedSteps.delete(id);
    }

    setValidatedSteps(newValidatedSteps);
    setFields({
      ...fields,
      ...payload,
    });
  };

  const leaveFlow = () => {
    if (seekerId) {
      router.push(getRecommendationsPath({ roomId }));
      return;
    }

    if (roomId) {
      router.push(getRoomDetailPath({ roomId }));
      return;
    }

    router.push(getInboxPath());
  };

  const handleFlowClose = (stepId) => {
    switch (stepId) {
      case BOOKING_STEPS.CREATED:
        leaveFlow();
        return;
      default:
    }

    openBookingLeaveModal({ onLeave: () => leaveFlow() });
  };

  const handleBookingResponse = (response, params) => {
    if (!response || !response.id) {
      throw new Error("booking.new.notifications.create_error_description");
    }

    const { id, price } = response;

    getBookingPreview(params).then(() => {
      setConnectionId(response.connectionId);
      setStatus(STATUS.CREATED);
    });
  };

  const performNewBookingRequest = (params) => {
    // select which request to do
    let newBookingRequest;
    if (isInstantBooking) {
      newBookingRequest = createInstantBooking;
    } else {
      newBookingRequest = createBooking;
    }

    return newBookingRequest(params)
      .then((response) => handleBookingResponse(response, params))
      .catch((error) => {
        UserFeedback.exception(
          "booking.new.notifications.create_error_title",
          error.message,
          error,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBookingRequestSubmission = () => {
    setLoading(true);

    const params = {
      discount: fields.discount,
      token,
      connectionId,
      roomId,
      seekerId,
      moveIn: fields.moveIn,
      moveOut: fields.moveOut,
      monthsLength: fields.monthsLength,
      message: sanitizeString(fields.message) || null,
      useCheckout: true,
    };

    return performNewBookingRequest(params);
  };

  const getContent = () => {
    const fetchedData = !!(room && user && userRole);
    const stepsToRender = fetchedData ? steps : [];

    switch (true) {
      case status === STATUS.CREATED:
        return (
          <CreatedStep
            connectionId={connectionId}
            otherUserName={user?.name}
            userRole={userRole}
          />
        );
      default:
        return stepsToRender.map(
          ({ id, title, render: Render, footer: Footer }) => (
            <Step
              buttonVariant={BUTTON_TYPES.PRIMARY}
              footer={Footer}
              id={id}
              key={id}
              skipMessageStep={skipMessageStep}
              valid={validatedSteps.has(id)}
            >
              <Render
                connectionId={connectionId}
                id={id}
                isInstantBooking={isInstantBooking}
                leaveFlow={leaveFlow}
                onValidation={handleValidStep}
                roomId={roomId}
                seekerId={seekerId}
                title={translate(title)}
                {...fields}
              />
            </Step>
          ),
        );
    }
  };

  const content = getContent();
  const isInProgress = status === STATUS.IN_PROGRESS;

  return (
    <StepperLayout
      disabled={loading}
      onClose={handleFlowClose}
      onSubmit={handleBookingRequestSubmission}
      showProgress={isInProgress}
    >
      {content}
    </StepperLayout>
  );
};

export default NewBooking;

// userRole, room, user
