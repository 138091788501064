import React from "react";
import { Link } from "react-router";

import "./shareExt.css";

const WhatsApp = ({ desktop, target, title }) => (
  <Link
    className="Button__share"
    target="_blank"
    to={`${
      desktop ? "https://web.whatsapp.com/" : "whatsapp://"
    }send?text=${encodeURIComponent(title)}%0a${encodeURIComponent(target)}`}
  >
    <div className="Button__share_whatsapp" />
  </Link>
);

WhatsApp.defaultProps = {
  children: null,
};

export default WhatsApp;
