import { createSelector } from "reselect";

const getListerMetrics = (state) => state.ListerMetrics;

export const getRooms = createSelector(
  getListerMetrics,
  (listerMetrics) => listerMetrics.rooms,
);

export const getFetchedRooms = createSelector(
  getListerMetrics,
  (listerMetrics) => listerMetrics.fetchedRooms,
);

export const getListerHints = createSelector(
  getListerMetrics,
  (listerMetrics) => listerMetrics.listerHints,
);
