import React from "react";

import AuthButton from "../button";

const EmailButton = ({ onClick, children }) => {
  const clickHandler = () => {
    onClick();
  };

  return (
    <AuthButton
      dataQa="email-button"
      iconAlt="email button"
      iconSrc="/assets/icons/auth-email.svg"
      onClick={clickHandler}
    >
      {children}
    </AuthButton>
  );
};

export default EmailButton;
