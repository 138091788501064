import styled from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const HeaderWrapper = styled.div`
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  width: 100%;
  display: flex;
  height: ${unitizedPx(46)};
  padding-bottom: ${unitizedPx(7)};

  ${mediaquery.md`
    height: ${unitizedPx(54)};
  `}

  ${mediaquery.lg`
    height: ${unitizedPx(72)};
  `}
`;
