import React from "react";
import { Flex, Text, Icon, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { IconWrapper } from "./styled-components";

const RoomConnections = ({ connections }) => {
  return (
    <Spacer top={1}>
      <Flex alignItems={"center"} gutter={1}>
        <IconWrapper>
          <Icon
            alt={translate("room.detail.bookings_requested")}
            data-qa="room-connections-icon"
            height={14}
            src={getPublicAsset("icons/green-paper-airplane.svg")}
            width={14}
          />
        </IconWrapper>
        <Text body={2} data-qa="room-connections-count-label">
          {translate("room.detail.contact_counter", {
            smart_count: connections,
          })}
        </Text>
      </Flex>
    </Spacer>
  );
};

export default RoomConnections;
