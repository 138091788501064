import { connect } from "react-redux";
import Actions from "datamodel/SearchFilters/actions";
import RoomsActions from "datamodel/search-rooms/actions";
import {
  getSearchFetchingStatus,
  getMarkers,
  getSearchSelectedRoomId,
  getRoomCardsMarker,
} from "datamodel/search-rooms/selectors";

import {
  getSearchFiltersBounds,
  getSearchFiltersCoordinates,
  getSearchFiltersZoom,
  getSearchFiltersRecalculateBounds,
} from "datamodel/SearchFilters/selectors";

import Map from "./map";

const mapStateToProps = (state) => ({
  fetching: getSearchFetchingStatus(state),
  bounds: getSearchFiltersBounds(state),
  coordinates: getSearchFiltersCoordinates(state),
  zoom: getSearchFiltersZoom(state),
  markers: getMarkers(state),
  selectedRoomId: getSearchSelectedRoomId(state),
  recalculateBounds: getSearchFiltersRecalculateBounds(state),
  shownRoomCardsMarker: getRoomCardsMarker(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectRoomById: (id) => dispatch(RoomsActions.selectRoom(id)),
  setLocation: ({ bounds, coordinates }) =>
    dispatch(Actions.setLocation({ bounds, coordinates })),
  setBoundsRecalculation: () => dispatch(Actions.setBoundsRecalculation(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
