import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "VIEWERS_REQUEST",
  "VIEWERS_SUCCESS",
  "VIEWERS_ERROR",
  "VIEWER_SET_STATUS_REQUEST",
  "VIEWER_SET_STATUS_ERROR",
  "VIEWER_SET_STATUS_SUCCESS",
  "VIEWERS_RESET",
  "VIEWER_CONTACTED",
]);
