import React from "react";
import { Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

import RecentSuggestItem from "./recent-suggest-item";
import { RecentSuggestListTitle } from "./styled-components";

const RecentSuggestList = ({ suggests, onSelect }) => {
  if (!suggests.length) return null;

  return (
    <>
      <RecentSuggestListTitle>
        <Spacer left={1.875}>
          {translate("search.location.options.recent")}
        </Spacer>
      </RecentSuggestListTitle>
      {suggests.map((suggest) => (
        <RecentSuggestItem
          key={suggest.placeId}
          onSelect={onSelect}
          suggest={suggest}
        />
      ))}
    </>
  );
};

export default RecentSuggestList;
