import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";

const fetchDynamicPopup = (params, token) => {
  const { BADI_DYNAMIC_POPUPS } = envVars();
  const serverUrl = getServerUrl(BADI_DYNAMIC_POPUPS);

  return fetch(serverUrl, { params, token });
};

export { fetchDynamicPopup };
