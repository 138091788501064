import React from "react";

import { SEARCH_VIEW } from "datamodel/SearchFilters/static";
import {
  HEADER_PARAMS,
  filterEtQueryParams,
} from "base/shared/tracking-headers";

import {
  SearchMapWrapper,
  SearchMainWrapper,
  SearchResultsWrapper,
} from "./wrappers/styled-components";
import SearchForm from "./filters/forms/form-mapping";
import ResultsList from "./results-list";
import MapRoomCard from "./map-room-card";

import Map from "./map";

const SearchResults = ({ onSelectView, cards, view }) => {
  const handleCloseForm = () => {
    onSelectView(SEARCH_VIEW.list);
  };

  const getSearchParams = () => {
    const {
      keyVal,
      options: { SEARCH_FEED },
    } = HEADER_PARAMS.ROOM_VIEW_SOURCE;
    return filterEtQueryParams().concat([[keyVal, SEARCH_FEED]]);
  };

  switch (view) {
    case SEARCH_VIEW.form:
      return <SearchForm onClose={handleCloseForm} />;
    case SEARCH_VIEW.list:
      return (
        <SearchMainWrapper>
          <SearchResultsWrapper onlyList={true}>
            <ResultsList
              cards={cards}
              handleEtParams={getSearchParams}
              onlyList={true}
            />
          </SearchResultsWrapper>
        </SearchMainWrapper>
      );
    case SEARCH_VIEW.map:
      return (
        <SearchMainWrapper>
          <SearchMapWrapper data-qa="search-map-wrapper">
            <Map overrideLocation={false} />
            <MapRoomCard handleEtParams={getSearchParams} />
          </SearchMapWrapper>
        </SearchMainWrapper>
      );
    case SEARCH_VIEW.both:
      return (
        <SearchMainWrapper>
          <SearchResultsWrapper>
            <ResultsList cards={cards} handleEtParams={getSearchParams} />
          </SearchResultsWrapper>
          <SearchMapWrapper data-qa="search-map-wrapper">
            <Map />
          </SearchMapWrapper>
        </SearchMainWrapper>
      );
    default:
      return null;
  }
};

export default SearchResults;
