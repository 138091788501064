import React, { Fragment } from "react";
import { StatusCard, Spacer, Heading, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

const Verified = ({ email = "", dataQa = "" }) => {
  return (
    <StatusCard dataQa={dataQa} isEditable={false} isVerified={true}>
      <Spacer bottom={3}>
        <Heading level={3}>
          {translate("verifications.email.verified.title")}
        </Heading>
      </Spacer>
      {email && (
        <Fragment>
          <Text body={2}>
            {translate("verifications.email.verified.label")}
          </Text>
          <Spacer top={1}>
            <Text>{email}</Text>
          </Spacer>
        </Fragment>
      )}
    </StatusCard>
  );
};

export default Verified;
