import React, { useState, useEffect } from "react";
import { Flex } from "@badi/badi-components";

import PhoneVerificationForm from "components/Verification/Phone/phone-verification-mapping";
import EmailVerificationForm from "components/Verification/Email/EmailVerificationMapping";
import BenefitsDescriptionCard from "components/benefits-description-card";
import BankAccountForm from "components/bank-account";
import ContactInformationForm from "components/contact-information";
import PaymentProvider from "components/PaymentProvider";
import BadiGuaranteeCard from "components/badi-guarantee-card";
import BookingPreview from "components/booking-preview";

import { translate } from "base/shared/Localization";
import { getOtherUserRole, isLister } from "datamodel/User/utils";
import ExpirationBadge from "../booking-expiration-badge";
import BookingStepLayout from "../step-layout";

const PaymentStep = ({
  getBankAccount,
  getPaymentDetails,
  hasBankAccountNumber = false,
  hasContactInformation = false,
  hasPaymentCard = false,
  hasVerifiedPhone = false,
  hasVerifiedEmail = false,
  title = "",
  id,
  onValidation = () => {},
  bookingRequestDetail,
  userRole,
  message,
}) => {
  const [loaded, setLoaded] = useState(false);
  const isUserLister = isLister(userRole);

  useEffect(() => {
    if (!userRole) return;

    const paymentDetailsRequest = isUserLister
      ? getBankAccount
      : getPaymentDetails;

    paymentDetailsRequest().then(() => {
      setLoaded(true);
    });
  }, [userRole]);

  useEffect(() => {
    const hasPaymentInformation = isUserLister
      ? hasBankAccountNumber && hasContactInformation
      : hasPaymentCard;

    onValidation({
      id,
      valid: hasPaymentInformation && hasVerifiedPhone && hasVerifiedEmail,
    });
  }, [
    hasBankAccountNumber,
    hasContactInformation,
    hasPaymentCard,
    hasVerifiedPhone,
    hasVerifiedEmail,
    userRole,
  ]);

  const otherUserRole = getOtherUserRole(userRole);
  const {
    expirationText,
    moveIn,
    moveOut,
    price,
    room,
    lengthInMonths,
    exactMonthsLength,
    bookingValue,
  } = bookingRequestDetail;
  const sidebar = [
    <Flex direction="row">
      <ExpirationBadge expiration={expirationText} key="badge" />
    </Flex>,
    <BookingPreview
      bookingValue={bookingValue}
      exactMonthsLength={exactMonthsLength}
      isFullInfoBr={!isUserLister}
      key="booking-preview"
      lengthInMonths={lengthInMonths}
      message={message}
      moveIn={moveIn}
      moveOut={moveOut}
      paymentPending={true}
      price={price}
      room={room}
      user={bookingRequestDetail[otherUserRole]}
      userRole={userRole}
    />,
  ];

  if (isUserLister) {
    sidebar.push(<BenefitsDescriptionCard />);
  } else {
    sidebar.push(
      <BadiGuaranteeCard
        alignText={false}
        textDirection="column"
        userRole={userRole}
      />,
    );
  }

  const sidebarTitle = isUserLister
    ? "booking.new.request_details_title_lister"
    : "booking.new.request_details_title";

  return (
    <BookingStepLayout
      sidebar={sidebar}
      sidebarTitle={translate(sidebarTitle)}
      subtitle={translate("booking.new.steps.payment.sub-heading")}
      title={translate(title)}
    >
      {loaded && (
        <Flex direction="column" gutter={2}>
          {isUserLister ? (
            /**
             * * We cannot use React.Fragment because <Flex/> will wrap
             * * Fragment component instead of each real child and gutter
             * * will not be applied
             */
            [
              <BankAccountForm key="bank-account" />,
              <ContactInformationForm key="contact-information" />,
            ]
          ) : (
            <PaymentProvider />
          )}

          <EmailVerificationForm />
          <PhoneVerificationForm />
        </Flex>
      )}
    </BookingStepLayout>
  );
};

export default PaymentStep;
