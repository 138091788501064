import React, { Component } from "react";
import { withFormsy } from "formsy-react";

import SelectMultiple from "./SelectMultiple";

class SelectMultipleWithFormsy extends Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
  }

  onValueChange(value) {
    const { name, getValue, isValidValue, onChange, setValue } = this.props;

    let values = getValue() || [];
    const newValue = parseInt(value, 10);

    if (values.includes(newValue)) {
      values = values.filter((v) => v !== newValue);
    } else {
      values = [...values, newValue];
    }

    setValue(values);
    if (onChange) onChange(name, values, isValidValue(values));
  }

  render() {
    const { getValue, ...rest } = this.props;

    const safeValue = getValue();

    return (
      <SelectMultiple
        {...rest}
        onChange={this.onValueChange}
        value={safeValue}
      />
    );
  }
}

SelectMultipleWithFormsy.defaultProps = {
  onChange: undefined,
};

export default withFormsy(SelectMultipleWithFormsy);
