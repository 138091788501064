import React from "react";
import { useSelector } from "react-redux";

import { BREAKPOINTS_PX, useMatchMedia } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getIsDesktop } from "datamodel/Device/selectors";
import { getInboxPath } from "Sections/inbox/routes";
import { getNewRoomPath } from "Sections/Room/routes";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import { getFavoritesPath } from "Sections/favorites/routes";

import TopBar from "../top-bar-base";
import Element from "../topbar-element";
import Menu from "./menu";
import Logo from "../Logo/Logo";
import SearchBar from "../search-bar";
import UserMenu from "../user-menu";
import Search from "../Search";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.lg})`];

const SeekerTopBar = ({
  city = null,
  isLister,
  logout,
  messages,
  picture,
  switchUser,
  withNavigationWrapper = true,
}) => {
  const desktopDevice = useSelector(getIsDesktop);
  const [isDesktop] = useMatchMedia(QUERIES, [desktopDevice]);

  const switchToLister = () => switchUser("lister");

  return (
    <TopBar
      dataQa="topbar-seeker"
      withNavigationWrapper={withNavigationWrapper}
    >
      {isDesktop ? (
        <>
          <Logo dataQa="topbar-logo" />
          <SearchBar dataQa="topbar-search-bar" initialValue={city} />
          <Element
            dataQa="topbar-seeker-menu-list-rent"
            name="topbar_seeker_list"
            position="right"
            to={isLister ? getNewRoomPath() : getRentRoomPath()}
          >
            {translate("topbar.element.rentroom.title")}
          </Element>
          <Element
            dataQa="topbar-seeker-menu-favorites"
            name="topbar_seeker_favorites"
            position="right"
            to={getFavoritesPath()}
          >
            {translate("topbar.element.favorite.title")}
          </Element>
          <Element
            dataQa="topbar-seeker-menu-inbox"
            notifications={messages}
            position="right"
            to={getInboxPath()}
          >
            {translate("topbar.element.inbox.title")}
          </Element>
          <Element
            dataQa="topbar-seeker-menu-help"
            position="right"
            to={translate("sitemap.faqs.new.link")}
          >
            {translate("topbar.element.help.title")}
          </Element>
          <Element position="right">
            <UserMenu
              data-qa="topbar-seeker-menu"
              logout={logout}
              mode="seeker"
              picture={picture}
              switchMode={isLister ? switchToLister : null}
            />
          </Element>
        </>
      ) : (
        <>
          <Menu
            isLister={isLister}
            logout={logout}
            messages={messages}
            onSwitchUser={switchToLister}
          />
          <Logo dataQa="topbar-logo" />
          <Search dataQa="topbar-search-button" initialValue={city} />
        </>
      )}
    </TopBar>
  );
};

export default SeekerTopBar;
