import React from "react";
import {
  Text,
  Heading,
  Flex,
  Spacer,
  BADI_COLORS,
} from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import ListerCards from "./lister-cards";

import { Card } from "../../styled-components";

const HowToRedeem = ({ isLister, isWebView, onRedeemClick, products }) => (
  <>
    <Heading data-qa="how-to-redeem-header" level="24-Bold">
      {translate("referrals.content.redeem.title")}
    </Heading>
    <Spacer top={2} />
    {isLister ? (
      <ListerCards
        isWebView={isWebView}
        onRedeemClick={onRedeemClick}
        products={products}
      />
    ) : (
      <Card>
        <Flex
          alignItems="center"
          data-qa="redeem-seeker-card"
          direction="column"
          fullHeight={true}
          gutter={1}
          justify="center"
        >
          <Text body="16-Regular" dataQa="redeem-seeker-header">
            {translate("referrals.content.redeem.seeker.header")}
          </Text>
          <Heading
            color={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
            data-qa="redeem-seeker-title"
            level="20-Bold"
          >
            {translate("referrals.content.redeem.seeker.booking_fee")}
          </Heading>
          <Text body="12-Regular" dataQa="redeem-seeker-description">
            {translate("referrals.content.redeem.seeker.description")}
          </Text>
        </Flex>
      </Card>
    )}
  </>
);

export default HowToRedeem;
