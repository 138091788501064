import { connect } from "react-redux";

import UserActions from "app/datamodel/User/actions";
import { getUserConfirmation } from "app/datamodel/User/selectors";
import { getToken } from "app/datamodel/Auth/selectors";
import { getPaymentEmail } from "app/datamodel/Payment/selectors";
import { getContactEmail } from "app/datamodel/Payouts/selectors";
import { getPayment } from "datamodel/Payment/actions";

import {
  checkEmailConfirmation,
  requestEmailConfirmation,
} from "datamodel/User/api";

import EmailVerification from "./email-verification";

const mapStateToProps = (state) => ({
  authtoken: getToken(state),
  email: getPaymentEmail(state) || getContactEmail(state),
  verified: getUserConfirmation(state, "email"),
});

const mapDispatchToProps = {
  setConfirmation: UserActions.setConfirmation,
  getPaymentDetails: getPayment,
};

// this is not connected to the store but if we centralized it there the component is pure
const mergeProps = (state, dispatch, ownProps) => ({
  ...state,
  ...dispatch,
  ...ownProps,
  requestEmailConfirmation: (data) =>
    requestEmailConfirmation(state.authtoken, data),
  checkEmailConfirmation: (data) =>
    checkEmailConfirmation(state.authtoken, data),
  onSuccess: () => dispatch.getPaymentDetails(state.authtoken),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(EmailVerification);
