import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import ReviewDetailed from "./models/review-detailed-model";
import ReviewsSummary from "./models/reviews-summary-model";
import { parseReviewToApi } from "./utils";

export const getReview = ({ reviewId, sgid, token }) => {
  const { BADI_REVIEWS } = envVars();

  const parsedParams = sgid ? { sgid } : null;

  const serviceUrl = getServerUrl(`${BADI_REVIEWS.replace(":id", reviewId)}`);
  return fetch(serviceUrl, {
    token,
    params: parsedParams,
  }).then((data) => new ReviewDetailed(data).toJSON());
};

export const submitReview = ({ reviewId, sgid, token, params }) => {
  const { BADI_REVIEWS } = envVars();

  const parsedParams = parseReviewToApi(params);
  const sgidParam = sgid ? { sgid } : {};

  const serviceUrl = getServerUrl(`${BADI_REVIEWS.replace(":id", reviewId)}`);

  return fetch(serviceUrl, {
    method: "PUT",
    token,
    params: { ...Object.assign(parsedParams, sgidParam) },
  }).then((data) => new ReviewDetailed(data).toJSON());
};

export const getPendingReviews = ({ token }) => {
  const { BADI_REVIEWS_PENDING } = envVars();

  const serviceUrl = getServerUrl(BADI_REVIEWS_PENDING);
  return fetch(serviceUrl, {
    token,
  }).then(({ data }) =>
    data.reviews.map((review) => new ReviewDetailed(review).toJSON()),
  );
};

export const getReviewsForUser = ({ userId, token }) => {
  const { BADI_REVIEWS_USER } = envVars();

  const serviceUrl = getServerUrl(
    `${BADI_REVIEWS_USER.replace(":id", userId)}`,
  );

  return fetch(serviceUrl, {
    token,
  }).then(({ data }) => new ReviewsSummary(data).toJSON());
};

export const getReviewsForRoom = ({ roomId, token }) => {
  const { BADI_REVIEWS_ROOM } = envVars();

  const serviceUrl = getServerUrl(
    `${BADI_REVIEWS_ROOM.replace(":id", roomId)}`,
  );

  return fetch(serviceUrl, {
    token,
  }).then(({ data }) => new ReviewsSummary(data).toJSON());
};
