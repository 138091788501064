import React, { useCallback, useState, useEffect } from "react";

import UserFeedback from "base/shared/user-feedback";
import { getBookingRequest } from "datamodel/booking/api";
import { BOOKING_STATUS } from "datamodel/booking/constants";

import { getRelativePath } from "base/shared/Utils";

import RecoverBookingStepsHandler from "./recover-booking-steps-handler";

const RecoverBooking = ({
  fromConnection = 0,
  requestId,
  router = {},
  token,
}) => {
  const [disabled, setDisabled] = useState(false);

  const [bookingInfo, setBookingInfo] = useState(null);

  const handleError = ({ title = "", message, error }) => {
    UserFeedback.exception(title, message, error);
  };

  const handleFlowClose = () => {
    router.push(getRelativePath("/inbox"));
  };

  useEffect(() => {
    getBookingRequest({ token, requestId })
      .then((bookingRequest) => {
        setBookingInfo(bookingRequest);
        setDisabled(bookingRequest.status === BOOKING_STATUS.EXPIRED);
      })
      .catch((error) => {
        handleError({ message: error.message, error });
      });
  }, []);

  if (bookingInfo) {
    return (
      <RecoverBookingStepsHandler
        bookingInfo={bookingInfo}
        disabled={disabled}
        fromConnection={fromConnection}
        handleError={handleError}
        handleFlowClose={handleFlowClose}
        requestId={requestId}
        router={router}
        token={token}
      />
    );
  }
  return null;
};

export default RecoverBooking;
