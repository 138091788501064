import platform from "platform";
import "isomorphic-fetch";
import envVars from "base/shared/Env";
import { localeISO } from "base/shared/Localization";
import {
  BADI_DEVICE_ID_HEADER,
  badiUniqueIdHeader,
} from "base/shared/badi-device-header";

import { BadiError } from "../Error";

const requiredHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const parseHeaderParams = (params) => {
  const baseHeaders = {
    [BADI_DEVICE_ID_HEADER]: badiUniqueIdHeader.getValue(),
  };
  return Object.keys(params).reduce((prev, key) => {
    const customParams = prev;

    if (typeof params[key] === "undefined") return customParams;

    switch (key) {
      case "ip":
        customParams["X-Forwarded-For"] = params.ip;
        customParams["X-Real-IP"] = params.ip;
        break;
      case "locale":
        customParams["accept-language"] = localeISO(params.locale);
        break;
      case "userAgent":
        customParams["User-Agent"] = params.userAgent;
        break;
      case "secureToken":
        customParams["X-Secure-Token"] = params.secureToken;
        break;
      default:
        customParams[key] = params[key];
    }

    return customParams;
  }, baseHeaders);
};

const fetchLanguages = () => {
  if (typeof navigator.languages !== "undefined") {
    return navigator.languages.join(", ");
  } else if (typeof navigator.language !== "undefined") {
    return navigator.language;
  }
  return null;
};

const getBadiContextHeaders = () => {
  let props = [
    { property: "Badi-Platform", value: "web" },
    { property: "Badi-App-Version", value: envVars().VERSION },
    { property: "Badi-Language", value: localeISO() },
    { property: "accept-language", value: localeISO() },
  ];

  if (typeof window !== "undefined") {
    const additionalProps = [
      {
        property: "Badi-Screen-Size",
        value: `${window.screen.width}x${window.screen.height}`,
      },
      {
        property: "Badi-Inner-Window-Size",
        value: `${window.innerWidth}x${window.innerHeight}`,
      },
      {
        property: "Badi-Os-Version",
        value: platform.os.toString(),
      },
      {
        property: "Badi-Favourite-Language",
        value: typeof navigator !== "undefined" ? fetchLanguages() : null,
      },
    ];
    props = props.concat(additionalProps);
  }
  return props;
};

const fetchBadiContextHeaders = (language) => {
  const header = getBadiContextHeaders(language);
  const validProperties = header.filter((h) => h.value !== null);
  return validProperties.reduce((memo, item) => {
    const values = memo;
    values[item.property] = item.value;
    return values;
  }, {});
};

export const encodeString = (payload) =>
  JSON.stringify(payload).replace(/[\u007F-\uFFFF]/g, (c) =>
    "\\u".concat("0000".concat(c.charCodeAt(0).toString(16)).substr(-4)),
  );

const getQueryStringParams = (params) => {
  if (!params) return "";

  const qsValues = Object.keys(params).map((key) => {
    const value = params[key];
    const isArray = Array.isArray(value);
    if (isArray) {
      const qsArrayValues = value.map((arrayValue) => `${key}[]=${arrayValue}`);
      return qsArrayValues.join("&");
    }

    return `${key}=${params[key]}`;
  });

  return `?${qsValues.join("&")}`;
};

export const getRequestHeader = (method, params, token, additional) => {
  const additionalHeader = parseHeaderParams(additional || {});

  const header = {
    method: method || "GET",
    headers: Object.assign(
      requiredHeaders,
      fetchBadiContextHeaders(),
      additionalHeader,
    ),
  };

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    header.body = params ? encodeString(params) : "";
  }

  if (token) {
    header.headers.Authorization =
      typeof token === "string"
        ? `Bearer ${token}`
        : `${token.type} ${token.token}`;
  }

  return header;
};

const getRequestUrl = (url, method, params) =>
  method === "GET" ? url + getQueryStringParams(params) : url;

const serverFetch = (url, header, res) =>
  fetch(url, header)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return response.json().then((err) => {
        throw new BadiError(err);
      });
    })
    .then((response) => res.status(200).json(response));

const fetchServerData = (dispatch, components, params, additionalActions) => {
  const actions = components.reduce(
    (prev, current) => {
      if (!current) return prev;

      return current.requiredActions
        ? current.requiredActions.concat(prev)
        : prev;
    },
    [...additionalActions],
  );
  const requiredActions = actions.map((action) => dispatch(action(params)));
  return Promise.all(requiredActions);
};

const fetchClientData = (actions, props, node, force = false) => {
  const checkSize = props[node];
  const { params, dispatch } = props;
  if (!checkSize || force) actions.map((action) => dispatch(action(params)));
};

export const fetchRequiredActions = (...args) => {
  const serverContext = !!~args.indexOf("server");
  return serverContext
    ? fetchServerData.apply(this, args)
    : fetchClientData.apply(this, args);
};

export default (
  serviceUrl,
  {
    method,
    params,
    token,
    additionalParams,
    returnParam = {},
    serverResponse,
    requiredParams,
  },
) => {
  if (requiredParams && !params) {
    throw new Error("Bad params for request!");
  }

  const header = getRequestHeader(method, params, token, additionalParams);
  const url = getRequestUrl(serviceUrl, header.method, params);

  if (serverResponse) return serverFetch(url, header, serverResponse);

  return fetch(url, header).then((response) => {
    if (response.ok) {
      if (response.status === 204) {
        return new Promise((resolve) => {
          resolve({ succeed: true, ...returnParam });
        });
      }
      return response.json();
    }
    return response.json().then((err) => {
      throw new BadiError(err);
    });
  });
};
