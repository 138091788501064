import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import envVars from "base/shared/Env";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { checkGoogleConfirmation } from "datamodel/User/api";
import UserActions from "app/datamodel/User/actions";
import GoogleButton from "Sections/auth/forms/authentication-form/components/google-button";

import styles from "./styles";

class GoogleConfirmation extends Component {
  constructor(props) {
    super(props);

    this.actions = bindActionCreators(UserActions, props.dispatch);
    this.googleLoginHandler = this.googleLoginHandler.bind(this);
    this.getContent = this.getContent.bind(this);
  }

  getContent() {
    const { isConfirmed } = this.props;
    const { BADI_AUTH_GOOGLE_APP_ID } = envVars();

    if (isConfirmed) {
      return (
        <div className="form__half">
          <div className="form__element">
            <div className="form__text">
              {translate("Your Google account has been connected.")}
            </div>
          </div>
          <div className="form__element text_right">
            <div className="form__true">{translate("Connected")}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="form__half">
        <div className="form__element">
          <div className="form__title">
            <span className="form__name">{translate("Google+")}</span>
          </div>
          <div className="form__text">
            {translate("myProfile.googleConfirmation.connect")}
          </div>
        </div>
        <div className="form__element text_right">
          <GoogleButton
            appId={BADI_AUTH_GOOGLE_APP_ID}
            onError={() => {}}
            onSuccess={this.googleLoginHandler}
          >
            {translate("Connect")}
          </GoogleButton>
        </div>
      </div>
    );
  }

  googleLoginHandler(response) {
    const { authtoken } = this.props;

    if (response.status === "success") {
      const params = { google_id_token: response.accessToken };
      checkGoogleConfirmation(authtoken, params)
        .then(() => {
          this.actions.setConfirmation("google");
        })
        .catch((e) => {
          UserFeedback.exception("Something went wrong", e.message, e);
        });
    }
  }

  render() {
    return <div className="form__row">{this.getContent()}</div>;
  }
}

export default connect((state) => ({
  authtoken: state.Auth.access_token,
  isConfirmed: state.User.confirmations.google,
}))(GoogleConfirmation);
