export const validateInputChange = (input, customValueChecker) => {
  const value = input.getValue ? input.getValue() : input.state._value;
  const validation = {
    hasValue: false,
    isValid: input.isValidValue(value),
    value,
  };

  if (customValueChecker) validation.hasValue = customValueChecker(value);
  else if (Array.isArray(value)) validation.hasValue = !!value.length;
  else validation.hasValue = value !== null && value !== undefined;

  validation.isValid = input.isValidValue(value);

  return validation;
};

export const validateFormChange = (inputs, customValueCheckers = {}) => {
  const form = { hasValues: {}, validations: {}, values: {} };

  inputs.forEach((input) => {
    const name = input.props.name;
    const inputValidation = validateInputChange(
      input,
      customValueCheckers[name],
    );

    form.hasValues[name] = inputValidation.hasValue;
    form.validations[name] = inputValidation.isValid;
    form.values[name] = inputValidation.value;
  });

  return form;
};
