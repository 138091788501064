import React from "react";
import classNames from "classnames";

import { translate } from "base/shared/Localization";

const VerificationFlag = ({ className, verificationLevel }) => {
  if (verificationLevel === "verified") {
    return (
      <div className={classNames("info__verify", className)}>
        {translate("Verified")}
      </div>
    );
  }

  if (verificationLevel === "trusted") {
    return (
      <div className={classNames("info__trusted", className)}>
        {translate("Trusted")}
      </div>
    );
  }

  return null;
};

VerificationFlag.defaultProps = {
  className: null,
  verificationLevel: "",
};

export default VerificationFlag;
