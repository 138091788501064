import styled from "styled-components";

export const RoomCardWrapper = styled.div`
  text-decoration: none;
`;

export const ImageWrapper = styled.div`
  height: 176px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

export const SlideWrapper = styled.div`
  width: 264px;
  padding: 0 12px;

  &:hover {
    text-decoration: none;
  }
`;

export const RoomCardDetails = styled.div`
  height: 100%;
  border-radius: 4px;
  }
`;

export const SimilarRoomsWrapper = styled.div`
  width: calc(100% + 48px);
  > .slick-initialized.slick-slider {
    position: relative;
    left: -12px;
  }
`;

export const Title = styled.h4`
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`;

export const LowDetailsSection = styled.div`
  height: 34px;
`;
