export const FILTERS_ID = {
  flat: "filter-flat",
  sort: "filter-sort",
  budget: "filter-budget",
  availability: "filter-availability",
  flatmates: "filter-flatmates",
  room: "filter-room",
  marketplace_segments: "filter-marketplace-segments",
};

export const FILTERS = [
  {
    id: FILTERS_ID.sort,
    icon: "/assets/icons/filters/filter-sort.svg",
    title: "search.filters.sortby",
    field: "sortFilter",
    resetAction: "resetSortBy",
  },
  {
    id: FILTERS_ID.marketplace_segments,
    icon: "/assets/icons/filters/filter-marketplace-segments.svg",
    title: "search.filters.marketplace_segments.title",
    field: "marketplaceSegmentsFilter",
    resetAction: "resetMarketplaceSegments",
  },
  {
    id: FILTERS_ID.budget,
    icon: "/assets/icons/filters/filter-budget.svg",
    title: "search.filters.budget",
    field: "budgetFilter",
    resetAction: "resetBudget",
  },
  {
    id: FILTERS_ID.availability,
    icon: "/assets/icons/filters/filter-availability.svg",
    title: "search.filters.availability",
    field: "availabilityFilter",
    resetAction: "resetAvailability",
  },
  {
    id: FILTERS_ID.flatmates,
    icon: "/assets/icons/filters/filter-flatmates.svg",
    title: "search.filters.flatmates",
    field: "flatmatesFilter",
    resetAction: "resetFlatmates",
  },
  {
    id: FILTERS_ID.room,
    icon: "/assets/icons/filters/filter-room.svg",
    title: "search.filters.room",
    field: "roomFilter",
    resetAction: "resetRoom",
  },
  {
    id: FILTERS_ID.flat,
    icon: "/assets/icons/filters/filter-flat.svg",
    title: "search.filters.flat",
    field: "flatFilter",
    resetAction: "resetFlat",
  },
];
