import { getRelativePath, objectToQueryParams } from "base/shared/Utils";

const BASE_PATH = "/plans";

export const getGoldUpgradePath = ({ roomId, feature, initiator } = {}) => {
  let path = `${BASE_PATH}/gold`;

  if (roomId) {
    path += `/${roomId}`;
  }

  const queryParams = objectToQueryParams({ feature, initiator });

  return getRelativePath(queryParams ? `${path}?${queryParams}` : path);
};

export const getBoostingPath = ({ roomId } = {}) => {
  let path = `${BASE_PATH}/boosting`;

  if (roomId) {
    path += `/${roomId}`;
  }

  return getRelativePath(path);
};
