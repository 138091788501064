import React, { memo } from "react";
import { PaginationToken, Spacer } from "@badi/badi-components";
import { SearchCardsList } from "Sections/Search/search-v2/wrappers/styled-components";
import { BANNER_TYPES } from "datamodel/search-rooms/static";

import RoomCard from "../room-card";
import { PaginationWrapper } from "./styled-components";
import { getBanner } from "./utils";

const Cards = ({
  cards,
  selectRoomById,
  handleEtParams = null,
  nextToken = null,
  setToken,
  previousToken = null,
  onlyList = false,
}) => {
  const handleChangePage = (token) => {
    setToken(token);
  };

  const generateCards = () => {
    let bannerCounter = 0;

    return cards.map(
      (
        {
          labels,
          pictures,
          lister,
          headline,
          subheading,
          extraInfo,
          roomId,
          type,
        },
        indexCard,
      ) => {
        if (BANNER_TYPES.includes(type)) {
          bannerCounter += 1;

          const BannerComponent = getBanner(type);
          return (
            <Spacer bottom={3}>
              <BannerComponent compact={onlyList} key="banner" />
            </Spacer>
          );
        }

        return (
          <RoomCard
            extraInfo={extraInfo}
            handleEtParams={handleEtParams}
            headline={headline}
            htmlId={`list-room-card-${roomId}`}
            index={indexCard + bannerCounter}
            key={roomId}
            labels={labels}
            lister={lister}
            pictures={pictures}
            roomId={roomId}
            selectRoomById={selectRoomById}
            subheading={subheading}
          />
        );
      },
    );
  };

  return (
    <SearchCardsList id="search-rooms-list">
      {generateCards()}
      {(!!nextToken || !!previousToken) && (
        <PaginationWrapper>
          <Spacer bottom={4} top={4}>
            <PaginationToken
              nextToken={nextToken}
              onClick={handleChangePage}
              previousToken={previousToken}
            />
          </Spacer>
        </PaginationWrapper>
      )}
    </SearchCardsList>
  );
};

export default memo(
  Cards,
  ({ cards: prevCards }, { cards: nextCards }) => prevCards === nextCards,
);
