import React from "react";
import {
  Ellipsis,
  EllipsisContent,
  RootEllipsis,
  animationEllipsis2,
  animationEllipsis3,
} from "./styled-components";

const Loader = () => (
  <RootEllipsis>
    <Ellipsis>
      <EllipsisContent animation={animationEllipsis2} />
    </Ellipsis>
    <Ellipsis>
      <EllipsisContent animationDelay="-1s" />
    </Ellipsis>
    <Ellipsis>
      <EllipsisContent animationDelay="-0.5s" />
    </Ellipsis>
    <Ellipsis>
      <EllipsisContent animationDelay="0s" />
    </Ellipsis>
    <Ellipsis>
      <EllipsisContent animation={animationEllipsis3} />
    </Ellipsis>
  </RootEllipsis>
);

export default Loader;
