import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Container, Row, Col } from "reactstrap";
import { arrayMove } from "react-sortable-hoc";
import {
  Card,
  InlineMessage,
  MESSAGE_TYPES,
  Text,
  Spacer,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getBase64FromFile } from "datamodel/Picture/utils";
import { upload } from "datamodel/Picture/api";

import Image from "components/Image/Image";
import SortableList from "./SortableList";
import Empty from "./Empty";

import { getFilesToUpload, PICTURE_STATUS } from "./utils";

import "./DropZoneExt.css";

class DropZone extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
    this.handleSortEnd = this.handleSortEnd.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.files });
  }

  onSuccess(file, result) {
    const fileToUpdate = this.props.files.find((f) => f.tempId === file.tempId);
    const f = fileToUpdate || file;
    const value = {
      ...f,
      pictureId: result.id,
      serverUrl: result.width_500_url,
      uploadStatus: PICTURE_STATUS.success,
    };

    this.props.onUpdate(value);
  }

  onError(file) {
    this.props.onUpdate({ ...file, uploadStatus: PICTURE_STATUS.error });
  }

  handleSortEnd({ oldIndex, newIndex }) {
    this.setState(
      { items: arrayMove(this.state.items, oldIndex, newIndex) },
      () => {
        this.props.onUpdate(this.state.items);
      },
    );
  }

  handleDrop(dropFiles) {
    const { values, files } = getFilesToUpload(
      this.props.files,
      dropFiles,
      this.props.maxFiles,
    );

    this.props.onUpdate(values);

    files.forEach((file) => this.uploadFile(file));
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dropzone.open();
  }

  handleRemove(tempId) {
    this.props.onRemove(tempId);
  }

  handleRetry(tempId) {
    const { files } = this.props;
    const file = files.find((item) => item.tempId === tempId);

    this.handleDrop([file]);
  }

  uploadFileToServer(file, base64) {
    upload(this.props.token, { file: base64 })
      .then((result) => this.onSuccess(file, result))
      .catch(() => this.onError(file));
  }

  uploadFile(file) {
    getBase64FromFile(file.file).then((base64) =>
      this.uploadFileToServer(file, base64),
    );
  }

  render() {
    const {
      accept,
      disableClick,
      className,
      isMobile,
      maxFiles,
      maxSize,
      multiple,
      warning,
      warningMessage,
      sectionName,
    } = this.props;
    const { items } = this.state;
    const filesLength = items.length;
    const pressDelay = isMobile ? "200" : "0";
    const requiredSymbol = String.fromCharCode(160, 42);

    return (
      <Card>
        <div className={classNames("DropZone", className)}>
          <Dropzone
            accept={accept}
            disableClick={disableClick || filesLength >= maxFiles}
            maxSize={maxSize}
            multiple={multiple}
            onDrop={this.handleDrop}
            ref={(node) => {
              this.dropzone = node;
            }}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Container
                    className={classNames({
                      DropZone__error: warning,
                      DropZone__containter_empty: !filesLength,
                    })}
                    fluid={true}
                    onClick={!filesLength ? this.handleClick : null}
                  >
                    {filesLength ? (
                      [
                        <Row
                          className="DropZone__container"
                          key="dropzone-title"
                        >
                          <Col className="DropZone__header" xs={12}>
                            <h3>
                              {translate("dropzone.container.title")}
                              {requiredSymbol}
                            </h3>
                            <div className="DropZone__counter">
                              {maxFiles < 999 && (
                                <div>{`${filesLength}/${maxFiles}`}</div>
                              )}
                            </div>
                          </Col>
                        </Row>,
                        <SortableList
                          addPicture={this.handleClick}
                          axis="xy"
                          items={this.state.items}
                          key="dropzone-container"
                          maxFiles={maxFiles}
                          onRemove={this.handleRemove}
                          onRetry={this.handleRetry}
                          onSortEnd={this.handleSortEnd}
                          pressDelay={pressDelay}
                          shouldCancelStart={(e) => {
                            if (e.target.closest(".DropZone__Preview--action"))
                              return true;
                            return false;
                          }}
                        />,
                        <Row key="dropzone-tip">
                          <Col className="DropZone__tip">
                            <Image src="/assets/icons/drag-drop.svg" />
                            <span>{translate("dropzone.container.tip")}</span>
                          </Col>
                        </Row>,
                      ]
                    ) : (
                      <Empty
                        onClick={this.handleClick}
                        sectionName={sectionName}
                      />
                    )}
                  </Container>
                </div>
              );
            }}
          </Dropzone>
        </div>
        {warningMessage && (
          <Spacer bottom={2} left={2} right={2}>
            <InlineMessage variant={MESSAGE_TYPES.WARNING}>
              <Text body="14-Regular" dataQa="dropzone-warning-banner">
                {warningMessage}
              </Text>
            </InlineMessage>
          </Spacer>
        )}
      </Card>
    );
  }
}

DropZone.defaultProps = {
  accept: "image/jpg, image/jpeg, image/png",
  className: null,
  disableClick: true,
  files: [],
  maxFiles: 999,
  maxSize: 15728640,
  multiple: true,
  required: false,
  sectionName: "",
  warning: false,
  warningMessage: null,
};

export default DropZone;
