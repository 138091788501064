import { getCookie } from "base/shared/CookiesHandler";
import { getRefreshedToken } from "datamodel/Auth/utils";
import { COOKIES } from "base/shared/StaticData";
import { DEFAULT_SW_NAME } from "./static";

export const findActivatedServiceWorker = async () => {
  if (!("serviceWorker" in navigator)) {
    return null;
  }

  const registrations = await navigator.serviceWorker.getRegistrations();
  return registrations.find(
    (reg) =>
      !!reg.active &&
      reg.active.state === "activated" &&
      reg.active.scriptURL.includes(DEFAULT_SW_NAME),
  );
};

export const shouldNotInitialize = (
  userId,
  arePushNotificationsEnabled,
  arePushNotificationsSupported,
) => !userId || !arePushNotificationsEnabled || !arePushNotificationsSupported;

export const getAuthTokensFromCookie = () => {
  const tApp = getCookie(COOKIES.APP);
  if (!tApp) return {};
  const splittedCookie = tApp.split("|");

  const accessToken = splittedCookie[0];
  const refreshToken = splittedCookie[8];
  return { accessToken, refreshToken };
};

export const getAccessToken = async () => {
  const { accessToken, refreshToken } = getAuthTokensFromCookie();
  if (!accessToken || !refreshToken) return;
  const newAuth = await getRefreshedToken(accessToken, refreshToken);
  const { access_token: newAccessToken } = newAuth || {};
  return newAccessToken || accessToken;
};
