class Pro {
  constructor(entity) {
    this.entity = entity || {};
  }

  get subscription() {
    if (!this.entity.subscription) {
      return null;
    }

    const {
      status,
      interval,
      subscription_cycle: {
        status: cycleStatus,
        period_start: start,
        period_end: end,
      },
    } = this.entity.subscription;
    return { status, interval, cycle: { start, end, status: cycleStatus } };
  }

  get tier() {
    return this.entity.plan_tokens?.tier || "";
  }

  get totalRooms() {
    return this.entity.rooms?.total || 0;
  }

  get totalTokens() {
    return this.entity.plan_tokens?.total || 0;
  }

  get activeTokens() {
    return this.entity.plan_tokens?.used?.total || 0;
  }

  get autoRenewedTokens() {
    return this.entity.plan_tokens?.used?.auto_renew || 0;
  }

  toJSON() {
    return {
      activeTokens: this.activeTokens,
      autoRenewedTokens: this.autoRenewedTokens,
      subscription: this.subscription,
      tier: this.tier,
      totalRooms: this.totalRooms,
      totalTokens: this.totalTokens,
    };
  }
}

class PlansInfo {
  constructor(entity) {
    this.entity = entity || {};
  }

  get pro() {
    return this.entity.pro ? new Pro(this.entity.pro).toJSON() : null;
  }

  toJSON() {
    return {
      pro: this.pro,
    };
  }
}

export default PlansInfo;
