import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  z-index: 12;
  background-color: ${(props) => props.backgroundColor}};
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  transform: scale(0);
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(1);
      opacity: 1;
    `}

  ${({ isClosed }) =>
    isClosed &&
    css`
      transform: scale(0);
      opacity: 0;
    `}
`;
