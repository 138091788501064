import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const SearchLocationWrapper = styled.div`
  border-radius: 8px;
  height: ${unitizedPx(6)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_05};

  :focus-within {
    background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
    border: 2px solid ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
  }
`;

export const GeosuggestWrapper = styled.div`
  > div {
    .geosuggest__input {
      border: none;
      outline: none;
      background-color: inherit;

      :placeholder {
        color: ${BADI_COLORS.NEUTRAL.NEUTRAL_50};
      }
    }

    .geosuggest__suggests.extendedWidth {
      top: ${unitizedPx(5)};
      z-index: 3;
      border: none;
      box-shadow:
        0 2px 6px rgba(0, 0, 0, 0.05),
        0 0 0 1px rgba(0, 0, 0, 0.07);
      width: calc(100% + 40px);
      min-width: auto;

      li {
        height: ${unitizedPx(8)};
        display: flex;
        align-items: center;
        padding: 0;

        > div {
          width: 100%;

          > span {
            font-size: 14px;
            line-height: 1.43;
          }
        }
      }
    }
  }
`;
