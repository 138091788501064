export const LISTINGS = {
  Barcelona: {
    weekly: 2500,
    total: 5000,
  },
  London: {
    weekly: 1500,
    total: 3000,
  },
  Madrid: {
    weekly: 1500,
    total: 3000,
  },
  Paris: {
    weekly: 1000,
    total: 1500,
  },
  Roma: {
    weekly: 500,
    total: 1000,
  },
  Berlin: {
    weekly: 0,
    total: 0,
  },
  Milano: {
    weekly: 0,
    total: 0,
  },
  "New York": {
    weekly: 0,
    total: 0,
  },
};

export const getNumberOfListings = (city, perWeek) => {
  const listings = LISTINGS[city];
  if (!listings) return 0;

  return perWeek ? listings.weekly : listings.total;
};

export const parseCity = (city) => {
  if (!city) return null;

  const { country, id, name, neighbourhoods, pictures } = city;

  return {
    city: name,
    country,
    id,
    neighbourhoods,
    pictures,
    listings: getNumberOfListings(name, true),
  };
};

export const parseCities = (cities) => {
  if (!cities) return [];
  return cities.map((city) => parseCity(city));
};
