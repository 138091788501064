import React from "react";
import classNames from "classnames";

class InputWithLabel extends React.PureComponent {
  state = {
    focused: false,
  };

  onBlur = () => this.setState({ focused: false });
  onFocus = () => this.setState({ focused: true });

  render() {
    const {
      className,
      disabled,
      inputClassName,
      inputType,
      label,
      maxlength,
      name,
      onChange,
      placeholder,
      required,
      setReference,
      value,
      dataQa,
    } = this.props;
    const { focused } = this.state;

    return (
      <div
        className={classNames(
          "Form__InputWithLabel--wrapper",
          { "Form__InputWithLabel--wrapper-active": !!value || !!focused },
          { disabled },
          className,
        )}
      >
        <input
          className={classNames(inputClassName)}
          data-qa={dataQa}
          disabled={disabled}
          maxLength={maxlength || undefined}
          name={name}
          onBlur={this.onBlur}
          onChange={onChange}
          onFocus={this.onFocus}
          placeholder={placeholder}
          ref={setReference}
          required={required}
          type={inputType}
          value={value}
        />
        <span
          className="Form__InputWithLabel--label"
          data-qa={`${dataQa}-label`}
        >
          {label}
        </span>
      </div>
    );
  }
}

InputWithLabel.defaultProps = {
  className: "",
  dataQa: "",
  disabled: false,
  inputClassName: null,
  inputType: "text",
  label: "",
  maxlength: 0,
  placeholder: "",
  required: false,
  setReference: () => {},
  value: null,
};

export default InputWithLabel;
