import React from "react";
import { translate } from "base/shared/Localization";
import {
  Text,
  Heading,
  Flex,
  Card,
  Spacer,
  BADI_COLORS,
} from "@badi/badi-components";

const ReferralAccount = ({ earnedCredit, redeemedCredit, availableCredit }) => (
  <>
    <Heading data-qa="referral-account-header" level="24-Bold">
      {translate("referrals.content.account.title")}
    </Heading>
    <Spacer top={2} />
    <Card
      backgroundColor={BADI_COLORS.ADDITIONAL.ADDITIONAL_07}
      withShadow={false}
    >
      <Spacer bottom={2} left={2} right={2} top={2}>
        <Flex alignItems="center" direction="row" justify="spaceBetween">
          <Heading
            color={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
            data-qa="referral-account-earned-credit-value"
            level="32-Extrabold"
          >
            {earnedCredit}
          </Heading>
          <Flex direction="column" gutter={1}>
            <Flex direction="row" gutter={1} justify="spaceBetween">
              <Text
                body="14-Bold"
                dataQa="referral-account-redeemed-credit-label"
              >
                {translate("referrals.content.account.used")}
              </Text>
              <Text
                body="14-Bold"
                color={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
                dataQa="referral-account-redeemed-credit-value"
              >
                {redeemedCredit}
              </Text>
            </Flex>
            <Flex direction="row" gutter={1} justify="spaceBetween">
              <Text
                body="14-Bold"
                dataQa="referral-account-available-credit-label"
              >
                {translate("referrals.content.account.available")}
              </Text>
              <Text
                body="14-Bold"
                color={BADI_COLORS.BRAND.SECONDARY.DEFAULT}
                dataQa="referral-account-available-credit-value"
              >
                {availableCredit}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Spacer>
    </Card>
  </>
);

export default ReferralAccount;
