import React from "react";
import { Label, Spacer } from "@badi/badi-components";
import { Text } from "app/badi-components";
import { LabelsBarWrapper } from "./styled-components";

const LabelsBar = ({ labels, dataQa }) => (
  <LabelsBarWrapper data-qa={dataQa}>
    {labels.map(({ content, color, backgroundColor }, index) => (
      <Spacer right={1} key={index}>
        <Label backgroundColor={backgroundColor}>
          <Text body="12-Medium" color={color} noWrap={true}>
            {content}
          </Text>
        </Label>
      </Spacer>
    ))}
  </LabelsBarWrapper>
);

export default LabelsBar;
