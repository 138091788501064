import styled from "styled-components";

import { colors, unitizedPx } from "@badi/badi-components";

export const InfoBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${colors.blueBooking};
  color: ${colors.white};
  height: ${unitizedPx(6)};
  line-height: ${unitizedPx(6)};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${unitizedPx(2)};
  right: ${unitizedPx(1)};
  border: 0;
  background: transparent;
  cursor: pointer;
`;
