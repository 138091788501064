import React, { Component } from "react";

import { Flex, TextButton } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getSelectedIds, getSelectedElements } from "../utils";

import FlatFields from "./flat-fields";
import { SubmitFormWrapper, StyledForm } from "../styled-components";

class FlatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
      flatAmenityIds: [],
      houseRuleIds: [],
      flatBenefitsIds: [],
      minFlatSize: 0,
      maxFlatSize: 0,
    };
    this.flatAmenities = translate(props.flatAmenities);
    this.flatBenefits = translate(props.flatBenefits);
    this.houseRules = translate(props.houseRules);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.updated) {
      return { updated: false };
    }

    const {
      flatAmenities,
      houseRules,
      flatBenefits,
      minFlatSize,
      maxFlatSize,
    } = props;

    return {
      flatAmenityIds: getSelectedIds(flatAmenities),
      houseRuleIds: getSelectedIds(houseRules),
      flatBenefitsIds: getSelectedIds(flatBenefits),
      minFlatSize,
      maxFlatSize,
    };
  }

  handleFlatSelect = (flatAmenityIds) => {
    this.setState({ flatAmenityIds, updated: true });
  };

  handleBenefitSelect = (flatBenefitsIds) => {
    this.setState({ flatBenefitsIds, updated: true });
  };

  handleHouseRuleSelect = (houseRuleIds) => {
    this.setState({ houseRuleIds, updated: true });
  };

  handleMinFlatSizeChange = (minFlatSize) =>
    this.setState({ minFlatSize, updated: true });

  handleMaxFlatSizeChange = (maxFlatSize) =>
    this.setState({ maxFlatSize, updated: true });

  handleApplyFilter = () => {
    const {
      flatAmenityIds,
      houseRuleIds,
      flatBenefitsIds,
      minFlatSize,
      maxFlatSize,
    } = this.state;
    const {
      onFilterApplied,
      flatAmenities,
      houseRules,
      setFlatFilter,
      flatBenefits,
    } = this.props;

    const parsedFlatBenefits = getSelectedElements(
      flatBenefits,
      flatBenefitsIds,
    );

    const parsedFlatAmenities = getSelectedElements(
      flatAmenities,
      flatAmenityIds,
    );
    const parsedHouseRules = getSelectedElements(houseRules, houseRuleIds);

    setFlatFilter(
      parsedFlatAmenities,
      parsedFlatBenefits,
      parsedHouseRules,
      minFlatSize,
      maxFlatSize,
    );
    onFilterApplied();
  };

  render() {
    const {
      flatAmenityIds,
      flatBenefitsIds,
      houseRuleIds,
      minFlatSize,
      maxFlatSize,
    } = this.state;

    return (
      <Flex as={StyledForm} direction="column">
        <FlatFields
          flatAmenities={this.flatAmenities}
          flatAmenityIds={flatAmenityIds}
          flatBenefits={this.flatBenefits}
          flatBenefitsIds={flatBenefitsIds}
          houseRuleIds={houseRuleIds}
          houseRules={this.houseRules}
          maxFlatSize={maxFlatSize}
          minFlatSize={minFlatSize}
          onBenefitSelect={this.handleBenefitSelect}
          onFlatSelect={this.handleFlatSelect}
          onHouseRuleSelect={this.handleHouseRuleSelect}
          onMaxFlatSizeValueChange={this.handleMaxFlatSizeChange}
          onMinFlatSizeValueChange={this.handleMinFlatSizeChange}
        />
        <SubmitFormWrapper>
          <TextButton onClick={this.handleApplyFilter}>
            {translate("search.filters.button")}
          </TextButton>
        </SubmitFormWrapper>
      </Flex>
    );
  }
}

export default FlatForm;
