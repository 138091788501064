import React from "react";

import { Icon, Text } from "@badi/badi-components";

import "./ReducedFilterExt";

const ReducedFilter = ({
  cluster,
  title,
  value,
  onCancelFilter,
  resetAction,
}) => {
  const handleCancelFilter = () => {
    onCancelFilter();
  };

  if (!value && !cluster) return null;

  const parsedCluster = cluster ? ` (${cluster})` : "";

  return (
    <div className="ReducedFilter">
      <Text body={6} className="ReducedFilter__Text" inline={true}>
        {value || title} {parsedCluster}
      </Text>
      <button
        data-qa="reset-filter-icon"
        onClick={handleCancelFilter}
        type="button"
      >
        <Icon height={16} name="close" width={16} />
      </button>
    </div>
  );
};

ReducedFilter.defaultProps = {
  cluster: 0,
  value: null,
};

export default ReducedFilter;
