import React from "react";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ProgressiveImg from "components/ProgressiveImg";
import { DEFAULT_ROOM_IMAGE, DEFAULT_USER_IMAGE } from "base/shared/StaticData";

import { SliderWrapper } from "./styled-components";

import BackCover from "./components/back-cover-room";
import { SLIDER_IMAGE_TYPES } from "./static";

SwiperCore.use([Navigation, Pagination]);

const Slider = ({ pictures, type, dataQa = "", children, onClick = null }) => {
  const isMultipleSlides = pictures?.urls?.length > 1;
  const defaultImage =
    type === SLIDER_IMAGE_TYPES.ROOM ? DEFAULT_ROOM_IMAGE : DEFAULT_USER_IMAGE;

  const { urls, backCover } = pictures;
  const images = urls?.length ? urls : [defaultImage];

  const { backgroundUrl, hint, cta } = backCover || {};

  return (
    <SliderWrapper data-qa={dataQa}>
      <Swiper
        navigation={!!isMultipleSlides}
        pagination={{
          type: "bullets",
          clickable: true,
          dynamicBullets: true,
        }}
      >
        {images.map((url) => (
          <SwiperSlide key={`pic-${url}`}>
            {children}
            <ProgressiveImg
              className="Slider--Progressive-wrapper"
              onClick={onClick}
              src={url}
              tinySrc={url}
            />
          </SwiperSlide>
        ))}
        {!!backCover && (
          <SwiperSlide>
            <BackCover
              backgroundUrl={backgroundUrl}
              cta={cta}
              hint={hint}
              key="back-cover"
            >
              {children}
            </BackCover>
          </SwiperSlide>
        )}
      </Swiper>
    </SliderWrapper>
  );
};

export default Slider;
