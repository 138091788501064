import React, { Fragment } from "react";
import { generateVisible } from "../utils";

import {
  PageRow,
  Languages,
  Tags,
  UserId,
  EditBlock,
  Block,
  Header,
} from "./Sections";
import AboutMe from "./sections/about-me";
import AvatarSlider from "./sections/avatar-slider";
import GenderAndOccupation from "./sections/gender-and-occupation";
import Metrics from "./sections/metrics";
import NameAge from "./sections/name-age";
import PublishedRooms from "./sections/published-rooms";
import Trust from "./sections/trust";
import WorkAndStudies from "./sections/work-and-studies";
import Links from "./sections/links";
import { Divider } from "./styled-components";

const MyProfile = ({ format, showVerificationFlag, title, user, visible }) => (
  <PageRow>
    <Header title={title} user={user} visible={visible} />
    {visible && (
      <AvatarSlider showVerificationFlag={showVerificationFlag} user={user} />
    )}
    <EditBlock visible={visible} />
    <Block>
      <GenderAndOccupation
        gender={user.gender}
        occupation={user.workStudiesStatus}
      />
      <NameAge age={user.age} name={user.name} />
      <Metrics format={format} metrics={user.metrics} />
      <Links
        isCompany={!!user.company}
        profileUserId={user.id}
        reviewsTotal={user.reviewsTotal}
        socialProfiles={user.socialProfiles}
      />
    </Block>
  </PageRow>
);

const OtherProfile = ({
  format,
  showVerificationFlag,
  title,
  user,
  visible,
}) => {
  const name = user.company ? user.company.name : user.name;
  const pictures = user.company ? user.company.pictures : user.pictures;
  const isCompany = !!user.company;

  return (
    <Fragment>
      <Header title={title} user={user} visible={visible} />
      <PageRow>
        {visible && (
          <AvatarSlider
            pictures={pictures}
            showVerificationFlag={showVerificationFlag}
            user={user}
          />
        )}
        <EditBlock visible={visible} />
        {!isCompany && (
          <GenderAndOccupation
            gender={user.gender}
            occupation={user.workStudiesStatus}
          />
        )}
        <NameAge age={user.age} isCompany={isCompany} name={name} />
        <Metrics format={format} metrics={user.metrics} />
        <Links
          isCompany={isCompany}
          profileUserId={user.id}
          reviewsTotal={user.reviewsTotal}
          socialProfiles={user.socialProfiles}
        />
      </PageRow>
    </Fragment>
  );
};

export const LowSection = ({ config, user }) => {
  const visible = generateVisible({ trust: !!user.listerScore, ...config });
  const aboutMe = user.company ? user.company.description : user.aboutMe;
  const isCompany = !!user.company;

  return (
    <PageRow>
      {visible.trust && (
        <Trust items={user.trustItems} score={user.listerScore} />
      )}
      <Divider>
        {visible.aboutMe && aboutMe && (
          <AboutMe isCompany={isCompany} text={aboutMe} />
        )}
        <Languages user={user} visible={visible} />
        {!isCompany && (
          <WorkAndStudies
            studies={user.studies}
            visibleStudies={visible.studies}
            visibleWork={visible.work}
            work={user.work}
          />
        )}
        <PublishedRooms
          publishedRooms={user.publishedRooms}
          visible={visible.publishedRooms}
        />
        {!isCompany && <Tags user={user} visible={visible} />}
        <UserId user={user} visible={visible} />
      </Divider>
    </PageRow>
  );
};

const topSection =
  (Component) =>
  ({ config = {}, ...props }) => {
    const visible = generateVisible(config);
    return <Component {...props} visible={visible} />;
  };

export const TopSectionMyProfile = topSection(MyProfile);
export const TopSectionOtherProfile = topSection(OtherProfile);

const UserProfileDefaultProps = {
  context: null,
  format: "myProfile",
  showVerificationFlag: false,
  title: null,
};

MyProfile.defaultProps = UserProfileDefaultProps;

OtherProfile.defaultProps = UserProfileDefaultProps;
