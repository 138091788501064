import { getPublicAsset } from "base/shared/Utils";

export const getIcon = (iconId) => {
  switch (iconId) {
    case "trusted":
      return getPublicAsset("icons/lister-trusted.svg");
    default:
      return getPublicAsset("icons/lister-trusted.svg");
  }
};
