import React from "react";
import { LinkButton, BUTTON_TYPES } from "@badi/badi-components";
import { translate } from "base/shared/Localization";

const CheckBadiHomeAvailabilityButton = ({ href }) => (
  <LinkButton
    target="_blank"
    href={href}
    variant={BUTTON_TYPES.SECONDARY}
    style={{ marginTop: "0.5rem" }}
  >
    {translate("room.detail.header.check-availability")}
  </LinkButton>
);

export default CheckBadiHomeAvailabilityButton;
