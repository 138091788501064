import styled, { css } from "styled-components";
import { BADI_COLORS } from "@badi/badi-components";

const selectedStyles = css`
  ::after {
    position: absolute;
    height: 3px;
    width: 32px;
    left: calc(50% - 16px);
    bottom: -3px;

    content: "";
    background-color: ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
    border-radius: 2px;
  }
`;

export const Button = styled.button`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 100%;
  margin: 0;
  padding: 0 0 16px 0;
  border: 0;
  background-color: transparent;
  text-align: center;

  ${({ selected }) => selected && selectedStyles}
`;

export const ScheduleBar = styled.div`
  border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  height: 3px;
  width: 100%;
`;
