import { getRelativePath } from "base/shared/Utils";

const BASE_PATH = "/inbox";

export const getInboxPath = ({ roomId, category } = {}) => {
  const params = new URLSearchParams();

  if (roomId) {
    params.append("room", roomId);
  }
  if (category) {
    params.append("category", category);
  }

  const queryParams = params.toString();

  return getRelativePath(
    queryParams ? `${BASE_PATH}?${queryParams}` : BASE_PATH,
  );
};
