import styled, { css } from "styled-components";
import { BADI_COLORS, mediaquery } from "@badi/badi-components";
import { TopbarElementWrapper } from "../topbar-element/styled-components";

export const TopBarMenuRoot = styled(TopbarElementWrapper)`
  z-index: 10;
  ${mediaquery.lg`
    display: none;
  `}
`;

const menuActiveStyles = css`
  transform-origin: 0% 0%;

  transform: translate(100vw, 0);
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

const menuInactiveStyles = css`
  transform: translate(-100vw, 0);
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

export const MenuRoot = styled.div`
  cursor: initial;
  position: fixed !important;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  padding: 24px;
  box-sizing: border-box;
  z-index: 1000;

  overflow-y: auto;

  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform: none;

  ${({ active }) => (active ? menuActiveStyles : menuInactiveStyles)}
`;

export const MenuCloseWrapper = styled.div`
  text-align: left;
  outline: none;
  cursor: pointer;
`;

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MenuSeparator = styled.li`
  width: 100%;
  margin: 12px 0;
  height: 1px;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
`;

export const TopBarMenuElement = styled(TopbarElementWrapper)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 48px;
  padding: 4px 0;
  cursor: pointer;
  background: none;
  text-decoration: none;
  ${({ complex }) => complex && `min-height: 64px;`}
`;

const notificatedStyles = css`
  position: absolute;
  right: -8px;
  top: -2px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 4px;
  box-sizing: border-box;
  background: ${BADI_COLORS.SEMANTIC.INFO.DEFAULT};
`;

export const MenuTitle = styled.span`
  font-size: 1.06rem;
  :after {
    ${({ notifications }) => notifications && notificatedStyles}
  }

  ${({ highlighted }) => highlighted && `font-weight: bold;`}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MenuDescription = styled.span`
  line-height: 1.43;
  font-weight: 400;
`;
