import { TIME_SCHEDULE } from "./constants";

export const getFirstAvailableTime = (times) => {
  if (!Array.isArray(times)) {
    return null;
  }
  return times.find(({ available }) => available)?.localStartAt || null;
};

export const getFirstAvailableScheduleAndTime = ({
  morning = [],
  evening = [],
  afternoon = [],
} = {}) => {
  switch (true) {
    case !!morning.find(({ available }) => available):
      return {
        schedule: TIME_SCHEDULE.MORNING,
        time: getFirstAvailableTime(morning),
      };
    case !!afternoon.find(({ available }) => available):
      return {
        schedule: TIME_SCHEDULE.AFTERNOON,
        time: getFirstAvailableTime(afternoon),
      };
    case !!evening.find(({ available }) => available):
      return {
        schedule: TIME_SCHEDULE.EVENING,
        time: getFirstAvailableTime(evening),
      };
    default:
  }
  return { schedule: null, time: null };
};
