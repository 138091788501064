import styled from "styled-components";

import { getHideOnStyles } from "lib/styles-helpers";

export const LogoWrapper = styled.div`
  position: static;
  left: initial;

  > a {
    display: flex;
  }

  ${(props) => props.hideOn && getHideOnStyles(props.hideOn)}
`;
