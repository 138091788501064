export const LISTER_TIPS = [
  {
    title: "inbox_popup_visit.completed.lister.tips_0_title",
    description: "inbox_popup_visit.completed.lister.tips_0_description",
  },
  {
    title: "inbox_popup_visit.completed.lister.tips_1_title",
    description: "inbox_popup_visit.completed.lister.tips_1_description",
  },
  {
    title: "inbox_popup_visit.completed.lister.tips_2_title",
    description: "inbox_popup_visit.completed.lister.tips_2_description",
  },
];

export const SEEKER_TIPS = [
  {
    title: "inbox_popup_visit.completed.seeker.tips_0_title",
    description: "inbox_popup_visit.completed.seeker.tips_0_description",
  },
  {
    title: "inbox_popup_visit.completed.seeker.tips_1_title",
    description: "inbox_popup_visit.completed.seeker.tips_1_description",
  },
  {
    title: "inbox_popup_visit.completed.seeker.tips_2_title",
    description: "inbox_popup_visit.completed.seeker.tips_2_description",
  },
];
