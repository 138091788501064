import React, { useState, useCallback } from "react";
import { locale } from "base/shared/Localization";
import Arrow from "components/Icons/Arrow";
import FooterCategory from "./footer-category";
import {
  CategoryContent,
  CategoryList,
  CategoryRoot,
  CategoryHeader,
  CategoryTitle,
  ArrowWrapper,
} from "./styled-components";
import { getCategories } from "./utils";

const FooterCategories = () => {
  const [selectedCategory, setCategory] = useState(null);

  const categorySelected = useCallback(
    (category) => () => {
      if (selectedCategory === category) {
        setCategory(null);
      } else {
        setCategory(category);
      }
    },
    [selectedCategory],
  );

  const currentLocale = locale();
  const categories = getCategories(currentLocale);

  return (
    <CategoryList>
      {categories.map(({ value, subCategories, title }) => (
        <CategoryRoot key={title}>
          <CategoryHeader>
            <CategoryTitle
              onClick={categorySelected(value)}
              role="presentation"
              value={value}
            >
              <span>{title}</span>
              <ArrowWrapper>
                <Arrow
                  direction={selectedCategory === value ? "up" : "down"}
                  type="caret"
                />
              </ArrowWrapper>
            </CategoryTitle>

            <CategoryContent selected={selectedCategory === value}>
              <FooterCategory
                currentLocale={currentLocale}
                key={value}
                subCategories={subCategories}
              />
            </CategoryContent>
          </CategoryHeader>
        </CategoryRoot>
      ))}
    </CategoryList>
  );
};

export default FooterCategories;
