import styled from "styled-components";
import { BADI_COLORS, unitizedPx } from "@badi/badi-components";

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const TransportButton = styled.button`
  position: absolute;
  right: ${unitizedPx(1.25)};
  bottom: ${unitizedPx(13.75)};

  width: ${unitizedPx(5)};
  height: ${unitizedPx(3.5)};

  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;

  border-radius: ${unitizedPx(0.25)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.298039);
`;

export const TransportIconOpacity = styled.div`
  opacity: ${({ selected }) => (selected ? 1 : 0.85)};

  &:hover {
    opacity: 1;
  }
`;

export const LoadingMapWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
`;

export const LoadingAssetWrapper = styled.div`
  width: ${unitizedPx(12)};
  height: ${unitizedPx(12)};
  border-radius: ${unitizedPx(0.5)};
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  box-shadow: 0px ${unitizedPx(0.25)} ${unitizedPx(0.5)} 0px rgba(0, 0, 0, 0.16);
`;
