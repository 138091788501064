import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { Heading } from "@badi/badi-components";
import styled from "styled-components";
import Image from "components/Image";
import { Button } from "app/badi-components";
import { translate } from "base/shared/Localization";

const closeModal = () => {
  modal.remove(MODAL_ID.LISTER_CANDIDATES);
};

const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    max-width: 25rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  `,
  Text: styled.p`
    max-width: 20rem;
    text-align: center;
  `,
  Image: styled(Image)`
    width: 100%;
  `,
  Button: styled(Button)`
    max-width: 15rem;
  `,
};

const YourCandidates = () => {
  return (
    <Components.Container>
      <Heading>
        {translate("inbox-lister.first-time-information.heading")}
      </Heading>
      <Components.Text>
        {translate("inbox-lister.first-time-information.text")}
      </Components.Text>
      <Components.Image
        alt={translate("inbox-lister.first-time-information.heading")}
        src="/assets/images/swiping/first-time-requests-screen.svg"
      />
      <Components.Button fullWidth={true} onClick={closeModal}>
        {translate("inbox-lister.first-time-information.cta")}
      </Components.Button>
    </Components.Container>
  );
};

const openYourCandidatesModal = () => {
  modal.add(<YourCandidates />, {
    onIconClose: closeModal,
    id: MODAL_ID.LISTER_CANDIDATES,
    onClose: closeModal,
    type: MODAL_TYPE.ONBOARDING,
    containerProps: { padding: "0" },
  });
};

export default openYourCandidatesModal;
