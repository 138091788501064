class Plans {
  constructor(data) {
    this.entity = data || {};
  }

  get standaloneBoostingDates() {
    return this.entity.standalone_boosting || [];
  }

  get badiGoldDates() {
    return this.entity.badi_plus || [];
  }

  toJSON() {
    return {
      standaloneBoostingDates: this.standaloneBoostingDates,
      badiGoldDates: this.badiGoldDates,
    };
  }
}

export default Plans;
