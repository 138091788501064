import styled, { css } from "styled-components";
import { colors } from "@badi/badi-components";

const MODAL_PADDING_OFFSET = "15px";

export const ButtonWrapper = styled.div`
  box-shadow: 0 0 16px 0 ${colors.lightGrey};
  position: absolute;
  bottom: 0;
  left: 0;

  /* this is needed because of the inner padding of the modal */
  width: calc(100% + ${MODAL_PADDING_OFFSET} + ${MODAL_PADDING_OFFSET});
  margin: 0 -${MODAL_PADDING_OFFSET} -${MODAL_PADDING_OFFSET};
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  overflow: scroll;
`;

export const RecommendationRoot = styled.div`
  max-height: 300px; /* this enables the css animation */
  border-bottom: 1px solid ${colors.lightGrey};

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0.5;
      transition: all 0.25s ease-out;
      max-height: 0;
      padding: 0;
      overflow: hidden;
      border-bottom: none;
    `}
`;

export const SpinnerWrapper = styled.div`
  width: 50px;
  margin: 200px auto;
`;

export const ActionBar = styled.div`
  background-color: ${colors.white};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
`;

export const ModalRoot = styled.div`
  position: relative;
  height: 100%;
`;
