import React from "react";
import { withFormsy } from "formsy-react";

import Slider from "./Slider";

class SliderWithFormsy extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.props.setValue(value);
  }

  render() {
    const { getValue, ...rest } = this.props;
    const safeValue = getValue();

    return <Slider onChange={this.onChange} value={safeValue} {...rest} />;
  }
}

export default withFormsy(SliderWithFormsy);
