import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import { Flex, Text, Button, Heading, Spacer } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image/Image";

export const closeHowToNotificationsModal = () => {
  modal.remove(MODAL_ID.HOW_TO_NOTIFICATION);
};

export const openHowToNotificationsModal = () => {
  modal.add(
    <HowToNotificationsModal onClose={closeHowToNotificationsModal} />,
    {
      mainClassName: "NotificationModal",
      id: MODAL_ID.HOW_TO_NOTIFICATION,
      closeOnOutsideClick: true,
      closable: false,
      type: MODAL_TYPE.ONBOARDING,
    },
  );
};

const HowToNotificationsModal = ({ onClose = null }) => (
  <Flex
    alignItems="center"
    className="NotificationModal"
    data-qa="howto-notification-modal"
    direction="column"
  >
    <Image
      alt={translate("notifications.turnon_modal_title")}
      data-qa="howto-notification-image"
      src="/assets/images/notifications.svg"
    />
    <Spacer bottom={2} top={5}>
      <Heading data-qa="howto-notification-heading" level={3}>
        {translate("notifications.turnon_modal_title")}
      </Heading>
    </Spacer>
    <Spacer bottom={5}>
      <Text align="center" body={1} data-qa="howto-notification-body">
        {translate("notifications.turnon_modal_text")}
      </Text>
    </Spacer>
    <Button data-qa="howto-notification-button" onClick={onClose}>
      {translate("notifications.turnon_modal_cta")}
    </Button>
  </Flex>
);

export default HowToNotificationsModal;
