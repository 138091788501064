import { generateFetchTypes } from "base/shared/TypeHelper";
import Types from "./types";
import {
  fetchInboxConnection,
  fetchInboxHistory,
  sendNewChatTextMessage,
  sendNewChatPictureMessage,
  sendMessageEnquiry,
} from "./api";

export default {
  addMessage(connectionId, message) {
    return {
      type: Types.INBOX_ADD_MESSAGE,
      connectionId,
      message,
    };
  },
  getConnectionDetails(id, token) {
    return {
      types: generateFetchTypes(Types.INBOX_CONNECTION_REQUEST),
      request: fetchInboxConnection(id, token),
    };
  },
  getConnectionHistory(id, params = { per: 20, page: 1 }, token) {
    return {
      types: generateFetchTypes(Types.INBOX_HISTORY_REQUEST),
      request: fetchInboxHistory(id, params, token),
    };
  },
  resetConnectionDetails() {
    return {
      type: Types.INBOX_CONNECTION_RESET,
    };
  },
  newChatMessage(type, content, id, token) {
    const request =
      type === "picture"
        ? sendNewChatPictureMessage(content, id, token)
        : sendNewChatTextMessage(content, id, token);

    return {
      types: generateFetchTypes(Types.INBOX_NEW_MESSAGE_REQUEST),
      request,
    };
  },
  updateConnection(connection) {
    return {
      type: Types.INBOX_CONNETION_UPDATE,
      connection,
    };
  },
  sendMessageEnquiry({
    token,
    roomId,
    message,
    withVisit = false,
    saveMessage = false,
    isFastTrack = false,
  }) {
    return {
      types: generateFetchTypes(Types.SEND_MESSAGE_ENQUIRY_REQUEST),
      request: sendMessageEnquiry(token, {
        roomId,
        message,
        withVisit,
        saveMessage,
        isFastTrack,
      }),
    };
  },
  resetMessageEnquiryError() {
    return {
      type: Types.RESET_MESSAGE_ENQUIRY_ERROR,
    };
  },
};
