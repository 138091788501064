import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${unitizedPx(10)});
  ${mediaquery.lg`
    height: calc(100vh - ${unitizedPx(12)});
  `};
`;

export const SearchMainWrapper = styled.div`
  width: 100%;
  height: calc(100% - ${unitizedPx(12)});
  position: relative;
  display: flex;
`;

export const SearchResultsWrapper = styled.div`
  width: ${({ onlyList }) => (onlyList ? "100%" : unitizedPx(55.5))};
  height: 100%;

  ${mediaquery.lg`
    width: ${unitizedPx(55.5)};
  `}

  ${mediaquery.xl`
    width: ${unitizedPx(108)};
  `}
`;

export const SearchCardsList = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 ${unitizedPx(1)};

  ${mediaquery.md`
      padding: 0 ${unitizedPx(3)};
          &::-webkit-scrollbar {
            display: none;
          }
          scrollbar-width: none;
          &::-ms-overflow-style: none;
    `}
`;

export const SearchMapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${mediaquery.lg`
  width: calc(100% - ${unitizedPx(55.5)});
  `}

  ${mediaquery.xl`
    width: calc(100% - ${unitizedPx(108)});
  `}
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  box-sizing: border-box;
  border-top: solid 1px ${BADI_COLORS.NEUTRAL.NEUTRAL_05};
  margin-bottom: ${unitizedPx(3)};
`;

export const SubSectionWrapper = styled.div`
  & + & {
    margin-top: ${unitizedPx(2)};
  }
`;

export const SearchFiltersWrapper = styled.div`
  position: relative;
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  padding: ${unitizedPx(2)} 0;
  height: ${unitizedPx(12)};
  > div {
    flex-grow: 1;
  }

  align-items: center;

  ${mediaquery.lg`
  padding: ${unitizedPx(3)};
  > div {
    flex-grow: 0;
  }
`}

  ${mediaquery.xl`
  padding: ${unitizedPx(3)} ${unitizedPx(3)} ${unitizedPx(3)};
`}
`;
