import React from "react";
import { Spacer, Heading } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import RoomPreview from "components/room-preview";

const RoomDetail = ({ room, token, isDesktop }) => {
  if (!room) return null;

  return (
    <div data-qa="enquiry-room-detail">
      {isDesktop && (
        <Spacer bottom={3}>
          <Heading>{translate("lister.contact.request_room_details")}</Heading>
        </Spacer>
      )}

      <RoomPreview room={room} token={token} />
    </div>
  );
};

export default RoomDetail;
