import React, { useState, useEffect, useCallback } from "react";

import UserFeedback from "base/shared/user-feedback";
import { translate } from "base/shared/Localization";

import MessageEnquiry from "components/message-enquiry";
import SuccessScreen from "./success-screen";

import { STATUS } from "./constants";

const RoomInvite = ({
  sendInvite,
  room,
  user,
  onInviteSent,
  sendViewerInvite,
  isRecommendation = true,
}) => {
  const { id: userId } = user;
  const { id: roomId, city, address, picture, title } = room;

  const [status, setStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    if (status !== STATUS.ERROR) return;

    UserFeedback.exception("enquiry.error.title", "enquiry.error.description");
  }, [status]);

  const actionHandler = isRecommendation ? sendInvite : sendViewerInvite;

  const handleSubmit = useCallback(({ message }) => {
    setStatus(STATUS.FETCHING);
    actionHandler({
      roomId,
      message,
      userId,
    })
      .then(() => {
        setStatus(STATUS.FETCHED);
        onInviteSent({ roomId, userId });
      })
      .catch(() => setStatus(STATUS.ERROR));
  }, []);

  if (status === STATUS.FETCHED) {
    return <SuccessScreen />;
  }

  return (
    <MessageEnquiry
      buttonText={translate("room_invite.button_text")}
      description={translate("room_invite.description")}
      disabled={status === STATUS.FETCHING}
      isSaveMessageAvailable={false}
      messageRequired={false}
      onSubmit={handleSubmit}
      owner={user}
      placeholder={translate("room_invite.placeholder")}
      roomAddress={address}
      roomCity={city}
      roomId={roomId}
      roomPicture={picture}
      roomTitle={title}
      title={translate("room_invite.title")}
    />
  );
};

export default RoomInvite;
