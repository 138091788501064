import React from "react";
import { Container, Row, Col } from "reactstrap";
import { BADI_COLORS, Text, Spacer, Flex } from "@badi/badi-components";
import Image from "components/Image";
import InjectHtml from "components/inject-html";
import Padlock from "components/ds-icons/padlock";
import {
  Avatar,
  ContentWrapper,
  Root,
  ImageWrapper,
  CustomHeading,
} from "./styled-components";
import { getColorsByType } from "./utils";
import {
  AVATAR_STYLES,
  DESKTOP_FLEX_CONFIG,
  DESKTOP_MARGINS,
  MOBILE_MARGINS,
  HEADER_CONFIG_SIZE,
  LAYOUT_TYPES,
} from "./constants";

const PlansLayout = ({
  avatar = null,
  children,
  title,
  description = null,
  image = null,
  type = LAYOUT_TYPES.PLUS,
}) => {
  const colors = getColorsByType(type);

  return (
    <Flex direction="column">
      <Root background={colors.background}>
        <Spacer {...MOBILE_MARGINS} md={DESKTOP_MARGINS}>
          <Container>
            <Row>
              <Col {...HEADER_CONFIG_SIZE}>
                <Flex direction="column" gutter={2} md={DESKTOP_FLEX_CONFIG}>
                  <ImageWrapper>
                    {avatar && (
                      <>
                        <Avatar alt="" data-qa="user-avatar" src={avatar} />
                        <Padlock
                          color={BADI_COLORS.NEUTRAL.NEUTRAL_00}
                          customStyles={AVATAR_STYLES}
                          height={20}
                          width={20}
                        />
                      </>
                    )}
                    {image && (
                      <Image
                        styles={{ maxWidth: "60%" }}
                        alt="checkout image"
                        lazy={false}
                        src={image}
                      />
                    )}
                  </ImageWrapper>
                  <CustomHeading color={colors.heading} data-qa="plans-title">
                    {title}
                  </CustomHeading>
                  {description && (
                    <Text
                      align="center"
                      color={colors.description}
                      dataQa="plans-description"
                    >
                      <InjectHtml>{description}</InjectHtml>
                    </Text>
                  )}
                </Flex>
              </Col>
            </Row>
          </Container>
        </Spacer>
      </Root>
      <Spacer bottom={10}>
        <ContentWrapper>
          <Container>
            <Row>{children}</Row>
          </Container>
        </ContentWrapper>
      </Spacer>
    </Flex>
  );
};

PlansLayout.TYPES = LAYOUT_TYPES;

export default PlansLayout;
