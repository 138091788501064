import React from "react";
import { BUTTON_TYPES, Button, Spacer } from "@badi/badi-components";

import withStripeSepaRedirect from "./with-sepa-details-redirect";

const SetSepaDetailsButton = ({
  dataQa,
  isSepaEnabled,
  handleRedirectToCheckout,
  variant,
  children,
}) => (
  <Spacer top={8}>
    <Button
      data-qa={dataQa}
      disabled={!isSepaEnabled}
      fullWidth={true}
      onClick={handleRedirectToCheckout}
      variant={variant}
    >
      {children}
    </Button>
  </Spacer>
);

export default withStripeSepaRedirect(SetSepaDetailsButton);
