import styled from "styled-components";
import { BADI_COLORS, mediaquery, unitizedPx } from "@badi/badi-components";
import { getPublicAsset } from "base/shared/Utils";

const bgImage = getPublicAsset("images/landings/about-badi/info-foot.jpg");

export const Wrapper = styled.div`
  width: 100%;
  height: auto;

  background-image: url("${bgImage}");
  background-size: cover;
  background-repeat: no-repeat;

  ${mediaquery.md`
    background-position-x: ${unitizedPx(-50)};
  `}

  ${mediaquery.lg`
    height: ${unitizedPx(58)};
    background-position-x: initial;
  `}
`;

export const Content = styled.div`
  background-color: ${BADI_COLORS.NEUTRAL.NEUTRAL_80};
  padding: ${unitizedPx(2)};
  border-top-left-radius: ${unitizedPx(1)};
  border-bottom-left-radius: ${unitizedPx(1)};
  margin-left: ${unitizedPx(-2)};
  margin-right: ${unitizedPx(-2)};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);

  ${mediaquery.md`
    margin-left: auto;
    margin-right: ${unitizedPx(-5)};
    max-width: 50%;
  `}

  ${mediaquery.lg`
    padding: ${unitizedPx(3)};
    margin-bottom: ${unitizedPx(-6)};
    margin-right: initial;
    border-radius: ${unitizedPx(1)};
  `}
`;

export const AdditionalSpace = styled.div`
  ${mediaquery.lg`
    height: 48px;
    width: 100%;
    background-color: #f3f1e9;
  `}
`;
