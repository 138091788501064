export const ROOM_ACTIONS = {
  CHAT: "chat",
  CONTACT: "contact",
  BOOKED: "booked",
  INBOX: "inbox",
  PENDING: "pending",
  SETTINGS: "settings",
  REQUESTED: "requested",
  BOOK: "book",
  RENTED: "rented",
  NOT_AVAILABLE: "not_available",
  VISIT: "visit",
};
