import React, { useState } from "react";
import { Flex } from "@badi/badi-components";

import Close from "components/Icons/Close";
import TopbarMenu from "components/Icons/TopbarMenu";

import MenuContext from "./menu-context";
import { Button, ContentWrapper, Root } from "./styled-components";

const Menu = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <Root data-qa="topbar-menu">
      <Button onClick={handleClick}>
        <TopbarMenu />
      </Button>
      <ContentWrapper visible={visible}>
        <Flex direction="column">
          <Button onClick={handleClick}>
            <Close format="small" />
          </Button>
          <MenuContext.Provider value={{ onClose: handleClick }}>
            {children}
          </MenuContext.Provider>
        </Flex>
      </ContentWrapper>
    </Root>
  );
};

export default Menu;
