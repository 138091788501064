import VisitModel from "datamodel/visits/models/visit-model";

const InboxConnectionModel = {
  fetching: false,
  error: null,
  connection: {
    allowedActions: {
      message: false,
      archive: false,
      report: false,
      leave: false,
    },
    room: null,
    user: null,
  },
  newMessage: {
    fetching: false,
    error: null,
  },
  history: {
    fetching: false,
    error: null,
    messages: [],
    pagination: {},
  },
  newMessageEnquiry: {
    fetching: false,
    fetched: false,
    error: null,
    message: "",
  },
  visits: new VisitModel(),
  enquiry: {},
  lastMessageId: 0,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.InboxConnection) {
    return {
      ...InboxConnectionModel,
      error: "There was an error in InboxConnectionModel",
    };
  }
  return state;
}

export { InboxConnectionModel, setInitialState };
