import React from "react";
import Slider from "components/slider-new";
import { Spacer } from "@badi/badi-components";

import { interpolateAllQueryParam } from "base/shared/tracking-headers";
import { getRoomDetailPath } from "Sections/Room/routes";
import "swiper/swiper-bundle.min.css";

import Subheading from "./components/subheading";
import { RoomCardWrapper, RoomCardLink } from "./styled-components";
import Headline from "./components/headline";
import ExtraInfo from "./components/extra-info";
import LabelsBar from "./components/labels-bar";
import ListerAvatar from "./components/lister-avatar";

const RoomCard = ({
  pictures,
  subheading,
  headline,
  extraInfo,
  labels,
  lister,
  selectRoomById = null,
  handleEtParams,
  roomId,
  htmlId,
  index = 1,
  isMapCard = false,
}) => {
  const handleMouseEnterHandler = () => {
    selectRoomById(roomId);
  };

  const handleMouseLeaveHandler = () => {
    selectRoomById(0);
  };

  const etParams = handleEtParams ? handleEtParams() : null;
  const linkUrl = interpolateAllQueryParam(
    getRoomDetailPath({ roomId }),
    etParams,
  );

  return (
    <RoomCardWrapper
      data-qa="room-card"
      id={htmlId}
      index={index}
      onMouseEnter={selectRoomById && handleMouseEnterHandler}
      onMouseLeave={selectRoomById && handleMouseLeaveHandler}
    >
      <RoomCardLink data-qa="room-card-link" href={linkUrl} target="_blank">
        <Slider dataQa="slider-room-card" pictures={pictures} type="room">
          {labels && <LabelsBar dataQa="room-card-labels" labels={labels} />}
        </Slider>
        {lister && (
          <ListerAvatar
            dataQa="room-card-lister-avatar"
            label={lister.label}
            picture={lister.picture}
          />
        )}
        <Spacer left={isMapCard ? 1 : 0} right={isMapCard ? 1 : 0}>
          {subheading && (
            <Spacer top={2}>
              <Subheading
                color={subheading.color}
                content={subheading.content}
                dataQa="room-card-subheading"
              />
            </Spacer>
          )}
          {headline && (
            <Spacer top={2}>
              <Headline
                color={headline.color}
                content={headline.content}
                dataQa="room-card-headline"
              />
            </Spacer>
          )}
          {extraInfo && (
            <Spacer top={2}>
              <ExtraInfo dataQa="room-card-extra-info" extraInfo={extraInfo} />
            </Spacer>
          )}
        </Spacer>
      </RoomCardLink>
    </RoomCardWrapper>
  );
};

export default RoomCard;
