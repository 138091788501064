import React, { Component } from "react";
import { Link } from "react-router";
import classNames from "classnames";

import { translate } from "base/shared/Localization";
import { scrollTo } from "base/shared/Utils";

import "./SideBarMenuExt.css";

class SideBarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedItemId: props.initialSelectedId };
    this.menuItem = null;

    this.clickHandler = this.clickHandler.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getItem = this.getItem.bind(this);
  }

  getItem(item) {
    const { itemType } = this.props;

    switch (itemType) {
      case "button":
        return (
          <button
            className="info__page"
            onClick={this.clickHandler}
            value={item.id}
          >
            <span className="info__label">
              {translate(item.title, { smart_count: 2 })}
            </span>
            <span className="info__counter">
              {item.total || item.items.length}
            </span>
          </button>
        );
      case "link":
        return (
          <span className="info__page">
            <Link className="info__label" to={item.url}>
              {translate(item.title)}
            </Link>
          </span>
        );
      default:
        return (
          <span className="info__page">
            <span
              className="info__label"
              onClick={item.clickHandler}
              role="button"
              tabIndex="-1"
            >
              {translate(item.title)}
            </span>
          </span>
        );
    }
  }

  clickHandler(e) {
    e.preventDefault();

    const itemId = parseInt(e.currentTarget.value, 10);

    this.setState({ selectedItemId: itemId }, () => {
      this.toggleMenu();

      if (this.props.autoScroll) {
        this.scrollToItemContent(itemId);
      }
    });
  }

  toggleMenu(e) {
    if (e) e.preventDefault();

    if (this.menuItem) {
      if (this.menuItem.classList.contains("info_open")) {
        this.menuItem.className = this.menuItem.className.replace(
          " info_open",
          "",
        );
      } else {
        this.menuItem.className += " info_open";
      }
    }
  }

  scrollToItemContent(id) {
    const { prefixContentClassName } = this.props;
    const element = document.getElementById(`${prefixContentClassName}-${id}`);

    scrollTo(element.offsetTop, 200);
  }

  filteredItems = () => {
    const { items, isSepaShown } = this.props;
    return [...items].filter((item) => !(!isSepaShown && item.id === "sepa"));
  };

  render() {
    const { className, title } = this.props;
    const { selectedItemId } = this.state;

    const mappedItems = this.filteredItems().map((item, i) => (
      <li
        className={classNames(
          "info__item",
          { info__item_active: selectedItemId === item.id || item.active },
          className,
        )}
        key={item.id || i}
      >
        {this.getItem(item)}
      </li>
    ));

    return (
      <React.Fragment>
        <aside className="sidebar sidebar_listings">
          <div className="sidebar__content">
            <section
              className="info info_menu"
              ref={(e) => {
                this.menuItem = e;
              }}
            >
              <header className="info__head">
                <h2>{translate(title)}</h2>
              </header>
              <div className="info__main">
                <ul className="info__menu">{mappedItems}</ul>
              </div>
              <div
                className="info__overlay"
                onClick={this.toggleMenu}
                role="button"
                tabIndex="-2"
              />
              <div
                className="info__trigger"
                onClick={this.toggleMenu}
                role="button"
                tabIndex="-3"
              />
            </section>
          </div>
        </aside>
        <div className="SideBarMenu__Space" />
      </React.Fragment>
    );
  }
}

SideBarMenu.defaultProps = {
  autoScroll: true,
  className: "",
  initialSelectedId: 0,
  itemType: "button",
  prefixContentClassName: "",
};

export default SideBarMenu;
