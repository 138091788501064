import React from "react";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { Router, browserHistory } from "react-router";
import ReactGA from "react-ga";
import { hot } from "react-hot-loader/root";

import envVars from "base/shared/Env";
import initialState from "store/InitialState";
import configureStore from "store/ConfigureStore";
import routes from "../routes";

(() => {
  if (typeof window !== "undefined") {
    const { BADI_GA, NODE_ENV } = envVars();
    ReactGA.initialize(
      BADI_GA,
      NODE_ENV === "development" ? { debug: false, cookieDomain: "none" } : {},
    );

    ReactGA.set({ anonymizeIp: true });
  }
})();

const updateRouteHandler = () => {
  if (typeof window !== "undefined") window.scrollTo(0, 0);
};

const store = configureStore(initialState);

// this component exists for the hot module
const RouterComponent = hot(() => {
  return (
    <Router history={browserHistory} onUpdate={updateRouteHandler}>
      {routes}
    </Router>
  );
});

hydrate(
  <Provider store={store}>
    <RouterComponent />
  </Provider>,
  document.getElementById("root"),
);
