import React from "react";

import { TopBarBase } from "components/Layout/TopBar";
import Logo from "components/Layout/TopBar/components/Logo";

const ListerScoreTopBar = () => (
  <TopBarBase>
    <Logo wrapperClassName="" />
  </TopBarBase>
);

export default ListerScoreTopBar;
