import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl as getServerUrl } from "base/shared/Utils";
import { parseTags } from "datamodel/User/utils";
import { DeleteReasons, User, Legal } from "datamodel/User/models";

const changePassword = (params, token) => {
  const { BADI_CHANGE_PASS_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_CHANGE_PASS_SERVICE);

  return fetch(serverUrl, { params, method: "POST", token });
};

const fetchMe = (token, additionalParams) => {
  const { BADI_USER_PROFILE_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_USER_PROFILE_SERVICE);

  return fetch(serverUrl, { token, additionalParams });
};

const fetchLegal = (token, additionalParams) => {
  const { BADI_LEGAL } = envVars();
  const serverUrl = getServerUrl(BADI_LEGAL);
  return fetch(serverUrl, { token, additionalParams }).then((res) =>
    new Legal(res).toJSON(),
  );
};

const fetchOnboarding = (token) => {
  const { BADI_ONBOARDING } = envVars();
  const serverUrl = getServerUrl(BADI_ONBOARDING);

  return fetch(serverUrl, { token }).then((res) => res.data);
};

const fetchUpdateOnboarding = (token, params) => {
  const { BADI_ONBOARDING } = envVars();
  const serverUrl = getServerUrl(BADI_ONBOARDING);

  return fetch(serverUrl, { params, method: "PUT", token });
};

const fetchUser = (id, token) => {
  const { BADI_USER_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_USER_SERVICE.replace("{id}", id));

  return fetch(serverUrl, { token }).then((res) => new User(res).toJSON());
};

const fetchTags = (token) => {
  const { BADI_USER_TAGS } = envVars();
  const serverUrl = getServerUrl(BADI_USER_TAGS);

  return fetch(serverUrl, { token }).then((res) => parseTags(res));
};

const updateProfile = (token, params) => {
  const { BADI_USER_PROFILE_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_USER_PROFILE_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "PUT",
    token,
  });
};

const fetchSettings = (token) => {
  const { BADI_USER_SETTINGS_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_USER_SETTINGS_SERVICE);

  return fetch(serverUrl, { token });
};

const fetchDeleteReasons = (token, mode) => {
  const { BADI_DELETE_REASONS_ACCOUNT_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_DELETE_REASONS_ACCOUNT_SERVICE);

  return fetch(serverUrl, { params: { account_mode: mode }, token }).then(
    (res) => new DeleteReasons(res.data).toJSON(),
  );
};

const deleteAccount = (token, mode, { reason, otherReason, feedback }) => {
  const { BADI_DELETE_ACCOUNT_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_DELETE_ACCOUNT_SERVICE);
  const params = {
    account_mode: mode,
    reason,
    other_reason: otherReason,
    feedback,
  };

  return fetch(serverUrl, { params, method: "POST", token });
};

const updateSettings = (token, params) => {
  const { BADI_USER_SETTINGS_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_USER_SETTINGS_SERVICE);

  return fetch(serverUrl, { params, method: "PUT", token });
};

const fetchUpdateLegal = ({ token }, params) => {
  const { BADI_LEGAL } = envVars();
  const serverUrl = getServerUrl(BADI_LEGAL);

  return fetch(serverUrl, { params, method: "PUT", token }).then((res) =>
    new Legal(res).toJSON(),
  );
};

const requestEmailConfirmation = (token, params) => {
  const { BADI_EMAIL_CONFIRMATION_REQUEST_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_EMAIL_CONFIRMATION_REQUEST_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const checkEmailConfirmation = (token, params) => {
  const { BADI_EMAIL_CONFIRMATION_CHECK_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_EMAIL_CONFIRMATION_CHECK_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const setPromoCode = (token, params) => {
  const { BADI_COUPON_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_COUPON_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const requestPhoneConfirmation = (token, params) => {
  const { BADI_PHONE_CONFIRMATION_REQUEST_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_PHONE_CONFIRMATION_REQUEST_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const checkPhoneConfirmation = (token, params) => {
  const { BADI_PHONE_CONFIRMATION_CHECK_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_PHONE_CONFIRMATION_CHECK_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const checkFacebookConfirmation = (token, params) => {
  const { BADI_FACEBOOK_CONFIRMATION_CHECK_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_FACEBOOK_CONFIRMATION_CHECK_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const checkGoogleConfirmation = (token, params) => {
  const { BADI_GOOGLE_CONFIRMATION_CHECK_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_GOOGLE_CONFIRMATION_CHECK_SERVICE);

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const reportUser = (token, { id, reason, description }) => {
  const { BADI_USER_REPORT_SERVICE } = envVars();
  const serverUrl = getServerUrl(BADI_USER_REPORT_SERVICE.replace("{id}", id));
  const params = { reason, description };

  return fetch(serverUrl, {
    params,
    requiredParams: true,
    method: "POST",
    token,
  });
};

const fetchListerScore = ({ token }) => {
  const { BADI_OVERVIEW_SCORE } = envVars();
  const serverUrl = getServerUrl(BADI_OVERVIEW_SCORE);

  return fetch(serverUrl, { token }).then((res) => res.data);
};

export {
  changePassword,
  checkEmailConfirmation,
  checkFacebookConfirmation,
  checkGoogleConfirmation,
  checkPhoneConfirmation,
  deleteAccount,
  fetchMe,
  fetchLegal,
  fetchOnboarding,
  fetchUpdateOnboarding,
  fetchSettings,
  fetchTags,
  fetchUser,
  fetchListerScore,
  fetchDeleteReasons,
  requestEmailConfirmation,
  requestPhoneConfirmation,
  reportUser,
  updateProfile,
  updateSettings,
  setPromoCode,
  fetchUpdateLegal,
};
