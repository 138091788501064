import styled from "styled-components";
import { unitizedPx, BADI_COLORS } from "@badi/badi-components";

export const LoginButton = styled.div`
  cursor: pointer;
  img {
    display: block;
  }
`;

export const MenuLink = styled.a`
  padding: ${unitizedPx(0.25)} ${unitizedPx(1)};
  cursor: pointer;
  position: relative;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`;

export const MenuButton = styled.div`
  position: relative;
  border: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_00};
  padding: ${unitizedPx(0.75)} ${unitizedPx(3)};
  cursor: pointer;
  border-radius: ${unitizedPx(0.5)};
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`;

export const UserMenuWrapper = styled.div`
  &:hover {
    nav > ul {
      visibility: visible;

      opacity: 1;
      transform: translate(0, 0);
    }
  }
  .TopBar__UserMenu > .UserMenu__avatar {
    width: ${unitizedPx(4)};
    height: ${unitizedPx(4)};
  }
`;
