import React from "react";
import { Flex, Heading, Spacer } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import ContextualButton from "components/ContextualButton";
import { openReportModal } from "components/Report";

import { BlockWrapper } from "../styled-components";

const SidebarMeta = ({
  inboxConnectionId = undefined,
  name,
  roomId,
  showEnquiry = true,
  sendEnquiry = () => {},
  token,
  userId,
}) => {
  const handleOpenReportModal = () =>
    openReportModal({ roomId, userId, token });

  return (
    <Spacer bottom={4}>
      <Flex direction="column" gutter={1}>
        {sendEnquiry && showEnquiry && (
          <BlockWrapper>
            <Heading data-qa="send-enquiry-title" level={3}>
              {translate("room.detail.contact", { name })}
            </Heading>
            <Spacer bottom={3} top={2}>
              <ContextualButton
                dataQa="send-enquiry-button"
                disabled={!!inboxConnectionId}
                icon="message"
                onClick={sendEnquiry}
                text={translate("room.detail.header.message")}
              />
            </Spacer>
          </BlockWrapper>
        )}
        <Spacer top={2}>
          <ContextualButton
            dataQa="report-button"
            icon="report"
            onClick={handleOpenReportModal}
            text={translate("room.detail.button.report")}
          />
        </Spacer>
      </Flex>
    </Spacer>
  );
};

export default SidebarMeta;
