import React from "react";
import { Flex, Text, Spacer } from "@badi/badi-components";
import { Detail } from "./styled-components";

const ExtraInfo = ({ extraInfo, dataQa }) => (
  <div data-qa={dataQa}>
    <Flex>
      {extraInfo.map(
        (
          {
            title: { content: contentTitle, color: colorTitle },
            body: { content: contentBody, color: colorBody },
          },
          i,
        ) => (
          <Spacer
            basis="content"
            grow={0}
            key={contentTitle}
            right={i < 2 ? 1 : 0}
          >
            <Detail
              as={Flex}
              direction="column"
              showBorder={i < extraInfo.length - 1}
            >
              <Spacer bottom={0.5}>
                <Text body="10-Medium" color={colorTitle}>
                  {contentTitle.toUpperCase()}
                </Text>
              </Spacer>
              <Text body="12-Medium" color={colorBody}>
                {contentBody}
              </Text>
            </Detail>
          </Spacer>
        ),
      )}
    </Flex>
  </div>
);

export default ExtraInfo;
