export const HEADER_PARAMS = {
  SEARCH_SOURCES: {
    keyVal: "et-search-source",
    options: {
      MAIN_CITY_BUTTON: "main-city-button",
      MAIN_NEIGHBOURHOOD_BUTTON: "main-neighbourhood-button",
      SEARCH_BAR: "search-bar",
      RECENT_SEARCH: "recent-search",
      NEARBY_SEARCH: "nearby-search",
      DEEP_LINK: "deep-link",
      COLLECTION: "collection",
      SEEKER_PREFERENCES: "seeker-preferences",
      NONE: "none",
    },
  },
  MAP_USAGE: {
    keyVal: "et-map-usage",
    options: {
      NEW_SEARCH: "new-search",
      NONE: "none",
    },
  },
  SEARCH_SOURCE_SCREEN: {
    keyVal: "et-search-source-screen",
    options: {
      HOME_PAGE: "home-page",
      RESULTS_PAGE: "results-page",
      SEEKER_PREFERENCES: "seeker-preferences",
      NONE: "none",
    },
  },
  SEARCH_SORTING: {
    keyVal: "et-search-sorting",
    options: {
      RELEVANCE: "relevance",
      RECENT: "recent",
      CHEAPEST: "cheapest",
      MORE_EXPENSIVE: "more_expensive",
      CLOSEST: "closest",
      NONE: "none",
    },
  },
  ROOM_VIEW_SOURCE: {
    keyVal: "et-room-view-source",
    options: {
      SEARCH_FEED: "search-feed",
      SEARCH_MAP: "search-map",
      SIMILAR_ROOMS: "similar-rooms",
      RECOMMENDATIONS_NEWSLETTER: "recommendations-newsletter",
      URL: "url",
      INBOX: "inbox",
      MY_LISTINGS: "my-listings",
      NONE: "none",
    },
  },
};

const { RELEVANCE, RECENT, CHEAPEST, MORE_EXPENSIVE } =
  HEADER_PARAMS.SEARCH_SORTING.options;

export const SEARCH_SORTING_MAPPER = {
  1: RECENT,
  2: CHEAPEST,
  3: MORE_EXPENSIVE,
  4: RELEVANCE,
};

export const PARAMS_NULL_VALUES = {
  "search-source": "none",
  "map-usage": "none",
  "search-source-screen": "none",
  "search-sorting": "none",
  "room-view-source": "none",
};

export const HEADER_PARAMS_KEY_VALUE = Object.values(HEADER_PARAMS).map(
  ({ keyVal }) => keyVal,
);

export const ET_PARAMS_PREFIX = "et-";
