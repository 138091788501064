import React, { PureComponent } from "react";
import { Container, Row, Col } from "reactstrap";
import Formsy from "formsy-react";
import { Flex, Button, Spacer, Text } from "@badi/badi-components";
import Select from "components/Form/Select/SelectWithFormsy";
import Input from "components/Form/Input/InputWithFormsy";
import Counter from "components/Form/Counter/CounterWithFormsy";
import Choice from "components/Form/Choice/ChoiceWithFormsy";
import Checkbox from "components/Form/checkbox-with-formsy";

import { getAmenities } from "Sections/Room/components/Steps/Property/utils";
import { AMENITIES_TYPES } from "Sections/Room/components/Steps/Property/constants";

import { translate } from "base/shared/Localization";
import {
  convertAffirmationToBool,
  convertBoolToAffirmation,
} from "base/shared/Utils";

import FormBlock from "components/form-block";

import RadioButtonGroup from "components/Form/RadioButton/RadioButtonGroupWithFormsy";

import ButtonsWrapper from "../../buttons-wrapper";
import DecorativeRightImage from "../../decorative-right-image";
import {
  HAS_LIFT,
  ORIENTATION,
  FURNISHED_ROOM,
  CERTIFICATION,
  SUB_PLACE_TYPES,
  BED_TYPES,
  PLACE_TYPES,
} from "./constants";

class RoomListProperty extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      flatAmenities: [],
      roomAmenities: [],
      bedType: null,
      femaleTenants: 0,
      maleTenants: 0,
      nonBinaryTenants: 0,
      doubleBedrooms: 0,
      singleBedrooms: 0,
      bathrooms: 0,
      toilets: 0,
      propertyType: null,
      roomType: null,
      roomSize: null,
      rules: [],
      canSubmit: false,
      flatSize: "",
      lift: null,
      orientation: null,
      bedIncluded: null,
      resident: false,
    };

    this.bedTypeItems = translate(BED_TYPES).map((b) => ({
      ...b,
      label: b.title,
    }));

    this.propertyTypeItems = translate(SUB_PLACE_TYPES[props.propertyType]);
    this.roomAmenitiesList = translate(
      getAmenities({
        amenityType: AMENITIES_TYPES.AMENITIES,
        propertyType: PLACE_TYPES.ROOM,
      }),
    );

    this.flatAmenitiesList = translate(
      getAmenities({
        amenityType: AMENITIES_TYPES.AMENITIES,
        propertyType: PLACE_TYPES.ENTIRE_PLACE,
      }),
    );
    this.rulesList = translate(
      getAmenities({
        amenityType: AMENITIES_TYPES.RULES,
        propertyType: props.propertyType,
      }),
    );
    this.liftOptions = translate(HAS_LIFT);
    this.orientationOptions = translate(ORIENTATION);
    this.bedIncludedOptions = translate(FURNISHED_ROOM);
  }

  UNSAFE_componentWillMount() {
    this.propsToState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.propsToState(nextProps);
  }

  getFormData = () => {
    const {
      flatAmenities,
      roomAmenities,
      bedType,
      femaleTenants,
      maleTenants,
      nonBinaryTenants,
      propertyType,
      roomType,
      roomSize,
      doubleBedrooms,
      singleBedrooms,
      bathrooms,
      toilets,
      rules,
      flatSize,
      isEntireProperty,
      lift,
      orientation,
      bedIncluded,
      resident,
    } = this.state;

    const property = {
      flatAmenities,
      roomAmenities,
      bedType,
      femaleTenants: isEntireProperty ? 0 : femaleTenants,
      maleTenants: isEntireProperty ? 0 : maleTenants,
      nonBinaryTenants: isEntireProperty ? 0 : nonBinaryTenants,
      doubleBedrooms,
      singleBedrooms,
      bathrooms,
      toilets,
      propertyType,
      roomType,
      roomSize,
      rules,
      flatSize,
      lift,
      orientation,
      bedIncluded,
      resident,
    };

    return property;
  };

  handleOnChange = (formData) => {
    const placeType = formData.placeType || this.state.placeType;
    const isEntireProperty = placeType === 2;

    this.setState({
      ...formData,
      isEntireProperty,
    });
  };

  handleOnInvalid = () => {
    this.setState({ canSubmit: false });
  };

  handleOnValid = () => {
    this.setState({ canSubmit: true });
  };

  handleGoPreviousStep = () => {
    const { setRoomProperty, previousStep } = this.props;

    const payload = this.getFormData();
    setRoomProperty(payload);
    previousStep(payload);
  };

  handleSubmit = () => {
    const { nextStep, setRoomProperty } = this.props;

    const payload = this.getFormData();

    setRoomProperty(payload);
    nextStep(payload);
  };

  getFormSections = () => {
    const {
      flatAmenities,
      roomAmenities,
      bedType,
      femaleTenants,
      maleTenants,
      nonBinaryTenants,
      propertyType,
      roomType,
      doubleBedrooms,
      singleBedrooms,
      bathrooms,
      toilets,
      roomSize,
      rules,
      flatSize,
      isEntireProperty,
      lift,
      orientation,
      bedIncluded,
      resident,
    } = this.state;
    const { sizeUnit } = this.props;

    const sections = {
      orientationSection: (
        <FormBlock
          isRequired={true}
          title={translate("room.list.step2.orientation.title")}
          subTitle={
            isEntireProperty
              ? null
              : translate("room.list.step2.orientation.subtitle")
          }
        >
          <RadioButtonGroup
            dataQa="listing-orientation"
            items={this.orientationOptions}
            name="orientation"
            required={true}
            value={orientation || null}
          />
        </FormBlock>
      ),
      bedIncludedSection: (
        <FormBlock title={translate("room.list.step2.bed.title")}>
          <RadioButtonGroup
            dataQa="listing-bed-included"
            items={this.bedIncludedOptions}
            name="bedIncluded"
            required={true}
            value={bedIncluded || convertBoolToAffirmation(bedType)}
          />
        </FormBlock>
      ),
      bedTypeSection: (
        <FormBlock
          disabled={!convertAffirmationToBool(bedIncluded)}
          isRequired={true}
          title={translate("room.list.step3.bedType.title")}
        >
          <RadioButtonGroup
            dataQa="listing-bed-type"
            items={this.bedTypeItems}
            name="bedType"
            required={convertAffirmationToBool(bedIncluded)}
            value={bedType}
          />
        </FormBlock>
      ),
      roomSizeSection: (
        <FormBlock
          isRequired={true}
          title={translate("room.list.step3.roomSize.title")}
        >
          <Input
            className="roomSize"
            dataQa="listing-room-size"
            digitsType="number"
            label={sizeUnit.label}
            maxlength={4}
            name="roomSize"
            required={true}
            value={roomSize}
          />
        </FormBlock>
      ),
      tenantsSection: (
        <FormBlock title={translate("room.list.step2.tenants.title")}>
          <Counter
            dataQa="maleTenants-counter"
            label={translate("room.list.step2.tenants.male")}
            max={10}
            min={0}
            name="maleTenants"
            value={maleTenants}
          />
          <Counter
            dataQa="femaleTenants-counter"
            label={translate("room.list.step2.tenants.female")}
            max={10}
            min={0}
            name="femaleTenants"
            value={femaleTenants}
          />
          <Counter
            dataQa="nonBinaryTenants-counter"
            label={translate("room.list.step2.tenants.non_binary")}
            max={10}
            min={0}
            name="nonBinaryTenants"
            value={nonBinaryTenants}
          />
          <Checkbox
            checked={resident}
            dataQa="is-lister-resident"
            id="resident"
            name="resident"
            value={resident}
          >
            <Text body="14-Regular" inline={true}>
              {translate("room.list.step2.tenants.resident")}
            </Text>
          </Checkbox>
        </FormBlock>
      ),
      flatSizeSection: (
        <FormBlock
          isRequired={true}
          title={translate("room.list.step2.flatSize.title")}
        >
          <Input
            className="flatSize"
            dataQa="listing-entire-place-size"
            digitsType="number"
            label={sizeUnit.label}
            maxlength={4}
            name="flatSize"
            required={true}
            value={flatSize}
          />
        </FormBlock>
      ),
      bedroomsSection: (
        <FormBlock title={translate("listing.setup.bedrooms.title")}>
          <Counter
            dataQa="listing-double-bedroom"
            label={translate("listing.setup.bedrooms.option_double")}
            max={10}
            min={0}
            name="doubleBedrooms"
            value={doubleBedrooms}
          />
          <Counter
            dataQa="listing-single-bedroom"
            label={translate("listing.setup.bedrooms.option_single")}
            max={10}
            min={0}
            name="singleBedrooms"
            value={singleBedrooms}
          />
        </FormBlock>
      ),
      bathroomsSection: (
        <FormBlock title={translate("listing.setup.bathrooms.title")}>
          <Counter
            dataQa="listing-bathrooms"
            label={translate("listing.setup.bathrooms.option")}
            max={10}
            min={0}
            name="bathrooms"
            value={bathrooms}
          />
          <Counter
            dataQa="listing-toilets"
            label={translate("listing.setup.toilets.option")}
            max={10}
            min={0}
            name="toilets"
            value={toilets}
          />
        </FormBlock>
      ),
      flatAmenitiesSection: (
        <FormBlock title={translate("room.list.step2.flatAmenities.title")}>
          <Choice
            dataQa="listing-flat-amenities"
            items={this.flatAmenitiesList}
            name="flatAmenities"
            required="isFalse"
            value={flatAmenities}
          />
        </FormBlock>
      ),
      roomAmenitiesSection: (
        <FormBlock title={translate("room.list.step2.roomAmenities.title")}>
          <Choice
            dataQa="listing-room-amenities"
            items={this.roomAmenitiesList}
            name="roomAmenities"
            required="isFalse"
            value={roomAmenities}
          />
        </FormBlock>
      ),
      rulesSection: (
        <FormBlock title={translate("room.list.step2.rules.title")}>
          <Choice
            dataQa="listing-house-rules"
            items={this.rulesList}
            name="rules"
            required="isFalse"
            value={rules}
          />
        </FormBlock>
      ),
      liftSection: (
        <FormBlock
          isRequired={true}
          title={translate("room.list.step2.lift.title")}
        >
          <RadioButtonGroup
            dataQa="listing-lift"
            items={this.liftOptions}
            name="lift"
            required={true}
            value={lift}
          />
        </FormBlock>
      ),
    };

    const roomSectionIds = [
      "flatSizeSection",
      "liftSection",
      "bedroomsSection",
      "bathroomsSection",
      "tenantsSection",
      "roomSizeSection",
      "orientationSection",
      "bedIncludedSection",
      "bedTypeSection",
      "flatAmenitiesSection",
      "roomAmenitiesSection",
      "rulesSection",
    ];
    const flatSectionsIds = [
      "flatSizeSection",
      "liftSection",
      "bedroomsSection",
      "bathroomsSection",
      "orientationSection",
      "flatAmenitiesSection",
      "rulesSection",
    ];

    const sectionIdsList = isEntireProperty ? flatSectionsIds : roomSectionIds;
    return sectionIdsList.map((sectionId) => sections[sectionId]);
  };

  propsToState = ({ storeProperty }) => {
    const propertyHasInfo = !!Object.keys(storeProperty).length;
    const bedIncluded = storeProperty.bedType
      ? "yes"
      : storeProperty.bedIncluded;

    if (propertyHasInfo) {
      const isEntireProperty = storeProperty.placeType === 2;

      this.setState({
        ...storeProperty,
        bedIncluded,
        isEntireProperty,
      });
    }
  };

  render() {
    const { canSubmit } = this.state;
    return (
      <Spacer bottom={12} top={4}>
        <Container>
          <Row>
            <Col className="RoomList__form" lg={6} md={12}>
              <Formsy
                onChange={this.handleOnChange}
                onInvalid={this.handleOnInvalid}
                onSubmit={this.handleSubmit}
                onValid={this.handleOnValid}
                ref={(node) => {
                  this.formsy = node;
                }}
              >
                <Row className="RoomList__form--body">
                  <Col lg={{ size: 10, offset: 1 }} md={12}>
                    <Flex direction="column" gutter={5}>
                      {this.getFormSections()}
                    </Flex>
                  </Col>
                </Row>
                <ButtonsWrapper>
                  <Flex gutter={2}>
                    <Button
                      basis="50%"
                      fullWidth={true}
                      name="back"
                      onClick={this.handleGoPreviousStep}
                      variant="secondary"
                    >
                      {translate("Back")}
                    </Button>
                    <Button
                      basis="50%"
                      disabled={!canSubmit}
                      fullWidth={true}
                      name="next"
                      type="submit"
                    >
                      {translate("Continue")}
                    </Button>
                  </Flex>
                </ButtonsWrapper>
              </Formsy>
            </Col>
            <DecorativeRightImage
              alt="Property decorative image"
              src="/assets/images/roomlist-property.svg"
            />
          </Row>
        </Container>
      </Spacer>
    );
  }
}

export default RoomListProperty;
