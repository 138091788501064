import React, { useEffect } from "react";

import { SEARCHROOMS_STATUSES as STATUS } from "datamodel/search-rooms/static";

import Cards from "./cards";
import CardsNotFound from "./cards-not-found";
import { LoadingLayer } from "./styled-components";

const ResultsList = ({
  cards,
  status,
  selectRoomById,
  handleEtParams = null,
  nextToken = null,
  previousToken = null,
  setToken,
  onlyList = false,
}) => {
  const isLoading = status === STATUS.fetching;

  const areRoomsLoaded = status === STATUS.loaded;
  const hasRooms = !!cards?.length;

  switch (true) {
    case status === STATUS.initial:
      return null;
    case areRoomsLoaded && !hasRooms:
      return <CardsNotFound />;
    case isLoading:
      return <LoadingLayer />;
    default:
      return (
        <Cards
          cards={cards}
          handleEtParams={handleEtParams}
          nextToken={nextToken}
          onlyList={onlyList}
          previousToken={previousToken}
          selectRoomById={selectRoomById}
          setToken={setToken}
        />
      );
  }
};

export default ResultsList;
