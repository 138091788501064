import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const Anchor = styled.a`
  display: inline-block;
  padding-left: ${unitizedPx(0.5)};
  color: inherit;
  text-decoration: underline;

  :hover {
    color: inherit;
  }
`;
