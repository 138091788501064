import { createSelector } from "reselect";

export const getInbox = (state) => state.Inbox;
const getConnection = (state) => state.InboxConnection.connection;

export const getConnectionId = createSelector(
  getConnection,
  (connection) => connection.id || null,
);

export const getInboxCategory = createSelector(
  getInbox,
  (inbox) => inbox.category,
);

export const getInboxConnectionsPerPage = createSelector(
  getInbox,
  (inbox) => inbox.perPage,
);

export const getConnectionRoomDetails = createSelector(
  getConnection,
  (connection) => connection.room,
);

export const getConnectionUser = createSelector(
  getConnection,
  (connection) => connection.user,
);

export const getConnectionsSelector = createSelector(
  getInbox,
  (inbox) => inbox.connections,
);

export const getConnectionStatusLevel = createSelector(
  getConnection,
  (connection) => connection.status?.statusLevel,
);

export const getPaginationSelector = createSelector(
  getInbox,
  (inbox) => inbox.pagination,
);

export const getCurrentPageSelector = createSelector(
  getInbox,
  (inbox) => inbox.pagination?.currentPage || 1,
);

export const getTotalPagesSelector = createSelector(
  getInbox,
  (inbox) => inbox.pagination?.totalPages || 1,
);

export const getRoomsSummary = createSelector(
  getInbox,
  (inbox) => inbox.roomsSummary.rooms,
);

export const getRoomsSummaryTotalPages = createSelector(
  getInbox,
  (inbox) => inbox.roomsSummary.pagination.totalPages,
);

export const getRoomsSummaryCurrentPage = createSelector(
  getInbox,
  (inbox) => inbox.roomsSummary.pagination.currentPage,
);

export const getInboxRoomId = createSelector(
  getInbox,
  (inbox) => inbox.room.id,
);

export const getInboxRoomTitle = createSelector(
  getInbox,
  (inbox) => inbox.room.title,
);
