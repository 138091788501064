import React, { forwardRef } from "react";
import { Text, BADI_COLORS } from "@badi/badi-components";

import { Root, Wrap, Item } from "./styled-components";

export const TabListItem = ({
  label,
  count = null,
  id,
  isActive = false,
  onClick,
  dataQa,
  extendStyles = null,
}) => {
  const labelToPrint = count === null ? label : `${label} (${count})`;
  const textColor = isActive
    ? BADI_COLORS.NEUTRAL.NEUTRAL_80
    : BADI_COLORS.NEUTRAL.NEUTRAL_50;
  return (
    <Item
      css={extendStyles}
      data-qa={`${label.toLowerCase()}-${dataQa}`}
      isActive={isActive}
      key={label}
      onClick={(event) => onClick(id, event)}
      role="menuitem"
    >
      <Text body="12-Bold" color={textColor} inline={true}>
        {labelToPrint}
      </Text>
    </Item>
  );
};

const TabList = forwardRef(
  (
    {
      items = [],
      activeItem = 0,
      onItemClick = () => {},
      disabled,
      extendChildStyles,
      dataQa,
    },
    ref,
  ) => {
    return (
      <Root disabled={disabled} ref={ref}>
        <Wrap>
          {items.map((item) => (
            <TabListItem
              key={item.id}
              {...item}
              dataQa={dataQa}
              extendStyles={extendChildStyles}
              isActive={activeItem === item.id}
              onClick={onItemClick}
            />
          ))}
        </Wrap>
      </Root>
    );
  },
);

TabList.displayName = "TabList";

export default TabList;
