const BADI_ANDROID_PROTOCOL = "badi://";
const BADI_IOS_PROTOCOL = "badiapp://";

const ANDROID_REFERRERS = [
  "android-app://com.badi.staging",
  "android-app://es.inmovens.badi",
];

export const isAndroidWebView = () =>
  ANDROID_REFERRERS.some((referrer) => document.referrer.includes(referrer));

export const routerEventListenerInitiator = (router, isIOSApp) => () => {
  const currentPath = router.location.pathname;
  const { host } = window.location;

  const unlisten = router.listen((location) => {
    const { pathname: nextPath } = location;

    if (nextPath !== currentPath) {
      unlisten();
      if (isAndroidWebView()) {
        window.location.href = BADI_ANDROID_PROTOCOL + host + nextPath;
      } else if (isIOSApp) {
        window.location.href = BADI_IOS_PROTOCOL + nextPath.replace(/^\//, "");
      }
    }
  });
  return unlisten;
};
