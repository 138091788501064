import React from "react";
import { Button } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

const ChatButton = ({ onOpenConversation, connectionId }) => {
  const handleClick = () => {
    onOpenConversation({ id: connectionId });
  };

  return (
    <Button dataQa="room-action-chat" onClick={handleClick} variant="secondary">
      {translate("room.detail.cta_reconnect")}
    </Button>
  );
};

export default ChatButton;
