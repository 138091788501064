import dayjs from "dayjs";

export const getYearsInRange = (min, max) => {
  const years = [];

  for (let year = max; year > min; year -= 1) {
    years.push({ label: year, value: year });
  }

  return years;
};

export const getMonths = () => {
  const language = Object.keys(dayjs.Ls).find((lang) => lang !== "en") || "en";
  return dayjs.Ls[language].months.map((month, i) => ({
    label: month,
    value: i,
  }));
};

export const getDays = ({ year, month }) => {
  const days = [];
  if (!year || !month) return days;

  const date = dayjs(new Date(year, month, 1));
  const maxDay = date.daysInMonth();

  for (let day = 1; day <= maxDay; day += 1) {
    days.push({ label: day, value: day });
  }

  return days;
};

export const formatDate = (year, month, day) => {
  const date = dayjs(new Date(year, month, day));
  return date.format("YYYY-MM-DD");
};

export const breakDownDate = (dateText) => {
  const date = new Date(dateText);

  if (isNaN(date)) {
    return {
      day: "",
      month: "",
      year: "",
    };
  }
  return {
    day: date.getDate().toString(),
    month: date.getMonth().toString(),
    year: date.getFullYear().toString(),
  };
};
