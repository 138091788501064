import { connect } from "react-redux";
import {
  getSearchSelectedRoomId,
  getSearchStatus,
  getAllCards,
  getNextTokenCards,
  getPreviousTokenCards,
} from "datamodel/search-rooms/selectors";
import Actions from "datamodel/search-rooms/actions";
import FilterActions from "datamodel/SearchFilters/actions";

import ResultsList from "./results-list";

const mapStateToProps = (state) => ({
  selectedRoomId: getSearchSelectedRoomId(state),
  status: getSearchStatus(state),
  cards: getAllCards(state),
  nextToken: getNextTokenCards(state),
  previousToken: getPreviousTokenCards(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectRoomById: (id) => dispatch(Actions.selectRoom(id)),
  setToken: (nextToken) => dispatch(FilterActions.setToken(nextToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsList);
