import React from "react";
import { Flex } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";
import { SOCIAL_SRC } from "./constants";
import { SocialIcon, Icon } from "./styled-components";

const SocialLinks = () => (
  <Flex gutter={2}>
    {SOCIAL_SRC.map((social) => (
      <SocialIcon
        href={social.url}
        key={`social-${social.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon alt={social.id} src={`${getCDNUrl()}${social.image}`} />
      </SocialIcon>
    ))}
  </Flex>
);

export default SocialLinks;
