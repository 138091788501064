import { connect } from "react-redux";
import {
  getPaymentEmail,
  getCreditCardLastDigits,
  getPaymentPhone,
} from "app/datamodel/Payment/selectors";
import {
  getBankAccountLastDigits,
  getContactVerification,
  getContactPhone,
  getContactEmail,
} from "app/datamodel/Payouts/selectors";
import { getToken } from "app/datamodel/Auth/selectors";
import { getPayment } from "datamodel/Payment/actions";
import { getAccount } from "datamodel/Payouts/actions";
import PaymentStep from "./payment-pending-booking-step";

const mapStateToProps = (state) => ({
  token: getToken(state),
  hasBankAccountNumber: !!getBankAccountLastDigits(state),
  hasContactInformation: getContactVerification(state),
  hasPaymentCard: !!getCreditCardLastDigits(state),
  hasVerifiedPhone: !!getPaymentPhone(state) || !!getContactPhone(state),
  hasVerifiedEmail: !!getPaymentEmail(state) || !!getContactEmail(state),
  email: getPaymentEmail(state) || !!getContactEmail(state),
  phoneNumber: getPaymentPhone(state) || !!getContactPhone(state),
});

const mapDispatchToProps = {
  getAccount,
  getPayment,
};

const mergeProps = (state, dispatch, ownProps) => ({
  ...state,
  ...ownProps,
  getBankAccount: () => dispatch.getAccount(),
  getPaymentDetails: () => dispatch.getPayment(state.token),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PaymentStep);
