import { getColor } from "base/shared/get-color";

class LabelDescriptionModel {
  constructor(labelDescription) {
    this.data = labelDescription;
  }

  get title() {
    return this.data.title;
  }

  get lines() {
    return this.data.lines;
  }

  get backgroundColor() {
    return this.data.background_color && getColor(this.data.background_color);
  }

  toJSON() {
    return {
      title: this.title,
      lines: this.lines,
      backgroundColor: this.backgroundColor,
    };
  }
}

class LabelModel {
  static IDS = {
    badi_homes: "badi_homes",
  };

  constructor(label) {
    this.data = label;
  }

  get color() {
    return this.data.color && getColor(this.data.color);
  }

  get backgroundColor() {
    return this.data.background_color && getColor(this.data.background_color);
  }

  get content() {
    return this.data.content;
  }

  get icon() {
    return this.data.icon;
  }

  get description() {
    return (
      this.data.description && new LabelDescriptionModel(this.data.description)
    );
  }

  get id() {
    return this.data.id;
  }

  toJSON() {
    return {
      id: this.id,
      color: this.color,
      backgroundColor: this.backgroundColor,
      content: this.content,
      icon: this.icon,
      description: this.description,
    };
  }
}

export default LabelModel;
