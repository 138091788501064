import styled from "styled-components";
import { unitizedPx, colors } from "@badi/badi-components";

export const AnnotationHeading = styled.div`
  position: relative;

  :after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: 1px solid #f1f1f1;
  }
`;

export const AnnotationHeadingText = styled.span`
  position: relative;
  z-index: 1;
  padding: 0 ${unitizedPx(3)};
  background-color: ${colors.white};
`;
