import styled from "styled-components";
import { unitizedPx } from "@badi/badi-components";

export const FormContent = styled.div`
  position: relative;
  z-index: 1;
`;

export const FormCompoundTitle = styled.span`
  padding-right: ${unitizedPx(0.5)};
`;

export const FormCompoundExtra = styled.span`
  font-size: ${unitizedPx(2)};
  font-weight: normal;
`;

export const FullWidthFlex = styled.div`
  width: 100%;
`;
