import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { getAPIUrl, objectToQueryParams } from "base/shared/Utils";
import {
  BookingRequest,
  BookingReference,
  BookingConditions,
  BookingMoveIn,
  BookingMoveOut,
} from "./models";
import { handleMutuallyExclusiveParams } from "./utils";

const ROOM_ID_PARAMETER = "room_id";
const CONNECTION_ID_PARAMETER = "connection_id";
const MOVE_IN_PARAMATER = "move_in";
const MOVE_OUT_PARAMETER = "move_out";
const LENGTH_IN_MONTHS = "length_in_months";
const SEEKER_ID_PARAMETER = "seeker_id";

export const getMoveInDate = ({ token, connectionId, roomId }) => {
  const { BADI_BOOKING_MOVE_IN_DATE } = envVars();

  const queryParameters = objectToQueryParams({
    [ROOM_ID_PARAMETER]: roomId,
    [CONNECTION_ID_PARAMETER]: connectionId,
  });

  const serviceUrl = getAPIUrl(
    `${BADI_BOOKING_MOVE_IN_DATE}?${queryParameters}`,
  );

  return fetch(serviceUrl, { token });
};

export const getMoveOutDate = ({ token, connectionId, moveIn, roomId }) => {
  const { BADI_BOOKING_MOVE_OUT_DATE } = envVars();

  const queryParameters = objectToQueryParams({
    [MOVE_IN_PARAMATER]: moveIn,
    [ROOM_ID_PARAMETER]: roomId,
    [CONNECTION_ID_PARAMETER]: connectionId,
  });

  const serviceUrl = getAPIUrl(
    `${BADI_BOOKING_MOVE_OUT_DATE}?${queryParameters}`,
  );

  return fetch(serviceUrl, { token });
};

export const getBookingPreview = ({
  token,
  connectionId,
  moveIn,
  moveOut,
  monthsLength,
  roomId,
  seekerId,
}) => {
  const { BADI_BOOKING_PREVIEW } = envVars();

  const { connectionId: connectionIdParsed, roomId: roomIdParsed } =
    handleMutuallyExclusiveParams({
      connectionId,
      roomId,
    });

  const queryParameters = objectToQueryParams({
    [MOVE_IN_PARAMATER]: moveIn,
    [MOVE_OUT_PARAMETER]: moveOut,
    [LENGTH_IN_MONTHS]: monthsLength,
    [ROOM_ID_PARAMETER]: roomIdParsed,
    [CONNECTION_ID_PARAMETER]: connectionIdParsed,
    [SEEKER_ID_PARAMETER]: seekerId,
  });

  const serviceUrl = getAPIUrl(`${BADI_BOOKING_PREVIEW}?${queryParameters}`);

  return fetch(serviceUrl, { token }).then((response) =>
    new BookingRequest(response.data).toJSON(),
  );
};

export const createBooking = ({
  discount,
  token,
  connectionId,
  moveIn,
  moveOut,
  monthsLength,
  paymentIntentId,
  useCheckout,
}) => {
  const { BADI_BOOKING_REQUEST } = envVars();
  const serviceUrl = getAPIUrl(BADI_BOOKING_REQUEST);
  const params = {
    connection_id: connectionId,
    discount: discount || undefined,
    move_in: moveIn,
    move_out: moveOut,
    length_in_months: monthsLength,
    payment_intent_id: paymentIntentId,
    use_checkout: !!useCheckout,
  };

  return fetch(serviceUrl, {
    method: "POST",
    token,
    params,
  }).then(({ data }) => {
    return data ? new BookingReference(data).toJSON() : null;
  });
};

export const createInstantBooking = ({
  discount,
  token,
  roomId,
  moveIn,
  moveOut,
  monthsLength,
  message,
  paymentIntentId,
  seekerId,
  useCheckout,
}) => {
  const { BADI_INSTANT_BOOKING } = envVars();
  const serviceUrl = getAPIUrl(BADI_INSTANT_BOOKING);
  const params = {
    move_in: moveIn,
    move_out: moveOut,
    length_in_months: monthsLength,
    message,
    room_id: roomId,
    discount: discount || undefined,
    payment_intent_id: paymentIntentId,
    seeker_id: seekerId,
    use_checkout: !!useCheckout,
  };

  return fetch(serviceUrl, {
    method: "POST",
    token,
    params,
  }).then(({ data }) => {
    return data ? new BookingReference(data).toJSON() : null;
  });
};

export const getBookingRequest = ({ token, requestId }) => {
  const { BADI_BOOKING_REQUEST } = envVars();

  const serviceUrl = getAPIUrl(`${BADI_BOOKING_REQUEST}/${requestId}`);

  return fetch(serviceUrl, {
    token,
  }).then((response) => new BookingRequest(response.data).toJSON());
};

export const declineBookingRequest = ({ token, requestId }) => {
  const { BADI_BOOKING_DECLINE } = envVars();

  const declineBookingUrl = BADI_BOOKING_DECLINE.replace(":id", requestId);
  const serviceUrl = getAPIUrl(declineBookingUrl);

  return fetch(serviceUrl, {
    method: "PUT",
    token,
  });
};

export const getBookingReference = ({ referenceId, token }) => {
  const { BADI_BOOKING_REFERENCE } = envVars();

  const serviceUrl = getAPIUrl(`${BADI_BOOKING_REFERENCE}/${referenceId}`);

  return fetch(serviceUrl, {
    token,
  }).then((response) => new BookingReference(response.data).toJSON());
};

const saveBookingRequest = ({ serviceUrl, method = "PUT", token, params }) =>
  fetch(serviceUrl, {
    method,
    token,
    params,
  })
    .then(({ context, data }) => {
      const response = { booking: data, requiresAction: false };
      if (context && context.requires_action) {
        response.requiresAction = context.requires_action;
        response.clientSecret = context.client_secret;
      }

      return response;
    })
    .catch((error) => {
      return { error };
    });

export const retryBookingRequestPayment = ({
  token,
  requestId,
  paymentIntentId,
}) => {
  const { BADI_BOOKING_RETRY } = envVars();
  const serviceUrl = getAPIUrl(BADI_BOOKING_RETRY.replace(":id", requestId));
  const params = {
    payment_intent_id: paymentIntentId,
  };

  return saveBookingRequest({ serviceUrl, token, method: "POST", params });
};

export const acceptBookingRequest = ({ token, requestId, paymentIntentId }) => {
  const { BADI_BOOKING_ACCEPT } = envVars();
  const serviceUrl = getAPIUrl(BADI_BOOKING_ACCEPT.replace(":id", requestId));
  const params = {
    payment_intent_id: paymentIntentId,
  };

  return saveBookingRequest({ serviceUrl, token, params });
};

export const completePaymentTransaction = async ({
  token,
  paymentIntentId,
  requestId,
  stripeVerification,
  action,
}) => {
  const params = { token, paymentIntentId, requestId };

  const acceptance = await action(params);
  const { booking, error, requiresAction, clientSecret } = acceptance;

  if (error) {
    return { error };
  }

  if (!requiresAction) {
    return { booking };
  }

  const authorization = await stripeVerification(clientSecret);

  return authorization.error
    ? authorization
    : completePaymentTransaction({
        ...params,
        action,
        stripeVerification,
        paymentIntentId: authorization.paymentIntentId,
      });
};

export const confirmBookingRequest = (params) =>
  completePaymentTransaction({
    ...params,
    action: acceptBookingRequest,
  });

export const retryBookingRequest = (params) =>
  completePaymentTransaction({
    ...params,
    action: retryBookingRequestPayment,
  });
