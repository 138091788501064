import React from "react";
import classNames from "classnames";
import { colors, Spacer, Text } from "@badi/badi-components";

import { ICON_TYPES } from "./utils";
import Icon from "./Icon";

import "./ContextualButtonExt.css";

const ContextualButton = ({
  className = "",
  disabled = false,
  icon = null,
  text,
  dataQa = null,
  onClick,
}) => {
  const color = disabled ? colors.softGrey : colors.darkGrey;

  return (
    <button
      className={classNames("ContextualButton", className)}
      data-qa={dataQa}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && (
        <Spacer right={1}>
          <Icon className="ContextualButton__Icon" color={color} type={icon} />
        </Spacer>
      )}
      <Text body={5} color={color} inline={true}>
        {text}
      </Text>
    </button>
  );
};

export default ContextualButton;
