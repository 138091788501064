import { USER_LEGAL_CONSENT } from "datamodel/User/constants";

const getConsent = (consentName) => {
  switch (consentName) {
    case USER_LEGAL_CONSENT.TERMS:
      return {
        id: consentName,
        label: "settings.privacy.termsandcond_terms_checkbox",
        required: true,
        link: "sitemap.tc.link",
        accepted: false,
      };
    case USER_LEGAL_CONSENT.MARKETING:
      return {
        id: consentName,
        label: "settings.privacy.termsandcond_promotional_checkbox",
        required: false,
        accepted: false,
      };
    case USER_LEGAL_CONSENT.PRIVACY:
      return {
        id: consentName,
        label: "settings.privacy.termsandcond_privacy_checkbox",
        required: true,
        link: "sitemap.privacy.link",
        accepted: false,
      };

    default:
      return null;
  }
};

export const getConsents = (consentListNames) =>
  consentListNames
    .map((consentName) => getConsent(consentName))
    .filter((consent) => !!consent);

export const toggleConsent = (consentList, consentId) =>
  consentList.map((consent) =>
    consent.id !== consentId
      ? consent
      : { ...consent, accepted: !consent.accepted },
  );

export const parseToAPIObject = (consentList) =>
  consentList.reduce(
    (parsedConsents, consent) => ({
      ...parsedConsents,
      [consent.id]: consent.accepted,
    }),
    {},
  );
