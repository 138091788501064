import React from "react";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import RoomPreview from "./room-preview";

export const closeRoomPreviewModal = () => {
  modal.remove(MODAL_ID.ROOM_PREVIEW);
};

const MODAL_OPTIONS = {
  id: MODAL_ID.ROOM_PREVIEW,
  type: MODAL_TYPE.LATERAL,
  onClose: closeRoomPreviewModal,
};

export const openRoomPreviewModal = ({
  isInvite,
  room,
  offset,
  token,
  user,
}) => {
  modal.add(
    <RoomPreview
      isInvite={isInvite}
      offset={offset}
      room={room}
      token={token}
      user={user}
    />,
    MODAL_OPTIONS,
  );
};
