import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import { Button, Flex, Heading, Spacer, Text } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import UserFeedback from "base/shared/user-feedback";
import { getToken } from "datamodel/Auth/selectors";
import { changePassword } from "datamodel/User/api";
import { useForm } from "components/hooks";
import Input from "components/form-text-input";
import LayoutWrapper from "components/Layout/Wrapper";
import SideBarMenu from "components/SideBarMenu";
import { Form } from "./styled-components";
import { getSettingsMenuItems } from "../utils";
import { PASSWORD_VALIDATIONS } from "./constants";

const ChangePassword = () => {
  const token = useSelector(getToken);
  const [values, setInputValue] = useForm({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [validations, setInputValidation] = useForm({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [valid, setValid] = useState(false);
  const [saving, setSaving] = useState(false);

  const menuItems = useMemo(
    () => translate(getSettingsMenuItems(false, "change-password")),
    [],
  );

  const handleChange = useCallback(({ name: inputName, value }) => {
    setInputValue(inputName, value);
  }, []);

  const handleValidation = useCallback(({ name: inputName, isValid }) => {
    setInputValidation(inputName, isValid);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = values;
    setSaving(true);

    changePassword(
      {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      },
      token,
    )
      .then(() => {
        UserFeedback.success(
          "Password Changed!",
          "Your password has been updated successfully.",
        );
        setSaving(false);
      })
      .catch((e) => {
        UserFeedback.exception("Something went wrong", e.message, e);
        setSaving(false);
      });
  };

  useEffect(() => {
    setValid(Object.values(validations).every((validation) => !!validation));
  }, [validations]);

  return (
    <LayoutWrapper>
      <Container>
        <Row>
          <Col lg={{ offset: 1, size: 10 }}>
            <Flex
              direction="column"
              justify="spaceBetween"
              lg={{ direction: "row" }}
            >
              <SideBarMenu
                autoScroll={false}
                className="info__item_link"
                itemType="link"
                items={menuItems}
                title="Settings"
              />
              <Spacer grow={1} left={0} lg={{ left: 29 }}>
                <Flex direction="column" gutter={3}>
                  <Spacer top={4}>
                    <Heading data-qa="change-password-title" level="20-Bold">
                      {translate("Change Password")}
                    </Heading>
                  </Spacer>
                  <Form onSubmit={handleSubmit}>
                    <Spacer bottom={1}>
                      <Text dataQa="change-password-desc">
                        {translate(
                          "Remember, the password must be at least 6 characters long, containing letters and numbers.",
                        )}
                      </Text>
                    </Spacer>
                    <Input
                      dataQa="current-password"
                      name="currentPassword"
                      newType={true}
                      onChange={handleChange}
                      onInputValidation={handleValidation}
                      placeholder={translate("Current Password")}
                      required={true}
                      type="password"
                      validations={PASSWORD_VALIDATIONS}
                      warning={translate(
                        "Remember, the password must be at least 6 characters long, containing letters and numbers.",
                      )}
                    />
                    <Input
                      dataQa="new-password"
                      name="newPassword"
                      newType={true}
                      onChange={handleChange}
                      onInputValidation={handleValidation}
                      placeholder={translate("New Password")}
                      required={true}
                      type="password"
                      validations={PASSWORD_VALIDATIONS}
                      warning={translate(
                        "Remember, the password must be at least 6 characters long, containing letters and numbers.",
                      )}
                    />
                    <Input
                      dataQa="confirm-password"
                      name="confirmPassword"
                      newType={true}
                      onChange={handleChange}
                      onInputValidation={handleValidation}
                      placeholder={translate("Confirm Password")}
                      required={true}
                      type="password"
                      validations={PASSWORD_VALIDATIONS}
                      warning={translate(
                        "Remember, the password must be at least 6 characters long, containing letters and numbers.",
                      )}
                    />
                    <Spacer top={1}>
                      <Button
                        dataQa="change-password-button"
                        disabled={!valid || saving}
                        type="submit"
                      >
                        {translate("Save and Update")}
                      </Button>
                    </Spacer>
                  </Form>
                </Flex>
              </Spacer>
            </Flex>
          </Col>
        </Row>
      </Container>
    </LayoutWrapper>
  );
};

export default ChangePassword;
