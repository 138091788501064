import { BADI_COLORS } from "@badi/badi-components";
import { VARIANT } from "./constants";

export const getVariantStyles = (variant) => {
  switch (variant) {
    case VARIANT.PREMIUM:
      return {
        descriptionColor: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        titleColor: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        background: BADI_COLORS.GRADIENT.GRADIENT_02,
      };
    case VARIANT.PLUS:
      return {
        descriptionColor: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        titleColor: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        background: BADI_COLORS.ADDITIONAL.ADDITIONAL_01,
      };
    case VARIANT.LIGHT:
      return {
        descriptionColor: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        titleColor: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
        background: BADI_COLORS.ADDITIONAL.ADDITIONAL_03,
      };
    case VARIANT.TERTIARY:
    default:
      return {
        descriptionColor: BADI_COLORS.NEUTRAL.NEUTRAL_00,
        titleColor: BADI_COLORS.ADDITIONAL.ADDITIONAL_04,
        background: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
      };
  }
};
