export const BOOKING_STATUS = {
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 3,
  EXPIRED: 4,
  NOT_POSSIBLE: 5,
  PAYMENT_REQUIRED: 6,
  PAYMENT_REQUIRED_CHECKOUT: 7,
};

export const DEFAULT_EXPIRATION_HOURS = 72;
