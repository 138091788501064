import React from "react";
import {
  Flex,
  Heading,
  Spacer,
  Text,
  Button,
  useMatchMedia,
  BREAKPOINTS_PX,
} from "@badi/badi-components";
import Image from "components/Image/Image";

import { translate } from "base/shared/Localization";
import SearchModal from "components/search-modal";

import {
  RoomsNotFoundWrapper,
  RoomsNotFoundDescription,
  RoomsNotFoundTitle,
} from "./styled-components";

const FavoritesEmpty = () => {
  const [mediumMq] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.md})`]);

  return (
    <RoomsNotFoundWrapper>
      <Flex
        alignItems="center"
        direction="column"
        fullHeight={true}
        justify="center"
      >
        <Spacer bottom={4}>
          <Image height={160} src="/assets/images/search-empty-state.svg" />
        </Spacer>
        <Heading as={RoomsNotFoundTitle} level={3}>
          {translate("room.favorites.empty.title")}
        </Heading>
        <Spacer left={2.5} right={2.5} top={2}>
          <RoomsNotFoundDescription>
            <Text>{translate("room.favorites.empty.description")}</Text>
          </RoomsNotFoundDescription>
        </Spacer>
      </Flex>
      {!mediumMq && (
        <Spacer left={2.5} right={2.5} top={12}>
          <SearchModal>
            <Button dataQa="favorites-search" fullWidth={true}>
              {translate("room.favorites.empty.cta")}
            </Button>
          </SearchModal>
        </Spacer>
      )}
    </RoomsNotFoundWrapper>
  );
};

export default FavoritesEmpty;
