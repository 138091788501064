import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import {
  getAPIUrl as getServerUrl,
  removeUndefinedAndNullFields,
} from "base/shared/Utils";

export const fetchTransactions = ({ token }, { page, per }) => {
  const { BADI_TRANSACTIONS } = envVars();
  const serverUrl = getServerUrl(BADI_TRANSACTIONS);
  const params = { page, per };
  if (params) removeUndefinedAndNullFields(params);

  return fetch(serverUrl, { token, params });
};
