export const CONNECTION_STATUS_CODE = {
  CLOSED: 0,
  ENQUIRY_PENDING: 1,
  ENQUIRY_ACCEPTED: 2,
  INVITE_PENDING: 3,
  INVITE_ACCEPTED: 4,
  BOOKING_REQUEST_PENDING: 5,
  BOOKING_REQUEST_CANCELLED: 6,
  BOOKING_REQUEST_EXPIRED: 7,
  BOOKING_REQUEST_DECLINED: 8,
  BOOKING_REQUEST_NOT_POSSIBLE: 9,
  BOOKED: 10,
  BOOKING_CANCELLED: 11,
  BOOKING_REQUEST_PAYMENT_FAILED: 12,
};

export const CONNECTION_STATUS_LEVEL = {
  INFO: "info",
  WARNING: "warning",
  FAILURE: "failure",
  SUCCESS: "success",
  PRIORITY: "priority",
};

export const CONNECTION_ACTIONS = {
  replyEnquiry: "reply_enquiry",
  replyInvite: "reply_invite",
  requestToBook: "request_to_book",
  inviteToBook: "invite_to_book",
  seeBookingRequest: "see_booking_request",
  replyBookingRequest: "reply_booking_request",
};

/*
  This just exists because we're using the connection primary action as the bar action which is not the case
  TODO: remove using the `CONNECTION_ACTIONS` in favor of `CONNECTION_STATUS_CODE`
*/

export const CONVERSATION_ACTION = {
  REPLY_ENQUIRY: "reply_enquiry",
  REPLY_INVITE: "reply_invite",
  REQUEST_TO_BOOK: "request_to_book",
  INVITE_TO_BOOK: "invite_to_book",
  SEE_BOOKING_REQUEST: "see_booking_request",
  SEE_BOOKING_REFERENCE: "see_booking_reference",
  REPLY_BOOKING_REQUEST: "reply_booking_request",
  SEE_EXPIRED_REQUEST: "see_expired_request",
  RETRY_PAYMENT: "retry_payment",
};

export const CONVERSATION_NEXT_STEP = {
  MESSAGE: "message",
  REPLY_ENQUIRY: "reply_enquiry",
  REPLY_INVITE: "reply_invite",
  REPLY_BOOKING_REQUEST: "reply_booking_request",
  RESEND_BOOKING_REQUEST: "resend_booking_request",
  RECOVER_BOOKING_REQUEST: "recovery",
  VISIT_PROPOSAL: "visit_proposal",
  VISITS_FEEDBACK_SEEKER: "seeker_visit_feedback",
  VISITS_FEEDBACK_LISTER: "lister_visit_feedback",
  VISIT_OVERVIEW: "visit_overview",
};

export const ANNOTATION_ACTION_TYPE_BUTTON = "button";
export const ANNOTATION_ACTION_TYPE_LINK = "link";
export const ANNOTATION_ACTION_TYPE_POPUP = "popup";
