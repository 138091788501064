import React, { useEffect, useState, useRef } from "react";
import Helmet from "react-helmet";
import slugify from "slugify";
import { Container, Row, Col } from "reactstrap";
import { MatchMedia, BREAKPOINTS_PX, Spacer } from "@badi/badi-components";
import { isSeeker } from "datamodel/User/utils";

import { getDomain } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import {
  parseEtParamsApi,
  getParamsEntriesFromUrl,
} from "base/shared/tracking-headers";

import { openCompleteProfileModal } from "components/complete-profile";

import { USER_STATUS } from "datamodel/User/constants";
import { openMessageEnquiryModal } from "components/message-enquiry";

import HeaderLang from "components/header-lang";
import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import NotFound from "Sections/not-found";
import Share from "Sections/Room/Detail/RoomDetailShare";

import RoomDetailHeader from "./RoomDetailHeader";
import RoomDetailSidebar from "./sidebar";
import RoomDetailContent from "./RoomDetailContent";

import { replaceUrlWithNoEtParams } from "./utils";
import { ACTIONS } from "./constants";

import "./stylesExt.css";
import { openFastTrackInformationModal } from "components/fast-track/open-fast-track-information-modal";
import { openEnquiryFeedbackModal } from "components/message-enquiry/";

const RoomDetail = ({
  clearRoom,
  location,
  room,
  token,
  userId,
  userRole,
  isInReview,
  labels,
  messageEnquiryFetched,
  error,
  similarRooms,
  showPriceDiscount,
  matchProfileFlag,
  isFavoriteToggleButtonEnabled,
  userMode,
  isEntireProperty,
  getRoom,
  getOwner,
  params,
  getSimilarRooms,
  initialFlow,
  router,
  userStatus,
  setRegister,
  openProfile,
  fetched,
  isUserWithFastTrack,
  fastTrackRoomConnectionsCount,
  standardEnquiriesCount,
  isSwipingSeekerExperienceEnabled,
  sendMessageEnquiry,
}) => {
  const [etParams, setEtParams] = useState([]);
  const mounted = useRef(false);

  const openAuthModal = (modalCallback, action = "", initial = false) => {
    switch (userStatus) {
      case USER_STATUS.UNREGISTERED:
        setRegister();
        break;
      case USER_STATUS.PROFILE_COMPLETE:
        modalCallback();
        break;
      case USER_STATUS.PROFILE_INCOMPLETE:
        if (initial === true) {
          return;
        }
        openCompleteProfileModal({
          onSuccess: () => openProfile(action),
        });

        break;
      default:
    }
  };

  const handleEnquiryClick = (initial) => {
    if (isSwipingSeekerExperienceEnabled && !isUserWithFastTrack) {
      openAuthModal(
        () => {
          sendMessageEnquiry({
            roomId: room.id,
            message: translate(
              "room.detail.header.seeker.chat-default-message",
            ),
            isFastTrack: isUserWithFastTrack,
          });
          openEnquiryFeedbackModal({
            onFastTrackFindOutMoreClick: openFastTrackInformationModalWithAuth,
            standardEnquiriesCount,
          });
        },
        ACTIONS.ENQUIRY_MESSAGE,
        initial,
      );
    } else {
      openAuthModal(
        () =>
          openMessageEnquiryModal({
            owner: room.owner,
            title: room.title,
            displayAddress: room.displayAddress,
            picture: room.picture,
            id: room.id,
            city: room.city,
            onFastTrackFindOutMoreClick: openFastTrackInformationModalWithAuth,
            isUserWithFastTrack,
          }),
        ACTIONS.ENQUIRY_MESSAGE,
        initial,
      );
    }
  };

  const openFastTrackInformationModalWithAuth = (initial) => {
    openAuthModal(
      () =>
        openFastTrackInformationModal({
          owner: room.owner,
          title: room.title,
          displayAddress: room.displayAddress,
          picture: room.picture,
          id: room.id,
          city: room.city,
        }),
      ACTIONS.FAST_TRACK_INFORMATION,
      initial,
    );
  };

  const shouldShowEnquiry = () =>
    false && // * Keeping this logic for future implementations
    !messageEnquiryFetched &&
    !room.inboxConnectionId &&
    room.allowToContact &&
    !!userId;

  const autoActions = () => {
    if (room.owned) {
      return;
    }

    if (initialFlow === ACTIONS.ENQUIRY_MESSAGE) {
      handleEnquiryClick(true);
    }
  };

  useEffect(() => {
    const { id } = room;
    const { roomId } = params;
    const isLoggedUser = !!userId;

    if (!error) {
      setEtParams(getParamsEntriesFromUrl());

      if (!id) {
        getRoom({
          roomId,
          isLoggedUser,
          etParams: parseEtParamsApi(etParams),
        });
      }

      getSimilarRooms({ roomId, isLoggedUser });

      replaceUrlWithNoEtParams(router);
    }

    return () => clearRoom();
  }, []);

  useEffect(() => {
    if (mounted.current) {
      getRoom({ roomId: params.roomId, isLoggedUser: !!userId });
    }
  }, [userId]);

  useEffect(() => {
    if (!fetched) {
      return;
    }

    if (!room.owner && userId) {
      getOwner(room.user.id);
    }

    autoActions();
  }, [userStatus, fetched]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  if (error) return <NotFound location={location} />;
  if (!room.id) return null;

  const { canonicalUrl, city, description, id, picture, shortTitle } = room;
  const roomTitle = `${shortTitle} - ${translate("Rent")} ${city} - Badi`;
  const pathname = location?.pathname;
  const url = getDomain().concat(pathname);

  const owner = userId ? room.owner : null;
  const user = !userId ? room.user : null;
  const isUserLoggedIn = !!userId;
  const areButtonsVisible =
    isFavoriteToggleButtonEnabled &&
    isUserLoggedIn &&
    isSeeker(userMode) &&
    !room.owned;

  return (
    <LayoutWrapper footer={<Footer campaign="home_footer" />}>
      <div className="roomdetail_wrapper">
        <Container>
          <Row>
            <Col>
              <RoomDetailHeader
                areButtonsVisible={areButtonsVisible}
                entireProperty={isEntireProperty}
                error={error}
                messageEnquiryFetched={messageEnquiryFetched}
                onEnquiryClick={handleEnquiryClick}
                openFastTrackInformationModal={
                  openFastTrackInformationModalWithAuth
                }
                room={room}
                showPriceDiscount={showPriceDiscount}
                token={token}
                userId={userId}
                userRole={userRole}
                fastTrackRoomConnectionsCount={fastTrackRoomConnectionsCount}
                standardEnquiriesCount={standardEnquiriesCount}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ size: 8 }}>
              <RoomDetailContent
                {...room}
                entireProperty={isEntireProperty}
                etParams={etParams}
                matchProfile={matchProfileFlag && room.matchProfile}
                similarRoomsList={similarRooms}
                token={token}
                userId={userId}
                labels={labels}
              />
              <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.lg})`}>
                {!isInReview && (
                  <Spacer bottom={4} left={2} right={2} top={4}>
                    <Share city={city} owned={room.owned} url={url} />
                  </Spacer>
                )}
              </MatchMedia>
            </Col>
            <Col lg={{ size: 4 }}>
              <RoomDetailSidebar
                owned={room.owned}
                owner={owner}
                roomId={room.id}
                sendEnquiry={handleEnquiryClick}
                showEnquiry={shouldShowEnquiry()}
                token={token}
                user={user}
              />
              <MatchMedia query={`(max-width: ${BREAKPOINTS_PX.lg})`}>
                {!isInReview && (
                  <Spacer bottom={4} top={4}>
                    <Share city={city} owned={room.owned} url={url} />
                  </Spacer>
                )}
              </MatchMedia>
            </Col>
          </Row>
        </Container>
      </div>

      <Helmet
        link={[{ rel: "canonical", href: canonicalUrl }]}
        meta={[
          { name: "robots", content: "noindex" },
          { name: "description", content: description },
          { property: "og:type", content: "website" },
          { property: "twitter:card", content: "summary" },
          { property: "og:title", content: roomTitle },
          { property: "twitter:title", content: roomTitle },
          { property: "og:description", content: description },
          { property: "twitter:description", content: description },
          { property: "og:url", content: url },
          { property: "og:image", content: picture },
          { property: "twitter:image", content: picture },
        ]}
        title={roomTitle}
      />
      <HeaderLang
        canonicalUrl={pathname}
        relativePath={`/room/${slugify(shortTitle)}-{Rent}-${city}/${id}`}
        translations={[
          {
            name: "Rent",
            es: "Alquiler",
            en: "Rent",
            it: "Affitto",
            ca: "Lloguer",
            fr: "Louer",
          },
        ]}
      />
    </LayoutWrapper>
  );
};

RoomDetail.defaultProps = {
  error: null,
  initialFlow: ACTIONS.NONE,
  userStatus: USER_STATUS.UNREGISTERED,
};

export default RoomDetail;
