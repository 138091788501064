import { connect } from "react-redux";

import RoomListActions from "datamodel/RoomList/actions";
import {
  isEntiredPropertySelector,
  getRoomModerationResultSelector,
  getRoomFetchedSelector,
} from "datamodel/RoomList/selectors";

import { isListingFlowWithBankAccountSelector } from "datamodel/Features/selectors";
import { getToken } from "datamodel/Auth/selectors";

import RoomForm from "./room-form";

const mapStateToProps = (state) => ({
  isEntireProperty: isEntiredPropertySelector(state),
  isListingFlowWithBankAccountEnabled:
    isListingFlowWithBankAccountSelector(state),
  token: getToken(state),
  moderationResult: getRoomModerationResultSelector(state),
  isRoomFetched: getRoomFetchedSelector(state),
});

const mapDispatchToProps = {
  resetRoomList: RoomListActions.resetRoomList,
  getModerationResult: RoomListActions.getModerationResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomForm);
