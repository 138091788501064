import React from "react";

const SelectItem = ({ title, name, value, disabled, description, dataQa }) => (
  <option
    data-qa={`${dataQa}-${value}`}
    disabled={disabled}
    key={`${name}-${value}`}
    value={value.toString()}
  >
    {description ? `${title}: ${description}` : title}
  </option>
);

SelectItem.defaultProps = {
  disabled: false,
};

export default SelectItem;
