import React, { memo, useCallback } from "react";
import { Spacer, Flex, Text, BADI_COLORS } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import Image from "components/Image/Image";
import { openBookingPaymentInfoModal } from "components/booking-payment-info-modal";
import { isLister } from "datamodel/User/utils";

import { showSummaryExtraInfo, showFeeDiscountInfo } from "../utils";
import {
  BlockWrapper,
  BookingSummaryHighlight,
  InfoButton,
} from "../styled-components";

const BookingRequestValueSection = ({
  price,
  room,
  userRole,
  lengthInMonths = 0,
  exactMonthsLength = false,
  bookingValue = "",
}) => {
  const showExtraInfo = showSummaryExtraInfo(lengthInMonths, price.fee);
  const showFeeDiscountRow = showFeeDiscountInfo(price);
  const infoClickHandler = useCallback(() => {
    openBookingPaymentInfoModal({ userRole });
  }, [userRole]);
  const space = showExtraInfo ? 1 : 4;

  return (
    <Flex direction="column">
      {showExtraInfo && [
        <Spacer
          bottom={1}
          data-qa="booking-summary-title"
          key="booking-summary-title"
        >
          <Flex justify="flexStart">
            <Text body="14-Bold">{translate("booking.summary.title")}</Text>
          </Flex>
        </Spacer>,

        <Spacer
          bottom={1}
          data-qa="booking-summary-monthly-payment"
          key="booking-summary-monthly-payment"
        >
          <Flex justify="spaceBetween">
            <Text body="14-Regular">
              {translate(`booking.summary.monthly_payment`)}
            </Text>
            <Text body="14-Regular">{room.monthlyPrice}</Text>
          </Flex>
        </Spacer>,

        <Spacer
          bottom={1}
          data-qa="booking-summary-length"
          key="booking-summary-length"
        >
          <Flex justify="spaceBetween">
            <Text body="14-Regular">{translate(`booking.summary.length`)}</Text>
            <Text body="14-Regular">
              {translate("num_months", {
                smart_count: exactMonthsLength
                  ? lengthInMonths
                  : `${lengthInMonths}+`,
              })}
            </Text>
          </Flex>
        </Spacer>,
      ]}
      {!!room.deposit && (
        <BlockWrapper>
          <Spacer bottom={space} top={space}>
            <Flex dataQa="booking-summary-deposit" justify="spaceBetween">
              <Text body="14-Bold">{translate("booking.summary.deposit")}</Text>
              <Text body="14-Regular">{room.deposit}</Text>
            </Flex>
          </Spacer>
        </BlockWrapper>
      )}
      {showExtraInfo && (
        <BlockWrapper>
          <Spacer bottom={space} data-qa="booking-summary-value" top={space}>
            <Flex justify="spaceBetween">
              <Text body="14-Bold">
                {translate(
                  `booking.summary.${
                    isLister(userRole)
                      ? "lister.booking_value"
                      : "seeker.total_amount"
                  }`,
                )}
              </Text>
              <Text body="14-Regular">{bookingValue}</Text>
            </Flex>
          </Spacer>
        </BlockWrapper>
      )}
      {price.method && (
        <BlockWrapper>
          <Spacer bottom={4} top={4}>
            <Flex justify="spaceBetween">
              <Text body={2}>
                {translate(`booking.preview.${userRole}.payment_method_label`)}
              </Text>
              <Text>{price.method}</Text>
            </Flex>
          </Spacer>
        </BlockWrapper>
      )}
      <BlockWrapper>
        <Spacer bottom={space} top={space}>
          <Spacer bottom={1}>
            <Text body="14-Bold">
              {translate(`booking.summary.${userRole}_payment`)}
            </Text>
          </Spacer>
          <BookingSummaryHighlight
            backgroundColor={BADI_COLORS.SEMANTIC.INFO.LIGHTEST}
            withShadow={false}
          >
            <Flex direction="column" gutter={1}>
              <Flex
                dataQa="booking-summary-monthly-price"
                justify="spaceBetween"
              >
                <Text body="14-Regular">
                  {translate(`booking.summary.first_payment`)}
                </Text>
                <Text body="14-Regular">{room.monthlyPrice}</Text>
              </Flex>
              <Flex justify="spaceBetween">
                <Text body="14-Regular">
                  {translate("booking.preview.fee_payment_label")}
                </Text>
                {showFeeDiscountRow ? (
                  <Flex alignItems="center" data-qa="booking-summary-discount">
                    <Text
                      body="14-Regular"
                      color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
                      dataQa="booking-summary-original-fee"
                    >
                      <strike>{price.fee}</strike>
                    </Text>
                    <Spacer dataQa="booking-summary-discounted-fee" left={1}>
                      <Text body="14-Regular">{price.netFee}</Text>
                    </Spacer>
                  </Flex>
                ) : (
                  <Text body="14-Regular" dataQa="booking-summary-single-fee">
                    {price.fee}
                  </Text>
                )}
              </Flex>
              <Spacer top={2}>
                <Flex
                  dataQa="booking-summary-total-price"
                  justify="spaceBetween"
                >
                  <Text body="14-Bold">
                    {translate(
                      `booking.summary.${
                        isLister(userRole) ? "lister_receive" : "seeker_pay"
                      }`,
                    )}
                  </Text>
                  <Text body="14-Bold">{price.total}</Text>
                </Flex>
              </Spacer>
            </Flex>
          </BookingSummaryHighlight>
          <Spacer top={2}>
            <InfoButton onClick={infoClickHandler}>
              <Flex
                dataQa="booking-summary-payment-info"
                gutter={0.3}
                justify="flexStart"
              >
                <Text body="14-Regular">
                  {translate(`booking.summary.payment_info`)}
                </Text>
                <Image alt="info" src="/assets/icons/info_black.svg" />
              </Flex>
            </InfoButton>
          </Spacer>
        </Spacer>
      </BlockWrapper>
    </Flex>
  );
};

export default memo(BookingRequestValueSection);
