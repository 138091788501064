import React from "react";
import { Button, Flex, Text } from "@badi/badi-components";
import { getFormattedDate } from "app/components/booking-preview/utils";
import { translate } from "base/shared/Localization";

const ContactInformationFilled = ({
  name,
  surname,
  birthdate,
  address,
  city,
  postalcode,
  onUpdateClick,
}) => {
  return (
    <Flex direction="column" gutter={1}>
      <Text
        body={1}
        dataQa="contact-information-name-field"
      >{`${name} ${surname}`}</Text>
      <Text body={1} dataQa="contact-information-birthdate">
        {getFormattedDate(birthdate)}
      </Text>
      <Text
        body={1}
        dataQa="contact-information-address-field"
      >{`${address}, ${city}. ${postalcode}`}</Text>
      <Flex justify="flexEnd">
        <Button
          dataQa="contact-information-update-button"
          onClick={onUpdateClick}
        >
          {translate("payout.bank_account.update")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ContactInformationFilled;
