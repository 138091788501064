import React, { useEffect } from "react";
import { Spacer, Card, Text, colors, Icon, Flex } from "@badi/badi-components";
import { getOtherUserRole, isLister } from "datamodel/User/utils";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";

import { LISTER_USER_ROLE, SEEKER_USER_ROLE } from "datamodel/User/constants";

import {
  MessageCaret,
  MessageRoot,
} from "Sections/booking/user-message-step/styled-components";
import UserProfileBadge from "components/user-profile-badge";
import RoomDetailBadge from "components/room-detail-badge";
import BookingRequestSummary from "components/booking-request-summary";
import { openBookingModifyModal } from "components/booking-modify-modal";
import { getFormattedDate } from "./utils";

import { BlockWrapper } from "./styled-components";

const BookingPreview = ({
  userRole,
  token,
  moveIn,
  paymentPending = false,
  children = null,
  moveOut = "",
  user = null,
  room,
  price,
  lengthInMonths,
  exactMonthsLength,
  bookingValue,
  message = "",
  isFullInfoBr = true,
}) => {
  const handleModifyClick = () => {
    openBookingModifyModal();
  };

  return (
    <Spacer top={5}>
      {user && (
        <BlockWrapper data-qa="booking-preview-user">
          <Spacer bottom={4}>
            <UserProfileBadge
              {...user}
              token={token}
              userRole={getOtherUserRole(userRole)}
            />
          </Spacer>
        </BlockWrapper>
      )}
      {message && (
        <BlockWrapper data-qa="booking-preview-message">
          <MessageRoot>
            <MessageCaret />
            <Card backgroundColor={colors.lightGrey} withShadow={false}>
              <Spacer bottom={2} left={2} right={2} top={2}>
                <Text>{message}</Text>
              </Spacer>
            </Card>
          </MessageRoot>
        </BlockWrapper>
      )}
      {!isFullInfoBr && isLister(userRole) && (
        <Spacer top={2}>
          <Flex alignItems="center">
            <Icon
              alt="info"
              iconMaxHeight={14}
              iconMaxWidth={14}
              src={getPublicAsset("icons/blue-info-icon.svg")}
            />
            <Spacer left={1}>
              <Text body="12-Regular">
                {translate("boooking_received.message_warning")}
              </Text>
            </Spacer>
          </Flex>
        </Spacer>
      )}

      {room && (
        <BlockWrapper data-qa="booking-preview-room">
          <Spacer bottom={4} top={4}>
            <RoomDetailBadge {...room} />
          </Spacer>
        </BlockWrapper>
      )}
      <BookingRequestSummary
        bookingValue={bookingValue}
        exactMonthsLength={exactMonthsLength}
        handleModifyClick={paymentPending ? handleModifyClick : null}
        isFullInfoBr={isFullInfoBr}
        lengthInMonths={lengthInMonths}
        moveIn={getFormattedDate(moveIn)}
        moveOut={getFormattedDate(moveOut)}
        price={price}
        room={room}
        userRole={userRole}
      />
      {children}
    </Spacer>
  );
};

export default BookingPreview;
