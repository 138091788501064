import React, { useEffect, useCallback, useMemo } from "react";
import { Flex } from "@badi/badi-components";
import Select from "components/form-select";
import { useForm } from "components/hooks";
import { formatDate, getDays, getMonths, getYearsInRange } from "./utils";

const INPUT_YEAR = "year";
const INPUT_MONTH = "month";
const INPUT_DAY = "day";

const BirthDate = ({
  disabled,
  minYear,
  maxYear,
  name,
  onChange,
  onValidation,
  required,
  year: initialYear,
  month: initialMonth,
  day: initialDay,
  yearPlaceholder,
  monthPlaceholder,
  dayPlaceholder,
}) => {
  const [values, setInputValue] = useForm({
    [INPUT_YEAR]: initialYear,
    [INPUT_MONTH]: initialMonth,
    [INPUT_DAY]: initialDay,
  });

  const handleChange = useCallback(
    ({ name: selectName, value }) => {
      const inputName = selectName.replace(`${name}-`, "");

      setInputValue(inputName, value);
    },
    [name],
  );

  useEffect(() => {
    // * Checking if there is an empty value
    if (Object.values(values).some((value) => !value)) {
      onValidation({
        isValid: false,
        name,
        value: "",
      });
      return;
    }
    const value = formatDate(values.year, values.month, values.day);

    if (onChange) {
      onChange({
        name,
        value,
      });
    }
    if (onValidation) {
      onValidation({
        isValid: true,
        name,
        value,
      });
    }
  }, [values]);

  const getAvailableYears = useCallback(
    () => getYearsInRange(minYear, maxYear),
    [minYear, maxYear],
  );

  const months = useMemo(() => getMonths(), []);

  const getAvailableDays = useCallback(() => getDays(values), [values]);

  return (
    <Flex direction="row" gutter={2}>
      <Select
        disabled={disabled}
        grow={1}
        initialValue={initialYear}
        name={`${name}-${INPUT_YEAR}`}
        onChange={handleChange}
        options={getAvailableYears()}
        placeholder={yearPlaceholder}
        required={required}
      />
      <Select
        disabled={disabled || !values.year}
        grow={2}
        initialValue={initialMonth}
        name={`${name}-${INPUT_MONTH}`}
        onChange={handleChange}
        options={months}
        placeholder={monthPlaceholder}
        required={required}
      />
      <Select
        disabled={disabled || !values.month}
        grow={1}
        initialValue={initialDay}
        name={`${name}-${INPUT_DAY}`}
        onChange={handleChange}
        options={getAvailableDays()}
        placeholder={dayPlaceholder}
        required={required}
      />
    </Flex>
  );
};

BirthDate.defaultProps = {
  day: "",
  dayPlaceholder: "",
  disabled: false,
  maxYear: new Date().getFullYear() - 18,
  minYear: new Date().getFullYear() - 100,
  month: "",
  monthPlaceholder: "",
  onChange: null,
  onValidation: null,
  placeholder: "",
  required: false,
  year: "",
  yearPlaceholder: "",
};

export default BirthDate;
