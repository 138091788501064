import React, { useState } from "react";

import { Heading, Flex, Text } from "@badi/badi-components";

import { VISIT_FEEDBACK_STATUS } from "datamodel/visits/constants";
import { translate } from "base/shared/Localization";

import { isSeeker } from "datamodel/User/utils";
import BlockSeparator from "components/block-separator";
import UserProfileBadge from "components/user-profile-badge";
import RoomDetailsBadge from "components/room-detail-badge";
import Choice from "./feedback-choice";

const VisitFeedbackForm = ({
  roomDetails,
  screenRole,
  userDetails,
  date,
  onSubmit,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (changedValue) => {
    setValue(changedValue);
    onSubmit(changedValue);
  };

  return (
    <Flex direction="column" fullHeight={true} gutter={2}>
      <Heading data-qa="visit-feedback-title">
        {translate(`inbox_popup_visit.feedback.${screenRole}.title`)}
      </Heading>

      <BlockSeparator>
        {isSeeker(screenRole) ? (
          <RoomDetailsBadge
            address={roomDetails.address}
            id={roomDetails.id}
            picture={roomDetails.picture}
            title={roomDetails.title}
          />
        ) : (
          <UserProfileBadge
            age={userDetails.age}
            avatar={userDetails.picture}
            id={userDetails.id}
            name={userDetails.name}
            occupation={userDetails.occupation}
          />
        )}
      </BlockSeparator>
      <BlockSeparator grow={1}>
        <Flex direction="column">
          <Text body="14-Bold" dataQa="visit-details-date-label">
            {translate("inbox_popup_visit.proposal.date_label")}
          </Text>
          <Text body="14-Regular" dataQa="visit-details-date">
            {date}
          </Text>
        </Flex>
      </BlockSeparator>
      <Choice
        checked={value === VISIT_FEEDBACK_STATUS.INTERESTED}
        id="yes"
        name="visit-feedback"
        onChange={handleChange}
        value={VISIT_FEEDBACK_STATUS.INTERESTED}
      >
        {translate(`inbox_popup_visit.feedback.${screenRole}.positive`)}
      </Choice>
      <Choice
        checked={value === VISIT_FEEDBACK_STATUS.UNINTERESTED}
        id="no"
        name="visit-feedback"
        onChange={handleChange}
        value={VISIT_FEEDBACK_STATUS.UNINTERESTED}
      >
        {translate(`inbox_popup_visit.feedback.${screenRole}.negative`)}
      </Choice>
    </Flex>
  );
};

export default VisitFeedbackForm;
