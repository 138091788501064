import React, { Fragment } from "react";
import { Text, Flex, Spacer } from "@badi/badi-components";
import { BADI_COLORS } from "app/badi-components";

import { translate } from "base/shared/Localization";
import Calendar from "components/Icons/Calendar";
import UserProfileBadge from "components/user-profile-badge";
import RoomDetailBadge from "components/room-detail-badge";

import { BlockWrapper, LocationIcon } from "./styled-components";

const RoomPreview = ({
  isInvite = false,
  clickable = true,
  room,
  offset = 4,
  token,
  user = null,
  showAvailability = true,
  showRoomDetails = true,
  status,
}) => {
  const billsIncluded = room.billsIncluded
    ? "bills.included"
    : "bills.not_included";

  return (
    <Fragment>
      {user && (
        <BlockWrapper>
          <Spacer bottom={offset}>
            <UserProfileBadge
              {...user}
              data-qa="user-profile-badge"
              token={token}
              userRole={user.role}
              status={status}
            />
          </Spacer>
        </BlockWrapper>
      )}
      <BlockWrapper>
        <Spacer bottom={offset} top={offset}>
          <RoomDetailBadge
            {...room}
            clickable={clickable}
            data-qa="room-detail-badge"
          />
          {isInvite && (
            <Spacer data-qa="room-invite-explanation" top={1}>
              <Flex alignItems="center" gutter={1}>
                <LocationIcon />
                <Text body={5} color={BADI_COLORS.SEMANTIC.INFO.DEFAULT}>
                  {translate("conversation.room_invite_explanation")}
                </Text>
              </Flex>
            </Spacer>
          )}
        </Spacer>
      </BlockWrapper>
      {room.externalAgencyId && (
        <BlockWrapper>
          <Spacer bottom={offset} top={offset}>
            <Text
              body="14-Regular"
              data-qa="conversation-agency-listing-id"
            >{`${translate("conversation.room.externalAgencyId")}: ${
              room.externalAgencyId
            }`}</Text>
          </Spacer>
        </BlockWrapper>
      )}
      {showAvailability && (
        <BlockWrapper>
          <Spacer bottom={offset} top={offset}>
            <Flex alignItems="center" justify="spaceBetween">
              <div>
                <Text body={3}>{translate("inbox.info.available_from")}</Text>
                <Text body={2} data-qa="room-available-from">
                  {room.availableFrom}
                </Text>
              </div>
              <Calendar color={BADI_COLORS.NEUTRAL.NEUTRAL_50} />
              <div>
                <Text body={3}>{translate("inbox.info.min_stay")}</Text>
                <Text body={2}>
                  {translate("num_months", { smart_count: room.minStay || 1 })}
                </Text>
              </div>
            </Flex>
          </Spacer>
        </BlockWrapper>
      )}
      {showRoomDetails && (
        <BlockWrapper>
          <Spacer bottom={offset} top={offset}>
            <Flex justify="spaceBetween">
              <div>
                <Text body={3}>{translate("inbox.info.monthly_price")}</Text>
                <Text
                  body={6}
                  color={BADI_COLORS.NEUTRAL.NEUTRAL_50}
                  data-qa="room-bills-included-text"
                >
                  {translate(billsIncluded)}
                </Text>
              </div>
              <Text body={2} data-qa="room-price" style={{ margin: 0 }}>
                {room.price || room.monthlyPrice}
              </Text>
            </Flex>
          </Spacer>
        </BlockWrapper>
      )}
    </Fragment>
  );
};

export default RoomPreview;
