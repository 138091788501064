import { getRelativePath } from "base/shared/Utils";
import { locale } from "base/shared/Localization";
import { stringify } from "qs";

export const SEO_LANDING_PATHS = {
  ABOUT_BADI: {
    ES: "/sobre-badi",
    EN: "/about-badi",
  },
};

export const getHomePath = ({ source = "" } = {}) => {
  const path = getRelativePath("/");

  return source ? `${path}?source=${encodeURI(source)}` : path;
};

export const getRegisterPath = () => {
  return getRelativePath("/register");
};

const BADI_PLUS_URL = {
  en: "https://plus.badi.com",
  es: "https://plus.badi.com/es",
};

export const getBadiPlusPath = (path = "/", { query } = {}) => {
  const baseUrl = BADI_PLUS_URL[locale() || "en"];
  const queryString = query ? `?${stringify(query)}` : "";
  if (path.startsWith("/")) {
    return `${baseUrl}${path}${queryString}`;
  }
  return `${baseUrl}/${path}${queryString}`;
};

export const getAboutBadiPath = () =>
  getRelativePath(
    locale() === "es"
      ? SEO_LANDING_PATHS.ABOUT_BADI.ES
      : SEO_LANDING_PATHS.ABOUT_BADI.EN,
  );
