import FavoriteRoomModel from "datamodel/favorites/favorite-model";

class CollectionRoomModel extends FavoriteRoomModel {
  constructor(data) {
    super();
    this.entity = data || {};
  }

  get pictures() {
    if (!this.entity.cover_picture) {
      return [];
    }
    return Object.entries(this.entity.cover_picture).reduce(
      (result, [key, val]) => {
        return [...result, { [key]: val }];
      },
      [],
    );
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      verified: this.verified,
      matchProfile: this.matchProfile,
      coordinates: this.coordinates,
      lister: this.lister,
      pictures: this.pictures,
      url: this.url,
      previousPrice: this.previousPrice,
      price: this.price,
    };
  }
}

export default CollectionRoomModel;
