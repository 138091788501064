import React from "react";
import { Flex, Heading, BADI_COLORS } from "@badi/badi-components";
import UserFeedback from "base/shared/user-feedback";
import LoadingSpinner from "components/Icons/LoadingSpinner";
import ReviewItem from "components/review-item/review-item";
import { getReviewsForUser, getReviewsForRoom } from "datamodel/reviews/api";
import { getReviewsSummaryHeaderText } from "./utils";
import { REVIEW_MODAL_TYPE } from "./constants";

const ReviewsSummary = ({ reviewsSummary }) => {
  const heading = `${reviewsSummary.total} ${getReviewsSummaryHeaderText(
    reviewsSummary.total,
  )}`;

  return (
    <Flex direction="column" gutter={1}>
      <Heading data-qa="reviews-summary-header" level="28-Bold">
        {heading}
      </Heading>
      {reviewsSummary.reviews.map(({ text, collectedAt, reviewer }) => (
        <ReviewItem
          collectedAt={collectedAt}
          key={reviewer.id}
          reviewer={reviewer}
          text={text}
          withDivider={true}
        />
      ))}
    </Flex>
  );
};

export default ReviewsSummary;
