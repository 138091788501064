import React from "react";
import { withRouter } from "react-router";
import { getNewRoomPath } from "Sections/Room/routes";
import { getRentRoomPath } from "Sections/seo-pages/routes";
import { getMyProfilePath } from "Sections/user-profile/routes";
import DesktopBar from "./desktop-bar-mapping";

const DesktopBarRoute = ({ router, ...props }) => {
  const handleListYourProperty = (isLister) => {
    router.push(isLister ? getNewRoomPath() : getRentRoomPath());
  };

  const openMyProfile = () => {
    router.push(getMyProfilePath());
  };

  return (
    <DesktopBar
      {...props}
      onListYourProperty={handleListYourProperty}
      openMyProfile={openMyProfile}
    />
  );
};

export default withRouter(DesktopBarRoute);
