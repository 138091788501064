import React from "react";
import { getButtonStyles } from "../button/utils";
import { BUTTON_TYPES } from "../button";
import { BaseLink } from "./styled-components";

const LinkButton = ({
  children,
  fullWidth = false,
  href,
  target = "_self",
  variant = BUTTON_TYPES.PRIMARY,
  onClick = null,
  as: asComponent = "a",
  ...rest
}) => {
  const styles = getButtonStyles(variant);

  return (
    <BaseLink
      {...styles}
      as={asComponent}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      target={target}
      {...rest}
    >
      {children}
    </BaseLink>
  );
};

export default LinkButton;
