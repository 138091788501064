export const ROOM_STATUS = {
  PUBLISHED: 1,
  UNPUBLISHED: 2,
  BANNED: 3,
  RENTED: 4,
  BOOKED: 5,
  DRAFT: 6,
  PENDING: 7,
};

export const ROOM_SIZE_UNITS = {
  1: "m²",
  2: "ft²",
};

export const DEFAULT_UNIT_ID = 1;

export const ROOM_MIN_DAYS_STAY = "33";

export const CLEANING_OPTIONS = [
  {
    key: "biweekly",
    value: "cleaning_services_twice_a_week",
  },
  {
    key: "weekly",
    value: "cleaning_services_once_a_week",
  },
  {
    key: "bimonthly",
    value: "cleaning_services_biweekly",
  },
  {
    key: "monthly",
    value: "cleaning_services_monthly",
  },
];

export const BENEFITS_OPTIONS = {
  rentalContract: "rental_contract",
  registrationAvailable: "registration_available",
  maintenance: "maintenance",
  cleaningBiweekly: "cleaning_services_twice_a_week",
  cleaningWeekly: "cleaning_services_once_a_week",
  cleaningBimonthly: "cleaning_services_biweekly",
  cleaningMonthly: "cleaning_services_monthly",
};
