import { connect } from "react-redux";

import Actions from "datamodel/SearchFilters/actions";
import SearchRoomsActions from "datamodel/search-rooms/actions";
import RequirementsActions from "datamodel/Requirements/actions";
import { HEADER_PARAMS } from "base/shared/tracking-headers/static";

const { SEARCH_SOURCES } = HEADER_PARAMS;

import {
  getSearchMetadata,
  getRoomCardsIds,
  isLoginRequired,
} from "datamodel/search-rooms/selectors";
import { getUserId } from "datamodel/User/selectors";

import {
  getSearchFiltersSelector,
  getSearchFiltersPreviousToken,
} from "datamodel/SearchFilters/selectors";

import Search from "./search";

const mapStateToProps = (state) => ({
  searchfilters: getSearchFiltersSelector(state),
  metadata: getSearchMetadata(state),
  roomCardsIds: getRoomCardsIds(state),
  userId: getUserId(state),
  isLoginRequired: isLoginRequired(state),
  searchfiltersPreviousToken: getSearchFiltersPreviousToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  searchRooms: (params) => {
    dispatch(SearchRoomsActions.getRooms(params));
  },
  setLocation: (location) => {
    dispatch(Actions.setLocation(location));
  },
  handleRequireLogin: () => dispatch(RequirementsActions.setRegister(true)),
  resetLoginRequired: () => dispatch(SearchRoomsActions.resetLoginRequired()),
  setToken: (token) => dispatch(Actions.setToken(token)),
  setBoundsRecalculation: () => dispatch(Actions.setBoundsRecalculation(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
