import { connect } from "react-redux";
import { getIsAbleToPushNotifications } from "datamodel/PushNotifications/selectors";
import { completeProfileBannerSelector } from "datamodel/Features/selectors";
import { getGhost } from "datamodel/User/selectors";

import NotificationWrapper from "./info-bar-wrapper";

export default connect(
  (state) => ({
    isAbleToPushNotifications: getIsAbleToPushNotifications(state),
    showCompleteProfileBar:
      getGhost(state) && completeProfileBannerSelector(state),
  }),
  null,
)(NotificationWrapper);
