import styled from "styled-components";
import { getPublicAsset } from "base/shared/Utils";

export const SliderWrapper = styled.div`
  && {
    .swiper-container {
      width: 100%;
      height: 100%;
      /*this gives the dimension of slider container*/
      /*these values will make the container as big as the parent div*/
      overflow: hidden;
      /*important parameter to not disrupt the container when slides increases in coverflow and other effect*/
      z-index: 0;
    }

    .swiper-container:hover div.swiper-button-prev,
    .swiper-container:hover div.swiper-button-next {
      display: initial;
      text-align: center;
      display: table;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    .swiper-container:hover div.swiper-button-prev {
      background: url(${getPublicAsset("icons/slider-arrow-prev.svg")});
    }

    .swiper-container:hover div.swiper-button-next {
      background: url(${getPublicAsset("icons/slider-arrow-next.svg")});
    }

    .swiper-slide {
      width: 100%;
      height: 220px;
      /*this one holds the dimension of each slides and can be changed according to the user wish*/
    }

    span.swiper-pagination-bullet {
      background-color: #f0f0f0;
    }

    .swiper-pagination-bullet:only-child {
      visibility: hidden;
    }

    div.swiper-button-prev,
    div.swiper-button-next {
      color: #999999;
      display: none;
      border-radius: 50%;
      background-color: white;
      height: 32px;
      width: 32px;
    }

    div.swiper-button-prev:after,
    div.swiper-button-next:after {
      display: none;
    }

    .swiper-button-disabled {
      pointer-events: auto;
      opacity: 0.4 !important;
    }
  }
`;
