import styled, { css } from "styled-components";
import { unitizedPx, colors, mediaquery } from "@badi/badi-components";

export const TopBarWrapper = styled.div`
  width: 100%;
  height: ${unitizedPx(10)};
  padding: ${unitizedPx(3.5)};
`;

export const SearchDateWrapper = styled.div`
  .DateInput_input {
    width: ${unitizedPx(42)};
    cursor: pointer;

    > div {
      font-size: ${unitizedPx(1.75)};
      font-weight: bold;
    }
  }
`;

export const FooterBar = styled.div`
  height: ${unitizedPx(10.5)};
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${colors.softGrey};
  box-shadow: 0px 0px ${unitizedPx(1)} 0px rgba(0, 0, 0, 0.24);
  background-color: ${colors.white};
  z-index: 2;

  ${mediaquery.md`
    height: ${unitizedPx(9)};
   `}
`;

export const DisabledWrapper = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
