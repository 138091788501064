import React from "react";

import Inbox from "./inbox-mapping";

const InboxRoute = ({ location, router }) => {
  const { query = {} } = location;
  const { page = 1, category, room, fromFilter } = query;
  return (
    <Inbox
      category={category}
      currentPage={Number(page)}
      resetFilters={!fromFilter}
      room={Number(room)}
      router={router}
    />
  );
};

export default InboxRoute;
