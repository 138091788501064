import React from "react";

import { modal, MODAL_TYPE } from "containers/ReduxModal/emitter";
import ConnectionDetails from "./connection-details-route";

const CONNECTION_DETAILS_MODAL_ID = "connection-details";

export function closeConnectionDetailsModal() {
  modal.remove(CONNECTION_DETAILS_MODAL_ID);
}

export function openConnectionDetailsModal({
  allowedActions,
  connectionId,
  room,
  user,
  token,
  isInvite,
}) {
  modal.add(
    <ConnectionDetails
      allowedActions={allowedActions}
      connectionId={connectionId}
      isInvite={isInvite}
      room={room}
      token={token}
      user={user}
    />,
    {
      id: CONNECTION_DETAILS_MODAL_ID,
      type: MODAL_TYPE.LATERAL,
    },
  );
}
