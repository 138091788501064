export const removeItemById = (array, id) => {
  const index = array.findIndex(({ id: itemId }) => itemId === id);

  if (index < 0) {
    return array;
  }

  const newArray = [...array];
  newArray.splice(index, 1);

  return newArray;
};
