import { typeBuilder } from "base/shared/TypeHelper";

export default typeBuilder([
  "REFERRALS_PENDING_REQUEST",
  "REFERRALS_PENDING_SUCCESS",
  "REFERRALS_PENDING_ERROR",
  "REFERRALS_ACCOUNT_REQUEST",
  "REFERRALS_ACCOUNT_SUCCESS",
  "REFERRALS_ACCOUNT_ERROR",
  "REFERRAL_PRODUCTS_REQUEST",
  "REFERRAL_PRODUCTS_SUCCESS",
  "REFERRAL_PRODUCTS_ERROR",
  "RESET_REFERRALS",
]);
