import { BUTTON_TYPES } from "@badi/badi-components";

export const getButtonConfig = ({
  handleBadiGoldClick,
  payToMatchRequired,
  onAccept,
}) => {
  const config = {
    dataQa: "enquiry-footer-button-accept",
    onClick: onAccept,
    variant: BUTTON_TYPES.PRIMARY,
  };

  if (payToMatchRequired) {
    return {
      dataQa: "enquiry-footer-button-plus",
      text: "lister.contact.request.cta_upgrade",
      onClick: handleBadiGoldClick,
      variant: BUTTON_TYPES.PREMIUM,
    };
  }

  return { ...config, text: "lister.contact.request.cta_accept" };
};
