import { connect } from "react-redux";
import { FilterButton } from "@badi/badi-components";
import Actions from "datamodel/SearchFilters/actions";

import { parseFilter, parseFilterToKebabCase } from "../utils";

const mapStateToProps = (state, props) => ({
  ...parseFilter(state.SearchFilters[props.field]),
});

const mapDispatchToProps = (dispatch, props) => ({
  onCancelFilter: () => {
    props.trackResetFilter(parseFilterToKebabCase(props.resetAction));
    dispatch(Actions[props.resetAction]());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
