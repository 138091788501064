import { generateFetchTypes } from "base/shared/TypeHelper";
import types from "./types";
import { getPendingReferrals, getReferralAccount, getProducts } from "./api";

export const getPendingReferralsAction = (paginationToken, sgid) => ({
  types: generateFetchTypes(types.REFERRALS_PENDING_REQUEST),
  request: getPendingReferrals,
  params: { paginationToken, sgid },
  requireAuth: true,
});

export const getReferralAccountAction = (sgid) => ({
  types: generateFetchTypes(types.REFERRALS_ACCOUNT_REQUEST),
  request: getReferralAccount,
  params: { sgid },
  requireAuth: true,
});

export const getReferralProductsAction = (sgid) => ({
  types: generateFetchTypes(types.REFERRAL_PRODUCTS_REQUEST),
  request: getProducts,
  params: { sgid },
  requireAuth: true,
});

export const resetReferralsAction = () => ({
  type: types.RESET_REFERRALS,
});
