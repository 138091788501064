import React, { Fragment, useEffect, useState, useCallback } from "react";

import { Col, Container, Row } from "reactstrap";

import { translate } from "base/shared/Localization";
import LayoutWrapper from "components/Layout/Wrapper";
import PolicyCard from "components/policy-card";
import BookingRequestSummary from "components/booking-request-summary";

import { getBookingReference } from "datamodel/booking/api";
import { getOtherUserRole } from "datamodel/User/utils";

import { Spacer, Heading, Text } from "@badi/badi-components";

import { BlockWrapper } from "./styled-components";
import TopBar from "../top-bar";

const BookingReference = ({ params, router, authtoken }) => {
  const [bookingInfo, setBookingInfo] = useState(null);
  const [otherUser, setOtherUser] = useState(null);

  useEffect(() => {
    getBookingReference({
      token: authtoken,
      referenceId: params.referenceId,
    }).then((response) => {
      const otherUserRole = getOtherUserRole(response.role);

      setOtherUser(response[otherUserRole]);
      setBookingInfo(response);
    });
  }, [params.referenceId]);

  const onClose = useCallback(() => {
    // we suppose that they come from inbox or a conversation
    router.goBack();
  }, []);

  return (
    <LayoutWrapper topBar={<TopBar onClose={onClose} />}>
      <Spacer bottom={8} top={8}>
        <Container>
          <Row>
            <Col lg={{ size: 7 }} sm={12}>
              <Spacer bottom={1}>
                <Heading>{translate("booking.reference.heading")}</Heading>
              </Spacer>
              <Spacer bottom={4}>
                {bookingInfo && (
                  <Text>
                    {translate(
                      `booking.reference.${bookingInfo.role}.sub-heading`,
                    )}
                  </Text>
                )}
              </Spacer>
              <BlockWrapper>
                <Spacer bottom={4} top={4}>
                  <Spacer bottom={0.5}>
                    <Text body={2}>
                      {translate("booking.reference.transaction_id_label")}
                    </Text>
                  </Spacer>
                  <Text>{params.referenceId}</Text>
                </Spacer>
              </BlockWrapper>
              {otherUser && (
                <Fragment>
                  <BlockWrapper>
                    <Spacer bottom={4} top={4}>
                      <Spacer bottom={0.5}>
                        <Text body={2}>
                          {translate("booking.reference.mobile_phone_label")}
                        </Text>
                      </Spacer>
                      <Text>{otherUser.phone}</Text>
                    </Spacer>
                  </BlockWrapper>
                  <BlockWrapper>
                    <Spacer bottom={4} top={4}>
                      <Spacer bottom={0.5}>
                        <Text body={2}>
                          {translate("booking.reference.email_label")}
                        </Text>
                      </Spacer>
                      <Text>{otherUser.email}</Text>
                    </Spacer>
                  </BlockWrapper>
                </Fragment>
              )}
              {bookingInfo && (
                <Fragment>
                  <BlockWrapper>
                    <Spacer bottom={4} top={4}>
                      <Spacer bottom={0.5}>
                        <Text body={2}>
                          {translate("booking.reference.address_label")}
                        </Text>
                      </Spacer>
                      <Text>{bookingInfo.room.fullAddress}</Text>
                    </Spacer>
                  </BlockWrapper>
                  <BookingRequestSummary
                    bookingValue={bookingInfo.bookingValue}
                    exactMonthsLength={bookingInfo.exactMonthsLength}
                    lengthInMonths={bookingInfo.lengthInMonths}
                    moveIn={bookingInfo.moveIn}
                    moveOut={bookingInfo.moveOut}
                    price={bookingInfo.price}
                    room={bookingInfo.room}
                    userRole={bookingInfo.role}
                  />
                </Fragment>
              )}
              <Spacer bottom={4} />
            </Col>
            <Col lg={{ size: 4, offset: 1 }} sm={12}>
              <PolicyCard />
            </Col>
          </Row>
        </Container>
      </Spacer>
    </LayoutWrapper>
  );
};

export default BookingReference;
