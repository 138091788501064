let sdkLoaded = false;
let facebookAppId = null;

const isSdkLoaded = () => sdkLoaded;

const setFbAsyncInit = ({ appId, cookie, xfbml, version }, callback) => {
  facebookAppId = appId;

  window.fbAsyncInit = () => {
    window.FB.init({
      appId,
      cookie,
      xfbml,
      version,
    });
    sdkLoaded = true;
    callback();
  };
};

const loadSdkAsynchronously = (language = "en_US") => {
  const element = document.getElementsByTagName("script")[0];
  const fjs = element;
  let js = element;

  if (document.getElementById("facebook-jssdk")) return;

  js = document.createElement("script");
  js.id = "facebook-jssdk";
  js.src = `//connect.facebook.net/${language.replace("-", "_")}/all.js`;
  fjs.parentNode.insertBefore(js, fjs);
};

const loadFacebookSdk = (params, callback) => {
  const { language } = params || {};

  if (document.getElementById("facebook-jssdk")) {
    sdkLoaded = true;
    callback();
    return;
  }

  let fbRoot = document.getElementById("fb-root");
  if (!fbRoot) {
    fbRoot = document.createElement("div");
    fbRoot.id = "fb-root";
    document.body.appendChild(fbRoot);
  }

  setFbAsyncInit(params, callback);
  loadSdkAsynchronously(language);
};

const openLogin = (scope, callback) => {
  window.FB.login(callback, { scope });
};

const sendFacebookMessage = (link) => {
  window.FB.ui({ app_id: facebookAppId, method: "send", link }, () => {});
};

const sendMessengerMessage = (link) => {
  window.open(
    `fb-messenger://share?link=${encodeURIComponent(
      link,
    )}&app_id=${encodeURIComponent(facebookAppId)}`,
  );
};

export {
  isSdkLoaded,
  loadFacebookSdk,
  openLogin,
  sendFacebookMessage,
  sendMessengerMessage,
};
