export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export const BREAKPOINTS_PX = Object.entries(BREAKPOINTS).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: `${value}px`,
  }),
  {},
);
