import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";
import { getUserListerScore } from "datamodel/Overview/selectors";
import ListerScoreCard from "./lister-score-card";

const mapStateToProps = (state) => ({
  token: getToken(state),
  listerScore: getUserListerScore(state),
});

export default connect(mapStateToProps, null)(ListerScoreCard);
