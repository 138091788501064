import * as firebaseApp from "firebase/app";
import "firebase/messaging";

const RECEIVER_STATES_ALLOWED = ["foreground", "any"];

class FirebaseMessagingController {
  constructor(publicVapidKey, firebaseConfig) {
    this.publicVapidKey = publicVapidKey;
    this.firebaseConfig = firebaseConfig;
    this.messaging = null;
  }

  install = async () => {
    try {
      this.instanciateMessaging();
      const token = await this.getToken();
      return token;
    } catch (error) {
      return error;
    }
  };

  instanciateMessaging = () => {
    if (this.messaging) return;
    const firebase = firebaseApp.initializeApp(this.firebaseConfig);
    this.messaging = firebase.messaging();
    this.messaging.usePublicVapidKey(this.publicVapidKey);
    this.registerOnMessage();
  };

  registerOnMessage = () => {
    this.messaging.onMessage(({ data }) => {
      if (!RECEIVER_STATES_ALLOWED.includes(data.receiver_state)) return;
      const notification = new Notification(data.title, {
        body: data.body,
        icon: "/assets/icons/badi_favicon.svg",
      });
      if (!data.action_link) return;
      notification.onclick = (event) => {
        event.preventDefault();
        window.open(data.action_link);
      };
    });
  };

  getToken = async (retry = true) => {
    let token;
    try {
      token = await this.messaging.getToken();
    } catch (error) {
      // * this is because of a specific case, an internal error of firebase when user remove manually the SW and refresh quickly the page
      if (retry) return this.getToken(false);
    }
    return token;
  };
}

export default FirebaseMessagingController;
