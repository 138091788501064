import React from "react";
import {
  Button,
  MatchMedia,
  BREAKPOINTS_PX,
  Flex,
} from "@badi/badi-components";

import { translate } from "base/shared/Localization";

const Footer = ({ disabled = true, onSubmit }) => (
  <MatchMedia query={`(min-width: ${BREAKPOINTS_PX.md})`}>
    {(matches) => (
      <Flex alignItems="center" justify="flexEnd">
        <Button
          basis={matches ? "auto" : "100%"}
          dataQa="main-profile-submit-button"
          disabled={disabled}
          fullWidth={!matches}
          onClick={onSubmit}
        >
          {translate("onboarding.profile.seeker_CTA")}
        </Button>
      </Flex>
    )}
  </MatchMedia>
);

export default Footer;
