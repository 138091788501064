import React from "react";
import classNames from "classnames";
import Rheostat from "rheostat";
import { debounce } from "radash";

import { translate } from "base/shared/Localization";
import { formatValue } from "./utils";

import "./SliderExt.css";

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { value: props.value };
    this.debouncedSetValue = debounce({ delay: 300 }, () => this.setValue());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  onChange = (value) => {
    this.setState({ value: value.values }, () => this.debouncedSetValue());
  };

  setValue = () => {
    this.props.onChange(this.state.value);
  };

  render() {
    const { className, legend, max, min } = this.props;
    const { value } = this.state;
    const [lowerBound, upperBound] = value;

    return (
      <div className={classNames("Form__Slider", className)}>
        {legend && (
          <div className="Form__Slider--legend_top">
            <span>
              {formatValue(lowerBound, max)} - {formatValue(upperBound, max)}
            </span>
            {translate("room.list.step5.age.slider-label")}
          </div>
        )}
        <Rheostat
          min={min}
          max={max}
          onValuesUpdated={this.onChange}
          values={value}
        />
        <div className="Form__Slider--legend_bottom">
          <span>{min}</span>
          <span>{max}+</span>
        </div>
      </div>
    );
  }
}

Slider.defaultProps = {
  className: "",
  legend: true,
};
export default Slider;
