import React, { useState } from "react";
import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";
import InboxLister from "./inbox-lister";
import { getIsUserInListerMode } from "app/datamodel/User/selectors";

const mapStateToProps = (state) => ({
  token: getToken(state),
  isUserInListerMode: getIsUserInListerMode(state),
});

const InboxListerMapping = (props) => {
  const [key, setKey] = useState(0);
  const reload = () => setKey(key + 1);
  return <InboxLister {...{ ...props, reload, key }} />;
};

export default connect(mapStateToProps)(InboxListerMapping);
