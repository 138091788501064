import React from "react";
import { Spacer, Heading, Text } from "@badi/badi-components";

import { translate } from "base/shared/Localization";

const Verified = ({ phoneNumber }) => {
  return (
    <>
      <Spacer bottom={3}>
        <Heading level={3}>
          {translate("verifications.phone.verified.title")}
        </Heading>
      </Spacer>
      {phoneNumber && (
        <>
          <Text body={2}>
            {translate("verifications.phone.verified.label")}
          </Text>
          <Spacer top={1}>
            <Text>{phoneNumber}</Text>
          </Spacer>
        </>
      )}
    </>
  );
};

Verified.defaultProps = {
  phoneNumber: "",
};

export default Verified;
