import styled, { css } from "styled-components";
import { mediaquery, unitizedPx } from "@badi/badi-components";

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${unitizedPx(2)};
  right: ${unitizedPx(2)};
  z-index: 1;
  cursor: pointer;

  svg {
    display: block;
  }
`;

export const Modal = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.backgroundColor};
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  transform: scale(0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(1);
      opacity: 1;
    `}

  ${({ isClosed }) =>
    isClosed &&
    css`
      transform: scale(0);
      opacity: 0;
    `}

  ${mediaquery.md`  
    width: auto;
    max-width: 570px;
    height: auto;
    border-radius: 4px;
    max-height: calc(100vh - ${unitizedPx(5)});
  `}
`;
