export const COMPLETE_SUCCESS_LISTER = {
  buttonLabel: "profile.created.success.lister_button",
  description: "profile.created.success.lister_desc",
  title: "profile.created.success.lister_title",
};

export const CREATED_SUCCESS_LISTER = {
  buttonLabel: "profile.complete.success.lister_button",
  description: "profile.complete.success.lister_desc",
  title: "profile.complete.success.lister_title",
};

export const CREATED_SUCCESS_SEEKER = {
  buttonLabel: "profile.created.success.seeker_button",
  description: "profile.created.success.seeker_desc",
  title: "profile.created.success.seeker_title",
};

export const COMPLETE_SUCCESS_SEEKER = {
  buttonLabel: "profile.complete.success.seeker_button",
  description: "profile.complete.success.seeker_desc",
  title: "profile.complete.success.seeker_title",
};

export const DATA_CONTEXT = "profile_setup";
