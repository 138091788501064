import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useNotifications } from "./hooks/notifications-hook";
import { NotificationPosition } from "./models";
import Notification from "./components/Notification";
import { Root } from "./styled-components";

const NotificationsManager = ({
  maxNotifications = 15,
  portalId,
  position = NotificationPosition.BOTTOM_LEFT,
  NotificationComponent = Notification,
  zIndex = 1,
}) => {
  const notificationList = useNotifications(maxNotifications);
  const [portalElement, setPortalElement] = useState(null);

  useEffect(() => {
    let portalDOMElement = document.getElementById(portalId);

    if (!portalDOMElement) {
      portalDOMElement = document.createElement("div");
      portalDOMElement.id = portalId;
      portalDOMElement.setAttribute("data-testid", portalId);
      document.body.appendChild(portalDOMElement);
    }

    setPortalElement(portalDOMElement);
  }, [portalId]);

  return portalElement
    ? ReactDOM.createPortal(
        <Root
          position={position}
          data-testid="notifications-wrapper"
          zIndex={zIndex}
        >
          {notificationList.map((notification) => (
            <NotificationComponent key={notification.uid} {...notification} />
          ))}
        </Root>,
        portalElement,
      )
    : null;
};

export default NotificationsManager;
