import React from "react";
import { BADI_COLORS, Badge } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { VISIT_STATUS } from "datamodel/visits/constants";

const getConfig = (status) => {
  switch (status) {
    case VISIT_STATUS.ACCEPTED:
      return {
        color: BADI_COLORS.SEMANTIC.SUCCESS.DEFAULT,
        text: "visits.confirmed.status",
        dataQa: "visit-status-badge-confirmed",
      };
    case VISIT_STATUS.REJECTED:
    case VISIT_STATUS.CANCELLED:
      return {
        color: BADI_COLORS.SEMANTIC.DANGER.DEFAULT,
        text: "visits.cancelled.status",
        dataQa: "visit-status-badge-cancelled",
      };
    case VISIT_STATUS.REQUESTED:
    case VISIT_STATUS.PROPOSED:
      return {
        color: BADI_COLORS.SEMANTIC.WARNING.DEFAULT,
        text: "visits.status.proposed",
        dataQa: "visit-status-badge-proposed",
      };
    case VISIT_STATUS.FINISHED:
    default:
      return null;
  }
};

const VisitStatusBadge = ({ status }) => {
  const config = getConfig(status);

  if (!config) return null;

  const { color, text, dataQa } = config;
  return (
    <Badge backgroundColor={color} data-qa={dataQa} rounded={true}>
      {translate(text)}
    </Badge>
  );
};

export default VisitStatusBadge;
