import React from "react";
import { withRouter } from "react-router";
import { getRecommendationsPath } from "Sections/recommendations/routes";
import RecommendedTenants from "./recommended-tenants";

const RecommendedTenantsRoute = ({ router, roomId, ...props }) => {
  const handleOpenRecommendations = () => {
    router.push(getRecommendationsPath({ roomId }));
  };

  return (
    <RecommendedTenants
      openRecommendations={handleOpenRecommendations}
      roomId={roomId}
      {...props}
    />
  );
};

export default withRouter(RecommendedTenantsRoute);
