import React, { useCallback } from "react";
import { BlockingClicksWrapper, Root } from "./styled-components";

const PremiumBlocker = ({ children, roomId, feature = "", onClick }) => {
  const clickHandler = useCallback((event) => {
    event.preventDefault();
    onClick({ roomId, feature });
  }, []);

  return (
    <Root
      data-qa="premium-block-clickable"
      onClick={clickHandler}
      role="button"
    >
      <BlockingClicksWrapper data-qa="premium-block-wrapper">
        {children}
      </BlockingClicksWrapper>
    </Root>
  );
};

export default PremiumBlocker;
