import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { getHomePath } from "Sections/Home/routes";
import { getRelativePath } from "base/shared/Utils";
import {
  AUTHENTICATION_FORM_MODE,
  openAuthenticationModal,
  closeAuthenticationModal,
} from "Sections/auth/forms/authentication-form";
import { getRegisterPath } from "./routes";

import Home from "./home";

const HomeRoute = ({
  isLoggedIn,
  location = {},
  loginHandler,
  router,
  ...props
}) => {
  const { pathname } = location;

  const openLogin = () => {
    if (isLoggedIn) {
      router.push(getHomePath());
    } else {
      loginHandler();
    }
  };

  const handleRegisterLeave = () => {
    closeAuthenticationModal();
    router.push(getRelativePath("/"));
  };

  const isRegisterPath = pathname === getRegisterPath();

  if (isRegisterPath && isLoggedIn) {
    router.push(getRelativePath("/"));
  }
  useEffect(() => {
    if (isRegisterPath && !isLoggedIn) {
      openAuthenticationModal({
        onSuccess: handleRegisterLeave,
        onClose: handleRegisterLeave,
        mode: AUTHENTICATION_FORM_MODE.REGISTER,
      });
    }
  }, [isRegisterPath, isLoggedIn]);

  return <Home {...props} openLogin={openLogin} />;
};

export default withRouter(HomeRoute);
