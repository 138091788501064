import { getOverviewPath } from "Sections/overview/routes";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { PLANS_CATEGORIES, PLAN_FEATURES } from "datamodel/plans/constants";
import { PLUS_FEATURES_PATHS } from "./constants";
import { LAYOUT_TYPES } from "./components/layout";

export const getPathFromFeature = ({ feature, roomId }) => {
  if (!feature) {
    return getOverviewPath();
  }
  const getPath = PLUS_FEATURES_PATHS.get(feature);
  return getPath ? getPath({ roomId }) : getOverviewPath();
};

export const getPlansContentByCategory = (
  categories,
  feature = "",
  user = null,
) => {
  const category =
    Array.isArray(categories) && categories.length === 1 ? categories[0] : null;

  switch (category) {
    case PLANS_CATEGORIES.UPSELL_BADI_PLUS:
      return {
        image: getPublicAsset("images/big_rocket_illustration.svg"),
        title: translate("boost_upselling.main.title"),
        layoutType: LAYOUT_TYPES.BOOSTING,
      };
    case PLANS_CATEGORIES.STANDALONE_BOOSTING:
      return {
        image: getPublicAsset("images/big_rocket_illustration.svg"),
        title: translate("boost_standalone.main.title"),
        layoutType: LAYOUT_TYPES.BOOSTING,
      };
    case PLANS_CATEGORIES.MATCHING:
      if (feature === PLAN_FEATURES.ACCEPT_MATCH) {
        return {
          description: user
            ? translate("new.plus.opt.in.inbox_subtitle", { name: user.name })
            : null,
          avatar: user ? user.avatar : null,
          title: translate("new.plus.opt.in.inbox_title"),
          layoutType: LAYOUT_TYPES.PLUS,
        };
      } else {
        return {
          description: translate("plans.screen_subtitle"),
          image: getPublicAsset("images/badi-gold/badi-gold-logo.svg"),
          title: translate("plans.screen_title"),
          layoutType: LAYOUT_TYPES.PLUS,
        };
      }
    default:
  }

  return {
    description: translate("plans.screen_subtitle"),
    image: getPublicAsset("icons/gold-large.svg"),
    title: translate("plans.screen_title"),
    layoutType: LAYOUT_TYPES.PLUS,
  };
};
