import React, { useMemo } from "react";
import dayjs from "dayjs";

import Select from "components/form-select";
import { locale } from "base/shared/Localization";
import { COOKIES } from "base/shared/StaticData";
import { setCookie } from "base/shared/CookiesHandler";
import { LANGUAGE_OPTIONS, getLocalizedPath } from "./utils";
import { Root } from "./styled-components";

const LanguageSelector = () => {
  const currentLocale = useMemo(() => locale(), []);

  const handleLocaleChange = ({ value }) => {
    const path = window.location.pathname;

    if (currentLocale === value) return;

    const localizedPath = getLocalizedPath({
      path,
      language: value,
    });

    const expires = dayjs().add(1, "year").toDate();
    setCookie(COOKIES.APP_LOCALE, value, expires);

    window.location.assign(localizedPath);
  };

  return (
    <Root>
      <Select
        initialValue={currentLocale}
        name="locale"
        onChange={handleLocaleChange}
        options={LANGUAGE_OPTIONS}
      />
    </Root>
  );
};

export default LanguageSelector;
