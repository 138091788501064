import { getConversationPath } from "Sections/chat/routes";
import { getRelativePath } from "base/shared/Utils";

export const getUrl = ({ connectionId } = {}) => {
  if (connectionId) {
    return getConversationPath({ connectionId });
  }

  return getRelativePath("/inbox");
};
