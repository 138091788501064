import { css } from "styled-components";

import mediaqueries from "./mediaqueries";

// #region Heading

// 32-Extrabold
const hero = css`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;

  ${mediaqueries.md`
    font-size: 40px;
    line-height: 48px;
  `}

  ${mediaqueries.xl`
    font-size: 56px;
    line-height: 64px;
  `}
`;

// 28-Extrabold
const h1 = css`
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
`;

// 24-Bold
const h2 = css`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`;

// 20-Bold
const h3 = css`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`;

// 16-Bold
const h4 = css`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
`;

// 16-Medium
const h5 = css`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

export const HEADING = {
  "32-Extrabold": hero,
  "28-Extrabold": h1,
  "24-Bold": h2,
  "20-Bold": h3,
  "16-Bold": h4,
  "16-Medium": h5,
};

export const HEADING_DEPRECATED = {
  hero,
  h1,
  h2,
  h3,
  h4,
  h5,
};

export const HEADING_LEVELS = Object.keys(HEADING);

export const getHeadingFont = (headingType) =>
  typeof headingType === "string"
    ? HEADING[headingType]
    : HEADING_DEPRECATED[`h${headingType}`];

// #endregion

// #region Body

// 16-Regular
const b1 = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

// 14-Bold
const b2 = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;

// 14-Medium
const b21 = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

// 14-Regular
const b3 = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

// 12-Bold
const b4 = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
`;

// 12-Medium
const b5 = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

// 12-Regular
const b6 = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

// 10-Medium
const b7 = css`
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
`;

export const BODY = {
  "16-Regular": b1,
  "14-Bold": b2,
  "14-Medium": b21,
  "14-Regular": b3,
  "12-Bold": b4,
  "12-Medium": b5,
  "12-Regular": b6,
  "10-Medium": b7,
};

export const BODY_DEPRECATED = {
  b1,
  b2,
  "b2.1": b21,
  b3,
  b4,
  b5,
  b6,
  b7,
};

export const BODY_LEVELS = Object.keys(BODY);

export const getBodyFont = (bodyType) =>
  typeof bodyType === "string"
    ? BODY[bodyType]
    : BODY_DEPRECATED[`b${bodyType}`];

// #endregion
